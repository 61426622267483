function parseOverview(entry) {
  const { displayTitle = '', objectives = '', description = '' } = entry
  return { description, objectives, title: displayTitle }
}
function parseDetails(entry) {
  const {
    time = '',
    metadata = [],
    slpType = '',
    advisoryActivityType = '',
  } = entry
  const tags = metadata.map(tag => {
    const { programTheme = '' } = tag || {}
    return programTheme
  })
  return {
    activityType: advisoryActivityType,
    projectType: slpType,
    tags,
    time,
  }
}
function parseInstructions(entry) {
  const {
    action = '',
    demonstration = '',
    discussion = '',
    introduction = '',
    investigation = '',
    materials = '',
    planning = '',
    preparation = '',
    projectDescription = '',
    reflection = '',
    steps = '',
    warmUp = '',
  } = entry || {}

  const part1 = { materials, preparation, projectDescription }
  const part2 = {
    action,
    discussion,
    introduction,
    investigation,
    planning,
    steps,
    warmUp,
  }
  const part3 = { demonstration, reflection }
  const instructions = { part1, part2, part3 }

  return instructions
}

export default function parseEntry(entry) {
  const { id = '' } = entry || {}

  const overview = parseOverview(entry)
  const details = parseDetails(entry)
  const instructions = parseInstructions(entry)

  return { details, id, instructions, overview }
}
