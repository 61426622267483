import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const ContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 15px 0px;
`

export const CloseButton = styled.button`
  background-color: ${themeGet('colors.iconBlue')};
  border-radius: 5px;
  color: ${themeGet('colors.white')};
  font-family: ${themeGet('font.molde.regular')};
  padding: 16px 0px;
  width: 25%;
`

export const Email = styled.a`
  color: ${themeGet('colors.iconBlue')};
  font-family: ${themeGet('font.molde.regular')};
`
export const Section1 = styled.p`
  font-family: ${themeGet('font.molde.regular')};
  text-align: center;
  margin-bottom: 20px;
`

export const Section2 = styled.p`
  font-family: ${themeGet('font.molde.regular')};
  text-align: center;
  margin-bottom: 15px;
`

export const Status = styled.p`
  background-color: ${themeGet('colors.gray200')};
  border-radius: 8px;
  font-family: ${themeGet('font.molde.regular')};
  margin-bottom: 20px;
  padding: 15px 0px;
  text-align: center;
  width: 100%;
`

export const Support = styled.p`
  font-family: ${themeGet('font.molde.regular')};
  margin-bottom: 35px;
  text-align: center;
`

export const Title = styled.h2`
  color: ${themeGet('colors.iconBlue')};
  font-family: ${themeGet('font.sharpSlab.semibold')};
  margin-bottom: 15px;
`
