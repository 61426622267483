export const VIEW_OPTION_PATHS = {
  PROGRAM_ADOPTION: 'program_adoption',
  MONITOR: 'monitor',
}

export const PROGRAM_PERMISSION_MAPPING = {
  // techincally K8 is k5SelDigital + mspDigital
  // but simplify this to only check a single permission flag per program:
  HS: 'highSchool',
  HS_MONITOR: 'highSchool',
  K8: 'k5SelDigital',
  K8_MONITOR: 'k5SelDigital',
  SELA: 'sela',
  BPU: 'bpuDigital',
  CPU: 'cpuDigital',
}

export const SNAPSHOTS_NAME_MAP = {
  HS: 'onboardingSnapshot',
  HS_MONITOR: 'progressMonitoringSnapshot',
  K8: 'onboardingSnapshot',
  K8_MONITOR: 'progressMonitoringSnapshot',
}

export const formatManageUsersLinkLabel = name => `Manage Users at ${name}`
export const formatReportLinkLabel = name => `See Report about ${name}`
export const formatUnitKey = index => `Unit ${index + 1}`
export const formatUserSetupLinkLabel = name =>
  `See Users without classes at ${name}`

export const getProgramSnapshot = (programKey, snapshots) => {
  const programPermissionKey = PROGRAM_PERMISSION_MAPPING[programKey]
  if (!programPermissionKey) {
    throw new Error(`unsupported program: ${programKey}`)
  }

  const match = snapshots?.find(
    snap => snap?.permissionsPrograms?.[programPermissionKey],
  )

  return match
}

export const getTooltip = (programSnapshot, location) => {
  return programSnapshot?.tooltips?.find(t => t.tooltipLocation === location)
}
