import { put } from 'redux-saga/effects'

import { NAVIGATION_EVENT } from 'layers/navigation/store/types'
import ContenfulTypes from 'layers/content/store/types'
import ContentfulActions from 'layers/content/store/actions'
import { ROOT_ENTRY_ID } from 'layers/content/Hocs/RootContent/data'
import { getRootEntry } from 'layers/content/utils'

import SagaLifeCycle from 'layers/saga/lifecycle'

import { getEntryForLearnLayer } from '../utils/api'
import { isANamespacePath } from '../utils/verify'
import { NODE_START } from '../nodes/start/constants'

import { findRawNamespaceEntryByPath } from './selectors'
import namespaceMap from './namespaceMap'

export function* handleNamespaceUpdates(namespaceEntry, sectionEntry) {
  const { id: namespaceId, pageData } = namespaceEntry || {}
  const { id: sectionId } = sectionEntry || {}
  const { route: namespaceRoute } = pageData || {}
  const { namespaceName, nextNodeName } = namespaceMap[namespaceRoute] || {}

  if (!namespaceId) return

  yield put(ContentfulActions.setContentNamespace(namespaceName, namespaceId))
  yield put(ContentfulActions.setContentNamespace(NODE_START, namespaceId))

  if (sectionId) {
    yield put(ContentfulActions.setContentNamespace(nextNodeName, sectionId))
  }
}

export function* handleEntryDataRetrieval(namespaceEntry, sectionEntry) {
  const { id: namespaceId } = namespaceEntry || {}
  const { id: sectionId } = sectionEntry || {}

  if (!namespaceId) return

  yield put(getEntryForLearnLayer({ entryId: namespaceId, include: 3 }))

  if (sectionId) {
    yield put(getEntryForLearnLayer({ entryId: sectionId, include: 2 }))
  }
}

export default class ContentNamespace extends SagaLifeCycle {
  *process(data) {
    const { CONTENTFUL_SUCCESS: entry, NAVIGATION_EVENT: navigation } = data
    const { value: pathname } = navigation
    const isNamespacePath = isANamespacePath(pathname)

    if (!isNamespacePath) return

    const { sectionEntry, namespaceEntry } =
      findRawNamespaceEntryByPath(entry, pathname) || {}

    if (!namespaceEntry) return

    yield handleNamespaceUpdates(namespaceEntry, sectionEntry)
    yield handleEntryDataRetrieval(namespaceEntry, sectionEntry)
  }

  subscribeEvents() {
    const { CONTENTFUL_SUCCESS } = ContenfulTypes
    return [CONTENTFUL_SUCCESS, NAVIGATION_EVENT]
  }

  filter_NAVIGATION_EVENT(action) {
    const { payload: actionPayload } = action
    const { silent, value: pathname } = actionPayload || {}
    const isNamespacePath = isANamespacePath(pathname)

    if (!pathname || !silent || !isNamespacePath) {
      this.earlyExit('filter_NAVIGATION_EVENT missing action')
    }
  }

  filter_CONTENTFUL_SUCCESS(action) {
    const { payload: entry, meta } = action || {}
    const { entryId: actionEntryId } = meta || {}
    if (
      !entry ||
      !actionEntryId ||
      // check that our action entry id matches the root id of the app
      actionEntryId !== ROOT_ENTRY_ID
    ) {
      this.earlyExit('filter_CONTENT_SUCCESS missing action')
    }
  }

  select_NAVIGATION_EVENT(state) {
    const { router } = state || {}
    const { location } = router || {}
    const { pathname } = location || {}
    return { value: pathname }
  }

  select_CONTENTFUL_SUCCESS(state) {
    const rootEntry = getRootEntry(state)
    const { payload } = rootEntry || {}
    return payload
  }
}
