import React from 'react'
import PropTypes from 'prop-types'
import {
  BannerContentWrapper,
  BannerWrapper,
  BannerText,
  BannerHeader,
  StyledTextLink,
  SiteSelectorWrapper,
} from './component.styles'
import { BACK_TO_DASHBOARD_TEXT } from './constants'
import SiteSelector from 'components/SiteSelector'

export const LandingBanner = props => {
  const {
    className,
    dataTestId = 'default-id',
    isPreview,
    isHsSupports,
    navigateToDashboardOnClick,
    title,
    text,
    // TODO: revisit/refactor this logic when we receive final designs for
    //       the breadcrumb nav. the breadcrumb component itself could potentially
    //       handle when it renders
    isDistrict,
  } = props

  const dataTestIdPrefix = `landing-banner`
  const dataTestIdWrapper = `${dataTestIdPrefix}-wrapper-${dataTestId}`
  const dataTestIdHeader = `${dataTestIdPrefix}-header-${dataTestId}`
  const dataTestIdText = `${dataTestIdPrefix}-header-${dataTestId}`

  return (
    <BannerWrapper
      className={className}
      data-testid={dataTestIdWrapper}
      isHsSupports={isHsSupports}
    >
      {!isPreview && isHsSupports && (
        <SiteSelectorWrapper>
          <SiteSelector />
        </SiteSelectorWrapper>
      )}
      <BannerContentWrapper>
        {isDistrict && (
          <StyledTextLink
            dataTestId={'back-to-dashboard-link'}
            onClick={navigateToDashboardOnClick}
          >
            {BACK_TO_DASHBOARD_TEXT}
          </StyledTextLink>
        )}
        <BannerHeader data-testid={dataTestIdHeader}>{title}</BannerHeader>
        <BannerText align="center" data-testid={dataTestIdText}>
          {text}
        </BannerText>
      </BannerContentWrapper>
    </BannerWrapper>
  )
}

LandingBanner.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  isDistrict: PropTypes.bool,
  isHsSupports: PropTypes.bool,
  isPreview: PropTypes.bool,
  navigateToDashboardOnClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default LandingBanner
