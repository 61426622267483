import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box, Image } from 'grommet'
import { VideoPlayButton } from 'secondstepVideo'

export const VideoCalloutButton = styled.a`
  border-radius: 0.75rem;
  display: flex;

  svg {
    transition: all 0.2s ease;
    color: ${themeGet('colors.blue')};
  }

  &:focus,
  &:hover {
    cursor: pointer;

    svg {
      color: ${themeGet('colors.orange')};
    }
  }
`

export const ThumbnailWrapper = styled(Box)`
  position: relative;
  width: 100%;
  ${VideoPlayButton} {
    width: 100%;
    height: 100%;
    position: absolute;
  }
`

export const VideoThumbnail = styled(Image)`
  width: 100%;
  height: auto;
  border-radius: 0.75rem;
`
