import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { HeaderOne } from 'secondstep-components'
import RadioToggle from 'components/Teach/RadioToggle'

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const PageContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
  box-sizing: border-box;
  padding: 0 1.25rem 2rem;
`

export const StyledRadioToggle = styled(RadioToggle)`
  border-radius: 18px;
  align-items: center;
  margin-bottom: ${themeGet('spacing.turquoise20_1_25rem')};
  [role='radio'] {
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-block;
    padding: 0.2rem 1rem;
    border-radius: 14px;
    font-family: ${themeGet('fontFamilySemibold')};
  }

  [role='radio'][aria-checked='true'] {
    color: ${themeGet('colors.white')};
    background-color: ${themeGet('colors.buttons.primaryBackground')};
  }

  [role='radio'][aria-checked='false']:hover,
  [role='radio'][aria-checked='false']:active {
    color: ${themeGet('colors.buttons.primaryBackgroundHover')};
  }

  ${themeGet('breakpoints.mobileLarge')} {
    margin-bottom: 0;
  }
`

export const StyledHeaderOne = styled(HeaderOne)`
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('fontFamilyHeadingBold')};
  margin: calc(
      ${themeGet('spacing.red40_2_5rem')} +
        ${themeGet('spacing.turquoise20_1_25rem')}
    )
    0 ${themeGet('spacing.amber28_1_75rem')} 0;
`
