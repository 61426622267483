import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const AdvistoryActivitiesPageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`

export const PageContentWrapper = styled.div`
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
`
