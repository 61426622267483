import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NavigationHelper from '../../layers/navigation/navigationHelper'
import {
  BodyText,
  CategoryCalloutWrapper,
  ContentWrapper,
  HeaderText,
  IconWrapper,
  LinkStyled,
} from './component.styles.js'

class CategoryCallout extends Component {
  static propTypes = {
    Icon: PropTypes.elementType,
    dataTestId: PropTypes.string,
    goToLocation: PropTypes.func,
    location: PropTypes.object,
    text: PropTypes.string,
    title: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      isHovered: false,
    }
  }

  hoverManager = isHovered => {
    this.setState({ isHovered })
  }

  navigateToPage = () => {
    const { goToLocation, link, location } = this.props || {}
    const { pathname } = location || {}
    const depth = pathname.split('/').length - 1
    if (link) {
      const fullRoute = NavigationHelper.generateUrlWithDepthForRoute(
        pathname,
        link,
        depth,
      )
      goToLocation && goToLocation(fullRoute)
    } else {
      console.error('link missing ina category callout')
    }
  }

  render() {
    const {
      dataTestId = 'default-id',
      Icon = () => null,
      text,
      title,
    } = this.props
    const { isHovered } = this.state

    const testDataPrefix = 'category-callout'

    const dataTestIdWrapper = `${testDataPrefix}-wrapper-${dataTestId}`
    const testDataHoverControl = `${testDataPrefix}-hover-control-${dataTestId}`
    const dataTestIdHeader = `${testDataPrefix}-header-${dataTestId}`

    return (
      <LinkStyled
        data-testid={testDataHoverControl}
        href="javascript:void(0)"
        onClick={e => {
          e.preventDefault()
          this.navigateToPage()
        }}
        onMouseEnter={() => this.hoverManager(true)}
        onMouseLeave={() => this.hoverManager(false)}
      >
        <CategoryCalloutWrapper
          data-testid={dataTestIdWrapper}
          isHovered={isHovered}
        >
          <ContentWrapper>
            <HeaderText dataTestId={dataTestIdHeader}>{title}</HeaderText>
            <BodyText>{text}</BodyText>
          </ContentWrapper>

          <IconWrapper isHovered={isHovered}>
            <Icon isHovered={isHovered} />
          </IconWrapper>
        </CategoryCalloutWrapper>
      </LinkStyled>
    )
  }
}

export default CategoryCallout
