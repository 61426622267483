import styled, { withTheme } from 'styled-components'
import styledNative from 'styled-components/native'
import { themeGet } from 'styled-system'
import { HeaderTwo, BodyCopy } from 'secondstep-components'
import { Link } from 'react-router-dom'

export const Container = styled.div`
  background-color: ${props =>
    props.backgroundColor ||
    themeGet('banners.contentTypeDescriptionBackgroundColor')};
  background-image: url(${props =>
    props.backgroundImage ||
    themeGet('banners.contentTypeDescriptionBackgroundImage')});
  background-position: center;
  border-radius: 0.688rem;
  flex-direction: column;
  border: ${props => {
    return props.borderColor
      ? `2px solid ${props.borderColor || themeGet('colors.borders.primary')}`
      : 'none'
  }};
  box-shadow: ${themeGet('banners.boxShadow', 'none')};
`

export const StyledImage = styled.img`
  height: 11.375rem;
  width: 11.375rem;
  border-radius: 50%;
  margin: 1rem 2.75rem 1rem 0;
  ${themeGet('breakpoints.mobileLarge')} {
    display: none;
  }
`

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  .text-content {
    padding: 1.25rem 1.875rem;
    ${themeGet('breakpoints.mobileLarge')} {
      padding: 1.375rem 1.25rem;
    }
  }
`

export const HeaderText = styled(HeaderTwo)`
  margin-top: 0.188rem;
  margin-bottom: 0;
  color: ${props => props.headerColor || themeGet('banners.headerTextColor')};
`

export const HeaderWrapper = withTheme(styledNative.View`
  display: flex;
  flex-direction: row;
`)

export const BodyText = styled(BodyCopy)`
  flex-wrap: wrap;
  margin-top: 0.688rem;
  color: ${props => props.bodyColor || themeGet('banners.bannerTextColor')};

  a {
    color: ${props => props.bodyColor || themeGet('banners.bannerTextColor')};
    text-decoration: underline;
  }

  a:hover {
    text-decoration: underline;
  }
`

export const IconWrapper = styled.div`
  height: 1.938rem;
  margin-right: 0.625rem;

  svg {
    width: auto;
    height: 110%;

    g {
      opacity: 1;
    }

    path {
      fill: ${themeGet(
        'banners.contentTypeDescriptionIconColor',
        'colors.black',
      )};
      opacity: 1;
    }
  }
`

export const LinkContent = styled.div.attrs(() => ({
  'data-testid': 'link-content',
}))`
  padding-top: 1rem;
  padding-bottom: 1rem;
`
export const TextLink = styled(Link)`
  color: ${({ color }) => color || themeGet('colors.grayLink')};
  text-decoration: none;
  font-family: ${themeGet('fontFamilySemibold')};
  &:hover {
    color: ${({ hoverColor }) => hoverColor || themeGet('colors.grayLink')};
  }
`

export const FooterSection = styled.div``
