import { connect } from 'react-redux'
import { navigationEvent } from 'layers/navigation/store/operations'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import { operations as reportsOperations } from 'store/reportsManager'
import ReportsUnitCollapsible from './component'

export const mapStateToProps = ({ reportsManager }, ownProps) => {
  const { selectedLicense, reports } = reportsManager || {}
  const { user_details } = reports[selectedLicense] || {}
  const {
    displayTitle,
    totalUsers: total,
    usersCompleted: completed,
    usersInProgress: inProgress,
    usersNotStarted: notStarted,
  } = ownProps || {}

  let usersCompleted = Math.round((completed / total) * 100) || 0
  let usersInProgress = Math.round((inProgress / total) * 100) || 0
  let usersNotStarted = Math.round((notStarted / total) * 100) || 0

  return {
    displayTitle,
    selectedLicense,
    user_details,
    usersCompleted,
    usersInProgress,
    usersNotStarted,
  }
}

const mapper = entry => {
  const { displayTitle, label, microlearnings } = entry || {}

  return {
    microlearnings,
    unitLabel: label,
    unitTitle: displayTitle,
  }
}

const options = {
  entryIdProp: true,
  mapper,
  spread: true,
}

const mapDispatchToProps = {
  navigationEvent,
  fetchReportsUserDetails: reportsOperations.fetchReportsUserDetails,
}

export default ContentEntryDataGetter(
  ReportsUnitCollapsible,
  options,
  connect(mapStateToProps, mapDispatchToProps),
)
