import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BaseContainer, HeaderFour } from 'secondstep-components'
import { getHighlightColor } from 'utils/highschoolHelpers'

export const StyledContainer = styled(BaseContainer)`
  margin: 2.5rem 1rem 1.25rem 1rem;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  ${themeGet('breakpoints.mobileLarge')} {
    margin: 1.75rem 1rem 2.5rem 1rem;
  }
  .content {
    width: 100%;
    max-width: ${themeGet('breakpoints.sizes.large')};
  }
`

export const StyledHeaderFour = styled(HeaderFour)`
  max-width: unset;
  padding-top: 1.3rem;
  padding-bottom: 1.6rem;
  background-color: ${props => getHighlightColor(props.audience)};
  width: ${props => (props.left ? 'calc(100% - 1.5625rem)' : '100%')};
  padding-left: ${props => (props.left ? '1.5625rem' : '0')};
  border-top-left-radius: ${props => (props.left ? '0.5rem' : '0')};
  border-top-right-radius: ${props => (!props.left ? '0.5rem' : '0')};
  margin-bottom: 0;
  ${themeGet('breakpoints.mobileLarge')} {
    width: calc(100% - 1.5625rem);
    padding-left: 1.5625rem;
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
  }
`

export const MetadataContainer = styled.div`
  display: flex;
  border: 1.5px ${props => getHighlightColor(props.audience)} solid;
  border-radius: 0.625rem;
  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;
    border: none;
    gap: 1.25rem;
  }
`

export const MetadataColumn = styled.div`
  dl {
    margin-block-start: 1.375rem;
    line-height: 1.5rem;
  }
  :nth-child(1) {
    flex: 1 0 35%;
    dl {
      padding-left: 1.5625rem;
    }
  }
  :nth-child(2) {
    flex: 1 0 60%;
    ${themeGet('breakpoints.mobileLarge')} {
      dl {
        padding-left: 1.5625rem;
      }
    }
    dl {
      padding-right: 1.75rem;
    }
  }
  ${themeGet('breakpoints.mobileLarge')} {
    border: 1.5px ${props => getHighlightColor(props.audience)} solid;
    border-radius: 0.625rem;
  }
`
export const MetadataCell = styled.div`
  padding-bottom: 0.65rem;
  dt {
    display: inline;
  }
  dd {
    margin-inline-start: 0;
    display: inline;
  }
`
