import NavigationHelper from 'layers/navigation/navigationHelper'
import ContentfulParser from 'utils/contentfulParser/contents'
import { KEY_WORDS_TITLE, TEACHER_TIP_TITLE } from './constants'

export const mapper = (entry, location) => {
  const {
    day1,
    day1Pdfs,
    day2,
    day2Pdfs,
    day3,
    day3Pdfs,
    day4,
    day4Pdfs,
    day5,
    day5Pdfs,
    books,
    bbPrepVideo,
    teacherTip,
    keyWords,
    ...formattedEntry
  } = entry || {}
  const days = parseDays(entry)
  const { streamingLessonMediaRoute, lessonScriptRoute } = parsePageRoutes(
    entry,
    location,
  )
  const formattedBbPrepVideos = Array.isArray(bbPrepVideo)
    ? bbPrepVideo.map(video => ContentfulParser.parseMedia(video))
    : []
  const formattedBooks = Array.isArray(books)
    ? books.map(book => {
        return {
          ...ContentfulParser.book(book),
        }
      })
    : []
  const formattedDays = formatDays(
    days,
    streamingLessonMediaRoute,
    lessonScriptRoute,
    formattedBooks,
    formattedBbPrepVideos,
  )
  const lessonPrepSections = parseLessonPrepSections(entry)

  return {
    ...formattedEntry,
    days: formattedDays,
    lessonPrepSections,
  }
}

export const parseLessonPrepSections = entry => {
  const { keyWords, teacherTip } = entry || {}
  let sections = []
  if (keyWords) {
    sections = [
      ...sections,
      { id: 'key-words', title: KEY_WORDS_TITLE, content: keyWords },
    ]
  }

  if (teacherTip) {
    sections = [
      ...sections,
      { id: 'teaching-notes', title: TEACHER_TIP_TITLE, content: teacherTip },
    ]
  }

  return sections
}

export const parsePageRoutes = (entry, location) => {
  const { content } = entry || {}
  let streamingLessonMediaRoute
  let lessonScriptRoute

  const pathname = ContentfulParser.parsePathname(location)
  const depth = pathname.split('/').length - 1

  content &&
    content.forEach(entry => {
      const contentType = ContentfulParser.parseContentType(entry)
      const slug = ContentfulParser.parseSlug(entry)
      const route = NavigationHelper.generateUrlWithDepthForRoute(
        pathname,
        slug,
        depth,
      )

      if (contentType === 'slm') {
        streamingLessonMediaRoute = route
      } else if (contentType === 'article') {
        lessonScriptRoute = route
      }
    })

  return { streamingLessonMediaRoute, lessonScriptRoute }
}

export const parseDays = entry => {
  let days = []
  /**
   * Contentful fields for separate days are in the format of "day1, day1Pdfs"
   * There will always be 5 days
   */
  for (let index = 1; index <= 5; index++) {
    const key = `day${index}`
    const pdfKey = `${key}Pdfs`
    const dayTitle = `Day ${index}`
    const { [key]: dayText, [pdfKey]: dayPdfs } = entry || {}

    if (dayText || dayPdfs) {
      days = [...days, { id: key, dayText, dayPdfs, title: dayTitle }]
    }
  }

  return days
}

export const formatDays = (
  days,
  streamingLessonMediaRoute,
  lessonScriptRoute,
  books,
  bbPrepVideos,
) => {
  return (
    days &&
    days.map(day => {
      if (day.id === 'day1') {
        const title = 'Puppet Script'
        return {
          ...day,
          streamingLessonMediaRoute,
          lessonScriptRoute,
          title: [day.title, title].join(': '),
          bbPrepVideos,
        }
      }

      if (day.id === 'day2') {
        const title = 'Story and Discussion'
        return {
          ...day,
          streamingLessonMediaRoute,
          lessonScriptRoute,
          title: [day.title, title].join(': '),
        }
      }

      if (day.id === 'day3') {
        const title = 'Skill-Practice Activity 1'
        return {
          ...day,
          title: [day.title, title].join(': '),
        }
      }

      if (day.id === 'day4') {
        const title = 'Skill-Practice Activity 2'
        return {
          ...day,
          title: [day.title, title].join(': '),
        }
      }

      if (day.id === 'day5') {
        const title = 'Read a Storybook'
        return { ...day, books, title: [day.title, title].join(': ') }
      }

      return day
    })
  )
}
