import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, HeaderThree, HeaderTwo } from 'secondstep-components'

export const ComponentWrapper = styled.div`
  align-items: center;
  justify-content: center;
`

export const PartsWrapper = styled.div`
  background-color: ${themeGet('colors.white')};
  border-radius: 0.625rem;
  box-shadow: 0rem 0rem 0.625rem ${themeGet('colors.boxShadow')};
  margin: -1.875rem 6.25rem 2.5rem;
  padding: 2rem 1.75rem 2.125rem;
  max-width: calc(${themeGet('breakpoints.sizes.large')} - 160px);

  ${themeGet('breakpoints.tablet')} {
    margin-left: 2.5rem;
    margin-right: 2.5rem;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
`

export const GrayBackground = styled.div`
  display: flex;
  align-items: center;
  background-color: ${themeGet('colors.backgroundNeutralGray')};
  flex-direction: column;
  justify-content: center;
`

export const PartTitle = styled(HeaderTwo).attrs(({ partIndex }) => ({
  borderTopWidth: partIndex === 1 ? '0px' : '1px',
  paddingTop: partIndex === 1 ? '0.1875rem' : '1.4375rem',
  marginTop: partIndex === 1 ? '0rem' : '1.313rem',
}))`
  border-top: ${({ borderTopWidth }) => borderTopWidth} solid
    ${themeGet('colors.gray500')};
  color: ${themeGet('colors.darkBlue')};
  font-family: ${themeGet('fontFamilyHeadingExtraBold')};
  font-size: ${themeGet('font.size.headers.h2')};
  margin-top: ${({ marginTop }) => marginTop};
  margin-bottom: 0;
  padding-top: ${({ paddingTop }) => paddingTop};
`

export const PartSubsection = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 0.875rem;
`

export const PartSubtitle = styled(HeaderThree)`
  display: inline;
  padding-bottom: 0.375rem;
  margin-bottom: 0.125rem;
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('fontFamilyBold')};
  font-size: 1.25rem;
  line-height: 1.75rem;
`

export const InstructionsText = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};

  p:not(:last-child) {
    margin-block-end: 0.375rem;
  }

  ul,
  ol {
    margin-top: 0.125rem;
    margin-bottom: 0;
    padding-left: 1.125rem;

    li:not(:last-child) {
      margin-bottom: 0.25rem;
    }
  }
`
