const k5Grades = ['k', '1', '2', '3', '4', '5']
const msGrades = ['6', '7', '8']
const titleBuilder = label => {
  return `k8 explore g${label} quick link`
}
export const k5ProgramContentfulInternalTitles = k5Grades.map(grade =>
  titleBuilder(grade),
)
export const msProgramContentfulInternalTitles = msGrades.map(grade =>
  titleBuilder(grade),
)
export const contentfulProgramLinksInternalTitle =
  'k8 admin quick links - explore lessons'
export const MY_CLASSES = 'My\u00A0Classes'
