import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import HighSchoolPreviewBanner from 'components/HighSchoolPreviewBanner'
import SiteSelector from 'components/SiteSelector'
import {
  BannerSpacer,
  PageContainer,
  LayoutContainer,
  ColumnContainer,
  RowContainer,
  SectionContainer,
  SiteSelectorWrapper,
} from './component.styles'
import NavigationHelper from 'layers/navigation/navigationHelper'
import {
  HIGH_SCHOOL_INDIVIDUAL_RESOURCE_PATH,
  filterByResourceType,
} from 'utils/highschoolHelpers'
import withSiteContext from 'layers/content/v2/Hocs/withSiteContext'
import { CardSection } from './cardSection'
import {
  educatorHeader,
  educatorTooltipLabel,
  schoolwideHeader,
  schoolwideTooltipLabel,
  studentTooltipLabel,
} from './constants'

function shouldShowDedicatedResources(dedicatedResources) {
  return !!dedicatedResources && dedicatedResources.length > 0
}

const Week = props => {
  const [displayTitle, setDisplayTitle] = useState()
  const [description, setDescription] = useState()
  const [dedicatedResources, setDedicatedResources] = useState()
  const [dedicatedLabel, setDedicatedLabel] = useState()
  const [integrated, setIntegrated] = useState({
    educatorResources: [],
    schoolwideResources: [],
  })

  const [tooltips, setTooltips] = useState([])

  const { content, navigationEvent, isPreview } = props

  useEffect(() => {
    if (!content) {
      return
    }

    const { week } = content

    if (week) {
      if (week.displayTitle) setDisplayTitle(week?.displayTitle)
      if (week.description) setDescription(week?.description)
      if (week.dedicatedResources)
        setDedicatedResources(week.dedicatedResources)
      if (week.dedicatedLabel) setDedicatedLabel(week.dedicatedLabel)
      if (week.integrated) {
        const educatorResources = filterByResourceType(
          week.integrated,
          educatorHeader,
        )
        const schoolwideResources = filterByResourceType(
          week.integrated,
          schoolwideHeader,
        )
        setIntegrated({ educatorResources, schoolwideResources })
      }
      if (week.tooltips) setTooltips(week.tooltips)
    }
  }, [content])

  const extractResourceType = resources => {
    return resources.map(resource => {
      const resourceType = resource.metadataValues.map(
        metadata => metadata.resourceType,
      )
      return { ...resource, resourceType }
    })
  }

  const onClick = routeSlug => {
    const type = NavigationHelper.types.NEW_TAB_SAME_SITE
    const { search: searchParams } = location
    navigationEvent(
      `${HIGH_SCHOOL_INDIVIDUAL_RESOURCE_PATH}/${routeSlug}${searchParams}`,
      type,
    )
  }

  return (
    <>
      {isPreview && (
        <>
          <BannerSpacer />
          <HighSchoolPreviewBanner />
        </>
      )}
      <LayoutContainer>
        <PageContainer>
          {!isPreview && (
            <SiteSelectorWrapper>
              <SiteSelector />
            </SiteSelectorWrapper>
          )}
          <ColumnContainer>
            <h1>{displayTitle}</h1>
            <p className="description">{description}</p>
            <RowContainer
              isSingleCard={integrated?.educatorResources?.length === 1}
            >
              <SectionContainer>
                {integrated?.educatorResources?.length > 0 && (
                  <CardSection
                    headerText={educatorHeader}
                    isSingleCard={integrated?.educatorResources?.length === 1}
                    onClick={onClick}
                    recourceType={extractResourceType(
                      integrated?.educatorResources,
                    )}
                    resources={integrated?.educatorResources}
                    tooltipLabel={educatorTooltipLabel}
                    tooltips={tooltips}
                  />
                )}
              </SectionContainer>
              <SectionContainer>
                {integrated?.schoolwideResources?.length > 0 && (
                  <CardSection
                    headerText={schoolwideHeader}
                    isSingleCard={integrated?.schoolwideResources?.length === 1}
                    onClick={onClick}
                    recourceType={extractResourceType(
                      integrated?.schoolwideResources,
                    )}
                    resources={integrated?.schoolwideResources}
                    tooltipLabel={schoolwideTooltipLabel}
                    tooltips={tooltips}
                  />
                )}
              </SectionContainer>
            </RowContainer>
          </ColumnContainer>
          {shouldShowDedicatedResources(dedicatedResources) && (
            <CardSection
              headerText={dedicatedLabel}
              isSingleCard={dedicatedResources?.length === 1}
              onClick={onClick}
              recourceType={extractResourceType(dedicatedResources)}
              resources={dedicatedResources}
              tooltipLabel={studentTooltipLabel}
              tooltips={tooltips}
            />
          )}
        </PageContainer>
      </LayoutContainer>
    </>
  )
}

Week.propTypes = {
  content: PropTypes.shape({
    week: PropTypes.shape({
      displayTitle: PropTypes.string.isRequired,
      dedicatedLabel: PropTypes.string,
      tooltips: PropTypes.array,
      description: PropTypes.string.isRequired,
      integrated: PropTypes.array,
      dedicatedResources: PropTypes.array,
    }),
  }),
  isFetching: PropTypes.bool,
  isPreview: PropTypes.bool,
  isSingleCard: PropTypes.bool,
  navigationEvent: PropTypes.func,
}

export default withSiteContext(Week, {
  useGuard: true,
})
