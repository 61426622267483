import styled from 'styled-components'

export const Container = styled.div`
  .mobile-only {
    select {
      padding: 12px;
      padding-left: 16px;
      padding-right: 12px;
    }
  }
`
