import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NavigationHelper from '../../layers/navigation/navigationHelper'
import ProgramSupportTrees from './assets/ProgramSupportTrees'

import {
  BodyText,
  Container,
  HeaderText,
  Icon,
  LearnMoreLink,
  Left,
  Right,
  Oval,
} from './component.styles'
import { isHighSchoolProgramSupports } from 'utils/highschoolHelpers'

const LEARN_MORE = 'Learn More'

class FeatureCategoryCallout extends Component {
  static propTypes = {
    body: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    link: PropTypes.string,
    location: PropTypes.string,
    navigationEvent: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
  }

  navigateToPage = () => {
    const { navigationEvent, link, location } = this.props || {}
    const { pathname } = location || {}
    const depth = pathname.split('/').length - 1
    const isDuplicatePath = NavigationHelper.isADuplicatePath(pathname, link)

    if (link && !isDuplicatePath) {
      const fullRoute = NavigationHelper.generateUrlWithDepthForRoute(
        pathname,
        link,
        depth,
      )
      navigationEvent && navigationEvent(fullRoute)
    }
  }

  render() {
    const { body, id, image, title } = this.props
    const isHsSupports = isHighSchoolProgramSupports()

    return (
      <Container data-testid={`feature-category-callout-${id}`}>
        <Left>
          {isHsSupports ? (
            <Oval>
              <ProgramSupportTrees />
            </Oval>
          ) : image ? (
            <Icon source={image} />
          ) : null}
        </Left>
        <Right>
          <HeaderText>{title}</HeaderText>
          <BodyText>{body}</BodyText>
          <LearnMoreLink
            dataTestId={`learn-more-link-${id}`}
            href="javascript:void(0)"
            onClick={e => {
              e.preventDefault()
              this.navigateToPage()
            }}
          >
            {LEARN_MORE}
          </LearnMoreLink>
        </Right>
      </Container>
    )
  }
}

export default FeatureCategoryCallout
