import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Body = styled.div``

export const Container = styled.div`
  margin-bottom: 1.375rem;
  max-width: 80%;
  &:last-of-type {
    margin-bottom: 0;
  }
  ${themeGet('breakpoints.large')} {
    max-width: 100%;
  }
`

export const Header = styled.button`
  all: unset;
  cursor: pointer;
  font-size: 1rem;
  font-family: ${themeGet('font.sharpSlab.semibold')};
  color: ${themeGet('colors.secondStepBlue')};
`

export const IconWrapper = styled.div`
  display: inline;
  margin: 0.5rem;
  svg {
    transform: translateY(0.25rem);
  }
`
