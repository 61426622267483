export const BUTTON_CANCEL = 'CANCEL'
export const BUTTON_SUBMIT = 'CREATE CLASS'
export const COURSE_INSTANCE_LABEL = 'Class Name'
export const COURSE_INSTANCE_PLACEHOLDER = 'Enter a class name'
export const CREATE_HEADER = 'Create Class'
export const END_DATE_LABEL = 'End Date'
export const END_DATE_PLACEHOLDER = 'Choose a date'
export const SCHOOL_LABEL = 'School'
export const SCHOOL_PLACEHOLDER = 'Select a school'
export const SELECTED_COURSE_LABEL = 'Grade'
export const SELECTED_COURSE_PLACEHOLDER = 'Select a grade'
export const START_DATE_LABEL = 'Start Date'
export const START_DATE_PLACEHOLDER = 'Choose a date'
export const ASK_NO_TEACHING = 'Not teaching students?'
export const PREVIEW_LESSONS = 'Preview lessons'
export const ALMOST_THERE = 'Almost there!'
export const START_TEACHING = ' Name your class to start teaching.'
