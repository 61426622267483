import { connect } from 'react-redux'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_CONTENTFUL_PRODUCT,
  ERROR_TYPE_LMS,
} from 'layers/errorHandling/apiError'
import ModuleDetails from './component'

export const mapper = (entry = {}) => {
  const { description, displayTitle, label } = entry
  return { description, displayTitle, label }
}

const options = {
  include: 1,
  mapper,
  spread: true,
  entryIdProp: true,
}

const mapStateToProps = ({ lmsManager }, ownProps) => {
  const { modules: selaModules } = lmsManager?.instructorData?.Sela[0] || []
  const { modules: ftModules } = lmsManager?.instructorData?.SelaFT[0] || []

  let resultModules = []
  resultModules = selaModules
    ? resultModules.concat(selaModules)
    : resultModules
  resultModules = ftModules ? resultModules.concat(ftModules) : resultModules
  const lmsModule = resultModules?.find(
    ({ contentfulID }) => contentfulID === ownProps.entryId,
  )

  return {
    lmsModule,
  }
}

export default ContentEntryDataGetter(
  withApiError(ModuleDetails, [
    ERROR_TYPE_CONTENTFUL,
    ERROR_TYPE_CONTENTFUL_PRODUCT,
    ERROR_TYPE_LMS,
  ]),
  options,
  connect(mapStateToProps, null),
)
