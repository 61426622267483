import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MindYetiPlaylistPage from './component'

export class MindYetiPlaylistContainer extends Component {
  static propTypes = {
    entry: PropTypes.object,
    location: PropTypes.object,
    navigationEvent: PropTypes.func,
  }

  componentDidMount() {
    this.navigateToFirstPlaylist()
  }

  componentDidUpdate(prevProps) {
    // guard against re-triggering the nav event after we've already called it
    if (
      !this.shouldReroute(prevProps.location.pathname) ||
      !this.shouldRerouteFamilies(prevProps.location.pathname)
    ) {
      this.navigateToFirstPlaylist()
    }
  }

  shouldReroute(pathname) {
    return pathname.endsWith('playlists') || pathname.endsWith('playlists/')
  }

  shouldRerouteFamilies(pathname) {
    return (
      pathname.endsWith('playlists-and-supports') ||
      pathname.endsWith('playlists-and-supports/')
    )
  }

  navigateToFirstPlaylist() {
    const { pathname } = location
    if (this.shouldReroute(pathname)) {
      const { navigationEvent } = this.props
      // ensure we strip out the trailing slash:
      const url = `${pathname.replace(/\/$/, '')}/get-started`
      navigationEvent(url)
    }
    if (this.shouldRerouteFamilies(pathname)) {
      const { navigationEvent } = this.props
      // ensure we strip out the trailing slash:
      const url = `${pathname.replace(/\/$/, '')}/get-started-playlist`
      navigationEvent(url)
    }
  }

  render() {
    const { pathname } = location
    if (this.shouldReroute(pathname) || this.shouldRerouteFamilies(pathname)) {
      return null
    }
    return <MindYetiPlaylistPage entry={this.props.entry} />
  }
}
