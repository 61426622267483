import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledTextLink,
  SubBannerBackground,
  SubBannerContentWrapper,
  SubBannerWrapper,
  SubBannerText,
  Title,
} from './component.styles'
import { BACK_TO_DASHBOARD_TEXT } from './constants'
import { pageTypes } from 'utils/getPageTypeByUrl'

export const SubLandingBanner = props => {
  const {
    dataTestId = 'default-id',
    isSelaLeaderPage,
    navigateToDashboardOnClick,
    pageType,
    text,
    title,
  } = props

  const dataTestIdPrefix = `sublanding-banner`
  const dataTestIdWrapper = `${dataTestIdPrefix}-wrapper-${dataTestId}`
  const dataTestIdHeader = `${dataTestIdPrefix}-header-${dataTestId}`
  const dataTestIdText = `${dataTestIdPrefix}-text-${dataTestId}`

  return (
    <SubBannerWrapper data-testid={dataTestIdWrapper}>
      <SubBannerBackground
        isSelaLeaderPage={isSelaLeaderPage}
        pageType={pageType}
      />
      <SubBannerContentWrapper>
        {pageType === pageTypes.FAMILY_RESOURCE_PAGE && (
          <StyledTextLink
            dataTestId={'back-to-dashboard-link'}
            onClick={navigateToDashboardOnClick}
          >
            {BACK_TO_DASHBOARD_TEXT}
          </StyledTextLink>
        )}
        <Title data-testid={dataTestIdHeader} pageType={pageType}>
          {title}
        </Title>
        <SubBannerText data-testid={dataTestIdText} pageType={pageType}>
          {text}
        </SubBannerText>
      </SubBannerContentWrapper>
    </SubBannerWrapper>
  )
}

SubLandingBanner.propTypes = {
  dataTestId: PropTypes.string,
  isSelaLeaderPage: PropTypes.bool,
  navigateToDashboardOnClick: PropTypes.func.isRequired,
  pageType: PropTypes.string,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default SubLandingBanner
