import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { TextLink } from 'secondstep-components'

export const UnitComponentWrapper = styled(Box)`
  flex-direction: column;
  padding: 0 0 ${themeGet('spacing.amber28_1_75rem')};
  width: 100%;
`

export const UnitOverviewBox = styled(Box)`
  flex-direction: row;
  gap: ${themeGet('spacing.turquoise20_1_25rem')};

  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
    gap: ${themeGet('spacing.blue16_1rem')};
  }
`

export const AcknowledgementsLink = styled(TextLink)`
  @media (max-width: 625px) {
    justify-content: center;
  }
`
