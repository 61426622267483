import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ImplementationGuide from './component'
import NavigationHelper from 'layers/navigation/navigationHelper'

const ImplementationGuideContainer = ({ entry, navigationEvent }) => {
  const { description, displayTitle } = entry || {}
  const programKey = location.pathname.includes('admin/implementation-guide')
    ? 'K8'
    : 'HS'

  const navigateToDashboardOnClick = () => {
    const url = `/`
    const type = NavigationHelper.types.PUSH
    navigationEvent(url, type)
  }

  return (
    <ImplementationGuide
      description={description}
      displayTitle={displayTitle}
      navigateToDashboardOnClick={navigateToDashboardOnClick}
      programKey={programKey}
    />
  )
}

ImplementationGuideContainer.propTypes = {
  entry: PropTypes.object,
  navigationEvent: PropTypes.func,
}

export default connect(
  null,
  NavigationHelper.mapDispatchToProps,
)(ImplementationGuideContainer)
