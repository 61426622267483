import {
  push,
  replace,
  go,
  goBack,
  goForward,
  LOCATION_CHANGE,
} from 'connected-react-router'
import { takeEvery, put } from 'redux-saga/effects'
import URL from 'url'

import {
  NAVIGATION_EVENT,
  DOWNLOAD_LINK,
  GO,
  GOBACK,
  GOFORWARD,
  OPEN,
  NEW_TAB,
  NEW_TAB_SAME_SITE,
  PUSH,
  REPLACE,
} from './types'
import redirectPaths from './redirectPaths'

const SPA_NAVIGATIONS = {
  [PUSH]: push,
  [GO]: go,
  [GOBACK]: goBack,
  [GOFORWARD]: goForward,
  [REPLACE]: replace,
}

export function navigationEvent(value, type = PUSH, silent = false) {
  return {
    payload: {
      silent,
      type,
      value,
    },
    type: NAVIGATION_EVENT,
  }
}

function handleRedirect(pathname) {
  const matchingPath = redirectPaths.find(
    path => pathname === path.originalPath,
  )

  if (matchingPath) {
    const redirectUrl = pathname.replace(
      matchingPath.originalPath,
      matchingPath.newPath,
    )
    window.location.replace(redirectUrl)
  }
}

export function* _handleNavigation(action) {
  const { payload } = action || {}
  let { type, value, silent } = payload || {}

  handleRedirect(value)

  if (silent) {
    return
  }

  if (!type) {
    return
  }

  if (!value) {
    return
  }

  if (SPA_NAVIGATIONS[type]) {
    const navOperation = SPA_NAVIGATIONS[type]
    yield put(navOperation(value))
  }
  if (type === NEW_TAB || type === DOWNLOAD_LINK) {
    window.open(value, '_blank', 'noopener,noreferrer')
  }
  if (type === NEW_TAB_SAME_SITE) {
    window.open(value, '_blank')
  }
  if (type === OPEN) {
    window.location.href = value
  }
  if (type === PUSH) {
    window.scrollTo(0, 0)
  }
}

function* _handleConnectedReactRouterEvents(action) {
  const { payload } = action || {}
  const { action: type, location } = payload || {}
  const { pathname, search, hash } = location || {}
  if (!type) {
    return
  }
  if (!location) {
    return
  }
  if (!pathname) {
    return
  }
  const url = URL.format({
    hash,
    pathname,
    search,
  })
  yield put(navigationEvent(url, type, true))
}

export function* watchNavigation() {
  yield takeEvery(NAVIGATION_EVENT, _handleNavigation)
  yield takeEvery(LOCATION_CHANGE, _handleConnectedReactRouterEvents)
}
