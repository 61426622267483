import React from 'react'
import PropTypes from 'prop-types'

import UnitLessonList from 'components/Teach/UnitLessonList'

const GradeUnitLessons = ({ lessons, routeToPage }) => {
  return lessons.map((item, index) => {
    const { lessonTitle, lessonInfos } = item
    const id = `unit-lesson-list-${index}`
    return (
      <UnitLessonList
        dataTestId={id}
        key={id}
        navigationEvent={routeToPage}
        rows={lessonInfos}
        title={lessonTitle}
      />
    )
  })
}

GradeUnitLessons.propTypes = {
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      lessonTitle: PropTypes.string,
      lessonInfos: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          teachUrl: PropTypes.string,
          slmUrl: PropTypes.string,
        }),
      ),
    }),
  ),
  routeToPage: PropTypes.func,
}

export default GradeUnitLessons
