import styled from 'styled-components'
import { themeGet } from 'styled-system'
import AdminFlexibleContentPanel from 'components/AdminFlexibleContentPanel'
import {
  BodyCopy,
  ContainedButton,
  Hairline,
  HeaderThree,
  HeaderTwo,
} from 'secondstep-components'

export const SetupWrapper = styled.div.attrs(props => {
  return {
    backgroundColor: props.isMultiSite
      ? themeGet('colors.paleGray')
      : themeGet('colors.linkLight'),
  }
})`
  [class*='ContentPanelWrapper'] {
    background-color: ${({ backgroundColor }) => backgroundColor};
  }
`

export const StyledHairline = styled(Hairline)`
  background-color: ${themeGet('colors.linkLight')};
  height: 2px;
`

export const BodyWrapper = styled.div.attrs(props => {
  return {
    backgroundColor: props.isMultiSite
      ? themeGet('colors.paleGray')
      : themeGet('colors.linkLight'),
    padding: props.isMultiSite ? '0' : '0 20px 20px',
  }
})`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  padding: ${({ padding }) => padding};

  @media screen and (max-width: 1355px) {
    flex-direction: column;
  }
`

export const ContentWrapper = styled.div.attrs(props => {
  return {
    backgroundColor: props.isMultiSite
      ? themeGet('colors.paleGray')
      : themeGet('colors.linkLight'),
  }
})`
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 0.625rem;
`

export const CardWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${themeGet('colors.linkLight')};
  border-radius: 0.625rem;
  padding: 2.5rem 1.75rem 1.25rem;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.3125rem 0;
`

export const StyledDescription = styled(BodyCopy)`
  padding-top: 0.8125rem;
`

export const StyledHeaderThree = styled(HeaderThree)`
  font-size: 1rem;
  font-size: 1.0625rem;
  font-family: ${themeGet('font.sharpSlab.semibold')};
  margin-top: 0.375rem;
  text-align: center;

  ${themeGet('breakpoints.tablet')} {
    font-size: 1rem;
    line-height: 1.0625rem;
    margin-bottom: 0.75rem;
  }
`

export const StyledHeaderTwo = styled(HeaderTwo)`
  color: ${themeGet('colors.darkBlue')};
  font-size: 1.4375rem;
  line-height: 1.5625rem;
  margin-bottom: 0;

  ${themeGet('breakpoints.mobile')} {
    font-size: 1.4375rem;
    line-height: 1.5625rem;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
`

export const StyledImage = styled.img`
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 0.75rem;

  ${themeGet('breakpoints.tablet')} {
    margin-bottom: 1.1875rem;
  }
`

export const StyledContainedButton = styled(ContainedButton)`
  width: 155px;
  padding: 0.6875rem 0.8125rem 0.5rem;
  margin-top: 0.75rem;
  margin-bottom: 1.25rem;
  text-align: center;
  text-transform: none;
  font-weight: normal;

  &:hover {
    box-shadow: none;
  }
`

export const StyledLabel = styled.p`
  color: ${themeGet('colors.darkGray')};
  font-size: 14px;
  margin-top: 0.625rem;
`

export const StyledAdminFlexibleContentPanel = styled(
  AdminFlexibleContentPanel,
).attrs(props => {
  return {
    backgroundColor: props.isMultiSite
      ? themeGet('colors.linkLight')
      : themeGet('colors.paleGray'),
    implementationLevelComplete: props.implementationLevel ? 'block' : 'none',
    implementationLevelOnGoing: props.implementationLevel ? 'none' : 'block',
  }
})`
  margin-top: 1rem;
  padding-top: 1.3125rem;

  [class*='StyledHeaderOne-sc'] {
    font-family: ${themeGet('font.molde.medium')};
    font-size: 0.875rem;
    color: ${themeGet('colors.darkGray')};
    letter-spacing: 0.88px;
    line-height: 1.0625rem;
    padding-bottom: 0.5625rem;
    text-transform: uppercase;
  }
  [class*='ColumnsWrapper-sc'] {
    > * {
      flex: 1;
    }
    flex-wrap: nowrap;
    gap: 1rem;
    padding-top: 2.25rem;
    [class*='ColumnWrapper-sc'] {
      margin: 0;
      border: 2px solid ${themeGet('colors.paleGray')};
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      // height: 110px;
      border-radius: 0.75rem;
      padding: 2.5rem 1rem 1.3125rem;

      img {
        margin-bottom: 1.5rem;
      }

      [class*='BodyCopy'] {
        color: ${themeGet('colors.darkGray')};
        padding-top: 0.8125rem;
        text-align: center;
      }

      [class*='StyledHeaderTwo'] {
        text-align: center;
      }

      [class*='StyledHeaderThree-sc'] {
        text-align: center;
        font-family: ${themeGet('font.sharpSlab.semibold')};
        color: ${themeGet('colors.darkBlue')};
        font-size: 1rem;
        line-height: 1.0625rem;
        margin-top: 0.75rem;
      }

      [class*='StyledContainedButton-sc'] {
        align-self: center;
        border-radius: 0.3125rem;
        line-height: 1;
        margin-top: 1.125rem;
        width: 157px;
      }
    }
  }

  [class*='ColumnWrapper'] {
    background-color: ${({ backgroundColor }) => backgroundColor};
  }

  [class*='ColumnWrapper']:nth-of-type(1) {
    img:first-child {
      display: ${({ implementationLevelOnGoing }) =>
        implementationLevelOnGoing};
    }

    img:last-of-type {
      display: ${({ implementationLevelComplete }) =>
        implementationLevelComplete};
    }
  }

  [class*='ColumnWrapper']:nth-of-type(1) {
    img:first-child {
      display: ${({ implementationLevelOnGoing }) =>
        implementationLevelOnGoing};
    }

    img:last-of-type {
      display: ${({ implementationLevelComplete }) =>
        implementationLevelComplete};
    }
  }
`
