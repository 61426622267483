import styled from 'styled-components'
import { themeGet } from 'styled-system'
import AdminHsExplorePrograms from 'components/AdminHsExplorePrograms'
import ExplorePrograms from 'components/ExplorePrograms'
import ImplementationChecklist from 'components/admin/ImplementationChecklist'

export const BlockWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1.25rem;
  max-width: none;
  width: 100%;

  ${themeGet('breakpoints.medium')} {
    flex-direction: column;
  }
`

export const StyledAdminHsExplorePrograms = styled(AdminHsExplorePrograms)`
  width: 60%;
  margin-right: 1.25rem;

  ${themeGet('breakpoints.medium')} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.25rem;
  }
`

export const StyledExplorePrograms = styled(ExplorePrograms)`
  width: 60%;
  margin-right: 1.25rem;

  ${themeGet('breakpoints.medium')} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.25rem;
  }
`

export const StyledImplementationChecklist = styled(ImplementationChecklist)`
  width: 40%;
  min-height: 366px;

  ${themeGet('breakpoints.medium')} {
    width: 100%;
  }
`
