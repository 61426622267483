import { connect } from 'react-redux'
import NavigationHelper from 'layers/navigation/navigationHelper'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import UnitComponent from './component'

const mapper = entry => {
  const { units: contentfulUnits, metadata: metadatas } = entry || {}
  const { grade = undefined } =
    Array.isArray(metadatas) &&
    metadatas.find(metadata => {
      const { grade } = metadata || {}
      return grade
    })
  const gradeTheme = grade && grade.replace(/ /g, '').toLowerCase()

  return { contentfulUnits, gradeTheme }
}
const options = {
  include: 3,
  mapper,
  spread: true,
  entryIdProp: true,
}

export default ContentEntryDataGetter(
  UnitComponent,
  options,
  connect(null, NavigationHelper.mapDispatchToProps),
)
