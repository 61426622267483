import { connect } from 'react-redux'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import NavigationHelper from 'layers/navigation/navigationHelper'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_CONTENTFUL_PRODUCT,
  ERROR_TYPE_LMS,
} from 'layers/errorHandling/apiError'
import AllModules from './component'
import { SELA_ROUTE, FT_ROUTE, SELA_MEMBER, FT_MEMBER } from './constants'

export const mapper = (entry = {}) => {
  const {
    description,
    displayTitle,
    productSubsections: contentfulModules,
  } = entry
  return { contentfulModules, description, displayTitle }
}

const options = {
  include: 1,
  mapper,
  spread: true,
  entryIdProp: true,
}

const mapStateToProps = ({ lmsManager }, props) => {
  const memberMap = {
    [SELA_ROUTE]: SELA_MEMBER,
    [FT_ROUTE]: FT_MEMBER,
  }

  const { modules } =
    lmsManager?.instructorData[memberMap[props.productName]][0] || []

  return {
    lmsModules: modules,
  }
}

export default ContentEntryDataGetter(
  withApiError(AllModules, [
    ERROR_TYPE_CONTENTFUL,
    ERROR_TYPE_CONTENTFUL_PRODUCT,
    ERROR_TYPE_LMS,
  ]),
  options,
  connect(mapStateToProps, NavigationHelper.mapDispatchToProps),
)
