import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { View } from 'react-native'
import StaticHeaderThree from 'components/StaticHeaderThree'
import StaticHeaderTwo from 'components/StaticHeaderTwo'
import { BodyWrapper, StyledBodyCopy, StyledSup } from './component.styles'

const markdownRenders = handlers => ({
  sup: props => (
    <StyledSup
      data-testid={`reference-link-${props.children[0]}`}
      hasPointerCursor={handlers?.onHandleClickSup}
      onClick={e => {
        if (handlers?.onHandleClickSup) {
          e.preventDefault()
          handlers.onHandleClickSup(props.children[0])
        }
      }}
    >
      {props.children}
    </StyledSup>
  ),
})

class HeaderBodyCopy extends Component {
  static propTypes = {
    bodyCopy: PropTypes.string,
    h2Text: PropTypes.string,
    h3Text: PropTypes.string,
    id: PropTypes.string.isRequired,
    onHandleClickSup: PropTypes.func,
  }

  render() {
    const { h2Text, h3Text, bodyCopy, id, onHandleClickSup } = this.props || {}
    const markdownHandlers = {
      onHandleClickSup,
    }

    return (
      <View data-testid={`article-header-body-copy-${id}`}>
        {h2Text && (
          <StaticHeaderTwo dataTestId={`article-header2-${id}`}>
            {h2Text}
          </StaticHeaderTwo>
        )}
        {h3Text && (
          <StaticHeaderThree dataTestId={`article-header3-${id}`}>
            {h3Text}
          </StaticHeaderThree>
        )}
        {bodyCopy && (
          <BodyWrapper>
            <StyledBodyCopy
              dataTestId={`styled-bodycopy-${id}`}
              renders={markdownRenders(markdownHandlers)}
            >
              {bodyCopy}
            </StyledBodyCopy>
          </BodyWrapper>
        )}
      </View>
    )
  }
}

export default HeaderBodyCopy
