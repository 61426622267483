/* eslint-disable */
import PropTypes from 'prop-types'

export const setShape = PropTypes.shape({
  size: PropTypes.number.isRequired,
  add: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  delete: PropTypes.func.isRequired,
  entries: PropTypes.func.isRequired,
  forEach: PropTypes.func.isRequired,
  has: PropTypes.func.isRequired,
  keys: PropTypes.func.isRequired,
  values: PropTypes.func.isRequired,
})

export const productMap = PropTypes.shape({
  entryPoint: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  skuCode: PropTypes.string,
  themeName: PropTypes.string.isRequired,
  productName: PropTypes.string.isRequired,
})

export const contentAudience = PropTypes.arrayOf(
  PropTypes.oneOf([
    'Student',
    'Teacher',
    'Counselor',
    'Parent/Carevgiver',
    'Site Administrator',
    'District Administrator',
    'Implementation Coordinator',
  ]),
)

const lmsLesson = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  status: PropTypes.oneOf(['not attempted', 'incomplete', 'completed']),
  // Scales score between 0..1, should be interpreted as the percentage of
  // completion in this single lesson  score:PropTypes.number,
  score: PropTypes.number,
})

export const lmsUnit = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  status: PropTypes.oneOf(['not attempted', 'incomplete', 'completed']),
  // Scales score between 0..1, should be interpreted as the percentage of
  // completion in this single lesson  score:PropTypes.number,
  score: PropTypes.number,
  lessons: PropTypes.arrayOf(lmsLesson),
})

export const lmsClassInstance = PropTypes.shape({
  createdDate: PropTypes.string.isRequired,
  instance: PropTypes.string.isRequired,
  contentfulID: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  createdDate: PropTypes.string,
  progress: PropTypes.number,
  status: PropTypes.string,
  // Scaled score between 0..1, should be interpreted as the percentage of
  // completion across the entire course
  score: PropTypes.number,
  //  Units that make up the course
  units: PropTypes.arrayOf(lmsUnit).isRequired,
})

export const lmsComponent = PropTypes.shape({
  isFetching: PropTypes.bool.isRequired,
  error: PropTypes.object,
  instructorData: PropTypes.object,
  selectedCourseInstance: lmsClassInstance,
})

export const userManagerShape = PropTypes.shape({
  getUser: PropTypes.func.isRequired,
  removeUser: PropTypes.func.isRequired,
  signinRedirect: PropTypes.func.isRequired,
  signinRedirectCallback: PropTypes.func.isRequired,
  signinSilent: PropTypes.func.isRequired,
  signinSilentCallback: PropTypes.func.isRequired,
  signinPopup: PropTypes.func.isRequired,
  signinPopupCallback: PropTypes.func.isRequired,
  signoutRedirect: PropTypes.func.isRequired,
  signoutRedirectCallback: PropTypes.func.isRequired,
  signoutPopup: PropTypes.func.isRequired,
  signoutPopupCallback: PropTypes.func.isRequired,
  querySessionStatus: PropTypes.func.isRequired,
  startSilentRenew: PropTypes.func.isRequired,
  stopSilentRenew: PropTypes.func.isRequired,
  clearStaleState: PropTypes.func.isRequired,
  settings: PropTypes.object.isRequired,
  events: PropTypes.object.isRequired,
  metadataService: PropTypes.object.isRequired,
})

export const contentfulPageDataShape = PropTypes.shape({
  internalTitle: PropTypes.string,
  route: PropTypes.string,
  theme: PropTypes.oneOf([
    'middleSchool',
    'elementary',
    'ostKit',
    'elKit',
    'k5Kit',
    'bpuKit',
    'cpuKit',
    'msKit',
  ]),
})

export const contentfulImageShape = PropTypes.shape({
  description: PropTypes.string,
  file: PropTypes.shape({
    contentType: PropTypes.string,
    details: PropTypes.shape({
      image: PropTypes.shape({
        height: PropTypes.number,
        width: PropTypes.number,
      }),
      size: PropTypes.number,
    }),
    fileName: PropTypes.string,
    url: PropTypes.string,
  }),
  id: PropTypes.string,
  locale: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
})
