import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const CardPanel = styled('div').attrs(props => ({
  backgroundColor: !props.clickable
    ? themeGet('colors.paleGray')(props)
    : props.isActive
    ? themeGet('colors.backgroundBlueLight')(props)
    : themeGet('colors.white')(props),
  border: props.clickable ? '2px solid' : 'none',
  borderColor: props.isActive
    ? themeGet('colors.blue')(props)
    : themeGet('colors.gray300')(props),
  clickable: props.clickable,
  hoverBackground: props.clickable
    ? themeGet('colors.backgroundBlueLight')(props)
    : null,
}))`
  align-items: center;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border-radius: 0.75rem;
  display: flex;
  border: ${({ border }) => border};
  border-color: ${({ borderColor }) => borderColor};
  flex-direction: column;
  justify-content: flex-start;
  max-width: 297px;
  max-height: 221px;
  padding-top: ${({ clickable }) => (clickable ? 1.25 : 0)}rem;

  &:hover {
    background-color: ${({ hoverBackground }) => hoverBackground};
  }
`

export const Icon = styled('img').attrs(props => ({}))`
  margin: 0;
`

export const IconWrapper = styled('div').attrs(props => ({
  clickable: props.clickable,
  iconWidth: props.iconWidth,
  iconHeight: props.iconHeight,
}))`
  align-items: center;
  display: flex;
  justify-content: center;
  height: ${({ clickable }) => (clickable ? 100 : 50)}px;
  margin-top: ${({ clickable }) => (clickable ? 0 : 1)}rem;

  & img {
    width: ${({ clickable, iconWidth }) =>
      clickable ? iconWidth : iconWidth * 0.7}px;
    height: ${({ clickable, iconHeight }) =>
      clickable ? iconHeight : iconHeight * 0.6}px;
    max-height: ${({ clickable }) => (clickable ? 70 : 30)}px;
  }
`

export const Titles = styled('div').attrs(props => ({
  justifyContent: props.subtitle ? 'space-evenly' : 'center',
}))`
  align-items: center;
  display: flex;
  justify-content: ${({ justifyContent }) => justifyContent};
`

export const Title = styled.h2`
  font-family: ${themeGet('font.sharpSlab.semibold')};
  color: ${themeGet('colors.iconBlue')};
  white-space: nowrap;
  font-size: 1.25rem;
`

export const TextArea = styled.div`
  display: flex;
  flex-direction: column;
`

export const Sub = styled('h4').attrs(props => ({
  color: props.isActive
    ? themeGet('colors.blue')(props)
    : themeGet('colors.darkGray')(props),
}))`
  font-family: ${themeGet('font.molde.medium')};
  font-size: 0.75rem;
  letter-spacing: 0.13rem;
  color: ${({ color }) => color};
`

export const Description = styled.div`
  font-family: ${themeGet('font.molde.regular')};
  font-size: 1rem;
  white-space: break-spaces;
  text-align: center;
  max-width: 253px;
  align-self: center;
`
