import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Accordion, Hairline } from 'secondstep-components'

export const BlockWrapper = styled.div`
  display: flex;
  flex: 1;
  align-self: flex-start;
  flex-direction: column;
  margin-right: 1.75rem;
  ${themeGet('breakpoints.tablet')} {
    margin-right: 0;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
  }
`

export const Illustration = styled.img`
  display: block;
  align-self: center;
  width: auto;
  min-width: 18.5rem;
  max-width: 22.5rem;
  height: ${({ height }) => height || 0}px;
  margin-bottom: 0.1875rem;
  object-fit: contain;
  ${themeGet('breakpoints.tablet')} {
    margin-top: 1.25rem;
  }
`

export const SubHeader = styled.h2`
  color: ${themeGet('colors.darkBlue')};
  font-size: 1.4375rem;
  font-family: ${themeGet('font.sharpSlab.semibold')};
`

export const StyledHairline = styled(Hairline)`
  margin: 1rem auto;
  height: 0.13rem;
  background-color: ${themeGet('colors.paleGray')};
`

export const TopHeader = styled.h3`
  color: ${themeGet('colors.darkGray')};
  font-size: 1rem;
  font-family: ${themeGet('fontFamily')};
`

export const Wrapper = styled.div`
  width: 100%;
  margin: 2.5rem auto 1.25rem;
  padding: 1.5rem 1.25rem 1.25rem;
  box-sizing: border-box;
  border-radius: 0.625rem;
  background: ${themeGet('colors.backgrounds.primary', 'colors.white')};
`

export const AccordionWrapper = styled(Accordion)`
  width: 100%;
  margin: 1.75rem auto 1.25rem;
  box-sizing: border-box;
  border-radius: 0.625rem;
  background: ${themeGet('colors.backgrounds.primary', 'colors.white')};
  display: flex;
  justify-content: space-between;
  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
  }

  [class*='AccordionPanel'] {
    padding: 1.25rem 1.25rem 1rem;
    [class*='StyledCaret'] {
      padding: 0;
    }
  }

  [class*='AccordionTitleWrapper'] {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  [class*='AccordionContent-'] {
    padding: 0 1.0625rem 1rem;
    [class*='StyledHairline'] {
      margin: 0.125rem 0 1rem;
    }
  }
`
