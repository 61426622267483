import { Component } from 'react'
import PropTypes from 'prop-types'

class GlobalEventListener extends Component {
  static propTypes = {
    onClick: PropTypes.func,
    onKeyPress: PropTypes.func,
    onUrlChange: PropTypes.func,
  }

  componentDidMount() {
    window.addEventListener('click', this.clickHandler)
    window.addEventListener('popstate', this.urlChangeHandler, false)
    window.addEventListener('keydown', this.keyPressHandler, false)
  }
  componentWillUnmount() {
    window.removeEventListener('click', this.clickHandler)
    window.removeEventListener('popstate', this.urlChangeHandler, false)
    window.removeEventListener('keydown', this.keyPressHandler, false)
  }

  clickHandler = e => {
    const { onClick } = this.props
    onClick && onClick(e)
  }

  urlChangeHandler = e => {
    const { onUrlChange } = this.props
    onUrlChange && onUrlChange(e)
  }

  keyPressHandler = e => {
    const { onKeyPress } = this.props
    onKeyPress && onKeyPress(e)
  }

  render() {
    return null
  }
}

export default GlobalEventListener
