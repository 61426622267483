import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { operations as userSessionOperations } from 'store/userSessionManager'
import ChecklistItem from './component'

const ChecklistItemContainer = ({
  internalKey,
  isChecked,
  links,
  selectedProgram,
  selectedTabSectionTitle,
  text,
  toggleChecklistItem,
}) => {
  const [isItemChecked, setIsItemChecked] = useState(isChecked)
  const toggleCheck = () => {
    toggleChecklistItem(internalKey, !isItemChecked)
    setIsItemChecked(!isItemChecked)
  }
  return (
    <ChecklistItem
      internalKey={internalKey}
      isChecked={isItemChecked}
      links={links}
      onChange={toggleCheck}
      selectedProgram={selectedProgram}
      selectedTabSectionTitle={selectedTabSectionTitle}
      text={text}
    />
  )
}

const mapStateToProps = (
  { userSessionManager: { adminDashboard } },
  { internalKey },
) => {
  const { items } = adminDashboard?.checklist || {}
  const isChecked = items?.[internalKey] || false
  return {
    isChecked,
  }
}

const mapDispatchToProps = {
  toggleChecklistItem: userSessionOperations.adminToggleChecklistItem,
}

ChecklistItemContainer.propTypes = {
  internalKey: PropTypes.string,
  isChecked: PropTypes.bool,
  links: PropTypes.array,
  selectedProgram: PropTypes.object,
  selectedTabSectionTitle: PropTypes.string,
  text: PropTypes.string,
  toggleChecklistItem: PropTypes.func,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ChecklistItemContainer)
