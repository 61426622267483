import React from 'react'
import PropTypes from 'prop-types'
import {
  CourseLabel,
  CreationDate,
  ContainedButton,
  ProgressStatusSmall,
} from 'secondstep-components'

import CreateCourse from 'experienceComponents/CreateCourse'
import {
  CardBody,
  ClassInfoBox,
  ClassInfoLeftBox,
  ClassInfoRightBox,
  CourseInstanceCardWrapper,
  StyledHeaderThree,
} from './component.styles'

const CourseInstanceCard = React.forwardRef((props, ref) => {
  const {
    course,
    courseInstanceName,
    createdDate,
    gradeNumber,
    gradeTitle,
    isEditable,
    onBlur,
    onFocus,
    setFocus,
    totalAmount,
    totalComplete,
    viewLessonsOnClick,
  } = props
  return (
    <CourseInstanceCardWrapper
      dataTestId="course-instance-card"
      onBlur={onBlur}
      onFocus={onFocus}
    >
      <CardBody>
        <CourseLabel gradeNumber={gradeNumber} />
        <ClassInfoBox>
          <ClassInfoLeftBox>
            <StyledHeaderThree dataTestId="header-course-name">
              {gradeTitle}
              {isEditable && (
                <CreateCourse
                  course={course}
                  courseInstanceName={courseInstanceName}
                  edit
                  key={`${courseInstanceName}`}
                  ref={ref}
                  setFocus={setFocus}
                />
              )}
            </StyledHeaderThree>
            <CreationDate
              createdDate={createdDate}
              dataTestId="creation-date"
            />
          </ClassInfoLeftBox>
          <ClassInfoRightBox>
            <CreationDate
              createdDate={createdDate}
              dataTestId="creation-date"
            />
            <ProgressStatusSmall
              dataTestId="progress-status"
              totalAmount={totalAmount}
              totalComplete={totalComplete}
            />
          </ClassInfoRightBox>
        </ClassInfoBox>
      </CardBody>
      <ContainedButton
        dataTestId={'button-view-lessons'}
        onClick={viewLessonsOnClick}
      >
        View Lessons
      </ContainedButton>
    </CourseInstanceCardWrapper>
  )
})

CourseInstanceCard.propTypes = {
  course: PropTypes.object.isRequired,
  courseInstanceName: PropTypes.string,
  createdDate: PropTypes.string.isRequired,
  gradeNumber: PropTypes.string.isRequired,
  gradeTitle: PropTypes.string.isRequired,
  isEditable: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  scoId: PropTypes.number,
  setFocus: PropTypes.func,
  totalAmount: PropTypes.number,
  totalComplete: PropTypes.number,
  viewLessonsOnClick: PropTypes.func,
}
CourseInstanceCard.displayName = 'CourseInstanceCardForwardedRef'
export default CourseInstanceCard
