import { connect } from 'react-redux'
import CodeActivationForm from './component'
import { operations as licenseOperations } from 'store/licenseManager'

const mapDispatchToProps = {
  postActivationCode: licenseOperations.sendActivationCode,
  validateActivationCode: licenseOperations.fetchValidateActivationCode,
}

const mapStateToProps = ({ licenseManager }) => ({
  isValidActivationCode: licenseManager.isValidActivationCode,
  isValidating: licenseManager.isValidating,
})

export default connect(mapStateToProps, mapDispatchToProps)(CodeActivationForm)
