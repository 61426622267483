import React, { Fragment, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'secondstep-components'
import { VIEW_OPTION_PATHS } from 'utils/adminExperienceHelpers'
import { TOGGLE_VIEW } from 'store/userSessionManager/constants'
import AdminDashboardBanner from 'components/AdminDashboardBanner'
import AdminHSMonitorView from 'components/AdminHSMonitorView'
import AdminHSMultiSiteView from 'components/AdminHSMultiSiteView'
import AdminHSSingleSiteView from 'components/AdminHSSingleSiteView'
import AdminMonitorSupport from 'components/AdminMonitorSupport'
import AdminMultiSiteView from 'components/AdminMultiSiteView'
import AdminSchoolTable from 'components/AdminSchoolTable'
import AdminSetup from 'components/AdminSetup'
import AdminSingleSiteView from 'components/AdminSingleSiteView'
import AdminSitesSummary from 'components/AdminSitesSummary'
import GetStartedPanel from 'components/admin/GetStartedPanel'

import {
  ADMIN_DASHBOARD_SUB_ROUTES,
  AVAILABLE_PROGRAMS,
  PROGRAM_TITLES,
} from './constants'
import { AdminDashboardWrapper, ContentWrapper } from './component.styles'

const AdminDashboard = ({
  activeAdminK8Sites,
  adminHSSites,
  match,
  navigationEvent,
  onboardingSnapshot,
  programTitles,
  selectedProgram: storedSelectedProgram,
  setLeaderDashboardRecentUrl,
  showFirstTimeSetupAdmin,
  hsToggleView,
  k8ToggleView,
  updateSelectedProgram,
}) => {
  const [selectedProgram, setSelectedProgram] = useState()

  useEffect(() => {
    setSelectedProgram({
      name: PROGRAM_TITLES[storedSelectedProgram?.name],
      key: storedSelectedProgram?.name,
    })
  }, [storedSelectedProgram])

  const createProgramRoute = program => {
    const hsViewOptionSegment =
      hsToggleView === TOGGLE_VIEW.MONITOR
        ? VIEW_OPTION_PATHS.MONITOR
        : VIEW_OPTION_PATHS.PROGRAM_ADOPTION
    if (program.key === 'HS') {
      return `${program.key.toLowerCase()}/${hsViewOptionSegment}`
    }

    const k8ViewOptionSegment =
      k8ToggleView === TOGGLE_VIEW.MONITOR
        ? VIEW_OPTION_PATHS.MONITOR
        : VIEW_OPTION_PATHS.PROGRAM_ADOPTION

    if (program.key === 'K8') {
      return `${program.key.toLowerCase()}/${k8ViewOptionSegment}`
    } else if (program.key === 'HS') {
      return `${program.key.toLowerCase()}/${hsViewOptionSegment}`
    } else {
      return program.key.toLowerCase()
    }
  }

  const onChangeProgram = program => {
    setSelectedProgram(program)
    updateSelectedProgram({ name: program.key, key: program.key })

    const programRoute = createProgramRoute(program)
    const route = `program/${programRoute}`
    setLeaderDashboardRecentUrl(route)
    navigationEvent(`/admin/${route}`)
  }

  const { params } = match
  const { programName = '', viewOption = null } = params
  const validProgram = AVAILABLE_PROGRAMS.find(
    program => program === programName.toUpperCase(),
  )
  const isValidSubRoute =
    ADMIN_DASHBOARD_SUB_ROUTES[programName.toLowerCase()] &&
    ADMIN_DASHBOARD_SUB_ROUTES[programName.toLowerCase()].includes(viewOption)

  if (
    (!showFirstTimeSetupAdmin && !validProgram) ||
    (viewOption && !isValidSubRoute)
  ) {
    return <ErrorMessage statusCode={404} />
  }

  let isLaunched = false

  if (selectedProgram?.key === 'K8') {
    isLaunched = true
  }

  if (selectedProgram?.key === 'HS') {
    if (Array.isArray(adminHSSites)) {
      isLaunched = adminHSSites?.find(
        site => site.sitePreferences?.highSchool?.isLaunched === true,
      )
    }
  }

  const isSingleHsSite = adminHSSites?.length === 1

  return (
    <AdminDashboardWrapper>
      {showFirstTimeSetupAdmin && <AdminSetup />}
      {!showFirstTimeSetupAdmin && (
        <Fragment key="admin-banner">
          <AdminDashboardBanner
            hsToggleView={hsToggleView}
            isLaunched={!!isLaunched}
            k8ToggleView={k8ToggleView}
            navigationEvent={navigationEvent}
            onChangeProgram={onChangeProgram}
            onboardingSnapshot={onboardingSnapshot}
            programTitles={programTitles}
          />
          <ContentWrapper>
            {selectedProgram?.key === 'HS' && (
              <Fragment key="admin-hs-view">
                {hsToggleView === TOGGLE_VIEW.LAUNCH && (
                  <Fragment key="admin-hs-launch-view">
                    {isSingleHsSite && (
                      <Fragment key="admin-hs-launch-view-single-site">
                        {!isLaunched && (
                          <GetStartedPanel programKey={selectedProgram.key} />
                        )}
                        <AdminHSSingleSiteView
                          onboardingSnapshot={onboardingSnapshot}
                          programKey={selectedProgram.key}
                        />
                      </Fragment>
                    )}
                    {adminHSSites?.length > 1 && <AdminHSMultiSiteView />}
                  </Fragment>
                )}
                {hsToggleView === TOGGLE_VIEW.MONITOR && (
                  <Fragment key="admin-hs-monitor-view">
                    {isSingleHsSite && <AdminHSMonitorView />}
                    {adminHSSites?.length > 1 && <AdminHSMultiSiteView />}
                  </Fragment>
                )}
              </Fragment>
            )}
            {selectedProgram?.key === 'K8' && (
              <Fragment key="k8-content">
                {k8ToggleView === TOGGLE_VIEW.LAUNCH && (
                  <Fragment key="k8-launch-view">
                    <AdminSitesSummary />
                    <AdminSchoolTable
                      navigationEvent={navigationEvent}
                      selectedProgram={selectedProgram}
                    />
                  </Fragment>
                )}
                {k8ToggleView === TOGGLE_VIEW.MONITOR && (
                  <Fragment key="k8-monitor-view">
                    <AdminMonitorSupport />
                    {activeAdminK8Sites?.length > 1 && <AdminMultiSiteView />}
                    {activeAdminK8Sites?.length === 1 && (
                      <AdminSingleSiteView />
                    )}
                  </Fragment>
                )}
              </Fragment>
            )}
          </ContentWrapper>
        </Fragment>
      )}
    </AdminDashboardWrapper>
  )
}

AdminDashboard.propTypes = {
  activeAdminK8Sites: PropTypes.array,
  adminHSSites: PropTypes.array,
  hsToggleView: PropTypes.string,
  k8ToggleView: PropTypes.string,
  match: PropTypes.object,
  multiSiteAdmin: PropTypes.bool,
  navigationEvent: PropTypes.func,
  onboardingSnapshot: PropTypes.array,
  programTitles: PropTypes.array,
  progressMonitoringSnapshot: PropTypes.array,
  selectedProgram: PropTypes.object,
  setLeaderDashboardRecentUrl: PropTypes.func,
  showFirstTimeSetupAdmin: PropTypes.bool,
  updateSelectedProgram: PropTypes.func,
}

export default AdminDashboard
