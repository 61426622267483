import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { BodyCopy, HeaderTwo } from 'secondstep-components'

export const Wrapper = styled(Box)`
  align-items: center;
  width: 100%;
  padding: 2.5rem 2.25rem;
  border-radius: 0.75rem;
  border-bottom: 3px solid ${themeGet('colors.gray500')};
  background-color: ${themeGet('colors.lighterGray')};
  text-align: center;

  ${HeaderTwo} {
    padding: 1.375rem 1rem 0;
    margin-bottom: 0.25rem;
    text-align: center;
    color: ${themeGet('colors.darkerDarkBlue')};
    font-family: ${themeGet('fontFamilySemibold')};
  }

  ${BodyCopy} {
    max-width: 500px;
    padding-bottom: 2rem;
    line-height: 1.375rem;
    color: ${themeGet('colors.darkGray')};
  }
`
