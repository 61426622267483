import React from 'react'
import PropTypes from 'prop-types'
import {
  LeftBox,
  LeftTop,
  LeftBottom,
  RightBox,
  Description,
  LabelBox,
  Title,
  Wrapper,
} from './component.styles'
import ModuleOverviewInset from 'components/ModuleOverviewInset'
import ProgressBar from 'components/ProgressBar'

const ModuleOverview = ({
  attempted,
  completeLessons,
  description,
  displayTitle,
  entryId,
  isDone,
  isStarted,
  label,
  lmsNextMicrolearning,
  totalLessons,
}) => {
  const percentComplete = {
    completed: 100,
    incomplete: Math.floor((completeLessons / totalLessons) * 100),
    'not attempted': 0,
  }[attempted]

  return (
    <Wrapper dataTestId="module-overview-wrapper">
      <LeftBox>
        <LeftTop>
          <LabelBox dataTestId="module-overview-label">{label}</LabelBox>
          <Title dataTestId="module-overview-title">{displayTitle}</Title>
          <Description dataTestId="module-overview-description">
            {description}
          </Description>
        </LeftTop>
        <LeftBottom>
          <ProgressBar
            className="progress-bar"
            dataTestId={`module-overview-progressbar`}
            percentComplete={percentComplete}
          />
        </LeftBottom>
      </LeftBox>
      <RightBox>
        <ModuleOverviewInset
          entryId={entryId}
          isModuleDone={isDone}
          isModuleStarted={isStarted}
          lmsNextMicrolearning={lmsNextMicrolearning}
        />
      </RightBox>
    </Wrapper>
  )
}

ModuleOverview.propTypes = {
  attempted: PropTypes.string,
  completeLessons: PropTypes.number,
  description: PropTypes.string,
  displayTitle: PropTypes.string,
  entryId: PropTypes.string,
  isDone: PropTypes.bool,
  isStarted: PropTypes.bool,
  label: PropTypes.string,
  lmsNextMicrolearning: PropTypes.object,
  totalLessons: PropTypes.number,
}

ModuleOverview.defaultProps = {
  attempted: 'not attempted',
  completeLessons: 0,
  description: '',
  displayTitle: '',
  isDone: false,
  isStarted: false,
  label: '',
  lmsNextMicrolearning: null,
  totalLessons: 0,
}

export default ModuleOverview
