import React from 'react'
import PropTypes from 'prop-types'
import UserProgramProfileModal from 'components/dashboard/UserProgramProfileModal'
import {
  EducatorContainer,
  StyledSVG,
  Description,
  ModalButton,
} from './component.styles'
import { BUTTON_TEXT, DESCRIPTION_TEXT } from './constants'

const EducatorProfileComponent = props => {
  const { currentSite, isModalOpen, handleModalOpen, handleCloseModal } = props

  return (
    <EducatorContainer>
      <StyledSVG alt="Bird Icon" />
      <Description>{DESCRIPTION_TEXT}</Description>
      <ModalButton onClick={handleModalOpen}>{BUTTON_TEXT}</ModalButton>
      {isModalOpen && (
        <UserProgramProfileModal
          currentSite={currentSite}
          handleClose={handleCloseModal}
        />
      )}
    </EducatorContainer>
  )
}

EducatorProfileComponent.propTypes = {
  currentSite: PropTypes.object.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  handleModalOpen: PropTypes.func.isRequired,
  isModalOpen: PropTypes.bool.isRequired,
}

export default EducatorProfileComponent
