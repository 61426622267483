import React from 'react'
import PropTypes from 'prop-types'

import {
  BACK_TO_DASHBOARD,
  LICENSE_LABEL,
  LICENSE_PLACEHOLDER,
} from './constants'
import BackLink from 'components/BackLink'
import {
  Background,
  LicenseSelect,
  ReportsBannerBody,
  ReportsBannerHeader,
  ReportsBannerInnerWrapper,
  ReportsBannerWrapper,
  TextInnerWrapper,
} from './component.styles'
import { getLicenseOption } from 'utils/productHelpers'

const ReportsBanner = ({
  activeAdminLicenses,
  navigateToDashboardOnClick,
  onLicenseChange,
  selectedLicense,
  title,
  body,
}) => {
  const licenseOptions = activeAdminLicenses.map(license =>
    getLicenseOption(license),
  )
  const licenseTextOptions = licenseOptions.map(license => license.text)
  const selectedLicenseId = selectedLicense?.licenseId
  const [selectedValue] = licenseTextOptions.filter(option =>
    option.includes(selectedLicenseId),
  )
  const singleLicense = licenseTextOptions.length < 2

  return (
    <ReportsBannerWrapper>
      <Background />
      <ReportsBannerInnerWrapper singleLicense={singleLicense}>
        <BackLink
          dataTestId="accessible-link-navigate-to-dash"
          onClick={navigateToDashboardOnClick}
        >
          {BACK_TO_DASHBOARD}
        </BackLink>
        <TextInnerWrapper>
          <ReportsBannerHeader dataTestId="banner-title">
            {title}
          </ReportsBannerHeader>
          <ReportsBannerBody dataTestId={body?.type}>
            {body?.text}
          </ReportsBannerBody>
        </TextInnerWrapper>
        {!singleLicense && (
          <LicenseSelect
            autoComplete={false}
            autofocus
            dataTestId="select-license"
            disabled={false}
            label={LICENSE_LABEL}
            name="selectedLicense"
            onChange={onLicenseChange}
            options={licenseTextOptions}
            placeholder={LICENSE_PLACEHOLDER}
            value={selectedValue}
          />
        )}
      </ReportsBannerInnerWrapper>
    </ReportsBannerWrapper>
  )
}

ReportsBanner.propTypes = {
  activeAdminLicenses: PropTypes.array,
  body: PropTypes.object,
  navigateToDashboardOnClick: PropTypes.func,
  onLicenseChange: PropTypes.func,
  selectedLicense: PropTypes.object,
  title: PropTypes.string,
}

ReportsBanner.defaultProps = {
  activeAdminLicenses: [],
}

export default ReportsBanner
