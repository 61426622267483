import React from 'react'
import PropTypes from 'prop-types'
import { BackpacksIcon, MegaphoneIcon } from 'secondstep-components'
import {
  ButtonText,
  ButtonTextLink,
  Card,
  IconsContainer,
  HeaderLine,
  LeftBox,
  ProgramToStaffButton,
  ProgramToStaffButtonContent,
  RightBox,
  StyledAwardIcon,
  StyledHairline,
  StaffTrainingButton,
  StaffTrainingButtonContent,
} from './component.styles'
import {
  PROGRAM_INTRODUCTION_LINK,
  PROGRAM_TO_STAFF_LINK_TEXT,
  SCHOOL_KICK_OFF_MESSAGE,
  STAFF_TRAINING_LINK,
  STAFF_TRAINING_LINK_TEXT,
  TEACHER_TRAINING_MESSAGE,
} from './constants'
import NavigationHelper from 'layers/navigation/navigationHelper'

const AdminK8NoClassesCreated = ({ className, navigationEvent }) => {
  const navigateToProgramIntroduction = () => {
    const type = NavigationHelper.types.NEW_TAB
    navigationEvent(PROGRAM_INTRODUCTION_LINK, type)
  }

  const navigateToStaffTraining = () => {
    const type = NavigationHelper.types.NEW_TAB
    navigationEvent(STAFF_TRAINING_LINK, type)
  }

  return (
    <Card className={className}>
      <LeftBox>
        <IconsContainer>
          <BackpacksIcon />
        </IconsContainer>
        <HeaderLine>No one has created a class yet</HeaderLine>
      </LeftBox>
      <StyledHairline />
      <RightBox>
        <ProgramToStaffButton onClick={navigateToProgramIntroduction}>
          <MegaphoneIcon />
          <ProgramToStaffButtonContent>
            <ButtonText>{SCHOOL_KICK_OFF_MESSAGE}</ButtonText>
            <ButtonTextLink>{PROGRAM_TO_STAFF_LINK_TEXT}</ButtonTextLink>
          </ProgramToStaffButtonContent>
        </ProgramToStaffButton>
        <StaffTrainingButton onClick={navigateToStaffTraining}>
          <StyledAwardIcon />
          <StaffTrainingButtonContent>
            <ButtonText>{TEACHER_TRAINING_MESSAGE}</ButtonText>
            <ButtonTextLink>{STAFF_TRAINING_LINK_TEXT}</ButtonTextLink>
          </StaffTrainingButtonContent>
        </StaffTrainingButton>
      </RightBox>
    </Card>
  )
}

AdminK8NoClassesCreated.propTypes = {
  className: PropTypes.string,
  navigationEvent: PropTypes.func,
}

export default AdminK8NoClassesCreated
