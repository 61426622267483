import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BaseContainer } from 'secondstep-components'

export const Wrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const PrimaryColumn = styled.div`
  flex-basis: 100%;
  flex: 2;
  margin: 0 ${themeGet('spacing.amber28_1_75rem')} 0 0;
`

export const SecondaryColumn = styled(BaseContainer)`
  flex-basis: 100%;
  flex: 1;
  padding: ${themeGet('spacing.amber28_1_75rem')};
  margin-top: 0;
`
