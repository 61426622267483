import * as clipboard from 'clipboard-polyfill'
import HeaderBodyCopy from 'experienceComponents/HeaderBodyCopy'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  ClipboardIcon,
  CloseButton,
  FormInputSelect,
  TextButton,
} from 'secondstep-components'
import {
  TranslationWrapper,
  TextWrapper,
  TranslatedContent,
} from './component.styles'
import { getLabelFor, sortLocales } from 'utils/translations'

class TranslationDisplay extends Component {
  static propTypes = {
    bodyCopy: PropTypes.string,
    entryId: PropTypes.string.isRequired,
    getEntryWithLocale: PropTypes.func,
    h2: PropTypes.string,
    h3: PropTypes.string,
    id: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    targetLocales: PropTypes.array.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = { isCopyEnabled: true, targetLocale: '' }
    this.translationText = React.createRef()
    this.selectNewLocale = this.selectNewLocale.bind(this)
  }

  canCopyToClipboard() {
    return this.translationText.current
  }

  announceCopy() {
    this.setState({ isCopyEnabled: false })
    setTimeout(() => this.setState({ isCopyEnabled: true }), 5000)
  }

  copyToClipboard() {
    if (!this.canCopyToClipboard()) {
      return
    }

    const data = [
      new clipboard.ClipboardItem({
        'text/html': new Blob([this.translationText.current.innerHTML], {
          type: 'text/html',
        }),
        'text/plain': new Blob([this.translationText.current.innerText], {
          type: 'text/plain',
        }),
      }),
    ]

    return clipboard
      .write(data)
      .then(() => this.announceCopy())
      .catch(e => {
        throw new Error(
          'Could not copy translation to clipboard. ' + JSON.stringify(e),
        )
      })
  }

  selectNewLocale(newLocale) {
    if (this.props.getEntryWithLocale) {
      this.props.getEntryWithLocale(this.props.id, newLocale)
    }

    this.setState({
      targetLocale: newLocale,
    })
  }

  render() {
    const { entryId, targetLocales, bodyCopy, h2, h3, isFetching, locale } =
      this.props || {}

    const sortedLocales = sortLocales(targetLocales)

    return (
      <TranslationWrapper>
        <FormInputSelect
          className="target-locale"
          dataTestId={'translation-select'}
          label="Translate"
          onChange={e => this.selectNewLocale(e.option.value)}
          optionKey={'name'}
          options={sortedLocales.map(locale => ({
            name: getLabelFor(locale),
            value: locale,
          }))}
          placeholder={'Translate'}
          value={{
            name: getLabelFor(this.state.targetLocale),
            value: this.state.targetLocale,
          }}
          valueKey={'value'}
        />

        {this.state.targetLocale && (
          <TranslatedContent
            dataTestId="translated-content"
            lang={isFetching ? locale : this.state.targetLocale}
          >
            <header key="header">
              <CloseButton
                dataTestId="button-close"
                onClick={() => this.selectNewLocale('')}
              />
            </header>

            {isFetching && <p key="loading">Loading. Please wait&hellip;</p>}

            {!isFetching && (
              <div key="loaded-content">
                <TextWrapper key="content" ref={this.translationText}>
                  <HeaderBodyCopy
                    bodyCopy={bodyCopy}
                    h2Text={h2}
                    h3Text={h3}
                    id={entryId}
                    key={entryId}
                  />
                </TextWrapper>
                <footer>
                  {this.canCopyToClipboard() && (
                    <TextButton
                      dataTestId={'copy-button'}
                      disabled={!this.state.isCopyEnabled}
                      icon={<ClipboardIcon />}
                      label={
                        this.state.isCopyEnabled
                          ? `Copy to Clipboard`
                          : `Copied`
                      }
                      onClick={() => this.copyToClipboard()}
                    />
                  )}
                </footer>
              </div>
            )}
          </TranslatedContent>
        )}
      </TranslationWrapper>
    )
  }
}

export default TranslationDisplay
