import React from 'react'
import PropTypes from 'prop-types'
import NavigationHelper from 'layers/navigation/navigationHelper'

import KitActivationLink from 'components/dashboard/KitActivationLink'
import FamilyAccessLink from 'components/dashboard/FamilyAccessLink'
import AccountDetailsLink from 'components/dashboard/AccountDetailsLink'
import WebinarLinkIcon from 'components/dashboard/WebinarLinkIcon'
import {
  QuickLinksWrapper,
  LinkSeparator,
  Column,
  Row,
} from './component.styles'
import { canAccessWebinar } from 'utils/webinarHelpers'

export const QuickLinksCard = ({
  className,
  navigationEvent,
  programClaims,
}) => {
  /* istanbul ignore next */
  const navigateOutOfAppOnClick = url => {
    const type = NavigationHelper.types.OPEN
    navigationEvent(url, type)
  }
  /* istanbul ignore next */
  const navigateInAppOnClick = url => {
    const type = NavigationHelper.types.PUSH
    navigationEvent(url, type)
  }

  const navigationProps = {
    navigateInAppOnClick,
    navigateOutOfAppOnClick,
    navigationEvent,
  }

  return (
    <QuickLinksWrapper className={className} role="list">
      <Column>
        <Row>
          <LinkSeparator />
        </Row>
        <Row>
          <AccountDetailsLink />
        </Row>
        <Row>
          <KitActivationLink {...navigationProps} />
        </Row>
        <Row>
          <FamilyAccessLink {...navigationProps} />
        </Row>
        {canAccessWebinar(programClaims) && (
          <Row>
            <WebinarLinkIcon />
          </Row>
        )}
      </Column>
    </QuickLinksWrapper>
  )
}

QuickLinksCard.propTypes = {
  className: PropTypes.string,
  navigationEvent: PropTypes.func,
  programClaims: PropTypes.shape({
    hasElemAdminClaim: PropTypes.bool,
    hasElemTeacherClaim: PropTypes.bool,
    hasMsAdminClaim: PropTypes.bool,
    hasMsTeacherClaim: PropTypes.bool,
    hasSelaAdminClaim: PropTypes.bool,
    hasSelaLeaderClaim: PropTypes.bool,
    hasSelaStaffClaim: PropTypes.bool,
  }),
}

export default QuickLinksCard
