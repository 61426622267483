import { FlagsComponentRouter, FlagsSingleton } from 'flags'
import {
  isHighSchoolOrMiddleSchoolJuneRelease,
  isHighSchoolFamilyResources,
  isNewFamilyResources,
  isElementaryProgramFlex,
} from 'utils/productHelpers'

import OffComponent from './flag.off/index'
import OnComponent from './flag.on/index'

const FlagToggler = FlagsComponentRouter({
  // Once either product is released we can remove one or both of the flags
  flagSubscriptions: [
    'middle-school-june-release',
    'high-school-june-release',
    'feature-learn-17909-program-flex-family-resources',
    'feature_LEARN-18248-hs-family-resources',
    'feature_LEARN-18511-k5-elem-program-flex',
  ],
  OnComponent,
  OffComponent,
  flagsChecker: FlagsSingleton.checkAtLeastOneFlagTrue,
  onCondition: (_, subscribedFlags) =>
    isHighSchoolOrMiddleSchoolJuneRelease(_, subscribedFlags) ||
    isNewFamilyResources(_, subscribedFlags) ||
    isHighSchoolFamilyResources(_, subscribedFlags) ||
    isElementaryProgramFlex(_, subscribedFlags),
})

export default FlagToggler
