import types from './types'

const setAppLoading = loadingText => {
  return {
    type: types.LOADING,
    payload: loadingText,
  }
}
const setAppNotLoading = () => {
  return {
    type: types.NOT_LOADING,
    payload: '',
  }
}

export default {
  setAppNotLoading,
  setAppLoading,
}
