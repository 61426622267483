/* istanbul ignore file */
// we don't unit test SVGs
import React from 'react'
import PropTypes from 'prop-types'

const ProgramArrowBack = ({ currentIndex }) => {
  const backgroundColor = currentIndex === 0 ? '#E0E6EB' : '#FAEEF3'
  const arrowColor = currentIndex === 0 ? '#fff' : '#C62D70'

  return (
    <svg
      height="24px"
      style={{ cursor: currentIndex === 0 ? 'not-allowed' : 'pointer' }}
      viewBox="0 0 24 24"
      width="24px"
    >
      <g
        fill="none"
        fillRule="evenodd"
        id="ss_hs_myprograms_arrow_back"
        stroke="none"
        strokeWidth="1"
      >
        <g id="Group-6">
          <rect
            fill={backgroundColor}
            height="24"
            id="Rectangle"
            rx="2"
            width="24"
            x="0"
            y="0"
          />
          <polyline
            id="Path-2"
            points="9 7 14 12 9 17"
            stroke={arrowColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            transform="translate(11.5, 12) rotate(-180) translate(-11.5, -12)"
          />
        </g>
      </g>
    </svg>
  )
}

ProgramArrowBack.propTypes = {
  currentIndex: PropTypes.number.isRequired,
}

export default ProgramArrowBack
