import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const BannerSpacer = styled.div`
  margin-top: 6.45rem;
`

export const Wrapper = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
  padding: 1.3125rem 1.25rem 0 1.25rem;

  @media screen and (max-width: ${themeGet('breakpoints.sizes.mobile')}) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  & nav.breadcrumbs {
    display: flex;
    align-items: flex-start;
    width: 100%;
    margin-bottom: 1.625rem;
  }
`
