export const FAQ_LINK =
  'https://support.secondstep.org/hc/en-us/articles/27652849593243-2024-25-Detailed-Program-Updates-for-Second-Step-Middle-School'

export const FAQ_LINK_TEXT = 'See FAQ'

export const TEXT_TITLE =
  'Recognizing Bullying & Harassment Unit Access for Grades 6–8'
export const TEXT_SUBTITLE =
  'Select schools and change their access to ON or OFF.'

export const defaultSort = {
  columnTitle: 'School',
  direction: 'asc',
}
