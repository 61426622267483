/* eslint-disable react/jsx-sort-props */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ReportingInProgress, ReportingDone } from 'secondstep-components'
import {
  LessonsTable,
  NotStartedIcon,
  SmallText,
  StatusBodyCopy,
  StatusTooltip,
} from './component.styles'
import {
  FlexContainer,
  TextContainer,
  TextHiddenOverflow,
} from 'components/ReportsTable/component.styles'
import {
  COL_SPAN_TWO,
  COL_SPAN_THREE,
  MOBILE_LARGE_VIEW_WIDTH,
} from './constants'
import dayjs from 'dayjs'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { toTitleCase } from 'utils/stringHelpers'
import { lessonTitleMap, unitTitleMap } from './unitAndLessonTitleMap'
dayjs.extend(localizedFormat)

const K8LessonsTable = ({
  lessons,
  lessonsTooltip,
  markedDoneTooltip,
  statusTooltip,
}) => {
  const [unitColspan, setUnitColspan] = useState(() =>
    window.innerWidth > MOBILE_LARGE_VIEW_WIDTH ? COL_SPAN_THREE : COL_SPAN_TWO,
  )

  const updateUnitColspan = () =>
    setUnitColspan(
      window.innerWidth > MOBILE_LARGE_VIEW_WIDTH
        ? COL_SPAN_THREE
        : COL_SPAN_TWO,
    )

  useEffect(() => {
    window.addEventListener('resize', updateUnitColspan)
    return () => window.removeEventListener('resize', updateUnitColspan)
  }, [])

  const addUnitRows = rows => {
    const formatUnitRowText = row => {
      const { parent, parentTitle } = row
      const unitTitle = unitTitleMap[parentTitle] || parentTitle
      return `${toTitleCase(parent)}: ${unitTitle}`
    }
    const rowsWithUnitRows = []
    if (rows.length > 0) {
      let currentUnit = rows[0].parent
      rowsWithUnitRows.push(formatUnitRowText(rows[0]))
      rows.forEach(r => {
        if (r.parent !== currentUnit) {
          currentUnit = r.parent
          rowsWithUnitRows.push(formatUnitRowText(r))
        }
        rowsWithUnitRows.push(r)
      })
    }
    return rowsWithUnitRows
  }
  const tableRows = addUnitRows(lessons)

  // TODO: componentize?
  const statusMap = {
    complete: { text: 'Done', Icon: ReportingDone },
    incomplete: {
      text: 'In Progress',
      Icon: ReportingInProgress,
      displayDate: true,
    },
    notStarted: { text: 'Not Started Yet' },
  }
  const columns = [
    {
      title: 'Lessons',
      component: <span>Lessons</span>,
      fieldValue: row => {
        if (typeof row === 'string') {
          return row
        }
        const { lessonNumber, lessonTitle } = row
        const userFriendlyLessonTitle =
          lessonTitleMap[lessonTitle] || lessonTitle
        return (
          <TextHiddenOverflow>
            {lessonNumber}. {userFriendlyLessonTitle}
          </TextHiddenOverflow>
        )
      },
    },
    {
      title: 'Status',
      component: (
        <span>
          Status
          <StatusTooltip
            content={<StatusBodyCopy>{statusTooltip}</StatusBodyCopy>}
          >
            <i className="fas fa-question-circle" />
          </StatusTooltip>
        </span>
      ),
      fieldValue: row => {
        if (typeof row === 'string') {
          return
        }
        const { status, lastViewedDate } = row
        const { Icon, text, displayDate } = statusMap[status]
        return (
          <FlexContainer>
            {Icon && <Icon className="cell-icon" />}
            {!Icon && <NotStartedIcon className="cell-icon" />}
            <TextContainer>
              <div>{text}</div>
              {displayDate && (
                <SmallText>
                  {dayjs(lastViewedDate).format('MM/DD/YYYY')}
                </SmallText>
              )}
            </TextContainer>
          </FlexContainer>
        )
      },
    },
    {
      title: 'Marked Done On',
      component: (
        <FlexContainer>
          <TextHiddenOverflow>Marked Done On</TextHiddenOverflow>
        </FlexContainer>
      ),
      fieldValue: row => {
        if (typeof row === 'string') {
          return
        }
        return row.completedDate
          ? dayjs(row.completedDate).format('MM/DD/YYYY')
          : '—'
      },
    },
  ]

  return (
    <LessonsTable
      columns={columns}
      headThemeLight
      key="k8-lessons-table"
      multiColSpanValue={unitColspan}
      rows={tableRows}
    />
  )
}

K8LessonsTable.propTypes = {
  lessons: PropTypes.array,
  lessonsTooltip: PropTypes.string,
  markedDoneTooltip: PropTypes.string,
  statusTooltip: PropTypes.string,
}

export default K8LessonsTable
