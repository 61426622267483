import React, { useEffect, useState } from 'react'
import { VideoHelper } from 'secondstepVideo'
import VideoComponent from './component'
import { DATA_TEST_ID_PREFIX } from './constants'

const VideoDataSetterContainer = props => {
  const {
    videoUrl,
    pageComponentIndex,
    id,
    refetchLocale,
    poster,
    videoUrlExtended,
    transcriptSource,
    transcriptExtended,
    tracks,
    variant,
    theme,
  } = props || {}

  // TODO remove after flagRouter is removed.
  //  necessary for now due to weird issue where FlagRouter can't fully copy a array with an object on all renders
  const modifiedTracks = JSON.parse(tracks)
  const [transcript, setTranscript] = useState(null)
  const [audioDescriptionTranscript, setAudioDescriptionTranscript] = useState(
    null,
  )
  const [isTranscriptActive, setIsTranscriptActive] = useState(false)
  const [transcriptRendered, setRenderTranscript] = useState(false)
  const [isFetchingEATranscript, setIsFetchingEATranscript] = useState(false)
  const [isAudioDescActive, setIsAudioDescActive] = useState(false)
  const [isFetchingTranscript, setIsTranscriptFetching] = useState(false)

  const transcriptSrc = transcriptSource?.file?.url
  const extendedTranscriptSrc = transcriptExtended?.file?.url

  const showTranscript = () => {
    if (!transcriptRendered && transcriptSrc) {
      setRenderTranscript(true)
      setIsTranscriptActive(true)
    }
    if (transcriptRendered) {
      setRenderTranscript(false)
      setIsTranscriptActive(false)
    }
  }

  const handleLoad = async () => {
    setIsTranscriptFetching(true)
    const r = await fetch(transcriptSrc)
    if (!r.ok) {
      return
    }
    const text = await r.text()
    setTranscript(text)
    setIsTranscriptFetching(false)
  }

  const handleExtendedAudioTranscriptLoad = async () => {
    setIsFetchingEATranscript(true)
    const r = await fetch(extendedTranscriptSrc)
    if (!r.ok) {
      return
    }
    const text = await r.text()
    setAudioDescriptionTranscript(text)
    setIsFetchingEATranscript(false)
  }

  const handleClickAudioDesc = () => {
    setIsAudioDescActive(!isAudioDescActive)
  }

  useEffect(() => {
    if (!transcript && transcriptSrc && !isFetchingTranscript) {
      handleLoad()
    }
  }, [transcriptSrc])

  useEffect(() => {
    if (
      extendedTranscriptSrc &&
      !audioDescriptionTranscript &&
      !isFetchingEATranscript
    ) {
      handleExtendedAudioTranscriptLoad()
    }
  }, [extendedTranscriptSrc])

  const currentVideoUrl = isAudioDescActive ? videoUrlExtended : videoUrl
  const hasVideoUrl = videoUrl !== null
  const hasLoadedTranscripts = transcript !== null
  const showAudioDescriptionButton =
    videoUrlExtended && audioDescriptionTranscript

  const settings = VideoHelper.getSettings({
    logo: { enabled: false },
    hotKeys: {
      enableVolumeScroll: false,
    },
  })

  const shouldWaitForTranscript =
    transcriptSrc && isFetchingTranscript && !transcript
  const shoulWaitForEATranscript =
    extendedTranscriptSrc &&
    isFetchingEATranscript &&
    !audioDescriptionTranscript
  const noTracks =
    !modifiedTracks ||
    (Array.isArray(modifiedTracks) && modifiedTracks.length === 0)

  if (!hasVideoUrl || shouldWaitForTranscript || shoulWaitForEATranscript) {
    return null
  }
  if (noTracks && id) {
    console.error(`There are no video captions uploaded for entry: ${id}`)
  }

  const dataTestId = pageComponentIndex
    ? `${DATA_TEST_ID_PREFIX}${pageComponentIndex}-${id}`
    : `${DATA_TEST_ID_PREFIX}${id}`

  return (
    <VideoComponent
      audioDescriptionTranscript={audioDescriptionTranscript}
      dataTestId={dataTestId}
      extendedTranscriptSrc={extendedTranscriptSrc}
      handleClickAudioDesc={handleClickAudioDesc}
      hasLoadedTranscripts={hasLoadedTranscripts}
      isAudioDescActive={isAudioDescActive}
      isTranscriptActive={isTranscriptActive}
      key={currentVideoUrl}
      poster={poster}
      refetchLocale={refetchLocale}
      settings={settings}
      showAudioDescriptionButton={showAudioDescriptionButton}
      showTranscript={showTranscript}
      theme={theme}
      tracks={modifiedTracks}
      transcript={transcript}
      transcriptRendered={transcriptRendered}
      variant={variant}
      videoUrl={currentVideoUrl}
      videoUrlExtended={videoUrlExtended}
    />
  )
}
export default VideoDataSetterContainer
