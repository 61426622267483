export const getCaptionTrackWithSource = (source = '', refetchLocale = '') => {
  let localLabel = ''
  if (refetchLocale === 'en-US') {
    localLabel = 'English'
  }
  if (refetchLocale === 'es-US') {
    localLabel = 'Español'
  }

  let modifiedSource = source
  if (modifiedSource.startsWith('//')) {
    modifiedSource = modifiedSource.slice(2)
    modifiedSource = `https://${modifiedSource}`
  }
  const formattedCaptionObject = {
    kind: 'captions',
    label: localLabel,
    src: modifiedSource,
    srclang: refetchLocale,
  }
  const holder = []
  holder.push(formattedCaptionObject)
  return holder
}
