import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const TabSetWrapper = styled.div`
  margin: 1.6875rem 0 1.3125rem 0;

  ${themeGet('breakpoints.mobile')} {
    display: flex;
    justify-content: center;
  }
`
