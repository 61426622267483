import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { BodyCopy, DownloadIcon } from 'secondstep-components'
import {
  ApiErrorWrapper,
  ButtonWrapper,
  CardContainer,
  DownloadPDFLink,
  DownloadWrapper,
  Header,
  Icon,
  ImageWrapper,
  SelectedCardWrapper,
  SelectedOptionWrapper,
  StepModalCurrentStepIndicator,
  StepModalFooter,
  StyledContainer,
  StyledHeader,
  SubHeader,
  TextWrapper,
} from './component.styles'

import {
  CONFIRM_ALERT_MESSAGE,
  CONFIRM_MESSAGE,
  CONFIRM_TITLE,
  IMPLEMENTATION_PREFERENCES,
} from './constants'

import ContinueButton from 'components/ContinueButton'

import ImplementationLevelCard from './../StepModalImplementationLevelCard'
import StepModalBackLink from './../StepModalBackLink'
import ApiErrorMessage from 'components/ApiErrorMessage'
import StepModalConfirmationPanel from './../StepModalConfirmationPanel'

const StepModalStep = ({
  confirmedOptions,
  displayConfirmationPanel,
  handleClose,
  handleSelection,
  handleStepChange,
  hasError,
  isCurrent,
  isLevelChangeConfirmed,
  onConfirmationChange,
  savedPreferences,
  stepsCount,
  stepContent,
  stepNumber,
}) => {
  const {
    backLabel,
    bodyCopy,
    choiceContent,
    confirmLabel,
    header,
    helpCopy,
    icon,
    nextLabel,
    pdfs,
    value,
  } = stepContent

  useEffect(() => {
    if (choiceContent) {
      const value = choiceContent[0].value
      const choiceValue = value.string || value.number || value.boolean

      for (const savedPreference in savedPreferences) {
        const preferenceValue = savedPreferences[savedPreference]
        if (typeof choiceValue !== typeof preferenceValue) {
          continue
        }
        if (!IMPLEMENTATION_PREFERENCES.includes(savedPreference)) continue
        if (choiceValue === preferenceValue) {
          handleSelection(choiceContent[0])
        } else {
          handleSelection(choiceContent[1])
        }
      }
    }
  }, [])

  const extractPDFSelection = () => {
    const selection = {}

    confirmedOptions.forEach(option => {
      if (option.value.number) {
        selection.level = option.value?.number
      } else if (option.value.boolean != null) {
        selection.studentActivities = option.value?.boolean
      }
    })

    return selection
  }

  const getCorrectPdf = () => {
    const selectedPDF = {}
    const userRuleSelection = extractPDFSelection()

    if (
      userRuleSelection.level == null ||
      userRuleSelection.studentActivities == null
    ) {
      return
    }

    pdfs.forEach(pdfMeta => {
      if (
        pdfMeta.values[0].boolean === userRuleSelection.studentActivities &&
        pdfMeta.values[0].number === userRuleSelection.level
      ) {
        selectedPDF.url = pdfMeta.file?.file?.url
        selectedPDF.displayTitle = pdfMeta.displayTitle
      }
    })

    return selectedPDF
  }

  const findActiveOption = value => {
    let isActiveOption = false
    for (const option in confirmedOptions) {
      if (Object.hasOwnProperty.call(confirmedOptions, option)) {
        const cardValue = value.string || value.number || value.boolean

        const confirmedOptionValue =
          confirmedOptions[option].value?.string ||
          confirmedOptions[option].value?.number ||
          confirmedOptions[option].value?.boolean

        if (typeof cardValue !== typeof confirmedOptionValue) {
          continue
        }
        if (cardValue === confirmedOptionValue) {
          isActiveOption = true
        }
      }
    }
    return isActiveOption
  }

  const isCurrentOptionSelected = value => {
    return (
      findActiveOption(choiceContent[0].value) ||
      findActiveOption(choiceContent[1].value)
    )
  }

  const handleContinueButtonClick = async () => {
    !confirmLabel ? await handleStepChange(stepNumber + 1) : handleClose()
  }

  return (
    <Fragment key="step-modal-step-outer">
      {isCurrent && (
        <StyledContainer
          centeredContent={!choiceContent}
          isLastStep={stepNumber > stepsCount}
        >
          {choiceContent || stepNumber > stepsCount ? (
            <StyledHeader>
              <StepModalBackLink
                onClick={() => handleStepChange(stepNumber - 1)}
              >
                {backLabel}
              </StepModalBackLink>
              {stepNumber <= stepsCount && (
                <StepModalCurrentStepIndicator>
                  STEP {stepNumber} OF {stepsCount}
                </StepModalCurrentStepIndicator>
              )}
            </StyledHeader>
          ) : (
            <Fragment key="step-modal-step-final-icon-wrapper">
              {icon && (
                <ImageWrapper>
                  <Icon
                    alt={icon.title}
                    height={icon.file.details.image.height}
                    src={icon.file.url}
                    width={icon.file.details.image.width}
                  />
                </ImageWrapper>
              )}
            </Fragment>
          )}
          {header && (
            <Header isLastStep={stepNumber > stepsCount}>
              {stepNumber === 4
                ? header +
                  confirmedOptions.map(choice => choice.header).join(', ')
                : header}
            </Header>
          )}
          <TextWrapper>
            {bodyCopy && (
              <SubHeader>
                <BodyCopy>{bodyCopy}</BodyCopy>
              </SubHeader>
            )}
          </TextWrapper>
          {confirmedOptions && !choiceContent && stepNumber !== 0 && (
            <Fragment key="step-modal-step-summary">
              <DownloadWrapper>
                <DownloadIcon />
                <DownloadPDFLink
                  dataTestId="step-modal-pdf-download"
                  href={getCorrectPdf()?.url}
                >
                  {getCorrectPdf()?.displayTitle}
                </DownloadPDFLink>
              </DownloadWrapper>
              <SelectedOptionWrapper>
                {confirmedOptions.map((option, id) => (
                  <SelectedCardWrapper key={id}>
                    <ImplementationLevelCard
                      alt={option.icon.title}
                      clickable={false}
                      icon={option.icon.file.url}
                      iconHeight={option.icon.file.details.image.height}
                      iconWidth={option.icon.file.details.image.width}
                      isActive={true}
                      title={option.header}
                      value={value}
                    />
                  </SelectedCardWrapper>
                ))}
              </SelectedOptionWrapper>
            </Fragment>
          )}
          {choiceContent?.length > 0 ? (
            <Fragment key="step-modal-step-choice-section">
              <CardContainer>
                {choiceContent.map((choice, id) => (
                  <ImplementationLevelCard
                    description={choice.bodyCopy}
                    handleSelection={handleSelection}
                    icon={choice.icon.file.url}
                    iconHeight={choice.icon.file.details.image.height}
                    iconWidth={choice.icon.file.details.image.width}
                    id={`implementationCard-${stepNumber}-${id}`}
                    isActive={findActiveOption(choice.value)}
                    key={`implementationCard-${stepNumber}-${id}`}
                    subtitle={choice.headerSecondary}
                    title={choice.header}
                    value={choice}
                  />
                ))}
              </CardContainer>
              {displayConfirmationPanel && (
                <StepModalConfirmationPanel
                  alertMessage={CONFIRM_ALERT_MESSAGE}
                  message={CONFIRM_MESSAGE}
                  onChange={onConfirmationChange}
                  title={CONFIRM_TITLE}
                />
              )}
              <StepModalFooter>
                {helpCopy && <BodyCopy>{helpCopy}</BodyCopy>}
                <div>
                  <ContinueButton
                    disabled={
                      !isCurrentOptionSelected() ||
                      hasError ||
                      (!isLevelChangeConfirmed && displayConfirmationPanel)
                    }
                    onClick={() => handleStepChange(stepNumber + 1)}
                  >
                    {nextLabel}
                  </ContinueButton>
                </div>
              </StepModalFooter>
            </Fragment>
          ) : (
            <ButtonWrapper>
              <ContinueButton onClick={() => handleContinueButtonClick()}>
                {nextLabel || confirmLabel}
              </ContinueButton>
            </ButtonWrapper>
          )}
          {hasError && (
            <ApiErrorWrapper>
              <ApiErrorMessage />
            </ApiErrorWrapper>
          )}
        </StyledContainer>
      )}
    </Fragment>
  )
}

StepModalStep.propTypes = {
  confirmedOptions: PropTypes.array,
  displayConfirmationPanel: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSelection: PropTypes.func,
  handleStepChange: PropTypes.func,
  hasError: PropTypes.bool,
  isCurrent: PropTypes.bool,
  isLevelChangeConfirmed: PropTypes.bool,
  onConfirmationChange: PropTypes.func,
  savedPreferences: PropTypes.object,
  stepContent: PropTypes.object,
  stepNumber: PropTypes.string,
  stepsCount: PropTypes.number,
}

export default StepModalStep
