/* eslint-disable react/prop-types */
import React, { useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import { ImageWrapper, ZoomButton, ZoomButtonGroup } from './component.styles'

const zoomButtonScale = 0.25
const zoomDoubleClickScale = 0.5
const initialScale = 1
const defaultMaxScale = 2 // Zooms in up to 200% by default
const defaultMinScale = 1
const zoomButtonClassName = 'zoom-button'
const zoomButtonsGroupClassName = 'zoom-buttons-group'

const WithZoomWrapper = React.memo(
  ({
    isPanning,
    isZooming,
    isZoomed,
    onZoomIn,
    onZoomOut,
    onReset,
    children,
    minScale,
    maxScale,
    currentScale,
  }) => {
    const cursorStyle = useMemo(() => {
      if (isPanning) {
        return 'zoom grab-cursor'
      }
      if (isZoomed && !isPanning) {
        return 'zoom pan-out-cursor'
      }
      if (!isZoomed && !isPanning) {
        return 'zoom pan-in-cursor'
      }

      return 'zoom pan-in-cursor'
    }, [isPanning, isZoomed])

    const disabledZoomIn = useMemo(
      () => !isPanning && (isZooming || currentScale >= maxScale),
      [isPanning, isZooming, currentScale, maxScale],
    )
    const disabledZoomOut = useMemo(
      () => !isPanning && (isZooming || currentScale <= minScale),
      [isPanning, isZooming, currentScale, minScale],
    )

    const handleDoubleClick = e => {
      e.stopPropagation()
      // Ignore double click on zoom buttons
      const zoomButtonWasDblClicked =
        e.target.classList.contains(zoomButtonsGroupClassName) ||
        e.target.classList.contains(zoomButtonClassName)
      if (zoomButtonWasDblClicked) {
        return
      }
      if (isZoomed) {
        onReset()
      } else {
        onZoomIn(zoomDoubleClickScale)
      }
    }

    const handleZoomIn = e => {
      e.stopPropagation()
      onZoomIn(zoomButtonScale)
    }
    const handleZoomOut = e => {
      e.stopPropagation()
      onZoomOut(zoomButtonScale)
    }

    return (
      <ImageWrapper onDoubleClick={handleDoubleClick}>
        <div className={cursorStyle}>
          <TransformComponent>{children}</TransformComponent>
          <ZoomButtonGroup className={zoomButtonsGroupClassName}>
            <ZoomButton
              className={zoomButtonClassName}
              disabled={disabledZoomIn}
              onClick={handleZoomIn}
              onDoubleClick={e => e.stopPropagation()}
            >
              <i className="fas fa-plus fa-xs" />
            </ZoomButton>
            <ZoomButton
              className={zoomButtonClassName}
              disabled={disabledZoomOut}
              onClick={handleZoomOut}
              onDoubleClick={e => e.stopPropagation()}
            >
              <i className="fas fa-minus" />
            </ZoomButton>
          </ZoomButtonGroup>
        </div>
      </ImageWrapper>
    )
  },
)

WithZoomWrapper.defaultProps = {
  onZoomIn: () => {},
  onZoomOut: () => {},
  onReset: () => {},
}

WithZoomWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  currentScale: PropTypes.number.isRequired,
  isPanning: PropTypes.bool.isRequired,
  isZoomed: PropTypes.bool.isRequired,
  isZooming: PropTypes.bool.isRequired,
  maxScale: PropTypes.number.isRequired,
  minScale: PropTypes.number.isRequired,
  onReset: PropTypes.func.isRequired,
  onZoomIn: PropTypes.func.isRequired,
  onZoomOut: PropTypes.func.isRequired,
}

const WithZoom = zoomProps => BodyComponent => {
  const InnerComponent = () => {
    const [isPanning, setIsPanning] = useState(false)
    const [currentScale, setCurrentScale] = useState(initialScale)
    const [isZooming, setIsZooming] = useState(false)

    // Zoom library is not the best at telling when zooming is done
    // so we use a timeout to update the zooming status after it started
    function updateZoomingStatus() {
      setIsZooming(true)

      setTimeout(() => {
        setIsZooming(false)
      }, 100)
    }

    const handleTransformation = ({ instance }, { scale }) => {
      if (instance.isPanning) {
        return
      }

      setCurrentScale(prevScale => {
        if (prevScale !== scale) {
          updateZoomingStatus()
          return scale
        }
        return prevScale
      })
    }

    const isZoomed = currentScale > initialScale
    const maxScale = zoomProps?.maxScale || defaultMaxScale
    const minScale = zoomProps?.minScale || defaultMinScale
    return (
      <TransformWrapper
        disabled={!isZoomed}
        doubleClick={{ disabled: true }}
        maxScale={maxScale}
        minScale={minScale}
        onPanningStart={() => setIsPanning(true)}
        onPanningStop={() => setIsPanning(false)}
        onTransformed={handleTransformation}
        wheel={{
          disabled: true,
        }}
      >
        {controls => {
          const { zoomIn, zoomOut, resetTransform } = controls
          return (
            <WithZoomWrapper
              currentScale={currentScale}
              isPanning={isPanning}
              isZoomed={isZoomed}
              isZooming={isZooming}
              maxScale={maxScale}
              minScale={minScale}
              onReset={resetTransform}
              onZoomIn={useCallback(zoomIn, [])}
              onZoomOut={useCallback(zoomOut, [])}
            >
              {BodyComponent}
            </WithZoomWrapper>
          )
        }}
      </TransformWrapper>
    )
  }
  InnerComponent.propTypes = {
    maxScale: PropTypes.number,
    minScale: PropTypes.number,
  }

  return InnerComponent
}

WithZoomWrapper.propTypes = {
  children: PropTypes.node,
  imageSize: PropTypes.number,
  isZoomed: PropTypes.bool,
  setIsZoomed: PropTypes.func,
}

export default WithZoom
