import React from 'react'
import PropTypes from 'prop-types'
import { CaretIcon } from 'secondstep-components'
import ChecklistItem from 'components/admin/ChecklistItem'
import {
  BodyCopyWrapper,
  BodyWrapper,
  ChecklistWrapper,
  CompletedTag,
  FooterWrapper,
  HeaderWrapper,
  LeftButton,
  PageButtonsWrapper,
  RightButton,
  StyledBodyCopy,
  StyledButton,
  StyledHairline,
  StyledHeaderTwo,
  SubHeaderWrapper,
  Tab,
  TabsWrapper,
  TagBackground,
} from './component.styles'
import { toKebabCase } from 'utils/stringHelpers'

const ImplementationChecklist = ({
  button,
  className,
  displayTitle,
  items,
  navigationEvent,
  selectedProgram,
  selectedTab,
  setSelectedChecklistTab,
  tabContent,
}) => {
  const navigateTabs = direction => {
    const currentIndex = tabContent.findIndex(
      ({ internalKey }) => internalKey === selectedTab,
    )
    if (direction === 'previous') {
      if (tabContent[currentIndex - 1]) {
        setSelectedChecklistTab(tabContent[currentIndex - 1].internalKey)
      }
    }
    if (direction === 'next') {
      if (tabContent[currentIndex + 1]) {
        setSelectedChecklistTab(tabContent[currentIndex + 1].internalKey)
      }
    }
  }

  const selectedTabContent = tabContent.find(
    ({ internalKey }) => internalKey === selectedTab,
  )
  const selectedTabIndex = tabContent.findIndex(
    ({ internalKey }) => internalKey === selectedTab,
  )

  if (!selectedTabContent) {
    return false
  }

  let tabContentIsComplete = true
  selectedTabContent.content.forEach(({ internalKey }) => {
    if (!items?.[internalKey]) {
      tabContentIsComplete = false
    }
  })
  const leftPageButtonIsDisabled = !tabContent[selectedTabIndex - 1]
  const rightPageButtonIsDisabled = !tabContent[selectedTabIndex + 1]
  const selectedTabSectionTitle = tabContent[selectedTabIndex]?.displayTitle

  const { displayTitle: tabSectionName } = selectedTabContent || {}

  return (
    <ChecklistWrapper className={className}>
      <HeaderWrapper>
        <StyledHeaderTwo>{displayTitle}</StyledHeaderTwo>
      </HeaderWrapper>
      <StyledHairline />
      <SubHeaderWrapper>
        <TabsWrapper>
          {tabContent.map(({ displayTitle, internalKey }) => {
            return (
              <Tab
                dataTestId={`${toKebabCase(displayTitle)}-tab-button`}
                isSelected={internalKey === selectedTab}
                key={internalKey}
                onClick={() => setSelectedChecklistTab(internalKey)}
              >
                {displayTitle}
              </Tab>
            )
          })}
        </TabsWrapper>
        <PageButtonsWrapper>
          <LeftButton
            dataTestId={`${toKebabCase(tabSectionName)}-left-button`}
            disabled={leftPageButtonIsDisabled}
            onClick={() => navigateTabs('previous')}
          >
            <CaretIcon />
          </LeftButton>
          <RightButton
            dataTestId={`${toKebabCase(tabSectionName)}-right-button`}
            disabled={rightPageButtonIsDisabled}
            onClick={() => navigateTabs('next')}
          >
            <CaretIcon />
          </RightButton>
        </PageButtonsWrapper>
      </SubHeaderWrapper>
      <BodyWrapper>
        {selectedTabContent.content.map(item => {
          if (item.contentType.id === 'actionItem') {
            return (
              <ChecklistItem
                internalKey={item?.internalKey}
                key={item?.id}
                links={item?.links}
                selectedProgram={selectedProgram}
                selectedTabSectionTitle={selectedTabSectionTitle}
                text={item?.staticText}
              />
            )
          } else if (item.contentType.id === 'headerBodyCopy') {
            return (
              <BodyCopyWrapper key={item.id}>
                <StyledBodyCopy>{item.bodyCopy}</StyledBodyCopy>
              </BodyCopyWrapper>
            )
          }
        })}
      </BodyWrapper>
      <FooterWrapper>
        <StyledButton
          dataTestId={`${toKebabCase(button.buttonText)}-button`}
          onClick={() => navigationEvent(button.url)}
        >
          {button.buttonText}
        </StyledButton>
        {tabContentIsComplete && (
          <CompletedTag>
            <TagBackground />
            <p>Completed</p>
          </CompletedTag>
        )}
      </FooterWrapper>
    </ChecklistWrapper>
  )
}

ImplementationChecklist.propTypes = {
  button: PropTypes.object,
  className: PropTypes.string,
  displayTitle: PropTypes.string,
  hsSelectedTab: PropTypes.string,
  items: PropTypes.object,
  msSelectedTab: PropTypes.string,
  navigationEvent: PropTypes.func,
  selectedProgram: PropTypes.object,
  selectedTab: PropTypes.string,
  setSelectedChecklistTab: PropTypes.func,
  tabContent: PropTypes.array,
}

export default ImplementationChecklist
