import PropTypes from 'prop-types'
import moment from 'moment'
import { StyledList } from './component.styles'
import { programMap } from './programMap'

const ExpiredLicensesList = ({ expiredLicenses }) => {
  const maxNumberOfLicenses = 3

  return (
    <StyledList>
      {expiredLicenses.map(
        ({ expirationDate, id, product, siteName }, index) => {
          if (index < maxNumberOfLicenses) {
            const code = product.programCodes.find(code => code in programMap)
            const programName = programMap[code]
            const formattedExpirationDate = moment
              .utc(expirationDate)
              .format('LL')
            const licenseTitle = `${siteName} ${programName} expired on ${formattedExpirationDate}`

            return (
              <li key={id}>
                <i>{licenseTitle}</i>
              </li>
            )
          }
        },
      )}
    </StyledList>
  )
}

ExpiredLicensesList.propTypes = {
  expiredLicenses: PropTypes.array,
}

export default ExpiredLicensesList
