import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { HeaderOne } from 'secondstep-components'
import variables from 'themes/variables'

export const TrainingHeaderWrapper = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1.25rem 0;
  max-width: ${variables.containerMaxWidth};
  width: 100%;
  background: none;

  ${HeaderOne} {
    color: ${themeGet('colors.darkerDarkBlue')};
    font-family: ${themeGet('fontFamilyHeadingBold')};
    font-size: ${themeGet('font.size.headers.h1')};
    margin-top: 0.5625rem;
  }

  @media screen and (max-width: 820px) {
    display: block;
    flex-direction: column;

    ${HeaderOne} {
      line-height: 2.5rem;
      margin-bottom: 0.375rem;
      margin-top: 0.625rem;
    }
  }

  ${themeGet('breakpoints.mobile')} {
    ${HeaderOne} {
      font-size: 2rem;
      letter-spacing: 0.0313rem;
      line-height: 2.375rem;
      margin-top: 0;
    }
  }
`

export const TimeWrapper = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  color: ${themeGet('colors.darkerDarkBlue')};
  flex-direction: row;
  margin-top: 0.8125rem;

  svg {
    margin-right: 0.5rem;
    margin-bottom: 0.0625rem;
  }

  span {
    font-family: ${themeGet('fontFamilySemibold')};
  }

  @media screen and (max-width: 820px) {
    margin-top: 0;
    margin-bottom: 0.875rem;
  }
`
