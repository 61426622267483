import styled from 'styled-components'
import AdminFlexibleContentPanel from 'components/AdminFlexibleContentPanel'

export const AdminDashboardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 1366px;
  padding: 0 1.25rem;
  margin-bottom: 2.25rem;
  box-sizing: border-box;
`

export const ContentWrapper = styled.div`
  box-sizing: border-box;
  justify-content: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;

  [class*='StyledGrommet'] {
    min-height: 0 !important;
  }
`

export const StyledAdminFlexibleContentPanel = styled(
  AdminFlexibleContentPanel,
)`
  margin-top: 1.25rem;
`
