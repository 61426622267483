export default {
  SAVING_SITE_PACING_DATES: 'site/SAVING_SITE_PACING_DATES',
  SAVING_SITE_PACING_DATES_SUCCESS: 'site/SAVING_SITE_PACING_DATES_SUCCESS',
  SAVING_SITE_PACING_DATES_ERROR: 'site/SAVING_SITE_PACING_DATES_ERROR',
  SAVING_SITE_PREFERENCES: 'site/SAVING_SITE_PREFERENCES',
  SAVING_SITE_PREFERENCES_SUCCESS: 'site/SAVING_SITE_PREFERENCES_SUCCESS',
  SAVING_SITE_PREFERENCES_ERROR: 'site/SAVING_SITE_PREFERENCES_ERROR',
  UPDATE_MSP_OPT_IN_PREFERENCES: 'site/UPDATE_MSP_OPT_IN_PREFERENCES',
  UPDATE_MSP_OPT_IN_PREFERENCES_SUCCESS:
    'site/UPDATE_MSP_OPT_IN_PREFERENCES_SUCCESS',
  UPDATE_MSP_OPT_IN_PREFERENCES_ERROR:
    'site/UPDATE_MSP_OPT_IN_PREFERENCES_ERROR',
  CLEAR_ERROR: 'site/CLEAR_ERROR',
  UPDATING_USER_PROGRAMPREFERENCES: 'site/UPDATING_USER_PROGRAMPREFERENCES',
  UPDATING_USER_PROGRAMPREFERENCES_SUCCESS:
    'site/UPDATING_USER_PROGRAMPREFERENCES_SUCCESS',
  UPDATING_USER_PROGRAMPREFERENCES_ERROR:
    'site/UPDATING_USER_PROGRAMPREFERENCES_ERROR',
}
