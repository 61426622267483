import cloneDeep from 'lodash/cloneDeep'

export default class ParallelArray {
  constructor(primary = [], secondary = []) {
    if (primary.length !== secondary.length) {
      throw new Error(
        `invalid array pair. arrays must be same length. 
        received lengths ${primary.length} and ${secondary.length}`,
      )
    }
    this.primary = primary
    this.secondary = secondary
  }

  get length() {
    return this.primary.length
  }

  includes(primaryEntry) {
    return this.primary.includes(primaryEntry)
  }

  push(primaryEntry, secondaryEntry) {
    this.primary.push(primaryEntry)
    this.secondary.push(secondaryEntry)
  }

  shift() {
    return [this.primary.shift(), this.secondary.shift()]
  }

  spliceAtPrimaryKey(key) {
    const index = this.primary.indexOf(key)
    return [
      this.primary.splice(index, 1)[0],
      this.secondary.splice(index, 1)[0],
    ]
  }

  clone() {
    return cloneDeep(this)
  }
}
