import React from 'react'
import { getDisplayName } from './componentHelpers'

// NOTE: this can be used to wrap HOC or Class components, not functional components
export const forwardRefWrapped = WrappedComponent => {
  const ForwardRefWrappedComponent = React.forwardRef((props, ref) => (
    <WrappedComponent {...props} forwardedRef={ref} />
  ))
  ForwardRefWrappedComponent.displayName = `ForwardRef(${getDisplayName(
    WrappedComponent,
  )})` // for React Developer Tools
  return ForwardRefWrappedComponent
}
