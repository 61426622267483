import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StyledContainer, StyledTelescopeIcon } from './component.styles'
import {
  BANNER_STRONG_TEXT,
  BANNER_LINK_TEXT,
  BANNER_SCROLL_THRESHOLD,
  MARGIN_TOP_DEFAULT,
  MARGIN_TOP_MOBILE,
  MARGIN_TOP_SCROLL,
  MOBILE_LARGE_BREAKPOINT,
} from './constants'
import previewBanner from './assets/ss_curriculumpreview_banner.svg'
import { Link } from 'react-router-dom'

const CoursePreviewBanner = ({ extractLessonsLink, lessonsLink }) => {
  const [dynamicCSSPosition, setDynamicCSSPosition] = useState('relative')
  const [dynamicMarginTop, setDynamicMarginTop] = useState(
    window.innerWidth <= MOBILE_LARGE_BREAKPOINT
      ? MARGIN_TOP_MOBILE
      : MARGIN_TOP_DEFAULT,
  )

  const goTolessonsParts = extractLessonsLink(BANNER_LINK_TEXT)

  /*
   simulate position: sticky

   actually setting position to sticky would require a lot of CSS work to position
   the header and the footer correctly so they scroll on and off screen
  */
  const handleScroll = () => {
    if (window.scrollY > BANNER_SCROLL_THRESHOLD) {
      setDynamicCSSPosition('fixed')
      setDynamicMarginTop(MARGIN_TOP_SCROLL)
    } else {
      setDynamicCSSPosition('relative')
      // need a negative offset if at the top of the screen:
      if (window.innerWidth <= MOBILE_LARGE_BREAKPOINT) {
        setDynamicMarginTop(MARGIN_TOP_MOBILE)
      } else {
        setDynamicMarginTop(MARGIN_TOP_DEFAULT)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <StyledContainer
      bannerImage={previewBanner}
      style={{
        position: dynamicCSSPosition,
        marginTop: dynamicMarginTop,
      }}
    >
      <StyledTelescopeIcon aria-hidden="true" role="presentation" />
      <div>
        <p>
          <strong>{BANNER_STRONG_TEXT}</strong>
        </p>
        <p>
          {/* first match [0] is the whole string */}
          {goTolessonsParts[1]}
          <Link to={lessonsLink}>{goTolessonsParts[2]}</Link>
          {goTolessonsParts[3]}
        </p>
      </div>
    </StyledContainer>
  )
}

CoursePreviewBanner.propTypes = {
  extractLessonsLink: PropTypes.func,
  lessonsLink: PropTypes.string,
}

export default CoursePreviewBanner
