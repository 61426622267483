export const fileTypes = {
  audio: 'audio',
  avi: 'video',
  flv: 'video',
  mov: 'video',
  mp3: 'audio',
  mp4: 'video',
  odp: 'powerpoint',
  pdf: 'pdf',
  powerpoint: 'powerpoint',
  ppt: 'powerpoint',
  pptm: 'powerpoint',
  pptx: 'powerpoint',
  video: 'video',
  wav: 'audio',
  wmv: 'video',
  doc: 'wordDoc',
}
