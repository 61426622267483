import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  BaseContainer,
  CalendarInput,
  WarningIcon,
} from 'secondstep-components'

export const Title = styled.span`
  font-family: ${themeGet('font.sharpSlab.semibold')};
  color: ${themeGet('colors.darkBlue')};
  margin-right: 20px;
`

export const SubTitle = styled.span`
  font-family: ${themeGet('font.molde.medium')};
  letter-spacing: 1px;
  line-height: 20px;
  font-size: 12px;
  color: ${themeGet('colors.lightGray')};
`
export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 6px;
`

export const TitleAndSubTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 40px;

  ${themeGet('breakpoints.mobile')} {
    flex-direction: column;
    align-items: start;
    margin-right: 0;
  }
`

export const DateTitle = styled.span`
  font-size: 0.75rem;
  line-height: 1.438rem;
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('font.molde.medium')};
`

export const StyledCalendarInput = styled(CalendarInput)`
  align-items: flex-start;
`

export const Divider = styled.hr`
  border-top: 2px solid white;
  border-left: 0;
  width: 100%;
`

export const DateRange = styled.span`
  color: ${themeGet('colors.darkGray')};
`

export const DateRangeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  ${themeGet('breakpoints.mobile')} {
    flex-direction: column;
  }
`

export const DateInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 50%;

  &:not(:last-child) {
    margin-right: 20px;
  }

  ${themeGet('breakpoints.mobile')} {
    &:not(:last-child) {
      margin-bottom: 14px;
    }
    width: 100%;
    margin-right: 0;
  }
`

export const Weeks = styled.span`
  font-size: 0.813rem;
  color: ${themeGet('colors.darkGray')};
`

export const StyledWarningIcon = styled(WarningIcon)`
  height: 10px;
  width: 10px;
  margin-right: 4px;

  circle:first-child {
    fill: ${themeGet('colors.errorRed')};
  }
`

export const Error = styled.div`
  display: flex;
  align-items: baseline;
  text-align: left;
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: -3px;
  color: ${themeGet('colors.errorRed')};
`

export const ErrorText = styled.span`
  line-height: 15px;
`

export const StyledContainer = styled(BaseContainer).attrs(() => ({
  as: 'section',
}))`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0.875rem 1.25rem 1.313rem;
  margin-top: 1.25rem;
  background-color: ${themeGet('colors.paleGray')};
  min-width: 560px;
  width: 100%;

  ${themeGet('breakpoints.mobile')} {
    min-width: 0;
  }
`
