import { connect } from 'react-redux'
import { navigationEvent } from 'layers/navigation/store/operations'
import {
  getProgramSnapshot,
  SNAPSHOTS_NAME_MAP,
} from 'utils/adminExperienceHelpers'
import { ADMIN_DASHBOARD_ENTRY_ID } from 'utils/entryIds'
import siteOperations from 'store/siteManager/operations'
import HighSchoolLaunchSnapshot from './component'

const mapStateToProps = ({ contentful, siteManager }, { programKey }) => {
  const { sitePreferences } = siteManager || {}
  const snapshotFieldName = SNAPSHOTS_NAME_MAP[programKey]
  const snapshots =
    contentful?.[ADMIN_DASHBOARD_ENTRY_ID]?.payload?.[snapshotFieldName] || []
  const snapshot = getProgramSnapshot(programKey, snapshots)
  const contentData = snapshot?.['setupPanel']

  return {
    contentData,
    programKey,
    sitePreferences,
  }
}

const mapDispatchToProps = {
  navigationEvent,
  updateSitePreferences: siteOperations.updateSitePreferences,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(HighSchoolLaunchSnapshot)
