import React from 'react'
import PropTypes from 'prop-types'
import {
  ExpandedWrapper,
  Wrapper,
  CheckDescription,
  CheckTitle,
  CheckWrapper,
  StyledCheckBox,
  TextWrapper,
} from './component.styles'

export const ExpandableCheckSection = ({
  children,
  id,
  title,
  description,
  isChecked,
  onChange,
}) => {
  return (
    <Wrapper>
      <CheckWrapper>
        <StyledCheckBox
          alternateId={id}
          dataTestId={`${id}-test-id`}
          hideText={true}
          isChecked={isChecked}
          onChange={onChange}
          text={'some text'}
        />
        <TextWrapper>
          <CheckTitle>{title}</CheckTitle>
          <CheckDescription>{description}</CheckDescription>
        </TextWrapper>
      </CheckWrapper>
      {isChecked && children && <ExpandedWrapper>{children}</ExpandedWrapper>}
    </Wrapper>
  )
}

ExpandableCheckSection.propTypes = {
  children: PropTypes.object,
  description: PropTypes.string,
  id: PropTypes.string,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  title: PropTypes.string,
}

export default ExpandableCheckSection
