import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { CompleteIcon, HeaderFive, MeetingIcon } from 'secondstep-components'
import { domains } from 'config/env'
import withApiError, { ERROR_TYPE_LMS } from 'layers/errorHandling/apiError'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { LEADER_TEXT, STAFF_TEXT } from './constants'
import {
  ContentWrapper,
  IconWrapper,
  TextWrapper,
  MicrolearningButton,
} from './component.styles'

export const Microlearning = ({
  displayTitle,
  description,
  hasLeaderMeeting,
  instance,
  isActive,
  isChoice,
  isDone,
  isLeader,
  label,
  microlearningType,
  navigationEvent,
  sco,
  time,
}) => {
  label = label || ''
  const launchMicrolearning = () => {
    const type = NavigationHelper.types.OPEN
    navigationEvent(`${domains.LMS_API_BASE}/launch/${sco}/${instance}`, type)
  }

  const isGroupOrLeader =
    microlearningType?.includes('group') ||
    microlearningType?.includes('leader')
  const isNumbered = label.includes('Microlearning')
  const className = isChoice ? 'microlearning-choice' : 'microlearning'
  let title = `${label}${isGroupOrLeader ? ':' : '.'} ${displayTitle}`
  if (!isNumbered && !isGroupOrLeader) {
    title = `${displayTitle}`
  }
  const totalTime = `${time} min.`
  const BODY_TEXT = isLeader ? LEADER_TEXT : STAFF_TEXT

  return (
    <MicrolearningButton
      className={className}
      isLeader={isLeader}
      onClick={launchMicrolearning}
    >
      <ContentWrapper isDone={isDone}>
        <IconWrapper isDone={isDone} isLeader={isLeader}>
          {isDone && <CompleteIcon />}
          {isGroupOrLeader && <MeetingIcon />}
        </IconWrapper>
        <TextWrapper isActive={isActive} isDone={isDone}>
          <HeaderFive>
            <span className="title">{title}</span>
            <span className="time">{totalTime}</span>
          </HeaderFive>
          {(hasLeaderMeeting || isLeader) && <span>{description}</span>}
          {hasLeaderMeeting && <span>{BODY_TEXT}</span>}
        </TextWrapper>
      </ContentWrapper>
    </MicrolearningButton>
  )
}

Microlearning.propTypes = {
  description: PropTypes.string,
  displayTitle: PropTypes.string,
  hasLeaderMeeting: PropTypes.bool,
  instance: PropTypes.string,
  isActive: PropTypes.bool,
  isChoice: PropTypes.bool,
  isDone: PropTypes.bool,
  isLeader: PropTypes.bool,
  label: PropTypes.string,
  microlearningType: PropTypes.string,
  navigationEvent: PropTypes.func,
  sco: PropTypes.number,
  time: PropTypes.number,
}

export default connect(
  null,
  NavigationHelper.mapDispatchToProps,
)(withApiError(Microlearning, [ERROR_TYPE_LMS]))
