import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_CONTENTFUL_PRODUCT,
  ERROR_TYPE_LMS,
} from 'layers/errorHandling/apiError'
import MicrolearningChoice from './component'

export class MicrolearningChoiceContainer extends Component {
  static propTypes = {
    choices: PropTypes.array,
    microlearnings: PropTypes.array,
    nextMicrolearningId: PropTypes.string,
  }

  mergeLmsAndContentfulData = (lmsLessons, microlearnings) => {
    const mergedData = lmsLessons.reduce((dataArray, lesson) => {
      const microlearningMatch = microlearnings.find(microlearning => {
        if (microlearning?.displayTitle) {
          return microlearning?.id === lesson.contentfulID
        }
      })

      if (microlearningMatch) {
        dataArray.push({
          ...lesson,
          ...microlearningMatch,
          time: microlearningMatch?.time,
        })
      }

      return dataArray
    }, [])

    return mergedData
  }

  render() {
    const { nextMicrolearningId, choices, microlearnings } = this.props
    const mergedMicrolearnings =
      (microlearnings &&
        this.mergeLmsAndContentfulData(choices, microlearnings)) ||
      []

    if (mergedMicrolearnings.length < 1) return null

    return (
      <MicrolearningChoice
        microlearnings={mergedMicrolearnings}
        nextMicrolearningId={nextMicrolearningId}
      />
    )
  }
}

export const mapper = (entry = {}) => {
  const { item1Lesson, item2Lesson } = entry
  return { microlearnings: [item1Lesson, item2Lesson] }
}

const options = {
  entryIdProp: true,
  include: 2,
  mapper,
  spread: true,
}

export default ContentEntryDataGetter(
  withApiError(MicrolearningChoiceContainer, [
    ERROR_TYPE_CONTENTFUL,
    ERROR_TYPE_CONTENTFUL_PRODUCT,
    ERROR_TYPE_LMS,
  ]),
  options,
)
