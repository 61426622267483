import { deepMerge } from 'grommet/utils'
import { themes as ssComponentsThemes } from 'secondstep-components'
import variables from './variables'

const grommetMainTheme = {
  meta: { themeName: 'main' },

  calendar: {
    day: {
      extend: ({ isSelected }) => ({
        background: isSelected ? variables.colors.lightOrange : null,
        borderRadius: '50%',
        lineHeight: 0,
      }),
    },
  },
  colors: {
    input: {
      text: variables.colors.darkerDarkBlue,
    },
    links: {
      primary: variables.colors.blue,
      primaryHover: variables.colors.darkBlue,
    },
    inlineTranslation: {
      background: variables.colors.paleGray,
    },
    programCard: {
      background: {
        color: variables.colors.themePrimary,
      },
    },
    buttons: {
      header: {
        level: {
          5: variables.colors.ost7,
        },
      },
    },
  },
  dashboard: {
    border: {
      color: variables.colors.gray500,
    },
  },
  global: {
    colors: {
      focus: variables.colors.blue,
    },
    hover: {
      background: variables.colors.secondStepBlue,
      color: variables.colors.white,
    },
    selected: {
      background: 'transparent',
      color: 'inherit',
    },
  },
  contentSetWrapper: {
    border: {
      radius: '0',
    },
  },

  heading: {
    level: {
      '1': {
        font: {
          family: variables.fontFamilyHeadingExtraBold,
          weight: variables.fontWeightNormal,
        },
        medium: {
          height: '3rem',
          size: '2.625rem',
        },
      },
      '2': {
        font: {
          family: variables.fontFamilyHeadingExtraBold,
          weight: variables.fontWeightNormal,
        },
        medium: {
          height: '2.125rem',
          size: '1.75rem',
        },
      },
      '3': {
        font: {
          family: variables.fontFamilyHeadingBold,
          weight: variables.fontWeightNormal,
        },
        large: {
          height: '2rem',
          size: '1.75rem',
        },
        medium: {
          height: '1.75rem',
          size: '1.375rem',
        },
        spacing: '0.069375rem',
      },
    },
  },
  anchor: {
    color: variables.colors.blue,
  },
}

const mainTheme = {
  breakpoints: variables.breakpoints,
  buttons: variables.buttons,

  cardBorder: '1px solid #ccc',
  cardBorderRadius: '0.75rem',
  colors: {
    ...variables.colors,
    advisoryActivities: {
      backgroundColor: variables.colors.darkBlue,
      copy: variables.colors.white,
    },
    borderColor: variables.colors.gray400,
    boxShadow: variables.colors.gray300,

    // Why can't we just have buttons pull the color directly
    buttons: {
      iconBase: variables.colors.blue,
      iconHover: variables.colors.secondStepBlue,
      primaryBackground: variables.colors.blue,
      primaryBackgroundDisabled: variables.colors.buttonDisabled,
      primaryBackgroundHover: variables.colors.secondStepBlue,
      primaryFont: variables.colors.white,
      resumeBackground: variables.colors.yellow,
      resumeBackgroundHover: variables.colors.darkYellow,
      reviewBackground: variables.colors.blue,
      reviewBackgroundHover: variables.colors.darkBlue,
      secondaryOutline: variables.colors.gray600,
      secondaryText: variables.colors.darkGray,
      startBackground: variables.colors.teal,
      startBackgroundHover: variables.colors.darkTeal,
      tabActive: variables.colors.secondStepBlue,
      tabDefault: variables.colors.darkGray,
    },
    contentSetWrapper: {
      background: {
        color: 'transparent',
      },
    },
    courseInstanceCardBackground: '#F2F2F2',
    danger: variables.colors.red,
    headerBase: variables.colors.navyBlue,
    heading: {
      level: {
        1: variables.colors.darkBlue,
        2: variables.colors.darkBlue,
        3: variables.colors.darkBlue,
        4: variables.colors.darkBlue,
        5: variables.colors.darkerDarkBlue,
        6: variables.colors.darkBlue,
      },
    },
    lessonABBackground: '#e7eff5',
    lessonReport: {
      allGradesBackground: variables.colors.gray1000,
      allGradesForeground: variables.colors.secondStepBlue,
      helperText: variables.colors.darkGray,
      helperTextHorizRule: variables.colors.gray500,
      singleGradeBackground: variables.colors.zircon,
      singleGradeForeground: variables.colors.tealButtonIconInset,
    },
    programLinkTile: {
      backgroundColor: variables.paleGray,
    },
    progressBarIndicator: variables.colors.yellow,
    tabTitleInactive: variables.colors.darkerDarkBlue,
    textBase: variables.colors.navyBlue,
    themeAccent: variables.colors.blue,
    themeHighlight: variables.colors.yellow,
    themePrimary: variables.colors.darkBlue,
    themeSecondary: variables.colors.orange,
  },
  fonts: {
    family: {
      level: {
        6: variables.font.molde.semiBold,
      },
    },
  },
  spacing: {
    ...variables.spacing,
  },

  // 9px
  courseInstanceCardBorderRadius: '0.5625rem',

  font: variables.font,

  globalNav: {
    background: variables.colors.orange,
  },

  headerFontFamilyBold: variables.fontFamilyHeadingBold,
  lessons: {
    height: '4.07rem',
  },
  navTabLink: {
    fontSize: '.9375rem',
    marginRight: '1.75rem',
  },
  padding: ['2px', '4px', '6px'],

  programNav: {
    backgroundColor: variables.colors.white,
    boxShadow: `0px 5px 7px ${variables.colors.boxShadow}`,
    boxShadowColor: variables.colors.boxShadow,
  },

  textFontFamily: variables.fontFamily,
  anchor: {
    color: variables.colors.blue,
  },
}
export default deepMerge(
  ssComponentsThemes.baseTheme,
  grommetMainTheme,
  mainTheme,
)
