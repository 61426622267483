import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { HeaderTwo, PipeIcon } from 'secondstep-components'

export const LessonListWrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
  key: dataTestId,
}))`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding-bottom: 0.75rem;
  margin-top: 1.25rem;
  border-radius: 0.5rem;
  background-color: ${themeGet('colors.white')};
  box-shadow: 1px 1px 10px 1px ${themeGet('colors.boxShadow')};
`

export const SubHeadBox = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.75rem 1.75rem 1.5rem;

  ${HeaderTwo} {
    margin-bottom: 0;
    line-height: 1.5rem;
  }

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 1.1875rem;

    ${HeaderTwo} {
      margin-bottom: 0.6875rem;
    }
  }

  @media (max-width: 500px) {
    padding: 1.75rem 1.1875rem 1.3125rem 1.1875rem;

    ${PipeIcon} {
      margin: 0 0.25rem 0.125rem;
    }
  }

  ${themeGet('breakpoints.mobile')} {
    padding: 1.75rem 1rem 1.1875rem;

    ${HeaderTwo} {
      margin-bottom: 0.875rem;
    }
  }
`
