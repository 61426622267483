import * as yup from 'yup'

export const validationSchema = yup.object().shape({
  className: yup
    .string()
    .nullable()
    .when('teachesEducatorActivities', {
      is: true,
      then: yup.string().required('Primary Department is required'),
    }),
  periods: yup
    .number()
    .nullable()
    .when('teachesEducatorActivities', {
      is: true,
      then: yup.number().required('Class Periods is required'),
    })
    .when('teachesEducatorActivities', {
      is: true,
      then: yup
        .number()
        .required('Class Periods is required')
        .min(0, 'Enter a number between 0 and 15')
        .max(15, 'Enter a number between 0 and 15'),
    }),
  teachesEducatorActivities: yup.boolean().nullable(),
  teachesSchoolwideActivities: yup.boolean().nullable(),
  teachesStudentActivities: yup.boolean().nullable(),
})
