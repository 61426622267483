import { getEntryFromParentContent } from '../utils/selectors'
import namespaceMap from './namespaceMap'

// If the pathname includes 3 items we are on the namespace level of routing.
// e.g. ["", "district", "supports"] namespace is "district"
// If the pathname includes 4 items we are on the section level of routing.
// e.g. ["", "district", "supports", "sel-for-adults"] section is "teach"
export function findRawNamespaceEntryByPath(rootEntry, pathname) {
  const paths = pathname.split('/')
  const levels = paths.length
  const namespacePath = paths[1]
  const sectionPath = paths[2]
  const { nextNodeName, nodeName } = namespaceMap[namespacePath]

  const namespaceEntry = getEntryFromParentContent(
    rootEntry,
    nodeName,
    namespacePath,
  )
  const sectionEntry =
    levels >= 3 &&
    getEntryFromParentContent(namespaceEntry, nextNodeName, sectionPath)

  return { namespaceEntry, sectionEntry }
}
