import React from 'react'
import PropTypes from 'prop-types'
import { ProgramThemeIcons } from 'secondstep-components'

import { toPascalCase } from '../../utils/stringHelpers'

import {
  ActivityTags,
  AdvisoryActivityDetailsPaneWrapper,
  MainTitle,
  StyledHairline,
  SubTitle,
  Tag,
  TagText,
  Text,
  TitleAndTextContainer,
} from './component.styles'
import {
  PROGRAM_THEMES_TITLE,
  PROJECT_TYPE_TITLE,
  TIME_TITLE,
} from './constants'
import { SERVICE_LEARNING_PROJECT } from './data'

const AdvisoryActivityDetailsPane = ({
  activityType,
  projectType,
  tags,
  time,
  id,
}) => {
  const detailsDataTestId = `advisory-activities-details-${id}`

  return (
    <AdvisoryActivityDetailsPaneWrapper data-testid={detailsDataTestId}>
      <MainTitle>{activityType}</MainTitle>
      <StyledHairline />
      {activityType === SERVICE_LEARNING_PROJECT && (
        <TitleAndTextContainer>
          <SubTitle>{PROJECT_TYPE_TITLE}</SubTitle>
          <Text>{projectType}</Text>
        </TitleAndTextContainer>
      )}
      <TitleAndTextContainer>
        <SubTitle>{TIME_TITLE}</SubTitle>
        <Text>{time}</Text>
      </TitleAndTextContainer>
      <TitleAndTextContainer>
        <SubTitle>{PROGRAM_THEMES_TITLE}</SubTitle>
      </TitleAndTextContainer>
      <ActivityTags>
        {tags.map(tag => {
          const pascalTag = toPascalCase(tag)
          const iconKey = `${pascalTag}Icon`
          const Icon = ProgramThemeIcons[iconKey]

          return (
            <Tag key={tag}>
              <Icon />
              <TagText>{tag}</TagText>
            </Tag>
          )
        })}
      </ActivityTags>
    </AdvisoryActivityDetailsPaneWrapper>
  )
}

AdvisoryActivityDetailsPane.propTypes = {
  activityType: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  projectType: PropTypes.string,
  tags: PropTypes.array.isRequired,
  time: PropTypes.string.isRequired,
}

export default AdvisoryActivityDetailsPane
