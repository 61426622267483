import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import {
  StyledContent,
  StyledAccordion,
  StyledBodyCopy,
  StyledReference,
} from './component.styles.js'

export const fixSuperscripts = references => {
  return references.replace(/<sup>/g, '&nbsp;<sup>')
}

const HsIndividualResourceReferences = forwardRef(
  (
    { audience, references, isOpen, focusedReference, setAccordionCollapse },
    ref,
  ) => {
    return (
      <StyledContent>
        <StyledAccordion
          audience={audience}
          followActiveProp={true}
          isActive={isOpen}
          onClick={() => setAccordionCollapse && setAccordionCollapse(!isOpen)}
          title="References"
        >
          <div ref={ref}>
            <StyledBodyCopy
              renders={{
                p: prop => {
                  const hasSupClicked = prop.children.find(
                    child =>
                      child.type === 'sup' &&
                      child.props.children[0] === focusedReference,
                  )
                  return (
                    <StyledReference isSelected={hasSupClicked}>
                      {prop.children}
                    </StyledReference>
                  )
                },
              }}
            >
              {fixSuperscripts(references)}
            </StyledBodyCopy>
          </div>
        </StyledAccordion>
      </StyledContent>
    )
  },
)

HsIndividualResourceReferences.propTypes = {
  audience: PropTypes.string,
  focusedReference: PropTypes.any,
  isOpen: PropTypes.bool,
  references: PropTypes.string,
  setAccordionCollapse: PropTypes.func,
}

export default HsIndividualResourceReferences
