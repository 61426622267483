import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Container = styled.button`
  display: none;
  ${themeGet('breakpoints.tablet')} {
    display: flex;
  }

  align-items: center;
  background: transparent;
  border: 1px solid ${themeGet('colors.gray500')};
  border-radius: 0.25rem;
  padding: 0.75rem;
  font-family: Molde-Medium;
  color: ${themeGet('callouts.bodyTextColor')};
  justify-content: center;
  height: 3.25rem;

  svg {
    fill: ${themeGet('colors.themeSecondary')};
    stroke: ${themeGet('colors.themeSecondary')};
  }

  &:focus,
  &:focus-within {
    border: 1px solid ${themeGet('colors.themePrimary')};
  }
`

export const ModalContainer = styled.section`
  display: block;
  ${themeGet('breakpoints.tablet')} {
    display: none;
    ${({ visible }) => {
      return visible && `display: block;`
    }};
  }

  margin-top: 1.75rem;

  h4 {
    border-bottom: 1px solid ${themeGet('colors.gray500')};
    margin: 0;
    padding-bottom: 1rem;
  }

  .ais-RefinementList-list,
  .ais-RefinementList-item {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  fieldset {
    border: none;
    position: relative;
    display: block;
    padding: 0;

    .collapsible-body {
      padding-bottom: 1.25rem;
    }

    legend {
      position: relative;
      display: flex;
      align-items: center;
      float: left;
      width: 100%;
      padding: 0;
      font-family: 'Molde-Medium';
      height: 60px;
      color: ${themeGet('colors.darkGray')};
      &:hover {
        color: ${themeGet('colors.themePrimary')};
      }
      button {
        position: absolute;
        right: 0;
        top: 12px;
        bottom: 0;
        left: 0;
        background: transparent;
        border: none !important;
        text-align: end;
        width: 100%;
        color: ${themeGet('colors.darkGray')};

        &[aria-expanded='true'] {
          color: ${themeGet('colors.themePrimary')};
        }
      }
    }
  }

  ${themeGet('breakpoints.tablet')} {
    div[role='dialog'] {
      overflow-y: unset;
    }
    form {
      height: 100%;
    }

    fieldset {
      border-bottom: none;
      legend {
        button {
          top: 0.313rem;
        }
      }
    }

    .ais-RefinementList-count {
      ::before {
        content: ' (';
      }
      ::after {
        content: ')';
      }
    }
  }
`

export const MobileModalContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 85%;
  width: 100%;

  section {
    overflow-y: auto;
    fieldset {
      border-bottom: 1px solid ${themeGet('colors.gray500')};
    }
  }

  footer {
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 100%;
    }
  }
`

export const MobileOnly = styled.div`
  display: none;
  ${themeGet('breakpoints.tablet')} {
    display: flex;
    justify-content: center;
    align-items: center;
    button: {
      width: 100% !important;
    }
  }
`
