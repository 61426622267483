import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'

const breakpoints = {
  aboveTablet: '@media (max-width: 1000px)',
  belowTablet: '@media (min-width: 750px)',
  aboveMobile: '@media (max-width: 750px)',
}

export const BoxContent = styled(Box)`
  padding: 0 1.25rem;
  position: relative;
`

export const FooterAnchor = styled(Box)`
  height: '100%';
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`
export const FooterBottomRightContainer = styled(Box)`
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2.5rem;

  ${breakpoints.aboveMobile} {
    display: none;
  }
`

export const DashboardCardBox = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  background: ${themeGet('colors.paleGray')};
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  ${breakpoints.aboveMobile} {
    flex-direction: column-reverse;
  }
`

export const DashboardWrapper = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  flex-direction: row;
  max-width: none;
  width: 100%;
`

export const DashboardRightBox = styled(Box)`
  width: 10%;
  min-width: 18.375rem;
  padding: 3.5rem 1.25rem 0rem 1.25rem;
  position: relative;
  text-align: center;
  background: ${themeGet('colors.white')};

  ${BoxContent} {
    width: 100%;
    padding: 0;
  }

  ${themeGet('breakpoints.xlarge')} {
    width: 10%;
    ${BoxContent} {
      margin-left: auto;
      margin-right: auto;
      padding: 0;
      width: 16.5rem;
    }
  }

  ${breakpoints.aboveTablet} {
    width: 18.375rem;
  }

  ${breakpoints.aboveMobile} {
    width: 100%;
    padding: 1rem;
    min-height: 0;
    ${BoxContent} {
      margin-left: 0;
    }
  }
`
