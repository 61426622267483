import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ErrorMessage } from 'secondstep-components'
import {
  operations as licenseOperations,
  selectors as licenseSelectors,
} from 'store/licenseManager'
import { operations as loadingOperations } from 'store/loadingManager'
import {
  operations as reportsOperations,
  selectors as reportsSelectors,
} from 'store/reportsManager'
import { K8_GRADE_KEY_TO_GRAPH_ENUM } from 'store/reportsManager/constants'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import { navigationEvent } from 'layers/navigation/store/operations'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { REPLACE } from 'layers/navigation/store/types'
import K8Report from './component'
import { ENTRY_ID, K8_GRADE_NAME_TO_GRADE_SLUG_MAP } from './constants'

export const K8ReportContainer = ({
  activeAdminK8Sites,
  classDetailsShouldFetch,
  classInstance,
  entry,
  errorStatusCode,
  fetchK8ReportClassDetails,
  fetchK8ReportGrade,
  fetchK8ReportGradeAndClassDetails,
  fetchK8ReportSchool,
  fetchUserSites,
  gradeAndClassShouldFetch,
  gradeEmptyDisplay,
  gradeGraphEnum,
  gradeShouldFetch,
  isFetchingUserSites,
  isLoadingApp,
  location,
  match,
  navigationEvent,
  query,
  schoolEmptyDisplay,
  schoolShouldFetch,
  selectedSite,
  setAppIsLoadingState,
  setAppNotLoadingState,
  shouldLoad,
  updateSelectedProgram,
  updateSelectedSite,
}) => {
  useEffect(() => {
    updateSelectedProgram({ name: 'K8', key: 'K8' })
  }, [])

  useEffect(() => {
    if (shouldLoad && isLoadingApp) setAppIsLoadingState('Loading content...')
    if (!shouldLoad && !isLoadingApp) setAppNotLoadingState()
  }, [isLoadingApp, shouldLoad])

  useEffect(() => {
    if (!activeAdminK8Sites) {
      fetchUserSites()
    }
    if (schoolShouldFetch) {
      fetchK8ReportSchool(selectedSite)
    }
    if (gradeAndClassShouldFetch) {
      fetchK8ReportGradeAndClassDetails(
        selectedSite,
        gradeGraphEnum,
        classInstance,
      )
    } else {
      if (gradeShouldFetch) {
        fetchK8ReportGrade(selectedSite, gradeGraphEnum)
      }
      if (classDetailsShouldFetch) {
        fetchK8ReportClassDetails(selectedSite, classInstance)
      }
    }
  }, [
    classDetailsShouldFetch,
    classInstance,
    errorStatusCode,
    gradeAndClassShouldFetch,
    gradeGraphEnum,
    gradeShouldFetch,
    schoolShouldFetch,
    selectedSite,
  ])

  useEffect(() => {
    if (activeAdminK8Sites?.length) {
      if (!query.site && location.pathname.includes('/reports/k8')) {
        navigationHandler(
          {
            siteId: activeAdminK8Sites[0].id,
            ...(query?.class && { class: query.class }),
          },
          REPLACE,
        )
      } else if (!selectedSite || query.site !== selectedSite.toString()) {
        const matchingSite =
          activeAdminK8Sites.find(site => site.id.toString() === query.site) ||
          {}
        updateSelectedSite(matchingSite.id)
      }
    }
  }, [
    activeAdminK8Sites,
    isFetchingUserSites,
    query.class,
    query.site,
    selectedSite,
  ])

  const navigationHandler = (paramsByKey, type) => {
    const { siteId, gradeName, classInstance } = paramsByKey
    const queryParamsByKey = {
      ...(siteId && { site: siteId }),
      ...(classInstance && { class: classInstance }),
    }
    const gradeSlug = K8_GRADE_NAME_TO_GRADE_SLUG_MAP[gradeName] || ''
    const gradeParam = gradeSlug ? `/${gradeSlug}/` : ''
    const route = `/reports/k8${gradeParam}`
    const url = NavigationHelper.generateRouteWithQueryParams(
      route,
      queryParamsByKey,
    )
    return navigationEvent(url, type)
  }

  const isSettled =
    !isFetchingUserSites && activeAdminK8Sites?.length > 0 && !!entry
  if (!isSettled) {
    return null
  }
  if (!selectedSite) {
    return (
      <ErrorMessage
        additionalDetails={`Don't have access to view site with id: ${query.site}. If you believe you should have access to this site, please contact us.`}
        serviceName={'accessError'}
        statusCode={401}
      />
    )
  }

  return (
    <K8Report
      entry={entry}
      gradeEmptyDisplay={gradeEmptyDisplay}
      match={match}
      navigationEvent={navigationEvent}
      navigationHandler={navigationHandler}
      schoolEmptyDisplay={schoolEmptyDisplay}
    />
  )
}

const mapStateToProps = (state, ownProps) => {
  const { licenseManager, loadingManager, reportsManager, router } = state
  const activeAdminK8Sites = licenseSelectors.selectActiveAdminK8Sites(state)
  const { isFetchingUserSites } = licenseManager || {}
  const { error, k8Reports, selectedSite } = reportsManager || {}
  const { status: errorStatusCode } = error || {}
  const { isLoading: isLoadingApp } = loadingManager
  const shouldLoad =
    (!activeAdminK8Sites || !ownProps.entry || !selectedSite) &&
    !errorStatusCode
  const { location } = router
  const { pathname, query } = router.location
  const { class: classInstance } = query || {}
  const gradeKey = pathname?.split('/')[3]?.split('-')[1]
  const gradeGraphEnum = K8_GRADE_KEY_TO_GRAPH_ENUM[gradeKey]
  const isSchoolPage =
    !pathname?.includes('grade') &&
    !query?.class &&
    !!query?.site &&
    !errorStatusCode
  const isGradePage = pathname?.includes('grade') && !query.class
  const isClassPage = query.class
  const schoolSummaryReport = reportsSelectors.selectSchoolSummaryReport(state)
  const gradeReport = reportsSelectors.selectGradeReport(state)
  const classDetailsReport = reportsSelectors.selectClassDetailsReport(
    state,
    classInstance,
  )
  const schoolShouldFetch =
    isSchoolPage && !!selectedSite && !schoolSummaryReport
  const gradeShouldFetch =
    isGradePage && !isClassPage && selectedSite && !gradeReport
  const classDetailsShouldFetch =
    isClassPage &&
    selectedSite &&
    classInstance &&
    (!classDetailsReport || !activeAdminK8Sites || !ownProps.entry) &&
    !errorStatusCode
  const gradeAndClassShouldFetch =
    pathname?.includes('grade') &&
    query.class &&
    !gradeReport &&
    selectedSite &&
    (!classDetailsReport || !activeAdminK8Sites || !ownProps.entry) &&
    !errorStatusCode

  return {
    activeAdminK8Sites,
    classDetailsShouldFetch,
    classInstance,
    errorStatusCode,
    gradeAndClassShouldFetch,
    gradeGraphEnum,
    gradeShouldFetch,
    k8Reports,
    isFetchingUserSites,
    isLoadingApp,
    location,
    query,
    schoolShouldFetch,
    selectedSite,
    shouldLoad,
  }
}

const mapDispatchToProps = {
  fetchK8ReportClassDetails:
    reportsOperations.fetchK8ReportClassDetailsBySiteId,
  fetchK8ReportGrade: reportsOperations.fetchK8ReportGradeBySiteId,
  fetchK8ReportGradeAndClassDetails:
    reportsOperations.fetchK8ReportGradeAndClassDetailsBySiteId,
  fetchK8ReportSchool: reportsOperations.fetchK8ReportSchoolBySiteId,
  fetchUserSites: licenseOperations.fetchUserSites,
  navigationEvent,
  setAppIsLoadingState: loadingOperations.setIsLoadingState,
  setAppNotLoadingState: loadingOperations.setNotLoadingState,
  updateSelectedSite: reportsOperations.updateSelectedSite,
  updateSelectedProgram: reportsOperations.updateSelectedProgram,
}

const mapper = entry => {
  if (!entry) {
    return {}
  }
  const {
    gradeEmptyStateIcon,
    gradeEmptyStateText,
    gradeEmptyStateHeader,
    schoolEmptyStateIcon,
    schoolEmptyStateText,
    schoolEmptyStateHeader,
  } = entry
  return {
    entry,
    gradeEmptyDisplay: {
      header: gradeEmptyStateHeader,
      icon: gradeEmptyStateIcon,
      text: gradeEmptyStateText,
    },
    schoolEmptyDisplay: {
      header: schoolEmptyStateHeader,
      icon: schoolEmptyStateIcon,
      text: schoolEmptyStateText,
    },
  }
}

K8ReportContainer.propTypes = {
  activeAdminK8Sites: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  classDetailsShouldFetch: PropTypes.bool,
  classInstance: PropTypes.string,
  entry: PropTypes.object,
  errorStatusCode: PropTypes.number,
  fetchK8ReportClassDetails: PropTypes.func,
  fetchK8ReportGrade: PropTypes.func,
  fetchK8ReportGradeAndClassDetails: PropTypes.func,
  fetchK8ReportSchool: PropTypes.func,
  fetchUserSites: PropTypes.func,
  getIdFromQueryParams: PropTypes.func,
  gradeAndClassShouldFetch: PropTypes.bool,
  gradeEmptyDisplay: PropTypes.object,
  gradeGraphEnum: PropTypes.string,
  gradeShouldFetch: PropTypes.bool,
  isFetchingUserSites: PropTypes.bool,
  isLoadingApp: PropTypes.bool,
  location: PropTypes.object,
  match: PropTypes.object,
  navigationEvent: PropTypes.func,
  query: PropTypes.object,
  schoolEmptyDisplay: PropTypes.object,
  schoolShouldFetch: PropTypes.bool,
  selectedSite: PropTypes.number,
  setAppIsLoadingState: PropTypes.func,
  setAppNotLoadingState: PropTypes.func,
  shouldLoad: PropTypes.bool,
  updateSelectedProgram: PropTypes.func,
  updateSelectedSite: PropTypes.func,
}

export default ContentEntryDataGetter(
  K8ReportContainer,
  {
    entryId: ENTRY_ID,
    include: 3,
    mapper,
    spread: true,
  },
  connect(mapStateToProps, mapDispatchToProps),
)
