import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { domains } from 'config/env'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { toKebabCase } from 'utils/stringHelpers'
import AdminSearchSelect from 'components/AdminSearchSelect'
import {
  LinkImage,
  StyledOutlineButton,
  SupportLinksWrapper,
} from './component.styles'
import { LICENSE_MANAGEMENT, USER_MANAGEMENT } from './constants'

const AdminResourceLinkList = ({ availableLinks, navigationEvent }) => {
  const navigateHandler = (urlPath, openSameTab) => {
    const type = openSameTab
      ? NavigationHelper.types.PUSH
      : NavigationHelper.types.NEW_TAB_SAME_SITE

    if (
      urlPath.includes(LICENSE_MANAGEMENT) ||
      urlPath.includes(USER_MANAGEMENT)
    ) {
      navigationEvent(
        `${domains.ADMIN_DOMAIN}${urlPath}`,
        NavigationHelper.types.OPEN,
      )
    } else {
      navigationEvent(urlPath, type)
    }
  }

  const renderSupportLinks = () => {
    let links
    if (availableLinks) {
      links = availableLinks.map(link => {
        const { buttonText, displayTitle, icon, linkMenu, openSameTab, url } =
          link || {}
        const { file, title } = icon || {}
        const { details, url: imgUrl } = file || {}
        const { image } = details || {}
        const { height, width } = image || {}
        const DropdownButtonIcon = dataTestId => {
          return (
            <LinkImage
              alt={title}
              dataTestId={dataTestId}
              height={height}
              key={dataTestId}
              src={imgUrl}
              width={width}
            />
          )
        }

        return (
          <Fragment key={title}>
            {linkMenu && (
              <AdminSearchSelect
                dataTestId={toKebabCase(displayTitle)}
                defaultLabel={displayTitle}
                dropdownButtonIcon={<DropdownButtonIcon />}
                keyProp="id"
                labelProp="buttonText"
                onChange={({ url: optionUrlPath, openSameTab }) =>
                  navigateHandler(optionUrlPath, openSameTab)
                }
                options={linkMenu}
                shouldHideSelectedIcon={true}
              />
            )}

            {!linkMenu && (
              <StyledOutlineButton
                dataTestId={toKebabCase(buttonText)}
                onClick={() => navigateHandler(url, openSameTab)}
              >
                <LinkImage
                  alt={title}
                  height={height}
                  src={imgUrl}
                  width={width}
                />
                {buttonText}
              </StyledOutlineButton>
            )}
          </Fragment>
        )
      })
    }
    return links
  }

  return <SupportLinksWrapper>{renderSupportLinks()}</SupportLinksWrapper>
}

AdminResourceLinkList.propTypes = {
  availableLinks: PropTypes.array,
  navigationEvent: PropTypes.func,
}

export default AdminResourceLinkList
