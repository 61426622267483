import React from 'react'
import PropTypes from 'prop-types'

import ContentOverview from 'experienceComponents/ContentOverview'
import {
  HeaderBackground,
  HeaderContent,
  HeaderWrapper,
  StyledHairline,
  StyledTabButton,
  StyledTabSet,
} from './component.styles'

const AdvisoryActivitiesHeader = ({
  activeContentType,
  contentOverview,
  contentTypes,
  entryId,
  handleContentChange,
  locale,
}) => {
  const contentOverviewKey = 'content-overview-key'
  const advisoryHairlineKey = 'advisory-hairline-key'
  const shouldRenderTabs = contentTypes?.length > 1

  return (
    <HeaderWrapper>
      <HeaderBackground />
      <HeaderContent>
        <ContentOverview
          {...contentOverview}
          entryId={entryId}
          key={contentOverviewKey}
          locale={locale}
        />
        <StyledHairline key={advisoryHairlineKey} />
        {shouldRenderTabs && (
          <StyledTabSet dataTestId="advisory-activity-tabset">
            {contentTypes.map((contentType, index) => (
              <StyledTabButton
                dataTestId={`advisory-activity-tab-${contentType.title.toLowerCase()}`}
                isActive={activeContentType.title === contentType.title}
                key={index}
                label={contentType.title}
                onClick={() => handleContentChange(contentType)}
              />
            ))}
          </StyledTabSet>
        )}
      </HeaderContent>
    </HeaderWrapper>
  )
}

AdvisoryActivitiesHeader.propTypes = {
  activeContentType: PropTypes.object,
  contentOverview: PropTypes.object,
  contentTypes: PropTypes.array,
  entryId: PropTypes.string,
  handleContentChange: PropTypes.func,
  locale: PropTypes.string,
}

export default AdvisoryActivitiesHeader
