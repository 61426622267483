export function onMouseEnter() {
  const { onHover } = this.props
  onHover && onHover(true)
}

export function onMouseLeave() {
  const { onHover } = this.props
  onHover && onHover(false)
}

export function onPress() {
  const { onPress } = this.props
  onPress && onPress()
}
