import styledNative from 'styled-components/native'
import { themeGet } from 'styled-system'
import { withTheme } from 'styled-components'

export const AnchorNavigationWrapper = withTheme(styledNative.View.attrs(
  props => {
    const borderColor = themeGet('anchorNavigation.borderColor')(props)
    if (!borderColor) {
      return {
        border: 'none',
      }
    }
    return {
      border: borderColor && `2px solid ${borderColor}`,
    }
  },
)`
  display: flex;
  background-color: ${themeGet(
    'anchorNavigation.backgroundColor',
    'colors.white',
  )};
  box-shadow:  ${themeGet('anchorNavigation.boxShadow', 'none')};
  border: ${({ border }) => border};
  border-radius: 12px;
  padding-right: 1.75rem;
  padding-left: 1.75rem;
  padding-top: 1.5rem;
  padding-bottom: 0.625rem;
`)

export const ContentsText = withTheme(styledNative.Text`
  font-style: italic;
  font-family: ${themeGet('textFontFamily')};
  font-size: inherit;
  padding-bottom: 0.563rem;
  line-height: 22px;
`)
