import { getContentModelNode } from './selectors'

export const buildContentNode = ({
  entryId,
  contentType,
  parentId,
  routeSlug,
  pathLevel,
}) => {
  const contentModelNode = getContentModelNode(contentType)

  if (
    !contentModelNode ||
    !entryId ||
    !contentType ||
    !parentId ||
    !routeSlug ||
    !pathLevel
  ) {
    return null
  }

  return {
    entryId,
    contentType,
    parentId,
    routeSlug,
    pathLevel,
    ...contentModelNode,
  }
}
