import React from 'react'
import PropTypes from 'prop-types'

import NavigationHelper from 'layers/navigation/navigationHelper'
import ContentParentNodeGetter from 'layers/content/Hocs/ContentParentNodeGetter'

import {
  getContentTabRoutes,
  getLastSlug,
  processEntryAndParentEntry,
  stripLastRouteSlug,
} from './data'
import AdvisoryActivitiesLanding from './component'

/*
  whole file is guarding against usage of activityList contentType. If we get that
  we push to the groupedContent using a navigationEvent, then grab
  the activityList as the parent. This is to align the the url in the app
  with the current location of this component (which is reliant upon  ContentParentNodeGetter and
  ContentLastNodeGetter)
*/

export class AdvisoryActivitiesLandingContainer extends React.Component {
  static propTypes = {
    descriptionPdf: PropTypes.array,
    entry: PropTypes.object,
    getEntryWithNewLocale: PropTypes.func,
    getEntryWithNewLocaleCurrentNode: PropTypes.func,
    location: PropTypes.object.isRequired,
    navigationEvent: PropTypes.func.isRequired,
    parentEntry: PropTypes.object,
  }
  constructor(props) {
    super(props)

    const finalState = processEntryAndParentEntry(this.props) || {}
    this.state = finalState

    this.handleContentChange = this.handleContentChange.bind(this)
    this.rerouteToATabPage = this.rerouteToATabPage.bind(this)
  }

  static getDerivedStateFromProps(props) {
    const finalState = processEntryAndParentEntry(props) || {}

    return finalState
  }

  componentDidMount() {
    this.rerouteToATabPage()
  }

  componentDidUpdate() {
    this.rerouteToATabPage()
  }

  rerouteToATabPage = () => {
    const { entry, navigationEvent, location } = this.props
    const { pathname } = location
    const lastRoute = getLastSlug(pathname)

    const { contentType: contentTypeContainer, pageData } = entry || {}
    const { route: entryRoute } = pageData || {}
    const { id: contentType } = contentTypeContainer || {}

    if (contentType === 'activityList' && lastRoute === entryRoute) {
      const contentTabRoutes = getContentTabRoutes(entry) || []
      const firstChildRoute = contentTabRoutes[0]
      const currentRouteAndFirstChild = `${pathname}/${firstChildRoute}`

      navigationEvent(currentRouteAndFirstChild, NavigationHelper.types.REPLACE)
    }
  }

  handleContentChange = contentType => {
    const { navigationEvent, location } = this.props
    const { pathname } = location

    const pathWithOutLastRoute = stripLastRouteSlug(pathname)
    const { route: updatedRoute } = contentType
    const pathWithNewRoute = `${pathWithOutLastRoute}/${updatedRoute}`

    navigationEvent(pathWithNewRoute)
  }

  handleGetEntryWithNewLocale = locale => {
    const {
      getEntryWithNewLocale,
      getEntryWithNewLocaleCurrentNode,
    } = this.props
    if (getEntryWithNewLocaleCurrentNode) {
      getEntryWithNewLocaleCurrentNode(locale)
    }

    if (getEntryWithNewLocale) {
      getEntryWithNewLocale(locale)
    }
  }

  render() {
    const { entry, location, navigationEvent, parentEntry } = this.props
    const { video } = parentEntry || {}
    const { id } = video || {}

    const {
      contentType: contentTypeContainer,
      descriptionImage,
      descriptionPdf,
      locale,
    } = entry || {}
    const { id: contentType } = contentTypeContainer || {}

    const { contentOverview, contentCardList, languageToggle } =
      this.state || {}

    if (
      !contentOverview ||
      !contentCardList ||
      contentType === 'activityList'
    ) {
      return null
    }
    return (
      <AdvisoryActivitiesLanding
        contentCardList={contentCardList}
        contentOverview={contentOverview}
        descriptionImage={descriptionImage}
        descriptionPdf={descriptionPdf}
        entryId={id}
        getEntryWithNewLocale={this.handleGetEntryWithNewLocale}
        handleContentChange={this.handleContentChange}
        languageToggle={languageToggle}
        locale={locale}
        location={location}
        navigationEvent={navigationEvent}
      />
    )
  }
}

export default ContentParentNodeGetter(AdvisoryActivitiesLandingContainer, {
  include: 3,
})
