import React from 'react'

import { ROLES } from 'config/constants'
import { domains } from 'config/env'
import AccessibilityStatement from 'containers/AccessibilityStatement'
import AdminRouter from 'containers/AdminRouter'
import NamespaceRouter from 'containers/NamespaceRouter'
import ProductContainer from 'containers/Product'
import ReportRouter from 'containers/ReportRouter'
import Dashboard from 'pages/Dashboard'
import Webinars from 'pages/Webinars'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect, Route, Switch, withRouter } from 'react-router'
import { NotFound, Unauthorized } from 'secondstep-components'
import { canAccessWebinar } from 'utils/webinarHelpers'

import {
  ELEMENTARY_ROUTE,
  FAMILY_MIND_YETI_ROUTE,
  FAMILY_RESOURCES_ROUTE,
  FAIMLY_RESOURCES_HS_ROUTE,
  FT_ROUTE,
  K8_ROUTE,
  MIDDLE_SCHOOL_ROUTE,
  SELA_ROUTE,
  HIGH_SCHOOL_ROUTE,
} from './constants'

export const App = ({ profile, roles, userClaims }) => {
  const getProtectedNamespaceRoute = routerProps => {
    const {
      hasElemAdminClaim,
      hasElemFamilyClaim,
      hasMsAdminClaim,
      hasMsFamilyClaim,
      hasSelaAdminClaim,
      hasHighschoolAdminClaim,
      hasHighschoolFamilyClaim,
    } = userClaims || {}
    const hasAdminClaim =
      hasElemAdminClaim ||
      hasMsAdminClaim ||
      hasSelaAdminClaim ||
      hasHighschoolAdminClaim

    const hasFamilyClaim =
      hasElemFamilyClaim || hasMsFamilyClaim || hasHighschoolFamilyClaim
    const { pathname } = routerProps.location
    if (
      (pathname.includes(FAMILY_RESOURCES_ROUTE) && hasFamilyClaim) ||
      (pathname.includes(FAMILY_MIND_YETI_ROUTE) && hasFamilyClaim) ||
      (pathname.includes(FAIMLY_RESOURCES_HS_ROUTE) && hasFamilyClaim) ||
      hasAdminClaim
    ) {
      return <NamespaceRouter {...routerProps} />
    } else {
      return <Unauthorized />
    }
  }

  const getProtectedProductRoute = routerProps => {
    const { productName } = routerProps?.match?.params || {}
    const {
      hasElemTeacherClaim,
      hasMsTeacherClaim,
      hasSelaLeaderClaim,
      hasSelaStaffClaim,
      hasFTLeaderClaim,
      hasFTStaffClaim,
      hasHighschoolTeacherClaim,
    } = userClaims || {}
    const hasSelaAccess = hasSelaLeaderClaim || hasSelaStaffClaim
    const hasFTAccessClaim = hasFTLeaderClaim || hasFTStaffClaim

    // route logic is based on the fact that currently if you are an admin you are also a teacher
    // TODO: Currently not handling bpu/cpu routes, will still show 404's for them
    if (
      (productName === ELEMENTARY_ROUTE && !hasElemTeacherClaim) ||
      (productName === MIDDLE_SCHOOL_ROUTE && !hasMsTeacherClaim) ||
      (productName === SELA_ROUTE && !hasSelaAccess) ||
      (productName === FT_ROUTE && !hasFTAccessClaim) ||
      (productName === HIGH_SCHOOL_ROUTE && !hasHighschoolTeacherClaim)
    ) {
      return <Unauthorized />
    } else {
      return <ProductContainer {...routerProps} />
    }
  }

  const getProtectedReportsRoute = routerProps => {
    const { reportName } = routerProps?.match?.params || {}
    const {
      hasElemReportsClaim,
      hasMsReportsClaim,
      hasSelaReportsClaim,
      hasFTReportsClaim,
    } = userClaims || {}
    const hasDigitalAdminClaim = hasElemReportsClaim || hasMsReportsClaim
    if (
      (reportName === K8_ROUTE && !hasDigitalAdminClaim) ||
      (reportName === SELA_ROUTE && !hasSelaReportsClaim && !hasFTReportsClaim)
    ) {
      return <Unauthorized />
    } else {
      return <ReportRouter {...routerProps} />
    }
  }

  const getProtectedAdminRoute = routerProps => {
    const {
      hasBpuAdminClaim,
      hasCpuAdminClaim,
      hasElemAdminClaim,
      hasMsAdminClaim,
      hasSelaAdminClaim,
      hasHighschoolAdminClaim,
    } = userClaims || {}
    const hasAdminClaim =
      hasElemAdminClaim ||
      hasMsAdminClaim ||
      hasSelaAdminClaim ||
      hasBpuAdminClaim ||
      hasCpuAdminClaim ||
      hasHighschoolAdminClaim
    const isSetupAdmin = roles?.includes(ROLES.SETUP_ADMIN)
    if (hasAdminClaim || isSetupAdmin) {
      return <AdminRouter {...routerProps} />
    } else {
      return <Unauthorized />
    }
  }

  const getProtectedWebinarsRoute = routerProps =>
    canAccessWebinar(userClaims) ? (
      <Webinars {...routerProps} />
    ) : (
      <Unauthorized />
    )

  const {
    hasElemAdminClaim,
    hasHighschoolAdminClaim,
    hasMsAdminClaim,
    hasSelaAdminClaim,
  } = userClaims || {}
  const hasAdminClaim =
    hasElemAdminClaim ||
    hasHighschoolAdminClaim ||
    hasMsAdminClaim ||
    hasSelaAdminClaim

  const isSetupAdmin = roles?.includes(ROLES.SETUP_ADMIN)

  if (profile && profile?.isFirstTimeUser) {
    window.location.href = `${domains.APP_DOMAIN}/account-details/first-time`
    return null
  }

  if (profile && !profile?.hasUpdatedProfile) {
    window.location.href = `${domains.APP_DOMAIN}/account-details/prompt-to-complete`
    return null
  }
  return (
    <Switch>
      <Route path={`/admin/program`} render={getProtectedAdminRoute} />
      <Route path={`/admin`} render={getProtectedAdminRoute} />
      <Route path={`/family-resources`} render={getProtectedNamespaceRoute} />
      <Route path={`/family-mind-yeti`} render={getProtectedNamespaceRoute} />
      <Route path={`/family`} render={getProtectedNamespaceRoute} />
      {/* LEARN-15444: sunset route when /district is removed from Contentful. check with James or Angie */}
      <Route path={`/district`} render={getProtectedNamespaceRoute} />
      <Route
        // keep as render. Stops unnecessary flash of old data in contentful props
        path={`/product/:productName`}
        render={getProtectedProductRoute}
      />
      <Route path={`/reports/:reportName`} render={getProtectedReportsRoute} />
      <Redirect exact from={`/reports`} to={`/reports/k8`} />
      <Route
        exact
        path={`/dashboard/:tabName`}
        render={routerProps => <Dashboard match={routerProps.match} />}
      />
      <Route
        exact
        path={`/dashboard`}
        render={routerProps => <Dashboard match={routerProps.match} />}
      />
      <Route
        exact
        path={`/accessibility`}
        render={routerProps => (
          <AccessibilityStatement match={routerProps.match} />
        )}
      />
      <Route exact path="/webinars" render={getProtectedWebinarsRoute} />
      {hasAdminClaim || isSetupAdmin ? (
        <Redirect exact from="/" to={`/admin`} />
      ) : (
        <Redirect exact from="/" to={`/dashboard`} />
      )}
      <Route component={NotFound} />
    </Switch>
  )
}

// Exported for testing purposes (see App.test.js)
export const mapStateToProps = ({ userAccessManager, userProfileManager }) => {
  return {
    profile: userProfileManager?.profile,
    roles: userProfileManager?.profile?.roles,
    userClaims: userAccessManager?.claims,
  }
}

export default withRouter(connect(mapStateToProps, null)(App))

// Exported for testing purposes (see App.test.js)
export const JustTheComponent = App

App.propTypes = {
  profile: PropTypes.object,
  roles: PropTypes.array,
  userClaims: PropTypes.shape({
    hasElemAdminClaim: PropTypes.bool,
    hasElemTeacherClaim: PropTypes.bool,
    hasMsAdminClaim: PropTypes.bool,
    hasHighschoolAdminClaim: PropTypes.bool,
    hasHighschoolTeacherClaim: PropTypes.bool,
    hasHighschoolFamilyClaim: PropTypes.bool,
    hasMsTeacherClaim: PropTypes.bool,
    hasSelaAdminClaim: PropTypes.bool,
    hasSelaLeaderClaim: PropTypes.bool,
    hasSelaStaffClaim: PropTypes.bool,
  }),
}
