import playButtonHSDefault from 'static/img/playButtonHSDefault.svg'
import playButtonHSHover from 'static/img/playButtonHSHover.svg'
import playButtonMSDefault from 'static/img/playButtonMSDefault.svg'
import playButtonMSHover from 'static/img/playButtonMSHover.svg'
import playButtonMainDefault from 'static/img/playButtonMainDefault.svg'
import playButtonMainHover from 'static/img/playButtonMainHover.svg'
import playButtonElemDefault from 'static/img/playButtonElemDefault.svg'
import playButtonElemHover from 'static/img/playButtonElemHover.svg'
import playButtonYetiDefault from 'static/img/playButtonYetiDefault.svg'
import playButtonYetiHover from 'static/img/playButtonYetiHover.svg'
import playButtonMSKitDefault from 'static/img/playButtonMSKitDefault.svg'
import playButtonMSKitHover from 'static/img/playButtonMSKitHover.svg'
import playButtonK5KitDefault from 'static/img/playButtonK5KitDefault.svg'
import playButtonK5KitHover from 'static/img/playButtonK5KitHover.svg'

// Define a function to get the appropriate SVG files based on the theme name
export const getPlayButtonSVG = themeName => {
  switch (themeName) {
    case 'highschool':
      return {
        default: playButtonHSDefault,
        hover: playButtonHSHover,
      }
    case 'middleSchool':
      return {
        default: playButtonMSDefault,
        hover: playButtonMSHover,
      }
    case 'elementary':
      return {
        default: playButtonElemDefault,
        hover: playButtonElemHover,
      }
    case 'mindyeti':
      return {
        default: playButtonYetiDefault,
        hover: playButtonYetiHover,
      }
    case 'mskit':
      return {
        default: playButtonMSKitDefault,
        hover: playButtonMSKitHover,
      }
    case 'k5kit':
      return {
        default: playButtonK5KitDefault,
        hover: playButtonK5KitHover,
      }
    default:
      return {
        default: playButtonMainDefault,
        hover: playButtonMainHover,
      }
  }
}
