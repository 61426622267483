import React from 'react'
import { connect } from 'react-redux'
import ReportsBanner from './component.jsx'
import PropTypes from 'prop-types'

import NavigationHelper from 'layers/navigation/navigationHelper'
export class ReportsBannerContainer extends React.Component {
  navigateToDashboardOnClick = () => {
    const { navigationEvent } = this.props
    const url = `/`
    const type = NavigationHelper.types.PUSH
    navigationEvent(url, type)
  }
  render() {
    const {
      activeAdminLicenses = [],
      selectedLicense,
      onLicenseChange,
      title,
      body,
    } = this.props

    return (
      <ReportsBanner
        activeAdminLicenses={activeAdminLicenses}
        body={body}
        navigateToDashboardOnClick={this.navigateToDashboardOnClick}
        onLicenseChange={onLicenseChange}
        selectedLicense={selectedLicense}
        title={title}
      />
    )
  }
}

ReportsBannerContainer.propTypes = {
  activeAdminLicenses: PropTypes.array,
  body: PropTypes.object,
  navigationEvent: PropTypes.func,
  onLicenseChange: PropTypes.func,
  selectedLicense: PropTypes.object,
  title: PropTypes.string,
}

export default connect(
  null,
  NavigationHelper.mapDispatchToProps,
)(ReportsBannerContainer)
