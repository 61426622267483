import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import PropTypes from 'prop-types'
import VideoDataGetter from 'experienceComponents/VideoDataGetter'
import { BodyCopy } from 'secondstep-components'
import { VideoPlayButton } from 'secondstepVideo'

import {
  CopyBox,
  StyledModal,
  ThumbnailWrapper,
  VideoCalloutButton,
  VideoThumbnail,
  VideoTitle,
} from './component.styles'
import { CLICK, ENTER, SEARCHKEY, PLAY } from './constants'

const VideoCallout = ({
  dataTestId,
  entryId,
  locale,
  videoDisplayTitle,
  videoLength,
  videoThumbnailUrl,
}) => {
  const isNotReadyToRender = entryId === undefined || !videoThumbnailUrl
  if (isNotReadyToRender) {
    return null
  }

  const [showModal, setShowModal] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ])
  const openModal = event => {
    if (!showModal && (event.type === CLICK || event.key === ENTER)) {
      searchParams.append(SEARCHKEY, entryId)
      history.replace({ search: searchParams.toString() })
      setShowModal(true)
    }
  }

  const closeModal = () => {
    searchParams.delete(SEARCHKEY, entryId)
    history.replace({ search: searchParams.toString() })
    setShowModal(false)
  }

  useEffect(() => {
    if (searchParams.get(SEARCHKEY) === entryId && !showModal) {
      setShowModal(true)
    }
  }, [searchParams])

  return (
    <VideoCalloutButton
      data-testid={`${dataTestId}-modal-open`}
      onClick={openModal}
      onKeyPress={openModal}
      role="button"
      tabIndex="0"
    >
      <ThumbnailWrapper>
        <VideoThumbnail imgUrl={videoThumbnailUrl} />
        <VideoPlayButton
          aria-label={`${PLAY} ${videoDisplayTitle ?? 'video'}`}
          tabIndex="-1"
        />
      </ThumbnailWrapper>
      <CopyBox>
        {videoDisplayTitle && (
          <VideoTitle dataTestId="video-callout-title">
            {videoDisplayTitle}
          </VideoTitle>
        )}
        <BodyCopy data-testid="video-callout-copy">{`(${videoLength})`}</BodyCopy>
      </CopyBox>
      <StyledModal
        dataTestId="video-callout-modal"
        modalTitle={videoDisplayTitle}
        onClose={closeModal}
        show={showModal}
        showClose
      >
        {entryId && (
          <VideoDataGetter entryId={entryId} refetchLocale={locale} />
        )}
      </StyledModal>
    </VideoCalloutButton>
  )
}

VideoCallout.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  entryId: PropTypes.string,
  locale: PropTypes.string,
  videoDisplayTitle: PropTypes.string,
  videoLength: PropTypes.string,
  videoRef: PropTypes.object,
  videoThumbnailUrl: PropTypes.string,
  videoUrl: PropTypes.string,
}

export default VideoCallout
