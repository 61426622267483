import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  ProgressBarIndicator,
  TooltipContentWrapper,
} from 'secondstep-components'
import AdminTooltip from 'components/AdminTooltip'

export const DetailsLessonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.4375rem;

  ${themeGet('breakpoints.mobile')} {
    flex-direction: column;
  }
`

export const LessonsCompletedTitle = styled.div``

export const LessonCount = styled.div`
  text-align: right;
`

export const ProgressSummaryContainer = styled.div`
  [class*='IndicatorWrapper'] {
    [class*='Indicator-'] {
      background: ${themeGet('colors.backgroundDarkBlueGradient')};
    }
  }
`

export const StyledAdminTooltip = styled(AdminTooltip)`
  ${TooltipContentWrapper} {
    top: -92px;

    ::after {
      bottom: 42px;
      left: 76px;
    }
  }
`

export const StyledProgressBarIndicator = styled(ProgressBarIndicator)`
  width: 100%;

  [class*='Background-'] {
    height: 1.5rem;
    border-radius: 20px;

    [class*='IndicatorWrapper-'] {
      height: 100%;
      border-radius: 20px;

      [class*='Indicator-'] {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 1.5rem;
        border-radius: 20px;
      }
      [class*='BodyCopy'] {
        align-items: center;
        height: 100%;
        font-size: 1rem;
        line-height: 1.0625rem;
      }
    }
  }
`

export const TextCountLesson = styled.p`
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: ${themeGet('font.molde.regular')};
  font-size: 1rem;
  line-height: 1.1875rem;
  text-align: right;
  color: ${themeGet('colors.darkGray')};

  ${themeGet('breakpoints.mobile')} {
    text-align: left;
    line-height: 0.5625rem;
  }
`

export const TextLessons = styled.p`
  margin-block-start: 0;
  margin-block-end: 0;
  margin-bottom: 0.125rem;
  font-family: ${themeGet('font.molde.medium')};
  font-size: 1.125rem;
  line-height: 1.35rem;
  text-align: start;
  color: ${themeGet('colors.darkGray')};

  ${themeGet('breakpoints.mobile')} {
    margin-bottom: 1.125rem;
  }
`
