import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  StyledTable,
  StyledTBody,
  StyledTD,
  StyledTHead,
  StyledTH,
  StyledTR,
} from './component.styles'

const ReportsUnitDetail = ({ microlearnings, titles }) => {
  const [width, setWidth] = useState(window.innerWidth)

  const updateDimensions = () => setWidth(window.innerWidth)

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  const smallScreen = width <= 790

  return (
    <StyledTable>
      <StyledTHead>
        <StyledTR>
          {!smallScreen && (
            <>
              <StyledTH key={titles[0].key}>{titles[0].value}</StyledTH>
              <StyledTH key={titles[1].key}>{titles[1].value}</StyledTH>
            </>
          )}
          {smallScreen && (
            <StyledTH key={titles[0].key}>
              {titles[0].value}
              <br />
              {titles[1].value}
            </StyledTH>
          )}
        </StyledTR>
      </StyledTHead>
      <StyledTBody>
        {microlearnings.map(microlearning => {
          const { id } = microlearning
          return (
            !(
              microlearning.label === 'Leader Team Meeting' ||
              microlearning.label === 'Small-Group Meeting'
            ) && (
              <StyledTR key={id}>
                {!smallScreen && (
                  <>
                    <StyledTD key={id + 1}>
                      <b>{`${microlearning.label}:`}</b>{' '}
                      {microlearning.displayTitle}
                    </StyledTD>
                    <StyledTD key={id + 2}>
                      <b>{microlearning.completionRate}</b>
                    </StyledTD>
                  </>
                )}
                {smallScreen && (
                  <StyledTD key={id + 1}>
                    <b>{`${microlearning.label}:`}</b>{' '}
                    {microlearning.displayTitle} <br />
                    <b>{microlearning.completionRate}</b>
                  </StyledTD>
                )}
              </StyledTR>
            )
          )
        })}
      </StyledTBody>
    </StyledTable>
  )
}

ReportsUnitDetail.propTypes = {
  microlearnings: PropTypes.array,
  titles: PropTypes.array,
}

export default ReportsUnitDetail
