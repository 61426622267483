import { domains } from 'config/env'

export const ADMIN_HEADER_TITLE =
  'Second Step® licenses that you manage have expired'
export const ADMIN_MESSAGE = 'To resume schoolwide access, renew your licenses.'
export const DISMISS_BUTTON_TEXT = 'Dismiss'
export const LINK_HREF = `${domains.ADMIN_DOMAIN}/license-management/expired-licenses`
export const LINK_TEXT = 'See all licenses in License Management'
export const NON_ADMIN_HEADER_TITLE =
  'Second Step® licenses associated with your account have expired'
export const RENEW_BUTTON_TEXT = 'Renew'
export const RENEW_HREF =
  'https://support.secondstep.org/hc/en-us/articles/7704410319899-How-do-I-renew-my-Second-Step-licenses'
export const STAFF_MESSAGE =
  'You may have lost access to your programs and progress data. If you have questions, contact your school administrator.'
