import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withApiError, {
  ERROR_TYPE_REPORTING,
} from 'layers/errorHandling/apiError'
import {
  operations as reportsOperations,
  selectors as reportsSelectors,
} from 'store/reportsManager'
import K8GradeViewComponent from './component'
import {
  MSP_GRADES_MAP,
  K8_GRADE_KEY_TO_GRAPH_ENUM,
  K8_GRAPH_ENUM_TO_GRADE_NAME_MAP,
} from 'store/reportsManager/constants'

export const K8GradeViewContainer = ({
  bullyingHarassmentUnitEnabled,
  classDetailsReport,
  classInstance,
  entry,
  gradeEmptyDisplay,
  gradeName,
  gradeReport,
  isFetchingGrade,
  lastUpdatedAt,
  navigationHandler,
  selectedGrade,
  selectedSite,
  updateSelectedGrade,
}) => {
  useEffect(() => {
    if (selectedGrade !== gradeName) updateSelectedGrade(gradeName)
  }, [classDetailsReport, classInstance, gradeName, gradeReport, selectedGrade])

  return (
    <K8GradeViewComponent
      bullyingHarassmentUnitEnabled={bullyingHarassmentUnitEnabled}
      classInstance={classInstance}
      entry={entry}
      gradeEmptyDisplay={gradeEmptyDisplay}
      gradeReport={gradeReport}
      isFetchingGrade={isFetchingGrade}
      lastUpdatedAt={lastUpdatedAt}
      navigationHandler={navigationHandler}
      selectedGrade={selectedGrade}
      selectedSite={selectedSite}
    />
  )
}

export const mapStateToProps = (state, ownProps) => {
  const { licenseManager, reportsManager, router } = state
  const classInstance = `${router.location.query.class || ''}`
  const { isFetchingGrade, selectedSite, selectedGrade } = reportsManager
  const gradeParam = ownProps.gradeParam
  const gradeKey = gradeParam.split('-')[1]
  const gradeGraphEnum = K8_GRADE_KEY_TO_GRAPH_ENUM[gradeKey]
  const gradeName = K8_GRAPH_ENUM_TO_GRADE_NAME_MAP[gradeGraphEnum]
  const classDetailsReport = reportsSelectors.selectClassDetailsReport(
    state,
    classInstance,
  )
  const gradeReport = reportsSelectors.selectGradeReport(state)
  const { sitePreferences } =
    licenseManager?.sites?.find(site => site.id === selectedSite) || {}
  const isMspDigital = !!MSP_GRADES_MAP?.[parseInt(gradeKey)]
  const bullyingHarassmentUnitEnabled =
    sitePreferences?.mspDigital?.bullyingHarassmentUnitEnabled && isMspDigital

  return {
    bullyingHarassmentUnitEnabled,
    classDetailsReport,
    classInstance,
    gradeGraphEnum,
    gradeName,
    gradeReport,
    gradeKey,
    isFetchingGrade,
    selectedGrade,
    selectedSite,
  }
}

export const mapDispatchToProps = {
  fetchK8ReportGrade: reportsOperations.fetchK8ReportGrade,
  fetchK8ReportGradeAndClassDetails:
    reportsOperations.fetchK8ReportGradeAndClassDetails,
  fetchK8ReportClassDetails: reportsOperations.fetchK8ReportClassDetails,
  updateSelectedGrade: reportsOperations.updateSelectedGrade,
}

K8GradeViewContainer.propTypes = {
  bullyingHarassmentUnitEnabled: PropTypes.bool,
  classDetailsReport: PropTypes.object,
  classInstance: PropTypes.string,
  entry: PropTypes.object,
  gradeEmptyDisplay: PropTypes.object,
  gradeGraphEnum: PropTypes.string,
  gradeName: PropTypes.string,
  gradeReport: PropTypes.object,
  isFetchingGrade: PropTypes.bool,
  lastUpdatedAt: PropTypes.string,
  navigationHandler: PropTypes.func,
  selectedGrade: PropTypes.string,
  selectedSite: PropTypes.number,
  updateSelectedGrade: PropTypes.func,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withApiError(K8GradeViewContainer, [ERROR_TYPE_REPORTING]))
