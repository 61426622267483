import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Collapsible } from 'grommet'
import { StyledCollapsibleButton, StyledHeaderThree } from './component.styles'

const CollapsibleGroup = ({ label, children }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      <StyledCollapsibleButton
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
      >
        <StyledHeaderThree>{label}</StyledHeaderThree>
      </StyledCollapsibleButton>
      <Collapsible open={isOpen}>{children}</Collapsible>
    </>
  )
}

CollapsibleGroup.propTypes = {
  children: PropTypes.element,
  label: PropTypes.string.isRequired,
}
export default CollapsibleGroup
