import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { FormInputSelect, HeaderOne, BodyCopy } from 'secondstep-components'

export const ReportsBannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 0 0 0.375rem;

  a {
    color: ${themeGet('colors.links.secondary')};
    font-family: ${themeGet('font.links.secondary')};
    text-decoration: none;
    margin-bottom: 0.938rem;
    margin-top: 0.625rem;
    display: inline-block;
  }

  li {
    list-style-type: none;
  }
`

export const Background = styled.div`
  width: 100%;
  height: calc(100% + 40px);
  position: absolute;
  left: 0;
  top: 0;
  background: ${themeGet('colors.backgrounds.secondary', 'colors.white')};
`

export const ReportsBannerHeader = styled(HeaderOne).attrs(
  ({ dataTestId }) => ({
    'data-testid': dataTestId,
  }),
)`
  margin-bottom: 0.938rem;
  text-align: center;
`

export const ReportsBannerBody = styled(BodyCopy).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin-bottom: 1.5625rem;
  flex-direction: row;
  text-align: center;
  line-height: 1.375rem;
`

export const LicenseSelect = styled(FormInputSelect).attrs(
  ({ dataTestId }) => ({
    'data-testid': dataTestId,
  }),
)`
  margin-bottom: 0.625rem;

  input {
    padding: 0.875rem 1rem 0.75rem;
  }

  svg {
    color: ${themeGet('colors.blue')};
  }
`

export const ReportsBannerInnerWrapper = styled.div`
  width: 100%;
  max-width: 1195px;
  min-height: 100%;
  padding: 0 1.25rem;
  box-sizing: border-box;
  align-text: center;
  margin-bottom: ${({ singleLicense }) => (singleLicense ? '3.5rem' : 'none')};
  z-index: 1;

  a {
    margin-top: 0.9375rem;
    margin-bottom: 1.5625rem;
  }

  ${themeGet('breakpoints.mobile')} {
    padding: 0 1rem;
    margin-bottom: ${({ singleLicense }) =>
      singleLicense ? '1.625rem' : 'none'};
  }
`

export const TextInnerWrapper = styled.div`
  max-width: 688px;
  margin: 0 auto;
  text-align: center;

  ${themeGet('breakpoints.tablet')} {
    max-width: 100%;
    width: 90%;
  }

  ${themeGet('breakpoints.mobile')} {
    width: 100%;
  }
`
