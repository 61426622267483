import React from 'react'
import PropTypes from 'prop-types'
import AdminSiteHeader from 'components/AdminSiteHeader'
import AdminSiteList from 'components/AdminSiteList'
import { MultiSiteWrapper } from './component.styles'

const AdminMultiSiteView = ({
  currentPage,
  getPaginatedSites,
  isFetchingLessons,
  orderBy,
  sites,
  sitesPerPage,
  sitesPreferences,
  sortKey,
  summary,
  totalCount,
  totalPages,
}) => {
  const { etlLastUpdatedAt: lastUpdatedAt } = summary
  const currentSiteCount = sites?.length || 0
  return (
    <MultiSiteWrapper>
      <AdminSiteHeader
        key="admin-site-header"
        {...summary}
        sitesPreferences={sitesPreferences}
      />
      <AdminSiteList
        currentPage={currentPage}
        currentSiteCount={currentSiteCount}
        getPaginatedSites={getPaginatedSites}
        isFetching={isFetchingLessons}
        lastUpdatedAt={lastUpdatedAt}
        monitorView={true}
        orderBy={orderBy}
        sites={sites}
        sitesPerPage={sitesPerPage}
        sitesPreferences={sitesPreferences}
        sortKey={sortKey}
        totalPages={totalPages}
        totalSites={totalCount}
      />
    </MultiSiteWrapper>
  )
}

AdminMultiSiteView.propTypes = {
  currentPage: PropTypes.number,
  getPaginatedSites: PropTypes.func,
  isFetchingLessons: PropTypes.bool,
  orderBy: PropTypes.string,
  sites: PropTypes.array,
  sitesPerPage: PropTypes.number,
  sitesPreferences: PropTypes.object,
  sortKey: PropTypes.string,
  summary: PropTypes.object,
  totalCount: PropTypes.number,
  totalPages: PropTypes.number,
}

export default AdminMultiSiteView
