import React from 'react'
import PropTypes from 'prop-types'
import { toKebabCase } from 'utils/stringHelpers'
import MediaGroup from 'components/Teach/MediaGroup'
import { ContainerSection, StyledHeaderTwo } from './component.styles'

const StreamingLessonMedia = ({ sections = [], locale }) => {
  if (!Array.isArray(sections)) {
    return null
  }

  return (
    <>
      {sections.map(section => {
        const { title, id, mediaList } = section
        const mediaGroupTitleId = title
          ? 'title-' + toKebabCase(title)
          : 'title'
        const mediaGroupHeaderDataTestId =
          'streaming-lesson-media-header-' + mediaGroupTitleId
        const mediaGroupDataTestId =
          'streaming-lesson-media-' + mediaGroupTitleId

        return (
          <React.Fragment key={id}>
            <StyledHeaderTwo dataTestId={mediaGroupHeaderDataTestId}>
              {title}
            </StyledHeaderTwo>
            <ContainerSection dataTestId={mediaGroupDataTestId}>
              <MediaGroup locale={locale} mediaList={mediaList} />
            </ContainerSection>
          </React.Fragment>
        )
      })}
    </>
  )
}

StreamingLessonMedia.propTypes = {
  locale: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      mediaList: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          fileType: PropTypes.string,
          fileUrl: PropTypes.string,
          thumbnailUrl: PropTypes.string,
          tracks: PropTypes.array,
          id: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
      title: PropTypes.string,
    }),
  ),
}

export default StreamingLessonMedia
