import React from 'react'
import PropTypes from 'prop-types'
import { SearchSelect } from 'secondstep-components'
import CSVReportsDropdown from './CSVReportsDropdown'
import {
  Background,
  ContentWrapper,
  GradeDropdownWrapper,
  RightWrapper,
  SiteDropdownWrapper,
  StyledHeaderTwo,
} from './component.styles.js'

const ReportsNavHeader = ({
  classesCount,
  error,
  grades,
  historicalReportingOnClick,
  historicalReports,
  isFetchingDocumentUrl,
  isFetchingHistoricalReportData,
  navigationHandler,
  onClickCurrentYear,
  schoolCsvDownloadTooltip,
  selectedGradeOption,
  selectedSite,
  selectedSiteOption,
  showGradeDropdown,
  sites,
  updateSelectedGrade,
  updateSelectedSite,
}) => {
  const hasOneSite = sites?.length === 1
  const handleGradeChange = e => {
    updateSelectedGrade(e.key)
    navigationHandler({ gradeName: e.key, siteId: selectedSite })
  }

  const handleSiteChange = e => {
    updateSelectedSite(e.key)
    navigationHandler({ siteId: e.key })
  }

  const csvDropdownData = {
    classesCount,
    error,
    historicalReports,
    historicalReportingOnClick,
    isFetchingHistoricalReportData,
    onClickCurrentYear,
    schoolCsvDownloadTooltip,
  }

  return (
    <Background data-testid="reports-nav-header" hasOneSite={hasOneSite}>
      <ContentWrapper hasOneSite={hasOneSite}>
        {hasOneSite && <StyledHeaderTwo>{sites[0].name}</StyledHeaderTwo>}
        <RightWrapper>
          {!hasOneSite && (
            <SiteDropdownWrapper>
              <SearchSelect
                dataTestId="select-site"
                isSearchable
                label="School"
                name="Select School"
                noMatchText="No Schools Found"
                onChange={handleSiteChange}
                options={sites}
                placeholder="Enter School Name"
                selectedOption={selectedSiteOption}
              />
            </SiteDropdownWrapper>
          )}
          {grades.length > 0 && showGradeDropdown && (
            <GradeDropdownWrapper>
              <SearchSelect
                dataTestId="select-grade"
                label="Grade"
                name="Select Grade"
                onChange={handleGradeChange}
                options={grades}
                selectedOption={selectedGradeOption}
              />
            </GradeDropdownWrapper>
          )}
        </RightWrapper>
        <CSVReportsDropdown
          {...csvDropdownData}
          isFetchingDocumentUrl={isFetchingDocumentUrl}
        />
      </ContentWrapper>
    </Background>
  )
}

ReportsNavHeader.propTypes = {
  classesCount: PropTypes.number,
  error: PropTypes.object,
  grades: PropTypes.arrayOf(PropTypes.object),
  historicalReportingOnClick: PropTypes.func,
  historicalReports: PropTypes.array,
  isFetchingDocumentUrl: PropTypes.bool,
  isFetchingHistoricalReportData: PropTypes.bool,
  navigationHandler: PropTypes.func,
  onClickCurrentYear: PropTypes.func,
  schoolCsvDownloadTooltip: PropTypes.string,
  selectedGradeOption: PropTypes.object,
  selectedSite: PropTypes.number,
  selectedSiteOption: PropTypes.object,
  showGradeDropdown: PropTypes.bool,
  sites: PropTypes.arrayOf(PropTypes.object),
  updateSelectedGrade: PropTypes.func,
  updateSelectedSite: PropTypes.func,
}

export default ReportsNavHeader
