import { variables } from 'themes'

export const BANNER_DEFAULT_STRONG_TEXT = 'You are previewing the program.'
export const BANNER_SCROLL_THRESHOLD = 90
export const MARGIN_TOP_DEFAULT = '-6.95rem'
export const MARGIN_TOP_MOBILE = '-6.95rem'
export const MARGIN_TOP_SCROLL = '0'
export const MOBILE_LARGE_BREAKPOINT = parseInt(
  variables.breakpoints.sizes.mobileLarge.replace('px', ''),
)
