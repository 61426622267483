import types from './types'
import { isEqual } from 'lodash'
import {
  findExpiredAdminLicenses,
  findExpiredNonAdminLicenses,
  getExpiredLicensesWithClaims,
} from 'utils/licenseHelpers'

export const initialState = {
  activeDigitalLicenses: null,
  addedCodes: [],
  adminNotificationsExist: false,
  error: null,
  expiredLicenses: [],
  isFetchingActiveDigitalLicenses: false,
  isFetchingSetupLicenses: false,
  isFetchingUserLicenses: false,
  isFetchingUserSites: false,
  isLoading: false,
  isSending: false,
  isValidating: false,
  isValidActivationCode: false,
  licenses: [],
  nonAdminNotificationsExist: false,
  selectedUserLicense: {},
  // TODO: Move sites into the sites reducer
  sites: null,
  unassignedLicenses: null,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.FETCHING_VALIDATE_ACTIVATION_CODE: {
      return {
        ...state,
        error: null,
        isValidating: true,
        isValidActivationCode: false,
      }
    }

    case types.FETCHING_VALIDATE_ACTIVATION_CODE_ERROR: {
      return {
        ...state,
        error: action.error,
        isValidating: false,
        isValidActivationCode: false,
      }
    }

    case types.FETCHING_VALIDATE_ACTIVATION_CODE_SUCCESS: {
      return {
        ...state,
        error: null,
        isValidating: false,
        isValidActivationCode: true,
      }
    }

    case types.FETCHING_SETUP_LICENSES: {
      return {
        ...state,
        error: null,
        isFetchingSetupLicenses: true,
      }
    }

    case types.FETCHING_SETUP_LICENSES_ERROR: {
      return {
        ...state,
        error: action.error,
        isFetchingSetupLicenses: false,
      }
    }

    case types.FETCHING_SETUP_LICENSES_SUCCESS: {
      return {
        ...state,
        error: null,
        isFetchingSetupLicenses: false,
        unassignedLicenses: action.licenseData.unassignedLicenses,
        licenses: action.licenseData.adminLicenses,
      }
    }

    case types.FETCHING_USER_LICENSES: {
      return {
        ...state,
        error: null,
        isFetchingActiveDigitalLicenses: true,
        isFetchingUserLicenses: true,
      }
    }

    case types.FETCHING_USER_LICENSES_ERROR: {
      return {
        ...state,
        error: action.error,
        isFetchingActiveDigitalLicenses: false,
        isFetchingUserLicenses: false,
      }
    }

    case types.FETCHING_USER_LICENSES_SUCCESS: {
      const {
        activeLicenses,
        // adminLicenses, // TODO: implement in LEARN-16297
        // expiredLicenses, // NOTE: used for surfacing notifications, but since claims are on sites in v3, added to store from that action
        unassignedLicenses,
      } = action.licenseData
      return {
        ...state,
        error: null,
        isFetchingActiveDigitalLicenses: false,
        isFetchingUserLicenses: false,
        activeDigitalLicenses: activeLicenses,
        unassignedLicenses,
      }
    }

    case types.FETCHING_USER_SITES: {
      return {
        ...state,
        error: null,
        isFetchingUserSites: true,
      }
    }

    case types.FETCHING_USER_SITES_ERROR: {
      return {
        ...state,
        error: action.error,
        isFetchingUserSites: false,
      }
    }

    case types.FETCHING_USER_SITES_SUCCESS: {
      const { expiredLicenses: persistedExpiredLicenses } = state
      const { siteData: sites } = action

      const apiExpiredLicenses = getExpiredLicensesWithClaims(sites)

      const newLicensesExist = !isEqual(
        apiExpiredLicenses.sort((a, b) => a.id - b.id),
        // redux-persist does NOT guarantee the sort order persists
        persistedExpiredLicenses.sort((a, b) => a.id - b.id),
      )

      let adminNotificationsExist = state.adminNotificationsExist || false
      let nonAdminNotificationsExist = state.nonAdminNotificationsExist || false

      if (newLicensesExist) {
        const adminExpiredLicenses = findExpiredAdminLicenses(
          apiExpiredLicenses,
        )
        const nonAdminExpiredLicenses = findExpiredNonAdminLicenses(
          apiExpiredLicenses,
        )
        if (adminExpiredLicenses.length) {
          adminNotificationsExist = true
        }
        if (nonAdminExpiredLicenses.length) {
          nonAdminNotificationsExist = true
        }
      }

      return {
        ...state,
        adminNotificationsExist,
        error: null,
        isFetchingUserSites: false,
        nonAdminNotificationsExist,
        sites: action.siteData,
        expiredLicenses: newLicensesExist
          ? apiExpiredLicenses
          : persistedExpiredLicenses,
      }
    }

    case types.FETCHING_ACTIVE_DIGITAL_LICENSES: {
      return {
        ...state,
        error: null,
        isFetchingActiveDigitalLicenses: true,
      }
    }

    case types.FETCHING_ACTIVE_DIGITAL_LICENSES_ERROR: {
      return {
        ...state,
        error: action.error,
        isFetchingActiveDigitalLicenses: false,
      }
    }

    case types.FETCHING_ACTIVE_DIGITAL_LICENSES_SUCCESS: {
      const persistedExpiredLicenses = state.expiredLicenses.sort((a, b) => {
        return a.licenseId - b.licenseId
      })
      const apiExpiredLicenses = action.expiredLicenses.sort((a, b) => {
        return a.licenseId - b.licenseId
      })
      const newLicensesExist = !isEqual(
        state.expiredLicenses,
        action.expiredLicenses,
      )
      const expiredLicenses = newLicensesExist
        ? apiExpiredLicenses
        : persistedExpiredLicenses
      const adminLicenses = findExpiredAdminLicenses(expiredLicenses)
      const nonAdminLicenses = findExpiredNonAdminLicenses(expiredLicenses)
      const adminLicensesExist = adminLicenses?.length > 0
      const nonAdminLicensesExist = nonAdminLicenses?.length > 0

      let adminNotificationsExist
      let nonAdminNotificationsExist

      if (!persistedExpiredLicenses) {
        if (adminLicensesExist) {
          adminNotificationsExist = true
        }
        if (nonAdminLicensesExist) {
          nonAdminNotificationsExist = true
        }
      }

      if (persistedExpiredLicenses) {
        if (!newLicensesExist) {
          adminNotificationsExist = state.adminNotificationsExist || false
          nonAdminNotificationsExist = state.nonAdminNotificationsExist || false
        }
        if (newLicensesExist) {
          if (adminLicensesExist) {
            adminNotificationsExist = true
          }
          if (nonAdminLicensesExist) {
            nonAdminNotificationsExist = true
          }
        }
      }

      return {
        ...state,
        error: null,
        activeDigitalLicenses: action.activeDigitalLicenses,
        adminNotificationsExist,
        expiredLicenses,
        isFetchingActiveDigitalLicenses: false,
        nonAdminNotificationsExist,
      }
    }

    case types.SENDING_ACTIVATION_CODE: {
      return {
        ...state,
        error: null,
        isSending: true,
      }
    }

    case types.SENDING_ACTIVATION_CODE_ERROR: {
      return {
        ...state,
        error: action.error,
        isSending: false,
        isValidActivationCode: false,
      }
    }

    case types.SENDING_ACTIVATION_CODE_SUCCESS: {
      return {
        ...state,
        error: null,
        isSending: false,
        isValidActivationCode: false,
        addedCodes: Array.from(
          new Set([...state.addedCodes, action.payload.pakNumber]),
        ),
      }
    }

    case types.SET_NOTIFICATION_AS_READ_SUCCESS: {
      return { ...state, [action.notificationName]: false }
    }

    case types.UPDATE_SITE_PACING_DATES: {
      const {
        siteId,
        pacingDates,
        programName,
        schoolYearEndDateString,
      } = action.payload
      const siteIndex = state.sites.findIndex(site => site.id === siteId)
      if (siteIndex !== -1) {
        const newSites = [...state.sites]
        const updatedSite = {
          ...newSites[siteIndex],
          pacing: {
            [programName]: pacingDates,
          },
          schoolYearEndDate: schoolYearEndDateString,
        }
        newSites[siteIndex] = updatedSite
        return {
          ...state,
          sites: newSites,
        }
      }

      return state
    }

    case types.UPDATE_SITE: {
      const { siteId, sitePreferences } = action.payload

      const sitePreferencesWithoutNulls = Object.entries(
        sitePreferences,
      ).reduce((newObj, [key, value]) => {
        if (value !== null) {
          newObj[key] = value
        }
        return newObj
      }, {})
      const siteIndex = state.sites.findIndex(site => site.id === siteId)
      if (siteIndex !== -1) {
        return {
          ...state,
          sites: state.sites.map((site, index) =>
            index === siteIndex
              ? {
                  ...site,
                  sitePreferences: {
                    ...site.sitePreferences,
                    ...sitePreferencesWithoutNulls,
                  },
                }
              : site,
          ),
        }
      }
      return state
    }

    case types.UPDATE_SITES_MSP_OPT_IN: {
      const mspOptInArr = action.payload
      const newStateSites = state.sites.map(site => {
        const matchingSite = mspOptInArr.find(
          modifiedSite => modifiedSite.siteId === site.id,
        )
        if (matchingSite) {
          return {
            ...site,
            sitePreferences: {
              ...site.sitePreferences,
              mspDigital: {
                ...site.sitePreferences.mspDigital,
                bullyingHarassmentUnitEnabled:
                  matchingSite.sitePreferences.mspDigital
                    .bullyingHarassmentUnitEnabled,
              },
            },
          }
        }
        return site
      })
      return {
        ...state,
        sites: newStateSites,
      }
    }
    case types.RESET_STATE: {
      return { ...initialState }
    }

    default:
      return state
  }
}
