import React from 'react'
import PropTypes from 'prop-types'
import {
  BodyCopy,
  ContainedButton,
  HeaderTwo,
  OutlinedButton,
} from 'secondstep-components'

import { ButtonsWrapper, Wrapper } from './component.styles'
import {
  BODY_COPY,
  COMPLETED_BUTTON_TEXT,
  FORWARD_BUTTON_TEXT,
  HEADER_TEXT,
  PREVIOUS_BUTTON_TEXT,
} from './constants'

const WizardNav = ({
  formIsComplete,
  forwardOnClick,
  isDone,
  previousExists,
  previousOnClick,
}) => {
  const forwardButtonText = formIsComplete
    ? COMPLETED_BUTTON_TEXT
    : FORWARD_BUTTON_TEXT

  if (formIsComplete) isDone = true

  return (
    <Wrapper>
      {formIsComplete && (
        <>
          <HeaderTwo>{HEADER_TEXT}</HeaderTwo>
          <BodyCopy>{BODY_COPY}</BodyCopy>
        </>
      )}
      <ButtonsWrapper>
        {previousExists && (
          <OutlinedButton
            dataTestId="button-wizard-nav-previous"
            onClick={previousOnClick}
          >
            {PREVIOUS_BUTTON_TEXT}
          </OutlinedButton>
        )}
        <ContainedButton
          dataTestId={
            formIsComplete
              ? 'button-wizard-nav-start-training'
              : 'button-wizard-nav-next'
          }
          disabled={!isDone}
          onClick={forwardOnClick}
        >
          {forwardButtonText}
        </ContainedButton>
      </ButtonsWrapper>
    </Wrapper>
  )
}

WizardNav.propTypes = {
  formIsComplete: PropTypes.bool,
  forwardOnClick: PropTypes.func,
  isDone: PropTypes.bool,
  previousExists: PropTypes.bool,
  previousOnClick: PropTypes.func,
}

export default WizardNav
