import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { operations as loadingOperations } from 'store/loadingManager'
import EducatorProfileComponent from './component'

const EducatorProfileContainer = props => {
  const {
    currentSite,
    setAppIsLoadingState,
    setAppNotLoadingState,
    setIsModalLocked,
    isLoadingApp,
  } = props || {}

  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleModalOpen = () => {
    setIsModalOpen(true)
    setIsModalLocked(true)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
    setIsModalLocked(false)
  }
  useEffect(() => {
    if (!currentSite && !isLoadingApp) {
      setAppIsLoadingState()
    }
    if (currentSite && isLoadingApp) {
      setAppNotLoadingState()
    }
  }, [currentSite])
  return (
    <EducatorProfileComponent
      currentSite={currentSite}
      handleCloseModal={handleCloseModal}
      handleModalOpen={handleModalOpen}
      isModalOpen={isModalOpen}
    />
  )
}

EducatorProfileContainer.propTypes = {
  currentSite: PropTypes.object.isRequired,
  setAppIsLoadingState: PropTypes.func,
  setAppNotLoadingState: PropTypes.func,
  setIsModalLocked: PropTypes.func,
}

export const mapStateToProps = ({ loadingManager }) => {
  return {
    isLoadingApp: loadingManager?.isLoading,
  }
}

const mapDispatchToProps = {
  setAppIsLoadingState: loadingOperations.setIsLoadingState,
  setAppNotLoadingState: loadingOperations.setNotLoadingState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(EducatorProfileContainer)
