import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BaseContainer } from 'secondstep-components'
import TextLinkWithFileIcon from 'components/TextLinkWithFileIcon'

export const Header2 = styled.h2`
  color: ${themeGet('colors.secondaryHeaderBodyItem.bodyText')};
  font-family: ${themeGet('fonts.secondaryHeaderSemiBold')};
  font-size: 1.4375rem;
`

export const Paragraph = styled.p`
  color: ${themeGet('colors.secondaryHeaderBodyItem.bodyText')};
  margin-top: 0.5rem;
`

export const Sprite = styled.img`
  position: absolute;
  right: 5%;
  ${themeGet('breakpoints.xlarge')} {
    right: 25%;
  }
  @media screen and (max-width: 1900px) {
    right: 20%;
  }
  @media screen and (max-width: 1650px) {
    right: 10%;
  }
  ${themeGet('breakpoints.large')} {
    right: 8%;
  }
  @media screen and (max-width: 1000px) {
    right: 6%;
  }
  width: 17rem;
  height: auto;
  margin-top: -13.05rem;
  ${themeGet('breakpoints.tablet')} {
    display: none;
  }
`

export const StyledContainer = styled(BaseContainer).attrs(() => ({
  as: 'section',
}))`
  margin-top: -4rem;
  margin-left: auto;
  margin-right: auto;
  ${themeGet('breakpoints.large')} {
    margin-left: 20px;
    margin-right: 20px;
  }
  padding: 1.1rem 1.25rem;
  max-width: 85.375rem;
`

export const StyledList = styled.ul`
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
  ${themeGet('breakpoints.tablet')} {
    columns: 1;
    -webkit-columns: 1;
    -moz-columns: 1;
  }
  ${themeGet('breakpoints.mobile')} {
    width: 20rem;
  }

  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 1rem;
  width: 48.5rem;
`

export const StyledListItem = styled.li`
  margin-bottom: 0.5rem;
  display: inline-table;
  ${themeGet('breakpoints.tablet')} {
    display: block;
  }
  a:hover {
    color: ${themeGet('colors.links.hover')};
  }
`

export const StyledTextLinkWithFileIcon = styled(TextLinkWithFileIcon)`
  position: relative;
  svg {
    position: absolute;
    min-width: 1.125rem;
    margin-top: 0;
    padding-top: 0;
    top: 0;
  }
  span {
    margin-left: 1.5rem;
    ${themeGet('breakpoints.mobileLarge')} {
      max-width: 19rem;
    }
    ${themeGet('breakpoints.mobile')} {
      max-width: 15rem;
    }
  }
`
