import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useAuth0 } from '@auth0/auth0-react'
import { useIdleTimer } from 'react-idle-timer'
import SessionTimeoutPrompt from '../SessionTimeoutPrompt'
import { shouldEnableTimer } from './data'

function TimeoutHandler(props) {
  const { logout, user } = useAuth0()
  const [isApproachingTimeout, setIsApproachingTimeout] = useState(false)

  const handleOnIdle = event => {
    message('refresh')
    logout()
  }

  const handleOnPrompt = event => {
    setIsApproachingTimeout(true)
  }

  const handleOnMessage = message => {
    if (message === 'resetPrompt') setIsApproachingTimeout(false)
    if (message === 'refresh') location.reload()
  }

  const handleContinueSession = () => {
    setIsApproachingTimeout(false)
    reset()
    start()
    message('resetPrompt')
  }

  const { reset, start, message } = useIdleTimer({
    timeout: 1000 * 60 * 29,
    promptTimeout: 1000 * 60 * 1,
    onIdle: handleOnIdle,
    onPrompt: handleOnPrompt,
    onMessage: handleOnMessage,
    debounce: 500,
    crossTab: true,
    startManually: true,
    startOnMount: false,
    syncTimers: 500,
  })

  if (shouldEnableTimer(user)) {
    start()
  }

  return (
    <>
      {isApproachingTimeout && (
        <SessionTimeoutPrompt
          onContinueSession={handleContinueSession}
          onLogout={logout}
        />
      )}
    </>
  )
}

TimeoutHandler.propTypes = {
  auth0: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
}

export default TimeoutHandler
