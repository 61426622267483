import React from 'react'
import PropTypes from 'prop-types'
import Microlearning from '../Microlearning'
import { COPY_TEXT } from './constants'
import { ChoiceWrapper, CopyWrapper } from './component.styles'

const MicrolearningChoice = ({ microlearnings, nextMicrolearningId }) => {
  const hasLeaderMeeting = microlearnings.length > 1

  return (
    <ChoiceWrapper className="microlearning-choice-wrapper">
      {hasLeaderMeeting && (
        <CopyWrapper>
          <p>{COPY_TEXT}</p>
        </CopyWrapper>
      )}
      {microlearnings.map(microlearning => {
        const isActive = microlearning?.id === nextMicrolearningId
        const isLeader = microlearning?.microlearningType?.includes('leader')

        return (
          <Microlearning
            displayTitle={microlearning.displayTitle}
            hasLeaderMeeting={hasLeaderMeeting}
            instance={microlearning.instance}
            isActive={isActive}
            isChoice={true}
            isDone={microlearning.is_done}
            isLeader={isLeader}
            key={microlearning.sco}
            label={microlearning.label}
            microlearningType={microlearning.microlearningType}
            sco={microlearning.sco}
            time={microlearning.time}
          />
        )
      })}
    </ChoiceWrapper>
  )
}

MicrolearningChoice.propTypes = {
  microlearnings: PropTypes.array,
  nextMicrolearningId: PropTypes.bool,
}

export default MicrolearningChoice
