import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import {
  BodyCopy,
  CollapsibleButton as BaseCollapsibleButton,
  HeaderFive,
} from 'secondstep-components'

export const Container = styled(Box)`
  border-radius: 0.625rem;
`

export const CollapsibleButton = styled(BaseCollapsibleButton).attrs(
  ({ isOpen }) => ({
    borderBottomRadius: isOpen ? '0' : '0.625rem',
  }),
)`
  background-color: ${themeGet('colors.paleGray')};
  padding: 1.25rem 1.125rem;
  border-radius: 0.625rem;
  border-bottom-left-radius: ${({ borderBottomRadius }) => borderBottomRadius};
  border-bottom-right-radius: ${({ borderBottomRadius }) => borderBottomRadius};
  color: ${themeGet('colors.darkBlue')};
  justify-content: flex-start;
  transition: all 0.2s ease;
  svg {
    margin-bottom: 0;
  }
  ${HeaderFive} {
    color: ${themeGet('colors.darkBlue')};
    font-size: 1rem;
    margin-bottom: 0;
  }
  &:hover {
    color: ${themeGet('colors.orange')};
    ${HeaderFive} {
      color: ${themeGet('colors.orange')};
    }
  }
`

export const BottomBox = styled(Box)``

export const CollapsibleContainer = styled(Box)`
  padding: 0 1rem;
  text-align: left;
  background-color: ${themeGet('colors.paleGray')};
  border: 1.5px solid ${themeGet('colors.paleGray')};
  border-bottom-left-radius: 0.625rem;
  border-bottom-right-radius: 0.625rem;

  ${BodyCopy} {
    max-width: 18.75rem;
    margin-bottom: 0.25rem;
  }

  @media screen and (max-width: 1315px) {
    ${BodyCopy} {
      max-width: 100%;
    }
  }

  [class*='FormFieldStyled'] {
    margin-bottom: 0rem;
  }
`
