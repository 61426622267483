import React from 'react'
import PropTypes from 'prop-types'
import {
  AlertSection,
  AlertText,
  ApiErrorSection,
  Button,
  DefaultValue,
  ErrorHandlingText,
  Footer,
  FooterText,
  StyledErrorIcon,
  StyledMarkdown,
  Subtitle,
  Title,
  WarningIconStyled,
} from './component.styles'
import { SelectOption } from 'secondstep-components'
import ExpandableCheckSection, {
  StyledFormInputNumber,
  StyledFormInputSelect,
} from 'components/ExpandableCheckSection'

const UserProgramProfileModal = ({
  errors,
  handleSaveClick,
  hasApiError,
  hasStudentActivities,
  isAdmin,
  isEducatorValid,
  isValid,
  modalContent,
  onTeachesEducatorActivitiesChange,
  onPeriodChange,
  onClassChange,
  onTeachesSchoolwideActivitiesChange,
  onTeachesStudentActivitiesChange,
  showAlertSection,
  touched,
  values,
}) => {
  const {
    bodyCopy: subtitle,
    choiceContent: content,
    header: title,
    helpCopy: footerText,
    nextLabel: buttonText,
  } = modalContent || {}
  const schoolwideContent = content?.[0]
  const educatorContent = [content?.[1], content?.[2], content?.[3]]
  const educatorContentChoices = educatorContent?.[2]?.answerChoices.map(
    option => option.answerText,
  )
  const studentContent = content?.[4]
  const alertContent = isAdmin ? content?.[5]?.bodyCopy : content?.[6]?.bodyCopy
  return (
    <div key="user-program-profile-component">
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <section>
        {isAdmin && (
          <ExpandableCheckSection
            description={schoolwideContent?.bodyCopy}
            isChecked={values.teachesSchoolwideActivities}
            onChange={onTeachesSchoolwideActivitiesChange}
            title={schoolwideContent?.h3}
          />
        )}
        <ExpandableCheckSection
          description={educatorContent?.[0]?.bodyCopy}
          isChecked={values.teachesEducatorActivities}
          onChange={onTeachesEducatorActivitiesChange}
          title={educatorContent?.[0]?.h3}
        >
          <StyledFormInputNumber
            dataTestId="user-program-profile-periods"
            hasError={errors.periods && touched.periods}
            inputErrors={
              errors.periods && touched.periods ? errors.periods : ''
            }
            label={educatorContent?.[1]?.questionText}
            onChange={onPeriodChange}
            placeholder={'Enter a Number'}
            value={values.periods}
          />
          {errors.periods && touched.periods && (
            <ErrorHandlingText>
              <StyledErrorIcon />
              <span>{errors.periods}</span>
            </ErrorHandlingText>
          )}
          <StyledFormInputSelect
            dataTestId="user-program-profile-department"
            defaultValue={values.className || null}
            dropHeight={'small'}
            inline={true}
            label={educatorContent?.[2]?.questionText}
            onChange={onClassChange}
            options={educatorContentChoices?.map(choice => (
              <SelectOption key={choice} value={values.className}>
                {choice}
              </SelectOption>
            ))}
            value={values.className}
            valueLabel={
              <SelectOption>
                {values.className || (
                  <DefaultValue>Select from below</DefaultValue>
                )}
              </SelectOption>
            }
          />
        </ExpandableCheckSection>
        {hasStudentActivities && (
          <ExpandableCheckSection
            description={studentContent?.bodyCopy}
            isChecked={values.teachesStudentActivities}
            onChange={onTeachesStudentActivitiesChange}
            title={studentContent?.h3}
          />
        )}
      </section>
      {showAlertSection && (
        <AlertSection>
          <WarningIconStyled />
          <AlertText>{alertContent}</AlertText>
        </AlertSection>
      )}
      <Footer>
        <FooterText>
          <StyledMarkdown>{footerText}</StyledMarkdown>
        </FooterText>
        <Button
          disabled={!isValid && !isEducatorValid}
          onClick={handleSaveClick}
        >
          {buttonText}
        </Button>
      </Footer>
      {hasApiError && (
        <ApiErrorSection>
          <StyledErrorIcon />
          Something went wrong and we were unable to save. Please try again.
        </ApiErrorSection>
      )}
    </div>
  )
}

UserProgramProfileModal.propTypes = {
  errors: PropTypes.object,
  handleSaveClick: PropTypes.func,
  hasApiError: PropTypes.bool,
  hasStudentActivities: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isEducatorValid: PropTypes.bool,
  isValid: PropTypes.bool,
  modalContent: PropTypes.object,
  onClassChange: PropTypes.func,
  onPeriodChange: PropTypes.func,
  onTeachesEducatorActivitiesChange: PropTypes.func,
  onTeachesSchoolwideActivitiesChange: PropTypes.func,
  onTeachesStudentActivitiesChange: PropTypes.func,
  showAlertSection: PropTypes.bool,
  touched: PropTypes.object,
  values: PropTypes.object,
}

export default UserProgramProfileModal
