import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BaseContainer, Hairline, RightArrowIcon } from 'secondstep-components'

export const ArrowIconWrapper = styled.div`
  margin-left: 0.5rem;
  margin-top: -3rem;
  display: inline-block;
  position: relative;
  transform: translateY(-0.15rem);
`

export const LessonProgress = styled.div`
  margin: 1rem 1.125rem 0.875rem 1.125rem;
  svg {
    width: 16px;
    height: 16px;
    float: left;
    margin-top: 0.1rem;
  }
  p {
    margin: 0rem;
    margin-left: -1rem;
    width: 90%;
    float: left;
    text-indent: 1.5rem;
    color: ${themeGet('colors.darkGray')};
  }
  b {
    font-family: ${themeGet('fontFamilySemibold')};
    font-weight: unset;
  }
`

export const Spacer = styled.div`
  width: 100%;
  height: 2.325rem;
`

export const Subtitle = styled.p`
  margin-top: 0rem;
  margin-left: 1.25rem;
  margin-right: 1rem;
  margin-bottom: 0.75rem;
  color: ${themeGet('colors.darkGray')};
`

export const StyledContainer = styled(BaseContainer)`
  border-radius: 0.75rem;
  border: 0.15rem solid ${themeGet('colors.borders.reporting')};
  justify-content: center;
  width: 100%;
  min-width: 19rem;
  height: 100%;
  padding: 0rem;
  float: left;
  margin: 0rem;

  &:focus,
  &:hover {
    border: 0.15rem solid ${themeGet('colors.blueGray')};
    background-color: ${themeGet('colors.lightBlueGray')};
    outline: none;
  }

  ${themeGet('breakpoints.xlarge')} {
    width: 19.8125rem;
  }
`

export const StyledHairline = styled(Hairline)`
  height: 2px;
  background-color: ${themeGet('colors.borders.reporting')};
`

export const StyledLink = styled.a`
  color: black;
  cursor: pointer;
`

export const StyledRightArrowIcon = styled(RightArrowIcon)`
  color: ${themeGet('colors.blueGray')};
`

export const Title = styled.h2`
  color: ${themeGet('colors.darkBlue')};
  margin-top: 0.875rem;
  margin-left: 1.1875rem;
  margin-right: 1rem;
  font-family: ${themeGet('heading.level.2.font.family')};
  font-weight: ${themeGet('heading.level.2.font.weight')};
`
