import styledNative from 'styled-components/native'
import styled, { withTheme } from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, HeaderOne } from 'secondstep-components'

export const ContentBodyCopy = styled(BodyCopy)`
  font-size: 1.125rem;
  padding-bottom: 0.875rem;
`

export const ContentHeaderOne = styled(HeaderOne)`
  margin-bottom: 0.375rem;
`

export const ContentOverviewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};

  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
    align-items: center;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 70%;
  max-width: 70%;

  ${themeGet('breakpoints.mobileLarge')} {
    flex-basis: 100%;
    max-width: 100%;

    h1,
    span {
      text-align: center;
    }
  }
`

export const VideoWrapper = withTheme(styledNative.View`
  padding-top: 0.313rem;
  flex-basis: 30%;
`)
