import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, HeaderOne } from 'secondstep-components'

const below735 = '@media (max-width: 735px)'

export const Wrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  border-radius: 0.625rem;
  background-color: ${themeGet('colors.modules.primaryBackground')};
  z-index: 1;

  ${below735} {
    flex-direction: column;
  }
`

export const LeftBox = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin-top: calc(${themeGet('spacing.red40_2_5rem')} - 0.625rem);
  margin-right: ${themeGet('spacing.red40_2_5rem')};
  margin-bottom: ${themeGet('spacing.red40_2_5rem')};
  margin-left: calc(${themeGet('spacing.red40_2_5rem')} - 0.0625rem);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 59.375rem;

  ${below735} {
    margin: 0;
  }
`

export const RightBox = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: column;
  margin: ${themeGet('spacing.red40_2_5rem')};
  margin-left: 0;
  min-width: 21.875rem;
  max-width: calc((100% - 2 * ${themeGet('spacing.red40_2_5rem')}) / 3);

  ${below735} {
    margin: ${themeGet('spacing.turquoise20_1_25rem')};
    margin-top: ${themeGet('spacing.amber28_1_75rem')};
    max-width: none;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    min-width: auto;
  }
`

export const LeftTop = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${below735} {
    padding: 0 ${themeGet('spacing.turquoise20_1_25rem')};
    padding-top: calc(${themeGet('spacing.amber28_1_75rem')} - 0.625rem);
    width: calc(100% - ${themeGet('spacing.red40_2_5rem')});
  }
`

export const LeftBottom = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  width: 100%;

  .progress-bar {
    margin-top: ${themeGet('spacing.amber28_1_75rem')};
    padding: 0;
    width: 100%;
  }

  .progress-bar-text {
    font-size: 1.125rem;
  }

  .progress-bar-background {
    height: 1.0625rem;
    width: 100%;
    border-radius: 0.7813rem;
  }

  .progress-bar-indicator {
    height: 1.0625rem;
    border-radius: 0.7813rem;
  }

  ${themeGet('breakpoints.tablet')} {
    .progress-bar {
      margin-top: ${themeGet('spacing.amber28_1_75rem')};
    }
  }

  ${below735} {
    padding: 0 ${themeGet('spacing.turquoise20_1_25rem')};
    width: calc(100% - ${themeGet('spacing.red40_2_5rem')});

    .progress-bar {
      margin-top: ${themeGet('spacing.blue16_1rem')};
    }
  }
`

export const LabelBox = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  font-family: ${themeGet('fonts.label')};
  font-size: 1.125rem;
  line-height: 2.1875rem;
`

export const Title = styled(HeaderOne).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin: 0;
  margin-top: calc(${themeGet('spacing.red40_2_5rem')} - 1.25rem);
  margin-left: -0.0625rem;
  padding: 0;
  line-height: ${themeGet('heading.level.1.medium.height')};

  ${below735} {
    line-height: ${themeGet('heading.level.1.mobile.height')};
    margin-top: calc(${themeGet('spacing.amber28_1_75rem')} - 1.125rem);
  }
`

export const Description = styled(BodyCopy).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: inline-block;
  margin: 0;
  margin-top: calc(${themeGet('spacing.turquoise20_1_25rem')} - 1rem);
  padding: 0;
  color: ${themeGet('colors.primaryCopy')};
  font-family: ${themeGet('fonts.bodyCopy')};
  line-height: ${themeGet('bodyCopy.medium.height')};
  font-size: ${themeGet('bodyCopy.medium.size')};

  ${below735} {
    margin-top: calc(${themeGet('spacing.turquoise20_1_25rem')} - 0.875rem);
    line-height: ${themeGet('bodyCopy.mobile.height')};
    font-size: ${themeGet('bodyCopy.mobile.size')};
  }
`
