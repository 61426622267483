import axios from 'axios'
import { domains } from 'config/env'

const baseApiAddress = `${domains.ROUTE_TREE_FUNC_APP}/`

export function makeClient(baseApiAddress) {
  const _api = axios.create({
    baseURL: baseApiAddress,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
  })

  return {
    baseApiAddress,

    getResourceEntryIdBySlug: async slug => {
      return _api.get(`GetResourceEntryIdBySlug?slug=${slug}`)
    },
  }
}

const api = makeClient(baseApiAddress)

export default api
