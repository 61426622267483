import React from 'react'
import PropTypes from 'prop-types'
import { toUrlSafe } from 'utils/stringHelpers'
import { TabButton, TabSet } from 'secondstep-components'
import { TabSetWrapper } from './component.styles'

const UnitTabSet = ({ onSelect, paramsContentfulId, units, ariaControls }) => {
  return (
    <TabSetWrapper>
      <TabSet dataTestId="unit-tabset">
        {units.map((unit, i) => {
          const { displayTitle, id } = unit
          const unitId = toUrlSafe(id)
          const unitNumber = i + 1

          const isActive = unitId === toUrlSafe(paramsContentfulId)
          return (
            <TabButton
              ariaControls={ariaControls}
              dataTestId={`unit-tab-${unitNumber}`}
              isActive={isActive}
              key={unitId}
              label={`Unit ${unitNumber}`}
              onClick={() => onSelect(displayTitle, unitId, unitNumber)}
            />
          )
        })}
      </TabSet>
    </TabSetWrapper>
  )
}

UnitTabSet.propTypes = {
  ariaControls: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  paramsContentfulId: PropTypes.string.isRequired,
  units: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default UnitTabSet
