import styled from 'styled-components'
import { themeGet } from 'styled-system'
import ReportsLoading from 'components/ReportsLoading'

export const DrawerContent = styled.div.attrs(({ fixedTop }) => ({
  paddingTop: fixedTop ? '10.6875rem' : '0',
}))`
  padding-top: ${({ paddingTop }) => paddingTop};
`

export const LessonTableWrapper = styled.div`
  padding: 0 1.25rem;
  margin-bottom: 2.5rem;

  ${themeGet('breakpoints.mobileLarge')} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

export const StyledReportsLoading = styled(ReportsLoading)`
  justify-content: flex-start;
  align-items: flex-start;
`
