import { actions } from './reducer'
import api from 'lib/api/docServiceApi'
import { formatError } from 'utils/formatError'
import { isFlagOn } from 'utils/featureFlags'

const clearDocumentUrl = () => {
  return async dispatch => {
    dispatch(actions.clearDocumentUrl())
  }
}

const clearErrors = () => {
  return async dispatch => {
    dispatch(actions.clearErrors())
  }
}

const fetchDocumentUrl = args => {
  return async dispatch => {
    dispatch(actions.fetchingDocumentUrl())
    try {
      const useFlexTemplate = isFlagOn([
        'feature_learn-17857_doc-svc-k8-flex-export',
      ])
      const useHsFullTemplate = isFlagOn([
        'feature_learn-18731_doc-svc-hs-full-export',
      ])
      const options = {
        useFlexTemplate,
        useHsFullTemplate,
      }
      const response = await api.fetchDocumentUrl({ ...args, options })
      dispatch(actions.fetchingDocumentUrlSuccess(response))
    } catch (error) {
      dispatch(actions.fetchingDocumentUrlError(formatError(error)))
    }
  }
}

export default {
  clearDocumentUrl,
  clearErrors,
  fetchDocumentUrl,
}
