import React from 'react'
import PropTypes from 'prop-types'
import { useAuth0 } from '@auth0/auth0-react'
import { AuthZeroErrorMessage, SecondStepLogo } from 'secondstep-components'
import { domains } from 'secondstep-learn/src/config/env'
import {
  CopyrightIcon,
  ErrorDetail,
  ErrorContainer,
  ErrorHeader,
  ErrorMessageStyled,
  Footer,
  FooterLink,
  FooterLinks,
  FooterLogo,
  FooterTerms,
  FooterText,
  HelpContainer,
  HelpList,
  HelpListItem,
  HelpMessage,
  HelpText,
  Hr,
  LogoutLink,
  SecondStepLogoLink,
  UnableToAuthenticateContainer,
  UnauthenticatedErrorPage,
  UnauthorizedErrorPage,
} from './component.styles'
import {
  ABOUT_US,
  CFC_NAME,
  CURRENT_YEAR,
  HELP,
  LOG_OUT,
  PRIVACY_POLICY,
  SS_MESSAGE,
  START_YEAR,
  TERMS_OF_USE,
  VERIFICATION_NEEDED_HEADER,
  VERIFICATION_NEEDED_MESSAGE,
  VERIFICATION_HELP_HEADER,
  VERIFICATION_HELP_ITEMS,
} from './constants'

function Auth0ErrorHandler(props) {
  const { children } = props
  const { error, logout } = useAuth0()
  const isUnauthorized = error?.error === 'access_denied'

  const ErrorPage = isUnauthorized
    ? UnauthorizedErrorPage
    : UnauthenticatedErrorPage

  return (
    <>
      {error ? (
        <ErrorPage>
          <SecondStepLogoLink href="https://www.secondstep.org/">
            <SecondStepLogo />
          </SecondStepLogoLink>
          {!isUnauthorized && (
            <UnableToAuthenticateContainer>
              <AuthZeroErrorMessage
                error={error}
                logOut={() => logout({ returnTo: window.location.origin })}
              />
            </UnableToAuthenticateContainer>
          )}
          {isUnauthorized && (
            <>
              <ErrorContainer>
                <ErrorHeader data-testid="auth-error-header">
                  {VERIFICATION_NEEDED_HEADER}
                </ErrorHeader>
                <Hr />
                <ErrorMessageStyled>
                  {VERIFICATION_NEEDED_MESSAGE}
                  <ErrorDetail>{error?.message}</ErrorDetail>
                </ErrorMessageStyled>
                <HelpContainer>
                  <HelpMessage>{VERIFICATION_HELP_HEADER}</HelpMessage>
                  <HelpList>
                    {VERIFICATION_HELP_ITEMS.map((helpItem, index) => (
                      <HelpListItem key={index}>
                        <HelpText>{helpItem}</HelpText>
                      </HelpListItem>
                    ))}
                  </HelpList>
                </HelpContainer>
                <LogoutLink
                  onClick={() =>
                    logout({ returnTo: domains.PUBLIC_SITE_DOMAIN })
                  }
                >
                  {LOG_OUT}
                </LogoutLink>
              </ErrorContainer>

              <Footer>
                <FooterTerms>
                  <FooterText>
                    <span>
                      <CopyrightIcon>©</CopyrightIcon>
                      {START_YEAR}&ndash;{CURRENT_YEAR} {CFC_NAME}
                    </span>
                  </FooterText>
                  <FooterText>
                    <span>{SS_MESSAGE}</span>
                  </FooterText>
                  <FooterLinks>
                    <FooterLink href="https://www.secondstep.org/about-us/">
                      {ABOUT_US}
                    </FooterLink>
                    |
                    <FooterLink href="https://support.secondstep.org/hc/en-us/">
                      {HELP}
                    </FooterLink>
                    |
                    <FooterLink href="https://www.secondstep.org/privacy-policy/">
                      {PRIVACY_POLICY}
                    </FooterLink>
                    |
                    <FooterLink href="//www.secondstep.org/terms-of-use/">
                      {TERMS_OF_USE}
                    </FooterLink>
                  </FooterLinks>
                </FooterTerms>
                <FooterLogo
                  alt="Committee for Children logo"
                  src="https://cdn.secondstep.org/static/img/login/cfc-logo.svg"
                />
              </Footer>
            </>
          )}
        </ErrorPage>
      ) : (
        <div>{children}</div>
      )}
    </>
  )
}

Auth0ErrorHandler.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
}

export default Auth0ErrorHandler
