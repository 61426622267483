import ContentDataEntryManager from '../Hocs/dataManagers/contentDataEntryManager'
import dayjs from 'dayjs'
import ContenfulTypes from './types'
import ContentfulReduxHelper from './helpers'
import ParallelArray from 'utils/parallelArray'

class ContentfulReducer {
  static #ROOT_NAMESPACE = 'contentful'
  static get ROOT_NAMESPACE() {
    return this.#ROOT_NAMESPACE
  }

  static #CONTENT_NAMESPACE = 'contentNamespace'
  static get CONTENT_NAMESPACE() {
    return this.#CONTENT_NAMESPACE
  }

  static #CONTENT_NODES = 'contentNodes'
  static get CONTENT_NODES() {
    return this.#CONTENT_NODES
  }

  static #CONTENTFUL_LIVE_QUEUE = 'contentLiveQueue'
  static get CONTENTFUL_LIVE_QUEUE() {
    return this.#CONTENTFUL_LIVE_QUEUE
  }

  static #DASHBOARD_ENTRY_ID = '6trL7mXs2rPPMT2PXE0zE7'
  static get DASHBOARD_ENTRY_ID() {
    return this.#DASHBOARD_ENTRY_ID
  }

  static #CONTENTFUL_LIVE_QUEUE_MAX_TOTAL_SIZE_BYTES = 10e6 // 10MB
  static get CONTENTFUL_LIVE_QUEUE_MAX_TOTAL_SIZE_BYTES() {
    return this.#CONTENTFUL_LIVE_QUEUE_MAX_TOTAL_SIZE_BYTES
  }

  static #contentNodeDefault = {
    last: undefined,
    first: undefined,
    current: undefined,
    nodes: {},
    startTime: undefined,
    startPath: undefined,
  }
  static get contentNodeDefault() {
    return this.#contentNodeDefault
  }
  static #NO_UPDATE_STATUS_CODE = 304
  static get NO_UPDATE_STATUS_CODE() {
    return this.#NO_UPDATE_STATUS_CODE
  }
  static #DEFAULT_NODES_FAILURE_MESSAGE = 'Content nodes default failure'
  static get DEFAULT_NODES_FAILURE_MESSAGE() {
    return this.#DEFAULT_NODES_FAILURE_MESSAGE
  }
  static #initialState = {
    [this.CONTENT_NAMESPACE]: {},
    [this.CONTENT_NODES]: this.contentNodeDefault,
    [this.CONTENTFUL_LIVE_QUEUE]: new ParallelArray(),
  }
  static get initialState() {
    return this.#initialState
  }

  static getContentNodesFromState(state) {
    const { [this.ROOT_NAMESPACE]: contentful } = state || {}
    const { [this.CONTENT_NODES]: contentNodes } = contentful || {}
    return contentNodes
  }

  static shouldExcludeFromQueue(key) {
    // we always want the dashboard entry retained
    return key.includes(this.DASHBOARD_ENTRY_ID)
  }

  static updateContentfulLiveQueue(key, state, payload) {
    let entryQueue = state[this.CONTENTFUL_LIVE_QUEUE]

    if (!entryQueue || this.shouldExcludeFromQueue(key)) {
      return state
    }

    entryQueue = entryQueue.clone()
    let payloadSize
    if (entryQueue.includes(key)) {
      // ensure we only have one copy of the key in the queue
      // we only need the result of the payloadSize: [1], but this splice also returns the key
      payloadSize = entryQueue.spliceAtPrimaryKey(key)[1]
    }
    // ensure it's the last entry (so it gets ejected the latest)
    if (!payloadSize) {
      payloadSize = ContentfulReduxHelper.getRoughSizeOfObject(payload)
    }
    entryQueue.push(key, payloadSize)
    let keyToEject
    let entrySizeSum = entryQueue.secondary.reduce((a, b) => a + b, 0)
    const stateUpdate = { ...state }
    while (entrySizeSum > this.CONTENTFUL_LIVE_QUEUE_MAX_TOTAL_SIZE_BYTES) {
      let sizeToSubtract
      ;[keyToEject, sizeToSubtract] = entryQueue.shift()
      entrySizeSum -= sizeToSubtract
      stateUpdate[keyToEject] = undefined
    }
    return {
      ...stateUpdate,
      [this.CONTENTFUL_LIVE_QUEUE]: entryQueue,
    }
  }

  static createNamespace = (key, payload, state) => {
    let updatedState = state
    // before we add a new Contentful key and payload
    //  first check the queue to remove an entry:
    if (payload.payload) {
      // we need the result fromt Contentful before we can update the queue
      updatedState = this.updateContentfulLiveQueue(key, state, payload)
    }
    return {
      ...updatedState,
      [key]: payload,
    }
  }

  static optimisticResponse = (state, key, isRefetching = false) => {
    const entry = state[key]
    const { payload } = entry || {}
    const isFetching = !payload
    return this.createNamespace(
      key,
      {
        isFetching,
        isRefetching: isRefetching || isFetching,
        payload,
      },
      state,
    )
  }

  static optimisticResponseError = ({
    error,
    state,
    key,
    status,
    isRefetching = false,
  }) => {
    return this.createNamespace(
      key,
      {
        isFetching: false,
        isRefetching,
        payload: {},
        error,
      },
      state,
    )
  }

  static entryReducer = (state = this.initialState, action = {}) => {
    const { entryId, locale, include } = action.meta || {}
    const key = ContentDataEntryManager.createEntryKey(entryId, locale, include)
    switch (action.type) {
      case ContenfulTypes.CONTENTFUL_REQUEST: {
        return this.optimisticResponse(state, key)
      }
      case ContenfulTypes.CONTENTFUL_SUCCESS: {
        const { status } = action.payload || {}
        if (status === this.NO_UPDATE_STATUS_CODE) {
          return this.optimisticResponse(state, key)
        }
        return this.createNamespace(
          key,
          {
            isFetching: false,
            isRefetching: false,
            payload: action.payload,
          },
          state,
        )
      }
      case ContenfulTypes.CONTENTFUL_FAILURE: {
        const { payload, error: actionError } = action || {}
        const { status } = payload || {}

        const error = payload || actionError

        return this.optimisticResponseError({ error, state, key, status })
      }

      case ContenfulTypes.SET_CONTENT_NAMESPACE: {
        let updatedNamespaces = state[this.CONTENT_NAMESPACE]
        if (action.entryIdNamespace) {
          updatedNamespaces[action.entryIdNamespace] = action.entryId
        }
        return {
          ...state,
          [this.CONTENT_NAMESPACE]: updatedNamespaces,
        }
      }
      case ContenfulTypes.RESET_CONTENT_NODES: {
        return {
          ...state,
          [this.CONTENT_NODES]: this.contentNodeDefault,
        }
      }

      case ContenfulTypes.START_CONTENT_NODES: {
        const currentNodes = this.getContentNodesFromState(state)
        const { startPath, startTime } = currentNodes || {}
        const { startPath: newStartPath, startTime: newStartTime } =
          action.startOptions || {}
        if (startTime) {
          if (dayjs(newStartTime).isBefore(startTime)) {
            return state
          }
        }
        if (startPath) {
          if (startPath === newStartPath) {
            return state
          }
        }
        let resetContentNodes = {
          first: action.node,
          current: action.node,
          last: undefined,
          nodes: {},
          startPath: newStartPath,
          startTime: newStartTime,
        }
        if (action.node && action.node.entryId) {
          resetContentNodes['nodes'][action.node.entryId] = action.node
          resetContentNodes['startTime'] = newStartTime
          resetContentNodes['startPath'] = newStartPath
          resetContentNodes['nodesSystemError'] = undefined
        }
        if (action.lastNode) {
          resetContentNodes['last'] = action.node
        }
        return {
          ...state,
          [this.CONTENT_NODES]: resetContentNodes,
        }
      }

      case ContenfulTypes.UPDATE_CONTENT_NODES_ERROR: {
        let updatedContentNodes = {
          ...state[this.CONTENT_NODES],
        }
        if (action.node && action.node.entryId) {
          updatedContentNodes['nodes'][action.node.entryId] = action.node
        }

        updatedContentNodes['current'] = action.node
        updatedContentNodes['last'] = action.node

        return {
          ...state,
          [this.CONTENT_NODES]: updatedContentNodes,
        }
      }

      case ContenfulTypes.UPDATE_CONTENT_NODES: {
        /* nodes are the following format
          {
            id: '1234'
            contentType: 'article'
            parentId: '4567',
            nextNodesField: 'metadata',
            nextNodesType: 'array',
            inputsDepth: 3,
            routeSlug:
            pathLevel
          }
         */
        let updatedContentNodes = {
          ...state[this.CONTENT_NODES],
        }
        if (action.node && action.node.entryId) {
          updatedContentNodes['nodes'][action.node.entryId] = action.node
        }
        updatedContentNodes['current'] = action.node
        if (action.lastNode) {
          updatedContentNodes['last'] = action.node
        }
        return {
          ...state,
          [this.CONTENT_NODES]: updatedContentNodes,
        }
      }

      case ContenfulTypes.UPDATE_CONTENT_NODES_ERROR_SYSTEM_MESSAGE: {
        const { error } = action || {}
        let updatedContentNodes = {
          ...state[this.CONTENT_NODES],
        }
        if (error) {
          const { message = this.DEFAULT_NODES_FAILURE_MESSAGE } = error || {}
          updatedContentNodes['nodesSystemError'] = message
        }
        return {
          ...state,
          [this.CONTENT_NODES]: updatedContentNodes,
        }
      }
      default: {
        return state
      }
    }
  }
}

export default ContentfulReducer
