import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'

import {
  CollapsibleButton,
  HeaderFive,
  HeaderSix,
  ContainedButton,
  TextLink,
} from 'secondstep-components'

export const LessonCollapsibleWrapper = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  flex-direction: row;
  align-items: center;
  padding: 1.3125rem 0 1.6875rem 0;
  margin: -0.0625rem 1.75rem 0;
  border-top: 1px solid #ccc;

  ${CollapsibleButton} {
    padding: 0;
    margin: 0.125rem 1rem 0 0;

    svg {
      margin-bottom: 0;
    }
  }

  ${HeaderFive} {
    margin-bottom: 0;
    font-size: 1.25rem;
  }

  svg {
    margin-left: 0.4375rem;
  }

  @media (max-width: 650px) {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 1.3125rem 0 1.75rem 0;

    ${ContainedButton} {
      margin: 1.4375rem 0 0 2.6875rem;
    }
  }

  @media (max-width: 500px) {
    margin: -0.0625rem 1.25rem 0;

    ${CollapsibleButton} {
      margin-right: 0;
    }

    ${ContainedButton} {
      margin-left: 1.875rem;
    }
  }

  ${themeGet('breakpoints.mobile')} {
    padding: 1.3125rem 0 1.75rem;
  }
`

export const TextBox = styled(Box)`
  flex-direction: row;
  align-items: center;
  flex-grow: 1;
  padding: 0 1rem 0 0;

  circle {
    color: ${themeGet('colors.checkmarkGreen')};
  }

  @media (max-width: 550px) {
    align-items: flex-start;

    ${CollapsibleButton} {
      margin-top: 0.25rem;
    }
  }
`

export const ToggleWrapper = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))``

export const CollapsibleContentWrapper = styled(Box)`
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 2.5rem 2.1875rem 0;
  margin-left: 5rem;

  ${HeaderSix} {
    margin: -0.375rem 0 0.75rem 0;
    font-size: 1rem;
    line-height: 1rem;
    color: ${themeGet('colors.headers.primary')};
  }

  ${themeGet('breakpoints.tablet')} {
    justify-content: space-between;
    padding: 0 1.75rem 1.5rem 0;
  }

  @media (max-width: 700px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 500px) {
    margin-left: 3.0625rem;
  }
`

export const LeftContent = styled(Box)`
  flex-direction: column;
  width: 100%;

  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
    width: auto;
  }
`

export const RightContent = styled(Box)``

export const OverviewBox = styled(Box)`
  width: 80%;
  padding-right: 3rem;
  margin-bottom: 2rem;

  ${HeaderSix} {
    margin-bottom: 0.625rem;
  }

  ul {
    margin: 0;
  }

  span {
    margin-bottom: 0.5rem;
  }

  ${themeGet('breakpoints.tablet')} {
    width: auto;
    max-width: 26.6875rem;
    margin-bottom: 1.25rem;
  }

  @media (max-width: 700px) {
    padding-right: 0;
  }
`

export const LinkBox = styled(Box)`
  // width: 40%;

  ${TextLink} {
    margin-bottom: 0.375rem;
  }

  ${themeGet('breakpoints.tablet')} {
    width: 100%;
  }
`

export const ButtonBox = styled.div`
  display: flex;
  flex-direction: column;

  a {
    &:first-of-type {
      margin-bottom: 1rem;
    }
  }

  @media (max-width: 700px) {
    flex-direction: row;
    margin-bottom: 1.9375rem;

    a {
      &:first-of-type {
        margin-bottom: 0;
        margin-right: 1rem;
      }
    }
  }
`

export const LessonsWrapper = styled.div`
  display: flex;

  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
  }
`
export const PdfsWrapper = styled.div`
  margin-right: 2rem;
`

export const ExtendLessonWrapper = styled.div`
  ${themeGet('breakpoints.tablet')} {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
`

export const AccordionButton = styled.button`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  h3 {
    margin-bottom: 0;
  }
`
