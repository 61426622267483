import { getTheme } from 'themes'

const themeMap = {
  'elementary digital': getTheme('elementary'),
  'el kit': getTheme('elkit'),
  'el kit - family tab only': getTheme('elkit'),
  'hs program': getTheme('highschool'),
  'k5 kit': getTheme('k5kit'),
  'k5 kit - family tab only': getTheme('k5kit'),
  'k8 digital programs': getTheme('mskit'),
  'bpu kit': getTheme('bpu'),
  'bpu kit - family tab only': getTheme('bpu'),
  'cpu kit': getTheme('cpu'),
  'cpu kit - el': getTheme('cpu'),
  'cpu kit - family tab only': getTheme('cpu'),
  'highschool program - families': getTheme('highschool'),
  'middle school 2020': getTheme('middleSchool'),
  'ms kit': getTheme('mskit'),
  'ms08 - family tab only': getTheme('mskit'),
  ost: getTheme('ostKit'),
  'sela program': getTheme('sela'),
  'sela field test program': getTheme('sela'),
}

export default themeMap
