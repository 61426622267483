import cloneDeep from 'lodash/cloneDeep'
import { DATE_FORMAT, NO_DATA_PLACEHOLDER, TOTAL_LESSONS } from './constants'
import { displayDate } from 'utils/timeFormattingHelpers'

import { createSelector } from 'reselect'

/**
 * This function takes in contentful data and lms data of which need to be at the same level meaning they must share the same contentfulIds.
 * For example with class data you pass in the Classes lms array and the Contentful productSubsection array
 * @param {Array} lmsData
 * @param {Array} contentfulData
 * @returns {Array} - returns an array that combines lms data with Contentful data (currently only adds the Contentful displaytitle)
 */
const combineContentfulAndLmsData = (lmsData, contentfulData) => {
  let mergedData

  if (lmsData.length > 0 && !!contentfulData) {
    mergedData = lmsData.filter(lmsItem => {
      const matchedContentfulItem = contentfulData.find(
        ({ id }) => id === lmsItem.contentfulID,
      )
      if (matchedContentfulItem) {
        lmsItem.displayTitle = matchedContentfulItem.displayTitle
        return lmsItem
      }
      return false
    })
    return sortClasses(mergedData)
  }

  return mergedData
}

const attachLessonCountToUnits = course => {
  let lessonStart = 1
  course.units.map(unit => {
    unit.lessonStartingIndex = lessonStart
    lessonStart = lessonStart + unit.lessons.length
  })
  return course
}

/**
 *  This function takes the courseInstances from LMS and compares it to the courses in the selected product from Contentful,
 *  -only returns the lms instances that fall in the selected product
 * @param {Array} lmsClasses - array of courseInstances fetched from LMS
 * @param {Array} productClasses - array of all courses in selected product
 * @return {Array} - user's lms courseInstances from selected product
 */
const filterBySelectedProduct = (lmsClasses, productClasses) => {
  return lmsClasses.filter(lmsClass =>
    productClasses.find(
      productClass => productClass.id === lmsClass.contentfulID,
    ),
  )
}

const insertItem = (array, action) => {
  let newArray = array.slice()
  newArray.splice(action.index, 0, action.item)
  return newArray
}

const sortClasses = classes => {
  return classes.sort((a, b) => {
    a = new Date(a.createdDate).getTime()
    b = new Date(b.createdDate).getTime()
    return b - a
  })
}

const updateTitleOnExistingClass = (array, { instanceId, title: newTitle }) => {
  const newClasses = array?.map(currentClass => {
    const isClassToUpate = currentClass.instance === instanceId
    if (isClassToUpate) {
      const newClass = cloneDeep(currentClass)
      newClass.title = newTitle
      return newClass
    }
    return currentClass
  })
  return newClasses
}

const createLessonReportDownloadData = (
  lessonProgressData,
  license,
  reportFilename = null,
) => {
  const { skuCode = '', licenseId, siteName } = license || {}
  const fileDate = reportFilename || displayDate(Date.now(), 'LLL')
  const activeSiteName = siteName || ''
  const filename = `${activeSiteName} ${skuCode} Lesson Progress Report for license id ${licenseId} as of ${fileDate}.csv`.trim()
  const formattedData = lessonProgressData
    ? lessonProgressData.map(lmsUser => {
        const formattedLessonProgressData = formatLessonReportData(lmsUser)
        return formattedLessonProgressData
      })
    : []

  const createLessonKeys = () => {
    let lessonArray = []
    for (let i = 0; i < TOTAL_LESSONS; i++) {
      let lessonObj = {}
      lessonObj.label = `Lesson ${i + 1}`
      lessonObj.key = `lesson${i + 1}`
      lessonArray.push(lessonObj)
    }
    return lessonArray
  }

  const headers = [
    { label: 'Name', key: 'name' },
    { label: 'Email', key: 'email' },
    { label: 'User Status', key: 'licenseStatus' },
    { label: 'Class Name', key: 'className' },
    { label: 'Grade', key: 'grade' },
    { label: 'Most recent lesson viewed on', key: 'updatedAt' },
    { label: 'Lessons Completed', key: 'lessonsCompleted' },
    { label: 'Lessons in Grade', key: 'totalLessons' },
  ]
  const combinedHeaders = headers.concat(createLessonKeys())

  const lessonReportDownloadData = {
    data: formattedData,
    headers: combinedHeaders,
    filename,
  }
  return lessonReportDownloadData
}

const formatLessonReportData = lmsUser => {
  const { email, learner } = lmsUser || {}
  const {
    licenseStatus,
    updatedAt,
    className,
    grade,
    lessonsCompleted,
    lessonsTotal,
  } = lmsUser || {}
  const formattedEmail = email || NO_DATA_PLACEHOLDER
  const formattedName = learner || NO_DATA_PLACEHOLDER
  const formattedStatus = licenseStatus || NO_DATA_PLACEHOLDER
  const formattedClassName = className || NO_DATA_PLACEHOLDER
  const formattedGrade = grade?.toUpperCase() || NO_DATA_PLACEHOLDER
  const formattedUpdatedAt =
    (updatedAt && displayDate(updatedAt, DATE_FORMAT)) || NO_DATA_PLACEHOLDER
  const formattedTotalLessons = lessonsTotal || NO_DATA_PLACEHOLDER
  const formattedLessonsCompleted = lessonsTotal
    ? lessonsCompleted || 0
    : NO_DATA_PLACEHOLDER

  const formattedLessons = lessons => {
    // loop over each and create a return object of all of the lessons (max 32)
    let lessonObj = {}
    for (let i = 0; i < TOTAL_LESSONS; i++) {
      lessonObj[`lesson${i + 1}`] =
        (lessons[i] && lessons[i].status) || NO_DATA_PLACEHOLDER
    }
    return lessonObj
  }
  const formattedData = {
    email: formattedEmail,
    name: formattedName,
    licenseStatus: formattedStatus,
    className: formattedClassName,
    grade: formattedGrade,
    lessonsCompleted: formattedLessonsCompleted,
    totalLessons: formattedTotalLessons,
    updatedAt: formattedUpdatedAt,
    ...formattedLessons(lmsUser.lessons),
  }
  return formattedData
}

const selectHsInstructorData = createSelector(
  [
    state => state.lmsManager?.instructorData,
    state => state.lmsManager?.isFetchingOrCreatingProgramFlex,
    state => state.lmsManager?.error,
  ],
  (instructorData, isFetchingOrCreatingProgramFlex, error) => {
    const shouldFetch = !error || !isFetchingOrCreatingProgramFlex

    if (!shouldFetch) {
      return {} // this return value can be checked in components that use this selector to prevent refetching if error or fetching in progress
    }

    return instructorData?.['HighSchool']
  },
)

export default {
  combineContentfulAndLmsData,
  createLessonReportDownloadData,
  attachLessonCountToUnits,
  filterBySelectedProduct,
  insertItem,
  sortClasses,
  updateTitleOnExistingClass,
  selectHsInstructorData,
}
