import { createBrowserHistory } from 'history'
import { applyMiddleware, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { persistStore } from 'redux-persist'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './reducers'
import thunk from 'redux-thunk'
import loggerMiddleware from './middleware/logger'
import { getContentLayerMiddleWare } from '../layers/content'
import * as navigationLayer from '../layers/navigation'
import { all, call, spawn } from 'redux-saga/effects'
import removeError from './middleware/removeError'
import _ from 'lodash'

import { watchForSetupContentfulGet } from 'layers/content/store/operations'
import learnSagas from 'layers/learn'

import ResetContentNodesToDefault from 'layers/learn/content/nodes/nodeDefaultSetter/operations'

const WatchResetNodeToDefault = new ResetContentNodesToDefault()

export const history = createBrowserHistory()
const sagaMiddleware = createSagaMiddleware()

// make sure sagaMiddleware is always the LAST middleware so it catches all actions
const middleware = [
  routerMiddleware(history),
  getContentLayerMiddleWare(),
  thunk,
  sagaMiddleware,
]

// Redux Dev Tools Enhancers
const actionSanitizer = action =>
  action.type === 'CONTENTFUL_SUCCESS' && action.payload
    ? { ...action, payload: action.payload }
    : action
const stateSanitizer = state =>
  state.contentful ? { ...state, contentful: state.contentful } : state

const enhancers = []

if (process.env.NODE_ENV !== 'production') {
  // add redux console logging
  middleware.push(loggerMiddleware)

  // add redux dev tool
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__
  if (typeof devToolsExtension === 'function') {
    // Remove sanitizers here if you need to see Contentful data in Redux for debug purposes
    // Comment either or both out if you need to see the Contentful payloads to debug
    enhancers.push(
      devToolsExtension({
        actionSanitizer,
        stateSanitizer,
      }),
    )
  }
}

const rootReducer = createRootReducer(history)
const initialState = {}
const composedEnhancers = compose(
  applyMiddleware(...middleware, removeError),
  ...enhancers,
)

const store = createStore(rootReducer, initialState, composedEnhancers)

// TODO: Update this function to use configureMockStore instead of createStore (deprecated) - https://secondstep.atlassian.net/browse/LEARN-16342
export const createStoreForTesting = (state, shouldMergeWithInitialState) => {
  const mockState =
    state && shouldMergeWithInitialState
      ? _.merge(initialState, state)
      : state || initialState
  return createStore(rootReducer, mockState, composedEnhancers)
}

export const persistor = persistStore(store)

function* rootSaga() {
  const sagas = [
    navigationLayer.watchNavigation,
    WatchResetNodeToDefault.subscribe,

    watchForSetupContentfulGet,
    learnSagas,
  ]

  yield all(
    sagas.map(saga =>
      spawn(function*() {
        while (true) {
          try {
            yield call(saga)
            break
          } catch (e) {
            console.error('ROOT SAGA FAIL:', e)
          }
        }
      }),
    ),
  )
}

sagaMiddleware.run(rootSaga)

export default store
