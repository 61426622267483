export const addWeeks = (date, weeks) => {
  const dateCopy = new Date(date)
  dateCopy.setDate(dateCopy.getDate() + 7 * weeks)
  return dateCopy
}

export const addDays = (date, daysToAdd) => {
  const dateCopy = new Date(date)
  dateCopy.setDate(dateCopy.getDate() + daysToAdd)
  return dateCopy
}
