import React from 'react'
import PropTypes from 'prop-types'

const NotesThumbnail = () => {
  return (
    <svg height="154px" version="1.1" viewBox="0 0 213 154" width="213px">
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g id="assets" transform="translate(-40.000000, -105.000000)">
          <g id="song-thumbnail" transform="translate(40.000000, 105.000000)">
            <rect height="154" id="button-base" width="213" x="0" y="0" />
            <g
              id="horizontal-lines"
              transform="translate(17.000000, 34.000000)"
            >
              <path d="M1.92481579,0 L178.014079,0 C180.5805,0 180.5805,3.87 178.014079,3.87 L1.92481579,3.87 C-0.641605263,3.87 -0.641605263,0 1.92481579,0" />
              <path d="M1.92481579,29 L178.014079,29 C180.5805,29 180.5805,33.258 178.014079,33.258 L1.92481579,33.258 C-0.641605263,33.258 -0.641605263,29 1.92481579,29" />
              <path d="M1.92481579,54 L178.014079,54 C180.5805,54 180.5805,58.257 178.014079,58.257 L1.92481579,58.257 C-0.641605263,58.257 -0.641605263,54 1.92481579,54" />
              <path d="M1.92481579,81 L178.014079,81 C180.5805,81 180.5805,84.87 178.014079,84.87 L1.92481579,84.87 C-0.641605263,84.87 -0.641605263,81 1.92481579,81" />
            </g>
            <path
              d="M73.478,114.0627 C70.79,111.6097 67.018,110.3227 62.95,111.0657 C57.866,111.9937 53.849,116.1927 53.127,121.3097 C51.796,130.7187 61.179,138.4507 70.851,134.0807 C75.103,132.1597 77.685,127.7567 77.478,123.0947 L77.478,123.0917 L77.478,86.0377 L119.431,73.2197 L119.431,99.4257 C117.155,97.3487 114.103,96.1087 110.752,96.2267 C104.43,96.4517 99.192,101.6787 98.961,108.0007 C98.708,114.9677 104.281,120.6967 111.192,120.6967 C117.949,120.6967 123.426,115.2217 123.431,108.4657 L123.431,60.0027 C123.431,57.3147 120.833,55.3917 118.262,56.1767 L76.312,68.9947 C74.63,69.5077 73.481,71.0597 73.481,72.8187 L73.478,114.0627 Z"
              fill="#FFFFFF"
            />
            <path
              d="M151.3032,38.0998 L151.3032,61.6878 C149.8362,60.8898 148.0792,60.5568 146.2312,60.8898 C143.1992,61.4348 140.7372,63.8538 140.1492,66.8778 C139.1982,71.7768 142.9242,76.0648 147.6522,76.0648 C151.8752,76.0648 155.2992,72.6428 155.3032,68.4198 L155.3032,44.7948 L178.5312,37.6988 L178.5312,52.5668 C177.2452,51.8678 175.7392,51.5258 174.1422,51.6748 C170.6062,52.0058 167.6872,54.8558 167.2812,58.3828 C166.6002,64.2848 172.6422,69.0428 178.7402,65.9928 C181.2232,64.7498 182.6542,62.0668 182.5312,59.2918 L182.5302,59.2758 L182.5312,59.2758 L182.5312,31.0028 C182.5312,28.3148 179.9332,26.3918 177.3622,27.1768 L154.1342,34.2738 C152.4512,34.7878 151.3032,36.3398 151.3032,38.0998"
              fill="#FFFFFF"
            />
            <path
              d="M53.7715104,25.1739864 L73.7505104,31.2139864 C75.4375104,31.7239864 76.5915104,33.2789864 76.5915104,35.0419864 L76.5915104,43.4689864 C76.5915104,46.1519864 74.0025104,48.0739864 71.4345104,47.2969864 L52.6145104,41.6079864 L52.6145104,70.3599864 L52.6105104,70.3599864 L52.6115104,70.3689864 C52.8245104,74.6039864 50.6675104,78.7059864 46.8945104,80.6399864 C37.3315104,85.5409864 27.8055104,77.9419864 29.1225104,68.6449864 C29.8165104,63.7339864 33.6545104,59.6949864 38.5285104,58.7779864 C42.4095104,58.0479864 46.0155104,59.2329864 48.6145104,61.5309864 L48.6145104,29.0019864 C48.6145104,26.3199864 51.2045104,24.3969864 53.7715104,25.1739864"
              fill="#FFFFFF"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

NotesThumbnail.propTypes = {
  color: PropTypes.string,
}

export default NotesThumbnail
