import React, { Component } from 'react'
import PropTypes from 'prop-types'

import AdvisoryActivityOverviewContent from '../../experienceComponents/AdvisoryActivityOverviewContent'
import AdvisoryActivityInstructions from '../../experienceComponents/AdvisoryActivityInstructions'
import AdvisoryActivityDetailsPane from '../../experienceComponents/AdvisoryActivityDetailsPane'

import {
  TopWrapper,
  BottomWrapper,
  PageWrapper,
  TopLeft,
  TopRight,
} from './component.styles'

export default class AdvisoryActivitiesContent extends Component {
  static propTypes = {
    details: PropTypes.shape({
      activityType: PropTypes.string,
      projectType: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.string),
      time: PropTypes.string,
    }).isRequired,
    id: PropTypes.string.isRequired,
    instructions: PropTypes.shape({
      part1: PropTypes.object,
      part2: PropTypes.object,
      part3: PropTypes.object,
    }).isRequired,
    overview: PropTypes.shape({
      description: PropTypes.string,
      objectives: PropTypes.string,
      title: PropTypes.string,
    }).isRequired,
  }

  render() {
    const { details, id, instructions, overview } = this.props
    const pageDataTestId = `advisory-activities-content-page-${id}`

    return (
      <PageWrapper data-testid={pageDataTestId}>
        <TopWrapper>
          <TopLeft>
            <AdvisoryActivityOverviewContent {...overview} id={id} />
          </TopLeft>
          <TopRight>
            <AdvisoryActivityDetailsPane {...details} id={id} />
          </TopRight>
        </TopWrapper>
        <BottomWrapper>
          <AdvisoryActivityInstructions {...instructions} id={id} />
        </BottomWrapper>
      </PageWrapper>
    )
  }
}
