import styled from 'styled-components'
import {
  ModalBackdropLight,
  ContainedButton,
  OutlinedButton,
} from 'secondstep-components'

export const SessionTimeoutPromptContainer = styled.div`
  margin: calc(35vh) auto;
  text-align: center;
  width: 50%;
  button {
    & :not(:last-of-type) {
      margin-right: 1rem;
    }
  }
`

export const PromptModalBackdrop = styled(ModalBackdropLight)`
  background-color: white;
`

export const LogoutButton = styled(ContainedButton)`
  border-radius: 0.3125rem;
  background-color: #3c6e90;
  border: 0;
  color: white;

  :hover {
    background-color: #002855;
  }
`

export const ContinueButton = styled(OutlinedButton)`
  border-radius: 0.3125rem;
  border-color: #3c6e90;
  color: #3c6e90;

  :hover {
    color: white;
    border-color: #002855;
    background-color: #002855;
  }
`
