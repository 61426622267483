import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const DefaultSiteSelectorWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  padding: 1.25rem 1.25rem 0 0;
  align-items: center;
  width: 100%;

  ${themeGet('breakpoints.mobileLarge')} {
    padding-right: 1rem;
  }
`
