import React from 'react'
import PropTypes from 'prop-types'
import {
  AllModulesContainer,
  HeaderBanner,
  ModuleDetailsWrapper,
} from './component.styles'
import ModuleOverview from 'components/ModuleOverview'
import ModuleUnit from 'components/ModuleUnit'

const ModuleDetails = ({ description, displayTitle, label, lmsModule }) => {
  const unitsExist = lmsModule?.units?.length > 0

  if (!description || !displayTitle) return null

  const { attempted, completeLessons, totalLessons } = lmsModule
  const isDone = lmsModule?.is_done || false
  const isStarted = lmsModule?.completeLessons !== 0
  const lmsActiveUnit = lmsModule?.units?.find(unit => !unit.is_done)
  const moduleTitle = `${label}: ${displayTitle}`
  const lmsNextMicrolearning = lmsActiveUnit?.lessons.find(ml => !ml.is_done)
  const nextMicrolearningId = lmsNextMicrolearning?.choices
    ? lmsNextMicrolearning?.choices[0].contentfulID
    : lmsNextMicrolearning?.contentfulID

  return (
    <AllModulesContainer>
      <HeaderBanner />
      <ModuleDetailsWrapper>
        <ModuleOverview
          attempted={attempted}
          completeLessons={completeLessons}
          description={description}
          displayTitle={displayTitle}
          entryId={lmsNextMicrolearning?.contentfulID}
          isDone={isDone}
          isStarted={isStarted}
          label={label}
          lmsNextMicrolearning={lmsNextMicrolearning}
          totalLessons={totalLessons}
        />
        {unitsExist && (
          <div>
            {lmsModule.units.map((lmsUnit, i) => {
              const isActive =
                lmsUnit.contentfulID === lmsActiveUnit?.contentfulID

              return (
                <ModuleUnit
                  dataTestId={`module-unit-${i}`}
                  entryId={lmsUnit.contentfulID}
                  instance={lmsUnit.instance}
                  isActive={isActive}
                  key={lmsUnit.sco}
                  lmsLessons={lmsUnit.lessons}
                  lmsUnit={lmsUnit}
                  moduleTitle={moduleTitle}
                  nextMicrolearningId={nextMicrolearningId}
                  sco={lmsUnit.sco}
                  totalLessons={lmsUnit.totalLessons}
                />
              )
            })}
          </div>
        )}
      </ModuleDetailsWrapper>
    </AllModulesContainer>
  )
}

ModuleDetails.propTypes = {
  description: PropTypes.string,
  displayTitle: PropTypes.string,
  label: PropTypes.string,
  lmsModule: PropTypes.object,
}

ModuleDetails.defaultProps = {
  lmsModule: {},
}

export default ModuleDetails
