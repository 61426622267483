import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  BodyWrapper,
  ContentWrapper,
  SetupWrapper,
  StyledHairline,
} from './component.styles'
import AdminHsSetupStepCard from './AdminHSSetupStepCard'
import AdminHSSetupHeader from './AdminHSSetupHeader'
import { domains } from 'config/env'
import PacingModal from 'components/PacingModal'
import StepModal from 'components/StepModal'

export const LICENSE_MANAGEMENT_HREF = `${domains.ADMIN_DOMAIN}/user-management`

export const AdminHSSetup = ({
  contentData,
  fetchUserSites,
  isMultiSite,
  navigationEvent,
  preferences,
  programKey,
  siteId,
  siteName,
  sitePreferences,
  updateSitePreferences,
}) => {
  const [isHighSchoolLaunchedError, setIsHighSchoolLauchedError] = useState(
    false,
  )

  const {
    areUsersAdded,
    implementationLevel,
    isImplementationPlanComplete,
    isPacingComplete,
    isSetup,
  } = preferences || {}

  const {
    description,
    displayTitle,
    panelIcon,
    tabColumn1,
    tabColumn2,
    tabColumn3,
  } = contentData || {}

  const [isStepModalOpen, setStepModalOpen] = useState(false)
  const [isPacingModalOpen, setPacingModalOpen] = useState(false)

  useEffect(() => {
    if (sitePreferences.error && sitePreferences.siteId === siteId) {
      setIsHighSchoolLauchedError(true)
    }
  }, [sitePreferences])

  const launchHighSchool = async () => {
    await updateSitePreferences(siteId, {
      highSchool: { isLaunched: true },
    })
    fetchUserSites()
  }

  const toggleStepModal = () => {
    setStepModalOpen(!isStepModalOpen)
  }
  const togglePacingModal = () => {
    setPacingModalOpen(!isPacingModalOpen)
  }

  return (
    <>
      <SetupWrapper isMultiSite={isMultiSite}>
        {isMultiSite && <StyledHairline />}
        <ContentWrapper isMultiSite={isMultiSite}>
          <AdminHSSetupHeader
            description={description}
            displayTitle={displayTitle}
            isHighSchoolLaunchedError={isHighSchoolLaunchedError}
            isMultiSite={isMultiSite}
            isSetup={isSetup}
            launchHighSchool={launchHighSchool}
            panelIcon={panelIcon}
            siteName={siteName}
          />
          <BodyWrapper isMultiSite={isMultiSite}>
            <AdminHsSetupStepCard
              isComplete={!!implementationLevel}
              navigationEvent={navigationEvent}
              preferences={preferences}
              programKey={programKey}
              siteId={siteId}
              tabColumn={tabColumn1}
              toggleModal={toggleStepModal}
            />
            <AdminHsSetupStepCard
              isButtonDisabled={!isImplementationPlanComplete}
              isComplete={isPacingComplete}
              navigationEvent={navigationEvent}
              preferences={preferences}
              programKey={programKey}
              siteId={siteId}
              tabColumn={tabColumn2}
              toggleModal={togglePacingModal}
            />
            <AdminHsSetupStepCard
              isComplete={areUsersAdded}
              navigationEvent={navigationEvent}
              preferences={preferences}
              programKey={programKey}
              siteId={siteId}
              tabColumn={tabColumn3}
              toggleModal={toggleStepModal}
            />
          </BodyWrapper>
        </ContentWrapper>
      </SetupWrapper>
      {isStepModalOpen && (
        <StepModal
          handleClose={toggleStepModal}
          programKey={programKey}
          savedPreferences={preferences}
          siteId={siteId}
        />
      )}
      {isPacingModalOpen && (
        <PacingModal
          handleClose={togglePacingModal}
          programKey={programKey}
          savedPreferences={preferences}
          siteId={siteId}
        />
      )}
    </>
  )
}

AdminHSSetup.propTypes = {
  contentData: PropTypes.object,
  fetchUserSites: PropTypes.func,
  hideSetupHeader: PropTypes.bool,
  isMultiSite: PropTypes.bool,
  navigationEvent: PropTypes.func,
  preferences: PropTypes.object,
  programKey: PropTypes.string,
  showHairline: PropTypes.bool,
  siteId: PropTypes.number,
  siteName: PropTypes.string,
  sitePreferences: PropTypes.object,
  updateSitePreferences: PropTypes.func,
}

export default AdminHSSetup
