import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextLink from '../../components/TextLinkWithFileIcon'

import {
  AssetContainer,
  Container,
  Description,
  Image,
  Left,
  Right,
  TextLinkContainer,
} from './component.styles'

class AssetDescription extends Component {
  static propTypes = {
    assets: PropTypes.arrayOf(
      PropTypes.shape({
        description: PropTypes.string.isRequired,
        fileType: PropTypes.string.isRequired,
        image: PropTypes.string,
        title: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired,
      }),
    ),
    id: PropTypes.string.isRequired,
  }

  render() {
    const { assets = [], id } = this.props
    if (assets.length <= 0) {
      return null
    }

    return (
      <Container dataTestId={id}>
        {assets.map((asset, index) => {
          const { description, image, title, fileType, url } = asset
          const key = `${id}-${index}`
          return (
            <AssetContainer dataTestId={key} key={key}>
              {image && (
                <Left>
                  <Image src={image} />
                </Left>
              )}
              <Right>
                <TextLinkContainer>
                  <TextLink fileType={fileType} href={url} text={title} />
                </TextLinkContainer>
                <Description>{description}</Description>
              </Right>
            </AssetContainer>
          )
        })}
      </Container>
    )
  }
}

export default AssetDescription
