import { put, takeEvery } from 'redux-saga/effects'
import autoBind from 'auto-bind'

import { NAVIGATION_EVENT } from '../../../../navigation/store/types'
import ContentfulActions from 'layers/content/store/actions'
import NavigationHelper from '../../../../navigation/navigationHelper'

// We need this to reset the contentful data for static pages whenever we change pages
export default class ResetContentNodesToDefault {
  constructor() {
    autoBind(this)
  }

  *process(action) {
    const { payload: actionPayload } = action || {}
    const { type } = actionPayload || {}

    const { GO, GOBACK, GOFORWARD, PUSH, REPLACE } = NavigationHelper.types

    const checkTypes = [GO, GOBACK, GOFORWARD, PUSH, REPLACE]
    const doesEventMatchAnyTypes = checkTypes.some(
      checkType => checkType === type,
    )

    if (doesEventMatchAnyTypes) {
      yield put(ContentfulActions.resetContentNodes())
    }
  }

  *subscribe() {
    yield takeEvery([NAVIGATION_EVENT], this.process)
  }
}
