import React from 'react'

import PropTypes from 'prop-types'
import VideoDataGetter from 'experienceComponents/VideoDataGetter'
import { BodyCopy } from 'secondstep-components'
import {
  ContentWrapper,
  RatioWrapper,
  VideoWrapper,
} from 'components/InlineVideo/component.styles'

import { Column, Row, Wrapper } from './component.styles'

const MediaBodyCopyComponent = props => {
  const { locale, videoEntryId, bodyCopy, id } = props || {}

  return (
    <Wrapper dataTestId={`media-copy-body-${id}`}>
      <Row>
        <Column>
          <ContentWrapper>
            <RatioWrapper>
              <VideoWrapper>
                <VideoDataGetter
                  entryId={videoEntryId}
                  refetchLocale={locale}
                />
              </VideoWrapper>
            </RatioWrapper>
          </ContentWrapper>
        </Column>

        <Column>
          <BodyCopy>{bodyCopy}</BodyCopy>
        </Column>
      </Row>
    </Wrapper>
  )
}

MediaBodyCopyComponent.propTypes = {
  bodyCopy: PropTypes.string,
  id: PropTypes.string,
  locale: PropTypes.string,
  videoEntryId: PropTypes.string,
}

export default MediaBodyCopyComponent
