import React from 'react'
import { ErrorWrapper, StyledWarningIcon } from './component.styles'
import { apiErrorText } from './data'

const ApiErrorMessage = () => {
  return (
    <ErrorWrapper>
      <StyledWarningIcon /> {apiErrorText}
    </ErrorWrapper>
  )
}

ApiErrorMessage.propTypes = {}

export default ApiErrorMessage
