import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 1.25rem;
  box-sizing: border-box;

  &:last-of-type {
    margin-bottom: 0;
  }

  h2 {
    margin-bottom: 0.75rem;
  }
`

export const BodyWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0.875rem 1.25rem;
  background: ${themeGet('colors.backgrounds.secondary', 'none')};
  border-radius: 9px;

  button {
    align-self: flex-start;
  }

  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
  }
`

export const ContentBlockWrapper = styled.div.attrs(({ name }) => {
  const isText = name === 'text'
  const isPdf = name === 'pdf'
  const margin = isText ? '0 0 1.25rem' : '0 1.25rem 1.25rem 0'
  // special logic for PDFs if they are last child, keep margin consistent:
  const marginBottomLast = isPdf ? '1.25rem' : '0'
  const width = isText ? '100%' : 'auto'
  return { margin, width, marginBottomLast }
})`
  display: flex;
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  &:last-of-type {
    margin-bottom: ${({ marginBottomLast }) => marginBottomLast};
  }
`
