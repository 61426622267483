import {
  AUSTRALIAN_LOCALE,
  ENGLISH_LOCALE,
  SPANISH_LOCALE,
} from 'layers/content/data'

const LOCALE_ORDER = [ENGLISH_LOCALE, SPANISH_LOCALE, AUSTRALIAN_LOCALE]

export const getLanguageOptions = (materialsByLocale = {}) => {
  let options = Object.entries(materialsByLocale).reduce(
    (languageOptions, [locale, materials = []]) => {
      if (SUPPORTED_LANGUAGES[locale] && materials.length > 0) {
        languageOptions = [
          ...languageOptions,
          { id: locale, title: SUPPORTED_LANGUAGES[locale] },
        ]
      }

      return languageOptions
    },
    [],
  )

  options = options.sort(sortByLocale)

  return options
}

const sortByLocale = (localeA, localeB) => {
  return LOCALE_ORDER.indexOf(localeA.id) - LOCALE_ORDER.indexOf(localeB.id)
}

export const SUPPORTED_LANGUAGES = {
  [ENGLISH_LOCALE]: 'English',
  [SPANISH_LOCALE]: 'Spanish',
  [AUSTRALIAN_LOCALE]: 'Australia',
}

const getPdfLocale = title => {
  if (title && title.toLowerCase().includes('spanish')) {
    return SPANISH_LOCALE
  } else if (title && title.toLowerCase().includes('australian')) {
    return AUSTRALIAN_LOCALE
  } else {
    return ENGLISH_LOCALE
  }
}

export const getPdfLinksByLanguage = pdfs => {
  let pdfLinks = {
    [ENGLISH_LOCALE]: [],
    [SPANISH_LOCALE]: [],
    [AUSTRALIAN_LOCALE]: [],
  }

  if (!pdfs) {
    return pdfLinks
  }

  pdfLinks = pdfs.reduce((pdfLinks, pdf) => {
    const { id, internalTitle, displayTitle, file: pdfFile } = pdf || {}
    const { file } = pdfFile || {}
    const { url } = file || {}
    const pdfText = displayTitle || internalTitle
    const pdfLocale = getPdfLocale(internalTitle)
    const pdfLink = {
      // TextLinkWithFileIcon props
      fileType: 'pdf',
      href: url,
      id: id,
      text: pdfText,
    }

    pdfLinks[pdfLocale].push(pdfLink)

    return pdfLinks
  }, pdfLinks)

  return pdfLinks
}
