import { K8_GRAPH_ENUM_TO_GRADE_NAME_MAP } from 'store/reportsManager/constants'

const mockSchool = siteId => ({
  administratorsCount: 1,
  allGrades: [
    {
      gradeName: 'Grade 1',
    },
    {
      gradeName: 'Kindergarten',
    },
  ],
  hasClassesWithCompletedLessonLastPeriod: true,
  numClassesWithCompletedLessonLastPeriod: 12,
  period: 7,
  lastUpdatedAt: 'some time ago',
  pendingInvitations: 1,
  siteId,
  siteName: 'blue school',
  teachersCount: 123,
  totalClassesCount: 123,
})

const mockGrade = gradeName => ({
  period: 7,
  lastUpdatedAt: 'some time ago',
  gradeDetails: {
    [gradeName]: {
      gradeName,
      classesCount: 3,
      numClassesWithCompletedLessonLastPeriod: 3,
      hasClassesWithCompletedLessonLastPeriod: true,
      classes: [],
    },
  },
})

const mockClass = classInstance => ({
  classInstance,
  gradeName: 'Grade 8',
  className: "Doc Ock's Grade 8 lab rats",
  teacherFirstName: 'Doctor',
  teacherLastName: 'Octavius',
  lessonsCompleted: 1,
  lessonCount: 2,
  lessons: [],
})

const lastNightMidnight = new Date()
lastNightMidnight.setDate(lastNightMidnight.getDate() - 1)
lastNightMidnight.setHours(0, 0, 0, 0)

const mockTeachersList = [
  ['Chris', 'Evans'],
  ['Jennifer', 'Lawrence'],
  ['Channing', 'Tatum'],
  ['Margot', 'Robbie'],
  ['Ryan', 'Reynolds'],
  ['Will', 'Smith'],
  ['Dwayne', 'Johnson'],
  ['Samuel', 'Jackson'],
  ['Gal', 'Gadot'],
  ['Idris', 'Elba'],
  ['Melissa', 'McCarthy'],
  ['Denzel', 'Washington'],
  ['Daisy', 'Ridley'],
]
const mockClassesList = [
  'The Great Gatsby',
  'To Kill a Mockingbird',
  'The Catcher in the Rye',
  'Nineteen Eighty-Four',
  'One Hundred Years of Solitude',
  'The Color Purple',
  'Don Quixote',
  'Pride and Prejudice',
  'The Kite Runner',
  'Frankenstein',
  'Lord of the Flies',
  'Hamlet',
]
const mockUnitsList = [
  'Growth Mindset & Goal-Setting',
  'Emotion Management',
  'Empathy & Kindness',
  'Problem-Solving',
]
const mockLessonsList = [
  'We Watch. We Listen. We Think',
  'Why We Pay Attention',
  'Mistakes Are Okay!',
  'Practice Makes Better',
  'Performance Task: Lets Practice and Learn!',
  'Sometimes We Feel Happy',
  'Sometimes We Feel Sad',
  'Sometimes We Feel Mad',
  'We Can Feel Calm',
  'Performance Task: What Are They Feeling?',
  'We Can Be Kind',
  'Why Kindness?',
  'Showing Kindness',
  'Kindness at School',
  'Performance Task: Demonstrating Kindness',
  'We Can Say the Problem',
  'Ready to Solve Problems',
  'Apologizing Can Help',
  'Taking Turns and Sharing',
  'Performance Task: We Can Solve Problems',
]
const mockK8AllGrades = [
  {
    gradeName: 'Kindergarten',
    classesCount: 10,
    numClassesWithCompletedLessonLastPeriod: 1,
    hasClassesWithCompletedLessonLastPeriod: true,
  },
  {
    gradeName: 'Grade 1',
    classesCount: 20,
    numClassesWithCompletedLessonLastPeriod: 10,
    hasClassesWithCompletedLessonLastPeriod: true,
  },
  {
    gradeName: 'Grade 7',
    classesCount: 0,
    numClassesWithCompletedLessonLastPeriod: 0,
    hasClassesWithCompletedLessonLastPeriod: false,
  },
  {
    gradeName: 'Grade 8',
    classesCount: 1,
    numClassesWithCompletedLessonLastPeriod: 0,
    hasClassesWithCompletedLessonLastPeriod: false,
  },
]

const mockK8Class = (idx, completedCount) => ({
  teacherFirstName: mockTeachersList[idx % mockTeachersList.length][0],
  teacherLastName: mockTeachersList[idx % mockTeachersList.length][1],
  className: mockClassesList[idx % mockClassesList.length],
  hasCompletedLessonLastPeriod: idx > 0 && completedCount > 0, // TODO: this should ref lessons
  lessonCount: mockLessonsList.length,
  lessonsCompleted: idx % mockLessonsList.length,
  lastLessonCompletedParent: idx > 0 ? `unit ${Math.trunc(idx / 5) + 1}` : '',
  lastLessonCompleted: idx > 0 ? `lesson ${idx % mockLessonsList.length}` : '',
  lastLessonCompletedTitle:
    idx > 0 ? mockLessonsList[idx - (1 % mockLessonsList.length)] : '',
  lastLessonCompletedDate:
    idx > 0 ? (completedCount > 0 ? lastNightMidnight : '01/01/2023') : '',
  classInstance: `${idx + 1}`,
})
const mockK8Grade = gradeName => {
  const mockGrade = mockK8AllGrades.find(g => g.gradeName === gradeName)
  const {
    classesCount,
    numClassesWithCompletedLessonLastPeriod,
    hasClassesWithCompletedLessonLastPeriod,
  } = mockGrade
  const classes = []
  let completedCount = numClassesWithCompletedLessonLastPeriod + 1
  for (let c = 0; c < classesCount; ++c) {
    classes.push(mockK8Class(c, completedCount))
    completedCount--
  }
  const mockGradeDetails = {
    gradeName,
    classesCount,
    numClassesWithCompletedLessonLastPeriod,
    hasClassesWithCompletedLessonLastPeriod,
    classes,
  }
  return mockGradeDetails
}
const mockK8ClassDetailsResolver = (gradeName, classInstance) => {
  const mockGrade = mockK8Grade(gradeName)
  const mockClass = mockGrade.classes[classInstance - 1]
  const {
    className,
    lastLessonCompletedDate,
    lessonCount,
    lessonsCompleted,
    teacherFirstName,
    teacherLastName,
  } = mockClass
  const mockLessons = []
  for (let l = 0; l < lessonCount; ++l) {
    mockLessons.push({
      parent: `unit ${Math.trunc(l / 5) + 1}`,
      parentTitle: mockUnitsList[Math.trunc(l / 5)],
      lessonNumber: l + 1,
      lessonTitle: mockLessonsList[l % mockLessonsList.length],
      lastViewedDate: '01/01/2023', // in progress
      status:
        l < lessonsCompleted
          ? 'complete'
          : l === lessonsCompleted
          ? 'incomplete'
          : 'notStarted',
      completedDate: l < lessonsCompleted ? lastLessonCompletedDate : '',
    })
  }
  return {
    classInstance,
    gradeName,
    className,
    lessonCount,
    lessons: mockLessons,
    lessonsCompleted,
    teacherFirstName,
    teacherLastName,
  }
}
const mockK8ReportSchoolData = siteId => ({
  data: {
    SiteDetails: {
      siteId,
      lastUpdatedAt: lastNightMidnight,
      period: 7,
      totalClassesCount: mockK8AllGrades.reduce((count, grade) => {
        return count + grade.classesCount
      }, 0),
      teachersCount: mockTeachersList.length,
      administratorsCount: 1,
      siteName: 'My Mock School',
      pendingInvitations: 1,
      hasClassesWithCompletedLessonLastPeriod: true,
      numClassesWithCompletedLessonLastPeriod: mockK8AllGrades.reduce(
        (count, grade) => {
          return count + grade.numClassesWithCompletedLessonLastPeriod
        },
        0,
      ),
      allGrades: mockK8AllGrades,
    },
  },
})

const mockK8ReportGradeData = (siteId, gradeName) => ({
  data: {
    SiteDetails: {
      siteId,
      allGrades: mockK8AllGrades,
      period: 7,
      lastUpdatedAt: lastNightMidnight,
      gradeDetails: mockK8Grade(K8_GRAPH_ENUM_TO_GRADE_NAME_MAP[gradeName]),
    },
  },
})

const mockK8ReportClassDetailsData = (siteId, gradeName, classInstance) => ({
  data: {
    SiteDetails: {
      siteId,
      period: 7,
      lastUpdatedAt: lastNightMidnight,
    },
    ClassDetails: mockK8ClassDetailsResolver(gradeName, classInstance),
  },
})

const mockK8ReportGradeAndClassDetailsData = (
  siteId,
  gradeName,
  classInstance,
) => ({
  data: {
    SiteDetails: {
      siteId,
      allGrades: mockK8AllGrades,
      period: 7,
      lastUpdatedAt: lastNightMidnight,
      gradeDetails: mockK8Grade(K8_GRAPH_ENUM_TO_GRADE_NAME_MAP[gradeName]),
    },
    ClassDetails: mockK8ClassDetailsResolver(
      K8_GRAPH_ENUM_TO_GRADE_NAME_MAP[gradeName],
      classInstance,
    ),
  },
})

export {
  mockK8ReportSchoolData,
  mockK8ReportGradeData,
  mockK8ReportClassDetailsData,
  mockK8ReportGradeAndClassDetailsData,
  mockSchool,
  mockGrade,
  mockClass,
  lastNightMidnight,
}
