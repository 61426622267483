import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const StyledTHead = styled.thead`
  text-align: left;
  border-bottom: 2px solid;
  border-color: ${themeGet('colors.beaconGray')};
  font-style: italic;
`

export const StyledTR = styled.tr``

export const StyledTH = styled.th`
  padding-bottom: ${themeGet('spacing.yellow12_0_75rem')};
  padding-right: 3.75rem;
`

export const StyledTBody = styled.tbody``

export const StyledTD = styled.td`
  border-bottom: 1px solid;
  border-color: ${themeGet('colors.beaconGray')};
  padding: ${themeGet('spacing.blue16_1rem')} 3.75rem
    ${themeGet('spacing.yellow12_0_75rem')} 0rem;

  ${themeGet('breakpoints.mobile')} {
    0.75rem 3.75rem 0.5rem 0rem;
  }
`

export const StyledTable = styled.table`
  width: 100%;
  color: ${themeGet('colors.darkGray')};
  empty-cell: hide;
  border-collapse: collapse;

  td:first-child {
    width: 1%;
    white-space: nowrap;
  }

  th:first-child {
    width: 1%;
    white-space: nowrap;
  }

  @media (max-width: 790px) {
    th:first-child {
      width: 100%;
      white-space: pre-wrap;
    }

    td:first-child {
      white-space: pre-wrap;
    }
  }
`
