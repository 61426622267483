import styled from 'styled-components'
import {
  ContainedButton,
  HeaderTwo,
  Modal,
  ModalContentWrapper,
} from 'secondstep-components'
import { themeGet } from 'styled-system'

export const StyledModal = styled(Modal)`
  ${ModalContentWrapper} {
    padding: ${themeGet('spacing.red40_2_5rem')}
      ${themeGet('spacing.amber28_1_75rem')};

    ${themeGet('breakpoints.mobile')} {
      padding: ${themeGet('spacing.red40_2_5rem')}
        ${themeGet('spacing.turquoise20_1_25rem')};
    }

    text-align: center;
    align-items: center;
    font-family: ${themeGet('font.molde.regular')};
  }

  ${HeaderTwo} {
    margin-bottom: 1rem;
    font-size: 1.625rem;

    ${themeGet('breakpoints.tablet')} {
      text-align: center;
    }
  }

  ${ContainedButton} {
    width: 6.875rem;
    margin-top: 20px;
  }

  p {
    color: #444444;
  }

  a {
    color: ${themeGet('colors.blue')};
    text-decoration: none;
  }

  ul {
    list-style-position: inside;
    padding-left: 0;
    list-style: none;
  }

  li::before {
    content: '-';
    padding-right: 8px;
  }
`
