import { useEffect } from 'react'
import { connect } from 'react-redux'
import withApiError, {
  ERROR_TYPE_REPORTING,
} from 'layers/errorHandling/apiError'
import PropTypes from 'prop-types'
import { SpinnerAnimatedIcon } from 'secondstep-components'
import {
  operations as reportsOperations,
  selectors as reportsSelectors,
} from 'store/reportsManager'
import { selectors as licenseSelectors } from 'store/licenseManager'
import AdminMultiSiteView from './component'
import { LoadingWrapper } from './component.styles'

export const AdminMultiSiteViewContainer = ({
  currentPage,
  email,
  fetchLessonCompletionData,
  isFetchingLessons,
  orderBy,
  programKey,
  shouldFetchLessons,
  sites,
  sitesPerPage,
  sitesPreferences,
  sortKey,
  summary,
  totalCount,
  totalPages,
  updateMonitorSelectedPaginationCurrentPage,
  updateMonitorSelectedPaginationSortKey,
  updateMonitorSelectedPaginationSortOrder,
}) => {
  useEffect(() => {
    if (shouldFetchLessons) {
      fetchLessonCompletionData({ adminEmail: email, program: programKey })
    }
  }, [shouldFetchLessons])

  const getPaginatedSites = (page, key, order) => {
    if (key !== sortKey) updateMonitorSelectedPaginationSortKey(key)
    if (order !== orderBy) updateMonitorSelectedPaginationSortOrder(order)
    if (page !== currentPage) updateMonitorSelectedPaginationCurrentPage(page)
  }

  if (isFetchingLessons) {
    return (
      <LoadingWrapper>
        <SpinnerAnimatedIcon />
        <p>Loading...</p>
      </LoadingWrapper>
    )
  }

  if (!Array.isArray(sites) || !summary) {
    return null
  }

  return (
    <AdminMultiSiteView
      currentPage={currentPage}
      getPaginatedSites={getPaginatedSites}
      isFetchingLessons={isFetchingLessons}
      orderBy={orderBy}
      sites={sites}
      sitesPerPage={sitesPerPage}
      sitesPreferences={sitesPreferences}
      sortKey={sortKey}
      summary={summary}
      totalCount={totalCount}
      totalPages={totalPages}
    />
  )
}

const mapDispatchToProps = {
  fetchLessonCompletionData: reportsOperations.fetchLessonCompletionData,
  updateMonitorSelectedPaginationCurrentPage:
    reportsOperations.updateMonitorSelectedPaginationCurrentPage,
  updateMonitorSelectedPaginationSortKey:
    reportsOperations.updateMonitorSelectedPaginationSortKey,
  updateMonitorSelectedPaginationSortOrder:
    reportsOperations.updateMonitorSelectedPaginationSortOrder,
}

export const mapStateToProps = state => {
  const {
    reportsManager: {
      admin,
      isFetchingAdminLessonCompletionData: isFetchingLessons,
      error,
      selectedProgram: { key: selectedProgramKey },
    },
    userProfileManager: { profile },
  } = state
  const { email } = profile
  const sites = reportsSelectors.selectSortedAdminLessonCompletionData(state)
  const sitesPreferences = licenseSelectors.selectActiveAdminSitePreferences(
    state,
  )
  const summary = reportsSelectors.selectAdminLessonCompletionSummaryData(state)

  const { monitorPagination } = admin
  const {
    currentPage,
    sitesPerPage,
    totalCount,
    totalPages,
    sortKey,
    orderBy,
  } = monitorPagination
  const shouldFetchLessons = !sites && !summary && !isFetchingLessons && !error

  return {
    currentPage,
    email,
    isFetchingLessons,
    orderBy,
    programKey: selectedProgramKey,
    shouldFetchLessons,
    sites,
    sitesPerPage,
    sitesPreferences,
    sortKey,
    summary,
    totalCount,
    totalPages,
  }
}

AdminMultiSiteViewContainer.propTypes = {
  currentPage: PropTypes.number,
  email: PropTypes.string,
  fetchLessonCompletionData: PropTypes.func,
  isFetchingLessons: PropTypes.bool,
  orderBy: PropTypes.string,
  programKey: PropTypes.string,
  shouldFetchLessons: PropTypes.bool,
  sites: PropTypes.array,
  sitesPerPage: PropTypes.number,
  sitesPreferences: PropTypes.object,
  sortKey: PropTypes.string,
  summary: PropTypes.object,
  totalCount: PropTypes.number,
  totalPages: PropTypes.number,
  updateMonitorSelectedPaginationCurrentPage: PropTypes.func,
  updateMonitorSelectedPaginationSortKey: PropTypes.func,
  updateMonitorSelectedPaginationSortOrder: PropTypes.func,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withApiError(AdminMultiSiteViewContainer, [ERROR_TYPE_REPORTING]))
