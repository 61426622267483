import { connect } from 'react-redux'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import NavigationHelper from 'layers/navigation/navigationHelper'
import ContentNamespaceGetter from 'layers/content/Hocs/ContentNamespaceGetter'
import { operations as productOperations } from 'store/productManager'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_CONTENTFUL_PRODUCT,
  ERROR_TYPE_LMS,
} from 'layers/errorHandling/apiError'
import { operations as lmsOperations } from 'store/lmsManager'
import { operations as loadingOperations } from 'store/loadingManager'
import Training from './component'
import { LOADING_TEXT } from './constants'
export class TrainingContainer extends Component {
  static propTypes = {
    displayTitle: PropTypes.string,
    fetchInstructorData: PropTypes.func,
    fetchSeedIds: PropTypes.func.isRequired,
    getProductMap: PropTypes.func,
    isFetching: PropTypes.bool,
    isLmsFetching: PropTypes.bool,
    isLoadingApp: PropTypes.bool,
    lmsTrainings: PropTypes.array,
    match: PropTypes.object,
    navigationEvent: PropTypes.func,
    seedIds: PropTypes.object,
    setAppIsLoadingState: PropTypes.func,
    setAppNotLoadingState: PropTypes.func,
    trainingPaths: PropTypes.array,
  }
  constructor(props) {
    super(props)
    this.state = {
      shouldRefresh: false,
    }
    this.shouldUpdateLoadingState = this.shouldUpdateLoadingState.bind(this)
  }
  componentDidMount() {
    const {
      fetchSeedIds,
      fetchInstructorData,
      seedIds,
      lmsTrainings,
      trainingPaths,
      isFetching,
      isLmsFetching,
      setAppIsLoadingState,
      isLoadingApp,
    } = this.props || {}

    const { shouldRefresh } = this.state
    const readyToLoad =
      isFetching ||
      isLmsFetching ||
      !trainingPaths ||
      !lmsTrainings ||
      shouldRefresh

    window.addEventListener('message', this.messageListener)
    document.addEventListener('visibilitychange', this.visibilityHandler)
    fetchInstructorData()
    if (!seedIds) {
      fetchSeedIds()
    }
    if (readyToLoad && !isLoadingApp) {
      setAppIsLoadingState(LOADING_TEXT)
    }
  }

  componentDidUpdate() {
    this.shouldUpdateLoadingState()
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.messageListener)
    document.removeEventListener('visibilitychange', this.visibilityHandler)
  }

  shouldUpdateLoadingState() {
    const {
      isFetching,
      isLmsFetching,
      trainingPaths,
      lmsTrainings,
      setAppIsLoadingState,
      setAppNotLoadingState,
      isLoadingApp,
    } = this.props || {}
    const { shouldRefresh } = this.state

    const readyToLoad =
      isFetching ||
      isLmsFetching ||
      !trainingPaths ||
      !lmsTrainings ||
      shouldRefresh

    if (readyToLoad && !isLoadingApp) {
      setAppIsLoadingState(LOADING_TEXT)
    }
    if (!readyToLoad && isLoadingApp) {
      setAppNotLoadingState()
    }
  }

  messageListener = ({ data, origin }) => {
    const messages = ['refresh']
    const { shouldRefresh } = this.state
    if (origin.includes('lms') && messages.includes(data) && !shouldRefresh) {
      this.setState({ shouldRefresh: true })
      this.visibilityHandler() // if tab is opened by ctrl + click
    }
  }

  visibilityHandler = async () => {
    const { fetchInstructorData } = this.props
    const { shouldRefresh } = this.state
    if (document.visibilityState === 'visible' && shouldRefresh) {
      await fetchInstructorData()
      this.setState({ shouldRefresh: false })
    }
  }

  render() {
    const {
      displayTitle,
      isFetching,
      isLmsFetching,
      lmsTrainings,
      match,
      navigationEvent,
      seedIds,
      trainingPaths,
      getProductMap,
      setAppIsLoadingState,
      setAppNotLoadingState,
      isLoadingApp,
    } = this.props

    const { shouldRefresh } = this.state

    return (
      <Training
        displayTitle={displayTitle}
        getProductMap={getProductMap}
        isFetching={isFetching}
        isLmsFetching={isLmsFetching}
        isLoadingApp={isLoadingApp}
        lmsTrainings={lmsTrainings}
        match={match}
        navigationEvent={navigationEvent}
        seedIds={seedIds}
        setAppIsLoadingState={setAppIsLoadingState}
        setAppNotLoadingState={setAppNotLoadingState}
        showUpdateLoader={shouldRefresh}
        trainingPaths={trainingPaths}
      />
    )
  }
}

const mapStateToProps = ({ lmsManager, loadingManager }) => ({
  isLmsFetching: lmsManager.isFetching,
  lmsTrainings:
    lmsManager.instructorData && lmsManager.instructorData.Trainings,
  seedIds: lmsManager.seedIds,
  isLoadingApp: loadingManager?.isLoading,
})

const mapDispatchToProps = {
  fetchInstructorData: lmsOperations.fetchInstructorData,
  fetchSeedIds: lmsOperations.fetchSeedIds,
  getProductMap: productOperations.getProductMap,
  setAppIsLoadingState: loadingOperations.setIsLoadingState,
  setAppNotLoadingState: loadingOperations.setNotLoadingState,
}

const mapper = entry => {
  const { productSubsections } = entry || {}
  const [productSubsection] = productSubsections || []
  const { trainingPaths, displayTitle } = productSubsection || {}
  return { displayTitle, trainingPaths }
}

const options = { include: 3, mapper, spread: true }

export default ContentNamespaceGetter(
  withApiError(TrainingContainer, [
    ERROR_TYPE_CONTENTFUL,
    ERROR_TYPE_CONTENTFUL_PRODUCT,
    ERROR_TYPE_LMS,
  ]),
  options,
  'productSection',
  connect(mapStateToProps, mapDispatchToProps),
  connect(null, NavigationHelper.mapDispatchToProps),
)
