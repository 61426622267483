import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  error: null,
  isFetching: false,
  courses: {},
}

const reducers = {
  fetchingCourseTree: state => {
    state.error = null
    state.isFetching = true
  },

  fetchCourseTreeError: (state, { payload }) => {
    const { error } = payload
    state.error = error
    state.isFetching = false
  },

  fetchCourseTreeSuccess: (state, { payload }) => {
    const { data, courseName } = payload

    state.error = null
    state.isFetching = false

    state.courses[courseName] = data
  },
}

const slice = createSlice({ initialState, name: 'courseManager', reducers })

export const actions = slice.actions
export default slice.reducer
