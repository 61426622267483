export default {
  admin: {
    namespaceName: 'admin',
    nextNodeName: 'implementation',
    nodeName: 'adminPanel',
  },
  district: {
    namespaceName: 'district',
    nextNodeName: 'resourcePages',
    nodeName: 'accountPanel',
  },
  'family-resources': {
    namespaceName: 'family-resources',
    nextNodeName: 'subsection',
    nodeName: 'familiesSection',
  },
  'family-mind-yeti': {
    namespaceName: 'family-mind-yeti',
    nodeName: 'familiesSection',
  },
}
