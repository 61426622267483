import { CompleteIcon } from 'secondstep-components'
import {
  InProgressWrapper,
  InProgressText,
  CompletedWrapper,
  CompletedText,
  LeaderCompletedWrapper,
  LeaderInProgressWrapper,
} from './component.styles'

export default {
  default: {
    completed: {
      StatusBox: CompletedWrapper,
      StatusText: CompletedText,
      StatusIcon: CompleteIcon,
      statusValue: 'Completed',
    },
    incomplete: {
      StatusBox: InProgressWrapper,
      StatusText: InProgressText,
      StatusIcon: null,
      statusValue: 'In Progress',
    },
    'not attempted': {},
  },
  leader: {
    completed: {
      StatusBox: LeaderCompletedWrapper,
      StatusText: CompletedText,
      StatusIcon: CompleteIcon,
      statusValue: 'Completed',
    },
    incomplete: {
      StatusBox: LeaderInProgressWrapper,
      StatusText: InProgressText,
      StatusIcon: null,
      statusValue: 'In Progress',
    },
    'not attempted': {},
  },
}
