import React from 'react'

const IconVideo = ({ role }) => {
  return (
    <svg role={role} height="13px" viewBox="0 0 21.0 13.0" width="21px">
      <title>Play Video</title>
      <path
        d="M11.956,0 C13.054,0 13.996,0.761 14.306,1.853 L17.116,0.558 C17.635,0.318 18.209,0.359 18.691,0.668 C19.215,1.003 19.527,1.591 19.527,2.241 L19.527,9.287 C19.527,9.937 19.215,10.525 18.691,10.86 C18.209,11.171 17.635,11.21 17.116,10.971 L14.306,9.676 C13.996,10.767 13.054,11.528 11.956,11.528 L2.456,11.528 C1.102,11.528 0,10.353 0,8.907 L0,2.621 C0,1.176 1.102,0 2.456,0 Z M11.956,2.099 L2.456,2.099 C2.199,2.099 1.989,2.333 1.989,2.621 L1.989,8.907 C1.989,9.195 2.199,9.429 2.456,9.429 L11.956,9.429 C12.214,9.429 12.424,9.195 12.424,8.907 L12.424,2.621 C12.424,2.333 12.214,2.099 11.956,2.099 Z M17.539,2.654 L14.412,4.094 L14.412,7.434 L17.539,8.875 L17.539,2.654 Z"
        fill="currentColor"
        stroke="none"
      />
    </svg>
  )
}

export default IconVideo
