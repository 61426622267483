import React from 'react'
import PropTypes from 'prop-types'
import ProgramTileIcon from 'components/dashboard/ProgramTileIcon'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { Button, Label, Title, LabelWrapper } from './component.styles'
import { DEFAULT_TITLE_TEXT } from './constants'
import { toUrlSafe } from 'utils/stringHelpers'

const ProgramLinkTile = ({
  displayTitle,
  isPreview,
  linkLabel,
  navigationEvent,
  path,
  programName,
  secondary,
  ProgramSupportsIcon,
  ProgramTrainingIcon,
  SharedIcon,
}) => {
  const titleText = displayTitle || DEFAULT_TITLE_TEXT

  const modifiedPath = isPreview ? `${path}?mode=preview` : path

  return (
    <Button
      data-testid={`resource-and-training-card-${toUrlSafe(titleText)}`}
      onClick={() => navigationEvent(modifiedPath, NavigationHelper.types.PUSH)}
      programName={programName}
      secondary={secondary}
    >
      <Title className={'title'} secondary={secondary}>
        {titleText}
      </Title>
      {linkLabel && (
        <LabelWrapper>
          <ProgramTileIcon
            ProgramSupportsIcon={ProgramSupportsIcon}
            ProgramTrainingIcon={ProgramTrainingIcon}
            SharedIcon={SharedIcon}
            titleText={titleText}
          />
          <Label className={'label'} secondary={secondary}>
            {linkLabel}
          </Label>
        </LabelWrapper>
      )}
    </Button>
  )
}

ProgramLinkTile.propTypes = {
  ProgramSupportsIcon: PropTypes.elementType,
  ProgramTrainingIcon: PropTypes.elementType,
  SharedIcon: PropTypes.elementType,
  displayTitle: PropTypes.string,
  isPreview: PropTypes.bool,
  linkLabel: PropTypes.string,
  navigationEvent: PropTypes.func,
  path: PropTypes.string,
  programName: PropTypes.string,
  secondary: PropTypes.bool,
  showIcon: PropTypes.bool,
}

export default ProgramLinkTile
