import React from 'react'
import AccessibleTextLink from 'components/AccessibleTextLink'

const BackLink = ({ children, ...props }) => {
  // we should use better selectors than data test id.
  // Instead of [dataTestId = "back-link"] we should use something like screen.getByRole('link', { name: /back/i })
  const withObligatoryDataTestId = {
    ...props,
    dataTestId:
      props.dataTestId ||
      'back-link-' + Math.floor(Math.random() * 1e6).toString(36),
  }

  return (
    <AccessibleTextLink {...withObligatoryDataTestId}>
      <span aria-hidden="true">&lt;&nbsp;</span>
      {children}
    </AccessibleTextLink>
  )
}

BackLink.propTypes = AccessibleTextLink.propTypes

export default BackLink
