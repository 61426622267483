import React from 'react'
import PropTypes from 'prop-types'
import { toKebabCase } from 'utils/stringHelpers'
import ButtonToggle from 'components/Teach/ButtonToggle'
import StreamingLessonMedia from 'components/Teach/StreamingLessonMedia'
import { StyledHeaderOne } from 'components/Teach/StreamingLessonMedia/component.styles'
import {
  Row,
  PrimaryColumn,
  SecondaryColumn,
  Wrapper,
} from './component.styles'
import { LANGUAGE_TOGGLE_DESCRIPTION } from './constants'
import { getLanguageToggleOptions } from './data.js'

const StreamingLessonMediaPage = ({
  entry,
  getEntryWithNewLocale,
  parentEntry,
}) => {
  const { displayTitle } = parentEntry || {}
  const { sections, locale, languageToggle } = entry || {}
  const headerDataTestId = displayTitle
    ? 'header-' + toKebabCase(displayTitle)
    : 'header'

  const languageToggleOptions = getLanguageToggleOptions(languageToggle)
  const hasLanguageToggle = languageToggleOptions && getEntryWithNewLocale

  return (
    <Wrapper>
      <Row>
        <PrimaryColumn>
          {displayTitle && (
            <StyledHeaderOne dataTestId={headerDataTestId}>
              {displayTitle}
            </StyledHeaderOne>
          )}
        </PrimaryColumn>
        <SecondaryColumn>
          {hasLanguageToggle && (
            <ButtonToggle
              description={LANGUAGE_TOGGLE_DESCRIPTION}
              onSelect={getEntryWithNewLocale}
              options={languageToggleOptions}
              value={locale}
            />
          )}
        </SecondaryColumn>
      </Row>
      <StreamingLessonMedia locale={locale} sections={sections} />
    </Wrapper>
  )
}

StreamingLessonMediaPage.propTypes = {
  entry: PropTypes.object,
  getEntryWithNewLocale: PropTypes.func,
  parentEntry: PropTypes.object,
}
export default StreamingLessonMediaPage
