import React from 'react'
import PropTypes from 'prop-types'

import {
  BackButton,
  Button,
  FinishModal,
  Footer,
  ConfirmationIcon,
  Subtitle,
  Title,
} from './component.styles'

const ConfirmationModal = ({
  handleBackClick,
  handleSaveClick,
  modalContent,
}) => {
  const title = modalContent?.header
  const subtitle = modalContent?.bodyCopy
  const backButtonText = modalContent?.backLabel
  const buttonText = modalContent?.confirmLabel

  return (
    <FinishModal key="user-program-profile-confirm">
      <ConfirmationIcon />
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Footer>
        <BackButton onClick={handleBackClick}>{backButtonText}</BackButton>
        <Button onClick={handleSaveClick}>{buttonText}</Button>
      </Footer>
    </FinishModal>
  )
}

ConfirmationModal.propTypes = {
  handleBackClick: PropTypes.func,
  handleSaveClick: PropTypes.func,
  modalContent: PropTypes.object,
}
export default ConfirmationModal
