export const VERIFICATION_NEEDED_HEADER = 'Email Verification Needed'
export const VERIFICATION_NEEDED_MESSAGE = `To verify your email, follow the instructions we've just sent to:`
export const VERIFICATION_HELP_HEADER = `Can't find the email?`
export const VERIFICATION_HELP_ITEMS = [
  'Check your spam folder.',
  `Ask your IT team to ensure emails from support@secondstep.org via SendGrid.me aren't blocked.`,
]

export const LOG_OUT = 'Log Out'
export const START_YEAR = '2012'
export const CURRENT_YEAR = new Date().getFullYear().toString()
export const CFC_NAME = 'Committe for Children.'

export const SS_MESSAGE =
  'Second Step is a registered trademark of Committee for Children.'
export const ABOUT_US = 'About Us'
export const HELP = 'Help'
export const PRIVACY_POLICY = 'Privacy Policy'
export const TERMS_OF_USE = 'Terms of Use'
