import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Modal, ModalContentWrapper } from 'secondstep-components'

export const StyledModal = styled(Modal)`
  [class*='ReactModal__Overlay'] {
    background-color: ${themeGet('colors.modalOverlay')}85;
  }

  ${ModalContentWrapper} {
    box-sizing: border-box;
    width: 40.625rem;
    ${themeGet('breakpoints.mobileLarge')} {
      height: auto;
    }
  }
`

export const LayoutWrapper = styled.div`
  padding: 1.25rem 1.75rem;
`

export const FinishLayoutWrapper = styled.div`
  padding: 2.5rem;
`
