import React from 'react'
import PropTypes from 'prop-types'
import { domains } from 'config/env'
import NavigationHelper from 'layers/navigation/navigationHelper'
import AccessibleTextLink from 'components/AccessibleTextLink'
import ClosableModal from 'experienceComponents/ClosableModal'
import VideoDataGetter from 'experienceComponents/VideoDataGetter'
import { useModal } from 'hooks/useModal'
import { toKebabCase } from 'utils/stringHelpers'

const TextLinkWithModal = ({
  className,
  contentType,
  prefixText = 'Prefix Text',
  isDownload,
  navigationEvent,
  staticText,
  url,
  video,
  searchKey,
}) => {
  const { showModal, createOpenHandler, closeModal } = useModal({
    searchKey: video && searchKey ? searchKey : undefined,
    searchValue: video?.id ?? '',
  })
  const idText = toKebabCase(`${prefixText}-${staticText}-link`)

  const navigateTo = () => {
    if (url.includes('/user-management')) {
      navigationEvent(
        `${domains.ADMIN_DOMAIN}${url}`,
        NavigationHelper.types.OPEN,
      )
    } else if (isDownload) {
      navigationEvent(url, NavigationHelper.types.DOWNLOAD_LINK)
    } else {
      navigationEvent(url)
    }
  }

  const clickHandler =
    contentType === 'video' && video ? createOpenHandler : navigateTo

  return (
    <AccessibleTextLink
      ariaLabel={idText}
      className={className}
      dataTestId={idText}
      onClick={clickHandler}
    >
      {staticText}
      {video && (
        <ClosableModal onClose={closeModal} show={showModal} showClose>
          <VideoDataGetter entryId={video.id} locale={video.locale} />
        </ClosableModal>
      )}
    </AccessibleTextLink>
  )
}

TextLinkWithModal.propTypes = {
  className: PropTypes.string,
  contentType: PropTypes.string,
  isDownload: PropTypes.bool,
  navigationEvent: PropTypes.func,
  prefixText: PropTypes.string,
  searchKey: PropTypes.string,
  staticText: PropTypes.string,
  url: PropTypes.string,
  video: PropTypes.object,
}

export default TextLinkWithModal
