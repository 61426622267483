import styled from 'styled-components'
import { themeGet } from 'styled-system'
import LandingBanner from 'experienceComponents/LandingBanner'
import AdminFlexibleContentPanel from 'components/AdminFlexibleContentPanel'

export const ImplementationGuideWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 1366px;
  padding: 0 1.25rem;
`

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1366px;
  background-color: ${themeGet('colors.white')};
  border-radius: 0.688rem;
  border: none;
  margin-bottom: 1.25rem;
`

export const StyledLandingBanner = styled(LandingBanner)`
  [class*='BannerText'] {
    margin-bottom: 0;
  }

  [class*='BannerWrapper'] {
    padding-top: 3.25rem;
    padding-bottom: 3.25rem;
  }

  [class*='StyledAnchor'] {
    left: 0;
  }
`

export const StyledAdminFlexibleContentPanel = styled(
  AdminFlexibleContentPanel,
)`
  [class*='Hairline'] {
    margin-top: 0.8125rem;
  }

  [class*='HeaderTwo'] {
    padding-bottom: 0.6875rem;
  }
`

export const FirstAdminFlexibleContentPanel = styled(
  StyledAdminFlexibleContentPanel,
)`
  padding: 1.5625rem 1.25rem 1.25rem;

  [class*='ColumnWrapper'] {
    [class*='StyledImage'] {
      margin-bottom: 0.875rem;
    }

    a:nth-of-type(1) {
      font-family: ${themeGet('font.sharpSlab.semibold')};
      color: ${themeGet('colors.darkBlue')};
    }

    a:nth-of-type(2) {
      padding-top: 0.75rem;
    }

    a:nth-of-type(n + 3) {
      padding-top: 0.375rem;
    }
  }

  svg {
    color: ${themeGet('colors.blueGray')};
    flex-shrink: 0;

    &:hover {
      color: ${themeGet('colors.darkBlue')};
    }
  }

  sup {
    font-size: 0.75rem;
    vertical-align: super;
  }
`

export const SecondAdminFlexibleContentPanel = styled(
  StyledAdminFlexibleContentPanel,
)`
  padding-bottom: 1.75rem;

  [class*='StyledImage'] {
    height: 165px;
    width: 225px;
    margin: 1.625rem 1.125rem -0.5rem 1.625rem;

    ${themeGet('breakpoints.medium')} {
      margin: 0;
    }
  }

  [class*='ImageLinkListWrapper'] {
    border-radius: 0.625rem;

    ${themeGet('breakpoints.medium')} {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    ${themeGet('breakpoints.medium')} {
      padding-bottom: 1.25rem;
    }

    [class*='ImageWrapper'] {
      flex: 1;

      ${themeGet('breakpoints.medium')} {
        display: flex;
        justify-content: center;
        padding: 1.125rem 0 0.75rem;
      }
    }

    [class*='LinkListWrapper'] {
      display: flex;
      flex: 1.5;
      flex-direction: column;
      justify-content: center;
      margin-left: 2.125rem;

      ${themeGet('breakpoints.medium')} {
        margin-left: 0;
      }

      [class*='StyledLinkDropdown'] {
        padding-left: 0;
        padding-top: 0.25rem;

        svg {
          color: ${themeGet('colors.linkLight')};
        }

        svg:first-child {
          width: 25.93px;
          height: 17.28px;
          padding-right: 0.4375rem;
        }

        [class*='DropdownButton'] {
          background-color: ${themeGet('colors.blueGray')};
          border-color: ${themeGet('colors.blueGray')};
          padding: 0.8125rem 0.875rem 0.6875rem 0.75rem;
          width: 100%;
          max-width: 15rem;

          span {
            color: ${themeGet('colors.linkLight')};
          }

          &:hover {
            background-color: ${themeGet('colors.darkBlue')};
            border-color: ${themeGet('colors.darkBlue')};
          }

          ${themeGet('breakpoints.tablet')} {
            width: 100%;
          }
        }
      }
    }
  }

  [class*='ColumnWrapper']:nth-of-type(2) {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto 0.9375fr;
    grid-template-rows: repeat(4, 2rem);
    align-self: center;
    margin-left: 2.5rem;
    margin-top: 0.1875rem;

    svg {
      flex-shrink: 0;

      &:hover {
        color: ${themeGet('colors.darkBlue')};
      }
    }

    [class*='HeaderBodyCopyWrapper'] {
      grid-column: 1 / -1;
    }
    a {
      padding-top: 0.5rem;
    }

    ${themeGet('breakpoints.medium')} {
      display: flex;
      flex-direction: column;
    }

    ${themeGet('breakpoints.tablet')} {
      align-self: unset;
      margin-left: 0;
      padding-top: 1.4375rem;
    }
  }
`

export const ThirdAdminFlexibleContentPanel = styled(
  StyledAdminFlexibleContentPanel,
)`
  padding: 1.5625rem 1.25rem 1.75rem;

  [class*='ColumnWrapper'] {
    background-color: ${themeGet('colors.paleGray')};
    border-radius: 0.625rem;
    padding: 1.3125rem 1.25rem;

    a:nth-of-type(1) {
      padding-top: 0.25rem;
    }

    a:nth-of-type(n + 2) {
      padding-top: 0.375rem;
    }

    svg {
      flex-shrink: 0;

      &:hover {
        color: ${themeGet('colors.darkBlue')};
      }
    }
  }
`
