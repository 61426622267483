import React from 'react'
import PropTypes from 'prop-types'
import {
  TileTitle,
  TileInformation,
  TileContainer,
  TileDescription,
} from './component.styles'

export function ProgramTile(props) {
  const { icon, button, title, description, dataTestId, ...rest } = props
  return (
    <TileContainer data-testid={dataTestId} {...rest}>
      {icon}
      <TileInformation>
        <TileTitle>{title}</TileTitle>
        <TileDescription>{description}</TileDescription>
      </TileInformation>
      {button}
    </TileContainer>
  )
}

ProgramTile.propTypes = {
  button: PropTypes.node.isRequired,
  dataTestId: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
}

export default ProgramTile
