import styled from 'styled-components'

import { themeGet } from 'styled-system'

import {
  ContainedButton,
  HeaderOne,
  Modal,
  ModalContentWrapper,
  OutlinedButton,
} from 'secondstep-components'

export const ModalContent = styled(Modal)`
  font-family: ${themeGet('font.molde.regular')};
  padding: 1.25rem 0.9375rem;
  height: 48.6875rem;
  width: 40.625rem;
  border-radius: 0.625rem;
  background-color: #ffffff;
  border-color: gray;
  border: solid;
  ${ModalContentWrapper} {
    box-sizing: border-box;
    max-height: 100%;
    font-family: ${themeGet('font.molde.regular')};
    padding: 1.25rem 0.9375rem;
  }
`
export const ModalInfo = styled.div``

export const CenteredSubTitle = styled.div`
  display: flex;
  gap: 0.4rem;
  margin-top: 0.5rem;
  padding-left: 5rem;
  padding-right: 5rem;
  text-align: center;
  line-height: 0.625rem;
  color: ${themeGet('colors.darkGray')};
`

export const ButtonPanel = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.25rem;
  margin-top: 1.75rem;
  margin-bottom: 0.5rem;
`

export const StyledButton = styled(ContainedButton)`
  width: 7.5rem;
  height: 2.6875rem;
`

export const StyledOutlinedButton = styled(OutlinedButton)`
  width: 7.5rem;
  height: 2.6875rem;
`

export const CenteredTitle = styled(HeaderOne)`
  margin-top: 0.9375rem;
  text-align: center;
  font-size: 1.4375rem;
  padding-left: 4rem;
  padding-right: 4rem;
  line-height: 1.5625rem;
  font-family: ${themeGet('font.sharpSlab.semibold')};
`

export const StyledModal = styled(Modal)`
  ${ModalContentWrapper} {
    box-sizing: border-box;
    font-family: ${themeGet('font.molde.regular')};
    min-width: 660px;
    max-height: 100%;
    padding: 1.25rem;
    overflow-x: auto;
  }
`
