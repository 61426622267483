import { useEffect, useState } from 'react'

import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { Loading } from 'secondstep-components'

import { GetRootContent } from 'layers/content/Hocs/RootContent'
import ContentDataGetter from 'layers/content/v2/Hocs/ContentDataGetter'
import NavigationHelper from 'layers/navigation/navigationHelper'
import {
  operations as licenseOperations,
  selectors as licenseSelectors,
} from 'store/licenseManager'
import { operations as lmsOperations } from 'store/lmsManager'
import { operations as loadingOperations } from 'store/loadingManager'
import { middleSchoolV2ContentfulMappings } from 'utils/productMaps'

import {
  INITIAL_LOADING_TEXT,
  UPDATE_LOADING_TEXT,
  COURSE_LEVEL_CONTENT_QUERY_TYPE,
} from './constants'
import LessonsComponent from './component'
import {
  backToLessonsHandlerFactory,
  classSelectHandlerFactory,
  courseSelectHandlerFactory,
  gotoDashboardHandlerFactory,
  unitSelectHandlerFactory,
} from './handlers'
import {
  getCourseLinks,
  getCourses,
  getRelevantClasses,
  getSelectedProduct,
  getTeachTab,
} from './utils'
import {
  resolveEntryIdForCourseLevelContent,
  extractUnitSlugsFromTabSummaries,
} from './data'

export const LessonsContainer = props => {
  const {
    activeDigitalLicenses,
    isLmsFetching,
    isLmsFetchingV2,
    products,
    location,
    match,
    navigationEvent,
    isFetchingActiveDigitalLicenses,
    isLoading,
    isLoadingApp,
    classes,
    instructorDataExists,
    fetchActiveDigitalLicensesForUser = () => {},
    fetchInstructorData = () => {},
    fetchInstructorDataV2 = () => {},
    fetchCoursesForProduct = () => {},
    setAppIsLoadingState,
    setAppNotLoadingState,
    userContext,
    content: courseContent,
  } = props

  const [shouldRefresh, setShouldRefresh] = useState(false)

  const productName = 'middle-school'
  const { pathname } = window.location
  const selectedProduct = getSelectedProduct(products, productName)
  const productSections = selectedProduct?.productSections
  const teachTab = getTeachTab(productSections)
  const courses = getCourses(teachTab)

  const { data } = courseContent ?? {}
  const { courseLevelContent } = data ?? {}
  const { tabSummaries } = courseLevelContent ?? {}
  const unitSlugs = extractUnitSlugsFromTabSummaries(tabSummaries)

  // TODO: This is a temporary mapping that replaces the V1 MS Contentful ids with V2 MS Contentful ids, remove this function when refactoring for program flex
  const coursesWithNewContentfulIds = courses.map(course => {
    if (courses.length === 0) return course
    const matchingCourse = middleSchoolV2ContentfulMappings.find(
      newCourse => course.internalTitle === newCourse.internalTitle,
    )
    const id = matchingCourse ? matchingCourse.id : course.id
    return { ...course, id }
  })

  const courseLinks = getCourseLinks(coursesWithNewContentfulIds)
  // TODO: Find proper way to get relevant classes (hopefully by using course manager).
  //       For now, this can be overridden by `const relevantClasses = classes`
  // const relevantClasses = classes
  const relevantClasses = getRelevantClasses(classes, courseLinks)

  const isDataMissing =
    !selectedProduct ||
    !productSections ||
    !courses ||
    !courseLinks ||
    !relevantClasses ||
    !instructorDataExists

  const checkToUpdateLmsLoadingState = () => {
    const isLoadingLmsData =
      isLmsFetching ||
      isLmsFetchingV2 ||
      !classes ||
      isFetchingActiveDigitalLicenses

    if (!shouldRefresh && isLoadingLmsData && !isLoadingApp) {
      setAppIsLoadingState(INITIAL_LOADING_TEXT)
    }
    if (!shouldRefresh && !isLoadingLmsData && isLoadingApp) {
      setAppNotLoadingState()
    }
  }

  const messageListener = ({ data, origin }) => {
    const isLoadingLmsData =
      isLmsFetching ||
      isLmsFetchingV2 ||
      !classes ||
      isFetchingActiveDigitalLicenses
    const messages = ['refresh']
    // Page refresh loader
    if (
      origin.includes('lms') &&
      messages.includes(data) &&
      !shouldRefresh &&
      !isLoadingLmsData
    ) {
      setShouldRefresh(true)
      setAppIsLoadingState(UPDATE_LOADING_TEXT)
      visibilityHandler() // if tab is opened by ctrl + click
    }
  }

  const visibilityHandler = async () => {
    const isLoadingLmsData =
      isLmsFetching ||
      isLmsFetchingV2 ||
      !classes ||
      isFetchingActiveDigitalLicenses
    if (
      document.visibilityState === 'visible' &&
      shouldRefresh &&
      !isLoadingLmsData
    ) {
      await fetchInstructorData()
      await fetchInstructorDataV2(userContext)

      setShouldRefresh(false)
      setAppNotLoadingState()
    }
  }

  useEffect(() => {
    window.addEventListener('message', messageListener)
    document.addEventListener('visibilitychange', visibilityHandler)
    return () => {
      window.removeEventListener('message', messageListener)
      document.removeEventListener('visibilitychange', visibilityHandler)
      if (isLoadingApp) {
        setAppNotLoadingState()
      }
    }
  }, [
    instructorDataExists,
    shouldRefresh,
    isFetchingActiveDigitalLicenses,
    isLmsFetching,
    isLmsFetchingV2,
  ])

  useEffect(() => {
    checkToUpdateLmsLoadingState()
    if (
      !isLmsFetching &&
      !isLmsFetchingV2 &&
      classes?.length === 0 &&
      match.url !== pathname
    ) {
      navigationEvent(match.url, NavigationHelper.types.REPLACE)
    }
    if (!instructorDataExists) {
      fetchInstructorData()
      fetchInstructorDataV2(userContext)
    }
    if (
      !Array.isArray(activeDigitalLicenses) &&
      !isFetchingActiveDigitalLicenses
    ) {
      fetchActiveDigitalLicensesForUser()
    }
  }, [
    instructorDataExists,
    activeDigitalLicenses,
    isFetchingActiveDigitalLicenses,
    shouldRefresh,
    isLmsFetching,
    isLmsFetchingV2,
  ])

  useEffect(() => {
    if (!productSections) {
      fetchCoursesForProduct(productName)
    }
  }, [fetchCoursesForProduct, productSections, productName])

  const isDataLoadingOrMissing = isLoading || isDataMissing

  if (isDataLoadingOrMissing) {
    return <Loading />
  }

  return (
    <LessonsComponent
      classes={relevantClasses}
      contentfulCourses={courses}
      courseLinks={courseLinks}
      goToDashboard={gotoDashboardHandlerFactory(navigationEvent)}
      match={match}
      onBacklinkClick={backToLessonsHandlerFactory(
        productName,
        navigationEvent,
      )}
      onClassClick={classSelectHandlerFactory(productName, navigationEvent)}
      onCourseClick={courseSelectHandlerFactory(productName, navigationEvent)}
      onUnitSelect={unitSelectHandlerFactory(
        productName,
        navigationEvent,
        location,
      )}
      productName={productName}
      unitSlugs={unitSlugs}
    />
  )
}

const mapStateToProps = (state, ownProps) => {
  const {
    lmsManager,
    licenseManager,
    loadingManager,
    userContextManager,
  } = state
  const instructorDataExists =
    !!lmsManager?.instructorData?.Elementary ||
    !!lmsManager?.instructorData?.MiddleSchool

  const classes = instructorDataExists
    ? [
        ...(lmsManager?.instructorData?.Elementary ?? []),
        ...(lmsManager?.instructorData?.MiddleSchool ?? []),
      ]
    : undefined
  return {
    activeDigitalLicenses: licenseSelectors.selectActiveDigitalLicenses(state),
    isFetchingActiveDigitalLicenses:
      licenseManager?.isFetchingActiveDigitalLicenses,
    isLoading:
      licenseManager.isFetchingActiveDigitalLicenses ||
      licenseManager.isSending ||
      loadingManager.isLoading ||
      lmsManager.isFetching ||
      lmsManager.isFetchingV2 ||
      ownProps.isLoading ||
      ownProps.isFetching,
    isLoadingApp: loadingManager?.isLoading,
    isLmsFetching: lmsManager.isFetching,
    isLmsFetchingV2: lmsManager.isFetchingV2,
    classes,
    instructorDataExists,
    userContext: userContextManager,
  }
}

const mapDispatchToProps = {
  fetchActiveDigitalLicensesForUser:
    licenseOperations.fetchActiveDigitalLicensesForUser,
  fetchInstructorData: lmsOperations.fetchInstructorData,
  fetchInstructorDataV2: lmsOperations.fetchInstructorDataV2,
  setAppIsLoadingState: loadingOperations.setIsLoadingState,
  setAppNotLoadingState: loadingOperations.setNotLoadingState,
}

export const mapper = entry => {
  return {
    isFetching: entry?.isFetching,
    isLoading: entry?.isLoading,
    products: entry?.products,
  }
}

const options = {
  include: 3,
  mapper,
  spread: true,
}

const contentDataGetterOptions = {
  // NOTE: this function currently does NOT support K5, only MS:
  entryId: resolveEntryIdForCourseLevelContent,
  queryType: COURSE_LEVEL_CONTENT_QUERY_TYPE,
}

/*
 * use a hybrid of the old and new high order components:
 *  - ContentDataGetter is used to fetch the course level data to handle redirects to the correct unit
 *  - GetRootContent is used to fetch the general course data to populate the component
 */
export default ContentDataGetter(
  GetRootContent(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(LessonsContainer)),
    options,
    connect(null, NavigationHelper.mapDispatchToProps),
  ),
  contentDataGetterOptions,
)

LessonsContainer.propTypes = {
  activeDigitalLicenses: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]),
  classes: PropTypes.arrayOf(
    PropTypes.shape({
      contentfulID: PropTypes.string,
      completeLessons: PropTypes.number,
      instance: PropTypes.string,
      scoId: PropTypes.number,
      totalLessons: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
  content: PropTypes.object,
  fetchActiveDigitalLicensesForUser: PropTypes.func,
  fetchCoursesForProduct: PropTypes.func,
  fetchInstructorData: PropTypes.func,
  fetchInstructorDataV2: PropTypes.func,
  instructorDataExists: PropTypes.bool,
  isFetchingActiveDigitalLicenses: PropTypes.bool,
  isLmsFetching: PropTypes.bool,
  isLmsFetchingV2: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingApp: PropTypes.bool,
  isLoadingContentful: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      productName: PropTypes.string,
      verb: PropTypes.string,
    }),
  }),
  navigationEvent: PropTypes.func,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      pageData: PropTypes.shape({
        route: PropTypes.string,
      }),
    }),
  ),
  setAppIsLoadingState: PropTypes.func,
  setAppNotLoadingState: PropTypes.func,
  userContext: PropTypes.object,
}
