import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  MenuContainer,
  SquaresContainer,
  SquareContainer,
  Line,
  ArrowButton,
  Label,
} from './component.styles'
import PathwayNavigationIcon from './PathwayNavigationIcon'

import ProgramArrowBack from 'components/ProgramArrowBack'
import ProgramArrowForward from 'components/ProgramArrowForward'

const PathwayNavigationComponent = ({
  activeIndex,
  pathwayTitles,
  handleSquareClick,
  tabStatuses,
}) => {
  const [firstVisibleIndex, setFirstVisibleIndex] = useState(0)
  const [isTablet, setIsTablet] = useState(window.innerWidth <= 992)
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 580)

  const updateMedia = () => {
    setIsTablet(window.innerWidth <= 992)
    setIsMobile(window.innerWidth <= 580)
  }

  useEffect(() => {
    window.addEventListener('resize', updateMedia)
    return () => window.removeEventListener('resize', updateMedia)
  }, [])

  const nextSlide = () => {
    if (isMobile) {
      if (firstVisibleIndex < pathwayTitles.length - 1) {
        setFirstVisibleIndex(prevIndex => prevIndex + 1)
      }
    } else if (isTablet) {
      if (firstVisibleIndex < pathwayTitles.length - 3) {
        setFirstVisibleIndex(prevIndex => prevIndex + 1)
      }
    }
  }

  const prevSlide = () => {
    if (firstVisibleIndex > 0) {
      setFirstVisibleIndex(prevIndex => prevIndex - 1)
    }
  }

  const squares = pathwayTitles.map((link, index) => {
    const tabStatus = tabStatuses?.[index] || {
      isComplete: false,
      isInCurrentPacing: false,
    }
    return (
      <SquareContainer
        isActive={activeIndex === index}
        key={index}
        onClick={() => handleSquareClick(index)}
      >
        {/* icon will return null if neither flag is true */}
        <PathwayNavigationIcon {...tabStatus} />
        <Label>{link}</Label>
      </SquareContainer>
    )
  })

  return (
    <MenuContainer>
      {(isTablet || isMobile) && (
        <ArrowButton disabled={firstVisibleIndex === 0} onClick={prevSlide}>
          <ProgramArrowBack firstVisibleIndex={firstVisibleIndex} />
        </ArrowButton>
      )}
      <SquaresContainer>
        {isMobile ? (
          <>
            {squares
              .slice(firstVisibleIndex, firstVisibleIndex + 1)
              .map((square, index) => (
                <React.Fragment key={index}>{square}</React.Fragment>
              ))}
          </>
        ) : isTablet ? (
          <>
            {squares
              .slice(firstVisibleIndex, firstVisibleIndex + 3)
              .map((square, index) => (
                <React.Fragment key={index}>
                  {index > 0 && <Line />}
                  {square}
                </React.Fragment>
              ))}
          </>
        ) : (
          squares.map((square, index) => (
            <React.Fragment key={index}>
              {index > 0 && <Line />}
              {square}
            </React.Fragment>
          ))
        )}
      </SquaresContainer>
      {(isTablet || isMobile) && (
        <ArrowButton
          disabled={
            isMobile
              ? firstVisibleIndex === pathwayTitles.length - 1
              : firstVisibleIndex === pathwayTitles.length - 3
          }
          onClick={nextSlide}
        >
          <ProgramArrowForward />
        </ArrowButton>
      )}
    </MenuContainer>
  )
}

PathwayNavigationComponent.propTypes = {
  activeIndex: PropTypes.number,
  handleSquareClick: PropTypes.func.isRequired,
  pathwayTitles: PropTypes.arrayOf(PropTypes.string).isRequired,
  tabStatuses: PropTypes.array,
}

export default PathwayNavigationComponent
