import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  CheckBox,
  FormInputNumber,
  FormInputSelect,
} from 'secondstep-components'

export const Wrapper = styled.div`
  [class*='StyledSelect__StyledSelectDropButton-sc'] {
    [class*='componentstyles__SelectWrapper-sc'] {
      padding: 11px 4px 8px 15px;
    }
  }

  border: solid 2px ${themeGet('colors.gray500')};
  border-width: 0 2px 2px 2px;
  background-color: ${themeGet('colors.gray100')};
  padding: 20px 20px 16px 20px;

  &:first-of-type {
    border-width: 2px 2px 2px 2px;
    border-radius: 4px 4px 0 0;
  }

  &:last-of-type {
    border-radius: 0 0 4px 4px;
  }

  &:first-of-type:last-of-type {
    border-radius: 4px;
  }
`

export const ExpandedWrapper = styled.div`
  padding: 15px 20px 6px;
  margin-top: 12px;
  margin-bottom: 8px;
  border-radius: 4px;
  background-color: ${themeGet('colors.white')};
`

export const CheckWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: -3px;
`

export const CheckTitle = styled.span`
  font-family: ${themeGet('font.komet')};
  font-weight: 600;
  font-size: 17px;
  color: ${themeGet('colors.highschool.green2')};
`

export const CheckDescription = styled.span`
  font-size: 14px;
`

export const StyledCheckBox = styled(CheckBox)`
  margin-right: 8px;
  &:checked {
    background-color: #c62d70;
  }

  [class*='CheckBoxStyled-sc-'] {
    width: 1.1875rem;
    height: 1.125rem;
    padding-top: 0.125rem;
    border: 1.5px solid ${themeGet('colors.darkGray')};
    border-radius: 3px;
  }

  [class*='CheckBoxHidden']:checked ~ [class*='CheckBoxStyled'] {
    color: ${themeGet('colors.white')};
    background: ${themeGet('colors.highschool.pink1')};
    border-color: ${themeGet('colors.highschool.pink1')};
  }
`

export const StyledFormInputNumber = styled(FormInputNumber)`
  label {
    font-family: ${themeGet('font.molde.regular')};
    margin-bottom: 8px;
  }

  input {
    /* TODO: add this back when SelectOption is fixed
    padding: 14px 15px 12px; 
    */
    height: 46px;
  }

  input:focus {
    border-color: ${themeGet('colors.highschool.pink1')};
    box-shadow: none;
  }

  input::placeholder {
    font-weight: normal;
    font-style: normal;
  }

  [class*='ControlsBox-sc'] {
    top: 12px;
    right: 13px;
  }

  svg:hover {
    fill: ${themeGet('colors.highschool.pink1')};
    stroke: ${themeGet('colors.highschool.pink1')};
  }
`

export const StyledFormInputSelect = styled(FormInputSelect)`
  :hover {
    polyline {
      stroke: ${themeGet('colors.highschool.pink1')};
    }
  }

  label {
    font-family: ${themeGet('font.molde.regular')};
    margin-bottom: 8px;
  }

  input {
    font-family: ${themeGet('font.molde.regular')};
    padding: 14px 15px 12px;
  }

  [class*='StyledSelect__StyledSelectDropButton-sc'] {
    border: solid 2px ${themeGet('colors.gray500')};
  }

  [class*='StyledDrop-sc'] {
    border-radius: 4px;
    box-shadow: none;
    left: unset !important;
    bottom: unset !important;
    top: unset !important;

    svg {
      display: inline;
    }
  }

  [class*='StyledSelect__OptionsContainer-'] {
    border: solid 2px ${themeGet('colors.gray500')};
    border-radius: 4px;

    button {
      border: none;
      border-radius: 0;
    }

    button:hover,
    button:focus {
      background-color: ${themeGet('colors.highschool.pink1')};

      svg {
        background-color: ${themeGet('colors.highschool.pink1')};
        color: white;
      }
    }
  }
`
