import {
  ALGOLIA_INDEX_NAME,
  REPLICA_DURATION_SHORTER_TO_LONGER,
  REPLICA_DURATION_LONGER_TO_SHORTER,
  REPLICA_TITLE_A_TO_Z,
  REPLICA_TITLE_Z_TO_A,
} from 'config/env'

export const RESULTS_PER_PAGE = 24

export const SIDEBAR_SCROLL_THRESHOLD = 125
export const MARGIN_TOP_DEFAULT = '-20px'
export const MARGIN_TOP_SCROLL = '-152px'
export const RESULTS_PADDING_LEFT_DEFAULT = '0.6rem'
export const RESULTS_PADDING_LEFT_SCROLL = '15.6rem'
export const DEFAULT_SEARCHBOX_PLACEHOLDER = 'Type Here...'
export const DEFAULT_SEARCHBOX_TITLE = 'Search'

export const SEE_MORE_BUTTON_TEXT = 'See more...'
export const SEE_LESS_BUTTON_TEXT = 'See less...'
export const CLEAR_ALL_BUTTON_TEXT = 'Clear all'

export const SORT_BY_OPTIONS = [
  { label: 'Featured', value: ALGOLIA_INDEX_NAME },
  {
    label: 'Duration (shorter to longer)',
    value: REPLICA_DURATION_SHORTER_TO_LONGER,
  },
  {
    label: 'Duration (longer to shorter)',
    value: REPLICA_DURATION_LONGER_TO_SHORTER,
  },
  {
    label: 'Title (A to Z)',
    value: REPLICA_TITLE_A_TO_Z,
  },
  {
    label: 'Title (Z to A) ',
    value: REPLICA_TITLE_Z_TO_A,
  },
]
export const PAGE_HEADER_TITLE = 'Library'
export const MOBILE_MODAL_TITLE = 'Filters'
export const MOBILE_MODAL_DESCRIPTION = 'Learning Experiences in the Library'
export const FILTER_BY = 'Filter by'
export const SORT_BY = 'Sort by:'
export const AI_SORT_BY_USAGE_KEY = 'high_school_sorted_by'
