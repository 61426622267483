import styled from 'styled-components'
import {
  HeaderOne,
  Hairline,
  TextLink,
  ProgressStatusMedium,
} from 'secondstep-components'
import { Box } from 'grommet'
import { themeGet } from 'styled-system'

export const CourseHeaderWrapper = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  width: 100%;
  display: grid;
  grid-template:
    'title title links' min-content
    'count count links' min-content
    'hairline hairline hairline' 0 / 1fr 1fr 1fr;

  ${themeGet('breakpoints.mobileLarge')} {
    grid-template:
      'title' min-content
      'count' min-content
      'links' min-content
      'hairline' 0 / 1fr;
  }
`

export const CourseTitle = styled(HeaderOne)`
  margin-bottom: 0.125rem;
  span {
    display: inline-block;
    vertical-align: middle;
  }
  grid-area: title;
  margin: 0;
  line-height: 1.8rem;
  ${themeGet('breakpoints.mobileLarge')} {
    line-height: 3rem;
  }
`

export const StyledHairline = styled(Hairline)`
  grid-area: hairline;
`

export const StyledLessonsCount = styled.div`
  display: flex;
  gap: 1rem;
  padding: 1.25rem 0;
  grid-area: count;

  ${themeGet('breakpoints.mobileLarge')} {
    gap: 0.1rem;
    padding: 0.5rem 0;
    flex-direction: column;
  }

  span,
  ${ProgressStatusMedium} {
    line-height: 1rem;
  }

  // Prepends a bullet to every lesson count except the first
  > span:nth-child(n + 2),
  ${ProgressStatusMedium}:nth-child(n + 2) {
    &::before {
      content: '•';
      position: relative;
      left: -0.5rem;

      ${themeGet('breakpoints.mobileLarge')} {
        display: none;
      }
    }
  }
`

export const StyledScopeAndSequenceContainer = styled.div`
  grid-area: links;
  justify-self: end;
  align-self: end;
  padding-bottom: 1.25rem;

  ${themeGet('breakpoints.mobileLarge')} {
    justify-self: baseline;
  }

  ${TextLink} {
    &:first-child {
      margin: 0 0 0.3rem 0;
    }
  }
`
