import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'
import { BodyCopy, HeaderThree } from 'secondstep-components'

export const BottomRow = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin-top: ${themeGet('spacing.amber28_1_75rem')};
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  border-radius: 0 0 0.625rem 0.625rem;
  background-color: ${themeGet('colors.modules.secondaryBackground')};
`

export const BodyRow = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 75%;
  padding: 0 ${themeGet('spacing.turquoise20_1_25rem')};

  ${themeGet('breakpoints.medium')} {
    max-width: 100%;
  }

  ${themeGet('breakpoints.mobile')} {
    padding: 0 ${themeGet('spacing.blue16_1rem')};
  }
`

export const BottomText = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  color: ${themeGet('colors.modules.secondaryCopy')};
  font-size: 1rem;
  font-family: ${themeGet('fonts.buttonText')};
  font-weight: 600;
  letter-spacing: 0.0313rem;
`

export const Wrapper = styled(Button).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin: 0px !important;
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  background-color: ${themeGet('colors.modules.primaryBackground')};
  cursor: pointer;

  .progress-bar {
    margin-top: 0.9375rem;
    padding: 0;
  }

  &:hover {
    background: ${({ isOnlyLeader }) =>
      isOnlyLeader
        ? themeGet('colors.modules.leaderPrimaryBackgroundHover')
        : themeGet('colors.modules.primaryBackgroundHover')};
    & > ${BottomRow} {
      background-color: ${({ isOnlyLeader }) =>
        isOnlyLeader
          ? themeGet('colors.modules.leaderSecondaryBackgroundHover')
          : themeGet('colors.modules.secondaryBackgroundHover')};
    }
  }

  ${themeGet('breakpoints.mobile')} {
    $hover:active {
      background: ${({ isOnlyLeader }) =>
        isOnlyLeader
          ? themeGet('colors.modules.leaderPrimaryBackgroundHover')
          : themeGet('colors.modules.primaryBackgroundHover')};
      & > ${BottomRow} {
        background-color: ${({ isOnlyLeader }) =>
          isOnlyLeader
            ? themeGet('colors.modules.leaderSecondaryBackgroundHover')
            : themeGet('colors.modules.secondaryBackgroundHover')};
      }
    }
  }
`

export const TopRow = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const LabelBox = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  line-height: 2.1875rem;
  margin: 0;
  margin-top: 1.0625rem;
  padding-left: ${themeGet('spacing.turquoise20_1_25rem')};
  font-size: 1rem;
  color: ${themeGet('colors.primaryCopy')};
  font-family: ${themeGet('fonts.label')};
  letter-spacing: 0;

  ${themeGet('breakpoints.mobile')} {
    padding-left: ${themeGet('spacing.blue16_1rem')};
  }
`

export const Title = styled(HeaderThree).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin: 0;
  margin-top: 0.1875rem;

  letter-spacing: 0;

  ${themeGet('breakpoints.tablet')} {
    // TODO: manage breakpoint sizes with grommet theming
    font-size: ${themeGet('heading.level.3.tablet.size')};
    line-height: ${themeGet('heading.level.3.tablet.height')};
  }

  ${themeGet('breakpoints.mobile')} {
    margin-top: 0.625rem;
    // TODO: manage breakpoint sizes with grommet theming
    font-size: ${themeGet('heading.level.3.mobile.size')};
    line-height: ${themeGet('heading.level.3.mobile.height')};
  }
`

export const Description = styled(BodyCopy)`
  margin: 0;
  margin-top: -0.25rem;
  color: ${themeGet('colors.primaryCopy')};
  font-size: ${themeGet('bodyCopy.medium.size')};
  font-family: ${themeGet('fonts.bodyCopy')};
  line-height: ${themeGet('bodyCopy.medium.height')};
  letter-spacing: 0;

  ${themeGet('breakpoints.tablet')} {
    margin-top: 0.1875rem;
  }

  ${themeGet('breakpoints.mobile')} {
    font-size: ${themeGet('bodyCopy.mobile.size')};
    line-height: ${themeGet('bodyCopy.mobile.height')};
  }
`

export const InProgressWrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: top;
  height: 2.25rem;
  border-radius: 0 0.625rem 0 0.625rem;
  background-color: ${themeGet('colors.progressBar.primary')};
  padding: 0 ${themeGet('spacing.turquoise20_1_25rem')};

  ${themeGet('breakpoints.mobile')} {
    padding: 0 ${themeGet('spacing.yellow12_0_75rem')};
  }
`

export const LeaderInProgressWrapper = styled(InProgressWrapper).attrs(
  ({ dataTestId }) => ({
    'data-testid': dataTestId,
  }),
)`
  background-color: ${themeGet(
    'colors.modules.leaderSecondaryBackgroundHover',
  )};
`

export const InProgressText = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  font-family: ${themeGet('fonts.statusText')};
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.0313rem;
  color: ${themeGet('colors.modules.secondaryCopy')};
  margin-top: 0.4375rem;
  white-space: nowrap;
`

export const CompletedWrapper = styled(InProgressWrapper).attrs(
  ({ dataTestId }) => ({
    'data-testid': dataTestId,
  }),
)`
  background-color: ${themeGet('colors.modules.tertiaryBackground')};

  svg {
    margin-top: 0.5625rem;
    width: 1.0625rem;
    height: 1.0625rem;
    color: ${themeGet('colors.icons.primary')};
  }
`

export const LeaderCompletedWrapper = styled(CompletedWrapper).attrs(
  ({ dataTestId }) => ({
    'data-testid': dataTestId,
  }),
)`
  background-color: ${themeGet('colors.modules.leaderTertiaryBackground')};

  svg {
    color: ${themeGet('colors.icons.secondary')};
  }
`

export const CompletedText = styled(InProgressText).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  color: ${themeGet('colors.modules.primaryStatusCopy')};
  padding-left: ${themeGet('spacing.pink8_0_5rem')};

  ${themeGet('breakpoints.mobile')} {
    display: none;
  }
`
