import { connect } from 'react-redux'
import withApiError, {
  ERROR_TYPE_REPORTING,
} from 'layers/errorHandling/apiError'
import PropTypes from 'prop-types'
import { useEffect } from 'react'
import AdminSingleSiteView from './component'
import { selectors as licenseSelectors } from 'store/licenseManager'
import {
  operations as reportsOperations,
  selectors as reportsSelectors,
} from 'store/reportsManager'

export const AdminSingleSiteViewContainer = ({
  email,
  fetchLessonCompletionData,
  isFetchingLessons,
  lastUpdatedAt,
  programKey,
  shouldFetchLessons,
  sites,
  sitesPreferences,
  summary,
}) => {
  useEffect(() => {
    if (shouldFetchLessons) {
      fetchLessonCompletionData({ adminEmail: email, program: programKey })
    }
  }, [shouldFetchLessons])

  return (
    <AdminSingleSiteView
      isFetchingLessons={isFetchingLessons}
      lastUpdatedAt={lastUpdatedAt}
      sites={sites}
      sitesPreferences={sitesPreferences}
      summary={summary}
    />
  )
}

AdminSingleSiteViewContainer.propTypes = {
  email: PropTypes.string,
  fetchLessonCompletionData: PropTypes.func,
  isFetchingLessons: PropTypes.bool,
  lastUpdatedAt: PropTypes.string,
  programKey: PropTypes.string,
  shouldFetchLessons: PropTypes.bool,
  sites: PropTypes.array,
  sitesPreferences: PropTypes.object,
  summary: PropTypes.object,
}

const mapDispatchToProps = {
  fetchLessonCompletionData: reportsOperations.fetchLessonCompletionData,
}

export const mapStateToProps = state => {
  const {
    reportsManager: {
      isFetchingAdminLessonCompletionData: isFetchingLessons,
      error,
      selectedProgram: { key: selectedProgramKey },
    },
    userProfileManager: { profile },
  } = state

  const { email } = profile
  const sites = reportsSelectors.selectSortedAdminLessonCompletionData(state)
  const summary = reportsSelectors.selectAdminLessonCompletionSummaryData(state)

  const sitesPreferences = licenseSelectors.selectActiveAdminSitePreferences(
    state,
  )

  const shouldFetchLessons = !summary && !isFetchingLessons && !error

  const lastUpdatedAt = summary?.etlLastUpdatedAt

  return {
    email,
    isFetchingLessons,
    lastUpdatedAt,
    programKey: selectedProgramKey,
    shouldFetchLessons,
    sites,
    sitesPreferences,
    summary,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withApiError(AdminSingleSiteViewContainer, [ERROR_TYPE_REPORTING]))
