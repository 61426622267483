import React, { Component } from 'react'
import PropTypes from 'prop-types'
import numberFromString from 'utils/numberFromString'
import removeRedactedContentfulArray from 'utils/removeRedactedContentfulArray'
import { checkSelaProduct } from './helper'
import ActiveTabProgramCardsComponent from './component'
import {
  PROGRAM_ELEMENTARY,
  PROGRAM_FAMILY,
  PROGRAM_HIGH_SCHOOL_FAMILY,
  PROGRAM_HS,
  PROGRAM_K8_DIGITAL,
  PROGRAM_MS,
  PROGRAM_MIND_YETI,
  PROGRAM_OST,
} from './constants'

export default class ActiveTabProgramCardsContainer extends Component {
  static propTypes = {
    activeTabProgramsAvailable: PropTypes.array,
    classes: PropTypes.array,
    contentfulCourses: PropTypes.array,
    contentfulModules: PropTypes.array,
    currentProgramName: PropTypes.string,
    firstName: PropTypes.string,
    getActiveTraining: PropTypes.func,
    lmsFTPath: PropTypes.array,
    lmsSelaPath: PropTypes.array,
    lmsTrainings: PropTypes.array,
    onComponentClick: PropTypes.func,
    seedIds: PropTypes.object,
  }

  getAvailableLinks = ({ staticLinks, teachLinks, trainingLinks }) => {
    const staticLinksAvailable = removeRedactedContentfulArray(staticLinks)

    const trainingLinksAvailable = removeRedactedContentfulArray(trainingLinks)
    const sortedTeachLinks = removeRedactedContentfulArray(teachLinks).sort(
      (a, b) => {
        if (a.linkLabel && b.linkLabel) {
          return numberFromString(a.linkLabel) - numberFromString(b.linkLabel)
        }
      },
    )

    return {
      staticLinksAvailable,
      trainingLinksAvailable,
      sortedTeachLinks,
    }
  }

  getProductType = internalTitle => {
    const isOstProduct = internalTitle.includes(PROGRAM_OST)
    const isK5Product = internalTitle.includes(PROGRAM_ELEMENTARY)
    const isMsProduct = internalTitle.includes(PROGRAM_MS)
    const isHighSchool = internalTitle.includes(PROGRAM_HS)
    const isHighSchoolFamily = internalTitle.includes(
      PROGRAM_HIGH_SCHOOL_FAMILY,
    )

    const isSelaProduct = checkSelaProduct(internalTitle)

    const isFamilyProduct =
      internalTitle.includes(PROGRAM_K8_DIGITAL) ||
      internalTitle.includes(PROGRAM_FAMILY)

    const isMindYetiProduct = internalTitle.includes(PROGRAM_MIND_YETI)

    return {
      isFamilyProduct,
      isHighSchool,
      isHighSchoolFamily,
      isK5Product,
      isMindYetiProduct,
      isMsProduct,
      isOstProduct,
      isSelaProduct,
    }
  }

  getActiveTraining = (internalTitle, lmsTrainings) => {
    const { seedIds } = this.props
    const lmsSeedIds = seedIds || {}
    let normalizedTitle = internalTitle.toLowerCase()
    if (normalizedTitle.includes('k5')) {
      // k5 is called ssp in some locations, this converts normalizedTitle so we get the key "ssp" since "k5" no longer maps
      normalizedTitle = 'ssp'
    }

    const [, value] = Object.entries(lmsSeedIds).find(([key]) =>
      normalizedTitle.includes(key),
    )
    const lmsTrainingPaths = value

    const activeTraining =
      lmsTrainings?.find(training =>
        lmsTrainingPaths.find(path => path === training.seed_id),
      ) || {}
    return activeTraining
  }

  render() {
    const {
      activeTabProgramsAvailable,
      classes,
      contentfulCourses,
      contentfulModules,
      currentProgramName,
      firstName,
      lmsSelaPath,
      lmsFTPath,
      lmsTrainings,
      onComponentClick,
      seedIds,
    } = this.props

    return (
      <ActiveTabProgramCardsComponent
        activeTabProgramsAvailable={activeTabProgramsAvailable}
        classes={classes}
        contentfulCourses={contentfulCourses}
        contentfulModules={contentfulModules}
        currentProgramName={currentProgramName}
        firstName={firstName}
        getActiveTraining={this.getActiveTraining}
        getAvailableLinks={this.getAvailableLinks}
        getProductType={this.getProductType}
        lmsFTPath={lmsFTPath}
        lmsSelaPath={lmsSelaPath}
        lmsTrainings={lmsTrainings}
        onComponentClick={onComponentClick}
        seedIds={seedIds}
      />
    )
  }
}
