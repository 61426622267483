import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { MarkdownParagraph } from 'components/MarkdownComponent'

export const StyledMarkdownParagraph = styled(MarkdownParagraph)`
  li {
    list-style-type: disc;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0.563rem;
  }

  p,
  li {
    font-family: ${themeGet('font.molde.regular')};
    color: ${themeGet('colors.darkGray')};
    font-size: 1rem;
    line-height: 1.375rem;
  }

  strong {
    font-weight: lighter;
  }

  ul {
    padding-left: 0.75rem;
    margin-top: 0;
    margin-top: -0.5rem;
  }

  p:last-of-type {
    {
    margin-top: -0.625rem;
  }

  ul:last-of-type{
    margin-bottom: 0rem;
  }
`

export const BodyWrapper = styled.div`
  font-family: ${themeGet('font.molde.medium')};
  font-size: 1.125rem;
  line-height: 1.35rem;
  text-align: start;
  color: ${themeGet('colors.darkGray')};
`

export const CardContainer = styled.div`
  padding: 0;
  margin-top: 0;
  gap: 1.25rem;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(21.875rem, 1fr));
`

export const Card = styled.div`
  background-color: ${themeGet('colors.paleGray')};
  border-radius: 1.25rem;
  padding: 1.25rem;
  padding-bottom: 0.188rem;
  margin-bottom: 0;
  ${themeGet('breakpoints.mobile')} {
    max-width: 18rem;
  }
`

export const CardTitle = styled.h4`
  margin-bottom: 1.75rem;
  font-size: 1rem;
  font-family: ${themeGet('font.sharpSlab.semibold')};
  color: ${themeGet('colors.darkBlue')};
`

export const CardHeader = styled.div`
  display: flex;
  height: 1rem;
  margin-bottom: 1.425rem;
`
export const CardImage = styled.img`
  vertical-align: middle;
  margin-right: 0.625rem;
  margin-bottom: 0.25rem;
`

export const StyledMarkdownLink = styled(MarkdownParagraph)`
  p {
    text-align: right;
    margin-right: 1.25rem;
  }
  a {
    font-weight: bold;
  }

  margin-top: -2.375rem;
  margin-bottom: 0.925rem;

  ${themeGet('breakpoints.belowTablet')} {
    margin-top: 0;
  }
`
