import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  CardWrapper,
  HeaderWrapper,
  ImageWrapper,
  LabelWrapper,
  ModalFlowWrapper,
  PacingTooltip,
  StepWrapper,
  StyledCompleteIcon,
  StyledContainedButton,
  StyledHeaderThree,
  StyledImage,
  StyledLabel,
  UserManagementTooltip,
} from './component.styles'
import { USER_MANAGEMENT } from './constants'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { domains } from 'config/env'
import { toKebabCase } from 'utils/stringHelpers'
import { TOOLTIP_LOCATIONS } from 'utils/tooltipHelpers'

const AdminHsSetupStepCard = ({
  className,
  isComplete,
  isButtonDisabled,
  navigationEvent,
  preferences,
  programKey,
  siteId,
  tabColumn,
  toggleModal,
}) => {
  const incompleteImage = tabColumn?.find(
    element => element.displayTitle === 'incomplete',
  )
  const completeImage = tabColumn?.find(
    element => element.displayTitle === 'complete',
  )
  const modalFlowData = tabColumn?.find(
    element => element.contentType.id === 'modalFlow',
  )

  const { buttonLabel, buttonLabelComplete, flowLabel, header } =
    modalFlowData || {}
  const buttonText = isComplete ? buttonLabelComplete : buttonLabel

  const headerBody = tabColumn?.find(
    element => element.contentType.id === 'headerBodyCopy',
  )
  const button = tabColumn?.find(
    element => element.contentType.id === 'linkButton',
  )

  const handleNavigate = (url, type) => {
    if (url === '/user-management') {
      url = `${domains.ADMIN_DOMAIN}${url}/${siteId}`
    }
    navigationEvent(url, type)
  }

  const implementationLevelText = `Level ${preferences?.implementationLevel}`
  const studentActivities = preferences?.studentActivities
    ? 'Student Activites Added'
    : 'No Student Activities'

  const implementationLevelHeader =
    preferences?.implementationLevel > 0
      ? `${implementationLevelText} • ${studentActivities}`
      : modalFlowData?.header || ''

  return (
    <CardWrapper className={className}>
      {!isComplete && (
        <ImageWrapper>
          <StyledImage
            alt={incompleteImage?.image?.description}
            key={`${incompleteImage?.image?.description}-${siteId}`}
            src={incompleteImage?.image?.file?.url}
          />
        </ImageWrapper>
      )}
      {!!isComplete && (
        <ImageWrapper>
          <StyledImage
            alt={completeImage?.image?.description}
            key={`${completeImage?.image?.description}-${siteId}`}
            src={completeImage?.image?.file?.url}
          />
        </ImageWrapper>
      )}
      {modalFlowData && (
        <Fragment
          key={`modal-flow-${siteId}-${toKebabCase(
            modalFlowData.internalTitle,
          )}`}
        >
          <LabelWrapper isComplete={isComplete}>
            <StyledCompleteIcon />
            <StyledLabel>{flowLabel}</StyledLabel>
          </LabelWrapper>
          {flowLabel !== 'IMPLEMENTATION APPROACH' && (
            <ModalFlowWrapper>
              <StepWrapper>
                <StyledHeaderThree>{header}</StyledHeaderThree>
                <PacingTooltip
                  location={
                    TOOLTIP_LOCATIONS.HS_ADMIN_SET_UP_START_DATE_DEACTIVATED
                  }
                  panelName="setupPanel"
                  programKey={programKey}
                />
              </StepWrapper>
              <StyledContainedButton
                disabled={isButtonDisabled}
                isComplete={isComplete}
                label={buttonText}
                onClick={toggleModal}
              />
            </ModalFlowWrapper>
          )}
          {flowLabel === 'IMPLEMENTATION APPROACH' && (
            <ModalFlowWrapper>
              <StyledHeaderThree>{implementationLevelHeader}</StyledHeaderThree>
              <StyledContainedButton
                isComplete={isComplete}
                label={buttonText}
                onClick={toggleModal}
              />
            </ModalFlowWrapper>
          )}
        </Fragment>
      )}
      {headerBody && (
        <Fragment key={`user-management`}>
          <LabelWrapper isComplete={isComplete}>
            <StyledCompleteIcon />
            <StyledLabel>{USER_MANAGEMENT}</StyledLabel>
          </LabelWrapper>
          <HeaderWrapper>
            <StyledHeaderThree>{headerBody.h3}</StyledHeaderThree>
            <UserManagementTooltip
              location={TOOLTIP_LOCATIONS.HS_ADMIN_USER_MANAGEMENT_TILE}
              panelName="setupPanel"
              programKey={programKey}
            />
          </HeaderWrapper>
        </Fragment>
      )}
      {button && (
        <Fragment key={`button`}>
          <StyledContainedButton
            isComplete={isComplete}
            label={button.buttonText}
            onClick={() =>
              handleNavigate(button.url, NavigationHelper.types.OPEN)
            }
          />
        </Fragment>
      )}
    </CardWrapper>
  )
}

AdminHsSetupStepCard.propTypes = {
  className: PropTypes.string,
  isButtonDisabled: PropTypes.bool,
  isComplete: PropTypes.string,
  navigationEvent: PropTypes.func,
  preferences: PropTypes.object,
  programKey: PropTypes.string,
  siteId: PropTypes.number,
  tabColumn: PropTypes.object,
  toggleModal: PropTypes.func,
}
export default AdminHsSetupStepCard
