import React from 'react'
import PropTypes from 'prop-types'
import HighSchoolTrainingOverdueBanner from './component'
import {
  isTrainingNotificationDismissed,
  setTrainingNotificationDismissed,
  formatNotificationText,
} from './helpers'
import { LINK_TEXT } from './constants'

function HighSchoolTrainingOverdueBannerContainer({
  navigationEvent,
  pathwayTitle,
  programTrainingLink,
}) {
  if (isTrainingNotificationDismissed()) {
    return null
  }

  return (
    <HighSchoolTrainingOverdueBanner
      linkText={LINK_TEXT}
      onDismiss={setTrainingNotificationDismissed}
      onProgramTrainingClick={() => navigationEvent(programTrainingLink)}
      text={formatNotificationText(pathwayTitle)}
    />
  )
}

HighSchoolTrainingOverdueBannerContainer.propTypes = {
  navigationEvent: PropTypes.func,
  pathwayTitle: PropTypes.string,
  programTrainingLink: PropTypes.string,
}

export default HighSchoolTrainingOverdueBannerContainer
