export const ENTRY_ID = '6uvfEUUMHx9IJzTHOlyeHA'

// we can consolidate these maps to unify the keys we use
export const K8_GRADE_NAME_TO_GRADE_SLUG_MAP = {
  Kindergarten: 'grade-k', // keep this extra mapping in so mock data works
  'Grade K': 'grade-k',
  'Grade 1': 'grade-1',
  'Grade 2': 'grade-2',
  'Grade 3': 'grade-3',
  'Grade 4': 'grade-4',
  'Grade 5': 'grade-5',
  'Grade 6': 'grade-6',
  'Grade 7': 'grade-7',
  'Grade 8': 'grade-8',
}
