import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { HeaderFour, TextLink } from 'secondstep-components'

export const Link = styled(TextLink)`
  margin-top: 0.438rem;
  color: ${themeGet(`colors.themePrimary`)};
  &:hover {
    color: ${themeGet(`colors.themeHighlight`)};
    transition: all 150ms;
  }
`

export const ListItem = styled.li`
  display: flex;
  cursor: pointer;
  &::before {
    content: '•';
    color: ${themeGet(`colors.themePrimary`)};
    display: inline-block;
    font-size: 2rem;
    margin-left: -1.25rem;
    width: 0.75rem;
    padding-bottom: 0.25rem;
  }
  &:hover:before {
    color: ${themeGet(`colors.themeHighlight`)};
    transition: all 150ms;
  }

  a {
    margin-top: 0;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;

  ${HeaderFour} {
    color: ${themeGet('colors.darkerDarkBlue')};
    font-family: ${themeGet('fontFamilySemibold')};
    font-size: 1.125rem;
    line-height: 1.375rem;
    margin-bottom: 0;
  }
`
