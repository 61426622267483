import React from 'react'
import PropTypes from 'prop-types'
import { ScheduleWrapper, ScheduleCopy } from './component.styles'
import {
  CancelButton,
  ConfirmButton,
  ButtonWrapper,
  ModalHeader,
} from '../component.styles'
import PacingDateInput from 'components/PacingDateInput'
import { weeksAmount, collectionName } from './data'
import { nodeNames } from '../data'
import ApiErrorMessage from 'components/ApiErrorMessage'

const EditPacing = ({
  handleContinueButtonClick,
  implementationLevel,
  data,
  values,
  errors,
  isValid,
  touched,
  handleChange,
  hasApiError,
  goBack,
}) => {
  const { backLabel, bodyCopy, choiceContent, header, nextLabel } = data || {}
  const [pathway1Data, pathway2Data, pathway3Data, pathway4Data] =
    choiceContent || []
  const shouldShowThirdDate = implementationLevel === 2

  return (
    <ScheduleWrapper wide={shouldShowThirdDate}>
      <ModalHeader>{header}</ModalHeader>
      <ScheduleCopy>{bodyCopy}</ScheduleCopy>
      <PacingDateInput
        collectionName={collectionName}
        errors={errors}
        firstDateName={nodeNames.node1}
        handleChange={handleChange}
        secondDateName={nodeNames.node2}
        showThirdDate={shouldShowThirdDate}
        subtitle={pathway1Data.headerSecondary}
        thirdDateName={nodeNames.node3}
        title={pathway1Data.header}
        touched={touched}
        values={values}
        weeks={weeksAmount}
      />
      <PacingDateInput
        collectionName={collectionName}
        errors={errors}
        firstDateName={nodeNames.node4}
        handleChange={handleChange}
        secondDateName={nodeNames.node5}
        showThirdDate={shouldShowThirdDate}
        subtitle={pathway2Data.headerSecondary}
        thirdDateName={nodeNames.node6}
        title={pathway2Data.header}
        touched={touched}
        values={values}
        weeks={weeksAmount}
      />
      <PacingDateInput
        collectionName={collectionName}
        errors={errors}
        firstDateName={nodeNames.node7}
        handleChange={handleChange}
        secondDateName={nodeNames.node8}
        showThirdDate={shouldShowThirdDate}
        subtitle={pathway3Data.headerSecondary}
        thirdDate={values.node9}
        thirdDateName={nodeNames.node9}
        title={pathway3Data.header}
        touched={touched}
        values={values}
        weeks={weeksAmount}
      />
      <PacingDateInput
        collectionName={collectionName}
        errors={errors}
        firstDateName={nodeNames.node10}
        handleChange={handleChange}
        secondDateName={nodeNames.node11}
        showThirdDate={shouldShowThirdDate}
        subtitle={pathway4Data.headerSecondary}
        thirdDateName={nodeNames.node12}
        title={pathway4Data.header}
        touched={touched}
        values={values}
        weeks={weeksAmount}
      />
      <ButtonWrapper>
        <CancelButton onClick={goBack}>{backLabel}</CancelButton>
        <ConfirmButton
          disabled={!isValid || hasApiError}
          onClick={() => handleContinueButtonClick()}
        >
          {nextLabel}
        </ConfirmButton>
      </ButtonWrapper>
      {hasApiError && <ApiErrorMessage />}
    </ScheduleWrapper>
  )
}

EditPacing.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  goBack: PropTypes.func,
  handleChange: PropTypes.func,
  handleClose: PropTypes.func,
  handleContinueButtonClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  hasApiError: PropTypes.bool,
  implementationLevel: PropTypes.number,
  isValid: PropTypes.bool,
  touched: PropTypes.object,
  values: PropTypes.object,
}

export default EditPacing
