/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React from 'react'
import Pathways from 'pages/Pathway/flag.off/component'
import ContentParentNodeGetter from 'layers/content/Hocs/ContentParentNodeGetter'
import { stripLastRouteSlug } from 'utils/urlHelpers'

const Container = props => {
  const { entry, parentEntry, match, navigationEvent, location } = props || {}

  const handleTabChange = contentType => {
    const { pathname, query } = location
    const pathWithOutLastRoute = stripLastRouteSlug(pathname)
    const {
      pageData: { route: updatedRoute },
    } = contentType
    const pathWithNewRoute = `${pathWithOutLastRoute}/${updatedRoute}?${new URLSearchParams(
      query,
    )?.toString()}`
    navigationEvent(pathWithNewRoute)
  }

  const matchingUnit = parentEntry.units.find(
    unit => unit.pageData.route === entry.pageData.route,
  )
  const unitId = parentEntry.units.indexOf(matchingUnit) + 1
  return (
    <Pathways
      entry={parentEntry}
      handleTabChange={handleTabChange}
      match={match}
      unitId={unitId}
    />
  )
}

const mapper = entry => {
  return { entry }
}

const options = {
  include: 3,
  mapper,
  entryIdProp: true,
  prefetch: ['es-US'],
  spread: true,
}

export default ContentParentNodeGetter(Container, options)
