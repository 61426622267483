import React from 'react'
import PropTypes from 'prop-types'
import ReportsUnitPanel from 'components/ReportsUnitPanel'
import ReportsUnitDetail from 'components/ReportsUnitDetail'
import {
  BodyCopy,
  CsvDownloadLink,
  SpinnerAnimatedIconMedium,
} from 'secondstep-components'
import {
  LeaderTeamUnitReportDetail,
  LeaderTeamUnitReportDownload,
  StaffUnitReportDetail,
  StaffUnitReportDownload,
  StyledAccordion,
  UnitCsvTooltip,
} from './component.styles'
import { ROLE_LEADER, ROLE_STAFF } from './constants'
import { selectors as reportsSelectors } from 'store/reportsManager'
import {
  formatHeader,
  generateDownloadLinkText,
} from 'utils/selaReportsHelpers'

const ReportsUnitCollapsible = ({
  dataTestId,
  entryId,
  fetchReportsUserDetails,
  lessons,
  microlearnings,
  moduleLabel,
  moduleTitle,
  programTrack,
  schoolName,
  selectedLicense,
  trackLabel,
  type,
  unitLabel,
  unitTitle,
  user_details,
  usersCompleted,
  usersInProgress,
  usersNotStarted,
  unitTooltipCopy,
}) => {
  if (!entryId || !schoolName || !type) {
    return <SpinnerAnimatedIconMedium />
  }

  const unitHeader = formatHeader(unitLabel, unitTitle, 'Unit')
  const userDetailsExist = !!user_details?.length > 0
  const { data, filename, headers } =
    reportsSelectors.createCsvData(
      user_details,
      entryId,
      moduleLabel,
      moduleTitle,
      unitLabel,
      schoolName,
      type,
      programTrack,
    ) || {}

  const userRole = programTrack?.includes('staff') ? ROLE_STAFF : ROLE_LEADER
  const { downloadLinkText, fetchLinkText } = generateDownloadLinkText(
    userRole,
    'Unit',
  )

  const UnitReportDownload =
    userRole === ROLE_STAFF
      ? userDetailsExist
        ? StaffUnitReportDownload
        : StaffUnitReportDetail
      : userDetailsExist
      ? LeaderTeamUnitReportDownload
      : LeaderTeamUnitReportDetail

  return (
    <StyledAccordion
      dataTestId={dataTestId}
      title={
        <ReportsUnitPanel
          data-testid={`${dataTestId}-unit-panel`}
          displayTitle={unitHeader}
          trackLabel={trackLabel}
          usersCompleted={usersCompleted}
          usersInProgress={usersInProgress}
          usersNotStarted={usersNotStarted}
        />
      }
    >
      <ReportsUnitDetail
        displayTitle={unitHeader}
        entryId={entryId}
        lessons={lessons}
        microlearnings={microlearnings}
        programTrack={programTrack}
      />
      <UnitReportDownload>
        {!userDetailsExist && (
          <CsvDownloadLink
            asyncOnClick={() => fetchReportsUserDetails(selectedLicense)}
            data={data}
            filename={filename}
            headers={headers}
            isAsync
          >
            {fetchLinkText}
          </CsvDownloadLink>
        )}
        {userDetailsExist && (
          <CsvDownloadLink data={data} filename={filename} headers={headers}>
            {downloadLinkText}
          </CsvDownloadLink>
        )}
        <UnitCsvTooltip content={<BodyCopy>{unitTooltipCopy}</BodyCopy>}>
          <i className="fas fa-question-circle" />
        </UnitCsvTooltip>
      </UnitReportDownload>
    </StyledAccordion>
  )
}

ReportsUnitCollapsible.propTypes = {
  dataTestId: PropTypes.string,
  entryId: PropTypes.string,
  fetchReportsUserDetails: PropTypes.func,
  lessons: PropTypes.array,
  microlearnings: PropTypes.array,
  moduleLabel: PropTypes.string,
  moduleTitle: PropTypes.string,
  programTrack: PropTypes.string,
  schoolName: PropTypes.string,
  selectedLicense: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  trackLabel: PropTypes.string,
  type: PropTypes.string,
  unitLabel: PropTypes.string,
  unitTitle: PropTypes.string,
  unitTooltipCopy: PropTypes.string,
  user_details: PropTypes.array,
  usersCompleted: PropTypes.number,
  usersInProgress: PropTypes.number,
  usersNotStarted: PropTypes.number,
}

export default ReportsUnitCollapsible
