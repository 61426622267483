/* istanbul ignore file */
// we don't unit test SVGs
import React from 'react'

const ToolboxIcon = props => {
  return (
    <svg
      height="16"
      viewBox="0 0 17 16"
      width="17"
      xmlns="http://www.w3.org/2000/svg"
      role="presentation"
      aria-hidden="true"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g
          fill="currentColor"
          fillRule="nonzero"
          transform="translate(-40 -39)"
        >
          <g transform="translate(40 39)">
            <path d="M14.858 4.019h-3.06l.035-1.193c0-1.015-.85-1.833-1.904-1.833H6.444C5.39.993 4.54 1.81 4.54 2.826l-.018 1.193h-3.06c-.765 0-1.377.589-1.377 1.325L.067 8.97h5.83c0-.72-.13-2.127 1.345-2.127h1.802c1.533 0 1.36 1.407 1.36 2.127h5.831l-.017-3.625c.017-.736-.595-1.325-1.36-1.325zm-8.857.041V2.882c0-.213.187-.393.408-.393h3.485c.221 0 .408.18.408.393V4.06H6.001z" />
            <path d="M10.404 10c0 .72-.595 1.03-1.343 1.03H7.259c-.748 0-1.343-.306-1.343-1.026H.085v4.674c0 .736.612 1.325 1.377 1.325h13.396c.765 0 1.377-.589 1.377-1.325V10h-5.831zM9.282 9.5v-1a.5.5 0 00-.5-.5H7.538a.5.5 0 00-.5.5v1a.5.5 0 00.5.5h1.244a.5.5 0 00.5-.5z" />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ToolboxIcon
