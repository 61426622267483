import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledContainer,
  SubTitle,
  DateTitle,
  DateInputWrapper,
  DateRangeWrapper,
  Divider,
  Error,
  ErrorText,
  StyledWarningIcon,
  Title,
  TitleAndSubTitleWrapper,
  TitleWrapper,
  Weeks,
} from './component.styles'
import { startDateOne, startDateTwo, startDateThree } from './data'
import { CalendarInput } from 'secondstep-components'

const PacingDateInput = ({
  title,
  subtitle,
  firstDateName,
  secondDateName,
  thirdDateName,
  handleChange,
  weeks,
  collectionName,
  showThirdDate,
  values,
  errors,
  touched,
}) => {
  return (
    <StyledContainer>
      <TitleWrapper>
        <TitleAndSubTitleWrapper>
          <Title>{title}</Title>
          <SubTitle>{subtitle.toUpperCase()}</SubTitle>
        </TitleAndSubTitleWrapper>
        <Weeks>{weeks}</Weeks>
      </TitleWrapper>
      <Divider />
      <DateRangeWrapper>
        <DateInputWrapper small={showThirdDate}>
          <DateTitle>
            {collectionName} {startDateOne}
          </DateTitle>
          <CalendarInput
            date={values[firstDateName]}
            handleChange={handleChange}
            hasError={touched[firstDateName] && errors[firstDateName]}
            name={firstDateName}
          />
          {touched[firstDateName] && errors[firstDateName] && (
            <Error>
              <StyledWarningIcon />
              <ErrorText>{errors[firstDateName]}</ErrorText>
            </Error>
          )}
        </DateInputWrapper>
        <DateInputWrapper small={showThirdDate}>
          <DateTitle>
            {collectionName} {startDateTwo}
          </DateTitle>
          <CalendarInput
            date={values[secondDateName]}
            handleChange={handleChange}
            hasError={touched[secondDateName] && errors[secondDateName]}
            name={secondDateName}
          />
          {touched[secondDateName] && errors[secondDateName] && (
            <Error>
              <StyledWarningIcon />
              <ErrorText>{errors[secondDateName]}</ErrorText>
            </Error>
          )}
        </DateInputWrapper>
        {showThirdDate && (
          <DateInputWrapper small={showThirdDate}>
            <DateTitle>
              {collectionName} {startDateThree}
            </DateTitle>
            <CalendarInput
              date={values[thirdDateName]}
              handleChange={handleChange}
              hasError={touched[thirdDateName] && errors[thirdDateName]}
              name={thirdDateName}
            />
            {touched[thirdDateName] && errors[thirdDateName] && (
              <Error>
                <StyledWarningIcon />
                <ErrorText>{errors[thirdDateName]}</ErrorText>
              </Error>
            )}
          </DateInputWrapper>
        )}
      </DateRangeWrapper>
    </StyledContainer>
  )
}

PacingDateInput.propTypes = {
  collectionName: PropTypes.string,
  errors: PropTypes.object,
  firstDateName: PropTypes.string,
  handleChange: PropTypes.func,
  secondDateName: PropTypes.string,
  showThirdDate: PropTypes.bool,
  subtitle: PropTypes.string,
  thirdDateName: PropTypes.string,
  title: PropTypes.string,
  touched: PropTypes.object,
  values: PropTypes.object,
  weeks: PropTypes.string,
}

export default PacingDateInput
