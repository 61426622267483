export function getStaticRoutes(path) {
  return [
    `${path}/elementary/elem-program-overview`,
    `${path}/elementary/gradek-lessons`,
    `${path}/elementary/grade1-lessons`,
    `${path}/elementary/grade2-lessons`,
    `${path}/elementary/grade3-lessons`,
    `${path}/elementary/grade4-lessons`,
    `${path}/elementary/grade5-lessons`,
    `${path}/elementary/kindergarten-lessons`,
    `${path}/middle-school/grade6-lessons`,
    `${path}/middle-school/grade7-lessons`,
    `${path}/middle-school/grade8-lessons`,
    `${path}/middle-school/ms-program-overview`,
    `${path}/playlists-and-supports`,
    `${path}/playlists-and-supports/get-started-playlist`,
    `${path}/playlists-and-supports/keep-practicing-playlist`,
    `${path}/supports/grades-k-8`,
    `${path}/supports/sel-for-adults`,
    `${path}/high-school`,
  ]
}
