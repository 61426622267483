import { PROGRAM_SELA, PROGRAM_FIELD_TEST } from './constants'

export function checkSelaProduct(program) {
  const selaMap = {
    [PROGRAM_SELA]: true,
    [PROGRAM_FIELD_TEST]: true,
  }
  return selaMap[program] || false
}

export function getLmsPath(program, selaPath, ftPath) {
  const lmsMap = {
    [PROGRAM_SELA]: selaPath,
    [PROGRAM_FIELD_TEST]: ftPath,
  }

  return lmsMap[program]
}

export function getFtueViewField(program) {
  // we renamed this field in Contentful from ftue --> ftuView
  return program?.ftueView || program?.ftue
}
