import { FlagsComponentRouter } from 'flags'

import OffComponent from './flag.off/index'
import OnComponent from './flag.on/index'

const FlagToggler = FlagsComponentRouter({
  flagSubscriptions: ['hs-admin-training-and-kickoff-data'],
  OnComponent,
  OffComponent,
})

export default FlagToggler
