import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { TextLink } from 'secondstep-components'

export const Wrapper = styled.div`
  font-family: ${themeGet('font.molde.regular')};
  display: flex;
  flex-direction: column;
  text-align: right;
  color: ${themeGet('colors.heading.primary')};

  ${themeGet('breakpoints.tablet')} {
    text-align: left;
    flex-direction: row;
  }
`

export const StyledCompleted = styled.p.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))``

export const StyledInfo = styled.p.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  ${themeGet('breakpoints.tablet')} {
    :after {
      content: '${props => (props.time ? ' | ' + props.time : '')}';
    }
  }
`

export const StyledTime = styled.p.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  ${themeGet('breakpoints.tablet')} {
    visibility: hidden;
  }
`

export const StyledSeparator = styled.div`
  visibility: hidden;

  ${themeGet('breakpoints.tablet')} {
    visibility: visible;
    white-space: pre-wrap;
    :before {
      content: ' | ';
    }
  }
`

export const StyledTextLink = styled(TextLink).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }

  &:focus {
    text-decoration: underline;
  }
`
