import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BaseContainer } from 'secondstep-components'

export const Title = styled.span`
  font-family: ${themeGet('font.sharpSlab.semibold')};
  color: ${themeGet('colors.darkBlue')};
`

export const SubTitle = styled.span`
  font-family: ${themeGet('font.molde.medium')};
  letter-spacing: 1px;
  line-height: 20px;
  font-size: 12px;
  color: ${themeGet('colors.lightGray')};
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 2.5rem;

  ${themeGet('breakpoints.mobile')} {
    margin-bottom: 5px;
  }
`

export const DateRange = styled.span`
  display: flex;
  color: ${themeGet('colors.darkGray')};

  ${themeGet('breakpoints.mobile')} {
    flex-direction: column;

    span {
      line-height: 21px;
    }
  }
`

export const StyledContainer = styled(BaseContainer).attrs(() => ({
  as: 'section',
}))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.875rem 1.25rem;
  margin-top: 1.25rem;
  background-color: ${themeGet('colors.paleGray')};
  min-width: 560px;
  width: 100%;

  ${themeGet('breakpoints.mobile')} {
    flex-direction: column;
    align-items: start;
    min-width: 0;
  }
`
