import React from 'react'
import PropTypes from 'prop-types'
import { StyledModal, StyledCloseButton } from './component.styles'
import Schedule from './Schedule'
import AddDates from './AddDates'
import ReviewPacing from './ReviewPacing'
import EditPacing from './EditPacing'
import BackLink from './BackLink'

const PacingModal = ({
  currentStep,
  handleClose,
  handleContinueButtonClick,
  handleSubmit,
  implementationLevel,
  modals,
  values,
  errors,
  touched,
  handleChange,
  hasApiError,
  goBack,
  isValid,
  unmodifiedDates,
}) => {
  const shouldHaveWideModal = implementationLevel === 2 && currentStep === 3
  const [scheduleData, addDatesData, reviewPacingData, editPacingData] =
    modals || []

  return (
    !!modals && (
      <StyledModal
        dataTestId="step-modal-modal"
        isModalOpen={true}
        modalClose={() => handleClose}
        wide={shouldHaveWideModal}
      >
        {currentStep !== 0 && <BackLink onClick={goBack}>Back</BackLink>}
        <StyledCloseButton
          dataTestId="modal-close-button"
          onClick={handleClose}
        />
        {currentStep === 0 && (
          <Schedule
            data={scheduleData}
            handleContinueButtonClick={handleContinueButtonClick}
          />
        )}
        {currentStep === 1 && (
          <AddDates
            data={addDatesData}
            errors={errors}
            handleChange={handleChange}
            handleContinueButtonClick={handleContinueButtonClick}
            handleSubmit={handleSubmit}
            hasApiError={hasApiError}
            isValid={isValid}
            touched={touched}
            values={values}
          />
        )}
        {currentStep === 2 && (
          <ReviewPacing
            data={reviewPacingData}
            handleClose={handleClose}
            handleContinueButtonClick={handleContinueButtonClick}
            implementationLevel={implementationLevel}
            unmodifiedDates={unmodifiedDates}
          />
        )}
        {currentStep === 3 && (
          <EditPacing
            data={editPacingData}
            errors={errors}
            goBack={goBack}
            handleChange={handleChange}
            handleClose={handleClose}
            handleContinueButtonClick={handleContinueButtonClick}
            handleSubmit={handleSubmit}
            hasApiError={hasApiError}
            implementationLevel={implementationLevel}
            isValid={isValid}
            touched={touched}
            values={values}
          />
        )}
      </StyledModal>
    )
  )
}

PacingModal.propTypes = {
  currentStep: PropTypes.number,
  errors: PropTypes.object,
  goBack: PropTypes.func,
  handleChange: PropTypes.func,
  handleClose: PropTypes.func,
  handleContinueButtonClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  hasApiError: PropTypes.bool,
  implementationLevel: PropTypes.number,
  isValid: PropTypes.bool,
  modals: PropTypes.array,
  touched: PropTypes.object,
  unmodifiedDates: PropTypes.object,
  values: PropTypes.object,
}

export default PacingModal
