import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { TextLink } from 'secondstep-components'

export const Link = styled(TextLink)`
  margin-top: 0.438rem;
`
export const List = styled.ul`
  padding-left: 1.188rem;
  list-style: none;
  margin: 0;
`

export const ListItem = styled.li`
  display: flex;
  &::before {
    content: '•';
    color: ${themeGet('colors.blue')};
    display: inline-block;
    font-size: 2rem;
    margin-left: -1.25rem;
    width: 0.75rem;
    padding-top: 0.313rem;
  }
`
