import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import ClosableModal from 'experienceComponents/ClosableModal'

export const ThumbnailWrapper = styled(Box).attrs(() => ({
  className: 'thumbnail-wrapper',
}))`
  position: relative;
  width: 100%;
  svg {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1rem;
  }
  img {
    cursor: zoom-in;
  }
`

export const StyledClosableModal = styled(ClosableModal).attrs(props => ({
  ...props,
  overlayBgColor: themeGet('colors.modals.overlay')(props),
}))``

export const ImageCalloutButton = styled.a`
  border-radius: 0.75rem;
  display: flex;

  svg {
    transition: all 0.2s ease;
    color: ${themeGet('colors.blue')};
  }

  &:focus,
  &:hover {
    cursor: pointer;

    svg {
      color: ${themeGet('colors.orange')};
    }
  }

  ${themeGet('breakpoints.mobileLarge')} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.25rem;
  }
`
