import React from 'react'
import PropTypes from 'prop-types'
import H3LinkList from 'experienceComponents/H3LinkList'
import VideoCallout from 'experienceComponents/VideoCallout'
import {
  ContentBodyCopy,
  ContentHeaderOne,
  ContentOverviewWrapper,
  TextWrapper,
  VideoWrapper,
} from './component.styles.js'

const ContentOverview = ({
  title,
  description,
  entryId,
  listProps,
  videoProps,
  locale,
}) => {
  const dataTestIdPrefix = `content-overview`
  const dataTestIdHeader = `${dataTestIdPrefix}-header`
  const dataTestIdText = `${dataTestIdPrefix}-description`
  const dataTestIdVideo = `${dataTestIdPrefix}-video`

  const videoUrlIsValid = videoProps?.videoUrl || videoProps?.videoThumbnailUrl

  return (
    <ContentOverviewWrapper className="content-overview-wrapper">
      <TextWrapper className="text-wrapper">
        <ContentHeaderOne data-testid={dataTestIdHeader} key={dataTestIdPrefix}>
          {title}
        </ContentHeaderOne>
        {description && (
          <ContentBodyCopy data-testid={dataTestIdText} key={dataTestIdText}>
            {description}
          </ContentBodyCopy>
        )}
        {listProps?.links?.length > 0 && (
          <H3LinkList key="h3LinkKey" {...listProps} />
        )}
      </TextWrapper>
      {videoUrlIsValid && (
        <VideoWrapper>
          <VideoCallout
            {...videoProps}
            dataTestId={dataTestIdVideo}
            entryId={entryId}
            refetchLocale={locale}
          />
        </VideoWrapper>
      )}
    </ContentOverviewWrapper>
  )
}

ContentOverview.propTypes = {
  description: PropTypes.string,
  entryId: PropTypes.string,
  listProps: PropTypes.shape({
    header: PropTypes.string,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
    ),
    id: PropTypes.string,
  }),
  locale: PropTypes.string,
  title: PropTypes.string.isRequired,
  videoProps: PropTypes.shape({
    dataTestId: PropTypes.string,
    videoDisplayTitle: PropTypes.string,
    videoLength: PropTypes.string,
    videoThumbnailUrl: PropTypes.string,
    videoUrl: PropTypes.string,
  }),
}

export default ContentOverview
