import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { BodyCopy } from 'secondstep-components'
import { View } from 'react-native'

import StaticHeaderThree from 'components/StaticHeaderThree'

import { BodyWrapper, ArticleStaticHeaderTwo } from './component.styles'

class HeaderBodyCopy extends Component {
  static propTypes = {
    bodyCopy: PropTypes.string,
    h2Text: PropTypes.string,
    h3Text: PropTypes.string,
    id: PropTypes.string.isRequired,
  }

  render() {
    const { h2Text, h3Text, bodyCopy, id } = this.props || {}

    return (
      <View data-testid={`article-header-body-copy-${id}`}>
        {h2Text && (
          <ArticleStaticHeaderTwo dataTestId={`article-header2-${id}`}>
            {h2Text}
          </ArticleStaticHeaderTwo>
        )}
        {h3Text && (
          <StaticHeaderThree dataTestId={`article-header3-${id}`}>
            {h3Text}
          </StaticHeaderThree>
        )}
        <BodyWrapper>
          <BodyCopy>{bodyCopy}</BodyCopy>
        </BodyWrapper>
      </View>
    )
  }
}

export default HeaderBodyCopy
