import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'secondstep-components'
import withSiteContext from 'layers/content/v2/Hocs/withSiteContext'
import { SiteType } from 'layers/content/v2/Hocs/withSiteContext/types'
import { Container } from './component.styles'
import InstanceView from './InstanceView'
import Preview from './Preview'
import EducatorProfile from './EducatorProfile'
import {
  CourseManagerGetter,
  CourseManagerType,
} from 'layers/content/v2/Hocs/CourseManagerGetter'
import { programs } from 'layers/content/v2/hooks/useCourseManager'

const ViewModes = {
  PREVIEW: 'preview',
  INSTANCE: 'instance',
  EDUCATOR_PROFILE: 'educatorProfile',
}

export const Component = props => {
  const { currentSite, setIsHsInstancedView } = props
  const [isModalLocked, setIsModalLocked] = useState(false)

  if (!currentSite) {
    return <ErrorMessage statusCode={404} />
  }

  function resolveViewMode() {
    if (!currentSite.isSetup) {
      setIsHsInstancedView(false)
      return ViewModes.PREVIEW
    }
    if (
      !currentSite.highSchoolEducatorPreferences?.hasUpdatedProfile ||
      isModalLocked
    ) {
      setIsHsInstancedView(false)
      return ViewModes.EDUCATOR_PROFILE
    }
    setIsHsInstancedView(true)
    return ViewModes.INSTANCE
  }

  function renderView() {
    const viewMode = resolveViewMode()
    switch (viewMode) {
      case ViewModes.PREVIEW:
        return <Preview {...props} />
      case ViewModes.INSTANCE:
        return <InstanceView {...props} />
      case ViewModes.EDUCATOR_PROFILE:
        return (
          <EducatorProfile {...props} setIsModalLocked={setIsModalLocked} />
        )
    }
  }

  return <Container>{renderView()}</Container>
}

Component.propTypes = {
  activeTabProgramsAvailable: PropTypes.array,
  courseManager: CourseManagerType,
  currentSite: SiteType,
  displayTitle: PropTypes.string,
  headerDataTestId: PropTypes.string,
  setIsHsInstancedView: PropTypes.func,
  sortedTeachLinks: PropTypes.array,
  staticLinksAvailable: PropTypes.array,
}

Component.displayName = 'HighSchoolProgramCard'

export default CourseManagerGetter(withSiteContext(Component), {
  // this component is only used for High School:
  programResolver: () => programs.highSchool,
})
