import React from 'react'
import PropTypes from 'prop-types'
import { getIconComponent, mapTextToImage } from './helper'
import {
  CardHeaderContainer,
  CardBodyContainer,
  CardBodyContent,
  CardBodyHeader,
  CardHeaderH3,
  MetadataContainer,
  StyledLink,
} from './component.styles'

const CardSection = ({ section }) => {
  const headerImage = mapTextToImage(section?.header)
  console.log('section', section)
  return (
    <div>
      <CardHeaderContainer>
        <img
          alt={section?.headerIcon?.title || 'Header Icon'}
          src={headerImage}
        />
        <CardHeaderH3>{section?.header}</CardHeaderH3>
      </CardHeaderContainer>

      <CardBodyContainer>
        {section?.cards?.map((card, cardIndex) => (
          <StyledLink key={cardIndex} to={card?.route || '#'}>
            <CardBodyContent>
              <CardBodyHeader>{card?.displayTitle}</CardBodyHeader>
              <ul>
                {card?.metadata?.map((meta, metaIndex) => (
                  <li key={metaIndex}>
                    {meta?.programTheme && (
                      <MetadataContainer>
                        {getIconComponent(meta.programTheme)}
                        <span>{meta.programTheme}</span>
                      </MetadataContainer>
                    )}
                    {meta?.caselCompetency && (
                      <MetadataContainer>
                        {getIconComponent(meta.caselCompetency)}
                        <span>{meta.caselCompetency}</span>
                      </MetadataContainer>
                    )}
                  </li>
                ))}
              </ul>
            </CardBodyContent>
          </StyledLink>
        ))}
      </CardBodyContainer>
    </div>
  )
}

CardSection.propTypes = {
  section: PropTypes.shape({
    header: PropTypes.string,
    headerIcon: PropTypes.shape({
      url: PropTypes.string,
      title: PropTypes.string,
    }),
    cards: PropTypes.arrayOf(
      PropTypes.shape({
        displayTitle: PropTypes.string,
        route: PropTypes.string,
        metadata: PropTypes.arrayOf(
          PropTypes.shape({
            programTheme: PropTypes.string,
            caselCompetency: PropTypes.string,
          }),
        ),
      }),
    ),
  }).isRequired,
}

export default CardSection
