import variables from './variables'

import my_banner1x from '../static/img/my_banner1x.png'
import my_banner2x from '../static/img/my_banner2x.png'
import my_sprite from '../static/img/my_sprite_yeti.png'
import video_icon from '../static/img/my_icon_video.svg'

export default {
  meta: { themeName: 'mindyeti' },

  globalNav: {
    background: variables.colors.mindyeti.blue1,
    iconHoverBackground: variables.colors.opacity_20,
  },
  colors: {
    background: variables.colors.mindyeti.teal2,
    primaryHeader: {
      background: variables.colors.mindyeti.blue2,
      bodyText: variables.colors.mindyeti.white,
      activeTabText: variables.colors.mindyeti.white,
      tabText: variables.colors.mindyeti.blue4,
    },
    secondaryHeader: {
      background: variables.colors.mindyeti.white,
      bodyText: variables.colors.mindyeti.blue3,
    },
    secondaryHeaderBodyItem: {
      background: variables.colors.mindyeti.teal2,
      bodyText: variables.colors.mindyeti.blue3,
    },
    modalHeader: {
      titleText: variables.colors.mindyeti.blue3,
    },
    buttons: {
      primaryWatchVideo: variables.colors.mindyeti.teal1,
      hoverWatchVideo: variables.colors.mindyeti.darkTeal,
      primaryPlaySession: variables.colors.mindyeti.blue1,
      hoverPlaySession: variables.colors.mindyeti.blue2,
      textButtonSelected: variables.colors.mindyeti.white,
      textButtonDefault: variables.colors.mindyeti.blue4,
      textButtonHover: variables.colors.mindyeti.blue4,
    },
    links: {
      primary: variables.colors.mindyeti.blue1,
      hover: variables.colors.mindyeti.blue2,
    },
    programCard: {
      background: {
        color: variables.colors.lightBlue,
      },
    },
  },
  banners: {
    myBanner1x: my_banner1x,
    myBanner2x: my_banner2x,
    mySprite: my_sprite,
  },
  icons: {
    videoIcon: video_icon,
  },
  fonts: {
    primaryHeaderBold: variables.font.museoSansRounded.bold,
    primaryHeaderRegular: variables.font.museoSansRounded.regular,
    secondaryHeaderSemiBold: variables.font.museoSansRounded.semibold,
    introCopy: variables.font.molde.regular,
    boldBodyCopy: variables.font.molde.semiBold,
    headerTab: variables.font.molde.medium,
    regularBodyCopy: variables.font.molde.regular,
  },
}
