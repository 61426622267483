import React from 'react'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
} from 'layers/errorHandling/apiError'
import PropTypes from 'prop-types'
import { Loading } from 'secondstep-components'
import { toTitleCase } from 'utils/stringHelpers'
import CoursePreview from './component'
import { toInstanceUnit } from './instanceFaker'

// TODO: This is problematic from an i18n perspective
export function getPageTitleFromSlug(slug) {
  if (slug === 'grade-k') {
    return 'Kindergarten Lessons'
  }
  return toTitleCase(slug.replace(/-/g, ' ') + ' Lessons').trim()
}

export function countLessonsInUnits(units) {
  return units?.reduce((acc, unit) => acc + unit?.lessons?.length, 0) ?? 0
}

export function unitIsOutOfRange(unitIndex, units) {
  return unitIndex < 0 || unitIndex >= units.length
}

export const Container = ({
  entryId,
  acknowledgements,
  match,
  notFound,
  onSelectUnit,
  productName,
  units = [],
  unitIndex = 0,
}) => {
  const title = getPageTitleFromSlug(match?.params?.course ?? '')
  const totalLessons = countLessonsInUnits(units)

  // Convert the course into an LMSClass format
  const unitsInLmsClassFormat = (units ?? []).map(toInstanceUnit)
  // Calculate the starting indexes for each unit as they are counted cumulatively
  for (let i = 0; i < unitsInLmsClassFormat.length - 1; i++) {
    const lastUnit = unitsInLmsClassFormat[i]
    if (!lastUnit.lessonStartingIndex) {
      unitsInLmsClassFormat[0].lessonStartingIndex = 1
    }
    const thisUnit = unitsInLmsClassFormat[i + 1]
    thisUnit.lessonStartingIndex =
      (lastUnit.lessonStartingIndex ?? 0) + lastUnit.lessons.length
  }

  const isStillLoading =
    !units || units.length === 0 || !unitsInLmsClassFormat || !totalLessons

  if (isStillLoading) {
    return <Loading />
  }

  const isOutOfRange = unitIsOutOfRange(unitIndex, units)

  if (isOutOfRange) {
    return notFound
  }

  return (
    <>
      <CoursePreview
        acknowledgements={acknowledgements}
        entryId={entryId}
        match={match}
        onSelectUnit={onSelectUnit}
        productName={productName}
        title={title}
        totalLessons={totalLessons}
        unitEntryId={units?.[unitIndex]?.id}
        unitIndex={unitIndex}
        units={unitsInLmsClassFormat}
      />
    </>
  )
}

Container.propTypes = {
  acknowledgements: PropTypes.object,
  entryId: PropTypes.string.isRequired,
  match: PropTypes.object,
  notFound: PropTypes.element,
  onSelectUnit: PropTypes.func.isRequired,
  productName: PropTypes.string,
  unitIndex: PropTypes.number,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      lessons: PropTypes.array,
    }),
  ),
}

const mapper = entry => {
  return {
    entryId: entry?.id,
    acknowledgements: entry?.acknowledgements,
    units: entry?.units,
    entry,
  }
}

const options = {
  include: 2,
  mapper,
  spread: true,
  entryIdProp: true,
}

export default ContentEntryDataGetter(
  withApiError(Container, [ERROR_TYPE_CONTENTFUL]),
  options,
)
