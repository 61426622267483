import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { PipeIcon } from 'secondstep-components'

import RadioToggle from '../RadioToggle'

export const StyledRadioToggle = styled(RadioToggle)`
  ${PipeIcon} {
    color: ${themeGet('colors.buttons.iconBase')};
    margin: 0 0.5rem;
    vertical-align: middle;
  }

  [role='radio'][aria-checked='true'] {
    color: ${themeGet('colors.buttons.primaryBackgroundHover')};
  }
`
