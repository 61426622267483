import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  operations as reportsOperations,
  selectors as reportsSelectors,
} from 'store/reportsManager'
import AdminHSMonitorView from './component'
import withApiError, {
  ERROR_TYPE_REPORTING,
} from 'layers/errorHandling/apiError'

const AdminHSMonitorViewContainer = ({
  adminEmail,
  fetchHsAdminSnapshot,
  sites,
}) => {
  useEffect(() => {
    if (!Array.isArray(sites) || !sites) {
      fetchHsAdminSnapshot(adminEmail)
    }
  }, [sites])

  if (!Array.isArray(sites)) return null
  return <AdminHSMonitorView site={sites[0]} />
}

const mapStateToProps = state => {
  const {
    userProfileManager: {
      profile: { email },
    },
  } = state
  const hsSitesWithPreferences = reportsSelectors.selectSortedAdminHsSnapshotSites(
    state,
  )

  return {
    adminEmail: email,
    sites: hsSitesWithPreferences,
  }
}

const mapDispatchToProps = {
  fetchHsAdminSnapshot: reportsOperations.fetchHsAdminSnapshot,
}

AdminHSMonitorViewContainer.propTypes = {
  adminEmail: PropTypes.string,
  fetchHsAdminSnapshot: PropTypes.func,
  sites: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        adminsActiveCount: PropTypes.number,
        adminsPendingCount: PropTypes.number,
        currentPacingEndDate: PropTypes.string,
        currentPacingEndDateFormatted: PropTypes.string,
        currentPacingName: PropTypes.string,
        educatorPracticesPathways: PropTypes.object,
        preferences: PropTypes.object,
        schoolTraining: PropTypes.object,
        schoolwidePractices: PropTypes.object,
        schoolwidePracticesPathways: PropTypes.object,
        siteId: PropTypes.number,
        siteName: PropTypes.string,
        studentActivities: PropTypes.object,
        studentActivitiesByGrade: PropTypes.object,
        teachersActiveCount: PropTypes.number,
        teachersPendingCount: PropTypes.number,
        totalUsersCount: PropTypes.number,
      }),
    ), // fully hydrated
    PropTypes.object, // fetching or error
    PropTypes.oneOf([null]), // not hydrated
  ]),
}

export default connect(mapStateToProps, mapDispatchToProps)(
  withApiError(AdminHSMonitorViewContainer),
  [ERROR_TYPE_REPORTING],
)
