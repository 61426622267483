import React from 'react'
import PropTypes from 'prop-types'

import { isPreviewMode } from 'utils/highschoolHelpers'
import {
  COMPLETED_CLASS_NAME,
  NOT_COMPLETED_CLASS_NAME,
  HS_TRAINING_URL,
} from './constants'
import { TraningCompletionIcon } from './component.styles'

const HsTrainingCheckmark = props => {
  const isHsTrainingPage = location.pathname.includes(HS_TRAINING_URL)

  if (isPreviewMode() || !isHsTrainingPage) {
    return null
  }

  const { isComplete } = props || {}
  const className = isComplete ? COMPLETED_CLASS_NAME : NOT_COMPLETED_CLASS_NAME
  return <TraningCompletionIcon className={className} isComplete={isComplete} />
}

HsTrainingCheckmark.propTypes = {
  isComplete: PropTypes.bool,
}

export default HsTrainingCheckmark
