import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CloseButton } from 'secondstep-components'
import {
  ClassCardModalWrapperDelete,
  DeleteButton,
  DeleteCheckBox,
  DeleteMessageWrapper,
  DeleteWarningSpan,
  ModalWrapperDelete,
  StyledButtonsWrapper,
} from './component.styles'
import { CancelButton, ModalHeaderInput } from '../component.styles'
import {
  DELETE_CANCEL,
  DELETE_HEADER,
  DELETE_MESSAGE,
  DELETE_NOTICE,
  DELETE_SUBMIT,
} from './constants'

const propTypes = {
  courseInstanceName: PropTypes.string,
  dataTestId: PropTypes.string,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  isClassCardMenu: PropTypes.bool,
}

const Delete = ({
  courseInstanceName,
  dataTestId,
  handleCancel,
  handleSubmit,
  isClassCardMenu,
}) => {
  const [isDisabled, setIsDisabled] = useState(true)

  const DeleteModal = isClassCardMenu
    ? ClassCardModalWrapperDelete
    : ModalWrapperDelete

  return (
    <DeleteModal dataTestId={dataTestId} isClassCardMenu={isClassCardMenu}>
      <CloseButton dataTestId="button-close" onClick={handleCancel} />
      <ModalHeaderInput dataTestId="modal-delete-header">
        {DELETE_HEADER(courseInstanceName)}
      </ModalHeaderInput>
      <DeleteWarningSpan>{DELETE_MESSAGE}</DeleteWarningSpan>
      <DeleteMessageWrapper>
        <span>{DELETE_NOTICE}</span>
      </DeleteMessageWrapper>
      <DeleteCheckBox
        dataTestId={'delete-input'}
        onChange={() => setIsDisabled(!isDisabled)}
        options={['Got it']}
        selected={{}}
      />
      <StyledButtonsWrapper>
        <CancelButton dataTestId={'button-cancel'} onClick={handleCancel}>
          {DELETE_CANCEL}
        </CancelButton>
        <DeleteButton
          dataTestId={'button-save-changes'}
          disabled={isDisabled}
          isDisabled={isDisabled}
          onClick={handleSubmit}
        >
          {DELETE_SUBMIT}
        </DeleteButton>
      </StyledButtonsWrapper>
    </DeleteModal>
  )
}

Delete.propTypes = propTypes

export default Delete
