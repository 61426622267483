import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const ProgressIndicatorWrapper = styled.p`
  position: absolute;
  top: -2rem;
  right: 0;
  z-index: 1;
  font-size: 1rem;
  line-height: 1.375rem;
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('font.molde.regular')};

  ${themeGet('breakpoints.tablet')} {
    position: relative;
    align-self: center;
    top: -1rem;
  }
`
