import { ERROR_TYPES } from 'layers/content/constants'
import { createContentStoreKey } from './helpers'

const selectContent = state => state.contentServiceManager.content

const selectContentById = (id, locale) => state => {
  const contentStoreKey = createContentStoreKey(id, locale)
  const { contentServiceManager } = state

  const data = contentServiceManager?.content?.[contentStoreKey]?.['data']
  const prevFetchedUserContext =
    contentServiceManager?.content?.[contentStoreKey]?.['userContext']
  const error = state.contentServiceManager?.error
  const loading = state.contentServiceManager?.isFetchingContent

  const contentError =
    error ||
    (!loading &&
      !error &&
      data &&
      typeof data === 'object' &&
      Object.keys(data).length === 0)
      ? new Error('Content not found')
      : undefined

  return {
    data,
    prevFetchedUserContext,
    error: contentError
      ? {
          message: contentError,
          errorType: ERROR_TYPES.contentServiceError,
        }
      : null,
    loading,
  }
}

export default {
  selectContent,
  selectContentById,
}
