import React from 'react'
import PropTypes from 'prop-types'
import { RouteContentType } from 'layers/content/v2/Hocs/RouteContentGetter'
import pages from 'containers/Pages/pages'

function getContentType(content) {
  if (typeof content !== 'object') {
    throw new Error('getContentType: content must be an object')
  }

  const contentKeys = Object.keys(content)
  const pagesKeys = Object.keys(pages)

  // Get the pages key that matches any of the content key
  const match = contentKeys.find(key => pagesKeys.includes(key))
  return match
}

const FamilyResources = props => {
  const { routeContent } = props
  const contentType = getContentType(routeContent.content)
  const Page = pages[contentType]

  return Page ? <Page {...props} /> : null
}

FamilyResources.propTypes = {
  routeContent: RouteContentType(PropTypes.object),
}

export default FamilyResources
