import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import NavigationHelper from 'layers/navigation/navigationHelper'
import ContentParentNodeGetter from 'layers/content/Hocs/ContentParentNodeGetter'

import CourseDetailsPage from './CourseDetailsPage'
import { processTabRoutes, getLastSlug } from './data'
import {
  CONTENT_TYPE_COURSE_SLM,
  CONTENT_TYPE_KIT_TEACH,
  TEACH,
} from './constants'

export const CourseDetailsPageContainer = ({
  entry,
  parentEntry,
  location,
  navigationEvent,
}) => {
  const {
    activities = [],
    displayTitle,
    description,
    internalTitle,
    coursePdfs = [],
    lessons = [],
    contentType: contentTypeContainer,
  } = entry || {}
  const { id: contentType } = contentTypeContainer || {}
  const showCourse = contentType === CONTENT_TYPE_COURSE_SLM
  const { pathname } = location
  const lastRoute = getLastSlug(pathname)

  let gradeOptions
  if (contentType === CONTENT_TYPE_KIT_TEACH && lastRoute === TEACH) {
    gradeOptions = processTabRoutes(entry)
  } else if (contentType === CONTENT_TYPE_COURSE_SLM) {
    gradeOptions = processTabRoutes(parentEntry)
  }

  React.useEffect(() => {
    if (contentType === CONTENT_TYPE_KIT_TEACH && lastRoute === TEACH) {
      const [firstChoice] = gradeOptions || []
      const { route: firstChildRoute } = firstChoice || {}
      const currentRouteAndFirstChild = `${pathname}/${firstChildRoute}`
      navigationEvent(currentRouteAndFirstChild)
    }
  }, [entry, location, parentEntry])

  return (
    <>
      {showCourse && (
        <CourseDetailsPage
          activities={activities}
          coursePdfs={coursePdfs}
          currentGrade={internalTitle}
          description={description}
          displayTitle={displayTitle}
          gradeOptions={gradeOptions}
          lessons={lessons}
          navigationEvent={navigationEvent}
          pathname={pathname}
        />
      )}
    </>
  )
}

CourseDetailsPageContainer.propTypes = {
  entry: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
  navigationEvent: PropTypes.func,
  parentEntry: PropTypes.object,
}

const navigationConnect = connect(
  NavigationHelper.mapStateToProps,
  NavigationHelper.mapDispatchToProps,
)
export default ContentParentNodeGetter(
  CourseDetailsPageContainer,
  {
    include: 2,
  },
  navigationConnect,
)
