import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  StyledHeader4,
  StyledParagraph,
  SectionWrapper,
  StatusColumn,
  NextColumn,
  MarkCompleteButton,
  NextSectionButton,
  MarkCompleteIcon,
  MarkCompleteButtonWrapper,
  CompletionCommentary,
} from './component.styles'

export default function MarkCompleteSection({
  buttonText = 'Mark Complete',
  completeText = 'Complete',
  completionCommentaryComplete = undefined,
  completionCommentaryTodo = undefined,
  handleClickNext = () => {},
  handleToggleComplete = () => {},
  hasPermissionsToComplete = true,
  id,
  isAlreadyComplete,
  nextSectionButtonText = undefined,
  nextSectionPromptText = undefined,
  promptText,
  shoulDisplayCompletionDetails = false,
}) {
  const [isComplete, setIsComplete] = useState(false)

  function toggleComplete() {
    // TODO: for Delivery 4, re-enable the ability to toggle complete back to false:
    if (isComplete || !hasPermissionsToComplete) {
      return
    }

    const newStatus = !isComplete
    setIsComplete(newStatus)
    handleToggleComplete(newStatus)
  }

  useEffect(() => {
    if (isAlreadyComplete) {
      setIsComplete(isAlreadyComplete)
    }
  }, [isAlreadyComplete])

  return (
    <SectionWrapper id={id}>
      <StatusColumn>
        <StyledHeader4>{promptText}</StyledHeader4>
        <MarkCompleteButtonWrapper>
          <MarkCompleteButton
            hasPermissionsToComplete={hasPermissionsToComplete}
            isComplete={isComplete}
            onClick={toggleComplete}
          >
            {(hasPermissionsToComplete || isComplete) && (
              <MarkCompleteIcon isComplete={isComplete} />
            )}
            {isComplete ? completeText : buttonText}
          </MarkCompleteButton>
          {(!!completionCommentaryTodo || !!completionCommentaryComplete) &&
            shoulDisplayCompletionDetails && (
              <CompletionCommentary isComplete={isComplete}>
                {isComplete
                  ? completionCommentaryComplete
                  : completionCommentaryTodo}
              </CompletionCommentary>
            )}
        </MarkCompleteButtonWrapper>
      </StatusColumn>
      {hasPermissionsToComplete &&
        (!!nextSectionButtonText || !!nextSectionPromptText) && (
          <NextColumn>
            {!!nextSectionPromptText && (
              <StyledParagraph>{nextSectionPromptText}</StyledParagraph>
            )}
            {!!nextSectionButtonText && (
              <NextSectionButton
                isComplete={isComplete}
                onClick={handleClickNext}
              >
                {nextSectionButtonText}
              </NextSectionButton>
            )}
          </NextColumn>
        )}
    </SectionWrapper>
  )
}

MarkCompleteSection.propTypes = {
  buttonText: PropTypes.string,
  completeText: PropTypes.string,
  completionCommentaryComplete: PropTypes.string,
  completionCommentaryTodo: PropTypes.string,
  handleClickNext: PropTypes.func,
  handleToggleComplete: PropTypes.func,
  hasPermissionsToComplete: PropTypes.bool,
  id: PropTypes.string,
  isAlreadyComplete: PropTypes.bool,
  nextSectionButtonText: PropTypes.string,
  nextSectionPromptText: PropTypes.string,
  promptText: PropTypes.string,
  shoulDisplayCompletionDetails: PropTypes.bool,
}
