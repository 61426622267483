import { ROOT_ENTRY_ID } from 'secondstep-learn/src/layers/content/Hocs/RootContent/data.js'
export const HOSTING_APP = Object.freeze({
  IDP: 1,
  SECOND_STEP: 2,
  DNN: 3,
})

export const SECONDSTEP_CDN = '//cdn.secondstep.org/<_ENV_>'
export const ENV = '<_ENV_>'
export const maxClasses = 40
export const ROLES = {
  ADMIN: 'Admin',
  COORDINATOR: 'Coordinator',
  FAMILY: 'Family',
  REPORTER: 'Reporter',
  SETUP_ADMIN: 'Set-Up Admin',
  SITE_LEADER: 'Site Leader',
  TEACHER: 'Teacher',
}

export const CONTENTFUL_ENDPOINT = 'GetContentfulResource'

export const CACHE_STORAGE_KEY = 'secondstep'
export const CFC_TEMP_STORAGE_KEY = 'CFC_TEMP_STORAGE_KEY'

export const CONTENTFUL_IDS = [
  // Dashboard
  ROOT_ENTRY_ID,
]

export const MAX_FETCH_LEVELS = process.env.MAX_FETCH_LEVELS || 3

export const INCLUDE_ALLOWLIST = [
  { entryId: '4evS2d4N8ZmLoNf6IJAzE8', includeLimit: 4 },
  { entryId: '41YPUkFllPN00shovh0DGM', includeLimit: 4 },
  { entryId: '7y3ECPHgDw0hVGVTObivbi', includeLimit: 4 },
  { entryId: '4iIwgKbkFBQa2uJV03Sn6U', includeLimit: 4 },
  { entryId: '4qXR1n1EQnagsFvLHXc9Yb', includeLimit: 4 },
  { entryId: '18fBS1b76VIILYmyqOqr3q', includeLimit: 4 },
  { entryId: 'xmAtSdPiQ9aC4BHitJFD6', includeLimit: 4 },
  { entryId: '1wwVGaOIoIthBK4uKW7wQO', includeLimit: 10 },
  { entryId: '5mw9Oyw1BCHvHQr19NQ429', includeLimit: 4 },
  { entryId: '2cMi4u2o8ORQqYYx8tDC7w', includeLimit: 4 },
  { entryId: '1287k8H7iCyN28FcRN9H89', includeLimit: 4 },
  { entryId: '7D1J37lweBXJHL8TKisOUX', includeLimit: 4 },
  { entryId: '54xrGXfHwHuN8vYDABp8sG', includeLimit: 4 },
  { entryId: '1C7PPDMEdNwEKkin5YxjqR', includeLimit: 4 },
  { entryId: '1qkkuG7p9pRQQxbppZzZrv', includeLimit: 4 },
  { entryId: '6F1ZiBbFO7rhwREfSLUKu4', includeLimit: 4 },
  { entryId: '4xRVSEiT5Ay22wj2zTWChe', includeLimit: 4 },
  { entryId: '4chp6raFV0SJMbyB3j5tQy', includeLimit: 4 },
  { entryId: '2RlGRd100YoikjakabLj2b', includeLimit: 4 },
  { entryId: '4ymSHfoTham495uZOBjtQc', includeLimit: 4 },
  { entryId: '4halnwbN2G2001UAxrabuA', includeLimit: 4 },
  { entryId: '3dW2WwVUaTQUkBP882Q17I', includeLimit: 4 },
  { entryId: '1sMEXxWmuceshOCEk3Dk6O', includeLimit: 4 },
  { entryId: '2YzkjGBt15lyc0p4AAX97T', includeLimit: 4 },
  { entryId: '518162liimCo5tVkgbJEUZ', includeLimit: 4 },
  { entryId: '1tbPJakunUAOnlkr4KkCbm', includeLimit: 4 },
  { entryId: '5Cu8TecBFzCQjOBJnjDCYT', includeLimit: 4 },
  { entryId: 'Z6mjv5LPZb7LrmJWtMWlo', includeLimit: 4 },
  { entryId: '7CCziD1yYjBSTtfEGew8QY', includeLimit: 4 },
  { entryId: '64DvTbtpWOqny803ftgnD2', includeLimit: 4 },
  { entryId: '3KVQRtOqLZCxcX2VHD6aom', includeLimit: 4 },
  { entryId: '5y64xxiJizB4gdwDg7q4EJ', includeLimit: 4 },
  { entryId: '1QBuC6ophV6IMrEow6kGLC', includeLimit: 4 },
  { entryId: '3Q0HJOMmt27Cx816IFVY2l', includeLimit: 4 },
  { entryId: '5vYejJe7Fxq0OQO15nvb0v', includeLimit: 4 },
  { entryId: '1DaqR1YYXt5HTJ8VJsMaSM', includeLimit: 4 },
  { entryId: '6dOltLP9wNZlZeBHtGBvSd', includeLimit: 4 },
  { entryId: '4mPy5gjRdhDTs69L08RpoX', includeLimit: 4 },
  { entryId: '5vSd2Gl4phLySbMABCSzX', includeLimit: 4 },
  { entryId: '52ztCWITzt0SlASdgTKLPr', includeLimit: 4 },
  { entryId: 'oZL2DBqeG2gRbWOVcZWEY', includeLimit: 4 },
  { entryId: '2TH7kNemWW4I2Os2SFOorf', includeLimit: 4 },
  { entryId: '5yD4l8VauPsYZe4sRYwTeW', includeLimit: 4 },
  { entryId: '7g4hnCiCE3m9jjMcT7NnZg', includeLimit: 4 },
  { entryId: '2re6jmz2QR53poVSbAjL8V', includeLimit: 4 },
  { entryId: 'EwXrq3ivbiHLqtKxj2Rpr', includeLimit: 4 },
  { entryId: '5uzoNZBJBLALrrT08wDI3l', includeLimit: 4 },
  { entryId: '73rirO54XqwJCwQdDExWIr', includeLimit: 4 },
  { entryId: '1tbPJakunUAOnlkr4KkCbm', includeLimit: 4 },
  { entryId: '5Cu8TecBFzCQjOBJnjDCYT', includeLimit: 4 },
  { entryId: 'Z6mjv5LPZb7LrmJWtMWlo', includeLimit: 4 },
  { entryId: '2xAjynuvoiUNyWHvv67xmZ', includeLimit: 4 },
  { entryId: '1PfCTdI1DKdSBelqG7Xlax', includeLimit: 4 },
  { entryId: '21nh48UXG8tUjT0CD2k9r5', includeLimit: 4 },
  { entryId: '2i4Gkf8dZitOknmV0FNNaG', includeLimit: 4 },
  { entryId: '1Yzo0wA0bfbYmI9eDFtJuk', includeLimit: 4 },
  { entryId: '35w5YlZgFxK3asFoNNYttv', includeLimit: 4 },
  { entryId: 'n5q2Wc5GtuBdRY7TcNZM9', includeLimit: 4 },
  { entryId: '38bHP1HeWmPABYWjBLrqT', includeLimit: 4 },
  { entryId: '6V73lAJ8HGEUPKGRnVro2n', includeLimit: 4 },
  { entryId: '5KZg2qFM2CO1KEsfjYpxQT', includeLimit: 4 },
  { entryId: '3fSAMxcTuMEtDpbXkRVFQb', includeLimit: 4 },
  { entryId: '6Gi3yrVZxCgDK1rslGvtje', includeLimit: 4 },
  { entryId: '6FnAsF2MxOrrV4XXHK0onF', includeLimit: 4 },
  { entryId: '5xvnH2fewgGlb8I9hBeyOb', includeLimit: 4 },
  { entryId: '5LbU9PKDn8yQTFBYIhD2Ft', includeLimit: 4 },
  { entryId: 'eow6VclsxiicjSnLATM39', includeLimit: 4 },
  { entryId: '5MQDsJF3pBU7ebq0pzE19d', includeLimit: 4 },
  { entryId: '44KeCEgXbqLuPbDl36pHt0', includeLimit: 4 },
  { entryId: '73fbXDrfQZT3T7jnFEnRNN', includeLimit: 4 },
  { entryId: '7u9tnVihoPGhwYgE1rj2fV', includeLimit: 4 },
  { entryId: '5oqdbnI289AADFVmm2sJyx', includeLimit: 5 },
  { entryId: '4ZErlVNUbLdWB4MJvEByfs', includeLimit: 5 },
  { entryId: '1e9atmVygAbjnzJOURCPDo', includeLimit: 5 }, // old K8 implementation guide entryId; should be removed once new implementation guide is live
  { entryId: '6yktKzt8VIc5Hn57xyTkmF', includeLimit: 5 }, // old HS implementation guide entryId; should be removed once new implementation guide is live
  { entryId: 'qxnIojrDaH27CjKZzc6zC', includeLimit: 5 },

  // Add these to list due to Family Resources Program Flex Migration https://secondstep.atlassian.net/browse/LEARN-17909.
  // Perhaps can be removed after https://secondstep.atlassian.net/browse/LEARN-18413
  { entryId: '6yWZX4VnPPe8jOXlxtY0Ia', includeLimit: 4 }, // allow family-resources/middle-school/grade6-lessons/intro to fetch 4 levels deep
  { entryId: '4WVuXfGU3ec71kHURCxbjo', includeLimit: 4 }, // allow family-resources/middle-school/grade6-lessons/g6-recognizing-bullying-and-harassment to fetch 4 levels deep
  { entryId: '6m4XHk7vSJ50SWuvpOY78Y', includeLimit: 4 }, // allow family-resources/middle-school/grade7-lessons/intro to fetch 4 levels deep
  { entryId: '6JNH3gXggCGviMtVwZndXN', includeLimit: 4 }, // allow family-resources/middle-school/grade7-lessons/g7-recognizing-bullying-and-harassment to fetch 4 levels deep
  { entryId: '1jwjR5ztRpaVZpCcga2HHz', includeLimit: 4 }, // allow family-resources/middle-school/grade8-lessons/g8-recognizing-bullying-and-harassment
  { entryId: '7aC12SMh0Svv1Bl01dqWX0', includeLimit: 4 }, // allow family-resources/elementary/grade1-lessons/intro to fetch 4 levels deep
  { entryId: '3jrSrWCgdB2IOPA12dlRnv', includeLimit: 4 }, // allow family-resources/elementary/grade2-lessons/intro
  { entryId: '1nBCfK9HXSxqJKST25NKQf', includeLimit: 4 }, // allow family-resources/elementary/grade3-lessons/intro
  { entryId: '7B9dQ0BcUbEUULGqU3TRrs', includeLimit: 4 }, // allow family-resources/elementary/grade4-lessons/intro
  { entryId: '2Kow0O1Xd64k6C3yUgYwaB', includeLimit: 4 }, // allow family-resources/elementary/grade5-lessons/intro

  { entryId: '1DkX7SHS51zspEH3OPYK1g', includeLimit: 4 }, // allow /product/k5-kit/families/gr-k/family-activities-k/family-brain-builders
  { entryId: '5Q2e8iqAeybeRe7IDaH7NB', includeLimit: 4 }, // allow /product/k5-kit/families/gr-4/family-activities-4/music-4-5
]

export const INCLUDE_ERROR = `You're making a request with an include level greater than ${MAX_FETCH_LEVELS}. Check the README for more details.`
