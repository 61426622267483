import React, { Fragment } from 'react'
import { BrainBuilderIcon, SongIcon } from 'secondstep-components'
import PropTypes from 'prop-types'

import { StyledHeaderTwo } from '../LessonDetail/component.styles'
import LessonMaterialsList from '../LessonMaterialsList/component'

import {
  ActivitiesBox,
  ChildSpacer,
  Container,
  Description,
  Overview,
  StyledLessonMaterialList,
  ViewWrapper,
} from './component.styles'
import LabelButton from './LabelButton'
import { BRAIN_BUILDER_ICON, LESSON_MATERIALS_TITLE } from './constants'

const GradeSummary = ({
  title,
  description,
  activities = [],
  materials = [],
  navigationEvent,
}) => {
  const hasActivities = activities.length !== 0
  const hasMaterials = materials.length !== 0

  const activityButtons = activities.map((item, index) => {
    const { icon, id, displayTitle, locale, url } = item
    const iconComponent =
      icon === BRAIN_BUILDER_ICON ? (
        <BrainBuilderIcon ariaHidden role="presentation" />
      ) : (
        <SongIcon ariaHidden role="presentation" />
      )
    const addLocale = locale ? `-${locale}` : ''

    return (
      <Fragment key={id}>
        {index !== 0 && <ChildSpacer />}
        <LabelButton
          dataTestId={`label-button-${index}${addLocale}`}
          icon={iconComponent}
          labelText={displayTitle}
          onClick={() => navigationEvent(url)}
        />
      </Fragment>
    )
  })

  return (
    <Container data-testid="grade-summary-section">
      <Overview>
        <StyledHeaderTwo>{title}</StyledHeaderTwo>
        <ViewWrapper>
          <Description>{description}</Description>
          {hasActivities && <ActivitiesBox>{activityButtons}</ActivitiesBox>}
        </ViewWrapper>
      </Overview>
      {hasMaterials && (
        <StyledLessonMaterialList>
          <LessonMaterialsList
            materials={materials}
            title={LESSON_MATERIALS_TITLE}
          />
        </StyledLessonMaterialList>
      )}
    </Container>
  )
}

GradeSummary.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      displayTitle: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  description: PropTypes.string,
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      internalTitle: PropTypes.string,
      displayTitle: PropTypes.string,
      file: PropTypes.shape({
        title: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  ),
  navigationEvent: PropTypes.func,
  title: PropTypes.string,
}

export default GradeSummary
