import { connect } from 'react-redux'
import {
  getProgramSnapshot,
  SNAPSHOTS_NAME_MAP,
} from 'utils/adminExperienceHelpers'
import { ADMIN_DASHBOARD_ENTRY_ID } from 'utils/entryIds'
import GetStartedPanel from './component'

const mapStateToProps = ({ contentful }, { programKey }) => {
  const snapshotFieldName = SNAPSHOTS_NAME_MAP[programKey]
  const snapshots =
    contentful?.[ADMIN_DASHBOARD_ENTRY_ID]?.payload?.[snapshotFieldName] || []
  const { getStartedPanel } = getProgramSnapshot(programKey, snapshots) || {}
  const { displayTitle } = getStartedPanel || {}
  let tabColumns = []

  for (let key in getStartedPanel) {
    if (key.startsWith('tabColumn')) {
      tabColumns.push(getStartedPanel[key][0])
    }
  }

  return {
    displayTitle,
    tabColumns,
  }
}

export default connect(mapStateToProps)(GetStartedPanel)
