import React from 'react'
import { connect } from 'react-redux'
import AdminRouter from './component'

const AdminRouterContainer = props => {
  return <AdminRouter {...props} />
}

const mapStateToProps = ({
  userSessionManager: {
    adminDashboard: {
      hs: { toggleView: hsToggleView },
      k8: { toggleView: k8ToggleView },
    },
  },
}) => ({
  hsToggleView,
  k8ToggleView,
})

export default connect(mapStateToProps)(AdminRouterContainer)
