import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  CategoryCalloutDisplayWrapper,
  CategoryCalloutStyles,
  CalloutsWrapper,
  CategoryCalloutDisplayHeader,
} from './component.styles.js'
import CategoryCallout from '../CategoryCallout'

class CategoryCalloutDisplay extends Component {
  static propTypes = {
    categoryInfo: PropTypes.arrayOf(
      PropTypes.shape({
        Icon: PropTypes.elementType,
        goToLocation: PropTypes.func,
        location: PropTypes.object,
        text: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
    dataTestId: PropTypes.string,
    displayHeaderText: PropTypes.string.isRequired,
  }

  render() {
    const {
      categoryInfo,
      dataTestId = 'default-id',
      displayHeaderText,
    } = this.props

    const dataTestIdPrefix = 'category-callout-display'

    const dataTestIdWrapper = `${dataTestIdPrefix}-wrapper-${dataTestId}`
    const dataTestIdHeader = `${dataTestIdPrefix}-header-${dataTestId}`
    const isTwoColumnLayout = categoryInfo.length === 2

    return (
      <CategoryCalloutDisplayWrapper dataTestId={dataTestIdWrapper}>
        <CategoryCalloutDisplayHeader dataTestId={dataTestIdHeader}>
          {displayHeaderText}
        </CategoryCalloutDisplayHeader>
        <CalloutsWrapper>
          {categoryInfo.map((categoryProps, key) => {
            return (
              <CategoryCalloutStyles
                index={key}
                isTwoColumnLayout={isTwoColumnLayout}
                key={categoryProps.title}
              >
                <CategoryCallout
                  dataTestId={`${dataTestId}-${key}`}
                  {...categoryProps}
                />
              </CategoryCalloutStyles>
            )
          })}
        </CalloutsWrapper>
      </CategoryCalloutDisplayWrapper>
    )
  }
}

export default CategoryCalloutDisplay
