import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const ImageWrapper = styled.div`
  display: flex;
  overflow: hidden;
  object-fit: contain;
  overflow: hidden;
  justify-content: center;

  ${themeGet('breakpoints.mobileLarge')} {
    align-items: center;
  }

  .zoom {
    min-width: 100%;
  }
  .grab-cursor {
    cursor: grabbing;
  }

  .pan-in-cursor {
    cursor: zoom-in;
  }

  .pan-out-cursor {
    cursor: zoom-out;
  }

  .react-transform-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    user-select: none;
    margin: 0;
    padding: 0;

    .react-transform-component {
      display: flex;
      flex-wrap: wrap;
      width: inherit;
      height: inherit;
      margin: 0;
      padding: 0;
      transform-origin: 0% 0%;
      justify-content: center;

      img {
        object-fit: scale-down;
      }
    }

    ${themeGet('breakpoints.mobileLarge')} {
      .react-transform-component {
        height: unset;
        img {
          object-fit: unset;
        }
      }
    }
  }
`

export const ZoomButton = styled.button`
  border: 2px solid ${themeGet('colors.borders.primary')};
  background: white;
  height: 2.688rem;
  width: 2.688rem;
  display: flex;
  place-items: center;
  place-content: center;
  border-radius: 8px;
  padding: 0;
  color: ${themeGet('colors.buttons.outlined.label', '#677F1A')};

  &:hover {
    color: ${themeGet('colors.buttons.outlined.labelHover', '#C62D70')};
    cursor: pointer;
  }

  &[disabled],
  :disabled {
    border-color: ${themeGet('colors.gray500')};
    color: ${themeGet('colors.gray500')};
    cursor: not-allowed;
    pointer-events: none;
  }
`

export const ZoomButtonGroup = styled.div`
  width: 2.688rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: absolute;
  left: 1.25rem;
  bottom: 1.25rem;

  ${themeGet('breakpoints.mobileLarge')} {
    display: none;
  }
`
