import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { HeaderTwo, TextLink } from 'secondstep-components'

export const SelaTitle = styled(HeaderTwo)`
  font-size: 1.75rem;
  font-family: ${themeGet('font.lufgaCFC.bold')};
`

export const StyledTextLink = styled(TextLink)`
  display: block;
  color: inherit;
  font-family: ${themeGet('font.sharpSlab.semibold')};
  svg {
    vertical-align: middle;
    margin: -0.25rem 0.75rem 0;
    g > g {
      stroke: ${({ theme }) =>
        theme.meta.themeName === 'elementary'
          ? themeGet('colors.elemDigital1')
          : themeGet('colors.blue')};
    }
  }

  sup {
    font-size: 0.75rem;
    vertical-align: super;
  }

  &:hover,
  :focus {
    color: inherit;
    svg {
      g > g {
        stroke: ${themeGet('colors.darkBlue')};
      }
    }
  }
`
