import styled, { withTheme } from 'styled-components'

import styledNative from 'styled-components/native'
import { themeGet } from 'styled-system'
import StaticHeaderTwo from 'components/StaticHeaderTwo'

export const BodyWrapper = withTheme(styledNative.View`
  margin-top: 0.438rem;
  color: ${themeGet('colors.darkGray')};
`)

export const ArticleStaticHeaderTwo = styled(StaticHeaderTwo)`
  margin-top: 1.75rem;
`
