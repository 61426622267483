import FeedbackCollection from '@cfc/feedback-collection'
import { domains } from 'config/env'
import { ROLES } from 'config/constants'
import CacheApiUtil from 'lib/middleware/cacheApiUtil'
import Auth0Manager from 'auth/utils/auth0Manager'
import { clearLatestDashboardTab } from 'utils/highschoolHelpers'
import HelpIcon from './assets/ss_icon_help'
import AdminInsightsIcon from './assets/ss_icon_leaderdashboard.jsx'
import LicenseManagementIcon from './assets/ss_icon_licensemanagement.jsx'
import LogoutIcon from './assets/ss_icon_logout.jsx'
import MyDashboardIcon from './assets/ss_icon_myprograms.jsx'
import UserManagementIcon from './assets/ss_icon_usermanagement.jsx'

const getItem = (listItem, { navigateLeaveApp, navigateToPage }) => {
  return listItem.map(item => {
    const { link, extend, leaveApp } = item

    const navToPage = () => navigateToPage(link)
    const navToLeaveApp = () => navigateLeaveApp(link)

    let itemWithNavAction = item

    const action = leaveApp ? navToLeaveApp : navToPage
    const onPress = extend
      ? (...args) => {
          extend()
          action(...args)
        }
      : action

    itemWithNavAction.onPress = onPress

    return itemWithNavAction
  })
}

const getNavigationItems = (navigationObject, roles, firstName, lastName) => {
  const redirectUrl = domains.PUBLIC_SITE_DOMAIN
  const isSetupAdmin = roles.includes(ROLES.SETUP_ADMIN)
  const isAdmin = isSetupAdmin || roles.includes(ROLES.ADMIN)

  const HELP = 'Help'
  const LOG_OUT = 'Log Out'
  const MY_PROGRAMS = 'My Programs'
  const LEADER_DASHBOARD = 'Leader Dashboard'
  const LICENSE_MANGEMENT = 'License Management'
  const USER_MANAGEMENT = 'User Management'

  const NAME_ITEM = [
    {
      // hack the item format so we can display first name and last name on separate lines:
      firstName: firstName,
      lastName: lastName,
      leaveApp: true,
      link: `${domains.APP_DOMAIN}/account-details`,
    },
  ]

  const NAVIGATION_ITEMS = [
    ...(isAdmin
      ? [
          {
            icon: AdminInsightsIcon,
            iconTopOffset: '0',
            label: LEADER_DASHBOARD,
            leaveApp: true,
            link: '/admin',
          },
        ]
      : []),
    {
      icon: MyDashboardIcon,
      iconTopOffset: '0',
      label: MY_PROGRAMS,
      leaveApp: true,
      link: '/dashboard',
    },
  ]

  const MANAGEMENT_ITEMS = [
    ...(isAdmin
      ? [
          {
            icon: LicenseManagementIcon,
            iconTopOffset: '0.13rem',
            label: LICENSE_MANGEMENT,
            leaveApp: true,
            link: `${domains.ADMIN_DOMAIN}/license-management`,
          },
        ]
      : []),
    ...// IF I am an ADMIN I see user management
    // IF I am a SETUP ADMIN but NOT an ADMIN, I don't see user management
    (roles.includes(ROLES.ADMIN)
      ? [
          {
            icon: UserManagementIcon,
            iconTopOffset: '0.13rem',
            label: USER_MANAGEMENT,
            leaveApp: true,
            link: `${domains.ADMIN_DOMAIN}/user-management`,
          },
        ]
      : []),
  ]

  const ACCOUNT_ITEMS = [
    {
      icon: HelpIcon,
      iconTopOffset: '0',
      label: HELP,
      leaveApp: true,
      link: 'https://support.secondstep.org/',
    },
    {
      extend: () => {
        FeedbackCollection.logout()
        CacheApiUtil.deleteCache()
        // high school-specific:
        clearLatestDashboardTab()
        const auth0 = Auth0Manager.getAuth0Instance()
        auth0.logout({ returnTo: redirectUrl })
      },
      icon: LogoutIcon,
      iconTopOffset: '0',
      label: LOG_OUT,
      leaveApp: false,
    },
  ]

  // function returns an array but only a single one is needed:
  const nameItem = getItem(NAME_ITEM, navigationObject)[0]
  const navigationItems = getItem(NAVIGATION_ITEMS, navigationObject)
  const accountItems = getItem(ACCOUNT_ITEMS, navigationObject)
  const managementItems = getItem(MANAGEMENT_ITEMS, navigationObject)

  return { nameItem, accountItems, managementItems, navigationItems }
}

export default getNavigationItems
