import React from 'react'
import { ErrorBoundary } from 'secondstep-components'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router'
import SchoolSummary from 'pages/SchoolSummary'
import GradeView from 'pages/K8GradeView'
import ReportsNavHeader from 'components/ReportsNavHeader'
import { PageWrapper } from './component.styles'

const K8Report = ({
  entry,
  gradeEmptyDisplay,
  match,
  navigationHandler,
  schoolEmptyDisplay,
}) => {
  const { schoolCsvDownloadTooltip } = entry || {}
  return (
    <ErrorBoundary>
      <PageWrapper>
        <ReportsNavHeader
          navigationHandler={navigationHandler}
          schoolCsvDownloadTooltip={schoolCsvDownloadTooltip}
        />
        <Switch>
          <Route
            path={`${match.path}/:gradeName`}
            render={routeProps => {
              return (
                <GradeView
                  // TODO [LEARN-16417]: treeshake entry to give component only what it needs
                  entry={entry}
                  gradeEmptyDisplay={gradeEmptyDisplay}
                  gradeParam={routeProps.match.params.gradeName}
                  location={routeProps.location}
                  navigationHandler={navigationHandler}
                />
              )
            }}
          />
          <Route
            path={`${match.path}`}
            render={() => (
              <SchoolSummary
                entry={entry}
                navigationHandler={navigationHandler}
                schoolEmptyDisplay={schoolEmptyDisplay}
              />
            )}
          />
        </Switch>
      </PageWrapper>
    </ErrorBoundary>
  )
}

K8Report.propTypes = {
  entry: PropTypes.object,
  gradeEmptyDisplay: PropTypes.object,
  match: PropTypes.object,
  navigationHandler: PropTypes.func,
  schoolEmptyDisplay: PropTypes.object,
  siteId: PropTypes.string,
}

export default K8Report
