/*
  List of redirect routes provided by MS product team for the BTS 2021 updates 
*/
export default [
  {
    originalPath:
      '/product/middle-school/program-supports/get-started/performance-tasks',
    newPath: '/product/middle-school/trainings/performance-tasks',
  },
  {
    originalPath:
      '/product/middle-school/principal-toolkit/plan/build-your-sel-team',
    newPath:
      '/product/middle-school/principal-toolkit/implement-schoolwide/build-your-sel-team',
  },
  {
    originalPath:
      '/product/middle-school/program-supports/get-started/teaching-advisory',
    newPath: '/product/middle-school/trainings/teaching-advisory',
  },
  {
    originalPath:
      '/product/middle-school/principal-toolkit/plan/plan-advisory-program',
    newPath:
      '/product/middle-school/principal-toolkit/implement-schoolwide/plan-advisory-program',
  },
  {
    originalPath:
      '/product/middle-school/principal-toolkit/monitor-reinforce/monitor-implementation',
    newPath:
      '/product/middle-school/principal-toolkit/evaluate-monitor-reinforce/monitor-implementation',
  },
  {
    originalPath:
      '/product/middle-school/principal-toolkit/plan/plan-schoolwide',
    newPath:
      '/product/middle-school/principal-toolkit/implement-schoolwide/plan-schoolwide',
  },
  {
    originalPath:
      '/product/middle-school/principal-toolkit/monitor-reinforce/reinforce-sel-schoolwide',
    newPath:
      '/product/middle-school/principal-toolkit/evaluate-monitor-reinforce/reinforce-sel-schoolwide',
  },
  {
    originalPath: '/product/middle-school/trainings/training-resources',
    newPath: '/product/middle-school/principal-toolkit/all-staff-meetings',
  },
  {
    originalPath:
      '/product/middle-school/principal-toolkit/get-started-leadership/get-started-leader',
    newPath:
      '/product/middle-school/principal-toolkit/implement-schoolwide/get-started-leader',
  },
  {
    originalPath:
      '/product/middle-school/program-supports/get-started/program-overview',
    newPath: '/product/middle-school/trainings/how-the-program-works',
  },
  {
    originalPath:
      '/product/middle-school/principal-toolkit/plan/flexible-learning',
    newPath:
      '/product/middle-school/principal-toolkit/implement-schoolwide/flexible-learning',
  },
  {
    originalPath:
      '/product/middle-school/principal-toolkit/monitor-reinforce/reinforce-sel-families',
    newPath:
      '/product/middle-school/principal-toolkit/partner/family-engagement',
  },
  {
    originalPath: '/product/middle-school/principal-toolkit/plan/evaluate',
    newPath:
      '/product/middle-school/principal-toolkit/evaluate-monitor-reinforce/evaluate',
  },

  {
    originalPath: '/product/elementary/program-supports/engage-families',
    newPath: '/product/elementary/engage-families',
  },
  {
    originalPath:
      '/product/elementary/program-supports/engage-families/family-communications-k',
    newPath: '/product/elementary/engage-families/family-communications-k',
  },
  {
    originalPath:
      '/product/elementary/program-supports/engage-families/family-communications-1',
    newPath: '/product/elementary/engage-families/family-communications-1',
  },
  {
    originalPath:
      '/product/elementary/program-supports/engage-families/family-communications-2',
    newPath: '/product/elementary/engage-families/family-communications-2',
  },
  {
    originalPath:
      '/product/elementary/program-supports/engage-families/family-communications-3',
    newPath: '/product/elementary/engage-families/family-communications-3',
  },
  {
    originalPath:
      '/product/elementary/program-supports/engage-families/family-communications-4',
    newPath: '/product/elementary/engage-families/family-communications-4',
  },
  {
    originalPath:
      '/product/elementary/program-supports/engage-families/family-communications-5',
    newPath: '/product/elementary/engage-families/family-communications-5',
  },
  {
    originalPath:
      '/product/elementary/principal-toolkit/evaluate-monitor-reinforce/reinforce-sel-families',
    newPath: '/product/elementary/principal-toolkit/partner/family-engagement',
  },
  {
    originalPath: '/product/middle-school/program-supports/engage-families',
    newPath: '/product/middle-school/engage-families',
  },
  {
    originalPath:
      '/product/middle-school/program-supports/engage-families/family-communications-6',
    newPath: '/product/middle-school/engage-families/family-communications-6',
  },
  {
    originalPath:
      '/product/middle-school/program-supports/engage-families/family-communications-7',
    newPath: '/product/middle-school/engage-families/family-communications-7',
  },
  {
    originalPath:
      '/product/middle-school/program-supports/engage-families/family-communications-8',
    newPath: '/product/middle-school/engage-families/family-communications-8',
  },
  {
    originalPath:
      '/product/middle-school/program-supports/engage-families/parenteen-connect',
    newPath: '/product/middle-school/engage-families/parenteen-connect',
  },
  {
    originalPath:
      '/product/middle-school/principal-toolkit/evaluate-monitor-reinforce/reinforce-sel-families',
    newPath:
      '/product/middle-school/principal-toolkit/partner/family-engagement',
  },
  {
    originalPath:
      '/admin/implementation-guide/support/monitoring-implementation',
    newPath: '/admin/implementation-guide/support/supporting-all-staff',
  },
  {
    originalPath: '/admin/implementation-guide/support/improving-fidelity',
    newPath:
      '/admin/implementation-guide/support/supporting-lesson-instructors',
  },
]
