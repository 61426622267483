import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, HeaderTwo } from 'secondstep-components'
import ProgressBar from 'components/ProgressBar'

export const ClassHeaderWrapper = styled.div.attrs(props => {
  const { fixedTop, fixedView } = props
  return {
    boxShadow: fixedView
      ? `0 4px 4px ${themeGet('colors.grayBlue')(props)}`
      : 'none',
    padding: fixedView
      ? '1.5rem 1.25rem 1.375rem 1.25rem'
      : '2.125rem 1.25rem 1.3rem 1.25rem',
    paddingTop: fixedView ? '1.5rem' : fixedTop ? '0' : '32px',
    position: fixedTop ? 'fixed' : 'relative',
    zIndex: fixedView ? 200 : -1,
  }
})`
  display: flex;
  flex-direction: column;
  width: 746px;
  position: ${({ position }) => position};
  padding: ${({ padding }) => padding};
  padding-top: ${({ paddingTop }) => paddingTop};
  background: ${themeGet('colors.white')};
  box-shadow: ${({ boxShadow }) => boxShadow};
  z-index: ${({ zIndex }) => zIndex};
  ${themeGet('breakpoints.mobileLarge')} {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1.375rem;
  }
`

export const ClassInfoText = styled(BodyCopy)`
  margin-bottom: 0.4375rem;
  font-family: ${themeGet('font.molde.medium')};
  color: ${themeGet('colors.darkGray')};
`

export const ClassTitle = styled(HeaderTwo)`
  font-size: 1.75rem;
  line-height: 2.3125rem;
  margin-bottom: 0.25rem;
  margin-right: 1.125rem;
  ${themeGet('breakpoints.mobileLarge')} {
    font-size: 1.4375rem;
    line-height: 1.875rem;
    margin-bottom: 0.3125rem;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  ${themeGet('breakpoints.mobileLarge')} {
    width: 90%;
  }
  ${themeGet('breakpoints.mobile')} {
    width: 100%;
  }
`

export const LessonsComplete = styled(BodyCopy)`
  font-size: 0.875rem;
`

export const StyledProgressBar = styled(ProgressBar)`
  width: 100%;
  margin-bottom: 0.1875rem;
  padding: 0;
  #class-progress-bar-text {
    display: none;
  }
  #class-progress-bar-background,
  #class-progress-bar-indicator {
    height: 10px;
  }
  ${themeGet('breakpoints.mobileLarge')} {
    margin-top: 0.625rem;
  }
  ${themeGet('breakpoints.mobile')} {
    width: 100%;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-end;
  width: 100%;
`

export const ProgressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 14px;
  gap: 28px;
`

export const ProgressSection = styled.div`
  width: 100%;
`
