export default {
  FETCHING_VALIDATE_ACTIVATION_CODE:
    'license/FETCHING_VALIDATE_ACTIVATION_CODE',
  FETCHING_VALIDATE_ACTIVATION_CODE_ERROR:
    'license/FETCHING_VALIDATE_ACTIVATION_CODE_ERROR',
  FETCHING_VALIDATE_ACTIVATION_CODE_SUCCESS:
    'license/FETCHING_VALIDATE_ACTIVATION_CODE_SUCCESS',
  FETCHING_SETUP_LICENSES: 'license/FETCHING_SETUP_LICENSES',
  FETCHING_SETUP_LICENSES_ERROR: 'license/FETCHING_SETUP_LICENSES_ERROR',
  FETCHING_SETUP_LICENSES_SUCCESS: 'license/FETCHING_SETUP_LICENSES_SUCCESS',
  FETCHING_ACTIVE_DIGITAL_LICENSES: 'license/FETCHING_ACTIVE_DIGITAL_LICENSES',
  FETCHING_ACTIVE_DIGITAL_LICENSES_ERROR:
    'license/FETCHING_ACTIVE_DIGITAL_LICENSES_ERROR',
  FETCHING_ACTIVE_DIGITAL_LICENSES_SUCCESS:
    'license/FETCHING_ACTIVE_DIGITAL_LICENSES_SUCCESS',
  FETCHING_USER_LICENSES: 'FETCHING_USER_LICENSES',
  FETCHING_USER_LICENSES_ERROR: 'FETCHING_USER_LICENSES_ERROR',
  FETCHING_USER_LICENSES_SUCCESS: 'FETCHING_USER_LICENSES_SUCCESS',
  FETCHING_USER_SITES: 'FETCHING_USER_SITES',
  FETCHING_USER_SITES_ERROR: 'FETCHING_USER_SITES_ERROR',
  FETCHING_USER_SITES_SUCCESS: 'FETCHING_USER_SITES_SUCCESS',
  RESET_STATE: 'license/RESET_STATE',
  SENDING_ACTIVATION_CODE: 'license/SENDING_ACTIVATION_CODE',
  SENDING_ACTIVATION_CODE_ERROR: 'license/SENDING_ACTIVATION_CODE_ERROR',
  SENDING_ACTIVATION_CODE_SUCCESS: 'license/SENDING_ACTIVATION_CODE_SUCCESS',
  SET_NOTIFICATION_AS_READ_ERROR: 'license/SET_NOTIFICATION_AS_READ_ERROR',
  SET_NOTIFICATION_AS_READ_SUCCESS: 'license/SET_NOTIFICATION_AS_READ_SUCCESS',
  UPDATE_SITE: 'license/UPDATE_SITE',
  UPDATE_SITE_PACING_DATES: 'license/UPDATE_SITE_PACING_DATES',
  UPDATE_SITES_MSP_OPT_IN: 'license/UPDATE_SITES_MSP_OPT_IN',
}
