import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'

export const Wrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  position: relative;
`

export const DropdownButton = styled(Button).attrs(
  ({ dataTestId, isOpen }) => ({
    'data-testid': dataTestId,
    degrees: `${isOpen ? 180 : 0}deg`,
    id: dataTestId,
    marginBottom: `${isOpen ? '0.125rem' : '0'}`,
    marginTop: `${isOpen ? '-0.125rem' : '0'}`,
  }),
)`
  border: solid 0.125rem
    ${themeGet('colors.borders.primary', 'colors.beaconGray')};
  width: 100%;
  border-radius: 0.3125rem;
  padding: 0.8125rem 1.0625rem 0.6875rem 0.9375rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 2;

  span {
    padding-right: 0.875rem;
    color: ${themeGet('colors.mediumGray')};
    font-family: ${themeGet('font.molde.regular')};
    font-size: 1rem;
    line-height: 1.25rem;
  }

  svg {
    padding-bottom: 0.125rem;
    margin-top: ${({ marginTop }) => marginTop};
    margin-bottom: ${({ marginBottom }) => marginBottom};
    color: ${themeGet('colors.blue')};
    transform: rotate(${({ degrees }) => degrees});
  }

  ${themeGet('breakpoints.mobile')} {
    padding: 0.6875rem 0.9375rem 0.5625rem 0.8125rem;
  }
`

export const DropdownSpaceFiller = styled.div`
  position: absolute;
  width: calc(100% - 3px);
  height: 8px;
  margin-top: -0.3125rem;
  background: ${themeGet('colors.white')};
  border-left: 1.5px solid
    ${themeGet('colors.borders.primary', 'colors.beaconGray')};
  border-right: 1.5px solid
    ${themeGet('colors.borders.primary', 'colors.beaconGray')};
  z-index: 1;
`

export const DropdownOptions = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  position: absolute;
  width: calc(100% - 3px);
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  border: 1.5px solid ${themeGet('colors.borders.primary', 'colors.beaconGray')};
  border-top: none;
  border-radius: 0 0 0.3125rem 0.3125rem;
  background-color: ${themeGet('colors.white')};
  z-index: 3;
`

export const DropdownOption = styled(Button).attrs(
  ({ dataTestId, isDefaultValue }) => ({
    'data-testid': dataTestId,
    displaySvg: `${isDefaultValue ? 'block' : 'none'}`,
  }),
)`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: ${themeGet('spacing.blue16_1rem')};
  padding-right: 0.625rem;
  cursor: pointer;
  svg {
    min-width: 10px;
    width: 10px;
    margin-top: 0;
    display: ${({ displaySvg }) => displaySvg};
    margin-top: 0.1875rem;
    color: ${themeGet('colors.darkGray')};
  }
  span {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    color: ${themeGet('colors.darkGray')};
  }
  &:hover {
    background-color: ${themeGet('colors.paleGray')};
  }
`

export const DropdownOptionSeparator = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: row;
  padding: 0 ${themeGet('spacing.blue16_1rem')};
  margin: 0.5rem 0;
  span {
    margin-right: ${themeGet('spacing.blue16_1rem')};
    margin-left: ${themeGet('spacing.blue16_1rem')};
    color: ${themeGet('colors.mediumGray')};
    font-family: ${themeGet('font.molde.regular')};
    font-size: 0.75rem;
    line-height: 0.625rem;
    opacity: 0.8;
  }

  div {
    border-top: solid 0.125rem
      ${themeGet('colors.borders.primary', 'colors.beaconGray')};
    width: 100%;
    height: 0;
    margin: auto 0;
  }
`

export const LabelWrapper = styled.div`
  position: relative;
  display: flex;
`

export const StyledLabel = styled.div`
  color: ${themeGet('colors.darkerGray')};
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 0.4375rem;
  margin-right: 0.25rem;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    font-size: 1rem;
  }
`
