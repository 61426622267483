import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  BaseContainer,
  BodyCopy,
  HeaderTwo,
  ModalContentWrapper,
  OutlinedButton,
  RightArrowIcon,
} from 'secondstep-components'
import ClosableModal from 'experienceComponents/ClosableModal'

export const LeaderContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  width: 100%;
  grid-gap: 1.25rem;
  justify-content: flex-start;
`

export const StyledContainer = styled(BaseContainer)`
  max-width: 1366px;
  width: 100%;
  min-width: 19rem;
  height: 76px;
  padding: 1rem;
  margin: 0;
  border-radius: 0.75rem;
  border: 0.15rem solid ${themeGet('colors.borders.reporting')};
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin-left: 0.25rem;
  }

  .arrowIcon {
    display: none;
  }

  &:focus,
  &:hover {
    border: 0.15rem solid ${themeGet('colors.blueGray')};
    background-color: ${themeGet('colors.lightBlueGray')};
    .arrowIcon {
      display: inline;
    }
    outline: none;
  }

  ${themeGet('breakpoints.xlarge')} {
    width: 19.8125rem;
  }
`

export const StyledLink = styled.a.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  color: black;
  cursor: pointer;
`

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
`

export const HighlightText = styled.div`
  color: ${themeGet('colors.blueGray')};
  font-family: ${themeGet('font.molde.medium')};
  line-height: 1.1875rem;
`

export const RegularText = styled.div`
  line-height: 1.1875rem;
`

export const StyledHeader = styled(HeaderTwo)`
  font-size: 1.625rem;
  margin-bottom: 0.75rem;
  margin-left: auto;
  margin-right: auto;

  ${themeGet('breakpoints.mobileLarge')} {
    margin-bottom: 1.25rem;
    text-align: center;
  }

  ${themeGet('breakpoints.mobile')} {
    font-size: 1.125rem;
    line-height: 2rem;
    margin-bottom: 0.375rem;
  }
`

export const StyledClosableModal = styled(ClosableModal)`
  ${ModalContentWrapper} {
    height: unset;
    width: 29.6875rem;
    min-width: 28.125rem;
    padding: 2.25rem 1.75rem 2.5rem;

    ${themeGet('breakpoints.tablet')} {
      min-width: unset;
      height: auto;
    }

    ${themeGet('breakpoints.mobileLarge')} {
      padding: 2.125rem 1rem 2.625rem;
    }

    ${themeGet('breakpoints.mobile')} {
      padding: 2rem 1rem 2.5rem;
    }
  }
`

export const StyledDescription = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.375rem;
  text-align: center;
  font-size: 1rem;
  margin-left: auto;
  margin-right: auto;

  ${themeGet('breakpoints.mobile')} {
    margin-left: 3rem;
    margin-right: 3rem;
  }
`

export const SvgWithBodyCopy = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
  margin-bottom: 0.3125rem;
  img {
    margin-right: 1.307rem;
  }

  ${themeGet('breakpoints.mobile')} {
    margin-top: 0.625rem;
    margin-bottom: 0;

    img {
      margin-right: 0.75rem;
    }
  }
`

export const StyledRightArrowIcon = styled(RightArrowIcon)`
  color: ${themeGet('colors.blueGray')};
  margin-left: 0.5rem !important;
`

export const StyledSvg = styled.img`
  height: 2.188rem;
  width: 2.256rem;
`

export const SchoolModalText = styled(BodyCopy)`
  color: #555555;
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.375rem;
  a {
    text-decoration: none;
    color: ${themeGet('colors.blueGray')};
  }
`

export const SchoolPageModalText = styled(SchoolModalText)`
  text-align: center;
`

export const CloseModalButton = styled(OutlinedButton)`
  background-color: ${themeGet('colors.blueGray')};
  color: white;
  margin: 1.75rem auto 0;
  width: 10rem;

  ${themeGet('breakpoints.mobile')} {
    margin: 1.375rem auto 0;
  }
`
