import React from 'react'
import PropTypes from 'prop-types'
import {
  ScheduleWrapper,
  ScheduleCopy,
  PacingContinue,
} from './component.styles'
import { ModalHeader } from '../component.styles'
import PacingStartDateInput from 'components/PacingStartDateInput'
import {
  trainingStartName,
  programStartName,
  schoolYearEndName,
} from './data.js'
import ApiErrorMessage from 'components/ApiErrorMessage'

const AddDates = ({
  handleContinueButtonClick,
  data,
  values,
  errors,
  touched,
  handleChange,
  isValid,
  hasApiError,
}) => {
  const { bodyCopy, choiceContent, header, nextLabel } = data || {}
  const [trainingStartData, programStartData, schoolYearEndData] =
    choiceContent || []

  return (
    <ScheduleWrapper>
      <ModalHeader>{header}</ModalHeader>
      <ScheduleCopy>{bodyCopy}</ScheduleCopy>
      <PacingStartDateInput
        date={values[trainingStartName]}
        description={trainingStartData.bodyCopy}
        error={errors[trainingStartName]}
        handleChange={handleChange}
        name={trainingStartName}
        title={trainingStartData.header}
        touched={touched[trainingStartName]}
      />
      <PacingStartDateInput
        date={values[programStartName]}
        description={programStartData.bodyCopy}
        error={errors[programStartName]}
        handleChange={handleChange}
        name={programStartName}
        title={programStartData.header}
        touched={touched[programStartName]}
      />
      <PacingStartDateInput
        date={values[schoolYearEndName]}
        description={schoolYearEndData.bodyCopy}
        error={errors[schoolYearEndName]}
        handleChange={handleChange}
        name={schoolYearEndName}
        title={schoolYearEndData.header}
        touched={touched[schoolYearEndName]}
      />
      <PacingContinue
        disabled={!isValid}
        onClick={() => handleContinueButtonClick()}
      >
        {nextLabel}
      </PacingContinue>
      {hasApiError && <ApiErrorMessage />}
    </ScheduleWrapper>
  )
}

AddDates.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  handleChange: PropTypes.func,
  handleContinueButtonClick: PropTypes.func,
  handleSubmit: PropTypes.func,
  hasApiError: PropTypes.bool,
  isValid: PropTypes.bool,
  touched: PropTypes.object,
  values: PropTypes.object,
}

export default AddDates
