import React from 'react'
import PropTypes from 'prop-types'
import {
  CelebrationIcon,
  CompletionCommentary,
  CompletionStatusDetail,
  Container,
  MarkCompleteButton,
  MarkCompleteButtonIcon,
  SurveyButton,
  SurveyCommentary,
  TextWrapper,
} from './component.styles'
import CelebrationIconAsset from './assets/party_hat.svg'
import {
  DEFAULT_SURVEY_BUTTON_TEXT,
  DEFAULT_SURVEY_COMMENTARY,
} from './constants'

function shouldShowCompletionStatusDetail({
  isComplete,
  completionStatusDetail,
}) {
  return isComplete && !!completionStatusDetail?.length
}

function HsIndividualResourceMarkCompleteComponent({
  // this prop is checked if isComplete is true, and determines if mark complete button still has a hover state and clickability
  canReupdateProgress,
  completionCommentary,
  // specifically used for complete Educator Activities to elaborate on the button text:
  completionStatusDetail,
  hasSurvey,
  handleMarkComplete,
  // primarily passed down to component so it can reason about styling
  isComplete,
  isDisabled,
  markCompleteButtonIconUrl,
  markCompleteButtonIconUrlHover,
  // whether this is completed or not, the container should drive the state of ths prop
  markCompleteButtonText,
  // usually this should match isComplete, but not for all cases in an Educator Practice
  shouldShowCelebrationIcon,
  surveyButtonText = DEFAULT_SURVEY_BUTTON_TEXT,
  surveyCommentary = DEFAULT_SURVEY_COMMENTARY,
}) {
  return (
    // only show the green backgroud when we also show celebration icon
    // NOTE: known bug here where "Some Classes" should have a slightly green background, but NOT show the celebration icon
    <Container isComplete={isComplete && shouldShowCelebrationIcon}>
      <TextWrapper>
        <MarkCompleteButton
          canReupdateProgress={canReupdateProgress}
          // confusingly this icon needs to be set in the parent button so it knows when its hovered:
          hoverSrc={markCompleteButtonIconUrlHover}
          isComplete={isComplete}
          isDisabled={isDisabled}
          // defer to the container to handle the reasoning about whether or not to actually fire the mark complete logic:
          onClick={handleMarkComplete}
          // TODO: this logic should really live in the container, but didn't seem worth adding yet another prop to an already complex component:
          // ensure the button cannot be tabbed over when it is disabled/complete and cannot be reupdated:
          tabIndex={isDisabled || (isComplete && !canReupdateProgress) ? -1 : 0}
        >
          {!!markCompleteButtonIconUrl && (
            <MarkCompleteButtonIcon
              alt=""
              className="mark-complete-button-icon"
              src={markCompleteButtonIconUrl}
            />
          )}
          {markCompleteButtonText}
        </MarkCompleteButton>
        {shouldShowCompletionStatusDetail({
          isComplete,
          completionStatusDetail,
        }) && (
          <CompletionStatusDetail>
            {completionStatusDetail}
          </CompletionStatusDetail>
        )}
        <CompletionCommentary>{completionCommentary}</CompletionCommentary>
        {!!hasSurvey && (
          <>
            <SurveyCommentary>{surveyCommentary}</SurveyCommentary>
            {/* The survey button is driven by Pendo, so no onClick handler is needed */}
            <SurveyButton>{surveyButtonText}</SurveyButton>
          </>
        )}
      </TextWrapper>
      {shouldShowCelebrationIcon && (
        <CelebrationIcon alt="party hat" src={CelebrationIconAsset} />
      )}
    </Container>
  )
}

HsIndividualResourceMarkCompleteComponent.propTypes = {
  canReupdateProgress: PropTypes.bool,
  completionCommentary: PropTypes.string,
  completionStatusDetail: PropTypes.string,
  handleMarkComplete: PropTypes.func,
  hasSurvey: PropTypes.bool,
  isComplete: PropTypes.bool,
  isDisabled: PropTypes.bool,
  // make the unit tests happy by allowing this to be anything: should be a URL to the icon
  markCompleteButtonIconUrl: PropTypes.any,
  markCompleteButtonIconUrlHover: PropTypes.any,
  markCompleteButtonText: PropTypes.string,
  shouldShowCelebrationIcon: PropTypes.bool,
  surveyButtonText: PropTypes.string,
  surveyCommentary: PropTypes.string,
}

export default HsIndividualResourceMarkCompleteComponent
