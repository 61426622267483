import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import {
  BodyCopy,
  ContainedButton,
  HeaderTwo,
  OutlinedButton,
} from 'secondstep-components'

export const Wrapper = styled(Box)`
  align-items: center;
  padding: 1.25rem 1rem 2.5rem;

  ${BodyCopy} {
    max-width: 410px;
    margin-bottom: 2.125rem;
    text-align: center;
  }

  ${HeaderTwo} {
    margin-bottom: 0.25rem;
    text-align: center;
    color: ${themeGet('colors.darkerDarkBlue')};
    font-family: ${themeGet('fontFamilySemibold')};
  }
`

export const ButtonsWrapper = styled(Box)`
  flex-direction: row;
  justify-content: center;

  ${ContainedButton} {
    margin-right: 1rem;
  }

  ${OutlinedButton} {
    border-color: ${themeGet('colors.buttons.secondaryOutline')};
    color: ${themeGet('colors.buttons.secondaryText')};

    &:hover {
      background-color: ${themeGet('colors.themeHighlight')};
      border-color: ${themeGet('colors.themeHighlight')};
      color: ${themeGet('colors.white')};
    }
  }
`
