import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Accordion } from 'secondstep-components'

export const StyledAccordion = styled(Accordion).attrs(
  ({ dataTestId, isLeader }) => ({
    'data-testid': dataTestId,
    fontColor: themeGet('colors.heading.primary'),
    hoverColor: isLeader
      ? themeGet('colors.icons.secondary')
      : themeGet('colors.icons.primary'),
  }),
)`
  display: flex;
  flex-direction: column;
  background: ${themeGet('colors.buttons.secondaryBackground')};
  font-family: ${themeGet('font.lufgaCFC.semiBold')};

  svg {
    margin: 0 0.9375rem 0.25rem 0;
    color: ${themeGet('colors.sela.gray2')};
    min-width: 1rem;
    min-height: 0.75rem;
  }

  [class*='AccordionPanel'] {
    padding: 2rem 2.5rem 1.875rem;
    background: ${themeGet('colors.buttons.secondaryBackground')};

    &:hover {
      svg {
        color: ${({ hoverColor }) => hoverColor};
      }
    }
  }

  [class*='AccordionContentWrapper'] {
    margin: 0;
    border-radius: 0 0 0.625rem 0.625rem;
    width: 100%;

    [class*='AccordionContent'] {
      display: flex;
      flex-direction: column;
      padding: 0 1.25rem 2.5rem 1.25rem;
    }
  }

  [class*='AccordionTitle'] {
    font-size: 1.5rem;
  }

  .microlearning {
    [class*='ContentWrapper'] {
      border-top: 2px solid;
      border-color: ${themeGet('colors.borderColor')};
      transition: border-color 0.2s ease;
    }

    :last-child {
      [class*='ContentWrapper'] {
        border-bottom: 2px solid;
        border-color: ${themeGet('colors.borderColor')};
        transition: border-color 0.2s ease;
      }
    }

    &:hover {
      [class*='ContentWrapper'],
      & + .microlearning-choice-wrapper [class*='CopyWrapper'] {
        border-color: rgba(0, 0, 0, 0);
        transition: border-color 0.2s ease;
      }

      & + .microlearning [class*='ContentWrapper'],
      & + .microlearning-choice-wrapper .microlearning-choice:only-child [class*='ContentWrapper']  {
        border-top-color: rgba(0, 0, 0, 0);
        transition: border-color 0.2s ease;
      }
    }
  }

  .microlearning-choice-wrapper  {
    [class*='CopyWrapper'] {
      border-top: 2px solid;
      border-color: ${themeGet('colors.borderColor')};
      transition: border-color 0.2s ease;
    }

    .microlearning-choice:only-child [class*='ContentWrapper'] {
      border-top: 2px solid;
      border-bottom: 2px solid;
      border-color: ${themeGet('colors.borderColor')};
      transition: border-color 0.2s ease;

      &:hover {
          border-color: rgba(0, 0, 0, 0);
          transition: border-color 0.2s ease;
    }
  }

  ${themeGet('breakpoints.mobile')} {
    [class*='AccordionTitle'] {
      font-size: 1.25rem;
      line-height: 2.1875rem;
    }

    [class*='AccordionPanel'] {
      padding: 1.625rem 1.25rem 1.4375rem;
    }

    [class*='AccordionContentWrapper'] {
      [class*='AccordionContent'] {
        padding: 0;
      }
    }
  }
`
