import PropTypes from 'prop-types'
import {
  previewModeQueryParam,
  teachModeQueryParam,
  HS_MODE_KEY,
  isHighSchoolUrl,
  HS_TEACH_MODE_VALUE,
  HS_PREVIEW_MODE_VALUE,
} from 'utils/highschoolHelpers'
import { SiteType } from 'layers/content/v2/Hocs/withSiteContext/types'
import LoadingParent from 'components/LoadingParent'
import { SITE_QUERY_PARAM } from './constants'

const urlManager = {
  hasQueryParam: param => window.location.search.includes(param),
  createBaseUrl: () =>
    new URL(`${window.location.origin}${window.location.pathname}`),
}

export class SimpleGuard {
  shouldRedirect() {
    return false
  }

  redirect() {}
}

const REDIRECT_REASON = {
  NO_SITE: 'no site',
  SITE_NOT_SETUP: 'site not setup',
  INVALID_PREVIEW_URL: 'mode is preview and has site id',
  SITE_SETUP: 'site is setup',
  NO_SITE_ID: 'no site id',
}

export class HighSchoolGuard {
  _urlManager
  _mode
  redirectTo
  constructor(urlManager) {
    this._urlManager = urlManager
    this._mode = this._getMode()
  }

  _warn(message) {
    console.log(`[${this.constructor.name}] redirecting. reason: ${message}`)
  }

  _getMode() {
    if (this._urlManager.hasQueryParam(teachModeQueryParam())) {
      return HS_TEACH_MODE_VALUE
    }
    if (this._urlManager.hasQueryParam(previewModeQueryParam())) {
      return HS_PREVIEW_MODE_VALUE
    }

    return HS_TEACH_MODE_VALUE
  }

  _getRedirectUrl(siteId) {
    let url = this._urlManager.createBaseUrl()
    if (this.redirectTo === 'teach') {
      url.searchParams.append(HS_MODE_KEY, HS_TEACH_MODE_VALUE)
      url.searchParams.append(SITE_QUERY_PARAM, siteId)
    } else {
      url.searchParams.append(HS_MODE_KEY, HS_PREVIEW_MODE_VALUE)
    }
    return url
  }

  _hasSiteId() {
    return this._urlManager.hasQueryParam(SITE_QUERY_PARAM)
  }

  _hasMode() {
    return this._urlManager.hasQueryParam(HS_MODE_KEY)
  }

  _checkTeachMode(site) {
    if (!site.isSetup) {
      this._warn(REDIRECT_REASON.SITE_NOT_SETUP)
      this.redirectTo = 'preview'
      return true
    }

    const missingParams = !this._hasSiteId() || !this._hasMode()
    if (missingParams) {
      this._warn(REDIRECT_REASON.NO_SITE_ID)
      this.redirectTo = 'teach'
      return true
    }

    return false
  }

  _checkPreviewMode() {
    // If it's preview mode and there is a site id in the url, strip the site id
    if (this._hasSiteId()) {
      this._warn(REDIRECT_REASON.INVALID_PREVIEW_URL)
      this.redirectTo = 'preview'
      return true
    }
    return false
  }

  shouldRedirect(site) {
    // If no site is found, redirect to preview
    if (!site) {
      this._warn(REDIRECT_REASON.NO_SITE)
      this.redirectTo = 'preview'
      return true
    }

    if (this._mode === 'teach') {
      return this._checkTeachMode(site)
    } else {
      return this._checkPreviewMode()
    }
  }

  redirect(navigate, site) {
    if (!site) {
      return
    }

    navigate(this._getRedirectUrl(site.siteId))
  }
}

export function createSiteGuard() {
  // We don't want a mode parameter on the dashboard
  return isHighSchoolUrl() ? new HighSchoolGuard(urlManager) : new SimpleGuard()
}

function SiteGuard(props) {
  const { guard, site, navigate, children } = props

  if (guard.shouldRedirect(site)) {
    guard.redirect(navigate, site)
    return <LoadingParent loadingText={'Loading...'} />
  }

  return children
}

SiteGuard.propTypes = {
  children: PropTypes.node,
  guard: PropTypes.shape({
    shouldRedirect: PropTypes.func,
    redirect: PropTypes.func,
  }),
  navigate: PropTypes.func,
  site: SiteType,
}

export default SiteGuard
