import { domains, isContentfulPreview } from 'config/env'
import { NEW_TAB_SAME_SITE } from 'layers/navigation/store/types'
import Auth0Manager from 'auth/utils/auth0Manager'

const choiceLetters = ['a', 'b']

export const launchPresentation = async params => {
  const url = await formatUrlParams(params)
  const { navigationEvent } = params
  navigationEvent(url, NEW_TAB_SAME_SITE)
}

export const getPresentationLink = async params => {
  const url = await formatUrlParams(params)

  return {
    href: url,
    target: '_blank',
  }
}

const formatUrlParams = async ({
  attempted = '',
  entryId = '',
  extraQueryParams = '',
  instance = false,
  lessonIndex = null,
  lessonTitle = '',
  locale = '',
  mode = '',
  theme = '',
  scoId,
  unitText = '',
  selectedLessonABIndex,
}) => {
  if (
    mode &&
    mode !== 'preview' &&
    mode !== 'prep' &&
    mode !== 'family' &&
    mode !== 'teach'
  ) {
    throw new Error(
      `launchPresentation: mode must be one of 'teach', 'preview', 'prep', or 'family'`,
    )
  }
  if (!theme && (!lessonIndex || !lessonTitle)) {
    throw new Error(
      'launchPresentation: When constructing the lesson player url, the following parameters are required to be passed to the launchPresentation function: theme and one of -> lessonIndex or lessonTitle',
    )
  }
  const token = await Auth0Manager.getAccessToken()
  const authHeader = encodeURIComponent(`Bearer ${token}`)
  const localeQueryParam = locale ? `&locale=${locale}` : ''
  const themeQueryParam = theme ? `&theme=${theme}` : ''
  const modeQueryParam = instance ? `&mode=teach` : mode ? `&mode=${mode}` : ''
  const lessonQueryParam =
    theme && (lessonIndex || lessonTitle)
      ? createLessonQueryParam(
          theme,
          unitText,
          lessonIndex,
          lessonTitle,
          selectedLessonABIndex,
        )
      : ''
  let url
  if (instance) {
    if (!scoId) {
      throw new Error(
        'launchPresentation: scoId must be provided when instance is true',
      )
    }
    const prepModeQueryParam = attempted === 'preview' ? '&prep=1' : ''
    url = `${domains.LMS_API_BASE}/launch/${scoId}/${instance}?${themeQueryParam}${localeQueryParam}${prepModeQueryParam}${modeQueryParam}${lessonQueryParam}${extraQueryParams}`
  } else {
    const previewModeQueryParam = isContentfulPreview() ? '&preview=true' : ''
    url = `${domains.PLAYER_URL}/?entry=${entryId}${themeQueryParam}${localeQueryParam}${previewModeQueryParam}${modeQueryParam}${lessonQueryParam}${extraQueryParams}&token=${authHeader}`
  }
  return url.replace('?&', '?')
}

const createLessonQueryParam = (
  theme,
  unitText,
  lessonIndex,
  lessonTitle,
  selectedLessonABIndex = -1,
) => {
  // Handles programs like K8 where there is a lesson index and also covers MS RBH
  if (lessonIndex) {
    const unitString = (unitText && `-${unitText}`) || ''
    const isLessonLessThan10 = lessonIndex < 10
    const lessonIndexString = isLessonLessThan10
      ? `-lesson0${lessonIndex.toString()}`
      : `-lesson${lessonIndex.toString()}`

    const letterSuffix = choiceLetters[selectedLessonABIndex] || ''
    // The result of the string will look like lesson=grade6-lesson04 and is used in identifying the lesson for things like analytics
    return `&lesson=${theme}${unitString}${lessonIndexString}${letterSuffix}`
  }
  // Handles programs like HS where there is no lesson index or unitText but a title
  if (lessonTitle) {
    const lessonTitleKebab = lessonTitle.replace(/\s+/g, '-').toLowerCase()
    // Result of this string will look like lesson=grade6-lesson-visualizing-success-g09
    return `&lesson=${theme}-lesson-${lessonTitleKebab}`
  }
}
