import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { HeaderTwo } from 'secondstep-components'

export const CategoryCalloutDisplayWrapper = styled.div`
  background-color: ${themeGet('callouts.backgroundColor')};
  border-radius: 0.688rem;
  box-shadow: ${themeGet('callouts.boxShadow', '0 0 0 #DDD')};
  border: ${themeGet('callouts.border', 'none')};
  margin-bottom: ${themeGet('spacing.turquoise20_1_25rem')};
  padding: ${themeGet('spacing.amber28_1_75rem')};
  ${themeGet('breakpoints.mobileLarge')} {
    padding: ${themeGet('spacing.amber28_1_75rem')}
      ${themeGet('spacing.turquoise20_1_25rem')}
      ${themeGet('spacing.turquoise20_1_25rem')};
  }
`

export const CategoryCalloutStyles = styled.div.attrs(
  ({ isTwoColumnLayout }) => {
    const flexSeting = isTwoColumnLayout ? 'unset' : '1 1 30%'

    return {
      flexSeting,
    }
  },
)`
  display: flex;
  flex: ${({ flexSeting }) => flexSeting};
`

export const CalloutsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: ${themeGet('spacing.turquoise20_1_25rem')};

  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
  }
`

export const CategoryCalloutDisplayHeader = styled(HeaderTwo)`
  color: ${themeGet('callouts.headerTextColor')};
  font-size: ${themeGet('font.h2', '1.75rem')};
  font-family: ${themeGet('callouts.headerTextFont')};
  font-weight: ${themeGet('callouts.headerTextFontWeight')};
  margin-bottom: ${themeGet('spacing.amber28_1_75rem')};

  ${themeGet('breakpoints.mobileLarge')} {
    margin-bottom: ${themeGet('spacing.turquoise20_1_25rem')};
  }
`
