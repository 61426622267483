export const BELONGING_AND_CONNECTION = 'Belonging <br>& Connection'
export const BELONGING_AND_CONNECTION_LINK =
  '/product/high-school/pathways/belonging-and-connection'

export const CONFIDENCE_AND_CAPABILITY = 'Confidence <br>& Capability'
export const CONFIDENCE_AND_CAPABILITY_LINK =
  '/product/high-school/pathways/confidence-and-capability'

export const AGENCY_AND_OPPORTUNITY = 'Agency <br>& Opportunity'
export const AGENCY_AND_OPPORTUNITY_LINK =
  '/product/high-school/pathways/agency-and-opportunity'

export const WELL_BEING_AND_COMMUNITY = 'Well-Being <br>& Community'
export const WELL_BEING_AND_COMMUNITY_LINK =
  '/product/high-school/pathways/well-being-and-community'

export const EXPLORE_PROGRAMS_TITLE = 'Explore the Program'
export const PATHWAYS_TITLE = 'Pathways'
export const PRACTICES_TITLE = 'See My Practices'

export const HS_DASHBOARD_LINK = '/dashboard/grades-9-12'
