import variables from './variables'

export default {
  colors: {
    buttons: {
      primaryBackground: variables.colors.skittlesGreen,
      secondaryText: variables.colors.skittlesGreen,
      secondaryOutline: variables.colors.skittlesGreen,
    },
  },
  anchorNavigation: {
    borderBottomStyle: 'none',
    borderBottomColor: 'none',
    borderBottomWidth: 'none',
    boxShadow: `0px 0px 10px ${variables.colors.gray300}`,
  },
}
