import React from 'react'
import PropTypes from 'prop-types'

import {
  LabelButtonContainer,
  LabelContainer,
  IconWrapper,
} from './component.styles'
import { VIEW_ALL } from './constants'

const LabelButton = ({ dataTestId, icon, labelText, onClick }) => {
  return (
    <LabelButtonContainer data-testid={dataTestId}>
      <IconWrapper>{icon}</IconWrapper>
      <LabelContainer onClick={onClick}>
        {VIEW_ALL}
        <br />
        <b>{labelText}</b>
      </LabelContainer>
    </LabelButtonContainer>
  )
}

LabelButton.propTypes = {
  dataTestId: PropTypes.string,
  icon: PropTypes.node,
  labelText: PropTypes.string,
  onClick: PropTypes.string,
}

export default LabelButton
