import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy } from 'secondstep-components'
import {
  ReportsTable,
  TextHiddenOverflow,
  TableHeaderTooltip,
} from 'components/ReportsTable/component.styles'

export const LessonsTable = styled(ReportsTable)`
  border-radius: ${themeGet('spacing.yellow12_0_75rem')};
  table-layout: fixed;

  [class*='Cell']:not(:not([colspan='1'])) {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 1.125rem;
    min-height: 60px;
    height: 60px;
  }

  [class*='Cell']:not([colspan='1']) {
    background-color: ${themeGet('colors.paleGray')};
    font-family: ${themeGet('font.molde.medium')};
    padding: 0.625rem 1.125rem;
    font-size: 0.8125rem;
    ${themeGet('breakpoints.tablet')} {
      padding-left: ${themeGet('spacing.blue16_1rem')};
      padding-right: ${themeGet('spacing.blue16_1rem')};
    }
    ${themeGet('breakpoints.mobile')} {
      padding-left: ${themeGet('spacing.yellow12_0_75rem')};
    }
  }

  [class*='Cell']:nth-child(2) {
    padding-left: 1.375rem;
    padding-right: ${themeGet('spacing.purple24_1_5rem')};
  }

  [class*='AutoTableHeaderTh']:first-child {
    width: 45%;

    ${themeGet('breakpoints.mobile')} {
      width: 52%;
    }
  }

  [class*='AutoTableHeaderTh']:nth-child(2) {
    width: 25%;
    padding-left: 1.375rem;
  }

  [class*='AutoTableHeaderTh']:last-child {
    width: 21.5%;
    padding-right: 2.25rem;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    [class*='AutoTableHeader']:nth-child(3),
    [class*='Cell']:nth-child(3) {
      display: none;
    }
    [class*='AutoTableHeader']:nth-child(2),
    [class*='Cell']:nth-child(2) {
      padding-left: ${themeGet('spacing.yellow12_0_75rem')};
    }
    [class*='AutoTableHeader']:nth-child(2) {
      width: 31%;
      border-radius: 0 10px 0 0;
    }

    [class*='Row']:last-child {
      [class*='Cell']:nth-child(2) {
        border-radius: 0 0 10px 0;
      }
    }
  }

  ${themeGet('breakpoints.mobileLarge')} {
    [class*='AutoTableHeaderTh']:first-child {
      padding-left: ${themeGet('spacing.yellow12_0_75rem')};
    }

    [class*='Cell']:first-child {
      padding-left: ${themeGet('spacing.yellow12_0_75rem')};
    }

    [class*='Cell']:nth-child(2) {
      padding-right: 0.875rem;
    }
  }

  .cell-icon {
    margin-right: ${themeGet('spacing.pink8_0_5rem')};
    ${themeGet('breakpoints.mobileLarge')} {
      display: none;
    }
  }
`

export const SmallText = styled(TextHiddenOverflow)`
  font-size: 0.8125rem;
  margin-top: ${themeGet('spacing.green4_0_25rem')};
`

export const NotStartedIcon = styled.span`
  height: ${themeGet('spacing.turquoise20_1_25rem')};
  width: ${themeGet('spacing.turquoise20_1_25rem')};
  min-width: ${themeGet('spacing.turquoise20_1_25rem')};
  background-color: ${themeGet('colors.beaconGray')};
  border-radius: 50%;
  display: inline-block;
`

export const LessonsTooltip = styled(TableHeaderTooltip)`
  [class*='TooltipContentWrapper'] {
    left: 4rem;

    ::after {
      left: 32%;
    }
  }
`

export const StatusTooltip = styled(TableHeaderTooltip)`
  [class*='TooltipContentWrapper'] {
    left: -2.5625rem;
    top: 1.5rem;

    ::after {
      left: 68%;
    }

    ${themeGet('breakpoints.tablet')} {
      left: -2.3125rem;
    }

    ${themeGet('breakpoints.mobileLarge')} {
      left: -3.3125rem;

      ::after {
        left: 74%;
      }
    }
  }
`

export const MarkedDoneTooltip = styled(TableHeaderTooltip)`
  [class*='TooltipContentWrapper'] {
    left: -5.5rem;

    ::after {
      left: 89%;
    }
  }
`

export const StatusBodyCopy = styled(BodyCopy)`
  li {
    text-align: left;
  }
`
