import React from 'react'
import PropTypes from 'prop-types'

import ListItem from '../ListItem'
import { Wrapper } from './component.styles'
const ModuleList = ({ dataTestId, lmsAndContentfulModules }) => (
  <Wrapper dataTestId={dataTestId}>
    {lmsAndContentfulModules.map((module, index) => {
      const {
        contentfulID,
        attempted,
        displayTitle,
        instance,
        time = 'Not Available',
        scoID,
        children,
      } = module
      const isFirstModule = index === 0
      return (
        <ListItem
          attempted={attempted}
          choiceLessons={children}
          contentfulID={contentfulID}
          dataTestId="module-list-item"
          displayTitle={displayTitle}
          entryId={contentfulID}
          instance={instance}
          isFirstModule={isFirstModule}
          key={`box-${index}`}
          scoID={scoID}
          time={time}
        />
      )
    })}
  </Wrapper>
)

ModuleList.propTypes = {
  additionalResources: PropTypes.array,
  dataTestId: PropTypes.string.isRequired,
  displayTitle: PropTypes.string,
  lmsAndContentfulModules: PropTypes.array.isRequired,
  time: PropTypes.string,
}

export default ModuleList
