export const SLM_CONTENT_TYPE = 'slm'
export const SLM_LESSON_CONTENT_TYPE = 'slmLesson'
export const SUBSECTION_LANDING_CONTENT_TYPE = 'subsectionLanding'
export const PROGRAM_SECTION_ARTICLE_TREE_CONTENT_TYPE =
  'programSectionArticleTree'
export const ARTICLE_CONTENT_TYPE = 'article'
export const ACTIVITY_LIST_CONTENT_TYPE = 'activityList'
export const GROUPED_CONTENT_CONTENT_TYPE = 'groupedContent'
export const ADVISORY_ACTIVITY_CONTENT_TYPE = 'advisoryActivity'
export const SLM_COURSE_CONTENT_TYPE = 'courseSlm'
export const SLM_LESSON_EL_CONTENT_TYPE = 'lessonSlmEl'
export const TEACH_SECTION = 'kitTeachSection'
export const PLAYLIST_TYPE = 'playlist1'
export const PLAYIST_TAB_TYPE = 'playlist1Tab'
export const RESOURCE_LIBRARY = 'resourceLibraryPage'
export const STATIC_LANDING_PAGE_TYPE = 'staticLandingPage'
export const HS_COURSE = 'courseHs'
export const HS_UNIT = 'unitHs'
export const WEEK = 'week'
export const MULTIMEDIA_GROUPING = 'multimediaGrouping'
export const NAVIGATION_PAGE = 'navigationPage'
export const REDIRECT_RULE = 'redirectRule'
export const EXTENSION_LANDING = 'extensionLanding'

export const SUPPORTED_CONTENT_TYPES = [
  SLM_CONTENT_TYPE,
  SLM_LESSON_CONTENT_TYPE,
  SUBSECTION_LANDING_CONTENT_TYPE,
  PROGRAM_SECTION_ARTICLE_TREE_CONTENT_TYPE,
  ARTICLE_CONTENT_TYPE,
  ACTIVITY_LIST_CONTENT_TYPE,
  GROUPED_CONTENT_CONTENT_TYPE,
  ADVISORY_ACTIVITY_CONTENT_TYPE,
  SLM_COURSE_CONTENT_TYPE,
  SLM_LESSON_EL_CONTENT_TYPE,
  TEACH_SECTION,
  PLAYLIST_TYPE,
  PLAYIST_TAB_TYPE,
  RESOURCE_LIBRARY,
  STATIC_LANDING_PAGE_TYPE,
  HS_COURSE,
  HS_UNIT,
  WEEK,
]

export const LOADING_TEXT = 'Loading...'
export const MIND_YETI_LOADING_TEXT = 'Program is loading'
export const HS_TRAINING_URL = 'product/high-school/training'
export const MIND_YETI_URL = '/product/mind-yeti/'
