import {
  DEFAULT_ROOT_INCLUDE,
  DEFAULT_ROOT_LOCALE,
  KNOWN_LOCALES,
  ROOT_ENTRY_ID,
} from 'layers/content/Hocs/RootContent/data'
import ContentfulReducer from 'layers/content/store/reducer'

export const createEntryKey = (entryId, locale, include) =>
  `${entryId}:${locale}:${include}`
export const createEntryKeyWithoutInclude = (entryId, locale) =>
  `${entryId}:${locale}`

export const getHighestIncludeOption = (state, entryId, locale) => {
  const contentfulCache = state[ContentfulReducer.ROOT_NAMESPACE]

  if (!contentfulCache) {
    return
  }

  const MAX_INCLUDES = 10
  const MIN_INCLUDES = 0
  let entryData
  let keyAttempt
  let finalIncludeValue
  for (
    let includeLevel = MAX_INCLUDES;
    includeLevel >= MIN_INCLUDES;
    includeLevel--
  ) {
    keyAttempt = createEntryKey(entryId, locale, includeLevel)
    entryData = contentfulCache[keyAttempt]
    if (entryData) {
      finalIncludeValue = includeLevel
      break
    }
  }

  return {
    entryData,
    key: keyAttempt,
    include: finalIncludeValue,
  }
}

export function getHighestIncludeOptionWithKnownLocales(state, entryId) {
  let highestIncludeWrapper
  for (let locale of KNOWN_LOCALES) {
    highestIncludeWrapper = getHighestIncludeOption(state, entryId, locale)
    if (highestIncludeWrapper) {
      const { entryData: entryPayload } = highestIncludeWrapper || {}
      if (entryPayload) {
        break
      }
    }
  }

  return highestIncludeWrapper
}

// assuming here that the include for root is always 3
export function getRootEntry(state, include = DEFAULT_ROOT_INCLUDE) {
  const getDataAttempt =
    (state &&
      getHighestIncludeOption(
        state,
        ROOT_ENTRY_ID,
        DEFAULT_ROOT_LOCALE,
        include,
      )) ||
    {}

  const { entryData } = getDataAttempt || {}
  const rootEntry = entryData || {}
  const { isFetching, payload, error } = rootEntry

  if (isFetching === undefined) {
    const notSetError = new Error('root entry not set yet')

    return { error: notSetError, isFetching: false, payload }
  }

  return { error, isFetching, payload }
}
