import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { CloseButton } from 'secondstep-components'

export const StyledCloseButton = styled(CloseButton)`
  display: flex;
  align-self: flex-end;
  z-index: 210;
  font-weight: 400;

  svg {
    min-width: 13px;
    min-height: 13px;
    color: ${themeGet('colors.gray1200')};
  }
  ${themeGet('breakpoints.mobileLarge')} {
    padding-right: 1rem;
  }
`
