import ResumeButton from '../../ResumeButton'
import ReviewButton from '../../ReviewButton'
import StartButton from '../../StartButton'

export default {
  'not attempted': {
    Button: StartButton,
    text: 'START',
  },
  incomplete: {
    Button: ResumeButton,
    text: 'RESUME',
  },
  completed: {
    Button: ReviewButton,
    text: 'REVIEW',
  },
}
