import React from 'react'
import PropTypes from 'prop-types'
import AdminHsExplorePrograms from './component'
import { connect } from 'react-redux'
import { navigationEvent } from 'layers/navigation/store/operations'
import { selectors as licenseSelectors } from 'store/licenseManager'
import { PACING_CONTENT } from './constants'

const AdminHsExploreProgramsContainer = ({
  className,
  isSingleHsAdminUser,
  name,
  navigationEvent,
  highSchoolPacing,
}) => {
  const currentDate = new Date()
  const programTrainingPace = highSchoolPacing?.find(
    element => element.nodeLevel === 'training',
  )
  const trainingPaceStartDate = new Date(programTrainingPace?.startDate)
  trainingPaceStartDate?.setHours(0, 0, 0, 0)
  const isBeforeTraining =
    currentDate.getTime() < new Date(trainingPaceStartDate).getTime()

  const highSchoolPace = highSchoolPacing?.find(element => {
    const startDate = new Date(element?.startDate)
    startDate?.setHours(0, 0, 0, 0)
    const endDate = new Date(element?.endDate)
    endDate?.setHours(23, 59, 59, 999)

    if (
      element?.nodeLevel === 'training' &&
      startDate?.getTime() > currentDate?.getTime()
    ) {
      return element
    }

    if (
      element?.nodeLevel === 'training' &&
      startDate?.getTime() <= currentDate?.getTime() &&
      endDate?.getTime() >= currentDate?.getTime()
    ) {
      return element
    }

    if (
      startDate?.getTime() <= currentDate?.getTime() &&
      endDate?.getTime() >= currentDate?.getTime()
    ) {
      return element
    }
  })

  const currentPacing = {
    ...highSchoolPace,
    nodeKey: isBeforeTraining ? null : highSchoolPace?.nodeKey,
  }

  const backgroundKey =
    isBeforeTraining || currentPacing?.nodeFriendlyName?.includes('program')
      ? 'training'
      : currentPacing?.nodeFriendlyName?.substring(3, 6)

  const startDate = new Date(currentPacing?.startDate)
  const endDate = new Date(currentPacing?.endDate)
  const options = { month: 'short', day: 'numeric' }
  const formattedStartDate = startDate?.toLocaleDateString('en-US', options)
  const formattedEndDate = endDate?.toLocaleDateString('en-US', options)

  const singleSiteButtonDate = isBeforeTraining
    ? `Before ${formattedStartDate}`
    : `${formattedStartDate} - ${formattedEndDate}`
  const singleSiteButtonText =
    PACING_CONTENT[currentPacing?.nodeKey]?.textName ||
    PACING_CONTENT['beforeTraining']?.textName
  const singleSitePathwayLink =
    PACING_CONTENT[currentPacing?.nodeKey]?.link ||
    PACING_CONTENT['beforeTraining']?.link

  return (
    <AdminHsExplorePrograms
      backgroundKey={backgroundKey}
      className={className}
      isSingleHsAdminUser={isSingleHsAdminUser}
      name={name}
      navigationEvent={navigationEvent}
      singleSiteButtonDate={singleSiteButtonDate}
      singleSiteButtonText={singleSiteButtonText}
      singleSitePathwayLink={singleSitePathwayLink}
    />
  )
}

const mapDispatchToProps = {
  navigationEvent,
}

const mapStateToProps = state => {
  const adminHSSites = licenseSelectors.selectActiveAdminHsSites(state)
  const isSingleHsAdminUser = adminHSSites?.length === 1
  const { name, pacing } = isSingleHsAdminUser ? adminHSSites[0] : {}

  // TODO: this is just a temporary fix. There shouldn't be two variants.
  // Currently running into an issue where some pacing objects have "highschool" and others have "highSchool"
  const { highSchool, highschool } = pacing || {}
  const highSchoolPacing = highSchool || highschool

  return {
    isSingleHsAdminUser,
    name,
    highSchoolPacing,
  }
}

AdminHsExploreProgramsContainer.propTypes = {
  className: PropTypes.string,
  highSchoolPacing: PropTypes.array,
  isSingleHsAdminUser: PropTypes.bool,
  name: PropTypes.string,
  navigationEvent: PropTypes.func,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminHsExploreProgramsContainer)
