export const CANCEL_CREATE = {
  CANCEL_HEADER: 'Are you sure you want to cancel?',
  CANCEL_SUBHEADER:
    "Your class won't be created and your progress won't be saved.",
  CANCEL_CONFIRM: 'Yes, Cancel',
  CANCEL_DENY: "Don't Cancel",
}

export const CANCEL_EDIT = {
  CANCEL_HEADER: 'Are you sure you want to cancel?',
  CANCEL_SUBHEADER: "Your changes won't be saved.",
  CANCEL_CONFIRM: 'Yes, Cancel',
  CANCEL_DENY: "Don't Cancel",
}
