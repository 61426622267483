import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import {
  ContainerSection,
  DividerHairline,
  EmphasisHairline,
  StyledHeaderTwo,
  StyledHeaderThree,
  ViewWrapper,
  MainViewColumn,
  SecondaryViewColumn,
} from '../LessonDetail/component.styles'
import LessonContent from 'components/Teach/LessonContent'
import LessonMaterialsList from '../LessonMaterialsList'
import MediaGroup from '../MediaGroup'

import {
  PREP_MATERIALS_TITLE,
  BB_PREP_VIDEO_TITLE,
  LESSON_PREP_TITLE,
  PREP_MATERIALS_PDF_TITLE,
} from './constants'
import { getLessonPrepSections } from './data'

const LessonPrep = ({
  bbPrepVideo = [],
  keyWords,
  lessonPreparation,
  locale,
  prepMaterials,
  prepMaterialsPdf = [],
  teacherTip,
}) => {
  const hasPrepMaterialsPdf =
    Array.isArray(prepMaterialsPdf) && prepMaterialsPdf.length > 0
  const hasMaterials = prepMaterials || hasPrepMaterialsPdf
  const hasPrepVideos = Array.isArray(bbPrepVideo) && bbPrepVideo.length > 0
  const lessonPrepSections = getLessonPrepSections({
    keyWords,
    teacherTip,
    lessonPreparation,
  })

  return (
    <>
      <StyledHeaderTwo dataTestId="lesson-prep-title">
        {LESSON_PREP_TITLE}
      </StyledHeaderTwo>
      <ContainerSection dataTestId="lesson-prep">
        <ViewWrapper>
          <MainViewColumn>
            {lessonPrepSections.map((section, index) => {
              const hasDividerLine = index > 0
              return (
                <Fragment key={section.title}>
                  {hasDividerLine && <DividerHairline />}
                  <LessonContent
                    content={section.content}
                    title={section.title}
                  />
                </Fragment>
              )
            })}
            {hasPrepVideos && (
              <React.Fragment key="bbPrepVideo">
                {(keyWords || teacherTip || lessonPreparation) && (
                  <DividerHairline />
                )}
                <StyledHeaderThree>{BB_PREP_VIDEO_TITLE}</StyledHeaderThree>
                <MediaGroup locale={locale} mediaList={bbPrepVideo} />
              </React.Fragment>
            )}
          </MainViewColumn>
          {hasMaterials && (
            <SecondaryViewColumn>
              {prepMaterials && (
                <React.Fragment key="prepMaterials">
                  <StyledHeaderThree>{PREP_MATERIALS_TITLE}</StyledHeaderThree>
                  <EmphasisHairline />
                  <ReactMarkdown>{prepMaterials}</ReactMarkdown>
                </React.Fragment>
              )}
              {hasPrepMaterialsPdf && (
                <LessonMaterialsList
                  materials={prepMaterialsPdf}
                  title={PREP_MATERIALS_PDF_TITLE}
                />
              )}
            </SecondaryViewColumn>
          )}
        </ViewWrapper>
      </ContainerSection>
    </>
  )
}

LessonPrep.propTypes = {
  bbPrepVideo: PropTypes.arrayOf(PropTypes.object),
  keyWords: PropTypes.string,
  lessonPreparation: PropTypes.string,
  locale: PropTypes.string,
  prepMaterials: PropTypes.string,
  prepMaterialsPdf: PropTypes.arrayOf(
    PropTypes.shape({
      internalTitle: PropTypes.string,
      displayTitle: PropTypes.string,
      file: PropTypes.shape({
        title: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  ),
  teacherTip: PropTypes.string,
}

export default LessonPrep
