import React from 'react'
import PropTypes from 'prop-types'
import { Container, Title, Credit, Description } from './component.styles'
import { getAuthorText, getIllustratorText } from './constants'

export const Book = ({
  className,
  author,
  illustrator,
  description,
  title,
}) => {
  return (
    <Container className={className}>
      {title && <Title>{title}</Title>}
      {author && <Credit>{getAuthorText(author)}</Credit>}
      {illustrator && <Credit>{getIllustratorText(illustrator)}</Credit>}
      {description && <Description>{`${description}`}</Description>}
    </Container>
  )
}

Book.propTypes = {
  author: PropTypes.string,
  className: PropTypes.string,
  description: PropTypes.string,
  illustrator: PropTypes.string,
  title: PropTypes.string,
}

export default Book
