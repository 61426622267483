import React from 'react'
import PropTypes from 'prop-types'
import { TabButton, TabSet } from 'secondstep-components'
import { formatUnitKey } from 'utils/adminExperienceHelpers'
import AdminSearchSelect from 'components/AdminSearchSelect'
import { ALL_LESSONS_KEY, ALL_LESSONS_NAME } from './constants'
import { TabSetWrapper, DropdownContainer } from './component.styles'

const AdminLessonProgressTabs = ({
  bhuStatus,
  className,
  lessonSelectedRow,
  outline,
  setLessonSelectedRow,
  units,
}) => {
  const selectedTab = lessonSelectedRow?.selectedUnitTab ?? ALL_LESSONS_KEY
  const allLessonsLabel = bhuStatus ? ALL_LESSONS_NAME : ALL_LESSONS_KEY

  return (
    <TabSetWrapper className={className}>
      <TabSet dataTestId="admin-lessons-tabset">
        <TabButton
          dataTestId="lessons-tab"
          isActive={selectedTab === ALL_LESSONS_KEY}
          key="lessons"
          label={allLessonsLabel}
          onClick={() =>
            setLessonSelectedRow({ selectedUnitTab: ALL_LESSONS_KEY })
          }
        />
        {units?.map((_unit, i) => {
          const tabLabel = formatUnitKey(i)
          return (
            <TabButton
              dataTestId={`unit-tab-${i + 1}`}
              isActive={tabLabel === selectedTab}
              key={`unit-tab-${i + 1}`}
              label={tabLabel}
              onClick={() =>
                setLessonSelectedRow({ selectedUnitTab: tabLabel })
              }
            />
          )
        })}
      </TabSet>
      <DropdownContainer outline={outline}>
        <AdminSearchSelect
          defaultLabel={selectedTab}
          labelProp="name"
          onChange={e => setLessonSelectedRow({ selectedUnitTab: e.name })}
          options={[
            { name: allLessonsLabel, key: ALL_LESSONS_KEY },
            ...units?.map((_unit, i) => {
              return { name: formatUnitKey(i), key: formatUnitKey(i) }
            }),
          ]}
          value={{ name: selectedTab, key: selectedTab }}
        />
      </DropdownContainer>
    </TabSetWrapper>
  )
}

AdminLessonProgressTabs.propTypes = {
  bhuStatus: PropTypes.bool,
  className: PropTypes.string,
  lessonSelectedRow: PropTypes.object,
  outline: PropTypes.bool,
  setLessonSelectedRow: PropTypes.func,
  units: PropTypes.array,
}

export default AdminLessonProgressTabs
