import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { TextLink } from 'secondstep-components'
import { toKebabCase } from 'utils/stringHelpers'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { isFlagOn } from 'utils/featureFlags'
import {
  AdminSearchSelectExplore,
  ExploreContainer,
  ProgramResourceContainer,
  ProgramResourceRow,
  ProgramResource,
  StyledBackpackV2Icon,
  StyledTelescopeIcon,
  SupportLinksWrapper,
  Title,
  TitleHairline,
} from './component.styles'
import {
  contentfulProgramLinksInternalTitle,
  k5ProgramContentfulInternalTitles,
  msProgramContentfulInternalTitles,
  MY_CLASSES,
} from '../constants'

import { withTheme } from 'styled-components'

const ExplorePrograms = ({ availableLinks, className, navigationEvent }) => {
  const navigateHandler = (urlPath, openSameTab) => {
    const type = openSameTab
      ? NavigationHelper.types.PUSH
      : NavigationHelper.types.NEW_TAB_SAME_SITE

    navigationEvent(urlPath, type)
  }

  const renderProgramSupportLinks = programName => {
    let filteredLinks
    if (availableLinks?.length > 0) {
      filteredLinks = availableLinks.find(link => {
        return link?.internalTitle === contentfulProgramLinksInternalTitle
      })
    }
    let linkProgramTitles
    if (programName === 'k5') {
      linkProgramTitles = k5ProgramContentfulInternalTitles
    } else if (programName === 'ms') {
      linkProgramTitles = msProgramContentfulInternalTitles
    }
    if (filteredLinks) {
      const { icon, linkMenu } = filteredLinks || {}
      const filteredLinkMenu = linkMenu.filter(link => {
        return linkProgramTitles.indexOf(link.internalTitle) !== -1
      })
      const { title } = icon || {}
      const DropdownButtonIcon = () => {
        return <StyledTelescopeIcon />
      }
      return (
        <Fragment key={title}>
          {filteredLinkMenu && (
            <AdminSearchSelectExplore
              dataTestId={toKebabCase(programName + ' Preview Lessons')}
              defaultLabel={'Preview Lessons'}
              dropdownButtonIcon={<DropdownButtonIcon />}
              keyProp="id"
              labelProp="buttonText"
              onChange={({ url: optionUrlPath, openSameTab }) =>
                navigateHandler(optionUrlPath, openSameTab)
              }
              options={filteredLinkMenu}
              shouldHideSelectedIcon={true}
            />
          )}
        </Fragment>
      )
    }

    return null
  }
  if (
    isFlagOn('middle-school-leader-dashboard-optin-functionality-release-2')
  ) {
    console.log('teststest')
  }
  return (
    <ExploreContainer className={className}>
      <Title>Explore the Program</Title>
      <TitleHairline />
      <ProgramResourceContainer>
        <ProgramResourceRow>
          <ProgramResource>
            <h3>Elementary (Grades K-5)</h3>
          </ProgramResource>
          <ProgramResource>
            <SupportLinksWrapper>
              {renderProgramSupportLinks('k5')}
            </SupportLinksWrapper>
          </ProgramResource>
          <ProgramResource>
            <TextLink
              aria-label={'View all lessons elementary'}
              dataTestId={`all-lessons-k5`}
              href={'/product/elementary/lessons'}
              target="_self"
            >
              <StyledBackpackV2Icon />
              {MY_CLASSES}
            </TextLink>
          </ProgramResource>
        </ProgramResourceRow>
      </ProgramResourceContainer>
      <ProgramResourceContainer>
        <ProgramResourceRow>
          <ProgramResource>
            <h3>Middle School (Grades 6-8)</h3>
          </ProgramResource>
          <ProgramResource>
            <SupportLinksWrapper>
              {renderProgramSupportLinks('ms')}
            </SupportLinksWrapper>
          </ProgramResource>
          <ProgramResource>
            <TextLink
              aria-label={'View all lessons middle school'}
              dataTestId={`all-lessons-ms`}
              href={'/product/middle-school/lessons'}
              target="_self"
            >
              <StyledBackpackV2Icon />
              {MY_CLASSES}
            </TextLink>
          </ProgramResource>
        </ProgramResourceRow>
      </ProgramResourceContainer>
    </ExploreContainer>
  )
}

ExplorePrograms.propTypes = {
  availableLinks: PropTypes.array,
  bullyingHarassmentUnitInformation: PropTypes.string,
  className: PropTypes.string,
  navigationEvent: PropTypes.func,
  sites: PropTypes.array,
}

export default withTheme(ExplorePrograms)
