import { OptionalUnits } from 'utils/productMaps'

function transformLessons(contentLessons, lmsLessons = []) {
  return contentLessons.map((contentLesson, index) => {
    const transformed = toInstanceLesson(contentLesson)
    const lmsLesson = lmsLessons[index]

    return {
      ...transformed,
      ...lmsLesson,
    }
  })
}

function isChoice(contentfulLesson) {
  return contentfulLesson?.lessonType === 'ChoiceCurriculum'
}

function collapseChoice(contentfulLesson) {
  if (!isChoice(contentfulLesson)) return {}
  return {
    choices: [
      toInstanceLesson(contentfulLesson.item1Lesson),
      toInstanceLesson(contentfulLesson.item2Lesson),
    ],
  }
}

function toInstanceLesson(contentfulLesson) {
  return {
    ...collapseChoice(contentfulLesson),
    attempted: 'preview',
    doneAt: null,
    is_done: null,
    progress: null,
    score: null,
    time: 'P0D',
    updatedAt: null,
  }
}

function getUnitId(route) {
  return route.split('/').pop()
}

const resettingIndexUnitIds = [OptionalUnits.BullyingHarassmentUnit.slug]

function getLessonStartingIndex(contentUnit, currentUnitId) {
  const lessonsIndexesMap = contentUnit.courseContent.tabSummaries.reduce(
    (currentMap, unit, index) => {
      const currentUnitId = getUnitId(unit.route)

      if (index === 0 || resettingIndexUnitIds.includes(currentUnitId)) {
        currentMap[currentUnitId] = 1
        return currentMap
      } else {
        const prevUnitId = contentUnit.courseContent.tabSummaries[
          index - 1
        ].route
          .split('/')
          .pop()
        currentMap[currentUnitId] =
          currentMap[prevUnitId] +
          contentUnit.courseContent.tabSummaries[index - 1].lessonCount
      }
      return currentMap
    },
    {},
  )
  return lessonsIndexesMap[currentUnitId]
}

export function getData({ contentUnit, currentUnitId, lmsUnit }) {
  let advisoryActivities = new Set()
  const unitTabs = contentUnit.courseContent.tabSummaries

  const { unitVideo, additionalResources } = contentUnit
  if (additionalResources) {
    additionalResources.forEach(additionalResource => {
      advisoryActivities.add(additionalResource.advisoryActivityType)
    })
  }

  const found = contentUnit.courseContent.tabSummaries.find(
    unit => getUnitId(unit.route) === currentUnitId,
  )
  const unitName = found?.displayTitle || ''

  const lmsLessons = transformLessons(
    contentUnit.lessons,
    (lmsUnit ?? {}).lessons,
  )
  const lessonStartingIndex = getLessonStartingIndex(contentUnit, currentUnitId)
  return {
    advisoryActivities,
    unitVideo,
    lessonStartingIndex,
    lmsLessons,
    unitName,
    unitTabs,
  }
}
