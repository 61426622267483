import React from 'react'
import PropTypes from 'prop-types'

import StaticHeaderThree from '../../components/StaticHeaderThree'

import { Link, List, ListItem } from './component.styles'

const H3LinkList = ({ header, links, id, navigationHandler }) => {
  return (
    <div>
      <StaticHeaderThree dataTestId={`static-header-${id}`}>
        {header}
      </StaticHeaderThree>
      <List>
        {links.map((link, index) => {
          const dataTestId = `article-links-${index}-${id}`
          const { href, text, contentType } = link || {}

          const navigate = () => {
            navigationHandler(href, contentType)
          }

          return (
            <ListItem key={dataTestId} onClick={navigate}>
              <Link dataTestId={dataTestId}>{text}</Link>
            </ListItem>
          )
        })}
      </List>
    </div>
  )
}

H3LinkList.propTypes = {
  header: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
      // not required!
      contentType: PropTypes.string,
    }),
  ).isRequired,
  navigationHandler: PropTypes.func.isRequired,
}

export default H3LinkList
