import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  AutoTable,
  CheckBox,
  ContainedButton,
  Hairline,
  HeaderSix,
} from 'secondstep-components'

export const ListButtonPanel = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.25rem;
`

export const ListContainer = styled.div`
  margin-top: 1.8rem;
  color: ${themeGet('colors.gray1200')};
  font-size: 0.8rem;
  background-color: ${themeGet('colors.paleGray')};
  border-radius: 0.625rem;
  min-height: 285px;
  padding: 1.25rem;
  max-height: 30.75rem;
  width: 580px;
`

export const ListTitle = styled(HeaderSix)`
  font-size: 1rem;
  text-align: left;
  line-height: 1.5625rem;
  width: 112px;
  font-family: ${themeGet('font.sharpSlab.semibold')};
`

export const ListTitlePanel = styled.div`
  display: flex;
  text-align: left;
`

export const ListSubtitle = styled.div`
  letter-spacing: 0.05rem;
  font-size: 1rem;
  margin-left: 0.625rem;
  color: ${themeGet('colors.darkGray')};
  & span {
    font-family: ${themeGet('font.molde.medium')};
  }
`

export const SelectAllInputSection = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: ${themeGet('colors.darkGray')};

  & div {
    font-family: ${themeGet('font.molde.regular')};
  }
}
`

export const SelectAllSection = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledTableWrapper = styled.div`
  height: 532px;
`

export const StyledAutoTable = styled(AutoTable)`
  margin-top: -0.625rem; /* not ideal but only way I could set Autotable to have a different spacing only for thead */
  border: none;
  border-spacing: 0rem 0.5rem;
  width: 550px;
  height: auto;

  & tbody {
    position: absolute;
    overflow-y: scroll;
    max-height: 370px;
    min-height: 165px;
    min-width: 578px;
    height: calc(100% - 400px);
  }

  & tr {
    line-height: 2.6875rem;
    background-color: white;
    margin-bottom: 0.5rem;
    width: 19.375rem;
  }

  & tr td {
    padding: 0 0.75rem 0 0.75rem;
    color: ${themeGet('colors.darkGray')};
    background-color: white;
    font-size: 1rem;
  }

  & .text-center {
    width: 322px;
  }

  & tr td:first-child {
    border-top-left-radius: 0.3125rem;
    border-bottom-left-radius: 0.3125rem;

    & div {
      line-height: 0.75rem;
    }
  }

  & tr td:last-child {
    border-top-right-radius: 0.3125rem;
    border-bottom-right-radius: 0.3125rem;
    margin-right: 2rem;
    width: 144px;
  }

  & thead {
    height: 0.625rem;
    line-height: 0.125rem;

    & tr {
      margin-bottom: 0;
    }
  }

  & th {
    line-height: 2.5rem;
    background-color: ${themeGet('colors.paleGray')};
  }

  & th div {
    height: 1.25rem;
    width: auto;
    padding: 0;
    color: ${themeGet('colors.darkGray')};
  }

  & th:nth-child(2) > div {
    margin-left: 3.125rem;
    width: 170px;
  }

  & th span {
    margin-right: 0.3125rem;
  }

  & tbody::-webkit-scrollbar {
    width: 8px;
  }

  & tbody::-webkit-scrollbar-track {
    background: ${themeGet('colors.grayBlue')};
    border-radius: 0.4375rem;
  }

  & tbody::-webkit-scrollbar-thumb {
    background: ${themeGet('colors.blueGray')};
    border-radius: 0.4375rem;
  }
`
export const StatusOn = styled.div`
  font-family: ${themeGet('font.molde.semiBold')};
  color: ${themeGet('colors.darkBlue')};
`
export const StatusOff = styled.div`
  font-family: ${themeGet('font.molde.medium')};
  color: ${themeGet('colors.darkGray')};
`

export const DividerHairline = styled(Hairline)`
  background-color: white;
  height: 0.125rem;
  width: 570px;
`

export const StyledText = styled.div`
  letter-spacing: 0.05rem;
  font-size: 0.875rem;
  font-family: ${themeGet('font.molde.medium')};
  color: ${themeGet('colors.darkGray')};
`

export const TableStyledCheckBox = styled(CheckBox).attrs(props => ({
  borderColor: props.isChecked
    ? themeGet('colors.elemDigital1')
    : themeGet('colors.blueGray'),
  backgroundCheckedColor: themeGet('colors.elemDigital1')(props),
}))`
  display: flex;
  margin-right: 0.4375rem;
  font-size: 1rem;
  padding-top: 0.1875rem;

  [class*='CheckBoxStyled-sc-'] {
    width: 1.1875rem;
    height: 1.125rem;
    padding-top: 0.125rem;
    border: 2px solid ${({ borderColor }) => borderColor} !important;
    border-radius: 3px;
  }

  [class*='CheckBoxHidden']:checked ~ [class*='CheckBoxStyled'] {
    color: ${themeGet('colors.white')};
    background: ${({ backgroundCheckedColor }) => backgroundCheckedColor};
  }
`

export const StyledCheckBox = styled(CheckBox).attrs(props => {
  return {
    borderColor: props.indeterminate
      ? themeGet('colors.white')(props)
      : themeGet('colors.blueGray')(props),
    backgroundCheckedColor: props.indeterminate
      ? themeGet('colors.white')(props)
      : themeGet('colors.blueGray')(props),
  }
})`
  display: flex;
  margin-right: 0.4375rem;
  font-size: 1rem;
  padding-top: 0.1875rem;

  [class*='CheckBoxStyled-sc-'] {
    width: 1.1875rem;
    height: 1.125rem;
    padding-top: 0.125rem;
    border: 2px solid ${({ borderColor }) => borderColor} !important;
    border-radius: 3px;
  }

  [class*='CheckBoxHidden']:checked ~ [class*='CheckBoxStyled'] {
    color: ${themeGet('colors.white')};
    background: ${({ backgroundCheckedColor }) => backgroundCheckedColor};
  }
`

export const StyledStatusButton = styled(ContainedButton)`
  border-radius: 0.9375rem;
  width: 87px;
  height: 33px;
  font-size: 1rem;
  min-width: 0px;
  padding: 0.3125rem;
  margin-left: 1rem;
`
