import React from 'react'
import PropTypes from 'prop-types'
import MediaThumbnailButton from '../MediaThumbnailButton'
import { ViewWrapper } from '../LessonDetail/component.styles'
import { ThumbnailButtonContainer } from './component.styles'

const MediaGroup = ({ mediaList = [], locale }) => {
  if (!Array.isArray(mediaList)) {
    return null
  }

  return (
    <ViewWrapper data-testid="teach-media-group">
      {mediaList.map(media => {
        const {
          description,
          fileType,
          fileUrl,
          thumbnailUrl,
          time,
          title,
          tracks,
          id,
          videoUrlExtended,
          transcriptSource,
          transcriptExtended,
        } = media || {}

        return (
          id && (
            <ThumbnailButtonContainer key={id}>
              <MediaThumbnailButton
                description={description}
                entryId={id}
                fileType={fileType}
                fileUrl={fileUrl}
                locale={locale}
                thumbnailUrl={thumbnailUrl}
                time={time}
                title={title}
                tracks={tracks}
                transcriptExtended={transcriptExtended}
                transcriptSource={transcriptSource}
                videoUrlExtended={videoUrlExtended}
              />
            </ThumbnailButtonContainer>
          )
        )
      })}
    </ViewWrapper>
  )
}

MediaGroup.propTypes = {
  locale: PropTypes.string,
  mediaList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      fileType: PropTypes.string.isRequired,
      fileUrl: PropTypes.string.isRequired,
      thumbnailUrl: PropTypes.string,
      time: PropTypes.string,
      title: PropTypes.string,
      tracks: PropTypes.array,
    }),
  ).isRequired,
}

export default MediaGroup
