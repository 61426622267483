import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { TelescopeIcon } from 'secondstep-components'

export const StyledContainer = styled.div`
  background-image: url(${props => props.bannerImage});
  background-repeat: repeat-x;
  background-size: cover;
  margin-bottom: ${themeGet('spacing.turquoise20_1_25rem')};
  width: 100vw;
  padding-top: ${themeGet('spacing.turquoise20_1_25rem')};
  padding-bottom: ${themeGet('spacing.turquoise20_1_25rem')};
  top: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  color: ${themeGet('colors.secondStepBlue')};

  a,
  a:visited {
    color: ${themeGet('colors.secondStepBlue')};
  }
`

export const StyledTelescopeIcon = styled(TelescopeIcon)`
  float: left;
  margin-top: ${themeGet('spacing.green4_0_25rem')};
  margin-right: ${themeGet('spacing.blue16_1rem')};
  rect {
    stroke: ${themeGet('colors.secondStepBlue')};
  }
  path {
    stroke: ${themeGet('colors.secondStepBlue')};
    fill: ${themeGet('colors.lightishBlueGray')};
  }
`
