import React from 'react'
import PropTypes from 'prop-types'
import { toUrlSafe } from 'utils/stringHelpers'
import { FTUE } from './constants'
import FirstTimeClasses from './component'
import {
  StyledContainedButton as ContainedButton,
  StyledOutlinedButton as OutlinedButton,
  StyledCreateCourse as CreateCourse,
} from './component.styles'
import Spinner from './Spinner'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import withApiError, {
  SHOW_CONTENT_ON_401,
  SHOW_ERROR_IN_MODAL,
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_ACCESS,
  ERROR_TYPE_LMS,
} from 'layers/errorHandling/apiError'

const ftueButtonType = PropTypes.shape({
  buttonText: PropTypes.string,
  url: PropTypes.string,
})

export const FirstTimeClassesContainer = props => {
  const { isFetching, isMsProduct, show } = props

  const getTailGroups = () => {
    const { entry, onComponentClick, contentfulCourses } = props

    if (!entry) {
      return []
    }

    return [
      {
        name: FTUE.EDUCATOR_PREP,
        tiles: [
          {
            title: entry.training,
            description: entry.trainingDescription,
            button: (
              <ContainedButton
                aria-labelledby={`${entry.trainingButton.buttonText} button`}
                dataTestId={`${toUrlSafe(
                  entry.trainingButton.buttonText,
                )}-button`}
                onClick={() => onComponentClick(entry.trainingButton.url)}
                onKeyPress={event => {
                  if (event.key === 'Enter' || event.key === 'Space') {
                    return onComponentClick(entry.trainingButton.url)
                  }
                }}
                tabIndex={0}
              >
                {entry.trainingButton.buttonText}
              </ContainedButton>
            ),
            tileType: 'training',
          },
          {
            title: entry.createClass,
            description: entry.createClassDescription,
            // CREATECOURSE FROM DASHBOARD
            button: (
              <CreateCourse
                contentfulCourses={contentfulCourses}
                emptyClasses
                key={`create-course`}
              />
            ),
            tileType: 'createClass',
          },
        ],
      },
      {
        name: FTUE.DISCOVER_MORE,
        tiles: [
          {
            title: entry.explore,
            description: entry.exploreDescription,
            button: (
              <OutlinedButton
                dataTestId={`${toUrlSafe(
                  entry.exploreButton.buttonText,
                )}-button`}
                onClick={() => onComponentClick(entry.exploreButton.url)}
                onKeyPress={event => {
                  if (event.key === 'Enter' || event.key === 'Space') {
                    return onComponentClick(entry.exploreButton.url)
                  }
                }}
                tabIndex={0}
              >
                {entry.exploreButton.buttonText}
              </OutlinedButton>
            ),
            tileType: 'explore',
          },
        ],
      },
    ]
  }

  if (show && isFetching) {
    return <Spinner />
  }
  return (
    <FirstTimeClasses
      isMsProduct={isMsProduct}
      show={show}
      tileGroups={getTailGroups()}
    />
  )
}

FirstTimeClassesContainer.propTypes = {
  contentfulCourses: PropTypes.array,
  entry: PropTypes.shape({
    explore: PropTypes.string,
    exploreButton: ftueButtonType,
    exploreDescription: PropTypes.string,
    createClass: PropTypes.string,
    createClassDescription: PropTypes.string,
    id: PropTypes.string,
    training: PropTypes.string,
    trainingButton: ftueButtonType,
    trainingDescription: PropTypes.string,
  }),
  isFetching: PropTypes.bool,
  isMsProduct: PropTypes.bool,
  onComponentClick: PropTypes.func,
  /**
   * This prop is used to determine whether or not to show the component and it's children.
   * We can't simply unmount it as we need `<CreateCourse/>` to be in the DOM for us to
   * leverage it's modal funtionality.
   */
  show: PropTypes.bool.isRequired,
}

const options = {
  include: 1,
  entryIdProp: true,
}

export default ContentEntryDataGetter(
  withApiError(
    FirstTimeClassesContainer,
    [ERROR_TYPE_CONTENTFUL, ERROR_TYPE_LMS, ERROR_TYPE_ACCESS],
    [SHOW_CONTENT_ON_401, SHOW_ERROR_IN_MODAL],
  ),
  options,
)
