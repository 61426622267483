import styled from 'styled-components'
import { BodyCopy, HeaderOne, TextLink } from 'secondstep-components'
import { themeGet } from 'styled-system'
import { pageTypes } from 'utils/getPageTypeByUrl'

const breakpoints = {
  aboveTablet: '@media (max-width: 900px)',
  belowTablet: '@media (max-width: 650px)',
}

export const SubBannerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 3.25rem;
  padding-top: 3.3125rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  box-sizing: border-box;
  position: relative;
  text-align: center;

  ${breakpoints.belowTablet} {
    padding-bottom: 2rem;
  }
`

export const SubBannerBackground = styled.div.attrs(
  ({ isSelaLeaderPage, pageType }) => ({
    background: isSelaLeaderPage
      ? themeGet(
          'colors.backgrounds.banners.secondary',
          'colors.backgrounds.secondary',
        )
      : themeGet(
          'colors.backgrounds.banners.primary',
          'colors.backgrounds.primary',
        ),
    backgroundImages: getBannerThemeSettingsByPageType(pageType),
  }),
)`
  width: 100%;
  height: calc(100% + 60px);
  position: absolute;
  top: 0;
  left: 0;
  background: ${({ background }) => background};
  background-image: url(${({ backgroundImages }) =>
    themeGet(`${backgroundImages}.sublandingBackgroundImage`)});
  background-size: cover;
  background-position: center;
  z-index: 0;

  ${breakpoints.aboveTablet} {
    background-image: url(${({ backgroundImages }) =>
      themeGet(`${backgroundImages}.tabletSublandingBackgroundImg`)});
    background-size: ${themeGet('banners.tabletSublandingBackgroundSize') ??
      '100% 100%'};
    background-position: ${themeGet(
      'banners.tabletSublandingBackgroundPosition',
    ) ?? 'inherit'};
  }

  ${breakpoints.belowTablet} {
    background-image: url(${({ backgroundImages }) =>
      themeGet(`${backgroundImages}.mobileSublandingBackgroundImg`)});
    padding-left: 0;
    padding-right: 0;
    background-position: ${themeGet(
      'banners.mobileSublandingBackgroundPosition',
    ) ?? 'inherit'};
  }
`

export const SubBannerContentWrapper = styled.div`
  position: relative;
  z-index: 1;
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
`

export const StyledTextLink = styled(TextLink)`
  position: absolute;
  top: -2.4375rem;
  left: 1.75rem;

  ${themeGet('breakpoints.large')} {
    left: 0;
  }
`

export const SubBannerText = styled(BodyCopy).attrs(({ pageType }) => ({
  textSettings: getBannerThemeSettingsByPageType(pageType),
}))`
  a {
    color: ${({ textSettings }) => themeGet(`${textSettings}.bannerTextColor`)};
  }
  color: ${({ textSettings }) => themeGet(`${textSettings}.bannerTextColor`)};
  margin-top: 0.1875rem;
  max-width: 60%;
  font-size: 1.125rem;
  display: inline-block;

  ${breakpoints.belowTablet} {
    max-width: 75%;
    font-size: 1rem;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    max-width: 100%;
  }
`

export const Title = styled(HeaderOne).attrs(({ pageType }) => ({
  textSettings: getBannerThemeSettingsByPageType(pageType),
}))`
  color: ${({ textSettings }) => themeGet(`${textSettings}.headerTextColor`)};
  font-family: ${themeGet('banners.headerTextFont')};
  font-weight: ${themeGet('banners.headerTextFontWeight', 'normal')};
  margin-bottom: 0.688rem;
  text-align: center;
  max-width: none;

  ${breakpoints.belowTablet} {
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    margin: 0;
    max-width: 100%;
  }
`

const getBannerThemeSettingsByPageType = pageType => {
  switch (pageType) {
    case pageTypes.HS_TRAINING_PAGE:
      return 'banners.trainingPage'
    case pageTypes.HS_SUPPORT_PAGE:
      return 'banners.supportPage'
    default:
      return 'banners'
  }
}
