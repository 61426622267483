import React from 'react'

export function manageContent(
  content,
  ContentComponents,
  getContentComponent,
  callback,
) {
  if (!content || content.length <= 0) {
    return null
  }

  const contentComponents = []
  let inAccordion = false
  let accordionContent = []
  let accordionData = {}
  for (const child of content) {
    const { name, data } = child
    const ContentComponent = ContentComponents[name]

    if (name === 'accordion' && !inAccordion) {
      inAccordion = true
      accordionData = data
      accordionContent = []
      continue
    }
    if (name === 'accordion' && inAccordion) {
      inAccordion = false
      contentComponents.push(
        <ContentComponent
          key={`content-${contentComponents.length}-${name}`}
          {...accordionData}
        >
          {accordionContent}
        </ContentComponent>,
      )
      continue
    }
    if (inAccordion) {
      accordionContent.push(getContentComponent(child))
      continue
    }
    contentComponents.push(getContentComponent(child, contentComponents.length))
  }
  return callback(contentComponents)
}
