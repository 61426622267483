import styled from 'styled-components'
import { AwardIcon, TelescopeV2Icon, ToolboxIcon } from 'secondstep-components'

export const StyledTelescope = styled(TelescopeV2Icon)`
  width: 1.25rem;
  color : theme.meta.themeName === 'elementary'
        ? themeGet('colors.elemDigital3')
        : themeGet('colors.darkBlue');
`

export const StyledAwardIcon = styled(AwardIcon)`
  width: 1.25rem;
`

export const StyledToolboxIcon = styled(ToolboxIcon)`
  width: 1.25rem;
`
