import { themeGet } from 'styled-system'
import {
  BackpackIcon,
  ToolboxIcon,
  ProgramThemeIcons,
  AwardIcon,
} from 'secondstep-components'

import { VideoPlayIcon } from 'secondstepVideo'

export const iconMap = {
  'Principal Toolkit': {
    Icon: ToolboxIcon,
  },
  'Family Resources': {
    Icon: BackpackIcon,
  },
  'Grades 9–12 Family Resources': {
    Icon: BackpackIcon,
  },
  'Program Training': {
    Icon: ProgramThemeIcons['AcademicSuccessIcon'],
  },
  'Program Supports': {
    Icon: ProgramThemeIcons['HelpingOthersIcon'],
  },
  'k8 digital - video button': {
    Icon: VideoPlayIcon,
  },
  'k8 digital - elementary button': {
    Icon: BackpackIcon,
  },
  'k8 digital - ms button': {
    Icon: BackpackIcon,
  },
  'ms static training': {
    Icon: AwardIcon,
  },
  'elem static training': {
    Icon: AwardIcon,
  },
  'ost training': {
    Icon: ProgramThemeIcons['AcademicSuccessIcon'],
  },
}

export const linkMap = {
  'not attempted': {
    text: 'Start Training',
    color: themeGet('colors.teal'),
  },
  incomplete: {
    text: 'Resume Training',
    color: themeGet('colors.yellow'),
  },
  completed: {
    text: 'Review Training',
    color: themeGet('colors.blue'),
  },
}
