export const splitProgramThemes = programThemes => {
  const needSecondColumn = programThemes.length > 3
  let firstProgramThemesArray = []
  let secondProgramThemesArray = []

  if (needSecondColumn) {
    const half = Math.ceil(programThemes.length / 2)
    firstProgramThemesArray = programThemes.slice(0, half)
    secondProgramThemesArray = programThemes.slice(half, programThemes.length)
  }

  if (!needSecondColumn) {
    firstProgramThemesArray = programThemes
  }

  return {
    needSecondColumn,
    firstProgramThemesArray,
    secondProgramThemesArray,
  }
}
