import styled, { withTheme } from 'styled-components'
import styledNative from 'styled-components/native'
import { themeGet } from 'styled-system'
import { BodyCopy } from 'secondstep-components'

export const BodyWrapper = withTheme(styledNative.View`
  margin-top: 0.438rem;
  color: ${themeGet('colors.darkGray')};
  overflow-wrap: break-word;
`)

export const StyledSup = styled.sup`
  ${props => props.hasPointerCursor && 'cursor: pointer;'}
`

export const StyledBodyCopy = styled(BodyCopy).attrs(
  ({ renders, children }) => {
    return {
      components: renders,
      children,
    }
  },
)``
