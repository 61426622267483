import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { CloseButton, Modal, ModalContentWrapper } from 'secondstep-components'

export const StyledCloseButton = styled(CloseButton)`
  ${themeGet('breakpoints.mobileLarge')} {
    padding-right: 1rem;
  }
`

export const StyledModal = styled(Modal)`
  ${ModalContentWrapper} {
    box-sizing: border-box;
    height: 431px;
    padding: 20px;
    width: 40.625rem;
    ${themeGet('breakpoints.mobileLarge')} {
      height: auto;
    }
  }
`
