import axios from 'axios'
import { domains } from 'config/env'
import Auth0Manager from 'auth/utils/auth0Manager'

const baseApiAddress = `${domains.LMS_API_V2}/`

const REQ = {
  optional: 'optional',
  required: 'required',
}

const api = (function(baseApiAddress, fakeout = false) {
  // const _generateFakeData = fakeout
  // let _lastId = 0
  const _api = axios.create({
    baseURL: baseApiAddress,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
    timeout: 25000, // HACK: This should be 10 seconds instead of 25.
    // Azure pipelines sometimes delay network request and made E2E tests fail
  })
  _api.interceptors.request.use(async request => {
    const token = await Auth0Manager.getAccessToken()
    request.headers.Authorization = `Bearer ${token}`
    return request
  })

  _api.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response.status === 401) {
        location.replace(window.location.origin)
      }
      return Promise.reject(error)
    },
  )

  return {
    /*
    /instructor/{email}:
      post:
        operationId: addClassToInstructor
        summary: creates a new class for the given instructor
        description: creates a new class for the given instructor
        tags:
          - lmsadmins
          - admins
        produces:
          - application/json
        parameters:
          - in: path
            name: email
            description: Email address of the instructor for which to add the class
            required: true
            type: string
          - in: body
            name: class
            description: Class to create
            required: true
            schema:
              $ref: '#/definitions/Class'
        responses:
          201:
            description: Class created
          400:
            description: |
              Bad request; invalid Course ID _or_ instructor has no license for
              specified Course
          403:
            description: |
              User is not allowed to manage classes for the specified instructor
    */

    addClassToInstructor: async (courseInstance, userContext) => {
      // deal with making sure requirements are met
      const gradeToProgramMap = {
        K: 'k5SelDigital',
        '1': 'k5SelDigital',
        '2': 'k5SelDigital',
        '3': 'k5SelDigital',
        '4': 'k5SelDigital',
        '5': 'k5SelDigital',
        '6': 'mspDigital',
        '7': 'mspDigital',
        '8': 'mspDigital',
      }
      const { location, paks, sites } = userContext
      const gradeNumber = courseInstance.grade.split(' ')[1]
      const site =
        sites?.find(({ siteId }) => siteId === courseInstance.siteId) || {}

      const programName = gradeToProgramMap?.[gradeNumber]
      const program = site.programs[programName] || {}
      const course =
        program.courses?.find(({ name }) => name === gradeNumber) || {}
      const newSite = {
        claims: site.claims,
        programs: {
          [programName]: {
            courses: [course],
            preferences: {
              ...program.preferences,
            },
          },
        },
        siteId: site.siteId,
      }

      const requiredKeys = {
        licenseId: REQ.required,
        title: REQ.required,
      }
      const missingKey = Object.entries(requiredKeys).find(
        ([key, require]) =>
          !Object.keys(courseInstance).includes(key) &&
          require === REQ.required,
      )

      const expectedReqUserContext = {
        location,
        paks,
        sites: [newSite],
      }

      if (missingKey) {
        console.error(
          `Error: addClassToInstructor() missing "${missingKey[0]}"`,
        )
        return
      }

      const userContextB64 = btoa(JSON.stringify(expectedReqUserContext))

      const reqBody = {
        classTitle: courseInstance.title,
        licenseId: courseInstance.licenseId,
        userContextB64,
      }

      return _api.post(`/instructor/ms`, reqBody)
    },

    fetchOrCreateInstructorProgramFlex: async (siteId, userContext) => {
      const userContextB64 = btoa(JSON.stringify(userContext))
      const reqBody = {
        siteId,
        userContextB64,
      }
      return _api.post('/instructor/me', reqBody)
    },

    baseApiAddress: baseApiAddress,
  }
})(baseApiAddress, true)

export default api
