import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Bullet = styled.span`
  display: inline-block;
  height: 0.8125rem;
  width: 0.8125rem;
  margin-right: 0.5625rem;
  margin-bottom: 0.25rem;
  border-radius: 50%;
  background: ${themeGet('colors.yellow')};
`

export const Divider = styled.span`
  font-size: 1.125rem;
`

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 18.125rem;
  min-width: 15.5rem;
`

export const SecondaryText = styled.span`
  font-family: ${themeGet('font.molde.regular')};
  font-size: 1.125rem;
  line-height: 1.25rem;
  color: ${themeGet('colors.darkGray')};
  margin-top: 0.3125rem;
`

export const SchoolsText = styled(SecondaryText)`
  font-family: ${themeGet('font.molde.medium')};
  padding-right: 0.5625rem;
`

export const SummaryCards = styled.div`
  display: flex;
  gap: 1.25rem;
  padding-top: 1rem;
`

export const SummaryHeader = styled.div`
  display: flex;
  align-items: center;
`

export const Title = styled.span`
  font-family: ${themeGet('font.sharpSlab.semibold')};
  font-size: 1.4375rem;
  line-height: 1.5625rem;
  color: ${themeGet('colors.darkBlue')};
  margin-right: 20px;
`

export const TotalWithoutClasses = styled.div`
  display: flex;
  margin-left: 0.8rem;

  span:first-child {
    margin-top: 0.375rem;
    margin-bottom: 0;
  }
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5rem 1.25rem 1.25rem;
  margin-bottom: 1.25rem;
  box-sizing: border-box;
  border-radius: 0.625rem;
  background: ${themeGet('colors.backgrounds.primary', 'colors.white')};

  &:last-of-type {
    margin-bottom: 0;
  }
`
