import React from 'react'
import PropTypes from 'prop-types'

import {
  CardPanel,
  Description,
  Icon,
  IconWrapper,
  Sub,
  TextArea,
  Title,
  Titles,
} from './component.styles'

const ImplementationLevelCardComponent = props => {
  const {
    alt,
    clickable,
    description,
    icon,
    iconHeight,
    iconWidth,
    isActive,
    subtitle,
    title,
    toggleActiveState,
  } = props

  const handleClick = () => {
    if (clickable === false) return
    toggleActiveState()
  }

  return (
    <CardPanel
      clickable={clickable}
      isActive={isActive}
      onClick={() => handleClick()}
    >
      {subtitle && <Sub isActive={isActive}>{subtitle}</Sub>}
      <IconWrapper
        clickable={clickable}
        iconHeight={iconHeight}
        iconWidth={iconWidth}
      >
        <Icon alt={alt} src={icon} />
      </IconWrapper>
      <TextArea>
        <Titles subtitle={subtitle}>
          <Title>{title}</Title>
        </Titles>
        <Description>{description}</Description>
      </TextArea>
    </CardPanel>
  )
}

ImplementationLevelCardComponent.defaultProps = {
  clickable: true,
}

ImplementationLevelCardComponent.propTypes = {
  alt: PropTypes.string,
  clickable: PropTypes.bool,
  description: PropTypes.string,
  icon: PropTypes.string,
  iconHeight: PropTypes.number,
  iconWidth: PropTypes.number,
  isActive: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  toggleActiveState: PropTypes.func,
}

export default ImplementationLevelCardComponent
