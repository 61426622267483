import styled from 'styled-components'
import { BaseContainer, HeaderTwo } from 'secondstep-components'

export const StyledBaseContainer = styled(BaseContainer)`
  padding: 1.375rem 1.25rem 1.25rem;
  margin-top: 0;
`

export const StyledHeaderTwo = styled(HeaderTwo)`
  margin-bottom: 0.875rem;
`
