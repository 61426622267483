/* istanbul ignore file */
import React from 'react'

const ProgramArrowForward = () => (
  <svg height="24px" viewBox="0 0 24 24" width="24px">
    <g
      fill="none"
      fillRule="evenodd"
      id="ss_hs_myprograms_arrow_forward"
      stroke="none"
      strokeWidth="1"
    >
      <g id="Group-4">
        <rect
          fill="#FAEEF3"
          height="24"
          id="Rectangle"
          rx="2"
          width="24"
          x="0"
          y="0"
        />
        <polyline
          id="Path-2"
          points="10 7 15 12 10 17"
          stroke="#C62D70"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
        />
      </g>
    </g>
  </svg>
)

export default ProgramArrowForward
