import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  CsvDownloadMenu,
  HeaderTwo,
  Tooltip,
  TooltipContentWrapper,
  BodyCopy,
} from 'secondstep-components'

export const Background = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0.625rem 0 1.25rem;
  background: ${themeGet('colors.beaconGray')};
  [class*='StyledLabel'] {
    margin-bottom: 0;
    font-family: ${themeGet('font.molde.medium')};
    color: ${themeGet('colors.darkGray')};
    font-size: 0.8125rem;
    line-height: 1.5rem;
  }
  ${themeGet('breakpoints.mobileLarge')} {
    padding: 0;
  }
`

export const ContentWrapper = styled.div.attrs(({ hasOneSite }) => ({
  justifyContent: hasOneSite ? 'space-between' : 'flex-start',
}))`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  max-width: 1366px;

  @media (max-width: 730px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 0.375rem 1rem 0.625rem;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    padding-bottom: 1.5rem;
  }
`

const DropdownWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  position: relative;
  padding-right: 1.25rem;

  ${themeGet('breakpoints.mobile')} {
    width: 100%;
  }
`

export const CsvDropdownWrapper = styled(DropdownWrapper)`
  min-width: 285px;

  ${themeGet('breakpoints.tablet')} {
  }
`

export const CsvWrapper = styled(DropdownWrapper)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 165px;

  label {
    font-size: 0.75rem;
    line-height: 1.5rem;
    font-family: ${themeGet('font.molde.medium')};
    color: ${themeGet('colors.darkGray')};
  }

  ${themeGet('breakpoints.tablet')} {
    min-width: unset;
  }

  @media (max-width: 730px) {
    padding-left: 0;
    padding-right: 0;
  }

  ${themeGet('breakpoints.mobile')} {
    width: 165px;
  }
`

export const CsvTooltip = styled(Tooltip)`
  width: 14px;
  margin-left: 0.25rem;
  ${TooltipContentWrapper} {
    bottom: -7.75rem;
    left: -6.75rem;
    padding: 0.75rem 1rem;
    border-radius: 0.3125rem;
    line-height: 1rem;
    ::after {
      content: ' ';
      width: 0.75rem;
      height: 0.75rem;
      position: absolute;
      bottom: 93%;
      right: 8px;
      background-color: white;
      transform: translate(-50%) rotate(45deg);
    }
  }
  i.fas {
    color: ${themeGet('colors.blueGray')};
    font-size: 0.875rem;
  }
  ${BodyCopy} {
    color: ${themeGet('colors.darkGray')};
    margin-bottom: 0;
    p {
      font-size: 0.9125rem;
      line-height: 1rem;
      margin-block-end: 0;
      text-align: start;
    }
    strong {
      font-family: ${themeGet('fontFamilySemibold')};
      font-size: 0.9125rem;
    }
  }
`

export const GradeDropdownWrapper = styled(DropdownWrapper)`
  min-width: 165px;

  [class*='__Wrapper-'] {
    width: 100%;
  }

  ${themeGet('breakpoints.medium')} {
    min-width: 174px;
  }

  ${themeGet('breakpoints.tablet')} {
    width: 160px;
    min-width: auto;
  }

  @media (max-width: 730px) {
    margin-bottom: 0.875rem;
    padding-right: 0;
    width: 100%;
  }
`

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;

  label {
    font-size: 0.8125rem;
  }

  ${themeGet('breakpoints.mobile')} {
    justify-content: flex-start;
  }
`

export const LeftWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`

export const RightWrapper = styled.div`
  display: flex;

  ${themeGet('breakpoints.tablet')} {
    flex-direction: row;
  }

  @media (max-width: 730px) {
    flex-direction: column;
    width: 100%;
  }
`

export const SiteDropdownWrapper = styled(DropdownWrapper)`
  min-width: 285px;
  padding-left: 1.25rem;

  [class*='__Wrapper-'] {
    width: 100%;
  }

  ${themeGet('breakpoints.medium')} {
    min-width: 256px;
  }

  ${themeGet('breakpoints.tablet')} {
    width: 268px;
    min-width: 220px;
  }

  @media (max-width: 730px) {
    margin-top: 0.4375rem;
    margin-bottom: 0.875rem;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }
`

export const StyledCsvDownloadMenu = styled(CsvDownloadMenu)`
  align-self: flex-end;
  width: 100%;

  .dropdown-space-filler {
    display: none;
  }

  [class*='DownloadButton'] {
    position: relative;
    justify-content: flex-start;
    z-index: 2;
    padding: 0.125rem 0.6875rem 0.25rem 0.8125rem;
    background: ${themeGet('colors.blue')};
    border: none;
    border-radius: 15px;

    span {
      margin-top: 0.375rem;
      color: ${themeGet('colors.white')};
      font-family: ${themeGet('font.molde.medium')};
    }

    & > svg:first-of-type {
      width: 26px;
      height: 18px;
      margin-right: 0.5rem;
      transform: none;
      color: ${themeGet('colors.white')};
    }

    & > svg:last-of-type {
      position: absolute;
      right: 10px;
      width: 14px;
      height: 12px;
      margin-top: 0.125rem;
      color: ${themeGet('colors.white')};
    }
  }

  [class*='ReportLinkSeparator'] {
    justify-content: space-between;
    padding-left: 0.5625rem;
    margin: 0.5rem 0;

    span {
      margin-right: 0;
      width: 5.9375rem;
    }

    div {
      width: calc(100% - 5.9375rem);
    }
  }

  [data-testid='csv-download-menu-links'] {
    width: 225px;
    margin-top: 0.5rem;
    right: 20px;
    border-radius: 5px;

    [class*='ReportLink-'] {
      padding: 0.1875rem 0.625rem 0.3125rem;
      margin: 0.3125rem 0 !important;

      span {
        margin-left: 0;
        color: ${themeGet('colors.darkBlue')};
        font-family: ${themeGet('font.molde.medium')};
      }

      &:hover {
        background: ${themeGet('colors.darkBlue')};

        span {
          color: ${themeGet('colors.white')};
        }
      }
    }
  }

  [class*='ErrorMsg'] {
    position: absolute;
    bottom: -18px;
  }

  ${themeGet('breakpoints.tablet')} {
    align-self: flex-start;
    // max-width: none;
  }

  @media (max-width: 730px) {
    [data-testid='csv-download-menu-links'] {
      left: 0;
    }
  }

  ${themeGet('breakpoints.mobile')} {
    [data-testid='csv-download-menu-links'] {
      right: 0;
    }
  }
`

export const StyledHeaderTwo = styled(HeaderTwo)`
  margin: 0.625rem 1.125rem 0 0;
  font-size: 1.4375rem;
  line-height: 1.9375rem;
  padding-left: 1.25rem;
  max-width: 373px;
  font-family: ${themeGet('font.sharpSlab.semibold')};
  @media screen and (max-width: 630px) {
    margin-bottom: 0.5rem;
    margin-top: 0.25rem;
  }
  ${themeGet('breakpoints.mobile')} {
    font-size: 1.25rem;
    line-height: 1.6875rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    padding-left: 0;
  }
`

export const CsvLoadingMsg = styled.div`
  width: 100%;
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('font.molde.regular')};
  font-size: 0.75rem;
  line-height: 1rem;
  position: absolute;
  top: 4.25rem;
`
