import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import DividerHairline from 'components/Teach/DividerHairline'
import LessonSummary from 'components/Teach/LessonSummary'
import LessonDailyActivities from 'components/Teach/LessonDailyActivities'
import LessonContent from 'components/Teach/LessonContent'
import LessonGroup from 'components/Teach/LessonGroup'
import ContainerSection from 'components/Teach/ContainerSection'
import { SUMMARY_TITLE, PREP_TITLE } from './constants'
import { Wrapper, StyledHeaderOne } from './component.styles'

const LessonDetailsElPage = ({
  days,
  description,
  displayTitle,
  lessonPrepSections,
  materials,
  materialsPdfs,
  objectives,
  whyThisThemeMatters,
}) => {
  const hasLessonSummary = description || objectives || whyThisThemeMatters
  const hasLessonPrepSections =
    Array.isArray(lessonPrepSections) && lessonPrepSections.length > 0
  const hasLessonPrep = hasLessonPrepSections || materials || materialsPdfs

  return (
    <Wrapper>
      {displayTitle && <StyledHeaderOne>{displayTitle}</StyledHeaderOne>}
      {hasLessonSummary && (
        <LessonSummary
          description={description}
          objectives={objectives}
          summaryTitle={SUMMARY_TITLE}
          whyThisLessonMatters={whyThisThemeMatters}
        />
      )}
      {hasLessonPrep && (
        <ContainerSection title={PREP_TITLE}>
          <LessonGroup materials={materials} pdfs={materialsPdfs}>
            {hasLessonPrepSections &&
              lessonPrepSections.map((section, index) => {
                const { id, content, title } = section
                const hasDivider = index >= 1

                if (!content) {
                  return null
                }

                return (
                  <Fragment key={id}>
                    {hasDivider && <DividerHairline />}
                    <LessonContent content={content} title={title} />
                  </Fragment>
                )
              })}
          </LessonGroup>
        </ContainerSection>
      )}
      {days && <LessonDailyActivities days={days} />}
    </Wrapper>
  )
}

LessonDetailsElPage.propTypes = {
  bbPrepVideo: PropTypes.array,
  content: PropTypes.array,
  days: PropTypes.arrayOf(
    PropTypes.shape({
      dayText: PropTypes.string,
      dayPdfs: PropTypes.arrayOf(PropTypes.object),
    }),
  ),
  description: PropTypes.string,
  displayTitle: PropTypes.string,
  lessonPrepSections: PropTypes.array,
  materials: PropTypes.string,
  materialsPdfs: PropTypes.arrayOf(PropTypes.object),
  objectives: PropTypes.string,
  whyThisThemeMatters: PropTypes.string,
}

export default LessonDetailsElPage
