import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  BannerSpacer,
  HeaderWrapper,
  HeaderBackground,
  HeaderContent,
  StyledHairline,
  StyledTabSet,
  StyledTabButton,
  PageWrapper,
  ContentSetWrapper,
  StyledContentTypeDescription,
} from './component.styles'
import ContentOverview from 'experienceComponents/ContentOverview'
import PathwayCard from 'components/PathwayCard'
import PathwayDropdown from 'components/PathwayDropdown'
import HighSchoolPreviewBanner from 'components/HighSchoolPreviewBanner'

const PathwayTemplate = ({
  activeTab,
  baseRoute,
  description,
  handleTabChange,
  tabDescription,
  tabs,
  title,
  weeks,
  trainingCallout,
  isPreview,
}) => {
  const selectRef = useRef()
  const [tabTitle, setTabTitle] = useState(activeTab?.tabTitle)
  const handleOnPathwayChange = ({ option }) => {
    const pathWaySelected = tabs.find(unit => unit.tabTitle === option)
    setTabTitle(option)
    handleTabChange(pathWaySelected)
  }

  return (
    <PageWrapper>
      <HeaderWrapper>
        {isPreview && (
          <>
            <BannerSpacer />
            <HighSchoolPreviewBanner />
          </>
        )}
        <HeaderBackground />
        <HeaderContent>
          <ContentOverview description={description} title={title} />
          <StyledHairline />
          {tabs && (
            <>
              <PathwayDropdown
                className="pathway_dropdown"
                handleOnChange={handleOnPathwayChange}
                options={tabs.map(unit => unit.tabTitle)}
                ref={selectRef}
                value={tabTitle}
              />
              <StyledTabSet dataTestId="pathway-unit-tabset">
                {tabs.map((unit, index) => (
                  <StyledTabButton
                    dataTestId={`pathway-unit-tab-${(unit?.tabTitle ?? '')
                      .trim()
                      .toLowerCase()}`}
                    isActive={activeTab.tabTitle === unit.tabTitle}
                    key={index}
                    label={unit.tabTitle}
                    onClick={() => handleTabChange(unit)}
                  />
                ))}
              </StyledTabSet>
            </>
          )}
        </HeaderContent>
      </HeaderWrapper>

      <ContentSetWrapper hasFooter={tabDescription.hasFooter}>
        <StyledContentTypeDescription {...tabDescription} />
        {trainingCallout && (
          <PathwayCard
            description={trainingCallout.description}
            isPreview={isPreview}
            path={trainingCallout.route}
            title={trainingCallout.displayTitle}
          />
        )}
        {weeks
          ?.filter(
            ({ description, displayTitle, pageData } = {}) =>
              !!description && !!displayTitle && !!pageData?.route,
          )
          .map((week, i) => (
            <PathwayCard
              description={week.description}
              isPreview={isPreview}
              key={i}
              path={`${baseRoute}/${week?.pageData?.route}`}
              title={week.displayTitle}
            />
          ))}
      </ContentSetWrapper>
    </PageWrapper>
  )
}

PathwayTemplate.propTypes = {
  activeTab: PropTypes.object,
  baseRoute: PropTypes.string,
  description: PropTypes.string,
  handleTabChange: PropTypes.func,
  isPreview: PropTypes.bool,
  tabDescription: PropTypes.object,
  tabs: PropTypes.array,
  title: PropTypes.string,
  trainingCallout: PropTypes.shape({
    displayTitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
  }),
  weeks: PropTypes.array,
}

export default PathwayTemplate
