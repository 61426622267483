import React from 'react'
import ExplorePrograms from './component'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import { ERROR_TYPE_CONTENTFUL } from 'layers/errorHandling/apiError'
import withApiError from 'layers/errorHandling/apiError/component'
import { navigationEvent } from 'layers/navigation/store/operations'
import { findProgramLinks } from 'utils/findProgramLinks'
import { getMSOptInData } from 'utils/siteData'
import siteOperations from 'store/siteManager/operations'

const ExploreProgramsContainer = ({
  claims,
  className,
  navigationEvent,
  programQuickLinks,
  selectedProgram,
  mspSites,
  updateMSPOptIn,
}) => {
  const getAvailableLinks = () => {
    if (!programQuickLinks || !selectedProgram) {
      return
    }
    const { hasElemAdminClaim, hasMsAdminClaim } = claims

    const programAccessMap = {
      K8: {
        access: hasElemAdminClaim && hasMsAdminClaim,
        k5program: 'k5SelDigital',
        msprogram: 'mspDigital',
      },
    }

    return programAccessMap?.[selectedProgram.name]?.access
      ? findProgramLinks(programQuickLinks, selectedProgram, programAccessMap)
      : []
  }
  return (
    <ExplorePrograms
      availableLinks={getAvailableLinks()}
      className={className}
      mspSites={mspSites}
      navigationEvent={navigationEvent}
      updateMSPOptIn={updateMSPOptIn}
    />
  )
}

ExploreProgramsContainer.propTypes = {
  claims: PropTypes.object,
  className: PropTypes.string,
  mspSites: PropTypes.array,
  navigationEvent: PropTypes.func,
  programQuickLinks: PropTypes.array,
  selectedProgram: PropTypes.object,
  updateMSPOptIn: PropTypes.func,
}

const mapStateToProps = ({
  licenseManager,
  reportsManager,
  userAccessManager,
}) => {
  const { selectedProgram } = reportsManager || {}
  const { claims } = userAccessManager || {}
  let mspSites = []
  if (selectedProgram.key === 'K8') {
    mspSites = getMSOptInData(licenseManager.sites)
  }
  return {
    claims,
    selectedProgram,
    mspSites,
  }
}
const mapper = entry => {
  const { programQuickLinks } = entry || {}
  return {
    programQuickLinks,
  }
}

const options = {
  entryId: '5oqdbnI289AADFVmm2sJyx',
  include: 3,
  mapper,
  spread: true,
}
const mapDispatchToProps = {
  navigationEvent,
  updateMSPOptIn: siteOperations.updateMSPOptIn,
}

export default ContentEntryDataGetter(
  withApiError(ExploreProgramsContainer, [ERROR_TYPE_CONTENTFUL]),
  options,
  connect(mapStateToProps, mapDispatchToProps),
)
