import { FlagsComponentRouter } from 'flags'
import { isHighSchoolUrl, isHighSchoolDashboard } from 'utils/highschoolHelpers'

import OffComponent from './flag.off'
import OnComponent from './flag.on'

export { DefaultSiteSelectorWrapper } from './component.styles'

const FlagToggler = FlagsComponentRouter({
  flagSubscriptions: ['high-school-june-release'],
  OnComponent,
  OffComponent,
  onCondition: () => isHighSchoolUrl() || isHighSchoolDashboard(),
})

export default FlagToggler
