import TakeAction from './component'

const Container = props => {
  const { tabContent } = props || {}
  const { tabColumn1, tabColumn2, tabColumn3, moreInformation } =
    tabContent || {}
  const cards = [tabColumn1[0], tabColumn2[0], tabColumn3[0]]
  return (
    cards.length > 0 && (
      <TakeAction cards={cards} moreInformation={moreInformation} />
    )
  )
}

export default Container
