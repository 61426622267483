import { deepMerge } from 'grommet/utils'
import { themes as componentLibraryThemes } from 'secondstep-components'
import variables from './variables'
import bpu from './bpu'
import cpu from './cpu'
import elementary from './elementary'
import elkit from './elkit'
import extend from './extend'
import familyResources from './familyResources'
import k5kit from './k5kit'
import mainTheme from './main'
import middleSchool from './middleSchool'
import mskit from './mskit'
import ostKit from './ostKit'
import ostKitK1 from './ostKitK1'
import ostKit23 from './ostKit23'
import ostKit45 from './ostKit45'
import library from './library'
import platform from './platform'
import principalToolkit from './principalToolkit'
import professionalDevelopment from './professionalDevelopment'
import resources from './resources'
import sela from './sela'
import teach from './teach'
import training from './training'
import mindyeti from './mindyeti'
import highschool from './highSchool'

const main = deepMerge(componentLibraryThemes.baseTheme, mainTheme)

const themes = {
  bpu,
  cpu,
  elementary,
  elkit,
  extend,
  familyResources,
  k5kit,
  library,
  main,
  middleSchool,
  mskit,
  ostKit,
  ostKitK1,
  ostKit23,
  ostKit45,
  platform,
  principalToolkit,
  professionalDevelopment,
  resources,
  sela,
  teach,
  training,
  mindyeti,
  highschool,
}

const getTheme = theme => {
  if (themes[theme]) {
    return deepMerge(themes.main, themes[theme])
  }
  return themes.main
}

export { variables, getTheme, themes }
