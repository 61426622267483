import React from 'react'
import PropTypes from 'prop-types'

import LicenseCard from 'components/dashboard/LicenseCard'

const LicenseCardNotification = ({ unassignedLicenses, navigationEvent }) => (
  <LicenseCard
    navigationEvent={navigationEvent}
    unassignedLicenses={unassignedLicenses}
  />
)

LicenseCardNotification.propTypes = {
  navigationEvent: PropTypes.func,
  unassignedLicenses: PropTypes.array,
}

export default LicenseCardNotification
