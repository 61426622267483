import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'
dayjs.extend(localizedFormat)
dayjs.extend(duration)

const INVALID_DATE_DURATIONS = {
  POH: 'P0D',
  POM: 'P0D',
  P0: 'P0D',
  P: 'P0D',
}

const DEFAULT_DURATION = 0

export const convertDurationToMs = durationInput => {
  const validDuration = durationInput
    ? INVALID_DATE_DURATIONS[durationInput] || durationInput
    : DEFAULT_DURATION
  const ms = dayjs.duration(validDuration).asMilliseconds()
  return ms
}

export const convertMillisecondsToHoursMinutesObject = ms => ({
  hours: Math.floor((ms / (1000 * 60 * 60)) % 24),
  minutes: Math.floor((ms / (1000 * 60)) % 60),
})

export const convertMillisecondsToHoursMinutes = ms => {
  const { hours, minutes } = convertMillisecondsToHoursMinutesObject(ms)

  let hourTextDisplay = ''
  let minutesTextDisplay = `${minutes} minutes`
  if (hours > 1) {
    hourTextDisplay = `${hours} hours ` // trailing space is intentional
  } else if (hours === 1) {
    hourTextDisplay = `${hours} hour ` // trailing space is intentional
  }

  if (minutes === 1) {
    minutesTextDisplay = `${minutes} minute`
  }
  return `${hourTextDisplay}${minutesTextDisplay}`
}

export const convertMilliSecondsToHoursMinutesCertificate = ms => {
  const { hours, minutes } = convertMillisecondsToHoursMinutesObject(ms)
  // 1 hr. 30 min.
  let hourTextDisplay = ''
  let minutesTextDisplay = `${minutes} min.`
  if (hours > 1) {
    hourTextDisplay = `${hours} hr. ` // trailing space is intentional
  } else if (hours === 1) {
    hourTextDisplay = `${hours} hr. ` // trailing space is intentional
  }

  if (minutes === 1) {
    minutesTextDisplay = `${minutes} min.`
  }
  return `${hourTextDisplay}${minutesTextDisplay}`
}

export const calculateTotalCompletedTime = (trainingChildren = []) => {
  let totalCompletedTrainingTime = 0
  if (trainingChildren.length) {
    const timeInMilliseconds =
      trainingChildren.length &&
      trainingChildren
        .filter(({ doneAt }) => doneAt)
        .reduce(
          (accumulatedTimeInMs, { time: duration }) =>
            accumulatedTimeInMs + convertDurationToMs(duration),
          0,
        )

    totalCompletedTrainingTime = convertMillisecondsToHoursMinutes(
      timeInMilliseconds,
    )
  }

  return totalCompletedTrainingTime
}

export const dateToYYYYMMDD = date => {
  const MMDDYYYY = /(\d{2})\/(\d{2})\/(\d{4})/
  if (date && date.match(MMDDYYYY)) {
    date = date.replace(MMDDYYYY, '$3-$1-$2')
  }
  return date
}

export const displayDate = (date, format = 'MMMM DD, YYYY') => {
  if (!date) {
    return ''
  }
  const formattedDate = dayjs(date).format(format)
  return formattedDate
}

export const isoDurationToHoursMinutes = durationInput => {
  const durationMs = convertDurationToMs(durationInput)
  const hoursMinutesText = convertMillisecondsToHoursMinutes(durationMs)
  return hoursMinutesText
}

export const dateToMMMMddyyyy = date => {
  return `${date?.toLocaleString('default', {
    month: 'long',
  })} ${date?.getDate()}, ${date?.getFullYear()}`
}
