import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const ResourcesLandingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`

export const BackgroundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0 0 2rem;
  margin-top: -${themeGet('spacing.redTurquoise60_3_75rem')};
  background-color: ${themeGet('resources.backgroundColorBottom')};
`

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
  box-sizing: border-box;
  padding: 0 ${themeGet('spacing.turquoise20_1_25rem')} 0;
  ${themeGet('breakpoints.mobileLarge')} {
    padding: 0 ${themeGet('spacing.blue16_1rem')} 0;
  }
`
