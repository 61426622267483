import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { AwardIcon, BodyCopy, Hairline, TextLink } from 'secondstep-components'

export const Card = styled.div`
  background-color: ${themeGet('colors.white')};
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  font-family: ${themeGet('font.molde.medium')};
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 4.875rem 0 1.25rem 1.25rem;
  width: 100%;

  @media screen and (max-width: 1250px) {
    flex-direction: column;
    padding: 1.25rem;
  }
`

export const HeaderLine = styled.p`
  color: ${themeGet('colors.darkGray')};
  font-size: 1.125rem;
  margin: 1rem 0 0.5rem 0;
  text-align: center;
`

export const IconsContainer = styled.div`
  display: flex;
  gap: 1rem;
`

export const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 300px;
  width: 100%;
`

export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 0.25rem;

  @media screen and (max-width: 970px) {
    padding-left: 0;
  }
`

export const SecondText = styled.span`
  max-width: 300px;
  color: ${themeGet('colors.darkGray')};
  font-size: 1rem;
  line-height: 1.0625rem;
  font-family: ${themeGet('font.molde.regular')};
  text-align: center;
`

export const StyledHairline = styled(Hairline)`
  background-color: ${themeGet('colors.paleGray')};
  transform: rotate(90deg);
  height: 2px;
  width: 220px;
  margin: 0 -6rem;

  @media screen and (max-width: 1250px) {
    display: none;
  }
`

const Button = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 2px solid ${themeGet('colors.paleGray')};
  border-radius: 0.625rem;
  width: 100%;
  max-width: 360px;
  padding: 0.875rem 1.25rem 0.875rem 1rem;

  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;
  }
`

export const ButtonText = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamily')};
`

export const ButtonTextLink = styled(TextLink)`
  color: ${themeGet('colors.blueGray')};
  font-family: ${themeGet('fontFamilySemibold')};
  padding-top: 0.1875rem;
`

export const ProgramToStaffButton = styled(Button)``

export const ProgramToStaffButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.75rem;

  ${themeGet('breakpoints.mobileLarge')} {
    align-items: center;
    padding-left: 0;
    padding-top: 1rem;
  }
`

export const StaffTrainingButton = styled(Button)`
  margin-top: 1.25rem;
  padding-left: 1.125rem;
`

export const StaffTrainingButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1.3125rem;

  ${themeGet('breakpoints.mobileLarge')} {
    align-items: center;
    padding-left: 0;
    padding-top: 1rem;
  }
`

export const StyledAwardIcon = styled(AwardIcon)`
  color: ${themeGet('colors.blueGray')};
  height: 30px;
  width: 21px;
`
