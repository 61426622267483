import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { operations as lmsOperations } from 'store/lmsManager'
import { operations as loadingOperations } from 'store/loadingManager'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_CONTENTFUL_PRODUCT,
  ERROR_TYPE_LMS,
} from 'layers/errorHandling/apiError'
import Sela from './component.jsx'
import { INITIAL_LOADING_TEXT, SELA_ROUTE, FT_ROUTE } from './constants'

export class SelaContainer extends Component {
  static propTypes = {
    lmsCourses: PropTypes.array.isRequired,
    location: PropTypes.object,
    match: PropTypes.object.isRequired,
  }
  constructor(props) {
    super(props)
    this.shouldUpdateLoadingState = this.shouldUpdateLoadingState.bind(this)
  }

  componentDidMount() {
    const {
      fetchInstructorData,
      isLoadingApp,
      lmsCourses,
      setAppIsLoadingState,
    } = this.props || {}

    if (lmsCourses.length === 0 && !isLoadingApp) {
      setAppIsLoadingState(INITIAL_LOADING_TEXT)
    }
    fetchInstructorData()
  }
  componentDidUpdate() {
    this.shouldUpdateLoadingState()
  }
  componentWillUnmount() {
    const { isLoadingApp, setAppNotLoadingState } = this.props || {}
    if (isLoadingApp) {
      setAppNotLoadingState()
    }
  }

  shouldUpdateLoadingState(prevProps) {
    const {
      lmsCourses,
      isLoadingApp,
      setAppIsLoadingState,
      setAppNotLoadingState,
    } = this.props || {}

    const readyToLoad =
      prevProps?.lmsCourses === lmsCourses && lmsCourses?.length === 0
    const notLoading = isLoadingApp && lmsCourses?.length > 0

    if (readyToLoad && !isLoadingApp) {
      setAppIsLoadingState(INITIAL_LOADING_TEXT)
    }
    if (notLoading) {
      setAppNotLoadingState()
    }
  }

  render() {
    const {
      lmsCourses,
      match,
      location: { pathname },
    } = this.props

    const contentfulMap = {
      [SELA_ROUTE]: '5SzltVIFMR8fBdxbCMiH32',
      [FT_ROUTE]: '6PXU4YwOlqyYJMxEpNd2Wu',
    }

    const productRoute = pathname.split('/')[2]

    const allModulesContentfulId = contentfulMap[productRoute]
    if (lmsCourses.length === 0) {
      return null
    }

    return (
      <Sela allModulesContentfulId={allModulesContentfulId} match={match} />
    )
  }
}

SelaContainer.propTypes = {
  entry: PropTypes.object,
  fetchInstructorData: PropTypes.func,
}

const mapStateToProps = ({ lmsManager, userAccessManager, loadingManager }) => {
  const { Sela, SelaFT } = lmsManager.instructorData || {}
  let lmsCourses = []
  lmsCourses = Sela?.length > 0 ? lmsCourses.concat(Sela) : lmsCourses
  lmsCourses = SelaFT?.length > 0 ? lmsCourses.concat(SelaFT) : lmsCourses
  return {
    lmsCourses,
    isLoadingApp: loadingManager?.isLoading,
    userAccessManager,
  }
}

const mapDispatchToProps = {
  fetchInstructorData: lmsOperations.fetchInstructorData,
  setAppIsLoadingState: loadingOperations.setIsLoadingState,
  setAppNotLoadingState: loadingOperations.setNotLoadingState,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  withApiError(SelaContainer, [
    ERROR_TYPE_CONTENTFUL,
    ERROR_TYPE_CONTENTFUL_PRODUCT,
    ERROR_TYPE_LMS,
  ]),
)
