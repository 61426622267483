/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import NavigationHelper from 'layers/navigation/navigationHelper'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_CONTENTFUL_PRODUCT,
} from 'layers/errorHandling/apiError'
import { userSegmentationBuilder } from 'utils/userSegmentationHelpers'
import {
  AI_HS_PAGE_USAGE_KEY,
  sendApplicationInsightsEvent,
} from 'utils/sendAnalyticsDataHelpers'
import { isPreviewMode } from 'utils/highschoolHelpers'

import Week from './component'

const Container = props => {
  const { entry, navigationEvent, userSegmentationInfo } = props || {}

  useEffect(() => {
    sendApplicationInsightsEvent(AI_HS_PAGE_USAGE_KEY, userSegmentationInfo)
  }, [userSegmentationInfo])

  return (
    <Week
      entry={entry}
      isPreview={isPreviewMode()}
      navigationEvent={navigationEvent}
    />
  )
}

const mapper = entry => {
  return { entry }
}

const options = {
  include: 3,
  mapper,
  entryIdProp: true,
  prefetch: ['es-US'],
  spread: true,
}
const navigationConnect = connect(
  NavigationHelper.mapStateToProps,
  NavigationHelper.mapDispatchToProps,
)

const mapStateToProps = state => ({
  userSegmentationInfo: userSegmentationBuilder(state),
})

export default ContentEntryDataGetter(
  withRouter(
    withApiError(Container, [
      ERROR_TYPE_CONTENTFUL,
      ERROR_TYPE_CONTENTFUL_PRODUCT,
    ]),
  ),
  options,
  navigationConnect,
  connect(mapStateToProps),
)
