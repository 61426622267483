import styled from 'styled-components'
import { themeGet } from 'styled-system'

const ReviewButton = styled.a`
  background: ${themeGet('colors.buttons.reviewBackground')};
  border-color: ${themeGet('colors.buttons.reviewBackground')};
  color: ${themeGet('colors.buttons.primaryFont')};
  border: 2px solid ${themeGet('colors.buttons.primaryBackground')};
  text-decoration: none;
  cursor: pointer;
  padding: 0.52rem 0 0.52rem;
  border-radius: ${themeGet('buttons.borderRadius')};
  min-width: 106px;
  font-family: ${themeGet('fontFamilySemibold')};
  text-align: center;
  text-transform: capitalize;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out,
    border-color 0.2s ease-in-out;

  :disabled {
    background: ${themeGet('colors.buttons.primaryBackgroundDisabled')};
    border-color: ${themeGet('colors.buttons.primaryBackgroundDisabled')};
  }

  h5 {
    color: ${themeGet('colors.buttons.primaryFont')};
  }

  &:not([disabled]):hover {
    background: ${themeGet('colors.buttons.reviewBackgroundHover')};
    border-color: ${themeGet('colors.buttons.reviewBackgroundHover')};
  }
`

export default ReviewButton
