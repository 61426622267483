import React from 'react'
import PropTypes from 'prop-types'

import { PROGRAM_SUPPORTS, PROGRAM_TRAINING } from './constants'

const ProgramTileIcon = ({
  titleText,
  ProgramSupportsIcon,
  ProgramTrainingIcon,
  SharedIcon,
}) => {
  if (titleText === PROGRAM_SUPPORTS && ProgramSupportsIcon) {
    return <ProgramSupportsIcon />
  } else if (titleText === PROGRAM_TRAINING && ProgramTrainingIcon) {
    return <ProgramTrainingIcon />
  } else if (SharedIcon) {
    return <SharedIcon />
  } else {
    return null
  }
}

ProgramTileIcon.propTypes = {
  ProgramSupportsIcon: PropTypes.elementType,
  ProgramTrainingIcon: PropTypes.elementType,
  SharedIcon: PropTypes.elementType,
  titleText: PropTypes.string.isRequired,
}

export default ProgramTileIcon
