import React from 'react'
import PropTypes from 'prop-types'
import StepModalCloseButton from './StepModalCloseButton'
import StepModalStep from './StepModalStep'
import { StyledModal } from './component.styles'

const StepModalComponent = ({
  confirmedOptions,
  currentStep,
  displayConfirmationPanel,
  handleClose,
  handleSelection,
  handleStepChange,
  hasError,
  isLevelChangeConfirmed,
  modals,
  onConfirmationChange,
  savedPreferences,
}) => (
  <StyledModal
    dataTestId="step-modal-modal"
    isModalOpen={true}
    modalClose={() => handleClose}
  >
    <StepModalCloseButton handleClose={handleClose} />
    {modals &&
      modals.length > 0 &&
      modals.map((step, id) => (
        <StepModalStep
          confirmedOptions={confirmedOptions}
          displayConfirmationPanel={displayConfirmationPanel}
          handleClose={handleClose}
          handleSelection={handleSelection}
          handleStepChange={handleStepChange}
          hasError={hasError}
          isCurrent={currentStep === id}
          isLevelChangeConfirmed={isLevelChangeConfirmed}
          key={`step-modal-step-${id}`}
          onConfirmationChange={onConfirmationChange}
          savedPreferences={savedPreferences}
          stepContent={step}
          stepNumber={id}
          stepsCount={modals.length - 2}
        />
      ))}
  </StyledModal>
)

StepModalComponent.propTypes = {
  confirmedOptions: PropTypes.array,
  currentStep: PropTypes.number,
  displayConfirmationPanel: PropTypes.bool,
  flowLabel: PropTypes.string,
  handleClose: PropTypes.func,
  handleSelection: PropTypes.func,
  handleStepChange: PropTypes.func,
  hasError: PropTypes.bool,
  isLevelChangeConfirmed: PropTypes.bool,
  modals: PropTypes.array,
  onConfirmationChange: PropTypes.func,
  savedPreferences: PropTypes.object,
}

export default StepModalComponent
