import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import CollapsibleIcon from '../CollapsibleIcon'
import { CollapsibleButtonStyled } from './component.styles'

const CollapsibleButton = ({
  tabIndex,
  a11yTitle = 'collapsible-button',
  children,
  className,
  dataTestId = 'collapsible-button',
  isOpen,
  onClick,
  small,
}) => (
  <CollapsibleButtonStyled
    a11yTitle={a11yTitle}
    tabIndex={tabIndex}
    className={className}
    dataTestId={dataTestId}
    isOpen={isOpen}
    onClick={onClick}
    small={small}
  >
    <CollapsibleIcon />
    {children}
  </CollapsibleButtonStyled>
)

CollapsibleButton.propTypes = {
  a11yTitle: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  small: PropTypes.bool,
}

export default styled(CollapsibleButton)``
