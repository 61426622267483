import {
  KEY_WORDS_TITLE,
  TEACHER_TIP_TITLE,
  LESSON_PREPARATION_TITLE,
} from './constants'

export const getLessonPrepSections = ({
  keyWords,
  teacherTip,
  lessonPreparation,
}) => {
  let sections = []

  if (keyWords) {
    sections = [...sections, { title: KEY_WORDS_TITLE, content: keyWords }]
  }

  if (teacherTip) {
    sections = [...sections, { title: TEACHER_TIP_TITLE, content: teacherTip }]
  }

  if (lessonPreparation) {
    sections = [
      ...sections,
      { title: LESSON_PREPARATION_TITLE, content: lessonPreparation },
    ]
  }

  return sections
}
