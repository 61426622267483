export const CLASSES_HEADER_TEXT = 'Classes Started'
export const LESSONS_HEADER_TEXT = 'Lesson Completion'
export const NEXT_BUTTON_TEXT = 'Next'
export const NO_CLASSES_TEXT = 'No Classes Yet'
export const ORDER_BY = {
  ASC: 'asc',
  DESC: 'desc',
}
export const PREVIOUS_BUTTON_TEXT = 'Previous'
export const SITES_HEADER_TEXT = 'Schools'
export const USERS_HEADER_TEXT = 'Users'
export const SORT_KEYS = {
  [SITES_HEADER_TEXT]: 'siteName',
  [USERS_HEADER_TEXT]: 'totalUsersCount',
  [CLASSES_HEADER_TEXT]: 'totalClassesCount',
}
export const SUBHEADING_TEXT =
  'Help staff get started by checking for active accounts and classes created'
