import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { getLabelFor, sortLocales, formatFileLinks } from 'utils/translations'
import { TranslatedPdfWrapper } from './component.styles'
import { FormInputSelect } from 'secondstep-components'

class MultilingualPdfSelector extends Component {
  static propTypes = {
    contentDescription: PropTypes.string,
    fileLinks: PropTypes.object.isRequired,
    locale: PropTypes.string.isRequired,
    targetLocales: PropTypes.array.isRequired,
  }

  openPdf(targetUrl) {
    window.open(targetUrl, '_blank')
  }

  render() {
    const { targetLocales, locale, contentDescription, fileLinks = {} } =
      this.props || {}
    const formattedFileLinks = formatFileLinks(fileLinks)
    const sortedLocales = sortLocales(targetLocales, locale).filter(
      targetLocale => !!formattedFileLinks[targetLocale],
    )

    const options = sortedLocales.map(locale => ({
      name: getLabelFor(locale),
      value: locale,
    }))

    const label = `Download ${contentDescription}`

    return (
      <TranslatedPdfWrapper>
        <FormInputSelect
          className="target-locale"
          dataTestId={'translation-select'}
          label={label}
          onChange={e => this.openPdf(formattedFileLinks[e.option.value])}
          optionKey={'name'}
          options={options}
          placeholder={label}
          value={{
            name: label,
            value: '',
          }}
          valueKey={'value'}
        />
      </TranslatedPdfWrapper>
    )
  }
}

export default MultilingualPdfSelector
