import PropTypes from 'prop-types'
import React from 'react'
import { Loading } from 'secondstep-components'
import Notification from 'components/Notification'
import LearnErrorBoundary from 'components/LearnErrorBoundary'
import LessonList from 'experienceComponents/LessonList'
import RecommendedAdvisoryActivities from 'experienceComponents/RecommendedAdvisoryActivities'
import UnitOverview from 'experienceComponents/UnitOverview'
import UnitTabSet from 'experienceComponents/UnitTabSet'
import VideoCallout from 'experienceComponents/VideoCallout'
import {
  AcknowledgementsLink,
  UnitComponentWrapper,
  UnitOverviewBox,
} from './component.styles'
import UnitComponentData from './data'
import UnitComponentEvents from './events'

const UnitComponent = ({
  acknowledgements,
  contentfulUnits = [],
  gradeTheme,
  match,
  isLoading,
  isFetching,
  navigationEvent,
  onSelectUnit,
  productName,
  unitEntryId,
  unitIndex,
  units = [],
}) => {
  if (isLoading || isFetching) {
    return <Loading />
  }
  const { index, unit, unitNumber } =
    typeof unitIndex === 'number'
      ? { index: unitIndex, unit: units[unitIndex], unitNumber: unitIndex + 1 }
      : UnitComponentData.findUnit({
          units,
          match,
        })
  const contentfulLessons = contentfulUnits && contentfulUnits[index]?.lessons

  if (!unit || contentfulUnits.length === 0) {
    return (
      <Notification color="danger">
        <h3>{`Unit ${match?.params?.unitTitle ?? 'unknown'} not found`}</h3>
      </Notification>
    )
  }

  const {
    advisoryActivities,
    videoEntryId,
    unitResources,
    contentfulItem,
  } = UnitComponentData.prepareContentfulData({
    contentfulUnits,
    unit,
  })
  const showAdditionalResources = advisoryActivities.size === 3
  const paramsContentfulId = unitEntryId ?? match?.params?.contentfulId

  return (
    <LearnErrorBoundary>
      <UnitComponentWrapper animation={'fadeIn'}>
        <UnitTabSet
          ariaControls={'unit-overviewbox'}
          onSelect={onSelectUnit}
          paramsContentfulId={paramsContentfulId}
          units={contentfulUnits}
        />
        <UnitOverviewBox id="unit-overviewbox" role={'tabpanel'}>
          <UnitOverview
            contentfulItem={contentfulItem}
            dataTestId={`unit-overview-${unitNumber}`}
            tabIndex={0}
          />
          {videoEntryId && (
            <VideoCallout
              dataTestId={`video-callout-${unitNumber}`}
              entryId={videoEntryId}
              id="video-callout"
            />
          )}
        </UnitOverviewBox>
        <LessonList
          contentfulLessons={contentfulLessons}
          dataTestId="lesson-list"
          gradeTheme={gradeTheme}
          lessonStartingIndex={unit.lessonStartingIndex}
          lessons={unit.lessons}
          match={match}
          unitResources={unitResources}
        />
        {showAdditionalResources && (
          <RecommendedAdvisoryActivities
            contentfulItem={contentfulItem}
            match={match}
            productName={productName}
            unitName={`Unit ${unitNumber}`}
          />
        )}
      </UnitComponentWrapper>

      {acknowledgements && (
        <AcknowledgementsLink
          acknowledgementsDataNOTCONNECTED={acknowledgements}
          dataTestId="acknowledgements-link"
          onClick={UnitComponentEvents.onClickAcknowledgements({
            match,
            acknowledgements,
            navigationEvent,
          })}
        >
          Acknowledgements
        </AcknowledgementsLink>
      )}
    </LearnErrorBoundary>
  )
}

UnitComponent.propTypes = {
  acknowledgements: PropTypes.object,
  contentfulUnits: PropTypes.array,
  gradeTheme: PropTypes.string,
  isFetching: PropTypes.bool,
  isLoading: PropTypes.bool,
  match: PropTypes.object.isRequired,
  navigationEvent: PropTypes.func,
  onSelectUnit: PropTypes.func.isRequired,
  productName: PropTypes.string,
  unitEntryId: PropTypes.string,
  unitIndex: PropTypes.number,
  units: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default UnitComponent
