import axios from 'axios'
import { domains } from 'config/env'
import Auth0Manager from 'auth/utils/auth0Manager'

const baseApiAddress = `${domains.LMS_API}/`

const REQ = {
  optional: 'optional',
  required: 'required',
}

const api = (function(baseApiAddress, fakeout = false) {
  // const _generateFakeData = fakeout
  // let _lastId = 0
  const _api = axios.create({
    baseURL: baseApiAddress,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
    timeout: 25000, // HACK: This should be 10 seconds instead of 25.
    // Azure pipelines sometimes delay network request and made E2E tests fail
  })
  _api.interceptors.request.use(async request => {
    const token = await Auth0Manager.getAccessToken()
    request.headers.Authorization = `Bearer ${token}`
    return request
  })

  _api.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response.status === 401) {
        location.replace(window.location.origin)
      }
      return Promise.reject(error)
    },
  )

  return {
    /*
    /instructor/{email}:
      post:
        operationId: addClassToInstructor
        summary: creates a new class for the given instructor
        description: creates a new class for the given instructor
        tags:
          - lmsadmins
          - admins
        produces:
          - application/json
        parameters:
          - in: path
            name: email
            description: Email address of the instructor for which to add the class
            required: true
            type: string
          - in: body
            name: class
            description: Class to create
            required: true
            schema:
              $ref: '#/definitions/Class'
        responses:
          201:
            description: Class created
          400:
            description: |
              Bad request; invalid Course ID _or_ instructor has no license for
              specified Course
          403:
            description: |
              User is not allowed to manage classes for the specified instructor
    */

    addClassToInstructor: async courseInstance => {
      // deal with making sure requirements are met
      const requiredKeys = {
        courseId: REQ.required,
        description: REQ.optional,
        endDate: REQ.required,
        licenseId: REQ.required,
        siteId: REQ.required,
        startDate: REQ.required,
        title: REQ.required,
      }

      const missingKey = Object.entries(requiredKeys).find(
        ([key, require]) =>
          !Object.keys(courseInstance).includes(key) &&
          require === REQ.required,
      )

      if (missingKey) {
        console.error(
          `Error: addClassToInstructor() missing "${missingKey[0]}"`,
        )
        return
      }

      return _api.post(`/instructor/me`, courseInstance)
    },

    baseApiAddress: baseApiAddress,

    createSelaClass: async () => {
      return _api.post(`/instructor/me/sela`)
    },

    createFTClass: async () => {
      return _api.post(`/instructor/me/sela/ft`)
    },

    deleteClass: async courseInstance => {
      return _api.delete(`/instructor/me/${courseInstance}`)
    },

    /*
    /{classid}:
      get:
        operationId: getClass
        summary: get single class by class (instance) ID
        description: Get one class by class (instance) ID
        tags:
          - lmsadmins
          - admins
          - instructors
        produces:
          - application/json
        parameters:
          - in: path
            name: classid
            description: Class ID
            required: true
            type: string
        responses:
          200:
            description: Class identified by the class (instance) ID
            schema:
              type: array
              items:
                $ref: '#/definitions/Class'
          403:
            description: |
              Class does not belong to the current user (instructor) or, if the
              current user is an admin or lmsadmin, is not allowed to manage
              classes owned by the class owner
          404:
            description: No such class exists
    */

    getClass: async id => {
      return _api.get(`/${id}`)
    },

    getHistoricalReports: async (licenseID, type) => {
      return _api.get(`/reports/archive/${type}/${licenseID}`, {
        timeout: 0,
      })
    },

    getTrainingQuestions: async () => {
      return _api.get(`/training/wizardquestions`)
    },

    /*
    /instructor/{email}:
      get:
        operationId: listClassesByInstructor
        summary: lists classes assigned to the given instructor
        description: Find all classes and their status for the given instructor
        tags:
          - lmsadmins
          - admins
        produces:
          - application/json
        parameters:
          - in: path
            name: email
            description: Email address of the instructor for which to list classes
            required: true
            type: string
        responses:
          200:
            description: list of zero or more classes for the instructor
            schema:
              type: array
              items:
                $ref: '#/definitions/Class'
          403:
            description: |
              User is not allowed to manage classes for the specified instructor
          404:
            description: Instructor email does not exist
    */
    listClassesByInstructor: async () => {
      return _api.get(`/instructor/me`)
    },
    postRegistrations: async seedId => {
      return _api.post(`/instructor/me/training`, { seedId })
    },

    setAuthToken: (access_token, force = false) => {
      if (force || !_api.defaults.headers.common['Authorization'])
        _api.defaults.headers.common['Authorization'] = `Bearer ${access_token}`
    },
    /*
    /{classid}:
      patch:
        operationId: updateClass
        summary: update single class by class (instance) ID
        description: Update one class by class (instance) ID
        tags:
          - lmsadmins
          - admins
          - instructors
        produces:
          - application/json
        parameters:
          - in: path
            name: classid
            description: Class ID
            required: true
            type: string
          - in: body
            name: metadata
            description: New class metadata
            required: true
            schema:
              type: object
              properties:
                title:
                  type: string
        responses:
          200:
            description: Class updated
          400:
            description: Bad request; missing or malformed body
          404:
            description: No such class exists
    */
    updateClass: async ({ scoId, instanceId, title }) => {
      return _api.put(`/instructor/me`, { instanceId, scoId, title })
    },
    getTrainingPaths: async () => {
      return _api.get(`/training/paths`)
    },
  }
})(baseApiAddress, true)

export default api
