import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const ChoiceWrapper = styled.div`
  .microlearning-choice:nth-of-type(1) {
    &:hover {
      & + .microlearning-choice [class*='ContentWrapper'] {
        border-top-color: rgba(0, 0, 0, 0);
        transition: border-top-color 0.2s ease;
      }
    }
  }

  .microlearning-choice:nth-of-type(2) {
    [class*='ContentWrapper'] {
      border-top: 2px dotted;
      border-bottom: 2px solid;
      border-color: ${themeGet('colors.borderColor')};
      transition: border-color 0.2s ease;
    }

    &:hover {
      [class*='ContentWrapper'] {
        border-color: rgba(0, 0, 0, 0);
        transition: border-color 0.2s ease;
      }
    }
  }
`

export const CopyWrapper = styled.div`
  padding-top: 2.0625rem;
  margin: 0 1.25rem;

  p {
    margin: 0 2rem;
    color: ${themeGet('colors.sela.gray6')};
    font-style: italic;
  }
`
