import React from 'react'
import PropTypes from 'prop-types'

import LessonSummary from '../LessonSummary'
import LessonPrep from '../LessonPrep'
import LessonTeach from '../LessonTeach'
import LessonReinforce from '../LessonReinforce'

import { StyledHeaderOne, Wrapper } from './component.styles'

const LessonDetail = ({
  classroomClimateActivities,
  description,
  objectives,
  whyThisLessonMatters,
  displayTitle,
  bbPrepVideo,
  keyWords,
  prepMaterials,
  prepMaterialsPdf,
  teacherTip,
  lessonConnections,
  lessonPreparation,
  lessonScript,
  locale,
  reinforceDay1,
  reinforceDay2,
  reinforceDay3,
  reinforceDay4,
  reinforceDay5,
  reinforceMaterialsPdfs,
  streamingLessonMedia,
  summaryTitle,
}) => {
  const hasLessonSummary = description || objectives || whyThisLessonMatters
  const hasLessonPrep =
    bbPrepVideo ||
    keyWords ||
    prepMaterials ||
    prepMaterialsPdf ||
    teacherTip ||
    lessonPreparation
  const hasLessonTeach = streamingLessonMedia || lessonScript
  const hasLessonReinforce =
    classroomClimateActivities ||
    lessonConnections ||
    reinforceDay1 ||
    reinforceDay2 ||
    reinforceDay3 ||
    reinforceDay4 ||
    reinforceDay5 ||
    reinforceMaterialsPdfs

  return (
    <Wrapper>
      <StyledHeaderOne dataTestId="lesson-detail-display-title">
        {displayTitle}
      </StyledHeaderOne>
      {hasLessonSummary && (
        <LessonSummary
          description={description}
          objectives={objectives}
          summaryTitle={summaryTitle}
          whyThisLessonMatters={whyThisLessonMatters}
        />
      )}

      {hasLessonPrep && (
        <LessonPrep
          bbPrepVideo={bbPrepVideo}
          keyWords={keyWords}
          lessonPreparation={lessonPreparation}
          locale={locale}
          prepMaterials={prepMaterials}
          prepMaterialsPdf={prepMaterialsPdf}
          teacherTip={teacherTip}
        />
      )}

      {hasLessonTeach && (
        <LessonTeach
          lessonScript={lessonScript}
          streamingLessonMedia={streamingLessonMedia}
        />
      )}

      {hasLessonReinforce && (
        <LessonReinforce
          classroomClimateActivities={classroomClimateActivities}
          lessonConnections={lessonConnections}
          reinforceDay1={reinforceDay1}
          reinforceDay2={reinforceDay2}
          reinforceDay3={reinforceDay3}
          reinforceDay4={reinforceDay4}
          reinforceDay5={reinforceDay5}
          reinforceMaterialsPdfs={reinforceMaterialsPdfs}
        />
      )}
    </Wrapper>
  )
}

LessonDetail.propTypes = {
  bbPrepVideo: PropTypes.arrayOf(PropTypes.object),
  classroomClimateActivities: PropTypes.string,
  description: PropTypes.string,
  displayTitle: PropTypes.string,
  keyWords: PropTypes.string,
  lessonConnections: PropTypes.string,
  lessonPreparation: PropTypes.string,
  lessonScript: PropTypes.string,
  locale: PropTypes.string,
  objectives: PropTypes.string,
  prepMaterials: PropTypes.string,
  prepMaterialsPdf: PropTypes.arrayOf(
    PropTypes.shape({
      internalTitle: PropTypes.string,
      displayTitle: PropTypes.string,
      file: PropTypes.shape({
        title: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  ),
  reinforceDay1: PropTypes.string,
  reinforceDay2: PropTypes.string,
  reinforceDay3: PropTypes.string,
  reinforceDay4: PropTypes.string,
  reinforceDay5: PropTypes.string,
  reinforceMaterialsPdfs: PropTypes.array,
  streamingLessonMedia: PropTypes.string,
  summaryTitle: PropTypes.string,
  teacherTip: PropTypes.string,
  whyThisLessonMatters: PropTypes.string,
}

export default LessonDetail
