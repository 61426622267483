export const DEFAULT_SURVEY_BUTTON_TEXT = 'Take Survey'
export const DEFAULT_SURVEY_COMMENTARY =
  'Tell us what you think about this activity.'
// these are the same values used by the LMS:
export const PERIOD_PROGRESS = {
  ALL: 'all',
  MOST: 'most',
  SOME: 'some',
  SKIPPED: 'skipped',
}

// these values are used in LMS instructor/me payload
export const LESSON_INTERACTION_TYPE = {
  SCHOOLWIDE_PRACTICE: 'schoolwidePractice',
  EDUCATOR_PRACTICE: 'educatorPractice',
  STUDENT_ACTIVITY: 'studentActivity',
}
