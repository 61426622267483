import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Tooltip, Hairline, HeaderTwo, TextLink } from 'secondstep-components'

import ReportsModuleBox from 'components/ReportsModuleBox'

const customBreakpoints = {
  belowTablet: '@media screen and (max-width: 700px)',
}

export const Wrapper = styled(ReportsModuleBox)`
  z-index: 2;

  ${HeaderTwo} {
    margin-bottom: ${themeGet('spacing.green4_0_25rem')};
  }
`

export const LicenseUserData = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 870px) {
    flex-direction: column;
  }
`

export const RightContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;

  ${TextLink} {
    padding-top: 0.0625rem;
    padding-left: 1rem;
  }

  @media (max-width: 870px) {
    justify-content: space-between;

    ${TextLink} {
      padding-left: 0;
    }
  }

  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;
  }
`

export const LeftContent = styled.div`
  display: flex;
  align-items: center;
`

export const Separator = styled(Hairline)`
  height: 2px;
  margin: 0.75rem 0 0.75rem;
  background-color: ${themeGet('colors.grayBlue')};
`

export const DropdownsWrapper = styled.div`
  display: flex;
  align-items: center;

  ${customBreakpoints.belowTablet} {
    flex-direction: column;
  }
`

export const LeftDropdown = styled.div`
  position: relative;
  width: 50%;
  margin-right: 1rem;

  label {
    margin-bottom: 0.375rem;
  }

  &:last-of-type {
    margin-right: 0;
  }

  ${customBreakpoints.belowTablet} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 0.8125rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`

export const RightDropdown = styled(LeftDropdown)``

export const UserTypesTooltip = styled(Tooltip)`
  width: 14px;
  margin: 0 0.5rem;

  div {
    bottom: 2rem;
    padding: 0.3rem 0.2rem 0.3rem 0.3rem;
    border-radius: 0.3125rem;

    ::after {
      content: ' ';
      width: 0.75rem;
      height: 0.75rem;
      position: absolute;
      left: 50%;
      background-color: white;
      transform: translate(-50%) rotate(45deg);
    }
  }

  [class*='BodyCopy'] {
    color: ${themeGet('colors.darkGray')};
    margin-bottom: 0;
    p {
      font-size: 0.75rem;
      line-height: 1rem;
      margin-block-end: 0;
    }
  }

  i.fas {
    color: ${themeGet('colors.blueGray')};
    font-size: 0.875rem;
  }

  @media (max-width: 870px) {
    div {
      left: 160%;

      :after {
        left: 43%;
      }
    }
  }
`

export const UserTypesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;

  [class*='tooltip-content'] {
    padding: 0.75rem 0.5rem 0.625rem 0.75rem;
    text-align: start;
    transform: translate(-8.125rem, 0.25rem);

    @media (max-width: 870px) {
      transform: translate(-8rem, 0.25rem);
    }
  }

  [class*='BodyCopy'] {
    padding: 0;
  }
`
