import React from 'react'
import PropTypes from 'prop-types'

import { PipeIcon } from 'secondstep-components'

import ToggleOption from '../RadioToggle/ToggleOption'
import { StyledRadioToggle } from './component.styles'

const TextToggle = ({ description, options = [], onSelect, value = '' }) => {
  return (
    <StyledRadioToggle
      description={description}
      onSelect={onSelect}
      options={options}
      value={value}
    >
      {Array.isArray(options) &&
        options.map((option, index) => {
          const { id } = option
          const shouldShowPipeIcon = index > 0

          return (
            <ToggleOption key={id} option={option}>
              {shouldShowPipeIcon && <PipeIcon />}
            </ToggleOption>
          )
        })}
    </StyledRadioToggle>
  )
}

TextToggle.propTypes = {
  description: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.string.isRequired,
}

export default TextToggle
