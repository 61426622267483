import styled from 'styled-components'
import { themeGet } from 'styled-system'
import DropdownMenu from 'components/DropdownMenu'
import { TextButton as AllCapsButton } from 'secondstep-components'

export const TextButton = styled(AllCapsButton)`
  text-transform: none;
  opacity: 1 !important;
`

export const StyledDropdownMenu = styled(DropdownMenu).attrs(
  ({ isClassCardMenu }) => ({
    fontFamily: themeGet('font.molde.medium'),
    marginLeft: isClassCardMenu ? '0' : '0.5rem',
    position: isClassCardMenu ? 'absolute' : 'relative',
    top: isClassCardMenu ? '8px' : 'auto',
    left: isClassCardMenu ? '90.5%' : 'auto',
    mobileTop: isClassCardMenu ? '8px' : '0.75rem',
    mobileRight: isClassCardMenu ? 'unset' : '0.75rem',
  }),
)`
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  margin-left: ${({ marginLeft }) => marginLeft};
  font-family: ${({ fontFamily }) => fontFamily};

  .dropdown-menu-button {
    margin-left: 0;
  }

  .dropdown-menu-wrapper {
    .edit-button {
      margin-left: 0;
    }

    .delete-button {
      margin-left: 0;
    }

    @media screen and (min-width: 591px) {
      right: unset;
    }
  }

  @media screen and (max-width: 590px) {
    position: absolute;
    top: ${({ mobileTop }) => mobileTop};
    right: ${({ mobileRight }) => mobileRight};
  }
`
