import { connect } from 'react-redux'
import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { ROLES } from 'config/constants'
import { VIEW_OPTION_PATHS } from 'utils/adminExperienceHelpers'
import { navigationEvent } from 'layers/navigation/store/operations'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import withApiError from 'layers/errorHandling/apiError/component'
import {
  ERROR_TYPE_CONTENTFUL,
  SHOW_CONTENT_ON_401,
} from 'layers/errorHandling/apiError'
import { operations as userSessionOperations } from 'store/userSessionManager'
import { TOGGLE_VIEW } from 'store/userSessionManager/constants'
import { operations as loadingOperations } from 'store/loadingManager'
import {
  operations as licenseOperations,
  selectors as licenseSelectors,
} from 'store/licenseManager'
import { operations as reportsOperations } from 'store/reportsManager'

import AdminPage from './component'
import { LOADING_MESSAGE } from './constants'

export const AdminDashboardContainer = ({
  activeAdminK8Sites,
  activeAdminSites,
  adminHSSites,
  claims,
  deleteReportsData,
  displayTitle,
  fetchUserSites,
  fetchUserLicenses,
  hasAccessToSelaProgram,
  isFetching: isFetchingContentful,
  isFetchingUserContext,
  isFetchingUserSites,
  isFetchingUserLicenses,
  k8ToggleView,
  location,
  match,
  navigationEvent,
  onboardingSnapshot,
  programs,
  programTitles,
  selectedProgram,
  setAppIsLoadingState,
  setAppNotLoadingState,
  roles = [],
  setLeaderDashboardRecentUrl,
  setK8ToggleView,
  unassignedLicenses,
  updateProgramsForUser,
  updateProgramTitles,
  updateSelectedProgram,
}) => {
  const isFirstTimeSetupAdmin =
    roles.length === 1 && roles?.includes(ROLES.SETUP_ADMIN)
  const [program, setProgram] = useState()
  const { params } = match
  const { programName: programPath, viewOption } = params || {}

  const addProgramRouteToAdminDashboard = programTitlesParsed => {
    if (isFetchingUserSites) return null
    if (programTitlesParsed.length > 0 && match.path === '/admin') {
      const defaultProgram = programTitlesParsed[0]?.toLowerCase()
      const path =
        defaultProgram === 'k8'
          ? `${defaultProgram}/${viewOption}`
          : defaultProgram
      navigationEvent(`admin/${path}${location.search}`, 'REPLACE')
    } else {
      const programSlug = programPath.toLowerCase()

      if (programSlug === 'k8') {
        navigationEvent(`${viewOption}${location.search}`, 'REPLACE')
      } else if (programSlug === 'hs') {
        navigationEvent(
          `${VIEW_OPTION_PATHS.PROGRAM_ADOPTION}${location.search}`,
          'REPLACE',
        )
      } else if (programSlug !== 'k8' && !viewOption) {
        navigationEvent(`${programSlug}${location.search}`, 'REPLACE')
      }

      const programName = programPath.toUpperCase()
      setProgram(programName)
      updateSelectedProgram({
        key: programName,
        name: programName,
      })
    }
  }

  useEffect(() => {
    const cleanup = async () => {
      await deleteReportsData()
    }
    return cleanup
  }, [])

  useEffect(() => {
    if (!activeAdminSites && !isFirstTimeSetupAdmin) {
      fetchUserSites()
    } else if (
      !selectedProgram &&
      !isFirstTimeSetupAdmin &&
      activeAdminSites?.length > 0
    ) {
      const aggregatePrograms = {
        BPU: new Set(),
        CPU: new Set(),
        HS: new Set(),
        K8: new Set(),
        SELA: new Set(),
      }

      for (let i = 0; i < activeAdminSites.length; i++) {
        const site = activeAdminSites[i]
        const siteProgramCodesSet = site.licenses?.reduce(
          (typesAcc, license) => {
            license?.product?.programCodes.forEach(code => typesAcc.add(code))
            return typesAcc
          },
          new Set(),
        )
        const siteProgramCodes = [...siteProgramCodesSet]
        for (let j = 0; j < siteProgramCodes.length; j++) {
          const code = siteProgramCodes[j]

          switch (code) {
            case 'K5P':
            case 'K8P':
            case 'MSP':
              if (claims?.hasElemAdminClaim || claims?.hasMsAdminClaim) {
                aggregatePrograms.K8.add(site)
              }
              break
            case 'HSP':
              if (claims?.hasHighschoolAdminClaim) {
                aggregatePrograms.HS.add(site)
              }
              break
            case 'BPU':
              if (claims?.hasBpuAdminClaim) {
                aggregatePrograms.BPU.add(site)
              }
              break
            case 'CPU':
              if (claims?.hasCpuAdminClaim) {
                aggregatePrograms.CPU.add(site)
              }
              break
            case 'ADL':
              if (hasAccessToSelaProgram) {
                aggregatePrograms.SELA.add(site)
              }
              break
          }
        }
      }

      const programTitlesParsed = Object.keys(aggregatePrograms)
      for (let i = programTitlesParsed.length; i > 0; i--) {
        const program = [...aggregatePrograms[programTitlesParsed[i - 1]]]
        if (program.length === 0) {
          programTitlesParsed.splice(i - 1, 1)
        }
      }

      for (const program in aggregatePrograms) {
        aggregatePrograms[program] = [...aggregatePrograms[program]]
      }
      updateProgramTitles(programTitlesParsed)
      updateProgramsForUser(aggregatePrograms)
      addProgramRouteToAdminDashboard(programTitlesParsed)
      setK8ToggleView(
        viewOption === VIEW_OPTION_PATHS.PROGRAM_ADOPTION
          ? TOGGLE_VIEW.LAUNCH
          : TOGGLE_VIEW.MONITOR,
      )
    }

    if (isFetchingContentful || isFetchingUserContext || isFetchingUserSites) {
      setAppIsLoadingState(LOADING_MESSAGE)
    } else {
      setAppNotLoadingState()
    }
  }, [
    activeAdminSites,
    displayTitle,
    isFetchingUserContext,
    isFetchingUserSites,
    isFetchingContentful,
    match,
    selectedProgram,
  ])

  useEffect(() => {
    if (
      isFirstTimeSetupAdmin &&
      !isFetchingUserLicenses &&
      !unassignedLicenses
    ) {
      fetchUserLicenses()
    }
  }, [unassignedLicenses, isFetchingUserLicenses])

  const multiSiteAdmin = activeAdminK8Sites?.length > 1
  const showFirstTimeSetupAdmin =
    isFirstTimeSetupAdmin &&
    !activeAdminK8Sites?.length &&
    unassignedLicenses?.length > 0

  return (
    <AdminPage
      activeAdminK8Sites={activeAdminK8Sites}
      adminHSSites={adminHSSites}
      displayTitle={displayTitle}
      k8ToggleView={k8ToggleView}
      match={match}
      multiSiteAdmin={multiSiteAdmin}
      navigationEvent={navigationEvent}
      onboardingSnapshot={onboardingSnapshot}
      programTabName={program}
      programTitles={programTitles}
      programs={programs}
      selectedProgram={selectedProgram}
      setLeaderDashboardRecentUrl={setLeaderDashboardRecentUrl}
      showFirstTimeSetupAdmin={showFirstTimeSetupAdmin}
      updateSelectedProgram={updateSelectedProgram}
    />
  )
}

const mapStateToProps = state => {
  const {
    licenseManager,
    reportsManager,
    userAccessManager,
    userContextManager,
    userProfileManager: {
      profile: { roles },
    },
    userSessionManager: {
      adminDashboard: {
        k8: { toggleView: k8ToggleView },
      },
    },
  } = state
  const { isFetching: isFetchingUserContext } = userContextManager || {}
  const { isFetchingUserSites, isFetchingUserLicenses } = licenseManager || {}
  const { selectedProgram, programTitles, programs } = reportsManager || {}
  const { claims } = userAccessManager || {}
  const activeAdminSites = licenseSelectors.selectActiveSites(state)
  // TODO: refactor - below selectors are iterating activeAdminSites. Do it here in one iteration.
  const activeAdminK8Sites = licenseSelectors.selectActiveAdminK8Sites(state)
  const adminHSSites = licenseSelectors.selectActiveAdminHsSites(state)

  return {
    activeAdminK8Sites,
    activeAdminSites,
    adminHSSites,
    claims,
    hasAccessToSelaProgram: claims?.hasSelaAdminClaim,
    isFetchingUserContext,
    isFetchingUserSites,
    isFetchingUserLicenses,
    k8ToggleView,
    programs,
    programTitles,
    roles,
    selectedProgram,
    unassignedLicenses: licenseManager?.unassignedLicenses,
  }
}

const mapDispatchToProps = {
  deleteReportsData: reportsOperations.deleteReportsData,
  fetchUserSites: licenseOperations.fetchUserSites,
  fetchActiveProgramsForUser: reportsOperations.fetchActiveProgramsForUser,
  navigationEvent,
  setLeaderDashboardRecentUrl:
    userSessionOperations.setLeaderDashboardRecentUrl,
  setAppIsLoadingState: loadingOperations.setIsLoadingState,
  setAppNotLoadingState: loadingOperations.setNotLoadingState,
  setK8ToggleView: userSessionOperations.adminSetK8ToggleView,
  fetchUserLicenses: licenseOperations.fetchUserLicenses,
  updateProgramTitles: reportsOperations.updateProgramTitles,
  updateProgramsForUser: reportsOperations.updateProgramsForUser,
  updateSelectedProgram: reportsOperations.updateSelectedProgram,
}

const mapper = entry => {
  const { displayTitle, onboardingSnapshot } = entry || {}
  return {
    displayTitle,
    onboardingSnapshot,
  }
}

const options = {
  entryId: '5oqdbnI289AADFVmm2sJyx',
  include: 5,
  mapper,
  spread: true,
}

AdminDashboardContainer.propTypes = {
  activeAdminK8Sites: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  activeAdminSites: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  adminHSSites: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  claims: PropTypes.object,
  deleteReportsData: PropTypes.func,
  displayTitle: PropTypes.string,
  fetchUserLicenses: PropTypes.func,
  fetchUserSites: PropTypes.func,
  hasAccessToSelaProgram: PropTypes.bool,
  isFetching: PropTypes.bool,
  isFetchingUserContext: PropTypes.bool,
  isFetchingUserLicenses: PropTypes.bool,
  isFetchingUserSites: PropTypes.bool,
  k8ToggleView: PropTypes.string,
  location: PropTypes.object.isRequired,
  match: PropTypes.object,
  navigationEvent: PropTypes.func,
  onboardingSnapshot: PropTypes.array,
  programTitles: PropTypes.array,
  programs: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  roles: PropTypes.array,
  selectedProgram: PropTypes.object,
  setAppIsLoadingState: PropTypes.func,
  setAppNotLoadingState: PropTypes.func,
  setK8ToggleView: PropTypes.func,
  setLeaderDashboardRecentUrl: PropTypes.func,
  unassignedLicenses: PropTypes.array,
  updateProgramTitles: PropTypes.func,
  updateProgramsForUser: PropTypes.func,
  updateSelectedProgram: PropTypes.func,
}

export default ContentEntryDataGetter(
  withApiError(
    AdminDashboardContainer,
    [ERROR_TYPE_CONTENTFUL],
    [SHOW_CONTENT_ON_401],
  ),
  options,
  connect(mapStateToProps, mapDispatchToProps),
)
