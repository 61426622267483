import { View, Text, Image } from 'react-native'
import styled from 'styled-components'
import styledNative from 'styled-components/native'
import { themeGet } from 'styled-system'
import { RightArrowIcon } from 'secondstep-components'

export const Container = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  align-content: center;
  background-color: ${themeGet('colors.white')};
  border-radius: 5px;
  box-shadow: 0 2px 4px ${themeGet('colors.lightShadeGray')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 190px;
  padding-top: 0.75rem;
  padding-bottom: 10px;
  position: absolute;
  right: 0;
  top: 100%;
  z-index: 1;
`

export const Divider = styledNative(View)(props => ({
  width: 160,
  alignSelf: 'center',
  borderBottomWidth: 1.5,
  borderBottomColor: themeGet('colors.gray500')(props),
  marginBottom: '0.625rem',
  marginTop: '0.625rem',
}))

export const Carrot = styledNative(Image)(() => {
  return {
    height: 8,
    position: 'absolute',
    right: 13,
    top: -5,
    width: 14,
  }
})

export const IconWrapper = styled.div`
  margin-top: -0.1rem
  margin-right: 0.3rem;
  padding-top: ${props => props.iconTopOffset};
  width: auto;
  height: 1rem;
`

export const MenuItemContainer = styledNative(View)`
  cursor: ${props => (props.hover ? 'pointer' : 'auto')};
  padding: ${props => props.extraPadding} 0;
  flex-direction: column;
  height: 1.875rem;
  justify-content: center;
  width: 100%;
`

export const MenuItem = styledNative(Text)(props => {
  const { hover } = props
  if (hover) {
    return {
      alignContent: 'center',
      color: themeGet('colors.secondStepBlue')(props),
      cursor: 'pointer',
      display: 'flex',
      fontFamily: themeGet('font.molde.medium')(props),
      fontSize: 14,
      marginLeft: '0.938rem',
      paddingTop: 2,
      fontWeight: themeGet('fontWeightNormal')(props),
    }
  } else {
    return {
      alignContent: 'center',
      color: themeGet('colors.links.primary')(props),
      display: 'flex',
      fontFamily: themeGet('font.molde.medium')(props),
      fontSize: 14,
      marginLeft: '0.938rem',
      paddingTop: 2,
      fontWeight: themeGet('fontWeightNormal')(props),
      marginBottom: '0.625rem',
      marginTop: '0.625rem',
    }
  }
})

export const StyledRightArrowIcon = styled(RightArrowIcon)`
  display: inline;
  margin-left: 0.5rem;
  margin-top: 0.25rem;
`

export const UserName = styled.div`
  font-family: ${themeGet('font.sharpSlab.semibold')};
  font-size: 1rem;
  color: ${themeGet('colors.darkBlue')};
`

export const UserIconWrapper = styledNative(View)(() => {
  return {
    height: 50,
    width: 50,
    marginRight: '0.75rem',
    alignItems: 'center',
  }
})
