import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { operations as lmsOperations } from 'store/lmsManager'
import WizardFlow from './component'
export class WizardContainer extends Component {
  static propTypes = {
    createTrainingRegistration: PropTypes.func,
    displayTitle: PropTypes.string,
    fetchTrainingQuestions: PropTypes.func,
    isFetching: PropTypes.bool,
    isUpdating: PropTypes.bool,
    productName: PropTypes.string,
    trainingQuestions: PropTypes.object,
  }
  componentDidMount() {
    const { fetchTrainingQuestions } = this.props
    fetchTrainingQuestions()
  }
  render() {
    const {
      createTrainingRegistration,
      displayTitle,
      isFetching,
      isUpdating,
      productName,
      trainingQuestions,
    } = this.props
    if (!trainingQuestions) return null
    return (
      <WizardFlow
        createTrainingRegistration={createTrainingRegistration}
        displayTitle={displayTitle}
        isFetching={isFetching}
        isUpdating={isUpdating}
        productName={productName}
        trainingQuestions={trainingQuestions}
      />
    )
  }
}
const mapStateToProps = ({ lmsManager }) => ({
  trainingQuestions: lmsManager.trainingQuestions,
  isFetching: lmsManager.isFetching,
  isUpdating: lmsManager.isUpdating,
})

const mapDispatchToProps = {
  createTrainingRegistration: lmsOperations.createTrainingRegistration,
  fetchTrainingQuestions: lmsOperations.fetchTrainingQuestions,
}

export default connect(mapStateToProps, mapDispatchToProps)(WizardContainer)
