import { connect } from 'react-redux'

import NavigationHelper from 'layers/navigation/navigationHelper'
import ContentLastNodeGetter from 'layers/content/Hocs/ContentLastNodeGetter'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_CONTENTFUL_PRODUCT,
} from 'layers/errorHandling/apiError'
import { SPANISH_LOCALE } from 'layers/content/data'
import { operations as loadingOperations } from 'store/loadingManager'
import { userSegmentationBuilder } from 'utils/userSegmentationHelpers'

import Component from './component'

const options = {
  include: 3,
  prefetch: SPANISH_LOCALE,
}

const mapStateToProps = state => {
  return {
    isLoadingApp: state?.loadingManager?.isLoading,
    userSegmentationInfo: userSegmentationBuilder(state),
    isFetchingOrCreatingProgramFlex:
      state?.lmsManager?.isFetchingOrCreatingProgramFlex || false,
  }
}

const mapDispatchToProps = {
  setAppIsLoadingState: loadingOperations.setIsLoadingState,
  setAppNotLoadingState: loadingOperations.setNotLoadingState,
}

const navigationConnect = connect(
  NavigationHelper.mapStateToProps,
  NavigationHelper.mapDispatchToProps,
)
const loadingConnect = connect(mapStateToProps, mapDispatchToProps)

export default ContentLastNodeGetter(
  withApiError(Component, [
    ERROR_TYPE_CONTENTFUL,
    ERROR_TYPE_CONTENTFUL_PRODUCT,
  ]),
  options,
  navigationConnect,
  loadingConnect,
)
