import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, TooltipContentWrapper } from 'secondstep-components'
import AdminTooltip from 'components/AdminTooltip'

export const Description = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('font.molde.medium')};
  font-size: 1.125rem;
`

export const DescriptionWrapper = styled.div`
  display: flex;
`

export const StyledAdminTooltip = styled(AdminTooltip)`
  ${TooltipContentWrapper} {
    top: -128px;

    ::after {
      bottom: 43px;
      left: 75px;
    }
  }
`

export const StyledHr = styled.hr`
  width: 100%;
  border: 1px solid ${themeGet('colors.paleGray')};
  margin: 0.9375rem 0;
`

export const Total = styled.span`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('font.sharpSlab.medium')};
  font-size: 2.5rem;
  line-height: 2.6875rem;
  margin-bottom: 0.125rem;
`

export const Updated = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  height: 21px;
  color: ${themeGet('colors.dashboard.gray1')};
  font-family: ${themeGet('font.molde.regular')};
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 0.8125rem;
`

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 1.3125rem 1.25rem 1.375rem;
  border: 2px solid ${themeGet('colors.paleGray')};
  border-radius: 10px;
  background: ${themeGet('colors.white')};
`
