import React, { Suspense } from 'react'

import PropTypes from 'prop-types'
import { Logger } from 'logging'
import { VideoV3, TranscriptComponent } from 'secondstepVideo'
import LearnErrorBoundary from 'components/LearnErrorBoundary'
import AccessibilityMenu from './accessibilityMenu'
import { getPlayButtonSVG } from './helper'
import {
  Wrapper,
  AccessibilityWrapper,
  TranscriptOutterWrapper,
} from './component.styles'

const VideoDataSetterComponent = props => {
  const {
    audioDescriptionTranscript,
    dataTestId,
    handleClickAudioDesc,
    hasLoadedTranscripts,
    isAudioDescActive,
    videoUrl,
    showAudioDescriptionButton,
    tracks,
    poster,
    transcriptRendered,
    transcript,
    isTranscriptActive,
    refetchLocale,
    showTranscript,
    settings,
    variant,
    videoUrlExtended,
    theme,
  } = props || {}

  const handleSourceSet = () => {
    document.querySelector('.vjs-screen-reader-text').remove()
  }
  const isEnglish = refetchLocale === 'en-US'
  const appInsightInstance = window.appInsight
  const playButtonSVG = getPlayButtonSVG(theme)
  const { default: defaultPlayButton, hover: hoverPlayButton } = playButtonSVG

  const video = (
    <Suspense fallback={<div>Loading...</div>}>
      <VideoV3
        appInsightInstance={appInsightInstance}
        dataTestId={dataTestId}
        logger={Logger}
        onSourceSet={handleSourceSet}
        poster={poster}
        settings={settings}
        styles={{
          bigPlayButton: {
            default: defaultPlayButton,
            hover: hoverPlayButton,
          },
        }}
        tracks={tracks}
        videoUrl={videoUrl}
      />
    </Suspense>
  )
  const showAccessibilityMenu = !!(transcript || videoUrlExtended)

  return (
    <AccessibilityWrapper
      data-testid="accessibilityWrapper"
      transcriptRendered={transcriptRendered}
    >
      <Wrapper transcriptRendered={transcriptRendered}>
        {video}
        {transcriptRendered && (
          <TranscriptOutterWrapper transcriptRendered={transcriptRendered}>
            <LearnErrorBoundary message="Problem loading video transcript">
              <TranscriptComponent
                audioDescriptionTranscript={audioDescriptionTranscript}
                isAudioDescActive={isAudioDescActive}
                transcript={transcript}
              />
            </LearnErrorBoundary>
          </TranscriptOutterWrapper>
        )}
      </Wrapper>
      {isEnglish && showAccessibilityMenu && (
        <AccessibilityMenu
          handleClickAudioDesc={handleClickAudioDesc}
          hasLoadedTranscripts={hasLoadedTranscripts}
          isAudioDescActive={isAudioDescActive}
          isTranscriptActive={isTranscriptActive}
          showAudioDescriptionButton={showAudioDescriptionButton}
          showTranscript={showTranscript}
          transcriptRendered={transcriptRendered}
          variant={variant}
        />
      )}
    </AccessibilityWrapper>
  )
}

VideoDataSetterComponent.defaultProps = {}

VideoDataSetterComponent.propTypes = {
  audioDescriptionTranscript: PropTypes.string,
  handleClickAudioDesc: PropTypes.func,
  hasLoadedTranscripts: PropTypes.bool,
  isAudioDescActive: PropTypes.bool,
  isTranscriptActive: PropTypes.bool,
  poster: PropTypes.string,
  refetchLocale: PropTypes.string,
  settings: PropTypes.object,
  showAudioDescriptionButton: PropTypes.bool,
  showTranscript: PropTypes.bool,
  tracks: PropTypes.array,
  transcript: PropTypes.string,
  transcriptRendered: PropTypes.bool,
  variant: PropTypes.string,
  videoRef: PropTypes.object,
  videoUrl: PropTypes.string,
}

export default VideoDataSetterComponent
