import React from 'react'
import PropTypes from 'prop-types'
import MsBHUMultiSiteList from './MsBHUMultiSiteList'

import {
  ButtonPanel,
  CenteredSubTitle,
  CenteredTitle,
  StyledButton,
  StyledModal,
  StyledOutlinedButton,
} from './component.styles'

import { TextLink } from 'secondstep-components'

import { FAQ_LINK, FAQ_LINK_TEXT, TEXT_TITLE, TEXT_SUBTITLE } from './constants'

const MsBHUMultiSiteModal = ({
  allSelected,
  changeBHUStatus,
  indeterminate,
  isSaveButtonEnabled,
  onCheckBoxClick,
  onModalCancel,
  onModalSave,
  onUpdate,
  selectAllSites,
  selectedSiteIds,
  setListedSites,
  sites,
  sitesHavingBHUnitCount,
}) => (
  <StyledModal
    dataTestId="MSBHUMiltiSite-modal"
    isModalOpen={true}
    modalClose={() => onModalCancel}
  >
    <CenteredTitle>{TEXT_TITLE}</CenteredTitle>
    <CenteredSubTitle>
      <span>{TEXT_SUBTITLE}</span>{' '}
      <TextLink href={FAQ_LINK} target="_blank">
        {FAQ_LINK_TEXT}
      </TextLink>
    </CenteredSubTitle>
    <MsBHUMultiSiteList
      allSelected={allSelected}
      changeBHUStatus={changeBHUStatus}
      indeterminate={indeterminate}
      onCheckBoxClick={onCheckBoxClick}
      onUpdate={onUpdate}
      selectAllSites={selectAllSites}
      selectedSiteIds={selectedSiteIds}
      setListedSites={setListedSites}
      sites={sites}
      sitesHavingBHUnitCount={sitesHavingBHUnitCount}
    />
    <ButtonPanel>
      <StyledOutlinedButton onClick={onModalCancel}>
        Cancel
      </StyledOutlinedButton>
      <StyledButton disabled={!isSaveButtonEnabled} onClick={onModalSave}>
        Save
      </StyledButton>
    </ButtonPanel>
  </StyledModal>
)

MsBHUMultiSiteModal.propTypes = {
  allSelected: PropTypes.bool,
  changeBHUStatus: PropTypes.func.isRequired,
  indeterminate: PropTypes.bool,
  isSaveButtonEnabled: PropTypes.bool,
  onCheckBoxClick: PropTypes.func.isRequired,
  onModalCancel: PropTypes.func,
  onModalSave: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  selectAllSites: PropTypes.func.isRequired,
  selectedSiteIds: PropTypes.arrayOf(PropTypes.number),
  setListedSites: PropTypes.func.isRequired,
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      siteId: PropTypes.number,
      name: PropTypes.string,
      sitePreferences: PropTypes.shape({
        mspDigital: PropTypes.shape({
          bullyingHarassmentUnitEnabled: PropTypes.bool,
        }),
      }),
    }),
  ).isRequired,
  sitesHavingBHUnitCount: PropTypes.number,
}

export default MsBHUMultiSiteModal
