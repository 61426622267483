import React from 'react'
import { bool, func } from 'prop-types'

import {
  Column,
  Row,
  IconPeople,
  IconRightArrow,
  Link,
} from './component.styles'
import { USER_MANAGEMENT_PATH, TEXT_MANAGE_USERS } from './constants'

const ManageUsersLink = ({ navigateOutOfAppOnClick, showIcon }) => (
  <Column key="admin-links">
    <Row>
      <Link
        alt={TEXT_MANAGE_USERS}
        dataTestId="text-link-manage-users"
        key="text-link-manage-users"
        onClick={() => navigateOutOfAppOnClick(USER_MANAGEMENT_PATH)}
      >
        <span>
          {showIcon && <IconPeople />}
          {TEXT_MANAGE_USERS}
        </span>
        {showIcon && <IconRightArrow />}
      </Link>
    </Row>
  </Column>
)

export const UserManagementTextLink = ({ navigateOutOfAppOnClick }) => (
  <Link
    alt={TEXT_MANAGE_USERS}
    dataTestId="text-link-users-manage"
    key="text-link-users-manage"
    onClick={() => navigateOutOfAppOnClick(USER_MANAGEMENT_PATH)}
  >
    {TEXT_MANAGE_USERS}
  </Link>
)

ManageUsersLink.defaultProps = {
  showIcon: true,
}

UserManagementTextLink.propTypes = {
  navigateOutOfAppOnClick: func.isRequired,
}

ManageUsersLink.propTypes = {
  navigateOutOfAppOnClick: func.isRequired,
  showIcon: bool,
}

export default ManageUsersLink
