import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { SortButton } from 'secondstep-components'

export const AdminSortButtonWrapper = styled.div.attrs(
  ({ isActive, monitorView }) => ({
    paddingBottom: isActive ? '0.1875rem' : '0',
    flex: monitorView ? 'none' : '1',
  }),
)`
  display: flex;
  flex: ${({ flex }) => flex};
  align-items: center;
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
`

export const StyledSortButton = styled(SortButton).attrs(({ isActive }) => ({
  caretTop: isActive ? '52%' : '44%',
}))`
  flex: none;
  gap: 0;
  text-wrap: nowrap;
  color: ${themeGet('colors.darkGray')};

  [class*='StyledButton'] {
    padding-left: 1.25rem;
    text-align: left;
    white-space: pre-line;
    position: relative;
  }

  [class*='StyledCaret'] {
    position: absolute;
    left: 4px;
    top: ${({ caretTop }) => caretTop};
    translateY(-50%);
  }
`
