export const getDomain = fullDomain => {
  const CFCTEST = 'cfctest.org'
  const SECONDSTEP = 'secondstep.org'
  const TRANSITORY = 'tftransitory.org'
  const LOCAL = 'localhost'

  if (fullDomain.includes(CFCTEST)) {
    return CFCTEST
  } else if (fullDomain.includes(SECONDSTEP)) {
    return SECONDSTEP
  } else if (fullDomain.includes(TRANSITORY)) {
    return TRANSITORY
  } else if (fullDomain.includes(LOCAL)) {
    return LOCAL
  } else {
    console.error('Where are you?')
    return ''
  }
}

// from https://www.w3schools.com/js/js_cookies.asp
export const setCookie = (cname, cvalue, exdays) => {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = `expires=${d.toUTCString()}`
  document.cookie = `${cname}=${cvalue};${expires};path=/`
}

// from https://www.w3schools.com/js/js_cookies.asp
export const getCookie = cname => {
  let name = cname + '='
  let decodedCookie = decodeURIComponent(document.cookie)
  let ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
  return ''
}
