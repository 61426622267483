import React from 'react'
import PropTypes from 'prop-types'
import {
  BodyWrapper,
  CardContainer,
  Card,
  CardTitle,
  StyledMarkdownParagraph,
  StyledMarkdownLink,
  CardImage,
  CardHeader,
} from './component.styles'

const TakeAction = ({ cards, moreInformation }) => {
  return (
    <BodyWrapper>
      <StyledMarkdownLink>{moreInformation}</StyledMarkdownLink>
      <CardContainer>
        {cards.map((c, i) => {
          return (
            <Card key={i}>
              <CardHeader>
                <CardTitle>
                  <CardImage alt={c.icon.title} src={c.icon.file.url} />
                  {c.heading1}
                </CardTitle>
              </CardHeader>
              <StyledMarkdownParagraph>{c.text}</StyledMarkdownParagraph>
            </Card>
          )
        })}
      </CardContainer>
    </BodyWrapper>
  )
}

TakeAction.propTypes = {
  cards: PropTypes.array.isRequired,
  moreInformation: PropTypes.string,
}

export default TakeAction
