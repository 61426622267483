import React from 'react'
import PropTypes from 'prop-types'
import FormField from '../FormField'
import { NumberInputContainer } from './component.styles'
import FormInputNumberInput from '../FormInputNumberInput'

const FormInputNumber = ({
  autofocus,
  dataTestId,
  disabled,
  inputErrors,
  label,
  name,
  onChange,
  placeholder,
  value,
  className,
}) => {
  return (
    <div className={className}>
      <FormField
        dataTestId={dataTestId}
        inputErrors={inputErrors}
        label={label}
      >
        <NumberInputContainer>
          <FormInputNumberInput
            autoFocus={autofocus}
            dataTestId={`input-${dataTestId}`}
            disabled={disabled}
            hasError={!!inputErrors}
            id={dataTestId}
            name={name}
            onChange={onChange}
            placeholder={placeholder}
            value={value}
          />
        </NumberInputContainer>
      </FormField>
    </div>
  )
}

FormInputNumber.propTypes = {
  autofocus: PropTypes.bool,
  className: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  inputErrors: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.number,
}

export default FormInputNumber
