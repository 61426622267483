import 'array-from-polyfill'
import ObjectAssign from 'es6-object-assign'
import 'promise-polyfill/src/polyfill'
import { Normalize } from 'styled-normalize'
import 'regenerator-runtime/runtime'
import 'resize-observer-polyfill/dist/ResizeObserver.global'
import React from 'react'
import { ConnectedRouter } from 'connected-react-router'
import { View } from 'react-native'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { FlagsProvider, FlagsSingleton } from 'flags'
import { GoogleAnalytics, Logger } from 'logging'
import store, { history, persistor } from 'store'
import Auth0ProviderWrapper from 'auth/providers/Auth0ProviderWrapper'
import PreviewTokenManager from 'utils/previewTokenManager'
import { LD_CREDS, auth0Settings } from '../src/config/env'
import flagsAppLevelSubscribes from 'config/flagsAppLevelSubscribes'
import flagsLocalDevFlagOverride from 'config/flagsLocalDevFlagOverride'
import LearnErrorBoundary from 'components/LearnErrorBoundary'
import LoggerContext from 'components/LoggerContext'

// ================================================================================
// NOTE: DO NOT CHANGE THE ORDER OF THIS VIDEO IMPORT HERE AS IT WILL BREAK STYLING
import { initializeAzureMediaPlayer } from 'secondstepVideo'
// ================================================================================

import App from './App'
import AppInitialize from './AppInitialize'
import 'dayjs/locale/es-us'

ObjectAssign.polyfill()

const PreviewTokenMngr = new PreviewTokenManager()

Logger.init(history)
initializeAzureMediaPlayer()

const render = async () => {
  const flagsConfig = {
    user: LD_CREDS.user,
    options: {},
    clientID: LD_CREDS.clientSideID,
  }
  FlagsSingleton.initialize({
    clientOptions: flagsConfig,
    appLevelSubscribedFlags: flagsAppLevelSubscribes,
    localDevFlagOverride: flagsLocalDevFlagOverride,
  })

  await FlagsSingleton.waitForFlagSystemReady()
  PreviewTokenMngr.setToken()

  // TODO: https://secondstep.atlassian.net/browse/LEARN-16007 - This whole class should be refactored to pull apart the Send event from the Init event
  GoogleAnalytics.init({
    globalPrefix: 'ss-learn',
    pageTitle:
      window.parent?.__GOOGLE_ANALYTICS_FILTER_PROPERTIES__?.title ||
      location.pathname,
    trackingId: process.env.GA_TRACKING_ID,
  })

  history.listen(location => {
    GoogleAnalytics.init({
      globalPrefix: 'ss-learn',
      pageTitle:
        window.parent?.__GOOGLE_ANALYTICS_FILTER_PROPERTIES__?.title ||
        location.pathname,
      trackingId: process.env.GA_TRACKING_ID,
    })
  })

  const loggerContextValues = {
    logger: Logger,
  }

  ReactDOM.render(
    <View
      style={{
        minHeight: '100vh',
        position: 'relative',
        overflowY: 'auto',
      }}
    >
      <FlagsProvider softReload={true}>
        <LoggerContext.Provider value={loggerContextValues}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <ConnectedRouter history={history}>
                <Auth0ProviderWrapper {...auth0Settings} history={history}>
                  <React.Fragment>
                    <Normalize />
                    <LearnErrorBoundary>
                      <AppInitialize>
                        <App history={history} />
                      </AppInitialize>
                    </LearnErrorBoundary>
                  </React.Fragment>
                </Auth0ProviderWrapper>
              </ConnectedRouter>
            </PersistGate>
          </Provider>
        </LoggerContext.Provider>
      </FlagsProvider>
    </View>,
    document.getElementById('root'),
  )
}

render()
