import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, HeaderThree } from 'secondstep-components'

const customBreakpoints = {
  belowTablet: '@media (max-width: 767px)',
}

export const UnitHeader = styled(HeaderThree)`
  font-family: ${themeGet('font.molde.medium')};
  margin-bottom: 0.375rem;

  ${themeGet('breakpoints.mobile')} {
    margin-bottom: 0.125rem;
  }
`

export const TrackLabel = styled(BodyCopy)`
  padding-right: 0.325rem;
  font-family: ${themeGet('font.molde.semiBold')};
`

export const CompletionInfoWrapper = styled.div`
  display: flex;
  flex-direction: row;

  ${customBreakpoints.belowTablet} {
    flex-direction: column;
  }

  ${BodyCopy} {
    font-size: 1rem;
    color: ${themeGet('colors.darkGray')};
    margin-top: 0;
    margin-bottom: 0;
  }
`

export const CompletionPercentage = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 1.25rem;
`

export const CompletedStatusIndicator = styled.div`
  height: 0.8125rem;
  width: 0.8125rem;
  margin-top: 0.325rem;
  margin-right: 0.25rem;
  border-radius: 50%;
  background-color: ${themeGet('colors.emeraldGreen')};
`

export const InProgressStatusIndicator = styled(CompletedStatusIndicator)`
  background-color: ${themeGet('colors.goldenYellow')};
`

export const NotStartedStatusIndicator = styled(CompletedStatusIndicator)`
  background-color: ${themeGet('colors.coralPink')};
`
