import React from 'react'
import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'
import Scroll from 'react-scroll'
import PropTypes from 'prop-types'
import {
  MainHeader,
  ExtraLargeHeader,
  LargeHeader,
  MediumHeader,
  SmallHeader,
  ExtraSmallHeader,
} from 'components/HeaderComponent'

const levelHeadersMap = {
  1: MainHeader,
  2: ExtraLargeHeader,
  3: LargeHeader,
  4: MediumHeader,
  5: SmallHeader,
  6: ExtraSmallHeader,
}

const quoteReplacer = text => {
  let formatText = text

  // if str is empty then return it as usual
  if (!text) {
    return text
  }
  // change all straight quotes to curly
  if (text.indexOf('"') > -1 && text.indexOf('<a href=') === -1) {
    formatText = formatText.replace(/"/g, '”')
  }
  // change all straight apostrophe to curly
  if (text.indexOf("'") > -1) {
    formatText = formatText.replace(/'/g, '’')
  }

  return formatText
}

export const MarkdownParagraph = props => {
  const onScrollableLinkClick = props.onScrollableLinkClick

  if (!props.children) return null

  return (
    <MarkdownText
      center={props.center}
      className={props.className}
      small={props.small}
      white={props.white}
    >
      <ReactMarkdown
        escapeHtml={false}
        renderers={{
          link: props => (
            <Link {...props} onScrollableLinkClick={onScrollableLinkClick} />
          ),
          strong: props => <Strong>{props.children}</Strong>,
          heading: props => {
            const Header = levelHeadersMap[props.level]
            return <Header white={props.white}>{props.children}</Header>
          },
        }}
        source={quoteReplacer(props.children)}
      />
    </MarkdownText>
  )
}

MarkdownParagraph.propTypes = {
  center: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  level: PropTypes.number,
  onScrollableLinkClick: PropTypes.func,
  small: PropTypes.bool,
  white: PropTypes.bool,
}

const MarkdownText = styled.div`
  font-family: ${props => props.theme.fontFamily}, sans-serif;
  font-size: ${props => (props.small ? '1rem' : '1.188rem')};
  line-height: ${props => (props.small ? '1.5rem' : '1.875rem')};
  margin: 0;
  color: ${props => (props.white ? '#fff' : '#1f1f1f')};
  box-sizing: border-box;
  width: 100%; /* IE 11 Fix */
  text-align: ${props => (props.center ? 'center' : 'left')};

  /* This is specifically to target react markdown. Don't normally do this. */
  p {
    margin: 0 0 0.625rem;
  }

  a {
    color: ${props => (props.white ? '#fff' : '#3C6E90')};
    text-decoration: ${props => (props.white ? 'underline' : 'none')};

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }

    &:visited {
      color: ${props => (props.white ? '#fff' : '#8a42a6')};
    }
  }

  ul {
    padding-left: 20px;

    ul {
      li {
        list-style-type: circle;
      }
    }
  }

  li {
    list-style-type: square;
  }

  ol {
    li {
      list-style-type: decimal;
    }
  }
`

class Link extends React.Component {
  constructor(props) {
    super(props)
    this.onScrollableLinkClick = this.onScrollableLinkClick.bind(this)

    let target = '_self'

    if (
      props.href.indexOf('.pdf') > -1 ||
      (props.href.startsWith('/') === false &&
        props.href.indexOf('secondstep.org') < 0)
    ) {
      target = '_blank'
    }
    this.state = { target }
  }

  onScrollableLinkClick = () => {
    if (this.props.onScrollableLinkClick) {
      this.props.onScrollableLinkClick([{ id: this.props.href.slice(1) }], true)
    }
  }

  render() {
    if (this.props.href.startsWith('#')) {
      return (
        <Scroll.Link
          duration={1000}
          onClick={this.onScrollableLinkClick}
          smooth
          to={this.props.href.slice(1)}
        >
          {this.props.children}
        </Scroll.Link>
      )
    }

    return (
      <a
        href={this.props.href}
        rel="noopener noreferrer"
        target={this.state.target}
      >
        {this.props.children}
      </a>
    )
  }
}

Link.propTypes = {
  children: PropTypes.array.isRequired,
  href: PropTypes.string.isRequired,
  onScrollableLinkClick: PropTypes.func,
}

const Strong = styled.strong`
  font-family: ${props => props.theme.fontFamilyBold}, sans-serif;
  font-weight: 800;
`

export const WithHighlight = Component => {
  const HighlightedParagraph = props => {
    let text = props.children
    if (props.highlightInput) {
      const regex = new RegExp(`${props.highlightInput}(?![^(]*\\))`, 'gi')
      text = props.children.replace(regex, '<mark>$&</mark>')
    }

    return <Component {...props}>{`${text}`}</Component>
  }

  HighlightedParagraph.propTypes = {
    children: PropTypes.string.isRequired,
    highlightInput: PropTypes.string.isRequired,
  }
  return HighlightedParagraph
}
