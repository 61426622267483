import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isPosting: false,
  error: null,
}

const reducers = {
  postAcceptUserAgreement: state => {
    state.isPosting = true
  },

  postAcceptUserAgreementSuccess: state => {
    state.isPosting = false
  },

  postAcceptUserAgreementError: (state, action) => {
    state.isPosting = false
    state.error = action.payload || 'Error in posting user agreement'
  },
}

const slice = createSlice({ name: 'termsManager', initialState, reducers })

export const actions = slice.actions
export default slice.reducer
