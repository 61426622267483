import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { CaretLink, ProgressBarIndicator } from 'secondstep-components'
import { domains } from 'config/env'
import { pluralizeEnglish, toTitleCase } from 'utils/stringHelpers'
import {
  Bullet,
  ClassCount,
  ContentWrapper,
  DetailsWrapper,
  GradeDataWrapper,
  StyledBodyCopy,
  StyledProgressBarIndicator,
  StyledTooltip,
} from './component.styles'
import {
  GRADES,
  LESSONS_NOT_STARTED_MULTIPLE_CLASSES,
  LESSONS_NOT_STARTED_SINGULAR_CLASS,
  MULTI_CLASSES,
  SINGULAR_CLASS,
} from './constants'

const NumberOfClasses = ({ classesNotStarted }) => {
  const numOfClassesNotStartedMessage =
    classesNotStarted > 1
      ? LESSONS_NOT_STARTED_MULTIPLE_CLASSES
      : LESSONS_NOT_STARTED_SINGULAR_CLASS
  return (
    <>
      <ClassCount>
        {pluralizeEnglish(classesNotStarted, SINGULAR_CLASS, MULTI_CLASSES)}
      </ClassCount>{' '}
      {numOfClassesNotStartedMessage}
    </>
  )
}

const GradeProgressData = ({
  bhuStatus,
  className,
  classesNotStarted,
  grade,
  lessonsCompleted,
  selectedTab,
  numOfClasses,
  siteId,
  totalLessons,
}) => {
  const isMounted = useRef(true)
  const percentComplete =
    numOfClasses === 0 ? 0 : Math.round((lessonsCompleted / totalLessons) * 100)
  const shouldRenderIndicator = numOfClasses > 0
  const gradeLabel = GRADES[grade]
  const linkUrl = `${domains.LEARN_APP_DOMAIN}/reports/k8/${grade}/?site=${siteId}`

  const classCount = pluralizeEnglish(
    numOfClasses,
    toTitleCase(SINGULAR_CLASS),
    toTitleCase(MULTI_CLASSES),
  )
  const caretLinkMessage = `${gradeLabel} • ${classCount}`
  const keyText = `${siteId}-${grade}-${selectedTab}`
  const lessonsCompletionMessage = `${lessonsCompleted} of ${totalLessons} Lessons`

  useEffect(() => {
    return () => (isMounted.current = false)
  }, [])

  return (
    <GradeDataWrapper className={className}>
      <DetailsWrapper>
        <ContentWrapper numOfClasses={numOfClasses}>
          {classesNotStarted > 0 && (
            <StyledTooltip
              content={
                <NumberOfClasses classesNotStarted={classesNotStarted} />
              }
            >
              <Bullet />
            </StyledTooltip>
          )}
          <CaretLink dataTestId={grade} href={linkUrl}>
            {caretLinkMessage}
          </CaretLink>
        </ContentWrapper>
        {numOfClasses > 0 && (
          <StyledBodyCopy>{lessonsCompletionMessage}</StyledBodyCopy>
        )}
      </DetailsWrapper>
      {bhuStatus ? (
        <StyledProgressBarIndicator
          isParentMounted={isMounted}
          isValueInsideBar
          keyText={keyText}
          percentComplete={percentComplete}
          shouldRenderIndicator={shouldRenderIndicator}
        />
      ) : (
        <ProgressBarIndicator
          isParentMounted={isMounted}
          isValueInsideBar
          keyText={keyText}
          percentComplete={percentComplete}
          shouldRenderIndicator={shouldRenderIndicator}
        />
      )}
    </GradeDataWrapper>
  )
}

NumberOfClasses.propTypes = {
  classesNotStarted: PropTypes.number,
}

GradeProgressData.propTypes = {
  bhuStatus: PropTypes.bool,
  className: PropTypes.string,
  classesNotStarted: PropTypes.number,
  grade: PropTypes.string,
  lessonsCompleted: PropTypes.number,
  numOfClasses: PropTypes.number,
  selectedTab: PropTypes.string,
  siteId: PropTypes.number,
  totalLessons: PropTypes.number,
}

export default GradeProgressData
