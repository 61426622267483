import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Hairline } from 'secondstep-components'
import { MainViewColumn } from '../LessonDetail/component.styles'
import { ContentContainer, H2, UnitLessonListWrapper } from './component.styles'
import Row from './Row'

const UnitLessonList = ({ dataTestId, title, rows, navigationEvent }) => {
  const Content = ({ rows }) => {
    if (!Array.isArray(rows) || !rows.length) {
      return null
    }
    const rowsMapped = rows.map((row, index) => {
      const { name, slmUrl, teachUrl } = row || {}

      return (
        <Fragment key={`row-for-${name}`}>
          {index !== 0 && <Hairline />}
          <Row
            index={index}
            name={name}
            navigationEvent={navigationEvent}
            slmUrl={slmUrl}
            teachUrl={teachUrl}
          />
        </Fragment>
      )
    })
    return <ContentContainer>{rowsMapped}</ContentContainer>
  }

  return (
    <UnitLessonListWrapper data-testid={dataTestId}>
      <MainViewColumn>
        <H2>{title}</H2>
      </MainViewColumn>
      <Content rows={rows} />
    </UnitLessonListWrapper>
  )
}

UnitLessonList.propTypes = {
  dataTestId: PropTypes.string,
  navigationEvent: PropTypes.func,
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      slmUrl: PropTypes.string,
      teachUrl: PropTypes.string,
    }),
  ),
  title: PropTypes.string,
}

export default UnitLessonList
