import React from 'react'
import PropTypes from 'prop-types'
import { LinkTitle } from 'utils/highschoolHelpers'
import {
  GradeButton,
  GradeDisplay,
  StudentActivitiesDescription,
  StudentActivitiesTitle,
  StyledAccordion,
  StyledAccordionWrapper,
} from './component.styles'

function StudentActivitiesCard({
  navigationEvent,
  startExpanded = true,
  studentActivities,
}) {
  return (
    <StyledAccordionWrapper>
      <StyledAccordion
        isActive={startExpanded}
        // TODO: decide if this should be driven by Contentful:
        title={
          <StudentActivitiesTitle>Student Activities</StudentActivitiesTitle>
        }
      >
        <StudentActivitiesDescription>
          {/* TODO: decide if this should be driven by Contentful: */}
          Choose one to complete based on the grade you teach.
        </StudentActivitiesDescription>
        <GradeDisplay>
          {studentActivities?.map((activity, index) => (
            <GradeButton
              key={index}
              onClick={() => navigationEvent(activity?.path)}
            >
              {activity?.title}
            </GradeButton>
          ))}
        </GradeDisplay>
      </StyledAccordion>
    </StyledAccordionWrapper>
  )
}

StudentActivitiesCard.propTypes = {
  navigationEvent: PropTypes.func,
  startExpanded: PropTypes.bool,
  studentActivities: PropTypes.arrayOf(LinkTitle),
}

export default StudentActivitiesCard
