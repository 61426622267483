import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { LinkTitle } from 'utils/highschoolHelpers'
import HighSchoolLessonStatusIcon from 'components/dashboard/HighSchoolLessonStatusIcon'
import {
  CompleteIconWrapper,
  GradeButton,
  GradeDisplay,
  StudentActivitiesDescription,
  StudentActivitiesTitle,
  StyledAccordion,
  StyledAccordionWrapper,
} from './component.styles'
import { shouldShowCardComplete } from './helpers'

function StudentActivitiesCard({
  navigationEvent,
  resetAccordionSignal,
  startExpanded = true,
  studentActivities,
}) {
  const [isActiveFromClick, setIsActiveFromClick] = useState(false)

  const cardIsComplete = shouldShowCardComplete(studentActivities)

  useEffect(() => {
    // even if the accordion has been open, reset it to closed because we changed tabs:
    setIsActiveFromClick(false)
  }, [resetAccordionSignal])

  return (
    <StyledAccordionWrapper>
      <StyledAccordion
        followActiveProp
        // the accordion is smart enough to handle clicks on its own...
        // but we need to track the state from clicks so that the followActiveProp functionality works
        isActive={startExpanded || isActiveFromClick}
        onClick={() => setIsActiveFromClick(!isActiveFromClick)}
        // TODO: decide if this should be driven by Contentful:
        title={
          <StudentActivitiesTitle>
            <CompleteIconWrapper>
              <HighSchoolLessonStatusIcon isComplete={cardIsComplete} />
            </CompleteIconWrapper>
            Student Activities
          </StudentActivitiesTitle>
        }
      >
        <StudentActivitiesDescription>
          {/* TODO: decide if this should be driven by Contentful: */}
          Choose one to complete based on the grade you teach.
        </StudentActivitiesDescription>
        <GradeDisplay>
          {studentActivities?.map((activity, index) => (
            <GradeButton
              key={index}
              onClick={() => navigationEvent(activity?.path)}
            >
              {activity?.title}
            </GradeButton>
          ))}
        </GradeDisplay>
      </StyledAccordion>
    </StyledAccordionWrapper>
  )
}

StudentActivitiesCard.propTypes = {
  navigationEvent: PropTypes.func,
  resetAccordionSignal: PropTypes.bool,
  startExpanded: PropTypes.bool,
  studentActivities: PropTypes.arrayOf(LinkTitle),
}

export default StudentActivitiesCard
