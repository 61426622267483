import { connect } from 'react-redux'
import ReportsUnitCollapsible from './component'
import * as Constants from './constants'

const addCompletionRate = (lesson, lessonInfo) => {
  const { total_users, users_completed, contentful_id } = lesson

  const completion = Math.floor((users_completed / total_users) * 100) || 0
  const completionRate = `${completion}%`

  return { ...lessonInfo, completionRate, id: contentful_id }
}

const mapMeetingInfo = (lesson, index, totalLessons) => {
  const { display_title } = lesson

  const label =
    totalLessons === 1 || index === 1
      ? Constants.STAFF_MEETING
      : Constants.LEADER_MEETING

  return addCompletionRate(lesson, {
    displayTitle: display_title,
    label: label,
  })
}

const mapLessonsInfo = (lessons, microlearnings) => {
  return lessons
    ?.map(lesson => {
      const { contentful_id, content_children, type } = lesson
      let programChildren =
        type === 'choiceLesson' ? content_children : microlearnings

      const { displayTitle, label } =
        programChildren?.find(programChild => {
          return programChild.id === contentful_id
        }) || {}

      if (type === Constants.LESSON_TYPE) {
        return addCompletionRate(lesson, { displayTitle, label })
      } else if (type === Constants.CHOICE_TYPE) {
        const { content_children } = lesson
        const totalChildren = content_children?.length

        return content_children?.map((childLesson, childIndex) => {
          return mapMeetingInfo(childLesson, childIndex, totalChildren)
        })
      }

      return null
    })
    .flat()
    .filter(obj => !!obj)
}

export const mapStateToProps = (_, ownProps) => {
  const {
    displayTitle,
    lessons,
    microlearnings: unitMicrolearnings,
    programTrack,
  } = ownProps || {}

  const isStaff = programTrack?.includes('staff')
  const trackCompletionRate = `${
    isStaff ? Constants.STAFF_TRACK : Constants.LEADER_TRACK
  } Completion Rate`

  const titles = [
    { key: 'displayTitle', value: displayTitle },
    { key: 'completionRate', value: trackCompletionRate },
  ]

  const microlearnings = mapLessonsInfo(lessons, unitMicrolearnings)

  return {
    titles,
    microlearnings,
  }
}

export default connect(mapStateToProps)(ReportsUnitCollapsible)
