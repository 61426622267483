import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const SpinnerContainer = styled.div`
  height: 15rem;
  padding: 0.625rem;
  font-size: 1.125rem;
  color: ${themeGet('colors.darkGray')};
  font-family: Molde-medium;
  ${themeGet('breakpoints.mobile')} {
    height: 10rem;
  }

  svg {
    height: 1.5rem;
    width: 1.563rem;
    color: ${themeGet('colors.darkBlue')};
    opacity: 0.5;
  }
`
