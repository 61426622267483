import React from 'react'
import AccessibleTextLink from 'components/AccessibleTextLink'

const StepModalBackLink = ({ children, ...props }) => {
  const withObligatoryDataTestId = {
    ...props,
    dataTestId:
      props.dataTestId ||
      'step-modal-back-link-' + Math.floor(Math.random() * 1e6).toString(36),
  }

  return (
    <AccessibleTextLink {...withObligatoryDataTestId}>
      <span aria-hidden="true">&lt;&nbsp;</span>
      {children}
    </AccessibleTextLink>
  )
}

StepModalBackLink.propTypes = AccessibleTextLink.propTypes

export default StepModalBackLink
