export const PART_1_ORDERED_SUBTITLES = [
  'materials',
  'preparation',
  'projectDescription',
]
export const PART_2_ORDERED_SUBTITLES = [
  'introduction',
  'steps',
  'warmUp',
  'discussion',
  'investigation',
  'planning',
  'action',
]
export const PART_3_ORDERED_SUBTITLES = ['reflection', 'demonstration']
