import React from 'react'
import PropTypes from 'prop-types'
import { TOOLTIP_LOCATIONS, TOOLTIP_PROGRAMS } from 'utils/tooltipHelpers'
import AdminK8NoClassesCreated from 'components/AdminK8NoClassesCreated'
import AdminTooltip from 'components/AdminTooltip'
import AdminBarChart from 'components/AdminBarChart'
import {
  ChartContainer,
  ClassesCount,
  Container,
  Label,
  NoClassesWrapper,
  ReportLink,
  Separator,
  Total,
  Updated,
} from './component.styles'
import {
  MANY_CLASSES_LABEL_TEXT,
  REPORT_SITE_PATH,
  SINGLE_CLASS_LABEL_TEXT,
} from './constants'

const K8ClassesCreatedPanel = ({
  className,
  classesCreated,
  lastUpdated,
  licenseOrSiteId,
  linkLabel,
}) => {
  const chartData = [
    { label: 'K', value: classesCreated.gradeK },
    { label: '1', value: classesCreated.grade1 },
    { label: '2', value: classesCreated.grade2 },
    { label: '3', value: classesCreated.grade3 },
    { label: '4', value: classesCreated.grade4 },
    { label: '5', value: classesCreated.grade5 },
    { label: '6', value: classesCreated.grade6 },
    { label: '7', value: classesCreated.grade7 },
    { label: '8', value: classesCreated.grade8 },
  ]
  const hasClassesCreated = Boolean(classesCreated.total)
  const labelText =
    classesCreated.total === 1
      ? SINGLE_CLASS_LABEL_TEXT
      : MANY_CLASSES_LABEL_TEXT

  return (
    <Container className={className}>
      <ClassesCount>
        <Updated>{lastUpdated}</Updated>
        <Total>{classesCreated.total}</Total>
        <Label>
          {labelText}

          <AdminTooltip
            className={className}
            location={TOOLTIP_LOCATIONS.LAUNCH_CLASSES_CREATED}
            programKey={TOOLTIP_PROGRAMS.K8}
          />
        </Label>
      </ClassesCount>

      {hasClassesCreated && (
        <ChartContainer>
          <AdminBarChart data={chartData} />
        </ChartContainer>
      )}
      {!hasClassesCreated && (
        <NoClassesWrapper>
          <Separator />
          <AdminK8NoClassesCreated />
        </NoClassesWrapper>
      )}

      {hasClassesCreated && licenseOrSiteId && (
        <ReportLink
          aria-label={linkLabel}
          dataTestId={`full-report-link`}
          href={REPORT_SITE_PATH + licenseOrSiteId}
          target="_self"
        >
          Lesson Completion Report
        </ReportLink>
      )}
    </Container>
  )
}

K8ClassesCreatedPanel.propTypes = {
  className: PropTypes.string,
  classesCreated: PropTypes.object.isRequired,
  lastUpdated: PropTypes.string,
  licenseOrSiteId: PropTypes.number,
  linkLabel: PropTypes.string,
}

export default K8ClassesCreatedPanel
