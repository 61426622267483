import React, { Component } from 'react'
import { arrayOf, shape, string, func, bool } from 'prop-types'

import carrotImage from './assets/carrot.svg'
import Item from './item'

import { LOG_OUT } from './constants'
import {
  Carrot,
  Container,
  UserName,
  Divider,
  StyledRightArrowIcon,
} from './component.styles'

const LinkNavigationShape = shape({
  label: string,
  link: string,
  onPress: func,
})

export default class ProfileDropdown extends Component {
  static propTypes = {
    accountItems: arrayOf(LinkNavigationShape),
    managementItems: arrayOf(LinkNavigationShape),
    nameItem: LinkNavigationShape,
    profileItems: arrayOf(LinkNavigationShape),
    resetLicenseManagerState: func,
    resetLmsManagerState: func,
    resetUserSessionManagerState: func,
    show: bool,
  }

  render() {
    const {
      accountItems,
      managementItems,
      nameItem,
      profileItems,
      resetLicenseManagerState,
      resetLmsManagerState,
      resetUserSessionManagerState,
      show,
      theme,
    } = this.props || {}
    return (
      show && (
        <Container dataTestId="global-nav-profile-dropdown">
          <Carrot source={carrotImage} />
          {nameItem && (
            <>
              <Item
                // override this attribute to make Nightwatch tests pass:
                dataTestIdOverride="Account Details"
                // add more padding around name:
                extraPadding="1.5rem"
                index={0}
                label={
                  <>
                    <UserName theme={theme}>
                      {nameItem.firstName}
                      <br />
                      {nameItem.lastName}
                      <StyledRightArrowIcon />
                    </UserName>
                  </>
                }
                link={nameItem.link}
                onPress={nameItem.onPress}
                theme={theme}
              />
              <Divider theme={theme} />
            </>
          )}
          {profileItems.map(
            ({ icon, iconTopOffset, label, link, onPress }, index) => {
              return (
                <Item
                  icon={icon}
                  iconTopOffset={iconTopOffset}
                  index={index}
                  key={`${index}-${label}`}
                  label={label}
                  link={link}
                  onPress={onPress}
                  theme={theme}
                />
              )
            },
          )}
          <Divider theme={theme} />
          {managementItems.length > 0 && (
            <>
              {managementItems.map(
                ({ icon, iconTopOffset, label, link, onPress }, index) => {
                  return (
                    <Item
                      icon={icon}
                      iconTopOffset={iconTopOffset}
                      index={index}
                      key={`${index}-${label}`}
                      label={label}
                      link={link}
                      onPress={onPress}
                      theme={theme}
                    />
                  )
                },
              )}
              <Divider theme={theme} />
            </>
          )}
          {accountItems.map(
            ({ icon, iconTopOffset, label, link, onPress }, index) => {
              const isLogout = label === LOG_OUT
              return (
                <Item
                  // hack to make the test id consistent for Nightwatch regardless
                  // of if the AdminX feature flag is on or off
                  dataTestIdOverride={isLogout ? LOG_OUT : null}
                  icon={icon}
                  iconTopOffset={iconTopOffset}
                  index={index}
                  key={`${index}-${label}`}
                  label={label}
                  link={link}
                  onPress={onPress}
                  resetLicenseManagerState={
                    isLogout && resetLicenseManagerState
                  }
                  resetLmsManagerState={isLogout && resetLmsManagerState}
                  resetUserSessionManagerState={
                    isLogout && resetUserSessionManagerState
                  }
                  theme={theme}
                />
              )
            },
          )}
        </Container>
      )
    )
  }
}
