import React from 'react'
import PropTypes from 'prop-types'
import { CloseButton, ContainedButton, YieldIcon } from 'secondstep-components'
import {
  CancelButton,
  Header,
  ModalWrapper,
  SubHeader,
} from '../component.styles'

import { ButtonsWrapperCancel } from './component.styles'
import { CANCEL_CREATE, CANCEL_EDIT } from './constants'

const propTypes = {
  cancelFor: PropTypes.string,
  dataTestId: PropTypes.string,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
}

const Cancel = ({ cancelFor, dataTestId, handleCancel, handleSubmit }) => {
  const modalSize = cancelFor === 'Create' ? 'medium' : 'small'
  const messaging = cancelFor === 'Create' ? CANCEL_CREATE : CANCEL_EDIT
  const {
    CANCEL_HEADER,
    CANCEL_SUBHEADER,
    CANCEL_CONFIRM,
    CANCEL_DENY,
  } = messaging

  return (
    <ModalWrapper dataTestId={dataTestId} size={modalSize}>
      <CloseButton dataTestId="button-close" onClick={handleCancel} />
      <YieldIcon dataTestId="icon-yield" />
      <Header dataTestId="modal-cancel-header">{CANCEL_HEADER}</Header>
      <SubHeader data-testid="modal-cancel-subheader">
        {CANCEL_SUBHEADER}
      </SubHeader>
      <ButtonsWrapperCancel>
        <CancelButton dataTestId={'button-cancel'} onClick={handleCancel}>
          {CANCEL_CONFIRM}
        </CancelButton>
        <ContainedButton
          dataTestId={'button-dont-cancel'}
          onClick={handleSubmit}
        >
          {CANCEL_DENY}
        </ContainedButton>
      </ButtonsWrapperCancel>
    </ModalWrapper>
  )
}

Cancel.propTypes = propTypes

export default Cancel
