import React from 'react'
import PropTypes from 'prop-types'
import { MarkdownParagraph } from 'components/MarkdownComponent'
import { StyledHeaderThree } from './component.styles'

const LessonContent = ({ title, content }) => {
  return (
    <>
      {title && <StyledHeaderThree>{title}</StyledHeaderThree>}
      {content && <MarkdownParagraph small>{content}</MarkdownParagraph>}
    </>
  )
}

LessonContent.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string,
}

export default LessonContent
