import React from 'react'
import PropTypes from 'prop-types'
import { BodyCopy, HeaderTwo } from 'secondstep-components'
import ProgramThemeList from '../ProgramThemeList'
import { UnitOverviewWrapper } from './component.styles'

const UnitOverview = ({ contentfulItem, dataTestId }) => {
  const programThemesArray = contentfulItem.metadata && contentfulItem.metadata

  return (
    <>
      <UnitOverviewWrapper data-testid={dataTestId}>
        <HeaderTwo dataTestId="header-unit-overview">
          {contentfulItem.displayTitle}
        </HeaderTwo>
        <BodyCopy>{contentfulItem.description}</BodyCopy>
        {programThemesArray && (
          <ProgramThemeList programThemes={programThemesArray} />
        )}
      </UnitOverviewWrapper>
    </>
  )
}

UnitOverview.propTypes = {
  contentfulItem: PropTypes.object.isRequired,
  dataTestId: PropTypes.string.isRequired,
}

export default UnitOverview
