import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  directions,
  Overlay,
  Pane,
  StyledCloseButton,
} from './component.styles'
import { createPortal } from 'react-dom'
import FocusTrap from 'focus-trap-react'

const DrawerComponent = React.forwardRef(
  (
    {
      children,
      className,
      direction = 'right',
      handleCloseDrawer,
      handleScroll,
      isOpen,
    },
    ref,
  ) => {
    useEffect(() => {
      if (isOpen) {
        document.body.classList.add('modal-open')
      }

      return () => {
        document.body.classList.remove('modal-open')
      }
    }, [isOpen])

    return createPortal(
      <FocusTrap
        active={isOpen}
        focusTrapOptions={{ fallbackFocus: '#close-drawer' }}
      >
        <div>
          <Pane
            className={className}
            dataTestId="drawer"
            onScroll={handleScroll}
            style={{
              ...directions(isOpen)[direction],
            }}
          >
            <StyledCloseButton
              dataTestId="close-drawer"
              id="close-drawer"
              onClick={handleCloseDrawer}
              ref={ref}
            />
            {children}
          </Pane>
          <Overlay
            aria-hidden="true"
            isOpen={isOpen}
            onClick={handleCloseDrawer}
          />
        </div>
      </FocusTrap>,
      document.body,
    )
  },
)
DrawerComponent.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  direction: PropTypes.string,
  handleCloseDrawer: PropTypes.func,
  handleScroll: PropTypes.func,
  isOpen: PropTypes.bool,
  speed: PropTypes.number,
  style: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default DrawerComponent
