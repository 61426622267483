import React from 'react'
import { PropTypes } from 'prop-types'
import LoadingParent from 'components/LoadingParent'
import {
  useCourseManager,
  programs,
} from 'layers/content/v2/hooks/useCourseManager'

function defaultProgramResolver(match) {
  const { url } = match
  if (url.includes('middle-school')) {
    return programs.middleSchool
  }
  if (url.includes('high-school')) {
    return programs.highSchool
  }
}

export function CourseManagerGetter(Component, options = {}) {
  const {
    programResolver = defaultProgramResolver,
    overrideHsPreferences = {},
  } = options
  function CourseManagerGetterComponent(props) {
    const { match } = props

    const program = programResolver(match)

    if (!program) {
      throw new Error('Program not found')
    }

    const courseManager = useCourseManager({
      program,
      overrideHsPreferences,
    })

    if (!!courseManager?.loading && !courseManager?.error) {
      return <LoadingParent loadingText={'Loading...'} />
    }

    return <Component {...props} courseManager={courseManager} />
  }

  CourseManagerGetterComponent.propTypes = {
    match: PropTypes.shape({
      url: PropTypes.string,
    }),
  }

  return CourseManagerGetterComponent
}

CourseManagerGetter.displayName = 'CourseManagerGetter'

export const CourseNodeType = PropTypes.shape({
  contentfulId: PropTypes.string,
  contenfulType: PropTypes.string,
  internalTitle: PropTypes.string,
})
CourseNodeType.children = PropTypes.arrayOf(CourseNodeType)

export const CourseManagerType = PropTypes.shape({
  courseTrees: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.object,
})
