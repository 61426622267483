import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 210px;
`

export const ThumbnailContainer = styled.div`
  display: flex;
`

export const ThumbnailText = styled.div`
  display: flex;
  margin-top: ${themeGet('spacing.yellow12_0_75rem')};
  font-size: ${themeGet('font.size.text.base')};
`
