/* istanbul ignore file */

import React, { useState } from 'react'
import {
  Container,
  ModalContainer,
  MobileModalContainer,
  MobileOnly,
} from './customFilterBy.styles'
import {
  HeaderTwo,
  HeaderFour,
  ContainedButton,
  MobileModal,
  FilterIcon,
} from 'secondstep-components'
import { RefinementList } from 'react-instantsearch-hooks-web'
import { Panel } from './panel'
import {
  MOBILE_MODAL_TITLE,
  MOBILE_MODAL_DESCRIPTION,
  FILTER_BY,
} from './constants'
import PropTypes from 'prop-types'

const CustomFilterBy = props => {
  const [showMobileFilters, setShowMobileFilters] = useState(false)
  const { filtersSelected, facetsFilters, resultsCount } = props

  const hasFiltersSelected =
    filtersSelected?.length !== 0 && facetsFilters?.length !== 0

  return (
    <>
      {!showMobileFilters && (
        <Container
          aria-controls="filters-panel"
          aria-expanded={showMobileFilters}
          onClick={() => setShowMobileFilters(true)}
          type="button"
        >
          <FilterIcon />
          {`Filter ${hasFiltersSelected ? `(${filtersSelected.length})` : ''}`}
        </Container>
      )}

      <ModalContainer visible={showMobileFilters}>
        <MobileModal
          close={() => setShowMobileFilters(false)}
          description={MOBILE_MODAL_DESCRIPTION}
          isOpen={showMobileFilters}
          title={<HeaderTwo>{MOBILE_MODAL_TITLE}</HeaderTwo>}
        >
          <HeaderFour id="filter-by-title">{FILTER_BY}</HeaderFour>
          <MobileModalContainer>
            <section aria-labelledby="filter-by-title">
              {facetsFilters.map((facet, index) => (
                <Panel header={facet.displayTitle} key={index}>
                  <RefinementList attribute={facet.key} />
                </Panel>
              ))}
            </section>
            {showMobileFilters && (
              <MobileOnly>
                <ContainedButton
                  aria-controls="filters-panel"
                  aria-expanded={showMobileFilters}
                  onClick={() => setShowMobileFilters(false)}
                  type="submit"
                >
                  View Results ({resultsCount})
                </ContainedButton>
              </MobileOnly>
            )}
          </MobileModalContainer>
        </MobileModal>
      </ModalContainer>
    </>
  )
}

CustomFilterBy.propTypes = {
  facetsFilters: PropTypes.array,
  filtersSelected: PropTypes.array,
  resultsCount: PropTypes.number,
}

export default CustomFilterBy
