import React from 'react'

export function superscriptRegistered(title) {
  if (title.includes('®')) {
    const parts = title.split('®')
    return (
      <>
        {parts[0]}
        <sup>®</sup> {parts[1].trim()}
      </>
    )
  }
  return title
}

export const getBackgroundColor = (
  isHighSchoolFamily,
  isSelaProduct,
  isMindYetiProduct,
  isOstProduct,
  themeGet,
) => {
  const productColors = {
    isOstProduct: themeGet('colors.programCard.background.color'),
    isMindYetiProduct: themeGet('colors.lightBlue'),
    isHighSchoolFamily: themeGet('colors.highschool.themePrimary'),
    isSelaProduct: themeGet('colors.themeAccent'),
  }

  if (isOstProduct) {
    return productColors.isOstProduct
  }
  if (isMindYetiProduct) {
    return productColors.isMindYetiProduct
  }
  if (isHighSchoolFamily) {
    return productColors.isHighSchoolFamily
  }
  if (isSelaProduct) {
    return productColors.isSelaProduct
  }

  // Default color if no conditions are met
  return themeGet('colors.themePrimary')
}
