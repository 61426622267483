import { connect } from 'react-redux'

import { navigationEvent } from 'layers/navigation/store/operations'
import { operations as reportsOperations } from 'store/reportsManager'

import { findUserStatusTotal } from './helpers'
import ReportsLicenseCard from './component'

export const mapStateToProps = ({ reportsManager }, { validateReportData }) => {
  const { isFetching, reports, selectedLicense } = reportsManager || {}
  const selaReport = reports?.[selectedLicense]
  const { users_totals } = selaReport || {}
  const { total: totalLicenseUsers, license_statuses } = users_totals || {}
  const totalAcceptedUsers = findUserStatusTotal('accepted', license_statuses)
  const totalInvitedUsers = findUserStatusTotal('invited', license_statuses)

  return {
    isFetching,
    selaReport,
    totalAcceptedUsers,
    totalInvitedUsers,
    totalLicenseUsers,
  }
}

const mapDispatchToProps = {
  navigationEvent,
  updateSelectedContentTrack: reportsOperations.updateSelectedContentTrack,
  updateSelectedModuleId: reportsOperations.updateSelectedModuleId,
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportsLicenseCard)
