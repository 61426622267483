import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { ContainedButton, OutlinedButton } from 'secondstep-components'

export const TileContainer = styled.div.attrs(() => ({
  columnStyles: `
    grid-template-columns: 1fr;
    grid-template-rows: repeat(3, max-content);
    justify-items: center;
    text-align: center;
    padding: 1.25rem 1.875rem;
    gap: 0;

    svg {
      margin-bottom: 1rem;
    }

    p {
      margin-bottom: 0.9375rem;
    }
  `,
  rowStyles: `
    grid-template-columns: max-content 1fr auto;
    grid-template-rows: initial;
    justify-items: start;
    text-align: left;
    padding: 1.125rem 1.25rem 1.1875rem;
    gap: 1.0625rem;

    svg {
      margin-bottom: 0;
    }

    p {
      margin-bottom: 0;
    }
  `,
}))`
  max-height: 3.55rem;
  display: grid;
  border-radius: 0.625rem;
  margin: 0 0 1.25rem 0;
  background: ${themeGet('colors.paleGray')};
  align-items: center;
  ${({ rowStyles }) => rowStyles} 

  }

  ${ContainedButton}, ${OutlinedButton} {
    transition: opacity 0.2s ease-in-out;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    height: 100%;
    text-transform: uppercase;
  }

  svg {
    scale: 1;
  }

  ${themeGet('breakpoints.medium')} {
    max-height: unset;
  }

  @media screen and (max-width: 880px) {
    ${({ columnStyles }) => columnStyles}
  }

  ${themeGet('breakpoints.belowTablet')} {
    ${({ rowStyles }) => rowStyles} 
  }

  ${themeGet('breakpoints.mobileLarge')} {
    ${({ columnStyles }) => columnStyles} 
  }
`

export const TileInformation = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

export const TileTitle = styled.h3`
  color: ${themeGet('colors.darkBlue')};
  font-size: 1.0625rem;
  line-height: 1.25rem;
  font-family: ${themeGet('font.molde.medium')};
  margin: 0;
`

export const TileDescription = styled.p`
  color: ${themeGet('colors.darkGray')};
  line-height: 1.125rem;
  font-size: 0.875rem;
  letter-spacing: 0;
  font-family: ${themeGet('font.molde.regular')};
  margin: 0;
`
