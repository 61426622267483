import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'
import birdSvg from 'static/img/bird.svg'

export const EducatorContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

export const StyledSVG = styled.div`
  margin-top: 3rem;
  width: 8.125rem;
  height: 7.428rem;
  background-image: url(${birdSvg});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`

export const Description = styled.p`
  height: 3.438rem;
  width: 21.438rem;
  color: ${themeGet('colors.themeAccent')};
  font-family: ${themeGet('fonts.primary')};
  font-size: 1.063rem;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-align: center;
  margin: 1rem 0;
`

export const ModalButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.688rem;
  width: 10.313rem;
  border-radius: 0.313rem;
  background-color: ${themeGet('colors.themePrimary')};
  color: #ffffff;
  font-family: ${themeGet('fonts.moldeMedium16.fontFamily')};
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-align: center;
  margin-bottom: 6rem;

  &:hover {
    cursor: pointer;
    background-color: ${themeGet('colors.themeSecondary')};
  }
`
