import React from 'react'
import ReactMarkdown from 'react-markdown'
import PropTypes from 'prop-types'
import MindYetiHeading from '../MindYetiHeading/component'
import MindYetiResources from '../MindYetiResources/component'
import MindYetiSessions from '../MindYetiSessions/component'
import MindYetiFooter from '../MindYetiFooter/component'
import { Container } from './component.styles'

const MindYetiPlaylistTemplate = ({
  backgroundImage,
  description,
  getEntryWithNewLocale,
  locale,
  mobileBackgroundImage,
  navigationEvent,
  playlist,
  tabs,
  title,
  yetiSprite,
}) => {
  const formatTitle = title => {
    if (title?.includes('®')) {
      const titleParts = title.split('®')
      return (
        <>
          <strong>{titleParts[0]}</strong>
          <sup>&reg;</sup>
          {titleParts[1]}
        </>
      )
    }
    return (
      <ReactMarkdown renderers={{ paragraph: React.Fragment }}>
        {title}
      </ReactMarkdown>
    )
  }

  return (
    <Container>
      <MindYetiHeading
        backgroundImage={backgroundImage}
        description={description}
        getEntryWithNewLocale={getEntryWithNewLocale}
        locale={locale}
        mobileBackgroundImage={mobileBackgroundImage}
        navigationEvent={navigationEvent}
        tabs={tabs}
        title={formatTitle(title)}
      />

      <MindYetiResources
        description={playlist.introDescription}
        docs={playlist.introDocs}
        title={playlist.introTitle}
        yetiSprite={yetiSprite}
      />

      <MindYetiSessions
        description={playlist.listDescription}
        locale={locale}
        sessions={playlist.sessions}
        title={playlist.listTitle}
      />

      <MindYetiFooter
        body={playlist.closingMessageBody}
        header={playlist.closingMessageHeader}
      />
    </Container>
  )
}

MindYetiPlaylistTemplate.propTypes = {
  backgroundImage: PropTypes.string,
  description: PropTypes.string,
  getEntryWithNewLocale: PropTypes.func,
  locale: PropTypes.string,
  mobileBackgroundImage: PropTypes.string,
  navigationEvent: PropTypes.func,
  playlist: PropTypes.object,
  tabs: PropTypes.array,
  title: PropTypes.string,
  yetiSprite: PropTypes.string,
}

export default MindYetiPlaylistTemplate
