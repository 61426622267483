export const REINFORCE_PAGE_TITLE = 'Reinforce'
export const REINFORCE_SECTION_TITLE = 'Daily Reinforcement'
export const REINFORCE_PDF_TITLE = 'Reinforce PDFs'
export const REINFORCE_CLASSROOM_CLIMATE_ACTIVITIES =
  'Classroom Climate Activities'
export const REINFORCE_LESSON_CONNECTIONS = 'Lesson Connections'
export const DAY_ONE = 'Day 1:'
export const DAY_TWO = 'Day 2:'
export const DAY_THREE = 'Day 3:'
export const DAY_FOUR = 'Day 4:'
export const DAY_FIVE = 'Day 5:'
