import React from 'react'
import ExplorePrograms from './component'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import { ERROR_TYPE_CONTENTFUL } from 'layers/errorHandling/apiError'
import withApiError from 'layers/errorHandling/apiError/component'
import { navigationEvent } from 'layers/navigation/store/operations'
import { ROLES } from 'config/constants'
import { findProgramLinks } from 'utils/findProgramLinks'

const ExploreProgramsContainer = ({
  claims,
  className,
  navigationEvent,
  programQuickLinks,
  selectedProgram,
  sites,
}) => {
  const bullyingHarassmentUnitInformation = () => {
    let sitesEnabled = 0
    let sitesAdmin = 0
    for (const site of sites) {
      if (site?.claims?.indexOf(ROLES.ADMIN) !== -1) {
        sitesAdmin++
        if (site?.sitePreferences?.mspDigital?.bullyingHarassmentUnitEnabled) {
          sitesEnabled++
        }
      }
    }

    if (sitesAdmin > 1) {
      return `${sitesEnabled} of ${sitesAdmin}`
    } else {
      // there is only one site
      return sitesEnabled > 0 ? 'ON' : 'OFF'
    }
  }

  const getAvailableLinks = () => {
    if (!programQuickLinks || !selectedProgram) {
      return
    }
    const { hasElemAdminClaim, hasMsAdminClaim } = claims

    const programAccessMap = {
      K8: {
        access: hasElemAdminClaim && hasMsAdminClaim,
        k5program: 'k5SelDigital',
        msprogram: 'mspDigital',
      },
    }

    return programAccessMap?.[selectedProgram.name]?.access
      ? findProgramLinks(programQuickLinks, selectedProgram, programAccessMap)
      : []
  }
  return (
    <ExplorePrograms
      availableLinks={getAvailableLinks()}
      bullyingHarassmentUnitInformation={bullyingHarassmentUnitInformation()}
      className={className}
      navigationEvent={navigationEvent}
      sites={sites}
    />
  )
}

ExploreProgramsContainer.propTypes = {
  claims: PropTypes.object,
  className: PropTypes.string,
  navigationEvent: PropTypes.func,
  programQuickLinks: PropTypes.array,
  selectedProgram: PropTypes.object,
  sites: PropTypes.array,
}

const mapStateToProps = ({ reportsManager, userAccessManager }) => {
  const { selectedProgram } = reportsManager || {}
  const { claims } = userAccessManager || {}
  let sites = []
  if (selectedProgram.key === 'K8') {
    sites = reportsManager.programs[selectedProgram.key]
  }
  return {
    claims,
    selectedProgram,
    sites,
  }
}
const mapper = entry => {
  const { programQuickLinks } = entry || {}
  return {
    programQuickLinks,
  }
}

const options = {
  entryId: '5oqdbnI289AADFVmm2sJyx',
  include: 3,
  mapper,
  spread: true,
}
const mapDispatchToProps = {
  navigationEvent,
}

export default ContentEntryDataGetter(
  withApiError(ExploreProgramsContainer, [ERROR_TYPE_CONTENTFUL]),
  options,
  connect(mapStateToProps, mapDispatchToProps),
)
