import isEmpty from 'lodash/isEmpty'
import { UserAnalytics } from '@cfc/analytics'
import { fetchUserAccess } from 'auth/stores/access/operations'
import { fetchUserProfile } from 'auth/stores/profile/operations'
import { fetchUserContext } from 'auth/stores/userContext/operations'
import SecondStepApi from 'lib/api/secondStepApi'
import { setCookie } from 'utils/cookiesHelper'
import { getQueryParams } from 'utils/urlHelpers'
import { FlagsSingleton } from 'flags'

// ============= AUTH UPDATES ================
export const handleAuthUpdate = ({ auth0, dispatch }) => {
  if (!auth0.isLoading && !auth0.isAuthenticated && !auth0.error) {
    const pathname =
      window.location.pathname + window.location.search + window.location.hash
    const loginOptions = getQueryParams(window.location.href)
    setCookie('pathname', pathname)
    auth0.loginWithRedirect(loginOptions)
  } else if (auth0.isAuthenticated) {
    fetchUserAccess(dispatch, SecondStepApi.baseApiAddress)
    fetchUserProfile(dispatch, SecondStepApi.baseApiAddress)
    fetchUserContext(dispatch, SecondStepApi.baseApiAddress)
  }
}
// ========== SITES ME ==========
export const handleSitesMeFetch = (auth0, fetchUserSites) => {
  if (auth0.isAuthenticated) {
    fetchUserSites()
  }
}

export const handleUpdateUserContext = async ({
  profile,
  setContextHasBeenCalled,
  setLoadingUserContext,
}) => {
  setContextHasBeenCalled(true)
  const { displayName, email, roles } = profile || {}
  const updatedUserConfigForLaunchDarkly = {
    userEmail: email,
    roles,
    name: displayName,
  }
  setLoadingUserContext(true)
  try {
    await FlagsSingleton.updateUserContext(updatedUserConfigForLaunchDarkly)
  } catch (e) {
    // Failing silently and we just serve a false variation
    console.error(e)
  }
  setLoadingUserContext(false)
}

export const handleUserAnalyticsSetup = (profile, sites) => {
  if (!isEmpty(profile) && sites !== null) {
    UserAnalytics.initialize({
      email: profile.email,
      userId: profile.id,
      profile,
      sites,
    })
  }
}
