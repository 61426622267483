import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Hairline } from 'secondstep-components'
import ReportsModuleBox from 'components/ReportsModuleBox'
import K8ReportingStatusBox from 'components/K8ReportingStatusBox'
import {
  EmptyIcon,
  StyledMarkdown,
} from 'components/K8ReportingStatusBox/component.styles'

export const EmptyGradesStatusBox = styled(K8ReportingStatusBox)`
  flex-direction: row;
  padding: 1.25rem;
  gap: 1rem;
  justify-content: flex-start;
  margin-top: 2.5rem;

  ${EmptyIcon} {
    height: 1.875rem;
    width: 2.75rem;
    margin: 0;
    align-self: center;
  }
  ${StyledMarkdown} {
    max-width: none;
    margin-left: 0;
    text-align: left;
  }
`

export const Footer = styled(ReportsModuleBox)`
  border: unset;
  border-radius: 0;
  background: unset;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  padding: 2.5rem 1.25rem;
  padding-bottom: 0;

  a {
    color: ${themeGet('colors.blueGray')};
  }

  p:not(:last-child) {
    margin-block-end: 1.5rem;
  }

  p:first-child {
    margin-block-end: 0.625rem;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    padding: 0.875rem 1rem 1rem;
  }
`

export const GradeTileContainer = styled.div`
  padding: 0.625rem 1.25rem;
  ${themeGet('breakpoints.mobileLarge')} {
    padding: 0.625rem 1rem;
  }
  padding-bottom: 0;
`
export const GradeTileWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 0;
  display: grid;
  grid-auto-rows: 1fr;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(auto-fill, minmax(19rem, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(8.75rem, 1fr));
  justify-content: flex-start;
`

export const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  flex: 1;
  width: 100%;
  background: ${themeGet('colors.backgrounds.secondary')};
`

export const Hr = styled(Hairline)`
  background-color: ${themeGet('colors.beaconGray')};
  height: 0.14rem;
`

export const ReportWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 1.25rem;
  padding-bottom: 0;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  max-width: 1366px;
`
