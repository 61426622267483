import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Collapsible } from 'grommet'
import { BodyCopy, CloseButton, CollapsibleButton } from 'secondstep-components'
import {
  BodyCopy1,
  BodyCopy2,
  CollapsibleWrapper,
  HeaderTwoWrapper,
  CodeActivationWrapper,
  StyledModal,
} from './component.styles'
import CodeActivationForm from 'components/dashboard/CodeActivationForm'
import {
  EDUCATOR_KIT_DESC,
  FAMILY_ACCESS_DESC,
  KEY_HELP_BODY,
  KEY_HELP_PROMPT,
  MODAL_HEADER,
} from './constants'

const KitActivationModal = ({ isModalOpen, modalClose }) => {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <StyledModal
      dataTestId={'pak-modal'}
      isModalOpen={isModalOpen}
      modalClose={modalClose}
    >
      <CloseButton dataTestId={'button-close-pak-modal'} onClick={modalClose} />
      <HeaderTwoWrapper>{MODAL_HEADER}</HeaderTwoWrapper>
      <BodyCopy1>{EDUCATOR_KIT_DESC}</BodyCopy1>
      <BodyCopy2>{FAMILY_ACCESS_DESC}</BodyCopy2>
      <CodeActivationWrapper>
        <CodeActivationForm modalClose={modalClose} showCancel />
      </CodeActivationWrapper>
      <CollapsibleWrapper>
        <CollapsibleButton
          dataTestId={'pak-collapsible-button'}
          isOpen={isOpen}
          onClick={() => setIsOpen(!isOpen)}
        >
          {KEY_HELP_PROMPT}
        </CollapsibleButton>
        <Collapsible open={isOpen}>
          <BodyCopy>{KEY_HELP_BODY}</BodyCopy>
        </Collapsible>
      </CollapsibleWrapper>
    </StyledModal>
  )
}

KitActivationModal.propTypes = {
  isModalOpen: PropTypes.bool,
  modalClose: PropTypes.func,
}

export default KitActivationModal
