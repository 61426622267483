import { rest } from 'msw'
import mockAlgoliaSearchResonse from './mock-algolia-search-response.json'
import mockAlgoliaSettingsResponse from './mock-algolia-settings-response.json'

export const handlers = [
  rest.get('*/1/indexes/*/settings', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockAlgoliaSettingsResponse))
  }),
  rest.post('*/1/indexes/*/queries', (req, res, ctx) => {
    return res(ctx.status(200), ctx.json(mockAlgoliaSearchResonse))
  }),
]
