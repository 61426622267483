import actions from './actions'
import selectors from './selectors'

const getProductMap = productName => {
  return dispatch => {
    const productMap = selectors.determineProductMap(productName)
    dispatch(actions.currentProductMap(productMap))
    return productMap
  }
}

export default {
  getProductMap,
}
