import collaboration from 'static/img/collaboration.png'
import debate from 'static/img/debate.png'
import discussion from 'static/img/discussion.png'
import inquiry from 'static/img/inquiry.png'
import writing from 'static/img/writing.png'
import digitalMediaLiteracyIcon from 'static/img/ss_hs_digitalmedialiteracy.svg'
import leadershipFutureReadinessIcon from 'static/img/ss_leadershipfuturereadiness.svg'
import relationshipSkillsIcon from 'static/img/ss_hs_relationshipskills.svg'
import resilienceAndPersonalGrowthIcon from 'static/img/ss_hs_resilience&personalgrowth.svg'
import responsibleDecisionMakingIcon from 'static/img/ss_hs_responsibledecisionmaking.svg'
import selfAwarenessIcon from 'static/img/ss_hs_selfawareness.svg'
import socialAwarenessIcon from 'static/img/ss_hs_socialawareness.svg'
import selfManagementIcon from 'static/img/ss_hs_selfmanagement.svg'
import socialEmotionalIntelligenceIcon from 'static/img/ss_socialemotionalintelligence.svg'

export const getIconComponent = name => {
  switch (name) {
    case 'Personal and Relational Understanding':
      return (
        <img
          alt="Personal and Relational Understanding"
          src={socialEmotionalIntelligenceIcon}
        />
      )
    case 'Relationship Skills':
      return <img alt="Relationship Skills" src={relationshipSkillsIcon} />
    case 'Leadership and Future Readiness':
      return (
        <img
          alt="Leadership and Future Readiness"
          src={leadershipFutureReadinessIcon}
        />
      )
    case 'Social Awareness':
      return <img alt="Social Awareness" src={socialAwarenessIcon} />
    case 'Self-Management':
      return <img alt="Self-Management" src={selfManagementIcon} />
    case 'Digital and Media Literacy':
      return (
        <img alt="Digital and Media Literacy" src={digitalMediaLiteracyIcon} />
      )
    case 'Resilience and Personal Growth':
      return (
        <img
          alt="Resilience and Personal Growth"
          src={resilienceAndPersonalGrowthIcon}
        />
      )
    case 'Responsible Decision-Making':
      return (
        <img
          alt="Responsible Decision-Making"
          src={responsibleDecisionMakingIcon}
        />
      )
    case 'Self-Awareness':
      return <img alt="Self-Awareness" src={selfAwarenessIcon} />
    default:
      return null
  }
}

export const mapTextToImage = text => {
  const imageMap = {
    'Creative Collaboration': collaboration,
    Debate: debate,
    Discussion: discussion,
    Inquiry: inquiry,
    Writing: writing,
  }

  if (!imageMap[text]) {
    console.warn(`No matching image found for text: "${text}"`)
  }

  return imageMap[text] || null
}
