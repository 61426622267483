import React from 'react'
import { CompleteIcon, IncompleteIcon } from 'secondstep-components'

import ResumeButton from '../../ResumeButton'
import ReviewButton from '../../ReviewButton'
import StartButton from '../../StartButton'

export const buttonMap = {
  'not attempted': {
    Button: StartButton,
    icon: <IncompleteIcon />,
    text: 'Not Started',
  },
  incomplete: {
    Button: ResumeButton,
    icon: <IncompleteIcon />,
    text: 'In Progress',
  },
  completed: {
    Button: ReviewButton,
    icon: <CompleteIcon />,
    text: 'Complete',
  },
}
