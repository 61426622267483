import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BaseContainer, WarningIcon } from 'secondstep-components'

export const ErrorWrapper = styled(BaseContainer)`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  text-align: left;
  font-size: 14px;
  font-family: ${themeGet('fontFamilyMedium')};
  color: ${themeGet('colors.deleteRed')};
  background-color: ${themeGet('colors.deleteRed')}25;
  width: 100%;
  padding: 6px 12px 4px;
  border-radius: 10px;
  align-items: baseline;
  margin-top: 0;
`

export const StyledWarningIcon = styled(WarningIcon)`
  height: 11px;
  width: 11px;
  margin-right: 8px;

  circle:first-child {
    fill: ${themeGet('colors.errorRed')};
  }
`
