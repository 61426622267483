import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1195px;
  margin-bottom: ${themeGet('spacing.turquoise20_1_25rem')};
  padding: 0 ${themeGet('spacing.turquoise20_1_25rem')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`
