import React from 'react'
import PropTypes from 'prop-types'
import { TabButtonFilled, TabButtonOutlined } from 'secondstep-components'

const AdminDashboardTabButton = ({
  className,
  isActive,
  label,
  onClick,
  onKeyPress,
}) => {
  return (
    <div className={className}>
      {isActive && (
        <TabButtonFilled
          label={label}
          onClick={onClick}
          onKeyPress={onKeyPress}
        />
      )}
      {!isActive && (
        <TabButtonOutlined
          label={label}
          onClick={onClick}
          onKeyPress={onKeyPress}
        />
      )}
    </div>
  )
}

AdminDashboardTabButton.propTypes = {
  className: PropTypes.string,
  isActive: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
}

export default AdminDashboardTabButton
