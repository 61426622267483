const extractClaims = userAccessManager => {
  const claims = Object.keys(userAccessManager?.claims ?? {}).reduce(
    (acc, claimName) => {
      const hasClaim = userAccessManager?.claims[claimName] === true
      if (hasClaim) {
        acc.push(claimName.replace(/^has(.*?)Claim$/, (match, p1) => p1))
      }
      return acc
    },
    [],
  )

  return claims
}

const extractArchetypes = userProfileManager =>
  userProfileManager?.profile?.archetypes?.map(a => a.archetypeName)

const extractRoles = userProfileManager => userProfileManager?.profile?.roles

export const userSegmentationBuilder = state => {
  const { userProfileManager, userAccessManager } = state

  return {
    roles: extractRoles(userProfileManager),
    archetypes: extractArchetypes(userProfileManager),
    claims: extractClaims(userAccessManager),
  }
}
