import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  HS_TRAINING_URL,
  LOADING_TEXT,
  MIND_YETI_LOADING_TEXT,
  MIND_YETI_URL,
} from './constants'
import pages from './pages'
import {
  AI_HS_PAGE_USAGE_KEY,
  sendApplicationInsightsEvent,
} from 'utils/sendAnalyticsDataHelpers'

export default class Pages extends Component {
  static propTypes = {
    entry: PropTypes.object,
    entryId: PropTypes.string,
    getEntryWithNewLocale: PropTypes.func,
    isContentChangingEntries: PropTypes.bool,
    isFetching: PropTypes.bool.isRequired,
    location: PropTypes.object,
    match: PropTypes.object.isRequired,
    userSegmentationInfo: PropTypes.object,
  }
  constructor(props) {
    super(props)
    this.shouldUpdateLoadingState = this.shouldUpdateLoadingState.bind(this)
  }

  componentDidMount() {
    this.shouldUpdateLoadingState()
  }
  componentDidUpdate() {
    this.shouldUpdateLoadingState()
  }
  componentWillUnmount() {
    const { isLoadingApp, setAppNotLoadingState } = this.props || {}
    if (isLoadingApp) {
      setAppNotLoadingState()
    }
  }
  shouldUpdateLoadingState() {
    const {
      setAppIsLoadingState,
      setAppNotLoadingState,
      isLoadingApp,
      userSegmentationInfo,
    } = this.props || {}

    const readyToLoad = this.isReadyToLoad()
    const isMindYeti = location.pathname.includes(MIND_YETI_URL)

    const currentLoadingText = isMindYeti
      ? MIND_YETI_LOADING_TEXT
      : LOADING_TEXT

    const isHsTrainingPage = location.pathname.includes(HS_TRAINING_URL)

    if (readyToLoad && !isLoadingApp) {
      setAppIsLoadingState(currentLoadingText)
      if (isHsTrainingPage) {
        sendApplicationInsightsEvent(AI_HS_PAGE_USAGE_KEY, userSegmentationInfo)
      }
    }
    if (!readyToLoad && isLoadingApp) {
      setAppNotLoadingState()
    }
  }

  isReadyToLoad() {
    const {
      isFetching,
      isContentChangingEntries,
      entry,
      isFetchingOrCreatingProgramFlex,
    } = this.props || {}
    return (
      isFetching ||
      isContentChangingEntries ||
      isFetchingOrCreatingProgramFlex ||
      !entry
    )
  }

  render() {
    const { entry, getEntryWithNewLocale: getEntryWithNewLocaleCurrentNode } =
      this.props || {}
    const { contentType: contentTypeShell } = entry || {}
    const { id: contentType } = contentTypeShell || {}
    const isDistrictSupportsPage = location.pathname.includes(
      '/district/supports',
    )
    const isFamilyResourcesPage = location.pathname.includes(
      '/family-resources/',
    )
    const isSelaLeaderPage = location.pathname.includes('leader-hub')
    const isSelaProduct = location.pathname.includes('/product/sel-for-adults')
    // entry makes sure we are not in a transition state between two entries and prevents that race condition
    const readyToLoad = this.isReadyToLoad()
    if (readyToLoad) {
      return null
    }

    // the error handler wrapper ought to be catching real errors, however, it is possible that,
    // while the contentful node grabber is processing a valid route, the 'isFetching' will be
    // false but the entry will not yet be grabbed and thus undefined. So I revert to null
    // to avoid blowing up the page. We should look into that later
    const Page = pages[contentType]
    return Page ? (
      <Page
        isDistrictSupportsPage={isDistrictSupportsPage}
        isFamilyResourcesPage={isFamilyResourcesPage}
        isSelaLeaderPage={isSelaLeaderPage}
        isSelaProduct={isSelaProduct}
        location={location}
        {...this.props}
        getEntryWithNewLocaleCurrentNode={getEntryWithNewLocaleCurrentNode}
      />
    ) : null
  }
}
