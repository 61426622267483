import { PATHWAY_STATUS_TEXT, PROMPT_TEXT, LINK_TEXT } from './constants'

const TRAINING_OVERDUE_NOTIFICATION_DISMISSED_KEY =
  'secondstep_high_school_training_overdue_notification_dismissed'

export function setTrainingNotificationDismissed() {
  localStorage.setItem(TRAINING_OVERDUE_NOTIFICATION_DISMISSED_KEY, 'true')
}

export function isTrainingNotificationDismissed() {
  return (
    localStorage.getItem(TRAINING_OVERDUE_NOTIFICATION_DISMISSED_KEY) === 'true'
  )
}

export function formatNotificationText(pathwayTitle) {
  return `${PATHWAY_STATUS_TEXT} ${pathwayTitle}. ${PROMPT_TEXT} ${LINK_TEXT}.`
}
