import React from 'react'
import PropTypes from 'prop-types'
import { CollectionCard } from './component.styles'

function StaticCollectionCard({ navigationEvent, path, title }) {
  return (
    <CollectionCard onClick={() => navigationEvent(path)}>
      <h4>{title}</h4>
    </CollectionCard>
  )
}

StaticCollectionCard.propTypes = {
  navigationEvent: PropTypes.func,
  path: PropTypes.string,
  title: PropTypes.string,
}

export default StaticCollectionCard
