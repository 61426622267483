import styled from 'styled-components'
import { BackpackIcon } from 'secondstep-components'
import { Button } from 'grommet'
import { themeGet } from 'styled-system'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const StyledButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    display: flex;
    align-items: center;
  }
  svg {
    margin: -10px 0rem 0.1875rem 0;
  }
  color: ${themeGet('colors.themeAccent')};
  font-weight: 400;
  font-family: ${themeGet('font.molde.medium')};
`

export const BackPackIcon = styled(BackpackIcon).attrs(props => ({
  fill: themeGet('colors.themeAccent')(props),
}))`
  width: 15px !important;
  height: 15px !important;
  padding-right: 10px;
  margin: -1px 0 0 0 !important;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    cursor: pointer;
    ${StyledButton} {
      color: ${props => themeGet('colors.themePrimary')(props)};
    }
    ${BackPackIcon} g > path {
      fill: ${props => themeGet('colors.themePrimary')(props)};
    }
  }
`
