import { useEffect, useRef } from 'react'

export const getDisplayName = Component => {
  return Component.displayName || Component.name || 'Component'
}

export const usePrevious = value => {
  const ref = useRef()
  useEffect(() => {
    ref.current = value // assign the value of ref to the argument
  }, [value]) // this code will run when the value of 'value' changes
  return ref.current // in the end, return the current ref value.
}
