import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

export const addStringsToItem = ({
  initialString = '',
  combineCharacter = '',
  addtlStrings = [],
}) => {
  const result = addtlStrings.reduce((accum, nextString) => {
    return nextString && nextString !== ''
      ? `${accum}${combineCharacter}${nextString}`
      : accum
  }, initialString)

  return result
}

export const camelToKebab = string => {
  return string.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase()
}

export const commaSeparateWords = words => {
  if (words.length === 1) return words[0]
  if (words.length === 2) return words.join(' and ')
  const lastWord = words.pop()
  return `${words.join(', ')}, and ${lastWord}`
}
// TODO: The following i18n related functions need to be replaced.
export const pluralizeEnglish = (n, singular, plural) => {
  if (n === 1) return `${n} ${singular}`
  return `${n} ${plural}`
}

export const pluralizeWord = (count, singular, plural, locale = 'en-US') => {
  const pluralRules = new Intl.PluralRules(locale, { type: 'cardinal' })
  const pluralForm = pluralRules.select(count)
  let pluralized

  switch (pluralForm) {
    case 'one':
      pluralized = singular
      break
    case 'other':
      pluralized = plural
      break
    default:
      pluralized = singular
      break
  }

  return pluralized
}

/**
 * @deprecated Using this function is unsafe as it causes
 * problems with internationalization. If you have a field,
 * such as "label", you need to convice the data source top:
 * also publish the plural form. Pleased do not use this
 * function.
 */
export const getRegularEnglishPluralForm = (count, word) => {
  if (count !== 1) {
    if (word.endsWith('y')) {
      return `${word.slice(0, -1)}ies`
    }
    if (
      word.endsWith('s') ||
      word.endsWith('x') ||
      word.endsWith('z') ||
      word.endsWith('ch') ||
      word.endsWith('sh')
    ) {
      return `${word}es`
    }
    return `${word}s`
  }
  return word
}

export const removeTrailingSlash = url => {
  return url.replace(/\/$/, '')
}

export const regexInputTest = str => {
  /*
  This regex expression tests the string passed in and will not allow the characters mentioned below
  This is a stop gap until we have a more robust error handling process
  */
  // TODO: add sql sanitization
  const regEx = /^[\w\-\s,_'/]+$/
  return regEx.test(str)
}

export const titleToIconName = string => {
  return string.substring(0, string.length - 1)
}

export const toCamelCase = string => {
  if (typeof string !== 'string') {
    return null
  }
  return string
    ?.replace(/[^a-zA-Z0-9]+(.)/g, (_, firstWordLetter) =>
      firstWordLetter.toUpperCase(),
    )
    ?.replace(/^\w/, indexZero => indexZero.toLowerCase())
}

export const toKebabCase = string => {
  if (!string) return ''
  const spaceReplacement = '-'
  const avoid = new RegExp('[^a-z0-9-]+', 'gi')
  const intermediate = string.replace(avoid, spaceReplacement)
  return intermediate.toLowerCase()
}

export const toPascalCase = string => {
  const arrayOfWords = string.replace('-', ' ').split(' ')
  arrayOfWords.forEach((word, index) => {
    arrayOfWords[index] = word.charAt(0).toUpperCase() + word.substring(1)
  })

  const pascalString = arrayOfWords.join('')
  return pascalString
}

export const toTitleCase = string => {
  return string.replace(/\b[a-z]/g, firstLetter => firstLetter.toUpperCase())
}

export const toUrlSafe = (str, toLower = true) => {
  if (!str || str.length === 0)
    throw new Error(`toUrlSafe: invalid input:${str}`)

  const spaceReplacement = '-'
  const avoid = new RegExp('[.!$&\'"()*+,;=]', 'g')
  const intermediate = str.replace(avoid, '').replace(/\s/g, spaceReplacement)
  if (toLower) return intermediate.toLowerCase()
  return intermediate
}
