import CourseManagerClient from '@cfc/course-manager-sdk'
import { formatError } from 'utils/formatError'
import { ENVIRONMENT_SUBDOMAIN } from 'config/env'
import { actions } from './reducer'

import Auth0Manager from 'auth/utils/auth0Manager'

function fetchCourseTree({ userContext, courseName }) {
  return async dispatch => {
    dispatch(actions.fetchingCourseTree())
    try {
      const token = await Auth0Manager.getAccessToken()
      const client = new CourseManagerClient({
        authToken: `${token}`,
        environment: ENVIRONMENT_SUBDOMAIN,
      })
      const data = await client.getCourseTree({ userContext })
      dispatch(
        actions.fetchCourseTreeSuccess({
          data,
          courseName,
        }),
      )
    } catch (error) {
      dispatch(actions.fetchCourseTreeError({ error: formatError(error) }))
    }
  }
}

export default {
  fetchCourseTree,
}
