import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { ModalContentWrapper } from 'secondstep-components'
import ClosableModal from 'experienceComponents/ClosableModal'
import { Button } from 'grommet'

export const StyledModal = styled(ClosableModal)`
  .Overlay {
    background-color: ${themeGet('colors.highschool.modalOverlay')};
  }
  ${ModalContentWrapper} {
    height: auto;
    width: 29.688rem;
    padding: 2.3rem 1.75rem 2.55rem;
    transition: 0.5s height ease;
  }

  form > div > div {
    left: 91.5%;
  }
  #search {
    border-radius: 0.25rem;
    padding-left: 0.938rem;
    width: 100%;

    &:focus {
      border: 0.125rem solid ${themeGet('colors.themePrimary')};
      box-shadow: none;
    }
    font-weight: normal;
    padding-top: 0.906rem;
    padding-bottom: 0.781rem;
  }

  table {
    border: 0.125rem solid ${themeGet('colors.gray500')};
    border-top: none;
    border-radius: 0.25rem;
    font-family: ${themeGet('font.molde.regular')};
    background-color: ${themeGet('colors.white')};
    color: ${themeGet('colors.darkerDarkBlue')};
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1.5rem;
    padding-bottom: 0.375rem;
    padding-top: 0.188rem;
  }
  thead {
    display: none;
  }

  tbody {
    max-height: 14.5rem;
    display: block;
    overflow: auto;
    scrollbar-color: #7f7f7f transparent;

    &:focus {
      outline: none;
    }
  }

  th {
    padding-left: 1rem;
    padding-bottom: 0.25rem;
    width: 100%;

    span {
      font-weight: normal;
      margin-bottom: 0;
    }
  }

  td {
    padding-left: 0;
  }

  tr {
    svg {
      margin-left: 5.125rem;
      path {
        fill: ${themeGet('colors.themePrimary')};
      }
    }
  }

  tr:hover,
  /* To override grommet styling while naving through the keyboard */
  .foAtBI {
    color: ${themeGet('colors.white')};
    background-color: ${themeGet('colors.themePrimary')};

    svg {
      path {
        fill: ${themeGet('colors.white')};
        stroke: ${themeGet('colors.white')};
      }
    }
  }
`

export const ModalTitle = styled.h2`
  color: ${themeGet('colors.highschool.green2')};
  font-family: ${themeGet('font.komet')};
  font-size: 1.75rem;
  font-weight: 800;
  line-height: 1.75rem;
  margin-bottom: 1.313rem;
`

export const StyledModalFooter = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 0.85rem;
`

const BaseButton = styled(Button)`
  height: 2.688rem;
  width: 6.875rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.5rem;
  text-align: center;

  font-family: ${themeGet('font.molde.medium')};
  margin-left: 1rem;
`

export const StyledPrimaryButton = styled(BaseButton)`
  background-color: ${themeGet('colors.buttons.primary.bgActive')};
  color: ${themeGet('colors.white')};

  &:not([disabled]):hover {
    background-color: ${themeGet('colors.buttons.primary.bgHover')};
    span {
      color: ${themeGet('colors.buttons.primary.labelHover')};
    }
    transition: all 0.2s ease;
  }
`

export const StyledSecondaryButton = styled(BaseButton)`
  background-color: ${themeGet('colors.white')};
  color: ${themeGet('colors.highschool.green2')};
  border: 0.125rem solid ${themeGet('colors.highschool.green2')};

  &:not([disabled]):hover {
    background-color: ${themeGet('colors.buttons.secondary.bgHover')};
    span {
      color: ${themeGet('colors.buttons.secondary.labelHover')};
    }
    transition: all 0.2s ease;
  }
`
export const StyledParagraph = styled.p``

export const StyledContainer = styled.div`
  height: 100%;
`

export const NoResults = styled.div`
  border: 0.125rem solid ${themeGet('colors.gray500')};
  border-top: none;
  border-radius: 0.25rem;
  font-family: ${themeGet('font.molde.regular')};
  background-color: ${themeGet('colors.white')};
  color: ${themeGet('colors.darkerDarkBlue')};
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.5rem;
  padding-left: 1rem;
  padding-bottom: 0.375rem;
  padding-top: 0.375rem;
`
