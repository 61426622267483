import { domains } from 'config/env'

export const MODAL_HEADER = 'Access Online Programs'
export const MODAL_BODY = `
Online programs don't use an Activation Key. To access an online program license:

- **Contact your license administrator and ask them to add you to your school's Second Step license.**


- Go to [Account Details](${domains.APP_DOMAIN}/account-details) to review the email address linked to this account. Confirm with your license administrator that it matches the email address associated with your school's Second Step license.


- You can also ask your license administrator check that your school license hasn't expired.


Need more information? [Visit the FAQ.](https://support.secondstep.org/hc/en-us/articles/360017505433-How-do-I-activate-my-kit-or-digital-product-)
`
export const SUBMIT_BUTTON = 'Okay'
