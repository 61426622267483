import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { RootWrapper, TooltipContentWrapper } from './component.styles'

const Tooltip = ({
  className,
  content,
  children,
  dataTestId,
  delay,
  isSiblingHovered,
}) => {
  let timeout
  const [active, setActive] = useState(false)

  const showTip = () => {
    timeout = setTimeout(() => {
      setActive(true)
    }, delay)
  }

  const hideTip = () => {
    clearInterval(timeout)

    if (!isSiblingHovered) setActive(false)
  }

  const switchTip = () => {
    if (active) {
      hideTip()
    } else {
      showTip()
    }
  }

  useEffect(() => {
    if (isSiblingHovered) {
      showTip()
    } else {
      hideTip()
    }
  }, [isSiblingHovered])

  return (
    // eslint-disable-next-line jsx-a11y/role-supports-aria-props
    <RootWrapper
      aria-expanded={active}
      className={className}
      // When to show the tooltip
      dataTestId={`${dataTestId}-root-wrapper`}
      onBlur={hideTip}
      onClick={switchTip}
      onPointerEnter={showTip}
      onPointerLeave={hideTip}
      role="tooltip"
    >
      {active && (
        <TooltipContentWrapper dataTestId={`${dataTestId}-content-wrapper`}>
          {content}
        </TooltipContentWrapper>
      )}
      {children}
    </RootWrapper>
  )
}

Tooltip.propTypes = {
  Icon: PropTypes.node,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  content: PropTypes.node.isRequired,
  dataTestId: PropTypes.string,
  delay: PropTypes.number,
  isSiblingHovered: PropTypes.bool,
}

Tooltip.defaultProps = {
  delay: 100,
}

export default Tooltip
