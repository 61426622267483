import dayjs from 'dayjs'

export const cleanObject = obj => {
  for (const prop in obj) {
    if (
      !obj.hasOwnProperty(prop) ||
      typeof obj[prop] !== 'object' ||
      obj[prop] === null
    ) {
      obj[prop] = null
    }
    cleanObject(obj[prop])
  }
  return obj
}

export const getDefaultDates = setCurrentDate => {
  // setCurrentDate is a new Date() object and is optional to pass
  const currentDate = setCurrentDate
    ? dayjs(setCurrentDate)
    : dayjs(new Date().setHours(0, 0, 0, 0))

  const currentYear = currentDate.year()
  const end = {
    date: 30,
    month: 6,
    year: currentYear,
  }

  let endDate = dayjs(new Date(`${end.month}/${end.date}/${end.year}`))

  // dayJs sets month on a zero based index, just normalizing the month here
  const adjustedCurrentMonth = currentDate.month() + 1
  const isDateJune30 = adjustedCurrentMonth === 6 && currentDate.date() === 30
  const isEndDateNextYear = adjustedCurrentMonth > 6 || isDateJune30

  if (isEndDateNextYear) {
    endDate = endDate.add(1, 'year')
  }

  let startDate = currentDate
  startDate = startDate.format('MM/DD/YYYY')
  endDate = endDate.format('MM/DD/YYYY')

  return {
    startDate,
    endDate,
  }
}
