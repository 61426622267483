import React from 'react'
import { WarningIcon } from 'secondstep-components'
import { ErrorContainer, IconTextContainer } from './LmsCSVError.styles'

const LmsCSVError = () => (
  <ErrorContainer>
    <IconTextContainer>
      <WarningIcon /> There was a problem loading CSV reports.
    </IconTextContainer>
    <div>Refresh the page and try again.</div>
  </ErrorContainer>
)

export default LmsCSVError
