import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import {
  BodyCopy,
  IconWithText,
  HeaderThree,
  HeaderFive,
} from 'secondstep-components'

const breakpoints = {
  medium: '@media screen and (max-width: 600px)',
}

export const Wrapper = styled('li').attrs(({ dataTestId, isOpen }) => ({
  borderRadius: isOpen ? '1.25rem 1.25rem 0 0' : '1.25rem',
  'data-testid': dataTestId,
}))`
  list-style: none;
  flex-direction: column;

  ${HeaderFive} {
    margin: 0 0 0.7rem 0;
  }

  button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 2.1875rem 1.75rem 2rem;
    border-radius: ${({ borderRadius }) => borderRadius};
    text-transform: none;
    transition: all 0.2s ease;

    ${HeaderThree} {
      margin: 0 1.75rem 0 0;
      color: inherit;
      font-size: 1.5rem;
      line-height: 1.875rem;
      font-family: ${themeGet('fontFamilySemibold')};
    }
  }

  ${breakpoints.medium} {
    button {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 1.375rem 1.75rem 1.75rem;

      ${HeaderThree} {
        margin: 0 0 0.3125rem;
      }
    }
  }

  ${themeGet('breakpoints.mobile')} {
    button {
      padding: 1.4375rem 1.25rem 1.3125rem;

      ${HeaderThree} {
        font-size: 1.375rem;
        line-height: 1.5rem;
      }
    }
  }
`

export const StatusWrapper = styled(Box).attrs(({ attempted }) => ({
  iconColor:
    attempted === 'completed'
      ? themeGet('colors.iconBlue')
      : themeGet('colors.white'),
  opacity: attempted === 'completed' ? 1 : 0.5,
}))`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  min-width: 116px;
  margin-bottom: 0.25rem;

  span {
    color: inherit;
    font-family: ${themeGet('fontFamilyBold')};
    white-space: nowrap;
    margin: 0;
  }

  svg {
    width: 1.4375rem;
    height: 1.4375rem;
    padding-bottom: 0.0625rem;
    margin: 0.125rem 0.5rem 0 0;
    color: ${({ iconColor }) => iconColor};
    opacity: ${({ opacity }) => opacity};
  }

  ${breakpoints.medium} {
    justify-content: flex-start;
    margin: 0;
  }
`

export const DetailsPanel = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  flex-direction: column;
  border-radius: 0 0 1.25rem 1.25rem;
  background-color: ${themeGet('colors.lighterGray')};
`

export const DescriptionWrapper = styled(Box)`
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  ${breakpoints.medium} {
    flex-direction: column-reverse;
  }
`

export const BodyWrapper = styled(Box)`
  flex-direction: column;
  padding: 1.5rem 1.6875rem;

  ${BodyCopy} {
    max-width: 680px;
    padding-right: 2.5rem;
    margin-bottom: 1.25rem;
    flex: 2;
  }

  p {
    margin-bottom: 0.625rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  button {
    flex-direction: column;
    align-items: flex-start;
    border-radius: 1.25rem;
    padding: 1.375rem 0.8125rem;
  }

  ${IconWithText} {
    margin-bottom: 0.625rem;
    color: ${themeGet('colors.darkerDarkBlue')};
    font-family: ${themeGet('fontFamilySemibold')};
  }

  ${breakpoints.medium} {
    padding: 1.375rem 0.8125rem;

    ${BodyCopy} {
      padding-right: 0;
    }
  }
`

export const ButtonWrapper = styled(Box)`
  flex-direction: row;
  & > * {
    margin: 0 1.25rem 0 0;
    &:last-child {
      margin: 0;
    }
  }

  ${breakpoints.medium} {
    flex-direction: column;
    & > * {
      margin: 0 0 1.25rem 0;
    }
  }
`

export const ResourcePanel = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  padding: 1.5rem 1.6875rem;
  border-radius: 0 0 1.25rem 1.25rem;
  background-color: ${themeGet('colors.mediumDarkGray')};
`
