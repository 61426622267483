import { useEffect, useState, useMemo, useCallback } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { CLICK, ENTER } from './constants'

// Variable used to keep track when the modal is opened. This is used to prevent the modal
// from opening twice in case hook is mounted twice in the same page.
let modalOpened = false

export const useModal = (options = {}) => {
  const { searchKey, searchValue } = options
  const [showModal, setShowModal] = useState(false)
  const history = useHistory()
  const location = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ])

  const openModal = useCallback(() => {
    if (showModal) {
      return
    }

    if (searchKey) {
      searchParams.set(searchKey, searchValue)
      history.replace({ search: searchParams.toString() })
    }
    setShowModal(true)
    modalOpened = true
  }, [showModal, searchKey])

  // This function provides a convienent way to open the modal from a DOM event.
  // If you want to control when the modal opens, you can call openModal directly.
  const createOpenHandler = useCallback(
    event => {
      if (event.type === CLICK || event.key === ENTER) {
        openModal(searchValue)
      }
    },
    [openModal],
  )

  const closeModal = useCallback(() => {
    if (searchKey) {
      searchParams.delete(searchKey)
      history.replace({ search: searchParams.toString() })
    }
    setShowModal(false)
    modalOpened = false
  }, [searchKey])

  const getQueryParamValue = () => {
    return encodeURIComponent(searchParams.get(searchKey))
  }

  // Effect spefically used for url search key tracking
  useEffect(() => {
    if (modalOpened) {
      return
    }

    // If the search key is present in the URL, open the modal.
    const urlSearchValue = getQueryParamValue()
    if (searchKey && urlSearchValue === searchValue && !showModal) {
      openModal(searchValue)
      modalOpened = true
    }
  }, [searchParams])

  return {
    showModal,
    openModal,
    closeModal,
    createOpenHandler,
  }
}
