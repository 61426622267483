import { OptionalUnits } from 'utils/productMaps'
import { CORE_UNITS_LABEL, BULLYING_UNITS_LABEL } from './constants'

function isCoreUnit(unit) {
  return (
    OptionalUnits.BullyingHarassmentUnit.courseNodeIds.indexOf(
      unit.contentfulID,
    ) === -1
  )
}

function sumLessons(acc, unit) {
  return acc + unit.completeLessons
}

function getLessonsCount(rawData, lmsClass) {
  const coreLessonsAmount = rawData.tabSummaries.reduce((acc, unit) => {
    if (unit.route.includes(OptionalUnits.BullyingHarassmentUnit.slug)) {
      return acc
    }
    return acc + unit.lessonCount
  }, 0)

  const bullyingLessonsAmount = rawData.tabSummaries.reduce((acc, unit) => {
    if (unit.route.includes(OptionalUnits.BullyingHarassmentUnit.slug)) {
      return acc + unit.lessonCount
    }
    return acc
  }, 0)

  const hasBullyingLessons = bullyingLessonsAmount > 0
  const lessonsAmounts = [
    {
      totalAmount: coreLessonsAmount,
      totalComplete:
        lmsClass?.units.filter(isCoreUnit).reduce(sumLessons, 0) ?? 0,
      categoryLabel: hasBullyingLessons ? CORE_UNITS_LABEL : undefined,
    },
  ]

  if (hasBullyingLessons) {
    lessonsAmounts.push({
      totalAmount: bullyingLessonsAmount,
      totalComplete:
        lmsClass?.units.filter(u => !isCoreUnit(u)).reduce(sumLessons, 0) ?? 0,
      categoryLabel: BULLYING_UNITS_LABEL,
    })
  }

  return lessonsAmounts
}

export function getData(rawUnit, lmsClass) {
  const { courseContent } = rawUnit ?? {}
  const lessonsAmounts = getLessonsCount(courseContent, lmsClass)
  const scopeAndSequenceData = (courseContent?.coursePdf ?? []).map(
    coursePdf => ({
      linkLabel: coursePdf?.displayTitle ?? '',
      linkHref: coursePdf?.file?.url ?? '',
    }),
  )
  return { lessonsAmounts, scopeAndSequenceData }
}

export function resolveEntryIdForCourseLevelContent() {
  const { pathname } = window.location
  if (pathname.includes('grade-6')) {
    return '5AGaTqoAm6L9dOBEGA0T3O'
  }
  if (pathname.includes('grade-7')) {
    return '23mmNGllWqaztBVKoMO40c'
  }
  if (pathname.includes('grade-8')) {
    return '6gU5RCfNJO87cBw072P6p0'
  }
  // default to grade 6 for lack of a better option
  return '5AGaTqoAm6L9dOBEGA0T3O'
}

export function extractUnitSlugsFromTabSummaries(tabSummaries) {
  return (
    tabSummaries?.map(tabSummary => {
      // the full pathname links to the preview routes
      // but we only need the last slug in the pathname, so extract that:
      const fullPathname = tabSummary.route
      const lastSlashIndex = fullPathname.lastIndexOf('/')
      return fullPathname.slice(lastSlashIndex + 1)
    }) ?? []
  )
}
