import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { SpinnerAnimatedIcon } from 'secondstep-components'
import { domains } from 'config/env'
import { navigationEvent } from 'layers/navigation/store/operations'
import NavigationHelper from '../../layers/navigation/navigationHelper'
import {
  getProgramSnapshot,
  SNAPSHOTS_NAME_MAP,
} from 'utils/adminExperienceHelpers'
import {
  ADMIN_DASHBOARD_ENTRY_ID,
  HS_IMPLEMENTATION_ENTRY_ID,
  K8_IMPLEMENTATION_ENTRY_ID,
} from 'utils/entryIds'
import AdminFlexibleContentPanel from './component'
import { LOADING_TEXT } from './constants'
import { ContentPanelWrapper, LoadingWrapper } from './component.styles'

export const AdminFlexibleContentPanelContainer = ({
  breakpointTablet,
  className,
  description,
  displayTitle,
  fullWidthContent,
  getAlternateContentForHeader,
  getAlternateContentForTab,
  internalTitle,
  navigationEvent,
  preferences,
  programKey,
  showHairline,
  siteId,
  tabColumns,
  toggleModal,
  tooltips,
}) => {
  const dataExists = displayTitle ?? false

  const navigateToPage = url => {
    const { pathname } = window?.location || {}
    const depth = pathname.split('/').length - 1
    const isDuplicatePath = NavigationHelper.isADuplicatePath(pathname, url)

    const urlPath = url
      .split('/')
      .slice(1)
      .join('/')

    if (url && !isDuplicatePath) {
      const fullRoute = NavigationHelper.generateUrlWithDepthForRoute(
        pathname,
        urlPath,
        depth,
      )
      navigationEvent && navigationEvent(fullRoute)
    }
  }

  const handleNavigate = (url, type) => {
    if (url === '/user-management') {
      url = `${domains.ADMIN_DOMAIN}${url}/${siteId}`
    }
    navigationEvent(url, type)
  }

  if (!dataExists) {
    return (
      <ContentPanelWrapper className={className}>
        <LoadingWrapper>
          <SpinnerAnimatedIcon />
          <p>{LOADING_TEXT}</p>
        </LoadingWrapper>
      </ContentPanelWrapper>
    )
  }

  return (
    <AdminFlexibleContentPanel
      alternateHeaderContent={getAlternateContentForHeader(
        internalTitle,
        displayTitle,
        description,
        tooltips,
      )}
      breakpointTablet={breakpointTablet}
      className={className}
      description={description}
      displayTitle={displayTitle}
      fullWidthContent={fullWidthContent}
      getAlternateContentForTab={getAlternateContentForTab}
      handleNavigate={handleNavigate}
      navigateToPage={navigateToPage}
      navigationEvent={navigationEvent}
      preferences={preferences}
      programKey={programKey}
      showHairline={showHairline}
      siteId={siteId}
      tabColumns={tabColumns}
      toggleModal={toggleModal}
      tooltips={tooltips}
    />
  )
}

const mapStateToProps = ({ contentful }, { contentKey, programKey }) => {
  const isImplementationGuide =
    location.pathname.includes('admin/implementation-guide') ||
    location.pathname.includes('admin/hs-implementation-guide')

  if (isImplementationGuide) {
    const IMPLEMENTATION_CONTENTFUL_ID = location.pathname.includes(
      'admin/implementation-guide',
    )
      ? K8_IMPLEMENTATION_ENTRY_ID
      : HS_IMPLEMENTATION_ENTRY_ID
    const implementation =
      contentful?.[IMPLEMENTATION_CONTENTFUL_ID]?.payload || []

    const implementationData = implementation?.[contentKey]
    const { description, displayTitle } = implementationData || {}

    let tabColumns = []

    for (let key in implementationData) {
      if (key.startsWith('tabColumn')) {
        tabColumns.push(implementationData[key])
      }
    }

    return {
      description,
      displayTitle,
      tabColumns,
    }
  }

  const snapshotFieldName = SNAPSHOTS_NAME_MAP[programKey]
  const snapshots =
    contentful?.[ADMIN_DASHBOARD_ENTRY_ID]?.payload?.[snapshotFieldName] || []
  const snapshot = getProgramSnapshot(programKey, snapshots)
  const contentData = snapshot?.[contentKey]
  const {
    description,
    displayTitle,
    fullWidthContent,
    internalTitle,
    tabColumn1,
    tabColumn2,
    tabColumn3,
    tooltips,
  } = contentData || {}

  const tabColumns = [tabColumn1, tabColumn2, tabColumn3].reduce((acc, tab) => {
    if (tab) {
      acc.push(tab)
    }
    return acc
  }, [])

  return {
    description,
    displayTitle,
    fullWidthContent,
    internalTitle,
    programKey, // TODO: used for modal, try to move to AdminHSSetup
    tabColumns,
    tooltips,
  }
}

const mapDispatchToProps = {
  navigationEvent,
}

AdminFlexibleContentPanelContainer.propTypes = {
  alternateHeaderContent: PropTypes.object,
  breakpointTablet: PropTypes.bool,
  className: PropTypes.string,
  description: PropTypes.string,
  displayTitle: PropTypes.string,
  fullWidthContent: PropTypes.array,
  getAlternateContentForHeader: PropTypes.func,
  getAlternateContentForTab: PropTypes.func,
  internalTitle: PropTypes.string,
  navigationEvent: PropTypes.func,
  preferences: PropTypes.object,
  programKey: PropTypes.string,
  showHairline: PropTypes.bool,
  siteId: PropTypes.number,
  tabColumns: PropTypes.array,
  toggleModal: PropTypes.func,
  tooltips: PropTypes.array,
}

AdminFlexibleContentPanelContainer.defaultProps = {
  getAlternateContentForHeader: () => null,
  getAlternateContentForTab: () => null,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminFlexibleContentPanelContainer)
