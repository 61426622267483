import React, { createRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BodyCopy } from 'secondstep-components'
import DrawerComponent from 'components/DrawerComponent'
import K8ClassesTable from 'components/K8ClassesTable'
import K8Class from 'pages/K8Class'
import K8ReportingStatusBox from 'components/K8ReportingStatusBox'
import NavigationHelper from 'layers/navigation/navigationHelper'
import ReportsLoading from 'components/ReportsLoading'
import ReportsPageHeader from 'components/ReportsPageHeader'
import {
  ContentWrapper,
  Footer,
  PageWrapper,
  ReportWrapper,
  TableContainer,
} from './component.styles'

const GradeView = ({
  bullyingHarassmentUnitEnabled,
  classInstance,
  entry,
  gradeEmptyDisplay,
  gradeReport,
  isFetchingGrade,
  navigationHandler,
  selectedGrade,
  selectedSite,
}) => {
  const { classes: classRefs } = gradeReport || {}

  const closeButtonRef = createRef(null)
  const tableRowRefs = createRef([])
  const [refocusClassRowIndex, setRefocusClassRowIndex] = useState(null)
  const [isOpen, setIsOpen] = useState(!!classInstance)
  const [fixedView, setFixedView] = useState(false)
  const [fixedTop, setFixedTop] = useState(false)

  // init refs, tried in useEffect[] but no workey
  if (classRefs?.length > 0) {
    tableRowRefs.current = Array(classRefs.length)
      .fill()
      .map((_, i) => tableRowRefs.current?.[i] || createRef())
      .map(() => createRef())
  }

  useEffect(() => {
    if (classRefs?.length > 0) {
      tableRowRefs.current = Array(classRefs.length)
        .fill()
        .map(() => createRef())
    }
    setRefocusClassRowIndex(null)
  }, [classRefs])

  useEffect(() => {
    if (isOpen) {
      closeButtonRef.current.focus()
    } else if (classRefs && refocusClassRowIndex !== null) {
      tableRowRefs?.current[refocusClassRowIndex]?.current?.focus()
    }
  }, [isOpen, classRefs, refocusClassRowIndex])

  useEffect(() => {
    const onKeyPress = e => {
      if (e.key === 'Escape') {
        toggleViewClassReport(e)
      }
    }

    if (isOpen) {
      window.addEventListener('keyup', onKeyPress)
    }

    return () => {
      window.removeEventListener('keyup', onKeyPress)
    }
  }, [isOpen])

  const formatGradeEmptyHeaderText = textTemplate => {
    if (!textTemplate) {
      return ''
    }

    return textTemplate.replace(/Grade\s+<string\?>/gi, gradeReport?.gradeName)
  }

  const handleScroll = e => {
    const offsetTop = e.target.scrollTop
    if (offsetTop > 22) setFixedTop(true)
    else if (fixedTop) setFixedTop(false)
    if (offsetTop > 52) setFixedView(true)
    else if (fixedView) setFixedView(false)
  }

  const gradeHasUnit5 = bullyingHarassmentUnitEnabled

  const toggleViewClassReport = (
    event,
    { classInstance: selectedClassInstance } = {},
    selectedClassTableRowIndex,
  ) => {
    if (event.type === 'click' || event.key === 'Enter') {
      event.preventDefault()

      if (selectedClassInstance) {
        setRefocusClassRowIndex(selectedClassTableRowIndex)
      }

      setIsOpen(!!selectedClassInstance)
      const params = { gradeName: selectedGrade, siteId: selectedSite }

      if (!classInstance || classInstance !== selectedClassInstance) {
        params.classInstance = selectedClassInstance
      }
      navigationHandler(params, NavigationHelper.types.REPLACE)
    } else if (event.key === 'Escape') {
      event.preventDefault()

      setIsOpen(false)
      const params = { gradeName: selectedGrade, siteId: selectedSite }
      navigationHandler(params, NavigationHelper.types.REPLACE)
    }
  }

  const {
    classes,
    classesCount,
    gradeName,
    hasClassesWithCompletedLessonLastPeriod,
    lastUpdatedAt,
    numClassesWithCompletedLessonLastPeriod,
    period,
    teachersCount,
  } = gradeReport || {}
  const {
    classLessonColTooltip,
    classMarkedDoneTooltip,
    classStatusColTooltip,
    gradeClassColTooltip,
    gradeFooterText: footerText,
    gradeHeaderTooltip,
    gradeLastLessonColTooltip,
    gradeMarkedDoneColTooltip,
    gradeProgressColCoreTooltip,
    gradeProgressColRbhuTooltip,
    gradeProgressColTooltip,
  } = entry || {}

  return (
    <PageWrapper>
      {isFetchingGrade && <ReportsLoading background />}
      {!isFetchingGrade && (
        <>
          <ContentWrapper>
            <ReportWrapper>
              <ReportsPageHeader
                entry={entry}
                gradeOrSiteName={gradeName}
                hasClassesWithCompletedLessonLastPeriod={
                  hasClassesWithCompletedLessonLastPeriod
                }
                isGradeView
                lastUpdatedAt={lastUpdatedAt}
                numClassesWithCompletedLessonLastPeriod={
                  numClassesWithCompletedLessonLastPeriod
                }
                period={period}
                teachersCount={teachersCount}
                tooltipText={gradeHeaderTooltip}
                totalClassesCount={classesCount}
              />
              {classes && classes.length === 0 && (
                <K8ReportingStatusBox
                  icon={gradeEmptyDisplay?.icon?.file?.url}
                  text={gradeEmptyDisplay?.text}
                  title={formatGradeEmptyHeaderText(gradeEmptyDisplay?.header)}
                />
              )}
              {classes && classes.length > 0 && (
                <TableContainer>
                  <K8ClassesTable
                    classes={classes}
                    classesTooltip={gradeClassColTooltip}
                    displayUnit5Column={gradeHasUnit5}
                    handleRowClick={toggleViewClassReport}
                    lastLessonTooltip={gradeLastLessonColTooltip}
                    markedDoneTooltip={gradeMarkedDoneColTooltip}
                    progressCoreTooltip={gradeProgressColCoreTooltip}
                    progressTooltip={gradeProgressColTooltip}
                    progressUnit5Tooltip={gradeProgressColRbhuTooltip}
                    ref={tableRowRefs}
                  />
                </TableContainer>
              )}
            </ReportWrapper>
            <Footer>
              <BodyCopy>{footerText}</BodyCopy>
            </Footer>
          </ContentWrapper>
        </>
      )}
      <DrawerComponent
        direction="right"
        handleCloseDrawer={toggleViewClassReport}
        handleScroll={handleScroll}
        isOpen={isOpen}
        ref={closeButtonRef}
      >
        <K8Class
          classLessonColTooltip={classLessonColTooltip}
          classMarkedDoneTooltip={classMarkedDoneTooltip}
          classStatusColTooltip={classStatusColTooltip}
          fixedTop={fixedTop}
          fixedView={fixedView}
          gradeName={gradeName}
          hasBHU={gradeHasUnit5}
        />
      </DrawerComponent>
    </PageWrapper>
  )
}

GradeView.propTypes = {
  bullyingHarassmentUnitEnabled: PropTypes.bool,
  classInstance: PropTypes.string,
  entry: PropTypes.object,
  gradeEmptyDisplay: PropTypes.object,
  gradeName: PropTypes.string,
  gradeReport: PropTypes.object,
  isFetchingGrade: PropTypes.bool,
  lastUpdatedAt: PropTypes.string,
  navigationHandler: PropTypes.func,
  period: PropTypes.number,
  selectedGrade: PropTypes.string,
  selectedSite: PropTypes.number,
}

export default GradeView
