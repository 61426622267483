import { getContent, makeUrlFriendly } from './helpers'
import { getContentTypeName } from './parser'

export function anchorNavigation(
  entry,
  isPreviewMode = false,
  highSchoolFlagOn = false,
) {
  const content = getContent(entry)
  if (!Array.isArray(content)) {
    return null
  }
  const linkContent = content.filter(contentItem => {
    const name = getContentTypeName(contentItem)
    return name === 'anchor'
  })

  const links = linkContent.map(({ h2Text }) => {
    const id = makeUrlFriendly(h2Text)
    const hash = `#${id}`
    return { hash, id: hash, text: h2Text }
  })

  // this is hardcoded entry because it lives outside of the dynamic content reference list in Contentful
  // however, this jump link should not be added in Preview Mode or if the High School flag is off:
  if (entry?.markComplete && !isPreviewMode && highSchoolFlagOn) {
    links.push({
      hash: '#mark-complete',
      id: '#mark-complete',
      text: 'Mark Complete',
    })
  }

  return links.length ? links : null
}
