import { operations as lmsOperations } from 'store/lmsManager'
import { operations as reportsOperations } from 'store/reportsManager'
import { operations as routeOperations } from 'store/routeServiceManager'
import { operations as contentOperations } from 'store/contentServiceManager'
import { NAVIGATION_EVENT } from 'layers/navigation/store/types'

const removeError = ({ dispatch }) => next => action => {
  next(action)

  if (action.type === NAVIGATION_EVENT) {
    dispatch(lmsOperations.clearErrors())
    dispatch(reportsOperations.clearErrors())
    dispatch(routeOperations.clearErrors())
    dispatch(contentOperations.clearErrors())
    /**
     * TODO: possible clear any other errors?
     * 'licenseError'
     * 'profileError'
     * 'contentfulError'
     */
  }
}

export default removeError
