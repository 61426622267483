import React from 'react'
import PropTypes from 'prop-types'
import AdminHSSetup from 'components/AdminHSSetup'
import HighSchoolLaunchSnapshot from 'components/admin/LaunchSnapshot/HighSchool'
import { HEADER_TEXT } from './constants'
import { StyledBaseContainer, StyledHeaderTwo } from './component.styles'

const AdminHSSingleSiteView = ({ programKey, site }) => {
  if (!site || Object.keys(site).length === 0) {
    return null
  }
  const { preferences, siteId, siteName } = site || {}
  const { isLaunched, isSetup } = preferences || {}
  return (
    <>
      {isLaunched && isSetup ? (
        <StyledBaseContainer>
          <StyledHeaderTwo>{HEADER_TEXT}</StyledHeaderTwo>
          <HighSchoolLaunchSnapshot programKey={programKey} site={site} />
        </StyledBaseContainer>
      ) : (
        <AdminHSSetup
          preferences={preferences}
          programKey={programKey}
          showHairline={true}
          siteId={siteId}
          siteName={siteName}
        />
      )}
    </>
  )
}

AdminHSSingleSiteView.propTypes = {
  programKey: PropTypes.string,
  site: PropTypes.object,
}

export default AdminHSSingleSiteView
