import React from 'react'
import { InlineLoading } from 'secondstep-components'
import { SpinnerContainer } from './component.styles'

function Spinner() {
  return (
    <SpinnerContainer>
      <InlineLoading />
    </SpinnerContainer>
  )
}

export default Spinner
