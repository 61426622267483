import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { ButtonsWrapper } from '../component.styles'

export const ButtonsWrapperCancel = styled(ButtonsWrapper)`
  margin-top: 3.5rem;
  ${themeGet('breakpoints.mobile')} {
    margin-top: 2.875rem;
  }
`
