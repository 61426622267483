import React from 'react'
import PropTypes from 'prop-types'
import { TextLink } from 'secondstep-components'
import { TEACH, STREAMING_LESSON_MEDIA } from './constants.js'
import { SplitColumn, ViewWrapper } from '../LessonDetail/component.styles'
import { ButtonBox, H3, TeachButton } from './component.styles'

const Row = ({ index, name, navigationEvent, teachUrl, slmUrl }) => {
  const handleNavigationEvent = url => {
    navigationEvent && navigationEvent(url)
  }
  return (
    <ViewWrapper>
      <SplitColumn>
        <H3>{name}</H3>
      </SplitColumn>
      <SplitColumn>
        <ButtonBox>
          {slmUrl && (
            <TextLink
              dataTestId={`text-link-${index}`}
              onClick={() => handleNavigationEvent(slmUrl)}
            >
              {STREAMING_LESSON_MEDIA}
            </TextLink>
          )}
          <TeachButton
            dataTestId={`teach-button-${index}`}
            onClick={() => handleNavigationEvent(teachUrl)}
          >
            {TEACH}
          </TeachButton>
        </ButtonBox>
      </SplitColumn>
    </ViewWrapper>
  )
}

Row.propTypes = {
  index: PropTypes.number,
  name: PropTypes.string,
  navigationEvent: PropTypes.func,
  slmUrl: PropTypes.func,
  teachUrl: PropTypes.func,
}

export default Row
