import { createType, get } from '../../utils/apiHelpers'
import Auth0Manager from '../../utils/auth0Manager'
import { FAILURE_TYPE, REQUEST_TYPE, SUCCESS_TYPE } from './types'

export async function fetchUserProfile(dispatch, secondStepDomain) {
  const token = await Auth0Manager.getAccessToken()
  dispatch(getUserProfile(secondStepDomain, token))
}

export const getUserProfile = (secondStepDomain, token) => {
  const fullUrl = createEndpoint(secondStepDomain)
  const typesOrdered = [
    createType(REQUEST_TYPE),
    createType(SUCCESS_TYPE),
    createType(FAILURE_TYPE),
  ]
  return get(fullUrl, typesOrdered, token)
}

export const createEndpoint = secondStepDomain => {
  const method = `profile/me`
  const url = `${secondStepDomain}${method}`
  return url
}
