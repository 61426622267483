import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Hairline, TabButton, TabSet } from 'secondstep-components'
import { getTabButtonStyles } from './helper'

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
`

export const HeaderBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 65px);
  background: ${themeGet('colors.backgrounds.banners.primary', 'colors.white')};
  z-index: 1;
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
  box-sizing: border-box;
  padding: 3.625rem 1.25rem 0;
  z-index: 1;

  ${themeGet('breakpoints.mobileLarge')} {
    align-items: center;
    padding-top: 2.5rem;
  }
  h1 {
    color: ${themeGet('colors.heading.level.1')};
  }
`

export const StyledHairline = styled(Hairline)`
  margin-top: 1.25rem;
  z-index: 1;
  background-color: ${themeGet('colors.grayBlue')};
  height: 2px;
`

export const StyledTabButton = styled(TabButton).attrs(({ isActive }) => {
  const color = getTabButtonStyles(isActive, themeGet)
  const underlineBackgroundColor = isActive
    ? themeGet('colors.underline.backgroundColor.active')
    : themeGet('colors.underline.backgroundColor.notActive')
  const hoverColor = !isActive
    ? themeGet('colors.underline.backgroundColor.hover')
    : themeGet('colors.underline.backgroundColor.active')
  return {
    color,
    underlineBackgroundColor,
    hoverColor,
  }
})`
  margin-top: 1.75rem;
  [class*='componentstyles__Underline-sc'] {
    background-color: ${({ underlineBackgroundColor }) =>
      underlineBackgroundColor};
  }
  [class*='componentstyles__StyledTab-sc'] {
    color: ${({ color }) => color};
    &:hover {
      color: ${({ hoverColor }) => hoverColor};
    }
  }
`

export const StyledTabSet = styled(TabSet)`
  flex-wrap: wrap;
  height: auto;
  margin-bottom: 1.25rem;
  z-index: 1;

  [class*='StyledTabsHeader'] {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`
