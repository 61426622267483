import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, Tooltip, TooltipContentWrapper } from 'secondstep-components'

export const BrightSpotWrapper = styled.div.attrs(({ hasTooltip }) => ({
  bodyCopyMarginRight: hasTooltip ? '0.4375rem' : '0',
}))`
  svg {
    margin-top: 0.3125rem;
    width: 1.125rem;
    min-width: 1.125rem;
    height: 1rem;
    min-height: 1rem;
    margin-right: 0.325rem;
  }

  ${BodyCopy} {
    margin-right: ${({ bodyCopyMarginRight }) => bodyCopyMarginRight};
    color: ${themeGet('colors.darkGray')};

    strong {
      font-family: ${themeGet('fontFamilySemibold')};
    }
  }
`

export const StyledTooltip = styled(Tooltip)`
  width: 14px;
  ${TooltipContentWrapper} {
    display: flex;
    bottom: -7.875rem;
    padding: 0.75rem 1rem;
    border-radius: 0.3125rem;
    line-height: 1rem;
    ::after {
      content: ' ';
      width: 0.75rem;
      height: 0.75rem;
      position: absolute;
      bottom: 93%;
      left: 50%;
      background-color: ${themeGet('colors.white')};
      transform: translate(-50%) rotate(45deg);
    }
    svg {
      position: absolute;
      top: 5px;
    }
  }
  i.fas {
    color: ${themeGet('colors.blueGray')};
    font-size: 0.875rem;
  }
  ${BodyCopy} {
    color: ${themeGet('colors.darkGray')};
    margin-bottom: 0;
    p {
      font-size: 0.9125rem;
      line-height: 1rem;
      margin-block-end: 0;
      text-align: start;
    }
    strong {
      font-family: ${themeGet('fontFamilySemibold')};
      font-size: 0.9125rem;
      margin-left: 1.5625rem;
    }
  }
  @media (max-width: 575px) {
    ${TooltipContentWrapper} {
      left: -92px;
      ::after {
        left: 90%;
      }
    }
  }
  ${themeGet('breakpoints.mobile')} {
    display: none;
  }
`
