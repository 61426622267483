export const INITIAL_COURSE = {
  id: null,
  name: '',
}
export const INITIAL_LICENSE = {
  licenseId: null,
  product: null,
  siteName: null,
  grades: [],
}
export const MODAL_CONTENT_TYPES = {
  Cancel: 'Cancel',
  Create: 'Create',
  Delete: 'Delete',
  Edit: 'Edit',
  Error: 'Error',
  ErrorDetails: 'ErrorDetails',
  Success: 'Success',
  Loading: 'Loading',
}

export const MODAL_FLOW_TYPES = {
  Create: 'Create',
  Delete: 'Delete',
  Edit: 'Edit',
}
export const SEPARATOR = ' | '

export const PRODUCTTEACHPAGE = '/product/middle-school/lessons'
