import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Container = styled.div.attrs({
  'data-testid': 'license-option',
})`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  min-height: 2.28rem;
  padding-left: 0.875rem;
  padding-top: 0.1875rem;
`

export const Text = styled.span.attrs({
  'data-testid': 'license-option-text',
})`
  color: ${themeGet('colors.gray800')};
  font-size: 1rem;
`

export const TextBold = styled.span.attrs({
  'data-testid': 'license-option-text-bold',
})`
  font-weight: ${themeGet('font.molde.bold')};
`
