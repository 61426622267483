export const formatError = (error, shouldShowInModal, requestName) => {
  const { response } = error || {}
  let formattedError = {}
  if (shouldShowInModal) {
    formattedError.shouldShowInModal = shouldShowInModal
  }
  if (!response && error?.message) {
    const { name, message } = error || {}
    Object.assign(formattedError, {
      status: 500,
      message: message,
      name: name,
      requestName,
    })
    return formattedError
  }
  formattedError = { ...formattedError, ...response }
  return formattedError
}
