import React from 'react'
import PropTypes from 'prop-types'
import { getLessonStatusIcon } from './helpers'
import { Icon } from './component.styles'

function HighSchoolLessonStatusIcon({
  isComplete,
  // some lessons do not support skipped status, so assume false:
  isSkipped = false,
}) {
  const { altText, icon } = getLessonStatusIcon({ isComplete, isSkipped })

  return <Icon alt={altText} src={icon} />
}

HighSchoolLessonStatusIcon.propTypes = {
  isComplete: PropTypes.bool,
  isSkipped: PropTypes.bool,
}

export default HighSchoolLessonStatusIcon
