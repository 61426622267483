import { connect } from 'react-redux'
import { operations as reportsOperations } from 'store/reportsManager'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import ReportsModuleCard from './component'

export const mapStateToProps = (
  { reportsManager },
  { selectedModule, selectedTrack },
) => {
  const { isFetching, reports, selectedLicense } = reportsManager || {}
  const { user_details } = reports[selectedLicense] || []
  const { content_children } = selectedTrack || []
  const { id, units: contentfulUnits } = selectedModule || []
  const {
    content_children: reportingUnits,
    type,
    users_completed,
    users_in_progress,
    users_not_started,
  } = content_children?.find(item => item?.contentful_id === id) || {}

  const units = contentfulUnits?.map(
    ({ displayTitle, id: unitId, microlearnings }) => {
      const matchingUnit =
        reportingUnits?.find(
          reportingUnit => reportingUnit.contentful_id === unitId,
        ) || {}

      return {
        ...matchingUnit,
        displayTitle,
        microlearnings,
      }
    },
  )

  return {
    isFetching,
    moduleId: id,
    type,
    units,
    users_completed,
    user_details,
    users_in_progress,
    users_not_started,
  }
}

const mapDispatchToProps = {
  fetchReportsUserDetails: reportsOperations.fetchReportsUserDetails,
}

const mapper = entry => {
  const { displayTitle, label } = entry || {}

  return {
    moduleTitle: displayTitle,
    moduleLabel: label,
  }
}

const options = {
  entryIdProp: true,
  mapper,
  spread: true,
}

export default ContentEntryDataGetter(
  ReportsModuleCard,
  options,
  connect(mapStateToProps, mapDispatchToProps),
)
