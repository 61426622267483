import {
  AUDIENCE_SCHOOL_LEADER,
  AUDIENCE_STUDENT,
  AUDIENCE_EDUCATOR,
} from 'utils/highschoolHelpers'
import { PERIOD_PROGRESS, LESSON_INTERACTION_TYPE } from './constants'
import IconDefault from './assets/donestate_outlined.svg'
import IconComplete from './assets/donestate_filled_green.svg'
import IconCompleteHover from './assets/donestate_hover_white.svg'
import IconCompleteSome from './assets/donestate_some.svg'
import IconSkipped from './assets/donestate_skipped.svg'
import IconSkippedHover from './assets/donestate_skipped_hover.svg'

export function getLessonInteractionTypeFromActivityType(activityType) {
  switch (activityType) {
    case AUDIENCE_SCHOOL_LEADER:
      return LESSON_INTERACTION_TYPE.SCHOOLWIDE_PRACTICE
    case AUDIENCE_EDUCATOR:
      return LESSON_INTERACTION_TYPE.EDUCATOR_PRACTICE
    case AUDIENCE_STUDENT:
      return LESSON_INTERACTION_TYPE.STUDENT_ACTIVITY
    default:
      return null
  }
}

export function getInstructorDataForLessonActivityType(
  allHighSchoolData,
  activityType,
) {
  const lessonInteractionType = getLessonInteractionTypeFromActivityType(
    activityType,
  )
  if (!lessonInteractionType) {
    return null
  }

  for (const instructorDataType of Object.values(allHighSchoolData)) {
    if (
      instructorDataType?.lesson_interaction_types?.includes(
        lessonInteractionType,
      )
    ) {
      return instructorDataType
    }
  }

  return null
}

export function getMarkCompleteButtonIcons({
  isComplete,
  isDisabled,
  isEducatorPractice,
  // only needed when isEducatorPractice is true:
  educatorPracticeStatus = null,
}) {
  // if complete AND disabled, show the default icon below:
  if (isDisabled && !isComplete) {
    // no icons:
    return {
      icon: null,
      hoverIcon: null,
    }
  }
  if (isComplete) {
    if (isEducatorPractice) {
      if (educatorPracticeStatus === PERIOD_PROGRESS.SOME) {
        return {
          icon: IconCompleteSome,
          hoverIcon: IconCompleteHover,
        }
      } else if (educatorPracticeStatus === PERIOD_PROGRESS.SKIPPED) {
        return {
          icon: IconSkipped,
          hoverIcon: IconSkippedHover,
        }
      } else {
        return {
          icon: IconComplete,
          hoverIcon: IconCompleteHover,
        }
      }
    }
    return {
      icon: IconComplete,
      hoverIcon: IconComplete,
    }
  } else {
    return {
      icon: IconDefault,
      hoverIcon: IconDefault,
    }
  }
}

export function getCompletionCommentary({
  markCompleteContent,
  schoolName,
  completionDate,
  activityType,
  isComplete,
}) {
  const completionSchool = `${markCompleteContent?.completionSchool ||
    ''} ${schoolName}`
  if (isComplete) {
    // only show completion date for schoolwide activities:
    if (activityType === AUDIENCE_SCHOOL_LEADER) {
      return `${markCompleteContent?.completionDateDefault ||
        ''} ${completionDate}\n${completionSchool}`
    }
    return completionSchool
  }
  if (markCompleteContent?.completionHelperText) {
    return `${markCompleteContent.completionHelperText}\n${completionSchool}`
  }
  return completionSchool
}

export function getMarkCompleteButtonText({
  markCompleteContent,
  isComplete,
  isAdmin,
  periodProgress,
}) {
  if (isComplete) {
    if (periodProgress) {
      return (
        getEducatorProgressText({ markCompleteContent, periodProgress })
          ?.progressCompletionLabel || ''
      )
    }
    return markCompleteContent?.completionLabelDone || ''
  }
  if (isAdmin) {
    return markCompleteContent?.completionButtonLabelDefaultAdmin || ''
  }
  // assume any non-admin is an educator:
  return markCompleteContent?.completionButtonLabelDefaultEducator || ''
}

export function getEducatorModalOptions(completionProgressContent) {
  // assume the PERIOD_PROGRESS values are in the same order as the options:
  return Object.values(PERIOD_PROGRESS).map((value, index) => ({
    value,
    // example: index === 0 should pull from progress1Button in Contentful
    text: completionProgressContent?.[`progress${index + 1}Button`] || '',
  }))
}

export function getEducatorCompleteModalContent(markCompleteContent) {
  const { completionProgress: completionProgressContent } =
    markCompleteContent || {}
  return {
    modalCancelButtonText: completionProgressContent?.cancelButtonText || '',
    modalDescription: completionProgressContent?.modalDescription || '',
    // HACK: replace the newline character with line break so it gets rendered in markdown:
    modalHelperText: (completionProgressContent?.modalHelperText || '').replace(
      /\n/g,
      '<br>',
    ),
    modalSaveButtonText: completionProgressContent?.saveButtonText || '',
    modalTitle: completionProgressContent?.modalDisplayTitle || '',
    options: getEducatorModalOptions(completionProgressContent),
  }
}

export function getEducatorProgressText({
  markCompleteContent,
  periodProgress,
}) {
  // convert zero-indexed to one-indexed in Contentful:
  const index = Object.values(PERIOD_PROGRESS).indexOf(periodProgress) + 1
  const { completionProgress: completionProgressContent } =
    markCompleteContent || {}
  return {
    progressCompletionLabel:
      completionProgressContent?.[`progress${index}CompletionLabelDone`] || '',
    progressCompletionText:
      completionProgressContent?.[`progress${index}CompletionText`] || '',
  }
}
