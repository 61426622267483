import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  CompleteIcon,
  ContainedButton,
  HeaderThree,
} from 'secondstep-components'
import AdminTooltip from 'components/AdminTooltip'

export const CardWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${themeGet('colors.linkLight')};
  border: solid 2px ${themeGet('colors.paleGray')};
  border-radius: 0.625rem;
  padding: 2.5rem 1.75rem 1.25rem;
`

export const HeaderWrapper = styled.div`
  display: flex;
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
`

export const LabelWrapper = styled.div.attrs(props => {
  return {
    svgColor: props.isComplete
      ? themeGet('colors.hsPrimaryPink')
      : 'transparent',
  }
})`
  display: flex;

  svg {
    color: ${({ svgColor }) => svgColor};
    padding-top: 0.5rem;
    padding-right: 0.4375rem;

    circle {
      r: 9;
      stroke: ${themeGet('colors.hsPrimaryPink')};
    }
  }
`

export const ModalFlowWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const PacingTooltip = styled(AdminTooltip)`
  [class*='TooltipContentWrapper'] {
    top: -61px;
    left: 48px;

    ::after {
      bottom: 67%;
      left: 74px;
    }
  }
`

export const UserManagementTooltip = styled(AdminTooltip)`
  [class*='TooltipContentWrapper'] {
    top: -100px;
    left: 48px;

    ::after {
      bottom: 42%;
      left: 74px;
    }
  }
`

export const StepWrapper = styled.div`
  display: flex;
`

export const StyledCompleteIcon = styled(CompleteIcon)``

export const StyledContainedButton = styled(ContainedButton).attrs(props => {
  return {
    backgroundColor: props.isComplete
      ? themeGet('colors.linkLight')(props)
      : themeGet('colors.blueGray')(props),
    textColor: props.isComplete
      ? themeGet('colors.blueGray')(props)
      : themeGet('colors.linkLight')(props),
  }
})`
  background-color: ${({ backgroundColor }) => backgroundColor};
  color: ${({ textColor }) => textColor};
  width: 155px;
  padding: 0.6875rem 0.8125rem 0.5rem;
  margin-top: 0.75rem;
  margin-bottom: 1.25rem;
  text-align: center;
  text-transform: none;
  font-weight: normal;

  &:not([disabled]):hover {
    background-color: ${themeGet('colors.linkLight')};
    border-color: ${themeGet('colors.blue')};
    color: ${themeGet('colors.darkBlue')};
    box-shadow: none;
  }

  &:focus {
    background: ${themeGet('colors.linkLight')};
    border-color: ${themeGet('colors.darkBlue')};
    color: ${themeGet('colors.darkBlue')};
  }

  :disabled {
    color: ${themeGet('colors.linkLight')};
  }
`

export const StyledHeaderThree = styled(HeaderThree)`
  font-size: 1rem;
  font-size: 1.0625rem;
  font-family: ${themeGet('font.sharpSlab.semibold')};
  margin-top: 0.375rem;
  text-align: center;

  ${themeGet('breakpoints.tablet')} {
    font-size: 1rem;
    line-height: 1.0625rem;
    margin-bottom: 0.75rem;
  }
`

export const StyledImage = styled.img`
  height: 120px;
  width: 120px;
  border-radius: 10px;
  margin-bottom: 0.75rem;

  ${themeGet('breakpoints.tablet')} {
    margin-bottom: 1.1875rem;
  }
`

export const StyledLabel = styled.p`
  color: ${themeGet('colors.darkGray')};
  font-size: 14px;
  margin-top: 0.625rem;
  text-align: center;
`
