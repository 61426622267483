import { connect } from 'react-redux'

import ContentfulReducer from 'layers/content/store/reducer'

import ContentEntryDataGetter from '../ContentEntryDataGetter'

export default (component, options = {}, ...functions) => {
  const mapStateToProps = state => {
    const firstLevelNamespace = ContentfulReducer.ROOT_NAMESPACE

    const entryId =
      state &&
      state[firstLevelNamespace] &&
      state[firstLevelNamespace][ContentfulReducer.CONTENT_NODES] &&
      state[firstLevelNamespace][ContentfulReducer.CONTENT_NODES]['last'] &&
      state[firstLevelNamespace][ContentfulReducer.CONTENT_NODES]['last'][
        'entryId'
      ]
        ? state[firstLevelNamespace][ContentfulReducer.CONTENT_NODES]['last'][
            'entryId'
          ]
        : undefined

    const nodeSystemError =
      state &&
      state[firstLevelNamespace] &&
      state[firstLevelNamespace][ContentfulReducer.CONTENT_NODES] &&
      state[firstLevelNamespace][ContentfulReducer.CONTENT_NODES][
        'nodesSystemError'
      ]

    return {
      entryId,
      nodeSystemError,
    }
  }
  return ContentEntryDataGetter(
    component,
    options,
    connect(mapStateToProps),
    ...functions,
  )
}
