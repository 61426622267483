import styled from 'styled-components'
import { Image } from 'grommet'
import styledNative from 'styled-components/native'
import { themeGet } from 'styled-system'
import { HeaderTwo, BodyCopy, TextLink } from 'secondstep-components'

export const ContentCalloutContainer = styled.div.attrs(props => {
  const borderColor = themeGet('colors.borders.primary')(props)
  const border = themeGet('callouts.border')(props)
  if (border) {
    return { border }
  }
  return {
    border: borderColor && `2px solid ${borderColor}`,
  }
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  maxheight: 15.063rem;
  padding: 1.75rem;
  margin-bottom: 1.25rem;
  border: ${({ border }) => border};
  border-radius: 0.625rem;
  background-color: ${themeGet('callouts.backgroundColor', 'colors.white')};
  box-shadow: ${themeGet('callouts.boxShadow', 'none')};

  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 11.563rem;
  height: 11.563rem;
  margin-right: 1.75rem;

  ${themeGet('breakpoints.tablet')} {
    margin-right: 0;
    margin-bottom: 1.5rem;
    align-self: center;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    width: 13rem;
    height: 13rem;
    margin-bottom: 1.375rem;
  }

  ${themeGet('breakpoints.mobile')} {
    width: 100%;
    height: 100%;
  }
`

export const Icon = styled(Image).attrs(props => {
  const borderRadiusShorthand = themeGet(
    'resources.callouts.iconBorderRadius',
    '0.688rem 0.688rem 0.688rem 0.688rem',
  )(props)
  const [
    borderTopLeftRadius,
    borderTopRightRadius,
    borderBottomRightRadius,
    borderBottomLeftRadius,
  ] = borderRadiusShorthand.split(' ')
  const borderRadius = `${borderTopLeftRadius} ${borderTopRightRadius} ${borderBottomLeftRadius} ${borderBottomRightRadius}`
  return {
    borderRadius,
  }
})`
  width: 100%;
  border-radius: ${({ borderRadius }) => borderRadius};
`

export const TextContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`

export const ContentWrapper = styledNative.View`
  flex-direction: row;
`

export const HeaderText = styled(HeaderTwo)`
  color: ${themeGet('callouts.headerTextColor')};
  font-size: 1.75rem;
  line-height: 2rem;
  font-family: ${themeGet('callouts.headerTextFont')};
  margin-bottom: 0rem;
`

export const BodyText = styled(BodyCopy)`
  flex-wrap: wrap;
  flex: 1;
  font-size: 1rem;
  font-family: ${themeGet('fontFamily')};
  color: ${themeGet('callouts.bodyTextColor')};
  margin-top: 1rem;
`

export const TextLinkContainer = styled(TextLink)`
  cursor: pointer;
  font-size: 1rem;
  font-family: ${themeGet('fontFamilyBold')};
  color: ${themeGet('colors.links.primary')};
  margin-top: 1rem;
`

export const ColumnWrapper = styled.div`
  display: flex;
`
