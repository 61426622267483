import React from 'react'

const ProgressIndicatorSvg = () => (
  <svg height="22px" viewBox="0 0 12 22" width="12px">
    <title>ss_hs_progressindicator</title>
    <g
      fill="none"
      fillRule="evenodd"
      id="ss_hs_progressindicator"
      stroke="none"
      strokeWidth="1"
    >
      <path
        d="M0,0 L10.3916637,9.52569172 C11.2059017,10.2720765 11.2609075,11.5372113 10.5145227,12.3514493 C10.4753688,12.3941627 10.4343771,12.4351543 10.3916637,12.4743083 L0,22 L0,22 L0,0 Z"
        fill="#C62D70"
        id="Path-4"
      />
    </g>
  </svg>
)

export default ProgressIndicatorSvg
