import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import MspBullyingUnitNotification from './component'
import { MspBullyingUnitNotificationWrapper } from './component.styles'
import { getMSOptInData } from 'utils/siteData'

const MspBullyingUnitNotificationContainer = ({ mspSiteContexts }) => (
  <MspBullyingUnitNotificationWrapper>
    <MspBullyingUnitNotification mspSiteContexts={mspSiteContexts} />
  </MspBullyingUnitNotificationWrapper>
)

MspBullyingUnitNotificationContainer.propTypes = {
  mspSiteContexts: PropTypes.array,
}

const mapStateToProps = ({ licenseManager }) => ({
  mspSiteContexts: getMSOptInData(licenseManager.sites),
})

export default connect(
  mapStateToProps,
  {},
)(MspBullyingUnitNotificationContainer)
