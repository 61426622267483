import React from 'react'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { operations as lmsOperations } from 'store/lmsManager'
import { operations as licenseManagerOperations } from 'store/licenseManager'
import { operations as userSessionManagerOperations } from 'store/userSessionManager'
import ProfileDropdown from './component'
import getNavigationItems from './data'

export const ProfileDropdownContainer = React.forwardRef(
  (
    {
      navigateLeaveApp,
      navigateToPage,
      resetLicenseManagerState,
      resetLmsManagerState,
      resetUserSessionManagerState,
      show,
      theme,
    },
    ref,
  ) => {
    const navigationObject = { navigateLeaveApp, navigateToPage }
    const { profile } = useSelector(state => state?.userProfileManager) || {
      profile: {},
    }
    const { firstName, lastName, roles } = profile || {
      firstName: '',
      lastName: '',
      roles: [],
    }

    const {
      nameItem,
      navigationItems,
      accountItems,
      managementItems,
    } = getNavigationItems(navigationObject, roles, firstName, lastName)

    return (
      <ProfileDropdown
        accountItems={accountItems}
        managementItems={managementItems}
        nameItem={nameItem}
        profileItems={navigationItems}
        ref={ref}
        resetLicenseManagerState={resetLicenseManagerState}
        resetLmsManagerState={resetLmsManagerState}
        resetUserSessionManagerState={resetUserSessionManagerState}
        show={show}
        theme={theme}
      />
    )
  },
)

const mapDispatchToProps = {
  resetLmsManagerState: lmsOperations.resetState,
  resetLicenseManagerState: licenseManagerOperations.resetState,
  resetUserSessionManagerState: userSessionManagerOperations.resetState,
}

ProfileDropdownContainer.defaultProps = {
  navigateLeaveApp: () => {},
  navigateToPage: () => {},
  theme: {},
}

const BUTTONS_MAIN_THEME_SHAPE = PropTypes.shape({
  borderBase: PropTypes.string,
  borderRadiusBase: PropTypes.string,
  borderRadiusRound: PropTypes.string,
  borderRadiusSquare: PropTypes.string,
  fontSizeBase: PropTypes.string,
  fontWeightBase: PropTypes.string,
  paddingSize: PropTypes.shape({
    large: PropTypes.string,
    small: PropTypes.string,
    standard: PropTypes.string,
  }),
  startBtBorder: PropTypes.string,
  widthBase: PropTypes.string,
})

const BREAKPOINTS_MAIN_THEME_SHAPE = PropTypes.shape({
  large: PropTypes.string,
  medium: PropTypes.string,
  mobile: PropTypes.string,
  mobileLarge: PropTypes.string,
  sizes: PropTypes.shape({
    large: PropTypes.string,
    medium: PropTypes.string,
    mobile: PropTypes.string,
    mobileLarge: PropTypes.string,
    tablet: PropTypes.string,
  }),
  tablet: PropTypes.string,
  xlarge: PropTypes.string,
})

const MAIN_THEME_SHAPE = PropTypes.shape({
  breakpoints: BREAKPOINTS_MAIN_THEME_SHAPE,
  buttons: BUTTONS_MAIN_THEME_SHAPE,
  calendar: PropTypes.shape({
    day: PropTypes.shape({
      extend: PropTypes.func,
    }),
  }),
  cardBorder: PropTypes.string,
  cardBorderRadius: PropTypes.string,
  colors: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  ), /// type Colors = { [key: string]: string | Object }
  courseInstanceCardBorderRadius: PropTypes.string,
  font: PropTypes.shape({
    lufgaCFC: PropTypes.objectOf(PropTypes.string),
    molde: PropTypes.objectOf(PropTypes.string),
    sharpSlab: PropTypes.objectOf(PropTypes.string),
    size: PropTypes.shape({
      buttons: PropTypes.objectOf(PropTypes.string),
      headers: PropTypes.objectOf(PropTypes.string),
      text: PropTypes.objectOf(PropTypes.string),
    }),
  }),
  fontFamily: PropTypes.string,
  fontFamilyBold: PropTypes.string,
  fontFamilyHeading: PropTypes.string,
  fontFamilyHeadingBold: PropTypes.string,
  fontFamilyHeadingExtraBold: PropTypes.string,
  fontFamilySemibold: PropTypes.string,
  globalNav: PropTypes.objectOf(PropTypes.string),
  headerFontFamilyBold: PropTypes.string,
  lessons: PropTypes.objectOf(PropTypes.string),
  meta: PropTypes.objectOf(PropTypes.string),
  navTabLink: PropTypes.objectOf(PropTypes.string),
  padding: PropTypes.arrayOf(PropTypes.string),
  programNav: PropTypes.objectOf(PropTypes.string),
  spacing: PropTypes.objectOf(PropTypes.string),
  textFontFamily: PropTypes.string,
})

ProfileDropdownContainer.propTypes = {
  navigateLeaveApp: PropTypes.func,
  navigateToPage: PropTypes.func,
  resetLicenseManagerState: PropTypes.func,
  resetLmsManagerState: PropTypes.func,
  resetUserSessionManagerState: PropTypes.func,
  show: PropTypes.bool.isRequired,
  theme: MAIN_THEME_SHAPE,
}

export default connect(null, mapDispatchToProps, null, { forwardRef: true })(
  ProfileDropdownContainer,
)
