import PropTypes from 'prop-types'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  ModalContentWrapper,
  ModalHeader,
  ModalSubHeader,
  OutlinedButton,
} from 'secondstep-components'

export const modalSizes = {
  medium: {
    height: {
      desktop: `25.375rem`,
      mobile: `31.125rem`,
    },
    padding: {
      desktop: `1.125rem 4.5rem 5rem`,
      mobile: `1.125rem ${themeGet('spacing.turquoise20_1_25rem')} 5rem`,
    },
  },
  small: {
    height: {
      desktop: `auto`,
      mobile: `auto`,
    },
    padding: {
      desktop: `2.125rem 4.5rem 5rem`,
      mobile: `1.125rem ${themeGet('spacing.turquoise20_1_25rem')} 5rem`,
    },
  },
}

export const ModalWrapper = styled.div.attrs(({ dataTestId, left, size }) => ({
  'data-testid': dataTestId,
  size: size || 'medium',
}))`
  align-items: ${({ left }) => (left ? 'inherit' : 'center')};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: ${({ left }) => (left ? 'flex-start' : 'center')};
  min-height: ${({ size }) => modalSizes[size].height.desktop};
  padding: ${({ size }) => modalSizes[size].padding.desktop};
  text-align: ${({ left }) => (left ? 'left' : 'center')};
  ${themeGet('breakpoints.mobile')} {
    min-height: ${({ size }) => modalSizes[size].height.mobile};
    padding: ${({ size }) => modalSizes[size].padding.mobile};
    ${ModalContentWrapper} {
      padding: ${({ size }) => (size === 'medium' ? null : 0)};
    }
  }
`

ModalWrapper.propTypes = {
  cancel: PropTypes.bool,
  dataTestId: PropTypes.string.isRequired,
}

export const Header = styled(ModalHeader)`
  margin: 1.375rem 0 1.125rem;
  text-align: center;
  font-family: ${themeGet('fontFamilySemibold')};
  color: ${themeGet('colors.darkBlue')};
`

export const SubHeader = styled(ModalSubHeader)`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamilySemibold')};
  margin-bottom: ${themeGet('spacing.green4_0_25rem')};
`

export const ButtonsWrapper = styled.div.attrs(({ right }) => ({
  right,
}))`
  display: flex;
  position: absolute;
  bottom: ${themeGet('spacing.amber28_1_75rem')};
  left: ${props => (props.right ? `calc(50% - 28px)` : `50%`)};
  transform: translateX(-50%);
  justify-content: ${props => (props.right ? 'flex-end' : 'center')};
  margin-right: ${props =>
    props.right ? themeGet('spacing.amber28_1_75rem') : 0};
  margin-top: 3rem;
  width: 100%;
  button {
    &:not(:last-of-type) {
      margin-right: ${themeGet('spacing.blue16_1rem')};
    }
  }
  ${themeGet('breakpoints.mobile')} {
    left: 50%;
  }
`

export const CancelButton = styled(OutlinedButton)`
  color: ${themeGet('colors.buttons.secondaryText')};
  border: 2px solid ${themeGet('colors.buttons.secondaryOutline')};

  &:hover {
    color: ${themeGet('colors.buttons.primaryFont')} !important;
    background-color: ${themeGet('colors.darkBlue')} !important;
    border: 2px solid ${themeGet('colors.darkBlue')} !important;
  }
`

export const ModalWrapperInput = styled(ModalWrapper).attrs(() => ({
  left: true,
}))`
  padding: ${themeGet('spacing.red40_2_5rem')}
    ${themeGet('spacing.amber28_1_75rem')}
    ${themeGet('spacing.amber28_1_75rem')}
    ${themeGet('spacing.amber28_1_75rem')};
  width: 100%;
  ${themeGet('breakpoints.mobile')} {
    padding: ${themeGet('spacing.red40_2_5rem')}
      ${themeGet('spacing.amber28_1_75rem')} 5rem;
  }
`

export const ModalHeaderInput = styled(ModalHeader)`
  margin: 0;
  font-family: ${themeGet('font.sharpSlab.semibold')};
  color: ${themeGet('colors.darkBlue')};
`
