import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Image } from 'grommet'
import { BaseContainer } from 'secondstep-components'

export const StyledImage = styled(Image)``

export const AdvisoryActivityBox = styled(BaseContainer)`
  background-color: ${themeGet('colors.advisoryActivities.backgroundColor')};
  border-radius: 0.5625rem;
  flex: 1;
  margin-top: 1.25rem;
  position: relative;
  a,
  h6,
  svg {
    color: ${themeGet('colors.advisoryActivities.copy')};
  }
  h6 {
    line-height: 1.25rem;
    margin-bottom: 0.625rem;
  }
  a {
    line-height: 1.125rem;
    margin-bottom: 0.5625rem;
  }
  .icon {
    bottom: 1.25rem;
    position: absolute;
    right: 1.25rem;
  }
  &:not(:last-of-type) {
    margin-right: 1.25rem;
  }
  ${themeGet('breakpoints.tablet')} {
    &:not(:last-of-type) {
      margin-bottom: 1.25rem;
      margin-right: 0;
    }
  }
`

export const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  ${themeGet('breakpoints.tablet')} {
    flex-direction: row;
    a {
      margin-right: 1.25rem;
    }
  }
  ${themeGet('breakpoints.mobile')} {
    flex-direction: column;
  }
`
