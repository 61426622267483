const CURRENT_YEAR = new Date().getFullYear().toString()
export const COPYRIGHT_TEXT = `© 2012-${CURRENT_YEAR} Committee for Children.`
export const TRADEMARK_TEXT =
  'Second Step is a registered trademark of Committee for Children.'
export const CONTENTFUL_TEXT = 'SecondStep.org uses Contentful'
export const DIVIDER_CHARACTER = '|'

export const ABOUT_US = 'About Us'
export const PRIVACY_POLICY = 'Privacy Policy'
export const TERMS_OF_USE = 'Terms of Use'
export const LICENSE_AGREEMENTS = 'License Agreements'

export const SS_LOGO_LABEL = 'SSLogo'
export const ACCESSIBILITY = 'Accessiblility Statement'
