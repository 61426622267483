import { getNamespaceIdFromState } from './selectors'
import namespacePaths from './namespacePaths'

export function isAProductPath(path) {
  if (typeof path !== 'string') {
    return false
  }

  return path.includes('/product/')
}

export function isANamespacePath(path) {
  const isNamespacePath = namespacePaths.some(namespacePath =>
    path?.includes(namespacePath),
  )

  return isNamespacePath
}

export function isDesiredContentUpdate(entry, entryId, desiredEntryId) {
  return !entry || !entryId || entryId === desiredEntryId
}

export function verifyRouteIsOnEntry(entry, routeSlug) {
  const { routes = [], pageData } = entry
  const { routes: pageDataRoutes = [], route } = pageData || {}
  const isRoute = route === routeSlug
  // TODO: remove this isOldRoutes and pageDataRoutes
  const isOldRoutes = routes.includes(routeSlug)
  const isPageDataRoutes = pageDataRoutes.includes(routeSlug)

  const onAnyRouteOptions = isRoute || isOldRoutes || isPageDataRoutes
  return onAnyRouteOptions
}

export function verifyWeAreOnProperPath(pathname, blocklist) {
  const isABlocklistedPath = isOnPathBlocklist(pathname, blocklist)
  const isNamespacePath = isAProductPath(pathname) || isANamespacePath(pathname)

  if (
    pathname === undefined ||
    pathname === null ||
    !isNamespacePath ||
    isABlocklistedPath
  ) {
    return false
  }

  return true
}

export function verifyIfDesiredContentUpdate(
  appState,
  entry,
  actionEntryId,
  desiredNamespace,
) {
  const parentEntryId = getNamespaceIdFromState(appState, desiredNamespace)
  if (!parentEntryId) {
    return false
  }

  const isParentEntryId = isDesiredContentUpdate(
    entry,
    actionEntryId,
    parentEntryId,
  )
  if (!isParentEntryId) {
    return false
  }

  return true
}

export function verifyThatStartTreeIsNew(pathname, startPath) {
  if (!startPath && pathname) {
    return true
  }

  if (startPath && pathname) {
    if (startPath !== pathname) {
      return true
    }
  }

  return false
}

export function isLastRoute(path, pathLevel) {
  let modifiedPath = path
  if (path && path.length && path.length > 0) {
    const lastCharacterIndex = path.length - 1
    const lastCharacter = path[lastCharacterIndex]

    if (lastCharacter === '/') {
      modifiedPath = modifiedPath.slice(0, modifiedPath.length - 1)
    }

    if (modifiedPath.length > 0) {
      const pathSlugs = modifiedPath.split('/')
      const isRouteLastSlug = pathSlugs.length === pathLevel + 1

      return isRouteLastSlug
    }
  }

  return false
}

export function isRouteInPath(path, routeSlug) {
  const pathSlugs = path.split('/')
  const pathIndex = pathSlugs.indexOf(routeSlug)
  const inPath = pathIndex !== -1

  return inPath
}

export const isOnPathBlocklist = (path, blocklist) => {
  return blocklist.some(blocklistedPath => {
    // Allows for wildcard matching
    if (blocklistedPath.endsWith('*')) {
      return path.startsWith(blocklistedPath.slice(0, -1))
    }

    // Exact match
    return path === blocklistedPath
  })
}
