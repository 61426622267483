import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BaseContainer, WarningIcon } from 'secondstep-components'

export const Title = styled.span`
  font-family: ${themeGet('font.sharpSlab.semibold')};
  color: ${themeGet('colors.darkBlue')};
  margin-bottom: 0.625rem;
`

export const Description = styled.span`
  line-height: 20px;
  color: ${themeGet('colors.darkGray')};

  ${themeGet('breakpoints.mobile')} {
    margin-bottom: 8px;
  }
`

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;

  ${themeGet('breakpoints.mobile')} {
    width: 100%;
  }
`

export const DateWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;

  ${themeGet('breakpoints.mobile')} {
    width: 100%;
  }
`

export const DateTitle = styled.span`
  font-size: 0.75rem;
  line-height: 1.438rem;
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('font.molde.medium')};
`

export const StyledWarningIcon = styled(WarningIcon)`
  height: 9px;
  width: 9px;
  margin-right: 4px;

  circle:first-child {
    fill: ${themeGet('colors.errorRed')};
  }
`

export const Error = styled.div`
  display: flex;
  align-items: baseline;
  text-align: left;
  font-size: 12px;
  margin-top: 6px;
  color: ${themeGet('colors.errorRed')};
`

export const ErrorText = styled.span`
  line-height: 15px;
`

export const StyledContainer = styled(BaseContainer).attrs(() => ({
  as: 'section',
}))`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 14px 20px 16px;
  margin-top: 1.25rem;
  background-color: ${themeGet('colors.paleGray')};
  min-width: 560px;
  width: 100%;

  ${themeGet('breakpoints.mobile')} {
    min-width: 0;
    flex-direction: column;
  }
`
