import React, { Component } from 'react'
import ParseEntry from './data'
import AdvisoryActivitiesContent from './component'

export default class AdvisoryActivitiesContentContainer extends Component {
  mapper = entry => {
    if (entry) {
      const { details, id, instructions, overview } = ParseEntry(entry)
      entry = { details, id, instructions, overview }

      return entry
    }

    return null
  }

  render() {
    const { entry } = this.props || {}
    const parsedEntry = this.mapper(entry) || {}

    return <AdvisoryActivitiesContent {...parsedEntry} />
  }
}
