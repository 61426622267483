import React from 'react'
import PropTypes from 'prop-types'
import { formatCountLabel, formatSchoolCounts } from 'utils/reportsHelpers'
import K8ReportingLeaderTools from 'components/K8ReportingLeaderTools'
import {
  ADMIN_PATH,
  DASHBOARD_BREADCRUMB,
  REPORT_SITE_PATH,
  SCHOOL_SUMMARY_BREADCRUMB,
} from './constants'
import {
  BottomWrapper,
  HeaderWrapper,
  LastUpdatedTooltip,
  LeaderToolsWrapper,
  PageHeaderWrapper,
  ProgressWrapper,
  StyledBodyCopy,
  StyledCopyTooltipWrapper,
  StyledHairline,
  StyledHeaderTwo,
  StyledLastUpdatedStatus,
  StyledReportsBrightSpot,
  TopWrapper,
  UserCountTooltip,
} from './component.styles'
import { BodyCopy } from 'secondstep-components'
import BackLink from 'components/BackLink'

const ReportsPageHeader = ({
  administratorsCount,
  hasClassesWithCompletedLessonLastPeriod,
  numClassesWithCompletedLessonLastPeriod,
  period,
  entry,
  gradeOrSiteName,
  isSchoolSummary,
  lastUpdatedAt,
  navigateTo,
  navigationEvent,
  selectedSite,
  teachersCount,
  tooltipText,
  totalClassesCount,
}) => {
  const pageHeaderText = isSchoolSummary
    ? formatSchoolCounts(totalClassesCount, teachersCount, administratorsCount)
    : `**${formatCountLabel(totalClassesCount, 'Class')}**`
  const breadcrumbText = isSchoolSummary
    ? DASHBOARD_BREADCRUMB
    : SCHOOL_SUMMARY_BREADCRUMB
  const urlText = isSchoolSummary
    ? ADMIN_PATH
    : `${REPORT_SITE_PATH}${selectedSite}`

  const handleBreadcrumbLink = () => {
    navigateTo(urlText)
  }

  const { schoolDataUpdatesTooltip, schoolUserCountsTooltip } = entry || {}

  return (
    <PageHeaderWrapper>
      <TopWrapper>
        <BackLink dataTestId="breadcrumb-link" onClick={handleBreadcrumbLink}>
          {breadcrumbText}
        </BackLink>
        <StyledCopyTooltipWrapper>
          <StyledLastUpdatedStatus
            dataTestId="last-updated-status"
            timestamp={lastUpdatedAt}
          />
          <LastUpdatedTooltip
            content={<BodyCopy>{schoolDataUpdatesTooltip}</BodyCopy>}
          >
            <i className="fas fa-question-circle" />
          </LastUpdatedTooltip>
        </StyledCopyTooltipWrapper>
      </TopWrapper>
      <BottomWrapper isSchoolSummary={isSchoolSummary}>
        <HeaderWrapper>
          <StyledHeaderTwo dataTestId="reports-page-title">
            {gradeOrSiteName}
          </StyledHeaderTwo>
          <StyledHairline />
          <ProgressWrapper>
            <StyledBodyCopy data-testid="page-header-text">
              {pageHeaderText}
            </StyledBodyCopy>
            {!!isSchoolSummary && (
              <UserCountTooltip
                content={<BodyCopy>{schoolUserCountsTooltip}</BodyCopy>}
                isSchoolSummary={isSchoolSummary}
              >
                <i className="fas fa-question-circle" />
              </UserCountTooltip>
            )}
          </ProgressWrapper>
          {hasClassesWithCompletedLessonLastPeriod && (
            <StyledReportsBrightSpot
              numClassesWithCompletedLessonLastPeriod={
                numClassesWithCompletedLessonLastPeriod
              }
              period={period}
              tooltipText={tooltipText}
              totalClassesCount={totalClassesCount}
            />
          )}
        </HeaderWrapper>
        <LeaderToolsWrapper isSchoolSummary={isSchoolSummary}>
          <K8ReportingLeaderTools
            entry={entry}
            isSchoolSummary={isSchoolSummary}
            navigationEvent={navigationEvent}
          />
        </LeaderToolsWrapper>
      </BottomWrapper>
    </PageHeaderWrapper>
  )
}

ReportsPageHeader.propTypes = {
  administratorsCount: PropTypes.number,
  classesTaught: PropTypes.number,
  entry: PropTypes.object,
  gradeOrSiteName: PropTypes.string,
  hasClassesWithCompletedLessonLastPeriod: PropTypes.bool,
  isSchoolSummary: PropTypes.bool,
  lastUpdatedAt: PropTypes.string,
  navigateTo: PropTypes.func,
  navigationEvent: PropTypes.func,
  numClassesWithCompletedLessonLastPeriod: PropTypes.number,
  period: PropTypes.number,
  selectedSite: PropTypes.number,
  teachersCount: PropTypes.number,
  tooltipText: PropTypes.string,
  totalClassesCount: PropTypes.number,
}

export default ReportsPageHeader
