import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy } from 'secondstep-components'

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  align-self: center;
  margin-bottom: 20px;

  ${themeGet('breakpoints.mobile')} {
    margin-top: 28px;
    margin-bottom: 8px;
    justify-content: space-around;
    width: 100%;
  }
`

export const ScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-items: flex-start;
  padding: 14px 20px 20px;
`

export const ScheduleCopy = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};
  text-align: center;
  margin-bottom: 2px;

  ${themeGet('breakpoints.mobile')} {
    text-align: start;
  }
`
