import React from 'react'
import PropTypes from 'prop-types'

import { StyledContainer, SchoolIcon } from './component.styles'

const CurrentSiteTile = props => {
  const { currentSite, handleClick } = props

  return (
    currentSite && (
      <StyledContainer onClick={handleClick}>
        <SchoolIcon />
        {currentSite}
      </StyledContainer>
    )
  )
}

CurrentSiteTile.propTypes = {
  currentSite: PropTypes.string.isRequired,
  handleClick: PropTypes.func,
}

export default CurrentSiteTile
