import React from 'react'
import PropTypes from 'prop-types'

import MsBHUMultiSiteModal from './component'
import { StatusOff, StatusOn, TableStyledCheckBox } from './component.styles'

const MsBHUMultiSiteListContainer = ({
  allSelected,
  changeBHUStatus,
  indeterminate,
  onCheckBoxClick,
  selectAllSites,
  selectedSiteIds,
  sites,
  sitesHavingBHUnitCount,
}) => {
  const columns = [
    {
      title: '',
      fieldValue: row => (
        <TableStyledCheckBox
          hideText={true}
          isChecked={selectedSiteIds.includes(row.siteId)}
          onChange={e => onCheckBoxClick(e, row.siteId)}
          text={row.siteId.toString()}
        />
      ),
      sort: false,
    },
    {
      title: 'School',
      fieldValue: 'name',
      cellClassName: 'text-center',
      sortValue: 'name',
      sort: true,
    },
    {
      title: 'Unit Access',
      fieldValue: row =>
        row.sitePreferences.mspDigital.bullyingHarassmentUnitEnabled ? (
          <StatusOn>ON</StatusOn>
        ) : (
          <StatusOff>OFF</StatusOff>
        ),
      sort: true,
      sortValue: row =>
        row.sitePreferences.mspDigital.bullyingHarassmentUnitEnabled,
    },
  ]

  return (
    <MsBHUMultiSiteModal
      allSelected={allSelected}
      changeBHUStatus={changeBHUStatus}
      columns={columns}
      indeterminate={indeterminate}
      listedSites={sites}
      selectAllSites={selectAllSites}
      selectedSchoolsCount={selectedSiteIds.length}
      sitesHavingBHUnitCount={sitesHavingBHUnitCount}
    />
  )
}

MsBHUMultiSiteListContainer.propTypes = {
  allSelected: PropTypes.bool,
  changeBHUStatus: PropTypes.func.isRequired,
  indeterminate: PropTypes.bool,
  onCheckBoxClick: PropTypes.func.isRequired,
  selectAllSites: PropTypes.func.isRequired,
  selectedSiteIds: PropTypes.arrayOf(PropTypes.number),
  sites: PropTypes.arrayOf(
    PropTypes.shape({
      siteId: PropTypes.number,
      name: PropTypes.string,
      sitePreferences: PropTypes.shape({
        mspDigital: PropTypes.shape({
          bullyingHarassmentUnitEnabled: PropTypes.bool,
        }),
      }),
    }),
  ),
  sitesHavingBHUnitCount: PropTypes.number,
}

export default MsBHUMultiSiteListContainer
