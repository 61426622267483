import React from 'react'
import PropTypes from 'prop-types'

import AdvisoryActivityCard from 'experienceComponents/AdvisoryActivityCard'
import BookDescription from 'experienceComponents/BookDescription'
import ButtonToggle from 'components/Teach/ButtonToggle'
import ContentBlock from 'components/ContentBlock'
import ContentTypeDescription from 'experienceComponents/ContentTypeDescription'
import MediaGroupingCard from 'experienceComponents/MediaGroupingCard'
import { contentfulImageShape } from 'utils/shapes'
import { toCamelCase } from 'utils/stringHelpers'

import {
  ContentCardListWrapper,
  ContentHeaderWrapper,
  ContentSetWrapper,
  ContentWrapper,
} from './component.styles'
import { contentComponentsProps, getLanguageToggleOptions } from './data'
import { LANGUAGE_TOGGLE_DESCRIPTION } from './constants'

class ContentCardList extends React.Component {
  static propTypes = {
    activeContent: PropTypes.object.isRequired,
    contentTypes: PropTypes.array.isRequired,
    descriptionImage: contentfulImageShape,
    descriptionPdf: PropTypes.array,
    getEntryWithNewLocale: PropTypes.func,
    languageToggle: PropTypes.object,
    locale: PropTypes.string,
    location: PropTypes.object.isRequired,
    navigationEvent: PropTypes.func.isRequired,
  }

  contentComponents = {
    advisoryActivity: AdvisoryActivityCard,
    book: BookDescription,
    contentBlock: ContentBlock,
    multimediaGrouping: MediaGroupingCard,
  }

  getContentComponents() {
    const { activeContent = {} } = this.props
    const { content: contentsList = [] } = activeContent || {}

    if (!contentsList || contentsList.length <= 0) {
      return null
    }

    return contentsList.map((contentItem, index) => {
      return this.getContentComponent(contentItem, index)
    })
  }

  getContentComponent(contentItem, index) {
    const { locale: cardlistLocale } = this.props || {}
    const { contentType, id } = contentItem || {}
    const name = contentType?.id || toCamelCase(contentType)
    const { location, navigationEvent } = this.props

    const ContentComponent = this.contentComponents[name] || null

    if (!ContentComponent) return null

    const componentProps =
      (contentComponentsProps[name] &&
        contentComponentsProps[name](contentItem)) ||
      null

    return (
      <ContentComponent
        {...componentProps}
        index={index}
        key={id ?? `content-${index}`}
        locale={cardlistLocale}
        location={location}
        navigationEvent={navigationEvent}
      />
    )
  }

  render() {
    const {
      activeContent = {},
      contentTypes = [],
      descriptionImage,
      descriptionPdf,
      getEntryWithNewLocale,
      languageToggle,
      locale,
    } = this.props

    const shouldRenderTabs = contentTypes.length > 1
    const { description, id, displayTitle } = activeContent

    const languageToggleOptions = getLanguageToggleOptions(languageToggle)
    const hasLanguageToggle = languageToggleOptions && getEntryWithNewLocale

    return (
      <ContentCardListWrapper multiGroupMargin={shouldRenderTabs}>
        <ContentSetWrapper multiGroupMargin={shouldRenderTabs}>
          <ContentTypeDescription
            body={description}
            descriptionImage={descriptionImage}
            descriptionPdf={descriptionPdf}
            id={id}
            title={displayTitle}
          />
          <ContentWrapper>
            {hasLanguageToggle && (
              <ContentHeaderWrapper>
                <ButtonToggle
                  description={LANGUAGE_TOGGLE_DESCRIPTION}
                  onSelect={getEntryWithNewLocale}
                  options={languageToggleOptions}
                  value={locale}
                />
              </ContentHeaderWrapper>
            )}
            {this.getContentComponents()}
          </ContentWrapper>
        </ContentSetWrapper>
      </ContentCardListWrapper>
    )
  }
}

export default ContentCardList
