import React, { Component } from 'react'
import PropTypes from 'prop-types'

import NavigationHelper from 'layers/navigation/navigationHelper'

import { NavTabLink, NavTabSet } from './component.styles'

class ProgramNav extends Component {
  onClick = route => {
    const { goToProgram, location } = this.props
    const { pathname } = location || {}
    const isHighSchool = pathname.includes('high-school')
    const routeDepth =
      !isHighSchool && pathname.includes('family-resources') ? 1 : 2
    const fullRoute = NavigationHelper.generateUrlWithDepthForRoute(
      pathname,
      route,
      routeDepth,
    )
    goToProgram && goToProgram(fullRoute)
  }

  render() {
    const { programs } = this.props
    if (!programs) {
      return null
    }
    return (
      <NavTabSet data-testid="program-nav-tabset">
        {programs.map((program, i) => {
          const { isSelected, route, title } = program || {}

          if (!route || !title || !program) {
            return null
          }

          const unitNumber = i + 1
          const isActive = isSelected || false
          const unitId = `${title}`
          const dataTestId = `nav-tab-${unitId}-${unitNumber}`
          const search = location?.search
          return (
            <NavTabLink
              dataTestId={dataTestId}
              isActive={isActive}
              key={dataTestId}
              onClick={() => {
                this.onClick(route + search)
              }}
            >
              {title}
            </NavTabLink>
          )
        })}
      </NavTabSet>
    )
  }
}

ProgramNav.propTypes = {
  goToProgram: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
  programs: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      route: PropTypes.string,
      isSelected: PropTypes.bool,
      entryId: PropTypes.string,
    }),
  ),
}

export default ProgramNav
