import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import GradeProgressData from './component'
import { ALL_LESSONS_KEY } from './constants'

const GradeProgressDataContainer = ({
  bhuStatus,
  classesNotStarted,
  grade,
  lessonsCompleted,
  lessonSelectedRow,
  numOfClasses,
  siteId,
  totalLessons,
}) => {
  const selectedTab = lessonSelectedRow?.selectedUnitTab ?? ALL_LESSONS_KEY
  return (
    <GradeProgressData
      bhuStatus={bhuStatus}
      classesNotStarted={classesNotStarted}
      grade={grade}
      key={`grade-${grade}-progress`}
      lessonsCompleted={lessonsCompleted}
      numOfClasses={numOfClasses}
      selectedTab={selectedTab}
      siteId={siteId}
      totalLessons={totalLessons}
    />
  )
}

const mapStateToProps = ({
  userSessionManager: {
    adminDashboard: { lessons },
  },
}) => {
  return {
    lessonSelectedRow: lessons.lessonSelectedRow,
  }
}

GradeProgressDataContainer.propTypes = {
  bhuStatus: PropTypes.bool,
  classesNotStarted: PropTypes.number,
  grade: PropTypes.string,
  lessonSelectedRow: PropTypes.object,
  lessonsCompleted: PropTypes.number,
  numOfClasses: PropTypes.number,
  siteId: PropTypes.number,
  totalLessons: PropTypes.number,
}

export default connect(mapStateToProps, null)(GradeProgressDataContainer)
