import React from 'react'
import PropTypes from 'prop-types'
import { Loading } from 'secondstep-components'

import { ModalWrapper } from '../component.styles.js'

const LoadingModal = ({ dataTestId }) => {
  return (
    <ModalWrapper dataTestId={dataTestId}>
      <Loading background={false} />
    </ModalWrapper>
  )
}

LoadingModal.propTypes = {
  dataTestId: PropTypes.string,
}

export default LoadingModal
