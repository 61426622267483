import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Image as StyledImage } from 'grommet'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 12px;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  background-color: ${themeGet('colors.white')};

  ${themeGet('breakpoints.tablet')} {
    .thumbnail-wrapper {
      display: inline;
    }
  }
  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;
    align-items: center;
    .thumbnail-wrapper {
      display: flex;
      width: 100%;
    }
  }
`
export const Image = styled(StyledImage).attrs(() => ({
  align: 'center',
  fill: 'vertical',
  fit: 'contain',
}))``

export const Thumbnail = styled.div`
  display: flex;
  flex: 1;
  align-self: flex-start;
  max-height: ${({ height }) => height}px;
  height: ${({ height }) => height}px;
  min-width: ${({ width }) => width}px;
  max-width: 25%;
  border-radius: 0.563rem;
  background: url(${({ src }) => src}) no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid ${themeGet('colors.gray500')};
  margin-right: 1.25rem;

  ${themeGet('breakpoints.mobileLarge')} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 1.25rem;
    align-self: center;
  }
`

export const Padded = styled.div`
  width: 1.25rem;
`
