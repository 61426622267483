import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, HeaderOne, TextLink } from 'secondstep-components'

const breakpoints = {
  belowTablet: '@media (max-width: 650px)',
}

export const BannerWrapper = styled.div.attrs(({ isHsSupports }) => ({
  paddingTop: isHsSupports
    ? themeGet('spacing.amber28_1_75rem')
    : themeGet('spacing.redTurquoise60_3_75rem'),
  paddingBottom: isHsSupports
    ? themeGet('spacing.size_5_75rem')
    : themeGet('spacing.redTurquoise60_3_75rem'),
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  padding-top: ${({ paddingTop }) => paddingTop};
  padding-left: ${themeGet('spacing.turquoise20_1_25rem')};
  padding-right: ${themeGet('spacing.turquoise20_1_25rem')};
  background-color: ${themeGet(
    'colors.backgrounds.banners.primary',
    'banners.landingBackgroundColor',
  )};
  background-image: url(${themeGet('banners.landingBackgroundImage')});
  background-position: ${themeGet('banners.landingBackgroundImageAlignment') ??
    'center'};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
`

export const BannerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${themeGet('banners.alignment', 'center')};
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
  padding: 0 ${themeGet('spacing.turquoise20_1_25rem')};
  box-sizing: border-box;
  position: relative;

  ${themeGet('breakpoints.mobileLarge')} {
    padding: 0;
    align-items: center;
  }

  ${themeGet('breakpoints.mobile')} {
    padding: 0;
  }
`

export const StyledTextLink = styled(TextLink)`
  position: absolute;
  top: -2.4375rem;
  left: ${themeGet('spacing.amber28_1_75rem')};
  color: ${themeGet('colors.darkGray')};

  &:hover {
    color: ${themeGet('colors.darkerGray')};
  }

  ${themeGet('breakpoints.large')} {
    left: 0;
  }
`

export const BannerText = styled(BodyCopy)`
  color: ${themeGet('banners.bannerTextColor')};
  max-width: 60%;
  font-size: 18px;
  text-align: ${themeGet('banners.textAlignment', 'center')};
  margin-bottom: ${themeGet('spacing.redTurquoise60_3_75rem')};

  ${breakpoints.belowTablet} {
    margin-bottom: ${themeGet('spacing.amber28_1_75rem')};
    max-width: 75%;
    font-size: ${themeGet('spacing.blue16_1rem')};
  }

  ${themeGet('breakpoints.mobileLarge')} {
    max-width: 100%;
    text-align: center;
  }
`

export const BannerHeader = styled(HeaderOne)`
  margin-bottom: ${themeGet('banners.headerMargin', 'spacing.amber28_1_75rem')};
  color: ${themeGet('banners.headerTextColor')};
  font-family: ${themeGet('banners.headerTextFont')};
  font-size: ${themeGet('banners.headerTextSize')};
  text-align: center;
`

export const SiteSelectorWrapper = styled.div`
  align-self: end;
  z-index: 1;
  position: static;
  margin-right: 7rem;
  ${themeGet('breakpoints.tablet')} {
    margin-right: 0;
  }
`
