import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { operations as userSessionOperations } from 'store/userSessionManager'
import { navigationEvent } from 'layers/navigation/store/operations'
import ImplementationChecklist from './component'
import { ADMIN_DASHBOARD_ENTRY_ID } from 'utils/entryIds'

export const ImplementationChecklistContainer = ({
  button,
  className,
  displayTitle,
  isMultiHsAdmin,
  items,
  navigationEvent,
  selectedProgram,
  selectedTab,
  setSelectedChecklistTab,
  tabContent,
}) => {
  if (!button || !tabContent) {
    return null
  }

  if (!selectedTab && selectedProgram.name === 'K8') {
    selectedTab = tabContent[0]?.internalKey
    setSelectedChecklistTab(selectedTab, selectedProgram.name)
  }

  if (!selectedTab && selectedProgram.name === 'HS') {
    selectedTab = tabContent[0]?.internalKey
    setSelectedChecklistTab(selectedTab, selectedProgram.name)
  }

  if (
    !isMultiHsAdmin &&
    selectedProgram.name === 'HS' &&
    selectedTab.includes('Multi')
  ) {
    selectedTab = tabContent[0]?.internalKey
    setSelectedChecklistTab(selectedTab, selectedProgram.name)
  }

  if (
    isMultiHsAdmin &&
    selectedProgram.name === 'HS' &&
    selectedTab.includes('Single')
  ) {
    selectedTab = tabContent[0]?.internalKey
    setSelectedChecklistTab(selectedTab, selectedProgram.name)
  }

  return (
    <ImplementationChecklist
      button={button}
      className={className}
      displayTitle={displayTitle}
      items={items}
      navigationEvent={navigationEvent}
      selectedProgram={selectedProgram}
      selectedTab={selectedTab}
      setSelectedChecklistTab={tab => {
        setSelectedChecklistTab(tab, selectedProgram.name)
      }}
      tabContent={tabContent}
    />
  )
}

export const mapStateToProps = ({
  contentful,
  reportsManager,
  userSessionManager: { adminDashboard },
}) => {
  const { programs, selectedProgram } = reportsManager || {}
  const { hsSelectedTab, items, msSelectedTab } =
    adminDashboard?.checklist || {}
  const { actionPanel } = contentful?.[ADMIN_DASHBOARD_ENTRY_ID]?.payload || []

  const isK8AdminDashboardUrl = selectedProgram.name === 'K8'

  let implementationChecklist
  if (isK8AdminDashboardUrl) {
    implementationChecklist = actionPanel?.find(panel => {
      const { audiences } = panel || {}
      const { programs } = audiences
      return (
        programs.includes('k5SelDigital') && programs.includes('mspDigital')
      )
    })
  } else if (programs.HS.length > 1) {
    implementationChecklist = actionPanel?.find(panel => {
      const { audiences, singleMultiSite } = panel || {}
      const { programs } = audiences
      return programs.includes('highSchool') && singleMultiSite === false
    })
  } else {
    implementationChecklist = actionPanel?.find(panel => {
      const { audiences, singleMultiSite } = panel || {}
      const { programs } = audiences
      return programs.includes('highSchool') && singleMultiSite === true
    })
  }

  const { button, displayTitle, tabContent } = implementationChecklist || {}
  const selectedTab =
    selectedProgram.name === 'HS' ? hsSelectedTab : msSelectedTab
  const isMultiHsAdmin = programs?.HS?.length > 1

  return {
    button,
    displayTitle,
    isMultiHsAdmin,
    items,
    selectedProgram,
    selectedTab,
    tabContent,
  }
}

const mapDispatchToProps = {
  setSelectedChecklistTab: userSessionOperations.adminSetSelectedChecklistTab,
  navigationEvent,
}

ImplementationChecklistContainer.propTypes = {
  button: PropTypes.object,
  className: PropTypes.string,
  displayTitle: PropTypes.string,
  hsSelectedTab: PropTypes.string,
  isMultiHsAdmin: PropTypes.bool,
  items: PropTypes.object,
  msSelectedTab: PropTypes.string,
  navigationEvent: PropTypes.func,
  selectedProgram: PropTypes.object,
  selectedTab: PropTypes.string,
  setSelectedChecklistTab: PropTypes.func,
  tabContent: PropTypes.array,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ImplementationChecklistContainer)
