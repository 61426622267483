import React from 'react'
import PropTypes from 'prop-types'

import { toKebabCase } from 'utils/stringHelpers'

import ImageFullWidth from 'experienceComponents/ImageFullWidth'

import {
  Container,
  ThumbnailContainer,
  ThumbnailText,
} from './component.styles'

const ImageThumbnailButton = ({
  description,
  thumbnailUrl,
  imageUrl,
  title = '',
}) => {
  const imageFullWidthAvailable = thumbnailUrl && imageUrl
  const dataTestId = title ? 'title-' + toKebabCase(title) : 'title'

  return (
    <Container>
      <ThumbnailContainer>
        {imageFullWidthAvailable && (
          <ImageFullWidth
            description={description}
            id={dataTestId}
            imageThumbnailUrl={thumbnailUrl}
            imageUrl={imageUrl}
            title={title}
          />
        )}
      </ThumbnailContainer>
      {title && <ThumbnailText>{title}</ThumbnailText>}
    </Container>
  )
}

ImageThumbnailButton.propTypes = {
  description: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
  thumbnailUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default ImageThumbnailButton
