import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Wrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin: 2.5rem 0;
  ${themeGet('breakpoints.mobileLarge')} {
    margin-bottom: 1rem;
`

export const Row = styled.div`
  display: flex;
  height: 100%;
  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
  }
`

export const Column = styled.div`
  div[data-testid='ratio-wrapper-content'] {
    margin: 0;
  }
  p,
  ul,
  li {
    margin: 0;
  }
  width: 50%;
  .azuremediaplayer {
    border-radius: 0.625rem;
  }
  .vjs-control-bar {
    border-radius: 0.625rem;
  }
  .vjs-tech {
    border-radius: 0.625rem;
  }
  &:last-child {
    margin-left: 1.75rem;
  }
  ${themeGet('breakpoints.tablet')} {
    &:last-child {
      margin-top: 0.8rem;
      margin-left: 0;
    }
    &:first-child {
      height: 100%;
    }

    width: 100%;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    ul {
      margin-block-end: 1rem;
    }
`
