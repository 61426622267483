import React from 'react'
import PropTypes from 'prop-types'
import {
  BodyCopy,
  ContainedButton,
  HeaderFive,
  OutlinedButton,
  RightArrowIcon,
  TextLink,
  YieldIcon,
} from 'secondstep-components'

import NavigationHelper from 'layers/navigation/navigationHelper'
import ExpiredLicensesList from 'components/ExpiredLicensesList'
import {
  ADMIN_HEADER_TITLE,
  ADMIN_MESSAGE,
  DISMISS_BUTTON_TEXT,
  LINK_HREF,
  LINK_TEXT,
  NON_ADMIN_HEADER_TITLE,
  RENEW_BUTTON_TEXT,
  RENEW_HREF,
  STAFF_MESSAGE,
} from './constants'
import {
  BottomWrapper,
  ButtonsWrapper,
  NotificationWrapper,
  TextWrapper,
  TopWrapper,
} from './component.styles'

const ExpiredLicensesNotification = ({
  licenses,
  isAdmin,
  navigationEvent,
  setNotificationAsRead,
}) => {
  if (!licenses) return null

  const dismissClickHandler = notificationName => {
    setNotificationAsRead(notificationName)
  }

  const renewClickHandler = () => {
    navigationEvent(RENEW_HREF, NavigationHelper.types.NEW_TAB)
  }

  const AdminButtons = () => (
    <ButtonsWrapper>
      <OutlinedButton
        onClick={() => dismissClickHandler('adminNotificationsExist')}
      >
        {DISMISS_BUTTON_TEXT}
      </OutlinedButton>
      <ContainedButton onClick={renewClickHandler}>
        {RENEW_BUTTON_TEXT}
      </ContainedButton>
    </ButtonsWrapper>
  )

  const NonAdminButtons = () => (
    <ButtonsWrapper>
      <ContainedButton
        onClick={() => dismissClickHandler('nonAdminNotificationsExist')}
      >
        {DISMISS_BUTTON_TEXT}
      </ContainedButton>
    </ButtonsWrapper>
  )

  const footerMessage = isAdmin ? ADMIN_MESSAGE : STAFF_MESSAGE
  const headerTitle = isAdmin ? ADMIN_HEADER_TITLE : NON_ADMIN_HEADER_TITLE

  return (
    <NotificationWrapper data-testid="expired-license-notification">
      <TopWrapper>
        <YieldIcon color="#E1523D" />
        <TextWrapper>
          <HeaderFive>{headerTitle}</HeaderFive>
          <ExpiredLicensesList expiredLicenses={licenses} />
          {isAdmin && (
            <TextLink href={LINK_HREF} target={'_self'}>
              {LINK_TEXT} <RightArrowIcon />
            </TextLink>
          )}
        </TextWrapper>
      </TopWrapper>
      <BottomWrapper>
        <BodyCopy>{footerMessage}</BodyCopy>
        <ButtonsWrapper>
          {isAdmin && <AdminButtons />}
          {!isAdmin && <NonAdminButtons />}
        </ButtonsWrapper>
      </BottomWrapper>
    </NotificationWrapper>
  )
}

ExpiredLicensesNotification.propTypes = {
  isAdmin: PropTypes.bool,
  licenses: PropTypes.array,
  navigationEvent: PropTypes.func,
  setNotificationAsRead: PropTypes.func,
}

export default ExpiredLicensesNotification
