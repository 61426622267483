export const createGradeOptions = array => {
  return array.map(({ gradeName }) => {
    return {
      key: gradeName,
      name: gradeName,
    }
  })
}

export const createSiteOptions = array => {
  return array.map(site => {
    return {
      key: site.id,
      name: site.name,
    }
  })
}
