import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { toKebabCase } from 'utils/stringHelpers'
import { DEFAULT_LOCALE } from 'layers/content/data'

import TextToggle from 'components/Teach/TextToggle'
import MaterialsList from '../MaterialsList'
import {
  EmphasisHairline,
  StyledHeaderThree,
  ViewWrapper,
} from '../LessonDetail/component.styles'

import { PrimaryColumn, SecondaryColumn } from './component.styles'
import { getPdfLinksByLanguage, getLanguageOptions } from './data'
import { LANGUAGE_TOGGLE_DESCRIPTION } from './constants'

const LessonMaterialsList = ({ materials, title }) => {
  const prepMaterialsPdfList = getPdfLinksByLanguage(materials)
  const languageOptions = getLanguageOptions(prepMaterialsPdfList) || []
  const defaultLanguage =
    languageOptions.length > 0 ? languageOptions[0].id : DEFAULT_LOCALE
  const hasLanguageToggle = languageOptions.length > 1
  const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage)

  const dataTestIdTitle = title ? toKebabCase(title) : 'title'
  const dataTestId = 'lesson-materials-list-' + dataTestIdTitle

  return (
    <>
      <ViewWrapper className={dataTestId} data-testid={dataTestId}>
        <PrimaryColumn>
          {title && <StyledHeaderThree>{title}</StyledHeaderThree>}
        </PrimaryColumn>
        <SecondaryColumn>
          {hasLanguageToggle && (
            <TextToggle
              description={LANGUAGE_TOGGLE_DESCRIPTION}
              onSelect={setCurrentLanguage}
              options={languageOptions}
              value={currentLanguage}
            />
          )}
        </SecondaryColumn>
      </ViewWrapper>
      <EmphasisHairline />
      <MaterialsList
        id={dataTestIdTitle}
        links={prepMaterialsPdfList[currentLanguage]}
      />
    </>
  )
}

LessonMaterialsList.propTypes = {
  materials: PropTypes.arrayOf(
    PropTypes.shape({
      internalTitle: PropTypes.string,
      displayTitle: PropTypes.string,
      file: PropTypes.shape({
        title: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  ).isRequired,
  title: PropTypes.string,
}

export default LessonMaterialsList
