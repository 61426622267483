import React from 'react'
import PropTypes from 'prop-types'
import { ProgramThemeLink } from 'secondstep-components'
import { StyledList } from './component.styles'

export const LinkList = ({ programThemes }) => (
  <StyledList>
    {programThemes.map(({ programTheme }) => (
      <li key={programTheme}>
        <ProgramThemeLink
          dataTestId={`${programTheme}-link`}
          programTheme={programTheme}
        >
          {programTheme}
        </ProgramThemeLink>
      </li>
    ))}
  </StyledList>
)

LinkList.propTypes = {
  programThemes: PropTypes.array.isRequired,
}

export default LinkList
