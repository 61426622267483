import React from 'react'
import PropTypes from 'prop-types'
import { TextLink } from 'secondstep-components'
import { pluralizeEnglish } from 'utils/stringHelpers'
import { TOOLTIP_LOCATIONS, TOOLTIP_PROGRAMS } from 'utils/tooltipHelpers'
import AdminTooltip from 'components/AdminTooltip'
import {
  Bullet,
  Card,
  CardHeader,
  CountDetail,
  HeaderLabel,
  Separator,
  Summary,
  SummaryDetail,
  SummaryTotal,
  Total,
} from './component.styles'
import {
  LICENSE_MANAGEMENT_HREF,
  MANY_USERS_DESCRIPTION_TEXT,
  SINGLE_USER_DESCRIPTION_TEXT,
} from './constants'

const AdminK8UsersCard = ({ className, countData, linkLabel, siteId }) => {
  const { administrators, teachers, totalUniqueUsers } = countData || {}
  const { active: activeAdmins, pending: pendingAdmins } = administrators || {}
  const { active: activeTeachers, pending: pendingTeachers } = teachers || {}
  const headerText =
    totalUniqueUsers === 1
      ? SINGLE_USER_DESCRIPTION_TEXT
      : MANY_USERS_DESCRIPTION_TEXT

  const usersManagementLink = siteId
    ? `${LICENSE_MANAGEMENT_HREF}/${siteId}`
    : LICENSE_MANAGEMENT_HREF
  return (
    <Card className={className}>
      <CardHeader>
        <Total>{totalUniqueUsers}</Total>
        <HeaderLabel>
          {headerText}
          <AdminTooltip
            className={className}
            location={TOOLTIP_LOCATIONS.LAUNCH_USERS_ADDED}
            programKey={TOOLTIP_PROGRAMS.K8}
          />
        </HeaderLabel>
      </CardHeader>

      <Separator />

      <Summary>
        <SummaryTotal>
          {pluralizeEnglish(
            activeAdmins + pendingAdmins,
            'Administrator',
            'Administrators',
          )}
        </SummaryTotal>
        <SummaryDetail>
          <CountDetail>
            <Bullet color="green" /> {activeAdmins} active
          </CountDetail>
          <CountDetail>
            <Bullet color="yellow" /> {pendingAdmins} pending
          </CountDetail>
        </SummaryDetail>
      </Summary>

      <Summary>
        <SummaryTotal>
          {pluralizeEnglish(
            activeTeachers + pendingTeachers,
            'Teacher',
            'Teachers',
          )}
        </SummaryTotal>
        <SummaryDetail>
          <CountDetail>
            <Bullet color="green" />
            {activeTeachers} active
          </CountDetail>
          <CountDetail>
            <Bullet color="yellow" /> {pendingTeachers} pending
          </CountDetail>
        </SummaryDetail>
      </Summary>

      <TextLink
        aria-label={linkLabel}
        dataTestId={`site-license-management-link`}
        href={usersManagementLink}
        target="_self"
      >
        Manage Users
      </TextLink>
    </Card>
  )
}

AdminK8UsersCard.propTypes = {
  className: PropTypes.string,
  countData: PropTypes.shape({
    administrators: PropTypes.shape({
      active: PropTypes.number,
      pending: PropTypes.number,
    }),
    teachers: PropTypes.shape({
      active: PropTypes.number,
      pending: PropTypes.number,
    }),
  }),
  linkLabel: PropTypes.string,
  siteId: PropTypes.number,
}

export default AdminK8UsersCard
