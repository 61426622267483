import React from 'react'
import PropTypes from 'prop-types'
import { CircleRightArrowIcon } from 'secondstep-components'
import { toUrlSafe } from 'utils/stringHelpers'
import { SelaTitle, StyledTextLink } from './component.styles'
import { superscriptRegistered } from '../helpers'

function ProgramTitle(props) {
  const {
    displayTitleLink,
    displayTitle,
    isSelaProduct,
    onComponentClick,
  } = props

  if (displayTitleLink) {
    const programTitleLinkTestId = `program-title-link-${toUrlSafe(
      displayTitle,
    )}`

    // SELA title is NOT a link and does not have the arrow icon:
    if (isSelaProduct) {
      return <SelaTitle>{superscriptRegistered(displayTitle)}</SelaTitle>
    }

    return (
      <StyledTextLink
        dataTestId={programTitleLinkTestId}
        href={displayTitleLink}
        onClick={e => {
          e.preventDefault()
          onComponentClick(displayTitleLink)
        }}
      >
        {superscriptRegistered(displayTitle)}
        {<CircleRightArrowIcon />}
      </StyledTextLink>
    )
  }

  return displayTitle
}

ProgramTitle.propTypes = {
  displayTitle: PropTypes.string.isRequired,
  displayTitleLink: PropTypes.string,
  isHighSchool: PropTypes.bool,
  isSelaProduct: PropTypes.bool,
  onComponentClick: PropTypes.func,
}

export default ProgramTitle
