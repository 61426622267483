import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import withApiError from 'layers/errorHandling/apiError/component'
import {
  ERROR_TYPE_CONTENTFUL,
  SHOW_CONTENT_ON_401,
} from 'layers/errorHandling/apiError'
import { selectors as contextSelectors } from 'auth/stores/userContext'
import {
  operations as siteOperations,
  selectors as siteSelectors,
  actions as siteActions,
} from 'store/siteManager'
import { Formik } from 'formik'
import { pacingNodesData, validationSchema } from './data'

import PacingModalComponent from './component'

export const PacingModalContainer = ({
  clearError,
  currentStep: initCurrentStep,
  hasApiError,
  handleClose,
  implementationLevel,
  modals,
  nodeData,
  pacingDates,
  schoolYearEndDateString,
  site,
  updateSitePacingDates,
}) => {
  const [currentStep, setCurrentStep] = useState(0)

  const trainingStartNode =
    pacingDates?.find(pace => pace.nodeLevel === 'training') || null
  const programStartNode =
    pacingDates?.find(pace => pace.nodeKey === nodeData.node1) || null
  const trainingStartDate = trainingStartNode
    ? new Date(trainingStartNode.startDate)
    : null
  const programStartDate = programStartNode
    ? new Date(programStartNode.startDate)
    : null
  const schoolYearEndDate = schoolYearEndDateString
    ? new Date(schoolYearEndDateString)
    : null

  const { formikDates, unmodifiedDates } = Object.keys(nodeData).reduce(
    (dates, nodeName) => {
      const nodeKey = nodeData[nodeName]
      const nodeValue = pacingDates?.find(pace => pace.nodeKey === nodeKey)
      if (nodeValue) {
        dates.formikDates[nodeName] = new Date(nodeValue.startDate)
        dates.unmodifiedDates[nodeName] = new Date(nodeValue.startDate)
      }
      return dates
    },
    {
      formikDates: {},
      unmodifiedDates: {
        trainingStartDate: trainingStartNode
          ? new Date(trainingStartNode.startDate)
          : null,
        programStartDate: programStartNode
          ? new Date(programStartNode.startDate)
          : null,
        schoolYearEndDate: schoolYearEndDateString
          ? new Date(schoolYearEndDateString)
          : null,
      },
    },
  )

  const hasInitialValues =
    !!trainingStartNode && !!programStartDate && !!schoolYearEndDate

  const formatValuesToPacingDates = values =>
    Object.keys(values)
      .filter(key => key.includes('node'))
      .map(nodeName => {
        const key = nodeData[nodeName]
        const nodeToUpdate = pacingDates.find(node => node.nodeKey === key)
        const newNode = {
          ...nodeToUpdate,
          startDate: values[nodeName],
        }
        delete newNode.endDate
        return newNode
      })

  const submitEditedDates = async values => {
    const pacingDateUpdate = {
      pacingDates: formatValuesToPacingDates(values),
      programStartDate: values.programStartDate,
      schoolYearEndDate: values.schoolYearEndDate,
      trainingDate: values.trainingStartDate,
    }
    await updateSitePacingDates(site?.id, 'highSchool', pacingDateUpdate)
  }

  const submitInitialDates = async (values, touched) => {
    const hasDateChanged =
      touched.programStartDate ||
      touched.schoolYearEndDate ||
      touched.trainingStartDate

    const pacingDateUpdate = {
      pacingDates: [],
      programStartDate: values.programStartDate,
      schoolYearEndDate: values.schoolYearEndDate,
      trainingDate: values.trainingStartDate,
    }
    if (hasDateChanged)
      await updateSitePacingDates(site?.id, 'highSchool', pacingDateUpdate)
  }

  const handleStepChange = toStep => setCurrentStep(toStep)
  const goBack = setFieldValue => {
    if (currentStep - 1 < 3) {
      setFieldValue('isEditingPacing', false)
    }
    handleStepChange(currentStep - 1)
  }

  const handleContinueButtonClick = async (values, touched, setFieldValue) => {
    if (currentStep + 1 === 2) await submitInitialDates(values, touched)
    if (currentStep + 1 === 4) {
      await submitEditedDates(values)
      handleStepChange(currentStep - 1)
      setFieldValue('isEditingPacing', false)
      return
    }

    handleStepChange(currentStep + 1)
    if (currentStep + 1 === 3) {
      setFieldValue('isEditingPacing', true)
    } else {
      setFieldValue('isEditingPacing', false)
    }
  }

  useEffect(() => {
    setCurrentStep(initCurrentStep || 0)
  }, [initCurrentStep])

  return (
    <Formik
      enableReinitialize={true}
      initialTouched={{
        trainingStartDate: !!trainingStartDate,
        programStartDate: !!programStartDate,
        schoolYearEndDate: !!schoolYearEndDate,
      }}
      initialValues={{
        trainingStartDate,
        programStartDate,
        schoolYearEndDate,
        implementationLevel,
        isEditingPacing: false,
        ...formikDates,
      }}
      isInitialValid={hasInitialValues}
      validationSchema={validationSchema}
    >
      {({
        values,
        errors,
        touched,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
        isValid,
      }) => (
        <PacingModalComponent
          currentStep={currentStep}
          errors={errors}
          goBack={() => goBack(setFieldValue)}
          handleChange={(name, value) => {
            setFieldTouched(name)
            setFieldValue(name, value)
          }}
          handleClose={() => {
            clearError('pacingDates')
            handleClose()
          }}
          handleContinueButtonClick={() =>
            handleContinueButtonClick(values, touched, setFieldValue)
          }
          handleStepChange={handleStepChange}
          handleSubmit={handleSubmit}
          hasApiError={hasApiError}
          hasInitialValues={hasInitialValues}
          implementationLevel={implementationLevel}
          isValid={isValid}
          modals={modals}
          touched={touched}
          unmodifiedDates={unmodifiedDates}
          values={values}
        />
      )}
    </Formik>
  )
}

const mapStateToProps = (state, ownProps) => {
  const context = contextSelectors.selectProgramContext(state)
  const contextType =
    context.contextType === 'highschool' ? 'highSchool' : context.contextType
  const site = siteSelectors?.selectSiteById(state, ownProps.siteId)
  const pacingDates =
    site?.pacing[contextType] || site?.pacing['highschool'] || []
  const implementationLevel =
    site?.sitePreferences?.[contextType]?.implementationLevel
  const nodeData = pacingNodesData[contextType] || []
  const { pacingDates: pacingDatesAPIResults } = state.siteManager
  const { error } = pacingDatesAPIResults

  return {
    hasApiError: !!error,
    implementationLevel,
    nodeData,
    pacingDates,
    schoolYearEndDateString: site?.schoolYearEndDate,
    site,
  }
}

const mapDispatchToProps = {
  updateSitePacingDates: siteOperations.updateSitePacingDates,
  clearError: siteActions.clearError,
}

PacingModalContainer.propTypes = {
  clearError: PropTypes.func,
  currentStep: PropTypes.number,
  dispatch: PropTypes.func,
  fetchUserSites: PropTypes.func,
  handleClose: PropTypes.func,
  hasApiError: PropTypes.bool,
  implementationLevel: PropTypes.number,
  modals: PropTypes.array,
  nodeData: PropTypes.object,
  pacingDates: PropTypes.array,
  schoolYearEndDateString: PropTypes.string,
  site: PropTypes.shape({
    address: PropTypes.object,
    id: PropTypes.number,
    isRostered: PropTypes.bool,
    name: PropTypes.string,
    netSuiteCustomerId: PropTypes.number,
    schoolYearEndDate: PropTypes.string,
    sitePreferences: PropTypes.object,
  }),
  siteId: PropTypes.number,
  updateSitePacingDates: PropTypes.func,
}

const mapper = entry => {
  const { modals } = entry || {}
  return {
    modals,
  }
}

const options = {
  entryId: '6PHmIWTvI3XGF6siazlac5',
  include: 3,
  mapper,
  spread: true,
}

export default ContentEntryDataGetter(
  withApiError(
    PacingModalContainer,
    [ERROR_TYPE_CONTENTFUL],
    [SHOW_CONTENT_ON_401],
  ),
  options,
  connect(mapStateToProps, mapDispatchToProps),
)
