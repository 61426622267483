import actions from './actions'
import api from 'lib/api/secondStepApi'
import { formatError } from 'utils/formatError'

const fetchValidateActivationCode = activationCode => {
  return async dispatch => {
    dispatch(actions.fetchingValidateActivationCode())
    try {
      let response
      if (activationCode?.startsWith('FAM')) {
        response = await api.getValidateAccessCode(activationCode)
      } else {
        response = await api.getValidatePak(activationCode)
      }
      dispatch(actions.fetchingValidateActivationCodeSuccess(response.data))
    } catch (error) {
      console.error(error)
      dispatch(actions.fetchingValidateActivationCodeError(formatError(error)))
    }
  }
}

const fetchSetupLicensesForUser = () => {
  return async dispatch => {
    dispatch(actions.fetchingSetupLicenses())

    try {
      const response = await api.getSetupLicensesForUser()
      dispatch(actions.fetchingSetupLicensesSuccess(response.data))
      return true
    } catch (error) {
      console.error(error)
      dispatch(actions.fetchingSetupLicensesError(formatError(error)))
      return false
    }
  }
}

const fetchActiveDigitalLicensesForUser = () => {
  return async dispatch => {
    dispatch(actions.fetchingActiveDigitalLicensesForUser())

    try {
      const [{ data: licenseData }, { data: siteData }] = await Promise.all([
        api.getUserLicenses(),
        api.getUserSites(), // NOTE: extra call to get site.claims for expired license notifications - b/c some pages are already behind feature flags
      ])
      dispatch(actions.fetchingUserLicensesSuccess(licenseData))
      dispatch(actions.fetchingUserSitesSuccess(siteData))
    } catch (error) {
      console.error(error)
      dispatch(
        actions.fetchingActiveDigitalLicensesForUserError(formatError(error)),
      )
    }
  }
}

const fetchUserLicenses = () => {
  return async dispatch => {
    dispatch(actions.fetchingUserLicenses())

    try {
      const { data } = await api.getUserLicenses()
      dispatch(actions.fetchingUserLicensesSuccess(data))
    } catch (error) {
      console.error(error)
      dispatch(actions.fetchingUserLicensesError(formatError(error)))
    }
  }
}

const fetchUserSites = () => {
  return async dispatch => {
    dispatch(actions.fetchingUserSites())

    try {
      const { data } = await api.getUserSites()
      dispatch(actions.fetchingUserSitesSuccess(data))
    } catch (error) {
      console.error(error)
      dispatch(actions.fetchingUserSitesError(formatError(error)))
    }
  }
}

const resetState = () => {
  return async dispatch => {
    dispatch(actions.resetState())
  }
}

const sendActivationCode = activationCode => {
  return async dispatch => {
    dispatch(actions.sendingActivationCode())

    try {
      if (!activationCode) throw new Error('Failed to send Activation Code')
      let response
      if (activationCode.startsWith('FAM')) {
        response = await api.postAccessCode(activationCode)
      } else {
        response = await api.postPakNumber(activationCode)
      }
      dispatch(
        actions.sendingActivationCodeSuccess({
          response: response.data,
          activationCode,
        }),
      )
      // TODO: Waiting Planet Express profile refresh function - Add Here
    } catch (error) {
      console.error(error)
      dispatch(actions.sendingActivationCodeError(formatError(error)))
    }
  }
}

const setNotificationAsRead = notificationName => {
  return async dispatch => {
    try {
      dispatch(actions.setNotificationAsReadSuccess(notificationName))
    } catch (error) {
      console.error(error)
      dispatch(actions.setNotificationAsReadError(formatError(error)))
    }
  }
}

export default {
  fetchValidateActivationCode,
  fetchActiveDigitalLicensesForUser,
  fetchSetupLicensesForUser,
  fetchUserLicenses,
  fetchUserSites,
  resetState,
  sendActivationCode,
  setNotificationAsRead,
}
