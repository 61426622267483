import { connect } from 'react-redux'
import DropdownMenu from './component'
import { operations as lmsManager } from 'store/lmsManager'
import { forwardRefWrapped } from 'utils/forwardRefWrapped'

const mapStateToProps = lmsManager => {
  const { isUpdating } = lmsManager || {}
  return { isUpdating }
}

const mapDispatchToProps = dispatch => ({
  updateCourseInstance: async updatedCourseInstance =>
    dispatch(lmsManager.updateCourseInstance(updatedCourseInstance)),
})

export default forwardRefWrapped(
  connect(mapStateToProps, mapDispatchToProps)(DropdownMenu),
)
