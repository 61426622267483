import React from 'react'
import PropTypes from 'prop-types'
import { Auth0Provider } from '@auth0/auth0-react'
import Auth0PropWrapper from '../components/Auth0PropWrapper'
import Auth0ErrorHandler from '../components/Auth0ErrorHandler'
import TimeoutHandler from '../components/TimeoutHandler'
import { getCookie } from '../utils/cookies'

function Auth0ProviderWrapper(props) {
  const onRedirectCallback = () => {
    const landingPath = getCookie('pathname')
    props.history.push(landingPath)
  }

  return (
    <Auth0Provider
      audience={props.audience}
      clientId={props.clientId}
      domain={props.domain}
      onRedirectCallback={onRedirectCallback}
      redirectUri={window.location.origin}
    >
      <Auth0ErrorHandler>
        <Auth0PropWrapper />
        <TimeoutHandler />
        {props.children}
      </Auth0ErrorHandler>
    </Auth0Provider>
  )
}

Auth0ProviderWrapper.propTypes = {
  audience: PropTypes.string.isRequired,
  children: PropTypes.object.isRequired,
  clientId: PropTypes.string.isRequired,
  domain: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
}

export default Auth0ProviderWrapper
