import React from 'react'
import PropTypes from 'prop-types'

import {
  DividerHairline,
  ListButtonPanel,
  ListContainer,
  ListTitle,
  ListTitlePanel,
  ListSubtitle,
  SelectAllInputSection,
  SelectAllSection,
  StyledAutoTable,
  StyledCheckBox,
  StyledStatusButton,
  StyledTableWrapper,
  StyledText,
} from './component.styles'

import { defaultSort } from '../constants'

const MsBHUMultiSiteList = ({
  allSelected,
  changeBHUStatus,
  columns,
  indeterminate,
  listedSites,
  selectAllSites,
  selectedSchoolsCount,
  sitesHavingBHUnitCount,
}) => (
  <ListContainer>
    <ListTitlePanel>
      <ListTitle>Unit Access</ListTitle>
      <ListSubtitle>
        <span> ON </span>
        for
        <span> {sitesHavingBHUnitCount} </span>
        of
        <span> {listedSites?.length} </span>
        Schools
      </ListSubtitle>
    </ListTitlePanel>
    <DividerHairline />
    <ListButtonPanel>
      <SelectAllSection>
        <StyledText>Select All</StyledText>
        <SelectAllInputSection>
          <StyledCheckBox
            dataTestId="msbhu-modal-select-all-checkbox"
            hideText={true}
            indeterminate={indeterminate}
            isChecked={allSelected}
            onChange={e => selectAllSites(e)}
            text="select all sites"
            type="checkbox"
          />
          <div>{selectedSchoolsCount} Schools selected </div>
        </SelectAllInputSection>
      </SelectAllSection>
      <div>
        <StyledStatusButton
          dataTestId="msbhu-modal-status-btn-off"
          disabled={selectedSchoolsCount === 0}
          onClick={() => changeBHUStatus(false)}
        >
          OFF
        </StyledStatusButton>
        <StyledStatusButton
          dataTestId="msbhu-modal-status-btn-on"
          disabled={selectedSchoolsCount === 0}
          onClick={() => changeBHUStatus(true)}
        >
          ON
        </StyledStatusButton>
      </div>
    </ListButtonPanel>
    <StyledTableWrapper>
      <StyledAutoTable
        columns={columns}
        defaultSort={defaultSort}
        filter={false}
        headThemeLight
        key="unassigned-users-autotable"
        rows={listedSites}
        sort
      />
    </StyledTableWrapper>
  </ListContainer>
)

MsBHUMultiSiteList.propTypes = {
  allSelected: PropTypes.bool,
  changeBHUStatus: PropTypes.func.isRequired,
  columns: PropTypes.array.isRequired,
  indeterminate: PropTypes.bool,
  listedSites: PropTypes.arrayOf(
    PropTypes.shape({
      siteId: PropTypes.number,
      name: PropTypes.string,
      sitePreferences: PropTypes.shape({
        mspDigital: PropTypes.shape({
          bullyingHarassmentUnitEnabled: PropTypes.bool,
        }),
      }),
    }),
  ),
  selectAllSites: PropTypes.func,
  selectedSchoolsCount: PropTypes.number,
  sitesHavingBHUnitCount: PropTypes.number,
}

export default MsBHUMultiSiteList
