import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { operations, selectors as lmsSelectors } from 'store/lmsManager'
import { selectors as contextSelectors } from 'auth/stores/userContext'
import { selectors as licenseSelectors } from 'store/licenseManager'
import {
  CourseManagerGetter,
  CourseManagerType,
} from 'layers/content/v2/Hocs/CourseManagerGetter'
import { navigationEvent } from 'layers/navigation/store/operations'
import { isPreviewMode } from 'utils/highschoolHelpers'
import MarkCompleteSection from './component'
import {
  PROGRAM_TRAINING_LESSON_TYPE,
  SCHOOLWIDE_TRAINING_LESSON_TYPE,
  CURRICULUM_TRAINING_LESSON_TYPE,
  MARK_COMPLETE_ID,
  MARK_COMPLETE_HASH,
} from './constants'
import {
  isAdmin,
  checkIfLessonIsComplete,
  findNode,
  formatDate,
  formatSchoolName,
} from './helpers'

function MarkCompleteSectionContainer({
  // TODO: pull in props for the schoolwide training
  completionButtonLabelDefaultAdmin,
  completionButtonLabelDefaultEducator,
  completionDateDefault,
  completionDateDone,
  completionHeader,
  completionLabelDone,
  completionSchool,
  courseManager,
  courseNodeId,
  fetchOrCreateInstructorProgramFlex,
  highSchoolInstructorData,
  hsActiveSites,
  navigationEvent,
  nextStepButtonLabel,
  nextStepHeader,
  nextStepRoute,
  onMarkComplete,
  userContext,
}) {
  const [lessonType, setLessonType] = useState('')
  const [registrationId, setRegistrationId] = useState('')
  const [leafNodeId, setLeafNodeId] = useState('')
  const [isAlreadyComplete, setIsAlreadyComplete] = useState(false)
  const [schoolName, setSchoolName] = useState(null)
  const [
    completionCommentaryComplete,
    setCompletionCommentaryComplete,
  ] = useState(null)
  const [completionCommentaryTodo, setCompletionCommentaryTodo] = useState(null)
  const [
    shoulDisplayCompletionDetails,
    setShoulDisplayCompletionDetails,
  ] = useState(false)

  useEffect(() => {
    if (lessonType && lessonType !== SCHOOLWIDE_TRAINING_LESSON_TYPE) {
      setShoulDisplayCompletionDetails(false)
      return
    }

    setShoulDisplayCompletionDetails(true)

    const currentSite = hsActiveSites.find(
      site => site?.id === userContext.hsActiveSiteId,
    )

    let completionTodo = ''

    setSchoolName(currentSite?.name)
    if (!!completionSchool && schoolName) {
      completionTodo = `${completionSchool} ${formatSchoolName(schoolName)}\n`
    }

    if (completionDateDefault) {
      completionTodo += completionDateDefault
    }

    setCompletionCommentaryTodo(completionTodo)
  }, [lessonType, hsActiveSites, userContext])

  useEffect(() => {
    // fake the scroll to hash because we're using a navigation event, not a real a href attribute:
    if (window?.location?.hash === MARK_COMPLETE_HASH) {
      setTimeout(() => {
        const markCompleteSection = document.getElementById(MARK_COMPLETE_ID)
        markCompleteSection?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
      }, 100) // add a 100ms delay to allow the page to fully render before scrolling
    }
  }, [])

  useEffect(() => {
    function updateLessonData(instructorData) {
      setRegistrationId(instructorData?.registration_id)

      const { isComplete, date } = checkIfLessonIsComplete(
        instructorData?.lesson_nodes || [],
        courseNodeId,
      )

      setIsAlreadyComplete(isComplete)

      let completionComentary = ''

      if (!!completionSchool && !!schoolName) {
        completionComentary = `${completionSchool} ${formatSchoolName(
          schoolName,
        )}\n`
      }

      if (!!completionDateDone && !!date) {
        completionComentary += `${completionDateDone} ${formatDate(date)}.`
      }

      setCompletionCommentaryComplete(completionComentary)
    }

    if (
      (lessonType && lessonType !== SCHOOLWIDE_TRAINING_LESSON_TYPE) ||
      (lessonType === SCHOOLWIDE_TRAINING_LESSON_TYPE && !schoolName)
    ) {
      return
    }

    if (!courseNodeId) {
      console.warn(
        'courseNodeId must be set in order to use MarkCompleteSectionContainer',
      )
      return
    }
    // TODO: in the future this component may be used for more than just high school:
    if (!courseManager?.courseTrees?.highschool) {
      return
    }
    if (!highSchoolInstructorData) {
      return
    }

    const courseTreeNode = findNode(
      courseManager.courseTrees.highschool,
      courseNodeId,
    )
    if (!courseTreeNode) {
      console.warn(
        `courseNodeId ${courseNodeId} not found in courseManager.courseTrees.highschool`,
      )
      return
    }
    setLessonType(courseTreeNode.type)
    setLeafNodeId(courseTreeNode.leafNodeId)
    switch (courseTreeNode.type) {
      // these two training types use the same registration_id:
      case PROGRAM_TRAINING_LESSON_TYPE:
      case CURRICULUM_TRAINING_LESSON_TYPE:
        updateLessonData(highSchoolInstructorData?.educator)
        break
      case SCHOOLWIDE_TRAINING_LESSON_TYPE:
        updateLessonData(highSchoolInstructorData?.site)
        break
    }
  }, [courseManager, schoolName, highSchoolInstructorData])

  async function handleToggleComplete(isComplete) {
    if (isComplete) {
      // Base64 encode the userContext
      const userContextB64 = btoa(JSON.stringify(userContext))

      const payload = {
        registrationId,
        leafNodeId: leafNodeId || courseNodeId,
        userContextB64,
      }
      // TODO: we may want to support other lessonTypes in this component in the future:
      if (
        lessonType === PROGRAM_TRAINING_LESSON_TYPE ||
        lessonType === SCHOOLWIDE_TRAINING_LESSON_TYPE ||
        lessonType === CURRICULUM_TRAINING_LESSON_TYPE
      ) {
        payload.progress = 1
      }
      await onMarkComplete(payload)
      fetchOrCreateInstructorProgramFlex(
        userContext.hsActiveSiteId,
        userContext,
      )
    }
  }

  function handleClickNext() {
    navigationEvent(nextStepRoute)
  }

  // should only be able to mark button complete in instance mode
  if (isPreviewMode()) {
    return null
  }

  return (
    <MarkCompleteSection
      buttonText={
        isAdmin(userContext)
          ? completionButtonLabelDefaultAdmin
          : completionButtonLabelDefaultEducator
      }
      completeText={completionLabelDone}
      completionCommentaryComplete={completionCommentaryComplete}
      completionCommentaryTodo={completionCommentaryTodo}
      handleClickNext={handleClickNext}
      handleToggleComplete={handleToggleComplete}
      hasPermissionsToComplete={
        // only admins can mark schoolwide training as complete
        // all other trainings, anyone can mark complete
        lessonType !== SCHOOLWIDE_TRAINING_LESSON_TYPE || isAdmin(userContext)
      }
      id={MARK_COMPLETE_ID}
      isAlreadyComplete={isAlreadyComplete}
      nextSectionButtonText={nextStepButtonLabel}
      nextSectionPromptText={nextStepHeader}
      promptText={completionHeader}
      shoulDisplayCompletionDetails={shoulDisplayCompletionDetails}
    />
  )
}

const mapStateToProps = state => {
  const activeSites = licenseSelectors?.selectActiveSites(state) || []
  let hsActiveSites = []

  if (Array.isArray(activeSites)) {
    hsActiveSites = activeSites.filter(sites =>
      sites?.licenses?.some(license =>
        license?.product?.programCodes?.includes('HSP'),
      ),
    )
  }

  return {
    hsActiveSites,
    highSchoolInstructorData: lmsSelectors.selectHsInstructorData(state),
    userContext: contextSelectors.selectProgramContext(state),
  }
}

const mapDispatchToProps = {
  onMarkComplete: operations.makeAttemptV2,
  navigationEvent,
  fetchOrCreateInstructorProgramFlex:
    operations.fetchOrCreateInstructorProgramFlex,
}

MarkCompleteSectionContainer.propTypes = {
  completionButtonLabelDefaultAdmin: PropTypes.string,
  completionButtonLabelDefaultEducator: PropTypes.string,
  completionDateDefault: PropTypes.string,
  completionDateDone: PropTypes.string,
  completionHeader: PropTypes.string,
  completionLabelDone: PropTypes.string,
  completionSchool: PropTypes.string,
  courseManager: CourseManagerType,
  courseNodeId: PropTypes.string,
  fetchOrCreateInstructorProgramFlex: PropTypes.func,
  highSchoolInstructorData: PropTypes.object,
  hsActiveSites: PropTypes.array,
  navigationEvent: PropTypes.func,
  nextStepButtonLabel: PropTypes.string,
  nextStepHeader: PropTypes.string,
  nextStepRoute: PropTypes.string,
  onMarkComplete: PropTypes.func,
  userContext: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CourseManagerGetter(MarkCompleteSectionContainer))
