import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { ProgressBarIndicator } from 'secondstep-components'
import AdminGradeList from 'components/AdminGradeList'
import AdminLessonProgressCard from 'components/AdminLessonProgressCard'
import AdminBHULessonProgressCard from 'components/AdminBHULessonProgressCard'
import AdminNoClasses from 'components/AdminNoClasses'
import {
  CLASSES_NOT_STARTED_MESSAGE,
  MAX_UNITS_FOR_HEADER,
  NOT_STARTED_MESSAGE,
} from './constants'
import {
  BodyWrapper,
  ClassesProgressWrapper,
  ClassProgressBullet,
  ClassesNotStartedText,
  ClassesNotStartedWrapper,
  ClassesStartedText,
  LessonCompletionWrapper,
  NoClassesMessage,
  PanelWrapper,
  RightWrapper,
  SiteCollapsible,
  StyledAdminLessonProgressTabs,
  StyledHairline,
  StyledHeader,
  UnitWrapper,
} from './component.styles'

const LessonCompletionAccordion = ({
  activeIndex,
  classesCreated,
  classesNotStarted,
  grades,
  handleAccordionClick,
  index,
  lastUpdatedAt,
  name,
  siteId,
  sitePreferences,
  summary,
  units,
}) => {
  const decimalToPercentage = decimal => {
    return decimal > 0
      ? decimal < 0.01
        ? Math.ceil(decimal * 100)
        : Math.round(decimal * 100)
      : decimal
  }
  const classesStarted = classesCreated - classesNotStarted
  const { completionPercentage } = summary ?? 0
  const lessonCompletionPercentage = decimalToPercentage(completionPercentage)
  const hasBHUEnabled =
    sitePreferences?.mspDigital?.bullyingHarassmentUnitEnabled ?? false
  const hasBHUClassCreated = summary.unit5.classesCreated > 0
  const bhuStatus = hasBHUEnabled && hasBHUClassCreated

  return (
    <SiteCollapsible
      accordionPanelDataTestId={`school-row`}
      ariaLabel={`${name}-accordion`}
      classesNotStarted={classesNotStarted}
      dataTestId={`site-collapsible-${siteId}`}
      followActiveProp={true}
      isActive={activeIndex === index}
      key={siteId}
      onClick={() => handleAccordionClick(index)}
      title={
        <PanelWrapper>
          <StyledHeader classesNotStarted={classesNotStarted}>
            {name}
          </StyledHeader>
          <ClassesProgressWrapper classesNotStarted={classesNotStarted}>
            {classesCreated === 0 && (
              <NoClassesMessage>{CLASSES_NOT_STARTED_MESSAGE}</NoClassesMessage>
            )}
            {classesCreated > 0 && (
              <Fragment key="classes-created-key">
                {classesNotStarted === 0 && (
                  <ClassesStartedText>{classesStarted}</ClassesStartedText>
                )}
                {classesNotStarted > 0 && (
                  <Fragment key="classes-not-started-key">
                    <ClassesStartedText>{classesStarted}</ClassesStartedText>
                    <ClassesNotStartedWrapper>
                      <ClassProgressBullet />
                      <ClassesNotStartedText>
                        {classesNotStarted} {NOT_STARTED_MESSAGE}
                      </ClassesNotStartedText>
                    </ClassesNotStartedWrapper>
                  </Fragment>
                )}
              </Fragment>
            )}
          </ClassesProgressWrapper>
          <LessonCompletionWrapper classesCreated={classesCreated}>
            <ProgressBarIndicator
              percentComplete={lessonCompletionPercentage}
              shouldRenderIndicator
            />
          </LessonCompletionWrapper>
          <RightWrapper>
            {units?.slice(0, MAX_UNITS_FOR_HEADER).map((unit, i) => {
              let unitLessonCompletionPercent
              if (unit.totalLessons > 0) {
                unitLessonCompletionPercent = `${Math.round(
                  (unit.completedLessons / unit.totalLessons) * 100,
                )}%`
              } else {
                unitLessonCompletionPercent = '0%'
              }
              return (
                <UnitWrapper classesCreated={classesCreated} key={`unit-${i}`}>
                  {unitLessonCompletionPercent}
                </UnitWrapper>
              )
            })}
          </RightWrapper>
        </PanelWrapper>
      }
    >
      {classesCreated > 0 && (
        <StyledAdminLessonProgressTabs bhuStatus={bhuStatus} />
      )}
      <BodyWrapper classesCreated={classesCreated}>
        {classesCreated === 0 && <AdminNoClasses />}
        {classesCreated > 0 && (
          <Fragment key="body-wrapper">
            <AdminLessonProgressCard
              bhuStatus={bhuStatus}
              lastUpdatedAt={lastUpdatedAt}
              siteId={siteId}
              summary={summary}
              units={units}
            />
            <StyledHairline />
            <AdminGradeList grades={grades} siteId={siteId} />
          </Fragment>
        )}
      </BodyWrapper>
      {bhuStatus && (
        <AdminBHULessonProgressCard
          classesCreated={classesCreated}
          grades={grades}
          siteId={siteId}
          summary={summary}
        />
      )}
    </SiteCollapsible>
  )
}

LessonCompletionAccordion.propTypes = {
  activeIndex: PropTypes.number,
  classesCreated: PropTypes.number,
  classesNotStarted: PropTypes.number,
  grades: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  handleAccordionClick: PropTypes.func,
  index: PropTypes.number,
  lastUpdatedAt: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  siteId: PropTypes.number,
  sitePreferences: PropTypes.object,
  summary: PropTypes.object,
  units: PropTypes.array,
}

export default LessonCompletionAccordion
