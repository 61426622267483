import React from 'react'
import PropTypes from 'prop-types'
import {
  CloseButton,
  ModalBodyCopy,
  ContainedButton,
  BackpackSuccess as SuccessIcon,
} from 'secondstep-components'
import {
  ButtonsWrapperSuccess,
  SuccessModalWrapper,
  Header,
  SubHeader,
} from './component.styles'
import { toUrlSafe } from 'utils/stringHelpers'
import {
  HEADER_SUCCESS,
  BODY_TYPE_PREFIX_CLASS,
  BODY_TEXT_CREATE,
  BODY_TEXT_EDIT,
  BUTTON_SUBMIT,
  MAX_CLASSES_REACHED,
} from './constants'

const propTypes = {
  dataTestId: PropTypes.string,
  handleCancel: PropTypes.func,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  maxClassesReached: PropTypes.bool,
  successFor: PropTypes.string,
  title: PropTypes.string.isRequired,
}

const Success = ({
  dataTestId,
  handleClose,
  handleSubmit,
  maxClassesReached,
  successFor,
  title,
}) => {
  const edit = successFor === 'Edit'
  const modalSize = edit ? 'small' : null
  const bodyText = edit ? BODY_TEXT_EDIT : BODY_TEXT_CREATE
  return (
    <SuccessModalWrapper dataTestId={dataTestId} size={modalSize}>
      <CloseButton dataTestId="button-close" onClick={handleClose} />
      <SuccessIcon dataTestId="icon-success" />
      <Header dataTestId="modal-success-header">{HEADER_SUCCESS}</Header>
      <SubHeader data-testid="modal-success-subheader">
        {`${bodyText} ${title} ${BODY_TYPE_PREFIX_CLASS}`}
      </SubHeader>
      {maxClassesReached && (
        <ModalBodyCopy data-testid="modal-success-max-classes-reached">
          {MAX_CLASSES_REACHED}
        </ModalBodyCopy>
      )}
      <ButtonsWrapperSuccess>
        <ContainedButton
          dataTestId={`button-${toUrlSafe(BUTTON_SUBMIT)}`}
          onClick={handleSubmit}
        >
          {BUTTON_SUBMIT}
        </ContainedButton>
      </ButtonsWrapperSuccess>
    </SuccessModalWrapper>
  )
}

Success.propTypes = propTypes

export default Success
