/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React from 'react'
import { RouteContentGetter } from 'layers/content/v2/Hocs/RouteContentGetter'
import withApiError from 'layers/errorHandling/apiError'
import Pathways from './component'
import { RouteContentProductAppMap } from 'utils/productMaps'
import { getRouteServiceProductPath } from 'utils/productHelpers'

const Container = props => {
  const { match, navigationEvent, location, routeContent } = props || {}

  const { content = {}, loading } = routeContent

  const readyToLoad = !!content && !loading

  const handleTabChange = tab => {
    const { query } = location
    const destination = `${tab.route}?${new URLSearchParams(query)?.toString()}`
    navigationEvent(destination)
  }

  return (
    readyToLoad && (
      <Pathways
        content={content}
        handleTabChange={handleTabChange}
        match={match}
      />
    )
  )
}

const options = {
  app: RouteContentProductAppMap.learnHsProduct,
  routeResolver: getRouteServiceProductPath,
}

export default RouteContentGetter(withApiError(Container), options)
