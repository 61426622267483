import styled from 'styled-components'
import { Tooltip, TooltipContentWrapper } from 'secondstep-components'
import { themeGet } from 'styled-system'
import { DefaultSiteSelectorWrapper } from 'components/SiteSelector'

export const LayoutContainer = styled.div`
  width: 100%;
  @media only screen and (min-width: 1346px) {
    background-color: #f1f6ea;
    display: flex;
    justify-content: center;
  }
`

export const PageContainer = styled.div`
  padding-left: 1.25rem;
  background-color: white;
  @media only screen and (min-width: 1346px) {
    width: 84.125rem;
  }

  h1 {
    padding: 3.625rem 0 0.8rem 0;
    color: ${themeGet('colors.headers.primaryHeader')};
    font-family: komet;
    font-size: 2.625rem;
    font-weight: 800;
    letter-spacing: 1.11px;
    line-height: 2.875rem;
  }

  .description {
    margin-bottom: 2.5rem;
    color: #555555;
    font-family: ${themeGet('fonts.moldeRegular16.fontFamily')};
    font-size: ${themeGet('fonts.moldeRegular16.fontSize')};
    letter-spacing: 0;
    line-height: ${themeGet('fonts.moldeRegular16.lineHeight')};
  }
  ${({ theme }) => theme?.breakpoints?.mobileLarge} {
    padding: 0 1rem;

    h1,
    .description {
      text-align: start;
    }
  }
`

export const CardContainer = styled.div.attrs(({ isSingleCard }) => {
  const cardLayout = isSingleCard ? '1fr' : 'repeat(5, 14.313rem)'

  return {
    cardLayout,
  }
})`
  display: grid;
  grid-template-columns: ${({ cardLayout }) => cardLayout};
  gap: 0.875rem;
  margin-bottom: 40px;

  @media only screen and (max-width: 1234px) {
    grid-template-columns: ${({ isSingleCard }) =>
      isSingleCard
        ? '1fr'
        : 'repeat(4, 14.313rem)'}; /* 4 cards per row on desktop */
  }

  @media screen and (max-width: ${themeGet('breakpoints.sizes.medium')}) {
    grid-template-columns: ${({ isSingleCard }) =>
      isSingleCard
        ? '1fr'
        : 'repeat(3, 14.313rem)'}; /* 3 cards per row on tablet */
  }

  ${({ theme }) => theme.breakpoints.tablet} {
    grid-template-columns: ${({ isSingleCard }) =>
      isSingleCard
        ? '1fr'
        : 'repeat(2, 14.313rem)'}; /* 2 cards per row on small tablet */
  }

  ${({ theme }) => theme.breakpoints.mobileLarge} {
    grid-template-columns: ${({ isSingleCard }) =>
      isSingleCard ? '1fr' : '1fr'}; /* 1 card per row on mobile */
  }
`

export const StyledTooltip = styled(Tooltip)`
  width: inherit;
  margin-left: 0.5rem;
  cursor: pointer;
  i.fas {
    color: #677f1a;
    font-size: 0.875rem;
    vertical-align: middle;
  }
  .fa-question-circle:before {
    font-size: 1rem;
  }
  ${TooltipContentWrapper} {
    border: 1px solid #677f1a;
    text-align: left;
    // tooltips should appear above their hover buttons:
    top: 2.4rem;
    left: 7.4rem;
    padding: 0.75rem 1rem;
    border-radius: 0.3125rem;
    line-height: 1rem;
    color: ${themeGet('colors.gray675')};
    ::before {
      margin-top: -1.225rem;
      margin-bottom: 0.5rem;
      margin-left: 0.1rem;
      content: ' ';
      width: 0.75rem;
      height: 0.75rem;
      position: relative;
      display: block;
      background-color: white;
      transform: rotate(45deg);
      border: 1px solid #677f1a;
      border-bottom: none;
      border-right: none;
    }
  }

  ${({ theme }) => theme?.breakpoints?.mobileLarge} {
    ${TooltipContentWrapper} {
      left: 0.6rem;
      ::after {
        left: 47%;
      }
    }
  }
`

export const TitleAndToolTipContainer = styled.div.attrs(
  ({ isIntegratedSection }) => ({
    marginRight: isIntegratedSection ? '0.6rem' : '0.9rem',
  }),
)`
  display: flex;
  flex-direction: row;
  .integratedHeader {
    margin-right: 0.5rem;
    color: #023125;
    font-family: komet;
    font-size: 1.75rem;
    font-weight: 800;
    letter-spacing: 0.5px;
    line-height: 1.75rem;
  }

  ${({ theme }) => theme?.breakpoints?.mobileLarge} {
    margin-right: 0;
    margin-bottom: 1.75rem;
  }
`

export const BannerSpacer = styled.div`
  margin-top: 7rem;
`

export const IntegratedSectionContainer = styled.div`
  display: flex;
  flex-direction: row;
`
export const PracticeContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  @media screen and (max-width: ${themeGet('breakpoints.sizes.medium')}) {
    ${({ isSingleCard }) =>
      isSingleCard &&
      `
        display: flex;
        flex-direction: column;
      `}
  }
`

export const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:nth-child(2) {
    margin-left: 0.875rem;
  }
  ${({ theme }) => theme?.breakpoints?.medium} {
    &:nth-child(2) {
      margin-left: 0;
    }
  }
`

export const SiteSelectorWrapper = styled(DefaultSiteSelectorWrapper)`
  ${({ theme }) => theme?.breakpoints?.mobileLarge} {
    padding-right: 0;
  }
`
