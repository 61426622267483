import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Collapsible } from 'grommet'
import { isoDurationToHoursMinutes } from 'utils/timeFormattingHelpers'
import {
  BodyCopy,
  ClockIcon,
  HeaderThree,
  HeaderFive,
  IconWithText,
  Loading,
} from 'secondstep-components'
import TrainingResourcePDFs from '../TrainingResourcePDFs'
import ModuleButton from '../ModuleButton'
import {
  DescriptionWrapper,
  DetailsPanel,
  StatusWrapper,
  Wrapper,
  ButtonWrapper,
  ResourcePanel,
  BodyWrapper,
} from './component.styles'
import { getResources } from './core'
import { buttonMap } from './data'
import { CHOICE_TEXT, LOADING_TEXT } from './constants'

const ListItem = props => {
  const {
    additionalResources,
    attempted,
    buttonLabel,
    dataTestId,
    description,
    displayTitle,
    instance,
    isFirstModule,
    time,
    scoID,
    choiceLessons,
    choiceText,
  } = props

  const [isOpen, setIsOpen] = useState(isFirstModule)
  const { Button } = buttonMap[attempted] || {}
  const { icon } = buttonMap[attempted] || {}
  const { text } = buttonMap[attempted] || {}
  const isComplete = attempted === 'completed'
  const totalTrainingTime = isoDurationToHoursMinutes(time)
  const hasMultipleChoices = choiceLessons.length > 0
  const resources = getResources(additionalResources)
  Button.displayName = 'HeaderButton'

  if (!displayTitle) return <Loading loadingText={LOADING_TEXT} />
  return (
    <Wrapper dataTestId={dataTestId} isOpen={isOpen}>
      <Button onClick={() => setIsOpen(!isOpen)}>
        <HeaderThree dataTestId="module-title">{displayTitle}</HeaderThree>
        <StatusWrapper attempted={attempted}>
          <IconWithText attempted={attempted} icon={icon} text={text} />
        </StatusWrapper>
      </Button>
      <Collapsible open={isOpen}>
        <DetailsPanel dataTestId="training-details">
          <BodyWrapper>
            <DescriptionWrapper>
              <BodyCopy data-testid="module-description">
                {description}
              </BodyCopy>
              {isComplete && (
                <IconWithText
                  dataTestId="module-training-time"
                  icon={<ClockIcon />}
                  text={`Training time: ${totalTrainingTime}`}
                />
              )}
            </DescriptionWrapper>
            {hasMultipleChoices && (
              <HeaderFive dataTestId="choice-button-header-five">
                {choiceText || CHOICE_TEXT}
              </HeaderFive>
            )}
            <ButtonWrapper>
              {hasMultipleChoices ? (
                choiceLessons.map(lesson => (
                  <ModuleButton
                    attempted={lesson.attempted}
                    dataTestId="module-start-button"
                    entryId={lesson.contentfulID}
                    instance={lesson.instance}
                    isChoice={hasMultipleChoices}
                    key={lesson.title}
                    scoID={lesson.scoID}
                  />
                ))
              ) : (
                <ModuleButton
                  attempted={attempted}
                  dataTestId="module-start-button"
                  instance={instance}
                  scoID={scoID}
                >
                  {buttonLabel}
                </ModuleButton>
              )}
            </ButtonWrapper>
          </BodyWrapper>
          {resources && (
            <ResourcePanel>
              <TrainingResourcePDFs
                dataTestId={'training-resource-pdfs'}
                resources={resources}
              />
            </ResourcePanel>
          )}
        </DetailsPanel>
      </Collapsible>
    </Wrapper>
  )
}

ListItem.propTypes = {
  additionalResources: PropTypes.array,
  attempted: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  choiceLessons: PropTypes.array.isRequired,
  choiceText: PropTypes.string,
  contentfulID: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  description: PropTypes.string,
  displayTitle: PropTypes.string,
  instance: PropTypes.string,
  isFirstModule: PropTypes.bool,
  scoID: PropTypes.number,
  time: PropTypes.string,
}

export default ListItem
