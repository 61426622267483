import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { HeaderOne } from 'secondstep-components'

const breakpoints = {
  customTablet: '@media screen and (max-width: 860px)',
}

export const Bold = styled.span`
  font-family: ${themeGet('font.molde.medium')};
`

export const Delimiter = styled.span`
  font-family: ${themeGet('font.molde.medium')};
`

export const SchoolSetupRequiredText = styled.span`
  font-family: ${themeGet('font.molde.fontFamily')};
  color: ${themeGet('colors.darkGray')};
  padding-left: 1.25rem;
  text-wrap: nowrap;

  @media screen and (max-width: 740px) {
    padding-top: 0.5rem;
    padding-left: 0;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    padding-top: 0.25rem;
  }
`

export const Bullet = styled.span`
  display: inline-block;
  height: 0.8125rem;
  width: 0.8125rem;
  margin-right: 0.375rem;
  border-radius: 50%;
  background-color: ${themeGet('colors.yellow')};
  transform: translateY(0.0938rem);
`

export const StyledHeaderOne = styled(HeaderOne)`
  margin-bottom: 0.625rem;
  font-size: 1.4375rem;
  line-height: 1.5625rem;
  font-family: ${themeGet('font.sharpSlab.semibold')};

  ${breakpoints.customTablet} {
    font-size: 1.4375rem;
    line-height: 1.5625rem;
  }
`

export const SubHeaderSummary = styled.div`
  display: flex;

  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;
  }
`

export const SubHeaderText = styled.div`
  font-size: 1rem;
  padding-right: 0.4375rem;
`

export const Summary = styled.div`
  display: flex;
  flex-direction: row;

  @media screen and (max-width: 740px) {
    flex-direction: column;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    [class*='Delimiter'] {
      display: none;
    }
  }
`
