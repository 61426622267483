import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import GlobalNav from 'components/GlobalNav'
import ProgramNav from 'components/ProgramNav'
import { SubNavGetterComponentPropTypes } from 'layers/content/v2/Hocs/SubNavGetter'

import { SETUP_ADMIN } from './constants'

const NavigationLayout = ({
  roles,
  userClaims,
  navigationEvent,
  product,
  location,
  theme,
  name,
  subNavData,
}) => {
  const isSetUpAdmin = roles?.includes(SETUP_ADMIN)
  const {
    hasElemAdminClaim,
    hasHighschoolAdminClaim,
    hasMsAdminClaim,
    hasSelaAdminClaim,
  } = userClaims || {}
  const hasAdminClaim =
    hasElemAdminClaim ||
    hasHighschoolAdminClaim ||
    hasMsAdminClaim ||
    hasSelaAdminClaim

  const programs = useMemo(() => {
    return (
      // keep the prop shape the same as it was in the old system for the ProgramNav component
      subNavData?.data?.map(({ displayTitle, path }) => ({
        title: displayTitle,
        route: path,
        isSelected: location.pathname.includes(path),
      })) || []
    )
  }, [subNavData, location.pathname])

  return (
    <React.Fragment>
      <GlobalNav
        hasAdminClaim={hasAdminClaim}
        isSetUpAdmin={isSetUpAdmin}
        name={name}
        navigationEvent={navigationEvent}
        pathname={location.pathname}
        product={product}
        theme={theme}
      />
      <ProgramNav
        goToProgram={navigationEvent}
        location={location}
        programs={programs}
      />
    </React.Fragment>
  )
}

NavigationLayout.propTypes = {
  location: PropTypes.shape({ pathname: PropTypes.string }),
  name: PropTypes.string,
  navigationEvent: PropTypes.func.isRequired,
  product: PropTypes.shape({
    product: PropTypes.string.isRequired,
  }),
  roles: PropTypes.array,
  subNavData: SubNavGetterComponentPropTypes,
  theme: PropTypes.object,
  userClaims: PropTypes.object,
}

export default NavigationLayout
