import React, { Component } from 'react'
import { findNodeHandle } from 'react-native'
import PropTypes from 'prop-types'
import SkipToMain from './SkipToMain/component'
import { SecondStepLogoIcon, TabSet } from 'secondstep-components'

import NavigationHelper from 'layers/navigation/navigationHelper'

import Item from './item'
import {
  Container,
  Divider,
  Icons,
  Left,
  LogoContainer,
  Right,
  StyledTabButton,
  TabSetWrapper,
  Title,
  TitleContainer,
  Wrapper,
} from './component.styles'
import {
  ADMIN_DASHBOARD_LINK,
  LOGO_LINK,
  NAVIGATION_ITEMS,
  PROFILE,
} from './data'

import ProfileDropdown from './ProfileDropdown'
import GlobalEventListener from 'components/shared/GlobalEventListener'

class GlobalNavigation extends Component {
  static propTypes = {
    hasAdminClaim: PropTypes.bool,
    navigationEvent: PropTypes.func,
    notifications: PropTypes.number,
    pathname: PropTypes.string,
    theme: PropTypes.object,
    title: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.iconRefs = []
    this.profileDropdownRef = React.createRef()
  }

  closeProfileDropdown = () => {
    this.setState({ showProfileDropdown: false })
  }

  handleClickOutsideModal = e => {
    const { showProfileDropdown } = this.state || {}

    if (showProfileDropdown) {
      const dropdownNode = findNodeHandle(this.profileDropdownRef.current)
      const iconNode = findNodeHandle(this.iconRefs[PROFILE])
      const clickedOnIcon = iconNode.contains(e.target)
      const clickedOnDropdown = dropdownNode?.contains(e.target) || false

      if (!clickedOnDropdown && !clickedOnIcon) {
        this.closeProfileDropdown()
      }
    }
  }

  handleEscapeKeyPress = e => {
    const { showProfileDropdown } = this.state || {}
    if (showProfileDropdown && e.key === 'Escape') {
      this.closeProfileDropdown()
    }
  }

  onPress = link => {
    if (link === PROFILE) {
      const { showProfileDropdown } = this.state || {}
      this.setState({ showProfileDropdown: !showProfileDropdown })
    } else {
      this.navigateLeaveApp(link)
    }
  }

  navigateLeaveApp = route => {
    const { navigationEvent } = this.props || {}
    this.closeProfileDropdown()
    navigationEvent(route, NavigationHelper.types.OPEN)
  }

  navigateToPage = route => {
    const { navigationEvent } = this.props || {}
    this.closeProfileDropdown()
    navigationEvent(route)
  }

  render() {
    const { showProfileDropdown = false } = this.state || {}
    const { hasAdminClaim, isSetUpAdmin, pathname, product, theme } =
      this.props || {}
    const { title } = product || {}
    const isAdminDashboard =
      pathname?.includes('/admin') && (hasAdminClaim || isSetUpAdmin)
    const isEducatorDashboard =
      pathname?.includes('/dashboard') && (hasAdminClaim || isSetUpAdmin)

    const navigateToAdminDashboard = () => {
      if (isEducatorDashboard) this.navigateToPage(ADMIN_DASHBOARD_LINK)
    }

    const navigateToEducatorDashboard = () => {
      if (isAdminDashboard) this.navigateToPage(LOGO_LINK)
    }

    return (
      <Container data-testid={'global-nav-container'} theme={theme}>
        <GlobalEventListener
          onClick={this.handleClickOutsideModal}
          onKeyPress={this.handleEscapeKeyPress}
          onUrlChange={this.closeProfileDropdown}
        />
        <Wrapper>
          <SkipToMain pathname={pathname} />
          <Left>
            <LogoContainer
              aria-label="Second Step Programs: Home"
              data-testid={'global-nav-logo'}
              href="/"
            >
              <SecondStepLogoIcon />
            </LogoContainer>
            <Divider />
            <TitleContainer
              isAdminDashboard={isAdminDashboard}
              isEducatorDashboard={isEducatorDashboard}
            >
              <Title data-testid={'global-nav-title'}>{title}</Title>
            </TitleContainer>
          </Left>
        </Wrapper>
        <Right>
          {(isAdminDashboard || isEducatorDashboard) && (
            <TabSetWrapper>
              <TabSet dataTestId="navigation-tabset">
                <StyledTabButton
                  dataTestId={'tab-button-educator-dashboard'}
                  isActive={isEducatorDashboard}
                  key="programs-tab"
                  label="My Programs"
                  onClick={navigateToEducatorDashboard}
                />
                <StyledTabButton
                  dataTestId={'tab-button-leader-dashboard'}
                  isActive={isAdminDashboard}
                  key="admin-dashboard-tab"
                  label="Leader Dashboard"
                  onClick={navigateToAdminDashboard}
                />
              </TabSet>
            </TabSetWrapper>
          )}
          <Icons>
            {NAVIGATION_ITEMS.map(
              ({ alt, ariaLabel, badge, label, link, source }, index) => (
                <Item
                  accessibilitylabel={alt}
                  accessible="true"
                  ariaExpanded={showProfileDropdown}
                  ariaLabel={ariaLabel}
                  badge={badge}
                  index={index}
                  key={`${index}-${label}`}
                  label={label}
                  link={link}
                  onPress={this.onPress}
                  ref={profileIcon => (this.iconRefs[label] = profileIcon)}
                  source={source}
                  theme={theme}
                />
              ),
            )}
          </Icons>
          <ProfileDropdown
            data-testid={'global-nav-profile-dropdown'}
            key="profile-dropdown"
            navigateLeaveApp={this.navigateLeaveApp}
            navigateToPage={this.navigateToPage}
            ref={this.profileDropdownRef}
            show={showProfileDropdown}
            theme={theme}
          />
        </Right>
      </Container>
    )
  }
}

export default GlobalNavigation
