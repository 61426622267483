import styled from 'styled-components'
import {
  Modal,
  ModalContentWrapper,
  HeaderTwo,
  BodyCopy,
} from 'secondstep-components'
import { themeGet } from 'styled-system'

export const StyledModal = styled(Modal)`
  ${ModalContentWrapper} {
    padding: ${themeGet('spacing.red40_2_5rem')}
      ${themeGet('spacing.amber28_1_75rem')};

    ${themeGet('breakpoints.mobile')} {
      padding: ${themeGet('spacing.red40_2_5rem')}
        ${themeGet('spacing.turquoise20_1_25rem')};
    }

    text-align: center;

    a {
      color: ${themeGet('colors.blue')};
      text-decoration: none;
    }

    a:hover {
      color: ${themeGet('colors.secondStepBlue')};
    }
  }
`

export const HeaderTwoWrapper = styled(HeaderTwo)`
  margin: 0rem;
  text-align: center;
`

export const BodyCopy1 = styled(BodyCopy)`
  margin-top: ${themeGet('spacing.blue16_1rem')};
  color: ${themeGet('colors.darkerGray')};
`

export const BodyCopy2 = styled(BodyCopy)`
  text-align: center;
  color: ${themeGet('colors.darkerGray')};
`

export const Terms = styled.p`
  text-align: center;
  margin-top: ${themeGet('spacing.amber28_1_75rem')};
  margin-bottom: ${themeGet('spacing.amber28_1_75rem')};
`

export const CodeActivationWrapper = styled.div`
  margin-bottom: 0rem;
  margin-top: ${themeGet('spacing.pink8_0_5rem')};

  [class*='Wrapper'] {
    width: 100%;
    margin-bottom: 0rem;
  }

  [class*='FormFieldStyled'] {
    margin-bottom: 0rem;
  }
`

export const CollapsibleWrapper = styled.div`
  [class*='CollapsibleButtonStyled'] {
    margin: 0 auto;
    padding-top: 0rem;
    padding-bottom: 0rem;
  }

  [aria-hidden='false'] {
    margin-top: ${themeGet('spacing.turquoise20_1_25rem')};
  }

  [class*='TextLink'] {
    margin: 0 auto;
  }
`
