import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_CONTENTFUL_PRODUCT,
} from 'layers/errorHandling/apiError'
import VideoDataSetterContainer from 'experienceComponents/VideoDataSetter'
import { getCaptionTrackWithSource } from './helpers'
import { usePrevious } from 'utils/componentHelpers'

const VideoDataGetter = props => {
  const { entry, refetchLocale, getEntryWithNewLocale, variant, theme } =
    props || {}
  const { videoRef, id } = entry || {}
  const {
    azureVideoUrl,
    azureVideoUrlExtended,
    posterImage,
    vttFile,
    transcript,
    transcriptExtended,
  } = videoRef || {}

  const [refetchStatus, setRefetchStatus] = useState(false)
  const prevRefAzureVideoUrl = usePrevious(azureVideoUrl)
  const firstElement = Array.isArray(vttFile) ? vttFile[0] : undefined
  const { file } = firstElement || {}
  const { url } = file || {}
  const { file: posterFileField } = posterImage || {}
  const { url: posterUrlField } = posterFileField || {}

  let tracks = null

  useEffect(() => {
    if (!refetchStatus && refetchLocale !== undefined) {
      setRefetchStatus(true)
      getEntryWithNewLocale(refetchLocale)
    }
  }, [refetchLocale])

  useEffect(() => {
    if (azureVideoUrl !== prevRefAzureVideoUrl) {
      setRefetchStatus(false)
    }
  }, [azureVideoUrl])

  if (url) {
    // TODO using stringify bc pass from here to VideoDataSetterContainer is not able to keep track of an array with an object in it
    tracks = JSON.stringify(getCaptionTrackWithSource(url, refetchLocale))
  }

  if (!entry || refetchStatus || !azureVideoUrl) {
    return null
  }

  return (
    <VideoDataSetterContainer
      id={id}
      key={azureVideoUrl}
      poster={posterUrlField}
      refetchLocale={refetchLocale}
      theme={theme}
      tracks={tracks}
      transcriptExtended={transcriptExtended}
      transcriptSource={transcript}
      variant={variant}
      videoUrl={azureVideoUrl}
      videoUrlExtended={azureVideoUrlExtended}
    />
  )
}
const mapper = entry => {
  return { entry }
}
const options = {
  include: 3,
  mapper,
  entryIdProp: true,
  prefetch: ['es-US'],
  spread: true,
}
const mapStateToProps = ({ productManager }) => {
  const theme = productManager?.productMap?.themeName

  return { theme }
}

export default connect(mapStateToProps)(
  ContentEntryDataGetter(
    withApiError(VideoDataGetter, [
      ERROR_TYPE_CONTENTFUL,
      ERROR_TYPE_CONTENTFUL_PRODUCT,
    ]),
    options,
  ),
)
