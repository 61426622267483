import { FlagsComponentRouter, FlagsSingleton } from 'flags'

import OffComponent from './flag.off/index'
import OnComponent from './flag.on/index'
import {
  isMiddleSchoolProductJuneRelease,
  isElementaryProgramFlex,
} from 'utils/productHelpers'

const FlagToggler = FlagsComponentRouter({
  flagSubscriptions: [
    'middle-school-june-release',
    'feature_LEARN-18511-k5-elem-program-flex',
  ],
  OnComponent,
  OffComponent,
  flagsChecker: FlagsSingleton.checkAtLeastOneFlagTrue,
  onCondition: (_, subscribedFlags) =>
    isMiddleSchoolProductJuneRelease(_, subscribedFlags) ||
    isElementaryProgramFlex(_, subscribedFlags),
})

export default FlagToggler
