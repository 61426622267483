import { domains } from 'config/env'

export const LESSON_PROGRESS_MESSAGE =
  'Lesson progress will appear here once classes are created'
export const PROGRAM_TO_STAFF_LINK_TEXT = 'Introduce the Program to Staff'
export const PROGRAM_INTRODUCTION_LINK = `${domains.LEARN_APP_DOMAIN}/admin/implementation-guide/launch/introduce-the-program-to-staff`
export const SCHOOL_KICK_OFF_MESSAGE = 'Has this school kicked off the program?'
export const STAFF_TRAINING_LINK = `${domains.LEARN_APP_DOMAIN}/admin/implementation-guide/launch/start-staff-training`
export const STAFF_TRAINING_LINK_TEXT = 'Start Staff Training'
export const TEACHER_CLASS_MESSAGE =
  'Teachers can create their classes when they log in.'
export const TEACHER_TRAINING_MESSAGE = 'Have teachers completed training?'
