import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const StyledContainer = styled.div`
  font-family: ${themeGet('font.molde.medium')};
  height: 1.188rem;
  display: flex;
  color: ${themeGet('colors.hsPrimaryPink')};
  cursor: pointer;
  z-index: 10;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.063rem;
  white-space: nowrap;
  cursor: ${({ onClick }) =>
    onClick && typeof onClick === 'function' ? 'pointer' : 'auto'};

  &:hover {
    color: ${({ onClick }) =>
      onClick && typeof onClick === 'function'
        ? themeGet('colors.hsSecondaryGreen')
        : themeGet('colors.hsPrimaryPink')};
  }

  svg {
    margin-right: 0.375rem;
    margin-top: -0.188rem;
  }
`

export const UserIcon = () => (
  <svg
    aria-hidden="true"
    height="18px"
    role="presentation"
    viewBox="0 0 17 18"
    width="17px"
  >
    <g
      fill="none"
      fillRule="evenodd"
      id="ss_hs_teachingprofile"
      stroke="currentColor"
      strokeWidth="1"
    >
      <path
        d="M12.2834355,4.76476687 C12.2834355,6.84365389 10.5892007,8.52953374 8.50009721,8.52953374 C6.41099369,8.52953374 4.71675887,6.84361549 4.71675887,4.76476687 C4.71675887,2.68591824 6.41099369,1 8.50009721,1 C10.5892007,1 12.2834355,2.68591824 12.2834355,4.76476687 M1.95368129,16.9998272 C1.38088437,16.9998272 0.928693534,16.4943512 1.00934198,15.9303754 C1.53162751,12.2781157 4.68656394,9.47023587 8.5,9.47023587 C12.3134375,9.47023587 15.468374,12.2781157 15.9906595,15.9297994 C16.0713022,16.494524 15.6190978,17 15.0463202,17 L1.95368129,16.9998272 Z"
        fill="currentColor"
        fillRule="nonzero"
        id="Combined-Shape"
      />
    </g>
  </svg>
)
