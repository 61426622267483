import React, { useState } from 'react'

import CurrentSiteTile from 'components/CurrentSiteTile'
import SelectSiteModal from 'components/SelectSiteModal'

const SiteSelector = () => {
  const [showSelectSiteModal, setShowSelectSiteModal] = useState(false)

  const closeSelectSiteModal = () => {
    if (showSelectSiteModal) {
      setShowSelectSiteModal(false)
    }
  }

  return (
    <>
      <SelectSiteModal
        handleClose={closeSelectSiteModal}
        show={showSelectSiteModal}
      />

      <CurrentSiteTile handleClick={() => setShowSelectSiteModal(true)} />
    </>
  )
}
export default SiteSelector
