import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router'
import { connect } from 'react-redux'
import withApiError, {
  ERROR_TYPE_LMS,
  ERROR_TYPE_PROFILE,
} from 'layers/errorHandling/apiError'
import { operations as productOperations } from 'store/productManager'

import { NotFound } from 'secondstep-components'
import DistrictContainer from 'containers/District'
import FamilyResourcesContainer from 'containers/FamilyResources'
import { isFlagOn } from 'utils/featureFlags'
import LearnErrorBoundary from 'components/LearnErrorBoundary'
import PagesContainer from '../Pages'
import PagesV2Container from '../PagesV2'

import { getStaticRoutes } from './data'

// This component is shared between the family-resources, family, & family-mind-yeti namespaces
function checkShouldRenderPagesV2(namespace) {
  if (
    // this case covers Family Resources K8
    namespace === 'family-resources' &&
    isFlagOn(['refactor_LEARN-18413-pages-component-program-flex'])
  ) {
    return true
  }
  if (
    // this case covers Family Resources High School:
    namespace === 'family' &&
    isFlagOn(['feature_LEARN-18248-hs-family-resources'])
  ) {
    return true
  }
  // use original pages component for Family Mind Yeti and all other namespaces:
  return false
}

export const NamespaceRouter = ({ match, getProductMap }) => {
  const { path } = match
  const namespace = path.split('/')[1]
  const productMap = getProductMap(namespace) || {}

  const shouldRenderPagesV2 = checkShouldRenderPagesV2(namespace)

  const PagesComponent = shouldRenderPagesV2 ? PagesV2Container : PagesContainer
  return (
    <LearnErrorBoundary>
      <Switch>
        <Route component={PagesComponent} path={getStaticRoutes(path)} />
        <Route
          component={FamilyResourcesContainer}
          exact
          path={`${path}/elementary`}
        />
        <Route
          component={FamilyResourcesContainer}
          exact
          path={`${path}/middle-school`}
        />
        <Route component={DistrictContainer} exact path={`${path}/supports`} />
        <Redirect exact from={path} to={`${path}/${productMap.defaultPage}`} />
        <Route component={NotFound} />
      </Switch>
    </LearnErrorBoundary>
  )
}

NamespaceRouter.propTypes = {
  getProductMap: PropTypes.func,
  match: PropTypes.object,
}

const mapStateToProps = ({ productManager }) => {
  return {
    productMap: productManager.productMap,
  }
}

const mapDispatchToProps = {
  getProductMap: productOperations.getProductMap,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withApiError(NamespaceRouter, [ERROR_TYPE_PROFILE, ERROR_TYPE_LMS]))
