import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const ListItem = styled.li.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  list-style-type: none;
`
export const UnorderedList = styled.ul.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  flex-direction: row;
  margin: 0;
  padding: 0;
  display: inline-flex;
  column-gap: ${themeGet('spacing.yellow12_0_75rem')};
  row-gap: ${themeGet('spacing.green4_0_25rem')};

  li {
    a {
      align-items: flex-start;
    }
  }

  ${themeGet('breakpoints.medium')} {
    li {
      a {
        svg {
          flex-shrink: 0;
        }
      }
    }
  }

  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;

    li a {
      flex: 1 0 100% !important;
      align-items: flex-start;

      svg {
        flex-shrink: 0;
      }
    }
  }
`
