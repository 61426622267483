import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ClosableModal from 'experienceComponents/ClosableModal'

import {
  ThumbnailWrapper,
  AudioCalloutButton,
  AudioThumbnail,
} from './component.styles'
import NotesThumbnail from './NotesThumbnail'
import { CLICK, ENTER } from './data'

const AudioFullWidth = ({
  id,
  description,
  displayTitle,
  audioThumbnailUrl,
  audioUrl,
}) => {
  const [showModal, setShowModal] = useState(false)

  const dataTestIdPrefix = `audio-full-width`
  const dataTestIdOpenButton = `${dataTestIdPrefix}-open-button-${id}`

  const openModal = event => {
    if (showModal || (event.type !== CLICK && event.key !== ENTER)) {
      return
    }

    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  if (!audioUrl) {
    return null
  }

  return (
    <AudioCalloutButton
      a11yTitle={description}
      data-testid={dataTestIdOpenButton}
      onClick={openModal}
      onKeyPress={openModal}
      role="button"
      tabIndex="0"
    >
      <ThumbnailWrapper>
        <AudioThumbnail
          a11yTitle="Audio Callout Image"
          fill="horizontal"
          fit="contain"
        >
          <NotesThumbnail />
        </AudioThumbnail>
      </ThumbnailWrapper>
      <ClosableModal
        id={id}
        modalTitle={displayTitle}
        onClose={closeModal}
        show={showModal}
      >
        <iframe
          align="center"
          allowFullScreen
          frameBorder="no"
          height="100%"
          name="azuremediaplayer"
          scrolling="no"
          src={`//aka.ms/ampembed?url=${audioUrl}`}
          title={`audio-${displayTitle}`}
          width="100%"
        />
      </ClosableModal>
    </AudioCalloutButton>
  )
}

AudioFullWidth.propTypes = {
  audioThumbnailUrl: PropTypes.string,
  audioUrl: PropTypes.string,
  description: PropTypes.string,
  displayTitle: PropTypes.string,
  id: PropTypes.string,
}

export default AudioFullWidth
