import React from 'react'
import PropTypes from 'prop-types'
import CSVError from './LmsCSVError.jsx'
import { BodyCopy, DownloadIcon } from 'secondstep-components'
import AdminExportDownloadButton from 'components/AdminExportDownloadButton'
import {
  CsvLoadingMsg,
  CsvTooltip,
  CsvWrapper,
  LabelWrapper,
  StyledCsvDownloadMenu,
} from './component.styles.js'
import { CSV_DOWNLOAD_LABEL, DOWNLOAD_LINK_TEXT } from './constants'

const CSVReportsDropdown = ({
  classesCount,
  error,
  historicalReports,
  historicalReportingOnClick,
  isFetchingDocumentUrl,
  isFetchingHistoricalReportData,
  lessonReportingOnClick,
  onClickCurrentYear,
  schoolCsvDownloadTooltip,
}) => {
  if (error) {
    return <CSVError />
  }

  return (
    (classesCount > 0 || historicalReports) &&
    !error && (
      <CsvWrapper>
        <LabelWrapper>
          <label htmlFor="csv-download">{CSV_DOWNLOAD_LABEL}</label>
          <CsvTooltip content={<BodyCopy>{schoolCsvDownloadTooltip}</BodyCopy>}>
            <i className="fas fa-question-circle" />
          </CsvTooltip>
        </LabelWrapper>
        {isFetchingDocumentUrl && (
          <AdminExportDownloadButton isFetching={true} />
        )}
        {!isFetchingDocumentUrl && (
          <StyledCsvDownloadMenu
            buttonIcon={DownloadIcon}
            buttonText={DOWNLOAD_LINK_TEXT}
            dataTestId="select-csv"
            disabledSelectButton={isFetchingHistoricalReportData}
            historicalReports={historicalReports}
            name="csv-download"
            onClickCurrent={lessonReportingOnClick}
            onClickCurrentYear={onClickCurrentYear}
            onClickHistorical={historicalReportingOnClick}
            showDownloadIcons={false}
          />
        )}
        {isFetchingHistoricalReportData && (
          <CsvLoadingMsg>
            Please wait. Information isn't ready yet.
          </CsvLoadingMsg>
        )}
      </CsvWrapper>
    )
  )
}

CSVReportsDropdown.propTypes = {
  classesCount: PropTypes.number,
  error: PropTypes.object,
  historicalReportingOnClick: PropTypes.func,
  historicalReports: PropTypes.array,
  isFetchingDocumentUrl: PropTypes.bool,
  isFetchingHistoricalReportData: PropTypes.bool,
  lessonReportingOnClick: PropTypes.func,
  onClickCurrentYear: PropTypes.func,
  schoolCsvDownloadTooltip: PropTypes.string,
}

export default CSVReportsDropdown
