export const ROOT_ENTRY_ID = '6trL7mXs2rPPMT2PXE0zE7'
export const ENGLISH_US_LOCALE = 'en-US'
export const SPANISH_US_LOCALE = 'es-US'
export const DEFAULT_ROOT_LOCALE = ENGLISH_US_LOCALE
export const DEFAULT_ROOT_INCLUDE = 3

// order matters here. We want the default locale first
export const KNOWN_LOCALES = [ENGLISH_US_LOCALE, SPANISH_US_LOCALE]

export const ROOT_ENTRY_ID_WITH_FULL_DEFAULT_KEY = `${ROOT_ENTRY_ID}:${DEFAULT_ROOT_LOCALE}:${DEFAULT_ROOT_INCLUDE}`
