import React from 'react'
import ContentDataGetter from 'layers/content/v2/Hocs/ContentDataGetter'
import { ENTRY_ID, QUERY_TYPE } from './constants'
import Component from './component'

// adapter function to map props to the shape the component expects
function mapper(props) {
  const { content } = props
  const mappedProps = {
    ...props,
  }

  // delete original(old content model) props
  delete mappedProps.sortedTeachLinks
  delete mappedProps.staticLinksAvailable

  const sortedTeachLinks = content?.data?.dashboardProgram?.teachLinks ?? []
  mappedProps.sortedTeachLinks = sortedTeachLinks

  const staticLinksAvailable =
    content?.data?.dashboardProgram?.staticLinks ?? []
  mappedProps.staticLinksAvailable = staticLinksAvailable

  return mappedProps
}

function Container(props) {
  const mappedProps = mapper(props)
  return <Component {...mappedProps} />
}

export default ContentDataGetter(Container, {
  entryId: ENTRY_ID,
  queryType: QUERY_TYPE,
})
