export const EMPTY_STATE_MESSAGE =
  'Program progress data will appear here when users select the Mark Complete button at the top of practices or activities'
export const MAIN_DESCRIPTION_TEXT = 'Help keep program implementation on track'
export const MAIN_TITLE_TEXT = 'Progress & Pacing Snapshot'
export const MODAL_CLOSE_BUTTON_TEXT = 'Close'
export const MODAL_LINK_TEXT = 'How are these calculated?'
export const OVERALL_PROGRESS_TITLE_TEXT = 'Overall Progress'
export const PROGRAM_NAME_EDUCATOR_PRACTICES = 'Educator Practices'
export const PROGRAM_NAME_SCHOOLWIDE_PRACTICES = 'Schoolwide Practices'
export const PROGRAM_NAME_STUDENT_ACTIVITIES = 'Student Activities'
