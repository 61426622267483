export const findExpiredAdminLicenses = licenses => {
  return licenses.filter(license => {
    const isAdminLicense = license.claims.find(
      claim => claim.toLowerCase() === 'admin',
    )

    if (isAdminLicense) return license
  })
}

export const findExpiredNonAdminLicenses = licenses => {
  return licenses.filter(license => {
    const isAdminLicense = license.claims.find(
      claim => claim.toLowerCase() === 'admin',
    )
    const isNonAdminLicense = license.claims.find(
      claim => claim.toLowerCase() === 'teacher',
    )
    if (!isAdminLicense && isNonAdminLicense) return license
  })
}

export const getExpiredLicensesWithClaims = sites => {
  const expiredLicenses = sites.reduce((expLicenseAcc, site) => {
    const { claims } = site
    site?.licenses.forEach(license => {
      if (
        license.isExpired &&
        !license.cancelledDate &&
        license.product?.programCodes
      ) {
        const {
          id,
          expirationDate,
          siteName,
          product: { programCodes, type },
        } = license
        const licenseWithClaims = {
          id,
          expirationDate,
          siteName,
          product: {
            programCodes,
            type,
          },
          claims,
        }
        expLicenseAcc.push(licenseWithClaims)
      }
    })
    return expLicenseAcc
  }, [])
  return expiredLicenses
}
