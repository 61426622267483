import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Hairline, TooltipContentWrapper } from 'secondstep-components'
import AdminTooltip from 'components/AdminTooltip'

export const BHUBodyWrapper = styled.div.attrs(({ classesCreated }) => ({
  padding: classesCreated === 0 ? '0' : '0.875rem 1.25rem 1.75rem',
  alignItems: classesCreated === 0 ? 'center' : 'inherit',
}))`
  align-items: ${({ alignItems }) => alignItems};
  background-color: ${themeGet('colors.linkLight')};
  border: 0.125rem solid ${themeGet('colors.paleGray')};
  border-radius: 0.625rem;
  margin-top: 1rem;
  padding: 1.625rem 1.25rem 1.75rem};

  ${themeGet('breakpoints.mobile')} {
    padding-bottom: 0.3125rem;
  }
`

export const BHUCardContainer = styled.div`
[class*='StyledBox-sc-13pk1d4-0'] {
  background-color: ${themeGet('colors.white')};
  padding-top: 0.75rem
`

export const BHUCardHeading = styled.p`
  height: 1.75rem;
  width: 34.188rem;
  color: #555555;
  font-family: ${themeGet('font.molde.medium')};
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.013rem;
  line-height: 1.35rem;
`

export const BHUCardSubHeading = styled.div`
  display: flex;
  justify-content: space-between;
`

export const BHUStyledHairline = styled(Hairline)`
  color: ${themeGet('colors.paleGray')};
  height: 0.125rem;
  margin: 0.688rem 0 0.938rem;
  opacity: 0.5;
  width: unset;

  ${themeGet('breakpoints.mobile')} {
    margin: 1.25rem 0 0.9375rem;
  }
`

export const LessonsCompleted = styled.p`
  margin-block-start: 0;
  margin-block-end: 0;
  font-family: ${themeGet('font.molde.regular')};
  font-size: 1rem;
  line-height: 1.1875rem;
  text-align: right;
  color: ${themeGet('colors.darkGray')};

  ${themeGet('breakpoints.mobile')} {
    text-align: left;
    line-height: 0.5625rem;
  }
`

export const LessonsTooltip = styled(AdminTooltip)`
  ${TooltipContentWrapper} {
    bottom: 2.125;
    left: -4.688rem;
    transform: none;
  }
`
