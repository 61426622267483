import React from 'react'

const PuzzleIcon = () => (
  <svg height="121px" version="1.1" viewBox="0 0 126 121" width="126px">
    <title>ss_siteadmin_spotillustration_puzzle</title>
    <g
      fill="none"
      fillRule="evenodd"
      id="ss_siteadmin_spotillustration_puzzle"
      stroke="none"
      strokeWidth="1"
    >
      <path
        d="M95.2633501,32.9825967 C92.8412567,32.9825967 92.633108,36.1805169 90.6525421,36.1805169 C88.6719761,36.1805169 87.9087644,33.12767 87.9087644,33.12767 L87.9087644,22.7712091 C87.9087644,21.6666396 87.0133339,20.7712091 85.9087644,20.7712091 L75.5459959,20.7712091 L75.5459959,20.7712091 C75.5459959,20.7712091 72.493149,21.5344208 72.493149,23.5149868 C72.493149,25.4955527 75.6910692,25.7037014 75.6910692,28.1257948 C75.6910692,30.5478882 72.2156174,32.2509227 69.4024568,32.2509227 C66.5829887,32.2509227 63.1138445,30.5478882 63.1138445,28.1257948 C63.1138445,25.7037014 66.3117647,25.4955527 66.3117647,23.5149868 C66.3117647,21.5344208 63.2589178,20.7712091 63.2589178,20.7712091 L52.9087644,20.7712091 C51.8041949,20.7712091 50.9087644,21.6666396 50.9087644,22.7712091 L50.9087644,55.7712091 C50.9087644,56.8757786 51.8041949,57.7712091 52.9087644,57.7712091 L85.9087644,57.7712091 C87.0133339,57.7712091 87.9087644,56.8757786 87.9087644,55.7712091 L87.9087644,45.4147481 L87.9087644,45.4147481 C87.9087644,45.4147481 88.6719761,42.3619012 90.6525421,42.3619012 C92.633108,42.3619012 92.8412567,45.5598214 95.2633501,45.5598214 C97.6854435,45.5598214 99.388478,42.0843696 99.388478,39.2712091 C99.388478,36.4580485 97.6854435,32.9825967 95.2633501,32.9825967 Z"
        fill="#B1C5D3"
        fillRule="nonzero"
        id="Path"
        stroke="#3C6E90"
        strokeWidth="2"
        transform="translate(75.1486, 39.2712) rotate(-166) translate(-75.1486, -39.2712)"
      />
      <path
        d="M34.8839909,29.3528989 C46.5202828,28.7370696 43.0886811,19.286129 37.9240157,22.5972733 C37.8785533,22.6314984 37.8186683,22.6380541 37.7679622,22.6144095 C37.7172562,22.5907649 37.6837838,22.5406783 37.6808545,22.4838854 C37.2903906,18.4394781 32.8466397,18.3503422 31.9778447,22.0106295 C31.3399089,24.3032279 33.765078,28.2341623 34.4814261,29.1654172 C34.5742067,29.293808 34.7259565,29.36457 34.8839909,29.3528989 Z"
        fillRule="nonzero"
        id="Path"
        stroke="#E77564"
        strokeWidth="1.5"
        transform="translate(37.2886, 24.3541) rotate(-51) translate(-37.2886, -24.3541)"
      />
      <path
        d="M50.5477986,12.9946443 L49.6906229,13.4452882 C49.3301461,13.6348021 48.8842905,13.4962093 48.6947766,13.1357324 C48.6193111,12.9921887 48.5932699,12.8277707 48.6206843,12.6679324 L48.7843903,11.7134532 C48.8400282,11.3890591 48.7324798,11.0580589 48.4967937,10.8283218 L47.803324,10.1523559 C47.5116921,9.86808504 47.5057248,9.40122363 47.7899956,9.10959174 C47.9031937,8.99346239 48.0515173,8.91788774 48.2120042,8.89456764 L49.1703557,8.75531101 C49.4960659,8.70798255 49.7776315,8.50341318 49.9232935,8.20826971 L50.3518813,7.33985588 C50.5321197,6.97465295 50.9742873,6.82470958 51.3394903,7.00494801 C51.484916,7.07671989 51.6026262,7.19443016 51.6743981,7.33985588 L52.102986,8.20826971 C52.248648,8.50341318 52.5302136,8.70798255 52.8559237,8.75531101 L53.8142753,8.89456764 C54.2173007,8.95313066 54.4965426,9.32732197 54.4379796,9.73034734 C54.4146595,9.89083416 54.3390848,10.0391577 54.2229555,10.1523559 L53.5294858,10.8283218 C53.2937997,11.0580589 53.1862512,11.3890591 53.2418891,11.7134532 L53.4055951,12.6679324 C53.4744401,13.0693292 53.2048535,13.4505355 52.8034566,13.5193805 C52.6436183,13.5467949 52.4792003,13.5207537 52.3356565,13.4452882 L51.4784808,12.9946443 C51.1871568,12.8414862 50.8391226,12.8414862 50.5477986,12.9946443 Z"
        fill="#E77564"
        id="Star"
      />
      <circle
        cx="25.0348789"
        cy="11.5217391"
        fill="#3C6E90"
        id="Oval"
        r="2.52173913"
      />
      <circle cx="17.556618" cy="32.0434783" id="Oval" r="2" stroke="#3C6E90" />
      <path
        d="M42.388478,44.7712091 L42.388478,55.12767 C42.388478,55.12767 41.6252662,58.1805169 39.6447003,58.1805169 C37.6641343,58.1805169 37.4559856,54.9825967 35.0338922,54.9825967 C32.6117988,54.9825967 30.9087644,58.4580485 30.9087644,61.2712091 C30.9087644,64.0843696 32.6117988,67.5598214 35.0338922,67.5598214 C37.4559856,67.5598214 37.6641343,64.3619012 39.6447003,64.3619012 C41.6252662,64.3619012 42.388478,67.4147481 42.388478,67.4147481 L42.388478,77.7712091 C42.388478,78.8757786 43.2839085,79.7712091 44.388478,79.7712091 L54.7512465,79.7712091 C54.7512465,79.7712091 57.8040934,79.0079973 57.8040934,77.0274314 C57.8040934,75.0468654 54.6061732,74.8387167 54.6061732,72.4166233 C54.6061732,69.9945299 58.0816249,68.2914955 60.8947855,68.2914955 C63.7142536,68.2914955 67.1833979,69.9945299 67.1833979,72.4166233 C67.1833979,74.8387167 63.9854776,75.0468654 63.9854776,77.0274314 C63.9854776,79.0079973 67.0383245,79.7712091 67.0383245,79.7712091 L77.388478,79.7712091 C78.0503465,79.7712091 78.6371232,79.4497029 79.0011544,78.9543445 L79,112 C79,113.104569 78.1045695,114 77,114 L10,114 C8.8954305,114 8,113.104569 8,112 L8,45 C8,43.8954305 8.8954305,43 10,43 L43.461035,42.9987871 C42.8234483,43.3330989 42.388478,44.0013576 42.388478,44.7712091 Z"
        fill="#3C6E90"
        id="Combined-Shape"
        stroke="#3C6E90"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M117.297496,69.4728463 C125.392253,57.8996804 113.80517,54.1249182 113.113752,61.5283627 C113.112516,61.5973216 113.074091,61.6594087 113.013672,61.6901938 C112.953253,61.7209789 112.880439,61.7155735 112.824011,61.6759927 C108.6699,59.0310081 105.26887,63.2086516 108.116463,66.7697066 C109.830064,69.0897111 115.394083,69.7067083 116.818044,69.7174319 C117.0099,69.724623 117.190719,69.6324914 117.297496,69.4728463 Z"
        fill="#E77564"
        fillRule="nonzero"
        id="Path"
        transform="translate(113.6118, 63.7178) rotate(70) translate(-113.6118, -63.7178)"
      />
      <path
        d="M94.9028899,82.1064978 L93.6048753,82.7889045 C93.1242395,83.0415897 92.5297654,82.8567992 92.2770802,82.3761634 C92.1764596,82.1847717 92.1417379,81.9655478 92.1782905,81.75243 L92.4261892,80.3070695 C92.4818272,79.9826754 92.3742787,79.6516752 92.1385926,79.421938 L91.0884767,78.398328 C90.6996342,78.0193002 90.6916778,77.3968184 91.0707056,77.0079758 C91.2216364,76.8531367 91.4194012,76.7523705 91.6333836,76.721277 L93.0846081,76.5104018 C93.4103182,76.4630733 93.6918838,76.2585039 93.8375458,75.9633605 L94.4865532,74.648328 C94.7268711,74.1613908 95.3164278,73.9614663 95.8033651,74.2017842 C95.9972661,74.29748 96.1542131,74.4544271 96.2499089,74.648328 L96.8989162,75.9633605 C97.0445782,76.2585039 97.3261438,76.4630733 97.651854,76.5104018 L99.1030785,76.721277 C99.6404456,76.7993611 100.012768,77.2982828 99.9346841,77.83565 C99.9035907,78.0496324 99.8028245,78.2473972 99.6479854,78.398328 L98.5978695,79.421938 C98.3621834,79.6516752 98.2546349,79.9826754 98.3102728,80.3070695 L98.5581716,81.75243 C98.6499648,82.2876258 98.290516,82.7959009 97.7553202,82.8876942 C97.5422024,82.9242467 97.3229785,82.8895251 97.1315868,82.7889045 L95.8335722,82.1064978 C95.5422481,81.9533397 95.1942139,81.9533397 94.9028899,82.1064978 Z"
        id="Star"
        stroke="#3C6E90"
        strokeWidth="1.5"
        transform="translate(95.3682, 78.8619) rotate(-27) translate(-95.3682, -78.8619)"
      />
      <circle cx="112.01314" cy="42.5" id="Oval" r="1.5" stroke="#3C6E90" />
      <circle cx="110.01314" cy="83.5" fill="#3C6E90" id="Oval" r="1.5" />
    </g>
  </svg>
)

export default PuzzleIcon
