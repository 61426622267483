import React from 'react'
import PropTypes from 'prop-types'
import { OutlinedButton, HeaderOne, HeaderThree } from 'secondstep-components'
import ClassCard from 'components/dashboard/ClassCard'
import CreateCourse from 'experienceComponents/CreateCourse'
import {
  StyledBackpackV2Icon,
  StyledTelescopeIcon,
  Wrapper,
} from './component.styles'

export const ProductLessons = React.forwardRef(
  (
    {
      classes,
      program,
      courseLinks,
      onClassClick,
      onCourseClick,
      title,
      contentfulCourses,
    },
    ref,
  ) => {
    return (
      <Wrapper>
        <header>
          <HeaderOne>{title}</HeaderOne>
        </header>

        <section>
          <header>
            <div className="spot">
              <StyledBackpackV2Icon aria-hidden="true" role="presentation" />
            </div>
            <div className="header-text">
              <div className="headline">
                <HeaderThree>My Classes</HeaderThree>
                <div className="spacer" />
                {!!classes && classes.length > 0 && (
                  <CreateCourse
                    contentfulCourses={contentfulCourses}
                    ref={ref}
                  />
                )}
              </div>
              <p>Teach lessons to students and track progress</p>
            </div>
          </header>
          <div>
            {(!classes || classes.length < 1) && (
              <div className="no-classes">
                <p>Classes you create will appear here</p>
                <CreateCourse
                  contentfulCourses={contentfulCourses}
                  emptyClasses={true}
                  ref={ref}
                />
              </div>
            )}
            {!!classes && classes.length > 0 && (
              <ul className="class-links">
                {classes.map(cl => {
                  const course = courseLinks.find(
                    c => cl.contentfulID === c.entryId,
                  )
                  return (
                    <li key={`class-card-li-${cl.instance}`}>
                      <ClassCard
                        className={cl.title}
                        dataTestId={`class-card-${cl.instance}`}
                        displayTitle={cl.title}
                        gradeDisplayTitle={course?.title}
                        instance={cl.instance}
                        onClick={onClassClick(course?.slug, cl.instance)}
                        program={program}
                        scoID={cl.scoID}
                        totalAmount={cl.totalLessons}
                        totalComplete={cl.completeLessons}
                      />
                    </li>
                  )
                })}
              </ul>
            )}
          </div>
        </section>
        <section id="explore-the-program">
          <header>
            <div className="spot">
              <StyledTelescopeIcon aria-hidden="true" role="presentation" />
            </div>
            <div>
              <HeaderThree>Explore the Program</HeaderThree>
              <p>Preview lessons and support</p>
            </div>
          </header>

          <ul className="course-links">
            {courseLinks &&
              courseLinks.map(c => (
                <li key={c.slug}>
                  <OutlinedButton onClick={onCourseClick(c.slug)}>
                    {c.title}
                  </OutlinedButton>
                </li>
              ))}
          </ul>
        </section>
      </Wrapper>
    )
  },
)

ProductLessons.propTypes = {
  classes: PropTypes.arrayOf(
    PropTypes.shape({
      contentfulID: PropTypes.string,
      completeLessons: PropTypes.number,
      instance: PropTypes.string,
      scoId: PropTypes.number,
      totalLessons: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
  contentfulCourses: PropTypes.arrayOf(
    PropTypes.shape({
      displayTitle: PropTypes.string,
    }),
  ).isRequired,
  courseLinks: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  onClassClick: PropTypes.func.isRequired,
  onCourseClick: PropTypes.func.isRequired,
  program: PropTypes.string,
  title: PropTypes.string.isRequired,
}
export default ProductLessons
