import React from 'react'
import PropTypes from 'prop-types'
import { toKebabCase } from 'utils/stringHelpers'
import VideoFullWidth from 'experienceComponents/VideoFullWidth'

import {
  Container,
  ThumbnailContainer,
  ThumbnailText,
} from './component.styles'
import { getThumbnailTextString } from './data'

const VideoThumbnailButton = ({
  description,
  entryId,
  thumbnailUrl,
  videoUrl,
  time = '',
  title = '',
  locale,
}) => {
  const thumbnailTextString = getThumbnailTextString(time, title)
  const videoFullWidthAvailable = thumbnailUrl && videoUrl
  const dataTestId = title ? toKebabCase(title) : 'title'

  return (
    <Container>
      <ThumbnailContainer>
        {videoFullWidthAvailable && (
          <VideoFullWidth
            dataTestId={dataTestId}
            description={description}
            displayTitle={title}
            id={entryId}
            locale={locale}
            videoThumbnailUrl={thumbnailUrl}
            videoUrl={videoUrl}
          />
        )}
      </ThumbnailContainer>
      {thumbnailTextString && (
        <ThumbnailText>{thumbnailTextString}</ThumbnailText>
      )}
    </Container>
  )
}

VideoThumbnailButton.propTypes = {
  description: PropTypes.string,
  entryId: PropTypes.string,
  locale: PropTypes.string,
  thumbnailUrl: PropTypes.string.isRequired,
  time: PropTypes.string,
  title: PropTypes.string,
  tracks: PropTypes.array,
  transcriptExtended: PropTypes.string,
  transcriptSource: PropTypes.string,
  videoUrl: PropTypes.string.isRequired,
  videoUrlExtended: PropTypes.string,
}

export default VideoThumbnailButton
