import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Logos from 'components/Footer/footerLogos'

export default class FooterLogos extends Component {
  static propTypes = {
    navigationEvent: PropTypes.func,
  }

  render() {
    const { navigationEvent } = this.props
    return <Logos navigationEvent={navigationEvent} />
  }
}
