import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import MSSupplementalUnitModal from './component'
import { StyledCloseButton, StyledModal } from './container.styles'
import { OFF, ON } from './constants'

const MSSupplementalUnitModalContainer = props => {
  const { handleClose, mspSiteContexts } = props
  const [bpuStatus, setBpuStatus] = useState(OFF)

  const getk8SiteStatuses = () => {
    mspSiteContexts?.forEach(site => {
      if (site?.sitePreferences?.hasOwnProperty('mspDigital')) {
        const preferences = site?.sitePreferences?.mspDigital
        if (preferences?.bullyingHarassmentUnitEnabled) {
          setBpuStatus(ON)
        }
      }
    })
  }

  useEffect(() => {
    if (mspSiteContexts?.length > 0) {
      getk8SiteStatuses()
    }
  }, [mspSiteContexts])

  return (
    <StyledModal
      dataTestId="step-modal-modal"
      isModalOpen={true}
      modalClose={() => handleClose}
    >
      <StyledCloseButton onClick={handleClose} />
      <MSSupplementalUnitModal
        bpuStatus={bpuStatus}
        handleClose={handleClose}
      />
    </StyledModal>
  )
}

MSSupplementalUnitModalContainer.propTypes = {
  handleClose: PropTypes.func,
  mspSiteContexts: PropTypes.array,
}

const mapStateToProps = () => {}
const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MSSupplementalUnitModalContainer)
