import React from 'react'
import PropTypes from 'prop-types'

import { HeaderTwo } from 'secondstep-components'
import { BodyWrapper, ContentBlockWrapper, Wrapper } from './component.styles'

import contentComponentMap from './contentComponentMap'

function getName(item) {
  if (!item) return null

  const name = item?.contentType?.id ?? item?.contentType

  return name ? name.toLowerCase() : null
}

const ContentBlock = ({
  content,
  displayTitle,
  location,
  presentationButtonLabel,
}) => {
  const { pathname } = location
  return (
    <Wrapper>
      <HeaderTwo>{displayTitle}</HeaderTwo>
      <BodyWrapper>
        {content?.map((entry, index) => {
          const name = getName(entry)
          const { Component, propsParser } = contentComponentMap[name] || {}
          const componentProps = propsParser?.(entry)

          if (!Component || !componentProps) return null

          if (name === 'presentation') {
            if (!presentationButtonLabel) return null
            componentProps.presentationButtonLabel = presentationButtonLabel
          }

          return (
            <ContentBlockWrapper
              key={`content-block-${name}-${index}`}
              name={name}
            >
              <Component {...componentProps} pathname={pathname} />
            </ContentBlockWrapper>
          )
        })}
      </BodyWrapper>
    </Wrapper>
  )
}

ContentBlock.propTypes = {
  content: PropTypes.array,
  displayTitle: PropTypes.string,
  location: PropTypes.object,
  presentationButtonLabel: PropTypes.string,
}

export default ContentBlock
