import contentTypes from './contents'
import { landingBanner as parseLandingBanner } from './rootContents'
import { anchorNavigation as parseAnchorNavigation } from './derivedContents'
import { toCamelCase } from 'utils/stringHelpers'

const failedParseContentResult = {
  data: { error: 'Failed to parse content' },
  name: 'error',
}

function getContent(entry) {
  const { fields, content: initialContent } = entry || {}
  const { content = [] } = fields || {}

  return initialContent || content
}

export function getContentTypeName(contentItem) {
  const { contentType } = contentItem || {}

  if (typeof contentType === 'string') {
    return toCamelCase(contentType)
  }

  const { id } = contentType || {}

  return id
}

function parseContent(entry) {
  const content = getContent(entry)
  const parsedContentList = content.map(contentItem => {
    const contentTypeName = getContentTypeName(contentItem)

    const contentFactory =
      contentTypes && contentTypeName && contentTypes[contentTypeName]

    if (!contentFactory || !contentItem) {
      return failedParseContentResult
    }

    const contentSpecificData = contentFactory(contentItem)
    const parsedContent = { data: contentSpecificData, name: contentTypeName }

    return parsedContent
  })

  return parsedContentList
}

function parseRootContent(entry) {
  const landingBanner = parseLandingBanner(entry)

  return {
    landingBanner,
  }
}

function parseDerivedContent(
  entry,
  isPreviewMode = false,
  highSchoolFlagOn = false,
) {
  const anchorNavigation = parseAnchorNavigation(
    entry,
    isPreviewMode,
    highSchoolFlagOn,
  )

  return {
    anchorNavigation,
  }
}

export default function parseEntry(
  entry,
  isPreviewMode = false,
  highSchoolFlagOn = false,
) {
  if (entry) {
    const rootContent = parseRootContent(entry)
    const derivedContent = parseDerivedContent(
      entry,
      isPreviewMode,
      highSchoolFlagOn,
    )
    const content = parseContent(entry)
    const parsedEntry = { content, derivedContent, rootContent }

    return parsedEntry
  }

  return entry
}
