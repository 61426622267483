import React from 'react'
import PropTypes from 'prop-types'
import { SkipToMainWrapper } from '../component.styles'

const SkipToMain = ({ pathname }) => {
  const href = pathname.includes('/dashboard')
    ? '#dashboard-header-tabs'
    : '#main-content'
  return (
    <SkipToMainWrapper>
      <a href={href}>Skip to Main</a>
    </SkipToMainWrapper>
  )
}

SkipToMain.propTypes = {
  pathname: PropTypes.string,
}

export default SkipToMain
