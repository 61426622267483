import React from 'react'
import PropTypes from 'prop-types'

import LicenseCardNotification from './component'
import { LicenseCardNotificationWrapper } from './component.styles'

const LicenseCardNotificationContainer = ({
  unassignedLicenses,
  navigationEvent,
}) => {
  return (
    <LicenseCardNotificationWrapper>
      <LicenseCardNotification
        navigationEvent={navigationEvent}
        unassignedLicenses={unassignedLicenses}
      />
    </LicenseCardNotificationWrapper>
  )
}

LicenseCardNotificationContainer.propTypes = {
  navigationEvent: PropTypes.func,
  unassignedLicenses: PropTypes.array,
}

export default LicenseCardNotificationContainer
