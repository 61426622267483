export const unitTitleMap = {
  'ms g6 u1': 'Mindset & Goals',
  'ms g6 u2': 'Developing a Positive Sense of Self',
  'ms g6 u3': 'Thoughts, Emotions, and Decisions',
  'ms g6 u4': 'Managing Relationships & Social Conflict',
  'ms g6 rbh, opt-in': 'Recognizing Bullying & Harassment',
  'ms g7 u1': 'Mindset & Goals',
  'ms g7 u2': 'Developing a Positive Sense of Self',
  'ms g7 u3': 'Thoughts, Emotions, and Decisions',
  'ms g7 u4': 'Managing Relationships & Social Conflict',
  'ms g7 rbh, opt-in': 'Recognizing Bullying & Harassment',
  'ms g8 u1': 'Mindset & Goals',
  'ms g8 u2': 'Developing a Positive Sense of Self',
  'ms g8 u3': 'Thoughts, Emotions, and Decisions',
  'ms g8 u4': 'Managing Relationships & Social Conflict',
  'ms g8 rbh, opt-in': 'Recognizing Bullying & Harassment',
}

export const lessonTitleMap = {
  'ms g6 u1 l01a-b *choice': 'Starting Middle School/Helping New Students',
  'ms g6 u1 l01a': 'Starting Middle School',
  'ms g6 u1 l01b': 'Helping New Students',
  'ms g6 u1 l02': 'How to Grow Your Brain',
  'ms g6 u1 l03': 'Trying New Strategies',
  'ms g6 u1 l04': 'Making Goals Specific',
  'ms g6 u1 l05': 'Breaking Down Your Goals',
  'ms g6 u1 l06': 'Monitoring Your Progress',
  'ms g6 u1 l07': 'Performance Task: Bringing It All Together',
  'ms g6 u2 l08': 'What Are Guiding Principles?',
  'ms g6 u2 l09': 'Your Guiding Principles',
  'ms g6 u2 l10': 'Making Decisions',
  'ms g6 u2 l11': 'Multiple Guiding Principles',
  'ms g6 u2 l12': 'Thinking Short-Term and Long-Term',
  'ms g6 u2 l13': 'Performance Task: Using Your Guiding Principles',
  'ms g6 u3 l14': 'What Emotions Tell You',
  'ms g6 u3 l15': 'Emotions and Your Brain',
  'ms g6 u3 l16': 'How Emotions Affect Your Decisions',
  'ms g6 u3 l17': 'Managing Your Emotions',
  'ms g6 u3 l18': 'What Works Best for You?',
  'ms g6 u3 l19': 'Performance Task: Raising Awareness About Managing Emotions',
  'ms g6 u4 l20': `You're Changing`,
  'ms g6 u4 l21': 'Why Conflicts Escalate',
  'ms g6 u4 l22': 'Considering Multiple Perspectives',
  'ms g6 u4 l23': 'Respectful Communication',
  'ms g6 u4 l24': 'Resolving Challenging Conflicts',
  'ms g6 u4 l25': 'Making Amends',
  'ms g6 u4 l26': 'Performance Task: Conflict Solvers',
  'ms g6 u4 l27': 'High School Challenges',
  'ms g6 rbh l01': 'Common Types of Bullying',
  'ms g6 rbh l02': 'Recognizing Bullying',
  'ms g6 rbh l03': 'Responding to Online Bullying',
  'ms g6 rbh l04': 'How to Be an Upstander',
  'ms g6 rbh l05': 'Standing Up and Staying Safe',
  'ms g6 rbh l06': 'Performance Task: Raising Awareness About Bullying',
  'ms g7 u1 l01a-b *choice': 'Starting Middle School/Helping New Students',
  'ms g7 u1 l01a': 'Starting Middle School',
  'ms g7 u1 l01b': 'Helping New Students',
  'ms g7 u1 l02': 'Creating New Pathways in Your Brain',
  'ms g7 u1 l03': 'Learning from Mistakes and Failure',
  'ms g7 u1 l04': 'Identifying Roadblocks',
  'ms g7 u1 l05': 'Overcoming Roadblocks 1',
  'ms g7 u1 l06': 'Overcoming Roadblocks 2',
  'ms g7 u1 l07': 'Performance Task: Advice on Roadblocks',
  'ms g7 u2 l08': 'What is Self-Concept',
  'ms g7 u2 l09': 'Your Self-Concept',
  'ms g7 u2 l10': 'Influences on Self-Concept',
  'ms g7 u2 l11': 'Changes in Self-Concept',
  'ms g7 u2 l12': 'Your Future Self-Concept',
  'ms g7 u2 l13': 'Performance Task: Who Will You Become?',
  'ms g7 u3 l14': 'Emotions Matter',
  'ms g7 u3 l15': 'Feel, Think, Do',
  'ms g7 u3 l16': 'Unhelpful Thoughts',
  'ms g7 u3 l17': 'Reframe the Situation',
  'ms g7 u3 l18': 'Practicing Positive Self-Talk',
  'ms g7 u3 l19': 'Performance Task: Making Better Decisions',
  'ms g7 u4 l20': 'What Makes a Conflict Escalate?',
  'ms g7 u4 l21': 'Keeping Your Cool in a Conflict',
  'ms g7 u4 l22': 'Conflicts and Perspectives',
  'ms g7 u4 l23': 'Resolving Conflict Part 1',
  'ms g7 u4 l24': 'Resolving Conflict Part 2',
  'ms g7 u4 l25': 'Taking Responsibility for Your Actions',
  'ms g7 u4 l26': 'Performance Task: Tips for Resolving Conflicts',
  'ms g7 rbh l01': 'What is Harassment?',
  'ms g7 rbh l02': 'What is Sexual Harassment?',
  'ms g7 rbh l03': 'The Effects of Sexual Harassment',
  'ms g7 rbh l04': 'Gender-Based Harassment',
  'ms g7 rbh l05': 'Our Rights and Responsibilities',
  'ms g7 rbh l06': 'Performance Task: Preventing Harassment',
  'ms g8 u1 l01': 'Welcome!',
  'ms g8 u1 l02': 'Who Am I? My Identity',
  'ms g8 u1 l03': 'My Interests and Strengths',
  'ms g8 u1 l04': 'Harnessing My Strengths',
  'ms g8 u1 l05': 'Pursuing My Interests',
  'ms g8 u1 l06': 'My Future Self',
  'ms g8 u1 l07': 'Performance Task: My Path Forward',
  'ms g8 u2 l08': 'Agency',
  'ms g8 u2 l09': 'Sources of Confidence',
  'ms g8 u2 l10': 'How to Build Confidence 1',
  'ms g8 u2 l11': 'How to Build Confidence 2',
  'ms g8 u2 l12': 'Agency and Confidence',
  'ms g8 u2 l13': 'Performance Task: Your Confidence-Building Plan',
  'ms g8 u3 l14': 'Understanding Stress and Anxiety',
  'ms g8 u3 l15': 'Where Does Stress Come From?',
  'ms g8 u3 l16': 'Can Stress Help You Grow?',
  'ms g8 u3 l17': 'Strategies for Managing Stress',
  'ms g8 u3 l18': 'Changing Strategies and Getting Help',
  'ms g8 u3 l19': 'Performance Task: My Stress-Management Plan',
  'ms g8 u4 l20': 'My Values',
  'ms g8 u4 l21': 'Values and Relationships',
  'ms g8 u4 l22': `Respecting Others' Perspectives`,
  'ms g8 u4 l23': 'Finding the Best Solution',
  'ms g8 u4 l24': 'Making Things Right',
  'ms g8 u4 l25': 'Unhealthy Relationships',
  'ms g8 u4 l26': 'Performance Task: Guide to Healthy Relationships',
  'ms g8 u4 l27': 'High School Challenges',
  'ms g8 rbh l01': 'Understanding Bullying',
  'ms g8 rbh l02': 'Social Factors that Contribute to Bullying',
  'ms g8 rbh l03': 'Environmental Factors that Contribute to Bullying',
  'ms g8 rbh l04': 'Speak Up and Start a Movement',
  'ms g8 rbh l05': 'Be Inclusive and Ask for a Change',
  'ms g8 rbh l06': 'Performance Task: Stand Up for Change!',
}
