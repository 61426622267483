import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  Accordion,
  BodyCopy,
  ContainedButton,
  HeaderTwo,
  TooltipContentWrapper,
} from 'secondstep-components'
import AdminK8UsersCard from 'components/AdminK8UsersCard'
import AdminSortButton from 'components/AdminSortButton'

const breakpoints = {
  customExtraLarge: '@media screen and (max-width: 1315px)',
  customLarge: '@media screen and (max-width: 1250px)',
  customMedium: '@media screen and (max-width: 1100px)',
  customTablet: '@media screen and (max-width: 860px)',
}

export const Bullet = styled.span`
  display: inline-block;
  height: 0.8125rem;
  width: 0.8125rem;
  border-radius: 50%;
  background: ${themeGet('colors.yellow')};
`

export const ContentWrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
`

export const CountCopy = styled.div`
  font-family: ${themeGet('font.molde.medium')};
`

export const CountWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  line-height: normal;
  color: ${themeGet('colors.darkGray')};
  gap: 0.5rem;
  flex: 1;
`

export const FixedHeaderWrapper = styled.div.attrs(props => ({
  boxShadow: props.isScrolled
    ? `${themeGet('colors.blueOpaque')(props)} 0px 0.2px 7px`
    : 'none',
  position: props.isScrolled ? 'fixed' : 'relative',
  top: props.isScrolled ? '0' : 'auto',
  width: props.isScrolled ? '100vw' : 'auto',
  zIndex: props.isScrolled ? '100' : '1',
}))`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  width: 100%;
  padding: 0;
  position: ${({ position }) => position};
  top: ${({ top }) => top};
  left: 0;
  background: ${themeGet('colors.white')};
  box-shadow: ${({ boxShadow }) => boxShadow};
  z-index: ${({ zIndex }) => zIndex};
`

export const HeaderRightBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 0.375rem;

  ${breakpoints.customTablet} {
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 0.375rem;
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${breakpoints.customTablet} {
    flex-direction: column;
  }
`

export const LeftBox = styled.div`
  width: 100%;
  min-width: 15.5rem;
`

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 550px;

  svg {
    color: ${themeGet('colors.darkBlue')};
  }

  p {
    margin-top: 0.25rem;
    margin-left: 0.5rem;
    font-size: 1.125rem;
    font-family: ${themeGet('font.molde.medium')};
    color: ${themeGet('colors.darkGray')};
  }
`

export const MultiSiteLeftBox = styled(LeftBox)`
  max-width: 18.125rem;
`

export const NameWrapper = styled.span`
  font-family: ${themeGet('font.molde.medium')};
  color: ${themeGet('colors.darkBlue')};
  height: 100%;
  line-height: 1.3;
  flex: 1;
`

export const NoClassesContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
`

export const NoClassesCopy = styled(CountCopy)`
  height: 1.0625rem;
`

export const PaginationButton = styled(ContainedButton)`
  // breaking from theme, roll an admin theme after the design dust settles?
  :disabled {
    background: ${themeGet('colors.white')};
    color: ${themeGet('colors.darkGray')};
    border-color: ${themeGet('colors.buttons.primaryBackgroundDisabled')};
  }
`

export const PaginationIndexContainer = styled.div`
  color: ${themeGet('colors.darkGray')};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1;
  gap: 0.625rem;
`

export const PaginationPageWrapper = styled.div`
  font-family: ${themeGet('font.molde.medium')};
`

export const PaginationSiteWrapper = styled.div``

export const PanelWrapper = styled.div`
  display: flex;
  direction: row;
  align-items: center;
  width: 100%;
`

export const RightBox = styled.div`
  width: 100%;
`

export const SingleSite = styled.div`
  ${PanelWrapper} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-bottom: 0.875rem;
  }
`

export const SingleSiteLeftBox = styled(LeftBox)`
  max-width: 20.4375rem;
`

export const SiteCollapsible = styled(Accordion).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  width: 100%;
  border: 2px solid
    ${themeGet('colors.backgrounds.secondary', 'colors.paleGray')};
  background: ${themeGet('colors.paleGray')};

  &:hover {
    outline: 2px solid ${themeGet('colors.grayBlue')};
  }

  svg {
    color: ${themeGet('colors.blue')};
    margin-right: 0;
  }

  [class*='AccordionPanel'] {
    padding: 1.25rem;
    background: ${themeGet('colors.paleGray')};
  }

  [class*='AccordionTitleWrapper'] {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  [class*='AccordionTitle'] {
    width: 100%;
  }

  [class*='AccordionContentWrapper'] {
    width: 100%;
    margin: 0;
    overflow: visible;
    [class*='AccordionContent'] {
      padding: 0 1.25rem 1.25rem 1.25rem;
      background: ${themeGet('colors.paleGray')};
    }
  }
`

export const SiteListContainer = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
`

export const SiteListFooter = styled.div`
  padding-top: 2.5rem;
  padding-bottom: 1.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 7.5rem;
`

export const SiteListHeader = styled.div.attrs(({ isScrolled }) => ({
  margin: isScrolled ? '0 1.25rem' : '0',
  mobileLargePaddingRight: isScrolled ? '1.3125rem' : '0',
  mobilePadding: isScrolled
    ? '1.125rem 0.3125rem 1.0625rem 1.75rem'
    : '0 0 1.0625rem',
  padding: isScrolled
    ? '1.125rem 5.6875rem 0.9375rem 1.25rem'
    : '0 4.375rem 0.9375rem 0',
  tabletPaddingRight: isScrolled ? '6.375rem' : '5.1rem',
}))`
  display: flex;
  width: 100%;
  max-width: 1366px;
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  box-sizing: border-box;

  .school,
  .classes,
  .lessons,
  .unit {
    flex: none;
  }

  .school {
    width: 29.2%;
  }

  .classes {
    width: 18.2%;
    padding-left: 0.125rem;
  }

  .lessons {
    width: 18.2%;
  }

  .unit {
    display: flex;
    width: 61px;
    padding-left: 2.3125rem;
  }

  ${breakpoints.customExtraLarge} {
    .unit {
      padding-left: 2rem;
    }
  }

  ${breakpoints.customLarge} {
    .school {
      width: 25.2%;
    }
  }

  ${breakpoints.customMedium} {
    justify-content: space-between;

    .school,
    .classes,
    .lessons {
      width: auto;
    }

    .classes {
      margin-left: 10.5rem;
    }

    .unit {
      display: none;
    }
  }

  ${breakpoints.customTablet} {
    padding-right: ${({ tabletPaddingRight }) => tabletPaddingRight};

    .classes {
      display: none;
    }

    .lessons {
      margin-right: 0;
    }
  }

  ${themeGet('breakpoints.mobileLarge')} {
    padding-right: ${({ mobileLargePaddingRight }) => mobileLargePaddingRight};
  }

  ${themeGet('breakpoints.mobile')} {
    padding: ${({ mobilePadding }) => mobilePadding};

    .lessons {
      padding-right: 0.4375rem;
    }
  }
`

export const StyledAdminK8UsersCard = styled(AdminK8UsersCard)`
  margin-bottom: 1.25rem;
`

export const StyledAdminSortButton = styled(AdminSortButton).attrs(
  ({ className }) => ({
    position: className?.includes('school')
      ? `
    top: 30px;
    left: 76px;

    :: after {
      top: -54px;
      left: 39px;
    }
  `
      : `
    top: 30px;
    left: -76px;

    :: after {
      top: -54px;
      right: 24px;
      left: unset;
    }
  `,
  }),
)`
  ${TooltipContentWrapper} {
    ${({ position }) => position};
  }

  ${themeGet('breakpoints.mobile')} {
    [class*='StyledTooltip-sc'] {
      display: none;
    }
  }
`

export const StyledBodyCopy = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};
`

export const StyledHeaderTwo = styled(HeaderTwo)`
  margin-bottom: 0.6875rem;
  line-height: 1;
`

export const StyledHr = styled.hr.attrs(({ isScrolled }) => ({
  margin: isScrolled ? '0.9375rem 0 3.3125rem' : '0.9375rem 0 1.125rem',
}))`
  width: 100%;
  border: 1px solid ${themeGet('colors.paleGray')};
  margin: ${({ margin }) => margin};
`

export const UnitsWrapper = styled.div`
  display: flex;
  margin-left: auto;

  ${breakpoints.customMedium} {
    display: none;
  }
`

export const Wrapper = styled.div.attrs(({ monitorView }) => ({
  padding: monitorView ? '0' : '1.5rem 1.25rem 1.25rem',
}))`
  width: 100%;
  padding: ${({ padding }) => padding};
  box-sizing: border-box;
  border-radius: 0.625rem;
  background: ${themeGet('colors.backgrounds.primary', 'colors.white')};

  ${themeGet('breakpoints.mobileLarge')} {
    margin-bottom: 1.25rem;
  }
`
