/**
 * While it would be desireable to refactor the UnitComponent in particular
 * to take a smaller subset of this "LMSClass" object, it's simply far more
 * expedient to use this conversion from a Contentful course to an LMSClass
 */

export function isChoice(contentfulItem) {
  return !!(contentfulItem?.contentType?.id === 'choiceCurriculum')
}

export function collapseChoice(contentfulLesson) {
  if (!isChoice(contentfulLesson)) return {}
  return {
    choices: [
      toInstanceLesson(contentfulLesson.item1Lesson),
      toInstanceLesson(contentfulLesson.item2Lesson),
    ],
  }
}

export function toInstanceLesson(contentfulLesson) {
  return {
    ...collapseChoice(contentfulLesson),
    attempted: 'preview',
    contentfulID: contentfulLesson.id,
    doneAt: null,
    is_done: null,
    progress: null,
    score: null,
    time: 'P0D',
    updatedAt: null,
  }
}

export function toInstanceUnit(contentfulUnit) {
  return {
    attempted: 'preview',
    completeLessons: 0,
    contentfulID: contentfulUnit.id,
    doneAt: null,
    is_done: null,
    lessonStartingIndex: 1,
    progress: null,
    score: null,
    time: 'P0D',
    totalLessons: contentfulUnit.lessons?.length ?? 0,
    lessons: contentfulUnit.lessons?.map(toInstanceLesson) ?? undefined,
    updatedAt: null,
  }
}
