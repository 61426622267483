import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  HeaderSix,
  HeaderTwo,
  Modal,
  ModalContentWrapper,
  CloseButton,
  OutlinedButton,
} from 'secondstep-components'
import ContinueButton from 'components/ContinueButton'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'

export const StyledFlowLabel = styled(HeaderSix)`
  letter-spacing: 0.2rem;
  color: ${themeGet('colors.gray1200')};
  font-size: 0.8rem;
`

export const StyledModal = styled(Modal)`
  ${ModalContentWrapper} {
    box-sizing: border-box;
    width: ${props => (props.wide ? '750px' : '650px')};
    max-height: 100%;
    overflow: auto;
    font-family: ${themeGet('font.molde.regular')};
  }
`

export const StyledCloseButton = styled(CloseButton)`
  display: flex;
  align-self: flex-end;
  z-index: 210;
  font-weight: 400;

  svg {
    height: 21px;
    width: 21px;
    color: ${themeGet('colors.gray1200')};

    g {
      stroke-width: 1.5;
    }
  }
`

export const PacingContinue = styled(ContinueButton)`
  width: 162px;
`

export const ModalHeader = styled(HeaderTwo)`
  margin-bottom: 18px;
  font-family: ${themeGet('font.sharpSlab.semibold')};

  ${themeGet('breakpoints.mobile')} {
    font-size: 1.438rem;
    margin-bottom: 6px;
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 40px;
  align-self: center;
  margin-bottom: 20px;

  ${themeGet('breakpoints.mobile')} {
    margin-top: 28px;
    margin-bottom: 8px;
    justify-content: space-around;
    width: 100%;
  }
`

export const ConfirmButton = styled(ContinueButton)`
  margin-left: 20px;
  width: 162px;

  ${themeGet('breakpoints.mobile')} {
    margin-left: 0;
    width: 138px;
  }
`

export const CancelButton = styled(OutlinedButton)`
  width: 162px;

  ${themeGet('breakpoints.mobile')} {
    width: 138px;
  }
`
