import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { ALL_LESSONS_KEY } from './constants'
import AdminLessonProgressCard from './component'

const AdminLessonProgressCardContainer = ({
  bhuStatus,
  lastUpdatedAt,
  lessonSelectedRow,
  units,
  siteId,
  summary,
}) => {
  const selectedTab = lessonSelectedRow?.selectedUnitTab ?? ALL_LESSONS_KEY

  return (
    <AdminLessonProgressCard
      bhuStatus={bhuStatus}
      lastUpdatedAt={lastUpdatedAt}
      selectedTab={selectedTab}
      siteId={siteId}
      summary={summary}
      units={units}
    />
  )
}

const mapStateToProps = ({
  userSessionManager: {
    adminDashboard: { lessons },
  },
}) => {
  return {
    lessonSelectedRow: lessons.lessonSelectedRow,
  }
}

AdminLessonProgressCardContainer.propTypes = {
  bhuStatus: PropTypes.bool,
  lastUpdatedAt: PropTypes.string,
  lessonSelectedRow: PropTypes.object,
  siteId: PropTypes.number,
  summary: PropTypes.object,
  units: PropTypes.array,
}

export default connect(mapStateToProps, null)(AdminLessonProgressCardContainer)
