import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const HeadingSpacer = styled.div`
  margin-top: 1.5rem;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
  box-sizing: border-box;
  padding: 1.3125rem 1.25rem 0 1.25rem;

  @media screen and (max-width: ${themeGet('breakpoints.sizes.mobile')}) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  & nav.breadcrumbs {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }
`

export const TopRow = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 1rem;
`
