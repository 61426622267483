import { domains } from 'config/env'

export const AUSTRALIAN_LOCALE = 'en-AU'
export const ENGLISH_LOCALE = 'en-US'
export const SPANISH_LOCALE = 'es-US'

export const DEFAULT_CONTENTFUL_URL = domains.CONTENTFUL_FUNC_APP
export const DEFAULT_INCLUDE = 3
export const DEFAULT_LOCALE = ENGLISH_LOCALE
export const DEFAULT_FORMAT = true
export const DEFAULT_PREVIEW = process.env.CONTENTFUL_PREVIEW
