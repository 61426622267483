import React from 'react'
import PropTypes from 'prop-types'
import { SecondStepLogo } from 'secondstep-components'
import {
  SessionTimeoutPromptContainer,
  PromptModalBackdrop,
  LogoutButton,
  ContinueButton,
} from './component.styles'

const SessionTimeoutPrompt = props => {
  return (
    <PromptModalBackdrop>
      <SessionTimeoutPromptContainer>
        <SecondStepLogo width="15rem" />
        <h2>Are you still there?</h2>
        <br />
        <h4>
          For your protection, we'll close your session soon due to inactivity.
        </h4>
        <br />
        <ContinueButton
          dataTestId="btn-session-timeout-continue"
          onClick={props.onContinueSession}
        >
          Continue Session
        </ContinueButton>
        <LogoutButton
          dataTestId="btn-session-timeout-logout"
          onClick={props.onLogout}
        >
          Logout
        </LogoutButton>
      </SessionTimeoutPromptContainer>
    </PromptModalBackdrop>
  )
}

SessionTimeoutPrompt.propTypes = {
  onContinueSession: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
}

export default SessionTimeoutPrompt
