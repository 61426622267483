import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'
import { Accordion } from 'secondstep-components'

export const CompleteIconWrapper = styled.div`
  display: inline-block;
  img {
    margin-bottom: -0.35rem;
  }
`

export const GradeButton = styled(Button)`
  background-color: ${themeGet('colors.white')};
  border: 0.125rem solid ${themeGet('colors.buttons.outlined.label')};
  color: ${themeGet('colors.buttons.outlined.label')};
  border-radius: 0.313rem;
  cursor: pointer;
  width: 7.5rem;
  height: 2.688rem;
  text-align: center;
  font-family: ${themeGet('fonts.moldeMedium16.fontFamily')};
  padding-top: 0.1rem;
  :hover {
    border: 0.125rem solid ${themeGet('colors.buttons.outlined.labelHover')};
    color: ${themeGet('colors.buttons.outlined.labelHover')};
  }
`

export const GradeDisplay = styled.div`
  display: flex;
  gap: 1.25rem;
  margin-bottom: 1.063rem;
  flex-wrap: wrap;
`

export const StudentActivitiesDescription = styled.div`
  font-size: 1rem;
  margin-bottom: 0.938rem;
  line-height: 1.15rem;
`

export const StudentActivitiesTitle = styled.h4`
  font-family: ${themeGet('fonts.moldeMedium18.fontFamily')};
  font-size: ${themeGet('fonts.moldeMedium18.fontSize')};
  // a little different than fonts.moldeMedium18.lineHeight:
  line-height: 1.25rem;
  margin: 0;
  margin-bottom: 0.375rem;
`

export const StyledAccordion = styled(Accordion).attrs({
  panelColor: themeGet('colors.hsSecondaryLightGreen'),
})`
  margin-top: 1rem;
  button[class*='AccordionPanel'] {
    background-color: ${themeGet('colors.hsSecondaryLightGreen')};
    padding-bottom: 0;
    padding-left: 1.25rem;
    padding-top: 1rem;
  }

  [class*='AccordionTitleWrapper'] {
    // put the caret on the right side of the title:
    flex-direction: row-reverse;
    justify-content: space-between;
    width: 100%;
  }

  [class*='AccordionContent'] {
    border: none;
    padding: 0.175rem 0.625rem 0;
    background-color: ${themeGet('colors.hsSecondaryLightGreen')};
  }

  [class*='StyledCaret'] {
    margin-top: -0.5rem;
    margin-right: -1.5rem;
    color: ${themeGet('colors.hsSecondaryGreen')};
  }
  :hover {
    [class*='StyledCaret'] {
      color: ${themeGet('colors.highschool.pink1')};
    }
  }
`

export const StyledAccordionWrapper = styled.div`
  margin-top: 1.25rem;
`
