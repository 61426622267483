import { getEntry } from '../../store/operations'
import { createEntryKey, getHighestIncludeOption } from '../../utils'
import {
  DEFAULT_CONTENTFUL_URL,
  DEFAULT_FORMAT,
  DEFAULT_INCLUDE,
  DEFAULT_LOCALE,
  DEFAULT_PREVIEW,
  ENGLISH_LOCALE,
  SPANISH_LOCALE,
} from '../../data'

export default class ContentDataEntryManager {
  constructor(entryRequestOptions, entryResultOptions) {
    const {
      entryId = null,
      locale = DEFAULT_LOCALE,
      format = ContentDataEntryManager.CONTENT_FORMAT,
      url = ContentDataEntryManager.CONTENT_URL,
      include = entryRequestOptions?.include || DEFAULT_INCLUDE,
      preview = DEFAULT_PREVIEW,
      entryIdProp = false,
      log,
    } = entryRequestOptions || {}

    const { mapper, spread = false } = entryResultOptions || {}

    this._entryId = entryId
    this._locale = locale
    this._mapper = mapper
    this._spread = spread
    this._format = format
    this._url = url
    this._include = include
    this._preview = preview
    this._log = log
    this._entryIdProp = entryIdProp
    log &&
      console.log('ContentDataEntryManager constructor', {
        entryId,
        format,
        include,
        locale,
        mapper,
        preview,
        spread,
        url,
      })
  }

  static CONTENT_URL = DEFAULT_CONTENTFUL_URL
  static CONTENT_FORMAT = DEFAULT_FORMAT
  static createEntryKey = createEntryKey
  static ENGLISH = ENGLISH_LOCALE
  static SPANISH = SPANISH_LOCALE

  static getEntryData = state => (
    entryId,
    locale = DEFAULT_LOCALE,
    include = DEFAULT_INCLUDE,
  ) => {
    const entryDataAttempt = getHighestIncludeOption(state, entryId, locale)
    const { entryData } = entryDataAttempt || {}
    const finalData = entryData || {}

    return finalData
  }

  mapStateToProps = (state, ownProps) => {
    // you do not have entryId or locale so no requests have been done
    // often used for first render of namespace item that doesnt have entryId at first render

    const { entryId } = ownProps || {}
    if (this._entryIdProp && entryId) {
      this._log &&
        console.log(
          'ContentDataEntryManager entryIdProp',
          ownProps,
          this._entryId,
          this._locale,
        )
      this._entryId = entryId
    }
    if (this._log) {
      console.log(
        'ContentDataEntryManager mapStateToProps',
        ownProps,
        this._entryId,
        this._locale,
      )
    }

    if (this._entryId === null || this._locale === null) {
      if (this._log) {
        console.log('ContentDataEntryManager return null null', {
          entry: undefined,
          entryId: undefined,
          error: undefined,
          idOnEntry: undefined,
          isFetching: false,
          isRefetching: false,
        })
      }
      return {
        entry: undefined,
        entryId: undefined,
        error: undefined,
        idOnEntry: undefined,
        isFetching: false,
        isRefetching: false,
      }
    }

    const entryData = ContentDataEntryManager.getEntryData(state)(
      this._entryId,
      this._locale,
      this._include,
    )

    /* this is the use case of a possible race condition where redux state has updated from some other state change
            and called this mapStateToProps before we get explicit update of the entryData in redux
         */
    if (!entryData) {
      if (this._log) {
        console.log('ContentDataEntryManager return !entryData)', {
          entry: undefined,
          entryId: this._entryId,
          error: undefined,
          idOnEntry: undefined,
          isFetching: true,
          isRefetching: false,
        })
      }
      return {
        entry: undefined,
        entryId: this._entryId,
        error: undefined,
        idOnEntry: undefined,
        isFetching: true,
        isRefetching: false,
      }
    }

    const isFetching = entryData.isFetching || false
    const isRefetching = entryData.isRefetching || false
    const error = entryData.error || undefined
    const entry = entryData.payload || undefined
    const { id: idOnEntry } = entry || {}

    const mappedEntry = this._mapper
      ? this._mapper(entry, state, ownProps)
      : entry
    const result = this._spread
      ? {
          ...mappedEntry,
          entryId: this._entryId,
          error,
          idOnEntry,
          isFetching,
          isRefetching,
          locale: this._locale,
        }
      : {
          entry: mappedEntry,
          entryId: this._entryId,
          error,
          idOnEntry,
          isFetching,
          isRefetching,
          locale: this._locale,
        }

    if (this._log) {
      console.log('ContentDataEntryManager return', result)
    }
    return result
  }

  getEntryHandler = ({ entryId, locale, preview }) => {
    if (entryId) {
      this._entryId = entryId
    }
    if (locale) {
      this._locale = locale
    }
    if (preview) {
      this._preview = preview
    }
    return getEntry(
      this._entryId,
      this._locale,
      this._preview,
      this._format,
      this._url,
      this._include,
    )
  }

  getPrefetchEntryHandler = ({
    entryId,
    locale = this._locale,
    preview = this._preview,
  }) => {
    return getEntry(
      entryId,
      locale,
      preview,
      this._format,
      this._url,
      this._include,
    )
  }

  mapDispatchToProps = dispatch => {
    return {
      getEntry: (entryId, preview) => {
        if (entryId || this._entryId) {
          dispatch(this.getEntryHandler({ entryId, preview }))
        } else {
          console.error('No entryId passed to getEntry')
        }
      },
      getEntryWithLocale: (entryId, locale, preview) => {
        dispatch(this.getEntryHandler({ entryId, locale, preview }))
      },
      getEntryWithNewLocale: (locale, preview) => {
        if (!this._entryId) {
          console.error('Cannot set local without entryId')
          return
        }
        dispatch(this.getEntryHandler({ locale, preview }))
      },
      prefetchEntryWithNewLocale: (entryId, locale, preview) => {
        dispatch(this.getPrefetchEntryHandler({ entryId, locale, preview }))
      },
    }
  }
}
