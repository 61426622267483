import VideoCallout from './component'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import ContentfulParser from 'utils/contentfulParser/contents'

const mapper = (entry, state, props) => {
  const urlIsValid = props => props.videoUrl || props.videoThumbnailUrl

  if (urlIsValid(props)) return props

  const {
    displayTitle: videoDisplayTitle,
    videoUrl,
    videoThumbnailUrl,
    time: videoLength,
    locale,
  } = ContentfulParser.video(entry)
  const entryId = props && props.entryId

  return {
    videoDisplayTitle,
    videoLength,
    videoThumbnailUrl,
    videoUrl,
    entryId,
    locale,
  }
}

const options = {
  include: 2,
  mapper,
  spread: true,
  entryIdProp: true,
}

export default ContentEntryDataGetter(VideoCallout, options)
