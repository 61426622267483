import React from 'react'
import PropTypes from 'prop-types'
import LessonDetailsElPage from './component'
import { mapper } from './data'

const LessonDetailsElPageContainer = ({ entry, location }) => {
  const parsedEntry = mapper(entry, location)

  return <LessonDetailsElPage {...parsedEntry} />
}

LessonDetailsElPageContainer.propTypes = {
  entry: PropTypes.object,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
}

export default LessonDetailsElPageContainer
