import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'secondstep-components'
import ReportsClassHeader from 'components/ReportsClassHeader'
import K8LessonsTable from 'components/K8LessonsTable'
import {
  DrawerContent,
  LessonTableWrapper,
  StyledReportsLoading,
} from './component.styles'
import { UNIT_5 } from './constants'

const K8Class = ({
  classDetailsReport,
  classLessonColTooltip,
  classMarkedDoneTooltip,
  classStatusColTooltip,
  fixedTop,
  fixedView,
  gradeName,
  hasBHU,
  hasError,
  isFetchingClass,
  statusCode,
}) => {
  const {
    className: classTitle,
    lessonCount,
    lessons,
    lessonsCompleted,
    teacherFirstName,
    teacherLastName,
  } = classDetailsReport || {}

  const bhuLessons = lessons?.filter(lesson => lesson.parent === UNIT_5)

  if (hasError) return <ErrorMessage statusCode={statusCode} />

  return (
    <>
      {isFetchingClass && <StyledReportsLoading text={'Loading class...'} />}
      {!isFetchingClass && (
        <>
          <ReportsClassHeader
            bhuLessons={bhuLessons}
            classTitle={classTitle}
            fixedTop={fixedTop}
            fixedView={fixedView}
            gradeName={gradeName}
            hasBHU={hasBHU}
            lessonCount={lessonCount}
            lessonsCompleted={lessonsCompleted}
            teacherFirstName={teacherFirstName}
            teacherLastName={teacherLastName}
          />
          <DrawerContent fixedTop={fixedTop} fixedView={fixedView}>
            <LessonTableWrapper>
              {lessons?.length > 0 && (
                <K8LessonsTable
                  lessons={classDetailsReport.lessons}
                  lessonsTooltip={classLessonColTooltip}
                  markedDoneTooltip={classMarkedDoneTooltip}
                  statusTooltip={classStatusColTooltip}
                />
              )}
            </LessonTableWrapper>
          </DrawerContent>
        </>
      )}
    </>
  )
}

K8Class.propTypes = {
  classDetailsReport: PropTypes.object,
  classLessonColTooltip: PropTypes.string,
  classMarkedDoneTooltip: PropTypes.string,
  classStatusColTooltip: PropTypes.string,
  fixedTop: PropTypes.bool,
  fixedView: PropTypes.bool,
  gradeName: PropTypes.string,
  hasBHU: PropTypes.bool,
  hasError: PropTypes.bool,
  isFetchingClass: PropTypes.bool,
  statusCode: PropTypes.number,
}

export default K8Class
