import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'

export const CelebrationIcon = styled.img`
  object-fit: cover;
  width: 4.163rem;
  height: 100%;
`

export const CompletionCommentary = styled.p`
  // support newlines in string:
  white-space: pre-line;
  font-style: italic;
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 0.5rem;
`

export const CompletionStatusDetail = styled.p`
  margin-top: 1rem;
  margin-bottom: 0;
`

export const Container = styled.div`
  display: flex;
  gap: 1.875rem;
  background-color: ${props =>
    props.isComplete
      ? themeGet('colors.hsSecondaryLightGreenSemiTransparent')
      : themeGet('colors.highschool.gray3')};
  max-width: 29.75rem;
  padding: 1.25rem;
  border-radius: 0.5rem;
  justify-content: space-between;
  ${themeGet('breakpoints.tablet')} {
    flex-direction: column-reverse;
    align-items: center;
    justify-content: auto;
  }
`

function getCompleteButtonCursor(props) {
  if (props.isDisabled) {
    return 'inherit'
  }
  if (props.isComplete && !props.canReupdateProgress) {
    return 'inherit'
  }
  return 'pointer'
}

// use the isComplete prop to determine the background color of the button
export const MarkCompleteButton = styled(Button)`
  width: 10.938rem;
  text-align: center;
  // if the button is complete and not an Educator Practice, its effectively disabled but should show as complete
  cursor: ${props => getCompleteButtonCursor(props)};
  border-radius: 0.5rem;
  padding-top: 0.688rem;
  padding-bottom: 0.5rem;
  outline: none;
  outline-color: transparent;
  border-style: solid;
  border-width: 2px;
  border-color: ${props =>
    props.isComplete
      ? themeGet('colors.highschool.green3')
      : props.isDisabled
      ? 'transparent'
      : themeGet('colors.highschool.green2')};
  color: ${props =>
    props.isComplete
      ? themeGet('colors.highschool.green5')
      : themeGet('colors.highschool.green2')};
  background-color: ${props =>
    props.isComplete
      ? themeGet('colors.hsSecondaryLightGreen')
      : props.isDisabled
      ? themeGet('colors.highschool.gray1')
      : themeGet('colors.white')};
  :hover {
    background-color: ${props =>
      props.isComplete
        ? props.canReupdateProgress
          ? themeGet('colors.hsSecondaryGreen')
          : themeGet('colors.hsSecondaryLightGreen')
        : props.isDisabled
        ? themeGet('colors.highschool.gray1')
        : themeGet('colors.highschool.green8')};
    border-color: ${props =>
      props.isComplete
        ? props.canReupdateProgress
          ? themeGet('colors.hsSecondaryGreen')
          : themeGet('colors.highschool.green3')
        : props.isDisabled
        ? 'transparent'
        : themeGet('colors.highschool.green2')};
    color: ${props =>
      props.isComplete
        ? props.canReupdateProgress
          ? 'white'
          : themeGet('colors.highschool.green5')
        : themeGet('colors.highschool.green2')};
  }
  :hover .mark-complete-button-icon {
    background-image: url(${props => props.hoverSrc});
  }
`

export const MarkCompleteButtonIcon = styled.span`
  display: inline-block;
  width: 1.1rem;
  height: 1.1rem;
  margin-bottom: -0.2rem;
  margin-right: 0.25rem;
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-image: url(${props => props.src});
`

export const SurveyButton = styled(Button)`
  cursor: pointer;
  border-radius: 0.5rem;
  padding-top: 0.688rem;
  padding-bottom: 0.5rem;
  padding-left: 1.252rem;
  padding-right: 1.123rem;
  border-style: solid;
  border-width: 2px;
  border-color: ${themeGet('colors.highschool.green2')};
  background-color: ${themeGet('colors.white')};
  color: ${themeGet('colors.highschool.green2')};
  :hover {
    background-color: ${themeGet('colors.highschool.green8')};
  }
`

export const SurveyCommentary = styled.p`
  margin-top: 1rem;
  margin-bottom: 0.75rem;
`

export const TextWrapper = styled.div``
