import { select, takeEvery } from 'redux-saga/effects'
import autoBind from 'auto-bind'

export const DO_NOT_RUN_CODE = 999

export default class SagaLifeCycle {
  constructor() {
    autoBind(this)
  }

  static SELECTOR_METHOD_PREFIX = 'select_'
  static FILTER_METHOD_PREFIX = 'filter_'
  static ERROR_CODE_HANDLED = DO_NOT_RUN_CODE
  static CONSOLE_LOG = false

  filterAction(action) {
    if (!action) {
      // Real error this should not happen
      throw Error('No action')
    }
    const { type } = action
    const methodName = this.generateFilterMethodName(type)
    const method = this[methodName]
    if (!method) {
      // Real error might happen during development nice helper error thrown to alert dev they need to implement the correct method
      throw Error(`Implement this.${methodName}`)
    }
    method(action)
  }

  *process() {
    throw Error(
      'Please implement generator this.process(data) in the child class',
    )
  }

  subscribeEvents() {
    throw Error(
      `Please implement
         this.subscribeEvents() -> [Reduxs events to watch]
         or
         override generator this.subscribe() in the child class override
       `,
    )
  }

  *subscribe() {
    const events = this.subscribeEvents()
    yield takeEvery(events, this.lifecycle)
  }

  getState(selector = state => state) {
    const value = selector(this.state)
    return value
  }

  *lifecycle(action) {
    try {
      this.log('select')
      this.state = yield select(state => state)
      this.log('filterAction')
      this.filterAction(action)
      this.log('filterSelectors')
      const selectors = this.filterSelectors(action)
      this.log('selection')
      const selectedState = this.selection(selectors, this.state)
      this.log('verifySelectedState')
      this.verifySelectedState(selectedState)
      this.log('normalize')
      const data = this.normalize(action, selectedState)
      this.log('process')
      yield this.process(data)
    } catch (e) {
      if (e.code === SagaLifeCycle.ERROR_CODE_HANDLED) {
        this.log(e, e.payload)
        return
      }
      console.error(e)
      throw e
    }
  }

  log(...args) {
    ;(this.CONSOLE_LOG || SagaLifeCycle.CONSOLE_LOG) && console.log(...args)
  }

  normalize(action, selectedState) {
    const { type, payload } = action
    return {
      triggerAction: type,
      [type]: payload,
      ...selectedState,
    }
  }

  verifySelectedState(state) {
    if (!state || Object.keys(state).length <= 0) {
      this.earlyExit(`No State`)
      return
    }
    for (const [key, value] of Object.entries(state)) {
      if (value === 0 || value === false) {
        continue
      }
      if (!value || this.emptyArray(value) || this.emptyObject(value)) {
        this.earlyExit(`Selector failed: ${key}:${value}`)
      }
    }
  }

  emptyObject(obj) {
    return Object.keys(obj).length === 0 && obj.constructor === Object
  }

  emptyArray(array) {
    return array.length === 0
  }

  selection(selectors, state) {
    return selectors.reduce((result, selector) => {
      const data = this[selector](state)
      const type = this.getTypeFromMethod(selector)
      return { ...result, [type]: data }
    }, {})
  }

  getTypeFromMethod(method) {
    const type = method.replace(SagaLifeCycle.SELECTOR_METHOD_PREFIX, '')
    return type
  }

  filterSelectors({ type }) {
    const allMethods = Reflect.ownKeys(this)
    const actionSelector = this.generateSelectorMethodName(type)
    const selectors = allMethods.filter(
      method =>
        method.startsWith(SagaLifeCycle.SELECTOR_METHOD_PREFIX) &&
        method !== actionSelector,
    )
    return selectors
  }

  generateMethodName(prefix, actionType) {
    const methodName = `${prefix}${actionType}`
    return methodName
  }

  generateSelectorMethodName(actionType) {
    return this.generateMethodName(
      SagaLifeCycle.SELECTOR_METHOD_PREFIX,
      actionType,
    )
  }

  generateFilterMethodName(actionType) {
    return this.generateMethodName(
      SagaLifeCycle.FILTER_METHOD_PREFIX,
      actionType,
    )
  }

  earlyExit(message, payload) {
    const e = new Error(message)
    e.code = SagaLifeCycle.ERROR_CODE_HANDLED
    e.payload = payload
    throw e
  }
}
