/* istanbul ignore file */

import React, { useState, useEffect } from 'react'
import { StyledDropdown, StyledDropdownOption } from './customSortBy.styles'
import { CheckIcon } from 'secondstep-components'
import PropTypes from 'prop-types'
import { useSortBy } from 'react-instantsearch-hooks-web'
import { sendApplicationInsightsEvent } from 'utils/sendAnalyticsDataHelpers'
import { AI_SORT_BY_USAGE_KEY } from './constants'

const CustomSortBy = React.forwardRef((props, ref) => {
  const { items, ...rest } = props
  const { options, refine } = useSortBy({
    items: props.items,
  })

  const [selectedOption, setSelectedOption] = useState(
    options.length !== 0 ? options[0] : [],
  )
  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    if (!isFirstRender) {
      sendApplicationInsightsEvent(AI_SORT_BY_USAGE_KEY, {
        sortedBy: selectedOption.label,
      })
    }
  }, [selectedOption])

  const dropDownProps = {
    round: {
      size: '5px',
    },
    align: { top: 'bottom', left: 'left' },
    margin: { vertical: '6px' },
    target: ref.current,
  }
  return (
    <StyledDropdown
      dropProps={dropDownProps}
      onChange={event => {
        setIsFirstRender(false)
        /* event.option is synthetically inserted by Grommet into their Select component's onChange. 
           When mocking that component, we can only modify the event.target property, not event.option */
        setSelectedOption(event.option ?? event.target.option)
        refine((event.option ?? event.target.option).value)
      }}
      options={options}
      ref={ref}
      value={selectedOption.label}
      {...rest}
    >
      {(option, _index, _options, optionStatus) => {
        return (
          <StyledDropdownOption key={option.value} value={option.value}>
            {option.label} {optionStatus.selected && <CheckIcon />}
          </StyledDropdownOption>
        )
      }}
    </StyledDropdown>
  )
})

CustomSortBy.propTypes = {
  items: PropTypes.array,
}

export default CustomSortBy
