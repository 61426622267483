export function courseSelectHandlerFactory(productName, navigationEvent) {
  return course => () =>
    navigationEvent(`/product/${productName}/lessons/preview/${course}`)
}
export function backToLessonsHandlerFactory(productName, navigationEvent) {
  return () => navigationEvent(`/product/${productName}/lessons`)
}
export function classSelectHandlerFactory(productName, navigationEvent) {
  return (course, instance) => () =>
    navigationEvent(
      `/product/${productName}/lessons/teach/${course}?class=${instance}`,
    )
}
export function gotoDashboardHandlerFactory(navigationEvent) {
  return () => navigationEvent('/')
}
export function unitSelectHandlerFactory(
  productName,
  navigationEvent,
  location,
) {
  return verb => (course, _, unitIndex) => {
    navigationEvent(
      `/product/${productName}/lessons/${verb}/${course}/unit-${unitIndex}` +
        location.search,
    )
  }
}
