import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import {
  CreationDate,
  HeaderThree,
  ProgressStatusSmall,
} from 'secondstep-components'

export const CourseInstanceCardWrapper = styled(Box).attrs(
  ({ dataTestId }) => ({
    'data-testid': dataTestId,
    direction: 'row',
  }),
)`
  justify-content: space-between;
  align-items: center;
  background-color: ${themeGet('colors.courseInstanceCardBackground')};
  border-radius: ${themeGet('courseInstanceCardBorderRadius')};
  padding: 0.8425rem 1rem;
  position: relative;

  && :not(:last-of-type) {
    margin-bottom: 1.25rem;
  }

  span {
    margin-bottom: 0;
  }

  ${ProgressStatusSmall} {
    margin-top: 0.125rem;
  }

  @media screen and (max-width: 850px) {
    padding: 0.725rem 1rem;

    ${ProgressStatusSmall} {
      margin-top: 0;
    }
  }

  ${themeGet('breakpoints.tablet')} {
    span {
      margin-right: 0.125rem;
    }
  }

  @media screen and (max-width: 590px) {
    flex-direction: column;
    padding: 1.5rem 1.1875rem 1.75rem;

    ${ProgressStatusSmall} {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0.375rem 0 1.375rem;

      span {
        margin: 0.25rem 0 0;
      }
    }
  }
`

export const CardBody = styled(Box)`
  flex: 1;
  flex-direction: row;

  @media screen and (max-width: 590px) {
    flex-direction: column;
    align-items: center;
  }
`

export const ClassInfoBox = styled(Box)`
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 2.625rem 0 1.125rem;

  @media screen and (max-width: 850px) {
    flex-direction: column;
    margin-right: 1rem;
  }

  ${themeGet('breakpoints.tablet')} {
    margin-left: 0.9375rem;
  }

  @media screen and (max-width: 590px) {
    margin: 0;
  }

  ${themeGet('breakpoints.mobile')} {
    align-items: center;
    margin: 0;
  }
`

export const ClassInfoLeftBox = styled(Box)`
  @media screen and (max-width: 850px) {
    ${CreationDate} {
      display: none;
    }
  }

  @media screen and (max-width: 590px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0.875rem 0 0.25rem;
  }
`

export const ClassInfoRightBox = styled(Box)`
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;

  ${CreationDate} {
    display: none;
  }

  @media screen and (max-width: 850px) {
    justify-content: flex-start;

    ${CreationDate} {
      display: inline-block;
      ::after {
        content: '•';
        margin-left: 0.3rem;
        margin-right: 0.3rem;
      }
    }
  }

  @media screen and (max-width: 760px) {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 0.1875rem;
  }

  @media screen and (max-width: 590px) {
    margin-top: 0;
  }

  ${themeGet('breakpoints.tablet')} {
    justify-content: flex-start;

    ${CreationDate} {
      ::after {
        content: '';
        margin: 0;
      }
    }
  }

  @media screen and (max-width: 590px) {
    align-items: center;
  }

  ${themeGet('breakpoints.mobile')} {
    flex-direction: column;
  }
`

export const StyledHeaderThree = styled(HeaderThree)`
  display: flex;
  align-items: center;
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('fontFamilyBold')};
  margin: 0;

  button {
    margin-left: 0.5rem;
  }

  ${themeGet('breakpoints.mobile')} {
    text-align: center;
    font-size: ${themeGet('heading.level.3.medium.size')};
  }
`
