import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Collapsible } from 'grommet'
import NavigationHelper from 'layers/navigation/navigationHelper'

import {
  BodyCopy,
  CollapsibleButton,
  CompleteIcon,
  HeaderThree,
  HeaderSix,
  IconPDF,
  TextLink,
} from 'secondstep-components'
import PresentationPlayerButton from 'experienceComponents/PresentationPlayerButton'
import {
  AccordionButton,
  ButtonBox,
  CollapsibleContentWrapper,
  LeftContent,
  LessonCollapsibleWrapper,
  LinkBox,
  OverviewBox,
  RightContent,
  TextBox,
  ToggleWrapper,
  ExtendLessonWrapper,
  LessonsWrapper,
  PdfsWrapper,
} from './component.styles'
import ExtendLesson from './ExtendLessonSection'

const LessonCollapsible = ({
  attempted,
  contentItem = {},
  dataTestId,
  entryId,
  isDone,
  isOpen,
  launchLesson,
  lessonIndex,
  lessonPdfsArray,
  navigationEvent,
  toggleOpen,
}) => {
  const {
    displayTitle = '',
    description = '',
    extensionLinks = [],
    extensionTitle = '',
  } = contentItem
  const pdfsExist = lessonPdfsArray?.length > 0
  const notAttempted = attempted === 'not attempted'
  const isCurriculumPreviewMode = attempted === 'preview'

  const onClick = (navigationEvent, href) => {
    const type = NavigationHelper.types.DOWNLOAD_LINK
    navigationEvent(href, type)
  }

  const onKeyPressCurry = href => event => {
    if (event.key === 'Enter') {
      onClick(navigationEvent, href)
    }
  }

  return (
    <Fragment>
      <LessonCollapsibleWrapper dataTestId={dataTestId}>
        <TextBox>
          <AccordionButton
            aria-controls={`lesson-${lessonIndex}-content-container`}
            aria-expanded={isOpen}
            className="accordion-toggle"
            onClick={toggleOpen}
          >
            <CollapsibleButton isOpen={isOpen} tabIndex="-1" />
            <HeaderThree dataTestId="header-lesson-title">
              {`${lessonIndex}. ${displayTitle}`}
            </HeaderThree>
          </AccordionButton>
          {isDone && <CompleteIcon />}
        </TextBox>
      </LessonCollapsibleWrapper>
      <ToggleWrapper dataTestId={`lesson-${lessonIndex}-content-container`}>
        <Collapsible open={isOpen}>
          <CollapsibleContentWrapper>
            <RightContent>
              <ButtonBox>
                <PresentationPlayerButton
                  attempted={attempted}
                  entryId={entryId}
                  getLaunchLessonHref={launchLesson}
                  lesson={displayTitle}
                />
                {notAttempted && !isCurriculumPreviewMode && (
                  <PresentationPlayerButton
                    attempted="preview"
                    entryId={entryId}
                    getLaunchLessonHref={launchLesson}
                    lesson={displayTitle}
                  />
                )}
              </ButtonBox>
            </RightContent>
            <LeftContent>
              <OverviewBox>
                <HeaderSix dataTestId="header-lesson-overview">
                  Overview
                </HeaderSix>
                <BodyCopy>{description || ''}</BodyCopy>
              </OverviewBox>
              <LessonsWrapper>
                {pdfsExist && (
                  <PdfsWrapper>
                    <Fragment key="pdf-header">
                      <HeaderSix dataTestId="header-lesson-pdfs">
                        Planning
                      </HeaderSix>
                      {lessonPdfsArray.map(
                        ({ displayTitle: pdfDisplayTitle, href }, index) => (
                          <LinkBox key={`lesson-pdf-${index + 1}`}>
                            <TextLink
                              dataTestId={`lesson-pdf-download-link-${index +
                                1}`}
                              onClick={() => onClick(navigationEvent, href)}
                              onKeyPress={onKeyPressCurry(href)}
                            >
                              <IconPDF role="img" />
                              {pdfDisplayTitle}
                            </TextLink>
                          </LinkBox>
                        ),
                      )}
                    </Fragment>
                  </PdfsWrapper>
                )}
                <ExtendLessonWrapper>
                  <ExtendLesson
                    extensionLinks={extensionLinks}
                    extensionTitle={extensionTitle}
                    navigationEvent={navigationEvent}
                  />
                </ExtendLessonWrapper>
              </LessonsWrapper>
            </LeftContent>
          </CollapsibleContentWrapper>
        </Collapsible>
      </ToggleWrapper>
    </Fragment>
  )
}

LessonCollapsible.propTypes = {
  attempted: PropTypes.string,
  contentItem: PropTypes.object,
  dataTestId: PropTypes.string.isRequired,
  entryId: PropTypes.string,
  isDone: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  launchLesson: PropTypes.func.isRequired,
  lessonIndex: PropTypes.number.isRequired,
  lessonPdfsArray: PropTypes.array,
  navigationEvent: PropTypes.func,
  toggleOpen: PropTypes.func.isRequired,
}

export default LessonCollapsible
