import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { selectors as reportsSelectors } from 'store/reportsManager'
import { operations as userSessionOperations } from 'store/userSessionManager'
import AdminPathwayProgress from './component'

const AdminPathwayProgressContainer = ({
  persistedActiveTab,
  programKey,
  site,
  setAdminActivePathwayTabBySite,
}) => {
  return !programKey || !site ? null : (
    <AdminPathwayProgress
      persistedActiveTab={persistedActiveTab}
      programKey={programKey}
      setAdminActivePathwayTabBySite={setAdminActivePathwayTabBySite}
      site={site}
    />
  )
}

export const mapStateToProps = (state, ownProps) => {
  const { reportsManager, userSessionManager } = state || {}
  const activePathwayTabBySite =
    userSessionManager?.adminDashboard?.hs?.activePathwayTabBySite
  const activeTab = activePathwayTabBySite?.[ownProps.siteId]
  const { key: programKey } = reportsManager.selectedProgram || {}
  const hsSitesWithPreferences =
    reportsSelectors.selectSortedAdminHsSnapshotSites(state) || []
  const matchingSite = hsSitesWithPreferences.find(
    ({ siteId }) => siteId === ownProps.siteId,
  )

  return {
    persistedActiveTab: activeTab,
    programKey,
    site: matchingSite,
  }
}

const mapDispatchToProps = {
  setAdminActivePathwayTabBySite:
    userSessionOperations.setAdminActivePathwayTabBySite,
}

AdminPathwayProgressContainer.propTypes = {
  persistedActiveTab: PropTypes.number,
  programKey: PropTypes.string.isRequired,
  setAdminActivePathwayTabBySite: PropTypes.func.isRequired,
  site: PropTypes.shape({
    currentPacingName: PropTypes.string.isRequired,
    currentPacingEndDate: PropTypes.string.isRequired,
    currentPacingEndDateFormatted: PropTypes.string.isRequired,
    educatorPracticesPathways: PropTypes.object.isRequired,
    preferences: PropTypes.object.isRequired,
    schoolwidePracticesPathways: PropTypes.object.isRequired,
    siteId: PropTypes.number.isRequired,
    studentActivitiesByGrade: PropTypes.object.isRequired,
  }),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminPathwayProgressContainer)
