export default {
  400: {
    type: 'Bad Request',
    message: 'Please check the URL and try again.',
  },
  401: {
    type: 'Unauthorized',
    message: 'Sorry, you are not authorized to view this page.',
  },
  403: {
    type: 'Forbidden',
    message: 'Please check the URL and try again.',
  },
  404: {
    type: 'Not Found',
    message: 'Page not found. Sorry, your requested content is not available.',
  },
  408: {
    type: 'Timeout',
    message:
      'Problem connecting. Check your internet connection and try again. ',
  },
  500: {
    type: 'Server Error',
    message:
      'The server encountered a temporary error and could not complete your request',
  },
  502: {
    type: 'Bad Gateway',
    message: `The server is temporarily overloaded and can't process your request.`,
  },
}
