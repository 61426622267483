import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { fileTypes } from 'utils/contentfulFileTypes'
import NavigationHelper from 'layers/navigation/navigationHelper'

import H3LinkList from './component'

class H3LinkListContainer extends Component {
  static propTypes = {
    header: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    links: PropTypes.arrayOf(
      PropTypes.shape({
        href: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
        // not required!
        contentType: PropTypes.string,
      }),
    ).isRequired,
    location: PropTypes.object.isRequired,
    navigationEvent: PropTypes.func.isRequired,
  }

  navigationHandler = (href, contentType) => {
    const { navigationEvent, location } = this.props
    const { pathname } = location || {}

    const isFileType =
      contentType && fileTypes.find(type => type === contentType)

    if (isFileType) {
      const { DOWNLOAD_LINK } = NavigationHelper.types
      navigationEvent(href, DOWNLOAD_LINK)

      return
    }

    if (contentType === 'url') {
      navigationEvent(href)

      return
    }

    // default to child selector option
    const isDuplicatePath = NavigationHelper.isADuplicatePath(pathname, href)
    if (!isDuplicatePath) {
      const depth = pathname.split('/').length - 1
      const fullRoute = NavigationHelper.generateUrlWithDepthForRoute(
        pathname,
        href,
        depth,
      )
      navigationEvent && navigationEvent(fullRoute)
    }
  }

  render() {
    return (
      <H3LinkList navigationHandler={this.navigationHandler} {...this.props} />
    )
  }
}

export default connect(
  NavigationHelper.mapStateToProps,
  NavigationHelper.mapDispatchToProps,
)(H3LinkListContainer)
