import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Thumbnail, Image, Container, Padded } from './component.styles'
import { BodyCopy } from 'secondstep-components'
import WithZoom from 'components/WithZoom'
import WithImageModal from 'components/WithImageModal'

const ImageDescription = ({
  altTextShort,
  bodyCopy,
  height,
  width,
  url,
  expandImage,
}) => {
  const [minWidth, setMinWidth] = useState(0)
  const [minHeight, setMinHeight] = useState(0)
  const [showModal, setShowModal] = useState(false)

  useEffect(() => {
    const ratio = isNaN(height / width) ? 0 : height / width
    const calculatedMinWidth = calcMinWidth()

    setMinWidth(calculatedMinWidth)
    setMinHeight(ratio * minWidth)
  })

  const openModal = event => {
    if (showModal || (event.type !== 'click' && event.key !== 'enter')) {
      return
    }
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const calcMinWidth = () => {
    const lowMinWidth = 200 // observed min that looks okay
    const highMinWidth = 250 // observed max that looks okay
    const windowWidth = window.innerWidth
    // get ratios average of the extremes
    const guessRatio =
      (windowWidth / lowMinWidth + windowWidth / highMinWidth) / 2
    const calculatedMinWidth = windowWidth / guessRatio // Aspect calc

    return calculatedMinWidth
  }

  const ThumbnailImage = (
    <Thumbnail
      accessibilitylabel={altTextShort}
      hasZoom={false}
      height={minHeight}
      src={url}
      width={minWidth}
    />
  )
  const ImageElement = <Image alt={altTextShort} src={url} />

  const modalProps = {
    openModal,
    onClose: closeModal,
    show: showModal,
  }

  const ZommableImage = () =>
    WithImageModal(ThumbnailImage, WithZoom()(ImageElement))(modalProps)

  return (
    <Container>
      {(expandImage && <ZommableImage />) || ThumbnailImage}
      <Padded />
      <BodyCopy>{bodyCopy}</BodyCopy>
    </Container>
  )
}

ImageDescription.propTypes = {
  altTextShort: PropTypes.string.isRequired,
  bodyCopy: PropTypes.string.isRequired,
  expandImage: PropTypes.bool,
  height: PropTypes.number.isRequired,
  url: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
}

export default ImageDescription
