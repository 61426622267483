import React from 'react'
import PropTypes from 'prop-types'
import AdminTooltip from 'components/AdminTooltip'
import { AdminSortButtonWrapper, StyledSortButton } from './component.styles'

const AdminSortButton = ({
  className,
  handleOnClick,
  isActive,
  isCaretUp,
  monitorView,
  programKey,
  title,
  tooltipLocation,
}) => (
  <AdminSortButtonWrapper
    className={className}
    isActive={isActive}
    monitorView={monitorView}
  >
    <StyledSortButton
      handleOnClick={() => handleOnClick()}
      isActive={isActive}
      isCaretUp={isCaretUp}
      title={title}
    >
      <AdminTooltip
        label={title}
        location={tooltipLocation}
        monitorView={monitorView}
        programKey={programKey}
      />
    </StyledSortButton>
  </AdminSortButtonWrapper>
)

AdminSortButton.propTypes = {
  className: PropTypes.string,
  handleOnClick: PropTypes.func,
  isActive: PropTypes.bool,
  isCaretUp: PropTypes.bool,
  monitorView: PropTypes.bool,
  programKey: PropTypes.string,
  title: PropTypes.string,
  tooltipLocation: PropTypes.string,
}

export default AdminSortButton
