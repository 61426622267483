import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import buttonsMap from './data'

const PresentationPlayerButton = ({
  className,
  attempted,
  entryId,
  getLaunchLessonHref,
  lesson,
}) => {
  const { Button, children, dataTestId } = buttonsMap[attempted]

  const [presentationUrl, setPresentationUrl] = useState(null)

  useEffect(() => {
    async function getPresentationUrl() {
      const url = await getLaunchLessonHref(attempted)
      setPresentationUrl(url)
    }
    getPresentationUrl()
  }, [attempted, entryId])

  return (
    <Button
      aria-label={`${children} ${lesson}`}
      className={className}
      data-testid={dataTestId}
      role="link"
      {...presentationUrl}
    >
      {children}
    </Button>
  )
}

PresentationPlayerButton.propTypes = {
  attempted: PropTypes.string.isRequired,
  className: PropTypes.string,
  entryId: PropTypes.string,
  getLaunchLessonHref: PropTypes.func.isRequired,
  lesson: PropTypes.string,
}

export default styled(PresentationPlayerButton)``
