import React from 'react'
import PropTypes from 'prop-types'
import { MediaIcon, ScriptIcon } from 'secondstep-components'

import { toKebabCase } from 'utils/stringHelpers'
import NavigationHelper from 'layers/navigation/navigationHelper'

import { IconWrapper, Container, Supertitle, Title } from './component.styles'

const Icon = ({ icon }) => {
  switch (icon) {
    case 'media':
      return <MediaIcon />
    case 'script':
      return <ScriptIcon />
    default:
      return null
  }
}

const ContainedIconButton = ({
  navigationEvent,
  icon,
  supertitle,
  title,
  url,
}) => {
  const onClick = () => {
    const { PUSH } = NavigationHelper.types
    navigationEvent(url, PUSH)
  }

  const dataTestId = 'contained-icon-button-' + toKebabCase(title)

  return (
    <Container dataTestId={dataTestId} onClick={onClick}>
      <IconWrapper>
        <Icon icon={icon} />
      </IconWrapper>
      <Supertitle>{supertitle}</Supertitle>
      <Title>{title}</Title>
    </Container>
  )
}

Icon.propTypes = {
  icon: PropTypes.oneOf(['media', 'script']).isRequired,
}

ContainedIconButton.propTypes = {
  icon: PropTypes.oneOf(['media', 'script']).isRequired,
  navigationEvent: PropTypes.func,
  supertitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
}

export default ContainedIconButton
