import React from 'react'
import PropTypes from 'prop-types'

import {
  EmptyIcon,
  StyledContainer,
  StyledHeader,
  StyledMarkdown,
} from './component.styles'

const K8ReportingStatusBox = ({ className, icon, text, title }) => {
  return (
    <StyledContainer className={className}>
      <EmptyIcon src={icon} />
      {title && <StyledHeader>{title}</StyledHeader>}
      {text && <StyledMarkdown>{text}</StyledMarkdown>}
    </StyledContainer>
  )
}

K8ReportingStatusBox.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
}

export default K8ReportingStatusBox
