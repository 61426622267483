import { toUrlSafe } from 'utils/stringHelpers'

class UnitComponentData {
  static findUnit = ({ units = [], match }) => {
    const mappedUnits = units.map((unit, i) => {
      return {
        index: i,
        unitNumber: i + 1,
        unit,
      }
    })

    if (!mappedUnits || mappedUnits.length === 0) {
      return {}
    }

    const matchingUnits = mappedUnits.filter(mappedUnit => {
      const unitId = mappedUnit.unit?.contentfulID
      const paramsId = match?.params?.contentfulId

      return unitId && paramsId && toUrlSafe(unitId) === toUrlSafe(paramsId)
    })
    return matchingUnits[0] || {}
  }

  static findContentfulItem = ({ contentfulUnits = [], unit = {} }) =>
    contentfulUnits.find(
      ({ id }) => id && unit.contentfulID && id === unit.contentfulID,
    )

  static prepareContentfulData = ({ contentfulUnits, unit }) => {
    const contentfulItem = this.findContentfulItem({ contentfulUnits, unit })

    const unitResources = contentfulItem?.unitResources

    let advisoryActivities = new Set()

    if (contentfulItem?.additionalResources) {
      contentfulItem.additionalResources.forEach(additionalResource => {
        advisoryActivities.add(additionalResource.advisoryActivityType)
      })
    }

    const videoEntryId = contentfulItem?.unitVideo?.id

    return { advisoryActivities, videoEntryId, unitResources, contentfulItem }
  }
}

export default UnitComponentData
