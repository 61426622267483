import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  formatManageUsersLinkLabel,
  formatReportLinkLabel,
  formatUserSetupLinkLabel,
} from 'utils/adminExperienceHelpers'
import { formatHoursSinceTimestamp } from 'utils/reportsHelpers'
import AdminK8UsersCard from 'components/AdminK8UsersCard'
import AdminUsersWithClassesCard from 'components/AdminUsersWithClassesCard'
import K8ClassesCreatedPanel from 'components/K8ClassesCreatedPanel'
import { MY_SITES_TEXT } from './constants'
import {
  Left,
  Bullet,
  Divider,
  SchoolsText,
  SecondaryText,
  SummaryCards,
  SummaryHeader,
  Title,
  TotalWithoutClasses,
  Wrapper,
} from './component.styles'

const AdminSitesSummary = ({
  classes,
  loading,
  totalSitesCount,
  usersData,
  withoutClassesTotalCount,
}) => {
  const usersAddedData = {
    totalUniqueUsers: usersData?.totalCount,
    administrators: {
      active: usersData?.administrators?.activeCount,
      pending: usersData?.administrators?.pendingCount,
    },
    teachers: {
      active: usersData?.teachers?.activeCount,
      pending: usersData?.teachers?.pendingCount,
    },
  }

  return (
    <Wrapper>
      {loading && <div>Loading summary...</div>}
      {!loading && (
        <Fragment key="admin-sites-summary-1">
          <SummaryHeader>
            <Title>Launch Snapshot</Title>
            <SchoolsText>
              {totalSitesCount} School{totalSitesCount > 1 ? 's' : ''}
            </SchoolsText>
            {withoutClassesTotalCount > 0 && (
              <Fragment key="admin-sites-summary-2">
                <Divider>{'|'}</Divider>
                <TotalWithoutClasses>
                  <Bullet />
                  <SecondaryText>
                    {withoutClassesTotalCount} school
                    {withoutClassesTotalCount > 1 ? 's' : ''} without classes
                  </SecondaryText>
                </TotalWithoutClasses>
              </Fragment>
            )}
          </SummaryHeader>
          <SummaryCards>
            <Left>
              <AdminK8UsersCard
                countData={usersAddedData}
                linkLabel={formatManageUsersLinkLabel(MY_SITES_TEXT)}
              />
              <AdminUsersWithClassesCard
                lastUpdated={formatHoursSinceTimestamp(usersData?.lastUpdated)}
                linkLabel={formatUserSetupLinkLabel(MY_SITES_TEXT)}
                usersWithClassesCount={usersData?.withClassesCount}
              />
            </Left>
            <K8ClassesCreatedPanel
              classesCreated={classes}
              linkLabel={formatReportLinkLabel(MY_SITES_TEXT)}
            />
          </SummaryCards>
        </Fragment>
      )}
    </Wrapper>
  )
}

AdminSitesSummary.propTypes = {
  classes: PropTypes.object,
  loading: PropTypes.bool,
  totalSitesCount: PropTypes.number,
  usersData: PropTypes.object,
  withoutClassesTotalCount: PropTypes.number,
}

export default AdminSitesSummary
