import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { HeaderOne } from 'secondstep-components'

export const TrainingDisplayTitle = styled(HeaderOne)`
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('fontFamilyHeadingBold')};
  font-size: ${themeGet('font.size.headers.h1')};
  margin-top: 0.375rem;
`

export const BpuCpuTrainingDisplayTitle = styled(TrainingDisplayTitle)`
  margin-top: 0.625rem;
  margin-bottom: ${themeGet('spacing.blue16_1rem')};
`

export const WizardContainer = styled.div``

export const QuestionWrapper = styled.div`
  background-color: ${themeGet('colors.lighterGray')};
  border-bottom: 3px solid ${themeGet('colors.gray500')};
  border-radius: 0.75rem;
  display: flex;
  flex-direction: column;
  padding: 2.25rem ${themeGet('spacing.amber28_1_75rem')} 0;
  text-align: center;
`

export const Question = styled.section`
  position: relative;
  opacity: ${props => (props.active ? 1 : 0.6)};
  margin-bottom: 1.5rem;
  label {
    text-align: left;
  }
  ${props =>
    !props.active &&
    ` 
    background-image:
        radial-gradient(circle at 2px,#999 1px,rgba(170,170,170,0) 2px);
    background-position: bottom;
    background-repeat: repeat-x;
    background-size: 10px 3px;
    padding-bottom: 0.75rem;

    &:after {
      background: transparent;
      content: '';
      display: block;
      height: 100%;
      opacity: 0.4;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  `}
`
