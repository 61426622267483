const SECOND_STEP_PROGRAMS = (
  <span>
    Second Step<sup>®</sup> Programs
  </span>
)

const ADMIN_TITLE_SUP = (
  <span>
    Second Step<sup>®</sup> Leader Dashboard
  </span>
)

export const ADMIN_TITLE = ADMIN_TITLE_SUP
export const DASHBOARD_TITLE = SECOND_STEP_PROGRAMS
export const ACCESSIBILITY_TITLE = SECOND_STEP_PROGRAMS

export const WEBINARS_TITLE = (
  <span>
    Second Step<sup>®</sup> Implementation Webinars
  </span>
)

export const DISTRICT_TITLE = 'District Supports'
export const FAMILY_RESOURCES_ELEMENTARY_TITLE =
  'Family Resources for Elementary'
export const FAMILY_RESOURCES_MIDDLE_SCHOOL_TITLE =
  'Family Resources for Middle School'
export const MIND_YETI_FAMILY = 'Mind Yeti® Sessions'
export const K8_REPORTS_TITLE = 'Lesson Completion Report'
export const SELA_REPORTS_TITLE = 'SEL for Adults Program Report'
export const SETUP_ADMIN = 'Set-Up Admin'
export const RESOURCE_LIBRARY_TITLE = 'Resource Library'
