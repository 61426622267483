import { SELA_ACTIVE_STATUS, SELA_PROGRESS_STATUS } from './enums'
import { NO_DATA_PLACEHOLDER } from './constants'
import pathwayNameMap from './pathwayNameMap'

export const createChoiceCurriculumHeaders = (detail, createdHeaders) => {
  const meetings = detail.user_children

  meetings.forEach(meeting => {
    const newHeaderKey = `${meeting.display_title} - ${meeting.sub_content_type}`

    const newHeaderLabel =
      meeting.sub_content_type === 'leader-group'
        ? 'Attended Leader Team Meeting'
        : 'Attended Small-Group Meeting'

    const headerExists = createdHeaders.some(header => {
      return header.key === newHeaderKey
    })

    if (!headerExists)
      createdHeaders.push({ key: newHeaderKey, label: newHeaderLabel })
  })

  const certificateKey = 'Unit Certificate'
  const certificateHeaderLabel = 'Unit Certificate'
  const headerExists = createdHeaders.some(header => {
    return header.key === certificateKey
  })

  if (!headerExists)
    createdHeaders.push({
      key: certificateKey,
      label: certificateHeaderLabel,
    })
}

export const createModuleOrUnitHeaders = (
  createdHeaders,
  detail,
  detailType,
  index,
  newHeaderKey,
) => {
  let label

  if (detailType === 'moduleSela') {
    if (
      !newHeaderKey.includes('For School Principals') &&
      !newHeaderKey.includes('Meet With Your Leader Team') &&
      !newHeaderKey.includes('Getting Started') &&
      !newHeaderKey.includes('Kickoff')
    ) {
      label = `Unit ${index}`
    }
  }

  if (detailType === 'unitSela' && detail.type !== 'choiceCurriculum') {
    label = index + 1
  }
  const newHeaderLabel = label ? `${label}: ${newHeaderKey}` : newHeaderKey

  const headerExists = createdHeaders.some(header => {
    return header.key === newHeaderKey
  })

  if (!headerExists)
    createdHeaders.push({ key: newHeaderKey, label: newHeaderLabel })
}

export const createDataForMatchingDetail = (
  createdData,
  matchingDetail,
  user,
) => {
  const newData = {
    name: user.name,
    email: user.email,
    license_status: SELA_ACTIVE_STATUS[user.license_status],
    role: user.role,
    children_completed: matchingDetail.children_completed,
    total_children: matchingDetail.total_children,
  }

  const progress = !isNaN(matchingDetail.progress)
    ? `${Math.round(matchingDetail.progress * 100)}%`
    : NO_DATA_PLACEHOLDER

  assignModuleOrUnitData(matchingDetail, newData, progress)

  newData.progress = progress
  createdData.push(newData)
}

const assignModuleOrUnitData = (matchingDetail, newData, progress) => {
  matchingDetail?.user_children.forEach(child => {
    if (child.type === 'choiceCurriculum') {
      child.user_children?.forEach(meeting => {
        newData[`${meeting.display_title} - ${meeting.sub_content_type}`] =
          meeting.progress === 1 ? 'Y' : 'N'
      })
      newData['Unit Certificate'] = progress === '100%' ? 'Y' : 'N'
    } else {
      const unitOrLessonKey = child.display_title
      newData[unitOrLessonKey] =
        SELA_PROGRESS_STATUS[child.status] || NO_DATA_PLACEHOLDER
    }
  })
}

export const createDataForNoMatchingDetail = (createdData, headers, user) => {
  const newData = {
    name: user.name || NO_DATA_PLACEHOLDER,
    email: user.email,
    license_status: SELA_ACTIVE_STATUS[user.license_status],
    role: user.role,
  }

  headers.forEach(header => {
    const newHeader =
      header.key !== 'name' &&
      header.key !== 'email' &&
      header.key !== 'license_status' &&
      header.key !== 'role'

    if (newHeader) newData[header.key] = NO_DATA_PLACEHOLDER
  })

  createdData.push(newData)
}

export const compare = (a, b) => {
  if (a.license_status < b.license_status) return -1
  if (a.license_status > b.license_status) return 1

  if (a.name.toLowerCase() < b.name.toLowerCase()) return -1
  if (a.name.toLowerCase() > b.name.toLowerCase()) return 1

  return 0
}

export const sortGrades = grades => {
  return grades.sort((grade1, grade2) => {
    if (grade1.gradeName === 'Kindergarten') {
      return -1
    }
    if (grade2.gradeName === 'Kindergarten') {
      return 1
    }

    return grade1.gradeName.localeCompare(grade2.gradeName)
  })
}

export const getPathwayDates = pathwayPacingNodes => {
  if (!pathwayPacingNodes) return
  const formatDateOptions = {
    month: 'short',
    day: 'numeric',
  }

  const datesByPathway = {}

  const sortedPathwayPacingNodes = pathwayPacingNodes
    .filter(pacingNode => pacingNode && pacingNode.startDate)
    .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
    .map(({ endDate, nodeFriendlyName, startDate }) => {
      const zeroedEndDate = new Date(endDate).setHours(0, 0, 0, 0)
      const zeroedStartDate = new Date(startDate).setHours(0, 0, 0, 0)

      return {
        endDate: new Date(zeroedEndDate).toISOString(),
        startDate: new Date(zeroedStartDate).toISOString(),
        nodeFriendlyName,
      }
    })

  sortedPathwayPacingNodes.forEach(({ nodeFriendlyName, startDate }, index) => {
    const isPathwayStartNode = nodeFriendlyName.endsWith('collection 1')
    const pathwayName = pathwayNameMap[nodeFriendlyName]
    const pathwayStartDate = new Date(startDate)

    if (isPathwayStartNode) {
      const nextPathwayStartNode = sortedPathwayPacingNodes
        .slice(index + 1)
        .find(nextNode => nextNode.nodeFriendlyName.includes('collection 1'))

      if (nextPathwayStartNode) {
        const nextPathwayStartDate = new Date(nextPathwayStartNode.startDate)
        const pathwayEndDate = nextPathwayStartDate.setDate(
          nextPathwayStartDate.getDate() - 1,
        )
        const endDateTextFormatted = new Intl.DateTimeFormat(
          'en-US',
          formatDateOptions,
        ).format(new Date(pathwayEndDate))
        const startDateTextFormatted = new Intl.DateTimeFormat(
          'en-US',
          formatDateOptions,
        ).format(new Date(pathwayStartDate))

        datesByPathway[pathwayName] = {
          endDateInMs: pathwayEndDate,
          endDateTextFormatted,
          startDateInMs: pathwayStartDate.getTime(),
          startDateTextFormatted,
        }
      } else {
        const lastPathwayNode =
          sortedPathwayPacingNodes[sortedPathwayPacingNodes.length - 1]
        const daysToAdd = lastPathwayNode.nodeFriendlyName.endsWith(
          'collection 2',
        )
          ? 21
          : 14
        const lastPathwayNodeStartDate = new Date(lastPathwayNode.startDate)
        const pathwayEndDate = lastPathwayNodeStartDate.setDate(
          lastPathwayNodeStartDate.getDate() + daysToAdd,
        )

        const endDateTextFormatted = new Intl.DateTimeFormat(
          'en-US',
          formatDateOptions,
        ).format(new Date(pathwayEndDate))
        const startDateTextFormatted = new Intl.DateTimeFormat(
          'en-US',
          formatDateOptions,
        ).format(new Date(pathwayStartDate))

        datesByPathway[pathwayName] = {
          endDateInMs: pathwayEndDate,
          endDateTextFormatted,
          startDateInMs: pathwayStartDate.getTime(),
          startDateTextFormatted,
        }
      }
    }
  })

  return datesByPathway
}

export const getCurrentPacingEndDateAndName = sites => {
  if (!sites) return {}

  const formatDateOptions = {
    month: 'short',
    day: 'numeric',
  }
  let currentPacingName = 'School Setup Required'
  let currentPacingEndDate = ''
  let currentPacingEndDateFormatted = ''
  let currentPacingStartDate = ''
  let currentPacingStartDateFormatted = ''

  const sortedByDescEndStartHsPacing = sites
    .filter(collection => collection && collection.startDate)
    .sort((a, b) => new Date(a.startDate) - new Date(b.startDate))
    .map(collection => {
      const { startDate, endDate } = collection
      const zeroedEndDate = new Date(endDate).setHours(0, 0, 0, 0)
      const zeroedStartDate = new Date(startDate).setHours(0, 0, 0, 0)
      return {
        endDate: new Date(zeroedEndDate).toISOString(),
        startDate: new Date(zeroedStartDate).toISOString(),
        nodeFriendlyName: collection.nodeFriendlyName,
      }
    })

  const todaysDate = new Date(new Date().setHours(0, 0, 0, 0)).toISOString()

  if (todaysDate < sortedByDescEndStartHsPacing[0].startDate) {
    currentPacingName = 'Staff Kick-Off'
    const kickOffEndDate = new Date(sortedByDescEndStartHsPacing[0].startDate)
    kickOffEndDate.setDate(kickOffEndDate.getDate() - 1)
    const formattedKickOffEndDate = new Intl.DateTimeFormat(
      'en-US',
      formatDateOptions,
    ).format(kickOffEndDate)
    currentPacingEndDate = kickOffEndDate.toISOString()
    currentPacingEndDateFormatted = formattedKickOffEndDate
  } else if (
    todaysDate >= sortedByDescEndStartHsPacing[0].startDate &&
    todaysDate < sortedByDescEndStartHsPacing[1].startDate
  ) {
    currentPacingName =
      pathwayNameMap?.[sortedByDescEndStartHsPacing[0].nodeFriendlyName] || ''
    const trainingStartDate = new Date(
      sortedByDescEndStartHsPacing[0].startDate,
    )
    const trainingEndDate = new Date(sortedByDescEndStartHsPacing[1].startDate)
    trainingEndDate.setDate(trainingEndDate.getDate() - 1)
    const formattedTrainingEndDate = new Intl.DateTimeFormat(
      'en-US',
      formatDateOptions,
    ).format(trainingEndDate)
    const formattedTrainingStartDate = new Intl.DateTimeFormat(
      'en-US',
      formatDateOptions,
    ).format(trainingStartDate)
    currentPacingEndDate = trainingEndDate.toISOString()
    currentPacingEndDateFormatted = formattedTrainingEndDate
    currentPacingStartDate = trainingStartDate.toISOString()
    currentPacingStartDateFormatted = formattedTrainingStartDate
  } else {
    const lastCollection =
      sortedByDescEndStartHsPacing[sortedByDescEndStartHsPacing.length - 1]
    const currentCollection =
      sortedByDescEndStartHsPacing.find((collection, index) => {
        const nextCollection = sortedByDescEndStartHsPacing[index + 1]
        if (!nextCollection) {
          return true
        } else {
          return todaysDate < nextCollection?.startDate
        }
      }) || lastCollection

    const nextPathwayCollection =
      sortedByDescEndStartHsPacing
        .slice(sortedByDescEndStartHsPacing.indexOf(currentCollection))
        .find(collection => {
          if (currentCollection.nodeFriendlyName === 'hs program training') {
            return collection.nodeFriendlyName === 'hs b&c collection 1'
          } else {
            return !collection.nodeFriendlyName.includes(
              currentCollection.nodeFriendlyName.slice(0, -1),
            )
          }
        }) || currentCollection

    const currentPathway =
      pathwayNameMap?.[currentCollection.nodeFriendlyName] || ''
    currentPacingName = currentPathway
    const pathwayStartDate = new Date(currentCollection.startDate)
    const pathwayEndDate = new Date(nextPathwayCollection.startDate)

    if (currentCollection === lastCollection) {
      if (currentCollection.nodeFriendlyName.endsWith('collection 3')) {
        pathwayEndDate.setDate(pathwayEndDate.getDate() + 14)
      }
      if (currentCollection.nodeFriendlyName.endsWith('collection 2')) {
        pathwayEndDate.setDate(pathwayEndDate.getDate() + 21)
      }
    } else {
      pathwayEndDate.setDate(pathwayEndDate.getDate() - 1)
    }

    const formattedPathwayStartDate = new Intl.DateTimeFormat(
      'en-US',
      formatDateOptions,
    ).format(pathwayStartDate)
    const formattedPathwayEndDate = new Intl.DateTimeFormat(
      'en-US',
      formatDateOptions,
    ).format(pathwayEndDate)
    currentPacingStartDate = pathwayStartDate.toISOString()
    currentPacingEndDate = pathwayEndDate.toISOString()
    currentPacingEndDateFormatted = formattedPathwayEndDate
    currentPacingStartDateFormatted = formattedPathwayStartDate
  }

  return {
    currentPacingEndDate,
    currentPacingEndDateFormatted,
    currentPacingName,
    currentPacingStartDate,
    currentPacingStartDateFormatted,
  }
}
