import variables from './variables'

export default {
  meta: { themeName: 'bpu' },

  globalNav: {
    background: variables.colors.bpu4,
    iconHoverBackground: variables.colors.opacity_20,
  },
  colors: {
    heading: {
      level: {
        1: variables.colors.bpu2,
        2: variables.colors.bpu2,
        3: variables.colors.bpu2,
      },
    },
    buttons: {
      primaryBackground: variables.colors.bpu4,
      primaryBackgroundHover: variables.colors.bpu1,
      primaryBackgroundDisabled: variables.colors.buttonDisabledKit,
      secondaryText: variables.colors.darkGray,
      secondaryOutline: variables.colors.gray600,
      tabActive: variables.colors.bpu2,
      tabDefault: variables.colors.bpu5,
    },
    links: {
      primary: variables.colors.bpu4,
      primaryHover: variables.colors.bpu2,
    },
    themeAccent: variables.colors.bpu5,
    themeHighlight: variables.colors.bpu2,
    themePrimary: variables.colors.bpu4,
    themeSecondary: variables.colors.bpu1,
  },
  buttons: {
    borderRadius: '21.5px',
  },
  // ideally these would be "global" / reused across the theme instead of specific values for each component
  banners: {
    headerTextColor: variables.colors.black,
    headerTextFont: 'Sharpslab-bold',
    headerTextSize: '36px',
    bannerTextColor: variables.colors.darkGray,
    landingBackgroundColor: variables.colors.transparent,
    alignment: 'flex-start',
    textAlignment: 'left',
  },
  callouts: {
    headerTextColor: variables.colors.black,
    headerTextFont: 'Sharpslab-bold',
    headerTextSize: '28px',
    bodyTextColor: variables.colors.darkGray,
    backgroundColor: variables.colors.lighterGray,
    iconBorderRadius: '0.688rem 0.688rem 0.688rem 0.688rem',
    boxShadow: `0px 3px 0px ${variables.colors.gray500}`,
  },
  resources: {
    backgroundColorTop: variables.colors.transparent,
    backgroundColorBottom: variables.colors.transparent,
  },
  anchorNavigation: {
    borderBottomStyle: 'dotted',
    borderBottomColor: '#BBBBBB',
    borderBottomWidth: 'thick',
    topPadding: '0.688rem',
    bottomPadding: '0.5rem',
    boxShadow: `none`,
  },
}
