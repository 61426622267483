import { all, call, spawn } from 'redux-saga/effects'

import { isFlagOn } from 'utils/featureFlags'

import { NODE_START } from './content/nodes/start/constants'

import ContentProduct from './content/product/operations'
import ContentNamespace from './content/namespace/operations'
import NodeContentStartSaga from './content/nodes/start/operations'
import NodeContentNextSaga from './content/nodes/next/operations'
import FailureChecker from './content/nodes/failureChecker/operations'

const registeredNamespace = NODE_START

// This function can be used to dinamyically blacklist certain routes or route patterns
function getBlacklist() {
  let blacklist = []
  const isOn = isFlagOn(['refactor_LEARN-18413-pages-component-program-flex'])

  if (isOn) {
    blacklist = [
      // Avoid getting content nodes for the family-resources namespace
      '/family-resources/*', // Support for wildcard is limited to the end of the string for now
    ]
  }

  return blacklist
}

const WatchNodeStart = new NodeContentStartSaga(
  registeredNamespace,
  getBlacklist,
)
const WatchProductNavigation = new ContentProduct()
const WatchNamespaceNavigation = new ContentNamespace()
const WatchNodeNext = new NodeContentNextSaga()
const WatchFailures = new FailureChecker()

export default function* learnSagas() {
  const sagas = [
    WatchProductNavigation.subscribe,
    WatchNamespaceNavigation.subscribe,
    WatchNodeStart.subscribe,
    WatchNodeNext.subscribe,
    WatchFailures.subscribe,
  ]

  yield all(
    sagas.map(saga =>
      spawn(function*() {
        while (true) {
          try {
            yield call(saga)
            break
          } catch (e) {
            console.error('LEARN SAGA SPAWN FAIL:', e)
          }
        }
      }),
    ),
  )
}
