import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { HeaderTwo } from 'secondstep-components'
import PlainProgramCard from 'components/dashboard/PlainProgramCard'

export const StyledPlainProgramCard = styled(PlainProgramCard)`
  padding: 1rem 1.25rem;
  margin: 0 0 -0.9375rem;

  ${themeGet('breakpoints.tablet')} {
    padding: 0.6125rem 1.25rem 1.25rem;
  }
`

export const LicenseCardBox = styled(Box)`
  flex-direction: row;

  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
  }
`

export const LicenseCardLeftBox = styled(Box)`
  align-self: center;
  width: 50%;

  span {
    color: ${themeGet('colors.darkGray')};
  }

  ${themeGet('breakpoints.tablet')} {
    width: 100%;
  }
`

export const LicenseCardRightBox = styled(Box)`
  flex-direction: row;
  justify-content: flex-end;
  width: 50%;

  ${themeGet('breakpoints.tablet')} {
    width: 100%;
    justify-content: flex-start;
    margin-top: 0.5rem;
  }
`

export const LicenseCardButtonBox = styled(Box)`
  align-self: center;
`

export const StyledHeaderTwo = styled(HeaderTwo)`
  margin: 0;
  font-family: ${themeGet('font.molde.semiBold')};
  font-size: 1rem;
  letter-spacing: 0;

  ${themeGet('breakpoints.tablet')} {
    font-size: 1rem;
  }
`
