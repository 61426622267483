import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  FormInputCheckBox,
  FormInputRadio,
  Loading,
} from 'secondstep-components'

import WizardWelcome from 'components/training/WizardWelcome'
import WizardNav from 'components/training/WizardNav'

import {
  BpuCpuTrainingDisplayTitle,
  TrainingDisplayTitle,
  QuestionWrapper,
  Question,
  WizardContainer,
} from './component.styles'
import { LOADING_TEXT } from './constants'

const inputTypes = {
  checkbox: FormInputCheckBox,
  radio: FormInputRadio,
}

export default class WizardFlow extends Component {
  static propTypes = {
    createTrainingRegistration: PropTypes.func,
    displayTitle: PropTypes.string,
    fetchInstructorData: PropTypes.func,
    isFetching: PropTypes.bool,
    isUpdating: PropTypes.bool,
    productName: PropTypes.string,
    trainingQuestions: PropTypes.object,
  }
  constructor(props) {
    super(props)
    this.state = {
      currentStep: 0,
      isComplete: false,
      seedId: '',
      stepResponses: [],
    }
  }

  onNextClick = () => {
    const { hasCompleted, seedId } = this.checkForFinishedWizard()
    if (!hasCompleted) {
      this.setState({ currentStep: this.state.currentStep + 1 })
    } else {
      this.setState({
        currentStep: this.state.currentStep + 1,
        isComplete: true,
        seedId,
      })
    }
  }

  onBackClick = () => {
    const { currentStep, stepResponses } = this.state
    const newstepResponses = stepResponses.slice(0, currentStep - 1)
    this.setState({
      currentStep: currentStep - 1,
      isComplete: false,
      stepResponses: newstepResponses,
    })
  }

  onChange = (e, question) => {
    const { stepResponses } = this.state
    let selected = stepResponses[question.step] || []

    if (question.type === 'checkbox') {
      selected.includes(e.currentTarget.value)
        ? selected.splice(selected.indexOf(e.currentTarget.value), 1)
        : selected.push(e.currentTarget.value)
    } else {
      selected = [e.currentTarget.value]
    }
    let newstepResponses = stepResponses
    newstepResponses[question.step] = selected

    this.setState({
      stepResponses: newstepResponses,
    })
  }

  onSubmit = () => {
    // This needs to call the thunk to postRegistrations and pass the seedId from state
    const { createTrainingRegistration } = this.props
    const { seedId } = this.state
    createTrainingRegistration(seedId)
  }

  // TODO: This is ugly, make it less uggers; https://secondstep.atlassian.net/browse/LEARN-5642
  // Probably start by passing in an array of objects to the inputs instead of strings, like:
  // options = [{key: q1a1, value: 'Early Learning'}]
  // the key--not the string--gets stored; i.e. state.stepResponses[0] would be like ['q1a1', 'q2a2']
  checkForFinishedWizard = () => {
    const { productName, trainingQuestions } = this.props
    let combinedAnswers = this.state.stepResponses
      .map((stepResponse, stepIndex) =>
        stepResponse
          .map(usersResponse => {
            const question = trainingQuestions[productName].questions[stepIndex]
            return Object.values(question)
              .map((possibleAnswer, answerIndex) => {
                if (possibleAnswer === usersResponse) {
                  return Object.keys(question)[answerIndex]
                } else return null
              })
              .join('')
          })
          .sort()
          .join(''),
      )
      .join('')

    // This pieces need to be dynamic, use the match prop to get the :productName
    const answers = trainingQuestions[productName].answerKey[combinedAnswers]
    return {
      hasCompleted: !!answers,
      seedId: answers && answers.seed_id,
    }
  }

  checkForEarlyLearning = questionList => {
    const { stepResponses } = this.state
    const hasEarlyLearning = stepResponses[0].includes('Early Learning')
    if (!hasEarlyLearning) {
      questionList.splice(
        questionList.findIndex(index => 'EarlyLearning'),
        1,
      )
    }
    return questionList
  }

  render() {
    const { state, props } = this
    // This would be set in redux potentially
    if (!props.trainingQuestions) return null
    const activeProgramQuestions =
      props.trainingQuestions[props.productName].questions
    const questionCount = state.isComplete
      ? state.currentStep - 1
      : state.currentStep
    const questionsToShow = activeProgramQuestions.slice(0, questionCount)

    const stepHasResponse =
      state.stepResponses[state.currentStep - 1] &&
      state.stepResponses[state.currentStep - 1].length > 0

    const DisplayTitle =
      props.productName === 'cpu' || props.productName === 'bpu'
        ? BpuCpuTrainingDisplayTitle
        : TrainingDisplayTitle

    return (
      <>
        {(props.isFetching || props.isUpdating) && (
          <Loading loadingText={LOADING_TEXT} />
        )}
        <DisplayTitle dataTestId="training-title">
          {props.displayTitle}
        </DisplayTitle>
        <WizardContainer data-testid="wizard-flow">
          {state.currentStep === 0 && (
            <WizardWelcome onClick={this.onNextClick} />
          )}
          <div key="wizard-questions">
            {state.currentStep > 0 && (
              <QuestionWrapper>
                {questionsToShow.map((question, index) => {
                  const activeQuestion = state.currentStep === index + 1
                  let options = []
                  for (const key in question) {
                    if (key.includes('A')) {
                      options.push(question[key])
                    }
                  }
                  if (index === 3) {
                    options = this.checkForEarlyLearning(options)
                  }
                  const Component = inputTypes[question.type]
                  const questionKey = `q${index + 1}`
                  return (
                    <Question
                      active={activeQuestion}
                      key={`input-${questionKey}`}
                    >
                      <Component
                        dataTestId={questionKey}
                        label={question[questionKey]}
                        onChange={e =>
                          this.onChange(e, {
                            type: question.type,
                            step: index,
                          })
                        }
                        options={options}
                        selected={state.stepResponses[index] || []}
                      />
                    </Question>
                  )
                })}
                <WizardNav
                  formIsComplete={state.isComplete}
                  forwardOnClick={
                    state.isComplete ? this.onSubmit : this.onNextClick
                  }
                  isDone={stepHasResponse}
                  onSubmit={this.onSubmit}
                  previousExists={state.currentStep > 1}
                  previousOnClick={this.onBackClick}
                />
              </QuestionWrapper>
            )}
          </div>
        </WizardContainer>
      </>
    )
  }
}
