import React from 'react'
import PropTypes from 'prop-types'
import NavigationHelper from 'layers/navigation/navigationHelper'
import VideoDataGetter from 'experienceComponents/VideoDataGetter'
import { useModal } from 'hooks/useModal'
import {
  Icon,
  ResourceCardWrapper,
  StyledBodyCopy,
  StyledHeaderFour,
  StyledModal,
  TextWrapper,
} from './component.styles'

const ResourceCard = ({
  buttonText,
  buttonText2,
  icon,
  navigationEvent,
  openSameTab,
  url,
  video,
}) => {
  const { showModal, closeModal, createOpenHandler } = useModal({
    searchKey: video ? 'video' : undefined,
    searchValue: video?.id ?? '',
  })

  const onClick = () => {
    const type = openSameTab
      ? NavigationHelper.types.OPEN
      : NavigationHelper.types.NEW_TAB
    navigationEvent(url, type)
  }

  const clickHandler = video ? createOpenHandler : onClick

  return (
    <ResourceCardWrapper
      data-testid={`${buttonText}-resource-card`}
      onClick={clickHandler}
    >
      <Icon src={icon?.file?.url} />
      <TextWrapper>
        <StyledHeaderFour>{buttonText}</StyledHeaderFour>
        {buttonText2 && <StyledBodyCopy>{buttonText2}</StyledBodyCopy>}
      </TextWrapper>
      {video && (
        <StyledModal
          dataTestId="resource-card-modal"
          modalTitle={video.displayTitle}
          onClose={closeModal}
          show={showModal}
          showClose
        >
          <VideoDataGetter entryId={video.id} locale={video.locale} />
        </StyledModal>
      )}
    </ResourceCardWrapper>
  )
}

ResourceCard.propTypes = {
  buttonText: PropTypes.string,
  buttonText2: PropTypes.string,
  icon: PropTypes.object,
  navigationEvent: PropTypes.func,
  openSameTab: PropTypes.bool,
  url: PropTypes.string,
  video: PropTypes.object,
}

export default ResourceCard
