import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Container,
  ContentContainer,
  FooterDashboardRightBox,
} from './component.styles'
import { EXEMPT_ROUTES } from './data'
import FooterTexts from './footerTexts'
import FooterLogos from './footerLogos'
export default class Footer extends Component {
  static propTypes = {
    navigationEvent: PropTypes.func,
    router: PropTypes.object,
  }

  doNotRender = () => {
    const { router } = this.props
    const { location } = router || {}
    const { pathname = '' } = location || {}
    const onExemptRoute = EXEMPT_ROUTES.some(route => {
      return pathname.includes(route)
    })
    return onExemptRoute
  }

  render() {
    const { navigationEvent, router } = this.props
    const { location } = router || {}
    const { pathname = '' } = location || {}
    const isDashboard = pathname.includes('dashboard')
    const isK8Report = pathname.includes('reports/k8')
    const isSelaReport = pathname.includes('reports/sel-for-adults')
    const isAdmin = pathname.includes('admin')
    const isPaleGray =
      isAdmin ||
      isDashboard ||
      isK8Report ||
      isSelaReport ||
      pathname.includes('accessibility')
    const id = 'global-footer-container'
    return (
      <Container
        dataTestId={id}
        isAdmin={isAdmin}
        isDashboard={isDashboard}
        isPaleGray={isPaleGray}
      >
        <ContentContainer isDashboard={isDashboard}>
          <div>
            <FooterTexts
              isDashboard={isDashboard}
              isK8Report={isK8Report}
              navigationEvent={navigationEvent}
            />
            <FooterLogos
              isAdmin={isAdmin}
              isDashboard={isDashboard}
              navigationEvent={navigationEvent}
            />
          </div>
        </ContentContainer>
        {isDashboard && <FooterDashboardRightBox />}
      </Container>
    )
  }
}
