import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import CourseComponent from './component'
import { forwardRefWrapped } from 'utils/forwardRefWrapped'

const mapper = entry => {
  const { displayTitle, scopeAndSequence: scopeAndSequences } = entry || {}
  const [scopeAndSequence] = scopeAndSequences || []
  const {
    displayTitle: scopeAndSequenceDisplayTitle,
    file: scopeAndSequenceFile,
  } = scopeAndSequence || {}
  const scopeAndSequenceUrl = scopeAndSequenceFile?.file?.url

  return {
    displayTitle,
    scopeAndSequence,
    scopeAndSequenceUrl,
    scopeAndSequenceDisplayTitle,
  }
}
const options = {
  include: 2,
  mapper,
  spread: true,
  entryIdProp: true,
}

export default forwardRefWrapped(
  ContentEntryDataGetter(CourseComponent, options),
)
