import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { HeaderTwo } from 'secondstep-components'

export const TilesGroupContainer = styled.div`
  display: ${props => (props.show ? 'block' : 'none')};
  margin: 0 0.625rem;
  font-family: Molde-Semibold;

  ${HeaderTwo} {
    color: ${themeGet('colors.darkBlue')};
    font-size: 1.125rem;
    line-height: 1.25rem;
    margin-bottom: 1.25rem;
    font-family: ${themeGet('font.molde.semiBold')};
  }

  &:not(:last-of-type) {
    border-bottom: 1.5px solid ${themeGet('colors.gray500')};
  }

  &:first-of-type {
    margin-top: 0.625rem;
  }

  &:not(:first-of-type) {
    margin-top: 1.25rem;
  }
`
