import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Container,
  Title,
  Description,
  ObjectivesText,
  ObjectivesTitle,
} from './component.styles'
import { OBJECTIVES_TITLE } from './constants'

export default class AdvisoryActivityOverviewContent extends Component {
  static propTypes = {
    description: PropTypes.string,
    id: PropTypes.string,
    objectives: PropTypes.string,
    title: PropTypes.string,
  }

  render() {
    const { title, description, objectives, id } = this.props
    const overviewDataTestId = `advisory-activities-overview-${id}`

    return (
      <Container data-testid={overviewDataTestId}>
        <Title>{title}</Title>
        <Description>{description}</Description>
        <ObjectivesTitle>{OBJECTIVES_TITLE}</ObjectivesTitle>
        <ObjectivesText>{objectives}</ObjectivesText>
      </Container>
    )
  }
}
