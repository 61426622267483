import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { HeartIcon } from 'secondstep-components'
import { ENTER } from './constants'
import {
  ArrowIconWrapper,
  LessonProgress,
  Subtitle,
  StyledContainer,
  StyledHairline,
  StyledLink,
  StyledRightArrowIcon,
  Title,
} from './component.styles'
import { formatCountLabel, formatCountOfCountLabel } from 'utils/reportsHelpers'

const K8ReportingGradeTile = ({
  hasClassesWithCompletedLessonLastPeriod,
  numClassesWithCompletedLessonLastPeriod,
  classesCount,
  daysToReport,
  grade,
  handleOnClick,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  const formatProgress = (
    numClassesWithCompletedLessonLastPeriod,
    classCount,
    daysToReport,
  ) => {
    return (
      <>
        <b>
          {formatCountOfCountLabel(
            numClassesWithCompletedLessonLastPeriod,
            classCount,
            'class',
          )}
        </b>{' '}
        marked a lesson done in the past {daysToReport} days
      </>
    )
  }

  const handleEnterKey = (e, onClick) => {
    if (!onClick) return

    if (e?.key === ENTER) {
      onClick(e)
    }
  }

  return (
    <StyledLink
      data-testid={`${grade}-grade-tile`}
      onClick={handleOnClick}
      onMouseEnter={() => {
        setIsHovered(true)
      }}
      onMouseLeave={() => {
        setIsHovered(false)
      }}
      role="link"
    >
      <StyledContainer
        onKeyDown={e => handleEnterKey(e, handleOnClick)}
        tabIndex={0}
      >
        <Title>
          {grade}
          {isHovered && (
            <ArrowIconWrapper>
              <StyledRightArrowIcon />
            </ArrowIconWrapper>
          )}
        </Title>
        <Subtitle>{formatCountLabel(classesCount, 'class')}</Subtitle>
        {hasClassesWithCompletedLessonLastPeriod && (
          <>
            <StyledHairline />
            <LessonProgress>
              <HeartIcon />
              <p>
                {formatProgress(
                  numClassesWithCompletedLessonLastPeriod,
                  classesCount,
                  daysToReport,
                )}
              </p>
            </LessonProgress>
          </>
        )}
      </StyledContainer>
    </StyledLink>
  )
}

K8ReportingGradeTile.propTypes = {
  classesCount: PropTypes.number,
  daysToReport: PropTypes.number,
  districtId: PropTypes.string,
  grade: PropTypes.string,
  handleOnClick: PropTypes.func,
  hasClassesWithCompletedLessonLastPeriod: PropTypes.bool,
  numClassesWithCompletedLessonLastPeriod: PropTypes.number,
  siteId: PropTypes.number,
}

export default K8ReportingGradeTile
