import React from 'react'
import PropTypes from 'prop-types'
import { Text } from 'grommet'
import { CompleteIcon, HeaderFive } from 'secondstep-components'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { domains } from 'config/env'
import { Wrapper } from './component.styles'
import buttonMap from './data'

const ModuleButton = ({
  attempted,
  children,
  label = children,
  dataTestId,
  instance,
  isChoice,
  navigationEvent,
  scoID,
}) => {
  const { Button, text } = buttonMap[attempted] || {}

  if (!Button) return null

  const onClick = () => {
    const type = NavigationHelper.types.NEW_TAB_SAME_SITE
    navigationEvent(`${domains.LMS_API_BASE}/launch/${scoID}/${instance}`, type)
  }

  const buttonLabel = isChoice ? label : children

  return (
    <Wrapper>
      <Button data-testid={dataTestId} onClick={onClick}>
        <Text>{text}</Text>
        {buttonLabel && <HeaderFive>{buttonLabel}</HeaderFive>}
        {attempted === 'completed' && <CompleteIcon />}
      </Button>
    </Wrapper>
  )
}

ModuleButton.propTypes = {
  attempted: PropTypes.string.isRequired,
  children: PropTypes.node,
  dataTestId: PropTypes.string.isRequired,
  instance: PropTypes.string,
  isChoice: PropTypes.bool,
  label: PropTypes.string,
  navigationEvent: PropTypes.func,
  scoID: PropTypes.number,
}

export default ModuleButton
