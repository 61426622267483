import styled from 'styled-components'
import { ModalContentWrapper } from 'secondstep-components'
import { ButtonsWrapper, ModalWrapper } from '../component.styles'

export const ModalWrapperError = styled(ModalWrapper)`
  padding: ${({ size }) =>
    size === 'medium' ? '4.5rem 3rem 5rem' : `2.125rem 1.5rem 5rem`};
  ${ModalContentWrapper} {
    align-items: ${({ left }) => (left ? 'inherit' : 'center')};
  }
`

export const ButtonsWrapperError = styled(ButtonsWrapper)`
  margin-top: 4rem;
`
