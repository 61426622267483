export const SELA_ACTIVE_STATUS = {
  Accepted: 'Active',
  Invited: 'Invited',
}

export const SELA_PROGRESS_STATUS = {
  incomplete: 'Not Done',
  'in progress': 'Not Done',
  'not attempted': 'Not Done',
  'not started': 'Not Done',
  completed: 'Done',
}

export const GRADE = {
  Kindergarten: 'gradek',
  'Grade 1': 'grade1',
  'Grade 2': 'grade2',
  'Grade 3': 'grade3',
  'Grade 4': 'grade4',
  'Grade 5': 'grade5',
  'Grade 6': 'grade6',
  'Grade 7': 'grade7',
  'Grade 8': 'grade8',
}
