import React, { useState } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { CardList } from 'secondstep-components'
import TextLinkWithFileIcon from 'components/TextLinkWithFileIcon'
import VideoDataGetter from 'experienceComponents/VideoDataGetter'
import { DEFAULT_LOCALE } from 'layers/content/data'
import mindyeti from 'themes/mindyeti'
import {
  HeaderParagraph,
  Header2,
  Paragraph,
  Strong,
  StyledCard,
  StyledClosableModal,
  StyledContainedButton,
  StyledContainer,
  TextLinkWithFileIconContainer,
} from './component.styles'

const MindYetiSessions = ({ title, description, locale, sessions }) => {
  const buttonTextMap = {
    'en-US': {
      video: 'Watch Video',
      session: 'Play Session',
    },
    'es-US': {
      video: 'Ver video',
      session: 'Iniciar sesión',
    },
  }

  const [videoId, setVideoId] = useState('')
  const [videoTitle, setVideoTitle] = useState('')
  const [isVideoShown, setVideoShown] = useState(false)
  const makeHeader = (title, isVideo) => (
    <Strong>
      {title}
      &nbsp;&nbsp;
      {isVideo && <img alt="video icon" src={mindyeti.icons.videoIcon} />}
    </Strong>
  )

  const getButtonText = (locale, isVideo) => {
    if (!buttonTextMap[locale]) {
      locale = DEFAULT_LOCALE
    }
    return buttonTextMap[locale][isVideo ? 'video' : 'session']
  }

  const makeButton = (vidId, isVideo, vidTitle) => (
    <StyledContainedButton
      isVideo={isVideo}
      onClick={() => {
        setVideoId(vidId)
        setVideoShown(true)
        setVideoTitle(vidTitle)
      }}
    >
      {getButtonText(locale, isVideo)}
    </StyledContainedButton>
  )

  const sessionsList = sessions.map(s => (
    <StyledCard
      actions={makeButton(s.video.id, s.isVideo, s.displayTitle)}
      key={s.id}
      title={makeHeader(s.displayTitle, s.isVideo)}
    >
      <ReactMarkdown
        renderers={{
          paragraph: Paragraph,
        }}
      >
        {`${s.video.description} &nbsp;&nbsp; ${s.video.time}`}
      </ReactMarkdown>
      {s.pdf && (
        <TextLinkWithFileIconContainer>
          <TextLinkWithFileIcon
            fileType="pdf"
            href={s.pdf.url}
            text={s.pdf.displayTitle}
          />
        </TextLinkWithFileIconContainer>
      )}
    </StyledCard>
  ))

  return (
    <StyledContainer id="mind-yeti-sessions">
      <Header2>{title}</Header2>
      <HeaderParagraph>{description}</HeaderParagraph>
      <CardList cards={sessionsList} />
      <StyledClosableModal
        modalTitle={videoTitle}
        onClose={() => setVideoShown(false)}
        show={isVideoShown}
      >
        <VideoDataGetter entryId={videoId} refetchLocale={locale} />
      </StyledClosableModal>
    </StyledContainer>
  )
}

MindYetiSessions.propTypes = {
  description: PropTypes.string,
  locale: PropTypes.string,
  sessions: PropTypes.array,
  title: PropTypes.string,
}

export default MindYetiSessions
