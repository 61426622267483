import React from 'react'
import PropTypes from 'prop-types'
import {
  ArticleIcon,
  ExcelIcon,
  GoogleDocIcon,
  IconDoc,
  IconPpt,
  IconPDF,
  StartIcon,
  StepOneIcon,
  StepTwoIcon,
  StepThreeIcon,
  StepFourIcon,
  StepFiveIcon,
} from 'secondstep-components'
import TexLinkItem from 'components/TextLinkItem'

const getFileIcon = fileType => {
  const fileTypeMap = {
    article: ArticleIcon,
    excelDoc: ExcelIcon,
    googledoc: GoogleDocIcon,
    wordDoc: IconDoc,
    pdf: IconPDF,
    powerpoint: IconPpt,
    start: StartIcon,
    stepOne: StepOneIcon,
    stepTwo: StepTwoIcon,
    stepThree: StepThreeIcon,
    stepFour: StepFourIcon,
    stepFive: StepFiveIcon,
  }
  const Icon = fileTypeMap[fileType] || (() => null)

  return Icon
}

const TextLinkWithFileIcon = ({ className, fileType, href, text }) => {
  if (!fileType || !href || !text) return null

  const Icon = getFileIcon(fileType)

  return (
    <TexLinkItem Icon={Icon} className={className} href={href} text={text} />
  )
}

TextLinkWithFileIcon.propTypes = {
  className: PropTypes.string,
  fileType: PropTypes.string,
  href: PropTypes.string,
  text: PropTypes.string,
}

export default TextLinkWithFileIcon
