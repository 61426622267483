import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 550px;
  width: 100%;
  padding: 1.5rem 1.25rem 1.25rem;
  margin: 1.25rem 0 2.5rem;
  box-sizing: border-box;
  border-radius: 0.625rem;
  background: ${themeGet('colors.backgrounds.primary', 'colors.white')};

  svg {
    color: ${themeGet('colors.darkBlue')};
  }

  p {
    margin-top: 0.25rem;
    margin-left: 0.5rem;
    font-size: 1.125rem;
    font-family: ${themeGet('font.molde.medium')};
    color: ${themeGet('colors.darkGray')};
  }

  ${themeGet('breakpoints.mobileLarge')} {
    margin-bottom: 1.25rem;
  }
`

export const MultiSiteWrapper = styled.div`
  padding: 1.5625rem 1.25rem;
  background: ${themeGet('colors.white')};
  border-radius: 10px;
`
