import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { HeaderTwo } from 'secondstep-components'
import ConfirmIcon from '../assets/confirmation_modal_illustration.svg'

export const BackButton = styled.button`
  background-color: ${themeGet('colors.white')};
  border: 0.125rem solid ${themeGet('colors.highschool.green2')};
  border-radius: 0.5rem;
  color: ${themeGet('colors.highschool.green2')};
  padding: 0.9375rem;
  margin-right: 1rem;
`

export const Button = styled.button`
  background-color: ${themeGet('colors.highschool.pink1')};
  border: none;
  border-radius: 0.5rem;
  color: ${themeGet('colors.white')};
  padding: 0.9375rem;
`

export const ConfirmationIcon = styled.img.attrs({
  src: ConfirmIcon,
})`
  margin-bottom: 1.5rem;
`

export const FinishModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Footer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`

export const Subtitle = styled.h6`
  font-family: ${themeGet('font.molde.regular')};
  margin-bottom: 1.375rem;
  text-align: center;
`

export const Title = styled(HeaderTwo)`
  font-family: ${themeGet('font.komet')};
  font-weight: 800;
  color: ${themeGet('colors.highschool.green2')};
  margin-bottom: 1.5625rem;
  text-align: center;
`
