import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  Hairline,
  HeaderOne,
  HeaderTwo,
  SearchSelect,
  TabSet,
} from 'secondstep-components'
import AdminDashboardTabButton from 'components/AdminDashboardTabButton'
import ManageLicensesLink from 'components/dashboard/ManageLicensesLink'
import ManageUsersLink from 'components/dashboard/ManageUsersLink'

const BACKROUND_COLOR_MAP = {
  BPU: {
    topColor: themeGet('colors.darkBlue'),
    bottomColor: themeGet('colors.bpu4'),
  },
  CPU: {
    topColor: themeGet('colors.cpu8'),
    bottomColor: themeGet('colors.cpu4'),
  },
  HS: {
    topColor: themeGet('colors.darkBlue'),
    bottomColor: themeGet('colors.hsPrimaryPink'),
  },
  K8: {
    topColor: themeGet('colors.darkBlue'),
    bottomColor: themeGet('colors.elemDigital1'),
  },
  SELA: {
    topColor: themeGet('colors.sela.teal7'),
    bottomColor: themeGet('colors.sela.teal6'),
  },
}

export const Background = styled.div.attrs(({ program }) => {
  const { topColor, bottomColor } = BACKROUND_COLOR_MAP[program]
  const overlapLength =
    program === 'K8' || program === 'HS'
      ? 'calc(100% - 20px)'
      : 'calc(100% + 60px)'

  return {
    topColor,
    bottomColor,
    overlapLength,
  }
})`
  background: linear-gradient(
    to bottom,
    ${({ topColor }) => topColor},
    ${({ bottomColor }) => bottomColor}
  );
  // banner should overlap sites list by 60px:
  height: ${({ overlapLength }) => overlapLength};
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  left: calc(-50vw + 50%);
`

export const BannerWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
`

export const BannerInnerWrapper = styled.div`
  flex: 1;
  max-width: 1366px;
  position: relative;
  z-index: 1;
`

export const Divider = styled.div`
  border-right: 1.5px solid
    ${themeGet(
      'colors.buttons.primaryBackground',
      `${themeGet('colors.ost6')}`,
    )};
  margin: 0.3125rem 0.4375rem 0.25rem;
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 0.75rem;

  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
  }
`

export const ManagementWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-top: 0.9375rem;

  [class*='Column'] {
    display: block;
    width: unset;

    &:hover {
      span {
        border-bottom: 1px solid ${themeGet('colors.white')};
      }

      [class*='IconRightArrow'] {
        display: none;
      }
    }
  }

  span {
    border-bottom: 1px solid transparent;
    color: ${themeGet('colors.linkLight')};
  }

  svg {
    display: none;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    justify-content: flex-start;
  }
`

export const ResourceLinksWrapper = styled.div`
  padding-top: 1.25rem;
  margin-bottom: 1rem;
`

export const StyledHairline = styled(Hairline)`
  color: ${themeGet('colors.paleGray')};
  height: 2px;
  opacity: 0.25;
`

export const StyledHeaderOne = styled(HeaderOne)`
  color: ${themeGet('colors.linkLight')};
  font-size: 2.375rem;
`

export const StyledHeaderTwo = styled(HeaderTwo)`
  color: ${themeGet('colors.linkLight')};
  font-size: 1.125rem;
  margin-bottom: 0.125rem;
  padding-top: 1rem;
`

export const StyledManageLicensesLink = styled(ManageLicensesLink)``

export const StyledManageUsersLink = styled(ManageUsersLink)``

export const StyledProgramDropdown = styled(SearchSelect)`
  padding-top: 0.5rem;
  padding-left: 1.75rem;

  svg {
    color: ${themeGet('colors.darkBlue')};

    &:last-of-type {
      width: 14px;
      height: 12px;
      margin-left: auto;
    }
  }

  span {
    color: ${themeGet('colors.darkBlue')};
    font-family: ${themeGet('fontFamilySemibold')};
  }

  [class*='LabelWrapper'] {
    display: none;
  }

  [class*='DropdownButton'] {
    border: unset;
    border-radius: 15px;
    height: 31px;

    &:hover {
      svg,
      span {
        color: ${themeGet('colors.blue')};
      }
    }

    ${themeGet('breakpoints.tablet')} {
      width: 12rem;
    }
  }

  [class*='DropdownOptions'] {
    border: none;
    border-radius: 0.3125rem;
    box-shadow: 0 0 22px rgba(0, 0, 0, 0.2);
    margin-top: 0.625rem;
    padding: 0.25rem 0;
    width: 18rem;

    button {
      padding: 0.125rem 1rem;

      svg {
        padding-left: 0.5rem;
      }
    }

    button:not(:last-child) {
      border-bottom: solid 1px ${themeGet('colors.paleGray')};
    }

    @media screen and (max-width: 1050px) {
      right: 0;
    }

    ${themeGet('breakpoints.tablet')} {
      right: unset;
    }
  }

  [class*='DropdownSpaceFiller'] {
    display: none;
  }

  ${themeGet('breakpoints.tablet')} {
    padding-left: 0;
  }
`

export const StyledTabSet = styled(TabSet)`
  height: auto;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;

  [class*='TabButtonWrapper'] {
    margin-right: 0;
  }
`

export const StyledAdminDashboardTabButton = styled(
  AdminDashboardTabButton,
).attrs(({ isActive }) => ({
  textColor: isActive
    ? themeGet('colors.darkBlue')
    : themeGet('colors.linkLight'),
  backgroundColor: isActive ? themeGet('colors.paleGray') : 'transparent',
  hoverBackgroundColor: isActive
    ? themeGet('colors.paleGray')
    : themeGet('colors.darkBlue'),
  hoverTextColor: isActive
    ? themeGet('colors.darkBlue')
    : themeGet('colors.linkLight'),
  cursorStatus: isActive ? 'arrow' : 'pointer',
}))`
  position: relative;
  svg {
    color: ${({ backgroundColor }) => backgroundColor};

    text {
      fill: ${({ textColor }) => textColor};
      font-family: ${themeGet('fontFamilySemibold')};
    }

    path:nth-of-type(2) {
      color: ${themeGet('colors.paleGray')};
    }
  }

  div {
    position: absolute;
    height: 37px;
    width: 93px;
    left: 2.0625rem;

    &:hover {
      cursor: ${({ cursorStatus }) => cursorStatus};
    }
    &:hover + svg > path:nth-of-type(1) {
      color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
    }

    &:focus-within + svg > path:nth-of-type(1) {
      color: ${({ hoverBackgroundColor }) => hoverBackgroundColor};
    }

    &:focus-visible {
      outline: none;
    }
  }

  svg {
    &:focus-visible {
      outline: none;
    }
  }
`

export const StyledAdminDashboardLaunchTabButton = styled(
  StyledAdminDashboardTabButton,
)`
  left: -1.75rem;
`

export const StyledAdminDashboardMonitorTabButton = styled(
  StyledAdminDashboardTabButton,
)`
  left: -3.625rem;
`
