import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { HeaderThree, TextLink } from 'secondstep-components'

export const LinksGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  padding-top: 1.25rem;
  margin: 0 0.625rem;
  border-top: 1.5px solid ${themeGet('colors.gray500')};

  ${TextLink} {
    font-weight: 500;
  }
`

export const StyledHeaderThree = styled(HeaderThree)`
  line-height: 1.125rem !important;
  margin: 0.625rem 0.625rem 0.375rem !important;
`
