import { connect } from 'react-redux'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_CONTENTFUL_PRODUCT,
  ERROR_TYPE_LMS,
} from 'layers/errorHandling/apiError'
import MultilingualPdfSelector from './component'
import NavigationHelper from 'layers/navigation/navigationHelper'

export const mapper = (entry = {}) => {
  return {
    contentDescription: entry?.displayTitle,
  }
}

const options = {
  include: 3,
  mapper,
  spread: true,
  entryIdProp: true,
  // Get all available locales for the entry
  prefetch: ['*'],
}

export const mapStateToProps = ({ contentful }, props) => {
  const entryFromCache = contentful[`${props?.entryId}:*:${options?.include}`]
    ?.payload || { file: {} }
  const fileInOurLocale = entryFromCache.file[props?.locale] || { file: {} }
  const mapOfLocalesToAssetInfo = fileInOurLocale.file || {}
  const mappedLocales = Object.keys(mapOfLocalesToAssetInfo)

  const fileLinks = {}
  for (const locale of mappedLocales) {
    const assetInfo = mapOfLocalesToAssetInfo[locale]
    fileLinks[locale] = assetInfo?.url
  }

  return {
    contentDescription: props.contentDescription,
    id: props.id,
    targetLocales: props.targetLocales,
    fileLinks,
  }
}

export default ContentEntryDataGetter(
  withApiError(MultilingualPdfSelector, [
    ERROR_TYPE_CONTENTFUL,
    ERROR_TYPE_CONTENTFUL_PRODUCT,
    ERROR_TYPE_LMS,
  ]),
  options,
  connect(mapStateToProps, NavigationHelper.mapDispatchToProps),
)
