import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { TextButton } from 'secondstep-components'

export const StyledTextButton = styled(TextButton)`
  text-decoration: ${props => (props.isSelected ? 'underline' : 'none')};
  color: ${props =>
    props.isSelected
      ? themeGet('colors.buttons.textButtonSelected', 'inherit')
      : themeGet('colors.buttons.textButtonDefault', 'inherit')};
  &:hover {
    color: ${themeGet('colors.buttons.textButtonHover', 'inherit')};
    text-decoration: underline;
  }
`
