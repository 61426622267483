import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { HeaderThree } from 'secondstep-components'
import { MarkdownParagraph } from 'components/MarkdownComponent'

export const StyledHeaderThree = styled(HeaderThree)`
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('fontFamilyBold')};
  margin: 0;
`

export const StyledMarkdownParagraph = styled(MarkdownParagraph)`
  font-weight: bold;

  h4 {
    font-size: 1.25rem;
  }
`
