import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const StyledDropdown = styled.div`
  margin-top: 1.75rem;
  margin-bottom: 1.25rem;
  align-self: center;

  button[aria-expanded='true'] {
    svg {
      transform: rotate(180deg);
    }
  }

  button {
    background-color: ${themeGet('colors.highschool.green0')};
    border-radius: 0.938rem;
    border: none;
    box-shadow: none;
    color: ${themeGet('colors.themePrimary')};

    input {
      padding: 0.25rem 0.75rem 0.375rem;
      padding-right: 0;
      font-size: 1rem;
      font-family: ${themeGet('font.molde.semiBold')};
      font-weight: normal;
      line-height: 1.5;
    }

    svg {
      fill: ${themeGet('colors.themeSecondary')};
      stroke: ${themeGet('colors.themeSecondary')};
    }
  }
`

export const StyledDropdownOption = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${themeGet('colors.themePrimary')};
  font-family: ${themeGet('font.molde.semiBold')};
  font-size: 1rem;
  align-items: center;
  background-color: ${themeGet('colors.highschool.green0')};
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  &:hover,
  &:focus,
  &:active,
  &:link,
  &:visited {
    color: ${themeGet('colors.white')};
    background-color: ${themeGet('colors.themePrimary')};

    svg {
      path {
        fill: ${themeGet('colors.themeSecondary')};
        stroke: ${themeGet('colors.themeSecondary')};
      }
    }
  }

  svg {
    margin: 0.4em 1rem;
    path {
      fill: ${themeGet('colors.themePrimary')};
    }
  }
`
export const StyledDropdownOptionText = styled.div`
  padding: 0.75rem;
`
