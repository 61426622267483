export const ELEM_DIGITAL_KEY = 'k5SelDigital'
export const MSP_DIGITAL_KEY = 'mspDigital'
export const BPU_DIGITAL_KEY = 'bpuDigital'
export const CPU_DIGITAL_KEY = 'cpuDigital'
export const SELA_DIGITAL_KEY = 'sela'
export const SELA_FIELD_TEST_KEY = 'fTsela'
export const HIGH_SCHOOL_DIGITAL_KEY = 'highSchool'

export const SELA_ADULT = 'adult'
export const SELA_LEADER = 'leader'
export const SELA_STAFF = 'staff'
export const SELA_ADMIN = 'admin'
export const REPORTS = 'reports'

export const SELA_ROLES = [
  SELA_ADULT,
  SELA_LEADER,
  SELA_STAFF,
  SELA_ADMIN,
  REPORTS,
]

// Kits
export const KIT_MSP = 'mspKit'
export const KIT_CPU = 'cpuKit'
export const KIT_BPU = 'bpuKit'
export const KIT_SSP = 'sspKit'
export const KIT_OST = 'ostKit'
export const KIT_EL = 'elKit'

// Map of has**Claim to license keys
export const LICENSE_TYPES = {
  elem: ELEM_DIGITAL_KEY,
  ms: MSP_DIGITAL_KEY,
  sela: SELA_DIGITAL_KEY,
  ft: SELA_FIELD_TEST_KEY,
  bpu: BPU_DIGITAL_KEY,
  cpu: CPU_DIGITAL_KEY,
  highschool: HIGH_SCHOOL_DIGITAL_KEY,
  kitMs: KIT_MSP,
  kitCpu: KIT_CPU,
  kitBpu: KIT_BPU,
  kitSsp: KIT_SSP,
  kitOst: KIT_OST,
  kitEarlyLearning: KIT_EL,
}
