import { getEntryFromParentContent } from '../utils/selectors'

export function findRawProductByPath(rootEntry, pathname) {
  const pathProduct = pathname.split('/')

  // if we have 3 items in the path then we are in the product only level
  // with 4 we are in product Section level . For example, /product/middle-school/
  // vs /product/middle-school/teach/
  const productLevelAmount = pathProduct.length

  if (productLevelAmount === 3) {
    const productLevelPath = pathProduct[2]
    const productEntry = getProductEntry(rootEntry, productLevelPath)

    return { productEntry }
  }

  if (productLevelAmount >= 4) {
    const productLevelPath = pathProduct[2]
    const productSectionPath = pathProduct[3]

    const productEntry = getProductEntry(rootEntry, productLevelPath)
    const productSectionEntry = getProductSection(
      productEntry,
      productSectionPath,
    )

    return { productSectionEntry, productEntry }
  }
}

export function getProductSection(productEntry, productSectionPath) {
  const entry = getEntryFromParentContent(
    productEntry,
    'productSections',
    productSectionPath,
  )

  return entry
}

export function getProductEntry(rootEntry, productSlug) {
  const entry = getEntryFromParentContent(rootEntry, 'products', productSlug)

  return entry
}
