export function getSlug(title) {
  return title
    .toLowerCase()
    .split(' ')
    .join('-')
}
export function getRelevantClasses(classes, courseLinks) {
  return classes?.filter(cl =>
    courseLinks.find(
      c => c.entryId.toLowerCase() === cl.contentfulID.toLowerCase(),
    ),
  )
}
export function getTeachTab(productSections) {
  return productSections?.find(
    section => section?.contentType?.id === 'firstLevelSections',
  )
}
export function getCourses(teachTab) {
  return teachTab?.productSubsections ?? []
}
export function getCourseLinks(courses) {
  return courses.map(course => ({
    slug: getSlug(course.displayTitle),
    title: course.displayTitle,
    entryId: course.id,
  }))
}
export function getSelectedProduct(products, productName) {
  return products?.find(p => p?.pageData?.route === productName)
}
