import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { Header2, StyledContainer, Paragraph } from './component.styles'

const MindYetiFooter = ({ header, body }) => {
  return (
    <StyledContainer id="mind-yeti-footer">
      <Header2>{header}</Header2>
      <ReactMarkdown
        renderers={{
          paragraph: Paragraph,
        }}
      >
        {body}
      </ReactMarkdown>
    </StyledContainer>
  )
}

MindYetiFooter.propTypes = {
  body: PropTypes.string,
  header: PropTypes.string,
}

export default MindYetiFooter
