import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import LanguageToggle from 'experienceComponents/LanguageToggle'
import LearnErrorBoundary from 'components/LearnErrorBoundary'
import {
  Anchor,
  BackLink,
  Container,
  ContentContainer,
  Header1,
  LanguageToggleWrapper,
  TabLinkContainer,
  Paragraph,
} from './component.styles'

const splitPathnameFromSlug = () => {
  const parts = location.pathname.split('/')
  // accounts for trailing slash
  const slug = parts.pop() || parts.pop()
  return {
    parentPaths: parts,
    slug: slug,
  }
}

const MindYetiHeading = ({
  backgroundImage,
  description,
  getEntryWithNewLocale,
  locale,
  mobileBackgroundImage,
  navigationEvent,
  tabs,
  title,
}) => {
  const { parentPaths, slug } = splitPathnameFromSlug()

  const tabLinks = tabs.map(t => (
    <Anchor
      active={slug === t.route}
      href=""
      key={t.route}
      onClick={e => {
        e.preventDefault()
        navigationEvent([...parentPaths, t.route].join('/'))
      }}
    >
      {t.title}
    </Anchor>
  ))

  return (
    <Container
      backgroundImage={backgroundImage}
      id="mind-yeti-heading"
      mobileBackgroundImage={mobileBackgroundImage}
    >
      <nav>
        <BackLink
          href=""
          onClick={e => {
            e.preventDefault()
            navigationEvent('/dashboard')
          }}
        >
          &lt; Back to Dashboard
        </BackLink>
      </nav>
      <LearnErrorBoundary>
        <LanguageToggleWrapper>
          <LanguageToggle
            boundary
            getEntryWithNewLocale={getEntryWithNewLocale}
            initialLocale={locale}
          />
        </LanguageToggleWrapper>
      </LearnErrorBoundary>
      <ContentContainer>
        <Header1>{title}</Header1>
        <ReactMarkdown
          renderers={{
            paragraph: Paragraph,
          }}
        >
          {description}
        </ReactMarkdown>
        <TabLinkContainer>{tabLinks}</TabLinkContainer>
      </ContentContainer>
    </Container>
  )
}

MindYetiHeading.propTypes = {
  backgroundImage: PropTypes.node,
  description: PropTypes.string,
  getEntryWithNewLocale: PropTypes.func,
  locale: PropTypes.string,
  mobileBackgroundImage: PropTypes.string,
  navigationEvent: PropTypes.func,
  tabs: PropTypes.array,
  title: PropTypes.node,
}

export default MindYetiHeading
