import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy } from 'secondstep-components'

export const CardHeading = styled.p`
  font-size: 2.5rem;
  margin-top: 0.5625rem;
  margin-bottom: 0;
  line-height: 2.6875rem;
  letter-spacing: 0, 3rem;
  font-family: ${themeGet('font.sharpSlab.medium')};
  color: ${themeGet('colors.darkGray')};
`

export const CardContainer = styled.div`
[class*='StyledBox-sc-13pk1d4-0'] {
  background-color: ${themeGet('colors.white')};
`

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const LastUpdatedMessage = styled(BodyCopy)`
  display: none;

  ${themeGet('breakpoints.tablet')} {
    display: block;
    position: relative;
    top: -8px;
    right: -8px;
    color: ${themeGet('colors.gray1100')};
    font-size: 0.75rem;
  }
`
