import styled from 'styled-components'
import { themeGet } from 'styled-system'
import ReactMarkdown from 'react-markdown'
import { BaseContainer, HeaderTwo } from 'secondstep-components'

export const EmptyIcon = styled.img`
  width: 6rem;
  margin: auto;
  margin-bottom: 1.25rem;
`

export const StyledContainer = styled(BaseContainer)`
  background-color: ${themeGet('colors.beaconGray')};
  margin: 1.25rem;
  padding: 7.5rem 0rem;
  text-align: center;

  ${themeGet('breakpoints.mobileLarge')} {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  ${themeGet('breakpoints.mobile')} {
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 2.5rem;
    padding-bottom: 2.25rem;
  }
`

export const StyledHeader = styled(HeaderTwo)`
  font-size: 1.125rem;
  margin-bottom: 1.25rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;

  ${themeGet('breakpoints.tablet')} {
    font-size: 1.125rem;
  }
`
export const StyledMarkdown = styled(ReactMarkdown)`
  color: ${themeGet('colors.darkGray')};
  max-width: 30rem;
  margin: auto;
  text-align: center;

  p {
    margin: 0;
  }

  a {
    color: ${themeGet('colors.darkGray')};
  }
`
