import React from 'react'
import PropTypes from 'prop-types'
import { formatHoursSinceTimestamp } from 'utils/reportsHelpers'
import { TOOLTIP_LOCATIONS, TOOLTIP_PROGRAMS } from 'utils/tooltipHelpers'
import AdminExportDownloadButton from 'components/AdminExportDownloadButton'
import {
  Bold,
  Bullet,
  Classes,
  LastUpdatedMessage,
  LeftBox,
  Lessons,
  LessonCompleted,
  LessonsTooltip,
  RightBox,
  SchoolTitle,
  SchoolsAndClasses,
  SiteHeaderWrapper,
  StyledHeaderOne,
  Summary,
  Totals,
} from './component.styles'
import { SITE_HEADER } from './constants'

const AdminSiteHeader = ({
  classesCreated,
  classesNotStarted,
  core,
  etlLastUpdatedAt: lastUpdatedAt,
  lessonsCompleted,
  lessonsTotal,
  singleSite,
  siteName,
  sitesTotal,
  sitesPreferences,
  unit5,
}) => {
  const hasBHUEnabled = Object.keys(sitesPreferences).reduce((enabled, key) => {
    const bhuEnabled =
      sitesPreferences[key].mspDigital?.bullyingHarassmentUnitEnabled ?? false
    return enabled || bhuEnabled
  }, false)
  const hasBHUClassCreated = unit5.lessonsTotal > 0
  const bhuStatus = hasBHUEnabled && hasBHUClassCreated
  const classesNotStartedStr = `class${classesNotStarted === 1 ? '' : 'es'} ha${
    classesNotStarted === 1 ? `sn't` : `ven't`
  } completed a lesson yet`
  const classesStr = `Class${classesCreated === 1 ? '' : 'es'} Created`
  const lessonsCompletedStr = `of ${
    bhuStatus ? core.lessonsTotal : lessonsTotal
  } Lessons Completed ${bhuStatus ? 'in Units 1-4' : ''}`
  const bhuLessonsCompletedStr = `of ${unit5.lessonsTotal} Recognizing Bullying & Harassment lessons completed`
  const lastUpdatedMessage = formatHoursSinceTimestamp(lastUpdatedAt)
  const schoolsStr = !singleSite && `School${sitesTotal === 1 ? '' : 's'}`

  return (
    <SiteHeaderWrapper>
      <LeftBox>
        {singleSite && <SchoolTitle>{siteName?.toUpperCase()}</SchoolTitle>}
        <StyledHeaderOne singleSite={singleSite}>{SITE_HEADER}</StyledHeaderOne>
        <Summary>
          <SchoolsAndClasses singleSite={singleSite}>
            {!singleSite && (
              <Classes key="multi-site-1">
                <Bold>{sitesTotal}</Bold> {schoolsStr}
                <Bold>{` • `}</Bold>
                <Bold>{classesCreated}</Bold> {classesStr}
                <span className="pipe">|</span>
                {classesNotStarted !== 0 && (
                  <span>
                    <Bullet /> {classesNotStarted} {classesNotStartedStr}
                  </span>
                )}
              </Classes>
            )}
            {singleSite && (
              <Totals>
                <Classes>
                  <Bold>{classesCreated}</Bold> {classesStr}
                  <span className="pipe">|</span>
                </Classes>
                {classesNotStarted !== 0 && (
                  <Classes>
                    <Bullet /> {classesNotStarted} {classesNotStartedStr}
                  </Classes>
                )}
              </Totals>
            )}
          </SchoolsAndClasses>
        </Summary>
        {!singleSite && (
          <Totals>
            <Lessons>
              <LessonCompleted>{lessonsCompleted}</LessonCompleted>{' '}
              {lessonsCompletedStr}
              {bhuStatus && (
                <Classes>
                  <span className="pipe">|</span>
                  <Bold>{unit5.lessonsCompleted}</Bold> {bhuLessonsCompletedStr}
                </Classes>
              )}
              <LessonsTooltip
                location={TOOLTIP_LOCATIONS.HEADER_LESSONS}
                monitorView
                programKey={TOOLTIP_PROGRAMS.K8_MONITOR}
              />
            </Lessons>
          </Totals>
        )}
      </LeftBox>
      <RightBox>
        <LastUpdatedMessage>{lastUpdatedMessage}</LastUpdatedMessage>
        {classesCreated > 0 && (
          <AdminExportDownloadButton
            programKey={TOOLTIP_PROGRAMS.K8_MONITOR}
            tooltipLocation={TOOLTIP_LOCATIONS.EXPORT_BUTTON}
          />
        )}
      </RightBox>
    </SiteHeaderWrapper>
  )
}

AdminSiteHeader.propTypes = {
  classesCreated: PropTypes.number,
  classesNotStarted: PropTypes.number,
  core: PropTypes.object,
  etlLastUpdatedAt: PropTypes.string,
  lessonsCompleted: PropTypes.number,
  lessonsTotal: PropTypes.number,
  singleSite: PropTypes.bool,
  siteName: PropTypes.string,
  sitesPreferences: PropTypes.object,
  sitesTotal: PropTypes.number,
  unit5: PropTypes.object,
}

export default AdminSiteHeader
