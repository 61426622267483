import React from 'react'
import PropTypes from 'prop-types'
import { ProgressIndicatorWrapper } from './component.styles'

const ProgressIndicator = ({ completed, text }) => {
  return (
    <ProgressIndicatorWrapper>
      {completed}
      {text}
    </ProgressIndicatorWrapper>
  )
}

ProgressIndicator.propTypes = {
  completed: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  total: PropTypes.number.isRequired,
}

export default ProgressIndicator
