import React, { Component } from 'react'
import PropTypes from 'prop-types'
import CourseHeader, {
  scopeAndSequenceLinksType,
  lessonsAmountsType,
} from 'experienceComponents/CourseHeader'
import CourseInstanceCard from 'experienceComponents/CourseInstanceCard'
import LearnErrorBoundary from 'components/LearnErrorBoundary'
import { isFlagOn } from 'utils/featureFlags'
import { isElementaryPage } from 'utils/productHelpers'

export default class CourseComponent extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.string,
    ]),
    createdDate: PropTypes.string,
    displayTitle: PropTypes.string,
    forwardedRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
    ]),
    instance: PropTypes.string,
    isEditable: PropTypes.bool,
    lessonsAmounts: lessonsAmountsType,
    listView: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    scoId: PropTypes.number,
    scopeAndSequenceLinks: scopeAndSequenceLinksType,
    selectCourseInstance: PropTypes.func,
    setFocus: PropTypes.func,
    skipHead: PropTypes.bool,
    title: PropTypes.string,
    totalAmount: PropTypes.number,
    totalComplete: PropTypes.number,
  }

  handleSelectCourseInstance = () => {
    const { props } = this
    if (!props.selectCourseInstance) return null
    return props.selectCourseInstance(props.instance)
  }

  parseGradeText = gradeText => {
    if (!gradeText) {
      return ''
    }
    return gradeText === 'Kindergarten' ? 'K' : gradeText.slice(6, 7)
  }

  onComponentDidMount() {
    if (
      isElementaryPage() &&
      isFlagOn(['feature_LEARN-18511-k5-elem-program-flex'])
    ) {
      console.log('[DEBUG] CourseComponent rendering K5 in program flex')
    }
  }

  render() {
    const {
      children,
      createdDate,
      displayTitle,
      isEditable,
      listView,
      onBlur,
      onFocus,
      title,
      scoId,
      scopeAndSequenceLinks,
      setFocus,
      lessonsAmounts,
      totalAmount,
      totalComplete,
      instance,
      skipHead,
    } = this.props

    const gradeNumber = this.parseGradeText(displayTitle)

    return (
      <LearnErrorBoundary>
        {listView && (
          <CourseInstanceCard
            course={this.props}
            courseInstanceName={title}
            createdDate={createdDate}
            gradeNumber={gradeNumber}
            gradeTitle={title}
            isEditable={isEditable}
            key={`course-instance-${title}`}
            onBlur={onBlur}
            onFocus={onFocus}
            ref={this.props.forwardedRef}
            scoId={scoId}
            setFocus={setFocus}
            totalAmount={totalAmount}
            totalComplete={totalComplete}
            viewLessonsOnClick={this.handleSelectCourseInstance}
          />
        )}
        {!listView && (
          <CourseHeader
            dataTestId="course-instance-header"
            gradeNumber={gradeNumber}
            gradeTitle={title}
            isPreview={!instance}
            key={`course-instance-header-${title}`}
            lessonsAmounts={lessonsAmounts}
            scopeAndSequenceLinks={scopeAndSequenceLinks}
            skipHead={skipHead}
          />
        )}
        {children && children}
      </LearnErrorBoundary>
    )
  }
}
