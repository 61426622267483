/* eslint-disable react/jsx-sort-props */
import React from 'react'
import PropTypes from 'prop-types'
import { BodyCopy, HeartIcon } from 'secondstep-components'
import dayjs from 'dayjs'
import {
  BigText,
  ClassesTable,
  ClassesTooltip,
  IconContainer,
  LastLessonTooltip,
  LessonProgressTooltip,
  MarkedDoneTooltip,
  MultipleClassesTextContainer,
  SingleClassTextContainer,
  SmallText,
  StyledTextContainer,
} from './component.styles'
import {
  FlexContainer,
  TextHiddenOverflow,
} from 'components/ReportsTable/component.styles'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { toTitleCase } from 'utils/stringHelpers'
import { lessonTitleMap } from 'components/K8LessonsTable/unitAndLessonTitleMap'

dayjs.extend(localizedFormat)

const K8ClassesTable = React.forwardRef(
  (
    {
      classes,
      classesTooltip,
      displayUnit5Column,
      handleRowClick,
      lastLessonTooltip,
      markedDoneTooltip,
      progressTooltip,
      progressCoreTooltip,
      progressUnit5Tooltip,
    },
    ref,
  ) => {
    const numOfClasses = classes.length
    const ClassesTextContainer =
      numOfClasses === 1
        ? SingleClassTextContainer
        : MultipleClassesTextContainer

    const columns = [
      {
        title: 'Classes',
        component: (
          <FlexContainer>
            <TextHiddenOverflow>Classes</TextHiddenOverflow>
            <ClassesTooltip content={<BodyCopy>{classesTooltip}</BodyCopy>}>
              <i className="fas fa-question-circle" />
            </ClassesTooltip>
          </FlexContainer>
        ),
        sortValue: row => row.teacherLastName,
        fieldValue: row => {
          const {
            hasCompletedLessonLastPeriod,
            teacherLastName,
            teacherFirstName,
            className,
          } = row
          return (
            <FlexContainer>
              {hasCompletedLessonLastPeriod && (
                <IconContainer visible={hasCompletedLessonLastPeriod}>
                  <HeartIcon />
                </IconContainer>
              )}
              <ClassesTextContainer
                hasCompletedLessonLastPeriod={hasCompletedLessonLastPeriod}
              >
                <SmallText>
                  {teacherLastName}, {teacherFirstName}
                </SmallText>
                <BigText>{className}</BigText>
              </ClassesTextContainer>
            </FlexContainer>
          )
        },
      },
      {
        title: displayUnit5Column
          ? 'Lesson Progress for Units 1-4'
          : 'Lesson Progress',
        component: (
          <FlexContainer>
            <TextHiddenOverflow>
              {displayUnit5Column
                ? 'Lesson Progress for Units 1-4'
                : 'Lesson Progress'}
              <LessonProgressTooltip
                content={
                  <BodyCopy>
                    {displayUnit5Column ? progressCoreTooltip : progressTooltip}
                  </BodyCopy>
                }
              >
                <i className="fas fa-question-circle" />
              </LessonProgressTooltip>
            </TextHiddenOverflow>
          </FlexContainer>
        ),
        sortValue: row => row.lessonsCompleted,
        fieldValue: row => {
          const { lessonsCompletedCore, lessonCountCore } = row
          return (
            <StyledTextContainer numOfClasses={numOfClasses}>
              {lessonsCompletedCore} of {lessonCountCore}
            </StyledTextContainer>
          )
        },
      },
      ...(displayUnit5Column
        ? [
            {
              title: 'Lesson Progress for Bullying Unit',
              component: (
                <FlexContainer dataTestId="report-table-unit5-column">
                  <TextHiddenOverflow>
                    Lesson Progress for Bullying Unit
                    <LastLessonTooltip
                      content={<BodyCopy>{progressUnit5Tooltip}</BodyCopy>}
                    >
                      <i className="fas fa-question-circle" />
                    </LastLessonTooltip>
                  </TextHiddenOverflow>
                </FlexContainer>
              ),
              sortValue: row => row.lessonsCompletedUnit5,
              fieldValue: row => {
                let { lessonsCompletedUnit5, lessonCountUnit5 } = row
                if (lessonCountUnit5 === 0) {
                  // this is temporary because reporting is currently returning
                  // a unit count of 0 if no unit 5 lessons have been completed
                  lessonCountUnit5 = 6
                }
                return (
                  <StyledTextContainer numOfClasses={numOfClasses}>
                    {lessonsCompletedUnit5} of {lessonCountUnit5}
                  </StyledTextContainer>
                )
              },
            },
          ]
        : []),
      {
        title: 'Last Lesson Marked Done',
        component: (
          <FlexContainer>
            <TextHiddenOverflow>Last Lesson Marked Done</TextHiddenOverflow>
            <LastLessonTooltip
              content={<BodyCopy>{lastLessonTooltip}</BodyCopy>}
            >
              <i className="fas fa-question-circle" />
            </LastLessonTooltip>
          </FlexContainer>
        ),
        sortValue: row => {
          const { lastLessonCompleted } = row
          return lastLessonCompleted
            ? Number(lastLessonCompleted.match(/\d+/)[0]) || ''
            : ''
        },
        fieldValue: row => {
          const {
            lastLessonCompletedParent,
            lastLessonCompleted,
            lastLessonCompletedTitle,
          } = row
          return (
            <StyledTextContainer numOfClasses={numOfClasses}>
              {!lastLessonCompleted ? (
                'Not Started Yet'
              ) : (
                <>
                  <SmallText>
                    {lastLessonCompletedParent && (
                      <>{toTitleCase(lastLessonCompletedParent)} · </>
                    )}
                    {toTitleCase(lastLessonCompleted || '')}
                  </SmallText>
                  <BigText>
                    {lessonTitleMap?.[lastLessonCompletedTitle] ||
                      lastLessonCompletedTitle}
                  </BigText>
                </>
              )}
            </StyledTextContainer>
          )
        },
      },
      {
        title: 'Marked Done On',
        component: (
          <FlexContainer>
            <TextHiddenOverflow>Marked Done On</TextHiddenOverflow>
            <MarkedDoneTooltip
              content={<BodyCopy>{markedDoneTooltip}</BodyCopy>}
            >
              <i className="fas fa-question-circle" />
            </MarkedDoneTooltip>
          </FlexContainer>
        ),
        sortValue: row => row.lastLessonCompletedDate || '',
        fieldValue: row => {
          const lastLessonsCompletedDate = row.lastLessonCompletedDate
            ? dayjs(row.lastLessonCompletedDate).format('MM/DD/YYYY')
            : '—'
          return (
            <StyledTextContainer numOfClasses={numOfClasses}>
              {lastLessonsCompletedDate}
            </StyledTextContainer>
          )
        },
      },
    ]
    return (
      <ClassesTable
        columns={columns}
        defaultSort={{ columnTitle: 'Classes', direction: 'asc' }}
        filter={false}
        handleRowClick={handleRowClick}
        headThemeLight
        key="k8-classes-table"
        numOfClasses={numOfClasses}
        hasUnit5={displayUnit5Column}
        ref={ref}
        rows={classes}
        sort
      />
    )
  },
)

K8ClassesTable.propTypes = {
  classes: PropTypes.array,
  classesTooltip: PropTypes.string,
  displayUnit5Column: PropTypes.bool,
  handleRowClick: PropTypes.func,
  hasUnit5: PropTypes.bool,
  lastLessonTooltip: PropTypes.string,
  markedDoneTooltip: PropTypes.string,
  progressCoreTooltip: PropTypes.string,
  progressTooltip: PropTypes.string,
  progressUnit5Tooltip: PropTypes.string,
}

export default K8ClassesTable
