import createCompressor from 'redux-persist-transform-compress'
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

export default (reducerKey, reducer, blacklist = [], whitelist = []) => {
  let version = 4

  if (reducerKey === 'userSessionManager') {
    version = 1
  }

  const compressor = createCompressor()
  const config = {
    blacklist,
    key: reducerKey,
    migrate: (state, version) => {
      const migrationState = state?._persist?.version !== version ? null : state

      return Promise.resolve(migrationState)
    },
    storage,
    transforms: [compressor],
    version,
    whitelist,
  }

  return persistReducer(config, reducer)
}
