import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import {
  HeaderWrapper,
  HeaderBackground,
  HeaderContent,
  StyledHairline,
  StyledTabSet,
  StyledTabButton,
  PageWrapper,
  ContentSetWrapper,
  StyledContentTypeDescription,
  BannerSpacer,
  SiteSelectorWrapper,
} from './component.styles'
import ContentOverview from 'experienceComponents/ContentOverview'
import PathwayCard from 'components/PathwayCard'
import PathwayDropdown from 'components/PathwayDropdown'
import HighSchoolPreviewBanner from 'components/HighSchoolPreviewBanner'
import SiteSelector from 'components/SiteSelector'

const PathwayTemplate = ({
  activeTab,
  description,
  handleTabChange,
  tabDescription,
  tabs,
  title,
  collection,
  trainingCallout,
  isPreview,
}) => {
  const selectRef = useRef()
  const [tabTitle, setTabTitle] = useState(activeTab?.displayTitle)
  const handleOnPathwayChange = ({ option }) => {
    const pathWaySelected = tabs?.find(tab => tab.displayTitle === option)
    setTabTitle(option)
    handleTabChange(pathWaySelected)
  }
  const queryParams = window.location.search

  return (
    <PageWrapper>
      <HeaderWrapper>
        {isPreview && (
          <>
            <BannerSpacer />
            <HighSchoolPreviewBanner />
          </>
        )}
        <HeaderBackground />
        <HeaderContent isPreview={isPreview}>
          {!isPreview && (
            <SiteSelectorWrapper>
              <SiteSelector />
            </SiteSelectorWrapper>
          )}
          <ContentOverview description={description} title={title} />
          <StyledHairline />
          {tabs && (
            <>
              <PathwayDropdown
                className="pathway_dropdown"
                handleOnChange={handleOnPathwayChange}
                options={tabs.map(tab => tab.displayTitle)}
                ref={selectRef}
                value={tabTitle}
              />
              <StyledTabSet dataTestId="pathway-unit-tabset">
                {tabs.map((tab, index) => (
                  <StyledTabButton
                    dataTestId={`pathway-unit-tab-${(tab?.displayTitle ?? '')
                      .trim()
                      .toLowerCase()}`}
                    isActive={activeTab.displayTitle === tab.displayTitle}
                    key={index}
                    label={tab.displayTitle}
                    onClick={() => handleTabChange(tab)}
                  />
                ))}
              </StyledTabSet>
            </>
          )}
        </HeaderContent>
      </HeaderWrapper>

      <ContentSetWrapper hasFooter={tabDescription.hasFooter}>
        <StyledContentTypeDescription {...tabDescription} />
        {trainingCallout && (
          <PathwayCard
            description={trainingCallout.description}
            path={trainingCallout.route}
            title={trainingCallout.displayTitle}
          />
        )}
        {collection
          ?.filter(
            ({ description, displayTitle, route } = {}) =>
              !!description && !!displayTitle && !!route,
          )
          .map((item, i) => (
            <PathwayCard
              description={item.description}
              isPreview={isPreview}
              key={i}
              path={`${item.route}${queryParams}`}
              title={item.displayTitle}
            />
          ))}
      </ContentSetWrapper>
    </PageWrapper>
  )
}

PathwayTemplate.propTypes = {
  activeTab: PropTypes.object,
  collection: PropTypes.arrayOf(
    PropTypes.shape({
      description: PropTypes.string,
      displayTitle: PropTypes.string,
      route: PropTypes.string,
    }),
  ),
  description: PropTypes.string,
  handleTabChange: PropTypes.func,
  isPreview: PropTypes.bool,
  tabDescription: PropTypes.object,
  tabs: PropTypes.array,
  title: PropTypes.string,
  trainingCallout: PropTypes.shape({
    displayTitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    route: PropTypes.string.isRequired,
  }),
}

export default PathwayTemplate
