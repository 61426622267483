import types from './types'

const fetchingValidateActivationCode = () => {
  return {
    type: types.FETCHING_VALIDATE_ACTIVATION_CODE,
  }
}

const fetchingValidateActivationCodeError = (
  error = 'There was a problem validating your ActivationCode.',
) => {
  return {
    type: types.FETCHING_VALIDATE_ACTIVATION_CODE_ERROR,
    error,
  }
}

const fetchingValidateActivationCodeSuccess = () => {
  return {
    type: types.FETCHING_VALIDATE_ACTIVATION_CODE_SUCCESS,
  }
}

const fetchingSetupLicenses = () => {
  return {
    type: types.FETCHING_SETUP_LICENSES,
  }
}

const fetchingSetupLicensesError = (
  error = 'There was a problem loading your license(s) to be assigned.',
) => {
  return {
    type: types.FETCHING_SETUP_LICENSES_ERROR,
    error,
  }
}

const fetchingSetupLicensesSuccess = licenseData => {
  return {
    type: types.FETCHING_SETUP_LICENSES_SUCCESS,
    licenseData,
  }
}

const fetchingActiveDigitalLicensesForUser = () => {
  return {
    type: types.FETCHING_ACTIVE_DIGITAL_LICENSES,
  }
}

const fetchingActiveDigitalLicensesForUserError = (
  error = `There was a problem retrieving the user's license data.`,
) => {
  return {
    type: types.FETCHING_ACTIVE_DIGITAL_LICENSES_ERROR,
    error,
  }
}

const fetchingActiveDigitalLicensesForUserSuccess = licenseData => {
  const { activeLicenses, expiredLicenses } = licenseData

  return {
    type: types.FETCHING_ACTIVE_DIGITAL_LICENSES_SUCCESS,
    activeDigitalLicenses: activeLicenses,
    expiredLicenses,
  }
}

const fetchingUserLicenses = () => {
  return {
    type: types.FETCHING_USER_LICENSES,
  }
}
const fetchingUserLicensesError = (
  error = `There was a problem retrieving the user's license data.`,
) => {
  return {
    type: types.FETCHING_USER_LICENSES_ERROR,
    error,
  }
}
const fetchingUserLicensesSuccess = licenseData => {
  return {
    type: types.FETCHING_USER_LICENSES_SUCCESS,
    licenseData,
  }
}

const fetchingUserSites = () => {
  return {
    type: types.FETCHING_USER_SITES,
  }
}

const fetchingUserSitesError = (
  error = `There was a problem retrieving the user's site data.`,
) => {
  return {
    type: types.FETCHING_USER_SITES_ERROR,
    error,
  }
}

const fetchingUserSitesSuccess = siteData => {
  return {
    type: types.FETCHING_USER_SITES_SUCCESS,
    siteData,
  }
}

const resetState = () => {
  return {
    type: types.RESET_STATE,
  }
}

const sendingActivationCode = () => {
  return {
    type: types.SENDING_ACTIVATION_CODE,
  }
}

const sendingActivationCodeError = (
  error = 'There was a problem submitting a Activation Code.',
) => {
  return {
    type: types.SENDING_ACTIVATION_CODE_ERROR,
    error,
  }
}

const sendingActivationCodeSuccess = payload => {
  return {
    type: types.SENDING_ACTIVATION_CODE_SUCCESS,
    payload,
  }
}

const setNotificationAsReadError = (
  error = 'There was a problem setting the notification status to read.',
) => {
  return {
    type: types.SET_NOTIFICATION_AS_READ_ERROR,
    error,
  }
}

const setNotificationAsReadSuccess = notificationName => {
  return {
    type: types.SET_NOTIFICATION_AS_READ_SUCCESS,
    notificationName,
  }
}

export default {
  fetchingActiveDigitalLicensesForUser,
  fetchingActiveDigitalLicensesForUserError,
  fetchingActiveDigitalLicensesForUserSuccess,
  fetchingSetupLicenses,
  fetchingSetupLicensesError,
  fetchingSetupLicensesSuccess,
  fetchingUserLicenses,
  fetchingUserLicensesError,
  fetchingUserLicensesSuccess,
  fetchingUserSites,
  fetchingUserSitesError,
  fetchingUserSitesSuccess,
  fetchingValidateActivationCode,
  fetchingValidateActivationCodeError,
  fetchingValidateActivationCodeSuccess,
  resetState,
  sendingActivationCode,
  sendingActivationCodeError,
  sendingActivationCodeSuccess,
  setNotificationAsReadError,
  setNotificationAsReadSuccess,
}
