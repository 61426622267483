import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { CloseButton } from 'secondstep-components'

export const directions = isOpen => ({
  right: {
    right: isOpen ? 0 : '-100vw',
    top: 0,
  },
  left: {
    left: isOpen ? 0 : '-100vw',
    top: 0,
  },
  top: {
    transform: isOpen ? 'translateY(100%)' : 'translateY(-100%)',
    left: 0,
    bottom: '100%',
  },
  bottom: {
    transform: isOpen ? 'translateY(-100%)' : 'translateY(100%)',
    left: 0,
    top: '100%',
  },
})

export const Overlay = styled.div`
  visibility: ${({ isOpen }) => (isOpen ? 'visible' : 'hidden')};
  z-index: 1001;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${themeGet('colors.modals.background')};
`

export const Pane = styled(Box).attrs(({ dataTestId }) => {
  return {
    'data-testid': dataTestId,
  }
})`
  position: fixed;
  background-color: ${themeGet('colors.white')};
  width: 786px;
  height: 100vh;
  overflow: auto;
  box-shadow: 0 0 15px 3px rgba(0, 0, 0, 0.1);
  z-index: 1002;
  transition: right 0.5s ease;
  ${themeGet('breakpoints.tablet')} {
    width: 100%;
  }
`

export const StyledCloseButton = styled(CloseButton)`
  display: flex;
  align-self: flex-end;
  position: fixed;
  margin: 1.25rem 1.25rem 0;
  z-index: 210;
  svg {
    min-width: 13px;
    min-height: 13px;
    color: ${themeGet('colors.gray1200')};
  }
  ${themeGet('breakpoints.mobileLarge')} {
    padding-right: 1rem;
  }
`
