import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ContentfulParser from 'utils/contentfulParser/contents'
import { connect } from 'react-redux'
import NavigationHelper from 'layers/navigation/navigationHelper'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_CONTENTFUL_PRODUCT,
} from 'layers/errorHandling/apiError'
import { SPANISH_LOCALE } from 'layers/content/data'
import ContentLastNodeGetter from 'layers/content/Hocs/ContentLastNodeGetter'
import ContentParentNodeGetter from 'layers/content/Hocs/ContentParentNodeGetter'
import MindYetiPlaylistTemplate from 'components/MindYetiPlaylist/component'
import mindyeti from 'themes/mindyeti'

const options = {
  include: 3,
  prefetch: SPANISH_LOCALE,
}

const MindYetiPlaylistPage = ({
  entry,
  getEntryWithNewLocale,
  getParentEntryForLocale,
  navigationEvent,
  parentEntry,
  prefetchEntryWithNewLocale,
}) => {
  const [fetchedParent, setFetchedParent] = useState(false)
  const localesDoNotMatch = parentEntry?.locale !== entry?.locale

  useEffect(() => {
    if (parentEntry && !fetchedParent) {
      // prefetch the parent Spanish translation to load top-level title in Spanish
      prefetchEntryWithNewLocale(parentEntry.id, SPANISH_LOCALE, true)
      setFetchedParent(true)
    }
  })

  const getBackgroundImage = () => {
    let img = entry?.bannerImage?.image?.file?.url
    return img || mindyeti.banners.myBanner2x
  }

  const getYetiSprite = () => {
    let img = entry?.bannerForegroundImage?.image?.file?.url
    return img || mindyeti.banners.mySprite
  }

  const getTabs = parentEntry => {
    return (
      parentEntry.content.map(c => {
        return {
          route: c.pageData.route,
          title: c.displayTitle,
        }
      }) || []
    )
  }

  if (localesDoNotMatch) {
    parentEntry = getParentEntryForLocale(entry?.locale)
  }
  if (!parentEntry?.content) {
    return null
  }

  const playlist = ContentfulParser?.playlist(entry) || {}
  const tabs = getTabs(parentEntry)

  return (
    <MindYetiPlaylistTemplate
      backgroundImage={getBackgroundImage()}
      description={parentEntry.description}
      getEntryWithNewLocale={getEntryWithNewLocale}
      locale={entry.locale}
      mobileBackgroundImage={mindyeti.banners.myBanner1x}
      navigationEvent={navigationEvent}
      playlist={playlist}
      tabs={tabs}
      title={parentEntry.pageTitle}
      yetiSprite={getYetiSprite()}
    />
  )
}

MindYetiPlaylistPage.propTypes = {
  entry: PropTypes.object,
  getEntryWithNewLocale: PropTypes.func,
  getParentEntryForLocale: PropTypes.func,
  navigationEvent: PropTypes.func,
  parentEntry: PropTypes.object,
  prefetchEntryWithNewLocale: PropTypes.func,
}

const navigationConnect = connect(
  NavigationHelper.mapStateToProps,
  NavigationHelper.mapDispatchToProps,
)

export default ContentParentNodeGetter(
  ContentLastNodeGetter(
    withApiError(MindYetiPlaylistPage, [
      ERROR_TYPE_CONTENTFUL,
      ERROR_TYPE_CONTENTFUL_PRODUCT,
    ]),
    options,
    navigationConnect,
  ),
)
