import React from 'react'
import PropTypes from 'prop-types'
import NavigationHelper from 'layers/navigation/navigationHelper'
import withSiteContext from 'layers/content/v2/Hocs/withSiteContext'
import { SITE_QUERY_PARAM } from 'layers/content/v2/Hocs/withSiteContext/constants'

import { DASHBOARD_ROUTE } from 'utils/highschoolHelpers'

import SelectSiteModal from './component'

const SelectSiteModalContainer = props => {
  const {
    activeSites,
    currentSite,
    customRoute,
    handleClose,
    navigationEvent,
    setActiveSite,
    show,
  } = props

  const redirectTo = customRoute || DASHBOARD_ROUTE

  const handleSiteChange = site => {
    const { id } = site
    setActiveSite(id)
    const newUrl = !id
      ? DASHBOARD_ROUTE
      : `${redirectTo}?${SITE_QUERY_PARAM}=${id}`
    navigationEvent(newUrl, NavigationHelper.types.OPEN)
  }

  return (
    <SelectSiteModal
      activeSites={activeSites.map(s => ({ name: s?.name, id: s?.id }))}
      currentSite={currentSite}
      handleClose={handleClose}
      handleSiteChange={handleSiteChange}
      show={show}
    />
  )
}

SelectSiteModalContainer.propTypes = {
  activeSites: PropTypes.array.isRequired,
  currentSite: PropTypes.object,
  customRoute: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  navigationEvent: PropTypes.func.isRequired,
  setActiveSite: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

export default withSiteContext(SelectSiteModalContainer)
