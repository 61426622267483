import styled, { withTheme } from 'styled-components'
import { HeaderTwo } from 'secondstep-components'
import { themeGet } from 'styled-system'

const StaticHeaderTwo = withTheme(styled(HeaderTwo)`
  font-family: ${themeGet('heading.level.2.font.family')};
  font-size: 2rem;
  margin-bottom: 0.188rem;
  margin-top: 0;
`)

export default StaticHeaderTwo
