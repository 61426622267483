export const ADDITIONAL_DETAILS = 'There was an error retrieving page content'
export const ERROR_TYPE_ACCESS = 'accessError'
export const ERROR_TYPE_CONTENTFUL = 'contentfulError'
export const ERROR_TYPE_CONTENTFUL_PRODUCT = 'ERROR_TYPE_CONTENTFUL_PRODUCT'
export const ERROR_TYPE_LICENSE = 'licenseError'
export const ERROR_TYPE_LMS = 'lmsError'
export const ERROR_TYPE_PROFILE = 'profileError'
export const ERROR_TYPE_REPORTING = 'reportingError'
export const NO_ENTIRE_PAGE_ERROR = 'NO_ENTIRE_PAGE_ERROR'
export const SHOW_CONTENT_ON_401 = 'SHOW_CONTENT_ON_401'
export const SHOW_ERROR_IN_MODAL = 'SHOW_ERROR_IN_MODAL'
