import React from 'react'
import PropTypes from 'prop-types'
import { TextLink } from 'secondstep-components'

const AccessibleTextLink = ({
  ariaLabel,
  children,
  className,
  dataTestId,
  href,
  onClick,
  onKeyPress,
  target,
}) => {
  const onEnterKeyPress = event => {
    if (event.key === 'Enter') {
      return onClick(event)
    }
  }

  // NOTE: if an anchor element does not have an href attribute, it is not a valid anchor element per
  // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/main/docs/rules/anchor-is-valid.md
  // this is a stop-gap to make anchors without hrefs accessible
  const attributes = {
    'aria-label': ariaLabel,
    className,
    dataTestId,
    href,
    onClick,
    onKeyPress: onKeyPress || (!href && onClick ? onEnterKeyPress : undefined),
    target,
  }

  return <TextLink {...attributes}>{children}</TextLink>
}

AccessibleTextLink.propTypes = {
  ariaLabel: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  onKeyPress: PropTypes.func,
  target: PropTypes.string,
}

export default AccessibleTextLink
