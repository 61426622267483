import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { domains } from 'config/env'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { TOOLTIP_LOCATIONS, TOOLTIP_PROGRAMS } from 'utils/tooltipHelpers'
import AccessibleTextLink from 'components/AccessibleTextLink'
import {
  Description,
  DescriptionWrapper,
  StyledAdminTooltip,
  StyledHr,
  Total,
  Updated,
  Wrapper,
} from './component.styles'
import {
  LINK_TEXT,
  MANY_USERS_DESCRIPTION_TEXT,
  SINGLE_USER_DESCRIPTION_TEXT,
} from './constants'

const AdminUsersWithClassesCard = ({
  className,
  lastUpdated,
  navigationEvent,
  linkLabel,
  siteId,
  usersWithClassesCount,
  usersWithoutClassesCount,
}) => {
  const descriptionText =
    usersWithClassesCount === 1
      ? SINGLE_USER_DESCRIPTION_TEXT
      : MANY_USERS_DESCRIPTION_TEXT
  return (
    <Wrapper className={className}>
      <Updated>{lastUpdated}</Updated>
      <Total>{usersWithClassesCount}</Total>
      <DescriptionWrapper>
        <Description>{descriptionText}</Description>
        <StyledAdminTooltip
          className={className}
          location={TOOLTIP_LOCATIONS.LAUNCH_USERS_WITH_CLASSES}
          programKey={TOOLTIP_PROGRAMS.K8}
        />
      </DescriptionWrapper>
      {usersWithoutClassesCount > 0 && (
        <Fragment key={linkLabel}>
          <StyledHr />
          <AccessibleTextLink
            ariaLabel={linkLabel}
            dataTestId={'user-class-setup-link'}
            onClick={() =>
              navigationEvent(
                `${domains.ADMIN_DOMAIN}/user-management/${siteId}?classCount=0`,
                NavigationHelper.types.OPEN,
              )
            }
          >
            {LINK_TEXT}
          </AccessibleTextLink>
        </Fragment>
      )}
    </Wrapper>
  )
}

AdminUsersWithClassesCard.propTypes = {
  className: PropTypes.string,
  lastUpdated: PropTypes.string,
  linkLabel: PropTypes.string,
  navigationEvent: PropTypes.func,
  siteId: PropTypes.string,
  usersWithClassesCount: PropTypes.number,
  usersWithoutClassesCount: PropTypes.number,
}

export default AdminUsersWithClassesCard
