import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withApiError, {
  ERROR_TYPE_REPORTING,
} from 'layers/errorHandling/apiError'
import { selectors as reportsSelectors } from 'store/reportsManager'
import SchoolSummary from './component'

export const SchoolSummaryContainer = ({
  administratorsCount,
  gradesWithClasses,
  gradesWithNoClasses,
  entry,
  hasClassesWithCompletedLessonLastPeriod,
  isFetchingSchool,
  lastUpdatedAt,
  navigationHandler,
  numClassesWithCompletedLessonLastPeriod,
  period,
  schoolEmptyDisplay,
  selectedSite,
  siteName,
  teachersCount,
  totalClassesCount,
}) => {
  const handleSelectGrade = (navigationFunction, gradeName) => {
    navigationFunction({
      gradeName,
      siteId: selectedSite,
    })
  }

  return (
    <SchoolSummary
      administratorsCount={administratorsCount}
      entry={entry}
      gradesWithClasses={gradesWithClasses}
      gradesWithNoClasses={gradesWithNoClasses}
      handleSelectGrade={handleSelectGrade}
      hasClassesWithCompletedLessonLastPeriod={
        hasClassesWithCompletedLessonLastPeriod
      }
      isFetchingSchool={isFetchingSchool}
      lastUpdatedAt={lastUpdatedAt}
      navigationHandler={navigationHandler}
      numClassesWithCompletedLessonLastPeriod={
        numClassesWithCompletedLessonLastPeriod
      }
      period={period}
      schoolEmptyDisplay={schoolEmptyDisplay}
      selectedSite={selectedSite}
      siteName={siteName}
      teachersCount={teachersCount}
      totalClassesCount={totalClassesCount}
    />
  )
}

export const mapStateToProps = state => {
  const { reportsManager } = state
  const { isFetchingSchool, selectedSite } = reportsManager || {}
  const schoolSummaryReport = reportsSelectors.selectSchoolSummaryReport(state)
  const {
    administratorsCount,
    gradesWithClasses,
    gradesWithNoClasses,
    hasClassesWithCompletedLessonLastPeriod,
    lastUpdatedAt,
    numClassesWithCompletedLessonLastPeriod,
    period,
    siteName,
    teachersCount,
    totalClassesCount,
  } = schoolSummaryReport || {}

  return {
    administratorsCount,
    gradesWithClasses,
    gradesWithNoClasses,
    hasClassesWithCompletedLessonLastPeriod,
    isFetchingSchool,
    numClassesWithCompletedLessonLastPeriod,
    period,
    lastUpdatedAt,
    selectedSite,
    siteName,
    teachersCount,
    totalClassesCount,
  }
}

SchoolSummaryContainer.propTypes = {
  administratorsCount: PropTypes.number,
  entry: PropTypes.object,
  gradesWithClasses: PropTypes.array,
  gradesWithNoClasses: PropTypes.array,
  hasClassesWithCompletedLessonLastPeriod: PropTypes.bool,
  isFetchingSchool: PropTypes.bool,
  lastUpdatedAt: PropTypes.string,
  navigationHandler: PropTypes.func,
  numClassesWithCompletedLessonLastPeriod: PropTypes.number,
  period: PropTypes.number,
  schoolEmptyDisplay: PropTypes.object,
  selectedSite: PropTypes.number,
  siteName: PropTypes.string,
  summaryReportExists: PropTypes.bool,
  summaryReportIsFetching: PropTypes.bool,
  teachersCount: PropTypes.number,
  totalClassesCount: PropTypes.number,
}

export default connect(mapStateToProps)(
  withApiError(SchoolSummaryContainer, [ERROR_TYPE_REPORTING]),
)
