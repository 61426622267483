class ContentfulNodeFailureTypes {
  static #NOT_DESIRED_ENTRY_UPDATE = 'Not desired entry update'
  static #PATH_NOT_AVAILABLE = 'Path not available'

  static #DID_NOT_VERIFY_RUN_START_NODE = 'Did not verify run start node'
  static #NOT_ON_DESIRED_PATH = 'Not on desired path'

  static #ITEM_SELECTION_FAILED = 'Failed to get an item for a selector'
  static #DID_NOT_VERIFY_FOR_NEXT_NODE = 'Did not verify for next node'
  static #DID_NOT_CREATE_START_NODE_TREE = 'Did not create start node tree'
  static #DID_NOT_CREATE_NEXT_NODE = 'Did not create next node'
  static #CAN_NOT_FIND_NEXT_CHILD_NODE = 'Cannot find next child node'
  static #NODE_IN_TREE_HAD_AN_ERROR = 'Node in tree had an error'

  static get NOT_DESIRED_ENTRY_UPDATE() {
    return this.#NOT_DESIRED_ENTRY_UPDATE
  }
  static get PATH_NOT_AVAILABLE() {
    return this.#PATH_NOT_AVAILABLE
  }

  static get DID_NOT_VERIFY_RUN_START_NODE() {
    return this.#DID_NOT_VERIFY_RUN_START_NODE
  }
  static get NOT_ON_DESIRED_PATH() {
    return this.#NOT_ON_DESIRED_PATH
  }

  static get ITEM_SELECTION_FAILED() {
    return this.#ITEM_SELECTION_FAILED
  }
  static get DID_NOT_VERIFY_FOR_NEXT_NODE() {
    return this.#DID_NOT_VERIFY_FOR_NEXT_NODE
  }
  static get DID_NOT_CREATE_START_NODE_TREE() {
    return this.#DID_NOT_CREATE_START_NODE_TREE
  }
  static get DID_NOT_CREATE_NEXT_NODE() {
    return this.#DID_NOT_CREATE_NEXT_NODE
  }
  static get CAN_NOT_FIND_NEXT_CHILD_NODE() {
    return this.#CAN_NOT_FIND_NEXT_CHILD_NODE
  }
  static get NODE_IN_TREE_HAD_AN_ERROR() {
    return this.#NODE_IN_TREE_HAD_AN_ERROR
  }
}

export default ContentfulNodeFailureTypes
