import { connect } from 'react-redux'

import NavigationHelper from 'layers/navigation/navigationHelper'
import withApiError from 'layers/errorHandling/apiError'

import Component from './component'
import { RouteContentGetter } from 'layers/content/v2/Hocs/RouteContentGetter'
import { RouteContentProductAppMap } from 'utils/productMaps'

const FamiliResourcesContainer = RouteContentGetter(withApiError(Component), {
  app: RouteContentProductAppMap.learnOrphans,
})

FamiliResourcesContainer.displayName = 'FamilyResourcesContainer'

const navigationConnect = connect(
  NavigationHelper.mapStateToProps,
  NavigationHelper.mapDispatchToProps,
)
export default navigationConnect(FamiliResourcesContainer)
