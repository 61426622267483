import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import MsBHUMultiSiteModal from './component'

const MsBHUMultiSiteModalContainer = ({
  onModalSave,
  onModalCancel,
  rawSitesData,
}) => {
  useEffect(() => {
    const sites = JSON.parse(JSON.stringify(rawSitesData))
    let sitesHavingBHUnitCount = 0

    sites.forEach(site => {
      const bullyingEnabled =
        site.sitePreferences.mspDigital.bullyingHarassmentUnitEnabled
      site.sitePreferences.mspDigital.bullyingHarassmentUnitEnabledOriginalValue = bullyingEnabled
      if (bullyingEnabled) sitesHavingBHUnitCount++
    })

    setSitesHavingBHUnitCount(sitesHavingBHUnitCount)
    setListedSites(sites)
  }, [])

  const [listedSites, setListedSites] = useState([])
  const [countOfSitesToUpdate, setCountOfSitesToUpdate] = useState([])
  const [selectedSiteIds, setSelectedSiteIds] = useState([])
  const [sitesHavingBHUnitCount, setSitesHavingBHUnitCount] = useState(0)

  const handleSave = async () => {
    const sitesToUpdate = listedSites.reduce((bullyingSites, currentSite) => {
      const currentBullyingValue =
        currentSite?.sitePreferences?.mspDigital?.bullyingHarassmentUnitEnabled
      const originalBullyingValue =
        currentSite?.sitePreferences?.mspDigital
          ?.bullyingHarassmentUnitEnabledOriginalValue
      if (currentBullyingValue !== originalBullyingValue) {
        bullyingSites.push(currentSite)
      }
      return bullyingSites
    }, [])
    await onModalSave(sitesToUpdate)
  }

  const selectAllSites = e => {
    e.stopPropagation()
    if (listedSites.length === selectedSiteIds.length) {
      setSelectedSiteIds([])
    } else {
      setSelectedSiteIds(
        listedSites.map(site => {
          return site.siteId
        }),
      )
    }
  }

  const onCheckBoxClick = (event, eventSiteId) => {
    event.stopPropagation()
    if (event.target.checked) {
      setSelectedSiteIds([...selectedSiteIds, eventSiteId])
    } else {
      setSelectedSiteIds(
        selectedSiteIds.filter(siteId => siteId !== eventSiteId),
      )
    }
  }

  const changeBHUStatus = value => {
    let numberOfSitesWithBullyingOn = 0
    let numberOfSitesToUpdate = 0
    const updatedSites = listedSites.map(site => {
      if (selectedSiteIds.includes(site.siteId)) {
        site.sitePreferences.mspDigital.bullyingHarassmentUnitEnabled = value
      }
      const originalValue =
        site.sitePreferences.mspDigital
          .bullyingHarassmentUnitEnabledOriginalValue
      const currentValue =
        site.sitePreferences.mspDigital.bullyingHarassmentUnitEnabled
      if (originalValue !== currentValue) numberOfSitesToUpdate++
      if (currentValue) numberOfSitesWithBullyingOn++
      return site
    })

    setListedSites(updatedSites)
    setSitesHavingBHUnitCount(numberOfSitesWithBullyingOn)
    setCountOfSitesToUpdate(numberOfSitesToUpdate)
  }

  const allSelected = selectedSiteIds.length > 0
  const indeterminate =
    listedSites.length !== selectedSiteIds.length && selectedSiteIds.length > 0
  return (
    <MsBHUMultiSiteModal
      allSelected={allSelected}
      changeBHUStatus={changeBHUStatus}
      indeterminate={indeterminate}
      isSaveButtonEnabled={countOfSitesToUpdate > 0}
      onCheckBoxClick={onCheckBoxClick}
      onModalCancel={onModalCancel}
      onModalSave={handleSave}
      selectAllSites={selectAllSites}
      selectedSiteIds={selectedSiteIds}
      setListedSites={setListedSites}
      sites={listedSites}
      sitesHavingBHUnitCount={sitesHavingBHUnitCount}
    />
  )
}

MsBHUMultiSiteModalContainer.propTypes = {
  onModalCancel: PropTypes.func,
  onModalSave: PropTypes.func.isRequired,
  rawSitesData: PropTypes.arrayOf(
    PropTypes.shape({
      siteId: PropTypes.number,
      name: PropTypes.string,
      sitePreferences: PropTypes.shape({
        mspDigital: PropTypes.shape({
          bullyingHarassmentUnitEnabled: PropTypes.bool,
        }),
      }),
    }),
  ).isRequired,
  sitesHavingBHUnitCount: PropTypes.number,
}

export default MsBHUMultiSiteModalContainer
