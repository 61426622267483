import React from 'react'
import PropTypes from 'prop-types'
import { StyledAccordion } from './component.styles'

const ResourcesContentAccordionComponent = ({
  children,
  id,
  pageComponentIndex,
  title,
  titleAs,
}) => {
  const dataTestId = `accordion-${pageComponentIndex}-${id}`
  return (
    <StyledAccordion dataTestId={dataTestId} title={title} titleAs={titleAs}>
      {children}
    </StyledAccordion>
  )
}

ResourcesContentAccordionComponent.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string,
  pageComponentIndex: PropTypes.string,
  title: PropTypes.string.isRequired,
  titleAs: PropTypes.string,
}

ResourcesContentAccordionComponent.defaultProps = {
  children: [],
  id: 'test-id',
  pageComponentIndex: '0',
  title: '',
}

export default ResourcesContentAccordionComponent
