import React from 'react'
import PropTypes from 'prop-types'
import { EmptyDeskIcon, MegaphoneIcon } from 'secondstep-components'
import {
  ButtonText,
  ButtonTextLink,
  LeftContentWrapper,
  RightContentWrapper,
  LeftBox,
  PrimaryText,
  ProgramToStaffButton,
  ProgramToStaffButtonContent,
  RightBox,
  SecondaryText,
  StaffTrainingButton,
  StaffTrainingButtonContent,
  StyledAwardIcon,
  StyledHairline,
  Wrapper,
} from './component.styles'
import NavigationHelper from 'layers/navigation/navigationHelper'
import {
  LESSON_PROGRESS_MESSAGE,
  PROGRAM_INTRODUCTION_LINK,
  PROGRAM_TO_STAFF_LINK_TEXT,
  SCHOOL_KICK_OFF_MESSAGE,
  STAFF_TRAINING_LINK,
  STAFF_TRAINING_LINK_TEXT,
  TEACHER_CLASS_MESSAGE,
  TEACHER_TRAINING_MESSAGE,
} from './constants'

const AdminNoClasses = ({ navigationEvent }) => {
  const navigateToProgramIntroduction = () => {
    const type = NavigationHelper.types.NEW_TAB
    navigationEvent(PROGRAM_INTRODUCTION_LINK, type)
  }

  const navigateToStaffTraining = () => {
    const type = NavigationHelper.types.NEW_TAB
    navigationEvent(STAFF_TRAINING_LINK, type)
  }

  return (
    <Wrapper>
      <LeftContentWrapper>
        <LeftBox>
          <EmptyDeskIcon />
          <PrimaryText>{LESSON_PROGRESS_MESSAGE}</PrimaryText>
          <SecondaryText>{TEACHER_CLASS_MESSAGE}</SecondaryText>
        </LeftBox>
      </LeftContentWrapper>
      <StyledHairline />
      <RightContentWrapper>
        <RightBox>
          <ProgramToStaffButton onClick={navigateToProgramIntroduction}>
            <MegaphoneIcon />
            <ProgramToStaffButtonContent>
              <ButtonText>{SCHOOL_KICK_OFF_MESSAGE}</ButtonText>
              <ButtonTextLink>{PROGRAM_TO_STAFF_LINK_TEXT}</ButtonTextLink>
            </ProgramToStaffButtonContent>
          </ProgramToStaffButton>
          <StaffTrainingButton onClick={navigateToStaffTraining}>
            <StyledAwardIcon />
            <StaffTrainingButtonContent>
              <ButtonText>{TEACHER_TRAINING_MESSAGE}</ButtonText>
              <ButtonTextLink>{STAFF_TRAINING_LINK_TEXT}</ButtonTextLink>
            </StaffTrainingButtonContent>
          </StaffTrainingButton>
        </RightBox>
      </RightContentWrapper>
    </Wrapper>
  )
}

AdminNoClasses.propTypes = {
  navigationEvent: PropTypes.func,
}

export default AdminNoClasses
