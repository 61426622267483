import { FlagsComponentRouter } from 'flags'

import OffComponent from './flag.off/index'
import OnComponent from './flag.on/index'

const FlagToggler = FlagsComponentRouter({
  flagSubscriptions: ['feature_learn-18371_survey-learning-experience'],
  OnComponent,
  OffComponent,
})

export default FlagToggler
