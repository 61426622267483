import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FlagSvg, UserManagementSvg } from 'secondstep-components'
import { CLICK, ENTER, CLOSE, USER_MANAGEMENT_PATH } from './constants'
import {
  CloseModalButton,
  LeaderContainer,
  StyledLink,
  StyledContainer,
  TextContainer,
  HighlightText,
  RegularText,
  SchoolPageModalText,
  StyledClosableModal,
  StyledDescription,
  SvgWithBodyCopy,
  StyledHeader,
  StyledRightArrowIcon,
  StyledSvg,
  SchoolModalText,
} from './component.styles'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { getRegularEnglishPluralForm } from 'utils/stringHelpers'

const K8ReportingLeaderTools = ({
  entry,
  isSchoolSummary,
  navigationEvent,
  pendingInvitations,
  selectedSite,
}) => {
  const [isModalShown, setModalIsShown] = useState(false)

  const {
    schoolModalButtonLabel,
    schoolModalButtonText,
    schoolModalHeader1 = '',
    schoolModalHeader2 = '',
    schoolModalIcon1,
    schoolModalIcon2,
    schoolModalIcon3,
    schoolPageModalIcon4,
    schoolModalText1 = '',
    schoolModalText2 = '',
    schoolModalText3 = '',
    schoolPageModalText4 = '',
  } = entry || {}

  const schoolModalIcon1Url = schoolModalIcon1?.file?.url || ''
  const schoolModalIcon2Url = schoolModalIcon2?.file?.url || ''
  const schoolModalIcon3Url = schoolModalIcon3?.file?.url || ''
  const schoolModalIcon4Url = schoolPageModalIcon4?.file?.url || ''

  // TODO [LEARN-14354]: Replace these values with the values set in contentful
  const userManagementButtonLabel = 'User Management'
  const userManagementButtonText = `${pendingInvitations} pending ${getRegularEnglishPluralForm(
    pendingInvitations,
    'account',
  )}`
  const openModal = ({ event }) => {
    if (isModalShown || (event.type !== CLICK && event.key !== ENTER)) {
      return
    }

    setModalIsShown(true)
  }
  const closeModal = () => {
    setModalIsShown(false)
  }

  const goToUserManagementPage = () => {
    const url = `${USER_MANAGEMENT_PATH}/${selectedSite}`
    const type = NavigationHelper.types.OPEN
    navigationEvent(url, type)
  }

  const handleEnterKey = (e, onClick) => {
    if (!onClick) return

    if (e?.key === ENTER) {
      onClick(e)
    }
  }

  return (
    <LeaderContainer data-testid="LeaderContainer">
      <StyledLink
        dataTestId="leaderGuideModalButton"
        onClick={event =>
          openModal({
            event,
          })
        }
        onKeyPress={event =>
          openModal({
            event,
          })
        }
        role="link"
      >
        <StyledContainer className="leader-guide-container" tabIndex={0}>
          <FlagSvg />
          <TextContainer>
            <HighlightText>
              {schoolModalButtonLabel}
              <StyledRightArrowIcon className="arrowIcon" />
            </HighlightText>
            <RegularText>{schoolModalButtonText}</RegularText>
          </TextContainer>
        </StyledContainer>
      </StyledLink>
      {isSchoolSummary && (
        <StyledLink
          dataTestId="user-management-invitations-link"
          onClick={() => goToUserManagementPage()}
          onKeyDown={e => handleEnterKey(e, goToUserManagementPage)}
          role="link"
        >
          <StyledContainer tabIndex={0}>
            <UserManagementSvg />
            <TextContainer>
              <HighlightText>
                {userManagementButtonLabel}
                <StyledRightArrowIcon className="arrowIcon" />
              </HighlightText>
              <RegularText>{userManagementButtonText}</RegularText>
            </TextContainer>
          </StyledContainer>
        </StyledLink>
      )}
      <StyledClosableModal onClose={closeModal} show={isModalShown}>
        {schoolModalHeader1 && (
          <StyledHeader>{schoolModalHeader1}</StyledHeader>
        )}
        {schoolModalHeader2 && (
          <StyledDescription>{schoolModalHeader2}</StyledDescription>
        )}
        {(!!schoolModalIcon1Url || !!schoolModalText1) && (
          <SvgWithBodyCopy>
            {schoolModalIcon1Url && <StyledSvg src={schoolModalIcon1Url} />}
            {schoolModalText1 && (
              <SchoolModalText>{schoolModalText1}</SchoolModalText>
            )}
          </SvgWithBodyCopy>
        )}
        {(!!schoolModalIcon2Url || !!schoolModalText2) && (
          <SvgWithBodyCopy>
            {schoolModalIcon2Url && <StyledSvg src={schoolModalIcon2Url} />}
            {schoolModalText2 && (
              <SchoolModalText>{schoolModalText2}</SchoolModalText>
            )}
          </SvgWithBodyCopy>
        )}
        {(!!schoolModalIcon3Url || !!schoolModalText3) && (
          <SvgWithBodyCopy>
            {schoolModalIcon3Url && <StyledSvg src={schoolModalIcon3Url} />}
            {schoolModalText3 && (
              <SchoolModalText>{schoolModalText3}</SchoolModalText>
            )}
          </SvgWithBodyCopy>
        )}
        {(!!schoolModalIcon4Url || !!schoolPageModalText4) && (
          <SvgWithBodyCopy>
            {schoolModalIcon4Url && <StyledSvg src={schoolModalIcon4Url} />}
            {schoolPageModalText4 && (
              <SchoolPageModalText>{schoolPageModalText4}</SchoolPageModalText>
            )}
          </SvgWithBodyCopy>
        )}
        <CloseModalButton data-testid="closeButton" onClick={closeModal}>
          {CLOSE}
        </CloseModalButton>
      </StyledClosableModal>
    </LeaderContainer>
  )
}

K8ReportingLeaderTools.propTypes = {
  entry: PropTypes.object,
  isSchoolSummary: PropTypes.bool,
  navigationEvent: PropTypes.func,
  pendingInvitations: PropTypes.string,
  selectedSite: PropTypes.number,
}

export default K8ReportingLeaderTools
