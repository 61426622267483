import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { HeaderFive } from 'secondstep-components'

export const Button = styled.button.attrs(props => ({
  background: props.secondary
    ? themeGet('colors.buttons.secondary.bgActive')(props)
    : themeGet('colors.buttons.primary.bgActive')(props),
  backgroundHover: props.secondary
    ? themeGet('colors.buttons.secondary.bgHover')(props)
    : themeGet('colors.buttons.primary.bgHover')(props),
  labelHoverColor: props.secondary
    ? themeGet('colors.buttons.secondary.labelHover')(props)
    : themeGet('colors.buttons.primary.labelHover')(props),
}))`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 18.4375rem;
  height: 5.45rem;
  padding: 0.8125rem 1.25rem 0.625rem;
  border: none;
  border-radius: 0.625rem;
  background: ${({ background }) => background};
  cursor: pointer;
  transition: background 0.2s ease;
  background-color: ${themeGet('colors.programLinkTile.backgroundColor')};
  &:hover {
    background: ${({ backgroundHover }) => backgroundHover};
    transition: background 0.2s ease;

    span {
      color: ${({ labelHoverColor }) => labelHoverColor};
    }
  }
`

export const Label = styled.span.attrs(props => ({
  color: props?.secondary
    ? themeGet('colors.buttons.secondary.labelActive')(props)
    : themeGet('colors.buttons.primary.labelActive')(props),
  fontFamily: themeGet('font.molde.medium')(props),
}))`
  color: ${({ color }) => color};
  font-family: ${({ fontFamily }) => fontFamily};
  transition: color 0.2s ease;
`

export const Title = styled(HeaderFive).attrs(props => ({
  color: props.secondary
    ? themeGet('colors.buttons.secondary.titleActive')(props)
    : themeGet('colors.buttons.primary.titleActive')(props),
  colorHover: props.secondary
    ? themeGet('colors.buttons.secondary.titleHover')(props)
    : themeGet('colors.buttons.primary.titleHover')(props),
}))`
  color: ${({ color }) => color};
  font-family: ${themeGet('fonts.primary')};
  font-weight: 700;
  font-size: 1.0625rem;
  line-height: 1.125rem;
  transition: color 0.2s ease;

  :only-child {
    padding: 0;
    margin: 0;
  }

  &:hover {
    color: ${({ colorHover }) => colorHover};
    transition: color 0.2s ease;
  }
`
export const LabelWrapper = styled.div`
  display: flex;
  flex-dirextion: column;
  align-items: center;
  &:hover {
    path {
      fill: ${themeGet('colors.icon.hover.path.fill')};
    }
    span {
      color: ${themeGet('colors.icon.hover.span.color')};
    }
  }
  svg {
    margin: 0 0.5rem 0.3rem 0;
  }
`
