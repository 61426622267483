import ContentfulParser from 'utils/contentfulParser/contents'

export const formContentCardList = (entry, path) => {
  const { tabSummaries: parentListContent = [] } = entry || {}

  const contentTypes = []

  parentListContent.forEach(contentGroup => {
    const { route, displayTitle } = contentGroup || {}

    if (displayTitle && route) {
      contentTypes.push({
        title: displayTitle,
        route,
      })
    }
  })

  const activeContentType = contentTypes.find(contentGroup => {
    const { route } = contentGroup || {}

    return route === path
  })

  if (!activeContentType) {
    return null
  }

  return {
    contentTypes,
    activeContent: entry,
    activeContentType,
  }
}

export const getVideoData = entry => {
  const { content } = entry || {}
  const videoEntry = !!content && content?.find(e => e.contentType === 'Video')
  const video = ContentfulParser.video(videoEntry)

  const { displayTitle, time, videoUrl, videoThumbnailUrl, tracks, id } =
    video || {}
  const videoData = {
    videoDisplayTitle: displayTitle,
    videoLength: time,
    tracks,
    videoThumbnailUrl,
    videoUrl,
    videoId: id,
  }
  return videoData
}

export const getListProps = entry => {
  const { content } = entry || {}
  const categoryEntry =
    !!content && content.find(e => e.contentType === 'Category')
  const { displayTitle, assets = [], id } = categoryEntry || {}
  const links = assets.map(asset => {
    const {
      displayTitle,
      url,
      contentType: contentTypeWrapper,
      file: fileWrapper,
    } = asset || {}
    const { file, url: url2 } = fileWrapper || {}
    const { url: fileUrl } = file || {}
    const { id: contentType } = contentTypeWrapper || {}
    // file type contentType (i.e. PDFs) links have a fileUrl, so url will be on this level of the asset
    const href = fileUrl || url || url2

    return {
      text: displayTitle,
      href,
      contentType: contentType || contentTypeWrapper?.toLowerCase(),
    }
  })

  return {
    header: displayTitle,
    id,
    links,
  }
}
