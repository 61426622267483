import { connect } from 'react-redux'
import NavigationHelper from 'layers/navigation/navigationHelper'
import QuickLinksCard from './component.jsx'

const mapStateToProps = ({ userAccessManager }) => {
  return {
    programClaims: userAccessManager?.claims,
  }
}

export default connect(
  mapStateToProps,
  NavigationHelper.mapDispatchToProps,
)(QuickLinksCard)
