import React from 'react'
import PropTypes from 'prop-types'
import { Loading } from 'secondstep-components'
import ReactPortal from 'components/LoadingPortal'
import { LoadingWrapper } from './component.styles'

const LoadingParent = ({ background = false, loadingText }) => {
  return (
    <ReactPortal>
      <LoadingWrapper>
        <Loading background={background} loadingText={loadingText} />
      </LoadingWrapper>
    </ReactPortal>
  )
}

LoadingParent.propTypes = {
  background: PropTypes.bool,
  loadingText: PropTypes.string,
}

export default LoadingParent
