import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, Accordion } from 'secondstep-components'
import { getHighlightColor } from 'utils/highschoolHelpers'

export const StyledContent = styled.div.attrs(() => ({
  'data-testid': 'references-section',
}))`
  display: contents;
`

export const StyledAccordion = styled(Accordion)`
  background: white;
  width: 100%;
  ${themeGet('breakpoints.large')} {
    width: calc(100% - 2.5rem);
  }
  border: 1.5px solid ${props => getHighlightColor(props.audience)};
  [class*='AccordionPanel'] {
    background: white;
    padding-left: 0.125rem;
    padding-top: 0.9375rem;
    padding-bottom: 0.75rem;
  }
  [class*='AccordionTitle'] {
    font-size: ${themeGet('fonts.kometBold20.fontSize')};
    font-family: ${themeGet('fonts.kometBold20.fontFamily')};
    font-weight: bold;
    color: ${themeGet('colors.highschool.green2')};
    margin-left: 0.8125rem;
  }
  [class*='AccordionTitleWrapper'] {
    svg {
      color: ${themeGet('colors.highschool.green1')};
    }
  }
  [class*='AccordionContentWrapper'] {
    [class*='AccordionContent'] {
      word-break: break-word;
      max-width: 38.75rem;
      margin: auto;
    }
  }
  a,
  a:visited,
  a:hover {
    color: ${themeGet('colors.sela.gray1')};
    font-weight: bold;
  }
  a {
    text-decoration-line: underline;
  }
  a:hover {
    text-decoration-line: none;
  }
`

export const StyledBodyCopy = styled(BodyCopy).attrs(
  ({ renders, children }) => {
    return {
      components: renders,
      children,
    }
  },
)``

export const StyledReference = styled.p`
  ${({ isSelected }) => isSelected && 'font-weight:bold;'}
  sup {
    padding-right: 0.5rem;
  }
`
