/* istanbul ignore file */
// we don't test SVGs without arguments
import React from 'react'

const TelescopeIcon = props => (
  <svg
    height="35px"
    version="1.1"
    viewBox="0 0 48 35"
    width="48px"
    xmlns="http://www.w3.org/2000/svg"
    role="presentation"
    aria-hidden="true"
    {...props}
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g strokeWidth="1.5" transform="translate(1.962797, 1.000000)">
        <g transform="translate(22.490695, 12.020759) rotate(-17.000000) translate(-22.490695, -12.020759) translate(0.786698, 6.086328)">
          <path
            d="M46.2526468,8.76524325 C46.2526468,5.68724763 43.731448,3.19203917 40.6213916,3.19203917 C37.5113352,3.19203917 34.9901363,5.68724763 34.9901363,8.76524325"
            fill="transparent"
            stroke="currentColor"
            transform="translate(40.621392, 5.978641) rotate(-270.000000) translate(-40.621392, -5.978641) "
          />
          <rect
            fill="transparent"
            height="9.37015336"
            stroke="currentColor"
            strokeLinejoin="round"
            width="11.7465079"
            x="10.5100334"
            y="1.22205837"
          />
          <rect
            fill="transparent"
            height="11.8688609"
            stroke="currentColor"
            strokeLinejoin="round"
            transform="translate(30.239782, 5.934430) scale(-1, 1) translate(-30.239782, -5.934430) "
            width="15.6049714"
            x="22.4372963"
            y="-1.8189894e-12"
          />
          <rect
            fill="transparent"
            height="5.62209202"
            stroke="currentColor"
            strokeLinejoin="round"
            transform="translate(7.109728, 5.907135) scale(-1, 1) translate(-7.109728, -5.907135) "
            width="6.80060982"
            x="3.70942354"
            y="3.09608905"
          />
          <rect
            fill="transparent"
            height="9.37015336"
            rx="1.85471177"
            stroke="currentColor"
            strokeLinejoin="round"
            width="3.70942354"
            x="-2.27373675e-13"
            y="1.22205837"
          />
        </g>
        <line
          stroke="currentColor"
          strokeLinecap="round"
          x1="19.7595716"
          x2="15.4319109"
          y1="18.0233833"
          y2="30.6410304"
        />
        <line
          stroke="currentColor"
          strokeLinecap="round"
          x1="19.7595716"
          x2="24.7054697"
          y1="18.0233833"
          y2="30.6410304"
        />
      </g>
    </g>
  </svg>
)

export default TelescopeIcon
