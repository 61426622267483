import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Wrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))``

export const MyClassesMenuButton = styled.button.attrs(
  ({ dataTestId, isOpen }) => ({
    background: isOpen
      ? themeGet('colors.buttons.opaqueDropdownHover')
      : 'none',
    'data-testid': dataTestId,
    svgColor: isOpen
      ? themeGet('colors.buttons.dropdownIconHover')
      : themeGet('colors.themeAccent'),
  }),
)`
  display: flex;
  padding: 0.25rem;
  background: ${({ background }) => background};
  border: none;
  border-radius: 0.3438rem;

  svg {
    color: ${({ svgColor }) => svgColor};
  }

  &:hover {
    border: none;
    border-image-width: 0;
    cursor: pointer;
    background: ${themeGet('colors.buttons.opaqueDropdownHover')};

    svg {
      color: ${themeGet('colors.buttons.dropdownIconHover')};
    }
  }

  &:focus-visible {
    background: ${themeGet('colors.buttons.opaqueDropdownHover')};
    outline: 0.125rem solid ${themeGet('colors.secondStepBlue')};

    svg {
      color: ${themeGet('colors.buttons.dropdownIconHover')};
    }
  }
`

export const ClassCardMenuButton = styled(MyClassesMenuButton).attrs(
  ({ dataTestId, isOpen }) => ({
    background: isOpen ? themeGet('colors.backgroundButtonLight') : 'none',
    'data-testid': dataTestId,
  }),
)`
  background: ${({ background }) => background}};

  svg {
    color: ${themeGet('colors.white')};
  }

  &:hover {
    background: ${themeGet('colors.backgroundButtonLight')};
    svg {
      color: ${themeGet('colors.white')};
    }
  }

  &:focus-visible {
    background: ${themeGet('colors.backgroundButtonLight')};
    outline: ${themeGet('buttons.buttonBorder')};

    svg {
      color: ${themeGet('colors.white')};
    }
  }
`

export const DropdownMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  padding: 0.25rem 0;
  margin-top: 0.1875rem;
  background-color: white;
  border-radius: 0.25rem;
  width: 100px;
  box-shadow: 0 0 4px 0 rgba(170, 170, 170, 0.5);

  @media screen and (max-width: 750px) {
    position: absolute;
    right: 0;
  }
`

export const EditButton = styled.button.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding-top: 0.375rem;
  padding-bottom: 0.1875rem;
  padding-left: 0.625rem;
  background: none;
  border: none;
  border-radius: 0;

  p {
    font-size: 0.875rem;
    margin: 0;
    color: ${themeGet('colors.themeAccent')};
  }

  svg {
    margin: 0 0.5rem 0.25rem 0;
    color: ${themeGet('colors.themeAccent')};
  }

  &:focus,
  &:hover {
    background: ${themeGet('colors.paleGray')};
    border: none;
    border-image-width: 0;
    cursor: pointer;

    p,
    svg {
      color: ${themeGet('colors.buttons.iconHover')};
    }
  }
`

export const DeleteButton = styled(EditButton)`
  &:focus,
  &:hover {
    p,
    svg {
      color: ${themeGet('colors.deleteRed')};
    }
  }
`
