import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { OutlinedButton } from 'secondstep-components'

export const StyledOutlineButton = styled(OutlinedButton)`
  display: flex;
  background-color: transparent;
  border: transparent;
  border-bottom: 1px solid transparent;
  border-radius: 0;
  color: ${themeGet('colors.white')};
  height: unset;
  margin-bottom: 0.1875rem;
  margin-right: 1.625rem;
  padding: 0;
  text-transform: none;

  &:not([disabled]):hover {
    background: none;
    border-color: none;
    border-radius: 0;
    border-bottom: 1px solid ${themeGet('colors.white')};
  }

  img {
    position: relative;
    top: 2px;
    padding-right: 0.375rem;
  }
`

export const SupportLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: start;
  align-items: center;
`

export const LinkImage = styled.img.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))``
