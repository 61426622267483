import React from 'react'
import PropTypes from 'prop-types'
import HighSchoolLessonStatusIcon from 'components/dashboard/HighSchoolLessonStatusIcon'
import { CollectionCard } from './component.styles'

function StaticCollectionCard({
  isComplete,
  // this flag is not always passed to the component:
  isSkipped = false,
  navigationEvent,
  path,
  title,
}) {
  return (
    <CollectionCard onClick={() => navigationEvent(path)}>
      <h4>
        <HighSchoolLessonStatusIcon
          isComplete={isComplete}
          isSkipped={isSkipped}
        />
        {title}
      </h4>
    </CollectionCard>
  )
}

StaticCollectionCard.propTypes = {
  isComplete: PropTypes.bool,
  isSkipped: PropTypes.bool,
  navigationEvent: PropTypes.func,
  path: PropTypes.string,
  title: PropTypes.string,
}

export default StaticCollectionCard
