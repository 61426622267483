import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { DIVIDER_CHARACTER } from './constants'
import { Row, Divider, TextContent } from './component.styles'

import NavigationHelper from 'layers/navigation/navigationHelper'

export default class FooterTextLink extends Component {
  static propTypes = {
    divider: PropTypes.bool,
    href: PropTypes.string,
    navigationEvent: PropTypes.func,
    text: PropTypes.string,
  }

  navigateLeaveApp = () => {
    const { navigationEvent, href } = this.props
    href &&
      navigationEvent &&
      navigationEvent(href, NavigationHelper.types.NEW_TAB)
  }

  render() {
    const { text, divider, href } = this.props
    const id = `footer-text-link-${text}`

    return (
      <Row data-testid={id}>
        <TextContent
          href={href}
          onClick={ev => {
            ev.preventDefault()
            this.navigateLeaveApp()
          }}
        >
          {text}
        </TextContent>
        {divider && <Divider>{DIVIDER_CHARACTER}</Divider>}
      </Row>
    )
  }
}
