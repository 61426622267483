import { put } from 'redux-saga/effects'
import autoBind from 'auto-bind'

import ContenfulTypes from 'layers/content/store/types'
import SagaLifeCycle from '../../../../saga/lifecycle'
import ContentDataEntryManager from '../../../../content/Hocs/dataManagers/contentDataEntryManager'
import ContentfulActions from 'layers/content/store/actions'

import {
  getChildContentNode,
  getContentNodesFromState,
  getEntryIdAndEntryFromAction,
} from '../../utils/selectors'
import {
  isDesiredContentUpdate,
  isLastRoute,
  isRouteInPath,
  verifyRouteIsOnEntry,
  verifyWeAreOnProperPath,
} from '../../utils/verify'
import { getEntryForLearnLayer } from '../../utils/api'
import ContentfulNodeFailureTypes from 'layers/learn/content/nodes/failureTypes'
const { CONTENTFUL_REQUEST, CONTENTFUL_SUCCESS } = ContenfulTypes

export default class NodeContentNextSaga extends SagaLifeCycle {
  constructor(registeredNamespace, blacklist = [], locale) {
    super()
    this.registeredNamespace = registeredNamespace
    this.blacklist = blacklist
    this.locale = locale
    autoBind(this)
  }

  *process(data) {
    const {
      CONTENTFUL_SUCCESS: contentSuccess,
      pathname,
      CONTENTFUL_REQUEST: contentOptimistic,
    } = data
    const entry = contentSuccess || contentOptimistic

    const { current: currentNode } =
      this.getState(getContentNodesFromState) || {}

    const {
      entryId: desiredEntryId,
      routeSlug,
      pathLevel,
      nextNodesField,
    } = currentNode

    this._shouldRunNextNodeProcess({
      entry,
      actionEntryId: entry.id,
      desiredEntryId,
      pathname,
      routeSlug,
      blacklist: this.blacklist,
    })

    const { childContentNode, isChildLastRoute } = yield this._buildNextNode({
      parentEntry: entry,
      pathname,
      pathLevel,
      nextNodesField,
    })

    yield this._createNextNode({ childContentNode, isChildLastRoute })
  }

  *_buildNextNode({ parentEntry, pathname, pathLevel, nextNodesField }) {
    if (!parentEntry || !pathname || !pathLevel || !nextNodesField) {
      this.earlyExit(ContentfulNodeFailureTypes.DID_NOT_CREATE_NEXT_NODE)
    }

    const childContentNode = getChildContentNode({
      path: pathname,
      currentPathLevel: pathLevel,
      parentEntry,
      parentField: nextNodesField,
    })

    if (!childContentNode) {
      const error = new Error(
        ContentfulNodeFailureTypes.CAN_NOT_FIND_NEXT_CHILD_NODE,
      )

      yield put(ContentfulActions.updateContentNodesErrorSystemMessage(error))
      throw this.earlyExit(
        ContentfulNodeFailureTypes.CAN_NOT_FIND_NEXT_CHILD_NODE,
      )
    }

    const { pathLevel: childPathLevel } = childContentNode || {}
    const isChildLastRoute = isLastRoute(pathname, childPathLevel)

    return { childContentNode, isChildLastRoute }
  }

  *_createNextNode({ childContentNode, isChildLastRoute }) {
    yield put(
      ContentfulActions.updateContentNodes(childContentNode, isChildLastRoute),
    )

    const { entryId: childEntryId, includesDepth: childIncludesDepth } =
      childContentNode || {}

    yield put(
      getEntryForLearnLayer({
        entryId: childEntryId,
        include: childIncludesDepth,
        locale: this.locale,
      }),
    )
  }

  _shouldRunNextNodeProcess({
    entry,
    actionEntryId,
    desiredEntryId,
    pathname,
    routeSlug,
    blacklist,
  }) {
    const isDesiredContent = isDesiredContentUpdate(
      entry,
      actionEntryId,
      desiredEntryId,
    )
    if (!isDesiredContent) {
      this.earlyExit(ContentfulNodeFailureTypes.DID_NOT_VERIFY_FOR_NEXT_NODE)
    }

    const isOnProperPath = verifyWeAreOnProperPath(pathname, blacklist)
    if (!isOnProperPath) {
      this.earlyExit(ContentfulNodeFailureTypes.DID_NOT_VERIFY_FOR_NEXT_NODE, {
        pathname,
      })
    }

    const isDesiredRouteInPath = isRouteInPath(pathname, routeSlug)
    const isRouteOnEntry = verifyRouteIsOnEntry(entry, routeSlug)

    if (!isRouteOnEntry || !isDesiredRouteInPath) {
      this.earlyExit(ContentfulNodeFailureTypes.DID_NOT_VERIFY_FOR_NEXT_NODE, {
        pathname,
      })
    }
  }

  subscribeEvents() {
    return [CONTENTFUL_SUCCESS, CONTENTFUL_REQUEST]
  }

  filter_CONTENTFUL_REQUEST(action) {
    const { last: lastNode, current: currentNode } = this.getState(
      getContentNodesFromState,
    )
    const { entryId: currentNodeEntryId } = currentNode || {}
    const { entryId } = getEntryIdAndEntryFromAction(action) || {}

    if (!currentNode || lastNode || entryId !== currentNodeEntryId) {
      this.earlyExit(ContentfulNodeFailureTypes.ITEM_SELECTION_FAILED, {
        lastNode,
        entryId,
        currentNodeEntryId,
      })
    }
    const { isFetching, payload: entry, error } = this.getState(state =>
      ContentDataEntryManager.getEntryData(state)(entryId),
    )

    if (error || isFetching || !entry || this.emptyObject(entry)) {
      this.earlyExit(ContentfulNodeFailureTypes.NOT_DESIRED_ENTRY_UPDATE)
    }
  }

  filter_CONTENTFUL_SUCCESS(action) {
    const { last: lastNode, current: currentNode } =
      this.getState(getContentNodesFromState) || {}
    const { entryId: currentNodeEntryId } = currentNode || {}
    const { entryId } = getEntryIdAndEntryFromAction(action) || {}

    if (!currentNode || lastNode || entryId !== currentNodeEntryId) {
      this.earlyExit(ContentfulNodeFailureTypes.ITEM_SELECTION_FAILED, {
        lastNode,
        entryId,
        currentNodeEntryId,
      })
    }
  }

  select_pathname(state) {
    const { router } = state || {}
    const { location } = router || {}
    const { pathname } = location || {}
    return pathname
  }

  select_CONTENTFUL_REQUEST(state) {
    return this._selectEntry(state)
  }

  _selectEntry(state) {
    const { current: currentNode } = getContentNodesFromState(state)
    const { entryId } = currentNode || {}
    const { payload } = ContentDataEntryManager.getEntryData(state)(
      entryId,
      this.locale,
    )
    if (!payload || this.emptyObject(payload)) {
      return false
    }

    return payload
  }

  select_CONTENTFUL_SUCCESS(state) {
    return this._selectEntry(state)
  }
}
