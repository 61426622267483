import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, BaseContainer } from 'secondstep-components'

export const ContainerSection = styled(BaseContainer)`
  background-color: ${themeGet('colors.lighterGray')};
  padding: ${themeGet('spacing.orange')};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;

  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;
    padding: ${themeGet('spacing.turquoise20_1_25rem')};
  }
`

export const BoldBodyCopy = styled(BodyCopy)`
  font-weight: bold;
  white-space: nowrap;
`

export const ViewWrapper = styled.div.attrs(({ dataTestId }) => {
  return {
    'data-testid': dataTestId,
  }
})`
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  padding-top: ${themeGet('spacing.amber28_1_75rem')};

  p {
    margin: 0;
    font-family: ${themeGet('fontFamily')};
  }

  ul {
    padding-left: ${themeGet('spacing.yellow12_0_75rem')};
  }
`

export const MarkDownWrapper = styled.div.attrs(({ dataTestId }) => {
  return {
    'data-testid': dataTestId,
  }
})`
  padding-left: ${themeGet('spacing.pink8_0_5rem')};
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

export const PDFTitleRow = styled.div.attrs(({ dataTestId }) => {
  return {
    'data-testid': dataTestId,
  }
})`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export const MainViewColumn = styled.div.attrs(({ dataTestId }) => {
  return {
    'data-testid': dataTestId,
  }
})`
  width: 55%;

  ${themeGet('breakpoints.mobileLarge')} {
    width: 100%;
  }
`

export const SecondaryViewColumn = styled.div.attrs(({ dataTestId }) => {
  return {
    'data-testid': dataTestId,
  }
})`
  width: 30%;

  ${themeGet('breakpoints.medium')} {
    margin-left: ${themeGet('spacing.blue16_1rem')};
    width: 50%;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    width: 100%;

    > div {
      padding: ${themeGet('spacing.amber28_1_75rem')}
        ${themeGet('spacing.turquoise20_1_25rem')};
    }
  }
`
