import React from 'react'
import PropTypes from 'prop-types'

import ExpiredLicensesNotification from 'components/ExpiredLicensesNotification'
import MspBullyingUnitNotification from 'components/MspBullyingUnitNotification'
import LicenseCardNotification from 'components/LicenseCardNotification'

const Notifications = ({
  isMSAdminUrl,
  navigationEvent,
  unassignedLicenses,
}) => {
  return (
    <>
      <ExpiredLicensesNotification />
      {unassignedLicenses?.length > 0 && (
        <LicenseCardNotification
          navigationEvent={navigationEvent}
          unassignedLicenses={unassignedLicenses}
        />
      )}
      {isMSAdminUrl && <MspBullyingUnitNotification />}
    </>
  )
}

Notifications.propTypes = {
  isMSAdminUrl: PropTypes.bool,
  navigationEvent: PropTypes.func,
  unassignedLicenses: PropTypes.array,
}

export default Notifications
