import ContentfulParser from 'utils/contentfulParser/contents'
import TextLinkWithFileIcon from 'components/TextLinkWithFileIcon'
import ContentPresentation from 'components/ContentPresentation'
import ContentText from 'components/ContentText'

export default {
  pdf: {
    Component: TextLinkWithFileIcon,
    propsParser: ContentfulParser.pdf,
  },
  presentation: {
    Component: ContentPresentation,
    propsParser: ContentfulParser.presentation,
  },
  text: {
    Component: ContentText,
    propsParser: ContentfulParser.text,
  },
}
