export const getResources = resources => {
  let hasMissingData = false
  let resourceData = []

  if (!resources || (resources && resources.length < 1)) hasMissingData = true
  if (!hasMissingData) {
    resourceData = resources.map(({ assets, displayTitle }) => {
      if (!assets || !displayTitle) hasMissingData = true

      return {
        assets,
        displayTitle,
      }
    })
  }

  return hasMissingData ? false : resourceData
}
