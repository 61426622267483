import React from 'react'
import { domains } from 'config/env'
import {
  IconProfile,
  IconRightArrow,
  Column,
  Row,
  Link,
} from './component.styles'
import { TEXT_MANAGE_ACCOUNT } from './constants'

const ACCOUNT_DETAILS_PATH = `${domains.APP_DOMAIN}/account-details`

const AccountDetailsLink = () => {
  return (
    <Column>
      <Row>
        <Link
          dataTestId="text-link-manage-account"
          href={ACCOUNT_DETAILS_PATH}
          role="link"
        >
          <span>
            <IconProfile aria-hidden="true" role="presentation" />
            {TEXT_MANAGE_ACCOUNT}
          </span>
          <IconRightArrow />
        </Link>
      </Row>
    </Column>
  )
}

export default AccountDetailsLink
