import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ImageThumbnail, ImageModal } from './component.styles'
import { CLICK, ENTER } from './data'
import WithZoom from 'components/WithZoom'
import WithImageModal from 'components/WithImageModal'

const ImageFullWidth = ({
  id,
  description,
  imageThumbnailUrl,
  imageUrl,
  title,
  isZoomable = false,
}) => {
  const [showModal, setShowModal] = useState(false)

  const dataTestIdPrefix = `image-full-width`
  const dataTestIdOpenButton = `${dataTestIdPrefix}-open-button-${id}`

  const openModal = event => {
    if (showModal || (event.type !== CLICK && event.key !== ENTER)) {
      return
    }

    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const modalProps = {
    title,
    openModal,
    onClose: closeModal,
    show: showModal,
    dataTestId: dataTestIdOpenButton,
  }

  if (!imageThumbnailUrl || !imageUrl) return null

  const ImageFunc = () => (
    <ImageModal a11yTitle={description} alt={description} src={imageUrl} />
  )
  const ImageComp = (
    <ImageModal a11yTitle={description} alt={description} src={imageUrl} />
  )

  const Thumbnail = (
    <ImageThumbnail
      a11yTitle="Callout Image"
      alt={description}
      src={imageThumbnailUrl}
      tabIndex="0"
    />
  )

  return WithImageModal(
    Thumbnail,
    (isZoomable && WithZoom()(ImageComp)) || ImageFunc,
  )(modalProps)
}

ImageFullWidth.propTypes = {
  description: PropTypes.string,
  id: PropTypes.string,
  imageThumbnailUrl: PropTypes.string,
  imageUrl: PropTypes.string,
  isZoomable: PropTypes.bool,
  title: PropTypes.string,
}

export default ImageFullWidth
