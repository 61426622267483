export const NAVIGATION_EVENT = 'NAVIGATION_EVENT'

export const PUSH = 'PUSH'
export const DOWNLOAD_LINK = 'DOWNLOAD_LINK'
export const NEW_TAB = 'NEW_TAB'
export const NEW_TAB_SAME_SITE = 'NEW_TAB_SAME_SITE'
export const OPEN = 'OPEN'
export const REPLACE = 'REPLACE'
export const GO = 'GO'
export const GOBACK = 'GOBACK'
export const GOFORWARD = 'GOFORWARD'
