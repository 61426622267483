import styled from 'styled-components'
import DefaultCheckmark from './assets/checkmark-default.svg'
import DoneCheckmark from './assets/checkmark-done.svg'

export const TraningCompletionIcon = styled.div`
  background-image: url(${props =>
    props.isComplete ? DoneCheckmark : DefaultCheckmark});
  width: 1.25rem;
  height: 1.25rem;
  background-size: cover;
  margin-top: 0.5rem;
  margin-right: 1.25rem;
  min-width: 1.25rem;
  margin-right: 0.938rem;
`
