import styledNative from 'styled-components/native'
import styled, { withTheme } from 'styled-components'
import { themeGet } from 'styled-system'
import { DefaultSiteSelectorWrapper } from 'components/SiteSelector'

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: ${themeGet('resources.sublandingBackgroundColor')};
  padding-bottom: 2rem;
  position: relative;
`

export const ContentContainer = withTheme(styledNative.View`
  padding: 0 1.25rem;
  width: 100%;
  max-width: 1366px;
`)

export const BannerSpacer = styled.div`
  margin-top: 7rem;
`
export const SiteSelectorWrapper = styled(DefaultSiteSelectorWrapper)`
  position: absolute;
  margin: auto;
  max-width: ${themeGet('breakpoints.sizes.large')};
  z-index: 100;
`
