import React from 'react'
import PropTypes from 'prop-types'

const WelcomeGuideArrowIcon = ({ stroke }) => (
  <svg height="23px" viewBox="0 0 23 23" width="23px">
    <g
      fill="none"
      fillRule="evenodd"
      id="ss_welcomeguide_arrow"
      stroke="none"
      strokeWidth="1"
    >
      <g>
        <circle cx="11.5" cy="11.5" fill="#EEF0F2" id="Oval" r="11.5" />
        <g
          id="Group-6"
          stroke={stroke}
          strokeLinecap="round"
          strokeWidth="1.61"
          transform="translate(6.1923, 7.0769)"
        >
          <line
            id="Path-2"
            x1="9.22893228"
            x2="0"
            y1="4.47428429"
            y2="4.41769969"
          />
          <polyline
            id="Path-3"
            points="3.85156419 6.76382043 8.27464111 2.08233342 12.697718 6.76382043"
            transform="translate(8.2746, 4.4231) rotate(-270) translate(-8.2746, -4.4231)"
          />
        </g>
      </g>
    </g>
  </svg>
)

WelcomeGuideArrowIcon.propTypes = {
  stroke: PropTypes.string,
}

export default WelcomeGuideArrowIcon
