import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import {
  Bold,
  Bullet,
  Delimiter,
  SchoolSetupRequiredText,
  StyledHeaderOne,
  SubHeaderSummary,
  SubHeaderText,
  Summary,
} from './component.styles'

const LeftBox = ({
  bottomHeader,
  delimiter = ` • `,
  header,
  subHeaderRows,
}) => {
  return (
    <>
      <StyledHeaderOne>{header}</StyledHeaderOne>
      <Summary>
        <SubHeaderSummary>
          {subHeaderRows.map((item, index) => (
            <SubHeaderText>
              <Fragment key={`multi-site-subheader-${index}`}>
                <Bold>{item.label}</Bold> {item.copy}
                {index < subHeaderRows.length - 1 && (
                  <Delimiter>{delimiter}</Delimiter>
                )}
              </Fragment>
            </SubHeaderText>
          ))}
        </SubHeaderSummary>
        {bottomHeader && (
          <SchoolSetupRequiredText>
            <Bullet /> {bottomHeader}
          </SchoolSetupRequiredText>
        )}
      </Summary>
    </>
  )
}

LeftBox.propTypes = {
  bottomHeader: PropTypes.string,
  delimiter: PropTypes.string,
  header: PropTypes.string,
  subHeaderRows: PropTypes.array,
}

export default LeftBox
