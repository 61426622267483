import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  BodyCopy,
  CollapsibleButton,
  ModalBodyCopy,
  ModalSubHeader,
} from 'secondstep-components'
import { ModalWrapper } from '../component.styles.js'

export const ModalWrapperErrorDetails = styled(ModalWrapper)`
  height: auto;
  padding: 2.5rem 3.75rem 0.75rem;
  overflow: scroll;
  ${themeGet('breakpoints.tablet')} {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
  ${themeGet('breakpoints.mobile')} {
    height: auto;
    padding: 2.75rem 1.5rem 2.5rem;
  }
`
export const ContentWrapper = styled.div`
  ${themeGet('breakpoints.tablet')} {
    padding: 0;
  }
`

export const SubHeader = styled(ModalSubHeader)`
  text-align: center;
  & {
    h4 {
      font-family: ${themeGet('fontFamilySemibold')};
      margin-bottom: 0.25rem;
      &:not(:last-of-type) {
        margin-bottom: 0;
      }
    }
  }
  ${themeGet('breakpoints.tablet')} {
    padding: 0 1.5rem;
  }
`

export const BodyCopyStyled = styled(BodyCopy)`
  margin-top: 0.75rem;
`

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1.75rem;

  button {
    &:not(:last-of-type) {
      margin-right: 1rem;
    }
  }
`

export const CollapsibleButtonStyled = styled(CollapsibleButton)`
  padding-bottom: 0.5rem;
`

export const ModalBodyCopyStyled = styled(ModalBodyCopy)`
  margin-bottom: 0;
  padding: 0.5rem 0;
  ${themeGet('breakpoints.tablet')} {
    padding: 0.5rem 0;
  }
`
