import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import img from './assets/module_details_banner.png'

export const AllModulesContainer = styled(Box)`
  width: 100%;
  background-color: ${themeGet('colors.themeAccent')};
  align-items: center;
`

export const HeaderBanner = styled(Box)`
  background-image: url(${img});
  height: 13.4375rem;
  background-size: cover;
  max-width: none;
  width: 100%;
  position: absolute;
`

export const ModuleDetailsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3.125rem ${themeGet('spacing.turquoise20_1_25rem')};
  z-index: 0;

  div[class*='AccordionWrapper'] {
    margin-top: ${themeGet('spacing.turquoise20_1_25rem')};
  }

  ${themeGet('breakpoints.mobile')} {
    margin-top: ${themeGet('spacing.blue16_1rem')};
  }
`
