import React from 'react'
import PropTypes from 'prop-types'
import {
  BodyWrapper,
  GridWrapper,
  StyledMarkdownParagraph,
  VideoWrapper,
  StyledTextLinkWithFileIcon,
  LinkWrapper,
  StyledLink,
} from './component.styles'
import { VIDEO_TEST_ID, PIPE_TEST_ID } from './constants'
import VideoCallout from 'experienceComponents/VideoCallout'

const renderAppropriateComponent = (link, displayIcon = false) => {
  const isPdfLink = link?.contentType?.id === 'pdf'
  const linkUrl = isPdfLink ? link?.file?.file?.url : link?.url
  const targetLink = isPdfLink ? '_blank' : '_self'

  if (isPdfLink && displayIcon) {
    return (
      <StyledTextLinkWithFileIcon
        fileType="pdf"
        href={linkUrl}
        key={linkUrl}
        text={link.displayTitle}
      />
    )
  } else {
    return (
      <StyledLink href={linkUrl} target={targetLink}>
        {link.displayTitle}
      </StyledLink>
    )
  }
}

const Understanding = ({ bodyCopy, videoId, link1, link2 }) => {
  return (
    <BodyWrapper>
      <GridWrapper>
        <div className="first-column">
          <StyledMarkdownParagraph>{bodyCopy}</StyledMarkdownParagraph>
        </div>
        <VideoWrapper>
          <VideoCallout
            dataTestId={VIDEO_TEST_ID}
            entryId={videoId}
            id="video-callout"
          />
        </VideoWrapper>
        <LinkWrapper>
          {link1 && renderAppropriateComponent(link1, true)}
          {!!link1 && !!link2 && (
            <span className={'pipe-between-links'} data-testid={PIPE_TEST_ID}>
              {' | '}
            </span>
          )}

          {link2 && renderAppropriateComponent(link2)}
        </LinkWrapper>
      </GridWrapper>
    </BodyWrapper>
  )
}

Understanding.propTypes = {
  bodyCopy: PropTypes.string.isRequired,
  link1: PropTypes.object,
  link2: PropTypes.object,
  videoId: PropTypes.string.isRequired,
}

export default Understanding
