import { TextLink } from 'secondstep-components'
import styled, { withTheme } from 'styled-components'
import { themeGet } from 'styled-system'

export const AnchoredContentLinkWrapper = withTheme(styled(TextLink)`
  border-bottom-style: ${themeGet('anchorNavigation.borderBottomStyle')};
  border-bottom-color: ${themeGet('anchorNavigation.borderBottomColor')};
  border-bottom-width: ${themeGet('anchorNavigation.borderBottomWidth')};
  padding-bottom: ${themeGet('anchorNavigation.bottomPadding', '0.688rem')};
  padding-top: ${themeGet('anchorNavigation.topPadding', '0rem')};

  line-height: 1.25rem;

  :first-of-type {
    padding-top: 0rem;
  }

  :last-child {
    border-bottom-style: none;
  }
`)
