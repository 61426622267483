import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Wrapper = styled('ul').attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin: 0;
  padding: 0;

  li {
    margin-bottom: 1.25rem;
  }

  ${themeGet('breakpoints.mobile')} {
    margin-bottom: 1.25rem;
  }
`
