import React from 'react'
import { func } from 'prop-types'
import { domains } from 'config/env'
import { Column, Row, IconGear, IconRightArrow, Link } from './component.styles'
import { TEXT_MANAGE_LICENSES } from './constants'

const LICENSE_MANAGEMENT_PATH = `${domains.ADMIN_DOMAIN}/license-management`

const ManageLicensesLink = ({ navigateOutOfAppOnClick }) => (
  <Column>
    <Row>
      <Link
        alt={TEXT_MANAGE_LICENSES}
        dataTestId="text-link-manage-licenses"
        key="text-link-manage-licenses"
        onClick={() => navigateOutOfAppOnClick(LICENSE_MANAGEMENT_PATH)}
      >
        <span>
          <IconGear />
          {TEXT_MANAGE_LICENSES}
        </span>

        <IconRightArrow />
      </Link>
    </Row>
  </Column>
)

ManageLicensesLink.propTypes = {
  navigateOutOfAppOnClick: func.isRequired,
}

export default ManageLicensesLink
