import { connect } from 'react-redux'
import { navigationEvent } from 'layers/navigation/store/operations'
import ContentPresentation from './component'
import { familyDomain } from './constants'

const mapStateToProps = ({ productManager }) => {
  const isFamilyResource = productManager?.productMap?.name === familyDomain

  return { isFamilyResource }
}

const mapDispatchToProps = {
  navigationEvent,
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentPresentation)
