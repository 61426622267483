import {
  TAB_FOUR_HEADER_TITLE,
  TAB_ONE_HEADER_TITLE,
  TAB_THREE_HEADER_TITLE,
  TAB_TWO_HEADER_TITLE,
} from './constants'

export default {
  1: TAB_ONE_HEADER_TITLE,
  2: TAB_TWO_HEADER_TITLE,
  3: TAB_THREE_HEADER_TITLE,
  4: TAB_FOUR_HEADER_TITLE,
}
