import * as yup from 'yup'

export const nodeNames = {
  node1: 'node1',
  node2: 'node2',
  node3: 'node3',
  node4: 'node4',
  node5: 'node5',
  node6: 'node6',
  node7: 'node7',
  node8: 'node8',
  node9: 'node9',
  node10: 'node10',
  node11: 'node11',
  node12: 'node12',
  nodeTraining: 'nodeTraining',
}

export const pacingNodesData = {
  highSchool: {
    node1: '1qn7Y1s7wgw1159p6wvr7e',
    node2: '55fJ7n3HGF4mIgKayEvfC7',
    node3: '3defdpTgDyBjcATjMzKIld',
    node4: 'CzQRYgvGwLbkAt2EJ3UZV',
    node5: '7944Hyt2zPsu2dW2DIWItM',
    node6: '675URDiS6lAuVAyWRjzC6h',
    node7: '0177b4RUNJw5q6e8VaIirP',
    node8: 'irTIW0uUaCtJbgGkXxJYS',
    node9: '1FXXLm7EV6svfb7J3sWaeR',
    node10: '5piI4wQ4bJels2cS24JRXx',
    node11: '1enBCq1RUZiup71e0cG1g0',
    node12: '2j6j9nxr2nzEH4K65VgmVA',
    nodeTraining: '5Po40tZBftn2ZlRYtmECWz',
  },
  // middle school nodes to be added later
  'middle-school': {},
}

export const validationSchema = yup.object().shape({
  trainingStartDate: yup
    .date()
    .nullable()
    .required('Training Start Date is required'),
  programStartDate: yup
    .date()
    .nullable()
    .required('Program Start Date is required')
    .test(
      'is-after-training-start',
      'Enter a date after training start',
      function(value) {
        const { trainingStartDate } = this.parent
        const valueDateString = value?.toISOString()?.slice(0, 10)
        const trainingStartDateString = trainingStartDate
          ?.toISOString()
          ?.slice(0, 10)
        return (
          !trainingStartDateString ||
          !valueDateString ||
          valueDateString > trainingStartDateString
        )
      },
    ),
  schoolYearEndDate: yup
    .date()
    .nullable()
    .required('School Year End Date is required')
    .test(
      'is-after-program-start',
      'Enter a date after program start',
      function(value) {
        const { programStartDate } = this.parent
        const valueDateString = value?.toISOString()?.slice(0, 10)
        const programStartDateString = programStartDate
          ?.toISOString()
          ?.slice(0, 10)
        return (
          !programStartDateString ||
          !valueDateString ||
          valueDateString > programStartDateString
        )
      },
    ),
  isEditingPacing: yup.boolean(),
  implementationLevel: yup.number(),
  node1: yup.date().when('isEditingPacing', {
    is: true,
    then: yup
      .date()
      .min(yup.ref('trainingStartDate'), 'Enter a date after training start')
      .required('Collection date is required'),
  }),
  node2: yup.date().when('isEditingPacing', {
    is: true,
    then: yup
      .date()
      .min(
        yup.ref(nodeNames.node1),
        'Enter a date after the previous collection date',
      )
      .required('Collection date is required'),
  }),
  node3: yup.date().when(['isEditingPacing', 'implementationLevel'], {
    is: (isEditingPacing, implementationLevel) =>
      isEditingPacing && implementationLevel === 2,
    then: yup
      .date()
      .min(
        yup.ref(nodeNames.node2),
        'Enter a date after the previous collection date',
      )
      .required('Collection date is required'),
  }),
  node4: yup.date().when('isEditingPacing', {
    is: true,
    then: yup
      .date()
      .test(
        'implementationLevel',
        'Enter a date after the previous collection date',
        function(value) {
          if (this.parent.implementationLevel === 1) {
            return value > this.parent.node2
          } else {
            return value > this.parent.node3
          }
        },
      ),
  }),
  node5: yup.date().when('isEditingPacing', {
    is: true,
    then: yup
      .date()
      .min(
        yup.ref(nodeNames.node4),
        'Enter a date after the previous collection date',
      )
      .required('Collection date is required'),
  }),
  node6: yup.date().when(['isEditingPacing', 'implementationLevel'], {
    is: (isEditingPacing, implementationLevel) =>
      isEditingPacing && implementationLevel === 2,
    then: yup
      .date()
      .min(
        yup.ref(nodeNames.node5),
        'Enter a date after the previous collection date',
      )
      .required('Collection date is required'),
  }),
  node7: yup.date().when('isEditingPacing', {
    is: true,
    then: yup
      .date()
      .test(
        'implementationLevel',
        'Enter a date after the previous collection date',
        function(value) {
          if (this.parent.implementationLevel === 1) {
            return value > this.parent.node5
          } else {
            return value > this.parent.node6
          }
        },
      ),
  }),
  node8: yup.date().when('isEditingPacing', {
    is: true,
    then: yup
      .date()
      .min(
        yup.ref(nodeNames.node7),
        'Enter a date after the previous collection date',
      )
      .required('Collection date is required'),
  }),
  node9: yup.date().when(['isEditingPacing', 'implementationLevel'], {
    is: (isEditingPacing, implementationLevel) =>
      isEditingPacing && implementationLevel === 2,
    then: yup
      .date()
      .min(
        yup.ref(nodeNames.node8),
        'Enter a date after the previous collection date',
      )
      .required('Collection date is required'),
  }),
  node10: yup.date().when('isEditingPacing', {
    is: true,
    then: yup
      .date()
      .test(
        'implementationLevel',
        'Enter a date after the previous collection date',
        function(value) {
          if (this.parent.implementationLevel === 1) {
            return value > this.parent.node8
          } else {
            return value > this.parent.node9
          }
        },
      ),
  }),
  node11: yup.date().when('isEditingPacing', {
    is: true,
    then: yup
      .date()
      .min(
        yup.ref(nodeNames.node10),
        'Enter a date after the previous collection date',
      )
      .required('Collection date is required'),
  }),
  node12: yup.date().when(['isEditingPacing', 'implementationLevel'], {
    is: (isEditingPacing, implementationLevel) =>
      isEditingPacing && implementationLevel === 2,
    then: yup
      .date()
      .min(
        yup.ref(nodeNames.node11),
        'Enter a date after the previous collection date',
      )
      .required('Collection date is required'),
  }),
})

export const trainingStartName = 'trainingStartDate'
export const programStartName = 'programStartDate'
export const schoolYearEndName = 'schoolYearEndDate'
