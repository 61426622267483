import { pluralizeWord } from 'utils/stringHelpers'

export const PENDING_COPY = count =>
  `${pluralizeWord(count, 'User', 'Users')} Pending`
export const SITE_HEADER = 'Launch Snapshot'
export const SCHOOLS_COPY = count => pluralizeWord(count, 'School', 'Schools')
export const USERS_COPY = count =>
  `${pluralizeWord(count, 'User', 'Users')} Added`
export const SETUP_COPY = count =>
  `${count} ${pluralizeWord(count, 'School', 'Schools')} ${pluralizeWord(
    count,
    'Requires',
    'Require',
  )} Setup`
