import * as yup from 'yup'

export const specialCharactersMessaging = `Names can only include letters, numbers, and the following: '"-_/()#&*:,.`
export const BASICINPUTSCHEMA = yup
  .string()
  .trim()
  .matches(/^[\w\-\sáéíñóúüýÁÉÍÑÓÚÜÝ'“\-–/()#&*:,."”]+$/, {
    message: specialCharactersMessaging,
    excludeEmptyString: true,
  })
  .required('This field is required')
export const CREATECOURSESCHEMA = yup.object().shape({
  courseInstanceName: BASICINPUTSCHEMA,
  selectedCourse: yup.string().required(),
  selectedLicenseId: yup.string().required(),
})
export const DATE = yup.date()
