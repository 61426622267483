import SortableAccordionList from 'components/SortableAccordionList'
import styled from 'styled-components'

export const StyledSortableAccordionList = styled(SortableAccordionList)`
  [class*='ListHeaderWrapper-sc'] {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 0.9375rem;
  }
  [class*='PanelWrapper-sc'] {
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    // align-items: center;
  }
`
