import React from 'react'
import PropTypes from 'prop-types'
import { BodyCopy } from 'secondstep-components'
import {
  CompletionInfoWrapper,
  CompletionPercentage,
  CompletedStatusIndicator,
  InProgressStatusIndicator,
  NotStartedStatusIndicator,
  TrackLabel,
  UnitHeader,
} from './component.styles'
import { COMPLETED_TEXT, IN_PROGRESS_TEXT, NOT_STARTED_TEXT } from './constants'

const ReportsUnitPanel = ({
  displayTitle,
  trackLabel,
  usersCompleted,
  usersInProgress,
  usersNotStarted,
}) => {
  return (
    <>
      <UnitHeader dataTestId="unit-header">{displayTitle}</UnitHeader>
      <CompletionInfoWrapper>
        <TrackLabel data-testid="track-label">{`**${trackLabel}**`}</TrackLabel>
        <CompletionPercentage>
          <CompletedStatusIndicator />
          <BodyCopy>{`**${usersCompleted}%** ${COMPLETED_TEXT}`}</BodyCopy>
        </CompletionPercentage>
        <CompletionPercentage>
          <InProgressStatusIndicator />
          <BodyCopy>{`**${usersInProgress}%** ${IN_PROGRESS_TEXT}`}</BodyCopy>
        </CompletionPercentage>
        <CompletionPercentage>
          <NotStartedStatusIndicator />
          <BodyCopy>{`**${usersNotStarted}%** ${NOT_STARTED_TEXT}`}</BodyCopy>
        </CompletionPercentage>
      </CompletionInfoWrapper>
    </>
  )
}
ReportsUnitPanel.propTypes = {
  displayTitle: PropTypes.string,
  trackLabel: PropTypes.string,
  usersCompleted: PropTypes.number,
  usersInProgress: PropTypes.number,
  usersNotStarted: PropTypes.number,
}

export default ReportsUnitPanel
