import { createPortal } from 'react-dom'
import { LOADING_PORTAL_ID } from './constants'

function ReactPortal({ children }) {
  let loader = document.getElementById(LOADING_PORTAL_ID)
  if (!loader) {
    loader = document.createElement('div')
    loader.setAttribute('id', LOADING_PORTAL_ID)
    document.body.appendChild(loader)
  }

  return createPortal(children, loader)
}
export default ReactPortal
