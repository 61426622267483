import styled from 'styled-components'
import { Hairline } from 'secondstep-components'
import { themeGet } from 'styled-system'

export const BodyWrapper = styled.div.attrs(({ noClasses }) => ({
  padding: noClasses ? '0' : '0.875rem 1.25rem 1.75rem',
  marginTop: noClasses ? '0.875rem' : '0,',
}))`
  margin-top: ${({ marginTop }) => marginTop};
  padding: ${({ padding }) => padding};
  border: 2px solid ${themeGet('colors.paleGray')};
  border-radius: 10px;

  ${themeGet('breakpoints.belowTablet')} {
    padding-bottom: 0.25rem;
  }
`

export const SingleSiteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.5625rem 1.25rem 1.25rem;
  margin-bottom: 1.75rem;
  border-radius: 10px;
  background: ${themeGet('colors.white')};

  ${themeGet('breakpoints.mobileLarge')} {
    margin-bottom: 1.25rem;
  }
`

export const StyledHairline = styled(Hairline)`
  height: 2px;
  margin: 0.9375rem 0;
  background-color: ${themeGet('colors.paleGray')};
`

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 550px;

  svg {
    color: ${themeGet('colors.darkBlue')};
  }

  p {
    margin-top: 0.25rem;
    margin-left: 0.5rem;
    font-size: 1.125rem;
    font-family: ${themeGet('font.molde.medium')};
    color: ${themeGet('colors.darkGray')};
  }
`
