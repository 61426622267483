/* istanbul ignore file */
import React, { useEffect } from 'react'

import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
} from 'layers/errorHandling/apiError'
import NavigationHelper from 'layers/navigation/navigationHelper'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { operations as loadingOperations } from 'store/loadingManager'
import { ENTRY_ID } from './constants'

import Webinars from './component.jsx'

import { userSegmentationBuilder } from 'utils/userSegmentationHelpers'
import {
  AI_HS_PAGE_USAGE_KEY,
  sendApplicationInsightsEvent,
} from 'utils/sendAnalyticsDataHelpers'

const WebinarsContainer = ({
  entry,
  isLoadingApp,
  navigationEvent,
  setAppIsLoadingState,
  setAppNotLoadingState,
  userSegmentationInfo,
}) => {
  useEffect(() => {
    if (!entry) {
      setAppIsLoadingState('Loading content…')
    } else {
      setAppNotLoadingState()
    }
  }, [entry, isLoadingApp])

  useEffect(() => {
    if (entry) {
      entry.content = entry.content.filter(c => !c?.redacted)
    }
  }, [entry])

  useEffect(() => {
    sendApplicationInsightsEvent(AI_HS_PAGE_USAGE_KEY, userSegmentationInfo)
  }, [userSegmentationInfo])

  const backToDashboard = () => {
    navigationEvent('/')
  }

  return <Webinars backToDashboard={backToDashboard} entry={entry} />
}

WebinarsContainer.propTypes = {
  entry: PropTypes.object,
  isLoadingApp: PropTypes.bool,
  navigationEvent: PropTypes.func,
  setAppIsLoadingState: PropTypes.func,
  setAppNotLoadingState: PropTypes.func,
  userSegmentationInfo: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.string),
    archetypes: PropTypes.arrayOf(PropTypes.string),
    claims: PropTypes.arrayOf(PropTypes.string),
  }),
}

const mapStateToProps = state => {
  const { loadingManager } = state
  const { isLoading: isLoadingApp } = loadingManager

  return {
    entryId: ENTRY_ID,
    isLoadingApp,
    userSegmentationInfo: userSegmentationBuilder(state),
  }
}

const mapDispatchToProps = {
  ...NavigationHelper.mapDispatchToProps,
  setAppIsLoadingState: loadingOperations.setIsLoadingState,
  setAppNotLoadingState: loadingOperations.setNotLoadingState,
}

export default ContentEntryDataGetter(
  withApiError(WebinarsContainer, [ERROR_TYPE_CONTENTFUL]),
  {},
  connect(mapStateToProps, mapDispatchToProps),
)
