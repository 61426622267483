import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toUrlSafe } from 'utils/stringHelpers'
import { getProductFromProductMap } from 'utils/productHelpers'
import removeRedactedContentfulArray from 'utils/removeRedactedContentfulArray'
import DashboardLeftBox from './component'

export default class DashboardLeftBoxContainer extends Component {
  static propTypes = {
    activeTabProgramGroup: PropTypes.object,
    classes: PropTypes.array,
    contentfulProgramsAvailable: PropTypes.array,
    hasUnassignedLicenses: PropTypes.bool,
    lmsFTPath: PropTypes.array,
    lmsSelaPath: PropTypes.array,
    lmsTrainings: PropTypes.array,
    navigationEvent: PropTypes.func,
    pageTitle: PropTypes.string,
    programGroupAvailable: PropTypes.array,
    programGroupExists: PropTypes.bool,
    seedIds: PropTypes.object,
    tabName: PropTypes.string,
    unassignedLicenses: PropTypes.array,
    userProfile: PropTypes.object,
  }

  findProgramContentfulData = (programNames, contentfulPrograms) => {
    const programContentfulData = contentfulPrograms
      .filter(program => programNames.includes(program?.internalTitle))
      .flatMap(program => program?.productSections[0]?.productSubsections)

    return programContentfulData
  }

  onComponentClick = urlFragment => {
    const { navigationEvent } = this.props
    navigationEvent(`${urlFragment}`)
  }

  onTabSelect = tabName => {
    const { navigationEvent } = this.props
    navigationEvent(`/dashboard/${toUrlSafe(tabName)}`)
  }

  render() {
    const {
      activeTabProgramGroup,
      contentfulProgramsAvailable,
      classes,
      hasUnassignedLicenses,
      lmsSelaPath,
      lmsFTPath,
      lmsTrainings,
      navigationEvent,
      pageTitle,
      programGroupAvailable,
      seedIds,
      tabName,
      unassignedLicenses,
      userProfile,
    } = this.props

    const contentfulModules = this.findProgramContentfulData(
      ['SELA', 'sela field test program'],
      contentfulProgramsAvailable,
    )
    const contentfulCourses = this.findProgramContentfulData(
      ['Elementary', 'Middle School'],
      contentfulProgramsAvailable,
    )
    const currentProgram = getProductFromProductMap(tabName)
    const currentProgramName = currentProgram?.productName

    const activeTabPrograms = activeTabProgramGroup.programTiles
    const activeTabProgramsAvailable = removeRedactedContentfulArray(
      activeTabPrograms,
    )

    const programGroupExists = programGroupAvailable.length > 0
    const firstTime = !programGroupExists

    const { firstName } = userProfile || ''

    return (
      <DashboardLeftBox
        activeTabProgramGroup={activeTabProgramGroup}
        activeTabProgramsAvailable={activeTabProgramsAvailable}
        classes={classes}
        contentfulCourses={contentfulCourses}
        contentfulModules={contentfulModules}
        currentProgramName={currentProgramName}
        firstName={firstName}
        firstTime={firstTime}
        hasUnassignedLicenses={hasUnassignedLicenses}
        lmsFTPath={lmsFTPath}
        lmsSelaPath={lmsSelaPath}
        lmsTrainings={lmsTrainings}
        navigationEvent={navigationEvent}
        onComponentClick={this.onComponentClick}
        onTabSelect={this.onTabSelect}
        pageTitle={pageTitle}
        programGroupAvailable={programGroupAvailable}
        seedIds={seedIds}
        tabName={tabName}
        unassignedLicenses={unassignedLicenses}
      />
    )
  }
}
