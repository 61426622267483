import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { TOOLTIP_PROGRAMS } from 'utils/tooltipHelpers'
import { TOOLTIP_MAP } from './constants'
import {
  DetailsLessonContainer,
  LessonsCompletedTitle,
  LessonCount,
  ProgressSummaryContainer,
  StyledAdminTooltip,
  StyledProgressBarIndicator,
  TextCountLesson,
  TextLessons,
} from './component.styles'
import { toKebabCase } from 'utils/stringHelpers'

const AdminLessonProgressSummary = ({
  completedLessons,
  completionPercentage = 0,
  selectedTab,
  siteId,
  title,
  totalLessons,
}) => {
  const isMounted = useRef(true)

  useEffect(() => {
    return () => {
      isMounted.current = false
    }
  }, [])

  const keyText = toKebabCase(`${title.toLowerCase()} ${siteId}`)
  const percent =
    completionPercentage > 0
      ? completionPercentage < 0.01
        ? Math.ceil(completionPercentage * 100)
        : Math.round(completionPercentage * 100)
      : completionPercentage
  const shouldRenderIndicator = totalLessons > 0

  return (
    <ProgressSummaryContainer>
      <DetailsLessonContainer>
        <LessonsCompletedTitle>
          <TextLessons>
            {title}
            <StyledAdminTooltip
              location={TOOLTIP_MAP[selectedTab]}
              programKey={TOOLTIP_PROGRAMS.K8_MONITOR}
            />
          </TextLessons>
        </LessonsCompletedTitle>
        <LessonCount>
          <TextCountLesson>
            {completedLessons} of {totalLessons} Lessons
          </TextCountLesson>
        </LessonCount>
      </DetailsLessonContainer>
      <StyledProgressBarIndicator
        isParentMounted={isMounted}
        isValueInsideBar
        keyText={keyText}
        percentComplete={percent}
        shouldRenderIndicator={shouldRenderIndicator}
      />
    </ProgressSummaryContainer>
  )
}

AdminLessonProgressSummary.propTypes = {
  completedLessons: PropTypes.number,
  completionPercentage: PropTypes.number,
  selectedTab: PropTypes.string,
  siteId: PropTypes.number,
  title: PropTypes.string,
  totalLessons: PropTypes.number,
}

export default AdminLessonProgressSummary
