import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import {
  Header2,
  Paragraph,
  Sprite,
  StyledContainer,
  StyledList,
  StyledListItem,
  StyledTextLinkWithFileIcon,
} from './component.styles'

const MindYetiResources = ({ description, docs, title, yetiSprite }) => {
  const introDocs = docs?.map(d => (
    <StyledListItem key={d.displayTitle}>
      <StyledTextLinkWithFileIcon
        fileType="pdf"
        href={d.url}
        key={d.url}
        text={d.displayTitle}
      />
    </StyledListItem>
  ))

  return (
    <StyledContainer id="mind-yeti-resources-for-teachers">
      <Sprite alt="mind yeti sprite" src={yetiSprite} />
      <Header2>{title}</Header2>
      <ReactMarkdown
        renderers={{
          paragraph: Paragraph,
        }}
      >
        {description}
      </ReactMarkdown>
      <StyledList>{introDocs}</StyledList>
    </StyledContainer>
  )
}

MindYetiResources.propTypes = {
  description: PropTypes.string,
  docs: PropTypes.array,
  title: PropTypes.string,
  yetiSprite: PropTypes.string,
}

export default MindYetiResources
