/* istanbul ignore file */
// we don't unit test SVGs
import React from 'react'
import PropTypes from 'prop-types'

const SongIcon = ({ height = 29, width = 26, role, ariaHidden }) => (
  <svg
    aria-hidden={ariaHidden}
    height={height}
    role={role}
    viewBox="0 0 26 29"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.90483852,6.7860587 L6.90483852,20.5850387 C6.04664352,20.1182087 5.01879852,19.9234037 3.93771852,20.1182087 C2.16399852,20.4370337 0.723728521,21.8521487 0.379748521,23.6211887 C-0.176586479,26.4871037 2.00312352,28.9955837 4.76900352,28.9955837 C7.23945852,28.9955837 9.24249852,26.9937137 9.24483852,24.5232587 L9.24483852,10.7026337 L22.8332185,6.5514737 L22.8332185,15.2492537 C22.0809085,14.8403387 21.1998985,14.6402687 20.2656535,14.7274337 C18.1970935,14.9210687 16.4894785,16.5883187 16.2519685,18.6516137 C15.8535835,22.1042837 19.3881535,24.8877137 22.9554835,23.1034637 C24.4080385,22.3763087 25.2451735,20.8067537 25.1732185,19.1833787 L25.1726335,19.1740187 L25.1732185,19.1740187 L25.1732185,2.6343137 C25.1732185,1.0618337 23.6533885,-0.0631213031 22.1493535,0.396103697 L8.56097352,4.5478487 C7.57641852,4.8485387 6.90483852,5.7564587 6.90483852,6.7860587"
      id="song-icon"
    />
  </svg>
)

SongIcon.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
}

export default SongIcon
