import { Accordion } from 'secondstep-components'
import styled from 'styled-components'

const gutterWidths = {
  desktop: '1.5rem',
  mobile: '1.5rem',
  tablet: '1.5rem',
}

const h2Font = ({ theme }) => theme?.fonts?.kometBold20

export const StyledAccordion = styled(Accordion).attrs(
  ({ dataTestId, theme }) => ({
    dataTestId: dataTestId,
    h3MarginLeft: '-' + gutterWidths.desktop,
    h3MarginLeftMobile: '-' + gutterWidths.mobile,
    h3PaddingLeft: gutterWidths.desktop,
    h3PaddingLeftMobile: gutterWidths.mobile,
    iconColor: theme?.colors?.accordion?.icon,
    iconHoverColor: theme?.colors?.accordion?.iconHoverColor,
    padding: gutterWidths.desktop,
    panelColor: theme?.colors?.accordion?.panelColor,
  }),
)`
  margin: 1.75rem 0;

  ${({ theme }) => theme?.breakpoints?.mobile} {
    margin: 1.25rem 0;
  }

  [class*='AccordionPanel'] {
    padding: 0.375rem;
  }

  h2 {
    font-weight: ${h2Font.weight};
    font-family: ${h2Font.fontFamily};
    font-size: ${h2Font.fontSize};
    line-height: ${h2Font.lineHeight};
    margin-left: ${({ h3MarginLeft }) => h3MarginLeft};
    padding-left: ${({ h3PaddingLeft }) => h3PaddingLeft};
    background: ${({ theme }) =>
      theme?.colors?.heading?.level[3]?.backgroundColor};
    color: ${({ theme }) => theme?.colors?.accordion?.headerTextColor};

    ${({ theme }) => theme?.breakpoints?.mobile} {
      margin-left: ${({ h3MarginLeftMobile }) => h3MarginLeftMobile};
      padding-left: ${({ h3PaddingLeftMobile }) => h3PaddingLeftMobile};
    }
  }
`
