import React from 'react'
import PropTypes from 'prop-types'
import {
  AdvisoryActivityIcons,
  CaretLink,
  HeaderSix,
} from 'secondstep-components'
import { AdvisoryActivityBox, LinkContainer } from './component.styles'
import { toCamelCase, toPascalCase } from '../../utils/stringHelpers'
import { createHref } from './core'

const AdvisoryActivityLink = ({ advisoryType, productName }) => {
  const typeName = toCamelCase(advisoryType[0].advisoryActivityType)
  const Icon = AdvisoryActivityIcons[toPascalCase(typeName)]
  const testId = `advisory-activity-${advisoryType[0].advisoryActivityType}`.toLowerCase()
  return (
    <AdvisoryActivityBox dataTestId={testId} type={typeName}>
      <HeaderSix dataTestId="header-advisory-activity">
        {advisoryType[0].advisoryActivityType}s
      </HeaderSix>
      <LinkContainer>
        {advisoryType.map(activity => {
          const activityHref = createHref(productName, activity)
          return (
            activityHref && (
              <CaretLink href={activityHref} key={activity.id}>
                {activity.displayTitle}
              </CaretLink>
            )
          )
        })}
      </LinkContainer>
      <Icon />
    </AdvisoryActivityBox>
  )
}

AdvisoryActivityLink.propTypes = {
  advisoryType: PropTypes.array.isRequired,
  productName: PropTypes.string.isRequired,
}

export default AdvisoryActivityLink
