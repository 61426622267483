import { domains } from 'config/env'

import SSLogo from './assets/SSLogo.svg'

import {
  ABOUT_US,
  ACCESSIBILITY,
  LICENSE_AGREEMENTS,
  PRIVACY_POLICY,
  TERMS_OF_USE,
  SS_LOGO_LABEL,
} from './constants'

export const EXEMPT_ROUTES = ['dashboard']

const ACCESSIBILITY_STATEMENT = {
  href: `/accessibility`,
  text: ACCESSIBILITY,
}

export const DASHBOARD_LINKS = [
  {
    href: `${domains.PUBLIC_SITE_DOMAIN}/about-us`,
    text: ABOUT_US,
  },
  {
    href: `${domains.PUBLIC_SITE_DOMAIN}/terms-of-use`,
    text: TERMS_OF_USE,
  },
  {
    href: `${domains.PUBLIC_SITE_DOMAIN}/license-agreements`,
    text: LICENSE_AGREEMENTS,
  },
  ACCESSIBILITY_STATEMENT,
]

const [ABOUTUS, TERMS, LICENSE] = DASHBOARD_LINKS

export const LINKS = [
  ABOUTUS,
  {
    href: `${domains.PUBLIC_SITE_DOMAIN}/privacy-policy`,
    text: PRIVACY_POLICY,
  },
  TERMS,
  LICENSE,
  ACCESSIBILITY_STATEMENT,
]

export const LOGOS = [
  {
    alt: 'Second Step Homepage',
    href: 'https://www.secondstep.org/',
    label: SS_LOGO_LABEL,
    source: SSLogo,
  },
]
