import React from 'react'
import PropTypes from 'prop-types'
import { HeaderTwo } from 'secondstep-components'
import ModuleList from '../ModuleList'
import { Wrapper } from './component.styles'

// TODO: Possibly connect this component to get LMS data about each module status
const ModuleCategories = ({ categories, dataTestId }) => (
  <Wrapper dataTestId={dataTestId}>
    {categories.map((category, index) => {
      const { displayTitle, lmsAndContentfulModules } = category
      return (
        <div key={`${displayTitle}-list-${index}`}>
          <HeaderTwo dataTestId={`${displayTitle}-header`}>
            {displayTitle}
          </HeaderTwo>
          <ModuleList
            dataTestId={`${displayTitle}-list`}
            lmsAndContentfulModules={lmsAndContentfulModules}
          />
        </div>
      )
    })}
  </Wrapper>
)

ModuleCategories.propTypes = {
  categories: PropTypes.array.isRequired,
  dataTestId: PropTypes.string.isRequired,
}

export default ModuleCategories
