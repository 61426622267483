import { deepMerge } from 'grommet/utils'
import variables from './variables'
import platform from './platform'

const familyResources = {
  meta: { themeName: 'familyresources' },
  colors: {
    backgrounds: {
      body: variables.colors.beaconGray,
    },
    backgroundLightGray: variables.colors.gray550,
  },
}

// Family Resources inherits most of its styling from the Platform theme:
export default deepMerge(platform, familyResources)
