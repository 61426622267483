import React from 'react'
import PropTypes from 'prop-types'
import { formatUnitKey } from 'utils/adminExperienceHelpers'
import { formatHoursSinceTimestamp } from 'utils/reportsHelpers'
import AdminLessonProgressSummary from 'components/AdminLessonProgressSummary'
import { ALL_LESSONS_KEY } from './constants'
import {
  CardContainer,
  CardHeading,
  HeaderWrapper,
  LastUpdatedMessage,
} from './component.styles'

const AdminLessonProgressCard = ({
  bhuStatus,
  lastUpdatedAt,
  selectedTab,
  siteId,
  units,
  summary,
}) => {
  let completedLessons = 0
  let totalLessons = 0
  let summaryTitle = ''
  const lastUpdatedMessage = formatHoursSinceTimestamp(lastUpdatedAt)

  if (selectedTab === ALL_LESSONS_KEY) {
    completedLessons = summary.completedLessons
    totalLessons = bhuStatus ? summary.core.totalLessons : summary.totalLessons
    summaryTitle = `Lesson${
      completedLessons === 1 ? '' : 's'
    } Completed Schoolwide${bhuStatus ? ' in Units 1-4' : ''}`
  } else {
    const selectedUnit = units.find(
      (_unit, i) => selectedTab === formatUnitKey(i),
    )
    completedLessons = selectedUnit.completedLessons
    totalLessons = selectedUnit.totalLessons
    summaryTitle =
      `Lesson${completedLessons === 1 ? '' : 's'} Completed Schoolwide in ` +
      selectedTab
  }

  return (
    <CardContainer>
      <HeaderWrapper>
        <CardHeading>{completedLessons}</CardHeading>
        <LastUpdatedMessage>{lastUpdatedMessage}</LastUpdatedMessage>
      </HeaderWrapper>
      <AdminLessonProgressSummary
        completedLessons={completedLessons}
        completionPercentage={summary?.completionPercentage}
        selectedTab={selectedTab}
        siteId={siteId}
        title={summaryTitle}
        totalLessons={totalLessons}
      />
    </CardContainer>
  )
}

AdminLessonProgressCard.propTypes = {
  bhuStatus: PropTypes.bool,
  lastUpdatedAt: PropTypes.string,
  selectedTab: PropTypes.string,
  siteId: PropTypes.number,
  summary: PropTypes.object,
  units: PropTypes.array,
}

export default AdminLessonProgressCard
