import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { toKebabCase, toUrlSafe } from 'utils/stringHelpers'
import {
  ChecklistItemWrapper,
  StyledCheckBox,
  StyledLabel,
  StyledTextLinkWithModal,
} from './component.styles'

const ChecklistItem = ({
  internalKey,
  isChecked,
  links,
  onChange,
  selectedProgram,
  selectedTabSectionTitle = 'Selected Section',
  text,
}) => {
  return (
    <ChecklistItemWrapper>
      <StyledCheckBox
        dataTestId={toKebabCase(
          `${selectedTabSectionTitle}-${internalKey}-checkbox`,
        )}
        hideText={true}
        isChecked={isChecked}
        onChange={onChange}
        selectedProgram={selectedProgram.name}
        text={text}
      />
      <StyledLabel
        htmlFor={`input-checkbox-${toUrlSafe(text)}`}
        isChecked={isChecked}
      >
        <span className="text">{text}</span>
        <span className="links">
          {links?.length > 1 && <span>{'('}</span>}
          {links?.map((link, index) => {
            const { contentType } = link
            const { id } = contentType

            if (id === 'video') {
              const sectionTitle = toKebabCase(selectedTabSectionTitle)
              return (
                <Fragment key={link.displayTitle}>
                  <StyledTextLinkWithModal
                    contentType={link.contentType.id}
                    key={link.staticText}
                    prefixText={sectionTitle}
                    searchKey="video"
                    staticText={link.displayTitle}
                    video={link}
                  />
                </Fragment>
              )
            } else {
              const linkUrl = link?.file?.file?.url || link.url
              const formattedLinkUrl = linkUrl.startsWith('//')
                ? `https:${linkUrl}`
                : linkUrl
              const isDownload =
                ['powerpoint', 'wordDoc'].includes(id) ||
                formattedLinkUrl.includes('docs.google.com')
              const sectionTitle = toKebabCase(selectedTabSectionTitle)
              return (
                <Fragment key={link.displayTitle}>
                  <StyledTextLinkWithModal
                    contentType={link.contentType.type}
                    fileUrl={link.file?.file?.url}
                    isDownload={isDownload}
                    key={link.staticText}
                    prefixText={sectionTitle}
                    staticText={link.displayTitle}
                    url={formattedLinkUrl}
                  />
                  {links?.length > 1 && index === 0 && (
                    <span className="pipe">{'|'}</span>
                  )}
                </Fragment>
              )
            }
          })}
          {links?.length > 1 && <span>{')'}</span>}
        </span>
      </StyledLabel>
    </ChecklistItemWrapper>
  )
}

ChecklistItem.propTypes = {
  internalKey: PropTypes.string,
  isChecked: PropTypes.bool,
  links: PropTypes.array,
  onChange: PropTypes.func,
  selectedProgram: PropTypes.object,
  selectedTabSectionTitle: PropTypes.string,
  text: PropTypes.string,
}

export default ChecklistItem
