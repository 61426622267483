import React from 'react'
import PropTypes from 'prop-types'
import {
  BannerContentWrapper,
  BannerWrapper,
  BannerText,
  BannerHeader,
} from './component.styles'

export const ContentLandingBanner = props => {
  const {
    dataTestId = 'default-id',
    isDistrictSupportsPage,
    isFamilyResourcesPage,
    isSelaLeaderPage,
    isSelaProduct,
    text,
    title,
  } = props

  const dataTestIdPrefix = `content-landing-banner`
  const dataTestIdWrapper = `${dataTestIdPrefix}-wrapper-${dataTestId}`
  const dataTestIdHeader = `${dataTestIdPrefix}-header-${dataTestId}`

  const getBannerType = () => {
    if (isDistrictSupportsPage) return 'districtSupports'
    if (isFamilyResourcesPage) return 'familyResources'
    if (isSelaLeaderPage) return 'selaLeader'
    if (isSelaProduct) return 'selaProduct'

    return 'general'
  }

  return (
    <BannerWrapper bannerType={getBannerType()} data-testid={dataTestIdWrapper}>
      <BannerContentWrapper>
        <BannerHeader dataTestId={dataTestIdHeader}>{title}</BannerHeader>
        <BannerText>{text}</BannerText>
      </BannerContentWrapper>
    </BannerWrapper>
  )
}

ContentLandingBanner.propTypes = {
  dataTestId: PropTypes.string,
  isDistrictSupportsPage: PropTypes.bool,
  isFamilyResourcesPage: PropTypes.bool,
  isSelaLeaderPage: PropTypes.bool,
  isSelaProduct: PropTypes.bool,
  text: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
}

export default ContentLandingBanner
