import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { ContainedButton, OutlinedButton } from 'secondstep-components'

export const Wrapper = styled(Box)`
  margin-top: 0.625rem;
  margin-bottom: 1.25rem;
  ${themeGet('breakpoints.medium')} {
    width: 18rem;
  }
`
export const InputBoxWrapper = styled.div``

export const StyledErrorText = styled.p.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  position: absolute;
  text-align: left;
  color: ${themeGet('colors.warningRed')};
  margin-top: ${themeGet('spacing.pink8_0_5rem')};
  ${themeGet('breakpoints.medium')} {
    margin-top: ${themeGet('spacing.green4_0_25rem')};
  }
`

export const StyledInputField = styled.div`
  width: 100%;
  max-width: 100%;
  font-size: 0.875rem;
  text-align: left;

  [class*='LabelStyled'] {
    margin-bottom: ${themeGet('spacing.pink8_0_5rem')};
  }

  [class*='TextInputStyled'] {
    padding: ${themeGet('spacing.yellow12_0_75rem')};

    :focus {
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color;
    }

    border-color: ${({ isValidActivationCode, showValidationWarning }) => {
      if (isValidActivationCode) return themeGet('colors.okGreen')
      if (showValidationWarning) return themeGet('colors.warningRed')

      return 'none'
    }};

    background: ${({ showValidationWarning }) =>
      showValidationWarning ? '#FFE0E0' : '#FFFFFF'};

    transition: all 0.25s;
  }
`

export const InputBox = styled(Box)`
  flex-direction: row;
  align-items: center;
  input {
    text-transform: uppercase;
  }
`

export const IconBox = styled(Box)`
  margin-top: 1.875rem;
  margin-left: -6.25rem;
  padding-top: 0rem;
  width: 1.3rem;
  height: 1.3rem;
`

export const ButtonBox = styled(Box)`
  display: flex;
  flex-direction: row;

  margin: ${props => {
    const { showCancel } = props
    return showCancel
      ? `${themeGet('spacing.amber28_1_75rem')(props)} auto 
         ${themeGet('spacing.amber28_1_75rem')(props)} auto`
      : `${themeGet('spacing.amber28_1_75rem')(props)} 0 0 0`
  }};

  ${themeGet('breakpoints.mobile')} {
    margin: ${props => {
      const { showCancel } = props
      return showCancel
        ? `${themeGet('spacing.amber28_1_75rem')(props)} auto 
           ${themeGet('spacing.turquoise20_1_25rem')(props)} auto`
        : `${themeGet('spacing.amber28_1_75rem')(props)} 0 0 0`
    }};
    flex-direction: column-reverse;
  }
`

export const CancelButton = styled(OutlinedButton)`
  color: ${themeGet('colors.buttons.secondaryText')};
  border: 2px solid ${themeGet('colors.buttons.secondaryOutline')};
  margin-right: 0.625rem;

  ${themeGet('breakpoints.mobile')} {
    width: 6.875rem;
    margin-right: auto;
    margin-left: auto;
  }
`

export const UnlockButton = styled(ContainedButton)`
  margin-left: ${({ showCancel }) => (showCancel ? '0.625rem' : '0')};

  ${themeGet('breakpoints.mobile')} {
    width: 11.5rem;
    margin-left: 0rem;
    margin-bottom: ${props => {
      const { showCancel } = props
      return showCancel
        ? ` 
           ${themeGet('spacing.turquoise20_1_25rem')(props)}`
        : '0'
    }};
  }
`

export const Terms = styled.div`
  text-align: center;
  padding-left: 4em;
  padding-right: 4em;
  padding-top: ${themeGet('spacing.pink8_0_5rem')};
  margin-top: ${themeGet('spacing.amber28_1_75rem')};
  a {
    display: inline;
    text-decoration: underline !important;
  }
  a:hover {
    color: ${themeGet('colors.secondStepBlue')};
  }
`
