import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { GraphIcon } from 'secondstep-components'
import AccessibleTextLink from 'components/AccessibleTextLink'
import GradeProgressData from 'components/GradeProgressData'

export const FullReportLink = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  ${themeGet('breakpoints.mobile')} {
    justify-content: flex-start;
  }
`

export const GradeListWrapper = styled.div`
  display: grid;
  gap: 1.375rem 1.75rem;
  grid-auto-flow: column;
  max-height: 22rem;
  ${({ bhuStatus }) =>
    bhuStatus
      ? `grid-template-rows: repeat(2, minmax(50px, 1fr));`
      : `grid-template-rows: repeat(auto-fill, minmax(50px, 1fr));`}
  grid-template-columns: repeat(auto-fill, minmax(40%, 1fr));

  ${themeGet('breakpoints.belowTablet')} {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    max-height: unset;
    padding-bottom: 1.125rem;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    gap: 0.875rem;
  }
`

export const StyledGradeProgressData = styled(GradeProgressData)`
  [class*='Background'] {
    max-width: unset;
  }

  ${themeGet('breakpoints.belowTablet')} {
    padding-bottom: 1.4375rem;
  }

  ${themeGet('breakpoints.mobile')} {
    padding-bottom: 1.5625rem;
  }
`

export const StyledGraphIcon = styled(GraphIcon)`
  padding-right: 0.5rem;
  margin-right: 0 !important;
`

export const StyledTextLink = styled(AccessibleTextLink)`
  margin-bottom: -0.5rem;
`
