import React from 'react'
import PropTypes from 'prop-types'
import {
  ContentWrapper,
  FirstAdminFlexibleContentPanel,
  ImplementationGuideWrapper,
  SecondAdminFlexibleContentPanel,
  StyledLandingBanner,
  ThirdAdminFlexibleContentPanel,
} from './component.styles'

const ImplementationGuide = ({
  description,
  displayTitle,
  navigateToDashboardOnClick,
  programKey,
}) => {
  return (
    <ImplementationGuideWrapper>
      <StyledLandingBanner
        isDistrict={true}
        navigateToDashboardOnClick={navigateToDashboardOnClick}
        text={description}
        title={displayTitle}
      />
      <ContentWrapper>
        <FirstAdminFlexibleContentPanel
          breakpointTablet={true}
          contentKey="panel1"
          programKey={programKey}
          showHairline
        />
      </ContentWrapper>
      <ContentWrapper>
        <SecondAdminFlexibleContentPanel
          breakpointTablet={true}
          contentKey="panel2"
          programKey={programKey}
          showHairline
        />
      </ContentWrapper>
      <ContentWrapper>
        <ThirdAdminFlexibleContentPanel
          breakpointTablet={true}
          contentKey="panel3"
          programKey={programKey}
          showHairline
        />
      </ContentWrapper>
    </ImplementationGuideWrapper>
  )
}

ImplementationGuide.propTypes = {
  description: PropTypes.string,
  displayTitle: PropTypes.string,
  navigateToDashboardOnClick: PropTypes.func,
  programKey: PropTypes.string,
}

export default ImplementationGuide
