import React from 'react'
import PropTypes from 'prop-types'

const MyProgramsIcon = ({ fill }) => (
  <svg height="18px" viewBox="0 0 17 18" width="17px">
    <title>ss_icon_myprograms</title>
    <g
      fill="none"
      fillRule="evenodd"
      id="ss_icon_myprograms"
      stroke="none"
      strokeWidth="1"
    >
      <g fill={fill} id="Group-15" transform="translate(2.000000, 1.000000)">
        <path
          d="M11.2899144,0.078666444 C11.236157,0.0297223501 11.1398938,-0.0216879192 10.9846801,0.00951868334 C9.99944857,0.210037704 9.23068908,0.617620595 8.63435713,1.25560117 C8.24151468,1.6759892 8.05379658,2.14750294 8.06023977,2.68854386 C8.05735476,2.81365483 8.08101186,2.9518555 8.10389962,3.08540837 L8.11668984,3.16138554 C8.14025077,3.30442371 8.23266731,3.54553977 8.58781227,3.55303315 C8.60493001,3.55341256 8.62204774,3.55360226 8.63916548,3.55360226 C9.14702373,3.55360226 9.61160681,3.39453396 10.0207977,3.08057088 C10.2348655,2.91609596 10.421622,2.72288061 10.5754893,2.50652115 C11.0154536,1.88779572 11.2857792,1.17592474 11.3798306,0.381057474 C11.3867546,0.32718103 11.4050263,0.183858305 11.2899144,0.078666444"
          id="Fill-1"
        />
        <path
          d="M12.9998333,8.97075912 C12.9885818,8.17979712 12.866815,7.54476238 12.6169474,6.97225807 C12.05412,5.68320615 10.8912935,4.9657812 9.43367657,5.0033473 C8.66323736,5.02336661 7.918385,5.20856514 7.15636362,5.56971024 L7.04718195,5.62211137 C6.91032981,5.68799164 6.78106204,5.75028279 6.657045,5.79311294 C6.55853146,5.82701017 6.48185426,5.8282863 6.39217527,5.79741988 C6.19489816,5.7294659 5.99878787,5.640376 5.79125935,5.54618157 L5.70924808,5.50909401 C4.85088012,5.1210704 4.04343583,4.95772562 3.24182567,5.00924941 C1.85521845,5.09849883 0.855914457,5.80874554 0.352011876,7.06326217 C0.103144342,7.68210599 -0.0147051863,8.36922283 0.00146370239,9.10531119 L0.00146370239,9.10531119 C0.0351349963,10.6255821 0.509533523,12.0187982 1.41157415,13.2462768 C1.77829121,13.7450846 2.22410248,14.162539 2.73692295,14.4869953 C3.60070832,15.0335782 4.54450469,15.1211527 5.5419751,14.7477248 C5.62715347,14.7158216 5.71116501,14.6808077 5.79492652,14.6456343 C5.96528327,14.5743305 6.11238682,14.5125977 6.2485722,14.491382 C6.53486154,14.4464781 6.81823382,14.4873143 7.11377443,14.6160441 C7.15994744,14.6362229 7.20578708,14.65696 7.25171005,14.6776174 C7.46265571,14.7729284 7.70193859,14.8810008 7.98006014,14.9392243 C8.17383677,14.9797414 8.36794678,15 8.56205679,15 C9.03462173,15 9.50735336,14.8802032 9.97350076,14.6417262 C10.6619287,14.289514 11.2479259,13.7519438 11.7648303,12.9981491 C12.4053349,12.0644199 12.8033063,10.9789907 12.9479095,9.77193018 C12.9769968,9.53058188 12.9938325,9.27487711 12.9998333,8.99014036 L13,8.98040986 L12.9998333,8.97075912 Z"
          id="Fill-4"
          stroke={fill}
        />
        <path
          d="M4.1915651,2.61679467 L4.08645712,2.70485827 C4.1009748,2.72175503 5.22072317,4.04514341 5.58874627,5.57587505 C5.84527361,5.68097861 6.10194612,5.79252585 6.35978005,5.91853556 L6.55547833,6.01519074 L6.5588174,6.01676586 C6.75190249,6.11284827 6.97750718,6.11714405 7.17393134,6.02779171 C7.28702404,5.97624228 7.40505275,5.92111303 7.52917888,5.86297673 L7.65011112,5.80655875 C7.57795827,5.24939534 7.42334502,4.67204174 7.18322265,4.07793458 C6.57798073,2.58056687 5.63897743,1.48242085 5.59934417,1.43631275 C5.24395145,1.02491968 4.61664266,0.975088562 4.199695,1.32476552 C3.9978993,1.49401948 3.87464422,1.73086046 3.85315806,1.99147146 C3.83181708,2.25222565 3.91456783,2.50553382 4.08645712,2.70471508 L4.1915651,2.61679467 Z"
          id="Fill-6"
        />
      </g>
    </g>
  </svg>
)

MyProgramsIcon.propTypes = {
  fill: PropTypes.string,
}

export default MyProgramsIcon
