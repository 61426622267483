import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { HeaderFour, ProgramThemeLink } from 'secondstep-components'

export const StyledHeaderFour = styled(HeaderFour)`
  font-size: 1.5rem;
  margin-bottom: 0.625rem;
  color: ${themeGet('colors.fontLight')};
`

export const StyledBox = styled(Box)`
  flex-direction: row;

  @media screen and (max-width: 730px) {
    flex-direction: column;
  }

  @media screen and (max-width: 500px) {
    flex-direction: column;
  }
`

export const StyledList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;

  &:first-of-type {
    margin-right: 1.75rem;
  }

  ${ProgramThemeLink} {
    margin-bottom: 0.25rem;

    // The styles below were implemented to temporarily disguise the links and make them appear as body copy
    // TODO: remove "visited, hover, and focus" properties once the static pages corresponding to the themes have been added

    &:visited {
      color: ${themeGet('colors.linkLight')};
    }

    &:hover {
      color: ${themeGet('colors.linkLight')};
      cursor: default;
      svg {
        g {
          g {
            path {
              fill: #fff;
            }
          }
        }
      }
    }

    &:focus {
      color: ${themeGet('colors.linkLight')};
    }
  }
`
