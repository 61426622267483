import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, HeaderOne } from 'secondstep-components'
import { Box } from 'grommet'
import img from './assets/all_modules_banner.png'

export const AllModulesWrapper = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  max-width: none;
  width: 100%;
  background-color: ${themeGet('colors.modules.tertiaryBackground')};
`

export const BodyContent = styled(Box)`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: calc(${themeGet('spacing.red40_2_5rem')} * 2);
  margin-bottom: calc(${themeGet('spacing.red40_2_5rem')} * 2);
  margin-left: ${themeGet('spacing.amber28_1_75rem')};
  margin-right: ${themeGet('spacing.amber28_1_75rem')};

  ${themeGet('breakpoints.tablet')} {
    margin: ${themeGet('spacing.amber28_1_75rem')}
      ${themeGet('spacing.amber28_1_75rem')}
      calc(${themeGet('spacing.red40_2_5rem')} * 2);
  }
  ${themeGet('breakpoints.mobile')} {
    margin: ${themeGet('spacing.amber28_1_75rem')}
      ${themeGet('spacing.blue16_1rem')}
      calc(${themeGet('spacing.red40_2_5rem')} * 2);
  }
`

export const Body = styled(Box)`
  background: ${themeGet('colors.white')};
  border-radius: 0.625rem;
  padding: ${themeGet('spacing.red40_2_5rem')};
  max-width: 51.875rem;
  z-index: 0;

  ${themeGet('breakpoints.tablet')} {
    max-width: 44.5rem;
    padding: ${themeGet('spacing.red40_2_5rem')}
      ${themeGet('spacing.amber28_1_75rem')}
      ${themeGet('spacing.amber28_1_75rem')};
  }
  ${themeGet('breakpoints.mobile')} {
    max-width: 21.438rem;
    padding: ${themeGet('spacing.amber28_1_75rem')}
      ${themeGet('spacing.blue16_1rem')} ${themeGet('spacing.blue16_1rem')};
  }
`
export const Description = styled(BodyCopy).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin-top: ${themeGet('spacing.turquoise20_1_25rem')};
  z-index: 1;
  font-size: ${themeGet('bodyCopy.medium.size')};
  line-height: ${themeGet('bodyCopy.medium.height')};

  ${themeGet('breakpoints.mobile')} {
    font-size: ${themeGet('bodyCopy.mobile.size')};
    line-height: ${themeGet('bodyCopy.mobile.height')};
  }
`

export const Hairline = styled.div`
  height: 0.125rem;
  border-radius: 0.0313rem;
  background-color: ${themeGet('colors.modules.tertiaryBackground')};
  margin: ${themeGet('spacing.yellow12_0_75rem')} 0
    ${themeGet('spacing.turquoise20_1_25rem')} 0;
`

export const HeaderBanner = styled(Box)`
  background-color: ${themeGet('colors.themeAccent')};
  background-image: url(${img});
  background-position: center;
  background-size: cover;
  max-width: none;
  height: 19.375rem;
  width: 100%;
  position: absolute;

  ${themeGet('breakpoints.mobile')} {
    height: 9.375rem;
  }
`

export const ModuleWrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  padding-bottom: ${themeGet('spacing.amber28_1_75rem')};
  & :last-of-type {
    padding-bottom: 0;
  }

  ${themeGet('breakpoints.mobile')} {
    padding-bottom: ${themeGet('spacing.blue16_1rem')};
  }
`

export const Title = styled(HeaderOne).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  color: ${themeGet('colors.primaryCopy')};
  font-family: ${themeGet('fonts.header')};
  margin-bottom: 0;

  ${themeGet('breakpoints.mobile')} {
    font-size: 2.375rem;
  }
`

Description.displayName = 'Description'
Title.displayName = 'Title'
