import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'

export const CollectionCard = styled(Button)`
  width: 100%;
  cursor: pointer;
  background-color: ${themeGet('colors.hsSecondaryLightGreen')};
  // need to set height so h4 child can center vertically:
  height: auto;
  min-height: 3rem;
  border-radius: 0.625rem;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  // FIX these after redlines are available:
  padding: 1rem 1.188rem;
  margin: 0rem;
  margin-top: 1.25rem;
  border: 0.125rem solid transparent;
  :hover {
    border: 0.125rem solid ${themeGet('colors.highschool.green1')};
  }
  h4 {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: ${themeGet('fonts.moldeMedium18.fontFamily')};
    font-size: ${themeGet('fonts.moldeMedium18.fontSize')};
    // a little different than fonts.moldeMedium18.lineHeight:
    line-height: 1.25rem;
    color: ${themeGet('colors.hsSecondaryDarkGreen')};
  }
`
