import { toUrlSafe } from 'utils/stringHelpers'

export const createHref = (product, activity) => {
  const { advisoryActivityType, pageData } = activity || {}
  const { route } = pageData || {}
  if (product && advisoryActivityType && route) {
    return `/product/${product}/advisory-activities/${toUrlSafe(
      advisoryActivityType,
    )}s/${toUrlSafe(route)}`
  }
}
