import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Collapsible } from 'grommet'
import { BodyCopy, HeaderFive } from 'secondstep-components'
import CodeActivationForm from '../CodeActivationForm'
import {
  CollapsibleButton,
  CollapsibleContainer,
  Container,
} from './component.styles'

import { BODY_COPY_BOTTOM, BODY_COPY_TOP, HEADER_ONE } from './constants'

export const PAKActivationCard = ({
  className,
  dataTestId = 'pak-activation-card',
}) => {
  const [open, setOpen] = useState(false)
  let clearValue = null

  const toggleCollapsible = () => {
    if (clearValue) {
      clearValue()
    }
    setOpen(!open)
  }

  const assignClearValue = clearValueFunc => {
    clearValue = clearValueFunc
  }

  return (
    <Container animation="fadeIn" className={className}>
      <CollapsibleButton
        dataTestId={`${dataTestId}-collapsible-button`}
        isOpen={open}
        onClick={toggleCollapsible}
      >
        <HeaderFive>{HEADER_ONE}</HeaderFive>
      </CollapsibleButton>
      <Collapsible open={open}>
        <CollapsibleContainer>
          <BodyCopy>{BODY_COPY_TOP}</BodyCopy>
          <BodyCopy>{BODY_COPY_BOTTOM}</BodyCopy>
          <CodeActivationForm
            clearValue={!open}
            dataTestId={`activation-form-${dataTestId}`}
            passClearValue={assignClearValue}
            setOpen={setOpen}
          />
        </CollapsibleContainer>
      </Collapsible>
    </Container>
  )
}

PAKActivationCard.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
}

export default PAKActivationCard
