export const ALL_LESSONS_KEY = 'All Lessons'
export const GRADES = {
  'grade-k': 'Kindergarten',
  'grade-1': 'Grade 1',
  'grade-2': 'Grade 2',
  'grade-3': 'Grade 3',
  'grade-4': 'Grade 4',
  'grade-5': 'Grade 5',
  'grade-6': 'Grade 6',
  'grade-7': 'Grade 7',
  'grade-8': 'Grade 8',
}
export const SINGULAR_CLASS = 'class'
export const MULTI_CLASSES = 'classes'
export const LESSONS_NOT_STARTED_MULTIPLE_CLASSES = `haven't started teaching yet`
export const LESSONS_NOT_STARTED_SINGULAR_CLASS = `hasn't started teaching yet`
