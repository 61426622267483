export const COMPLETED = 'Completed'
export const CERTIFICATE = 'View Certificate'
const ML_SINGULAR = 'Microlearning'
const ML_PLURAL = 'Microlearnings'
const LEADER = '1 Leader Team Meeting'
const STAFF = '1 Small-Group Meeting'
const TIME_SUFFIX = 'total'

export const getTimeInfo = time => `${time} ${TIME_SUFFIX}`

export const getMicroLearningInfo = (totalML, hasMeeting, isLeader) => {
  let info = `${getMicrolearningText(totalML)}`
  if (hasMeeting) info += ` | ${getMeetingText(isLeader)}`
  return info
}

const getMicrolearningText = totalML =>
  totalML === 1 ? `${totalML} ${ML_SINGULAR}` : `${totalML} ${ML_PLURAL}`

const getMeetingText = isLeader => (isLeader ? LEADER : STAFF)
