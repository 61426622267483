import React from 'react'
import PropTypes from 'prop-types'
import {
  HeaderParagraph,
  Header2,
  StyledContainer,
  StyledLinkWrapper,
} from './component.styles'

const PathwayCard = ({ title, description, path }) => {
  return (
    <StyledLinkWrapper to={path}>
      <StyledContainer>
        <Header2>{title}</Header2>
        <HeaderParagraph>{description}</HeaderParagraph>
      </StyledContainer>
    </StyledLinkWrapper>
  )
}

PathwayCard.propTypes = {
  description: PropTypes.string,
  isPreview: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string,
}

export default PathwayCard
