import { actions } from './reducer'
import api from 'lib/api/secondStepApi.js'

const postAcceptUserAgreement = documentId => {
  return async dispatch => {
    dispatch(actions.postAcceptUserAgreement())

    try {
      await api.postAcceptUserAgreement(documentId)
      dispatch(actions.postAcceptUserAgreementSuccess())
      return true
    } catch (error) {
      console.error(error)
      dispatch(actions.postAcceptUserAgreementError(error))
      return false
    }
  }
}

export default {
  postAcceptUserAgreement,
}
