import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { HeaderTwo, MultiTextLink } from 'secondstep-components'
import Lesson from 'experienceComponents/Lesson'
import LessonAB from 'experienceComponents/LessonAB'
import { isFlagOn } from 'utils/featureFlags'
import { isElementaryPage } from 'utils/productHelpers'
import { LessonListWrapper, SubHeadBox } from './component.styles'
import { getDefaultLessonABIndex } from './utils'

function getUnitResourceUrl(unitResource) {
  return unitResource?.file.url || unitResource?.file?.file?.url
}

// TODO: ADD MORE TESTS TO THIS FILE AND BREAK APART
const LessonList = props => {
  const {
    contentfulLessons,
    dataTestId,
    gradeTheme,
    lessonStartingIndex,
    lessons,
    match,
    navigationEvent,
    unitResources,
  } = props
  const [aLessonIsOpen, setALessonIsOpen] = useState(false)
  const [selectedLessonAB, setSelectedLessonAB] = useState(null)
  const [selectedLessonIndex, setSelectedLessonIndex] = useState(1)
  const [useDefaultLessonAB, setUseDefaultLessonAB] = useState(true)

  const findNextLessonIndex = () => {
    let nextLessonIndex = lessonStartingIndex
    const incompleteLesson = lessons.find(lesson => {
      if (!lesson.choices) return !lesson.is_done
      if (lesson.choices) {
        const lessonChoiceIsComplete = lesson.choices.some(
          abLesson => abLesson.is_done,
        )

        return !lessonChoiceIsComplete
      }
    })

    if (incompleteLesson) {
      nextLessonIndex = lessons.indexOf(incompleteLesson) + lessonStartingIndex
    } else {
      nextLessonIndex = lessonStartingIndex - 1 + lessons.length
    }
    return nextLessonIndex
  }

  const isALessonOpen = index => {
    let isLessonOpen = false

    if (selectedLessonIndex === lessonStartingIndex + index) {
      isLessonOpen = true
    }
    if (aLessonIsOpen) {
      isLessonOpen = false
    }
    return isLessonOpen
  }

  function getSelectedChoiceIndex() {
    if (!useDefaultLessonAB && selectedLessonAB !== null) {
      return selectedLessonAB
    }
  }

  function getChoiceIndex(lessonChoices) {
    let choiceIndex = getSelectedChoiceIndex()

    // Check if there is a selected lesson choice
    if (choiceIndex >= 0) {
      return choiceIndex
    }

    // Check if there is a default lesson choice
    return getDefaultLessonABIndex(lessonChoices)
  }

  function getChoiceContentLesson(choiceIndex, contentChoiceLesson) {
    if (choiceIndex >= 0) {
      return choiceIndex === 0
        ? contentChoiceLesson.item1Lesson
        : contentChoiceLesson.item2Lesson
    }

    return contentChoiceLesson
  }

  const onClick = href => {
    const url = href
    const type = NavigationHelper.types.DOWNLOAD_LINK
    navigationEvent(url, type)
  }
  const toggleSelectedLessonAB = (selectedLesson, index) => {
    setUseDefaultLessonAB(false)
    setSelectedLessonAB(selectedLesson)
    setSelectedLessonIndex(index + 1)
  }

  useEffect(() => {
    setALessonIsOpen(false)
    setSelectedLessonIndex(findNextLessonIndex())
  }, [lessons])

  const lessonPlan = unitResources && getUnitResourceUrl(unitResources[0])
  const studentHandouts = unitResources && getUnitResourceUrl(unitResources[1])
  const lessonLastIndex = lessonStartingIndex - 1 + lessons.length

  if (
    isElementaryPage() &&
    isFlagOn(['feature_LEARN-18511-k5-elem-program-flex'])
  ) {
    console.log('[DEBUG] LessonList rendering K5 in program flex')
  }

  return (
    <LessonListWrapper dataTestId={dataTestId}>
      <SubHeadBox>
        {lessons.length > 1 && (
          <HeaderTwo dataTestId="header-lessons">
            {`Lessons ${lessonStartingIndex}-${lessonLastIndex}`}
          </HeaderTwo>
        )}
        {unitResources && (
          <MultiTextLink
            dataTestId="text-link-plans-handouts"
            downloadLinkLabel={unitResources[0]?.displayTitle}
            downloadOnClick={() => onClick(lessonPlan)}
            textLinkLabel={unitResources[1]?.displayTitle}
            textLinkOnClick={() => onClick(studentHandouts)}
          />
        )}
      </SubHeadBox>
      {lessons.map((lesson, index) => {
        const openThisLesson = isALessonOpen(index)
        const lessonChoices = lesson.choices && lesson.choices
        let lessonChoiceIndex = lessonChoices && getChoiceIndex(lessonChoices)
        const contentfulLesson = lessonChoices
          ? getChoiceContentLesson(lessonChoiceIndex, contentfulLessons[index])
          : contentfulLessons[index]
        const selectedChoice =
          lessonChoices && lessonChoiceIndex >= 0
            ? lessonChoices[lessonChoiceIndex]
            : null

        const key = lessonChoices ? `lesson-ab-${lesson.scoID}` : lesson.scoID

        return (
          <Fragment key={key}>
            {lessonChoices && (
              <LessonAB
                contentfulLesson={contentfulLesson}
                isOpen={openThisLesson}
                lessonIndex={index}
                toggleSelectedLessonAB={toggleSelectedLessonAB}
              >
                {selectedChoice && (
                  <Lesson
                    attempted={selectedChoice.attempted}
                    contentfulLesson={contentfulLesson}
                    dataTestId={`lesson-${index + 1}`}
                    gradeTheme={gradeTheme}
                    instance={selectedChoice.instance}
                    isDone={selectedChoice.is_done}
                    isOpen={openThisLesson}
                    lessonIndex={lessonStartingIndex + index}
                    match={match}
                    scoId={selectedChoice.scoID}
                    selectedLessonABIndex={lessonChoiceIndex}
                    setALessonIsOpen={setALessonIsOpen}
                    setSelectedLessonIndex={setSelectedLessonIndex}
                  />
                )}
              </LessonAB>
            )}
            {!lessonChoices && (
              <Lesson
                attempted={lesson.attempted}
                contentfulLesson={contentfulLesson}
                dataTestId={`lesson-${index + 1}`}
                gradeTheme={gradeTheme}
                instance={lesson.instance}
                isDone={lesson.is_done}
                isOpen={openThisLesson}
                lessonIndex={lessonStartingIndex + index}
                match={match}
                scoId={lesson.scoID}
                setALessonIsOpen={setALessonIsOpen}
                setSelectedLessonIndex={setSelectedLessonIndex}
              />
            )}
          </Fragment>
        )
      })}
    </LessonListWrapper>
  )
}

LessonList.propTypes = {
  contentfulLessons: PropTypes.array.isRequired,
  dataTestId: PropTypes.string.isRequired,
  gradeTheme: PropTypes.string,
  lessonStartingIndex: PropTypes.number,
  lessons: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  navigationEvent: PropTypes.func,
  unitResources: PropTypes.array,
}

LessonList.displayName = 'LessonList'

export default LessonList
