import { pluralizeWord } from 'utils/stringHelpers'

export const PENDING_COPY = count =>
  `${pluralizeWord(count, 'User', 'Users')} Pending`
export const SITE_HEADER_LAUNCH = 'Launch Snapshot'
export const SITE_HEADER_MONITOR = 'Progress & Pacing Snapshot'
export const SCHOOLS_COPY = count => pluralizeWord(count, 'School', 'Schools')
export const USERS_COPY = count =>
  `${pluralizeWord(count, 'User', 'Users')} Added`
export const SETUP_COPY = count =>
  `${count} ${pluralizeWord(count, 'School', 'Schools')} ${pluralizeWord(
    count,
    'Requires',
    'Require',
  )} Setup`

export const sortOrders = {
  asc: 'asc',
  desc: 'desc',
}

export const titlesBySortKeyMap = {
  educatorPractices: 'Educator\nPractices',
  pacing: 'Pacing',
  school: 'School',
  schoolwidePractices: 'Schoolwide\nPractices',
  studentActivities: 'Student\nActivities',
  training: 'Staff Training Completion',
  users: 'Users Added',
}
