import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { TextBlock, BodyText, Row, TextWrapper } from './component.styles'
import { CONTENTFUL_TEXT, COPYRIGHT_TEXT, TRADEMARK_TEXT } from './constants'
import { LINKS, DASHBOARD_LINKS } from './data'
import FooterTextLink from './footerTextLink'

export default class FooterTexts extends Component {
  static propTypes = {
    isDashboard: PropTypes.bool,
    isK8Report: PropTypes.bool,
    navigationEvent: PropTypes.func,
  }

  render() {
    const { isDashboard, isK8Report, navigationEvent } = this.props
    const FooterLinks = isDashboard ? DASHBOARD_LINKS : LINKS

    return (
      <TextBlock isDashboard={isDashboard} isK8Report={isK8Report}>
        <TextWrapper>
          <BodyText>{COPYRIGHT_TEXT}</BodyText>
          <BodyText>{TRADEMARK_TEXT}</BodyText>
        </TextWrapper>
        <Row>
          {FooterLinks.map((link, index) => {
            const { href, text } = link || {}
            const divider = index < FooterLinks.length - 1
            return (
              <FooterTextLink
                divider={divider}
                href={href}
                key={text}
                navigationEvent={navigationEvent}
                tabIndex="0"
                text={text}
              />
            )
          })}
        </Row>
        <BodyText>{CONTENTFUL_TEXT}</BodyText>
      </TextBlock>
    )
  }
}
