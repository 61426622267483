import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  documentUrl: null,
  error: null,
  isFetching: false,
}

const reducers = {
  clearDocumentUrl: state => {
    state.documentUrl = initialState.documentUrl
  },
  clearErrors: state => {
    state.error = initialState.error
  },
  fetchingDocumentUrl: state => {
    state.error = null
    state.isFetching = true
  },
  fetchingDocumentUrlSuccess: (state, { payload }) => {
    state.error = null
    state.isFetching = false
    state.documentUrl = payload.url
  },
  fetchingDocumentUrlError: (state, { payload }) => {
    state.error = payload.error
    state.isFetching = false
  },
}

const slice = createSlice({ initialState, name: 'docService', reducers })

export const actions = slice.actions
export default slice.reducer
