// TODO: refactor this leviathan  ->  https://secondstep.atlassian.net/browse/LEARN-4251

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AddIcon, ContainedButton, Modal } from 'secondstep-components'
import { toKebabCase } from 'utils/stringHelpers'
import licenseManagerSelectors from 'store/licenseManager/selectors'
import removeRedactedContentfulArray from 'utils/removeRedactedContentfulArray'
import { cleanObject } from 'utils/stateHelpers'
import { dateToYYYYMMDD } from 'utils/timeFormattingHelpers'
import {
  getSchoolOption,
  getProductFromProductMap,
  getLicenseOption,
  getLicenseFromSchoolOptionText,
} from 'utils/productHelpers'

import { maxClasses } from 'config/constants'
import {
  INITIAL_COURSE,
  INITIAL_LICENSE,
  MODAL_CONTENT_TYPES,
  MODAL_FLOW_TYPES,
} from './constants'
import { BASICINPUTSCHEMA, CREATECOURSESCHEMA } from './data'
import {
  Cancel,
  Create,
  Delete,
  Edit,
  Error,
  ErrorDetails,
  Loading,
  Success,
} from './modals'
import { StyledDropdownMenu, TextButton } from './component.styles'

class CreateCourse extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFormValid: false,
      inputErrors: {
        className: {
          errors: null,
        },
        selectedGrade: '',
        startDate: {
          errors: null,
        },
        endDate: {
          errors: null,
        },
      },
      courseInstanceName: this.props.courseInstanceName || '',
      licensesWithGrades: [],
      modalContent: null,
      modalFlow: null,
      selectedCourse: INITIAL_COURSE,
      selectedSchool: getSchoolOption(INITIAL_LICENSE),
      submitAttempts: 0,
    }
    this._isMounted = false
  }

  componentDidMount() {
    this._isMounted = true
    if (
      this.props.lms.isCreated &&
      !this.props.edit &&
      /lessons/.test(window.location.href)
    ) {
      this.handleUpdateSuccessCreation()
    }
  }

  componentDidUpdate() {
    if (
      this.props.lms.isCreated &&
      !this.props.edit &&
      /lessons/.test(window.location.href)
    ) {
      this.handleUpdateSuccessCreation()
    }
  }
  componentWillUnmount() {
    this._isMounted = false
  }

  handleUpdateSuccessCreation() {
    const programLmsKey = this.currentProductFromUrl()?.lmsKeyV2
    const newCourse = this.props.lms.instructorData[programLmsKey][0]
    this.props.deletingCourseCreatedStatus()
    this.setState({
      selectedCourse: {
        id: newCourse.instance,
        name: newCourse.title,
        defaultUnit: newCourse.units[0],
      },
      selectedGrade: newCourse.grade,
      modalContent: MODAL_CONTENT_TYPES.Success,
      courseInstanceName: newCourse.title,
      previous: MODAL_CONTENT_TYPES.Create,
    })
  }

  handleResetFocus = () => {
    this.props.setFocus && this.props.setFocus()
  }

  resetState = () => {
    const deepCopy = JSON.parse(JSON.stringify(this.state))

    let blankState = cleanObject(deepCopy)
    blankState.modalContent = ''
    blankState.courseInstanceName = this.props.courseInstanceName || ''
    blankState.startDate = null
    blankState.endDate = null
    blankState.isFormValid = false
    blankState.licensesWithGrades = []
    blankState.selectedSchool = getLicenseOption(INITIAL_LICENSE)
    blankState.submitAttempts = 0

    this.setState(blankState)
    this.handleResetFocus()
  }

  clickCreateSideEffect = licenses => {
    const hasNoLicenses =
      !licenses || !Array.isArray(licenses) || licenses.length === 0
    const hasMoreThanOneLicense = licenses.length > 1

    if (hasNoLicenses || hasMoreThanOneLicense) {
      return
    }

    // If only one license, auto-select that license
    const e = { value: licenses[0].siteName }

    this.handleLicenseChange(e, licenses)
  }

  getFilterTypeFromPathname = () => {
    return this.currentProductFromUrl()?.skuCode
  }

  currentProductFromUrl() {
    const { pathName = '' } = this.props
    // [LEARN-8666] TODO: if we cant find anything from the parsed name, default to middle school
    const defaultParsedProduct = 'grades-6-8'
    const parsedPathname = pathName.split('/')[2] || defaultParsedProduct

    return getProductFromProductMap(parsedPathname)
  }

  get limitReached() {
    const currentProductLmsKey = this.currentProductFromUrl()?.lmsKeyV2
    const currentProductClasses =
      this.props.lms.instructorData?.[currentProductLmsKey] ?? []
    return currentProductClasses?.length >= maxClasses
  }

  handleCreate = () => {
    const {
      activeDigitalLicenses,
      contentfulCourses,
      userAccess,
      userProfile,
    } = this.props
    const { selectedCourse, selectedSchool } = this.state || {}
    const { activeCourseDates } = userProfile || {}
    const { endDate, startDate } = activeCourseDates || {}
    const availableContentfulCourses =
      removeRedactedContentfulArray(contentfulCourses) || []
    const hasOneAvailableContentfulCourse =
      availableContentfulCourses.length === 1

    if (hasOneAvailableContentfulCourse) {
      const firstAvailableContentfulCourse = availableContentfulCourses[0]
      const { id, displayTitle } = firstAvailableContentfulCourse || {}
      selectedCourse.id = id
      selectedCourse.name = displayTitle
    }

    const licensesWithGrades = licenseManagerSelectors.getLicensesWithGrades(
      activeDigitalLicenses,
      userAccess,
      this.getFilterTypeFromPathname(),
    )

    // Reorder grades using contentful order
    licensesWithGrades.forEach(licenseWithGrade => {
      const originalGrades = licenseWithGrade.grades
      const sortedGrades = []

      availableContentfulCourses.forEach(contentfulCourse => {
        const index = originalGrades.findIndex(
          grade =>
            contentfulCourse.displayTitle
              .toLowerCase()
              .includes(grade.toLowerCase()), // careful if we have grade 10+
        )

        if (index !== -1) {
          originalGrades.splice(index, 1)
          sortedGrades.push(contentfulCourse.displayTitle)
        }
      })

      // Concat any original grade remaining (this should not happen)
      licenseWithGrade.grades = sortedGrades.concat(originalGrades)
    })

    this.setState(
      {
        endDate,
        licensesWithGrades,
        modalContent: MODAL_CONTENT_TYPES.Create,
        modalFlow: MODAL_FLOW_TYPES.Create,
        previous: this.state.modalContent,
        selectedCourse,
        selectedSchool,
        startDate,
      },
      () => this.clickCreateSideEffect(licensesWithGrades),
    )
  }

  handleError = passedError => {
    const { modalContent: previous, submitAttempts = 0 } = this.state || {}
    const newState =
      submitAttempts < 2
        ? {
            modalContent: MODAL_CONTENT_TYPES.Error,
            submitAttempts: submitAttempts + 1,
            previous,
          }
        : {
            modalContent: MODAL_CONTENT_TYPES.ErrorDetails,
            error: passedError.message,
            submitAttempts,
            previous,
          }
    this.setState(newState)
  }

  handleSubmit = async () => {
    const { createCourseInstance, createCourseInstanceV2, userContext } =
      this.props || {}
    const {
      courseInstanceName,
      modalContent,
      selectedGrade,
      selectedCourse,
      selectedSchool,
    } = this.state || {}

    const { id: selectedCourseId } = selectedCourse || {}
    const { licenseId: selectedLicenseId, siteId: selectedSiteId } =
      selectedSchool || {}
    try {
      const formattedStartDate = new Date().toISOString()
      const formattedEndDate = new Date('1 jan 2199').toISOString()
      const courseInstance = {
        courseId: selectedCourseId,
        endDate: formattedEndDate,
        licenseId: selectedLicenseId,
        siteId: selectedSiteId,
        startDate: formattedStartDate,
        grade: selectedGrade,
        title: courseInstanceName.trim(),
      }
      const programLmsKey = this.currentProductFromUrl()?.lmsKeyV2
      this.setState({
        modalContent: MODAL_CONTENT_TYPES.Loading,
        previous: modalContent,
      })
      const newCourse =
        programLmsKey === 'Elementary'
          ? await createCourseInstance(courseInstance, programLmsKey)
          : (await createCourseInstanceV2(
              courseInstance,
              programLmsKey,
              userContext,
            )) || null

      if (/dashboard/.test(window.location.href)) {
        this.setState({
          selectedCourse: {
            id: newCourse.instance,
            name: newCourse.title,
            defaultUnit: newCourse.units[0],
          },
          modalContent: MODAL_CONTENT_TYPES.Success,
          courseInstanceName: newCourse.title,
          previous: modalContent,
        })
        this.props.deletingCourseCreatedStatus()
      }
    } catch (err) {
      console.error('ERROR CREATING CLASS:', err)
      this.handleError(err)
    }
  }

  goBackOneModal = () => {
    this.setState({
      modalContent: this.state.previous,
      previous: this.state.modalContent,
    })
  }

  handleClassNameChange = e => {
    const { target } = e || {}
    const { name, value } = target || {}
    const newState = { [name]: value }

    this.setState(newState, () => this.checkBlockingValidations())
  }

  handleGradeChange = (e, courses = []) => {
    const { option, target } = e || {}
    const { name } = target || {}
    const matchedCourse = courses.find(course =>
      course.displayTitle?.includes(option),
    )
    const { id: matchedCourseId } = matchedCourse || {}
    const noCourse = { selectedCourse: INITIAL_COURSE }
    const newState = matchedCourse
      ? { [name]: { name: option, id: matchedCourseId } }
      : noCourse

    this.setState(
      {
        ...newState,
        selectedGrade: e.target.value,
      },
      () => this.checkBlockingValidations(),
    )
  }

  licenseChangeSideEffect = license => {
    this.checkBlockingValidations()

    const { contentfulCourses } = this.props
    const { grades = [] } = license || {}
    const licenseHasOneGrade = grades.length === 1
    const target = { name: 'selectedCourse' }
    // If license has one accessible grade, auto-fill with that one.
    // If license has multiple grades accessible, retain the grade if it was previously chosen
    if (licenseHasOneGrade) {
      const option = grades[0]

      this.handleGradeChange(
        {
          option,
          target,
        },
        contentfulCourses,
      )
    }
  }

  handleLicenseChange = (e, licenses = []) => {
    const { value: selectedLicense } = e || {}
    let matchedLicense =
      typeof selectedLicense === 'string'
        ? getLicenseFromSchoolOptionText(selectedLicense, licenses)
        : null

    this.setState(
      {
        selectedSchool: { ...matchedLicense },
      },
      () => this.licenseChangeSideEffect(matchedLicense),
    )
  }

  handleSaveEdit = async () => {
    const { course, updateCourseInstance } = this.props
    const { courseInstanceName, modalContent: previous } = this.state || {}
    const { instance: courseInstance } = course || {}

    try {
      const courseData = { ...course }
      courseData.title = courseInstanceName
      courseData.instanceId = courseInstance

      this.setState({
        modalContent: MODAL_CONTENT_TYPES.Loading,
        previous,
      })

      const updatedCourse = await updateCourseInstance(courseData)
      const { title: updatedCourseTitle } = updatedCourse || {}

      if (!this._isMounted) return

      this.setState({
        courseInstanceName: updatedCourseTitle,
        isFormValid: false,
        modalContent: null,
      })
    } catch (err) {
      console.error('ERROR EDITING CLASS:', err)
      this.handleError(err)
    }
  }

  handleViewClass = () => {
    const currentProductName = this.currentProductFromUrl()?.productName
    let trimmedGradeName = this.state?.selectedGrade
      ? this.state.selectedGrade.replace('Grade ', '').toLowerCase()
      : null

    const destinationPath =
      currentProductName && this.state?.selectedCourse?.id && trimmedGradeName
        ? `/product/${currentProductName}/lessons/teach/grade-${trimmedGradeName}/unit-1?class=${this.state.selectedCourse.id}`
        : undefined

    this.props.redirectToMyClasses(destinationPath)
  }

  getLessonsLink = () => {
    const currentProductName = this.currentProductFromUrl()?.productName

    return currentProductName
      ? `/product/${currentProductName}/lessons`
      : undefined
  }

  checkBlockingValidations = async () => {
    const { state } = this
    const { modalContent } = state || {}
    const isEditMode = modalContent === MODAL_CONTENT_TYPES.Edit
    const isCreateMode = modalContent === MODAL_CONTENT_TYPES.Create

    let isValid = true
    const {
      isFormValid: startDateIsValid,
      startDateErrors,
    } = await this.validate.startDate()
    const {
      isFormValid: endDateIsValid,
      endDateErrors,
    } = await this.validate.endDate()
    const {
      isFormValid: classNameIsValid,
      classNameErrors,
    } = await this.validate.className(state.modalContent)
    const inputErrors = {
      className: classNameErrors,
      endDate: endDateErrors,
      startDate: startDateErrors,
    }
    const isCreateInValid =
      !startDateIsValid || !endDateIsValid || !classNameIsValid

    if (isCreateMode && isCreateInValid) {
      isValid = false
    } else if (isEditMode) {
      isValid = classNameIsValid
    }

    this.setState({
      isFormValid: isValid,
      inputErrors,
    })
  }

  handleCancel = () => {
    this.setState({
      courseInstanceName: this.props.courseInstanceName,
      inputErrors: [],
      isFormValid: false,
      modalContent: null,
      previous: null,
    })
    this.handleResetFocus()
  }

  handleDeleteSubmit = () => {
    this.setState({
      modalContent: MODAL_CONTENT_TYPES.Delete,
      modalFlow: MODAL_FLOW_TYPES.Delete,
    })
  }

  handleEditSubmit = () => {
    this.setState({
      modalContent: MODAL_CONTENT_TYPES.Edit,
      modalFlow: MODAL_FLOW_TYPES.Edit,
    })
  }

  handleDelete = async () => {
    const {
      course,
      deleteCourseInstance,
      fetchInstructorData,
      fetchInstructorDataV2,
      userContext,
    } = this.props

    const { modalContent } = this.state || {}
    const { instance: courseInstance } = course || {}

    try {
      this.setState({
        modalContent: MODAL_CONTENT_TYPES.Loading,
        previous: modalContent,
      })

      await deleteCourseInstance(courseInstance)
      await fetchInstructorData()
      await fetchInstructorDataV2(userContext)

      if (!this._isMounted) return

      this.setState({
        courseInstanceName: null,
        modalContent: null,
        previous: modalContent,
      })
    } catch (err) {
      console.error(err)
      this.handleError(err)
    }
  }

  validate = {
    schema: async () => {
      const {
        selectedCourse,
        selectedSchool,
        courseInstanceName,
        startDate,
        endDate,
      } = this.state || {}
      const { licenseId: selectedLicenseId } = selectedSchool
      const course = {
        selectedLicenseId,
        selectedCourse: selectedCourse?.name,
        courseInstanceName,
        startDate,
        endDate,
      }
      return CREATECOURSESCHEMA.isValid(course)
    },

    className: async modalType => {
      let isValid =
        modalType === MODAL_CONTENT_TYPES.Create
          ? await this.validate.schema()
          : true

      const { courseInstanceName } = this.state
      const courseInstanceNameTrimmed = courseInstanceName?.trim()
      const { lms } = this.props
      const isDuplicateCourseName = lms.instructorData[
        this.currentProductFromUrl()?.lmsKeyV2
      ]
        .map(x => x.title)
        .includes(courseInstanceNameTrimmed)
      let classNameErrors = { errors: null }

      if (courseInstanceNameTrimmed && courseInstanceNameTrimmed.length > 0) {
        try {
          await BASICINPUTSCHEMA.validate(courseInstanceNameTrimmed)
        } catch (e) {
          classNameErrors = e
          isValid = false
        }
      } else {
        isValid = false
      }

      if (isDuplicateCourseName) {
        classNameErrors = {
          errors: ['You may not have a duplicate class name'],
        }
        isValid = false
      }

      return {
        isFormValid: isValid,
        classNameErrors,
      }
    },

    startDate: async () => {
      let isValid = await this.validate.schema()
      let { startDate, endDate } = this.state || {}
      startDate = dateToYYYYMMDD(startDate)
      endDate = dateToYYYYMMDD(endDate)
      let startDateErrors = { errors: null }

      if (!startDate) {
        startDateErrors = { errors: ['You must have a start date'] }
        isValid = false
      }

      if ((endDate && startDate > endDate) || startDate === endDate) {
        startDateErrors = {
          errors: [`Start date must be before end date`],
        }
        isValid = false
      }

      return {
        isFormValid: isValid,
        startDateErrors,
      }
    },

    endDate: async () => {
      let isValid = await this.validate.schema()
      let { startDate, endDate } = this.state
      startDate = dateToYYYYMMDD(startDate)
      endDate = dateToYYYYMMDD(endDate)
      let endDateErrors = { errors: null }

      if (!endDate) {
        endDateErrors = { errors: ['You must have an end date'] }
        isValid = false
      }

      if ((startDate && endDate < startDate) || startDate === endDate) {
        endDateErrors = {
          errors: [`End date must be after start date`],
        }
        isValid = false
      }

      return {
        isFormValid: isValid,
        endDateErrors,
      }
    },
  }

  render() {
    const { props, state } = this
    const { course, isClassCardMenu, className } = props
    const { instance } = course

    const {
      courseInstanceName,
      endDate,
      inputErrors,
      isFormValid,
      licensesWithGrades,
      modalContent,
      selectedCourse,
      selectedSchool,
      startDate,
    } = state || {}
    const { name: selectedCourseName } = selectedCourse || {}
    const { licenseId: selectedLicenseId, grades: selectedLicenseGrades = [] } =
      selectedSchool || {}
    const userHasOneLicense = licensesWithGrades.length === 1
    const licenseDisabled = userHasOneLicense
    let modalBody, modalHandleCancel
    modalHandleCancel = this.resetState

    if (modalContent === MODAL_CONTENT_TYPES.Create) {
      modalBody = (
        <Create
          courseInstanceName={courseInstanceName}
          courses={selectedLicenseGrades}
          dataTestId={'modal-create'}
          endDate={endDate}
          handleCancel={modalHandleCancel}
          handleClassNameChange={this.handleClassNameChange}
          handleGradeChange={e =>
            this.handleGradeChange(e, props.contentfulCourses)
          }
          handleLicenseChange={e =>
            this.handleLicenseChange(e, licensesWithGrades)
          }
          handleSubmit={this.handleSubmit}
          inputErrors={inputErrors}
          lessonsLink={this.getLessonsLink()}
          licenseDisabled={licenseDisabled}
          licenses={licensesWithGrades}
          selectedCourse={selectedCourseName}
          selectedLicenseId={selectedLicenseId}
          startDate={startDate}
          submitDisabled={!isFormValid}
        />
      )
    } else if (modalContent === MODAL_CONTENT_TYPES.Delete) {
      modalBody = (
        <Delete
          courseInstanceName={state.courseInstanceName}
          dataTestId={'modal-delete'}
          handleCancel={this.handleCancel}
          handleSubmit={this.handleDelete}
          isClassCardMenu={isClassCardMenu}
        />
      )
    } else if (modalContent === MODAL_CONTENT_TYPES.Edit) {
      modalBody = (
        <Edit
          courseInstanceName={state.courseInstanceName}
          dataTestId={'modal-edit'}
          handleCancel={this.handleCancel}
          handleClassNameChange={this.handleClassNameChange}
          handleSubmit={this.handleSaveEdit}
          inputErrors={state.inputErrors}
          isClassCardMenu={isClassCardMenu}
          submitDisabled={!isFormValid}
        />
      )
    } else if (modalContent === MODAL_CONTENT_TYPES.Cancel) {
      modalBody = (
        <Cancel
          cancelFor={state.modalFlow}
          dataTestId={'modal-cancel'}
          handleCancel={modalHandleCancel}
          handleSubmit={this.goBackOneModal}
        />
      )
    } else if (modalContent === MODAL_CONTENT_TYPES.Success) {
      let submitFunc =
        state.modalFlow === MODAL_FLOW_TYPES.Edit
          ? this.resetState
          : this.handleViewClass
      let cancelFunc =
        state.modalFlow === MODAL_FLOW_TYPES.Create
          ? this.handleCreate
          : this.resetState
      modalBody = (
        <Success
          dataTestId={'modal-success'}
          handleCancel={cancelFunc}
          handleClose={this.resetState}
          handleSubmit={submitFunc}
          maxClassesReached={this.limitReached}
          successFor={state.modalFlow}
          title={state.selectedGrade}
        />
      )
    } else if (modalContent === MODAL_CONTENT_TYPES.Error) {
      let submitFunc =
        state.modalFlow === MODAL_FLOW_TYPES.Edit
          ? this.handleSaveEdit
          : this.handleSubmit
      modalBody = (
        <Error
          dataTestId={'modal-error'}
          error={state.error || props.lms.error}
          errorFor={state.modalFlow}
          handleCancel={modalHandleCancel}
          handleSubmit={submitFunc}
        />
      )
    } else if (modalContent === MODAL_CONTENT_TYPES.ErrorDetails) {
      modalBody = (
        <ErrorDetails
          dataTestId={'modal-error-details'}
          error={state.error || props.lms.error}
          errorFor={state.modalFlow}
          handleCancel={modalHandleCancel}
          handleSubmit={modalHandleCancel}
        />
      )
    } else if (modalContent === MODAL_CONTENT_TYPES.Loading) {
      modalBody = <Loading dataTestId={'modal-loading'} />
    }
    return (
      <div className={className}>
        {!props.edit && !this.limitReached && !props.emptyClasses && (
          <TextButton
            dataTestId={'button-create'}
            icon={<AddIcon />}
            label="Create Class"
            onClick={this.handleCreate}
            ref={this.props.forwardedRef}
          />
        )}
        {props.emptyClasses && (
          <ContainedButton
            dataTestId={'button-create'}
            onClick={this.handleCreate}
            ref={this.props.forwardedRef}
          >
            {'Create Class'}
          </ContainedButton>
        )}
        {props.edit && (
          <StyledDropdownMenu
            courseInstanceName={courseInstanceName}
            dataTestId={`dropdown-menu-button-${toKebabCase(
              courseInstanceName,
            )}`}
            handleDeleteSubmit={this.handleDeleteSubmit}
            handleEditSubmit={this.handleEditSubmit}
            instance={instance}
            isClassCardMenu={isClassCardMenu}
            ref={this.props.forwardedRef}
          />
        )}
        {modalBody && (
          <Modal
            dataTestId={'modal-create-course'}
            isModalOpen={!!modalBody}
            modalClose={modalHandleCancel}
          >
            {modalBody}
          </Modal>
        )}
      </div>
    )
  }
}

CreateCourse.propTypes = {
  activeDigitalLicenses: PropTypes.array,
  className: PropTypes.string,
  classesUrl: PropTypes.string,
  contentfulCourses: PropTypes.array,
  course: PropTypes.object,
  courseInstanceName: PropTypes.string,
  createCourseInstance: PropTypes.func.isRequired,
  createCourseInstanceV2: PropTypes.func.isRequired,
  deleteCourseInstance: PropTypes.func.isRequired,
  deletingCourseCreatedStatus: PropTypes.func.isRequired,
  edit: PropTypes.bool,
  emptyClasses: PropTypes.bool,
  fetchInstructorData: PropTypes.func.isRequired,
  fetchInstructorDataV2: PropTypes.func.isRequired,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  isClassCardMenu: PropTypes.bool,
  isCreated: PropTypes.bool,
  license: PropTypes.object,
  limitReached: PropTypes.bool,
  lms: PropTypes.object.isRequired,
  pathName: PropTypes.string,
  redirectToMyClasses: PropTypes.func,
  setFocus: PropTypes.func,
  updateCourseInstance: PropTypes.func.isRequired,
  userAccess: PropTypes.object,
  userContext: PropTypes.object,
  userProfile: PropTypes.object,
}

CreateCourse.defaultProps = {
  course: {},
  createCourseInstance: () => {},
  createCourseInstanceV2: () => {},
  deletingCourseCreatedStatus: () => {},
}

export default CreateCourse
