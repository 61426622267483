import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BaseContainer } from 'secondstep-components'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

export const HeaderParagraph = styled(ReactMarkdown)`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamily')};
  font-size: 1rem;
  margin-top: 0.75rem;
  p {
    margin: 0;
  }

  a {
    color: ${themeGet('colors.darkGray')};
  }
`

export const Header2 = styled.p`
  color: ${themeGet('colors.highschool.green2')};
  font-family: ${themeGet('font.komet')};
  font-weight: bold;
  font-size: 1.25rem;
`

export const StyledContainer = styled(BaseContainer).attrs(() => ({
  as: 'section',
}))`
  margin-top: 1.25rem;
  padding: 1.75rem;
  max-width: 85.375rem;
  border: 2px solid ${themeGet('colors.highschool.green3')};
  &:hover {
    border: 2px solid ${themeGet('colors.themePrimary')};
  }
  ${themeGet('breakpoints.mobileLarge')} {
    padding: 1.75rem 1rem;
  }
`
export const StyledLinkWrapper = styled(Link)`
  text-decoration: none;
`
