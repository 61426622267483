/* istanbul ignore file */

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CaretIcon } from 'secondstep-components'

export function Panel({ header, children }) {
  const [isExpanded, setIsExpanded] = useState(false)
  const id = `collapsible-body-${header}`

  return (
    <fieldset>
      <legend>
        {header}
        <button
          aria-controls={id}
          aria-expanded={isExpanded}
          className="collapse-button"
          onClick={() => setIsExpanded(!isExpanded)}
          type="button"
        >
          <CaretIcon rotation={isExpanded ? 0 : 180} />
        </button>
      </legend>
      <div className="collapsible-body" hidden={!isExpanded} id={id}>
        {children}
      </div>
    </fieldset>
  )
}

Panel.propTypes = {
  children: PropTypes.object,
  header: PropTypes.string,
}
