import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { PipeIcon } from 'secondstep-components'
import {
  ENGLISH,
  ENGLISH_DATA_TESTID,
  ENGLISH_TOGGLE_KEY,
  SPANISH,
  SPANISH_DATA_TESTID,
  SPANISH_TOGGLE_KEY,
} from './constants'

import LinksBox from 'components/Teach/LinksBox'

import {
  DEFAULT_LOCALE,
  ENGLISH_LOCALE,
  SPANISH_LOCALE,
} from 'layers/content/data'
import { StyledTextButton } from './component.styles'

export default function LanguageToggleComponent({
  boundary,
  getEntryWithNewLocale,
  initialLocale,
  locale,
}) {
  if (!boundary) {
    return null
  }

  // Parent components are able to pass the locale directly as prop
  const [currentLocale, setCurrentLocale] = useState(
    locale || initialLocale || DEFAULT_LOCALE,
  )

  const updateLocale = newLocale => {
    setCurrentLocale(newLocale)
    getEntryWithNewLocale(newLocale)
  }
  return (
    <LinksBox>
      <StyledTextButton
        dataTestId={ENGLISH_DATA_TESTID}
        isSelected={currentLocale === ENGLISH_LOCALE}
        key={ENGLISH_TOGGLE_KEY}
        label={ENGLISH}
        onClick={() => updateLocale(ENGLISH_LOCALE)}
      />
      <PipeIcon />
      <StyledTextButton
        dataTestId={SPANISH_DATA_TESTID}
        isSelected={currentLocale === SPANISH_LOCALE}
        key={SPANISH_TOGGLE_KEY}
        label={SPANISH}
        onClick={() => updateLocale(SPANISH_LOCALE)}
      />
    </LinksBox>
  )
}

LanguageToggleComponent.propTypes = {
  boundary: PropTypes.bool,
  getEntryWithNewLocale: PropTypes.func,
  initialLocale: PropTypes.string,
  locale: PropTypes.string,
}
