import { getHighestIncludeOptionWithKnownLocales } from 'layers/content/utils'
import { ROOT_ENTRY_ID } from 'layers/content/Hocs/RootContent'
import ContentfulReducer from 'layers/content/store/reducer'

import { create401ErrorForRedacted } from './data'

export default class ContentfulErrorStateSelectors {
  static selectRootContentfulState(state) {
    const { entryData: rootContent } =
      getHighestIncludeOptionWithKnownLocales(state, ROOT_ENTRY_ID) || {}
    const { error: initialRootError, payload: rootPayload = {} } =
      rootContent || {}
    const rootRedactedError =
      rootPayload.redacted && create401ErrorForRedacted()
    const contentfulRootError = initialRootError || rootRedactedError

    return { contentfulRootError }
  }

  static selectContentfulProductState(state) {
    const contentfulProductEntryId =
      state?.[ContentfulReducer.ROOT_NAMESPACE]?.[
        ContentfulReducer.CONTENT_NAMESPACE
      ]?.['product']
    const contentfulProductSectionId =
      state?.[ContentfulReducer.ROOT_NAMESPACE]?.[
        ContentfulReducer.CONTENT_NAMESPACE
      ]?.['productSection']

    const { entryData: productContent } =
      getHighestIncludeOptionWithKnownLocales(
        state,
        contentfulProductEntryId,
      ) || {}
    const { entryData: productSectionContent } =
      getHighestIncludeOptionWithKnownLocales(
        state,
        contentfulProductSectionId,
      ) || {}

    const { error: initialProductError, payload: productPayload = {} } =
      productContent || {}
    const productRedactedError =
      productPayload.redacted && create401ErrorForRedacted()
    const contentfulProductError = initialProductError || productRedactedError

    const {
      error: initialProductSectionError,
      payload: productSectionPayload = {},
    } = productSectionContent || {}
    const productSectionRedactedError =
      productSectionPayload.redacted && create401ErrorForRedacted()
    const contentfulProductSectionError =
      initialProductSectionError || productSectionRedactedError

    return { contentfulProductError, contentfulProductSectionError }
  }
}
