import { connect } from 'react-redux'
import ContentfulReducer from 'layers/content/store/reducer'

import ContentEntryDataGetter from '../ContentEntryDataGetter'

export default (component, options = {}, entryIdNamespace, ...functions) => {
  const mapStateToProps = state => {
    const firstLevelNamespace = ContentfulReducer.ROOT_NAMESPACE

    const entryId =
      state &&
      state[firstLevelNamespace] &&
      state[firstLevelNamespace][ContentfulReducer.CONTENT_NAMESPACE] &&
      state[firstLevelNamespace][ContentfulReducer.CONTENT_NAMESPACE][
        entryIdNamespace
      ]
        ? state[firstLevelNamespace][ContentfulReducer.CONTENT_NAMESPACE][
            entryIdNamespace
          ]
        : undefined

    return {
      entryId,
    }
  }
  return ContentEntryDataGetter(
    component,
    options,
    connect(mapStateToProps),
    ...functions,
  )
}
