export const flattenArrayOfLessonPdfs = lessons => {
  const flattenedLessons = lessons.flat(2).reduce((results, lesson) => {
    if (lesson) {
      const lessonPdf = {
        displayTitle: lesson.displayTitle,
        href:
          lesson.file?.url ||
          lesson.file?.file?.url ||
          lesson.file?.file?.upload,
      }
      const dataExists = lessonPdf.displayTitle && lessonPdf.href

      dataExists && results.push(lessonPdf)
    }

    return results
  }, [])

  return flattenedLessons
}
