import { HeaderThree } from 'secondstep-components'
import PropTypes from 'prop-types'
import { TilesContainer } from 'components/dashboard/ActiveTabProgramCards/component.styles'
import ProgramLinkTile from 'components/dashboard/ProgramLinkTile'
import { SUBHEADER_TEXT } from './constants'

function Component(props) {
  const {
    sortedTeachLinks,
    displayTitle,
    headerDataTestId,
    staticLinksAvailable,
  } = props
  return (
    sortedTeachLinks?.length > 0 && [
      <HeaderThree
        className="program-subheader"
        dataTestId={headerDataTestId}
        key={`subheader-${displayTitle}`}
      >
        {SUBHEADER_TEXT}
      </HeaderThree>,
      <TilesContainer key="high-school-pathways-links">
        {sortedTeachLinks
          .filter(({ displayTitle, path } = {}) => !!displayTitle && !!path)
          .map(({ displayTitle, path } = {}) => (
            <ProgramLinkTile
              displayTitle={displayTitle}
              isPreview
              key={`${displayTitle}-program-link`}
              path={path}
            />
          ))}
        {staticLinksAvailable
          .filter(
            ({ displayTitle, linkLabel, path } = {}) =>
              !!displayTitle && !!linkLabel && !!path,
          )
          .map(({ displayTitle, linkLabel, path }) => (
            <ProgramLinkTile
              displayTitle={displayTitle}
              key={`${displayTitle}-program-link`}
              linkLabel={linkLabel}
              path={path}
              secondary
            />
          ))}
      </TilesContainer>,
    ]
  )
}

Component.propTypes = {
  activeTabProgramsAvailable: PropTypes.array,
  displayTitle: PropTypes.string,
  headerDataTestId: PropTypes.string,
  sortedTeachLinks: PropTypes.array,
  staticLinksAvailable: PropTypes.array,
}

export default Component
