export default class ContentfulReduxHelper {
  // measured in bytes, for heuristics only
  // adapted from https://stackoverflow.com/a/6351386/5204093
  static getRoughSizeOfObject(obj) {
    const objectList = []

    // this function must be defined as var or the recursion doesn't work
    var calculateSize = value => {
      let bytes = 0

      if (typeof value === 'boolean') {
        bytes = 4
      } else if (typeof value === 'string') {
        bytes = value.length * 2
      } else if (typeof value === 'number') {
        bytes = 8
      } else if (
        typeof value === 'object' &&
        objectList.indexOf(value) === -1
      ) {
        objectList[objectList.length] = value

        for (let i in value) {
          bytes += 8 // an assumed existence overhead
          bytes += calculateSize(value[i])
        }
      }

      return bytes
    }

    return calculateSize(obj)
  }
}
