import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { HeaderTwo } from 'secondstep-components'

export const PanelContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const PanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.25rem;
  padding: 1.5rem 1.25rem 1.25rem;
  border-radius: 0.625rem;
  background: ${themeGet('colors.white')};

  [class*='ResourceCardWrapper-sc']:not(:last-child) {
    margin-right: 1rem;
  }
`

export const StyledHeaderTwo = styled(HeaderTwo)``
