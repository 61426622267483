import { select, takeEvery, put } from 'redux-saga/effects'

import { get, createType } from 'lib/middleware/api'
import PreviewTokenManager from 'utils/previewTokenManager'

import { getHighestIncludeOption } from '../utils/index'

import ContenfulTypes from './types'
import ContentfulActions from './actions'

export const CONTENTFUL_PREVIEW_DOMAIN = 'preview.contentful.com'
export const CONTENTFUL_PROD_DOMAIN = 'cdn.contentful.com'

const PreviewTokenMngr = new PreviewTokenManager()

export const getEntry = (entryId, locale, preview, format, url, include) => {
  const urlParameters = { entryId, locale, preview, format, url, include }
  return ContentfulActions.setupContentfulGet(urlParameters)
}

export const makeContentfulGetCall = ({
  entryId,
  locale,
  preview,
  format,
  url,
  include,
  contentLifeCycleTypes,
}) => {
  const fullUrl = createEndpoint(entryId, locale, preview, format, url, include)
  const typesOrdered = contentLifeCycleTypes.map(type =>
    createType(type, { entryId, locale, include }),
  )

  return get(fullUrl, typesOrdered, process.env.CONTENTFUL_TOKEN)
}

export const createEndpoint = (
  entryId,
  locale,
  preview,
  format,
  url = '',
  include,
) => {
  const environment = preview
    ? CONTENTFUL_PREVIEW_DOMAIN
    : CONTENTFUL_PROD_DOMAIN
  const separator = url.includes('?') ? '&' : '?'
  const endpoint = `${url}${separator}${makeQueries(
    entryId,
    locale,
    environment,
    format,
    include,
  )}`
  return endpoint
}

export const makeQueries = (entryId, locale, environment, format, include) => {
  const environmentObject = environment ? { environment } : {}
  const cache = PreviewTokenMngr.getToken()

  const parameters = {
    entryId,
    format,
    include,
    locale,
    ...environmentObject,
  }

  // TODO for previewToken feature. Remove after we have a better solution
  if (cache) {
    parameters.cache = cache
  }

  return encodeQueryData(parameters)
}

export function encodeQueryData(data) {
  const ret = []

  for (let d in data) {
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]))
  }

  return ret.join('&')
}

// saga funcs
function* prepareContentfulGetCall(action) {
  const { urlParams, contentLifeCycleTypes } = action
  const { entryId, locale, preview, format, url, include } = urlParams

  const highestEntryWithInclude = yield select(state =>
    getHighestIncludeOption(state, entryId, locale),
  )
  const { include: highestInclude } = highestEntryWithInclude

  const finalInclude = highestInclude > include ? highestInclude : include

  yield put(
    makeContentfulGetCall({
      entryId,
      locale,
      preview,
      format,
      url,
      include: finalInclude,
      contentLifeCycleTypes,
    }),
  )
}

export function* watchForSetupContentfulGet() {
  yield takeEvery(ContenfulTypes.SETUP_CONTENTFUL_GET, prepareContentfulGetCall)
}
