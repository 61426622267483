import {
  LICENSE_TYPES,
  SELA_ADULT,
  SELA_LEADER,
  SELA_STAFF,
  SELA_ADMIN,
  REPORTS,
} from './constants'

// interface AccessClaim {
//    license: keyof LICENSE_TYPES,
//    role: 'leader' | 'staff' | 'admin' | 'reports' | 'family' | 'teacher'
// }

// Returns { license, role }[]
export const getStructuredClaims = fullAccessPayload => {
  if (!fullAccessPayload) {
    return []
  }

  const results = []
  for (const licenseType in LICENSE_TYPES) {
    const licenseTypeKey = LICENSE_TYPES[licenseType]
    const access = fullAccessPayload[licenseTypeKey]
    const hasClaims = getCheckFunction(licenseTypeKey)(access)
    const newClaims = Object.keys(hasClaims)
      .filter(role => hasClaims[role])
      .map(role => ({ license: licenseType, role }))
    results.push(...newClaims)
  }
  return results
}

export const getClaimsBasedOnAccess = fullAccessPayload => {
  const structuredClaims = getStructuredClaims(fullAccessPayload)
  return structuredClaims.reduce(
    (acc, claim) => {
      const { license, role } = claim
      if (license === 'ft') {
        acc[`hasFT${capitalizeFirstLetter(role)}Claim`] = true
      } else {
        const key = `has${capitalizeFirstLetter(
          license,
        )}${capitalizeFirstLetter(role)}Claim`
        acc[key] = true
      }

      return acc
    },
    {
      hasBpuAdminClaim: false,
      hasBpuFamilyClaim: false,
      hasBpuReportsClaim: false,
      hasBpuTeacherClaim: false,
      hasCpuAdminClaim: false,
      hasCpuFamilyClaim: false,
      hasCpuReportsClaim: false,
      hasCpuTeacherClaim: false,
      hasElemAdminClaim: false,
      hasElemCoordinatorClaim: true, // TODO: Not sure why this is set or where it is needed. Investigate.
      hasElemFamilyClaim: false,
      hasElemReportsClaim: false,
      hasElemTeacherClaim: false,
      hasFTLeaderClaim: false,
      hasFTReportsClaim: false,
      hasFTStaffClaim: false,
      hasHighschoolAdminClaim: false,
      hasHighschoolTeacherClaim: false,
      hasHighschoolReportsClaim: false,
      hasHighschoolFamilyClaim: false,
      hasMsAdminClaim: false,
      hasMsCoordinatorClaim: true,
      hasMsFamilyClaim: false,
      hasMsReportsClaim: false,
      hasMsTeacherClaim: false,
      hasSelaAdminClaim: false,
      hasSelaLeaderClaim: false,
      hasSelaReportsClaim: false,
      hasSelaStaffClaim: false,
    },
  )
}

export const capitalizeFirstLetter = str =>
  str.replace(/^[\s]*([a-z])/, (_, c) => c.toUpperCase())

export const getCheckFunction = forKey => {
  if (forKey.toLowerCase().includes('ft')) {
    return checkLicenseForFieldTestAccessClaims
  } else if (forKey.toLowerCase().includes('sela')) {
    return checkLicenseForSelaAccessClaims
  } else {
    return checkLicenseForAccessClaims
  }
}

export const checkLicenseForAccessClaims = license => {
  let hasAdminClaim = false
  let hasTeacherClaim = false
  let hasReportsClaim = false
  let hasFamilyClaim = false
  let hasCoordinatorClaim = false

  for (const grade in license) {
    // Get reference for current grade
    const currentGrade = license[grade]

    // Check for family access - license access is independent of all other roles
    const isFamily =
      currentGrade.family?.hasRole &&
      currentGrade.family?.licenseIds?.length > 0

    if (isFamily) {
      hasFamilyClaim = true
    }
    // If the user has already been marked as an admin, we can skip these checks
    if (!hasAdminClaim) {
      const isAdmin = hasValidClaim('admin', currentGrade)
      const isTeacher = hasValidClaim('teacher', currentGrade)
      const isCoordinator = hasValidClaim('coordinator', currentGrade)

      // If admin role found in current grade access, mark the user as an admin and a teacher for that license
      if (isAdmin) {
        hasAdminClaim = true
        hasTeacherClaim = true
        hasReportsClaim = true
        hasCoordinatorClaim = true

        // Already has admin privs, no need to check for teacher or reporting role as they are implied in Admin role
        break
      }

      const isReporting =
        hasValidClaim('reporting', currentGrade) ||
        hasValidClaim('reports', currentGrade)

      if (isReporting) {
        hasReportsClaim = true
      }

      // Admin role not found, check for Teacher role
      if (isTeacher) {
        hasTeacherClaim = true
      }

      if (isCoordinator) {
        hasCoordinatorClaim = true
      }
    }
  }
  return {
    admin: hasAdminClaim,
    teacher: hasTeacherClaim,
    reports: hasReportsClaim,
    family: hasFamilyClaim,
    coordinator: hasCoordinatorClaim,
  }
}

const hasValidClaim = (property, access) =>
  access[property]?.hasRole && access[property]?.licenseIds?.length > 0

const checkLicenseForFieldTestAccessClaims = license => {
  let hasFTLeaderClaim = false
  let hasFTStaffClaim = false
  let hasFTReportsClaim = false

  if (license) {
    const adult = license[SELA_ADULT]
    if (adult) {
      hasFTLeaderClaim = hasValidClaim(SELA_LEADER, adult)
      hasFTStaffClaim = hasValidClaim(SELA_STAFF, adult)
      hasFTReportsClaim = hasValidClaim(REPORTS, adult)
    }
  }

  return {
    leader: hasFTLeaderClaim,
    staff: hasFTStaffClaim,
    reports: hasFTReportsClaim,
  }
}

const checkLicenseForSelaAccessClaims = license => {
  let hasSelaStaffClaim = false
  let hasSelaLeaderClaim = false
  let hasSelaAdminClaim = false
  let hasSelaReportsClaim = false

  if (license) {
    const adultSela = license[SELA_ADULT]
    if (adultSela) {
      hasSelaStaffClaim = hasValidClaim(SELA_STAFF, adultSela)
      hasSelaLeaderClaim = hasValidClaim(SELA_LEADER, adultSela)
      hasSelaAdminClaim = hasValidClaim(SELA_ADMIN, adultSela)
      hasSelaReportsClaim = hasValidClaim(REPORTS, adultSela)
    }
  }

  return {
    staff: hasSelaStaffClaim,
    leader: hasSelaLeaderClaim,
    admin: hasSelaAdminClaim,
    reports: hasSelaReportsClaim,
  }
}
