import React from 'react'
import PropTypes from 'prop-types'

const LeaderDashboardIcon = ({ fill }) => (
  <svg height="18px" viewBox="0 0 18 18" width="18px">
    <title>ss_icon_leaderdashboard</title>
    <g
      fill="none"
      fillRule="evenodd"
      id="ss_icon_leaderdashboard"
      stroke="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1"
    >
      <g
        id="Group-17"
        stroke={fill}
        strokeWidth="2"
        transform="translate(1.000000, 2.000000)"
      >
        <line
          id="Line-5"
          transform="translate(1.258396, 7.032215) rotate(177.878904) translate(-1.258396, -7.032215) "
          x1="0.758396457"
          x2="1.25839646"
          y1="14.0322155"
          y2="0.532215497"
        />
        <line
          id="Line-5"
          transform="translate(8.258701, 14.014766) rotate(3.000000) translate(-8.258701, -14.014766) "
          x1="1.75870104"
          x2="15.2372917"
          y1="14.0147657"
          y2="13.2882236"
        />
        <polyline
          id="Path-5"
          points="5.10825672 10.3016941 7.00838215 6.75497308 11.5747195 7.87390454 15.2583965 3.20825204"
        />
      </g>
    </g>
  </svg>
)

LeaderDashboardIcon.propTypes = {
  fill: PropTypes.string,
}

export default LeaderDashboardIcon
