import { createAction } from 'redux-api-middleware'
import {
  CONTENTFUL_ENDPOINT,
  CONTENTFUL_IDS,
  CACHE_STORAGE_KEY,
  CFC_TEMP_STORAGE_KEY,
  MAX_FETCH_LEVELS,
  INCLUDE_ALLOWLIST,
  INCLUDE_ERROR,
} from 'config/constants'
import { wait } from 'utils/asyncUtils'
import Auth0Manager from 'auth/utils/auth0Manager'
import ContenfulTypes from 'layers/content/store/types'
import { FlagsToggle } from 'flags'
import * as jwt from 'jsonwebtoken'
import deepEqual from 'deep-equal'

export const CACHE_AUTH_TOKEN_HEADER = 'sstep-prev-auth-token'
export const CACHE_EXPIRATION_TIME = 1000 * 60 * 60 // 1000ms * 60 (1min) * 60 = total 60mins
export const CACHE_EXPIRATION_HEADER = 'sstep-fetched-on'
const RETRY_DELAY = 500
const ATTEMPT_LIMIT = 3 // keep limit to 3 to lessen time until failure for users. Otherwise we get very long loading screens
export class InProgressFetchesCache {
  progressCache = {}

  startedFetch = url => {
    this.progressCache[url] = true
  }

  finishedFetch = url => {
    delete this.progressCache[url]
  }

  inProgress = url => {
    const inProgressNow = this.progressCache[url] || false
    return inProgressNow
  }
}

export const inProgressFetches = new InProgressFetchesCache()

// Check cache api storage for cached response data and return it or store incoming response data in cache
// Caching using Service Worker Cache API, which we use from the browser window api
// https://developer.mozilla.org/en-US/docs/Web/API/Cache
export const get = (url, types) => {
  return createAction({
    endpoint: url,
    fetch: async (url, options) => {
      inProgressFetches.startedFetch(url)
      if (!isValidIncludeValue(types)) {
        const error = new Error(INCLUDE_ERROR)
        inProgressFetches.finishedFetch(url)
        throw error
      }
      try {
        let cache
        const canCache = await isCacheStorageAvailable()
        // Get authorization token and build custom headers
        const authToken = await getAuthorization()
        const newOptions = {
          ...options,
          cache: 'no-cache',
          headers: {
            Authorization: authToken,
            'Content-Type': 'application/json',
          },
        }
        // If this is an lms call, return the data immediately, no need to cache it
        if (isBlacklistUrl(url)) {
          return await fetchGet(url, newOptions, inProgressFetches)
        }

        if (canCache) {
          // Open cache connection
          cache = await window?.caches?.open(CACHE_STORAGE_KEY)

          // Look for matching url key in cache
          const cachedRes = await cache?.match(url)
          // Verify cached response found and that it hasn't expired
          if (cachedRes && isCacheValid(cachedRes, authToken)) {
            // Return cached response
            inProgressFetches.finishedFetch(url)
            return cachedRes
          }
        }

        // Cached response not found, make fetch call to Contentful
        const res = await fetchGet(url, newOptions, inProgressFetches)

        // There was something wrong with the fetch (i.e 401 unauthorized), don't cache the response data
        if (res.status !== 200) {
          return res
        }

        // Clone res data to store in cache
        const resToCache = await cloneRes(res, authToken)

        if (canCache) {
          // Store cloned res data with custom headers in cache
          await cache?.put(url, resToCache)
        }

        // Return original untouched response data
        return res
      } catch (error) {
        // Check to make sure quota not exceeded
        if (error.name === 'QuotaExceededError') {
          console.warn('Exceeded quota limit for cache api')
          // TODO: Look into proper way of handling this
        }
        inProgressFetches.finishedFetch(url)
        console.error(`There was an error when connecting to the API: ${error}`)
      }
    },
    bailout: () => {
      return inProgressFetches.inProgress(url)
    },
    method: 'GET',
    types,
  })
}

function createComparableToken(token) {
  return {
    'https://secondstep.org/group_membership':
      token['https://secondstep.org/group_membership'], // determine if this is a cfc user: "Technology Admins"
    'https://secondstep.org/email': token['https://secondstep.org/email'], // email
    'https://secondstep.org/ss_id': token['https://secondstep.org/ss_id'], // secondstep id, this is the id we use to identify users
    'https://secondstep.org/roles': token['https://secondstep.org/roles'], // "Admin", "Family", "Teacher"
    'https://secondstep.org/privileges':
      token['https://secondstep.org/privileges'], // "Admin", "Family", "Reports", "Teacher"
    iss: token.iss, // issuer
    sub: token.sub, // subject
    azp: token.azp, // authorized party
  }
}

export const isAuthTokenExpiredOrChanged = (
  previousAuthToken,
  currentAuthToken,
  curTime,
) => {
  if (!previousAuthToken || !currentAuthToken) return true
  previousAuthToken = previousAuthToken?.replace(/bearer /i, '') || null
  previousAuthToken = jwt.decode(previousAuthToken)
  currentAuthToken = currentAuthToken?.replace(/bearer /i, '') || null
  currentAuthToken = jwt.decode(currentAuthToken)
  const hasTokenExpired = previousAuthToken.exp < curTime
  currentAuthToken = createComparableToken(currentAuthToken)
  previousAuthToken = createComparableToken(previousAuthToken)

  return deepEqual(previousAuthToken, currentAuthToken) && !hasTokenExpired
}

export const getAuthorization = async () => {
  const accessToken = await Auth0Manager.getAccessToken()
  return `Bearer ${accessToken}`
}

// Compares timestamp of cached response with current time and verifies that 5 mins hasn't passed
export const isCacheValid = function(response, currentAuthToken) {
  if (!response) return false

  // Get custom headers for last fetched time and authorization token
  const lastFetched = response.headers.get(CACHE_EXPIRATION_HEADER)
  let previousAuthToken = response.headers.get(CACHE_AUTH_TOKEN_HEADER)

  // Has more time than passed than the cache expiration threshold
  const curTime = new Date().getTime()
  const hasCacheExpired =
    parseFloat(lastFetched) + CACHE_EXPIRATION_TIME < curTime

  // Remove 'bearer ' from auth token, decode token, compare non-changing values
  const shouldRefetchRequest = isAuthTokenExpiredOrChanged(
    previousAuthToken,
    currentAuthToken,
    curTime,
  )

  // If the response header expiration time hasn't expired and the auth token is still valid
  if (!hasCacheExpired && shouldRefetchRequest) {
    // Return the response that's in the cache
    return true
  }
  // Cache has expired or the token doesn't match, cached response is invalid
  // Return false and make a new fetchGet call
  return false
}

export const createType = (type, meta = {}) => ({
  meta,
  type,
})

export async function fetchGet(url, config, inProgressFetches, attempt = 0) {
  const delay = RETRY_DELAY * attempt

  try {
    if (attempt > 0) {
      await wait(delay)
    }

    const res = await fetch(url, config)
    inProgressFetches.finishedFetch(url)
    if (!res.ok && attempt < ATTEMPT_LIMIT) {
      const newAttempt = attempt + 1
      return fetchGet(url, config, inProgressFetches, newAttempt)
    }

    return res
  } catch (error) {
    if (attempt < ATTEMPT_LIMIT) {
      const newAttempt = attempt + 1

      return fetchGet(url, config, inProgressFetches, newAttempt)
    } else {
      throw error
    }
  }
}

// Response needs to be cloned because cache.put consumes the response body
export const cloneRes = async (res, authToken) => {
  // https://developer.mozilla.org/en-US/docs/Web/API/Response/clone
  const resCopy = res.clone()

  // Create a new http header using the original response headers
  // https://developer.mozilla.org/en-US/docs/Web/API/Headers/Headers
  const newHeaders = new Headers(resCopy.headers)

  // Add a custom/unique "sstep-fetched-on" header to keep track of when the response initiated
  newHeaders.append(CACHE_EXPIRATION_HEADER, new Date().getTime())
  // Add a custom/unique "ssBearer token to header
  newHeaders.append(CACHE_AUTH_TOKEN_HEADER, authToken)

  // Create a copy of the original response body
  // https://developer.mozilla.org/en-US/docs/Web/API/Body/blob
  const resBody = await resCopy.blob()

  // Create a new response object with the newly created headers and body
  // https://developer.mozilla.org/en-US/docs/Web/API/Response/Response
  const resWithNewHeaders = new Response(resBody, {
    headers: newHeaders,
    status: resCopy.status,
    statusText: resCopy.statusText,
  })

  // Return the cloned res data with custom headers
  return resWithNewHeaders
}

export const isBlacklistUrl = url => {
  const isContentfulUrl = url.includes(CONTENTFUL_ENDPOINT)
  const isBlacklistedContentfulId = CONTENTFUL_IDS.some(id => url.includes(id))

  const doNotCache = !isContentfulUrl || isBlacklistedContentfulId

  return doNotCache
}

const isCacheStorageAvailable = async () => {
  // Firefox is having problems right now to let the CacheStorage API
  // and some Service Worker features work correctly in Incognito mode. It throws
  // a SecurityError exception.
  // This function help us make sure we can use the CacheStorage API. Also, this looks like
  // the only way to do feature detention for this case
  // See: https://bugzilla.mozilla.org/show_bug.cgi?id=1117808
  //      https://developer.mozilla.org/en-US/docs/Web/API/DOMException.

  try {
    await window?.caches?.open(CFC_TEMP_STORAGE_KEY)
    await window?.caches?.delete(CFC_TEMP_STORAGE_KEY)
    return true
  } catch (error) {
    console.warn(
      'request cannot be cache because the CacheStorage API is not available',
    )
    return false
  }
}

export const isValidIncludeValue = types => {
  const toggledFn = FlagsToggle({
    flagSubcriptions: ['feature_LEARN-13227_contentful-include-limit'],
    onCode: on_isValidIncludeValue,
    offCode: () => true,
    onCodeArgs: [types],
  })

  return toggledFn()
}

const on_isValidIncludeValue = types => {
  const contentfulRequestsWithMoreLevelsThanRecommended = types.filter(
    t =>
      t.meta?.include > MAX_FETCH_LEVELS &&
      t.type === ContenfulTypes.CONTENTFUL_REQUEST,
  )

  let allowRequest = true

  contentfulRequestsWithMoreLevelsThanRecommended.forEach(c => {
    const index = INCLUDE_ALLOWLIST.findIndex(
      w => w.entryId === c.meta?.entryId && w.includeLimit === c.meta?.include,
    )
    if (index === -1) {
      allowRequest = false
      console.error(`EntryId ${c.meta.entryId} has ${c.meta.include} includes.`)
    }
  })

  return allowRequest
}
