import NavigationHelper from 'layers/navigation/navigationHelper'
import ContentfulParser from 'utils/contentfulParser/contents'
import { LESSON_MATTERS_TITLE } from './constants'

export const mapper = (entry, location) => {
  const { bbPrepVideo = [], content = [] } = entry || {}
  const formattedBBPrepVideo = bbPrepVideo.map(video =>
    ContentfulParser.parseMedia(video),
  )
  let streamingLessonMedia
  let lessonScript

  const pathname = ContentfulParser.parsePathname(location)
  const depth = pathname.split('/').length - 1

  content.forEach(entry => {
    const contentType = ContentfulParser.parseContentType(entry)
    const slug = ContentfulParser.parseSlug(entry)
    const route = NavigationHelper.generateUrlWithDepthForRoute(
      pathname,
      slug,
      depth,
    )

    if (contentType === 'slm') {
      streamingLessonMedia = route
    } else if (contentType === 'article') {
      lessonScript = route
    }
  })

  return {
    ...entry,
    bbPrepVideo: formattedBBPrepVideo,
    streamingLessonMedia,
    lessonScript,
    summaryTitle: LESSON_MATTERS_TITLE,
  }
}
