import ContentServiceClient from '@cfc/content-service-sdk'
import { formatError } from 'utils/formatError'
import { ENVIRONMENT_SUBDOMAIN } from 'config/env'
import { actions } from './reducer'

// piggy back off of already set envs for old contentful system for preview vs publish modes
// staging and prod are FALSE for preview mode
const defaultContentfulPreviewMode = process.env.CONTENTFUL_PREVIEW

const fetchContent = ({
  entryId,
  queryType,
  locale,
  isPreview,
  userContext,
}) => {
  return async dispatch => {
    dispatch(actions.fetchingContent())

    try {
      const contentServiceClient = new ContentServiceClient({
        environment: ENVIRONMENT_SUBDOMAIN,
      })
      const data = await contentServiceClient.getContent({
        entryId,
        queryType,
        locale,
        isPreview: isPreview || defaultContentfulPreviewMode,
        userContext,
      })

      // stringify userContext to compare later
      const stringUserContext = JSON.stringify(userContext)

      dispatch(
        actions.fetchContentSuccess({
          entryId,
          data,
          userContext: stringUserContext,
          locale,
        }),
      )
    } catch (error) {
      dispatch(actions.fetchContentError({ error: formatError(error) }))
    }
  }
}

const clearErrors = () => {
  return async dispatch => {
    dispatch(actions.clearErrors())
  }
}

export default {
  fetchContent,
  clearErrors,
}
