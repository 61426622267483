import { connect } from 'react-redux'
import Component from './component'

import NavigationHelper from '../../layers/navigation/navigationHelper'

const mapper = router => {
  const { location } = router || {}
  const { hash } = location || {}
  return { currentHash: hash }
}

const spread = true

export default connect(
  NavigationHelper.mapStateToProps(mapper, spread),
  null,
  null,
  { pure: false },
)(Component)
