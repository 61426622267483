import React, { useState } from 'react'
import PropTypes from 'prop-types'
import NavigationHelper from 'layers/navigation/navigationHelper'
import ClosableModal from 'experienceComponents/ClosableModal'
import VideoDataGetter from 'experienceComponents/VideoDataGetter'
import Hoverable from 'components/Hoverable'
import { variables } from 'themes'
import { Body, Container, Header, IconWrapper } from './component.styles'
import WelcomeGuideArrowIcon from './assets/WelcomeGuideArrowIcon'

// export for testability
export const determineNavigationType = linkInfo => {
  const isRelativeLink = linkInfo.url.startsWith('/')
  if (linkInfo.openInSameTab) {
    if (isRelativeLink) {
      return NavigationHelper.types.PUSH
    } else {
      return NavigationHelper.types.OPEN
    }
  } else {
    if (isRelativeLink) {
      return NavigationHelper.types.NEW_TAB_SAME_SITE
    } else {
      return NavigationHelper.types.NEW_TAB
    }
  }
}

// export for testability
export const handleLinkClick = (
  linkInfo,
  navigationEvent,
  setShowVideoModal,
) => {
  if (linkInfo.isVideo) {
    setShowVideoModal(true)
    return
  }
  navigationEvent(linkInfo.url, determineNavigationType(linkInfo))
}

const AdminWelcomeBlock = ({ linkInfo, navigationEvent, text, title }) => {
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [hovered, setHovered] = useState(false)

  const onHover = hover => {
    setHovered(hover)
  }

  return (
    <Container>
      <Hoverable onHover={onHover}>
        <Header
          onClick={() => {
            handleLinkClick(linkInfo, navigationEvent, setShowVideoModal)
          }}
        >
          {title}
          <IconWrapper>
            <WelcomeGuideArrowIcon
              stroke={
                hovered ? variables.colors.navyBlue : variables.colors.blue
              }
            />
          </IconWrapper>
        </Header>
      </Hoverable>
      <Body>{text}</Body>
      <ClosableModal
        onClose={() => setShowVideoModal(false)}
        show={showVideoModal}
        showClose
      >
        {linkInfo?.videoEntryId && linkInfo?.videoLocale && (
          <VideoDataGetter
            entryId={linkInfo?.videoEntryId}
            locale={linkInfo?.videoLocale}
          />
        )}
      </ClosableModal>
    </Container>
  )
}

AdminWelcomeBlock.propTypes = {
  linkInfo: PropTypes.shape({
    isVideo: PropTypes.bool,
    openInSameTab: PropTypes.bool,
    url: PropTypes.string,
    // only set if isVideo is true:
    videoEntryId: PropTypes.string,
    // only set if isVideo is true:
    videoLocale: PropTypes.string,
  }),
  navigationEvent: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
}

export default AdminWelcomeBlock
