import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'
import { HeaderFive, ModalContentWrapper } from 'secondstep-components'
import ClosableModal from 'experienceComponents/ClosableModal'

export const Wrapper = styled(Button).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: column;
  height: 5.45rem;
  width: 18.4375rem;
  border-radius: 0.625rem;
  padding: 0.8125rem 1.25rem 0.625rem;
  background-color: ${themeGet('colors.paleGray')};

  ${HeaderFive} {
    font-size: 1.0625rem;
    color: ${themeGet('colors.darkBlue')};
    font-family: ${themeGet('fontFamilyBold')};
    font-size: 1.0625rem;
  }

  svg {
    margin-bottom: 0.125rem;
  }
`

export const TrainingStatus = styled.span`
  height: 0.9375rem;
  width: 0.9375rem;
  margin-right: 0.4375rem;
  margin-bottom: 0.25rem;
  border-radius: 50%;
  background-color: ${props => props.statusColor};
`

export const MindYetiContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    margin-top: 0.19rem;
  }
  :hover {
    svg {
      g {
        path {
          fill: ${themeGet('colors.mindyeti.blue2')};
        }
      }
    }
    p {
      color: ${themeGet('colors.mindyeti.blue2')};
    }
  }
`

export const MindYetiSpacer = styled.div`
  width: 100%;
  height: 2px;
`

export const MindYetiText = styled.p`
  margin-left: 0.4rem;
  margin-top: 0.465rem;
  color: ${themeGet('colors.mindyeti.blue1')};
  font-family: ${themeGet('font.molde.medium')};
  font-weight: 400;
  letter-spacing: 0;
  line-height: 1.063rem;
`

export const StyledModal = styled(ClosableModal)`
  ${ModalContentWrapper} {
    height: 100%;
    padding: 2.8125rem 1.75rem 1.75rem;
    width: 100%;
  }
`
