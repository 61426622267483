// we apply throttling to these operations to avoid observed issues...
// where the loading state is set too frequently and causes max-depth state update errors
import { throttle } from 'lodash'
import actions from './actions'

const setIsLoadingState = loadingText => {
  return async dispatch => {
    dispatch(actions.setAppLoading(loadingText))
  }
}

const setNotLoadingState = () => {
  return async dispatch => {
    dispatch(actions.setAppNotLoading())
  }
}

const throttledSetIsLoadingState = throttle(
  (dispatch, loadingText) => {
    dispatch(setIsLoadingState(loadingText))
  },
  // Throttle to once every 500ms (0.5 seconds):
  500,
  // Skip the trailing call which could have caused delayed invocations of showing the loading screen...
  // that could in turn result in an infinite loading spinner:
  { leading: true, trailing: false },
)

const throttledLoadingState = loadingText => {
  return dispatch => {
    throttledSetIsLoadingState(dispatch, loadingText)
  }
}

export default {
  // map the original operation names to the throttled versions so no usage updates are needed:
  setIsLoadingState: throttledLoadingState,
  // DON'T throttle not loading, so there's never a case the loading screen should be hidden that we're missing
  setNotLoadingState,
}
