import { createSlice } from '@reduxjs/toolkit'
import selectors from './selectors'

const defaultLessonReportData = {}
const initialLessonReports = {
  isFetchingLessonReport: false,
  lessonReportData: defaultLessonReportData,
}

const defaultHistoricalReportData = {}
const initialHistoricalReports = {
  isFetchingHistoricalReportData: false,
  historicalReportData: defaultHistoricalReportData,
}

export const initialState = {
  error: null,
  instructorData: null,
  isCreated: false,
  isCreating: false,
  isDeleting: false,
  isFetching: false,
  isFetchingOrCreatingProgramFlex: false,
  isFetchingV2: false,
  isUpdating: false,
  historicalReports: initialHistoricalReports,
  lessonReports: initialLessonReports,
  selectedCourseInstance: null,
  trainingQuestions: null,
  seedIds: null,
}

const reducers = {
  clearErrors: (state, action) => {
    state.error = null
  },

  creatingCourseInstance: (state, action) => {
    state.error = null
    state.isCreating = true
  },

  creatingCourseInstanceError: (state, { payload }) => {
    const { error } =
      payload ||
      'There was a problem creating your class. Please try again later.'
    state.error = error
    state.isCreating = false
    state.isCreated = false
  },

  creatingCourseInstanceSuccess: (state, { payload }) => {
    const { courseInstance, programLmsKey } = payload

    if (!state.instructorData) {
      state.instructorData = {}
      state.instructorData[programLmsKey] = []
    }

    state.instructorData[programLmsKey].unshift(courseInstance)
    state.error = null
    state.isCreating = false
    state.isCreated = true
  },

  creatingTrainingRegistration: (state, action) => {
    state.error = null
    state.isUpdating = true
  },

  creatingTrainingRegistrationError: (state, { payload }) => {
    const { error } = payload
    state.error = error
    state.isUpdating = false
  },

  creatingTrainingRegistrationSuccess: (state, { payload }) => {
    state.error = null
    state.isUpdating = false
  },

  deletingCourseCreatedStatus: (state, action) => {
    state.isCreated = false
  },

  deletingCourseInstance: (state, action) => {
    state.error = null
    state.isDeleting = true
    state.isCreated = false
  },

  deletingCourseInstanceError: (state, { payload }) => {
    const { error } =
      payload ||
      'There was a problem deleting your class. Please try again later.'
    state.error = error
    state.isDeleting = false
    state.isCreated = false
  },

  deletingCourseInstanceSuccess: (state, action) => {
    state.error = false
    state.isDeleting = false
    state.isCreated = false
  },

  deletingLessonProgressReport: (state, action) => {
    state.lessonReports = initialLessonReports
  },

  deletingHistoricalReports: (state, action) => {
    state.historicalReports = initialHistoricalReports
  },

  fetchingInstructorData: (state, action) => {
    const isFetching = !state.instructorData
    state.isFetching = isFetching
    state.error = null
  },

  fetchingInstructorDataError: (state, { payload }) => {
    const { error } = payload
    state.error = error || payload
    state.isFetching = false
  },

  fetchingInstructorDataSuccess: (state, { payload }) => {
    const lmsData = payload

    const lmsKeys = ['Classes', 'Elementary']
    lmsKeys.forEach(lmsKey => {
      lmsData[lmsKey] = selectors.sortClasses(lmsData[lmsKey])
      lmsData[lmsKey] = lmsData[lmsKey].map(course =>
        selectors.attachLessonCountToUnits(course),
      )
    })

    state.instructorData = { ...state.instructorData, ...lmsData }
    state.isFetching = false
    state.error = null
  },

  fetchingInstructorDataV2: (state, action) => {
    const isFetching =
      !state.instructorData?.HighSchool || !state.instructorData?.MiddleSchool
    state.isFetchingV2 = isFetching
    state.error = null
  },

  fetchingInstructorDataV2Error: (state, { payload }) => {
    const { error } = payload
    state.error = error || payload
    state.isFetchingV2 = false
  },

  fetchingInstructorDataV2Success: (state, { payload }) => {
    const lmsData = payload
    const lmsKeys = ['HighSchool', 'MiddleSchool']
    lmsKeys.forEach(lmsKey => {
      lmsData[lmsKey] = selectors.sortClasses(lmsData[lmsKey])
      lmsData[lmsKey] = lmsData[lmsKey].map(course =>
        selectors.attachLessonCountToUnits(course),
      )
    })

    if (
      Array.isArray(lmsData?.HighSchool) &&
      lmsData?.HighSchool?.length === 0 &&
      !!state?.instructorData?.HighSchool
    ) {
      // handle a case where /api/v2/instructor/ms is returning an empty array for HighSchool...
      // which may be overwriting real high school data:
      delete lmsData.HighSchool
    }

    state.instructorData = { ...state.instructorData, ...lmsData }
    state.isFetchingV2 = false
    state.error = null
  },

  fetchingOrCreatingInstructorProgramFlex: state => {
    state.isFetchingOrCreatingProgramFlex = true
    state.error = null
  },

  fetchingOrCreatingInstructorProgramFlexError: (state, { payload }) => {
    const { error } = payload
    state.error = error || payload
    state.isFetchingOrCreatingProgramFlex = false
  },

  fetchingOrCreatingInstructorProgramFlexSuccess: (state, { payload }) => {
    const lmsData = payload
    state.instructorData = {
      ...state.instructorData,
      ...lmsData,
    }
    state.isFetchingOrCreatingProgramFlex = false
    state.error = null
  },

  fetchingLessonProgressReport: (state, action) => {
    state.lessonReports.isFetchingLessonReport = true
  },

  fetchingLessonProgressReportError: (state, { payload }) => {
    const { error } =
      payload ||
      'There was a problem creating your class. Please try again later.'

    state.error = error
    state.lessonReports.isFetchingLessonReport = false
  },

  fetchingLessonProgressReportSuccess: (state, { payload }) => {
    const { lessonReportData, licenseId } = payload
    state.lessonReports.lessonReportData[licenseId] = lessonReportData
    state.error = null
    state.lessonReports.isFetchingLessonReport = false
  },

  fetchingHistoricalReportData: (state, action) => {
    state.historicalReports.isFetchingHistoricalReportData = true
  },

  fetchingHistoricalReportDataError: (state, { payload }) => {
    const { error } =
      payload ||
      'There was a problem fetching historical report data. Please try again later.'
    state.error = error
    state.historicalReports.isFetchingHistoricalReportData = false
  },

  fetchingHistoricalReportDataSuccess: (state, { payload }) => {
    const { historicalReportData, licenseId } = payload
    state.historicalReports.historicalReportData[
      licenseId
    ] = historicalReportData
    state.error = null
    state.historicalReports.isFetchingHistoricalReportData = false
  },

  fetchingTrainingQuestions: (state, action) => {
    const isFetching = !state.trainingQuestions
    state.isFetching = isFetching
    state.error = null
  },

  fetchingTrainingQuestionsError: (state, { payload }) => {
    const { error } =
      payload ||
      'There was a problem retrieving training data. Please try again later.'
    state.error = error
    state.isFetching = false
  },

  fetchingTrainingQuestionsSuccess: (state, { payload }) => {
    const { trainingQuestions } = payload
    state.trainingQuestions = trainingQuestions
    state.error = null
    state.isFetching = false
  },

  makingAttempt: (state, action) => {
    state.error = null
    state.isUpdating = true
  },

  makingAttemptError: (state, { payload }) => {
    const { error } =
      payload ||
      'There was a problem submitting your attempt. Please try again later.'
    state.error = error
    state.isUpdating = false
  },

  makingAttemptSuccess: (state, action) => {
    state.error = null
    state.isUpdating = false
  },

  resetCourseInstances: state => initialState,

  selectCourseInstance: (state, { payload }) => {
    const { instanceId } = payload

    const selectedCourse =
      (instanceId &&
        Object.values(state.instructorData)
          .flat()
          .find(course => course.instanceId === instanceId)) ||
      null

    state.selectedCourseInstance = selectedCourse
  },

  updatingCourseInstance: (state, action) => {
    state.error = null
    state.isUpdating = true
  },

  updatingCourseInstanceError: (state, { payload }) => {
    const { error } =
      payload ||
      'There was a problem updating your class. Please try again later.'
    state.error = error
    state.isUpdating = false
  },

  updatingCourseInstanceSuccess: (state, { payload }) => {
    const { courseInstance } = payload
    const { Classes, Elementary, MiddleSchool = [] } = state.instructorData

    updateTitleOnExistingClass(Classes, courseInstance)
    updateTitleOnExistingClass(Elementary, courseInstance)
    updateTitleOnExistingClass(MiddleSchool, courseInstance)
    state.error = null
    state.isUpdating = false
  },
  fetchSeedIds: (state, { payload }) => {
    state.error = null
    state.isFetching = true
  },
  fetchSeedIdsError: (state, { payload }) => {
    const { error } =
      payload || ' There was a problem fetching data. Please try again later.'

    state.error = error
    state.isFetching = false
  },
  fetchSeedIdsSuccess: (state, { payload }) => {
    state.seedIds = payload
    state.error = null
    state.isFetching = false
  },
}
const slice = createSlice({ initialState, name: 'lms', reducers })

export const actions = slice.actions
export default slice.reducer

const updateTitleOnExistingClass = (array, { instanceId, title: newTitle }) => {
  if (!array) return

  const index = array.findIndex(
    currentClass => currentClass.instance === instanceId,
  )
  if (index !== -1) {
    array[index].title = newTitle
  }
}
