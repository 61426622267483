import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const ErrorContainer = styled.div`
  align-items: center;
  color: ${themeGet('colors.darkGray')};
  display: flex;
  flex-direction: column;
  margin-top: 1.75rem;
  font-size: 0.875rem;
  line-height: 1.125rem;
  font-family: ${themeGet('font.molde.medium')};

  div {
    align-self: self-start;
  }
`

export const IconTextContainer = styled.div`
  align-items: center;
  display: flex;
  gap: 5px;

  svg {
    height: 13px;
    margin-top: -0.0625rem;
    width: 13px;

    circle:first-child {
      fill: ${themeGet('colors.darkGray')};
    }
  }
`
