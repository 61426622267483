export const BAR_CHART_TITLE = 'Student Activities'
export const EDUCATOR_PRACTICES_PROGRESS_BAR_LABEL = 'Educator Practices'
export const HEADER_TITLE = 'Pathway Progress'
export const PACING_SCHEDULE_LINK_TEXT = 'Edit Pacing Schedule'
export const SCHOOLWIDE_PRACTICES_PROGRESS_BAR_LABEL = 'Schoolwide Practices'
export const TAB_FOUR_LABEL = 'Pathway 4'
export const TAB_FOUR_HEADER_TITLE = 'Pathway 4: Well-Being & Community'
export const TAB_ONE_LABEL = 'Pathway 1'
export const TAB_ONE_HEADER_TITLE = 'Pathway 1: Belonging & Connection'
export const TAB_THREE_LABEL = 'Pathway 3'
export const TAB_THREE_HEADER_TITLE = 'Pathway 3: Agency & Opportunity'
export const TAB_TWO_LABEL = 'Pathway 2'
export const TAB_TWO_HEADER_TITLE = 'Pathway 2: Confidence & Capability'
