import React from 'react'
import PropTypes from 'prop-types'
import { StyledCloseButton } from './component.styles'

const CloseButton = ({ handleClose }) => (
  <StyledCloseButton
    dataTestId="step-modal-close-button"
    id="step-modal-close-button"
    onClick={handleClose}
  />
)

CloseButton.propTypes = {
  handleClose: PropTypes.func,
}

export default CloseButton
