import React from 'react'
import PropTypes from 'prop-types'
import { BodyCopy } from 'secondstep-components'
import { StyledHeaderTwo } from './component.styles'

const AdminFlexibleContentHeader = ({
  description,
  displayTitle,
  tooltips,
}) => {
  return (
    <>
      <StyledHeaderTwo>{displayTitle}</StyledHeaderTwo>
      {description && <BodyCopy>{description}</BodyCopy>}
    </>
  )
}
AdminFlexibleContentHeader.propTypes = {
  description: PropTypes.string,
  displayTitle: PropTypes.string,
  tooltips: PropTypes.array,
}
export default AdminFlexibleContentHeader
