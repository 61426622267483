import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  BodyCopy,
  ContainedButton,
  HeaderFive,
  NotificationBellIcon,
} from 'secondstep-components'
import { BUTTON_TEXT, BODY_TEXT, HEADER_TITLE } from './constants'
import {
  ButtonsWrapper,
  NotificationWrapper,
  TextWrapper,
  TopWrapper,
} from './component.styles'
import MSSupplementalUnitModal from 'components/MSSupplementalUnitModal'

const MspBullyingUnitNotification = ({ mspSiteContexts }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => setIsModalOpen(!isModalOpen)
  const handleCloseModal = () => setIsModalOpen(false)

  return (
    <NotificationWrapper data-testid="bullying-unit-notification">
      <TopWrapper>
        <TextWrapper>
          <NotificationBellIcon />
          <HeaderFive>{HEADER_TITLE}</HeaderFive>
        </TextWrapper>
        <BodyCopy>{BODY_TEXT}</BodyCopy>
      </TopWrapper>
      <ButtonsWrapper>
        <ContainedButton onClick={() => handleOpenModal()}>
          {BUTTON_TEXT}
        </ContainedButton>
      </ButtonsWrapper>
      {isModalOpen && (
        <MSSupplementalUnitModal
          handleClose={handleCloseModal}
          mspSiteContexts={mspSiteContexts}
        />
      )}
    </NotificationWrapper>
  )
}

MspBullyingUnitNotification.propTypes = {
  mspSiteContexts: PropTypes.array,
}

export default MspBullyingUnitNotification
