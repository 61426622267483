import styled from 'styled-components'
import { Button } from 'grommet'
import { themeGet } from 'styled-system'
import { HeaderTwo } from 'secondstep-components'

const below735 = '@media (max-width: 735px)'

export const Wrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  padding: ${themeGet('spacing.red40_2_5rem')};
  padding-top: calc(${themeGet('spacing.red40_2_5rem')} - 0.3125rem);
  width: calc(100% - 2 * ${themeGet('spacing.red40_2_5rem')});
  background-color: ${themeGet('colors.white')};
  border-radius: 0.625rem;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-width: 16.875rem;

  ${themeGet('breakpoints.tablet')} {
    min-width: 14.375rem;
  }
  ${below735} {
    width: calc(100% - 2 * ${themeGet('spacing.amber28_1_75rem')});
    padding: ${themeGet('spacing.amber28_1_75rem')};
    padding-top: calc(${themeGet('spacing.amber28_1_75rem')} - 0.3125rem);
  }
`

export const Label = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin: 0;
  margin-bottom: calc(${themeGet('spacing.amber28_1_75rem')} - 1.0625rem);
  padding: 0;
  color: ${themeGet('colors.primaryCopy')};
  font-family: ${themeGet('fonts.label')};
  font-size: 1.125rem;
  line-height: 1.625rem;

  ${below735} {
    margin-top: 0;
  }
`

export const Title = styled(HeaderTwo).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin: 0;
  padding: 0;
  color: ${themeGet('colors.primaryCopy')};
  font-family: ${themeGet('fonts.resourceHeader')};
  font-size: 1.25rem !important;
  line-height: 1.625rem;
  text-align: center;
`

export const CompleteTitle = styled(Title)`
  padding-top: calc(${themeGet('spacing.blue16_1rem')} - 0.3125rem);
`

export const Time = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin: 0;
  margin-top: calc(${themeGet('spacing.blue16_1rem')} - 0.8125rem);
  color: ${themeGet('colors.primaryCopy')};
  font-family: ${themeGet('fonts.bodyCopy')};
  font-size: 1rem;
  line-height: 1.625rem;
`

export const ButtonRow = styled.div`
  margin: 0;
  margin-top: calc(${themeGet('spacing.amber28_1_75rem')} - 0.5625rem);
  display: flex;
  flex-direction: row;
  padding: 0 ${themeGet('spacing.turquoise20_1_25rem')};

  ${below735} {
    flex-direction: column;
  }
`

export const StaffButton = styled(Button).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin: 0;
  border: none;
  border-radius: 1.3438rem;
  min-width: 9.125rem;
  height: 2.5rem;
  background-color: ${themeGet('colors.sela.teal1')};
  color: ${themeGet('colors.white')};
  font-family: ${themeGet('fonts.buttonText')};

  &:hover {
    background: ${themeGet('colors.sela.gray1')};
    box-shadow: none;
  }
`

export const LeaderButton = styled(StaffButton).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  background-color: ${themeGet('colors.sela.purple1')};
  margin-right: ${themeGet('spacing.yellow12_0_75rem')};
  font-family: ${themeGet('fonts.buttonText')};

  :only-child {
    margin-right: 0;
  }

  &:hover {
    background: ${themeGet('colors.sela.gray1')};
    box-shadow: none;
  }
  ${below735} {
    margin-right: 0;
    margin-bottom: ${themeGet('spacing.yellow12_0_75rem')};
  }
`
