import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { operations as docServiceOperations } from 'store/docServiceManager'
import {
  operations as lmsOperations,
  selectors as lmsSelectors,
} from 'store/lmsManager'
import { selectors as licenseSelectors } from 'store/licenseManager'
import {
  operations as reportsOperations,
  selectors as reportsSelectors,
} from 'store/reportsManager'
import { createGradeOptions, createSiteOptions } from './helpers'
import { LESSON_TYPE } from './constants'
import ReportsNavHeader from './component'

export const ReportsNavHeaderContainer = ({
  classesCount,
  clearDocumentUrl,
  deleteHistoricalReports,
  deleteLessonProgressReport,
  documentUrl,
  fetchDocumentUrl,
  fetchHistoricalReports,
  grades = [],
  historicalReportData,
  isFetchingDocumentUrl,
  isFetchingHistoricalReportData,
  k8LicenseIdForSite,
  lmsError,
  navigationHandler,
  schoolCsvDownloadTooltip,
  selectedGrade,
  selectedProgram,
  selectedSite,
  showGradeDropdown,
  siteHistoricalReports,
  sites = [],
  updateSelectedGrade,
  updateSelectedSite,
}) => {
  useEffect(() => {
    if (documentUrl) {
      const downloadLink = document.createElement('a')
      downloadLink.href = documentUrl
      document.body.appendChild(downloadLink)
      downloadLink.click()
      document.body.removeChild(downloadLink)
      clearDocumentUrl()
    }
  }, [documentUrl])

  useEffect(() => {
    if (!k8LicenseIdForSite || !selectedSite) return
    if (
      !historicalReportData[k8LicenseIdForSite] &&
      !isFetchingHistoricalReportData &&
      !lmsError
    ) {
      fetchHistoricalReports(k8LicenseIdForSite, LESSON_TYPE)
    }
  }, [historicalReportData, k8LicenseIdForSite, lmsError, selectedSite])

  useEffect(() => {
    const cleanUp = () => {
      deleteHistoricalReports()
      deleteLessonProgressReport()
    }

    return cleanUp
  }, [])

  const gradeOptions = createGradeOptions(grades)
  const siteOptions = createSiteOptions(sites)
  const selectedGradeOption = gradeOptions.find(
    ({ key }) => key === selectedGrade,
  )
  const selectedSiteOption = siteOptions.find(({ key }) => key === selectedSite)

  const onClickCurrentYear = () => {
    fetchDocumentUrl({ siteId: selectedSite, program: selectedProgram?.key })
  }

  const historicalReportingOnClick = (data, filename) => {
    const csvdata = lmsSelectors.createLessonReportDownloadData(
      data,
      k8LicenseIdForSite,
      filename,
    )
    return csvdata
  }

  return (
    <ReportsNavHeader
      classesCount={classesCount}
      error={lmsError}
      grades={gradeOptions}
      historicalReportingOnClick={historicalReportingOnClick}
      historicalReports={siteHistoricalReports}
      isFetchingDocumentUrl={isFetchingDocumentUrl}
      isFetchingHistoricalReportData={isFetchingHistoricalReportData}
      navigationHandler={navigationHandler}
      onClickCurrentYear={onClickCurrentYear}
      schoolCsvDownloadTooltip={schoolCsvDownloadTooltip}
      selectedGradeOption={selectedGradeOption}
      selectedSite={selectedSite}
      selectedSiteOption={selectedSiteOption}
      showGradeDropdown={showGradeDropdown}
      sites={siteOptions}
      updateSelectedGrade={updateSelectedGrade}
      updateSelectedSite={updateSelectedSite}
    />
  )
}

const mapStateToProps = state => {
  const {
    docServiceManager,
    lmsManager,
    reportsManager,
    router,
    userProfileManager,
  } = state
  const { documentUrl, isFetching: isFetchingDocumentUrl } =
    docServiceManager || {}
  const { pathname } = router?.location || {}
  const activeAdminSites = licenseSelectors.selectActiveAdminK8Sites(state)
  const { historicalReportData, isFetchingHistoricalReportData } =
    lmsManager?.historicalReports || {}
  const { k8Reports, selectedGrade, selectedSite, selectedProgram } =
    reportsManager || {}
  const { email } = userProfileManager?.profile || {}

  let k8LicenseIdForSite = null
  const siteData = activeAdminSites?.find(site => site.id === selectedSite)

  const { licenses } = siteData || {}
  const k8License = licenses?.find(l => {
    const expiredLicense = l.isExpired || l.cancelledDate
    if (expiredLicense) return false
    const { programCodes } = l.product
    return (
      (programCodes.includes('K5P') && programCodes.includes('K8P')) ||
      programCodes.includes('MSP')
    )
  })
  k8LicenseIdForSite = k8License?.id

  const siteHistoricalReports = historicalReportData[k8LicenseIdForSite] || []
  const { classesCount } =
    k8Reports?.[selectedSite]?.gradeDetails?.[selectedGrade] || {}

  const { allGrades } = reportsSelectors.selectSchoolAllGrades(state) || {}
  const showGradeDropdown = pathname.includes('/grade-')

  return {
    classesCount,
    documentUrl,
    grades: allGrades,
    historicalReportData,
    isFetchingDocumentUrl,
    isFetchingHistoricalReportData,
    lmsError: lmsManager.error,
    siteHistoricalReports,
    selectedGrade,
    selectedProgram,
    selectedSite,
    showGradeDropdown,
    sites: activeAdminSites,
    k8LicenseIdForSite,
    userEmail: email,
  }
}

const mapDispatchToProps = {
  clearDocumentUrl: docServiceOperations.clearDocumentUrl,
  deleteHistoricalReports: lmsOperations.deleteHistoricalReports,
  deleteLessonProgressReport: lmsOperations.deleteLessonProgressReport,
  fetchDocumentUrl: docServiceOperations.fetchDocumentUrl,
  fetchHistoricalReports: lmsOperations.fetchHistoricalReports,
  updateSelectedGrade: reportsOperations.updateSelectedGrade,
  updateSelectedSite: reportsOperations.updateSelectedSite,
}

ReportsNavHeaderContainer.propTypes = {
  classesCount: PropTypes.number,
  clearDocumentUrl: PropTypes.func,
  deleteHistoricalReports: PropTypes.func,
  deleteLessonProgressReport: PropTypes.func,
  documentUrl: PropTypes.string,
  fetchDocumentUrl: PropTypes.func,
  fetchHistoricalReports: PropTypes.func,
  grades: PropTypes.arrayOf(PropTypes.object),
  historicalReportData: PropTypes.object,
  isFetchingDocumentUrl: PropTypes.bool,
  isFetchingHistoricalReportData: PropTypes.bool,
  k8LicenseIdForSite: PropTypes.number,
  lmsError: PropTypes.object,
  navigationHandler: PropTypes.func,
  schoolCsvDownloadTooltip: PropTypes.string,
  selectedGrade: PropTypes.string,
  selectedProgram: PropTypes.object,
  selectedSite: PropTypes.number,
  showGradeDropdown: PropTypes.bool,
  siteHistoricalReports: PropTypes.array,
  sites: PropTypes.arrayOf(PropTypes.object),
  updateSelectedGrade: PropTypes.func,
  updateSelectedSite: PropTypes.func,
  userEmail: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsNavHeaderContainer)
