import { useSelector } from 'react-redux'
import { selectors } from 'auth/stores/userContext'
import { extractUserContextOverride } from 'utils/productHelpers'

export function useUserContext() {
  const userContextOverride = extractUserContextOverride()
  if (userContextOverride) {
    try {
      return JSON.parse(atob(userContextOverride))
    } catch (e) {
      console.warn(
        'Failed to parse base64-encoded userContextOverride',
        userContextOverride,
        'error:',
        e,
      )
    }
  }
  return useSelector(selectors.selectProgramContext)
}
