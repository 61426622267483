export default str => {
  if (!str) {
    return 0
  }
  const num = parseInt(str.match(/\d+/), 10)
  if (isNaN(num)) {
    return 0
  }
  return num
}
