import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { HeaderTwo } from 'secondstep-components'
import variables from 'themes/variables'

export const Wrapper = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  width: 100%;
  max-width: ${variables.containerMaxWidth};
  padding: 0 1.25rem 0;
  ${HeaderTwo} {
    margin-bottom: 1.3125rem;
    color: ${themeGet('colors.darkerDarkBlue')};
    font-family: ${themeGet('fontFamilyHeadingBold')};
  }

  ${themeGet('breakpoints.mobile')} {
    ${HeaderTwo} {
      margin-bottom: 0.75rem;
    }
  }
`
