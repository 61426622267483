import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  BodyCopy,
  Hairline,
  HeaderOne,
  TextLink,
  WarningIcon,
} from 'secondstep-components'

export const ActiveCount = styled.span`
  margin-right: 1.25rem;
`

export const BodyBox = styled.div`
  display: flex;
`

export const Bullet = styled.div.attrs(props => {
  let background = themeGet('colors.darkBlue')(props)

  if (props.color === 'yellow') {
    background = themeGet('colors.yellow')(props)
  }
  if (props.color === 'gray') {
    background = themeGet('colors.beaconGray')(props)
  }

  return { background }
})`
  display: inline-block;
  height: 0.8125rem;
  width: 0.8125rem;
  margin: 0 0.4375rem 0.25rem 0;
  border-radius: 50%;
  background: ${({ background }) => background};
`

export const Card = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 4.625rem 1rem 1.25rem 1.25rem;

  @media screen and (max-width: 1330px) {
    justify-content: center;
  }

  @media screen and (max-width: 1060px) {
    flex-direction: column;
    padding: 2rem 0 1.25rem;
  }
`

export const CompleteTextWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const CompletedTrainingText = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 1.125rem;
  margin-top: 0.3125rem;
`

export const CountBox = styled.div`
  display: flex;
  align-items: center;
`

export const CountRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const EditLink = styled(TextLink)``

export const InProgressTextWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1.25rem;

  @media screen and (max-width: 1330px) {
    padding-left: 0;
  }

  @media screen and (max-width: 1060px) {
    padding-left: 1.25rem;
  }
`

export const KickOffTrainingLink = styled(TextLink)`
  line-height: 1.375rem;
  margin-left: 1.25rem;

  @media screen and (max-width: 1330px) {
    margin-left: 0;
  }

  @media screen and (max-width: 1060px) {
    margin-left: 1.25rem;
  }
`

export const LastUpdatedMessage = styled(BodyCopy)`
  position: absolute;
  top: 5px;
  right: 13px;
  color: ${themeGet('colors.gray1100')};
  font-size: 0.75rem;
`

export const LaunchSnapshotBullet = styled(Bullet)`
  margin: 0 0.4375rem 0.125rem 0;
`

export const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  margin-right: 18px;
`

export const ManageUsersLink = styled(TextLink)`
  margin-top: 0.4375rem;
`

export const NotStartedTextWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 1.25rem;

  @media screen and (max-width: 1330px) {
    padding-left: 0;
  }

  @media screen and (max-width: 1060px) {
    padding-left: 1.25rem;
  }
`

export const PacingDescriptionBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.9375rem;
`

export const PacingScheduleLink = styled(TextLink)`
  margin-top: 0.3125rem;
`

export const PacingText = styled.span`
  margin-right: 0.625rem;
`

export const PendingCount = styled.span``

export const PercentageText = styled(HeaderOne)`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamilyHeadingMedium')};
  font-size: 2.5rem;
  margin-bottom: 0;
`

export const ProgressBarWrapper = styled.div`
  position: relative;
`

export const ProgressContentWrapper = styled.div`
  position: absolute;
  top: 65px;
  left: 89px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const RightBox = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 75%;
  padding: 1.4375rem 1.25rem 0.75rem;
  border: 2px solid ${themeGet('colors.paleGray')};
  border-radius: 10px;
  background: ${themeGet('colors.white')};
  box-sizing: border-box;
  text-align: center;
`

export const SchoolSettingsBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.4375rem 1.25rem 0.75rem;
  margin-bottom: 1.25rem;
  border: 2px solid ${themeGet('colors.paleGray')};
  border-radius: 10px;
  background: ${themeGet('colors.white')};
  box-sizing: border-box;
`

export const SchoolSettingsText = styled.span`
  margin: 0.1875rem 0 1rem;
  color: ${themeGet('colors.darkGray')};
  font-size: 1.125rem;
  font-family: ${themeGet('font.molde.medium')};
  line-height: 1.25rem;
`

export const StaffKickOffStatusText = styled.div`
  display: flex;
  align-items: center;
`

export const StaffKickOffTextWrapper = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 1330px) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media screen and (max-width: 1060px) {
    flex-direction: row;
  }
`

export const StaffKickOffWrapper = styled.div``

export const StaffTrainingProgressTextWrapper = styled.div`
  display: flex;

  @media screen and (max-width: 1330px) {
    flex-direction: column;
  }

  @media screen and (max-width: 1060px) {
    flex-direction: row;
  }
`

export const StaffTrainingProgressWrapper = styled.div`
  padding-top: 1.125rem;
`

export const StyledDataText = styled(BodyCopy)``

export const StyledError = styled.span`
  color: ${themeGet('colors.highschool.pink1')};
  display: flex;
  font-size: 0.9rem;
  margin: 0.75rem 0rem;
  vertical-align: text-top;
  line-height: 1;
`

export const StyledHairline = styled(Hairline)`
  height: 2px;
  background-color: ${themeGet('colors.paleGray')};
`

export const StyledHeaderText = styled(BodyCopy)`
  display: flex;
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamilySemibold')};
`

export const StyledWarningIcon = styled(WarningIcon)`
  display: inline-block;
  height: 0.75rem;
  margin-right: 0.25rem;
  width: 0.75rem;

  circle {
    fill: ${themeGet('colors.highschool.pink1')};
  }
`

export const TotalCount = styled.span`
  margin-top: 0.9375rem;
  font-family: ${themeGet('font.molde.medium')};
  line-height: 1.5rem;
`

export const TrainingDataContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const TrainingDataHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`

export const TrainingDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 0.875rem;
`

export const TrainingHeaderMessage = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 1.125rem;
`

export const TrainingHeaderText = styled(HeaderOne)`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamilyHeadingMedium')};
  font-size: 2.5rem;
  line-height: 2.6875rem;
  margin-bottom: 0;
`

export const UsersAddedBox = styled(SchoolSettingsBox)`
  margin-bottom: 0;
`

export const UsersAddedCount = styled.span`
  font-size: 2.5rem;
  line-height: 2.6875rem;
  font-family: ${themeGet('font.sharpSlab.medium')};
  color: ${themeGet('colors.darkGray')};
  margin-bottom: 0.1875rem;
`

export const UsersAddedText = styled.span`
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-family: ${themeGet('font.molde.medium')};
  color: ${themeGet('colors.darkGray')};
  margin-bottom: 0.9375rem;
`

export const UsersCompletionText = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};
  font-size: 0.875rem;
  margin-top: 0.3125rem;
`

export const VerticalHairline = styled(Hairline)`
  background-color: ${themeGet('colors.paleGray')};
  height: 2px;
  transform: rotate(90deg);
  width: 260px;
  margin: 0 -4.625rem 0 -4.25rem;

  @media screen and (max-width: 1060px) {
    margin: 1rem 0;
    transform: none;
    width: 100%;
  }
`
