import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  Hairline,
  OutlinedButton,
  TextLink,
  WarningIcon,
} from 'secondstep-components'

export const ActiveCount = styled.span`
  margin-right: 1.25rem;
`

export const BodyBox = styled.div`
  display: flex;
`

export const BodyText = styled.p`
  max-width: 530px;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: ${themeGet('colors.darkGray')};
  margin: 1.5625rem 0 1.375rem;

  span {
    font-family: ${themeGet('font.molde.medium')};
  }
`

export const Bullet = styled.div.attrs(props => {
  let background = themeGet('colors.darkBlue')(props)

  if (props.color === 'yellow') {
    background = themeGet('colors.yellow')(props)
  }
  if (props.color === 'gray') {
    background = themeGet('colors.beaconGray')(props)
  }

  return { background }
})`
  display: inline-block;
  height: 0.8125rem;
  width: 0.8125rem;
  margin: 0 0.4375rem 0.25rem 0;
  border-radius: 50%;
  background: ${({ background }) => background};
`

export const CountBox = styled.div`
  display: flex;
  align-items: center;
`

export const CountRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const EditLink = styled(TextLink)``

export const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  margin-right: 18px;
`

export const ManageUsersLink = styled(TextLink)`
  margin-top: 0.4375rem;
`

export const PacingDescriptionBox = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 0.9375rem;
`

export const PacingScheduleLink = styled(TextLink)`
  margin-top: 0.3125rem;
`

export const PacingText = styled.span`
  margin-right: 0.625rem;
`

export const PendingCount = styled.span``

export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 75%;
  padding: 1.4375rem 1.25rem 0.75rem;
  border: 2px solid ${themeGet('colors.paleGray')};
  border-radius: 10px;
  background: ${themeGet('colors.white')};
  box-sizing: border-box;
  text-align: center;
`

export const SchoolSettingsBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.4375rem 1.25rem 0.75rem;
  margin-bottom: 1.25rem;
  border: 2px solid ${themeGet('colors.paleGray')};
  border-radius: 10px;
  background: ${themeGet('colors.white')};
  box-sizing: border-box;
`

export const SchoolSettingsText = styled.span`
  margin: 0.1875rem 0 1rem;
  color: ${themeGet('colors.darkGray')};
  font-size: 1.125rem;
  font-family: ${themeGet('font.molde.medium')};
  line-height: 1.25rem;
`

export const StyledError = styled.span`
  color: ${themeGet('colors.highschool.pink1')};
  display: flex;
  font-size: 0.9rem;
  margin: 0.75rem 0rem;
  vertical-align: text-top;
  line-height: 1;
`

export const StyledHairline = styled(Hairline)`
  height: 2px;
  background-color: ${themeGet('colors.paleGray')};
`

export const StyledOutlineButton = styled(OutlinedButton)``

export const StyledWarningIcon = styled(WarningIcon)`
  display: inline-block;
  height: 0.75rem;
  margin-right: 0.25rem;
  width: 0.75rem;

  circle {
    fill: ${themeGet('colors.highschool.pink1')};
  }
`

export const TotalCount = styled.span`
  margin-top: 0.9375rem;
  font-family: ${themeGet('font.molde.medium')};
  line-height: 1.5rem;
`

export const UsersAddedBox = styled(SchoolSettingsBox)`
  margin-bottom: 0;
`

export const UsersAddedCount = styled.span`
  font-size: 2.5rem;
  line-height: 2.6875rem;
  font-family: ${themeGet('font.sharpSlab.medium')};
  color: ${themeGet('colors.darkGray')};
  margin-bottom: 0.1875rem;
`

export const UsersAddedText = styled.span`
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-family: ${themeGet('font.molde.medium')};
  color: ${themeGet('colors.darkGray')};
  margin-bottom: 0.9375rem;
`
