import { Button } from 'grommet'
import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Container = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding-top: ${themeGet('spacing.red40_2_5rem')};
  padding-bottom: ${themeGet('spacing.amber28_1_75rem')};
  background-color: ${themeGet('colors.lighterGray')};

  ${themeGet('breakpoints.medium')} {
    padding-top: ${themeGet('spacing.blue16_1rem')};
  }

  ${themeGet('breakpoints.mobileLarge')} {
    padding-top: ${themeGet('spacing.amber28_1_75rem')};
  }
`

export const ChildSpacer = styled.div`
  height: ${themeGet('spacing.turquoise20_1_25rem')};
`

export const ActivitiesBox = styled.div`
  flex: 1;
  flex-direction: column;
  margin-left: ${themeGet('spacing.turquoise20_1_25rem')};
  margin-bottom: ${themeGet('spacing.amber28_1_75rem')};
`

export const Overview = styled.div`
  margin-left: ${themeGet('spacing.amber28_1_75rem')};

  ${themeGet('breakpoints.mobileLarge')} {
    margin-left: 0;
  }
`

export const Description = styled.p`
  flex: 2;
  margin: 0 0 ${themeGet('spacing.turquoise20_1_25rem')} 0;
`

export const StyledLessonMaterialList = styled.div`
  background-color: ${themeGet('colors.white')};
  border-radius: 20px;
  padding-left ${themeGet('spacing.amber28_1_75rem')};
  padding-right ${themeGet('spacing.amber28_1_75rem')}; 
  padding-top ${themeGet('spacing.purple24_1_5rem')};
  padding-bottom ${themeGet('spacing.turquoise20_1_25rem')}; 

  ul {
    flex-wrap: wrap;
    display: flex;
  }

  ul li {
    flex: 1 0 30%;

    svg {
      flex-shrink: 0;
    }   
  }
`

export const IconWrapper = styled.div`
  width: 40px;
  height: 41px;
  border-radius: 10px;
  background: ${themeGet('colors.buttons.primaryBackground')};
  align-items: center;
  justify-content: center;
  display: flex;
  svg {
    path {
      fill: ${themeGet('colors.white')};
    }
  }
}
`

export const LabelButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`

export const LabelContainer = styled(Button)`
  margin-left: ${themeGet('spacing.blue16_1rem')};
  color: ${themeGet('colors.buttons.primaryBackground')};
  &:hover {
    color: ${themeGet('colors.buttons.primaryBackgroundHover')};
  }
`

export const ViewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: ${themeGet('spacing.blue16_1rem')};
`
