import React from 'react'
import PropTypes from 'prop-types'
import { TextLink } from 'secondstep-components'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { toKebabCase, addStringsToItem } from 'utils/stringHelpers'

const TextLinkItem = ({
  Icon = () => null,
  className,
  href,
  locale,
  navigationEvent,
  text,
  onClick,
}) => {
  const handleClick = event => {
    event.preventDefault()
    if (onClick) {
      onClick(event)
    } else {
      const { DOWNLOAD_LINK } = NavigationHelper.types
      navigationEvent(href, DOWNLOAD_LINK)
    }
  }

  const initialId = 'text-link-item'
  const addition = toKebabCase(text || '')

  const dashSeperator = '-'
  const dataTestId = addStringsToItem({
    initialString: initialId,
    combineCharacter: dashSeperator,
    addtlStrings: [addition, locale],
  })
  if (!text) {
    return null
  }
  return (
    <TextLink
      className={className}
      dataTestId={dataTestId}
      href="#"
      onClick={handleClick}
    >
      <Icon role="img" />
      <span>{text}</span>
    </TextLink>
  )
}

TextLinkItem.propTypes = {
  Icon: PropTypes.elementType,
  className: PropTypes.string,
  href: PropTypes.string,
  locale: PropTypes.string,
  navigationEvent: PropTypes.func,
  onClick: PropTypes.func,
  text: PropTypes.string,
}

export default TextLinkItem
