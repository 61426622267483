import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BaseContainer, Card, ContainedButton } from 'secondstep-components'
import ClosableModal from 'experienceComponents/ClosableModal'

export const HeaderParagraph = styled.p`
  color: ${themeGet('colors.secondaryHeaderBodyItem.bodyText')};
  margin-top: 0.5rem;
`

export const Header2 = styled.h2`
  color: ${themeGet('colors.secondaryHeaderBodyItem.bodyText')};
  font-family: ${themeGet('fonts.secondaryHeaderSemiBold')};
  font-size: 1.4375rem;
`

export const Paragraph = styled.p`
  color: ${themeGet('colors.secondaryHeaderBodyItem.bodyText')};
`

export const Strong = styled.strong`
  color: ${themeGet('colors.secondaryHeaderBodyItem.bodyText')};
`

export const StyledCard = styled(Card)`
  gap: 0rem;
`

export const StyledClosableModal = styled(ClosableModal)`
  h2 {
    color: ${themeGet('colors.modalHeader.titleText')};
    font-family: ${themeGet('fonts.secondaryHeaderSemiBold')};
    font-size: 1.4375rem;
  }
`

export const StyledContainedButton = styled(ContainedButton)`
  text-transform: none;
  width: 8rem;
  height: 2.5rem;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  background-color: ${props =>
    props.isVideo
      ? themeGet('colors.buttons.primaryWatchVideo')
      : themeGet('colors.buttons.primaryPlaySession')};
  float: right;
  ${themeGet('breakpoints.tablet')} {
    float: none;
    margin-top: 1.25rem;
  }
  &:not([disabled]):hover {
    background-color: ${props =>
      props.isVideo
        ? themeGet('colors.buttons.hoverWatchVideo')
        : themeGet('colors.buttons.hoverPlaySession')};
  }
`

export const StyledContainer = styled(BaseContainer).attrs(() => ({
  as: 'section',
}))`
  padding: 1.1rem 1.25rem 1.25rem;
  margin-left: auto;
  margin-right: auto;
  ${themeGet('breakpoints.large')} {
    margin-left: 20px;
    margin-right: 20px;
  }
  max-width: 85.375rem;
`

export const TextLinkWithFileIconContainer = styled.div`
  margin-top: 1rem;
  a:hover {
    color: ${themeGet('colors.links.hover')};
  }
  position: relative;
  svg {
    position: absolute;
    min-width: 1.125rem;
    margin-top: 0;
    padding-top: 0;
    top: 0;
  }
  span {
    margin-left: 1.5rem;
    ${themeGet('breakpoints.mobileLarge')} {
      max-width: 19rem;
    }
    ${themeGet('breakpoints.mobile')} {
      max-width: 15rem;
    }
  }
`
