/* eslint-disable */
import { CONTENTFUL_ENDPOINT } from './constants'
const SUBDOMAIN = process.env.SUBDOMAIN
const HOST_DOMAIN = process.env.HOST_DOMAIN
const LD_CLIENT_ID = process.env.LD_CLIENT_ID
const AUTH_SUBDOMAIN = process.env.AUTH_SUBDOMAIN
const AUTH_CLIENT_ID = process.env.AUTH_CLIENT_ID

//time in seconds 5 minutes
const API_CALL_CACHE_TIME_TO_LIVE =
  process.env.API_CALL_CACHE_TIME_TO_LIVE || 300

const adminUrl = process.env.LOCAL_ADMIN
  ? 'http://localhost:4001'
  : `https://admin${SUBDOMAIN}.${HOST_DOMAIN}`

const documentServiceUrl = process.env.LOCAL_DOC_SVC
  ? 'http://localhost:7071'
  : `https://func-apps${SUBDOMAIN}-document-service-func.${HOST_DOMAIN}`

const lmsUrl = process.env.LOCAL_LMS
  ? 'http://localhost:8091'
  : `https://lms${SUBDOMAIN}.${HOST_DOMAIN}`

const apiUrl = process.env.LOCAL_API
  ? 'http://localhost:5010'
  : `https://api${SUBDOMAIN}.${HOST_DOMAIN}`

const playerUrl = process.env.LOCAL_PLAYER
  ? 'http://localhost:9000'
  : `https://player${SUBDOMAIN}.${HOST_DOMAIN}`

const reportingUrl = process.env.LOCAL_REPORTING
  ? 'http://localhost:6010'
  : `https://reporting${SUBDOMAIN}.${HOST_DOMAIN}`

const config_env = {
  default: {
    domains: {
      ADMIN_DOMAIN: `https://admin${SUBDOMAIN}.${HOST_DOMAIN}`,
      APP_DOMAIN: `https://app${SUBDOMAIN}.${HOST_DOMAIN}`,
      BLOB: 'https://cfccdn.blob.core.windows.net',
      CONTENTFUL_FUNC_APP: `https://func-apps${SUBDOMAIN}-contentful-func.${HOST_DOMAIN}/api/${CONTENTFUL_ENDPOINT}`,
      // TODO: this URI can probably be removed at some point, as it only gets notifications which were a part of the old header
      CONTENTFUL_PASSTHROUGH_API:
        'https://contentful-passthrough-dev.azurewebsites.net/api/notifications/current',
      DOCUMENT_SERVICE: `${documentServiceUrl}`,
      DNN_DOMAIN: `https://app${SUBDOMAIN}.${HOST_DOMAIN}`,
      IDP_DOMAIN: `https://login${SUBDOMAIN}.${HOST_DOMAIN}`,
      LMS_API: `https://lms${SUBDOMAIN}.${HOST_DOMAIN}/api/v1`,
      LMS_API_BASE: `https://lms${SUBDOMAIN}.${HOST_DOMAIN}`,
      LMS_API_V2: `https://lms${SUBDOMAIN}.${HOST_DOMAIN}/api/v2`,
      REPORTS_API: `${reportingUrl}/graphql`,
      LEARN_APP_DOMAIN: `https://learn${SUBDOMAIN}.${HOST_DOMAIN}`,
      METRICS_API: '',
      PLAYER_URL: `${playerUrl}`,
      PUBLIC_SITE_DOMAIN: `https://www${SUBDOMAIN}.${HOST_DOMAIN}`,
      ROUTE_TREE_FUNC_APP: `https://func-apps${SUBDOMAIN}-route-tree.${HOST_DOMAIN}/api`,
      SECONDSTEP_API: `https://api${SUBDOMAIN}.${HOST_DOMAIN}`,
      STORE_DOMAIN: `https://www${SUBDOMAIN}.${HOST_DOMAIN}`,
    },
    apiCallCache: {
      TIME_TO_LIVE: API_CALL_CACHE_TIME_TO_LIVE,
    },
    auth0Settings: {
      domain: `login${SUBDOMAIN}.${HOST_DOMAIN}`,
      audience: 'secondstep-apis',
      clientId: AUTH_CLIENT_ID,
    },
  },
  local: {
    domains: {
      ADMIN_DOMAIN: `${adminUrl}`,
      APP_DOMAIN: 'http://localhost:3000',
      BLOB: 'https://cfccdn.blob.core.windows.net',
      CONTENTFUL_FUNC_APP: `https://func-apps-np-dev-contentful-func.azurewebsites.net/api/${CONTENTFUL_ENDPOINT}`,
      DNN_DOMAIN: 'https://app-np-dev.cfctest.org',
      DOCUMENT_SERVICE: `${documentServiceUrl}`,
      IDP_DOMAIN: 'https://login-np-dev.cfctest.org',
      LEARN_APP_DOMAIN: 'http://localhost:4000',
      LMS_API: 'https://lms-np-dev.cfctest.org/api/v1',
      LMS_API_BASE: 'https://lms-np-dev.cfctest.org',
      LMS_API_V2: `https://lms-np-dev.cfctest.org/api/v2`,
      REPORTS_API: `${reportingUrl}/graphql`,
      METRICS_API: '',
      PLAYER_URL: `${playerUrl}`,
      PUBLIC_SITE_DOMAIN: 'https://www-np-dev.cfctest.org',
      ROUTE_TREE_FUNC_APP: `https://func-apps-np-dev-route-tree.cfctest.org/api`,
      SECONDSTEP_API: `${apiUrl}`,
      STORE_DOMAIN: 'https://store-np-dev.secondstep.org',
    },
    apiCallCache: {
      TIME_TO_LIVE: API_CALL_CACHE_TIME_TO_LIVE,
    },
    auth0Settings: {
      domain: `login-np-dev.cfctest.org`,
      audience: 'secondstep-apis',
      clientId: 'qfrKmK8DN5b3rsLXjCqFYrQeh46YYqUJ',
    },
  },
  localApp: {
    domains: {
      ADMIN_DOMAIN: adminUrl,
      APP_DOMAIN: `https://app${SUBDOMAIN}.${HOST_DOMAIN}`,
      BLOB: 'https://cfccdn.blob.core.windows.net',
      CONTENTFUL_FUNC_APP: `https://func-apps${SUBDOMAIN}-contentful-func.${HOST_DOMAIN}/api/${CONTENTFUL_ENDPOINT}`,
      // TODO: this URI can probably be removed at some point, as it only gets notifications which were a part of the old header
      CONTENTFUL_PASSTHROUGH_API:
        'https://contentful-passthrough-dev.azurewebsites.net/api/notifications/current',
      DNN_DOMAIN: `https://app${SUBDOMAIN}.${HOST_DOMAIN}`,
      DOCUMENT_SERVICE: `${documentServiceUrl}`,
      IDP_DOMAIN: `https://login${SUBDOMAIN}.${HOST_DOMAIN}`,
      LEARN_APP_DOMAIN: 'http://localhost:4000',
      LMS_API: `${lmsUrl}/api/v1`,
      LMS_API_BASE: lmsUrl,
      LMS_API_V2: `${lmsUrl}/api/v2`,
      REPORTS_API: `${reportingUrl}/graphql`,
      METRICS_API: '',
      PLAYER_URL: `${playerUrl}`,
      PUBLIC_SITE_DOMAIN: `https://www${SUBDOMAIN}.${HOST_DOMAIN}`,
      ROUTE_TREE_FUNC_APP: `https://func-apps${SUBDOMAIN}-route-tree.${HOST_DOMAIN}/api`,
      SECONDSTEP_API: `${apiUrl}`,
      STORE_DOMAIN: `https://www${SUBDOMAIN}.${HOST_DOMAIN}`,
    },
    apiCallCache: {
      TIME_TO_LIVE: API_CALL_CACHE_TIME_TO_LIVE,
    },
    auth0Settings: {
      domain: `login-np-dev.cfctest.org`,
      audience: 'secondstep-apis',
      clientId: 'qfrKmK8DN5b3rsLXjCqFYrQeh46YYqUJ',
    },
  },
  localContentful: {
    domains: {
      ADMIN_DOMAIN: `https://admin-np-dev.cfctest.org`,
      APP_DOMAIN: 'http://localhost:3000',
      BLOB: 'https://cfccdn.blob.core.windows.net',
      CONTENTFUL_FUNC_APP: `/api/${CONTENTFUL_ENDPOINT}`,
      DNN_DOMAIN: 'https://app-np-dev.cfctest.org',
      DOCUMENT_SERVICE: `${documentServiceUrl}`,
      LEARN_APP_DOMAIN: 'http://localhost:4000',
      IDP_DOMAIN: 'https://login-np-dev.cfctest.org',
      LMS_API: 'https://lms-np-dev.cfctest.org/api/v1',
      LMS_API_BASE: 'https://lms-np-dev.cfctest.org',
      LMS_API_V2: `https://lms-np-dev.cfctest.org/api/v2`,
      METRICS_API: '',
      PLAYER_URL: `${playerUrl}`,
      PUBLIC_SITE_DOMAIN: 'https://www-np-dev.cfctest.org',
      REPORTS_API: `${reportingUrl}/graphql`,
      ROUTE_TREE_FUNC_APP: `https://func-apps-np-dev-route-tree.cfctest.org/api`,
      SECONDSTEP_API: `${apiUrl}`,
      STORE_DOMAIN: 'https://store-np-dev.secondstep.org',
    },
    apiCallCache: {
      TIME_TO_LIVE: API_CALL_CACHE_TIME_TO_LIVE,
    },
    auth0Settings: {
      domain: `login-np-dev.cfctest.org`,
      audience: 'secondstep-apis',
      clientId: 'qfrKmK8DN5b3rsLXjCqFYrQeh46YYqUJ',
    },
  },
}

// duplicate `default` settings as `test` for jest
config_env.test = config_env.default

const environment = process.env.BUILD_TYPE || 'test'
let env = config_env[environment]
env.environment = environment
export const domains = env.domains
export const auth0Settings = env.auth0Settings
export const apiCallCache = env.apiCallCache

export const LD_CREDS = {
  clientSideID: LD_CLIENT_ID,
  user: {
    email: 'fakeLearnUser@notrealdomain.org',
    kind: 'user',
    key: 'default_fake_learn_user_key',
    name: 'Default Fake Learn User',
  },
}

// even though PROD has no subdomain, return 'p-prod' because @cfc/content-service-sdk expects a value for every enviornment
// trim off the leading dash: '-np-dev' -> 'np-dev'
export const ENVIRONMENT_SUBDOMAIN =
  SUBDOMAIN === '' ? 'p-prod' : SUBDOMAIN?.substring(1)
export const ALGOLIA_INDEX_NAME = process.env.ALGOLIA_INDEX_NAME
export const ALGOLIA_APP_NAME = process.env.ALGOLIA_APP_NAME
export const ALGOLIA_API_KEY = process.env.ALGOLIA_API_KEY
export const REPLICA_DURATION_SHORTER_TO_LONGER =
  process.env.REPLICA_DURATION_SHORTER_TO_LONGER
export const REPLICA_DURATION_LONGER_TO_SHORTER =
  process.env.REPLICA_DURATION_LONGER_TO_SHORTER
export const REPLICA_TITLE_A_TO_Z = process.env.REPLICA_TITLE_A_TO_Z
export const REPLICA_TITLE_Z_TO_A = process.env.REPLICA_TITLE_Z_TO_A

// For staging and prod environments, we don't want to use the contentful preview API
env['isContentfulPreview'] = () =>
  SUBDOMAIN !== '-np-staging' && SUBDOMAIN !== ''

export const isContentfulPreview = env.isContentfulPreview
export default env
