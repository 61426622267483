import React from 'react'
import PropTypes from 'prop-types'
import FirstTimePakCard from 'components/dashboard/FirstTimePakCard'
import HeaderTabs from 'components/dashboard/HeaderTabs'
import LicenseCard from 'components/dashboard/LicenseCard'
import ActiveTabProgramCards from 'components/dashboard/ActiveTabProgramCards'
import Notifications from 'components/Notifications'
import {
  BoxContent,
  DashboardLeftBoxStyled,
  ProgramCardsContainer,
  UnassignedLicenseCardWrapper,
} from './component.styles'

const DashboardLeftBox = props => {
  let {
    activeTabProgramGroup,
    activeTabProgramsAvailable,
    classes,
    contentfulCourses,
    contentfulModules,
    currentProgramName,
    firstName,
    firstTime,
    hasUnassignedLicenses,
    lmsSelaPath,
    lmsFTPath,
    lmsTrainings,
    navigationEvent,
    onComponentClick,
    onTabSelect,
    pageTitle,
    programGroupAvailable,
    seedIds,
    tabName,
    unassignedLicenses,
  } = props

  return (
    <DashboardLeftBoxStyled>
      <BoxContent>
        <Notifications />
        {hasUnassignedLicenses && (
          <UnassignedLicenseCardWrapper>
            <LicenseCard
              navigationEvent={navigationEvent}
              unassignedLicenses={unassignedLicenses}
            />
          </UnassignedLicenseCardWrapper>
        )}
        <HeaderTabs
          dataTestId="dashboard-header-tabs"
          defaultActiveTab={activeTabProgramGroup}
          id="dashboard-header-tabs"
          onSelect={onTabSelect}
          pageTitle={pageTitle}
          programGroup={programGroupAvailable}
          routedTab={tabName}
        />
        {firstTime && (
          <ProgramCardsContainer
            dataTestId="ftue-dashboard-program-container"
            key="dashboard-programs-do-not-exist"
          >
            <FirstTimePakCard
              dataTestId="ftue-pak-card"
              firstName={firstName}
              navigationEvent={navigationEvent}
            />
          </ProgramCardsContainer>
        )}
        {!firstTime && (
          <ActiveTabProgramCards
            activeTabProgramsAvailable={activeTabProgramsAvailable}
            classes={classes}
            contentfulCourses={contentfulCourses}
            contentfulModules={contentfulModules}
            currentProgramName={currentProgramName}
            firstName={firstName}
            lmsFTPath={lmsFTPath}
            lmsSelaPath={lmsSelaPath}
            lmsTrainings={lmsTrainings}
            onComponentClick={onComponentClick}
            seedIds={seedIds}
          />
        )}
      </BoxContent>
    </DashboardLeftBoxStyled>
  )
}

DashboardLeftBox.propTypes = {
  activeTabProgramGroup: PropTypes.object,
  activeTabProgramsAvailable: PropTypes.array,
  classes: PropTypes.array,
  contentfulCourses: PropTypes.array,
  contentfulModules: PropTypes.array,
  currentProgramName: PropTypes.string,
  firstName: PropTypes.string,
  firstTime: PropTypes.bool,
  hasUnassignedLicenses: PropTypes.bool,
  lmsFTPath: PropTypes.array,
  lmsSelaPath: PropTypes.array,
  lmsTrainings: PropTypes.array,
  navigationEvent: PropTypes.func,
  onComponentClick: PropTypes.func,
  onTabSelect: PropTypes.func,
  pageTitle: PropTypes.string,
  programGroupAvailable: PropTypes.array,
  seedIds: PropTypes.object,
  tabName: PropTypes.string,
  unassignedLicenses: PropTypes.array,
}

export default DashboardLeftBox
