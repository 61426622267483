import React from 'react'
import {
  IconWebinar,
  IconRightArrow,
  Column,
  Row,
  StyledLink,
} from './component.styles'
import { WEBINAR_LINK_TEXT, WEBINAR_PATH } from './contants'

const WebinarLinkIcon = () => {
  return (
    <Column>
      <Row>
        <StyledLink
          alt={WEBINAR_LINK_TEXT}
          dataTestId="text-link-webinar"
          to={WEBINAR_PATH}
        >
          <span>
            <IconWebinar aria-hidden="true" role="presentation" />
            {WEBINAR_LINK_TEXT}
          </span>
          <IconRightArrow />
        </StyledLink>
      </Row>
    </Column>
  )
}

export default WebinarLinkIcon
