import React from 'react'
import PropTypes from 'prop-types'
import { Container, DismissButton, Link } from './component.styles'

function getTextParts(text, linkText, onProgramTrainingClick) {
  const parts = text.split(linkText)
  return (
    <>
      {parts[0]}
      <Link key="link" onClick={onProgramTrainingClick}>
        {linkText}
      </Link>
      {parts[1]}
    </>
  )
}

function HighSchoolTrainingOverdueBanner({
  linkText,
  onDismiss,
  onProgramTrainingClick,
  text,
}) {
  return (
    <Container>
      <p>{getTextParts(text, linkText, onProgramTrainingClick)}</p>
      <DismissButton onClick={onDismiss} />
    </Container>
  )
}

HighSchoolTrainingOverdueBanner.propTypes = {
  linkText: PropTypes.string,
  onDismiss: PropTypes.func,
  onProgramTrainingClick: PropTypes.func,
  text: PropTypes.string,
}

export default HighSchoolTrainingOverdueBanner
