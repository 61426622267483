const isUserAccessToRoleError = message => {
  return message.match(/user doesn't have access to role/i)
}
const isUserAccessToResourceError = message => {
  return message.match(/User does not have access to/i)
}

export const getErrorStatusCode = error => {
  if (error.message) {
    if (
      isUserAccessToRoleError(error.message) ||
      isUserAccessToResourceError(error.message)
    ) {
      return 401
    }
    if (
      error.message.includes('Site with licenseId') &&
      error.message.includes('does not exist in the database')
    ) {
      return 404
    }
  } else {
    return error.status ?? 500
  }
}
