/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React from 'react'
import PropTypes from 'prop-types'
import { isPreviewMode } from 'utils/highschoolHelpers'
import PathwayTemplate from 'components/HighSchoolPathway'
import { StyledBackgroundImage } from './component.styles'

const PathwayPage = props => {
  const { entry, match, unitId = 1, handleTabChange } = props
  const { displayTitle, description, units: tabs } = entry
  const activeUnit = tabs?.[unitId - 1]
  const hasFooter = !!activeUnit.unitImage

  const tabDescription = {
    body: activeUnit?.description ?? '',
    title: activeUnit?.displayTitle ?? '',
    hasFooter,
    footerChildren: hasFooter && (
      <StyledBackgroundImage src={activeUnit.unitImage.file.url} />
    ),
    ...(activeUnit?.descriptionRoute && {
      singleLink: {
        text: activeUnit.descriptionRouteLabel,
        href: activeUnit.descriptionRoute,
      },
    }),
  }

  const templateProps = {
    title: displayTitle,
    description,
    tabs,
    activeTab: activeUnit,
    tabDescription,
    weeks: activeUnit.weeks,
    baseRoute: `${match?.url}/${activeUnit.pageData.route}`,
    handleTabChange,
    trainingCallout: activeUnit.trainingCallout,
    isPreview: isPreviewMode(),
  }

  return <PathwayTemplate {...templateProps} />
}

PathwayPage.propTypes = {
  entry: PropTypes.shape({
    displayTitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    units: PropTypes.arrayOf(
      PropTypes.shape({
        displayTitle: PropTypes.string.isRequired,
        weeks: PropTypes.arrayOf(
          PropTypes.shape({
            displayTitle: PropTypes.string.isRequired,
          }),
        ),
      }),
    ),
  }).isRequired,
  handleTabChange: PropTypes.func,
  match: PropTypes.shape({
    url: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
  unitId: PropTypes.number,
}

export default PathwayPage
