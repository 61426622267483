import styled from 'styled-components'
import { themeGet } from 'styled-system'
import StepModalBackLink from './component'

export const StyledBackLink = styled(StepModalBackLink)`
  color: ${themeGet('colors.blue')};
  font-size: 1rem;
  font-weitght: 600;
  font-family: ${themeGet('font.molde.regular')};
`
