import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  admin: {
    launchPagination: {
      currentPage: 1,
      sitesPerPage: 25,
      totalCount: 0,
      totalPages: 0,
      sortKey: 'siteName',
      orderBy: 'asc',
    },
    monitorPagination: {
      currentPage: 1,
      sitesPerPage: 25,
      totalCount: 0,
      totalPages: 0,
      sortKey: 'name',
      orderBy: 'asc',
    },
    hs: {
      isFetching: false,
      launchPagination: {
        currentPage: 1,
        sitesPerPage: 25,
        totalCount: 0,
        totalPages: 0,
        sortKey: 'school',
        sortOrder: 'asc',
      },
      schoolPractices: null,
      schoolPracticesPathways: null,
      schoolStudentActivities: null,
      schoolStudentActivitiesPathways: null,
      schoolTraining: null,
      schools: null,
      users: null,
    },
    k8SitesList: null,
    k8SitesSummary: {
      withoutClassesTotalCount: null,
      users: null,
      classes: null,
      totalSitesCount: null,
    },
    selaSitesList: null,
    lastUpdatedAt: null,
    schools: null,
    unitSummary: null,
  },
  error: null,
  isFetching: false,
  isFetchingSchool: false,
  isFetchingGrade: false,
  isFetchingClass: false,
  isFetchingAdminK8SitesList: false,
  isFetchingAdminK8SitesSummary: false,
  isFetchingAdminSelaSitesList: false,
  isFetchingAdminLessonCompletionData: false,
  reports: {},
  k8Reports: {},
  k8Classes: {},
  programs: [],
  programTitles: [],
  selaReports: {},
  selectedContentTrack: null,
  selectedGrade: null,
  selectedLicense: null,
  selectedModuleId: null,
  selectedProgram: null,
  selectedSite: null,
}

const reducers = {
  clearErrors: state => {
    state.error = initialState.error
  },

  fetchingHsLaunchSnapshot: state => {
    state.error = null
    state.admin.hs.isFetching = true
  },
  fetchHsAdminSnapshotSuccess: (state, { payload }) => {
    const { data } = payload
    const {
      LastUpdatedAt,
      SchoolPracticesOverall,
      SchoolPracticesPathways,
      SchoolStudentActivitiesOverallCompletion,
      SchoolStudentActivitiesPathways,
      SchoolTraining,
      Schools,
      Users,
    } = data
    const { launchPagination } = state.admin.hs

    const totalCount = Schools.length
    const totalPages = Math.ceil(Schools.length / launchPagination.sitesPerPage)

    state.admin.hs.isFetching = false
    state.admin.hs.launchPagination.totalCount = totalCount
    state.admin.hs.launchPagination.totalPages = totalPages
    state.admin.hs.schoolPractices = SchoolPracticesOverall
    state.admin.hs.schoolPracticesPathways = SchoolPracticesPathways
    state.admin.hs.schoolStudentActivities = SchoolStudentActivitiesOverallCompletion
    state.admin.hs.schoolStudentActivitiesPathways = SchoolStudentActivitiesPathways
    state.admin.hs.schoolTraining = SchoolTraining
    state.admin.hs.schools = Schools
    state.admin.hs.users = Users
    state.admin.lastUpdatedAt = LastUpdatedAt
    state.error = null
  },
  fetchHsAdminSnapshotError: (state, { payload }) => {
    const { error } = payload
    state.error = error
    state.admin.hs.isFetching = false
  },

  fetchingLessonCompletionData: state => {
    state.error = null
    state.isFetchingAdminLessonCompletionData = true
  },
  fetchLessonCompletionDataSuccess: (state, { payload }) => {
    const { data } = payload
    const { LastUpdatedAt, Schools, UnitSummary } = data

    const { monitorPagination } = state.admin
    const totalCount = Schools.length
    const totalPages = Math.ceil(
      Schools.length / monitorPagination.sitesPerPage,
    )
    state.admin = {
      ...state.admin,
      monitorPagination: {
        ...monitorPagination,
        totalCount,
        totalPages,
      },
    }

    state.error = null
    state.isFetchingAdminLessonCompletionData = false
    state.admin.lastUpdatedAt = LastUpdatedAt
    state.admin.schools = Schools
    state.admin.unitSummary = UnitSummary
  },
  fetchLessonCompletionDataError: (state, { payload }) => {
    const { error } = payload
    state.error = error
    state.isFetchingAdminLessonCompletionData = false
  },

  deletingReportsData: state => {
    state.error = initialState.error
    state.reports = initialState.reports
    state.selectedContentTrack = initialState.selectedContentTrack
    state.selectedGrade = initialState.selectedGrade
    state.selectedLicense = initialState.selectedLicense
    state.selectedModuleId = initialState.selectedModuleId
    state.selectedSite = initialState.selectedSite
    state.k8Reports = initialState.k8Reports
    state.k8Classes = initialState.k8Classes
  },

  fetchK8ReportSchoolSuccess: (state, { payload }) => {
    const { SiteDetails } =
      process.env.BUILD_TYPE === 'local' ? payload : payload.data
    const { siteId } = SiteDetails
    state.error = null
    state.isFetchingSchool = false
    state.k8Reports[siteId] = {
      ...state.k8Reports?.[siteId],
      ...SiteDetails,
    }
  },

  fetchAdminK8SitesSuccess: (state, { payload }) => {
    const {
      K8AdminSitesPaginated: { totalCount, totalPages, currentPage, sites },
    } = payload.data
    const { launchPagination } = state.admin
    state.admin = {
      ...state.admin,
      launchPagination: {
        ...launchPagination,
        totalCount,
        totalPages,
        currentPage,
      },
      k8SitesList: sites,
    }

    state.error = null
    state.isFetchingAdminK8SitesList = false
  },

  fetchAdminK8SitesSummarySuccess: (state, { payload }) => {
    const {
      data: {
        AdminSitesSummary: {
          withoutClassesTotalCount,
          users,
          classes,
          totalSitesCount,
        },
      },
    } = payload

    state.admin = {
      ...state.admin,
      k8SitesSummary: {
        withoutClassesTotalCount,
        users,
        classes,
        totalSitesCount,
      },
    }

    state.error = null
    state.isFetchingAdminK8SitesSummary = false
  },

  fetchAdminSelaSitesListSuccess: (state, { payload }) => {
    const { SelaAdminSitesList } = payload.data
    state.admin.selaSitesList = SelaAdminSitesList
    state.error = null
    state.isFetchingAdminSelaSitesList = false
  },

  fetchK8ReportGradeSuccess: (state, { payload }) => {
    const { SiteDetails } =
      process.env.BUILD_TYPE === 'local' ? payload : payload.data
    const { siteId } = SiteDetails
    state.error = null
    state.isFetchingGrade = false

    const {
      gradeDetails: gradeDetailsPayload,
      ...reportingPayload
    } = SiteDetails

    state.k8Reports[siteId] = {
      ...state.k8Reports[siteId],
      ...reportingPayload,
      gradeDetails: {
        [gradeDetailsPayload.gradeName]: gradeDetailsPayload,
        ...state.k8Reports[siteId]?.gradeDetails,
      },
    }
  },

  fetchK8ReportGradeError: (state, { payload }) => {
    const { error } = payload
    state.error = error
    state.isFetchingGrade = false
  },

  fetchK8ReportGradeAndClassDetailsSuccess: (state, { payload }) => {
    const { SiteDetails, ClassDetails } =
      process.env.BUILD_TYPE === 'local' ? payload : payload.data
    const { siteId } = SiteDetails
    const classInstance = ClassDetails.classInstance
    state.error = null
    state.isFetchingGrade = false
    state.isFetchingClass = false

    const {
      gradeDetails: gradeDetailsPayload,
      ...reportingPayload
    } = SiteDetails

    state.k8Reports[siteId] = {
      ...state.k8Reports[siteId],
      ...reportingPayload,
      gradeDetails: {
        [gradeDetailsPayload.gradeName]: gradeDetailsPayload,
        ...state.k8Reports[siteId]?.gradeDetails,
      },
    }
    state.k8Classes[classInstance] = ClassDetails
  },

  fetchK8ReportGradeAndClassDetailsError: (state, { payload }) => {
    const { error } = payload
    state.error = error
    state.isFetchingGrade = false
    state.isFetchingClass = false
  },

  fetchK8ReportClassDetailsSuccess: (state, { payload }) => {
    const { ClassDetails } =
      process.env.BUILD_TYPE === 'local' ? payload : payload.data
    const classInstance = ClassDetails.classInstance
    state.error = null
    state.isFetchingClass = false
    state.k8Classes[classInstance] = ClassDetails
  },

  fetchingK8ReportClassDetailsError: (state, { payload }) => {
    const { error } = payload
    state.error = error
    state.isFetchingClass = false
  },

  fetchingReportsData: state => {
    state.error = null
    state.isFetching = true
  },

  fetchingReportsSchoolData: state => {
    state.error = null
    state.isFetchingSchool = true
  },

  fetchingReportsSchoolDataError: (state, { payload }) => {
    const { error } = payload
    state.error = error
    state.isFetchingSchool = false
  },

  fetchingAdminK8SitesListError: (state, { payload }) => {
    const { error } = payload
    state.error = error
    state.isFetchingAdminK8SitesList = false
  },

  fetchingAdminK8SitesSummaryError: (state, { payload }) => {
    const { error } = payload
    state.error = error
    state.isFetchingAdminK8SitesSummary = false
  },

  fetchingAdminSelaSitesListError: (state, { payload }) => {
    const { error } = payload
    state.error = error
    state.isFetchingAdminSelaSitesList = false
  },

  fetchingReportsGradeData: state => {
    state.error = null
    state.isFetchingGrade = true
  },

  fetchingReportsClassData: state => {
    state.error = null
    state.isFetchingClass = true
  },

  fetchingReportsGradeAndClassData: state => {
    state.error = null
    state.isFetchingClass = true
    state.isFetchingGrade = true
  },

  fetchingReportsDataSuccess: (state, { payload }) => {
    const { data } = payload
    const { sela_report } = data || {}
    const { license_id } = sela_report

    state.error = null
    state.isFetching = false

    if (license_id) {
      state.reports[license_id] = sela_report
    }
  },

  fetchingReportsDataError: (state, { payload }) => {
    const { error } = payload

    state.error = error
    state.isFetching = false
  },

  fetchingReportsContentTrackSuccess: (state, { payload }) => {
    const { data } = payload
    const { sela_report } = data || {}
    const { license_id, content_tracks, users_totals } = sela_report

    state.error = null
    state.isFetching = false

    if (license_id) {
      if (!state.reports[license_id]) {
        state.reports[license_id] = sela_report
        return
      }

      state.reports[license_id]['license_id'] = license_id
      state.reports[license_id]['content_tracks'] = content_tracks
      state.reports[license_id]['users_totals'] = users_totals
    }
  },

  fetchingReportsUsersDetailSuccess: (state, { payload }) => {
    const { data } = payload
    const { sela_report } = data || {}
    const { license_id, user_details } = sela_report

    state.error = null
    state.isFetching = false

    if (license_id) {
      if (!state.reports[license_id]) {
        state.reports[license_id] = sela_report
        return
      }

      state.reports[license_id]['license_id'] = license_id
      state.reports[license_id]['user_details'] = user_details
    }
  },

  fetchingAdminK8SitesList: state => {
    state.isFetchingAdminK8SitesList = true
  },

  fetchingAdminK8SitesSummary: state => {
    state.error = false
    state.isFetchingAdminK8SitesSummary = true
  },

  fetchingAdminSelaSitesList: state => {
    state.isFetchingAdminSelaSitesList = true
  },

  fetchingK8ReportsBatchDataSuccess: state => {
    state.isFetchingK8ReportsBatch = false
  },

  updateSelectedContentTrack: (state, { payload }) => {
    const { contentTrack } = payload
    state.selectedContentTrack = contentTrack
  },

  updateSelectedGrade: (state, { payload }) => {
    const { gradeName } = payload
    state.selectedGrade = gradeName
  },

  updateSelectedLicense: (state, { payload }) => {
    const { licenseId } = payload
    state.selectedLicense = licenseId
  },

  updateSelectedModuleId: (state, { payload }) => {
    const { moduleContentfulId } = payload
    state.selectedModuleId = moduleContentfulId
  },
  updateLaunchSelectedPaginationSortKey: (state, { payload }) => {
    const { sortKey } = payload
    state.admin.launchPagination.sortKey = sortKey
  },
  updateLaunchSelectedPaginationSortOrder: (state, { payload }) => {
    const { orderBy } = payload
    state.admin.launchPagination.orderBy = orderBy
  },
  updateMonitorSelectedPaginationCurrentPage: (state, { payload }) => {
    const { page } = payload
    state.admin.monitorPagination.currentPage = page
  },
  updateMonitorSelectedPaginationSortKey: (state, { payload }) => {
    const { sortKey } = payload
    state.admin.monitorPagination.sortKey = sortKey
  },
  updateMonitorSelectedPaginationSortOrder: (state, { payload }) => {
    const { orderBy } = payload
    state.admin.monitorPagination.orderBy = orderBy
  },
  updateSelectedSite: (state, { payload }) => {
    const { site } = payload
    state.selectedSite = site
  },
  updateSelectedProgram: (state, { payload }) => {
    const { program } = payload
    state.selectedProgram = program
  },
  updateProgramTitles: (state, { payload }) => {
    const { programTitles } = payload
    state.programTitles = programTitles
  },
  updateProgramsForUser: (state, { payload }) => {
    const { programs } = payload
    state.programs = programs
  },
}

const slice = createSlice({ initialState, name: 'reports', reducers })

export const actions = slice.actions
export default slice.reducer
