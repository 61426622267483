import React from 'react'
import PropTypes from 'prop-types'
import {
  BodyCopy,
  ContainedButton,
  HeaderTwo,
  LaptopIcon,
} from 'secondstep-components'
import { Wrapper } from './component.styles'

import {
  WIZARD_TRAINING_BODYCOPY,
  WIZARD_TRAINING_BUTTON_TEXT,
  WIZARD_TRAINING_HEADER,
} from './constants'

export const WizardWelcome = ({ onClick }) => (
  <Wrapper animation="fadeIn">
    <LaptopIcon />
    <HeaderTwo dataTestId="header-wizard-training">
      {WIZARD_TRAINING_HEADER}
    </HeaderTwo>
    <BodyCopy data-testid="wizard-training-bodycopy">
      {WIZARD_TRAINING_BODYCOPY}
    </BodyCopy>
    <ContainedButton
      dataTestId="wizard-training-start-button"
      onClick={onClick}
    >
      {WIZARD_TRAINING_BUTTON_TEXT}
    </ContainedButton>
  </Wrapper>
)

WizardWelcome.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default WizardWelcome
