import variables from './variables'
import blobImgMiddleSchool from 'experienceComponents/LandingBanner/assets/landingBannerBlobsELEM.svg'
import subBlobImgMiddleSchool from 'experienceComponents/SubLandingBanner/assets/subLandingBannerBlobsELEM.svg'
import backgroundImage from 'experienceComponents/UnitOverview/assets/img_unit_overview_blobs_elem.svg'

export default {
  meta: { themeName: 'elementary' },

  globalNav: {
    background: variables.colors.elemDigital2,
    iconHoverBackground: variables.colors.opacity_20,
  },
  colors: {
    themeAccent: variables.colors.elemDigital1,
    themeHighlight: variables.colors.elemDigital1,
    themePrimary: variables.colors.elemDigital2,
    themeSecondary: variables.colors.elemDigital3,
    themeDark: variables.colors.elemDigital3,
    classCardHover: variables.colors.elemDigital2,
    accordion: {
      headerTextColor: variables?.colors?.elemDigital3,
      icon: variables?.colors?.elemDigital1,
      iconHoverColor: variables?.colors?.elemDigital4,
      panelColor: variables?.colors?.paleGray,
    },
    backgrounds: {
      primary: variables.colors.elemDigital3,
    },
    buttons: {
      dropdownIconHover: variables.colors.elemDigital4,
      iconBase: variables.colors.elemDigital1,
      iconHover: variables.colors.elemDigital4,
      opaqueDropdownHover: variables.colors.elemDigital4Opaque,
      previewBackground: variables.colors.elemDigital4,
      primaryBackground: variables.colors.elemDigital1,
      primaryBackgroundHover: variables.colors.elemDigital4,
      resumeBackground: variables.colors.elemDigital4,
      resumeBackgroundHover: variables.colors.elemDigital5,
      reviewBackground: variables.colors.elemDigital2,
      reviewBackgroundHover: variables.colors.elemDigital3,
      secondaryFont: variables.colors.white,
      startBackground: variables.colors.elemDigital1,
      startBackgroundHover: variables.colors.elemDigital2,
      tabActive: variables.colors.elemDigital4,
      tabDefault: variables.colors.darkGray,
    },
    links: {
      primary: variables.colors.elemDigital2,
      primaryHover: variables.colors.elemDigital4,
    },
    headers: {
      primary: variables.colors.darkerDarkBlue,
    },
    heading: {
      level: {
        1: variables.colors.elemDigital3,
        2: variables.colors.elemDigital3,
        3: variables.colors.elemDigital3,
      },
    },
  },
  // TODO: LEARN-9633, move backround images and colors to separate objects
  banners: {
    contentTypeDescriptionBackgroundColor: variables.colors.elemDigital2,
    headerTextColor: variables.colors.white,
    headerTextFont: 'Sharpslab-extrabold',
    headerTextSize: '42px',
    backgroundImage: backgroundImage,
    bannerTextColor: variables.colors.white,
    landingBackgroundColor: variables.colors.elemDigital3,
    landingBackgroundImage: blobImgMiddleSchool,
    sublandingBackgroundImage: subBlobImgMiddleSchool,
    alignment: 'center',
    textAlignment: 'center',
  },
  callouts: {
    iconBase: variables.colors.elemDigital1,
    iconHover: variables.colors.elemDigital1,
    hover: variables.colors.elemDigital3,
    headerTextColor: variables.colors.elemDigital2,
    headerTextFont: 'Sharpslab-extrabold',
    headerTextSize: '28px',
    bodyTextColor: variables.colors.black,
    backgroundColor: variables.colors.white,
    iconBorderRadius: '0.688rem 0.688rem 2.813rem 0.688rem',
    boxShadow: `0rem 0rem 0.625rem ${variables.colors.gray300}`,
  },
  anchorNavigation: {
    borderBottomStyle: 'none',
    borderBottomColor: 'none',
    borderBottomWidth: 'none',
    boxShadow: `0px 0px 10px ${variables.colors.gray300}`,
  },
  resources: {
    backgroundColorTop: variables.colors.elemDigital2,
    backgroundColorBottom: variables.colors.backgroundNeutralGray,
  },
}
