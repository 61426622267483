const firstLessonCompleted = lessonChoices => {
  let lesson = null
  let lessonIndex = null
  lessonChoices.map((choice, index) => {
    if (!lesson && choice.doneAt) {
      lesson = choice
      lessonIndex = index
    } else if (
      choice.doneAt &&
      new Date(choice.doneAt) < new Date(lesson.doneAt)
    ) {
      lesson = choice
      lessonIndex = index
    }
  })
  return lessonIndex
}

const lessonWithMostRecentProgress = lessonChoices => {
  let lesson = null
  let lessonIndex = null
  lessonChoices.map((choice, index) => {
    if (!lesson && choice.updatedAt) {
      lesson = choice
      lessonIndex = index
    } else if (
      choice.updatedAt &&
      new Date(choice.updatedAt) > new Date(lesson.updatedAt)
    ) {
      lesson = choice
      lessonIndex = index
    }
  })
  return lessonIndex
}

export const getDefaultLessonABIndex = lessonChoices => {
  const hasProgress = lessonChoices.some(choice => choice.progress)
  const hasCompletion = lessonChoices.some(choice => choice.doneAt)

  if (hasProgress) {
    if (hasCompletion) {
      return firstLessonCompleted(lessonChoices)
    }
    return lessonWithMostRecentProgress(lessonChoices)
  }
}
