import React from 'react'

import PropTypes from 'prop-types'

import {
  DEFAULT_LICENSE_ID,
  DEFAULT_PRODUCT,
  DEFAULT_SITE_NAME,
  getLicenseOptionText,
  SEPARATOR,
  UNKNOWN_LICENSE,
} from 'utils/productHelpers'

import { Container, Text, TextBold } from './component.styles'

const LicenseOption = ({ licenseId, product, siteName }) => {
  const isUnknownLicense =
    licenseId === DEFAULT_LICENSE_ID &&
    product === DEFAULT_PRODUCT &&
    siteName === DEFAULT_SITE_NAME
  const content = isUnknownLicense ? (
    <TextBold>{UNKNOWN_LICENSE}</TextBold>
  ) : (
    <span>
      <TextBold>{siteName}</TextBold>
      {siteName ? SEPARATOR : ''}
      {getLicenseOptionText(licenseId, product)}
    </span>
  )

  return (
    <Container>
      <Text>{content}</Text>
    </Container>
  )
}

LicenseOption.propTypes = {
  licenseId: PropTypes.string,
  product: PropTypes.string,
  siteName: PropTypes.string,
}

LicenseOption.defaultProps = {
  licenseId: DEFAULT_LICENSE_ID,
  product: DEFAULT_PRODUCT,
  siteName: DEFAULT_SITE_NAME,
}

export default LicenseOption
