import PropTypes from 'prop-types'

export const SiteType = PropTypes.shape({
  siteId: PropTypes.number.isRequired,
  siteName: PropTypes.string.isRequired,
  isSetup: PropTypes.bool.isRequired,
  claims: PropTypes.arrayOf(PropTypes.string),
  highSchoolEducatorPreferences: PropTypes.shape({
    HasUpdatedProfile: PropTypes.bool,
    TeachesEducatorActivities: PropTypes.bool,
    TeachesStudentActivities: PropTypes.bool,
    TeachesSchoolwideActivities: PropTypes.bool,
  }),
  highSchoolSitePreferences: PropTypes.shape({
    studentActivities: PropTypes.bool,
  }),
})
