import variables from './variables'

export default {
  meta: { themeName: 'elkit' },
  globalNav: {
    background: variables.colors.elkit4,
    iconHoverBackground: variables.colors.opacity_20,
  },
  colors: {
    heading: {
      level: {
        1: variables.colors.elkit2,
        2: variables.colors.elkit2,
        3: variables.colors.elkit2,
      },
    },
    buttons: {
      primaryBackground: variables.colors.elkit3,
      primaryBackgroundHover: variables.colors.elkit1,
      primaryBackgroundDisabled: variables.colors.buttonDisabledKit,
      secondaryText: variables.colors.elkit4,
      secondaryOutline: variables.colors.elkit4,
    },
    links: {
      primary: variables.colors.elkit5,
      primaryHover: variables.colors.elkit2,
    },
    themeAccent: variables.colors.elkit5,
    themeHighlight: variables.colors.elkit2,
    themePrimary: variables.colors.elkit4,
    themeSecondary: variables.colors.elkit1,
  },
  banners: {
    headerTextColor: variables.colors.black,
    headerTextFont: 'Sharpslab-bold',
    headerTextSize: '36px',
    bannerTextColor: variables.colors.darkGray,
    landingBackgroundColor: variables.colors.transparent,
    alignment: 'flex-start',
    textAlignment: 'left',
    contentTypeDescriptionBackgroundColor: variables.colors.gray500,
    contentTypeDescriptionIconColor: variables.colors.black,
    boxShadow: `0px 3px 0px ${variables.colors.gray600}`,
  },
  callouts: {
    headerTextColor: variables.colors.black,
    headerTextFont: 'Sharpslab-bold',
    bodyTextColor: variables.colors.darkGray,
    backgroundColor: variables.colors.lighterGray,
    iconBorderRadius: '0.688rem 0.688rem 0.688rem 0.688rem',
    boxShadow: `0px 3px 0px ${variables.colors.gray500}`,
  },
  resources: {
    backgroundColorTop: variables.colors.transparent,
    backgroundColorBottom: variables.colors.transparent,
  },
  anchorNavigation: {
    borderBottomStyle: 'none',
    borderBottomColor: 'none',
    borderBottomWidth: 'none',
    boxShadow: `0px 0px 10px ${variables.colors.gray300}`,
  },
}
