import styled from 'styled-components'

// TODO: [LEARN-14103] Incorporate themeGet to Unable to Authenticate
// themeGet currently does not work for the auth error screen because the theme is being set at component where Auth0ErrorHander does not access to it
export const CopyrightIcon = styled.span`
  display: inline-block;
  max-height: 0.625rem;
  vertical-align: top;
  line-height: 1.313rem;
  font-size: 1.125rem;
  padding-right: 0.188rem;
`

export const HelpContainer = styled.div`
  padding-top: 8.875rem;

  @media only screen and (max-width: 600px) {
    padding-top: 3.875rem;
  }
`

export const HelpMessage = styled.p`
  font-family: 'Molde-Medium';
  margin-bottom: 0.5rem;
`

export const HelpList = styled.ul`
  font-family: 'Molde-Regular';
  padding-left: 0.625rem;
  margin-top: 0.5rem;
`

export const HelpListItem = styled.li`
  font-size: 0.75rem;
`

export const HelpText = styled.span`
  font-size: 1rem;
`

export const UnauthorizedErrorPage = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: 'Molde-regular';
  background-color: #eef0f2;
  height: 100%;
  overflow: auto;
`

export const UnauthenticatedErrorPage = styled(UnauthorizedErrorPage)`
  justify-content: center;

  @media only screen and (max-width: 600px) {
    justify-content: flex-start;
  }
`

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 37.5rem;
  padding: 1.25rem;
  background-color: #ffffff;
  box-sizing: border-box;
  border-radius: 6px;
  color: #555555;
  margin: 10rem 1rem 2.25rem;

  @media only screen and (max-width: 600px) {
    margin-top: 8.5rem;
  }
`

export const UnableToAuthenticateContainer = styled(ErrorContainer)`
  background-color: #eef0f2;
  margin: 0;
  max-width: 100%;

  @media only screen and (max-width: 600px) {
    margin-top: 4.625rem;
    padding: 1.25rem 0 1.25rem;
  }
`

export const ErrorDetail = styled.span`
  display: flex;
  font-family: 'Molde-Medium';
  align-self: center;
  color: #002855;
  font-size: 1.125rem;
  padding: 2rem 0 1.313rem;
`

export const ErrorHeader = styled.h2`
  font-family: 'SharpSlab-Semibold';
  color: #002855;
  font-size: 1.438rem;
  margin: 0.938rem 0 0.438rem;
`

export const ErrorMessageStyled = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Molde-Regular';
  background-color: #eef0f2;
  padding: 0.875rem 1rem;
  border-radius: 5px;
  color: #555555;
`

export const Footer = styled.footer`
  display: flex;
  justify-content: space-between;
  padding: 0 1.25rem;
  margin-top: auto;
  margin-bottom: 2.5rem;
  align-self: normal;

  @media only screen and (max-width: 600px) {
    flex-direction: column-reverse;
    text-align: center;
  }
`

export const FooterLink = styled.a`
  text-decoration: none;
  font-size: 0.75rem;
  color: inherit;
  padding: 0 0.3rem;

  &:first-of-type {
    padding-left: 0;
  }

  &:hover,
  &:visited {
    color: inherit;
  }
`

export const FooterLogo = styled.img`
  height: 2.938rem;

  @media only screen and (max-width: 600px) {
    margin-bottom: 1.125rem;
  }
`

export const FooterTerms = styled.div`
  font-size: 0.75rem;
  color: #25282a;
`

export const FooterText = styled.p`
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 0.2rem;
  }

  @media only screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;

    span:not(:last-child) {
      margin-bottom: 0.2rem;
    }
  }
`

export const FooterLinks = styled.p`
  margin: 0;

  &:not(:last-child) {
    margin-bottom: 0.2rem;
  }
`

export const LogoutLink = styled.button`
  font-size: 1rem;
  color: #205c96;
  align-self: start;
  font-family: 'Molde-Medium';
  padding: 0;
  cursor: pointer;
  border: none;
  background: none;
  margin: 1rem 0;

  :hover {
    text-decoration: underline;
  }
`

export const Hr = styled.hr`
  width: 100%;
  width: 100%;
  border: 1px solid #eef0f2;
  border-radius: 0.75px;
  margin-bottom: 1.25rem;
  margin-top: 0.375rem;
`

export const SecondStepLogoLink = styled.a`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 5.188rem;

  @media only screen and (max-width: 600px) {
    top: 40px;
    left: unset;
    width: 6.25rem;
  }
`
