import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  BodyCopy,
  Hairline,
  HeaderTwo,
  LastUpdatedStatus,
  Tooltip,
  TooltipContentWrapper,
} from 'secondstep-components'
import ReportsBrightSpot from 'components/ReportsBrightSpot'

export const BottomWrapper = styled.div.attrs(({ isSchoolSummary }) => ({
  flexDirection: isSchoolSummary ? 'column' : 'row',
}))`
  display: flex;
  flex-direction: ${({ flexDirection }) => flexDirection};
  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
  }
  ${({ isSchoolSummary }) =>
    !isSchoolSummary &&
    `
  [data-testid='LeaderContainer'] {
    grid-template-columns: repeat(auto-fit, minmax(25.87%, max-content));
  }
  `}
`

export const HeaderWrapper = styled.div`
  flex-grow: 1;
`

// TODO: move this tooltip stuff to a common component - src/components/TooltipReport
export const StyledCopyTooltipWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`

export const ProgressWrapper = styled.div`
  @media (max-width: 500px) {
    width: 16rem;
  }
`

// 16px padding all around for content, 13px size
export const StyledTooltip = styled(Tooltip)`
  width: 14px;
  margin-left: 0.25rem;
  ${TooltipContentWrapper} {
    bottom: -7rem;
    padding: 0.75rem 1rem;
    border-radius: 0.3125rem;
    font-size: 0.9125rem;
    line-height: 1rem;
    ::after {
      content: ' ';
      width: 0.75rem;
      height: 0.75rem;
      position: absolute;
      background-color: ${themeGet('colors.white')};
      transform: translate(-50%) rotate(45deg);
    }
  }
  i.fas {
    color: ${themeGet('colors.blueGray')};
    font-size: 0.875rem;
  }
  ${BodyCopy} {
    color: ${themeGet('colors.darkGray')};
    margin-bottom: 0;
    p {
      font-size: 0.9125rem;
      line-height: 1rem;
      margin-block-end: 0;
      text-align: start;
    }
    strong {
      font-family: ${themeGet('fontFamilySemibold')};
    }
  }
  ${themeGet('breakpoints.mobile')} {
    display: none;
  }
`

export const LastUpdatedTooltip = styled(StyledTooltip)`
  ${TooltipContentWrapper} {
    left: -7rem;
    ::after {
      bottom: 93%;
      left: 93.625%;
    }
  }
  @media (max-width: 575px) {
    ${TooltipContentWrapper} {
      left: -92px;
      ::after {
        left: 86.5%;
      }
    }
  }
`

export const UserCountTooltip = styled(StyledTooltip).attrs(
  ({ isSchoolSummary }) => ({
    isSchoolSummary,
  }),
)`
  bottom: 1px;
  ${TooltipContentWrapper} {
    left: ${props => (props.isSchoolSummary ? '0rem' : '6rem')};
    ::after {
      bottom: 93%;
      left: ${props => (props.isSchoolSummary ? '52.5%' : '17.25%')};
    }
  }
  @media (max-width: 575px) {
    ${TooltipContentWrapper} {
      left: ${props => (props.isSchoolSummary ? '-5rem' : '6rem')};
      ::after {
        left: ${props => (props.isSchoolSummary ? '82%' : '17.25%')};
      }
    }
  }

  @media (max-width: 500px) {
    ${TooltipContentWrapper} {
      left: ${props => (props.isSchoolSummary ? '-2rem' : '6rem')};
      ::after {
        left: ${props => (props.isSchoolSummary ? '64.25%' : '17.25%')};
      }
    }
  }
`

export const LeaderToolsWrapper = styled.div.attrs(({ isSchoolSummary }) => ({
  marginLeft: isSchoolSummary ? '0' : '1rem',
  marginTop: isSchoolSummary ? '2.25rem' : '0',
}))`
  display: flex;
  align-items: center;
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-top: ${({ marginTop }) => marginTop};
  ${themeGet('breakpoints.tablet')} {
    margin-top: 1.5rem;
    margin-left: 0;
  }
`

export const PageHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  padding: 0 1.25rem;
  padding-bottom: 0.625rem;
  ${themeGet('breakpoints.mobileLarge')} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

export const StyledBodyCopy = styled(BodyCopy)`
  display: inline;
  color: ${themeGet('colors.darkBlue')};
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-family: ${themeGet('font.sharpSlab.medium')};
  strong {
    font-family: ${themeGet('font.sharpSlab.semibold')};
  }
`

export const StyledHairline = styled(Hairline)`
  height: 2px;
  margin-bottom: 0.875rem;
  background: ${themeGet('colors.grayBlue')};
  ${themeGet('breakpoints.tablet')} {
    margin-bottom: 0.75rem;
  }
`

export const StyledHeaderTwo = styled(HeaderTwo)`
  font-size: 2.375rem;
  line-height: 3rem;
  font-family: ${themeGet('font.sharpSlab.semibold')};
  ${themeGet('breakpoints.tablet')} {
    font-size: 2rem;
    line-height: 2.25rem;
    margin-block-end: 0.6875rem;
  }
`

export const StyledLastUpdatedStatus = styled(LastUpdatedStatus)`
  color: ${themeGet('colors.darkGray')};
  font-size: 0.875rem;
  font-family: ${themeGet('font.molde.regular')};
`

export const StyledReportsBrightSpot = styled(ReportsBrightSpot)`
  svg {
    position: absolute;
    margin-top: 0.25rem;
  }

  @media (max-width: 480px) {
    width: 21rem;
  }

  ${BodyCopy} {
    display: inline;
    margin-left: 1.5rem;
  }

  ${TooltipContentWrapper} {
    ${BodyCopy} {
      margin-left: 0;
    }

    @media (max-width: 575px) {
      left: -6.3438rem;
    }

    @media (max-width: 480px) {
      left: 6.6562rem;
      ::after {
        left: 13.25%;
      }
    }
  }
`

export const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.875rem;
  margin-bottom: 1.5rem;
  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;
    margin-bottom: 0.625rem;
    [data-testid*='breadcrumb-link'] {
      margin-bottom: 0.875rem;
    }
  }
`
