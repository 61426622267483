import { compose } from 'redux'
import { connect } from 'react-redux'
import { operations as loadingOperations } from 'store/loadingManager'

import withApiError from './component'
import ContentfulErrorStateSelectors from './selectors'

const mapStateToProps = ({
  lmsManager,
  licenseManager,
  reportsManager,
  setNotLoadingState,
  userAccessManager,
  userProfileManager,
}) => ({
  accessError: userAccessManager?.error,
  licenseError: licenseManager?.error,
  lmsError: lmsManager?.error,
  profileError: userProfileManager?.error,
  reportingError: reportsManager?.error,
  setNotLoadingState,
  userId: userProfileManager?.profile?.id,
})

const mapDispatchToProps = {
  setNotLoadingState: loadingOperations.setNotLoadingState,
}

const connectToRootContentErrors = connect(
  ContentfulErrorStateSelectors.selectRootContentfulState,
  null,
)
const connectToProductContentErrors = connect(
  ContentfulErrorStateSelectors.selectContentfulProductState,
  null,
)

const composedWithApiError = compose(
  connectToRootContentErrors,
  connectToProductContentErrors,
  connect(mapStateToProps, mapDispatchToProps),
  withApiError,
)
export default composedWithApiError
