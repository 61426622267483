import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'

export const Background = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin-bottom: 1.25rem;
  border-radius: 0.75rem;
  background: ${({ hasBackground }) =>
    hasBackground ? themeGet('colors.themePrimary') : themeGet('colors.white')};
`

export const Wrapper = styled(Box)`
  padding: 1.25rem;
  margin-left: 0.5rem;
  border-radius: 0.75rem;
  background: ${themeGet('colors.white')};
`

Wrapper.displayName = 'Wrapper'
