import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  BodyCopy,
  HeaderOne,
  TooltipContentWrapper,
} from 'secondstep-components'
import AdminTooltip from 'components/AdminTooltip'

const breakpoints = {
  customTablet: '@media screen and (max-width: 860px)',
}

export const Bold = styled.span`
  font-family: ${themeGet('font.molde.medium')};
`

export const Bullet = styled.span`
  display: inline-block;
  height: 0.8125rem;
  width: 0.8125rem;
  margin-right: 0.375rem;
  border-radius: 50%;
  background-color: ${themeGet('colors.yellow')};
  transform: translateY(0.0938rem);
`

export const Classes = styled.div`
  text-wrap: nowrap;

  .pipe {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }

  ${breakpoints.customTablet} {
    margin-bottom: 0.5rem;
    .pipe {
      display: none;
    }
  }
`

export const LastUpdatedMessage = styled(BodyCopy)`
  position: relative;
  top: -10px;
  color: ${themeGet('colors.gray1100')};
  font-size: 0.75rem;

  ${breakpoints.customTablet} {
    display: none;
  }
`

export const LeftBox = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoints.customTablet} {
    margin-bottom: 0.4375rem;
  }
`

export const Lessons = styled.div`
  display: flex;
  flex-direction: row;
  text-wrap: nowrap;
  .bold {
    margin-right: 0.313rem;
  }
`
export const LessonCompleted = styled.span`
  margin-right: 0.313rem;
  font-family: ${themeGet('font.molde.medium')};
`

export const LessonsTooltip = styled(AdminTooltip)`
  ${TooltipContentWrapper} {
    bottom: 34px;
    left: -75px;
    transform: none;
  }
`

export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 0.375rem;

  ${breakpoints.customTablet} {
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 0.375rem;
  }
`

export const SchoolTitle = styled(BodyCopy)`
  font-size: 0.875rem;
  color: ${themeGet('colors.darkGray')};
  letter-spacing: 0.88px;
  line-height: 1.0625rem;
  padding-bottom: 0.5625rem;
`

export const SchoolsAndClasses = styled.span.attrs(({ singleSite }) => ({
  margin: singleSite ? '0' : '0 0.5rem 0.5rem 0',
}))`
  margin: ${({ margin }) => margin};
  font-family: ${themeGet('font.molde.regular')};

  ${themeGet('breakpoints.mobile')} {
    margin-bottom: 0.4375rem;
  }
`

export const SiteHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;

  ${breakpoints.customTablet} {
    flex-direction: column;
  }
`

export const StyledHeaderOne = styled(HeaderOne).attrs(({ singleSite }) => ({
  marginBottom: singleSite ? '1.25rem' : '0.625rem',
}))`
  margin-bottom: ${({ marginBottom }) => marginBottom};
  font-size: 1.4375rem;
  line-height: 1.5625rem;
  font-family: ${themeGet('font.sharpSlab.semibold')};

  ${breakpoints.customTablet} {
    font-size: 1.4375rem;
    line-height: 1.5625rem;
  }
`

export const Summary = styled.div`
  display: flex;

  ${themeGet('breakpoints.mobile')} {
    flex-direction: column;
  }
`

export const Totals = styled.div`
  display: flex;
  margin-right: 0.5rem;
  font-family: ${themeGet('font.molde.regular')};

  ${breakpoints.customTablet} {
    flex-direction: column;
  }
`
