import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import { BaseContainer } from 'secondstep-components'

import {
  StyledHeaderThreeNoWrap,
  StyledHeaderTwo,
} from '../LessonDetail/component.styles'
import LessonMaterialsList from '../LessonMaterialsList'
import {
  BoldBodyCopy,
  ContainerSection,
  ViewWrapper,
  SecondaryViewColumn,
  MainViewColumn,
  MarkDownWrapper,
} from './component.styles'
import {
  REINFORCE_PAGE_TITLE,
  REINFORCE_SECTION_TITLE,
  REINFORCE_PDF_TITLE,
  REINFORCE_CLASSROOM_CLIMATE_ACTIVITIES,
  REINFORCE_LESSON_CONNECTIONS,
  DAY_ONE,
  DAY_TWO,
  DAY_THREE,
  DAY_FOUR,
  DAY_FIVE,
} from './constants'

const LessonReinforce = ({
  classroomClimateActivities,
  lessonConnections,
  reinforceDay1,
  reinforceDay2,
  reinforceDay3,
  reinforceDay4,
  reinforceDay5,
  reinforceMaterialsPdfs = [],
}) => {
  const dayInfo = [
    { title: DAY_ONE, body: reinforceDay1 },
    { title: DAY_TWO, body: reinforceDay2 },
    { title: DAY_THREE, body: reinforceDay3 },
    { title: DAY_FOUR, body: reinforceDay4 },
    { title: DAY_FIVE, body: reinforceDay5 },
  ]

  const hasDailyReinforcement = dayInfo.find(day => !!day.body)

  return (
    <>
      <StyledHeaderTwo dataTestId="lesson-reinforce-title">
        {REINFORCE_PAGE_TITLE}
      </StyledHeaderTwo>
      <ContainerSection dataTestId="lesson-reinforce">
        <MainViewColumn>
          {hasDailyReinforcement && (
            <StyledHeaderThreeNoWrap>
              {REINFORCE_SECTION_TITLE}
            </StyledHeaderThreeNoWrap>
          )}
          {dayInfo.map(day => {
            const { title, body } = day
            return (
              body && (
                <ViewWrapper key={title}>
                  <BoldBodyCopy>{title}</BoldBodyCopy>
                  <MarkDownWrapper>
                    <ReactMarkdown>{body}</ReactMarkdown>
                  </MarkDownWrapper>
                </ViewWrapper>
              )
            )
          })}
          {lessonConnections && (
            <Fragment key="lessonConnections">
              <StyledHeaderThreeNoWrap>
                {REINFORCE_LESSON_CONNECTIONS}
              </StyledHeaderThreeNoWrap>
              <ReactMarkdown>{lessonConnections}</ReactMarkdown>
            </Fragment>
          )}
          {classroomClimateActivities && (
            <Fragment key="classroomClimateActivities">
              <StyledHeaderThreeNoWrap>
                {REINFORCE_CLASSROOM_CLIMATE_ACTIVITIES}
              </StyledHeaderThreeNoWrap>
              <ReactMarkdown>{classroomClimateActivities}</ReactMarkdown>
            </Fragment>
          )}
        </MainViewColumn>

        {reinforceMaterialsPdfs.length > 0 && (
          <SecondaryViewColumn>
            <BaseContainer>
              <LessonMaterialsList
                materials={reinforceMaterialsPdfs}
                title={REINFORCE_PDF_TITLE}
              />
            </BaseContainer>
          </SecondaryViewColumn>
        )}
      </ContainerSection>
    </>
  )
}

LessonReinforce.propTypes = {
  classroomClimateActivities: PropTypes.string,
  lessonConnections: PropTypes.string,
  reinforceDay1: PropTypes.string,
  reinforceDay2: PropTypes.string,
  reinforceDay3: PropTypes.string,
  reinforceDay4: PropTypes.string,
  reinforceDay5: PropTypes.string,
  reinforceMaterialsPdfs: PropTypes.array,
}

export default LessonReinforce
