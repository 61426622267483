import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Redirect } from 'react-router'
import { RouteContentType } from 'layers/content/v2/Hocs/RouteContentGetter'

/**
 * RedirectRule component
 *
 * This component handles the redirection logic based on the provided route data.
 * Whenever a redirect rule is defined in the content, this component will redirect to the specified route.
 *
 * Note: The `route` string within `routeData` should be relative to the current route the user is on, not an absolute path.
 *
 * @param {Object} props.routeContent.content.redirectRule - The redirect rule object within content.
 * @param {Object} props.routeContent.content.redirectRule.routeData - The route data object within redirectRule.
 * @param {string} props.routeContent.content.redirectRule.routeData.route - The route string within routeData.
 */
export const RedirectRule = props => {
  const { match, location, routeContent } = props
  const { route } = routeContent?.content?.redirectRule?.routeData ?? {}
  const searchParams = location?.search

  return (
    <Switch>
      <Redirect to={`${match.url}/${route}${searchParams}`} />
    </Switch>
  )
}

RedirectRule.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
  routeContent: RouteContentType(
    PropTypes.shape({
      redirectRule: PropTypes.shape({
        routeData: PropTypes.shape({
          route: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
  ),
}
