import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { SNAPSHOTS_NAME_MAP } from 'utils/adminExperienceHelpers'
import { ADMIN_DASHBOARD_ENTRY_ID } from 'utils/entryIds'
import {
  findTooltipByLocation,
  TOOLTIP_LOCATIONS,
  TOOLTIP_PROGRAMS,
} from 'utils/tooltipHelpers'
import AdminHsMonitorSnapshot from './component'

const AdminHsMonitorSnapshotContainer = ({
  isMultiSite,
  lastUpdatedAt,
  modalText,
  modalTitle,
  site,
}) => {
  return (
    <AdminHsMonitorSnapshot
      isMultiSite={isMultiSite}
      lastUpdatedAt={lastUpdatedAt}
      modalText={modalText}
      modalTitle={modalTitle}
      site={site}
    />
  )
}

const mapStateToProps = ({ contentful, reportsManager }) => {
  const lastUpdatedAt = reportsManager?.admin?.lastUpdatedAt?.last_updated_at
  const location = TOOLTIP_LOCATIONS.HOW_ARE_THESE_CALCULATED_MODAL
  const programKey = TOOLTIP_PROGRAMS.HS_MONITOR
  const snapshot =
    contentful?.[ADMIN_DASHBOARD_ENTRY_ID]?.payload?.[
      SNAPSHOTS_NAME_MAP?.[programKey]
    ]
  const { text: modalText, tooltipHeader: modalTitle } =
    findTooltipByLocation(location, programKey, snapshot) || {}
  return { lastUpdatedAt, modalText, modalTitle }
}

AdminHsMonitorSnapshotContainer.propTypes = {
  isMultiSite: PropTypes.bool,
  lastUpdatedAt: PropTypes.string,
  modalText: PropTypes.string,
  modalTitle: PropTypes.string,
  site: PropTypes.object,
}

export default connect(mapStateToProps, null)(AdminHsMonitorSnapshotContainer)
