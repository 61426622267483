import React from 'react'
import PropTypes from 'prop-types'
import ModuleCard from 'components/ModuleCard'
import {
  AllModulesWrapper,
  Body,
  BodyContent,
  Description,
  HeaderBanner,
  Hairline,
  ModuleWrapper,
  Title,
} from './component.styles'
const AllModules = ({
  description,
  displayTitle,
  contentfulModules,
  lmsModules,
  navigationEvent,
  productName,
}) => {
  if (!contentfulModules) return null
  const onClick = urlFragment => {
    navigationEvent(`${urlFragment}`)
  }
  return (
    <AllModulesWrapper dataTestId="all-modules-wrapper">
      <HeaderBanner />
      <BodyContent>
        <Body>
          <Title dataTestId="all-modules-title">{displayTitle}</Title>
          <Description dataTestId="all-modules-description">
            {description}
          </Description>
          <Hairline />
          {lmsModules.map(
            (
              {
                attempted,
                completeLessons,
                isDone,
                totalLessons,
                contentfulID,
              },
              index,
            ) => {
              const contentfulModule = contentfulModules.find(
                module => module.id === contentfulID,
              )

              const { permissionsRoles } = contentfulModule

              const isOnlyLeader =
                permissionsRoles?.leader && !permissionsRoles?.staff

              return (
                <ModuleWrapper
                  dataTestId={`module-card-wrapper-${index}`}
                  key={contentfulID}
                >
                  <ModuleCard
                    attempted={attempted}
                    completeLessons={completeLessons}
                    description={contentfulModule.description}
                    displayTitle={contentfulModule.displayTitle}
                    entryId={contentfulID}
                    index={index}
                    isDone={isDone}
                    isOnlyLeader={isOnlyLeader}
                    key={contentfulID}
                    label={contentfulModule.label}
                    onClick={onClick}
                    productName={productName}
                    totalLessons={totalLessons}
                  />
                </ModuleWrapper>
              )
            },
          )}
        </Body>
      </BodyContent>
    </AllModulesWrapper>
  )
}

AllModules.propTypes = {
  contentfulModules: PropTypes.array,
  description: PropTypes.string,
  displayTitle: PropTypes.string,
  lmsModules: PropTypes.array,
  navigationEvent: PropTypes.func,
  productName: PropTypes.string,
}

export default AllModules
