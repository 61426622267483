import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { ButtonWrapper, DateInputWrapper } from './component.styles'
import IconCalendar from '../IconCalendar'
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import DatePicker from 'react-date-picker'
import { dateToMMMMddyyyy } from '../../utils/dateHelper'

const CalendarInput = ({ date = null, handleChange, hasError, name }) => {
  const [isOpen, setIsOpen] = useState(false)
  const handleDateSelected = useCallback(date => {
    handleChange(name, date)
  }, [])

  const formattedDate = date !== null ? dateToMMMMddyyyy(date) : 'Select a date'

  return (
    <DateInputWrapper hasError={hasError} isOpen={isOpen}>
      <DatePicker
        calendarIcon={
          <ButtonWrapper>
            <span>{formattedDate}</span>
            <IconCalendar color="#999999" />
          </ButtonWrapper>
        }
        calendarProps={{
          calendarType: 'gregory',
        }}
        clearIcon={null}
        isOpen={isOpen}
        onCalendarClose={() => setIsOpen(false)}
        onCalendarOpen={() => setIsOpen(true)}
        onChange={handleDateSelected}
        value={date}
      />
    </DateInputWrapper>
  )
}

CalendarInput.propTypes = {
  date: PropTypes.obj,
  handleChange: PropTypes.func,
  hasError: PropTypes.bool,
  name: PropTypes.string,
}

export default CalendarInput
