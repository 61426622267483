import React from 'react'
import PropTypes from 'prop-types'

import AudioFullWidth from 'experienceComponents/AudioFullWidth'

import {
  Container,
  ThumbnailContainer,
  ThumbnailText,
} from './component.styles'
import { getThumbnailTextString } from './data'
import { toKebabCase } from 'utils/stringHelpers'

const AudioThumbnailButton = ({
  description,
  thumbnailUrl,
  audioUrl,
  time = '',
  title = '',
}) => {
  const thumbnailTextString = getThumbnailTextString(time, title)
  const audioFullWidthAvailable = audioUrl
  const dataTestId = title ? 'title-' + toKebabCase(title) : 'title'

  return (
    <Container>
      <ThumbnailContainer>
        {audioFullWidthAvailable && (
          <AudioFullWidth
            audioThumbnailUrl={thumbnailUrl}
            audioUrl={audioUrl}
            description={description}
            displayTitle={title}
            id={dataTestId}
          />
        )}
      </ThumbnailContainer>
      {thumbnailTextString && (
        <ThumbnailText>{thumbnailTextString}</ThumbnailText>
      )}
    </Container>
  )
}

AudioThumbnailButton.propTypes = {
  audioUrl: PropTypes.string.isRequired,
  description: PropTypes.string,
  thumbnailUrl: PropTypes.string.isRequired,
  time: PropTypes.string,
  title: PropTypes.string,
}

export default AudioThumbnailButton
