import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { operations as docServiceOperations } from 'store/docServiceManager'
import AdminExportDownloadButton from './component'

const AdminExportDownloadButtonContainer = ({
  className,
  clearDocumentUrl,
  documentUrl,
  fetchDocumentUrl,
  isFetching,
  programKey,
  selectedProgram,
  toggleView,
  tooltipLocation,
  userEmail,
}) => {
  return (
    <AdminExportDownloadButton
      className={className}
      clearDocumentUrl={clearDocumentUrl}
      documentUrl={documentUrl}
      fetchDocumentUrl={fetchDocumentUrl}
      isFetching={isFetching}
      programKey={programKey}
      selectedProgram={selectedProgram}
      toggleView={toggleView}
      tooltipLocation={tooltipLocation}
      userEmail={userEmail}
    />
  )
}

const mapStateToProps = ({
  docServiceManager,
  userProfileManager,
  reportsManager,
}) => {
  const { key: selectedProgramKey } = reportsManager.selectedProgram || {}

  return {
    documentUrl: docServiceManager.documentUrl,
    isFetching: docServiceManager.isFetching,
    userEmail: userProfileManager?.profile?.email,
    selectedProgram: selectedProgramKey,
  }
}

const mapDispatchToProps = {
  clearDocumentUrl: docServiceOperations.clearDocumentUrl,
  fetchDocumentUrl: docServiceOperations.fetchDocumentUrl,
}

AdminExportDownloadButtonContainer.propTypes = {
  className: PropTypes.string,
  clearDocumentUrl: PropTypes.func,
  documentUrl: PropTypes.string,
  fetchDocumentUrl: PropTypes.func,
  isFetching: PropTypes.bool,
  programKey: PropTypes.string,
  selectedProgram: PropTypes.string,
  toggleView: PropTypes.string,
  tooltipLocation: PropTypes.string,
  userEmail: PropTypes.string,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminExportDownloadButtonContainer)
