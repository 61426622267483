import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Container = styled.div`
  display: inline-block;

  #k5kit-teach-button-toggle-label {
    display: none;
  }

  button {
    text-transform: none;
  }

  [role='radio'] {
    cursor: pointer;
    display: inline-block;
    font-family: ${themeGet('fontFamilySemibold')};
  }
`
