import React from 'react'
import PropTypes from 'prop-types'
import {
  BodyCopy,
  CloseButton,
  ContainedButton,
  HeaderTwo,
} from 'secondstep-components'
import { StyledModal } from './component.styles'
import { MODAL_BODY, MODAL_HEADER, SUBMIT_BUTTON } from './constants'

const OnlineProgramsModal = ({ isModalOpen, modalClose }) => {
  return (
    <StyledModal
      dataTestId="online-programs-modal"
      isModalOpen={isModalOpen}
      modalClose={modalClose}
    >
      <CloseButton
        dataTestId="button-close-online-programs-modal"
        onClick={modalClose}
      />
      <HeaderTwo>{MODAL_HEADER}</HeaderTwo>
      <BodyCopy>{MODAL_BODY}</BodyCopy>
      <ContainedButton dataTestId="button-submit" onClick={modalClose}>
        {SUBMIT_BUTTON}
      </ContainedButton>
    </StyledModal>
  )
}

OnlineProgramsModal.propTypes = {
  isModalOpen: PropTypes.bool,
  modalClose: PropTypes.func,
}

export default OnlineProgramsModal
