import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledCompleted,
  StyledInfo,
  StyledTextLink,
  StyledTime,
  StyledSeparator,
  Wrapper,
} from './component.styles'
import * as constants from './constants'

const SelaAccordionStatus = ({
  certificateUrl,
  hasMeeting,
  isDone,
  isLeader,
  totalLessons,
  unitTime,
}) => {
  if (!isDone && !certificateUrl) return null

  const completed = isDone && constants.COMPLETED

  const certificate = isDone && certificateUrl && constants.CERTIFICATE

  const showMLInfo = !isDone && certificateUrl

  const totalMicrolearnings = totalLessons - 1

  const microLearningInfo =
    showMLInfo &&
    constants.getMicroLearningInfo(totalMicrolearnings, hasMeeting, isLeader)

  const time = showMLInfo && constants.getTimeInfo(unitTime)

  const handleClick = e => {
    e.stopPropagation()
  }

  return (
    <Wrapper>
      {completed && (
        <StyledCompleted dataTestId={'sela-microlearning-complete'}>
          {completed}
        </StyledCompleted>
      )}
      {microLearningInfo && (
        <StyledInfo dataTestId={'sela-microlearning-info'} time={time}>
          {microLearningInfo}
        </StyledInfo>
      )}
      {time && (
        <StyledTime dataTestId={'sela-microlearning-time'}>{time}</StyledTime>
      )}
      {certificate && (
        <>
          <StyledSeparator />
          <StyledTextLink
            dataTestId={'sela-microlearning-link'}
            href={certificateUrl}
            onClick={handleClick}
          >
            {certificate}
          </StyledTextLink>
        </>
      )}
    </Wrapper>
  )
}

SelaAccordionStatus.propTypes = {
  certificateUrl: PropTypes.string,
  hasMeeting: PropTypes.bool,
  isDone: PropTypes.bool,
  isLeader: PropTypes.bool,
  totalLessons: PropTypes.string,
  unitTime: PropTypes.string,
}

export default SelaAccordionStatus
