import { ContainedButton } from 'secondstep-components'
import styled, { css } from 'styled-components'
import { themeGet } from 'styled-system'

const blueText = css`
  color: ${themeGet('colors.secondStepBlue')};
`

const sharpSemiBoldText = css`
  font-family: ${themeGet('font.sharpSlab.semibold')};
`

export const SetupWrapper = styled.div`
  display: flex;
  width: 100%;
  margin-top: 4rem;
`

export const SetupPanel = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const WelcomeMessage = styled.span`
  ${blueText}
  ${sharpSemiBoldText}
  margin-bottom: 4rem;
  text-align: center;
  font-size: 1.5rem;
`

export const AssignLicensePanel = styled.div`
  background-color: ${themeGet('colors.white')};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 4rem;
  align-items: center;
  justify-content: center;
  padding: 2rem 0;
`

export const MainText = styled.div`
  ${blueText}
  ${sharpSemiBoldText}
  font-size: 1.5rem;
  margin: 1.5rem 2rem 0.8rem;
  text-align: center;
`

export const SecondText = styled.span`
  font-size: 1rem;
  margin: 0 2rem;
  text-align: center;
`

export const HelpContainer = styled.div`
  display: flex;
  margin-top: 1.2rem;
`

export const AssignButton = styled(ContainedButton).attrs(() => ({
  'data-testid': 'setup-admin-assign-licenses-button',
}))`
  margin-top: 1.5rem;
  width: 165px;
`
