import React from 'react'
import PropTypes from 'prop-types'
import { TextLink } from 'secondstep-components'

import { toUrlSafe } from 'utils/stringHelpers'
import {
  StyledTelescope,
  StyledAwardIcon,
  StyledToolboxIcon,
} from './component.styles'

const iconMap = {
  'elem browse lessons': StyledTelescope,
  'elem static training': StyledAwardIcon,
  'elem ptk': StyledToolboxIcon,
  'ms browse lessons': StyledTelescope,
  'ms static training': StyledAwardIcon,
  'ms ptk': StyledToolboxIcon,
}

function StaticLink(props) {
  const { staticLink } = props
  const Icon = iconMap[staticLink.internalTitle]
  return (
    <TextLink
      dataTestId={`static-link-${toUrlSafe(staticLink.displayTitle)}`}
      href={staticLink.path}
      onClick={e => {
        e.preventDefault()
        props.onComponentClick(staticLink.path)
      }}
    >
      <Icon /> {staticLink.linkLabel}
    </TextLink>
  )
}

StaticLink.defaultProps = {
  staticLink: {},
}

StaticLink.propTypes = {
  onComponentClick: PropTypes.func.isRequired,
  staticLink: PropTypes.shape({
    displayTitle: PropTypes.string.isRequired,
    internalTitle: PropTypes.string.isRequired,
    linkLabel: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
  }).isRequired,
}

export default StaticLink
