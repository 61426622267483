import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router'
import { NotFound } from 'secondstep-components'
import { toTitleCase } from 'utils/stringHelpers'
import BackLink from 'components/BackLink'
import CoursePreview from 'components/CoursePreview'
import ProductLessons from 'components/ProductLessons'
import CoursePreviewBanner from 'components/CoursePreviewBanner'
import TeachView from 'components/TeachView'
import { isFlagOn } from 'utils/featureFlags'
import { isElementaryPage } from 'utils/productHelpers'
import { BannerSpacer, Wrapper } from './component.styles'
import { DEFAULT_UNIT } from './constants'
import { getData } from './data'

export function getClassId(location) {
  const queryParams =
    location.search
      ?.replace(/^\?/, '')
      .split('&')
      .map(kv => kv.split('='))
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}) ?? {}
  return queryParams.class
}

export function getClass(classes, classId) {
  return classes.find(c => c.instance === classId)
}

export function getCurrentUnit(ourClass, unitSlugs) {
  const index = ourClass.units?.findIndex(u => !u.is_done)
  const unitIndex = index >= 0 ? index + 1 : ourClass.units?.length
  if (!!unitSlugs && !!unitSlugs.length) {
    return unitSlugs[unitIndex - 1]
  }
  // NOTE: keeping this legacy behavior as a fallback, but ideally unitSlugs will always be populated:
  if (unitIndex === 5) {
    return 'recognizing-bullying-and-harassment'
  }
  return `unit-${unitIndex}`
}

const LessonsComponent = props => {
  const {
    classes = [],
    contentfulCourses,
    courseLinks,
    match,
    goToDashboard,
    productName,
    onClassClick,
    onCourseClick,
    onBacklinkClick,
    onUnitSelect,
    unitSlugs,
  } = props
  const baseUrl = match.url
  const basePath = match.path
  const productTitle = toTitleCase(`${productName?.replace('-', ' ')} Lessons`)
  const withVerb = `${basePath}/:verb(preview|teach)`

  if (
    isElementaryPage() &&
    isFlagOn(['feature_LEARN-18511-k5-elem-program-flex'])
  ) {
    console.log('[DEBUG] LessonsV2 rendering K5 in program flex')
  }

  return (
    <Wrapper>
      <Switch>
        {/* Previewing or teaching a specific unit */}
        <Route
          exact
          path={`${withVerb}/:course/:unit`}
          render={routerOptions => {
            const backlink = (
              <nav className="breadcrumbs">
                <BackLink dataTestId="breadcrumb" onClick={onBacklinkClick}>
                  {productTitle}
                </BackLink>
              </nav>
            )

            const unitId = routerOptions.match?.params?.unit
            const courseSlug = routerOptions.match.params.course
            const classId = getClassId(routerOptions.location)
            const selectedCourse = courseLinks.filter(
              c => c.slug === courseSlug,
            )?.[0]

            const notFound = <NotFound {...routerOptions} />
            if (!selectedCourse) {
              return notFound
            }

            const handleUnitSelect = unitSlug =>
              onUnitSelect(routerOptions.match.params.verb)(
                courseSlug,
                unitSlug,
              )

            if (routerOptions.match.params.verb === 'teach') {
              const ourClass = getClass(classes, classId)
              if (!ourClass) return notFound
              return (
                <>
                  {backlink}
                  <TeachView
                    backlink={backlink}
                    getData={getData}
                    handleUnitSelect={handleUnitSelect}
                    match={routerOptions.match}
                    ourClass={ourClass}
                    productName={productName}
                    selectedCourse={selectedCourse}
                    unitId={unitId}
                  />
                </>
              )
            }

            // We're at the preview route
            return (
              <>
                <BannerSpacer />
                <CoursePreviewBanner lessonsLink={baseUrl} />
                {backlink}
                <CoursePreview
                  getData={getData}
                  match={routerOptions.match}
                  onSelectUnit={handleUnitSelect}
                  productName={productName}
                  unitId={unitId}
                />
              </>
            )
          }}
        />
        {/* Previewing or teaching a course but no unit set; go to the first unit that's incomplete */}
        <Route
          exact
          path={`${withVerb}/:course`}
          render={routerProps => {
            const classId = getClassId(routerProps.location)
            const ourClass = getClass(classes, classId)
            const verb = routerProps.match.params.verb

            // TODO: Get the correct unit using lms data
            const unit =
              verb === 'teach'
                ? getCurrentUnit(ourClass, unitSlugs)
                : DEFAULT_UNIT
            return (
              <Redirect
                to={`${baseUrl}/${routerProps.match.params.verb}/${
                  routerProps.match.params.course
                }/${unit}${routerProps.location.search ?? ''}`}
              />
            )
          }}
        />
        {/* Previewing or teaching a with nothing specified; go to just /lessons */}
        <Redirect exact from={`${withVerb}`} to={`${baseUrl}`} />
        {/* Show courses to preview and classes to teach (if there are any) */}
        <Route
          path={baseUrl}
          render={() => (
            <>
              <nav className="breadcrumbs">
                <BackLink onClick={goToDashboard}>Dashboard</BackLink>
              </nav>
              <ProductLessons
                classes={classes}
                contentfulCourses={contentfulCourses}
                courseLinks={courseLinks}
                onClassClick={onClassClick}
                onCourseClick={onCourseClick}
                program={productName}
                title={productTitle}
              />
            </>
          )}
        />
      </Switch>
    </Wrapper>
  )
}

LessonsComponent.propTypes = {
  classes: PropTypes.arrayOf(
    PropTypes.shape({
      contentfulID: PropTypes.string,
      completeLessons: PropTypes.number,
      instance: PropTypes.string,
      scoId: PropTypes.number,
      totalLessons: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
  contentfulCourses: PropTypes.arrayOf(
    PropTypes.shape({
      displayTitle: PropTypes.string,
    }),
  ).isRequired,
  courseLinks: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  goToDashboard: PropTypes.func.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
    path: PropTypes.string,
    params: PropTypes.shape({
      productName: PropTypes.string,
    }),
  }).isRequired,
  onBacklinkClick: PropTypes.func.isRequired,
  onClassClick: PropTypes.func.isRequired,
  onCourseClick: PropTypes.func.isRequired,
  onUnitSelect: PropTypes.func.isRequired,
  productName: PropTypes.string,
  unitSlugs: PropTypes.arrayOf(PropTypes.string),
}

export default LessonsComponent
