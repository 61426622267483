import styled from 'styled-components'
import { themeGet } from 'styled-system'

const breakpoints = {
  aboveTablet: '@media (max-width: 910px)',
}

export const DropdownContainer = styled.div.attrs(props => ({
  border: props.outline
    ? `2px solid ${themeGet('colors.paleGray')(props)}`
    : 'none',
}))`
  display: none;
  width: 167px;
  [class*='StyledButton'][class*='DropdownButton'] {
    color: ${themeGet('colors.darkBlue')};
    background-color: ${themeGet('colors.white')};
    border: ${({ border }) => border};
    border-radius: 0.9375rem;
    padding-top: 0.375rem;
    padding-bottom: 0.25rem;
    svg {
      position: absolute;
      right: 0.75rem;
      color: ${themeGet('colors.darkBlue')};
    }
    &:hover {
      border-radius: 0.9375rem;
    }
  }

  [class*='DropdownOptions'] button svg {
    g path {
      fill: ${themeGet('colors.darkBlue')};
    }
  }
`

export const TabSetWrapper = styled.div`
  margin-bottom: 0.875rem;

  ${breakpoints.aboveTablet} {
    [class*='StyledTabs'] {
      display: none;
    }

    ${DropdownContainer} {
      display: block;
    }
  }
`

export const LineBreak = styled.p`
  content: '';
  display: block;
  height: 0.8125rem;
`

export const DropdownUnits = styled.div`
  button {
    background-color: hsl(0, 0%, 100%);
    border-radius: 1.5625rem;
    border: none;
    box-shadow: none;
    color: ${themeGet('select.colors.primary')};
    width: 100%;

    input {
      padding: 0.25rem 0.75rem 0.375rem;
      font-size: 1rem;
      font-family: ${themeGet('font.molde.semiBold')};
      font-weight: normal;
      line-height: 1.5;
    }

    svg {
      fill: ${themeGet('select.colors.primary')};
      stroke: ${themeGet('select.colors.primary')};
    }
  }
`

export const UnitOption = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.6rem;
  color: ${themeGet('select.colors.primary')};
  font-family: ${themeGet('font.molde.semiBold')};
  font-size: 1rem;

  &:hover,
  &:focus,
  &:visited {
    color: ${themeGet('select.colors.secondary')};
    background-color: ${themeGet('select.colors.primary')};

    svg {
      path {
        fill: ${themeGet('select.colors.secondary')};
      }
    }
  }

  svg {
    margin-left: 2em;
    margin-top: 0.4em;

    path {
      fill: ${themeGet('select.colors.primary')};
    }
  }
`
