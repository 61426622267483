// the order of the headers matters, the dataKeys do not
const typeHeaderMap = {
  moduleSela: {
    headers: [
      { key: 'name', label: 'Name' },
      { key: 'email', label: 'Email' },
      { key: 'role', label: 'Role' },
      { key: 'license_status', label: 'Invite Status' },
      { key: 'progress', label: 'Module Progress' },
      { key: 'children_completed', label: 'Microlearnings Completed' },
      { key: 'total_children', label: 'Microlearnings in this Module' },
    ],
  },
  unitSela: {
    headers: [
      { key: 'name', label: 'Name' },
      { key: 'email', label: 'Email' },
      { key: 'role', label: 'Role' },
      { key: 'license_status', label: 'Invite Status' },
      { key: 'progress', label: 'Unit Progress' },
      { key: 'children_completed', label: 'Microlearnings Completed' },
      { key: 'total_children', label: 'Microlearnings in this Unit' },
    ],
  },
}

export default typeHeaderMap
