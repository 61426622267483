import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { ContentContainer, PageWrapper, BannerSpacer } from './component.styles'
import HighSchoolPreviewBanner from 'components/HighSchoolPreviewBanner'

import SubLandingBanner from 'experienceComponents/SubLandingBanner'
import ContentCallout from 'experienceComponents/ContentCallout'

import NavigationHelper from 'layers/navigation/navigationHelper'

export default class ResourcesSubLandingPage extends Component {
  static propTypes = {
    articles: PropTypes.arrayOf(PropTypes.object),
    banner: PropTypes.object,
    entry: PropTypes.object,
    isHsPreview: PropTypes.bool,
    navigationEvent: PropTypes.func,
  }

  render() {
    const {
      articles = [],
      banner,
      isSelaLeaderPage,
      isSelaProduct,
      pageType,
      isHsPreview,
    } = this.props || {}

    const filteredArticles = articles.filter(article => {
      return article.body && article.title
    })

    this.navigateToDashboardOnClick = () => {
      const { navigationEvent } = this.props
      const url = `/dashboard`
      const type = NavigationHelper.types.PUSH
      navigationEvent(url, type)
    }

    return (
      <PageWrapper isSelaProduct={isSelaProduct}>
        {isHsPreview && (
          <>
            <BannerSpacer />
            <HighSchoolPreviewBanner />
          </>
        )}
        <SubLandingBanner
          isSelaLeaderPage={isSelaLeaderPage}
          key="banner"
          navigateToDashboardOnClick={this.navigateToDashboardOnClick}
          pageType={pageType}
          {...banner}
        />
        <ContentContainer>
          {filteredArticles.map((article, index) => {
            const uniqueId = `article-${index + 1}`
            return (
              <ContentCallout
                {...article}
                dataTestId={uniqueId}
                isHsPreview={isHsPreview}
                key={uniqueId}
              />
            )
          })}
        </ContentContainer>
      </PageWrapper>
    )
  }
}
