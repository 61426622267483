import { HS_MODE_KEY, HS_TEACH_MODE_VALUE } from 'utils/highschoolHelpers'
import { SITE_QUERY_PARAM } from 'layers/content/v2/Hocs/withSiteContext/constants'
import { PERIOD_PROGRESS } from 'components/HsIndividualResourceMarkComplete/flag.on/constants'
import {
  HIGH_SCHOOL_TRAINING_PATH,
  HIGH_SCHOOL_LEARNING_EXPERIENCE_PATH,
  METADATA_RESOURCE_TYPE,
  METADATA_GRADE,
  SCHOOLWIDE_RESOURCE_TYPE,
  EDUCATOR_PRACTICE_RESOURCE_TYPE,
  GRADE_TITLE_MAP,
  TRAINING,
  PATHWAYONE,
  PATHWAYTWO,
  PATHWAYTHREE,
  PATHWAYFOUR,
} from './constants'

export function parseProgramTrainings(dashboardProgramData) {
  const trainings = []
  dashboardProgramData?.instancedCurriculum?.[0]?.link?.content?.map(
    training => {
      trainings.push({
        courseNodeId: training?.courseNodeConnector?.courseNodeEntryPoint,
        title: training?.displayTitle,
        path: `${HIGH_SCHOOL_TRAINING_PATH}${training?.pageData?.route}`,
      })
    },
  )
  return trainings
}

function parseSchoolwideActivity(collectionData) {
  const activity = collectionData?.integrated?.filter(activity =>
    activity?.metadataValues?.some(
      metadata =>
        metadata?.contentType === METADATA_RESOURCE_TYPE &&
        metadata?.resourceType === SCHOOLWIDE_RESOURCE_TYPE,
    ),
  )?.[0]
  if (!activity) {
    console.warn('No schoolwide activity found for collection:', collectionData)
    return null
  }
  return {
    title: `${SCHOOLWIDE_RESOURCE_TYPE}: ${activity?.displayTitle}`,
    path: `${HIGH_SCHOOL_LEARNING_EXPERIENCE_PATH}${activity?.routeSlug}`,
    courseNodeId: activity?.courseNodeConnector?.courseNodeEntryPoint,
  }
}

function parseEducatorActivities(collectionData) {
  const activity = collectionData?.integrated?.filter(activity =>
    activity?.metadataValues?.some(
      metadata =>
        metadata?.contentType === METADATA_RESOURCE_TYPE &&
        metadata?.resourceType === EDUCATOR_PRACTICE_RESOURCE_TYPE,
    ),
  )?.[0]
  if (!activity) {
    console.warn('No educator activity found for collection:', collectionData)
    return null
  }
  return {
    title: `${EDUCATOR_PRACTICE_RESOURCE_TYPE}: ${activity?.displayTitle}`,
    path: `${HIGH_SCHOOL_LEARNING_EXPERIENCE_PATH}${activity?.routeSlug}`,
    courseNodeId: activity?.courseNodeConnector?.courseNodeEntryPoint,
  }
}

function parseStudentActivities(collectionData) {
  return collectionData?.dedicatedResources?.map(activity => {
    const grade = activity?.metadataValues?.find(
      metadata => metadata?.contentType === METADATA_GRADE,
    )?.grade
    return {
      // fallback to raw grade name if no match is found in map
      title: GRADE_TITLE_MAP[grade] || grade,
      path: `${HIGH_SCHOOL_LEARNING_EXPERIENCE_PATH}${activity?.routeSlug}`,
      courseNodeId: activity?.courseNodeConnector?.courseNodeEntryPoint,
    }
  })
}

function parseCollections(pathwayData) {
  const collections = []
  // collections are still referred to as "weeks" in Contentful
  pathwayData?.link?.weeks?.map(collection => {
    collections.push({
      // this is used to associate the content data with scope and sequence from course-manager:
      courseNodeId: collection?.courseNodeConnector?.courseNodeEntryPoint,
      schoolwide: parseSchoolwideActivity(collection),
      educatorPractice: parseEducatorActivities(collection),
      studentActivities: parseStudentActivities(collection),
    })
  })
  return collections
}

export function parsePathways(dashboardProgramData) {
  const pathways = []
  // skip index 0 because that's a training (this will break if we switch order or add more training lists):
  dashboardProgramData?.instancedCurriculum?.slice(1)?.map(pathway => {
    pathways.push({
      pathwayTitle: pathway?.link?.displayTitle,
      pathwayDescription: pathway?.link?.description,
      pathwayTraining: {
        courseNodeId:
          pathway?.link?.trainingCallout?.courseNodeConnector
            ?.courseNodeEntryPoint,
        title: pathway?.link?.trainingCallout?.displayTitle,
        path: pathway?.link?.trainingCallout?.route,
      },
      collections: parseCollections(pathway),
      pathwaysTitle: pathway?.displayTitle,
    })
  })
  return pathways
}

export function mergePacingDataIntoPathways(pathways, activeSitePacing) {
  return pathways.map((pathway, index) => {
    const collectionsWithPacing = activeSitePacing
      ?.map(activeNode => {
        const matchedCollection = pathways[index]?.collections.find(
          collection => collection?.courseNodeId === activeNode?.nodeKey,
        )
        if (!matchedCollection) {
          return null
        }
        return {
          pacing: activeNode,
          ...matchedCollection,
        }
      })
      .filter(collection => !!collection)
    return {
      ...pathway,
      collections: collectionsWithPacing,
    }
  })
}

export function parseStaticLinks(dashboardProgramData) {
  const staticLinks = []
  dashboardProgramData?.instancedStatic?.map(link => {
    staticLinks.push({
      title: link.displayTitle,
      description: link.linkLabel,
      path: link.path,
    })
  })
  return staticLinks
}

export function parseTileImages(dashboardProgramData) {
  const tileImages = []
  dashboardProgramData?.instancedCurriculum?.map(pathway => {
    tileImages.push(pathway?.tileImage?.url)
  })
  return tileImages
}

export function addInstanceToRoute(route, siteId) {
  return `${route}?${HS_MODE_KEY}=${HS_TEACH_MODE_VALUE}&${SITE_QUERY_PARAM}=${siteId}`
}

export function filterPathwayWithCourseTree(pathway, courseTree) {
  return {
    ...pathway,
    collections: pathway?.collections?.filter(collection =>
      courseTreeContainsCollection(courseTree, collection),
    ),
  }
}

export function courseTreeContainsCollection(courseTree, collection) {
  if (!collection?.courseNodeId || !courseTree) {
    return false
  }

  function searchCourseTree(node) {
    if (node.contentfulId === collection.courseNodeId) {
      return true
    }
    if (node.children) {
      return node.children.some(child => searchCourseTree(child))
    }
    return false
  }

  return searchCourseTree(courseTree)
}

export function formatPacingDates(startDate, endDate) {
  const options = { month: 'short', day: 'numeric' }

  const start = new Date(startDate)
  const end = new Date(endDate)

  // Check for invalid start date
  if (isNaN(start.getTime())) {
    console.warn('Invalid start date:', startDate)
    return ''
  }

  // Check for invalid end date
  if (isNaN(end.getTime())) {
    console.warn('Invalid end date:', endDate)
    return ''
  }

  // Format the dates
  const formattedStart = start.toLocaleDateString('en-US', options)
  const formattedEnd = end.toLocaleDateString('en-US', options)

  // Return the formatted date range
  return `${formattedStart}–${formattedEnd}`
}

export const getMatchedPacingNodes = (activeSitePacing, pathways) => {
  // If activeSitePacing or pathways is not an array, return an empty array
  if (!Array.isArray(activeSitePacing) || !Array.isArray(pathways)) {
    console.error('Invalid data: activeSitePacing or pathways is not an array.')
    return []
  }

  return activeSitePacing?.map(activeNode => {
    // Use optional chaining to safely access properties
    const matchedPathway = pathways?.find(
      pathway => pathway?.courseNodeId === activeNode?.nodeKey,
    )

    return {
      ...activeNode,
      matchedPathway: matchedPathway || null,
    }
  })
}

export const getTrainingNode = matchedNodes => {
  if (!Array.isArray(matchedNodes)) {
    console.error('Invalid data: matchedNodes is not an array.')
    return null
  }

  const trainingNode = matchedNodes.find(node => node?.nodeLevel === 'training')

  if (!trainingNode) {
    console.warn('No training node found in matchedNodes.')
  }

  return trainingNode || null
}

export const updateCollectionsWithTitle = (data, trainingNode) => {
  if (!Array.isArray(data)) {
    console.error('Invalid data: data is not an array.')
    return []
  }

  if (typeof trainingNode !== 'object' || trainingNode === null) {
    console.error('Invalid trainingNode: Not an object or is null.')
    return []
  }

  const pacingData = data.flatMap(item => {
    if (!Array.isArray(item?.collections)) {
      console.warn('Invalid or missing collections in item:', item)
      return []
    }

    return item.collections.map(collection => ({
      ...collection?.pacing,
      pathwaysTitle: item?.pathwaysTitle || 'Unknown Title',
    }))
  })

  const trainingData = {
    ...trainingNode,
    pathwaysTitle: TRAINING,
  }

  return [...pacingData, trainingData]
}

// TODO: remove stripTimeToUTC function after Koalified has fixed timestamps on the backend: https://secondstep.atlassian.net/browse/LEARN-18325
export const getCollectionWithinDateRange = collections => {
  const currentDate = new Date(Date.now())

  // Helper function to strip time and convert to UTC
  const stripTimeToUTC = date => {
    const utcDate = new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()),
    )
    return utcDate
  }

  const strippedCurrentDate = stripTimeToUTC(currentDate)

  if (!Array.isArray(collections) || collections.length === 0) {
    console.error('Invalid data: collections is not an array or is empty.')
    return -1
  }

  // Iterate over the collections to find the one with a date range that includes the current date
  const indexWithinDateRange = collections.findIndex(collection => {
    const startDate = stripTimeToUTC(new Date(collection?.startDate))
    const endDate = stripTimeToUTC(new Date(collection?.endDate))

    if (isNaN(startDate) || isNaN(endDate)) {
      console.warn('Invalid date found in collection:', collection)
      return false
    }

    return strippedCurrentDate >= startDate && strippedCurrentDate <= endDate
  })

  if (indexWithinDateRange !== -1) {
    return collections[indexWithinDateRange]
  }
  return null
}

export const setActiveCollectionIndex = (closestCollection, setActiveIndex) => {
  // Define the hardcoded array of collection titles
  const pathwaysTitles = [
    TRAINING,
    PATHWAYONE,
    PATHWAYTWO,
    PATHWAYTHREE,
    PATHWAYFOUR,
  ]
  if (typeof setActiveIndex !== 'function') {
    console.error('Invalid setActiveIndex: Not a function.')
    return
  }

  const activeIndex = pathwaysTitles?.indexOf(closestCollection?.pathwaysTitle)

  if (activeIndex !== -1 && activeIndex !== undefined) {
    setActiveIndex(activeIndex)
  } else {
    console.warn(
      'Collection title not found in the predefined list or invalid input.',
    )
  }
  return activeIndex
}

function getCourseNodeId(lesson) {
  const leafNodeId = lesson?.leaf_node_id || ''
  // format is courseNodeId-presentationId OR courseNodeId
  // we only care about courseNodeId:
  return leafNodeId.split('-')[0]
}

function createCompletionMap(instructorData) {
  const completionMap = {}
  for (const section of Object.values(instructorData)) {
    for (const lesson of section.lesson_nodes) {
      if (lesson) {
        completionMap[getCourseNodeId(lesson)] = {
          isComplete: lesson.is_done,
          isSkipped: lesson.period_progress === PERIOD_PROGRESS.SKIPPED,
        }
      }
    }
  }
  return completionMap
}

export function mergeInstructorDataIntoPathways(pathways, instructorData) {
  const completionMap = createCompletionMap(instructorData)

  for (const pathway of pathways) {
    pathway.pathwayTraining.isComplete =
      completionMap[pathway.pathwayTraining.courseNodeId]?.isComplete
    for (const collection of pathway.collections) {
      for (const activity of collection.studentActivities) {
        // set isComplete to false if no completion data is found...
        // only lessons with progress will have completion data
        activity.isComplete =
          completionMap[activity.courseNodeId]?.isComplete || false
      }
      collection.schoolwide.isComplete =
        completionMap[collection.schoolwide.courseNodeId]?.isComplete || false
      const educatorPracticeStatus =
        completionMap[collection.educatorPractice.courseNodeId]
      collection.educatorPractice.isComplete =
        educatorPracticeStatus?.isComplete || false
      // only educator practices support a SKIPPED status
      collection.educatorPractice.isSkipped =
        educatorPracticeStatus?.isSkipped || false
    }
  }
  return pathways
}

export function mergeInstructorDataIntoProgramTrainings(
  trainings,
  instructorData,
) {
  const completionMap = createCompletionMap(instructorData)

  for (const training of trainings) {
    training.isComplete =
      completionMap[training.courseNodeId]?.isComplete || false
  }
  return trainings
}

export function allProgramTrainingsComplete(programTrainings) {
  return programTrainings.every(training => training.isComplete)
}

export function allCollectionsComplete(pathway) {
  return (
    pathway.collections.every(
      collection =>
        // even a single complete student activity per collection is enough to count all of them as complete:
        collection?.studentActivities?.some(activity => activity.isComplete) &&
        collection?.schoolwide?.isComplete &&
        collection?.educatorPractice?.isComplete,
    ) && pathway?.pathwayTraining?.isComplete
  )
}
