import { createSlice } from '@reduxjs/toolkit'
import { createContentStoreKey } from './helpers'

export const initialState = {
  error: null,
  isFetchingContent: false,
  content: {},
}

const reducers = {
  fetchingContent: state => {
    state.error = null
    state.isFetchingContent = true
  },

  fetchContentError: (state, { payload }) => {
    const { error } = payload
    state.error = error
    state.isFetchingContent = false
  },

  fetchContentSuccess: (state, { payload }) => {
    const { entryId, data, userContext, locale } = payload

    const contentStoreKey = createContentStoreKey(entryId, locale)

    state.error = null
    state.isFetchingContent = false
    state.content[contentStoreKey] = {}
    state.content[contentStoreKey]['data'] = data
    state.content[contentStoreKey]['userContext'] = userContext
  },

  clearErrors: state => {
    state.error = initialState.error
  },
}

const slice = createSlice({ initialState, name: 'contentService', reducers })

export const actions = slice.actions
export default slice.reducer
