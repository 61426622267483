import styled from 'styled-components'
import { themeGet } from 'styled-system'
import ReportsModuleBox from 'components/ReportsModuleBox'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  max-width: 1366px;
`

export const Footer = styled(ReportsModuleBox)`
  border: unset;
  border-radius: 0;
  background: unset;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 0.25rem 1.25rem;

  a {
    color: ${themeGet('colors.blueGray')};
  }

  p:not(:last-child) {
    margin-block-end: 1.5rem;
  }

  p:first-child {
    margin-block-end: 0.625rem;
  }

  ${themeGet('breakpoints.mobile')} {
    padding: 0.875rem 1rem 1.25rem;
  }
`

export const PageWrapper = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  flex-direction: column;
  width: 100%;
  background: ${themeGet('colors.backgrounds.secondary')};
`

export const ReportWrapper = styled.div``
export const TableContainer = styled.div`
  padding: 1.25rem;
  ${themeGet('breakpoints.mobileLarge')} {
    padding: 1rem;
  }
`
