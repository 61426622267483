/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React, { useEffect } from 'react'
import { Switch, Redirect, withRouter } from 'react-router'
import { connect } from 'react-redux'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_CONTENTFUL_PRODUCT,
} from 'layers/errorHandling/apiError'
import { userSegmentationBuilder } from 'utils/userSegmentationHelpers'
import {
  AI_HS_PAGE_USAGE_KEY,
  sendApplicationInsightsEvent,
} from 'utils/sendAnalyticsDataHelpers'

const Container = props => {
  const { userSegmentationInfo } = props || {}

  useEffect(() => {
    sendApplicationInsightsEvent(AI_HS_PAGE_USAGE_KEY, userSegmentationInfo)
  }, [userSegmentationInfo])

  const { entry, match } = props || {}
  const searchParams = location?.search

  return (
    <Switch>
      <Redirect
        to={`${match.url}/${entry?.units?.[0]?.pageData?.route}${searchParams}`}
      />
    </Switch>
  )
}

const mapper = entry => {
  return { entry }
}

const options = {
  include: 3,
  mapper,
  entryIdProp: true,
  prefetch: ['es-US'],
  spread: true,
}

const mapStateToProps = state => ({
  userSegmentationInfo: userSegmentationBuilder(state),
})

export default ContentEntryDataGetter(
  withRouter(
    withApiError(Container, [
      ERROR_TYPE_CONTENTFUL,
      ERROR_TYPE_CONTENTFUL_PRODUCT,
    ]),
  ),
  options,
  connect(mapStateToProps),
)
