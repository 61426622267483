import { ENGLISH_LOCALE, SPANISH_LOCALE } from './data'

export const LOCALES = {
  [ENGLISH_LOCALE]: 'English',
  [SPANISH_LOCALE]: 'Spanish',
}

export const ERROR_TYPES = {
  routeServiceError: 'routeServiceError',
  contentServiceError: 'contentServiceError',
  courseManagerError: 'courseManagerError',
}
