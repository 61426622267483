import productMaps from 'utils/productMaps'
import { toCamelCase } from 'utils/stringHelpers'

const determineProductMap = name => {
  return productMaps[toCamelCase(name)]
}

export default {
  determineProductMap,
}
