import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { DeleteIcon, EllipsisIcon, SmallEditIcon } from 'secondstep-components'
import {
  ClassCardMenuButton,
  DeleteButton,
  DropdownMenuWrapper,
  EditButton,
  MyClassesMenuButton,
  Wrapper,
} from './component.styles'

const DropdownMenu = ({
  className,
  courseInstanceName,
  dataTestId,
  handleDeleteSubmit,
  handleEditSubmit,
  isClassCardMenu,
  forwardedRef,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = event => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keydown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keydown', handleClickOutside)
    }
  })

  const CardMenuButton = isClassCardMenu
    ? ClassCardMenuButton
    : MyClassesMenuButton

  return (
    <Wrapper className={className} ref={menuRef}>
      <CardMenuButton
        aria-label="Class Card Menu Button"
        className="dropdown-menu-button"
        dataTestId={dataTestId}
        isOpen={isOpen}
        onClick={() => setIsOpen(!isOpen)}
        ref={forwardedRef}
      >
        <EllipsisIcon />
      </CardMenuButton>
      {isOpen && (
        <DropdownMenuWrapper className="dropdown-menu-wrapper">
          <EditButton
            aria-label="Class Card Edit Button"
            className="edit-button"
            dataTestId="edit-button"
            onClick={() => handleEditSubmit()}
          >
            <SmallEditIcon />
            <p>Edit</p>
          </EditButton>
          <DeleteButton
            aria-label="Class Card Delete Button"
            className="delete-button"
            dataTestId="delete-button"
            onClick={() => handleDeleteSubmit()}
          >
            <DeleteIcon />
            <p>Delete</p>
          </DeleteButton>
        </DropdownMenuWrapper>
      )}
    </Wrapper>
  )
}
DropdownMenu.propTypes = {
  className: PropTypes.string,
  courseInstanceName: PropTypes.string,
  dataTestId: PropTypes.string,
  forwardedRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  handleDeleteSubmit: PropTypes.func,
  handleEditSubmit: PropTypes.func,
  isClassCardMenu: PropTypes.bool,
}

export default DropdownMenu
