import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { navigationEvent } from 'layers/navigation/store/operations'
import { operations as licenseOperations } from 'store/licenseManager'
import {
  findExpiredAdminLicenses,
  findExpiredNonAdminLicenses,
} from 'utils/licenseHelpers'
import ExpiredLicensesNotification from './component'
import { ExpiredLicensesNotificationWrapper } from './component.styles'

export const ExpiredLicensesNotificationContainer = ({
  adminLicenses,
  adminNotificationsExist,
  navigationEvent,
  nonAdminLicenses,
  nonAdminNotificationsExist,
  setNotificationAsRead,
}) => {
  return (
    <ExpiredLicensesNotificationWrapper>
      {adminLicenses.length > 0 && adminNotificationsExist && (
        <ExpiredLicensesNotification
          isAdmin
          licenses={adminLicenses}
          navigationEvent={navigationEvent}
          setNotificationAsRead={setNotificationAsRead}
        />
      )}
      {nonAdminLicenses.length > 0 && nonAdminNotificationsExist && (
        <ExpiredLicensesNotification
          licenses={nonAdminLicenses}
          navigationEvent={navigationEvent}
          setNotificationAsRead={setNotificationAsRead}
        />
      )}
    </ExpiredLicensesNotificationWrapper>
  )
}

ExpiredLicensesNotificationContainer.propTypes = {
  adminLicenses: PropTypes.array,
  adminNotificationsExist: PropTypes.bool,
  navigationEvent: PropTypes.func,
  nonAdminLicenses: PropTypes.array,
  nonAdminNotificationsExist: PropTypes.bool,
  setNotificationAsRead: PropTypes.func,
}

export const mapStateToProps = ({ licenseManager }) => {
  const {
    adminNotificationsExist,
    expiredLicenses,
    nonAdminNotificationsExist,
  } = licenseManager
  const expiredLicensesByDate = expiredLicenses.sort((a, b) => {
    return new Date(b.expirationDate) - new Date(a.expirationDate)
  })
  const schoolwideExpiredLicenses = expiredLicensesByDate.filter(
    ({ product }) => product?.type === 'BULK',
  )
  const sixMonthsPriorDate = new Date(
    moment()
      .subtract(6, 'months')
      .format('LLLL'),
  )
  const filteredLicenses = schoolwideExpiredLicenses.filter(
    ({ expirationDate }) => {
      return moment(new Date(expirationDate)) > sixMonthsPriorDate
    },
  )

  const adminLicenses = findExpiredAdminLicenses(filteredLicenses)
  const nonAdminLicenses = findExpiredNonAdminLicenses(filteredLicenses)

  return {
    adminLicenses,
    adminNotificationsExist,
    nonAdminLicenses,
    nonAdminNotificationsExist,
  }
}

const mapDispatchToProps = {
  navigationEvent,
  setNotificationAsRead: licenseOperations.setNotificationAsRead,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpiredLicensesNotificationContainer)
