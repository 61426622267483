import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { IconContainer, Badge } from './component.styles'
import Hoverable from 'components/Hoverable'
import { iconMap, NAVIGATION_ITEMS } from './data'

export default class GlobalNavItem extends Component {
  static propTypes = {
    ariaExpanded: PropTypes.bool,
    ariaLabel: PropTypes.string,
    backgroundColor: PropTypes.string,
    badge: PropTypes.string,
    borderColor: PropTypes.string,
    index: PropTypes.number,
    label: PropTypes.string,
    link: PropTypes.string,
    onPress: PropTypes.func,
    show: PropTypes.bool,
    source: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    theme: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = { hover: false }
  }

  onPress = () => {
    const { link, onPress } = this.props
    onPress && onPress(link)
  }

  onHover = hover => {
    this.setState({ hover })
  }

  badgeNumber = value => {
    if (value > 9) {
      return '+'
    }
    return value
  }

  render() {
    const {
      ariaExpanded,
      ariaLabel,
      backgroundColor,
      badge,
      borderColor,
      label,
      index,
      theme,
    } = this.props

    const { hover } = this.state || {}

    return (
      <Hoverable onHover={this.onHover} onPress={this.onPress}>
        <IconContainer
          aria-expanded={ariaExpanded}
          aria-label={ariaLabel || label}
          data-testid={`global-nav-icon-${label}-${index}`}
          hover={hover}
          theme={theme}
        >
          {NAVIGATION_ITEMS.map(({ label, title }, index) => {
            const Icon = iconMap[label]

            return (
              <Icon
                accessible="true"
                aria-labelledby="t1"
                focusable="false"
                key={`${index}-${label}`}
                role="img"
                title={title}
              />
            )
          })}
          {badge && (
            <Badge backgroundColor={backgroundColor} borderColor={borderColor}>
              {this.badgeNumber(badge)}
            </Badge>
          )}
        </IconContainer>
      </Hoverable>
    )
  }
}
