import ContentfulReducer from 'layers/content/store/reducer'
import contentModelNodes from '../nodes/contentModelNodes'

import { buildContentNode } from './models'

// action selector
export function getEntryIdAndEntryFromAction(action) {
  const { payload: entry, meta } = action || {}
  const { entryId } = meta || {}

  if (!entryId) {
    return null
  }

  return { entry, entryId }
}

export function getPathFromAction(action) {
  const { payload: actionPayload } = action || {}
  const { silent, value: pathname } = actionPayload || {}

  if (silent === undefined || !pathname) {
    return null
  }

  return { silent, pathname }
}

// state selectors
export function getPathFromState(state) {
  const { router } = state || {}
  const { location } = router || {}
  const { pathname } = location || {}

  if (!pathname) {
    return null
  }

  return pathname
}

export function getNamespaceIdFromState(state, namespace) {
  const { [ContentfulReducer.ROOT_NAMESPACE]: contentful } = state || {}
  const { [ContentfulReducer.CONTENT_NAMESPACE]: contentNamespace } =
    contentful || {}

  if (contentNamespace && contentNamespace[namespace]) {
    return contentNamespace[namespace]
  }

  return null
}

export function getContentEntryWrapperFromState(entryId, appState) {
  const { [ContentfulReducer.ROOT_NAMESPACE]: contentful } = appState || {}
  // entryWrapper has {isFetching, payload, error} wrapper. Payload is wrapped around the actual entry we want
  const entryWrapper = contentful[entryId] || null

  return entryWrapper
}

// specific entry from data selectors
export function getContentTypeFromEntry(entry) {
  const { contentType: contentTypeHolder } = entry || {}
  const { id: contentType } = contentTypeHolder || {}

  return contentType || null
}

export function getEntryFromParentContent(parentEntry, parentField, routeSlug) {
  const { [parentField]: parentArray = [] } = parentEntry || {}
  let desiredEntry

  // TODO: Adding HS Implementation guide https://secondstep.atlassian.net/browse/LEARN-17544
  if (routeSlug === 'implementation-guide') {
    desiredEntry = parentArray.find(item => {
      const { pageData } = item || {}
      const { route } = pageData || {}

      return route === routeSlug && item.id === '4ZErlVNUbLdWB4MJvEByfs'
    })
  } else if (routeSlug === 'hs-implementation-guide') {
    desiredEntry = parentArray.find(item => {
      const { pageData } = item || {}
      const { route } = pageData || {}

      return route === routeSlug && item.id === 'qxnIojrDaH27CjKZzc6zC'
    })
  } else {
    desiredEntry = parentArray.find(item => {
      const { pageData } = item || {}
      const { route } = pageData || {}

      return route === routeSlug
    })
  }

  if (desiredEntry) {
    return desiredEntry
  }

  return null
}

export function getChildEntry({
  path,
  parentPathLevel,
  parentEntry,
  parentField,
}) {
  if (!path || !parentPathLevel || !parentEntry || !parentField) {
    return null
  }

  const childSlug = getChildSlug(path, parentPathLevel)
  if (!childSlug) {
    return null
  }

  const childEntry = getEntryFromParentContent(
    parentEntry,
    parentField,
    childSlug,
  )

  if (!childEntry) {
    return null
  }

  return { childEntry, childSlug }
}

// content nodes selectors
export function getContentModelNode(contentType) {
  const modelNode = contentModelNodes[contentType]

  return modelNode || null
}

export function getEntryNextNodes(entry) {
  const contentType = getContentTypeFromEntry(entry)
  if (!contentType) {
    return null
  }

  const modelNode = getContentModelNode(contentType) || {}
  const nextNodesField = modelNode['nextNodesField']

  return nextNodesField || null
}

export function getContentNodesFromState(state) {
  const { [ContentfulReducer.ROOT_NAMESPACE]: contentful } = state || {}
  const { [ContentfulReducer.CONTENT_NODES]: contentNodes } = contentful || {}

  return contentNodes
}

export function getChildContentNode({
  path,
  currentPathLevel,
  parentEntry,
  parentField,
}) {
  if (!path || !currentPathLevel || !parentEntry || !parentEntry) {
    return null
  }

  const { childEntry = [], childSlug } =
    getChildEntry({
      path,
      parentPathLevel: currentPathLevel,
      parentEntry,
      parentField,
    }) || {}

  if (childEntry.length === 0 || childSlug === '' || !childSlug) {
    return null
  }

  const { id: childEntryId } = childEntry
  const contentType = getContentTypeFromEntry(childEntry)
  const { id: parentEntryId } = parentEntry
  const childPathLevel = currentPathLevel + 1

  if (
    !childEntryId ||
    !contentType ||
    !parentEntryId ||
    !childSlug ||
    !childPathLevel
  ) {
    return null
  }

  const childContentNode = buildContentNode({
    entryId: childEntryId,
    contentType,
    parentId: parentEntryId,
    routeSlug: childSlug,
    pathLevel: childPathLevel,
  })

  if (!childContentNode) {
    return null
  }

  return childContentNode
}

// slug and path selectors
export function getDesiredSlug(path, desiredPathLevel) {
  if (!path || !desiredPathLevel) {
    return null
  }

  const pathSlugs = path.split('/')

  if (Array.isArray(pathSlugs) && pathSlugs.length >= desiredPathLevel + 1) {
    return pathSlugs[desiredPathLevel]
  }

  return null
}

export function getChildSlug(pathname, parentPathLevel) {
  const childPathLevel = parentPathLevel + 1
  const childSlug = getDesiredSlug(pathname, childPathLevel)

  return childSlug || null
}
