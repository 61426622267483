import { getDefaultDates } from '../../utils/stateHelpers'
import { optimisticResponse } from '../../utils/reduxHelpers'

import { NAMESPACE, SUCCESS_TYPE, REQUEST_TYPE, FAILURE_TYPE } from './types'

export const initialState = { activeCourseDates: getDefaultDates() }
export const REDUCER_KEY = NAMESPACE

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_TYPE:
      return optimisticResponse(state, REDUCER_KEY)

    case FAILURE_TYPE:
      const error = action.payload || action.error
      return {
        ...state,
        isFetching: false,
        profile: {},
        error,
      }

    case SUCCESS_TYPE: {
      return {
        ...state,
        isFetching: false,
        profile: action.payload,
        error: action.error,
        lastFetched: Date.now(),
      }
    }

    default: {
      return state
    }
  }
}
