export const CLOSE = 'Close'
export const EMAIL = 'support@secondstep.org'
export const OFF = 'OFF'
export const ON = 'ON'
export const STATUS = 'Recognizing Bullying and Harassment Unit is currently: '
export const SECTION1 =
  'Unit 2 in Grades 6-8 has a new topic: Developing a Positive Sense of Self. The previous Unit 2, Recognizing Bullying & Harassment, is now available as a supplemental unit.'
export const SECTION2 =
  "In the future, you'll be able to activate the supplemental unit from the Leader Dashboard."
export const SUPPORT =
  'To activate access now, please contact Customer Support for help:'
export const TITLE = 'Middle School Units Are Changing'
