import { FlagsComponentRouter } from 'flags'

import OffComponent from './flag.off/index'
import OnComponent from './flag.on/index'

const FlagToggler = FlagsComponentRouter({
  flagSubscriptions: ['feature_LEARN-16712_learn-video-v3'],
  OnComponent,
  OffComponent,
})

export default FlagToggler
