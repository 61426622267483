import React from 'react'
import PropTypes from 'prop-types'
import { withAuth0 } from '@auth0/auth0-react'
import Auth0Manager from '../../utils/auth0Manager'

function Auth0PropWrapper(props) {
  Auth0Manager.setAuth0Manager(props.auth0)
  return <></>
}

Auth0PropWrapper.propTypes = {
  auth0: PropTypes.object.isRequired,
}

export default withAuth0(Auth0PropWrapper)
