import { connect } from 'react-redux'
import AdminSetup from './component'
import NavigationHelper from 'layers/navigation/navigationHelper'

const mapStateToProps = ({ userProfileManager }) => {
  const { profile } = userProfileManager
  return {
    firstName: profile.firstName,
  }
}

export default connect(
  mapStateToProps,
  NavigationHelper.mapDispatchToProps,
)(AdminSetup)
