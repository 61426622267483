import React from 'react'
import PropTypes from 'prop-types'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { REPORT_LINK_HREF } from './constants'
import {
  FullReportLink,
  GradeListWrapper,
  StyledGradeProgressData,
  StyledGraphIcon,
  StyledTextLink,
} from './component.styles'

const AdminGradeList = ({ bhuStatus, grades, navigationEvent, siteId }) => {
  const navigateToSite = siteId => {
    navigationEvent(`${REPORT_LINK_HREF}${siteId}`, NavigationHelper.types.PUSH)
  }

  return (
    <GradeListWrapper bhuStatus={bhuStatus}>
      {grades?.map(grade => {
        const {
          classesNotStarted,
          lessonsCompleted,
          name,
          numOfClasses,
          totalLessons,
        } = grade
        return (
          <StyledGradeProgressData
            bhuStatus={bhuStatus}
            classesNotStarted={classesNotStarted}
            grade={name}
            key={`grade-${name}-progress`}
            lessonsCompleted={lessonsCompleted}
            numOfClasses={numOfClasses}
            siteId={siteId}
            totalLessons={totalLessons}
          />
        )
      })}
      <FullReportLink>
        <StyledTextLink
          dataTestId="accessible-link-manage-invitations"
          onClick={() => navigateToSite(siteId)}
        >
          <StyledGraphIcon />
          See Lesson Completion Report
        </StyledTextLink>
      </FullReportLink>
    </GradeListWrapper>
  )
}

AdminGradeList.propTypes = {
  bhuStatus: PropTypes.bool,
  grades: PropTypes.array,
  navigationEvent: PropTypes.func,
  siteId: PropTypes.number,
}

export default AdminGradeList
