import React from 'react'
import PropTypes from 'prop-types'
import AdminHsMonitorSnapshot from 'components/AdminHsMonitorSnapshot'
import { MonitorViewWrapper } from './component.styles'

const AdminHSMonitorView = ({ site }) => {
  return (
    <MonitorViewWrapper>
      <AdminHsMonitorSnapshot site={site} />
    </MonitorViewWrapper>
  )
}

AdminHSMonitorView.propTypes = {
  site: PropTypes.object,
}

export default AdminHSMonitorView
