import React from 'react'
import PropTypes from 'prop-types'
import { HeaderThree } from 'secondstep-components'
import ResourceLinkList from '../ResourceLinkList'
import { ResourceFlex, Wrapper } from './component.styles'

const TrainingResourcePDFs = ({ dataTestId, resources }) => (
  <Wrapper dataTestId={dataTestId}>
    <HeaderThree>Training Resource PDFs</HeaderThree>
    <ResourceFlex>
      {resources.map(({ assets, displayTitle }) => (
        <ResourceLinkList
          header={displayTitle}
          id={displayTitle}
          key={displayTitle}
          links={assets}
        />
      ))}
    </ResourceFlex>
  </Wrapper>
)

TrainingResourcePDFs.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  resources: PropTypes.array.isRequired,
}

export default TrainingResourcePDFs
