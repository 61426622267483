import React from 'react'

import PropTypes from 'prop-types'

import {
  DEFAULT_LICENSE_ID,
  DEFAULT_PRODUCT,
  DEFAULT_SITE_NAME,
  UNKNOWN_LICENSE,
} from 'utils/productHelpers'

import { Container, Text } from './component.styles'

const SchoolOption = ({ licenseId, product, siteName }) => {
  const isUnknownLicense =
    licenseId === DEFAULT_LICENSE_ID &&
    product === DEFAULT_PRODUCT &&
    siteName === DEFAULT_SITE_NAME
  const content = isUnknownLicense ? (
    <span>{UNKNOWN_LICENSE}</span>
  ) : (
    <span>
      <span>{siteName}</span>
    </span>
  )

  return (
    <Container>
      <Text>{content}</Text>
    </Container>
  )
}

SchoolOption.propTypes = {
  licenseId: PropTypes.string,
  product: PropTypes.string,
  siteName: PropTypes.string,
}

SchoolOption.defaultProps = {
  licenseId: DEFAULT_LICENSE_ID,
  product: DEFAULT_PRODUCT,
  siteName: DEFAULT_SITE_NAME,
}

export default SchoolOption
