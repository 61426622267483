import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const CollectionCard = styled.div`
  cursor: pointer;
  background-color: ${themeGet('colors.hsSecondaryLightGreen')};
  // need to set height so h4 child can center vertically:
  height: auto;
  min-height: 3rem;
  border-radius: 0.625rem;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  // FIX these after redlines are available:
  padding: 1rem 1.188rem;
  margin: 1rem 0rem;
  :hover {
    border: 0.125rem solid ${themeGet('colors.highschool.green1')};
    h4 {
      // account for offset from border-box outline:
      margin-left: -0.125rem;
    }
  }
  h4 {
    margin: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: left;
    font-family: ${themeGet('fonts.moldeMedium18.fontFamily')};
    font-size: ${themeGet('fonts.moldeMedium18.fontSize')};
    // a little different than fonts.moldeMedium18.lineHeight:
    line-height: 1.25rem;
    color: ${themeGet('colors.hsSecondaryDarkGreen')};
  }
`
