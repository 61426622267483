import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'
import { BodyCopy, HeaderFour } from 'secondstep-components'

export const Wrapper = styled(Button).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: 386px;
  border-radius: 0.625rem;
  padding: 1.0625rem 1.25rem 0.75rem;
  background-color: ${({ isOnlyLeader }) =>
    isOnlyLeader
      ? themeGet('colors.modules.leaderPrimaryBackgroundHover')
      : themeGet('colors.buttons.tertiaryBackground')};

  &:hover {
    background-color: ${({ isOnlyLeader }) =>
      isOnlyLeader
        ? themeGet('colors.buttons.tertiaryAltBackgroundHover')
        : themeGet('colors.buttons.tertiaryBackgroundHover')};
  }

  ${HeaderFour} {
    font-size: 1.0625rem;
    line-height: 1.3125rem;
    margin-bottom: 0;
  }

  ${BodyCopy} {
    color: ${({ isOnlyLeader }) =>
      isOnlyLeader
        ? themeGet('colors.links.secondary')
        : themeGet('colors.links.primary')};
    font-family: ${themeGet('fonts.link')};
    line-height: 1.5rem;
  }

  ${themeGet('breakpoints.mobile')} {
    padding-right: 0;

    $hover:active {
      background-color: ${({ isOnlyLeader }) =>
        isOnlyLeader
          ? themeGet('colors.buttons.tertiaryAltBackgroundHover')
          : themeGet('colors.buttons.tertiaryBackgroundHover')};
    }
  }
`
