import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { VideoPlayButton } from 'secondstepVideo'
import ClosableModal from 'experienceComponents/ClosableModal'
import VideoDataGetter from 'experienceComponents/VideoDataGetter'

import {
  ThumbnailWrapper,
  VideoCalloutButton,
  VideoThumbnail,
} from './component.styles'
import { CLICK, ENTER } from './data'

export default class VideoFullWidth extends Component {
  static propTypes = {
    description: PropTypes.string,
    displayTitle: PropTypes.string,
    id: PropTypes.string,
    locale: PropTypes.string,
    tracks: PropTypes.array,
    transcriptExtended: PropTypes.object,
    transcriptSource: PropTypes.object,
    videoThumbnailUrl: PropTypes.string,
    videoUrl: PropTypes.string,
    videoUrlExtended: PropTypes.string,
  }

  constructor(props) {
    super(props)
    this.state = { showModal: false }
  }

  openModal = event => {
    const { key, type } = event || {}
    if (this.state.showModal) {
      return
    }

    if (type !== CLICK && key !== ENTER) {
      return
    }

    this.setState({ showModal: true })
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  render() {
    const { id, videoThumbnailUrl, videoUrl, locale, displayTitle } = this.props
    if (!videoThumbnailUrl || !videoUrl) {
      return null
    }

    const dataTestIdPrefix = `video-full-width`
    const dataTestIdOpenButton = `${dataTestIdPrefix}-open-button-${id}`

    return (
      <VideoCalloutButton
        data-testid={dataTestIdOpenButton}
        onClick={this.openModal}
        onKeyPress={this.openModal}
        role="button"
        tabIndex="0"
      >
        <ThumbnailWrapper>
          <VideoThumbnail
            fill="horizontal"
            fit="contain"
            src={videoThumbnailUrl}
          />
          <VideoPlayButton tabIndex="-1" />
        </ThumbnailWrapper>
        <ClosableModal
          id={id}
          modalTitle={displayTitle}
          onClose={this.closeModal}
          show={this.state.showModal}
        >
          <VideoDataGetter entryId={id} refetchLocale={locale} />
        </ClosableModal>
      </VideoCalloutButton>
    )
  }
}
