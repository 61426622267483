import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import pages from 'containers/Pages/pages'
import {
  AI_HS_PAGE_USAGE_KEY,
  sendApplicationInsightsEvent,
} from 'utils/sendAnalyticsDataHelpers'
import { RouteContentType } from 'layers/content/v2/Hocs/RouteContentGetter'
import { HS_TRAINING_URL } from './constants'

function Pages(props) {
  const { location, userSegmentationInfo, routeContent } = props || {}

  function getEntryWithNewLocale(newLocale) {
    routeContent.changeLocale(newLocale)
  }

  function getContentType() {
    if (!routeContent?.route?.queryType) {
      throw new Error('No contentType found')
    }

    return routeContent.route.queryType
  }

  useEffect(() => {
    const isHsTrainingPage = location.pathname.includes(HS_TRAINING_URL)
    if (isHsTrainingPage && routeContent) {
      sendApplicationInsightsEvent(AI_HS_PAGE_USAGE_KEY, userSegmentationInfo)
    }
  }, [])

  const contentType = getContentType()
  const isDistrictSupportsPage = location.pathname.includes(
    '/district/supports',
  )
  const isFamilyResourcesPage =
    location.pathname.includes('/family-resources/') ||
    location.pathname.includes('/family/high-school')
  const isSelaLeaderPage = location.pathname.includes('leader-hub')
  const isSelaProduct = location.pathname.includes('/product/sel-for-adults')

  const Page = pages[contentType]

  return Page ? (
    <Page
      getEntryWithNewLocaleCurrentNode={getEntryWithNewLocale}
      isDistrictSupportsPage={isDistrictSupportsPage}
      isFamilyResourcesPage={isFamilyResourcesPage}
      isSelaLeaderPage={isSelaLeaderPage}
      isSelaProduct={isSelaProduct}
      locale={routeContent.locale}
      location={location}
      {...props}
    />
  ) : null
}

Pages.propTypes = {
  location: PropTypes.object,
  routeContent: RouteContentType(PropTypes.object),
  userSegmentationInfo: PropTypes.object,
}

Pages.displayName = 'PagesV2'

export default Pages
