import { pacingNodesData } from '../components/PacingModal/data'

const ADMIN = 'admin'

export const transformSiteData = () => {
  const instructorData = {
    HighSchool: {
      schoolwide: {
        lesson_nodes: [
          {
            leaf_node_id: 'wM06At1kR3sUWMIQ5WqfP',
            progress: 1,
            is_done: true,
            period_progress: null,
            is_complete: true,
            done_at: '2024-06-27T12:00:00.000Z',
            updated_by: 999,
            type: 'schoolwideTraining',
            time: '2024-06-27T15:30:36.000Z',
          },
        ],
        user_id: 999,
        site: 'siteId',
        registration_id: 123456,
        lesson_types: ['schoolwideTraining', 'schoolwidePractices'],
      },
      educatorTraining: {
        lesson_nodes: [
          {
            leaf_node_id: '4khx4l5Uc80GUKzhfYGC61',
            progress: 0,
            is_done: false,
            period_progress: null,
            is_complete: false,
            done_at: '2024-06-27T12:00:00.000Z',
            updatedBy: 999,
            type: 'trainingProgram',
            time: '2024-06-27T15:30:36.000Z',
          },
        ],
        user_id: 999,
        site: 'siteId',
        registration_id: 123456,
        lesson_types: ['trainingProgram', 'trainingCurriculum'],
      },
      educatorPractices: {
        lesson_nodes: [
          {
            leaf_node_id: '6e4GDhndQOyRi4tEcTq0M8',
            progress: 0,
            is_done: false,
            period_progress: null,
            is_complete: false,
            done_at: '2024-06-27T12:00:00.000Z',
            updatedBy: 999,
            type: 'trainingCurriculum',
            time: '2024-06-27T15:30:36.000Z',
          },
        ],
        user_id: 999,
        site: 'siteId',
        registration_id: 123456,
        lesson_types: ['educatorPractices', 'studentActivities'],
      },
    },
  }

  const highSchoolData = instructorData.HighSchool

  return {
    name: 'HighSchool',
    siteId: highSchoolData.schoolwide.site,
    schoolwide: {
      ...highSchoolData.schoolwide,
    },
    educatorTraining: {
      ...highSchoolData.educatorTraining,
    },
    educatorPractices: {
      ...highSchoolData.educatorPractices,
    },
  }
}

export const getMSOptInData = sites => {
  if (!sites) return []
  return sites?.reduce((acc, site) => {
    if (
      site?.claims?.filter(claim => claim.toLowerCase() === ADMIN).length > 0 &&
      site?.sitePreferences?.mspDigital &&
      site?.licenses?.some(
        license =>
          license?.product?.programCodes?.includes('MSP') &&
          license?.expirationDate > new Date().toISOString() &&
          (license.cancelledDate == null ||
            license?.cancelledDate > new Date().toISOString()),
      )
    ) {
      acc.push({
        name: site.name,
        siteId: site.id,
        sitePreferences: {
          mspDigital: {
            bullyingHarassmentUnitEnabled:
              site?.sitePreferences?.mspDigital?.bullyingHarassmentUnitEnabled,
          },
        },
      })
    }
    return acc
  }, [])
}
export const calculateSitesWithBullyingUnitOnCount = mspSiteContexts => {
  return mspSiteContexts?.reduce((sitesCount, currentSite) => {
    if (
      currentSite?.sitePreferences?.mspDigital?.bullyingHarassmentUnitEnabled
    ) {
      sitesCount++
    }
    return sitesCount
  }, 0)
}

export const setSitePreferencesCalculatedFields = (
  newSitePreferences,
  stateSite,
) => {
  const sitePreferences = { ...newSitePreferences }
  const sitePacing = stateSite?.pacing
  const schoolYearEndDate = stateSite?.schoolYearEndDate

  if (sitePreferences?.highSchool) {
    const { highSchool } = sitePreferences
    const highSchoolPacing = sitePacing?.highschool || sitePacing?.highSchool

    const isImplementationPlanComplete =
      !!highSchool?.implementationLevel &&
      !!highSchool?.implementationType &&
      highSchool?.studentActivities != null

    const hasTrainingPacingDate =
      highSchoolPacing?.findIndex(
        pacingDate =>
          pacingDate.nodeKey === pacingNodesData.highSchool.nodeTraining,
      ) > -1
    const hasCollection1PacingDate =
      highSchoolPacing?.findIndex(
        pacingDate => pacingDate.nodeKey === pacingNodesData.highSchool.node1,
      ) > -1
    const isPacingComplete =
      !!schoolYearEndDate && hasTrainingPacingDate && hasCollection1PacingDate

    const isSetup =
      isImplementationPlanComplete &&
      isPacingComplete &&
      highSchool.areUsersAdded

    sitePreferences.highSchool = {
      ...sitePreferences.highSchool,
      isImplementationPlanComplete,
      isPacingComplete,
      isSetup,
    }
  }

  return sitePreferences
}

export const getUserContextProgramPreferences = (
  siteId,
  programName,
  stateUserContextSites,
) => {
  if (!stateUserContextSites || !siteId || !programName) {
    return null
  }
  const site = stateUserContextSites.find(site => site.siteId === siteId)
  return site?.programs[programName]
}
