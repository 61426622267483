import React from 'react'
import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const StyledContainer = styled.div`
  font-family: ${themeGet('font.molde.medium')};
  height: 1.188rem;
  display: flex;
  color: ${themeGet('colors.hsPrimaryPink')};
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1.063rem;
  white-space: nowrap;
  cursor: ${({ onClick }) =>
    onClick && typeof onClick === 'function' ? 'pointer' : 'auto'};

  &:hover {
    color: ${({ onClick }) =>
      onClick && typeof onClick === 'function'
        ? themeGet('colors.hsSecondaryGreen')
        : themeGet('colors.hsPrimaryPink')};
  }

  svg {
    margin-right: 0.375rem;
    margin-top: -0.188rem;
  }
`

export const SchoolIcon = props => (
  <svg
    aria-hidden="true"
    height="17px"
    role="presentation"
    viewBox="0 0 20 17"
    width="20px"
  >
    <g
      fill="none"
      fillRule="evenodd"
      id="ss_hs_icon_school"
      stroke="none"
      strokeWidth="1"
      {...props}
    >
      <g id="Group" stroke="currentColor" transform="translate(1, 1)">
        <path
          d="M4.82971168,14.5890993 L13.1706101,14.5890993 C13.6245267,14.5890993 13.9924989,14.2211271 13.9924989,13.7672104 L13.9924989,2.81869843 C13.9924989,2.49750392 13.8053929,2.20574007 13.5134832,2.07174511 L9.34303399,0.157388651 C9.1253701,0.0574746466 8.87495164,0.0574746466 8.65728775,0.157388651 L4.48683856,2.07174511 C4.19492888,2.20574007 4.00782285,2.49750392 4.00782285,2.81869843 L4.00782285,13.7672104 C4.00782285,14.2211271 4.37579501,14.5890993 4.82971168,14.5890993 Z"
          id="Path-5"
          strokeWidth="2"
        />
        <path
          d="M14.8143877,6.5720254 L17.1706101,6.5720254 C17.6245267,6.5720254 17.9924989,6.93999757 17.9924989,7.39391423 L17.9924989,13.7672104 C17.9924989,14.2211271 17.6245267,14.5890993 17.1706101,14.5890993 L13.9924989,14.5890993 L13.9924989,14.5890993 L13.9924989,7.39391423 C13.9924989,6.93999757 14.3604711,6.5720254 14.8143877,6.5720254 Z"
          id="Path-6"
          strokeWidth="2"
        />
        <path
          d="M0.821888829,6.5720254 L3.18561228,6.5720254 C3.63952895,6.5720254 4.00750111,6.93999757 4.00750111,7.39391423 L4.00750111,13.7672104 C4.00750111,14.2211271 3.63952895,14.5890993 3.18561228,14.5890993 L0,14.5890993 L0,14.5890993 L0,7.39391423 C-5.55887593e-17,6.93999757 0.367972163,6.5720254 0.821888829,6.5720254 Z"
          id="Path-6"
          strokeWidth="2"
          transform="translate(2.0038, 10.5806) scale(-1, 1) translate(-2.0038, -10.5806)"
        />
        <ellipse
          cx="8.9793224"
          cy="5.98537297"
          id="Oval"
          rx="1.75450067"
          ry="1.75512216"
          strokeWidth="1.3"
        />
        <rect
          fill="currentColor"
          height="3.16646666"
          id="Rectangle"
          rx="0.821888829"
          strokeWidth="1.64377766"
          width="1.96297334"
          x="7.99783573"
          y="10.8157987"
        />
      </g>
    </g>
  </svg>
)
