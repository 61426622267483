import styled from 'styled-components'
import { ProfileIcon, RightArrowIcon } from 'secondstep-components'
import { themeGet } from 'styled-system'

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const IconRightArrow = styled(RightArrowIcon).attrs(props => ({
  fill: themeGet('colors.themePrimary')(props),
}))`
  display: none;
  width: 15px !important;
  height: 15px !important;
  margin: 1px 0 0 0 !important;
`
export const Link = styled.a`
  text-decoration: none;
  color: ${themeGet('colors.themeAccent')};
  font-weight: 400;
  font-family: ${themeGet('font.molde.medium')};
  width: 100%;
  display: flex;
  justify-content: space-between;
  span {
    display: flex;
    align-items: center;
  }
  svg {
    margin: -10px 0rem 0.1875rem 0;
  }
`

export const IconProfile = styled(ProfileIcon).attrs(props => ({
  fill: themeGet('colors.themeAccent')(props),
  height: '15px',
  variant: 'sm',
  viewBox: '0 0 15 15',
  width: '15px',
}))`
  width: 15px !important;
  height: 15px !important;
  padding-right: 10px;
  margin: -1px 0 0 0 !important;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0;

  &:hover {
    ${IconRightArrow} {
      display: inline;
    }
    ${Link} {
      color: ${props => themeGet('colors.themePrimary')(props)};
    }
    ${IconProfile} g > path {
      fill: ${props => themeGet('colors.themePrimary')(props)};
    }
  }
`
