import React from 'react'
import PropTypes from 'prop-types'
import NavigationHelper from 'layers/navigation/navigationHelper'

import KitActivationLink from 'components/dashboard/KitActivationLink'
import FamilyAccessLink from 'components/dashboard/FamilyAccessLink'
import AccountDetailsLink from 'components/dashboard/AccountDetailsLink'
import {
  QuickLinksWrapper,
  LinkSeparator,
  Column,
  Row,
} from './component.styles'

export const QuickLinksCard = ({ className, navigationEvent }) => {
  /* istanbul ignore next */
  const navigateOutOfAppOnClick = url => {
    const type = NavigationHelper.types.OPEN
    navigationEvent(url, type)
  }
  /* istanbul ignore next */
  const navigateInAppOnClick = url => {
    const type = NavigationHelper.types.PUSH
    navigationEvent(url, type)
  }

  const navigationProps = {
    navigateInAppOnClick,
    navigateOutOfAppOnClick,
    navigationEvent,
  }

  return (
    <QuickLinksWrapper className={className} role="list">
      <Column>
        <Row>
          <LinkSeparator />
        </Row>
        <Row>
          <AccountDetailsLink />
        </Row>
        <Row>
          <KitActivationLink {...navigationProps} />
        </Row>
        <Row>
          <FamilyAccessLink {...navigationProps} />
        </Row>
      </Column>
    </QuickLinksWrapper>
  )
}

QuickLinksCard.propTypes = {
  className: PropTypes.string,
  navigationEvent: PropTypes.func,
}

export default QuickLinksCard
