import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BackpackV2Icon, TelescopeIcon } from 'secondstep-components'

export const StyledBackpackV2Icon = styled(BackpackV2Icon)`
  margin-top: -0.1rem;
  // scale the backpack relatively smaller than the other spot illustrations:
  scale: 0.9 !important;

  rect,
  line {
    stroke: ${({ theme }) =>
      theme.meta.themeName === 'elementary'
        ? themeGet('colors.elemDigital1')
        : themeGet('colors.blue')};
  }
  path,
  rect:nth-of-type(1) {
    stroke: ${({ theme }) =>
      theme.meta.themeName === 'elementary'
        ? themeGet('colors.elemDigital3')
        : themeGet('colors.darkBlue')}};
  }
  path:last-of-type {
    fill: ${({ theme }) =>
      theme.meta.themeName === 'elementary'
        ? themeGet('colors.lightGreen')
        : themeGet('colors.lighterBlue')};
  }
`

export const StyledTelescopeIcon = styled(TelescopeIcon)`
  path {
    stroke: ${({ theme }) =>
      theme.meta.themeName === 'elementary'
        ? themeGet('colors.elemDigital1')
        : themeGet('colors.blue')};
    fill: ${({ theme }) =>
      theme.meta.themeName === 'elementary'
        ? themeGet('colors.lightGreen')
        : themeGet('colors.lighterBlue')};
  }
  line,
  rect {
    stroke: ${({ theme }) =>
      theme.meta.themeName === 'elementary'
        ? themeGet('colors.elemDigital3')
        : themeGet('colors.darkBlue')}};
  }
`

export const Wrapper = styled.article`
  max-width: ${themeGet('breakpoints.sizes.large')};
  width: 100%;
  & header {
    display: block;
    width: 100%;

    & p {
      font-size: 1.0625rem;
    }
  }

  & section {
    color: ${themeGet('colors.cardText')};

    display: block;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    border-radius: ${themeGet('cardBorderRadius')};
    background-color: ${themeGet('colors.white')};
    box-shadow: 0 1px 15px 2px rgba(0, 0, 0, 0.1);
    padding: 1.25rem;
    padding-bottom: 0.625rem;
    ${themeGet('breakpoints.mobile')} {
      flex-direction: column;
    }

    .no-classes {
      text-align: center;
      padding-bottom: 1.25rem;
      p {
        font-family: 'SharpSlab-Medium', sans-serif;
        font-weight: 600;
        font-size: 1.375rem;
        color: ${themeGet('colors.themeSecondary')};
        margin: 2.5rem;
        margin-bottom: 1.75rem;
      }
    }

    .class-links,
    .course-links {
      list-style-type: none;
      padding: 0;
      margin: 0;
      li {
        display: inline-block;
        margin-right: 1rem;
        margin-top: 0.625rem;
        margin-bottom: 0.625rem;
      }
    }

    & header {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 2px solid ${themeGet('colors.paleGray')};
      padding-bottom: 1rem;
      margin-bottom: 0.625rem;
      width: 100%;

      .header-text {
        display: block;
        flex-grow: 1;

        .headline {
          display: flex;
          .spacer {
            flex-grow: 1;
          }
        }
      }

      & .spot {
        display: flex;
        color: ${themeGet('colors.themePrimary')};
        svg {
          margin-right: 1rem;
          width: 3rem;
          height: 3rem;
        }
      }
    }
  }
`
