import { Box } from 'grommet'
import styled from 'styled-components'
import { TextLink } from 'secondstep-components'
import { themeGet } from 'styled-system'

export const LinkBox = styled(Box)`
  ${TextLink} {
    margin-bottom: 0.375rem;
  }

  ${themeGet('breakpoints.tablet')} {
    width: 100%;
  }
`
