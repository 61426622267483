import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const SquaresContainer = styled.div`
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;

  ${themeGet('breakpoints.medium')} {
    width: calc(3 * 161px + 42px); // width of 3 squares + 2 lines
  }
  ${themeGet('breakpoints.mobileLarge')} {
    width: 10.063rem; // width of 1 square
  }
`

export const SquareContainer = styled.div.attrs(props => ({
  borderColor: props.isActive
    ? themeGet('colors.blossom')
    : themeGet('colors.lightGrayishBlue'),
}))`
  box-sizing: border-box;
  height: 2.5rem;
  width: 10.063rem;
  border: 2px solid ${({ borderColor }) => borderColor};
  border-radius: 5px;
  background-color: ${props => (props.isActive ? '#FAEEF3' : '#FFFFFF')};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  letter-spacing: 0;
  line-height: 1.25rem;
  color: ${props => (props.isActive ? '#023125' : '#555')};
  font-family: komet;
  font-size: 0.8rem;
  font-weight: 700;

  &:hover {
    border: 2px solid ${themeGet('colors.blossom')};
  }
`

export const Label = styled.span`
  ::first-letter {
    font-size: 1rem;
  }
`

export const Line = styled.div`
  flex: 1;
  min-width: 1.313rem;
  height: 2px;
  background-color: ${themeGet('colors.lightGrayishBlue')};
`

export const ArrowButton = styled.div`
  margin: 0 1.5rem;
  cursor: pointer;
  margin-top: 0.313rem;

  ${props =>
    props.disabled
      ? `
    cursor: not-allowed;
    svg {
      g {
        g {
          rect {
            fill: #E0E6EB;
          }
          polyline {
            stroke: #6B6B6B;
          }
        }
      }
    }
  `
      : `
    &:hover {
      svg {
        g {
          g {
            rect {
              fill: #f1f6ea;
            }
            polyline {
              stroke: #677f1a;
            }
          }
        }
      }
    }
  `}
`
