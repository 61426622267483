import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper } from './component.styles'
import { BodyCopy, HeaderFour } from 'secondstep-components'

const ModuleResourceCard = ({
  dataTestId,
  headerTitle,
  linkLabel,
  onClick,
  isOnlyLeader,
}) => {
  return (
    <Wrapper
      dataTestId={dataTestId}
      isOnlyLeader={isOnlyLeader}
      onClick={onClick}
    >
      <HeaderFour>{headerTitle}</HeaderFour>
      <BodyCopy>{linkLabel}</BodyCopy>
    </Wrapper>
  )
}

ModuleResourceCard.propTypes = {
  dataTestId: PropTypes.string,
  headerTitle: PropTypes.string,
  isOnlyLeader: PropTypes.bool,
  linkLabel: PropTypes.string,
  onClick: PropTypes.func,
}

export default ModuleResourceCard
