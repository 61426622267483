import React from 'react'
import PropTypes from 'prop-types'
import { getIconForTab } from './helpers'
import { Icon } from './component.styles'

function PathwayNavigationIcon({ isComplete, isInCurrentPacing }) {
  const { altText = '', icon } = getIconForTab({
    isComplete,
    isInCurrentPacing,
  })
  if (!icon) {
    return null
  }
  return <Icon alt={altText} src={icon} />
}

PathwayNavigationIcon.propTypes = {
  isComplete: PropTypes.bool,
  isInCurrentPacing: PropTypes.bool,
}

export default PathwayNavigationIcon
