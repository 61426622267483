import React from 'react'
import PropTypes from 'prop-types'
import { HeaderFive, PipeIcon, TextButton } from 'secondstep-components'
import { ChooseLessonBox, LessonABWrapper, LinksBox } from './component.styles'

const LessonAB = ({
  children,
  contentfulLesson,
  isOpen,
  lessonIndex,
  toggleSelectedLessonAB,
}) => {
  const lessonNumber = lessonIndex + 1
  return (
    <>
      {!children && (
        <LessonABWrapper dataTestId={`lesson-ab-${lessonNumber}`}>
          <HeaderFive dataTestId="header-ab-lesson">
            {`${lessonNumber}. Are your students starting or returning to middle school?`}
          </HeaderFive>
          <LinksBox>
            <TextButton
              dataTestId="button-set-lesson-choice-a"
              label="Starting"
              onClick={() => toggleSelectedLessonAB(0, lessonIndex)}
            />
            <PipeIcon />
            <TextButton
              dataTestId="button-set-lesson-choice-b"
              label="Returning"
              onClick={() => toggleSelectedLessonAB(1, lessonIndex)}
            />
          </LinksBox>
        </LessonABWrapper>
      )}
      {/* Pass contentfulLesson prop to children and overwrite Lessons' contentfulLesson prop */}
      {contentfulLesson &&
        React.Children.map(children, child => {
          return React.cloneElement(child, { contentfulLesson }, null)
        })}
      {children && isOpen && (
        <ChooseLessonBox>
          <TextButton
            dataTestId="button-set-lesson-choice-reset"
            onClick={() => toggleSelectedLessonAB('')}
          >
            See Both Lesson Options
          </TextButton>
        </ChooseLessonBox>
      )}
    </>
  )
}

LessonAB.defaultProps = {
  contentfulLesson: {},
}

LessonAB.propTypes = {
  children: PropTypes.node,
  contentfulLesson: PropTypes.object,
  isOpen: PropTypes.bool.isRequired,
  lessonIndex: PropTypes.number.isRequired,
  toggleSelectedLessonAB: PropTypes.func.isRequired,
}

export default LessonAB
