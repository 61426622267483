import React, { useState, Suspense } from 'react'
import PropTypes from 'prop-types'
import {
  MindYetiContentWrapper,
  MindYetiSpacer,
  MindYetiText,
  TrainingStatus,
  Wrapper,
  StyledModal,
} from './component.styles'
import { HeaderFive, TextLink, MindYetiStarSvg } from 'secondstep-components'
import { VideoV3 } from 'secondstepVideo'
import playButtonMainDefault from 'static/img/playButtonMainDefault.svg'
import playButtonMainHover from 'static/img/playButtonMainHover.svg'
import { iconMap, linkMap } from './data.js'

const ResourceAndTrainingCard = ({
  attempted,
  dataTestId,
  headerTitle,
  internalTitle,
  isMindYetiProduct,
  linkLabel = '',
  onClick,
  videoUrl,
}) => {
  const { Icon } = iconMap[internalTitle] || iconMap[headerTitle] || {}
  const { text, color } = linkMap[attempted] || {}
  const [showModal, setShowModal] = useState(false)

  const openModal = event => {
    if (!showModal && (event.type === 'click' || event.key === 'Enter')) {
      setShowModal(true)
    }
  }

  const closeModal = () => {
    if (showModal) {
      setShowModal(false)
    }
  }

  const openMethod = videoUrl ? openModal : onClick

  return (
    <Wrapper
      className="resource-and-training-card"
      dataTestId={dataTestId}
      onClick={openMethod}
    >
      {isMindYetiProduct && <MindYetiSpacer />}
      <HeaderFive>{headerTitle}</HeaderFive>
      {Icon && !text && (
        <TextLink dataTestId={`${dataTestId}-link`} onClick={openMethod}>
          <Icon />
          {linkLabel}
          <StyledModal
            dataTestId="video-callout-modal"
            onClose={closeModal}
            show={showModal}
            showClose
          >
            <Suspense fallback={<div>Loading...</div>}>
              <VideoV3
                styles={{
                  bigPlayButton: {
                    default: playButtonMainDefault,
                    hover: playButtonMainHover,
                  },
                }}
                videoUrl={videoUrl}
              />
            </Suspense>
          </StyledModal>
        </TextLink>
      )}
      {text && (
        <TextLink dataTestId={`${dataTestId}-link`} onClick={onClick}>
          <TrainingStatus statusColor={color} />
          {text}
        </TextLink>
      )}
      {isMindYetiProduct && (
        <MindYetiContentWrapper>
          <MindYetiStarSvg />
          <MindYetiText>{linkLabel}</MindYetiText>
        </MindYetiContentWrapper>
      )}
    </Wrapper>
  )
}

ResourceAndTrainingCard.propTypes = {
  attempted: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  headerTitle: PropTypes.string,
  internalTitle: PropTypes.string,
  isMindYetiProduct: PropTypes.bool,
  linkLabel: PropTypes.string,
  onClick: PropTypes.func,
  videoUrl: PropTypes.string,
}

export default ResourceAndTrainingCard
