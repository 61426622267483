import React from 'react'
import PropTypes from 'prop-types'

import {
  ClassHeaderWrapper,
  ClassInfoText,
  ClassTitle,
  ContentWrapper,
  LessonsComplete,
  ProgressContainer,
  ProgressSection,
  StyledProgressBar,
  TitleWrapper,
} from './component.styles.js'
import { BHU_TITLE, UNIT_1_4_TITLE } from './constants'

const ReportsClassHeader = ({
  bhuLessons,
  classTitle,
  fixedTop,
  fixedView,
  gradeName,
  hasBHU,
  lessonCount,
  lessonsCompleted,
  teacherFirstName,
  teacherLastName,
}) => {
  const bhuLessonsCount = bhuLessons?.length || 6
  const bhuTotalLessons = hasBHU ? bhuLessonsCount : 0
  const bhuLessonsCompleted =
    bhuLessons?.filter(lesson => lesson.completedDate !== null).length || 0
  const unit1Through4LessonCount = lessonCount - bhuTotalLessons
  const unit1Through4LessonComplete = lessonsCompleted - bhuLessonsCompleted
  const unit1Through4LessonPercentComplete = Math.floor(
    (lessonsCompleted / unit1Through4LessonCount) * 100,
  )
  const bhuPercentComplete = Math.floor(
    (bhuLessonsCompleted / bhuTotalLessons) * 100,
  )
  const bhuLessonsCompleteText = `${bhuLessonsCompleted} of ${bhuTotalLessons} lessons marked done`

  const classHeaderTitle = `Class: ${classTitle}`
  const learnerGradeText = `${teacherFirstName} ${teacherLastName} • ${gradeName}`
  const fixedViewLearnerGradeText = `Class: ${classTitle} • ${learnerGradeText}`
  const lessonsCompleteText = `${unit1Through4LessonComplete} of ${unit1Through4LessonCount} lessons marked done`
  const classInfoText = fixedView ? fixedViewLearnerGradeText : learnerGradeText

  return (
    <ClassHeaderWrapper fixedTop={fixedTop} fixedView={fixedView}>
      <ContentWrapper>
        <TitleWrapper>
          {!fixedView && (
            <ClassTitle dataTestId="class-title">{classHeaderTitle}</ClassTitle>
          )}
          <ClassInfoText data-testid="class-info-text">
            {classInfoText}
          </ClassInfoText>
        </TitleWrapper>
      </ContentWrapper>
      <ProgressContainer>
        <ProgressSection>
          {UNIT_1_4_TITLE}
          {!fixedView && (
            <StyledProgressBar
              dataTestId="class-progress-bar"
              percentComplete={unit1Through4LessonPercentComplete}
            />
          )}
          <LessonsComplete data-testid="lessons-complete">
            {lessonsCompleteText}
          </LessonsComplete>
        </ProgressSection>
        {hasBHU && (
          <ProgressSection>
            {BHU_TITLE}
            {!fixedView && (
              <StyledProgressBar
                dataTestId="class-progress-bar"
                percentComplete={bhuPercentComplete}
              />
            )}
            <LessonsComplete data-testid="lessons-complete">
              {bhuLessonsCompleteText}
            </LessonsComplete>
          </ProgressSection>
        )}
      </ProgressContainer>
    </ClassHeaderWrapper>
  )
}

ReportsClassHeader.propTypes = {
  bhuLessons: PropTypes.array,
  classTitle: PropTypes.string,
  fixedTop: PropTypes.bool,
  fixedView: PropTypes.bool,
  gradeName: PropTypes.string,
  hasBHU: PropTypes.bool,
  lessonCount: PropTypes.number,
  lessonsCompleted: PropTypes.number,
  teacherFirstName: PropTypes.string,
  teacherLastName: PropTypes.string,
}

export default ReportsClassHeader
