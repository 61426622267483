import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { ErrorBoundary } from 'secondstep-components'
import LoggerContext from 'components/LoggerContext'

const LearnErrorBoundary = props => {
  const logger = useContext(LoggerContext)?.logger
  return (
    <ErrorBoundary {...props} logger={logger}>
      {props.children}
    </ErrorBoundary>
  )
}

LearnErrorBoundary.propTypes = {
  children: PropTypes.node,
  message: PropTypes.string,
}

export default LearnErrorBoundary
