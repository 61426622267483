import dayjs from 'dayjs'

export const cleanObject = obj => {
  for (const prop in obj) {
    if (
      !obj.hasOwnProperty(prop) ||
      typeof obj[prop] !== 'object' ||
      obj[prop] === null
    ) {
      obj[prop] = null
    }
    cleanObject(obj[prop])
  }
  return obj
}

export const getDefaultDates = () => {
  const currentDate = dayjs(new Date().setHours(0, 0, 0, 0))
  const currentYear = currentDate.year()
  let cutoffDate
  let startDate
  let endDate

  const cutoff = {
    date: 1,
    month: 7,
    year: currentYear,
  }

  const start = {
    date: 1,
    month: 8,
    year: currentYear,
  }

  const end = {
    date: 30,
    month: 6,
    year: currentYear,
  }

  cutoffDate = dayjs(new Date(`${cutoff.month}/${cutoff.date}/${cutoff.year}`))
  startDate = dayjs(new Date(`${start.month}/${start.date}/${start.year}`))
  endDate = dayjs(new Date(`${end.month}/${end.date}/${end.year}`))

  if (currentDate < cutoffDate) {
    endDate.set({ year: startDate.year() + 1 })
  }

  if (currentDate >= cutoffDate) {
    startDate.set({ year: currentYear + 1 })
    endDate.set({ year: startDate.year() + 1 })
  }

  startDate = startDate.format('MM/DD/YYYY')
  endDate = endDate.format('MM/DD/YYYY')

  return {
    startDate,
    endDate,
  }
}
