import styled from 'styled-components'
import { themeGet } from 'styled-system'

// TODO: final theme will be implemented in ticket LEARN-9392

export const Wrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  padding: 0 ${themeGet('spacing.turquoise20_1_25rem')};
  width: calc(100% - (2 * ${themeGet('spacing.turquoise20_1_25rem')}));
  display: flex;
  flex-direction: column;
  ${themeGet('breakpoints.mobile')} {
    padding: 0 ${themeGet('spacing.blue16_1rem')};
    width: calc(100% - (2 * ${themeGet('spacing.blue16_1rem')}));
  }
`

export const Text = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin: 0;
  padding: 0;
  color: ${themeGet('colors.primaryCopy')};
  font-family: ${themeGet('fonts.label')};
  font-size: 1rem;
  letter-spacing: 0;
`

export const Background = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin: 0;
  margin-top: 0.3125rem;
  padding: 0;
  height: 0.9375rem;
  border-radius: 0.5313rem;
  background-color: ${themeGet('colors.progressBar.background')};
  width: 100%;
`

export const Indicator = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin: 0;
  height: 0.9375rem;
  border-radius: 0.5313rem;
  background-color: ${({ isOnlyLeader }) =>
    isOnlyLeader
      ? themeGet('colors.progressBar.leaderPrimary')
      : themeGet('colors.progressBar.primary')};
  width: ${({ percentComplete }) => `${percentComplete}%`};
`
