import React from 'react'
import PropTypes from 'prop-types'
import { Redirect, Route, Switch } from 'react-router'
import { NotFound } from 'secondstep-components'
import { toTitleCase } from 'utils/stringHelpers'
import BackLink from 'components/BackLink'
import CourseComponent from 'components/CourseComponent'
import CoursePreview from 'components/CoursePreview'
import LearnErrorBoundary from 'components/LearnErrorBoundary'
import ProductLessons from 'components/ProductLessons'
import CoursePreviewBanner from 'components/CoursePreviewBanner'
import UnitComponent from 'components/UnitComponent'
import { BannerSpacer, Wrapper } from './component.styles'
import { DEFAULT_UNIT } from './constants'

export function getClassId(location) {
  const queryParams =
    location.search
      ?.replace(/^\?/, '')
      .split('&')
      .map(kv => kv.split('='))
      .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {}) ?? {}
  return queryParams.class
}

export function getClass(classes, classId) {
  return classes.find(c => c.instance === classId)
}

export function getCurrentUnit(ourClass) {
  const index = ourClass.units?.findIndex(u => !u.is_done)
  const unitIndex = index >= 0 ? index + 1 : ourClass.units?.length
  return `unit-${unitIndex}`
}

const LessonsComponent = ({
  classes = [],
  contentfulCourses,
  courseLinks,
  match,
  goToDashboard,
  productName,
  onClassClick,
  onCourseClick,
  onBacklinkClick,
  onUnitSelect,
}) => {
  const baseUrl = match.url
  const basePath = match.path
  const productTitle = toTitleCase(`${productName?.replace('-', ' ')} Lessons`)
  const withVerb = `${basePath}/:verb(preview|teach)`

  return (
    <Wrapper>
      <Switch>
        {/* Previewing or teaching a specific unit */}
        <Route
          exact
          path={`${withVerb}/:course/:unit`}
          render={routerOptions => {
            const backlink = (
              <nav className="breadcrumbs">
                <BackLink dataTestId="breadcrumb" onClick={onBacklinkClick}>
                  {productTitle}
                </BackLink>
              </nav>
            )
            const courseSlug = routerOptions.match.params.course
            const classId = getClassId(routerOptions.location)
            const selectedCourse = courseLinks.filter(
              c => c.slug === courseSlug,
            )?.[0]

            const unitSlug = routerOptions.match?.params?.unit
            const matches = unitSlug?.match(/^unit-(\d+)$/)
            const imparsibleUnit = !matches

            const notFound = <NotFound {...routerOptions} />

            // We haven't checked if the course actually has
            // this unit because we don't have that data yet;
            // thus, we pass on the notFound component to our
            // children; this way we can pass on the appropriate
            // router information
            if (!selectedCourse || imparsibleUnit) {
              return notFound
            }

            const unitIndex = parseInt(matches[1], 10) - 1

            const handleUnitSelect = (_, unitId, unitIndex) =>
              onUnitSelect(routerOptions.match.params.verb)(
                courseSlug,
                unitId,
                unitIndex,
              )

            if (routerOptions.match.params.verb === 'teach') {
              const ourClass = getClass(classes, classId)
              if (!ourClass) return notFound
              return (
                <>
                  {backlink}
                  <LearnErrorBoundary>
                    <CourseComponent
                      displayTitle={selectedCourse.title}
                      entryId={selectedCourse.entryId}
                      instance={ourClass.instance}
                      isActive
                      isEditable
                      match={routerOptions.match}
                      title={ourClass.title}
                      totalAmount={ourClass.totalLessons}
                      totalComplete={ourClass.completeLessons}
                    >
                      <UnitComponent
                        entryId={selectedCourse.entryId}
                        match={routerOptions.match}
                        onSelectUnit={handleUnitSelect}
                        productName={productName}
                        unitEntryId={ourClass.units[unitIndex].contentfulID}
                        unitIndex={unitIndex}
                        units={ourClass.units}
                      />
                    </CourseComponent>
                  </LearnErrorBoundary>
                </>
              )
            }

            // We're at the preview route
            return (
              <>
                <BannerSpacer />
                <CoursePreviewBanner lessonsLink={baseUrl} />
                {backlink}
                <CoursePreview
                  entryId={selectedCourse.entryId}
                  match={routerOptions.match}
                  notFound={notFound}
                  onSelectUnit={handleUnitSelect}
                  productName={productName}
                  unitIndex={unitIndex}
                />
              </>
            )
          }}
        />
        {/* Previewing or teaching a course but no unit set; go to the first unit that's incomplete */}
        <Route
          exact
          path={`${withVerb}/:course`}
          render={routerProps => {
            const classId = getClassId(routerProps.location)
            const ourClass = getClass(classes, classId)
            const verb = routerProps.match.params.verb
            const unit =
              verb === 'teach' ? getCurrentUnit(ourClass) : DEFAULT_UNIT

            return (
              <Redirect
                to={`${baseUrl}/${routerProps.match.params.verb}/${
                  routerProps.match.params.course
                }/${unit}${routerProps.location.search ?? ''}`}
              />
            )
          }}
        />
        {/* Previewing or teaching a with nothing specified; go to just /lessons */}
        <Redirect exact from={`${withVerb}`} to={`${baseUrl}`} />
        {/* Show courses to preview and classes to teach (if there are any) */}
        <Route
          path={baseUrl}
          render={() => (
            <>
              <nav className="breadcrumbs">
                <BackLink onClick={goToDashboard}>Dashboard</BackLink>
              </nav>
              <ProductLessons
                classes={classes}
                contentfulCourses={contentfulCourses}
                courseLinks={courseLinks}
                onClassClick={onClassClick}
                onCourseClick={onCourseClick}
                program={productName}
                title={productTitle}
              />
            </>
          )}
        />
      </Switch>
    </Wrapper>
  )
}

LessonsComponent.propTypes = {
  classes: PropTypes.arrayOf(
    PropTypes.shape({
      contentfulID: PropTypes.string,
      completeLessons: PropTypes.number,
      instance: PropTypes.string,
      scoId: PropTypes.number,
      totalLessons: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
  contentfulCourses: PropTypes.arrayOf(
    PropTypes.shape({
      displayTitle: PropTypes.string,
    }),
  ).isRequired,
  courseLinks: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  goToDashboard: PropTypes.func.isRequired,
  match: PropTypes.shape({
    url: PropTypes.string,
    path: PropTypes.string,
    params: PropTypes.shape({
      productName: PropTypes.string,
    }),
  }).isRequired,
  onBacklinkClick: PropTypes.func.isRequired,
  onClassClick: PropTypes.func.isRequired,
  onCourseClick: PropTypes.func.isRequired,
  onUnitSelect: PropTypes.func.isRequired,
  productName: PropTypes.string,
}

export default LessonsComponent
