export const BELONGING_AND_CONNECTION = 'Belonging <br>& Connection'
export const BELONGING_AND_CONNECTION_LINK =
  '/product/high-school/pathways/belonging-and-connection'

export const CONFIDENCE_AND_CAPABILITY = 'Confidence <br>& Capability'
export const CONFIDENCE_AND_CAPABILITY_LINK =
  '/product/high-school/pathways/confidence-and-capability'

export const AGENCY_AND_OPPORTUNITY = 'Agency <br>& Opportunity'
export const AGENCY_AND_OPPORTUNITY_LINK =
  '/product/high-school/pathways/agency-and-opportunity'

export const WELL_BEING_AND_COMMUNITY = 'Well-Being <br>& Community'
export const WELL_BEING_AND_COMMUNITY_LINK =
  '/product/high-school/pathways/well-being-and-community'

export const EXPLORE_PROGRAMS_TITLE = 'Explore the Program'
export const PATHWAYS_TITLE = 'Pathways'
export const PRACTICES_TITLE = 'See My Practices'

export const HS_DASHBOARD_LINK = '/dashboard/grades-9-12'
export const IN_PROGRESS = 'In Progress'

export const PACING_CONTENT = {
  '1qn7Y1s7wgw1159p6wvr7e': {
    link: '/product/high-school/pathways/belonging-and-connection/collection-1',
    textName: 'Belonging & Connection: Collection 1',
  },
  '55fJ7n3HGF4mIgKayEvfC7': {
    link: '/product/high-school/pathways/belonging-and-connection/collection-2',
    textName: 'Belonging & Connection: Collection 2',
  },
  '3defdpTgDyBjcATjMzKIld': {
    link: '/product/high-school/pathways/belonging-and-connection/collection-3',
    textName: 'Belonging & Connection: Collection 3',
  },
  CzQRYgvGwLbkAt2EJ3UZV: {
    link:
      '/product/high-school/pathways/confidence-and-capability/collection-1',
    textName: 'Confidence & Capability: Collection 1',
  },
  '7944Hyt2zPsu2dW2DIWItM': {
    link:
      '/product/high-school/pathways/confidence-and-capability/collection-2',
    textName: 'Confidence & Capability: Collection 2',
  },
  '675URDiS6lAuVAyWRjzC6h': {
    link:
      '/product/high-school/pathways/confidence-and-capability/collection-3',
    textName: 'Confidence & Capability: Collection 3',
  },
  '0177b4RUNJw5q6e8VaIirP': {
    link: '/product/high-school/pathways/agency-and-opportunity/collection-1',
    textName: 'Agency & Opportunity: Collection 1',
  },
  irTIW0uUaCtJbgGkXxJYS: {
    link: '/product/high-school/pathways/agency-and-opportunity/collection-2',
    textName: 'Agency & Opportunity: Collection 2',
  },
  '1FXXLm7EV6svfb7J3sWaeR': {
    link: '/product/high-school/pathways/agency-and-opportunity/collection-3',
    textName: 'Agency & Opportunity: Collection 3',
  },
  '5piI4wQ4bJels2cS24JRXx': {
    link: '/product/high-school/pathways/well-being-and-community/collection-1',
    textName: 'Well-being & Community: Collection 1',
  },
  '1enBCq1RUZiup71e0cG1g0': {
    link: '/product/high-school/pathways/well-being-and-community/collection-2',
    textName: 'Well-being & Community: Collection 2',
  },
  '2j6j9nxr2nzEH4K65VgmVA': {
    link: '/product/high-school/pathways/well-being-and-community/collection-3',
    textName: 'Well-being & Community: Collection 3',
  },
  '5Po40tZBftn2ZlRYtmECWz': {
    link: '/product/high-school/training',
    textName: 'Program Training: Parts 1 & 2',
  },
  beforeTraining: {
    link: '/product/high-school/training/program-kick-off',
    textName: 'Program Kickoff',
  },
}
