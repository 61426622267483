import React, { Component } from 'react'
import { TouchableWithoutFeedback, View } from 'react-native'
import PropTypes from 'prop-types'
import { onMouseEnter, onMouseLeave, onPress } from './core'

export default class Hoverable extends Component {
  static propTypes = {
    children: PropTypes.object,
    onHover: PropTypes.func,
    onPress: PropTypes.func,
  }

  onMouseEnter = onMouseEnter.bind(this)
  onMouseLeave = onMouseLeave.bind(this)
  onPress = onPress.bind(this)

  render() {
    const { children } = this.props

    return (
      <TouchableWithoutFeedback onPress={this.onPress}>
        <View
          onMouseEnter={this.onMouseEnter}
          onMouseLeave={this.onMouseLeave}
          style={{ flexGrow: '1' }}
        >
          {children}
        </View>
      </TouchableWithoutFeedback>
    )
  }
}
