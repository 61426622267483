import styled from 'styled-components'
import { HeaderFour } from 'secondstep-components'
import { getHighlightColor } from 'utils/highschoolHelpers'
import constants from 'themes/variables'
import HighSchoolLessonStatusIcon from 'components/dashboard/HighSchoolLessonStatusIcon'

const palette = constants.colors

export const CardItem = styled.div.attrs(({ isSingleCard }) => {
  const height = isSingleCard ? '20.375rem' : '26.7rem'
  const width = isSingleCard ? '29.124rem' : 'auto'

  return {
    height,
    width,
  }
})`
  position: relative;

  cursor: pointer;
  box-sizing: border-box;
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  border: 0.0938rem solid #ececec;
  border-radius: 1.0625rem;
  margin-top: 1rem;
  margin-right: 0.375rem;

  :hover {
    border: 0.0938rem solid #c62d70;
  }
  :focus {
    outline: 0.0938rem solid #c62d70;
    outline-offset: -0.125rem;
  }

  ${({ theme }) => theme?.breakpoints?.mobileLarge} {
    width: 100%;
    height: initial;
    max-width: 100%;
    margin-top: 0;
    margin-right: 0;
  }

  .ais-Highlight-highlighted {
    background-color: ${palette.highschool.pink4};
  }
`
export const HighSchoolLibraryStatusIconContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0.875rem 0.3125rem 0 0;
  justify-content: flex-end;
  box-sizing: border-box;
`
export const HighSchoolLibraryStatusIcon = styled(HighSchoolLessonStatusIcon)`
  position: relative;
  height: 1rem;
  width: 1rem;
`
export const CardImage = styled.img`
  height: 7.5rem;
  width: 100%;
  border-radius: 1rem 1rem 0 0;
  object-fit: cover;
  ${({ theme }) => theme?.breakpoints?.mobileLarge} {
    width: 100%;
  }
`

export const AudienceAndGradeContainer = styled.div`
  display: flex;
  flex-direction: row;

  ${({ theme }) => theme?.breakpoints?.mobileLarge} {
    margin-bottom: 0.875rem;
  }
  ${({ theme }) => theme?.breakpoints?.tablet} {
    margin-bottom: 0.875rem;
  }
`
export const HighlightBox = styled.div.attrs(({ audience }) => ({
  highlightColor: getHighlightColor(audience),
}))`
  margin-top: 1.125rem;
  border-radius: 0.25rem;
  background-color: ${({ highlightColor }) => highlightColor};
  padding: 0 0.25rem;
  font-size: 0.75rem;
  color: #023125;

  ${({ theme }) => theme?.breakpoints?.mobileLarge} {
    margin-top: 1rem;
    line-height: 0.875rem;
    padding: 0.375rem 0.75rem;
  }
`
export const HighlightGradeBox = styled.div`
  margin-left: 0.5rem;
  background-color: #daeba9;
  margin-top: 1.125rem;
  border-radius: 0.25rem;
  padding: 0 0.25rem;
  font-size: 0.75rem;
  color: #023125;

  ${({ theme }) => theme?.breakpoints?.mobileLarge} {
    margin-top: 1rem;
    line-height: 0.875rem;
    padding: 0.375rem 0.75rem;
  }
`
export const TextWrapper = styled.div.attrs(({ isSingleCard }) => {
  const width = isSingleCard ? '100%' : '11.875rem'

  return {
    width,
  }
})`
  padding: 0 1rem 0 1rem;
  .unit {
    color: #555555;
    font-family: Molde-regular;
    font-size: 0.875rem;
    letter-spacing: 0;
    line-height: 1rem;
    margin-bottom: 0.813rem;
    margin-top: 0.35rem;
  }
  .description {
    color: #555555;
    font-family: Molde-regular;
    font-size: 1rem;
    letter-spacing: 0;
    line-height: 1.375rem;
    height: 6.688rem;
    width: ${({ width }) => width};
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
    overflow: hidden;
    margin-bottom: 1.5rem;
  }

  ${({ theme }) => theme?.breakpoints?.tablet} {
    padding-right: 1rem;
    padding-left: 1rem;
    padding-bottom: 1.5rem;

    .description {
      text-align: left;
      width: initial;
      height: initial;
      margin-bottom: 0;
    }

    .unit {
      margin-bottom: 1rem;
    }
  }
`
export const Title = styled(HeaderFour)`
  margin-top: 1rem;
  margin-bottom: 0.938rem;
  ${({ theme }) => theme?.breakpoints?.mobileLarge} {
    line-height: 1.25rem;
    clip: auto !important;
    clip-path: none !important;
    width: 100% !important;
    overflow: visible !important;
  }
  ${({ theme }) => theme?.breakpoints?.tablet} {
    position: unset !important;
    line-height: 1.25rem;
    clip: auto !important;
    clip-path: none !important;
    width: 100% !important;
    overflow: visible !important;
  }
`
export const ResourceDurationContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: #555555;
  font-family: Molde-medium;
  font-size: 0.875rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 1rem;
`
