/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React from 'react'
import PropTypes from 'prop-types'
import HighSchoolPreviewBanner from 'components/HighSchoolPreviewBanner'

import NavigationHelper from 'layers/navigation/navigationHelper'
import { HIGH_SCHOOL_INDIVIDUAL_RESOURCE_PATH } from 'utils/highschoolHelpers'

import {
  PageContainer,
  LayoutContainer,
  BannerSpacer,
} from './component.styles'
import { CardSection } from './cardSection'

const Week = props => {
  const { entry, navigationEvent, isPreview } = props
  const {
    displayTitle,
    description,
    integrated,
    facetCardSetting,
    dedicatedResources,
    dedicatedLabel,
    integratedLabel,
    tooltips,
  } = entry || {}

  const onClick = routeSlug => {
    const type = NavigationHelper.types.NEW_TAB_SAME_SITE
    const { search: searchParams } = location
    navigationEvent(
      `${HIGH_SCHOOL_INDIVIDUAL_RESOURCE_PATH}/${routeSlug}${searchParams}`,
      type,
    )
  }

  return (
    <>
      {isPreview && (
        <>
          <BannerSpacer />
          <HighSchoolPreviewBanner />
        </>
      )}
      <LayoutContainer>
        <PageContainer>
          <h1>{displayTitle}</h1>
          <p className="description">{description}</p>
          {integrated && (
            <CardSection
              facetCardSetting={facetCardSetting}
              headerText={integratedLabel}
              isIntegratedSection
              onClick={onClick}
              resources={integrated}
              tooltips={tooltips}
            />
          )}

          {dedicatedResources && (
            <CardSection
              facetCardSetting={facetCardSetting}
              headerText={dedicatedLabel}
              onClick={onClick}
              resources={dedicatedResources}
              tooltips={tooltips}
            />
          )}
        </PageContainer>
      </LayoutContainer>
    </>
  )
}

Week.propTypes = {
  entry: PropTypes.shape({
    displayTitle: PropTypes.string.isRequired,
    dedicatedLabel: PropTypes.string,
    integratedLabel: PropTypes.string,
    tooltips: PropTypes.array,
    description: PropTypes.string.isRequired,
    facetCardSetting: PropTypes.shape({
      facets: PropTypes.array,
    }),
    integrated: PropTypes.shape({
      map: PropTypes.func,
    }),
    dedicatedResources: PropTypes.shape({
      map: PropTypes.func,
    }),
  }).isRequired,
  isPreview: PropTypes.bool,
  navigationEvent: PropTypes.func,
}

export default Week
