import { put } from 'redux-saga/effects'
import autoBind from 'auto-bind'

import ContenfulTypes from 'layers/content/store/types'
import { NAVIGATION_EVENT } from '../../../../navigation/store/types'
import SagaLifeCycle from '../../../../saga/lifecycle'
import ContentDataEntryManager from '../../../../content/Hocs/dataManagers/contentDataEntryManager'
import ContentfulActions from 'layers/content/store/actions'

import {
  getChildContentNode,
  getContentNodesFromState,
  getDesiredSlug,
  getEntryIdAndEntryFromAction,
  getEntryNextNodes,
  getNamespaceIdFromState,
  getPathFromAction,
} from '../../utils/selectors'
import {
  isANamespacePath,
  isAProductPath,
  isLastRoute,
  verifyRouteIsOnEntry,
  verifyThatStartTreeIsNew,
  verifyWeAreOnProperPath,
} from '../../utils/verify'
import { getEntryForLearnLayer } from '../../utils/api'
import { getIsoTime } from '../../utils/utils'
import ContentfulNodeFailureTypes from 'layers/learn/content/nodes/failureTypes'
const { CONTENTFUL_REQUEST, CONTENTFUL_SUCCESS } = ContenfulTypes

export default class NodeContentStartSaga extends SagaLifeCycle {
  constructor(registeredNamespace, blocklist = [], locale) {
    super()
    this.registeredNamespace = registeredNamespace
    this.registeredRouteLevel = null
    this.blocklist = blocklist
    this.locale = locale
    autoBind(this)
  }

  resolveBlocklist() {
    if (!this.blocklist) {
      return []
    }
    return typeof this.blocklist === 'function'
      ? this.blocklist()
      : this.blocklist
  }

  *process(data) {
    const {
      CONTENTFUL_SUCCESS: contentSuccess,
      NAVIGATION_EVENT: nav,
      CONTENTFUL_REQUEST: contentOptimistic,
    } = data
    const entry = contentSuccess || contentOptimistic

    const { value: pathname } = nav

    const { startPath } = this.getState(getContentNodesFromState) || {}
    const isNamespacePath = isANamespacePath(pathname)
    const isProductPath = isAProductPath(pathname)

    // registeredRouteLevel is used to determine which node to start from.
    // If registeredRouteLevel = 1 we start on the namespace's node.
    // e.g. /district/supports, we start at district
    // If registeredRouteLevel = 2 we start on the product's node.
    // e.g. /product/middle-school we start at middle-school
    if (isNamespacePath) {
      this.registeredRouteLevel = 1
    } else if (isProductPath) {
      this.registeredRouteLevel = 2
    } else {
      this.earlyExit(ContentfulNodeFailureTypes.NOT_ON_DESIRED_PATH, {
        pathname,
      })
    }

    const currentSlug = getDesiredSlug(pathname, this.registeredRouteLevel)

    this._shouldContentNodeStartRun({
      entry,
      startPath,
      pathname,
      currentSlug,
      blocklist: this.resolveBlocklist(),
    })

    const { childContentNode, isChildLastRoute } = this._buildStartNode({
      parentEntry: entry,
      pathname,
      pathLevel: this.registeredRouteLevel,
    })

    const startTime = getIsoTime()

    yield this._addFirstChild({
      childContentNode,
      isChildLastRoute,
      pathname,
      startTime,
    })
  }

  _shouldContentNodeStartRun({
    entry,
    startPath,
    pathname,
    currentSlug,
    blocklist,
  }) {
    const isOnProperPath = verifyWeAreOnProperPath(pathname, blocklist)
    if (!isOnProperPath) {
      this.earlyExit(ContentfulNodeFailureTypes.NOT_ON_DESIRED_PATH, {
        pathname,
      })
    }
    const isNewTree = verifyThatStartTreeIsNew(pathname, startPath)
    if (!isNewTree) {
      this.earlyExit(ContentfulNodeFailureTypes.DID_NOT_VERIFY_RUN_START_NODE)
    }
    const isRouteOnEntry = verifyRouteIsOnEntry(entry, currentSlug)
    if (!isRouteOnEntry) {
      this.earlyExit(ContentfulNodeFailureTypes.DID_NOT_VERIFY_RUN_START_NODE, {
        pathname,
        currentSlug,
      })
    }
  }

  _buildStartNode({ parentEntry, pathname, pathLevel }) {
    const parentNextNodesField = getEntryNextNodes(parentEntry)

    if (!parentNextNodesField) {
      this.earlyExit(ContentfulNodeFailureTypes.DID_NOT_CREATE_START_NODE_TREE)
    }

    const childContentNode = getChildContentNode({
      path: pathname,
      currentPathLevel: pathLevel,
      parentEntry,
      parentField: parentNextNodesField,
    })

    if (!childContentNode) {
      this.earlyExit(ContentfulNodeFailureTypes.DID_NOT_CREATE_START_NODE_TREE)
    }

    const { pathLevel: childPathLevel } = childContentNode || {}
    const isChildLastRoute = isLastRoute(pathname, childPathLevel)

    return { childContentNode, isChildLastRoute }
  }

  *_addFirstChild({ childContentNode, isChildLastRoute, pathname, startTime }) {
    const startOptions = {
      startPath: pathname,
      startTime,
    }

    const { entryId: childEntryId, includesDepth: childIncludesDepth } =
      childContentNode || {}

    // order matters here with puts. Do not switch order of these two events.
    // ContentfulActions.startContentNodes is essentially registering a listener for what getEntryForLearnLayer
    // eventually returns
    yield put(
      ContentfulActions.startContentNodes(
        childContentNode,
        startOptions,
        isChildLastRoute,
      ),
    )
    yield put(
      getEntryForLearnLayer({
        entryId: childEntryId,
        include: childIncludesDepth,
      }),
    )
  }

  subscribeEvents() {
    return [CONTENTFUL_SUCCESS, NAVIGATION_EVENT, CONTENTFUL_REQUEST]
  }

  filter_NAVIGATION_EVENT(action) {
    const { pathname } = getPathFromAction(action) || {}
    if (!pathname) {
      this.earlyExit(ContentfulNodeFailureTypes.PATH_NOT_AVAILABLE)
    }
    const isOnProperPath = verifyWeAreOnProperPath(
      pathname,
      this.resolveBlocklist(),
    )
    if (!isOnProperPath) {
      this.earlyExit(ContentfulNodeFailureTypes.PATH_NOT_AVAILABLE)
    }
  }

  filter_CONTENTFUL_REQUEST(action) {
    const namespaceEntryId = this.getState(state =>
      getNamespaceIdFromState(state, this.registeredNamespace),
    )
    const { entryId } = getEntryIdAndEntryFromAction(action) || {}

    if (entryId !== namespaceEntryId) {
      this.earlyExit(ContentfulNodeFailureTypes.NOT_DESIRED_ENTRY_UPDATE)
    }
    const { isFetching, payload: entry, error } =
      this.getState(state =>
        ContentDataEntryManager.getEntryData(state)(entryId),
      ) || {}
    if (error || isFetching || !entry || this.emptyObject(entry)) {
      this.earlyExit(ContentfulNodeFailureTypes.NOT_DESIRED_ENTRY_UPDATE)
    }
  }

  filter_CONTENTFUL_SUCCESS(action) {
    const namespaceEntryId = this.getState(state =>
      getNamespaceIdFromState(state, this.registeredNamespace),
    )
    const { entryId } = getEntryIdAndEntryFromAction(action) || {}
    if (entryId !== namespaceEntryId) {
      this.earlyExit(ContentfulNodeFailureTypes.NOT_DESIRED_ENTRY_UPDATE)
    }
  }

  select_NAVIGATION_EVENT(state) {
    const { router } = state || {}
    const { location } = router || {}
    const { pathname } = location || {}
    return { value: pathname }
  }

  select_CONTENTFUL_REQUEST(state) {
    return this._selectEntry(state)
  }

  _selectEntry(state) {
    const namespaceEntryId = getNamespaceIdFromState(
      state,
      this.registeredNamespace,
    )
    const { payload } = ContentDataEntryManager.getEntryData(state)(
      namespaceEntryId,
      this.locale,
    )
    if (!payload || this.emptyObject(payload)) {
      return false
    }

    return payload
  }

  select_CONTENTFUL_SUCCESS(state) {
    return this._selectEntry(state)
  }
}
