import React, { createRef, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { TextButton } from 'secondstep-components'
/* eslint-disable jsx-a11y/click-events-have-key-events */
/**
 * Disabling eslint here because the parent ButtonToggle
 * handles the key events
 */

const ToggleOption = ({ children, option, selectedOptionId, onSelect }) => {
  const { title, id } = option
  const isFirstRender = useRef(true)
  const optionRef = createRef()
  const isSelected = id === selectedOptionId
  const tabIndex = isSelected ? 0 : -1

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false
    } else if (isSelected) {
      optionRef.current.focus()
    }
  }, [optionRef.current, isSelected])

  const handleSelect = () => {
    onSelect(id)
  }

  const dataTestId = 'toggle-option-' + id

  return (
    <>
      {children}
      <TextButton
        aria-checked={isSelected}
        dataTestId={dataTestId}
        label={title}
        onClick={handleSelect}
        ref={optionRef}
        role="radio"
        tabIndex={tabIndex}
      />
    </>
  )
}

ToggleOption.propTypes = {
  children: PropTypes.node,
  onSelect: PropTypes.func.isRequired,
  option: PropTypes.shape({
    title: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  selectedOptionId: PropTypes.string.isRequired,
}

export default ToggleOption
