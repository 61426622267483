import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { TabButton, TabSet } from 'secondstep-components'
import {
  AccordionWrapper,
  HeaderTitle,
  StyledHairline,
  TabSetWrapper,
} from './component.styles'
import TakeAction from 'components/AdminMonitorSupport/TakeAction'
import Understanding from 'components/AdminMonitorSupport/Understanding'
import {
  ACTION_TAB_ORDER,
  UNDERSTANDING_TAB_ORDER,
  SUPPORT_LS_KEY,
} from './constants'

const AdminMonitorSupport = ({ tabs, title }) => {
  const updateLocalStorage = (key, value) => {
    const newSupportTabStatus = {
      ...supportTabStatus,
      [key]: value,
    }
    localStorage.setItem(SUPPORT_LS_KEY, JSON.stringify(newSupportTabStatus))
  }

  const supportTabStatus = JSON.parse(
    localStorage.getItem(SUPPORT_LS_KEY) || '{}',
  )

  const activeTab = supportTabStatus.activeTab

  const activeIndex =
    (activeTab && tabs.findIndex(t => t.id === activeTab?.id)) ||
    UNDERSTANDING_TAB_ORDER

  const isOpen =
    supportTabStatus.isOpen !== undefined ? supportTabStatus.isOpen : true

  const [selectedTab, setSelectedTab] = useState(tabs[activeIndex])
  const [isTabOpen, setIsTabOpen] = useState(isOpen)

  useEffect(() => {
    updateLocalStorage('activeTab', selectedTab)
  }, [selectedTab])

  useEffect(() => {
    updateLocalStorage('isOpen', isTabOpen)
  }, [isTabOpen])

  return (
    <>
      <AccordionWrapper
        isActive={isTabOpen}
        onClick={() => setIsTabOpen(!isTabOpen)}
        title={<HeaderTitle>{title}</HeaderTitle>}
      >
        <StyledHairline />
        <TabSetWrapper>
          <TabSet>
            {tabs?.map(tab => {
              return (
                <TabButton
                  dataTestId={tab.id}
                  isActive={tab.id === selectedTab.id}
                  key={tab.id}
                  label={tab.displayTitle}
                  onClick={() => setSelectedTab(tab)}
                />
              )
            })}
          </TabSet>
        </TabSetWrapper>
        {selectedTab.id === tabs[UNDERSTANDING_TAB_ORDER].id && (
          <Understanding tabContent={tabs[UNDERSTANDING_TAB_ORDER]} />
        )}
        {selectedTab.id === tabs[ACTION_TAB_ORDER].id && (
          <TakeAction tabContent={tabs[ACTION_TAB_ORDER]} />
        )}
      </AccordionWrapper>
    </>
  )
}

AdminMonitorSupport.propTypes = {
  tabs: PropTypes.array.isRequired,
  title: PropTypes.string,
}

export default AdminMonitorSupport
