import React from 'react'
import PropTypes from 'prop-types'

import { HeaderTwo } from 'secondstep-components'
import {
  CardContainer,
  StyledTooltip,
  TitleAndToolTipContainer,
} from './component.styles'
import ResourceCard from 'experienceComponents/ResourceCard'
import {
  getCollectionResourceValues,
  getLabelBasedOnLocation,
} from 'utils/highschoolHelpers'

export const CardSection = ({
  headerText,
  resources,
  onClick,
  tooltips,
  tooltipLabel,
  isSingleCard = false,
}) => {
  return (
    <>
      <TitleAndToolTipContainer>
        <HeaderTwo className="integratedHeader">
          {headerText}
          <StyledTooltip
            content={getLabelBasedOnLocation(tooltips, tooltipLabel)}
          >
            <i className="fas fa-question-circle" />
          </StyledTooltip>
        </HeaderTwo>
      </TitleAndToolTipContainer>
      <CardContainer isSingleCard={isSingleCard}>
        {resources?.map((resource, index) => {
          const resourceValues = getCollectionResourceValues(resource)

          const {
            audiences,
            grades,
            resourceTypes,
            durations,
            units,
          } = resourceValues
          return (
            <ResourceCard
              audience={audiences}
              description={resource?.description}
              duration={durations}
              grade={grades}
              image={resource?.image?.url}
              imageDescription={resource?.image?.description}
              isSingleCard={isSingleCard}
              key={index}
              onClick={() => onClick(resource.routeSlug)}
              resourceType={resourceTypes}
              shouldShowStatusIcon={false}
              title={resource?.displayTitle}
              unit={units}
            />
          )
        })}
      </CardContainer>
    </>
  )
}

CardSection.propTypes = {
  headerText: PropTypes.string,
  isSingleCard: PropTypes.bool,
  onClick: PropTypes.func,
  resources: PropTypes.arrayOf(PropTypes.object),
  tooltipLabel: PropTypes.string.isRequired,
  tooltips: PropTypes.array,
}

export default CardSection
