import ContenfulTypes from './types'

class ContentfulActions {
  // contetentfulUrlParams = {entryId, locale, preview, format, url, include}
  static setupContentfulGet = (
    contetentfulUrlParams,
    contentLifeCycleTypes = [
      ContenfulTypes.CONTENTFUL_REQUEST,
      ContenfulTypes.CONTENTFUL_SUCCESS,
      ContenfulTypes.CONTENTFUL_FAILURE,
    ],
  ) => {
    return {
      type: ContenfulTypes.SETUP_CONTENTFUL_GET,
      urlParams: contetentfulUrlParams,
      contentLifeCycleTypes,
    }
  }

  static setContentNamespace = (entryIdNamespace, entryId) => {
    return {
      type: ContenfulTypes.SET_CONTENT_NAMESPACE,
      entryId,
      entryIdNamespace,
    }
  }

  static startContentNodes = (node, startOptions, lastNode = false) => {
    return {
      type: ContenfulTypes.START_CONTENT_NODES,
      node,
      startOptions,
      lastNode,
    }
  }

  static updateContentNodes = (node, lastNode = false) => {
    return {
      type: ContenfulTypes.UPDATE_CONTENT_NODES,
      node,
      lastNode,
    }
  }

  static updateContentNodesError = node => {
    return {
      type: ContenfulTypes.UPDATE_CONTENT_NODES_ERROR,
      node,
    }
  }

  static updateContentNodesErrorSystemMessage = error => {
    return {
      type: ContenfulTypes.UPDATE_CONTENT_NODES_ERROR_SYSTEM_MESSAGE,
      error,
    }
  }

  static resetContentNodes = () => {
    return {
      type: ContenfulTypes.RESET_CONTENT_NODES,
    }
  }
}

export default ContentfulActions
