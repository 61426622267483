import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { TextLink } from 'secondstep-components'

export const ClassesCount = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  background-color: ${themeGet('colors.white')};
  border: 2px solid ${themeGet('colors.paleGray')};
  border-radius: 10px;
  font-family: ${themeGet('font.molde.medium')};
  padding: 1.375rem 1.25rem 1.25rem;
  width: 100%;
  height: 100%;
`

export const Separator = styled.hr`
  border: 1px solid ${themeGet('colors.paleGray')};
  margin-top: 0.9375rem;
  margin-bottom: 0.875rem;
  width: 100%;
`

export const Label = styled.span`
  font-size: 1.125rem;
  line-height: 1.25rem;
  font-family: ${themeGet('font.molde.medium')};
`

export const NoClassesWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

export const ReportLink = styled(TextLink)``

export const Total = styled.span`
  font-size: 2.5rem;
  line-height: 2.6875rem;
  font-family: ${themeGet('font.sharpSlab.medium')};
  margin-bottom: 0.1875rem;
`

export const Updated = styled.div`
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  height: 1.3125rem;
  color: ${themeGet('colors.dashboard.gray1')};
  font-family: ${themeGet('font.molde.regular')};
  font-size: 0.75rem;
  letter-spacing: 0;
  line-height: 0.8125rem;
`

// Hack: Setting 99% of width fixes the responsive problem when resizing
export const ChartContainer = styled.div`
  width: 99%;
  height: 100%;
  max-height: 266px;
`
