import React from 'react'
import PropTypes from 'prop-types'

import {
  StyledAlertText,
  StyledCheckbox,
  StyledPanel,
  StyledText,
  StyledTitle,
  StyledMessage,
} from './component.styles'

const StepModalConfirmationPanel = props => {
  const { alertMessage, message, title, onChange } = props

  return (
    <>
      <StyledPanel>
        <StyledText>
          <StyledTitle data-testid={'step-modal-confirmation-panel-title'}>
            {title}
          </StyledTitle>
          <StyledMessage data-testid={'step-modal-confirmation-panel-message'}>
            <StyledAlertText
              data-testid={'step-modal-confirmation-panel-alert-message'}
            >
              {alertMessage}
            </StyledAlertText>
            {message}
          </StyledMessage>
        </StyledText>
        <StyledCheckbox
          dataTestId={'step-modal-confirmation-panel-checkbox'}
          onChange={onChange}
          text={'Okay'}
        />
      </StyledPanel>
    </>
  )
}

StepModalConfirmationPanel.propTypes = {
  alertMessage: PropTypes.string,
  message: PropTypes.string,
  onChange: PropTypes.func,
  title: PropTypes.string,
}

export default StepModalConfirmationPanel
