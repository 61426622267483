import React from 'react'
import PropTypes from 'prop-types'
import { Background, Wrapper } from './component.styles'

const PlainProgramCard = ({
  dataTestId,
  hasBackground = true,
  children,
  className,
}) => (
  <Background dataTestId={dataTestId} hasBackground={hasBackground}>
    <Wrapper className={className}>{children}</Wrapper>
  </Background>
)

PlainProgramCard.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  hasBackground: PropTypes.bool,
}

export default PlainProgramCard
