import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy } from 'secondstep-components'

const largeBreakpoint = '1140px'

export const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 32%;
  height: 100%;
  max-height: 330px;
  position: relative;

  &:not(:last-of-type) {
    border-right: 2px solid ${themeGet('colors.paleGray')};
  }

  ${themeGet('breakpoints.medium')} {
    max-width: 430px;
    width: 100%;

    &:not(:last-of-type) {
      border: none;
    }
  }
`

export const CompletedText = styled(BodyCopy)`
  line-height: 1.3125rem;

  @media (max-width: ${largeBreakpoint}) {
    display: none;
  }

  ${themeGet('breakpoints.medium')} {
    display: inline;
    margin-top: 0;
  }
`

export const CompletedTextAlt = styled(BodyCopy)`
  display: none;
  max-width: 145px;
  line-height: 1.3125rem;
  text-align: center;
  margin-top: 0.4375rem;

  @media (max-width: ${largeBreakpoint}) {
    display: block;
  }

  ${themeGet('breakpoints.medium')} {
    display: none;
  }
`

export const DonutWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 330px;
  position: relative;
`

export const PercentText = styled.span`
  font-size: 2.5rem;
  font-family: ${themeGet('font.sharpSlab.medium')};
  margin-bottom: 1.25rem;

  @media (max-width: ${largeBreakpoint}) {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-bottom: 0;
  }

  ${themeGet('breakpoints.medium')} {
    position: relative;
    margin-bottom: 1.25rem;
    transform: translateY(0);
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 53%;
  transform: translateY(-50%);
  max-width: 145px;
  text-align: center;

  @media (max-width: ${largeBreakpoint}) {
    top: 50%;
  }

  ${themeGet('breakpoints.medium')} {
    top: 53%;
  }
}
`

export const UserCount = styled(BodyCopy)``

export const UserCountBox = styled.div`
  background: #f9eff2;
  padding: 0.3125rem 1.375rem 0.25rem;
  margin-bottom: 2rem;
  border-radius: 1rem;
`
