/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React, { useEffect, useState } from 'react'
import PropTypes, { arrayOf, shape } from 'prop-types'
import PathwayTemplate from 'components/HighSchoolPathway'
import { StyledBackgroundImage } from './component.styles'
import { isPreviewMode } from 'utils/highschoolHelpers'
import withSiteContext from 'layers/content/v2/Hocs/withSiteContext'

const PathwayPage = props => {
  const [courseDisplayTitle, setCourseDisplayTitle] = useState()
  const [courseDescription, setCourseDescription] = useState()

  const [pageDisplayTitle, setPageDisplayTitle] = useState()
  const [pageDescription, setPageDescription] = useState()
  const [pageImage, setPageImage] = useState()

  const [collection, setCollection] = useState()
  const [tabs, setTabs] = useState()

  const { content, handleTabChange } = props

  useEffect(() => {
    if (!content) {
      return
    }

    const { navigationPage = {} } = content
    const {
      courseSummary,
      pageSummary,
      linkSummaries,
      tabSummaries,
    } = navigationPage

    if (courseSummary) {
      const { displayTitle, description } = courseSummary
      if (displayTitle) setCourseDisplayTitle(displayTitle)
      if (description) setCourseDescription(description)
    }

    if (pageSummary) {
      const { displayTitle, description, image } = pageSummary
      if (displayTitle) setPageDisplayTitle(displayTitle)
      if (description) setPageDescription(description)
      if (image) setPageImage(image)
    }

    if (linkSummaries) {
      setCollection(linkSummaries)
    }

    if (tabSummaries) {
      setTabs(tabSummaries)
    }
  }, [content])

  const hasFooter = !!pageImage

  const tabDescription = {
    body: pageDescription ?? '',
    title: pageDisplayTitle ?? '',
    hasFooter,
    footerChildren: hasFooter && <StyledBackgroundImage src={pageImage?.url} />,
  }

  const templateProps = {
    title: courseDisplayTitle,
    description: courseDescription,
    tabs,
    activeTab: tabs?.find(t => window.location.pathname.endsWith(t.route)),
    tabDescription,
    collection,
    handleTabChange,
    isPreview: isPreviewMode(),
  }

  return <PathwayTemplate {...templateProps} />
}

const summary = shape({
  description: PropTypes.string.isRequired,
  displayTitle: PropTypes.string.isRequired,
  image: shape({
    title: PropTypes.string,
    url: PropTypes.url,
  }),
})

PathwayPage.propTypes = {
  content: PropTypes.shape({
    navigationPage: shape({
      courseSummary: shape(summary),
      pageSummary: shape(summary),
      tabSummaries: arrayOf(shape(summary)),
      linkSummaries: arrayOf(shape(summary)),
    }).isRequired,
  }),
  handleTabChange: PropTypes.func,
}

export default withSiteContext(PathwayPage, {
  useGuard: true,
})
