import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { HeaderTwo, MultiTextLink } from 'secondstep-components'
import Lesson from 'experienceComponents/Lesson'
import LessonAB from 'experienceComponents/LessonAB'
import { LessonListWrapper, SubHeadBox } from './component.styles'

// TODO: ADD MORE TESTS TO THIS FILE AND BREAK APART
const LessonList = ({
  contentfulLessons,
  dataTestId,
  gradeTheme,
  lessonStartingIndex,
  lessons,
  match,
  navigationEvent,
  unitResources,
}) => {
  const [aLessonIsOpen, setALessonIsOpen] = useState(false)
  const [selectedLessonAB, setSelectedLessonAB] = useState('')
  const [selectedLessonIndex, setSelectedLessonIndex] = useState(1)

  const [useDefaultLessonAB, setUseDefaultLessonAB] = useState(true)

  const findNextLessonIndex = () => {
    let nextLessonIndex = lessonStartingIndex
    const incompleteLesson = lessons.find(lesson => {
      if (!lesson.choices) return !lesson.is_done
      if (lesson.choices) {
        const lessonChoiceIsComplete = lesson.choices.some(
          abLesson => abLesson.is_done,
        )

        return !lessonChoiceIsComplete
      }
    })

    if (incompleteLesson) {
      nextLessonIndex = lessons.indexOf(incompleteLesson) + lessonStartingIndex
    } else {
      nextLessonIndex = lessonStartingIndex - 1 + lessons.length
    }
    return nextLessonIndex
  }

  const isALessonOpen = index => {
    let isLessonOpen = false

    if (selectedLessonIndex === lessonStartingIndex + index) isLessonOpen = true
    if (aLessonIsOpen) isLessonOpen = false

    return isLessonOpen
  }

  const getDefaultLessonAB = lessonChoices => {
    let selectedChoice
    const hasProgress = lessonChoices.find(choice => choice.progress)
    const hasCompletion = lessonChoices.find(choice => choice.doneAt)

    const firstLessonCompleted = () => {
      let lesson = null
      lessonChoices.map(choice => {
        if (!lesson && choice.doneAt) {
          lesson = choice
        } else if (
          choice.doneAt &&
          new Date(choice.doneAt) < new Date(lesson.doneAt)
        ) {
          lesson = choice
        }
      })
      return lesson
    }

    const lessonWithMostRecentProgress = () => {
      let lesson = null
      lessonChoices.map(choice => {
        if (!lesson && choice.updatedAt) {
          lesson = choice
        } else if (
          choice.updatedAt &&
          new Date(choice.updatedAt) > new Date(lesson.updatedAt)
        ) {
          lesson = choice
        }
      })
      return lesson
    }

    if (hasProgress) {
      if (hasCompletion) {
        selectedChoice = firstLessonCompleted()
        return selectedChoice
      }
      selectedChoice = lessonWithMostRecentProgress()
      return selectedChoice
    }

    return selectedChoice
  }

  const onClick = href => {
    const url = href
    const type = NavigationHelper.types.DOWNLOAD_LINK
    navigationEvent(url, type)
  }
  const toggleSelectedLessonAB = (selectedLesson, index) => {
    setUseDefaultLessonAB(false)
    setSelectedLessonAB(selectedLesson)
    setSelectedLessonIndex(index + 1)
  }

  useEffect(() => {
    setALessonIsOpen(false)
    setSelectedLessonIndex(findNextLessonIndex())
  }, [lessons])

  const lessonPlan = unitResources && unitResources[0]?.file?.file?.url
  const studentHandouts = unitResources && unitResources[1]?.file?.file?.url
  const lessonLastIndex = lessonStartingIndex - 1 + lessons.length
  return (
    <LessonListWrapper dataTestId={dataTestId}>
      <SubHeadBox>
        {lessons.length > 1 && (
          <HeaderTwo dataTestId="header-lessons">
            {`Lessons ${lessonStartingIndex}-${lessonLastIndex}`}
          </HeaderTwo>
        )}
        {unitResources && (
          <MultiTextLink
            dataTestId="text-link-plans-handouts"
            downloadLinkLabel={unitResources[0]?.displayTitle}
            downloadOnClick={() => onClick(lessonPlan)}
            textLinkLabel={unitResources[1]?.displayTitle}
            textLinkOnClick={() => onClick(studentHandouts)}
          />
        )}
      </SubHeadBox>
      {lessons.map((lesson, index) => {
        const openThisLesson = isALessonOpen(index)
        const lessonChoices = lesson.choices && lesson.choices
        let selectedLessonChoice =
          lessonChoices && getDefaultLessonAB(lessonChoices)

        if (lessonChoices && !useDefaultLessonAB) {
          selectedLessonChoice = undefined || lessonChoices[selectedLessonAB]
        }

        const key = lessonChoices ? `lesson-ab-${lesson.scoID}` : lesson.scoID
        const entryId = lesson.contentfulID
        const lessonChoiceEntryId =
          selectedLessonChoice && selectedLessonChoice.contentfulID

        return (
          <Fragment key={key}>
            {lessonChoices && (
              <LessonAB
                entryId={lessonChoiceEntryId}
                isOpen={openThisLesson}
                lessonIndex={index}
                toggleSelectedLessonAB={toggleSelectedLessonAB}
              >
                {selectedLessonChoice && (
                  <Lesson
                    attempted={selectedLessonChoice.attempted}
                    contentfulLesson={contentfulLessons[index]}
                    dataTestId={`lesson-${index + 1}`}
                    entryId={lessonChoiceEntryId}
                    gradeTheme={gradeTheme}
                    instance={selectedLessonChoice.instance}
                    isDone={selectedLessonChoice.is_done}
                    isOpen={openThisLesson}
                    lessonIndex={lessonStartingIndex + index}
                    match={match}
                    scoId={selectedLessonChoice.scoID}
                    setALessonIsOpen={setALessonIsOpen}
                    setSelectedLessonIndex={setSelectedLessonIndex}
                  />
                )}
              </LessonAB>
            )}
            {!lessonChoices && (
              <Lesson
                attempted={lesson.attempted}
                contentfulLesson={contentfulLessons[index]}
                dataTestId={`lesson-${index + 1}`}
                entryId={entryId}
                gradeTheme={gradeTheme}
                instance={lesson.instance}
                isDone={lesson.is_done}
                isOpen={openThisLesson}
                lessonIndex={lessonStartingIndex + index}
                match={match}
                scoId={lesson.scoID}
                setALessonIsOpen={setALessonIsOpen}
                setSelectedLessonIndex={setSelectedLessonIndex}
              />
            )}
          </Fragment>
        )
      })}
    </LessonListWrapper>
  )
}

LessonList.propTypes = {
  contentfulLessons: PropTypes.array.isRequired,
  dataTestId: PropTypes.string.isRequired,
  gradeTheme: PropTypes.string,
  lessonStartingIndex: PropTypes.number,
  lessons: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  navigationEvent: PropTypes.func,
  unitResources: PropTypes.array,
}

LessonList.displayName = 'LessonList'

export default LessonList
