export function getQueryParams(url) {
  const query = url.split('?')[1]

  if (!query) {
    return {}
  }

  const queryString = query.split('&')
  const queries = queryString.reduce((accum, bondedQuery) => {
    const pairs = bondedQuery.split('=')
    const key = pairs[0]
    const value = pairs[1]

    return { ...accum, [key]: value }
  }, {})

  return queries
}

export const stripLastRouteSlug = path => {
  let modifiedPath = path
  const lastCharacter = path.slice(-1)

  if (lastCharacter === '/') {
    modifiedPath = modifiedPath.slice(0, -1)
  }

  const pathSlugs = modifiedPath.split('/')
  pathSlugs.pop()

  const joinedPath = pathSlugs.join('/')

  return joinedPath
}
