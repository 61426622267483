import React from 'react'
import PropTypes from 'prop-types'

import {
  ContentContainer,
  PageWrapper,
  BannerSpacer,
  SiteSelectorWrapper,
} from './component.styles'

import { checkIfLessonIsComplete } from 'components/MarkCompleteSection/helpers'

import HighSchoolPreviewBanner from 'components/HighSchoolPreviewBanner'

import SubLandingBanner from 'experienceComponents/SubLandingBanner'
import ContentCallout from 'experienceComponents/ContentCallout'
import { isPreviewMode } from 'utils/highschoolHelpers'
import { pageTypes } from 'utils/getPageTypeByUrl'
import SiteSelector from 'components/SiteSelector'

const HighSchoolTrainingLandingComponent = props => {
  const { content, instructorData } = props || {}
  const { navigationPage } = content || {}
  const { linkSummaries, pageSummary } = navigationPage || {}
  const { description, displayTitle } = pageSummary || {}

  const filteredlinkSummaries = linkSummaries.filter(summary => {
    return summary.description && summary.displayTitle
  })

  return (
    <PageWrapper>
      {isPreviewMode() && (
        <>
          <BannerSpacer />
          <HighSchoolPreviewBanner />
        </>
      )}

      {!isPreviewMode() && (
        <SiteSelectorWrapper>
          <SiteSelector />
        </SiteSelectorWrapper>
      )}

      <SubLandingBanner
        key="banner"
        pageType={pageTypes.HS_TRAINING_PAGE}
        text={description}
        title={displayTitle}
      />
      <ContentContainer>
        {filteredlinkSummaries.map((summary, index) => {
          const uniqueId = `summary-${index + 1}`
          const { displayTitle, description, route, courseNodeConnector } =
            summary || {}
          const { courseNodeEntryPoint } = courseNodeConnector || {}
          const keysToSearch = Object.keys(instructorData)

          const arrayToSearch = []

          for (const key of keysToSearch) {
            if (instructorData[key].hasOwnProperty('lesson_nodes'))
              arrayToSearch.push(...instructorData[key].lesson_nodes)
          }

          const { isComplete } =
            checkIfLessonIsComplete(arrayToSearch, courseNodeEntryPoint) || {}

          return (
            <ContentCallout
              body={description}
              dataTestId={uniqueId}
              isComplete={isComplete}
              isHighSchoolTrainingPage
              key={uniqueId}
              route={route}
              title={displayTitle}
            />
          )
        })}
      </ContentContainer>
    </PageWrapper>
  )
}

// TODO: Move this types for a more appropriate place
const lessonInteractiveType = [
  'trainingCurriculum',
  'trainingProgram',
  'schoolwideTraining',
]
const lessonDataType = PropTypes.shape({
  lesson_interaction_types: PropTypes.arrayOf(lessonInteractiveType),
  lesson_nodes: PropTypes.arrayOf(
    PropTypes.shape({
      attempt_id: PropTypes.number,
      registration_id: PropTypes.number,
      leaf_node_id: PropTypes.string,
      is_done: PropTypes.bool,
      period_progress: null,
      progress: PropTypes.number,
      is_passed: PropTypes.bool,
      is_complete: PropTypes.bool,
      created_at: PropTypes.time,
      updated_at: PropTypes.string,
      done_at: PropTypes.string,
      updated_by: PropTypes.number,
      scorm_pkg_id: PropTypes.number,
      lesson_interaction_type: PropTypes.oneOf(lessonInteractiveType),
      time: PropTypes.any,
      score: PropTypes.any,
      cmi: PropTypes.any,
    }),
  ),
  site_id: PropTypes.number,
  user_id: PropTypes.number,
})

HighSchoolTrainingLandingComponent.propTypes = {
  content: PropTypes.shape({
    navigationPage: PropTypes.shape({
      linkSummaries: PropTypes.arrayOf(
        PropTypes.shape({
          description: PropTypes.string,
          displayTitle: PropTypes.string,
          route: PropTypes.string,
        }),
      ),
      pageSummary: PropTypes.shape({
        description: PropTypes.string,
        displayTitle: PropTypes.string,
      }),
    }),
  }),
  instructorData: PropTypes.shape({
    educator: PropTypes.shape(lessonDataType),
    site: PropTypes.shape(lessonDataType),
    siteEducator: PropTypes.shape(lessonDataType),
  }),
}

export default HighSchoolTrainingLandingComponent
