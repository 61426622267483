import React from 'react'
import PropTypes from 'prop-types'
import AdminHsK8Setup from './component'

const AdminHsK8SetupContainer = ({ isLaunched, selectedProgram }) => {
  return (
    <AdminHsK8Setup isLaunched={isLaunched} selectedProgram={selectedProgram} />
  )
}

AdminHsK8SetupContainer.propTypes = {
  isLaunched: PropTypes.bool,
  selectedProgram: PropTypes.object,
}

export default AdminHsK8SetupContainer
