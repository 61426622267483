export const CONTINUE_LEARNING = 'Continue Learning'
export const CONTINUE_TO_MEETING = 'Continue to Meeting'
export const LEADER_TEAM = 'Leader Team'
export const LEADER_TEAM_TITLE =
  'Attend at least one of these two team meetings'
export const MINUTES = 'min.'
export const ML_TYPE_SMALL_GROUP = 'small-group'
export const ML_TYPE_LEADER_GROUP = 'leader-group'
export const ML_TYPE_INDIVIDUAL = 'individual'
export const MODULE_COMPLETE = 'Module Complete'
export const SMALL_GROUP = 'Small Group'
export const START_MODULE = 'Start Module'
export const UP_NEXT = 'Up Next'
