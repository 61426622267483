import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'

export const CollectionContainer = styled.div`
  margin-bottom: 1.75rem;
  h3 {
    font-family: ${themeGet('fonts.kometHeavy28.fontFamily')};
    font-weight: ${themeGet('fonts.kometHeavy28.weight')};
    // 18px:
    font-size: 1.125rem;
  }
`

export const Container = styled.div`
  background-color: ${themeGet('colors.white')};
`

export const PathwayHeader = styled.div`
  margin-bottom: 1.75rem;
  h3 {
    font-family: ${themeGet('fonts.kometHeavy28.fontFamily')};
    font-weight: ${themeGet('fonts.kometHeavy28.weight')};
    color: ${themeGet('colors.hsSecondaryDarkGreen')};
    font-size: 1.5rem;
  }
  p {
    margin-top: 0.625rem;
    color: ${themeGet('colors.darkGray')};
    font-size: ${themeGet('fonts.moldeMedium16.fontSize')};
    font-family: ${themeGet('fonts.moldeMedium16.fontFamily')};
  }
`

export const PathwayNavigationWrapper = styled.div`
  margin-bottom: 2.5rem;
`

export const StaticDescriptionLinkContainer = styled(Button)`
  border: 0.125rem solid ${themeGet('colors.highschool.gray2')};
  border-radius: 0.625rem;
  padding: 0.75rem;
  padding-left: 1rem;
  width: 100%;
  height: 100%;
  max-width: 18.438rem;

  h4 {
    margin: 0;
    color: ${themeGet('colors.highschool.pink1')};
    font-size: ${themeGet('fonts.moldeMedium16.fontSize')};
    font-family: ${themeGet('fonts.moldeMedium16.fontFamily')};
  }
  p {
    margin: 0;
  }
  :hover {
    cursor: pointer;
    h4 {
      color: ${themeGet('colors.hsSecondaryGreen')};
    }
  }
`

export const StaticLinksPanelContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  grid-gap: 1.25rem;
  margin-top: 1.3rem;

  // set a higher breakpoint so we can maintain a smaller height for each link box:
  ${themeGet('breakpoints.medium')} {
    grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
    justify-items: left;
  }
`

export const StyledHairline = styled.hr`
  margin-top: 2.5rem;
  // 1px top + 1px bottom = 2px total height:
  border: 0.063rem solid ${themeGet('colors.highschool.gray2')};
`

export const TileImage = styled.img`
  margin-top: 2.5rem;
  margin-left: -1.3rem;
  margin-bottom: -1.1rem;
  border-radius: 0.75rem;
  // add 2.6rem to the width to account for the negative margin:
  width: calc(100% + 2.6rem);
`

export const TrainingHeader = styled.h3`
  font-family: ${themeGet('fonts.kometHeavy28.fontFamily')};
  font-weight: ${themeGet('fonts.kometHeavy28.weight')};
  // 24px:
  font-size: 1.5rem;
`

export const PacingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  svg {
    position: relative;
    right: 1.25rem;
  }
`

export const PacingDates = styled.div`
  box-sizing: border-box;
  height: 1.688rem;
  width: 6rem;
  border: 2px solid ${themeGet('colors.highschool.pinkWithOpacity')};
  border-radius: 13px;
  background-color: #faeef3;
  font-size: 12px;
  margin-left: 10px;
  text-align: center;
  color: ${themeGet('colors.highschool.green2')};
  font-weight: 700;
  font-family: ${themeGet('font.molde.regular')};
`

export const PacingHeader = styled.h3`
  margin-left: ${props => (props.isCurrentCollection ? '-0.75rem' : '0')};
  color: ${themeGet('colors.hsSecondaryDarkGreen')};
`
