import { themeGet } from 'styled-system'
import styled from 'styled-components'
import ResourceCard from 'experienceComponents/ResourceCard'

export const Background = styled.div`
  background-color: ${themeGet('colors.white')};
  position: relative;
  display: flex;
  justify-content: center;
  padding: 0 1rem;
  background-color: ${themeGet('colors.mutedBackground')};
  width: 100vw;

  ${themeGet('breakpoints.largish')},
  ${themeGet('breakpoints.medium')},
  ${themeGet('breakpoints.tablet')},
  ${themeGet('breakpoints.mobileLarge')} {
    background-color: ${themeGet('colors.white')};
    width: unset;
    padding: 3.625rem 1.25rem;
  }
`

export const PageWrapper = styled.div`
  position: relative;
  margin: 0;
  display: block;
  background-color: ${themeGet('colors.white')};
  padding: 3.625rem 1.25rem;
  max-width: min(calc(100vw - 4rem), 1366px);

  ${themeGet('breakpoints.largish')},
  ${themeGet('breakpoints.medium')},
  ${themeGet('breakpoints.tablet')},
  ${themeGet('breakpoints.mobileLarge')} {
    padding: 0;
    max-width: unset;
  }

  header {
    padding-bottom: 1.75rem;
    margin-bottom: 1.75rem;
    border-bottom: 1px solid ${themeGet('colors.gray500')};
    display: flex;
    flex-direction: column;
    h1 {
      margin-bottom: 1.25rem;
    }

    ${themeGet('breakpoints.tablet')} {
      border-bottom: none;
      align-items: center;
      margin-bottom: 2.5rem;
      padding-bottom: 0;
      p {
        text-align: center;
      }
    }
  }
`
export const BodyContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: space-between;
  gap: 2.5rem;
  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
    gap: unset;
  }
`
export const FiltersPanel = styled.section`
  h4 {
    margin-bottom: 0;
    padding-bottom: 1rem;
  }
  ${themeGet('breakpoints.tablet')} {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 0.625rem;
    margin-bottom: 1rem;
    h4 {
      display: none;
    }
    button {
      width: 50%;
    }
  }
`
export const SortByPanel = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 12.688rem;

  button:first-child {
    height: 2.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    border: 1px solid ${themeGet('colors.gray500')};
    cursor: pointer;
    &:hover {
      color: ${themeGet('colors.themePrimary')};
      div:nth-child(2) {
        svg {
          fill: ${themeGet('colors.themePrimary')};
          stroke: ${themeGet('colors.themePrimary')};
        }
      }
    }
    &[aria-expanded='true'] {
      div:nth-child(2) {
        svg {
          transform: rotate(180deg);
          fill: ${themeGet('colors.themePrimary')};
          stroke: ${themeGet('colors.themePrimary')};
        }
      }
    }
    div:nth-child(2) {
      margin: 0;
      svg {
        fill: ${themeGet('colors.themeSecondary')};
        stroke: ${themeGet('colors.themeSecondary')};
      }
    }
  }

  ${themeGet('breakpoints.tablet')} {
    margin-bottom: 0;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 1rem;

    button:first-child {
      width: 100%;
      height: 3.25rem;
      &[aria-haspopup='listbox'] {
        width: 50%;
      }
    }

    .collapse-button {
      border: none !important;
    }
  }
`
export const MainContainer = styled.div``

export const MainHeader = styled.section`
  h4 {
    margin-bottom: 1rem;
  }
  .aa-Autocomplete {
    margin-bottom: 1.75rem !important;
  }

  ${themeGet('breakpoints.tablet')} {
    .aa-Autocomplete {
      margin-bottom: 0 !important;
    }

    .aa-DetachedSearchButton {
      height: 3.25rem;
      border-radius: 0.25rem;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      padding: 0;
      border: 0.063rem solid ${themeGet('colors.gray500')};
    }
    h4 {
      display: none;
    }
  }
`
export const ResultsContainer = styled.div`
  h4 {
    margin-bottom: 1.75rem;
  }
  ${themeGet('breakpoints.tablet')} {
    padding-top: 2.5rem;
  }
`
export const CurrentlyAppliedFilters = styled.section`
  padding: 0;
`

export const CardItem = styled(ResourceCard).attrs(
  ({
    audience,
    description,
    duration,
    hit,
    imageUrl,
    imageAltText,
    resourceType,
    displayTitle,
    unit,
  }) => ({
    audience,
    description,
    duration,
    hit,
    unit,
    resourceType,
    image: imageUrl,
    imageDescription: imageAltText,
    title: displayTitle,
    className: 'card-item',
  }),
)``

export const CardContainer = styled.div`
  .ais-Hits-list {
    padding: 0;
    margin-top: 0;
    gap: 16px;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(4, 223px);
    margin-bottom: 2.5rem;
    .card-item {
      margin-top: 0;
      margin-right: 0;
    }

    ${themeGet('breakpoints.largish')} {
      grid-template-columns: repeat(3, auto);
    }
    ${themeGet('breakpoints.medium')} {
      grid-template-columns: repeat(2, auto);
      padding-right: 0;
    }
    ${themeGet('breakpoints.tablet')},
    ${themeGet('breakpoints.mobileLarge')} {
      grid: auto-flow / 1fr;
      .card-item {
        max-width: 100%;
      }
    }
  }
`
export const BannerSpacer = styled.div`
  margin-top: 7rem;
`
