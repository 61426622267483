import { connect } from 'react-redux'
import { compose } from 'redux'

import ContentfulReducer from 'layers/content/store/reducer'

const mapStateToProps = state => {
  const firstLevelNamespace = ContentfulReducer.ROOT_NAMESPACE
  const defaultLocale = 'en-US'

  const parentEntryId =
    state?.[firstLevelNamespace]?.[ContentfulReducer.CONTENT_NODES]?.['last']?.[
      'parentId'
    ]

  const includesDepth =
    state?.[firstLevelNamespace]?.[ContentfulReducer.CONTENT_NODES]?.[
      'nodes'
    ]?.[parentEntryId]?.['includesDepth']

  const getParentEntryPath = locale => {
    return `${parentEntryId}:${locale}:${includesDepth}`
  }

  const parentEntryFullPath =
    parentEntryId && includesDepth
      ? getParentEntryPath(defaultLocale)
      : undefined

  const parentEntry =
    state?.[firstLevelNamespace]?.[parentEntryFullPath]?.['payload']

  const getParentEntryForLocale = locale => {
    return state?.[firstLevelNamespace]?.[getParentEntryPath(locale)]?.[
      'payload'
    ]
  }

  return {
    parentEntry,
    getParentEntryForLocale,
  }
}

export default (component, options = {}, ...functions) => {
  const baseConnect = connect(mapStateToProps, null)

  return compose(baseConnect, ...functions)(component)
}
