import ContentEntryDataGetter from '../ContentEntryDataGetter'

import { ROOT_ENTRY_ID } from './data'

export default function GetRootContent(
  Component,
  additionalOptions,
  ...functions
) {
  const options = {
    entryId: ROOT_ENTRY_ID,
    ...additionalOptions,
  }

  return ContentEntryDataGetter(Component, options, ...functions)
}
