import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy } from 'secondstep-components'

export const LoadingContent = styled.div.attrs(props => ({
  background: props.background ? themeGet('colors.beaconGray')(props) : 'none',
}))`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 1.25rem;
  border-radius: 10px;
  background: ${({ background }) => background};

  svg {
    min-width: 24px;
    width: 24px;
    min-height: 24px;
    height: 24px;
    margin-right: 0.5rem;
  }
`

export const LoadingWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1366px;
  height: 100%;
  min-height: 200px;
  padding: 2.5rem 0;
`

export const StyledBodyCopy = styled(BodyCopy)`
  font-size: 1.125rem;
  font-family: ${themeGet('font.sharpSlab.semibold')};
  color: ${themeGet('colors.darkBlue')};
`
