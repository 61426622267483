import styled from 'styled-components'
import { themeGet } from 'styled-system'
import './algolia.css'
import '@algolia/autocomplete-theme-classic'

export const Container = styled.div`
  .aa-Autocomplete {
    box-sizing: border-box;
    margin-bottom: 2.5rem;
    font-family: ${themeGet('fonts.moldeRegular16.fontFamily')};
    font-size: ${themeGet('fonts.moldeRegular16.fontSize')};
    letter-spacing: 0;
    line-height: ${themeGet('fonts.moldeRegular16.lineHeight')};
    border-radius: 0.25rem;
    .aa-SubmitIcon {
      height: 1.25rem;
      width: 1.25rem;
      color: #757575;
    }
    .aa-SubmitButton {
      line-height: 0;
      padding: 0 0.063rem 0 0.063rem;
    }
    .aa-ClearIcon {
      height: 1rem;
      width: 1rem;
      color: ${themeGet('colors.gray625')};
    }
    .aa-ClearIcon:hover {
      color: ${themeGet('colors.themePrimary')};
    }
    @media screen and (min-width: ${themeGet('breakpoints.sizes.medium')}) {
      width: 29.125rem;
    }

    @media screen and (min-width: ${themeGet('breakpoints.sizes.large')}) {
      width: 32.375rem;
    }
    .aa-Form {
      height: 2.5rem;
    }
    .aa-Form:focus-within {
      border-color: ${themeGet('colors.gray500')};
      box-shadow: none;
    }
  }
`
