import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'

const defaultProps = {
  borderRadius: '0.625rem',
  headerFontSize: '2.6875rem',
  headerLineHeight: '3.5rem',
  letterSpacing: '0.0938rem',
  padding: '0.9375rem 0.9375rem 0.1875rem',
  titleFontSize: '0.875rem',
  titleLineHeight: '1.0625rem',
}

const smallProps = {
  borderRadius: '0.5rem',
  headerFontSize: '2rem',
  headerLineHeight: '2.5rem',
  padding: '0.5625rem 0.5625rem 0',
  titleFontSize: '0.6875rem',
  letterSpacing: '0.0656rem',
  titleLineHeight: '0.8125rem',
}

export const Wrapper = styled.div.attrs(({ dataTestId, size }) => {
  const defaultStyles = {
    ...defaultProps,
    'data-testid': dataTestId,
  }
  const smallStyles = {
    ...smallProps,
    'data-testid': dataTestId,
  }

  return size === 'default' ? defaultStyles : smallStyles
})`
  div,
  button {
    align-items: center;
    align-self: flex-start;
    padding: ${({ padding }) => padding};
    border-radius: ${({ borderRadius }) => borderRadius};
    background-color: ${themeGet('colors.themePrimary')};
    cursor: ${({ isStatic }) => (isStatic ? 'default' : 'pointer')};
    pointer-events: ${({ isStatic }) => (isStatic ? 'none' : 'auto')};

    transition: all 0.2s ease;
    &:not([disabled]):hover {
      background-color: ${themeGet('colors.themeHighlight')};
    }

    p,
    h6 {
      margin: 0;
      text-align: center;
      text-transform: uppercase;
      color: ${themeGet('colors.white')};
    }

    p {
      font-size: ${({ titleFontSize }) => titleFontSize};
      line-height: ${({ titleLineHeight }) => titleLineHeight};
      letter-spacing: ${({ letterSpacing }) => letterSpacing};
      font-family: ${themeGet('fontFamilyBold')};
    }

    h6 {
      text-align: center;
      font-size: ${({ headerFontSize }) => headerFontSize};
      line-height: ${({ headerLineHeight }) => headerLineHeight};
      font-family: ${themeGet('fontFamilyHeadingBold')};
    }
  }
`

export const StyledButton = styled(Button)``
export const StyledDiv = styled.div``
