import variables from './variables'
import blobImgMiddleSchool from 'experienceComponents/LandingBanner/assets/landingBannerBlobs.svg'
import subBlobImgMiddleSchool from 'experienceComponents/SubLandingBanner/assets/subLandingBannerBlobs.svg'
import contentBlobImgMiddleSchool from 'experienceComponents/ContentTypeDescription/assets/backgroundBlobs.svg'
import backgroundImage from 'experienceComponents/UnitOverview/assets/img_unit_overview_blobs.svg'

export default {
  meta: { themeName: 'middleschool' },

  globalNav: {
    background: variables.colors.msdigital2,
    iconHoverBackground: variables.colors.opacity_40,
  },

  skipToMain: {
    background: variables.colors.opacity_40,
  },

  colors: {
    textBase: variables.colors.darkGray,
    themeAccent: variables.colors.blue,
    themeHighlight: variables.colors.middleSchoolMediumBlue,
    themePrimary: variables.colors.msdigital2,
    themeSecondary: variables.colors.msdigital2,
    themeDark: variables.colors.middleSchoolDarkBlue,
    classCardHover: variables.colors.middleSchoolMediumBlue,
    accordion: {
      headerTextColor: variables?.colors?.darkBlue,
      icon: variables?.colors?.blueGray,
      iconHoverColor: variables?.colors?.darkBlue,
      panelColor: variables?.colors?.paleGray,
    },
    backgrounds: {
      primary: variables.colors.blue,
    },
    buttons: {
      dropdownIconHover: variables.colors.mskit4,
      opaqueDropdownHover: variables.colors.mskit4Opaque,
      iconBase: variables.colors.blue,
      iconHover: variables.colors.secondStepBlue,
      tabActive: variables.colors.msdigital2,
      tabDefault: variables.colors.darkGray,
      startBackground: variables.colors.teal,
      startBackgroundHover: variables.colors.darkTeal,
      previewBackground: variables.colors.teal,
    },
    links: {
      primary: variables.colors.blue,
      primaryHover: variables.colors.msdigital2,
    },
    headers: {
      primary: variables.colors.darkerDarkBlue,
    },
    heading: {
      level: {
        1: variables.colors.secondStepBlue,
        2: variables.colors.secondStepBlue,
        3: variables.colors.secondStepBlue,
      },
    },
  },

  heading: {
    level: {
      1: {
        mobile: {
          height: '2.25rem',
          size: '2rem',
        },
      },
      2: {
        mobile: {
          height: '1.625rem',
          size: '1.25rem',
        },
      },
    },
  },

  // TODO: LEARN-9633, move backround images and colors to separate objects
  banners: {
    headerTextColor: variables.colors.white,
    headerTextFont: 'Sharpslab-extrabold',
    headerTextSize: '42px',
    backgroundImage: backgroundImage,
    bannerTextColor: variables.colors.white,
    landingBackgroundColor: variables.colors.msdigital2,
    landingBackgroundImage: blobImgMiddleSchool,
    sublandingBackgroundImage: subBlobImgMiddleSchool,
    alignment: 'center',
    textAlignment: 'center',
    contentTypeDescriptionBackgroundColor: variables.colors.darkBlue,
    contentTypeDescriptionBackgroundImage: contentBlobImgMiddleSchool,
    contentTypeDescriptionIconColor: variables.colors.white,
  },

  callouts: {
    headerTextColor: variables.colors.msdigital2,
    headerTextFont: 'Sharpslab-extrabold',
    headerTextSize: '28px',
    bodyTextColor: variables.colors.black,
    backgroundColor: variables.colors.white,
    iconBorderRadius: '0.688rem 0.688rem 2.813rem 0.688rem',
    boxShadow: `0rem 0rem 0.625rem ${variables.colors.gray300}`,
    categoryTileH3Color: variables.colors.white,
    svgFillColor: variables.colors.darkBlue,
  },

  anchorNavigation: {
    borderBottomStyle: 'none',
    borderBottomColor: 'none',
    borderBottomWidth: 'none',
    boxShadow: `0px 0px 10px ${variables.colors.gray300}`,
  },

  resources: {
    backgroundColorTop: variables.colors.msdigital2,
    backgroundColorBottom: variables.colors.backgroundNeutralGray,
  },
}
