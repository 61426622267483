import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  BodyCopy,
  HeaderFour,
  IconVideo,
  ModalContentWrapper,
} from 'secondstep-components'
import ClosableModal from 'experienceComponents/ClosableModal'

export const Icon = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 0.5rem;
`

export const ResourceCardWrapper = styled.div`
  display: flex;
  align-items: center;
  min-width: 18.4375rem;
  width: 18.4375rem;
  height: 3.45rem;
  margin-bottom: 0.75rem;
  padding: 0.8125rem 1.25rem 0.625rem;
  border-radius: 0.625rem;
  background: ${themeGet('colors.paleGray')};
  transition: background 0.2s ease;

  &:hover {
    background: ${themeGet('colors.gray300')};
    transition: background 0.2s ease;
    cursor: pointer;
  }
`

export const StyledBodyCopy = styled(BodyCopy)``

export const StyledHeaderFour = styled(HeaderFour)`
  margin-bottom: 0;
`

export const StyledIconVideo = styled(IconVideo)``

export const StyledModal = styled(ClosableModal)`
  ${ModalContentWrapper} {
    width: 100%;
    height: 100%;
    padding: 2.8125rem 1.75rem 1.75rem;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`
