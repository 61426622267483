export const canAccessWebinar = userClaims => {
  let claims = Object.keys(userClaims || {}).reduce((acc, fullClaimKey) => {
    const claim = fullClaimKey.replace(
      /^has(.*?)([A-Z][a-z]+)Claim$/,
      (_, claim, role) => claim.toLowerCase() + ' ' + role.toLowerCase(),
    )
    if (userClaims[fullClaimKey]) {
      acc.push(claim)
    }
    return acc
  }, [])
  claims = claims
    .filter(claim => !claim.match(/famil/i))
    .filter(claim => !claim.match(/^ft/i))
    .filter(claim => !claim.match(/^highSchool/i)) // TODO: remove this when high school is no longer in field test
  return claims.length > 0
}
