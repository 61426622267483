import PropTypes from 'prop-types'
import { variables, themes } from 'themes'
import { toCamelCase } from 'utils/stringHelpers'

export const MOCK_RESOURCE_LIBRARY_DATA_FLAG =
  'LEARN-17179-learn-mock-resource-library'

export const GRADE_METADATA_ID = 'metadataGrade'
export const TYPE_METADATA_ID = 'metadataResourceType'
export const AUDIENCE_METADATA_ID = 'metadataAudience'

export const AUDIENCE_SCHOOL_LEADER = 'School Leader'
export const AUDIENCE_STUDENT = 'Student'
export const AUDIENCE_EDUCATOR = 'Educator'

export const HIGH_SCHOOL_URL = '/product/high-school/'

export const HIGH_SCHOOL_INDIVIDUAL_RESOURCE_PATH = `${HIGH_SCHOOL_URL}learning-experiences`

export const HIGH_SCHOOL_SETTING_LATEST_TAB =
  'secondstep_high_school_setting_latest_tab'

export const isHighSchoolProgramSupports = () => {
  if (typeof location !== 'undefined' && location.pathname) {
    return location.pathname.includes('/high-school/program-supports')
  }
  return false
}

export const LinkTitle = PropTypes.shape({
  title: PropTypes.string,
  path: PropTypes.string,
})

export const LinkDescriptionTitle = PropTypes.shape({
  title: PropTypes.string,
  description: PropTypes.string,
  path: PropTypes.string,
})

export const CollectionType = PropTypes.shape({
  schoolwide: LinkTitle,
  educatorPractice: LinkTitle,
  studentActivities: PropTypes.arrayOf(LinkTitle),
})

export const processGrades = matchingMetadata => {
  if (!matchingMetadata || matchingMetadata.length === 0) {
    return ''
  }

  return formatGrades(matchingMetadata.map(m => m.grade))
}

export const formatGrades = gradesArray => {
  if (!gradesArray) return ''

  const grades = gradesArray.map(m => parseInt(m.replace(/\D/g, '')))
  const uniqueGrades = [...new Set(grades)] // Remove duplicates
  if (uniqueGrades.length === 1) {
    return `G${uniqueGrades[0]}` // Return single grade if only one exists
  } else {
    const minGrade = Math.min(...uniqueGrades)
    const maxGrade = Math.max(...uniqueGrades)
    return `G${minGrade}–${maxGrade}`
  }
}

// TODO: add a themeGet call here and replace with theming calls
// see:
export const getHighlightColor = audience => {
  switch (audience) {
    case 'School Leader':
    case 'School Leaders':
      return variables.colors.highschool.cyan1
    case 'Student':
    case 'Students':
      return variables.colors.highschool.green3
    default:
      // (educators)
      return variables.colors.highschool.pink2
  }
}

export const getBackgroundGradient = audience => {
  switch (audience) {
    case 'School Leader':
    case 'School Leaders':
      return themes.highschool.colors.gradients.leaders
    case 'Student':
    case 'Students':
      return themes.highschool.colors.gradients.students
    default:
      // (educators)
      return themes.highschool.colors.gradients.educators
  }
}

const matchFacetIdAndContentType = (desiredFacets, metadataValues) => {
  if (!Array.isArray(desiredFacets)) {
    return { matches: [] } // Return an empty matches array if desiredFacets is not an array
  }
  const matches = desiredFacets.map(facet => {
    const { facetId } = facet
    const matchingMetadata = metadataValues.filter(
      metadata => metadata?.contentType?.id === facetId,
    )
    return { facetId, matchingMetadata }
  })

  return { matches }
}

const getFacetValueFromMatches = (matches, facetId, key) => {
  const matchingMetadata = matches.find(match => match?.facetId === facetId)
    ?.matchingMetadata

  if (matchingMetadata && matchingMetadata.length > 0) {
    const facetResults = matchingMetadata
      .map(metadata => metadata[key])
      .join(', ')
    return facetResults
  }

  return null
}

const getProcessedGrades = (matches, facetId) => {
  const matchingMetadata = matches.find(match => match.facetId === facetId)
    ?.matchingMetadata

  if (matchingMetadata && matchingMetadata.length > 0) {
    return processGrades(matchingMetadata)
  }

  return null
}

export const getResourceValues = (resource, desiredFacets) => {
  const result = matchFacetIdAndContentType(
    desiredFacets,
    resource.metadataValues,
  )

  const audiences = getFacetValueFromMatches(
    result.matches,
    'metadataAudience',
    'audience',
  )

  const grades = getProcessedGrades(result.matches, GRADE_METADATA_ID, 'grade')
  const resourceTypes = getFacetValueFromMatches(
    result.matches,
    'metadataResourceType',
    'resourceType',
  )
  const durations = getFacetValueFromMatches(
    result.matches,
    'metadataDuration',
    'duration',
  )
  const units = getFacetValueFromMatches(result.matches, 'metadataUnit', 'unit')
  return {
    audiences: audiences,
    grades: grades,
    resourceTypes: resourceTypes,
    durations: durations,
    units: units,
  }
}

export const getTextBasedOnLocation = (data, isIntegrated) => {
  if (!Array.isArray(data)) {
    console.error("Error: Input 'data' must be an array.")
    return null
  }

  if (typeof isIntegrated !== 'boolean') {
    console.error("Error: Input 'isIntegrated' must be a boolean value.")
    return null
  }

  const targetKey = isIntegrated ? 'integrated' : 'dedicated'
  const filteredItems = data.filter(item =>
    item.tooltipLocation.toLowerCase().includes(targetKey),
  )

  return filteredItems.length > 0 ? filteredItems.map(item => item.text) : null
}

export const getLabelBasedOnLocation = (data, label) => {
  if (!Array.isArray(data)) {
    console.error("Error: Input 'data' must be an array.")
    return null
  }

  if (typeof label !== 'string') {
    console.error("Error: Input 'label' must be a string.")
    return null
  }

  const targetKey = label.toLowerCase()
  const filteredItems = data.filter(item =>
    item.tooltipLocation.toLowerCase().includes(targetKey),
  )

  return filteredItems.length > 0 ? filteredItems.map(item => item.text) : null
}

export const getAudienceMetadata = resourceMetadata => {
  if (!resourceMetadata?.metadataValues) {
    return undefined
  }

  return resourceMetadata.metadataValues.find(
    meta =>
      meta?.contentType?.id === AUDIENCE_METADATA_ID ||
      toCamelCase(meta?.contentType) === AUDIENCE_METADATA_ID,
  )?.audience
}

export const getGradeMetadata = resourceMetadata => {
  if (!resourceMetadata?.metadataValues) {
    return undefined
  }

  const grades = resourceMetadata.metadataValues.filter(
    meta =>
      meta?.contentType?.id === GRADE_METADATA_ID ||
      toCamelCase(meta?.contentType) === GRADE_METADATA_ID,
  )

  return processGrades(grades)
}

export const getTypeMetadata = resourceMetadata => {
  if (!resourceMetadata?.metadataValues) {
    return undefined
  }

  return resourceMetadata.metadataValues.find(
    meta =>
      meta?.contentType?.id === TYPE_METADATA_ID ||
      toCamelCase(meta?.contentType) === TYPE_METADATA_ID,
  )?.resourceType
}

export const HS_MODE_KEY = 'mode'
export const HS_PREVIEW_MODE_VALUE = 'preview'
export const HS_TEACH_MODE_VALUE = 'teach'

export const isPreviewMode = () => {
  const queryParams = new URLSearchParams(location?.search)
  return queryParams.get(HS_MODE_KEY) === HS_PREVIEW_MODE_VALUE
}

export const previewModeQueryParam = () =>
  `?${HS_MODE_KEY}=${HS_PREVIEW_MODE_VALUE}`

export const isHighSchoolUrl = () => location.pathname.includes(HIGH_SCHOOL_URL)

export const DASHBOARD_ROUTE = `/dashboard/grades-9-12`

export const isHighSchoolDashboard = () =>
  location.pathname.includes(DASHBOARD_ROUTE)

const getJoinedMetadataValues = (metadataArray, property) => {
  return metadataArray
    .filter(metadata => metadata[property])
    .map(metadata => metadata[property])
    .join(', ')
}

export const getCollectionResourceValues = resource => {
  const matchingMetadata = resource.metadataValues || []

  const grades = getJoinedMetadataValues(matchingMetadata, 'grade')
  const resourceTypes = getJoinedMetadataValues(
    matchingMetadata,
    'resourceType',
  )
  const audiences = getJoinedMetadataValues(matchingMetadata, 'audience')
  const units = getJoinedMetadataValues(matchingMetadata, 'unit')
  return {
    grades,
    resourceTypes,
    audiences,
    units,
  }
}

export const filterByResourceType = (resources, type) => {
  return resources.filter(resource =>
    resource.metadataValues.some(metadata => metadata.resourceType === type),
  )
}

export const teachModeQueryParam = () =>
  `?${HS_MODE_KEY}=${HS_TEACH_MODE_VALUE}`

export const getLatestDashboardTab = () => {
  const latestTab = localStorage.getItem(HIGH_SCHOOL_SETTING_LATEST_TAB)
  if (latestTab !== null) {
    return parseInt(latestTab)
  }
  return null
}

// expects an integer for the tab index:
export const setLatestDashboardTab = tab => {
  localStorage.setItem(HIGH_SCHOOL_SETTING_LATEST_TAB, tab)
}

export const clearLatestDashboardTab = () => {
  localStorage.removeItem(HIGH_SCHOOL_SETTING_LATEST_TAB)
}
