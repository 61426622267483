import React from 'react'
import PropTypes from 'prop-types'
import { BodyCopy, HeaderThree } from 'secondstep-components'
import AdvisoryActivityLink from '../AdvisoryActivityLink'
import { RecommendedWrapper, ActivityFlex } from './component.styles'

const RecommendedAdvisoryActivities = ({
  contentfulItem,
  productName,
  unitName,
}) => {
  const advisoryTypes = filterAdvisories(contentfulItem.additionalResources)
  return (
    <RecommendedWrapper boxShadow dataTestId="recommended-advisory-activities">
      <HeaderThree dataTestId="header-additional-resources" size={'large'}>
        {contentfulItem.additionalResourcesLabel}
      </HeaderThree>
      <BodyCopy data-testid="subheader-recommended-advisory-activities">
        {`Supplement ${unitName} lessons and build relationships in your classroom with these recommended activities.`}
      </BodyCopy>
      <ActivityFlex>
        {Object.keys(advisoryTypes).map(type => {
          const advisoryType = advisoryTypes[type]
          return (
            <AdvisoryActivityLink
              advisoryType={advisoryType}
              key={`advisory-type-${advisoryTypes[type][0].id}`}
              productName={productName}
            />
          )
        })}
      </ActivityFlex>
    </RecommendedWrapper>
  )
}

const filterAdvisories = resources => {
  let types = {}
  resources.forEach(resource => {
    if (!resource.advisoryActivityType) return
    if (!types[resource.advisoryActivityType]) {
      types[resource.advisoryActivityType] = []
    }
    types[resource.advisoryActivityType].push(resource)
  })
  return types
}

RecommendedAdvisoryActivities.propTypes = {
  contentfulItem: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  productName: PropTypes.string,
  unitName: PropTypes.string.isRequired,
}

export default RecommendedAdvisoryActivities
