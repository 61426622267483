import AccessibleTextLink from 'components/AccessibleTextLink'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BaseContainer } from 'secondstep-components'
import { Link } from 'react-router-dom'
import ReactMarkdown from 'react-markdown'

export const BodyText = styled(ReactMarkdown)`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamily')};
  font-size: 1rem;
  margin-top: 0.75rem;
  p {
    margin: 0;
  }

  a {
    color: ${themeGet('colors.darkGray')};
  }
`

export const ApiErrorWrapper = styled.div`
  margin-bottom: 20px;
`

export const Header = styled('h2').attrs(props => ({
  marginTop: props.isLastStep ? '5.6875rem' : '1.175rem',
}))`
  font-family: ${themeGet('font.sharpSlab.semibold')};
  font-size: 1.4375rem;
  color: ${themeGet('colors.iconBlue')};
  margin-top: ${({ marginTop }) => marginTop};

  ${themeGet('breakpoints.mobileLarge')} {
    margin-top: 1.375rem;
  }
`

export const Icon = styled('img').attrs(props => ({
  width: props.width,
  height: props.height,
}))`
  height: ${({ height }) => height}px;
  margin-bottom: 1rem;
  width: ${({ width }) => width}px;
`

export const SubHeader = styled.div`
  font-family: ${themeGet('font.molde.regular')};
  font-size: 1rem;
  white-space: break-spaces;
  margin-top: 0.625rem;
  margin-bottom: 1.875rem;
  & a {
    text-decoration: none;
    color: ${themeGet('colors.blue')};
  }
`

export const TextWrapper = styled('div').attrs(props => ({
  justifyContent: props.subtitle ? 'space-evenly' : 'center',
}))`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent};
`

export const StyledContainer = styled(BaseContainer).attrs(props => ({
  as: 'section',
  marginLeft:
    props.centeredContent && !props.isLastStep ? '3.062rem' : '1.25rem',
  marginRight:
    props.centeredContent && !props.isLastStep ? '3.062rem' : '1.25rem',
  textAlign: props.centeredContent ? 'center' : 'left',
}))`
  align-items: initial;
  padding: 0;
  max-width: 85.375rem;
  margin-left: ${({ marginLeft }) => marginLeft};
  margin-right: ${({ marginRight }) => marginRight};
  text-align: ${({ textAlign }) => textAlign};

  ${themeGet('breakpoints.mobileLarge')} {
    margin-top: 0.9375rem;
  }
`
export const StyledLinkWrapper = styled(Link)`
  text-decoration: none;
`

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 1.125rem;
  height: 241px;
  margin-bottom: 1.75rem;

  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: auto;
  }

  & > div {
    width: 297px;
    height: 242px;
  }
`

export const StepModalCurrentStepIndicator = styled.div`
  color: ${themeGet('colors.lightGray')};
  margin-right: 2.1875rem;
`

export const StepModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0;
  font-size: 0.875rem;
  margin-bottom: 1.25rem;
  height: 43px;

  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;
    margin-bottom: 1.75rem;
    gap: 1.75rem;
  }

  & div:first-child {
    height: 3.75em;
    flex: 3;

    & a {
      text-decoration: none;
      color: ${themeGet('colors.blue')};
    }
  }

  & div:nth-child(2) {
    flex: 1;
    & button {
      width: 100%;
    }
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1.875rem;

  ${themeGet('breakpoints.mobileLarge')} {
    margin-top: 0;
    margin-bottom: 1.75rem;
  }

  & div:first-child {
    display: flex;
    height: 3.75em;
    flex: 3;
    gap: 0.75em;
    flex-direction: column;
  }

  & div:nth-child(2) {
    flex: 1;
    & button {
      width: 100%;
    }
  }

  & button {
    width: 10.125rem;
  }
`

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 5.125rem;
  & img {
    width: 189px;
    height: 121px;
    text-align: center;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    margin-top: 2.625rem;
  }
`

export const DownloadPDFLink = styled(AccessibleTextLink)`
  color: ${themeGet('colors.blue')};
  font-size: 1rem;
  font-weitght: 400;
  font-family: ${themeGet('font.molde.medium')};
`

export const DownloadWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 0.5rem;
  svg {
    margin-right: 0.5rem;
    width: 1.5625rem;
    color: ${themeGet('colors.blue', '#3c6e90')};
  }
`

export const SelectedOptionWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.625rem;
  margin-top: 2.1875rem;
  margin-bottom: 0;

  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;
    margin-bottom: 1.75rem;
    justify-content: flex-start;
    align-items: center;
    height: auto;
  }
`

export const SelectedOptionHeader = styled.h2`
  font-size: 1.4375rem;
  text-align: center;
  font-family: ${themeGet('fontFamilyHeadingMedium')};
  color: ${themeGet('colors.iconBlue')};
`

export const SelectedCardWrapper = styled.div`
  width: 18.75rem;
  & > div {
    height: 124px;

    & > img {
      margin-top: 0.625rem;
      margin-bottom: 0.5rem;
      max-height: 2.5rem;
    }

    & h2 {
      font-size: 1rem;
      color: ${themeGet('colors.darkBlue')};
      width: 168px;
      white-space: pre-wrap;
      line-height: 1.4rem;
    }
  }
`

export const StyledHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & button {
    align-self: center;
    position: relative;
  }

  & h6 {
    align-self: center;
    padding: 0;
    margin: 0;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  width: 35em;
  height: 10em;
  flex-direction: column;
  justify-content: center;
  background-color: ${themeGet('colors.gray200')};
  border-radius: 0.5313rem;
`
