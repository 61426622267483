import React from 'react'
import PropTypes from 'prop-types'
import AnchoredContentLink from '../AnchoredContentLink'
import { AnchorNavigationWrapper, ContentsText } from './component.styles'

const AnchorNavigation = ({ links }) => {
  return (
    <AnchorNavigationWrapper data-testid="content-anchor-navigation">
      <ContentsText>In this article:</ContentsText>
      {links.map((link, index) => {
        return (
          <AnchoredContentLink
            index={index}
            key={`${link}-${index}`}
            link={link}
          />
        )
      })}
    </AnchorNavigationWrapper>
  )
}

AnchorNavigation.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      hash: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default AnchorNavigation
