import axios from 'axios'
import { domains } from 'config/env'
import Auth0Manager from 'auth/utils/auth0Manager'

const baseApiAddress = `${domains.SECONDSTEP_API}/`

export function makeClient(baseApiAddress) {
  const _api = axios.create({
    baseURL: baseApiAddress,
    headers: {
      'Cache-Control': 'no-cache',
      'Content-Type': 'application/json',
    },
  })

  _api.interceptors.request.use(async request => {
    const token = await Auth0Manager.getAccessToken()
    request.headers.Authorization = `Bearer ${token}`
    return request
  })

  _api.interceptors.response.use(
    response => {
      return response
    },
    error => {
      if (error.response.status === 401) {
        location.replace(window.location.origin)
      }
      return Promise.reject(error)
    },
  )

  return {
    baseApiAddress: baseApiAddress,

    getSetupLicensesForUser: async () => _api.get(`licenses/me`),

    getUserAccess: async () => {
      return _api.get('access/me')
    },

    getUserLicenses: async () => {
      return _api.get(`licenses/me`)
    },

    getUserSites: async () => {
      return _api.get('sites/me')
    },

    getValidatePak: async pakNumber => {
      const body = {
        pakNumber,
      }
      return _api.post(`pak/validate`, body)
    },

    getValidateAccessCode: accessCode => {
      const body = {
        accessCode,
      }
      return _api.post(`access/codes`, body)
    },

    postAcceptUserAgreement: async documentId => {
      const body = {
        documentId,
      }
      return _api.post(`useragreement/me`, body)
    },

    postAccessCode: accessCode => {
      const body = {
        accessCode,
        processCode: true,
      }
      return _api.post(`access/codes`, body)
    },

    postPakNumber: async pakNumber => {
      const body = {
        pakNumber,
      }
      return _api.post(`userpak/me`, body)
    },

    updateSitePacingDates: async (siteId, programAccessCode, pacingUpdate) => {
      return _api.post(
        `sites/${siteId}/pacing/${programAccessCode}`,
        pacingUpdate,
      )
    },

    updateSitePreferences: async (siteId, sitePreferences) => {
      return _api.put(`sites/${siteId}/preferences`, sitePreferences)
    },

    updateUserProgramPreferences: async (siteId, sitePreferences) => {
      const url = `sites/${siteId}/programpreferences`
      return _api.put(url, sitePreferences)
    },

    updateMspOptInSettings: async mspOptInArr => {
      return _api.patch(`sites/adminpreferences`, mspOptInArr)
    },
  }
}

const api = makeClient(baseApiAddress)

export default api
