import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, Text, Background, Indicator } from './component.styles'

const ProgressBar = ({
  className,
  dataTestId,
  notStartedText,
  percentComplete,
  isOnlyLeader,
}) => {
  const progressText =
    percentComplete === 0 ? notStartedText : `${percentComplete}% Complete`

  return (
    <Wrapper className={className} dataTestId={dataTestId}>
      <Text
        className={`${className}-text`}
        dataTestId={`${dataTestId}-text`}
        id={`${dataTestId}-text`}
      >
        {progressText}
      </Text>
      <Background
        className={`${className}-background`}
        dataTestId={`${dataTestId}-background`}
        id={`${dataTestId}-background`}
      >
        <Indicator
          className={`${className}-indicator`}
          dataTestId={`${dataTestId}-indicator`}
          id={`${dataTestId}-indicator`}
          isOnlyLeader={isOnlyLeader}
          percentComplete={percentComplete}
        />
      </Background>
    </Wrapper>
  )
}

ProgressBar.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  isOnlyLeader: PropTypes.bool,
  notStartedText: PropTypes.string,
  percentComplete: PropTypes.number,
}

ProgressBar.defaultProps = {
  dataTestId: 'sela-progressbar',
  notStartedText: 'Not Started',
  percentComplete: 0,
  isOnlyLeader: false,
}

export default ProgressBar
