import React from 'react'
import PropTypes from 'prop-types'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
} from 'layers/errorHandling/apiError'
import AdminWelcomeBlock from './component'

// export for testability
export const shapeLinkInfo = link => {
  if (link.contentType.id === 'linkButton') {
    return {
      isVideo: false,
      openInSameTab: link?.openSameTab === undefined ? true : link?.openSameTab,
      videoEntryId: undefined,
      videoLocale: undefined,
      url: link?.url,
    }
  } else if (link.contentType.id === 'video') {
    return {
      isVideo: true,
      // this property doesn't really apply for videos, but set it anyway
      openInSameTab: true,
      videoEntryId: link?.id,
      videoLocale: link?.videoRef?.locale,
      // VideoDataGetter expects an entry id, but set this for consistency
      url: link?.videoRef?.azureVideoUrl,
    }
  }
  throw new Error(
    `Unsupported content type ${link.contentType.id} passed for link`,
  )
}

const AdminWelcomeBlockContainer = ({
  linkInfo,
  navigationEvent,
  text,
  title,
}) => {
  return (
    <AdminWelcomeBlock
      linkInfo={linkInfo}
      navigationEvent={navigationEvent}
      text={text}
      title={title}
    />
  )
}

AdminWelcomeBlockContainer.propTypes = {
  linkInfo: PropTypes.shape({
    isVideo: PropTypes.bool,
    openInSameTab: PropTypes.bool,
    url: PropTypes.string,
    // only set if isVideo is true:
    videoEntryId: PropTypes.string,
    videoLocale: PropTypes.string,
  }),
  navigationEvent: PropTypes.func,
  text: PropTypes.string,
  title: PropTypes.string,
}

const mapper = entry => {
  if (!entry) {
    return {}
  }
  try {
    return { linkInfo: shapeLinkInfo(entry) }
  } catch (err) {
    console.error(err)
    return {}
  }
}
const options = {
  include: 3,
  mapper,
  entryIdProp: true,
  spread: true,
}

export default ContentEntryDataGetter(
  withApiError(AdminWelcomeBlockContainer, [ERROR_TYPE_CONTENTFUL]),
  options,
)
