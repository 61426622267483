import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { TextLink } from 'secondstep-components'
import { toKebabCase } from 'utils/stringHelpers'
import NavigationHelper from 'layers/navigation/navigationHelper'
import MsBHUMultiSiteModal from 'components/MsBHUMultiSiteModal'
import MsBHUSingleSiteModal from 'components/MsBHUSingleSiteModal'
import { calculateSitesWithBullyingUnitOnCount } from 'utils/siteData'
import {
  AdminSearchSelectExplore,
  ExploreContainer,
  InlineTextLink,
  ProgramResourceContainer,
  ProgramResourceRow,
  ProgramResource,
  StyledBackpackV2Icon,
  StyledTelescopeIcon,
  SupportLinksWrapper,
  Title,
  TitleHairline,
  UnitContainer,
} from './component.styles'
import {
  contentfulProgramLinksInternalTitle,
  k5ProgramContentfulInternalTitles,
  msProgramContentfulInternalTitles,
  MY_CLASSES,
} from '../constants'

import { withTheme } from 'styled-components'

const ExplorePrograms = ({
  availableLinks,
  className,
  navigationEvent,
  mspSites,
  updateMSPOptIn,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleOpenModal = () => setIsModalOpen(!isModalOpen)
  const handleCloseModal = () => setIsModalOpen(false)
  const handleOnSave = async mspOptInArr => {
    await updateMSPOptIn(mspOptInArr)
    handleCloseModal()
  }
  const sitesWithBullyingUnitOnCount = calculateSitesWithBullyingUnitOnCount(
    mspSites,
  )
  const navigateHandler = (urlPath, openSameTab) => {
    const type = openSameTab
      ? NavigationHelper.types.PUSH
      : NavigationHelper.types.NEW_TAB_SAME_SITE

    navigationEvent(urlPath, type)
  }

  const renderProgramSupportLinks = programName => {
    let filteredLinks
    if (availableLinks?.length > 0) {
      filteredLinks = availableLinks.find(link => {
        return link?.internalTitle === contentfulProgramLinksInternalTitle
      })
    }
    let linkProgramTitles
    if (programName === 'k5') {
      linkProgramTitles = k5ProgramContentfulInternalTitles
    } else if (programName === 'ms') {
      linkProgramTitles = msProgramContentfulInternalTitles
    }
    if (filteredLinks) {
      const { icon, linkMenu } = filteredLinks || {}
      const filteredLinkMenu = linkMenu.filter(link => {
        return linkProgramTitles.indexOf(link.internalTitle) !== -1
      })
      const { title } = icon || {}
      const DropdownButtonIcon = () => {
        return <StyledTelescopeIcon aria-hidden="true" role="presentation" />
      }
      return (
        <Fragment key={title}>
          {filteredLinkMenu && (
            <AdminSearchSelectExplore
              dataTestId={toKebabCase(programName + ' Preview Lessons')}
              defaultLabel={'Preview Lessons'}
              dropdownButtonIcon={<DropdownButtonIcon />}
              keyProp="id"
              labelProp="buttonText"
              onChange={({ url: optionUrlPath, openSameTab }) =>
                navigateHandler(optionUrlPath, openSameTab)
              }
              options={filteredLinkMenu}
              shouldHideSelectedIcon={true}
            />
          )}
        </Fragment>
      )
    }

    return null
  }

  return (
    <ExploreContainer className={className}>
      <Title>Explore the Program</Title>
      <TitleHairline />
      <ProgramResourceContainer>
        <ProgramResourceRow>
          <ProgramResource>
            <h3>Elementary (Grades K-5)</h3>
          </ProgramResource>
          <ProgramResource>
            <SupportLinksWrapper>
              {renderProgramSupportLinks('k5')}
            </SupportLinksWrapper>
          </ProgramResource>
          <ProgramResource>
            <TextLink
              aria-label={'View all lessons elementary'}
              dataTestId={`all-lessons-k5`}
              href={'/product/elementary/lessons'}
              target="_self"
            >
              <StyledBackpackV2Icon aria-hidden="true" role="presentation" />
              {MY_CLASSES}
            </TextLink>
          </ProgramResource>
        </ProgramResourceRow>
      </ProgramResourceContainer>
      <ProgramResourceContainer>
        <ProgramResourceRow>
          <ProgramResource>
            <h3>Middle School (Grades 6-8)</h3>
          </ProgramResource>
          <ProgramResource>
            <SupportLinksWrapper>
              {renderProgramSupportLinks('ms')}
            </SupportLinksWrapper>
          </ProgramResource>
          <ProgramResource>
            <TextLink
              aria-label={'View all lessons middle school'}
              dataTestId={`all-lessons-ms`}
              href={'/product/middle-school/lessons'}
              target="_self"
            >
              <StyledBackpackV2Icon aria-hidden="true" role="presentation" />
              {MY_CLASSES}
            </TextLink>
          </ProgramResource>
        </ProgramResourceRow>

        <ProgramResourceRow>
          <UnitContainer>
            <h4>Unit Settings</h4>
            Recognizing Bullying and Harassment Unit
            <InlineTextLink
              onClick={() => handleOpenModal()}
              role="contentinfo"
            >
              {mspSites.length > 1
                ? ` ON for ${sitesWithBullyingUnitOnCount} out of ${mspSites.length} Schools`
                : sitesWithBullyingUnitOnCount > 0
                ? ': ON'
                : ': OFF'}
            </InlineTextLink>
          </UnitContainer>
        </ProgramResourceRow>
      </ProgramResourceContainer>
      {isModalOpen && mspSites.length > 1 && (
        <MsBHUMultiSiteModal
          onModalCancel={handleCloseModal}
          onModalSave={handleOnSave}
          rawSitesData={mspSites}
        />
      )}
      {isModalOpen && mspSites.length === 1 && (
        <MsBHUSingleSiteModal
          onModalCancel={handleCloseModal}
          onModalSave={handleOnSave}
          site={mspSites[0]}
        />
      )}
    </ExploreContainer>
  )
}

ExplorePrograms.propTypes = {
  availableLinks: PropTypes.array,
  className: PropTypes.string,
  mspSites: PropTypes.array,
  navigationEvent: PropTypes.func,
  updateMSPOptIn: PropTypes.func,
}

export default withTheme(ExplorePrograms)
