import {
  getRegularEnglishPluralForm,
  commaSeparateWords,
  pluralizeEnglish,
} from './stringHelpers'
import { convertMillisecondsToHoursMinutesObject } from './timeFormattingHelpers'

export const formatCountLabel = (count, labelName) => {
  return `${count} ${getRegularEnglishPluralForm(count, labelName)}`
}

export const formatCountOfCountLabel = (count, total, label) => {
  return count === total
    ? `All ${getRegularEnglishPluralForm(2, label)}`
    : `${count} of ${total} ${getRegularEnglishPluralForm(2, label)}`
}

export const formatEmptyGradesLabel = grades => {
  const gradeNames = grades.map(g => g.gradeName)
  return `${commaSeparateWords(gradeNames)} ${
    grades.length > 1 ? "haven't" : "hasn't"
  } created classes yet.`
}

export const formatHoursSinceTimestamp = utcTimestamp => {
  const currentUtcTimestamp = new Date()
  const timeDifferenceInMilliseconds =
    currentUtcTimestamp.getTime() - new Date(utcTimestamp).getTime()

  let { hours, minutes } = convertMillisecondsToHoursMinutesObject(
    timeDifferenceInMilliseconds,
  )

  let copy = `Updated just now`

  if (hours < 1 && minutes > 0) {
    copy = `Updated ${pluralizeEnglish(minutes, 'minute', 'minutes')} ago`
  } else if (hours > 0) {
    if (minutes > 30) {
      hours++
    }
    copy = `Updated ${pluralizeEnglish(hours, 'hour', 'hours')} ago`
  }

  return copy
}

export const formatPageCount = (currentPage, numPages) => {
  return `Page ${currentPage} of ${numPages}`
}

export const formatProgress = (classesTaught, daysToReport, classesCount) => {
  return `**${formatCountOfCountLabel(
    classesTaught,
    classesCount,
    'class',
  )} ** marked a lesson done in the past ${daysToReport} days`
}

export const formatSchoolCounts = (
  classesCount,
  teachersCount,
  administratorsCount,
) => {
  const classesCountText = formatCountLabel(classesCount, 'Class')
  const teachersCountText = ` • ${formatCountLabel(teachersCount, 'Teacher')}`
  const administratorsCountText = ` • ${formatCountLabel(
    administratorsCount,
    'Administrator',
  )}`

  return `**${classesCountText}**${teachersCountText}${administratorsCountText}`
}

export const formatItemCount = (
  itemStartIndex,
  itemEndIndex,
  totalItems,
  label,
) =>
  `${itemStartIndex}–${itemEndIndex} of ${formatCountLabel(totalItems, label)}`

export const formatSiteCount = (siteStartIndex, siteEndIndex, maxSites) =>
  formatItemCount(siteStartIndex, siteEndIndex, maxSites, 'School')
