import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import {
  ContentPanelWrapper,
  ColumnsWrapper,
  StyledHairline,
} from './component.styles'
import AdminFlexibleContentHeader from './AdminFlexibleContentHeader/component'
import AdminFlexibleContentColumn from './AdminFlexibleContentColumn'

const AdminFlexibleContentPanel = ({
  alternateHeaderContent,
  breakpointTablet,
  className,
  description,
  displayTitle,
  getAlternateContentForTab,
  handleNavigate,
  navigateToPage,
  navigationEvent,
  showHairline,
  tabColumns,
  toggleModal,
  tooltips,
}) => {
  const getModalFlowContent = content => {
    const modalFlowContent = content.find(column => {
      return column?.contentType?.id === 'modalFlow'
    })
    return modalFlowContent
  }

  return (
    <ContentPanelWrapper className={className}>
      <Fragment key={`admin-flex-panel-${displayTitle}`}>
        {alternateHeaderContent && alternateHeaderContent}
        {!alternateHeaderContent && (
          <AdminFlexibleContentHeader
            description={description}
            displayTitle={displayTitle}
            showHairline={showHairline}
            tooltips={tooltips}
          />
        )}
        {showHairline && <StyledHairline />}

        <ColumnsWrapper breakpointTablet={breakpointTablet}>
          {tabColumns.map((columnContent, index) => {
            const alternateContent =
              getAlternateContentForTab &&
              getAlternateContentForTab(columnContent)
            if (alternateContent) {
              return alternateContent
            } else {
              return (
                <AdminFlexibleContentColumn
                  alternateContent={alternateContent}
                  breakpointTablet={breakpointTablet}
                  columnContent={columnContent}
                  handleNavigate={handleNavigate}
                  key={`admin-flex-column-${index}`}
                  navigateToPage={navigateToPage}
                  navigationEvent={navigationEvent}
                  toggleModal={() =>
                    toggleModal(getModalFlowContent(columnContent))
                  }
                />
              )
            }
          })}
        </ColumnsWrapper>
      </Fragment>
    </ContentPanelWrapper>
  )
}

AdminFlexibleContentPanel.propTypes = {
  alternateHeaderContent: PropTypes.object,
  breakpointTablet: PropTypes.bool,
  className: PropTypes.string,
  description: PropTypes.string,
  displayTitle: PropTypes.string,
  getAlternateContentForHeader: PropTypes.func,
  getAlternateContentForTab: PropTypes.func,
  handleNavigate: PropTypes.func,
  navigateToPage: PropTypes.func,
  navigationEvent: PropTypes.func,
  showHairline: PropTypes.bool,
  tabColumns: PropTypes.array,
  toggleModal: PropTypes.func,
  tooltips: PropTypes.array,
}

export default AdminFlexibleContentPanel
