import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { HeaderFour, ModalContentWrapper } from 'secondstep-components'
import ClosableModal from 'experienceComponents/ClosableModal'
import { VideoPlayButton } from 'secondstepVideo'

export const VideoCalloutButton = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 220px;
  padding: 0;
  border-radius: 0.75rem;
  background: ${themeGet('colors.white')};

  // box-shadow is temporary to show container on white background
  // can be removed when gray background is added
  // talk to James Moon if you're really curious
  box-shadow: 1px 1px 10px 1px #dee2e6;

  svg {
    transition: all 0.2s ease;
    color: ${themeGet('colors.blue')};
  }

  &:focus,
  &:hover {
    cursor: pointer;

    svg {
      color: ${themeGet('colors.orange')};
    }
  }

  @media (max-width: 650px) {
    margin-left: 0;
  }
`

export const ThumbnailWrapper = styled(Box)`
  position: relative;
  width: 100%;

  ${VideoPlayButton} {
    position: absolute;
    right: 28px;
    bottom: -38px;

    ${themeGet('breakpoints.mobile')} {
      right: 20px;
    }
  }
`

export const StyledModal = styled(ClosableModal)`
  ${ModalContentWrapper} {
    height: 100%;
    padding: 2.8125rem 1.75rem 1.75rem;
    width: 100%;
  }
`

export const VideoThumbnail = styled(Box)`
  width: 100%;
  min-height: 216px;
  border-radius: 0.75rem 0.75rem 0 1.25rem;
  background: center / cover url(${({ imgUrl }) => imgUrl});
`

export const VideoTitle = styled(HeaderFour)`
  margin: 0;
  color: ${themeGet('colors.black')};
`

export const CopyBox = styled(Box)`
  padding: 2.375rem 1.75rem 1.3125rem;
  border-radius: 0 0 0.75rem 0.75rem;
  color: ${themeGet('colors.darkGray')};

  ${themeGet('breakpoints.mobile')} {
    padding: 2.375rem 1.25rem 1.3125rem;
  }
`
