import React, { Component } from 'react'
import PropTypes from 'prop-types'
import HeaderBodyCopy from 'experienceComponents/HeaderBodyCopy'
import TranslationDisplay from 'components/TranslationDisplay'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'

class InlineTranslation extends Component {
  static propTypes = {
    bodyCopy: PropTypes.string,
    bypassContainer: PropTypes.bool,
    contentId: PropTypes.string.isRequired,
    h2Text: PropTypes.string,
    h3Text: PropTypes.string,
    id: PropTypes.string.isRequired,
    targetLocales: PropTypes.array,
  }

  constructor(props) {
    super(props)
    this.displayContainer = ContentEntryDataGetter(TranslationDisplay, {
      include: 3,
      spread: true,
      entryIdProp: true,
    })
  }

  render() {
    const {
      h2Text,
      h3Text,
      bodyCopy,
      contentId,
      id,
      targetLocales,
      bypassContainer,
    } = this.props || {}

    const Display = bypassContainer ? TranslationDisplay : this.displayContainer

    return (
      <div data-testid={`article-inline-translation-${id}`}>
        <HeaderBodyCopy
          bodyCopy={bodyCopy}
          h2Text={h2Text}
          h3Text={h3Text}
          id={contentId}
        />

        {contentId && (
          <Display entryId={contentId} targetLocales={targetLocales} />
        )}
      </div>
    )
  }
}

export default InlineTranslation
