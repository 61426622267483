import React from 'react'
import PropTypes from 'prop-types'
import { toKebabCase } from 'utils/stringHelpers'
import { StyledHeaderTwo, StyledContainer } from './component.styles'

const ContainerSection = ({ title, children }) => {
  const titleDataTestId = title
    ? `section-title-${toKebabCase(title)}`
    : `section-title`
  const sectionDataTestId = 'container-section'
  return (
    <>
      {title && (
        <StyledHeaderTwo dataTestId={titleDataTestId}>{title}</StyledHeaderTwo>
      )}
      {children && (
        <StyledContainer dataTestId={sectionDataTestId}>
          {children}
        </StyledContainer>
      )}
    </>
  )
}

ContainerSection.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
}

export default ContainerSection
