/* istanbul ignore file */

import React from 'react'
import { usePagination } from 'react-instantsearch-hooks-web'
import { Pagination } from 'secondstep-components'
import LearnErrorBoundary from 'components/LearnErrorBoundary'
import { Container } from './pagination.styles'

// This is a wrapper around the Pagination component from secondstep-components
const InstantSearchPagination = props => {
  const { currentRefinement, nbPages, refine, createURL } = usePagination(props)
  return (
    <Container>
      <LearnErrorBoundary
        message={
          <pre>
            {JSON.stringify(
              {
                currentRefinement,
                nbPages,
              },
              null,
              2,
            )}
          </pre>
        }
      >
        <Pagination
          createURL={createURL}
          currentPage={currentRefinement}
          gotoPage={refine}
          pageCount={nbPages}
        />
      </LearnErrorBoundary>
    </Container>
  )
}

export default InstantSearchPagination
