import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'secondstep-components'
import withSiteContext from 'layers/content/v2/Hocs/withSiteContext'
import { SiteType } from 'layers/content/v2/Hocs/withSiteContext/types'
import { Container } from './component.styles'
import InstanceView from './InstanceView'
import Preview from './Preview'

const ViewModes = {
  PREVIEW: 'preview',
  INSTANCE: 'instance',
}

export const Component = props => {
  const { currentSite } = props

  if (!currentSite) {
    return <ErrorMessage statusCode={404} />
  }

  function resolveViewMode() {
    const mode = currentSite.isSetup ? ViewModes.INSTANCE : ViewModes.PREVIEW
    return mode
  }

  function renderView() {
    const viewMode = resolveViewMode()

    switch (viewMode) {
      case ViewModes.PREVIEW:
        return <Preview {...props} />
      case ViewModes.INSTANCE:
        return <InstanceView {...props} />
    }
  }

  return <Container>{renderView()}</Container>
}

Component.propTypes = {
  activeTabProgramsAvailable: PropTypes.array,
  currentSite: SiteType,
  displayTitle: PropTypes.string,
  headerDataTestId: PropTypes.string,
  sortedTeachLinks: PropTypes.array,
  staticLinksAvailable: PropTypes.array,
}

Component.displayName = 'HighSchoolProgramCard'

export default withSiteContext(Component)
