import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { operations as userSessionOperations } from 'store/userSessionManager'
import AdminLessonProgressTabs from './component'
import units from './__mocks__/units'

const AdminLessonProgressTabsContainer = ({
  bhuStatus,
  className,
  lessonSelectedRow,
  outline,
  setLessonSelectedRow,
  // units,
}) => {
  return (
    <AdminLessonProgressTabs
      bhuStatus={bhuStatus}
      className={className}
      lessonSelectedRow={lessonSelectedRow}
      outline={outline}
      setLessonSelectedRow={setLessonSelectedRow}
      units={units.units}
    />
  )
}

AdminLessonProgressTabsContainer.propTypes = {
  bhuStatus: PropTypes.bool,
  className: PropTypes.string,
  lessonSelectedRow: PropTypes.object,
  outline: PropTypes.bool,
  setLessonSelectedRow: PropTypes.func,
  units: PropTypes.array,
}

const mapStateToProps = ({
  userSessionManager: {
    adminDashboard: { lessons },
  },
}) => {
  return {
    lessonSelectedRow: lessons.lessonSelectedRow,
  }
}

const mapDispatchToProps = {
  setLessonSelectedRow: userSessionOperations.adminSetLessonSelectedRow,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminLessonProgressTabsContainer)
