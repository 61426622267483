import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Route, Switch } from 'react-router'
import { operations as reportsOperations } from 'store/reportsManager'
import { NotFound } from 'secondstep-components'
import K8Report from 'pages/K8Report'
import SelaReport from 'pages/SelaReport'
import LearnErrorBoundary from 'components/LearnErrorBoundary'

export const ReportRouter = ({ deleteReportsData }) => {
  useEffect(() => {
    const cleanup = async () => {
      await deleteReportsData?.()
    }
    return cleanup
  }, [])

  return (
    <LearnErrorBoundary>
      <Switch>
        <Route component={SelaReport} path={`/reports/sel-for-adults`} />
        <Route component={K8Report} path={`/reports/k8`} />
        <Route component={NotFound} />
      </Switch>
    </LearnErrorBoundary>
  )
}

const mapDispatchToProps = {
  deleteReportsData: reportsOperations.deleteReportsData,
}

ReportRouter.propTypes = {
  deleteReportsData: PropTypes.func,
}

export default connect(null, mapDispatchToProps)(ReportRouter)
