import React from 'react'
import PropTypes from 'prop-types'
import { toUrlSafe } from 'utils/stringHelpers'
import ClassCard from 'components/dashboard/ClassCard'
import StaticLink from 'components/dashboard/ActiveTabProgramCards/StaticLink'
import { TilesContainer } from 'components/dashboard/ActiveTabProgramCards/component.styles'
import { LinksGroup, StyledHeaderThree } from './component.styles'

const K8DigitalProgramCard = props => {
  const {
    classes,
    classMenuButtonRefs,
    contentfulCourses,
    currentProgramName,
    displayTitle,
    headerDataTestId,
    index,
    internalTitle,
    onClassBlur,
    onClassFocus,
    onComponentClick,
    programSubheader,
    setFocus,
    staticLinksAvailable,
  } = props
  return (
    <>
      {classes.length > 0 && [
        <StyledHeaderThree
          dataTestId={headerDataTestId}
          key={`subheader-${displayTitle}-${index}`}
        >
          {programSubheader}
        </StyledHeaderThree>,
        <TilesContainer key={internalTitle}>
          {classes.map(
            (
              {
                completeLessons,
                contentfulID,
                instance,
                scoID,
                title,
                totalLessons,
              },
              index,
            ) => {
              const { displayTitle } =
                contentfulCourses?.find(course => contentfulID === course.id) ||
                ''
              return (
                <ClassCard
                  dataTestId={`class-card-${toUrlSafe(title)}`}
                  displayTitle={title}
                  gradeDisplayTitle={displayTitle}
                  instance={instance}
                  key={`class-card-${title}-${index}`}
                  onBlur={onClassBlur}
                  onClick={onComponentClick}
                  onFocus={() => onClassFocus(index)}
                  program={currentProgramName}
                  ref={classMenuButtonRefs.current[index]}
                  scoID={scoID}
                  setFocus={() => setFocus(classMenuButtonRefs.current[index])}
                  totalAmount={totalLessons}
                  totalComplete={completeLessons}
                />
              )
            },
          )}
        </TilesContainer>,
        <LinksGroup key="static-links">
          {staticLinksAvailable.map(staticLink => (
            <StaticLink
              key={staticLink.path}
              onComponentClick={onComponentClick}
              staticLink={staticLink}
            />
          ))}
        </LinksGroup>,
      ]}
    </>
  )
}

K8DigitalProgramCard.propTypes = {
  classMenuButtonRefs: PropTypes.object,
  classes: PropTypes.array,
  contentfulCourses: PropTypes.array,
  currentProgramName: PropTypes.string,
  displayTitle: PropTypes.string,
  headerDataTestId: PropTypes.string,
  index: PropTypes.number,
  internalTitle: PropTypes.string,
  isK5Product: PropTypes.bool,
  isMsProduct: PropTypes.bool,
  onClassBlur: PropTypes.func,
  onClassFocus: PropTypes.func,
  onComponentClick: PropTypes.func,
  programSubheader: PropTypes.string,
  setFocus: PropTypes.func,
  staticLinksAvailable: PropTypes.array,
}

export default K8DigitalProgramCard
