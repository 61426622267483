import React, {
  createElement,
  Fragment,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react'
import PropTypes from 'prop-types'
import { render } from 'react-dom'
import { debounce } from 'lodash'

import { usePagination, useSearchBox } from 'react-instantsearch-hooks'
import { autocomplete } from '@algolia/autocomplete-js'

import { createLocalStorageRecentSearchesPlugin } from '@algolia/autocomplete-plugin-recent-searches'
import {
  AI_HS_SEARCH_MATCHED,
  sendApplicationInsightsEvent,
} from 'utils/sendAnalyticsDataHelpers'

import {
  HS_SEARCH_HISTORY_LIMIT,
  ALGOLIA_KEY_PREFIX,
  DELAY_TO_SAVE_SEARCH_MS,
  HS_SEARCH_HISTORY_KEY,
} from './constants'

import { Container } from './component.styles'

const AutocompleteSearch = ({ userEmail, ...props }) => {
  const userKey = `${HS_SEARCH_HISTORY_KEY}:${userEmail}`
  const localStorageKey = `${ALGOLIA_KEY_PREFIX}:${userKey}`

  const addSearchToLocalStorage = query => {
    if (!query) return

    let searchHistory = localStorage.getItem(localStorageKey)

    if (!searchHistory) {
      localStorage.setItem(localStorageKey, '[]')
      searchHistory = []
    } else {
      searchHistory = JSON.parse(searchHistory)
    }
    searchHistory = searchHistory
      .slice(0, HS_SEARCH_HISTORY_LIMIT - 1)
      .filter(c => c.id !== query)
    searchHistory.unshift({ id: query, label: query })
    localStorage.setItem(localStorageKey, JSON.stringify(searchHistory))
  }

  const plugins = useMemo(() => {
    const recentSearches = createLocalStorageRecentSearchesPlugin({
      key: userKey,
      limit: HS_SEARCH_HISTORY_LIMIT,
      transformSource({ source }) {
        return {
          ...source,
          onSelect({ item }) {
            setInstantSearchUiState({ query: item.label })
          },
        }
      },
    })

    return [recentSearches]
  }, [])

  const autocompleteContainer = useRef(null)

  const { query, refine: setQuery } = useSearchBox()
  const { refine: setPage } = usePagination()

  const [instantSearchUiState, setInstantSearchUiState] = useState({ query })

  const delayeAddSearchToLocalStorage = useRef(
    debounce(param => {
      sendApplicationInsightsEvent(AI_HS_SEARCH_MATCHED, {
        terms: param,
      })
      return addSearchToLocalStorage(param)
    }, DELAY_TO_SAVE_SEARCH_MS),
  ).current

  useEffect(() => {
    setQuery(instantSearchUiState.query)
    setPage(0)
  }, [instantSearchUiState])

  useEffect(() => {
    if (!autocompleteContainer.current) {
      return
    }
    const autocompleteInstance = autocomplete({
      ...props,
      container: autocompleteContainer.current,
      initialState: { query },
      plugins,
      onReset() {
        setInstantSearchUiState({ query: '' })
      },
      onSubmit({ state }) {
        setInstantSearchUiState({ query: state.query })
      },
      onStateChange({ prevState, state }) {
        if (prevState.query !== state.query) {
          setInstantSearchUiState({
            query: state.query,
          })
          if (state.query.length > 2) {
            delayeAddSearchToLocalStorage(state.query)
          }
        }
        if (
          Array.isArray(state.collections) &&
          state.collections.length === 0
        ) {
          togglePanel(false)
        } else {
          togglePanel(true)
        }
      },
      renderer: { createElement, Fragment, render },
    })

    return () => autocompleteInstance.destroy()
  }, [plugins])

  const togglePanel = open => {
    const panels = document.getElementsByClassName('aa-Panel')
    for (let i = 0; i < panels.length; i++) {
      panels[i].style.display = open ? 'unset' : 'none'
    }
  }

  return (
    <Container>
      <div ref={autocompleteContainer} />
    </Container>
  )
}

AutocompleteSearch.propTypes = {
  userEmail: PropTypes.string,
}

export default AutocompleteSearch
