import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, HeaderOne, HeaderThree } from 'secondstep-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${themeGet('colors.white')};
  padding: 1.25rem 1.75rem 1.25rem 0rem;
  flex-wrap: wrap;
`

export const Title = styled(HeaderOne)`
  color: ${themeGet('colors.darkBlue')};
  margin-bottom: 0.75rem;
`

export const Description = styled(BodyCopy)`
  margin-bottom: 0.75rem;
  font-size: 1.125rem;
  line-height: 1.5rem;
  color: ${themeGet('colors.darkGray')};
`

export const ObjectivesTitle = styled(HeaderThree)`
  color: ${themeGet('colors.darkerDarkBlue')};
`

export const ObjectivesText = styled(BodyCopy)`
  margin-top: -0.125rem;
  color: ${themeGet('colors.darkGray')};
`
