import RouteServiceClient from '@cfc/route-service-sdk'
import { formatError } from 'utils/formatError'
import { ENVIRONMENT_SUBDOMAIN, LD_CREDS } from 'config/env'
import { actions } from './reducer'

const cancelFetchRoute = ({ app, route }) => {
  return async dispatch => {
    dispatch(actions.fetchRouteCancel({ app, route }))
  }
}

const fetchRoute = ({ app, route, userContext }) => {
  return async (dispatch, getState) => {
    const userProfileManager = getState()?.userProfileManager
    const email = userProfileManager?.profile?.email
    dispatch(
      actions.fetchingRoute({
        app,
        route,
      }),
    )
    try {
      const client = new RouteServiceClient({
        environment: ENVIRONMENT_SUBDOMAIN,
        launchDarklyApiKey: LD_CREDS?.clientSideID,
        launchDarklyContext: {
          kind: 'user',
          key: email,
        },
      })
      const data = await client.getRoute({
        app,
        route,
        userContext,
      })
      dispatch(
        actions.fetchRouteSuccess({
          data,
          app,
          route,
        }),
      )
    } catch (error) {
      dispatch(
        actions.fetchRouteError({
          app,
          route,
          error: formatError(error),
        }),
      )
    }
  }
}

// route is not needed at this time, but pass it anyway for potential future proofing
const fetchSubnav = ({ app, route, userContext }) => {
  return async (dispatch, getState) => {
    const userProfileManager = getState()?.userProfileManager
    const email = userProfileManager?.profile?.email
    dispatch(actions.fetchingSubnav())
    try {
      const client = new RouteServiceClient({
        environment: ENVIRONMENT_SUBDOMAIN,
        launchDarklyApiKey: LD_CREDS?.clientSideID,
        launchDarklyContext: {
          kind: 'user',
          key: email,
        },
      })
      const data = await client.getSubNav({
        app,
        route,
        userContext,
      })
      dispatch(
        actions.fetchSubnavSuccess({
          app,
          data,
        }),
      )
    } catch (error) {
      dispatch(
        actions.fetchSubnavError({
          error: formatError(error),
        }),
      )
    }
  }
}

const clearErrors = () => {
  return async dispatch => {
    dispatch(actions.clearErrors())
  }
}

export default {
  cancelFetchRoute,
  fetchRoute,
  fetchSubnav,
  clearErrors,
}
