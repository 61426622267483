import variables from './variables'
import blobImgMiddleSchool from 'experienceComponents/LandingBanner/assets/landingBannerBlobs.svg'
import subBlobImgMiddleSchool from 'experienceComponents/SubLandingBanner/assets/subLandingBannerBlobs.svg'
import contentBlobImgMiddleSchool from 'experienceComponents/ContentTypeDescription/assets/backgroundBlobs.svg'

export default {
  meta: { themeName: 'mskit' },

  globalNav: {
    background: variables.colors.mskit4,
    iconHoverBackground: variables.colors.opacity_20,
  },
  colors: {
    themeAccent: variables.colors.mskit5,
    themeHighlight: variables.colors.mskit2,
    themePrimary: variables.colors.mskit4,
    themeSecondary: variables.colors.mskit1,
    backgrounds: {
      primary: variables.colors.blue,
    },
    buttons: {
      primaryBackground: variables.colors.mskit4,
      primaryBackgroundHover: variables.colors.mskit2,
      primaryBackgroundDisabled: variables.colors.buttonDisabledKit,
      tabActive: variables.colors.mskit2,
      tabDefault: variables.colors.darkGray,
    },
    links: {
      primary: variables.colors.mskit4,
      primaryHover: variables.colors.mskit2,
    },
    headers: {
      primary: variables.colors.darkerDarkBlue,
    },
    heading: {
      level: {
        1: variables.colors.darkerDarkBlue,
        2: variables.colors.darkerDarkBlue,
        3: variables.colors.darkerDarkBlue,
      },
    },
  },
  // TODO: LEARN-9633, move backround images and colors to separate objects
  banners: {
    headerTextColor: variables.colors.white,
    headerTextFont: 'Sharpslab-extrabold',
    headerTextSize: '42px',
    bannerTextColor: variables.colors.white,
    landingBackgroundColor: variables.colors.secondStepBlue,
    landingBackgroundImage: blobImgMiddleSchool,
    sublandingBackgroundImage: subBlobImgMiddleSchool,
    alignment: 'center',
    textAlignment: 'center',
    contentTypeDescriptionBackgroundColor: variables.colors.darkBlue,
    contentTypeDescriptionBackgroundImage: contentBlobImgMiddleSchool,
    contentTypeDescriptionIconColor: variables.colors.white,
  },
  callouts: {
    headerTextColor: variables.colors.secondStepBlue,
    headerTextFont: 'Sharpslab-extrabold',
    headerTextSize: '28px',
    bodyTextColor: variables.colors.black,
    backgroundColor: variables.colors.white,
    iconBorderRadius: '0.688rem 0.688rem 2.813rem 0.688rem',
    boxShadow: `0rem 0rem 0.625rem ${variables.colors.gray300}`,
  },
  anchorNavigation: {
    borderBottomStyle: 'none',
    borderBottomColor: 'none',
    borderBottomWidth: 'none',
    boxShadow: `0px 0px 10px ${variables.colors.gray300}`,
  },
  resources: {
    backgroundColorTop: variables.colors.secondStepBlue,
    backgroundColorBottom: variables.colors.backgroundNeutralGray,
  },
}
