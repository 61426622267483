import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { LOADING_TEXT } from './constants'
import pages from 'containers/Pages/pages'

const FamilyResources = props => {
  const {
    entry,
    isContentChangingEntries,
    isFetching,
    setAppIsLoadingState,
    setAppNotLoadingState,
    isLoadingApp,
  } = props
  const contentType = entry?.contentType?.id
  const Page = pages[contentType]
  const readyToLoad = isFetching || isContentChangingEntries || !entry

  const shouldUpdateLoadingState = () => {
    if (readyToLoad && !isLoadingApp) {
      setAppIsLoadingState(LOADING_TEXT)
    }
    if (!readyToLoad && isLoadingApp) {
      setAppNotLoadingState()
    }
  }

  useEffect(() => {
    shouldUpdateLoadingState()
    return () => {
      if (isLoadingApp) {
        setAppNotLoadingState()
      }
    }
  }, [readyToLoad])

  return Page ? <Page {...props} /> : null
}

FamilyResources.propTypes = {
  entry: PropTypes.object,
  isContentChangingEntries: PropTypes.bool,
  isFetching: PropTypes.bool,
  isLoadingApp: PropTypes.bool,
  setAppIsLoadingState: PropTypes.func,
  setAppNotLoadingState: PropTypes.func,
}

export default FamilyResources
