import React from 'react'
import PropTypes from 'prop-types'
import {
  ModalContentWrapper,
  CloseButton,
  ModalSubHeader,
  ContainedButton,
  WarningIcon,
} from 'secondstep-components'
import { Header } from '../component.styles'
import { ButtonsWrapperError, ModalWrapperError } from './component.styles'

import {
  ERROR_HEADER,
  ERROR_SUBHEADER,
  errorSubheaderStatus,
  ERROR_RETRY,
} from './constants'

const propTypes = {
  dataTestId: PropTypes.string,
  errorFor: PropTypes.string,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
}

const Error = ({ dataTestId, errorFor, handleCancel, handleSubmit }) => {
  const modalSize =
    errorFor === 'Create' || errorFor === 'Delete' || errorFor === 'Edit'
      ? 'medium'
      : 'small'
  return (
    <ModalWrapperError dataTestId={dataTestId} size={modalSize}>
      <CloseButton dataTestId="button-close" onClick={handleCancel} />
      <ModalContentWrapper>
        <WarningIcon dataTestId="icon-warning" />
        <Header dataTestId="modal-error-header">{ERROR_HEADER}</Header>
        <ModalSubHeader data-testid="modal-error-subheader">
          {errorFor ? errorSubheaderStatus(errorFor) : ERROR_SUBHEADER}
        </ModalSubHeader>
      </ModalContentWrapper>
      <ButtonsWrapperError>
        <ContainedButton dataTestId={'button-try-again'} onClick={handleSubmit}>
          {ERROR_RETRY}
        </ContainedButton>
      </ButtonsWrapperError>
    </ModalWrapperError>
  )
}

Error.propTypes = propTypes

export default Error
