import React, { Component } from 'react'
import PropTypes from 'prop-types'
import TextLinkItem from '../../components/TextLinkItem'
import { ProgramThemeIcons } from 'secondstep-components'

import {
  Author,
  BookCover,
  Container,
  Description,
  Left,
  Right,
  TagsContainer,
  Tag,
  Title,
} from './component.styles'
import { ILLUSTRATOR_PREFIX, AUTHOR_PREFIX } from './constants'

class BookDescription extends Component {
  static propTypes = {
    author: PropTypes.string,
    dataTestId: PropTypes.string,
    description: PropTypes.string,
    illustrator: PropTypes.string,
    image: PropTypes.string,
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        icon: PropTypes.string,
        link: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
    title: PropTypes.string,
  }

  navigateToPage = link => {
    const { navigationEvent } = this.props || {}
    link && navigationEvent && navigationEvent(link)
  }

  tags = () => {
    const { tags } = this.props || []
    if (!tags || tags.length <= 0) {
      return null
    }
    return tags.map(tag => {
      const { icon, link, title, id } = tag
      const Icon = ProgramThemeIcons[icon]

      return (
        <Tag dataTestId={id} key={id} onClick={this.navigateToPage(link)}>
          <TextLinkItem Icon={Icon} href={link} text={title} />
        </Tag>
      )
    })
  }
  render() {
    const {
      author,
      illustrator,
      dataTestId,
      description,
      image,
      title,
    } = this.props

    return (
      <Container dataTestId={dataTestId}>
        {image && (
          <Left>
            <BookCover source={image} />
          </Left>
        )}
        <Right>
          <Title>{title}</Title>
          {author && <Author>{`${AUTHOR_PREFIX}${author}`}</Author>}
          {illustrator && (
            <Author>{`${ILLUSTRATOR_PREFIX}${illustrator}`}</Author>
          )}
          <Description>{description}</Description>
          <TagsContainer>{this.tags()}</TagsContainer>
        </Right>
      </Container>
    )
  }
}

export default BookDescription
