import React from 'react'
import PropTypes from 'prop-types'
import {
  ContainerSection,
  StyledHeaderTwo,
  ViewWrapper,
  MainViewColumn,
} from '../LessonDetail/component.styles'
import ContainedIconButton from '../ContainedIconButton'

import {
  LESSON_TEACH_TITLE,
  BUTTON_SUPERTITLE,
  SLM_TITLE,
  SCRIPT_TITLE,
} from './constants'

const LessonPrep = ({ lessonScript, streamingLessonMedia }) => {
  return (
    <>
      <StyledHeaderTwo dataTestId="lesson-teach-title">
        {LESSON_TEACH_TITLE}
      </StyledHeaderTwo>
      <ContainerSection dataTestId="lesson-teach">
        <ViewWrapper>
          <MainViewColumn>
            {streamingLessonMedia && (
              <ContainedIconButton
                icon="media"
                key="media"
                supertitle={BUTTON_SUPERTITLE}
                title={SLM_TITLE}
                url={streamingLessonMedia}
              />
            )}
            {lessonScript && (
              <ContainedIconButton
                icon="script"
                key="script"
                supertitle={BUTTON_SUPERTITLE}
                title={SCRIPT_TITLE}
                url={lessonScript}
              />
            )}
          </MainViewColumn>
        </ViewWrapper>
      </ContainerSection>
    </>
  )
}

LessonPrep.propTypes = {
  lessonScript: PropTypes.string,
  streamingLessonMedia: PropTypes.string,
}

export default LessonPrep
