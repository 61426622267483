import React, { Children, cloneElement } from 'react'
import PropTypes from 'prop-types'
import { Container } from './component.styles'
import {
  ARROW_DOWN,
  ARROW_UP,
  ARROW_LEFT,
  ARROW_RIGHT,
  DESCRIPTION_LABEL_ID,
} from './constants'
/* eslint-disable jsx-a11y/interactive-supports-focus */
/**
 * Disabling eslint here because the focusable children
 * for the radiogroup div are present in the child component
 * ToggleOption
 */

const RadioToggle = ({
  className,
  children,
  description,
  options = [],
  onSelect,
  value = '',
}) => {
  const handleKeyDown = event => {
    const { key } = event

    let selectOptionId = null

    switch (key) {
      case ARROW_LEFT:
      case ARROW_UP:
        selectOptionId = getPrevOptionId(value)
        break
      case ARROW_RIGHT:
      case ARROW_DOWN:
        selectOptionId = getNextOptionId(value)
        break
      default:
      // do nothing
    }

    if (selectOptionId) {
      handleSelect(selectOptionId)
      event.preventDefault()
    }
  }

  const getNextOptionId = optionId => {
    const currentOptionIndex = options.findIndex(
      option => option.id === optionId,
    )
    const nextOptionIndex =
      currentOptionIndex === options.length - 1 ? 0 : currentOptionIndex + 1
    const nextOption = options[nextOptionIndex]
    const { id } = nextOption
    return id
  }

  const getPrevOptionId = optionId => {
    const currentOptionIndex = options.findIndex(
      option => option.id === optionId,
    )
    const prevOptionIndex =
      currentOptionIndex === 0 ? options.length - 1 : currentOptionIndex - 1
    const prevOption = options[prevOptionIndex]
    const { id } = prevOption
    return id
  }

  const handleSelect = optionId => {
    onSelect(optionId)
  }

  return (
    <Container>
      <span id={DESCRIPTION_LABEL_ID}>{description}</span>
      <div
        aria-labelledby={DESCRIPTION_LABEL_ID}
        className={className}
        onKeyDown={handleKeyDown}
        role="radiogroup"
      >
        {Children.map(children, child => {
          return cloneElement(child, {
            onSelect: handleSelect,
            selectedOptionId: value,
          })
        })}
      </div>
    </Container>
  )
}

RadioToggle.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  description: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.string.isRequired,
}

export default RadioToggle
