import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { operations } from 'store/courseManager'
import { useUserContext } from 'layers/content/v2/hooks/useUserContext'
import { ERROR_TYPES } from 'layers/content/constants'
import { middleSchool } from 'utils/productMaps'
import { HIGH_SCHOOL } from './constants'

export const CourseNames = {
  Grade6: '6',
  Grade7: '7',
  Grade8: '8',
  // course name is all lower-case: 'highschool'
  GradeHighSchool: HIGH_SCHOOL.toLowerCase(),
}

export const programs = {
  middleSchool: middleSchool.productName,
  highSchool: HIGH_SCHOOL,
}

function findCourse(courses, courseName) {
  return courses.find(course => course.name === courseName)
}

function resolveContext(programKey) {
  return (userContext, courseName) => {
    const clone = structuredClone(userContext)
    const sites = clone.sites
      .filter(site => {
        return !!site.programs[programKey]
      })
      .map(site => {
        site.programs[programKey].courses = [
          findCourse(site.programs[programKey].courses, courseName),
        ]

        // Ask for latest version of the course
        site.programs[programKey].courses = site.programs[
          programKey
        ].courses.map(course => {
          return {
            ...course,
            // in order to get latest version from Course Manager service, we need to set version to null
            version: null,
          }
        })

        return site
      })

    const preparedContext = {
      ...clone,
      sites,
    }

    return preparedContext
  }
}

const ProgramToCoursesMap = {
  [middleSchool.productName]: {
    courses: [CourseNames.Grade6, CourseNames.Grade7, CourseNames.Grade8],
    contextResolver: resolveContext('mspDigital'),
  },
  [HIGH_SCHOOL]: {
    courses: [CourseNames.GradeHighSchool],
    contextResolver: resolveContext('highSchool'),
  },
}

export function useCourseManager(options) {
  const { program, overrideHsPreferences } = options

  const dispatch = useDispatch()
  const userContext = useUserContext()
  const { loading, storedCourses, error } = useSelector(state => {
    const storedCourses = state.courseManager?.courses ?? {}
    const error = state.courseManager?.error
    const loading = state.courseManager?.isFetching

    return {
      storedCourses,
      error: error
        ? { message: error, errorType: ERROR_TYPES.courseManagerError }
        : null,
      loading,
    }
  })

  const { courses = [], contextResolver } = ProgramToCoursesMap[program]

  /* https://secondstep.atlassian.net/browse/LEARN-18665
    Temporary workaround to allow "mark complete" learn experiences
    outside the users course tree 
  */
  const overriddenUserContext = structuredClone(userContext)
  if (overrideHsPreferences) {
    const activeSiteIndex = userContext?.sites?.findIndex(
      s => s.siteId === userContext?.hsActiveSiteId,
    )

    if (activeSiteIndex > -1) {
      overriddenUserContext.sites[
        activeSiteIndex
      ].programs.highSchool.preferences = {
        ...overriddenUserContext.sites[activeSiteIndex].programs.highSchool
          .preferences,
        ...overrideHsPreferences,
      }
    }
  }
  // =============================================
  useEffect(() => {
    for (const course of courses) {
      dispatch(
        operations.fetchCourseTree({
          userContext: contextResolver(overriddenUserContext, course),
          courseName: course,
        }),
      )
    }
  }, [program, ...Object.values(overrideHsPreferences)])

  let courseTrees = {}
  for (const course of courses) {
    if (storedCourses[course]) {
      courseTrees[course] = Object.freeze(storedCourses[course])
    }
  }

  return {
    courseTrees,
    loading,
    error,
  }
}
