import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  BodyCopy,
  ContainedButton,
  Hairline,
  HeaderOne,
  HeaderThree,
  HeaderTwo,
  TextLink,
} from 'secondstep-components'

export const ColumnWrapper = styled.div.attrs(({ breakpointTablet }) => ({
  marginLeftEvenTablet: breakpointTablet ? '0' : '1.25rem',
  marginTablet: breakpointTablet ? '0.5rem 0 0' : '0',
  maxWidthTablet: breakpointTablet ? '100%' : 'calc(50% - 0.625rem)',
  flexTablet: breakpointTablet ? '1' : '1 0 calc(50% - 0.625rem)',
}))`
  background-color: ${props =>
    props.isComplete ? themeGet('colors.paleGray') : 'initial'};

  display: flex;
  flex-direction: column;
  flex: 1;

  &:not(:first-of-type) {
    margin-left: 1.25rem;
  }

  ${themeGet('breakpoints.tablet')} {
    flex: ${({ flexTablet }) => flexTablet};
    max-width: ${({ maxWidthTablet }) => maxWidthTablet};
    margin: ${({ marginTablet }) => marginTablet};

    &:first-of-type {
      margin: 0;
    }

    &:not(:first-of-type) {
      margin-left: 0;
    }

    &:nth-child(even) {
      margin-left: ${({ marginLeftEvenTablet }) => marginLeftEvenTablet};
    }

    &:nth-child(4n + 3),
    &:nth-child(4n + 4) {
      margin-top: 0.5rem;
    }
  }

  ${themeGet('breakpoints.mobileLarge')} {
    flex: 1;
    max-width: 100%;
    margin: 0.5rem 0 0;

    &:nth-child(even),
    &:nth-child(4n + 3),
    &:nth-child(4n + 4) {
      margin-left: 0;
    }
  }
`

export const ColumnsWrapper = styled.div.attrs(({ breakpointTablet }) => ({
  flexDirection: breakpointTablet ? 'column' : 'row',
  flexWrap: breakpointTablet ? 'nowrap' : 'wrap',
}))`
  display: flex;

  ${themeGet('breakpoints.tablet')} {
    flex-direction: ${({ flexDirection }) => flexDirection};
    flex-wrap: ${({ flexWrap }) => flexWrap};
  }

  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;
    flex-wrap: nowrap;
  }
`

export const ContentPanelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1.5625rem 1.25rem 0.5rem;
  box-sizing: border-box;
  border-radius: 0.625rem;
  background: ${themeGet('colors.white')};
`

export const HeaderBodyCopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const LinksWrapper = styled.div`
  display: flex;
`

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 200px;

  svg {
    color: ${themeGet('colors.darkBlue')};
  }

  p {
    margin-top: 0.25rem;
    margin-left: 0.5rem;
    font-size: 1.125rem;
    font-family: ${themeGet('font.molde.medium')};
    color: ${themeGet('colors.darkGray')};
  }
`

export const StyledBodyCopy = styled(BodyCopy)`
  margin-bottom: 0.875rem;
  color: ${themeGet('colors.darkGray')};
`

export const StyledContainedButton = styled(ContainedButton)`
  align-self: flex-start;
  width: 186px;
  padding: 0.6875rem 0.8125rem 0.5rem;
  margin-top: auto;
  margin-bottom: 1.25rem;
  text-align: center;
  text-transform: none;
  font-weight: normal;

  &:hover {
    box-shadow: none;
  }
`

export const StyledHairline = styled(Hairline)`
  height: 2px;
  margin-top: 1.4375rem;
  margin-bottom: 1.25rem;
  background-color: ${themeGet('colors.paleGray')};

  ${themeGet('breakpoints.mobile')} {
    margin-top: 0.9375rem;
  }
`

export const StyledHeaderThree = styled(HeaderThree)`
  font-size: 1rem;
  font-size: 1.0625rem;
  font-family: ${themeGet('font.sharpSlab.semibold')};

  ${themeGet('breakpoints.tablet')} {
    font-size: 1rem;
    line-height: 1.0625rem;
    margin-bottom: 0.75rem;
  }
`

export const StyledHeaderTwo = styled(HeaderTwo)`
  color: themeGet('colors.darkBlue');

  font-size: 1.4375rem;
  line-height: 1.5625rem;
  margin-bottom: 0;

  ${themeGet('breakpoints.mobile')} {
    font-size: 1.4375rem;
    line-height: 1.5625rem;
  }
`

export const StyledHeaderOne = styled(HeaderOne)``

export const StyledImage = styled.img`
  max-width: 100%;
  border-radius: 10px;
  margin-bottom: 0.75rem;

  ${themeGet('breakpoints.tablet')} {
    margin-bottom: 1.1875rem;
  }
`

export const StyledTextLink = styled(TextLink)``
