import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { BodyCopy, ContainedButton, HeaderFour } from 'secondstep-components'
import {
  CertificatePanelWrapper,
  ContentWrapper,
  GrayBackground,
  IconCertificateStyled,
  TextWrapper,
} from './component.styles'

import { CERTIFICATE_PANEL_HEADER } from './constants'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { domains } from 'config/env'

export const CertificatePanel = ({
  completedText,
  dateCompleted,
  inactiveText,
  isComplete,
  navigationEvent,
  seedId,
}) => {
  const formattedDate = isComplete && dayjs(dateCompleted).format('MM/DD/YYYY')
  const completeTextWithDate = `${completedText} ${formattedDate}.`
  const bodyText = isComplete ? completeTextWithDate : inactiveText

  const onClick = () => {
    const type = NavigationHelper.types.NEW_TAB
    navigationEvent(
      `${
        domains.LMS_API_BASE
      }/certificate/${seedId}/${new Date().getTimezoneOffset()}`,
      type,
    )
  }
  return (
    <GrayBackground>
      <CertificatePanelWrapper>
        <ContentWrapper>
          <IconCertificateStyled dataTestId="icon-certificate" />
          <TextWrapper>
            <HeaderFour dataTestId="header-certificate-panel">
              {CERTIFICATE_PANEL_HEADER}
            </HeaderFour>
            <BodyCopy data-testid="bodycopy-certificate-panel">
              {bodyText}
            </BodyCopy>
          </TextWrapper>
        </ContentWrapper>
        <ContainedButton
          dataTestId="button-view-certificate"
          disabled={!isComplete}
          onClick={onClick}
        >
          View
        </ContainedButton>
      </CertificatePanelWrapper>
    </GrayBackground>
  )
}

CertificatePanel.propTypes = {
  completedText: PropTypes.string,
  dateCompleted: PropTypes.string,
  inactiveText: PropTypes.string,
  isComplete: PropTypes.bool.isRequired,
  navigationEvent: PropTypes.func,
  seedId: PropTypes.string,
}

export default CertificatePanel
