import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  OutlinedButton,
  ContainedButton,
  BodyCopy,
} from 'secondstep-components'

export const ButtonsWrapper = styled.div`
  display: flex;
`

export const DismissButton = styled(OutlinedButton)`
  border-color: ${themeGet('colors.gray600')};
  color: ${themeGet('colors.gray1100')};
  width: 130px;
  margin-right: 20px;
`

export const SettingsButton = styled(ContainedButton)`
  width: 130px;
`

export const NotificationWrapper = styled.div`
  align-items: center;
  background: ${themeGet('colors.white')};
  border-radius: 0.8125rem;
  display: flex;
  justify-content: space-between;
  padding: 1.25rem 1.25rem 0.8rem;
  margin-bottom: 1.25rem;
  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 1.25rem;
  }
`

export const DescriptionText = styled(BodyCopy)`
  margin-bottom: 10px;
`

export const StatusText = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};

  span {
    color: ${themeGet('colors.darkBlue')};
  }

  ${themeGet('breakpoints.tablet')} {
    margin-bottom: 10px;
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  h5 {
    margin-bottom: 0.25rem;
    margin-left: 0.25rem;
    font-family: ${themeGet('font.molde.semiBold')};
    font-size: 1.0625rem;
  }
  margin-bottom: 8px;
`

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.8125rem 0.8125rem 0 0;
  margin-right: 20px;
  ${themeGet('breakpoints.tablet')} {
    margin-right: 0;
  }
`
