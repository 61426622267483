import types from './types'

export const initialState = {
  sitePreferences: {
    siteId: null,
    isLoading: false,
    success: null,
    error: null,
  },
  mspOptIn: {
    isLoading: false,
    data: null,
    error: null,
  },
  pacingDates: {
    isLoading: false,
    error: null,
  },
  userProgramPreferences: {
    isLoading: false,
    success: null,
    error: null,
  },
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.SAVING_SITE_PREFERENCES:
    case types.SAVING_SITE_PREFERENCES_SUCCESS:
    case types.SAVING_SITE_PREFERENCES_ERROR: {
      return {
        ...state,
        sitePreferences: {
          siteId: action.siteId ? action.siteId : state.sitePreferences.siteId,
          isLoading: action.type === types.SAVING_SITE_PREFERENCES,
          success:
            action.type === types.SAVING_SITE_PREFERENCES_SUCCESS ? true : null,
          error:
            action.type === types.SAVING_SITE_PREFERENCES_ERROR
              ? action.error
              : null,
        },
      }
    }

    case types.UPDATE_MSP_OPT_IN_PREFERENCES:
    case types.UPDATE_MSP_OPT_IN_PREFERENCES_SUCCESS:
    case types.UPDATE_MSP_OPT_IN_PREFERENCES_ERROR: {
      return {
        ...state,
        mspOptIn: {
          isLoading: action.type === types.UPDATE_MSP_OPT_IN_PREFERENCES,
          data:
            action.type === types.UPDATE_MSP_OPT_IN_PREFERENCES_SUCCESS
              ? action.data
              : null,
          error:
            action.type === types.UPDATE_MSP_OPT_IN_PREFERENCES_ERROR
              ? action.error
              : null,
        },
      }
    }

    case types.UPDATING_USER_PROGRAMPREFERENCES:
    case types.UPDATING_USER_PROGRAMPREFERENCES_SUCCESS:
    case types.UPDATING_USER_PROGRAMPREFERENCES_ERROR: {
      return {
        ...state,
        userProgramPreferences: {
          isLoading: action.type === types.UPDATING_USER_PROGRAMPREFERENCES,
          error:
            action.type === types.UPDATING_USER_PROGRAMPREFERENCES_ERROR
              ? action.error
              : null,
        },
      }
    }

    case types.SAVING_SITE_PACING_DATES:
    case types.SAVING_SITE_PACING_DATES_SUCCESS:
    case types.SAVING_SITE_PACING_DATES_ERROR: {
      return {
        ...state,
        pacingDates: {
          isLoading: action.type === types.SAVING_SITE_PACING_DATES,
          error:
            action.type === types.SAVING_SITE_PACING_DATES_ERROR
              ? action.error
              : null,
        },
      }
    }

    case types.CLEAR_ERROR: {
      return {
        ...state,
        [action.slice]: {
          ...state[[action.slice]],
          error: null,
        },
      }
    }

    default:
      return state
  }
}
