import axios from 'axios'

const api = (function() {
  const _api = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
    timeout: 10000,
  })
  // the OPTIONS and GET requests for blob/SAS urls
  // should not include the Authorization header
  _api.interceptors.request.use(
    function(config) {
      delete config.headers.common['Authorization']
      return config
    },
    null,
    { synchronous: true },
  )

  return {
    getBlobData: async url => {
      if (!url) return
      return _api.get(url, {
        timeout: 0,
      })
    },
  }
})()

export default api
