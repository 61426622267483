import React from 'react'
import PropTypes from 'prop-types'
import CoursePreviewBanner from './component'

export function extractLessonsLink(text) {
  return text.match(/^(.*?)<0>(.*?)<\/0>(.*?)$/)
}

const CoursePreviewBannerContainer = ({ lessonsLink }) => {
  return (
    <CoursePreviewBanner
      extractLessonsLink={extractLessonsLink}
      lessonsLink={lessonsLink}
    />
  )
}

CoursePreviewBannerContainer.propTypes = {
  lessonsLink: PropTypes.string,
}

export default CoursePreviewBannerContainer
