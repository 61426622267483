import React from 'react'
import PropTypes from 'prop-types'
import { TilesGroupContainer } from './component.styles'
import { HeaderTwo } from 'secondstep-components'

function TilesGroup(props) {
  const { groupName, children, show, ...rest } = props
  return (
    <TilesGroupContainer show={show} {...rest}>
      <HeaderTwo>{groupName}</HeaderTwo>
      {children}
    </TilesGroupContainer>
  )
}

TilesGroup.propTypes = {
  children: PropTypes.node.isRequired,
  groupName: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
}

export default TilesGroup
