import styled from 'styled-components'
import { ModalContentWrapper } from 'secondstep-components'
import {
  ButtonsWrapper,
  ModalWrapper,
  Header as HeaderStyled,
  SubHeader as SubHeaderStyled,
} from '../component.styles'
import { themeGet } from 'styled-system'

export const SuccessModalWrapper = styled(ModalWrapper)`
  width: 100%;
  padding: ${themeGet('spacing.red40_2_5rem')}
    ${themeGet('spacing.turquoise20_1_25rem')}
    ${themeGet('spacing.red40_2_5rem')}
    ${themeGet('spacing.turquoise20_1_25rem')};
  min-height: unset;

  ${ModalContentWrapper} {
    padding-left: ${themeGet('spacing.blue16_1rem')};
    padding-right: ${themeGet('spacing.blue16_1rem')};
  }
`
export const Header = styled(HeaderStyled)`
  margin: unset;
  margin-top: ${themeGet('spacing.amber28_1_75rem')};
  padding-bottom: ${themeGet('spacing.turquoise20_1_25rem')};
  font-family: ${themeGet('font.sharpSlab.semibold')};
`

export const SubHeader = styled(SubHeaderStyled)`
  margin: unset;
  margin-bottom: ${themeGet('spacing.amber28_1_75rem')} !important;
  font-family: ${themeGet('font.molde.medium')};
  color: ${themeGet('colors.darkBlue')};
`

export const ButtonsWrapperSuccess = styled(ButtonsWrapper)`
  margin-top: 2.25rem;
  position: unset;
  bottom: unset;
  left: unset;
  transform: unset;
  margin-right: unset;
  margin-top: unset;
`
