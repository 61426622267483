import React from 'react'
import PropTypes from 'prop-types'
import {
  AgencyButton,
  BelongingButton,
  ButtonText,
  ButtonsWrapper,
  ConfidenceButton,
  DateText,
  ExploreProgramsContainer,
  PathwaysContainer,
  PracticesContainer,
  SingleSiteAdminButton,
  SingleSiteButtonText,
  SingleSiteTextWrapper,
  StyledBodyCopy,
  StyledCircleRightArrowIcon,
  StyledTextLink,
  Title,
  TitleHairline,
  WellBeingButton,
} from './component.styles'
import {
  AGENCY_AND_OPPORTUNITY,
  AGENCY_AND_OPPORTUNITY_LINK,
  BELONGING_AND_CONNECTION,
  BELONGING_AND_CONNECTION_LINK,
  CONFIDENCE_AND_CAPABILITY,
  CONFIDENCE_AND_CAPABILITY_LINK,
  EXPLORE_PROGRAMS_TITLE,
  HS_DASHBOARD_LINK,
  IN_PROGRESS,
  PATHWAYS_TITLE,
  PRACTICES_TITLE,
  WELL_BEING_AND_COMMUNITY,
  WELL_BEING_AND_COMMUNITY_LINK,
} from './constants'

const AdminHsExplorePrograms = ({
  backgroundKey,
  className,
  isSingleHsAdminUser,
  name,
  navigationEvent,
  singleSiteButtonDate,
  singleSiteButtonText,
  singleSitePathwayLink,
}) => {
  const navigateToPathways = path => {
    navigationEvent(path)
  }

  return (
    <ExploreProgramsContainer className={className}>
      {isSingleHsAdminUser && <Title>{name}</Title>}
      {!isSingleHsAdminUser && <Title>{EXPLORE_PROGRAMS_TITLE}</Title>}
      <TitleHairline />
      <PathwaysContainer>
        {isSingleHsAdminUser && (
          <>
            <StyledBodyCopy>{IN_PROGRESS}</StyledBodyCopy>
            <SingleSiteAdminButton
              backgroundKey={backgroundKey}
              onClick={() => navigateToPathways(singleSitePathwayLink)}
            >
              <SingleSiteTextWrapper>
                <SingleSiteButtonText>
                  {singleSiteButtonText}
                </SingleSiteButtonText>
                <DateText>{singleSiteButtonDate}</DateText>
              </SingleSiteTextWrapper>
            </SingleSiteAdminButton>
          </>
        )}
        {!isSingleHsAdminUser && (
          <>
            <StyledBodyCopy>{PATHWAYS_TITLE}</StyledBodyCopy>
            <ButtonsWrapper>
              <BelongingButton
                onClick={() =>
                  navigateToPathways(BELONGING_AND_CONNECTION_LINK)
                }
              >
                <ButtonText>{BELONGING_AND_CONNECTION}</ButtonText>
              </BelongingButton>
              <ConfidenceButton
                onClick={() =>
                  navigateToPathways(CONFIDENCE_AND_CAPABILITY_LINK)
                }
              >
                <ButtonText>{CONFIDENCE_AND_CAPABILITY}</ButtonText>
              </ConfidenceButton>
              <AgencyButton
                onClick={() => navigateToPathways(AGENCY_AND_OPPORTUNITY_LINK)}
              >
                <ButtonText>{AGENCY_AND_OPPORTUNITY}</ButtonText>
              </AgencyButton>
              <WellBeingButton
                onClick={() =>
                  navigateToPathways(WELL_BEING_AND_COMMUNITY_LINK)
                }
              >
                <ButtonText>{WELL_BEING_AND_COMMUNITY}</ButtonText>
              </WellBeingButton>
            </ButtonsWrapper>
          </>
        )}
      </PathwaysContainer>
      <PracticesContainer>
        <StyledTextLink
          key="navigate-to-hs-educator-dashboard"
          onClick={() => navigationEvent(HS_DASHBOARD_LINK)}
        >
          {PRACTICES_TITLE}
          <StyledCircleRightArrowIcon />
        </StyledTextLink>
      </PracticesContainer>
    </ExploreProgramsContainer>
  )
}

AdminHsExplorePrograms.propTypes = {
  backgroundKey: PropTypes.string,
  className: PropTypes.string,
  isSingleHsAdminUser: PropTypes.bool,
  name: PropTypes.string,
  navigationEvent: PropTypes.func,
  singleSiteButtonDate: PropTypes.string,
  singleSiteButtonText: PropTypes.string,
  singleSitePathwayLink: PropTypes.string,
}

export default AdminHsExplorePrograms
