import React from 'react'
import PropTypes from 'prop-types'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { IconPDF, TextLink, ProgressStatusMedium } from 'secondstep-components'
import {
  CourseTitle,
  CourseHeaderWrapper,
  StyledHairline,
  StyledLessonsCount,
  StyledScopeAndSequenceContainer,
} from './component.styles'
import { pluralizeWord } from 'utils/stringHelpers'
import { lessonsAmountsType, scopeAndSequenceLinksType } from './types'

export function formatLessonsAmount(amount, categoryLabel) {
  const pluralized = pluralizeWord(amount, 'Lesson', 'Lessons')

  return categoryLabel ? `${categoryLabel} ${pluralized}` : `${pluralized}`
}

const CourseHeader = ({
  dataTestId,
  gradeNumber,
  gradeTitle,
  scopeAndSequenceLinks,
  navigationEvent,
  lessonsAmounts,
  isPreview,
  skipHead,
}) => {
  const onClick = linkHref => {
    const type = NavigationHelper.types.DOWNLOAD_LINK
    navigationEvent(linkHref, type)
  }

  const titleHead = !skipHead ? `G${gradeNumber}: ` : ''

  const howManyLessons = lessonsAmounts.map((lesson, index) => {
    const { totalAmount, totalComplete, categoryLabel } = lesson
    return isPreview ? (
      <span>
        {totalAmount} {formatLessonsAmount(totalAmount, categoryLabel)}
      </span>
    ) : (
      <ProgressStatusMedium
        dataTestId="progress-status"
        key={index}
        status={lessonsAmounts.length === 1 ? 'Complete' : ''}
        totalAmount={totalAmount}
        totalComplete={totalComplete}
        unitName={formatLessonsAmount(totalAmount, categoryLabel)}
      />
    )
  })

  return (
    <CourseHeaderWrapper dataTestId={dataTestId}>
      <CourseTitle dataTestId="course-instance-title">
        {titleHead + gradeTitle}
      </CourseTitle>
      <StyledLessonsCount>{howManyLessons}</StyledLessonsCount>
      <StyledScopeAndSequenceContainer>
        {scopeAndSequenceLinks.map((link, index) => (
          <TextLink
            dataTestId={`text-link-scopandsequence-${index}`}
            key={index}
            onClick={() => onClick(link.linkHref)}
          >
            <IconPDF role="img" />
            {link.linkLabel}
          </TextLink>
        ))}
      </StyledScopeAndSequenceContainer>
      <StyledHairline />
    </CourseHeaderWrapper>
  )
}

CourseHeader.defaultProps = {
  scopeAndSequenceLinks: [],
  lessonsAmounts: [],
}

CourseHeader.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  gradeNumber: PropTypes.string.isRequired,
  gradeTitle: PropTypes.string.isRequired,
  isPreview: PropTypes.bool,
  lessonsAmounts: lessonsAmountsType,
  navigationEvent: PropTypes.func,
  scopeAndSequenceLinks: scopeAndSequenceLinksType,
  skipHead: PropTypes.bool,
}

export default CourseHeader
