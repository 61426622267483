import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy } from 'secondstep-components'
import ClosableModal from 'experienceComponents/ClosableModal'
import { Image } from 'grommet'

export const BodyCopyContainer = styled.div`
  flex-grow: 1;
`

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  margin-bottom: 1.31rem;
  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;
    ${BodyCopy} {
      padding-bottom: 0 !important;
    }
  }
`

export const ImageWrapper = styled.div`
  width: ${props => props.width};
  display: flex;
  align-self: flex-end;
  position: relative;
  cursor: pointer;
  ${themeGet('breakpoints.mobileLarge')} {
    width: 100%;
  }
  svg {
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 1rem;
  }
  img {
    cursor: ${props => (props.expandImage ? 'zoom-in' : 'default')};
  }
`

export const StyledImage = styled(Image).attrs(() => ({
  align: 'center',
  fill: 'vertical',
  fit: 'contain',
}))``

export const StyledClosableModal = styled(ClosableModal).attrs(props => ({
  ...props,
  overlayBgColor: themeGet('colors.modals.overlay')(props),
}))``
