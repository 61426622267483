import React from 'react'
import PropTypes from 'prop-types'
import ResourcesContentAccordionComponent from './component'

const ResourcesContentAccordionContainer = ({
  children,
  title,
  id,
  pageComponentIndex,
}) => {
  return (
    <ResourcesContentAccordionComponent
      id={id}
      pageComponentIndex={pageComponentIndex}
      title={title}
      titleAs="h2"
    >
      {children}
    </ResourcesContentAccordionComponent>
  )
}

ResourcesContentAccordionContainer.propTypes = {
  children: PropTypes.object,
  id: PropTypes.string,
  pageComponentIndex: PropTypes.string,
  title: PropTypes.string,
}

ResourcesContentAccordionContainer.defaultProps = {
  pageComponentIndex: '0',
  title: '',
}

export default ResourcesContentAccordionContainer
