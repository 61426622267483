import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { HeaderSix, Modal, ModalContentWrapper } from 'secondstep-components'

export const StyledFlowLabel = styled(HeaderSix)`
  letter-spacing: 0.2rem;
  color: ${themeGet('colors.gray1200')};
  font-size: 0.8rem;
`

export const StyledModal = styled(Modal)`
  ${ModalContentWrapper} {
    box-sizing: border-box;
    width: 650px;
    min-height: 521px;
    ${themeGet('breakpoints.mobileLarge')} {
      height: auto;
    }
  }
`
