import React from 'react'
import PropTypes from 'prop-types'
import ReactMarkdown from 'react-markdown'
import {
  EmphasisHairline,
  StyledHeaderThree,
  ViewWrapper,
} from '../LessonDetail/component.styles'
import { ContainerSection, MainViewColumn } from './component.styles'
import { View } from 'react-native'

import { OBJECTIVES_TITLE, LESSON_CONCEPTS_TITLE } from './constants'

const LessonSummary = ({
  summaryTitle,
  whyThisLessonMatters,
  objectives,
  description,
}) => {
  return (
    <ContainerSection dataTestId="lesson-summary">
      {whyThisLessonMatters && (
        <React.Fragment key="whyThisLessonMatters">
          {summaryTitle && (
            <StyledHeaderThree>{summaryTitle}</StyledHeaderThree>
          )}
          <ReactMarkdown>{whyThisLessonMatters}</ReactMarkdown>
        </React.Fragment>
      )}

      {whyThisLessonMatters && <EmphasisHairline />}
      <ViewWrapper>
        {objectives && (
          <MainViewColumn>
            <StyledHeaderThree>{OBJECTIVES_TITLE}</StyledHeaderThree>
            <ReactMarkdown>{objectives}</ReactMarkdown>
          </MainViewColumn>
        )}

        <MainViewColumn>
          {description && (
            <View>
              <StyledHeaderThree>{LESSON_CONCEPTS_TITLE}</StyledHeaderThree>
              <ReactMarkdown>{description}</ReactMarkdown>
            </View>
          )}
        </MainViewColumn>
      </ViewWrapper>
    </ContainerSection>
  )
}

LessonSummary.propTypes = {
  description: PropTypes.string,
  objectives: PropTypes.string,
  summaryTitle: PropTypes.string,
  whyThisLessonMatters: PropTypes.string,
}

export default LessonSummary
