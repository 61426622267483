import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { CheckBox } from 'secondstep-components'
import TextLinkWithModal from 'components/TextLinkWithModal'

export const ChecklistItemWrapper = styled.div`
  display: flex;
  width: 100%;
  margin: 0.0625rem 0 0.8125rem;
`

const CHECKED_BACKGROUND_COLOR_MAP = {
  HS: themeGet('colors.hsPrimaryPink'),
  K8: themeGet('colors.elemDigital1'),
}

export const StyledCheckBox = styled(CheckBox).attrs(props => ({
  borderColor: props.isChecked
    ? CHECKED_BACKGROUND_COLOR_MAP[props.selectedProgram](props)
    : themeGet('colors.blueGray')(props),
  backgroundCheckedColor: CHECKED_BACKGROUND_COLOR_MAP[props.selectedProgram],
}))`
  display: flex;
  margin-right: 0.4375rem;
  font-size: 1rem;
  padding-top: 0.1875rem;

  [class*='CheckBoxStyled-sc-'] {
    width: 1.1875rem;
    height: 1.125rem;
    padding-top: 0.125rem;
    border: 2px solid ${({ borderColor }) => borderColor} !important;
    border-radius: 3px;
  }

  [class*='CheckBoxHidden']:checked ~ [class*='CheckBoxStyled'] {
    color: ${themeGet('colors.white')};
    background: ${({ backgroundCheckedColor }) => backgroundCheckedColor};
  }
`

export const StyledLabel = styled.label.attrs(props => ({
  opacity: props.isChecked ? '60%' : '100%',
}))`
  display: flex;
  flex-wrap: wrap;
  padding-top: 0.0625rem;
  opacity: ${({ opacity }) => opacity};

  .links {
    display: flex;
    white-space: nowrap;
  }

  .pipe {
    margin: 0 0.1875rem;
  }

  .text {
    margin-right: 0.25rem;
  }
`

export const StyledTextLinkWithModal = styled(TextLinkWithModal)`
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
  }
`
