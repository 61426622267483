import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, HeaderSix, ProgramThemeLink } from 'secondstep-components'

export const Container = styled.div`
  background-color: ${themeGet('colors.white')};
  flex-direction: column;
  padding: 0.875rem 0rem;

  @media (max-width: 600px) {
    padding: 1.25rem 0;

    img {
      margin-bottom: 1.625rem;
    }
  }
`

export const AssetContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

export const Left = styled.div`
  display: flex;
  padding-right: 1.563rem;
`

export const Image = styled.img`
  border-radius: 0.563rem;
  height: 15.313rem;
  width: 10rem;
  border: 1px solid #dddddd;
`

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const TextLinkContainer = styled(HeaderSix)`
  margin-top: 0rem;
  margin-bottom: 0.75rem;
  max-width: 100%;
`

export const Description = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};
`

export const PDFLink = styled(ProgramThemeLink)`
  cursor: pointer;
  color: ${themeGet('colors.blue')};
`
