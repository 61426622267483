import styled from 'styled-components'
import { Image } from 'grommet'

export const ImageThumbnail = styled(Image).attrs(() => ({
  fill: 'horizontal',
  fit: 'contain',
}))`
  width: 100%;
  height: auto;
  border-radius: 0.75rem;
`

export const ImageModal = styled(Image).attrs(() => ({
  align: 'center',
  fill: 'vertical',
  fit: 'contain',
}))``
