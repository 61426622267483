export const ENTRY_ID = '2fjaO4JP4UKru0YRy1SAvh'
export const QUERY_TYPE = 'dashboardProgram'
// NOTE: ideally these paths would not be hardcoded:
export const HIGH_SCHOOL_TRAINING_PATH = '/product/high-school/training/'
export const HIGH_SCHOOL_LEARNING_EXPERIENCE_PATH =
  '/product/high-school/learning-experiences/'
export const METADATA_RESOURCE_TYPE = 'MetadataResourceType'
export const METADATA_GRADE = 'MetadataGrade'
export const SCHOOLWIDE_RESOURCE_TYPE = 'Schoolwide Practice'
export const EDUCATOR_PRACTICE_RESOURCE_TYPE = 'Educator Practice'
// TODO: see if there's a more dynamic way to generate these:
export const GRADE_TITLE_MAP = {
  G9: 'Grade 9',
  G10: 'Grade 10',
  G11: 'Grade 11',
  G12: 'Grade 12',
}

export const TRAINING = 'TRAINING'
export const PATHWAYONE = 'PATHWAY 1'
export const PATHWAYTWO = 'PATHWAY 2'
export const PATHWAYTHREE = 'PATHWAY 3'
export const PATHWAYFOUR = 'PATHWAY 4'

export const PATHWAY_TITLES = [
  'Pathway 1',
  'Pathway 2',
  'Pathway 3',
  'Pathway 4',
]
