import LessonAB from './component.jsx'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'

// This dataGetter is necessary because the one made in UnitContainer isn't 1 level short
// of getting the pdf data for the LessonAB content items. This gets that data and passes it
// down thru the children props to overwrite the contentfulLesson prop in Lessons
export const mapper = entry => {
  return { contentfulLesson: entry }
}
const options = {
  include: 2,
  mapper,
  spread: true,
  entryIdProp: true,
}

export default ContentEntryDataGetter(LessonAB, options)
