import { ContainedButton, FormInputText } from 'secondstep-components'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  ModalHeaderInput,
  ModalWrapperInput,
  ButtonsWrapper,
  CancelButton,
} from '../component.styles'

export const ModalWrapperEdit = styled(ModalWrapperInput).attrs(() => ({
  size: 'small',
}))`
  padding: 2.125rem 1.75rem 2.5rem 1.75rem;

  ${ModalHeaderInput} {
    color: ${themeGet('colors.themeSecondary')};

    font-family: ${themeGet('font.sharpSlab.extraBold')};
    font-size: 1.625rem;
    margin-bottom: 0.5rem;
    text-align: center;

    ${themeGet('breakpoints.mobile')} {
      margin-bottom: 0.7rem;
    }
  }

  ${CancelButton} {
    max-width: 9.375rem;
    padding: 0.5rem 1.25rem 0.3125rem 1.25rem;
    width: 50%;

    ${themeGet('breakpoints.mobile')} {
      margin-right: 0.75rem !important;
    }
  }

  ${ContainedButton} {
    max-width: 150px;
    padding: 0.5rem 0.0625rem 0.3125rem 0.125rem;
    width: 50%;

    :disabled {
      background: ${themeGet('colors.gray650')};
      border-color: ${themeGet('colors.gray650')};
      opacity: 1;
    }
  }

  ${themeGet('breakpoints.mobile')} {
    padding: 2.3125rem 1.25rem 2.5rem 1.25rem;
  }
`

export const ClassCardModalWrapperEdit = styled(ModalWrapperEdit)`
  padding: 2.25rem 1.75rem 2.5rem 1.75rem;

  ${ModalHeaderInput} {
    color: ${themeGet('colors.secondStepBlue')};

    font-family: ${themeGet('font.sharpSlab.semibold')};
    font-size: 1.625rem;
    margin-bottom: 0.25rem;
    text-align: center;

    ${themeGet('breakpoints.mobile')} {
      margin-bottom: 0.6rem;
    }
  }

  ${themeGet('breakpoints.mobile')} {
    padding: 2.5rem 1.25rem;
  }
`

export const StyledFormInputText = styled(FormInputText)`
  padding-bottom: 1.25rem;

  input {
    border: ${themeGet('colors.formInputBorder')};
    border-radius: 0.3125rem;
    font-family: ${themeGet('fontFamily')};
    font-size: 1rem;
  }

  label {
    font-family: ${themeGet('fontFamilySemibold')};
    font-size: 1rem;
  }

  [class*='StyledParagraph'] {
    color: ${themeGet('colors.deleteRed')};
    position: absolute;
    top: 4.0625rem;
  }
`

export const StyledButtonsWrapper = styled(ButtonsWrapper)`
  position: relative;
  left: 0;
  bottom: 0;

  justify-content: center;
  margin-right: 0;
  margin-top: 1.625rem;
  transform: none;
  width: 100%;

  ${themeGet('breakpoints.mobile')} {
    left: 0;
  }
`
