import React, { Component } from 'react'
import PropTypes from 'prop-types'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_CONTENTFUL_PRODUCT,
  ERROR_TYPE_LMS,
} from 'layers/errorHandling/apiError'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import ModuleUnit from './component'
import { convertMilliSecondsToHoursMinutesCertificate } from 'utils/timeFormattingHelpers'
import moment from 'moment'

export class ModuleUnitContainer extends Component {
  static propTypes = {
    dataTestId: PropTypes.string,
    displayTitle: PropTypes.string,
    hasCertificate: PropTypes.bool,
    isActive: PropTypes.bool,
    label: PropTypes.string,
    lmsLessons: PropTypes.array,
    lmsUnit: PropTypes.object,
    microlearnings: PropTypes.array,
    moduleTitle: PropTypes.string,
    nextMicrolearningId: PropTypes.string,
    totalLessons: PropTypes.number,
  }

  mergeLmsAndContentfulData = (lmsLessons, contentfulMicrolearnings) => {
    const mergedMicrolearnings = contentfulMicrolearnings?.map(
      microlearning => {
        const lesson = lmsLessons?.find(
          lmsLesson => lmsLesson.contentfulID === microlearning.id,
        )

        if (lesson?.choices) {
          this.mergeLmsAndContentfulData(lesson.choices, [
            microlearning.item1Lesson,
            microlearning.item2Lesson,
          ])
        }

        return { ...lesson, ...microlearning, time: microlearning.time }
      },
    )

    return mergedMicrolearnings
  }

  mergeMicrolearnings = () => {
    const { lmsLessons, microlearnings } = this.props
    const mergedMicrolearnings =
      microlearnings &&
      this.mergeLmsAndContentfulData(lmsLessons, microlearnings)

    return mergedMicrolearnings
  }

  render() {
    const {
      dataTestId,
      displayTitle,
      hasCertificate,
      isActive,
      label,
      lmsUnit,
      moduleTitle,
      nextMicrolearningId,
      totalLessons,
    } = this.props
    const mergedMicrolearnings = this.mergeMicrolearnings()

    if (!mergedMicrolearnings || mergedMicrolearnings?.length < 1) return null

    const totalUnitTime = mergedMicrolearnings.reduce((prev, { time }) => {
      if (!time) {
        // handles small group choice option - default at 30 min
        return (prev += 30)
      }
      return (prev += parseInt(time))
    }, 0)
    const unitTime = convertMilliSecondsToHoursMinutesCertificate(
      moment.duration(totalUnitTime, 'minutes').asMilliseconds(),
    )

    return (
      <ModuleUnit
        dataTestId={dataTestId}
        displayTitle={displayTitle}
        hasCertificate={hasCertificate}
        isActive={isActive}
        label={label}
        lmsUnit={lmsUnit}
        microlearnings={mergedMicrolearnings}
        moduleTitle={moduleTitle}
        nextMicrolearningId={nextMicrolearningId}
        totalLessons={totalLessons}
        unitTime={unitTime}
      />
    )
  }
}

export const mapper = (entry = {}) => {
  const { certificate, displayTitle, label, microlearnings } = entry
  return { hasCertificate: certificate, displayTitle, label, microlearnings }
}

const options = {
  include: 2,
  mapper,
  spread: true,
  entryIdProp: true,
}

export default ContentEntryDataGetter(
  withApiError(ModuleUnitContainer, [
    ERROR_TYPE_CONTENTFUL,
    ERROR_TYPE_CONTENTFUL_PRODUCT,
    ERROR_TYPE_LMS,
  ]),
  options,
)
