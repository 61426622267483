import styled from 'styled-components'
import { themeGet } from 'styled-system'
import Tooltip, { TooltipContentWrapper } from '../Tooltip'

export const CurrentMarker = styled.div.attrs(({ markerPosition }) => ({
  left: `${markerPosition - 5}%`,
}))`
  position: absolute;
  left: ${({ left }) => left};
  height: 4px;
  width: 4px;
  margin-top: 1px;
  border: 2px solid ${themeGet('colors.hsPrimaryPink')};
  border-radius: 0.75rem;
  background-color: ${themeGet('colors.linkLight')};
`

export const DateRangeWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-left: 0.6875rem;
  margin-right: 0.625rem;
`

export const EndDateText = styled.span`
  padding-top: 0.1875rem;
  padding-left: 0.5rem;
`

export const StartDateText = styled.span`
  padding-top: 0.1875rem;
  padding-right: 0.5rem;
`

export const NotStartedText = styled.span`
  font-family: ${themeGet('fontFamily')};
  font-size: 1rem;
`

export const NotStartedWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.25rem 0.75rem 0.0625rem;
`

export const OverallBar = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 1px;
  width: 100%;
  border: 1px solid ${themeGet('colors.blueGray')};
  opacity: 0.4;
  margin: 0.9375rem 0 0.875rem;
`

export const ProgressBar = styled.div.attrs(({ progressBarWidth }) => ({
  border: progressBarWidth === 0 ? 'none' : `1px solid`,
  width: `${progressBarWidth}%`,
}))`
  position: absolute;
  margin-top: 1px;
  box-sizing: border-box;
  height: 1px;
  width: ${({ width }) => width};
  border: ${({ border }) => border};
  border-color: ${themeGet('colors.hsPrimaryPink')};
`

export const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 71px;
  position: relative;
`

export const StyledTooltip = styled(Tooltip).attrs(
  ({ tooltipContentPosition }) => ({
    left: `${tooltipContentPosition}%`,
  }),
)`
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;

  ${TooltipContentWrapper} {
    width: max-content;
    top: -43px;
    left: ${({ left }) => left};
    padding: 0.75rem 1rem;
    border: 1px solid ${themeGet('colors.grayBlue')};
    border-radius: 0.375rem;
    color: ${themeGet('colors.gray675')};
    text-align: left;
    text-wrap: wrap;
    line-height: 1rem;

    ::after {
      content: ' ';
      width: 0.75rem;
      height: 0.75rem;
      position: absolute;
      bottom: 45px;
      left: 46%;
      background-color: ${themeGet('colors.white')};
      border-bottom: 1px solid ${themeGet('colors.grayBlue')};
      border-right: 1px solid ${themeGet('colors.grayBlue')};
      transform: translateY(400%) rotate(45deg);
    }
  }
`

export const TooltipContainer = styled.div``

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  background: ${themeGet('colors.highschool.pink7')};
  border-radius: 1rem;
`
