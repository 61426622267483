import variables from './variables'

export default {
  meta: { themeName: 'platform' },

  globalNav: {
    background: variables.colors.white,
    borderColor: variables.colors.lightOrange,
    borderWidth: '2',
    dividerColor: variables.colors.paleGray,
    dividerOpacity: '1',
    fontFamily: variables.font.sharpSlab.semibold,
    iconHoverBackground: variables.colors.paleGray,
    primaryIconColor: variables.colors.orange,
    secondaryIconColor: variables.colors.secondStepBlue,
    titleColor: variables.colors.secondStepBlue,
  },

  skipToMain: {
    background: variables.colors.paleGray,
    color: variables.colors.blueGray,
    hoverColor: variables.colors.darkBlue,
  },

  banners: {
    contentTypeDescriptionBackgroundColor: variables.colors.white,
  },

  callouts: {
    categoryBackground: variables.colors.paleGray,
    categoryBackgroundHover: variables.colors.beaconGray,
    categoryTileBackground: variables.colors.paleGray,
    backgroundColor: variables.colors.white,
    headerTextFont: variables.font.sharpSlab.semibold,
  },

  colors: {
    accordion: {
      headerTextColor: variables?.colors?.darkerDarkBlue,
      icon: variables?.colors?.blueGray,
      iconHoverColor: variables?.colors?.darkBlue,
      panelColor: variables?.colors?.paleGray,
    },
    backgrounds: {
      banners: {
        primary: variables.colors.paleGray,
      },
      body: variables.colors.paleGray,
      footer: variables.colors.beaconGray,
      primary: variables.colors.white,
      secondary: variables.colors.paleGray,
      tertiary: variables.colors.paleGray,
    },
    borders: {
      primary: variables.colors.beaconGray,
      reporting: variables.colors.grayBlue,
    },
    buttons: {
      tabActive: variables.colors.secondStepBlue,
    },
    heading: {
      level: {
        1: variables.colors.secondStepBlue,
        2: variables.colors.secondStepBlue,
        3: variables.colors.secondStepBlue,
        4: variables.colors.secondStepBlue,
        5: variables.colors.secondStepBlue,
        6: variables.colors.secondStepBlue,
      },
    },
    highschool: {
      themePrimary: variables.colors.highschool.pink1,
    },
    hr: {
      primary: variables.colors.beaconGray,
    },
    icon: {
      light: variables.colors.blue,
    },
    links: {
      hover: {
        primary: variables.colors.orange,
      },
      primary: variables.colors.blue,
      secondary: variables.colors.darkGray,
    },
    progressBar: {
      background: variables.colors.grayBlue,
      primary: variables.colors.orange100,
    },
  },

  font: {
    inputs: {
      primary: variables.font.molde.regular,
    },
    labels: {
      primary: variables.font.molde.medium,
    },
    links: {
      primary: variables.font.molde.medium,
      secondary: variables.font.molde.regular,
    },
  },

  global: {
    hover: {
      background: variables.colors.paleGray,
      color: variables.colors.darkGray,
    },
  },

  heading: {
    level: {
      1: {
        font: {
          family: variables.font.sharpSlab.semibold,
          weight: variables.fontWeightNormal,
        },
        medium: {
          height: '3rem',
          size: '2.375rem',
        },
        tablet: {
          height: '3rem',
          size: '2.375rem',
        },
        mobile: {
          height: '2.25rem',
          size: '2rem',
        },
      },
      2: {
        font: {
          family: variables.font.sharpSlab.semibold,
          weight: variables.fontWeightNormal,
        },
        medium: {
          height: '1.875rem',
          size: '1.4375rem',
        },
        tablet: {
          height: '1.875rem',
          size: '1.4375rem',
        },
        mobile: {
          height: '1.625rem',
          size: '1.25rem',
        },
      },
      3: {
        font: {
          family: variables.font.lufgaCFC.medium,
          weight: variables.fontWeightNormal,
        },
        medium: {
          height: '1.75rem',
          size: '1.25rem',
        },
        tablet: {
          height: '1.75rem',
          size: '1.25rem',
        },
        mobile: {
          height: '1.75rem',
          size: '1.25rem',
        },
      },
      4: {
        font: {
          family: variables.font.lufgaCFC.medium,
          weight: variables.fontWeightNormal,
        },
        medium: {
          height: '1.3125rem',
          size: '1rem',
        },
        tablet: {
          height: '1.3125rem',
          size: '1rem',
        },
        mobile: {
          height: '1.3125rem',
          size: '1rem',
        },
      },
      5: {
        font: {
          family: variables.font.lufgaCFC.medium,
          weight: variables.fontWeightNormal,
        },
        medium: {
          height: '1.3125rem',
          size: '1rem',
        },
        tablet: {
          height: '1.3125rem',
          size: '1rem',
        },
        mobile: {
          height: '1.3125rem',
          size: '1rem',
        },
      },
      6: {
        font: {
          family: variables.font.lufgaCFC.medium,
          weight: variables.fontWeightNormal,
        },
        medium: {
          height: '1.3125rem',
          size: '1rem',
        },
        tablet: {
          height: '1.3125rem',
          size: '1rem',
        },
        mobile: {
          height: '1.3125rem',
          size: '1rem',
        },
      },
    },
  },

  resources: {
    backgroundColorBottom: variables.colors.beaconGray,
    callouts: {
      iconBorderRadius: '0.5625rem 0.5625rem 0.5625rem 3.125rem',
    },
  },
}
