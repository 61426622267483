import React from 'react'
import PropTypes from 'prop-types'

import withSiteContext from 'layers/content/v2/Hocs/withSiteContext'

import CurrentSiteTile from './component'

const CurrentSiteTileContainer = props => {
  const { activeSites, currentSite, handleClick } = props || {}
  const { siteName } = currentSite || {}
  const clickHandler = activeSites?.length > 1 ? handleClick : null

  return (
    !!siteName && (
      <CurrentSiteTile currentSite={siteName} handleClick={clickHandler} />
    )
  )
}

CurrentSiteTileContainer.propTypes = {
  currentSite: PropTypes.shape({
    siteId: PropTypes.number.isRequired,
    siteName: PropTypes.string.isRequired,
  }),
  handleClick: PropTypes.func,
}

export default withSiteContext(CurrentSiteTileContainer)
