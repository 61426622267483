import React from 'react'
import PropTypes from 'prop-types'
import { calculateTotalCompletedTime } from 'utils/timeFormattingHelpers'
import { ErrorMessage } from 'secondstep-components'

import WizardFlow from 'components/training/WizardFlow'
import {
  checkForActiveTraining,
  getActiveTrainingData,
  mergeModules,
} from './core'
import {
  DefaultTrainingContainer,
  K5TrainingContainer,
} from './component.styles'

import TrainingHeader from 'components/training/TrainingHeader'
import CertificatePanel from 'components/training/CertificatePanel'
import ModuleCategories from 'components/training/ModuleCategories/component'

const Training = ({
  displayTitle,
  isFetching,
  isLmsFetching,
  lmsTrainings,
  match,
  navigationEvent,
  seedIds,
  showUpdateLoader,
  trainingPaths,
  getProductMap,
}) => {
  const readyToLoad =
    isFetching ||
    isLmsFetching ||
    !trainingPaths ||
    !lmsTrainings ||
    showUpdateLoader

  if (readyToLoad) {
    return null
  }
  const { productName } = getProductMap(match?.params?.productName)
  const hasActiveTraining = checkForActiveTraining(
    productName,
    lmsTrainings,
    seedIds,
  )
  const {
    lmsTraining: trainingInstance,
    firstTrainingSet,
    trainingSets,
  } = getActiveTrainingData(lmsTrainings, trainingPaths)

  const mergedContentAndLms = mergeModules(trainingSets, trainingInstance)
  const {
    activeCertificateDescription,
    inactiveCertificateDescription,
    hasCertificate,
  } = firstTrainingSet || {}

  const someModulesCompleted = mergedContentAndLms.some(trainingPath => {
    return trainingPath?.lmsAndContentfulModules.some(
      ({ attempted }) => attempted === 'completed',
    )
  })

  const { children: trainingChildren, doneAt, is_done, seed_id } =
    trainingInstance || {}
  // TODO: This ultimately should be a completionTime coming from the LMS: https://secondstep.atlassian.net/browse/LEARN-7788
  const totalCompletedTrainingTime = calculateTotalCompletedTime(
    trainingChildren,
  )

  const TrainingContainer =
    productName !== 'k5kit' ? DefaultTrainingContainer : K5TrainingContainer

  return (
    <TrainingContainer>
      {!showUpdateLoader && (
        <>
          {!hasActiveTraining && productName !== 'k5kit' && (
            <WizardFlow displayTitle={displayTitle} productName={productName} />
          )}
          {!hasActiveTraining && productName === 'k5kit' && (
            <ErrorMessage
              additionalDetails="Your account is missing training information. If the issue persists, please contact us."
              statusCode={403}
            />
          )}
          {hasActiveTraining && (
            <div key="has-training">
              <TrainingHeader
                displayTitle={displayTitle}
                someModulesCompleted={someModulesCompleted}
                totalCompletedTrainingTime={totalCompletedTrainingTime}
              />
              {hasCertificate && (
                <CertificatePanel
                  completedText={activeCertificateDescription}
                  dateCompleted={doneAt}
                  inactiveText={inactiveCertificateDescription}
                  isComplete={is_done}
                  navigationEvent={navigationEvent}
                  seedId={seed_id}
                />
              )}
              <ModuleCategories
                categories={mergedContentAndLms}
                dataTestId="training-categories"
              />
            </div>
          )}
        </>
      )}
    </TrainingContainer>
  )
}

Training.propTypes = {
  displayTitle: PropTypes.string,
  getProductMap: PropTypes.func,
  isFetching: PropTypes.bool,
  isLmsFetching: PropTypes.bool,
  isLoadingApp: PropTypes.bool,
  lmsTrainings: PropTypes.array,
  match: PropTypes.object,
  navigationEvent: PropTypes.func,
  seedIds: PropTypes.object,
  showUpdateLoader: PropTypes.bool,
  trainingPaths: PropTypes.array,
}

export default Training
