import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box, Button } from 'grommet'
import GradeTile from 'components/dashboard/GradeTile'
import DropdownMenu from 'components/DropdownMenu'

export const StyledGradeTile = styled(GradeTile)``

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 18.4375rem;
  position: relative;
  border-radius: 0.625rem;
  background: ${themeGet('colors.themeAccent')};
`

export const StyledDropdownMenu = styled(DropdownMenu)`
  position: absolute;
  top: 9px;
  left: 90%;
`

export const CardWrapper = styled(Button).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: all 0.2s ease;
  padding: ${themeGet('spacing.yellow12_0_75rem')};
  border-radius: 0.625rem;
  background: ${themeGet('colors.themeAccent')};

  & > div {
    margin-right: ${themeGet('spacing.yellow12_0_75rem')};
  }
  ${StyledGradeTile} {
    & > div {
      background: ${themeGet('colors.themeDark')};
    }
  }
  &:hover {
    background: ${themeGet('colors.classCardHover')};

    ${StyledGradeTile} {
      & > div {
        background: ${themeGet('colors.themeSecondary')};
      }
    }
  }
`

export const InfoBox = styled(Box)`
  color: ${themeGet('colors.white')};

  h4 {
    margin-bottom: 0.5rem;
    color: ${themeGet('colors.white')};
    font-size: 1.0625rem;
    line-height: 1.125rem;
    font-family: ${themeGet('fontFamilyBold')};
  }

  span {
    font-style: italic;
  }
`
