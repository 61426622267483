import types from './types'

const clearError = slice => ({
  type: types.CLEAR_ERROR,
  slice,
})

const savingSitePacingDates = () => ({
  type: types.SAVING_SITE_PACING_DATES,
})

const savingSitePacingDatesSuccess = (data, programAccessCode) => ({
  type: types.SAVING_SITE_PACING_DATES_SUCCESS,
  data,
  programAccessCode,
})

const savingSitePacingDatesError = error => ({
  type: types.SAVING_SITE_PACING_DATES_ERROR,
  error: error,
})

const savingSitePreferences = siteId => ({
  type: types.SAVING_SITE_PREFERENCES,
  siteId,
})

const savingSitePreferencesSuccess = siteId => ({
  type: types.SAVING_SITE_PREFERENCES_SUCCESS,
  siteId,
})

const savingSitePreferencesError = error => ({
  type: types.SAVING_SITE_PREFERENCES_ERROR,
  error: error,
})

const updateMspOptInPrefences = () => ({
  type: types.UPDATE_MSP_OPT_IN_PREFERENCES,
})

const updateMspOptInPrefencesSuccess = data => ({
  type: types.UPDATE_MSP_OPT_IN_PREFERENCES_SUCCESS,
  data,
})

const updateMspOptInPrefencesError = formattedError => ({
  type: types.UPDATE_MSP_OPT_IN_PREFERENCES_ERROR,
  error: formattedError,
})

const updatingUserProgramPreferences = () => ({
  type: types.UPDATING_USER_PROGRAMPREFERENCES,
})
const updatingUserProgramPreferencesSuccess = site => ({
  type: types.UPDATING_USER_PROGRAMPREFERENCES_SUCCESS,
  site,
})
const updatingUserProgramPreferencesError = error => ({
  type: types.UPDATING_USER_PROGRAMPREFERENCES_ERROR,
  error,
})

export default {
  clearError,
  savingSitePacingDates,
  savingSitePacingDatesError,
  savingSitePacingDatesSuccess,
  savingSitePreferences,
  savingSitePreferencesSuccess,
  savingSitePreferencesError,
  updateMspOptInPrefences,
  updateMspOptInPrefencesSuccess,
  updateMspOptInPrefencesError,
  updatingUserProgramPreferences,
  updatingUserProgramPreferencesSuccess,
  updatingUserProgramPreferencesError,
}
