import React from 'react'
import PropTypes from 'prop-types'
import { StyledButton, StyledDiv, Wrapper } from './component.styles'

const GradeTile = ({
  className,
  dataTestId,
  disabled,
  displayText = 'GRADE NA',
  isStatic,
  onClick,
  size = 'default',
}) => {
  const Component = isStatic ? StyledDiv : StyledButton
  const displayTextArray = displayText.split(' ')
  const text = displayTextArray[0]
  const number = displayTextArray.length > 1 && displayTextArray[1]

  const handleClick = (isStatic, onClick) => (!isStatic ? onClick() : null)

  return (
    <Wrapper
      className={className}
      dataTestId={dataTestId}
      disabled={disabled}
      isStatic={isStatic}
      onClick={() => handleClick(isStatic, onClick)}
      size={size}
    >
      <Component>
        <p>{text}</p>
        {number && <h6>{number}</h6>}
      </Component>
    </Wrapper>
  )
}

GradeTile.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  disabled: PropTypes.bool,
  displayText: PropTypes.string,
  isStatic: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string,
}

export default GradeTile
