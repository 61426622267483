import React from 'react'
import PropTypes from 'prop-types'
import {
  HeaderWrapper,
  StyledContainedButton,
  StyledError,
  StyledDescription,
  StyledHeaderTwo,
  StyledImage,
  StyledSiteName,
  StyledWarningIcon,
  TitleWrapper,
} from './component.styles'
import AdminTooltip from 'components/AdminTooltip'
import { CONTINUE } from './constants'
import { TOOLTIP_LOCATIONS, TOOLTIP_PROGRAMS } from 'utils/tooltipHelpers'

const AdminHSSetupHeader = props => {
  const {
    className,
    description,
    displayTitle,
    isHighSchoolLaunchedError,
    isLaunched,
    isMultiSite,
    isSetup,
    launchHighSchool,
    panelIcon,
    siteName,
  } = props

  return (
    <HeaderWrapper className={className}>
      {!isMultiSite && siteName && !isSetup && (
        <StyledSiteName>{siteName.toUpperCase()}</StyledSiteName>
      )}

      {!isSetup && (
        <>
          <TitleWrapper>
            <StyledHeaderTwo>{displayTitle}</StyledHeaderTwo>
            <AdminTooltip
              location={TOOLTIP_LOCATIONS.HS_ADMIN_SCHOOL_SETUP_HEADER}
              panelName="setupPanel"
              programKey={TOOLTIP_PROGRAMS.HS}
            />
          </TitleWrapper>
          <StyledDescription>{description}</StyledDescription>
        </>
      )}
      {isSetup && !isLaunched && (
        <>
          <StyledImage
            alt={panelIcon?.description}
            key={panelIcon?.description}
            src={panelIcon?.file?.url}
          />
          <StyledHeaderTwo>{`Nice work! ${siteName} is successfully set up.`}</StyledHeaderTwo>
          <StyledContainedButton
            data-testid="admin-header-complete-button"
            onClick={launchHighSchool}
          >
            {CONTINUE}
          </StyledContainedButton>
          {isHighSchoolLaunchedError && (
            <StyledError>
              <StyledWarningIcon />
              Something went wrong.
              <br />
              Try again.
            </StyledError>
          )}
        </>
      )}
    </HeaderWrapper>
  )
}

AdminHSSetupHeader.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  displayTitle: PropTypes.string,
  isHighSchoolLaunchedError: PropTypes.bool,
  isLaunched: PropTypes.bool,
  isMultiSite: PropTypes.bool,
  isSetup: PropTypes.bool,
  launchHighSchool: PropTypes.func,
  panelIcon: PropTypes.object,
  siteName: PropTypes.string,
  tooltipText: PropTypes.string,
}
export default AdminHSSetupHeader
