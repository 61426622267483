import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { domains } from 'config/env'
import CertificateComponent from 'components/CertificateComponent'
import Microlearning from 'components/Microlearning'
import MicrolearningChoice from 'components/MicrolearningChoice'
import NavigationHelper from 'layers/navigation/navigationHelper'
import SelaAccordionStatus from 'components/SelaAccordionStatus'
import { StyledAccordion } from './component.styles'

export const ModuleUnit = ({
  displayTitle,
  hasCertificate,
  isActive,
  label,
  moduleTitle,
  lmsUnit,
  microlearnings,
  nextMicrolearningId,
  totalLessons,
  unitTime,
  navigationEvent,
  dataTestId,
}) => {
  const unitLabel = label ? `${label}: ` : ''
  const title = `${unitLabel}${displayTitle}`

  const isLeader = microlearnings[0]?.microlearningType?.includes('leader')

  const hasChoice = () => microlearnings.some(ml => ml.choices)

  const getStatus = () => (
    <SelaAccordionStatus
      certificateUrl={hasCertificate && getCertificateUrl()}
      hasMeeting={hasChoice()}
      isDone={lmsUnit?.is_done}
      isLeader={isLeader}
      totalLessons={totalLessons}
      unitTime={unitTime}
    />
  )

  const getCertificateUrl = () => {
    if (!lmsUnit) return ''
    const moduleNameb64 = Buffer.from(moduleTitle).toString('base64')
    const unitNameb64 = Buffer.from(title).toString('base64')
    const clockHoursb64 = Buffer.from(unitTime).toString('base64')
    const timeOffset = new Date().getTimezoneOffset()
    return `${domains.LMS_API_BASE}/certificate/sela/${timeOffset}/${lmsUnit.scoID}/${moduleNameb64}/${unitNameb64}/${clockHoursb64}`
  }

  const launchCertificate = () => {
    navigationEvent(getCertificateUrl(), NavigationHelper.types.NEW_TAB)
  }

  return (
    <StyledAccordion
      dataTestId={dataTestId}
      followActiveProp={true}
      isActive={isActive}
      isLeader={isLeader}
      status={getStatus()}
      title={title}
    >
      {microlearnings?.map(microlearning => {
        const isActive = nextMicrolearningId === microlearning.id

        return microlearning.choices ? (
          <MicrolearningChoice
            choices={microlearning.choices}
            entryId={microlearning.id}
            key={microlearning.sco}
            nextMicrolearningId={nextMicrolearningId}
          />
        ) : (
          <Microlearning
            description={microlearning.description}
            displayTitle={microlearning.displayTitle}
            instance={microlearning.instance}
            isActive={isActive}
            isDone={microlearning.is_done}
            isLeader={isLeader}
            key={`${microlearning.sco}`}
            label={microlearning.label}
            microlearningType={microlearning.microlearningType}
            sco={microlearning.sco}
            time={microlearning.time}
          />
        )
      })}
      {hasCertificate && (
        <CertificateComponent
          isComplete={lmsUnit?.is_done}
          launchCertificate={launchCertificate}
        />
      )}
    </StyledAccordion>
  )
}

ModuleUnit.propTypes = {
  dataTestId: PropTypes.string,
  displayTitle: PropTypes.string,
  hasCertificate: PropTypes.bool,
  isActive: PropTypes.bool,
  label: PropTypes.string,
  lmsUnit: PropTypes.object,
  microlearnings: PropTypes.array,
  moduleTitle: PropTypes.string,
  navigationEvent: PropTypes.func,
  nextMicrolearningId: PropTypes.string,
  totalLessons: PropTypes.number,
  unitTime: PropTypes.string,
}

export default connect(null, NavigationHelper.mapDispatchToProps)(ModuleUnit)
