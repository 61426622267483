import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const DefaultTrainingContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 1.3125rem ${themeGet('spacing.turquoise20_1_25rem')} 0;
  max-width: ${themeGet('breakpoints.sizes.large')};

  ${themeGet('breakpoints.mobileLarge')} {
    padding: ${themeGet('spacing.pink8_0_5rem')}
      ${themeGet('spacing.blue16_1rem')} 0;
  }
`

export const K5TrainingContainer = styled(DefaultTrainingContainer)`
  ${themeGet('breakpoints.mobileLarge')} {
    padding: 1.3125rem 0 0;
  }
`
