import styled from 'styled-components'
import { themeGet } from 'styled-system'
import CaretSortIcon from '../CaretSortIcon/component.styles'

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 0.25rem;
  flex: 1;
`

export const StyledButton = styled.button.attrs(props => ({
  fontFamily: props.isActive
    ? themeGet('fontFamilyBold')(props)
    : themeGet('fontFamilySemibold')(props),
}))`
  font-family: ${({ fontFamily }) => fontFamily};
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  font-size: inherit;
  cursor: pointer;
  color: inherit;
  position: relative;
`

export const StyledCaret = styled(CaretSortIcon)`
  padding-right: 0.625rem;
  position: absolute;
  top: 2px;
  left: 4px;
  top: 46%;
  transform: translateY(-50%);
`
