export const errorTitleMap = {
  accessError: 'Access Error',
  licenseError: 'License Error',
  lmsError: 'LMS Error',
  profileError: 'Profile Error',
  contentfulError: 'Contentful Error',
  reportingError: 'Reporting Error',
}

export function create401ErrorForRedacted() {
  const message = 'You are not authorized to view this page'
  const redact401 = new Error(message)
  redact401.status = 401
  redact401.statusText = message

  return redact401
}

export function create404ErrorForContentful() {
  const message =
    'Page not found. Sorry, your requested content is not available.'
  const error404 = new Error(message)
  error404.status = 404
  error404.statusText = message

  return error404
}

export function create413ErrorForContentful(message) {
  const error413 = new Error(message)
  error413.status = 413
  error413.statusText = message

  return error413
}
