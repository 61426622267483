import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  Wrapper,
  ContentHeaderOne,
  Container,
  BoxContent,
} from './component.styles'
import { BodyCopy } from 'secondstep-components'

const Accesibility = props => {
  const { content = {} } = props
  const { data, loading } = content

  const [displayTitle, setDisplayTitle] = useState()
  const [bodyText, setBodyText] = useState()

  const readyToLoad = !loading && !!data

  useEffect(() => {
    if (!data) {
      return
    }

    const { article } = data
    if (article && article?.displayTitle) {
      setDisplayTitle(article?.displayTitle)
    }
    if (
      article &&
      Array.isArray(article?.content) &&
      article?.content?.length > 0
    ) {
      setBodyText(article?.content[0]?.bodyCopy)
    }
  }, [data])

  return (
    readyToLoad && (
      <Wrapper animation="fadeIn">
        <Container>
          <ContentHeaderOne>{displayTitle}</ContentHeaderOne>
          {bodyText && (
            <BoxContent>
              <BodyCopy>{bodyText}</BodyCopy>
            </BoxContent>
          )}
        </Container>
      </Wrapper>
    )
  )
}

Accesibility.propTypes = {
  content: PropTypes.object,
  isFetching: PropTypes.bool,
}

export default Accesibility
