import { useEffect, useState } from 'react'

import { PropTypes } from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import { Loading } from 'secondstep-components'

import { GetRootContent } from 'layers/content/Hocs/RootContent'
import NavigationHelper from 'layers/navigation/navigationHelper'
import {
  operations as licenseOperations,
  selectors as licenseSelectors,
} from 'store/licenseManager'
import { operations as lmsOperations } from 'store/lmsManager'
import { operations as loadingOperations } from 'store/loadingManager'

import { INITIAL_LOADING_TEXT, UPDATE_LOADING_TEXT } from './constants'

import LessonsComponent from './component'
import {
  backToLessonsHandlerFactory,
  classSelectHandlerFactory,
  courseSelectHandlerFactory,
  gotoDashboardHandlerFactory,
  unitSelectHandlerFactory,
} from './handlers'
import {
  getCourseLinks,
  getCourses,
  getRelevantClasses,
  getSelectedProduct,
  getTeachTab,
} from './utils'

export const LessonsContainer = props => {
  const {
    activeDigitalLicenses,
    isLmsFetching,
    products,
    location,
    match,
    navigationEvent,
    isFetchingActiveDigitalLicenses,
    isLoading,
    isLoadingApp,
    classes,
    instructorDataExists,
    fetchActiveDigitalLicensesForUser = () => {},
    fetchInstructorData = () => {},
    fetchCoursesForProduct = () => {},
    setAppIsLoadingState,
    setAppNotLoadingState,
  } = props

  const [shouldRefresh, setShouldRefresh] = useState(false)

  const productName = 'middle-school'
  const { pathname } = window.location

  const selectedProduct = getSelectedProduct(products, productName)
  const productSections = selectedProduct?.productSections
  const teachTab = getTeachTab(productSections)
  const courses = getCourses(teachTab)
  const courseLinks = getCourseLinks(courses)
  const relevantClasses = getRelevantClasses(classes, courseLinks)

  const isDataMissing =
    !selectedProduct ||
    !productSections ||
    !courses ||
    !courseLinks ||
    !relevantClasses ||
    !instructorDataExists

  const checkToUpdateLmsLoadingState = () => {
    const isLoadingLmsData =
      isLmsFetching || !classes || isFetchingActiveDigitalLicenses

    if (!shouldRefresh && isLoadingLmsData && !isLoadingApp) {
      setAppIsLoadingState(INITIAL_LOADING_TEXT)
    }
    if (!shouldRefresh && !isLoadingLmsData && isLoadingApp) {
      setAppNotLoadingState()
    }
  }

  const messageListener = ({ data, origin }) => {
    const isLoadingLmsData =
      isLmsFetching || !classes || isFetchingActiveDigitalLicenses
    const messages = ['refresh']
    // Page refresh loader
    if (
      origin.includes('lms') &&
      messages.includes(data) &&
      !shouldRefresh &&
      !isLoadingLmsData
    ) {
      setShouldRefresh(true)
      setAppIsLoadingState(UPDATE_LOADING_TEXT)
      visibilityHandler() // if tab is opened by ctrl + click
    }
  }

  const visibilityHandler = async () => {
    const isLoadingLmsData =
      isLmsFetching || !classes || isFetchingActiveDigitalLicenses
    if (
      document.visibilityState === 'visible' &&
      shouldRefresh &&
      !isLoadingLmsData
    ) {
      await fetchInstructorData()
      setShouldRefresh(false)
      setAppNotLoadingState()
    }
  }

  useEffect(() => {
    window.addEventListener('message', messageListener)
    document.addEventListener('visibilitychange', visibilityHandler)
    return () => {
      window.removeEventListener('message', messageListener)
      document.removeEventListener('visibilitychange', visibilityHandler)
      if (isLoadingApp) {
        setAppNotLoadingState()
      }
    }
  }, [
    instructorDataExists,
    shouldRefresh,
    isFetchingActiveDigitalLicenses,
    isLmsFetching,
  ])

  useEffect(() => {
    checkToUpdateLmsLoadingState()
    if (!isLmsFetching && classes?.length === 0 && match.url !== pathname) {
      navigationEvent(match.url, NavigationHelper.types.REPLACE)
    }
    if (!instructorDataExists) {
      fetchInstructorData()
    }
    if (
      !Array.isArray(activeDigitalLicenses) &&
      !isFetchingActiveDigitalLicenses
    ) {
      fetchActiveDigitalLicensesForUser()
    }
  }, [
    instructorDataExists,
    activeDigitalLicenses,
    isFetchingActiveDigitalLicenses,
    shouldRefresh,
  ])

  useEffect(() => {
    if (!productSections) {
      fetchCoursesForProduct(productName)
    }
  }, [fetchCoursesForProduct, productSections, productName])

  const isDataLoadingOrMissing = isLoading || isDataMissing

  if (isDataLoadingOrMissing) {
    return <Loading />
  }

  return (
    <LessonsComponent
      classes={relevantClasses}
      contentfulCourses={courses}
      courseLinks={courseLinks}
      goToDashboard={gotoDashboardHandlerFactory(navigationEvent)}
      match={match}
      onBacklinkClick={backToLessonsHandlerFactory(
        productName,
        navigationEvent,
      )}
      onClassClick={classSelectHandlerFactory(productName, navigationEvent)}
      onCourseClick={courseSelectHandlerFactory(productName, navigationEvent)}
      onUnitSelect={unitSelectHandlerFactory(
        productName,
        navigationEvent,
        location,
        courses,
        courseLinks,
      )}
      productName={productName}
    />
  )
}

const mapStateToProps = (state, ownProps) => {
  const { lmsManager, licenseManager, loadingManager } = state
  const instructorDataExists =
    !!lmsManager?.instructorData?.Elementary ||
    !!lmsManager?.instructorData?.Classes

  return {
    activeDigitalLicenses: licenseSelectors.selectActiveDigitalLicenses(state),
    isFetchingActiveDigitalLicenses:
      licenseManager?.isFetchingActiveDigitalLicenses,
    isLoading:
      licenseManager.isFetchingActiveDigitalLicenses ||
      licenseManager.isSending ||
      loadingManager.isLoading ||
      lmsManager.isFetching ||
      ownProps.isLoading ||
      ownProps.isFetching,
    isLoadingApp: loadingManager?.isLoading,
    isLmsFetching: lmsManager.isFetching,
    classes: instructorDataExists
      ? [
          ...(lmsManager?.instructorData?.Elementary ?? []),
          ...(lmsManager?.instructorData?.Classes ?? []),
        ]
      : undefined,
    instructorDataExists,
  }
}

const mapDispatchToProps = {
  fetchActiveDigitalLicensesForUser:
    licenseOperations.fetchActiveDigitalLicensesForUser,
  fetchInstructorData: lmsOperations.fetchInstructorData,
  setAppIsLoadingState: loadingOperations.setIsLoadingState,
  setAppNotLoadingState: loadingOperations.setNotLoadingState,
}

export const mapper = entry => {
  return {
    isFetching: entry?.isFetching,
    isLoading: entry?.isLoading,
    products: entry?.products,
  }
}

const options = {
  include: 3,
  mapper,
  spread: true,
}

export default GetRootContent(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(LessonsContainer)),
  options,
  connect(null, NavigationHelper.mapDispatchToProps),
)

LessonsContainer.propTypes = {
  activeDigitalLicenses: PropTypes.array,
  classes: PropTypes.arrayOf(
    PropTypes.shape({
      contentfulID: PropTypes.string,
      completeLessons: PropTypes.number,
      instance: PropTypes.string,
      scoId: PropTypes.number,
      totalLessons: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
  fetchActiveDigitalLicensesForUser: PropTypes.func,
  fetchCoursesForProduct: PropTypes.func,
  fetchInstructorData: PropTypes.func,
  instructorDataExists: PropTypes.bool,
  isFetchingActiveDigitalLicenses: PropTypes.bool,
  isLmsFetching: PropTypes.bool,
  isLoading: PropTypes.bool,
  isLoadingApp: PropTypes.bool,
  isLoadingContentful: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
  match: PropTypes.shape({
    path: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    params: PropTypes.shape({
      productName: PropTypes.string,
      verb: PropTypes.string,
    }),
  }),
  navigationEvent: PropTypes.func,
  products: PropTypes.arrayOf(
    PropTypes.shape({
      pageData: PropTypes.shape({
        route: PropTypes.string,
      }),
    }),
  ),
  setAppIsLoadingState: PropTypes.func,
  setAppNotLoadingState: PropTypes.func,
}
