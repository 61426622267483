import React from 'react'
import PropTypes from 'prop-types'
import { BodyCopy, HeartIcon } from 'secondstep-components'
import { formatProgress } from 'utils/reportsHelpers'
import { BrightSpotWrapper, StyledTooltip } from './component.styles'

const ReportsBrightSpot = ({
  className,
  numClassesWithCompletedLessonLastPeriod,
  period,
  tooltipText,
  totalClassesCount,
}) => {
  const brightSpotText = formatProgress(
    numClassesWithCompletedLessonLastPeriod,
    period,
    totalClassesCount,
  )
  const hasTooltip = !!tooltipText?.length

  return (
    <BrightSpotWrapper className={className} hasTooltip={hasTooltip}>
      <HeartIcon />
      <BodyCopy data-testid="bright-spot-text">{brightSpotText}</BodyCopy>
      {tooltipText && (
        <StyledTooltip
          content={
            <>
              <HeartIcon />
              <BodyCopy>{tooltipText}</BodyCopy>
            </>
          }
        >
          <i className="fas fa-question-circle" />
        </StyledTooltip>
      )}
    </BrightSpotWrapper>
  )
}

ReportsBrightSpot.propTypes = {
  className: PropTypes.string,
  numClassesWithCompletedLessonLastPeriod: PropTypes.number,
  period: PropTypes.number,
  tooltipText: PropTypes.string,
  totalClassesCount: PropTypes.number,
}

export default ReportsBrightSpot
