import ContentfulParser from 'utils/contentfulParser/contents'

export const formContentCardListState = (parentEntry, entry, path) => {
  const { listContent: parentListContent = [] } = parentEntry || {}

  const { pageData } = entry || {}
  const { route: groupedContentRoute } = pageData || {}

  const lastRoute = getLastSlug(path)
  const contentTypes = []

  parentListContent.forEach(contentGroup => {
    const { pageData, displayTitle } = contentGroup || {}
    const { route } = pageData || {}

    if (displayTitle && route) {
      contentTypes.push({
        title: displayTitle,
        route,
      })
    }
  })

  const activeContentType = contentTypes.find(contentGroup => {
    const { route } = contentGroup || {}

    return route === lastRoute && groupedContentRoute === lastRoute
  })

  if (!activeContentType) {
    return null
  }

  return {
    contentTypes,
    activeContent: removeshowDefaultLocaleEntries(entry),
    activeContentType,
  }
}

/*
Recursive check for showDefaultLocale value throughout an entry. If field is found with false value,
remove object and all its children from the data tree
 */
const DEFAULT_LOCALE = 'en-US'
export function removeshowDefaultLocaleEntries(entry) {
  const { content, locale, showLanguageDefault } = entry
  const isLanguageDefaultShown =
    showLanguageDefault !== false ||
    (showLanguageDefault === false && DEFAULT_LOCALE === locale)

  if (isLanguageDefaultShown) {
    if (Array.isArray(content) && content.length > 0) {
      let filteredContent = []
      for (const childEntry of content) {
        const possibleChildEntry = removeshowDefaultLocaleEntries(childEntry)
        if (possibleChildEntry) {
          filteredContent.push(possibleChildEntry)
        }
      }

      return {
        ...entry,
        content: filteredContent,
      }
    } else {
      return entry
    }
  }
}

export const getLastSlug = path => {
  let modifiedPath = path
  const lastCharacterIndex = path.length - 1
  const lastCharacter = path[lastCharacterIndex]

  if (lastCharacter === '/') {
    modifiedPath = modifiedPath.slice(0, modifiedPath.length - 1)
  }

  if (modifiedPath.length > 0) {
    const pathSlugs = modifiedPath.split('/')
    const lastSlug = pathSlugs.pop()

    return lastSlug
  }

  return null
}

export const stripLastRouteSlug = path => {
  let modifiedPath = path
  const lastCharacterIndex = path.length - 1
  const lastCharacter = path[lastCharacterIndex]

  if (lastCharacter === '/') {
    modifiedPath = modifiedPath.slice(0, modifiedPath.length - 1)
  }

  const pathSlugs = modifiedPath.split('/')
  pathSlugs.pop()

  const joinedPath = pathSlugs.join('/')

  return joinedPath
}

export const getContentTabRoutes = entry => {
  const { listContent = [] } = entry || {}

  let contentTabRoutes = listContent.reduce((routes, contentItem) => {
    const { pageData } = contentItem || {}
    const { route } = pageData || {}

    if (route) {
      routes.push(route)
    }

    return routes
  }, [])

  return contentTabRoutes
}

export const formContentOverviewState = entry => {
  const { displayTitle, description } = entry || {}
  const videoProps = getVideoData(entry)
  const listProps = getListProps(entry)
  return {
    description,
    title: displayTitle,
    videoProps,
    listProps,
  }
}

export const getVideoData = entry => {
  const { video: content } = entry || {}
  const video = ContentfulParser.video(content)
  const { displayTitle, time, videoUrl, videoThumbnailUrl, tracks } =
    video || {}
  const videoData = {
    videoDisplayTitle: displayTitle,
    videoLength: time,
    tracks,
    videoThumbnailUrl,
    videoUrl,
  }
  return videoData
}

export const getListProps = entry => {
  const { additionalResources } = entry || {}
  const [additionalResource] = additionalResources || []
  const { displayTitle, assets = [], id } = additionalResource || {}
  const links = assets.map(asset => {
    const {
      displayTitle,
      url,
      contentType: contentTypeWrapper,
      file: fileWrapper,
    } = asset || {}
    const { file } = fileWrapper || {}
    const { url: fileUrl } = file || {}
    const { id: contentType } = contentTypeWrapper || {}
    // file type contentType (i.e. PDFs) links have a fileUrl, so url will be on this level of the asset
    const href = fileUrl || url

    return {
      text: displayTitle,
      href,
      contentType,
    }
  })

  return {
    header: displayTitle,
    id,
    links,
  }
}

export function formLanguageToggle(entry) {
  const { content = [] } = entry || {}
  const possibleLanguageToggle = content[0] || {}

  const { contentType } = possibleLanguageToggle || {}
  const { id } = contentType || {}
  const hasLanguageToggle = id === 'lang'

  if (hasLanguageToggle) {
    return possibleLanguageToggle
  } else {
  }
}

export function formContentForState(parentEntry, entry, pathname) {
  const contentOverview = formContentOverviewState(parentEntry)
  const contentCardList = formContentCardListState(parentEntry, entry, pathname)
  const languageToggle = formLanguageToggle(entry)

  return {
    contentOverview,
    contentCardList,
    languageToggle,
  }
}

export function processEntryAndParentEntry(props) {
  const { entry, parentEntry = {}, location } = props
  const { pathname } = location || {}
  const { contentType: contentTypeContainer } = entry || {}
  const { id: contentType } = contentTypeContainer || {}

  const { contentType: parentContentTypeContainer } = parentEntry || {}
  const { id: parentContentType } = parentContentTypeContainer || {}

  if (
    contentType === 'groupedContent' &&
    parentContentType === 'activityList'
  ) {
    const {
      contentOverview,
      contentCardList,
      languageToggle,
    } = formContentForState(parentEntry, entry, pathname)

    if (contentOverview && contentCardList) {
      return {
        contentOverview,
        contentCardList,
        languageToggle,
      }
    }
  }

  return {}
}
