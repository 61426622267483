import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { AwardIcon, BodyCopy, Hairline, TextLink } from 'secondstep-components'

const Button = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: 2px solid ${themeGet('colors.paleGray')};
  border-radius: 0.625rem;
  width: 100%;
  max-width: 360px;
  padding: 0.875rem 1.25rem 0.875rem 1rem;

  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;
  }
`

export const ButtonText = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamily')};
`

export const ButtonTextLink = styled(TextLink)`
  color: ${themeGet('colors.blueGray')};
  font-family: ${themeGet('fontFamilySemibold')};
  padding-top: 0.1875rem;
`

export const ContentWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const LeftContentWrapper = styled(ContentWrapper)`
  flex: 1;
  padding-right: 1.625rem;

  ${themeGet('breakpoints.medium')} {
    padding-right: 0.9375rem;
  }

  @media screen and (max-width: 970px) {
    padding-right: 0;
  }
`

export const RightContentWrapper = styled(ContentWrapper)`
  flex: 1;
  padding-left: 1.625rem;

  @media screen and (max-width: 970px) {
    padding-left: 0;
  }
`

export const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 371px;
  width: 100%;
`

export const PrimaryText = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 1.125rem;
  margin-top: 0.6875rem;
  padding: 0 1rem;
  text-align: center;

  ${themeGet('breakpoints.mobileLarge')} {
    padding: 0;
  }
`

export const ProgramToStaffButton = styled(Button)``

export const ProgramToStaffButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 0.75rem;

  ${themeGet('breakpoints.mobileLarge')} {
    align-items: center;
    padding-left: 0;
    padding-top: 1rem;
  }
`

export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 0.25rem;

  @media screen and (max-width: 970px) {
    padding-top: 2rem;
    padding-left: 0;
  }
`

export const SecondaryText = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};
  margin-top: 0.6875rem;
  max-width: 292px;
  text-align: center;
  width: 100%;
`

export const StaffTrainingButton = styled(Button)`
  margin-top: 1.25rem;
  padding-left: 1.125rem;
`

export const StaffTrainingButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1.3125rem;

  ${themeGet('breakpoints.mobileLarge')} {
    align-items: center;
    padding-left: 0;
    padding-top: 1rem;
  }
`

export const StyledAwardIcon = styled(AwardIcon)`
  color: ${themeGet('colors.blueGray')};
  height: 30px;
  width: 21px;
`

export const StyledHairline = styled(Hairline)`
  background-color: ${themeGet('colors.paleGray')};
  transform: rotate(90deg);
  height: 2px;
  width: 220px;
  margin: 0 -6rem;

  @media screen and (max-width: 970px) {
    display: none;
  }
`

export const Wrapper = styled.div`
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${themeGet('colors.linkLight')};
  border-radius: 0.625rem;
  padding: 3.5rem 2.5rem 3.25rem;
  width: -webkit-fill-available;

  ${themeGet('breakpoints.medium')} {
    padding: 3.5rem 2.5rem 3.25rem 1.625rem;
  }

  @media screen and (max-width: 970px) {
    flex-direction: column;
    padding: 2rem;
  }
`
