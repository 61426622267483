import styled from 'styled-components'
import { themeGet } from 'styled-system'
import BackLink from './component'

export const StyledBackLink = styled(BackLink)`
  color: ${themeGet('colors.blue')};
  font-size: 1rem;
  font-weight: 600;
  font-family: ${themeGet('font.molde.regular')};
  margin-bottom: 12px;
  margin-left: 20px;
  margin-top: 16px;
  width: min-content;
`
