import React from 'react'
import PropTypes from 'prop-types'
import { BodyCopy } from 'secondstep-components'
import K8ReportingGradeTile from 'components/K8ReportingGradeTile'
import K8ReportingStatusBox from 'components/K8ReportingStatusBox'
import ReportsLoading from 'components/ReportsLoading'
import ReportsPageHeader from 'components/ReportsPageHeader'
import {
  ContentWrapper,
  EmptyGradesStatusBox,
  Footer,
  GradeTileContainer,
  PageWrapper,
  ReportWrapper,
  GradeTileWrapper,
} from './component.styles'
import { formatEmptyGradesLabel } from 'utils/reportsHelpers'

const SchoolSummary = ({
  administratorsCount,
  entry,
  gradesWithClasses,
  gradesWithNoClasses,
  handleSelectGrade,
  hasClassesWithCompletedLessonLastPeriod,
  isFetchingSchool,
  lastUpdatedAt,
  navigationHandler,
  numClassesWithCompletedLessonLastPeriod,
  period,
  schoolEmptyDisplay,
  siteName,
  teachersCount,
  totalClassesCount,
}) => {
  const {
    gradeHeaderTooltip,
    schoolFooterText: footerText,
    gradeEmptyStateIcon,
  } = entry || {}
  return (
    <PageWrapper>
      {isFetchingSchool && <ReportsLoading background />}
      {!isFetchingSchool && (
        <>
          <ContentWrapper>
            <ReportWrapper>
              <ReportsPageHeader
                administratorsCount={administratorsCount}
                entry={entry}
                gradeOrSiteName={siteName}
                hasClassesWithCompletedLessonLastPeriod={
                  hasClassesWithCompletedLessonLastPeriod
                }
                isSchoolSummary
                lastUpdatedAt={lastUpdatedAt}
                numClassesWithCompletedLessonLastPeriod={
                  numClassesWithCompletedLessonLastPeriod
                }
                period={period}
                teachersCount={teachersCount}
                tooltipText={gradeHeaderTooltip}
                totalClassesCount={totalClassesCount}
              />
              {gradesWithClasses?.length === 0 && (
                <K8ReportingStatusBox
                  icon={schoolEmptyDisplay?.icon?.file?.url}
                  text={schoolEmptyDisplay?.text}
                  title={schoolEmptyDisplay?.header}
                />
              )}
              {gradesWithClasses?.length > 0 && (
                <>
                  <GradeTileContainer>
                    <GradeTileWrapper>
                      {gradesWithClasses?.map(grade => {
                        const {
                          hasClassesWithCompletedLessonLastPeriod,
                          numClassesWithCompletedLessonLastPeriod,
                          classesCount,
                          classesTaught,
                          gradeName,
                        } = grade
                        return (
                          <K8ReportingGradeTile
                            classesCount={classesCount}
                            classesTaught={classesTaught}
                            daysToReport={period}
                            grade={gradeName}
                            handleOnClick={() =>
                              handleSelectGrade(navigationHandler, gradeName)
                            }
                            hasClassesWithCompletedLessonLastPeriod={
                              hasClassesWithCompletedLessonLastPeriod
                            }
                            key={gradeName}
                            numClassesWithCompletedLessonLastPeriod={
                              numClassesWithCompletedLessonLastPeriod
                            }
                          />
                        )
                      })}
                    </GradeTileWrapper>
                  </GradeTileContainer>
                  {gradesWithNoClasses?.length > 0 && (
                    <EmptyGradesStatusBox
                      icon={gradeEmptyStateIcon?.file?.url}
                      text={formatEmptyGradesLabel(gradesWithNoClasses)}
                    />
                  )}
                </>
              )}
            </ReportWrapper>
            <Footer>
              <BodyCopy>{footerText}</BodyCopy>
            </Footer>
          </ContentWrapper>
        </>
      )}
    </PageWrapper>
  )
}

SchoolSummary.propTypes = {
  administratorsCount: PropTypes.number,
  entry: PropTypes.object,
  gradesWithClasses: PropTypes.array,
  gradesWithNoClasses: PropTypes.array,
  handleSelectGrade: PropTypes.func,
  hasClassesWithCompletedLessonLastPeriod: PropTypes.bool,
  isFetchingSchool: PropTypes.bool,
  lastUpdatedAt: PropTypes.string,
  navigationHandler: PropTypes.func,
  numClassesWithCompletedLessonLastPeriod: PropTypes.number,
  period: PropTypes.number,
  schoolEmptyDisplay: PropTypes.object,
  siteName: PropTypes.string,
  teachersCount: PropTypes.number,
  totalClassesCount: PropTypes.number,
}

export default SchoolSummary
