export const defaultGrades = {
  'All Lessons': [
    {
      name: 'grade-k',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-1',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-2',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-3',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-4',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-5',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-6',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-7',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-8',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
  ],
  'Unit 1': [
    {
      name: 'grade-k',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-1',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-2',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-3',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-4',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-5',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-6',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-7',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-8',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
  ],
  'Unit 2': [
    {
      name: 'grade-k',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-1',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-2',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-3',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-4',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-5',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-6',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-7',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-8',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
  ],
  'Unit 3': [
    {
      name: 'grade-k',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-1',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-2',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-3',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-4',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-5',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-6',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-7',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-8',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
  ],
  'Unit 4': [
    {
      name: 'grade-k',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-1',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-2',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-3',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-4',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-5',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-6',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-7',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-8',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
  ],
  'Unit 5': [
    {
      name: 'grade-k',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-1',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-2',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-3',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-4',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-5',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-6',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-7',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
    {
      name: 'grade-8',
      numOfClasses: 0,
      classesNotStarted: 0,
      lessonsCompleted: 0,
      lessonsCompletedPercent: 0,
      totalLessons: 0,
    },
  ],
}
