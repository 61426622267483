import React from 'react'
import PropTypes from 'prop-types'
import { shouldhighlight } from './helpers'
import {
  AI_HS_PAGE_HIT_CLICKED,
  sendApplicationInsightsEvent,
} from 'utils/sendAnalyticsDataHelpers'

import {
  CardItem,
  HighSchoolLibraryStatusIconContainer,
  HighSchoolLibraryStatusIcon,
  CardImage,
  AudienceAndGradeContainer,
  HighlightBox,
  TextWrapper,
  Title,
  ResourceDurationContainer,
  HighlightGradeBox,
} from './component.styles'

const ResourceCard = props => {
  const {
    dataTestId,
    image,
    imageDescription,
    isComplete,
    isSingleCard,
    isSkipped,
    audience,
    title,
    grade,
    onClick,
    description,
    resourceType,
    shouldShowStatusIcon,
    duration,
    unit,
    hit,
    objectID,
    className,
  } = props || {}

  const imageAlt = imageDescription !== '' ? imageDescription : 'resource card'

  const handleClick = () => {
    sendApplicationInsightsEvent(AI_HS_PAGE_HIT_CLICKED, {
      title,
      resourceType,
      audience,
      unit,
      objectID,
    })

    onClick()
  }
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      handleClick()
    }
  }

  return (
    <CardItem
      className={className}
      data-testid={dataTestId}
      isSingleCard={isSingleCard}
      onClick={handleClick}
      onKeyPress={e => handleKeyPress(e)}
      tabIndex="0"
    >
      {shouldShowStatusIcon && (
        <HighSchoolLibraryStatusIconContainer>
          <HighSchoolLibraryStatusIcon
            isComplete={isComplete}
            isSkipped={isSkipped}
          />
        </HighSchoolLibraryStatusIconContainer>
      )}
      <CardImage alt={imageAlt} src={image} />
      <TextWrapper isSingleCard={isSingleCard}>
        <AudienceAndGradeContainer>
          {!!resourceType && (
            <HighlightBox audience={audience}>{resourceType}</HighlightBox>
          )}
          {!!grade && <HighlightGradeBox>{grade}</HighlightGradeBox>}
        </AudienceAndGradeContainer>
        <Title>{shouldhighlight(hit, title, 'displayTitle')}</Title>
        <ResourceDurationContainer>
          <div className="duration">
            {!!duration &&
              shouldhighlight(hit, duration, 'duration', 'durationHighlighted')}
          </div>
        </ResourceDurationContainer>
        <div className="unit">{unit}</div>
        <div className="description">
          {shouldhighlight(hit, description, 'description')}
        </div>
      </TextWrapper>
    </CardItem>
  )
}

ResourceCard.propTypes = {
  entry: PropTypes.shape({
    displayTitle: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  isComplete: PropTypes.bool,
  isSingleCard: PropTypes.bool,
  isSkipped: PropTypes.bool,
}

export default ResourceCard
