import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { FaQuestionCircle } from 'react-icons/fa'
import PlainProgramCard from '../PlainProgramCard'
import {
  BodyCopy,
  ContainedButton,
  HeaderTwo,
  Tooltip,
} from 'secondstep-components'

export const StyledPlainProgramCard = styled(PlainProgramCard)`
  box-shadow: none;
  width: 41.125rem;

  ${HeaderTwo} {
    font-family: ${themeGet('font.sharpSlab.semibold')};
    font-size: 1.4375rem;
    line-height: 1.875rem;
    padding-top: 0.125rem;
  }
`

export const Hairline = styled.div`
  height: 0.125rem;
  border-radius: 0.75px;
  background-color: ${themeGet('colors.gray500')};
  width: calc(100%-1.25rem);
  margin-top: 0.375rem;
`

export const ContentWrapper = styled(Box)`
  align-items: center;
  flex-direction: column;
  width: 38.125rem;
  align-self: center;

  a {
    color: ${themeGet('colors.blue')};
    text-decoration: none;
  }

  a:hover {
    color: ${themeGet('colors.secondStepBlue')};
  }
`

export const IntroCopy = styled.span`
  font-family: ${themeGet('font.molde.medium')};
  font-size: 1.125rem;
  color: ${themeGet('colors.secondStepBlue')};
  text-align: center;
  line-height: 1.625rem;
  padding-top: 1.0625rem;

  @media (max-width: 988px) {
    padding-top: 0.75rem;
    font-size: 1rem;
  }

  ${themeGet('breakpoints.tablet')} {
    max-width: 16.25rem;
    line-height: 1.25rem;
  }
`

export const ProgramsWrapper = styled(Box)`
  display: flex;
  flex-direction: row;
  padding-top: 1.375rem;
  padding-bottom: 2.75rem;
  flex-wrap: wrap;

  ${ContainedButton} {
    width: 13.4375rem;
    text-transform: none;
  }

  @media (max-width: 988px) {
    width: 100%;
    flex-direction: column;
    padding-top: 0.875rem;
    padding-bottom: 2.5rem;
  }
`
export const TileImg = styled.img`
  padding-top: 3.875rem;
  height: 5rem;
`

export const TooltipAnchorCopy = styled.span`
  font-family: ${themeGet('font.molde.medium')};
  font-size: 1rem;
  color: ${themeGet('colors.blue')};
  line-height: 1.1875rem;
`

export const QuestionIcon = styled(FaQuestionCircle)`
  color: ${themeGet('colors.lightGray')};
  font-size: 0.75rem;
`

export const Tile = styled(Box)`
  background-color: ${themeGet('colors.paleGray')};
  border-radius: 0.625rem;
  min-width: 18.4375rem;
  height: 15.438rem;
  text-align: center;
  align-items: center;
  justify-content: center;
  cursor: default;
  margin-bottom: 2.688rem;

  @media (min-width: 989px) {
    &:nth-child(odd) {
      margin-right: 1.25rem;
    }

    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }
  }

  @media (max-width: 988px) {
    &:not(:last-child) {
      margin-bottom: 2.25rem;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`

export const ProgramTooltip = styled(Tooltip)`
  div {
    bottom: 150%;
    left: 50%;
    padding: 1rem;
    border-radius: 0.3125rem;
    font-size: 1rem;
    text-align: left;
    line-height: 1rem;
    width: 12rem;

    :after {
      content: ' ';
      width: 0.75rem;
      height: 0.75rem;
      position: absolute;
      top: 94%;
      left: 50%;
      background-color: white;
      transform: translate(-50%) rotate(45deg);
    }
  }
`

export const ProductsTooltip = styled(ProgramTooltip)`
  div {
    width: fit-content;
  }
`

export const ProgramToolipText = styled(BodyCopy)`
  white-space: nowrap;
`

export const DescriptionWrapper = styled.div`
  max-width: 15.9375rem;

  ${BodyCopy} {
    color: ${themeGet('colors.dashboard.gray1')};
    font-family: ${themeGet('font.molde.regular')};
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: left;
  }
`

export const Description = styled.p`
  font-family: ${themeGet('font.molde.regular')};
  font-size: 1rem;
  color: ${themeGet('colors.mediumGray')};
  line-height: 1.1875rem;
  min-height: 3.563rem;
  padding-top: 1.4375rem;
  padding-bottom: 1.5rem;
  padding-left: ${themeGet('spacing.yellow12_0_75rem')};
  padding-right: ${themeGet('spacing.yellow12_0_75rem')};
`
