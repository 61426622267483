import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toPascalCase, titleToIconName } from '../../utils/stringHelpers'
import ContentfulParser from 'utils/contentfulParser/contents'
import { AdvisoryActivityIcons } from 'secondstep-components'
import TextLinkWithFileIcon from 'components/TextLinkWithFileIcon'
import { contentfulImageShape } from 'utils/shapes'

import {
  BodyText,
  Container,
  HeaderText,
  IconWrapper,
  HeaderWrapper,
  TextLink,
  LinkContent,
  StyledImage,
  StyledWrapper,
  FooterSection,
} from './component.styles'

class ContentTypeDescription extends Component {
  static propTypes = {
    backgroundColor: PropTypes.string,
    backgroundImage: PropTypes.string,
    body: PropTypes.string.isRequired,
    bodyColor: PropTypes.string,
    borderColor: PropTypes.string,
    dataTestId: PropTypes.string,
    descriptionImage: contentfulImageShape,
    descriptionPdf: PropTypes.array,
    footerChildren: PropTypes.any,
    hasFooter: PropTypes.bool,
    id: PropTypes.string.isRequired,
    singleLink: PropTypes.shape({
      text: PropTypes.string,
      href: PropTypes.string,
      color: PropTypes.string,
      hoverColor: PropTypes.string,
    }),
    title: PropTypes.string.isRequired,
    titleColor: PropTypes.string,
  }

  render() {
    const {
      body,
      descriptionImage,
      descriptionPdf,
      id,
      title = '',
      singleLink,
      dataTestId,
      backgroundColor,
      backgroundImage,
      borderColor,
      hasFooter,
      footerChildren,
    } = this.props

    const iconName = titleToIconName(title)
    const iconKey = toPascalCase(iconName)
    const Icon = AdvisoryActivityIcons[iconKey]
    const containerProps = {
      backgroundColor,
      backgroundImage,
      borderColor,
    }
    return (
      <Container {...containerProps}>
        <StyledWrapper
          className="styled-wrapper"
          data-testid={dataTestId || `activity-type-description-${id}`}
        >
          <div className="text-content">
            <HeaderWrapper>
              {!!Icon && (
                <IconWrapper>
                  <Icon />
                </IconWrapper>
              )}
              <HeaderText headerColor={this.props.titleColor}>
                {title}
              </HeaderText>
            </HeaderWrapper>
            <BodyText bodyColor={this.props.bodyColor}>{body}</BodyText>
            {descriptionPdf?.map(pdf => {
              const { fileType, href, text } = ContentfulParser.pdf(pdf) || {}

              return (
                <TextLinkWithFileIcon
                  fileType={fileType}
                  href={href}
                  key={`text-link-with-icon-${text}`}
                  text={text}
                />
              )
            })}

            {singleLink?.href && singleLink?.text && (
              <LinkContent>
                <TextLink {...singleLink} to={singleLink.href}>
                  {singleLink.text}
                </TextLink>
              </LinkContent>
            )}
          </div>
          {!!descriptionImage?.file?.url && (
            <StyledImage src={descriptionImage.file.url} />
          )}
        </StyledWrapper>
        {hasFooter && <FooterSection>{footerChildren}</FooterSection>}
      </Container>
    )
  }
}

export default ContentTypeDescription
