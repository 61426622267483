/* eslint-disable react/no-unknown-property */
import React from 'react'
import PropTypes from 'prop-types'

class Notification extends React.Component {
  static propTypes = {
    autoDismissTimeout: PropTypes.number,
    children: PropTypes.node,
    clearBackground: PropTypes.bool,
    color: PropTypes.oneOf(['question', 'info', 'success', 'warning', 'danger'])
      .isRequired,
    dismissed: PropTypes.bool,
    dismissible: PropTypes.bool,
    fade: PropTypes.bool,
    id: PropTypes.string,
    onDismiss: PropTypes.func,
    shadow: PropTypes.bool,
    showIcon: PropTypes.bool,
    text: PropTypes.string,
  }
  static defaultProps = {
    fade: true,
    showIcon: true,
    dismissed: false,
    dismissible: false,
    autoDismissTimeout: 0,
    shadow: false,
    clearBackground: false,
  }

  constructor(props) {
    super(props)

    this.state = {
      show: true,
      dismissTimeout: null,
    }
  }

  componentDidMount = () => {
    const { dismissible, autoDismissTimeout } = this.props
    if (dismissible && autoDismissTimeout > 0) {
      this.setDismissTimeout(this.props.autoDismissTimeout)
    }
  }

  componentWillUnmount = () => {
    const { dismissTimeout } = this.state
    if (dismissTimeout) {
      clearTimeout(dismissTimeout)
      this.setState({ dismissTimeout: null })
    }
  }

  static pngs = {
    question:
      'https://cfccdn.blob.core.windows.net/static/img/question_icon_32px.png',
    info: 'https://cfccdn.blob.core.windows.net/static/img/info_icon_32px.png',
    success:
      'https://cfccdn.blob.core.windows.net/static/img/success_icon_32px.png',
    warning:
      'https://cfccdn.blob.core.windows.net/static/img/yield_icon_32px.png',
    danger:
      'https://cfccdn.blob.core.windows.net/static/img/warning_icon_32px.png',
    close: 'https://cfccdn.blob.core.windows.net/static/img/cross_icon.png',
  }

  setDismissTimeout = (ttl = 500) => {
    const { onDismiss } = this.props
    const timeout = setTimeout(() => {
      this.setState({ dismissed: true }, onDismiss)
    }, ttl)
    this.setState({ dismissTimeout: timeout })
  }

  dismiss = () => {
    this.setState({ show: false }, this.setDismissTimeout)
  }

  render = () => {
    const { dismissed, show } = this.state
    let {
      fade,
      color,
      clearBackground,
      id,
      showIcon,
      text,
      dismissible,
      shadow,
      children,
    } = this.props
    const content = children || text

    if (dismissed) return null

    const icon = color
    color = clearBackground ? 'none' : color === 'question' ? 'info' : color

    return (
      <div
        className={`notification-wrapper ${fade ? 'fade' : ''} ${
          show ? 'show' : ''
        }`}
        id={id}
      >
        <div className={`alert alert-${color} notification-alert`}>
          {showIcon && <div className="img" />}
          <div className="message">{content}</div>
          {dismissible && (
            <div
              className="dismiss-container"
              onClick={this.dismiss}
              onKeyDown={this.dismiss}
              role="button"
              tabIndex={0}
            >
              <div className="dismiss">
                <span className="dismiss-link">Dismiss</span>&nbsp;&nbsp;
                <i className="fas fa-times" />
              </div>
            </div>
          )}
        </div>

        <style jsx>
          {`
            .notification-wrapper {
              ${shadow
                ? 'box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);'
                : ''}
            }

            .notification-alert {
              display: flex;
              align-content: flex-start;
              padding: 1.12rem 1.5rem;
              font-size: 1.125rem;
              line-height: 1.5rem;
              border: none;
            }

            .notification-alert > * {
              display: flex;
              align-content: center;
              color: #1f1f1f !important;
            }

            .notification-alert .img {
              background-image: url(${Notification.pngs[icon]});
              background-repeat: no-repeat;
              width: 2rem;
              height: 2rem;
              padding-right: 3.4rem;
            }

            .notification-alert .message {
              flex-grow: 1;
              margin-top: 0.1875rem;
              ${dismissible ? 'padding-right: 3.75rem;' : ''}
              display: block;
            }

            .notification-alert .dismiss-container {
              display: flex;
              margin-top: 3px;
              white-space: nowrap;
              cursor: pointer;
            }

            .notification-alert .dismiss-link {
              font-weight: bold;
              text-decoration: underline;
            }

            .notification-alert .fa-times {
              display: inline-block;
              vertical-align: middle;
            }
          `}
        </style>
      </div>
    )
  }
}

export default Notification
