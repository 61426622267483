import React, { useState } from 'react'
import PropTypes from 'prop-types'

import withSiteContext from 'layers/content/v2/Hocs/withSiteContext'

import ProgramRoleTile from './component'

const ProgramRoleTileContainer = props => {
  const { currentSite } = props || {}
  const [isModalOpen, setIsModalOpen] = useState(false)

  const handleModalOpen = () => {
    setIsModalOpen(true)
    // setIsModalLocked(true)
  }
  const handleCloseModal = () => {
    setIsModalOpen(false)
    // setIsModalLocked(false)
  }
  return (
    <ProgramRoleTile
      currentSite={currentSite}
      handleCloseModal={handleCloseModal}
      handleModalOpen={handleModalOpen}
      isModalOpen={isModalOpen}
    />
  )
}

ProgramRoleTileContainer.propTypes = {
  currentSite: PropTypes.shape({
    siteId: PropTypes.number.isRequired,
    siteName: PropTypes.string.isRequired,
  }),
  handleClick: PropTypes.func,
}

export default withSiteContext(ProgramRoleTileContainer)
