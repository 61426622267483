export const COMING_SOON_TEXT_BOLD =
  'Launch Second Step® High School by facilitating the Kick-Off Meeting and supporting staff to complete program training.'
export const COMING_SOON_TEXT_REGULAR =
  'The Training Completion Report will be available soon.'
export const EDIT_LINK_TEXT = 'Edit'
export const HS_TRAINING_URL = '/product/high-school/training'
export const MANAGE_USERS_LINK_TEXT = 'Manage Users'
export const MANAGE_USERS_URL = '/user-management'
export const PACING_SCHEDULE_LINK_TEXT = 'Pacing Schedule'
export const PROGRAM_TRAINING_BUTTON_TEXT = 'See Program Training'
export const SCHOOL_SETTINGS_TEXT = 'School Settings'
export const USERS_ADDED_TEXT = 'Users Added'
