import styled from 'styled-components'
import { themeGet } from 'styled-system'

import RadioToggle from '../RadioToggle'

export const StyledRadioToggle = styled(RadioToggle)`
  background-color: ${themeGet('colors.lighterGray')};
  padding: 4px;
  border-radius: 18px;
  align-items: center;

  [role='radio'] {
    color: ${themeGet('colors.darkerDarkBlue')};
    cursor: pointer;
    transition: all 0.2s ease;
    display: inline-block;
    padding: 0.2rem 1rem;
    border-radius: 14px;
    font-family: ${themeGet('fontFamilySemibold')};
  }

  [role='radio'][aria-checked='true'] {
    color: ${themeGet('colors.white')};
    background-color: ${themeGet('colors.buttons.primaryBackground')};
  }

  [role='radio'][aria-checked='false']:hover,
  [role='radio'][aria-checked='false']:active {
    color: ${themeGet('colors.buttons.primaryBackgroundHover')};
  }
`
