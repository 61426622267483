import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { HeaderOne } from 'secondstep-components'

export const StyledHeaderOne = styled(HeaderOne)`
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('fontFamilyHeadingBold')};
  margin: 0 0 ${themeGet('spacing.amber28_1_75rem')} 0;
`

export const Wrapper = styled.div`
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
  padding: 1.3125rem 1.25rem 0;
  box-sizing: border-box;

  p {
    margin-block-end: 1rem;
    margin-block-start: 1rem;
  }
`
