export default {
  'hs program training': 'Program Training',
  'hs b&c collection 1': 'Pathway 1',
  'hs b&c collection 2': 'Pathway 1',
  'hs b&c collection 3': 'Pathway 1',
  'hs c&c collection 1': 'Pathway 2',
  'hs c&c collection 2': 'Pathway 2',
  'hs c&c collection 3': 'Pathway 2',
  'hs a&o collection 1': 'Pathway 3',
  'hs a&o collection 2': 'Pathway 3',
  'hs a&o collection 3': 'Pathway 3',
  'hs w&c collection 1': 'Pathway 4',
  'hs w&c collection 2': 'Pathway 4',
  'hs w&c collection 3': 'Pathway 4',
}
