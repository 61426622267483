import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AudioPlayer from '@cfc/secondstep-audio'
import ContentfulParser from 'utils/contentfulParser/contents'
import TextLinkItem from 'components/TextLinkItem'
import ClosableModal from 'experienceComponents/ClosableModal'
import VideoDataGetter from 'experienceComponents/VideoDataGetter'
import { IconPDF, IconVideo, IconAudio } from 'secondstep-components'
import { PUSH } from 'layers/navigation/store/types'
import { contentfulPageDataShape } from 'utils/shapes'
import {
  MediaGroupingWrapper,
  MediaTitle,
  MediaGroupWrapper,
  MediaTypeTitle,
  TextLinkWrapper,
} from './component.styles.js'
import { ASSET_TYPES, CLICK, ENTER } from './data'
import FocusTrap from 'focus-trap-react'

const MediaGroupingCard = props => {
  const [showModal, setShowModal] = useState(false)
  const [modalUrl, setModalUrl] = useState('')
  const [modalTitle, setModalTitle] = useState('')
  const [modalType, setModalType] = useState('')
  const [modalEntryId, setModalEntryId] = useState('')
  const { locale } = props || {}

  const openModal = ({ assetType, entryId, displayTitle, event, url }) => {
    if (showModal || (event.type !== CLICK && event.key !== ENTER)) {
      return
    }
    setModalUrl(url)
    setModalTitle(displayTitle)
    setModalType(assetType)
    setShowModal(true)
    setModalEntryId(entryId)
  }

  const closeVideoModal = () => {
    setShowModal(false)
  }

  const assetTextLinksMap = {
    [ASSET_TYPES.pdf]: asset => {
      const { displayTitle, file: fileWrapper, locale } = asset || {}
      const url = fileWrapper?.file?.url ?? fileWrapper.url ?? null

      return (
        <TextLinkItem
          Icon={IconPDF}
          href={url}
          locale={locale}
          text={displayTitle}
        />
      )
    },
    [ASSET_TYPES.video]: asset => {
      const { locale, id: entryId } = asset
      const videoData = ContentfulParser.video(asset)
      const { displayTitle } = videoData || {}
      const assetType = ASSET_TYPES.video

      return (
        <TextLinkItem
          Icon={IconVideo}
          locale={locale}
          onClick={event =>
            openModal({
              assetType,
              displayTitle,
              entryId,
              event,
              locale,
            })
          }
          text={displayTitle}
        />
      )
    },
    [ASSET_TYPES.audio]: asset => {
      const { locale } = asset
      const audioData = ContentfulParser.parseAudioData(asset)
      const { fileUrl: url, title: displayTitle } = audioData || {}
      const assetType = ASSET_TYPES.audio

      return (
        <TextLinkItem
          Icon={IconAudio}
          locale={locale}
          onClick={event =>
            openModal({
              assetType,
              displayTitle,
              event,
              url,
            })
          }
          text={displayTitle}
        />
      )
    },
  }

  const getContentTypeId = asset => {
    const { contentType } = asset ?? {}
    const contentTypeId = contentType?.id ?? contentType

    return contentTypeId ? contentTypeId.toLowerCase() : null
  }

  const getAssetLinks = asset => {
    const contentTypeId = getContentTypeId(asset)

    return assetTextLinksMap[contentTypeId](asset)
  }

  const { displayTitle, id, index, content, pageData, navigationEvent } = props

  // we render a list of articles differently than lists of static media:
  const isArticleList = content.every(c => c.contentType.id === 'article')
  const mediaGroupingCardPrefix = `media-grouping-card`
  const mediaGroupingCardId = `${mediaGroupingCardPrefix}-${index}-${id}`
  return (
    <div className="media-grouping-card" data-testid={mediaGroupingCardId}>
      <MediaTitle>{displayTitle}</MediaTitle>
      <MediaGroupingWrapper isArticleList={isArticleList}>
        {content.map(mediaGroup => {
          const { assets = [], displayTitle, pageData: childPageData = {} } =
            mediaGroup || {}
          const isArray = Array.isArray(assets)
          const isLink = !!(pageData?.route && childPageData?.route)
          return (
            <MediaGroupWrapper key={mediaGroup.id}>
              <MediaTypeTitle
                isLink={isLink}
                onClick={() => {
                  if (isLink) {
                    navigationEvent(
                      `${location.pathname}/${pageData.route}/${childPageData.route}`,
                      PUSH,
                    )
                  }
                }}
              >
                {displayTitle}
              </MediaTypeTitle>
              {isArray &&
                assets.map((asset, index) => {
                  return (
                    <TextLinkWrapper key={index}>
                      {getAssetLinks(asset)}
                    </TextLinkWrapper>
                  )
                })}
            </MediaGroupWrapper>
          )
        })}
        <ClosableModal
          id={id}
          modalTitle={modalTitle}
          onClose={closeVideoModal}
          show={showModal}
        >
          {modalType === ASSET_TYPES.audio && <AudioPlayer src={modalUrl} />}
          {modalType === ASSET_TYPES.video && (
            <FocusTrap>
              <VideoDataGetter entryId={modalEntryId} refetchLocale={locale} />
            </FocusTrap>
          )}
        </ClosableModal>
      </MediaGroupingWrapper>
    </div>
  )
}

MediaGroupingCard.propTypes = {
  content: PropTypes.array.isRequired,
  displayTitle: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  navigationEvent: PropTypes.func,
  pageData: contentfulPageDataShape,
}

export default MediaGroupingCard
