/* istanbul ignore file */
// TODO: ^ for the sake of time, temporarily ignore coverage on this file

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { programs } from 'layers/content/v2/hooks/useCourseManager'
import {
  CourseManagerGetter,
  CourseManagerType,
} from 'layers/content/v2/Hocs/CourseManagerGetter'
import {
  operations as lmsOperations,
  selectors as lmsSelectors,
} from 'store/lmsManager'
import { selectors as contextSelectors } from 'auth/stores/userContext'
import { selectors as licenseSelectors } from 'store/licenseManager'
import {
  AUDIENCE_SCHOOL_LEADER,
  AUDIENCE_EDUCATOR,
} from 'utils/highschoolHelpers'
import {
  checkIfLessonIsComplete,
  findNode,
  formatDate,
  formatSchoolName,
  isAdmin,
} from 'components/MarkCompleteSection/helpers'
import HsEducatorCompleteModal from 'components/HsEducatorCompleteModal'
import Component from './component'
import {
  getMarkCompleteButtonIcons,
  getInstructorDataForLessonActivityType,
  getCompletionCommentary,
  getMarkCompleteButtonText,
  getEducatorCompleteModalContent,
  getEducatorProgressText,
} from './helpers'
import { PERIOD_PROGRESS } from './constants'

function HsIndividualResourceMarkCompleteContainer({
  activeSites,
  activityType,
  courseManager,
  courseNodeId,
  fetchOrCreateInstructorProgramFlex,
  highSchoolInstructorData,
  markCompleteContent,
  onMarkComplete,
  practiceName,
  userContext,
}) {
  const [schoolName, setSchoolName] = useState('')
  const [isComplete, setIsComplete] = useState(false)
  const [completionDate, setCompletionDate] = useState('')
  const [registrationId, setRegistrationId] = useState(null)
  const [leafNodeId, setLeafNodeId] = useState(null)
  const [educatorProgressModalOpen, setEducatorProgressModalOpen] = useState(
    false,
  )
  const [periodProgress, setPeriodProgress] = useState(null)

  const {
    icon: markCompleteButtonIconUrl,
    hoverIcon: markCompleteButtonIconUrlHover,
  } = getMarkCompleteButtonIcons({
    isComplete,
    isDisabled: isDisabled(),
    isEducatorPractice: isEducatorPractice(),
    // TODO:
    educatorPracticeStatus: periodProgress,
  })

  function isDisabled() {
    // Schoolwide Activities can only be completed by admins
    return activityType === AUDIENCE_SCHOOL_LEADER && !isAdmin(userContext)
  }

  function isEducatorPractice() {
    return activityType === AUDIENCE_EDUCATOR
  }

  function shouldShowCelebrationIcon() {
    if (isComplete) {
      // NOTE: if periodProgress is somehow unset, just always show the icon
      if (isEducatorPractice() && periodProgress) {
        return (
          periodProgress === PERIOD_PROGRESS.ALL ||
          periodProgress === PERIOD_PROGRESS.MOST
        )
      }
      return true
    }
    return false
  }

  async function handleMarkCompleteButtonClicked() {
    if (isDisabled()) {
      return
    }

    if (activityType === AUDIENCE_EDUCATOR) {
      // defer the responsibility of sending the LMS attempt request to the modal
      setEducatorProgressModalOpen(true)
    } else {
      if (isComplete) {
        return
      }
      if (!registrationId || !leafNodeId) {
        console.error(
          'registrationId and leafNodeId are required to mark complete',
        )
        return
      }

      await onMarkComplete({
        registrationId,
        leafNodeId,
        progress: 1,
        userContextB64: btoa(JSON.stringify(userContext)),
      })
      fetchOrCreateInstructorProgramFlex(
        userContext.hsActiveSiteId,
        userContext,
      )
    }
  }

  async function handleEducatorProgressModalSubmit(progress) {
    setEducatorProgressModalOpen(false)
    if (progress === periodProgress) {
      // this means the user saved progress again with the same value LMS already supplied
      // if this happens, skip making redundant requests to the service
      return
    }
    if (!registrationId || !leafNodeId || !progress) {
      console.error(
        'registrationId, leafNodeId, and progress are required to mark an educator practice complete',
      )
      return
    }

    await onMarkComplete({
      registrationId,
      leafNodeId,
      periodProgress: progress,
      userContextB64: btoa(JSON.stringify(userContext)),
    })
    fetchOrCreateInstructorProgramFlex(userContext.hsActiveSiteId, userContext)
  }

  // handle the LMS data fetched:
  useEffect(() => {
    if (highSchoolInstructorData) {
      const dataForPractice = getInstructorDataForLessonActivityType(
        highSchoolInstructorData,
        activityType,
      )
      if (!dataForPractice) {
        console.warn(
          `Unsupported activity type ${activityType}. No matching instructorData found.`,
        )
        return
      }
      setRegistrationId(dataForPractice.registration_id)
      const {
        isComplete,
        date,
        periodProgress: lmsPeriodProgress,
      } = checkIfLessonIsComplete(dataForPractice?.lesson_nodes, courseNodeId)
      setIsComplete(isComplete)
      setCompletionDate(formatDate(date))
      if (lmsPeriodProgress) {
        setPeriodProgress(lmsPeriodProgress)
      }
    }
  }, [highSchoolInstructorData])

  // handle the course-manager data fetched:
  useEffect(() => {
    if (!courseManager?.courseTrees?.highschool) {
      return
    }

    const courseTreeNode = findNode(
      courseManager.courseTrees.highschool,
      courseNodeId,
    )
    if (!courseTreeNode) {
      console.warn(
        `courseNodeId ${courseNodeId} not found in courseManager.courseTrees.highschool`,
      )
      return
    }
    setLeafNodeId(courseTreeNode.leafNodeId)
  }, [courseManager])

  // handle the sites/me data fetched combined with userContext:
  useEffect(() => {
    if (activeSites.length && userContext?.hsActiveSiteId) {
      for (const site of activeSites) {
        if (site.id === userContext.hsActiveSiteId) {
          setSchoolName(formatSchoolName(site.name))
          break
        }
      }
    }
  }, [activeSites, userContext])

  return (
    <>
      <Component
        canReupdateProgress={isEducatorPractice()}
        completionCommentary={getCompletionCommentary({
          markCompleteContent,
          schoolName,
          completionDate,
          isComplete,
          activityType,
        })}
        completionStatusDetail={
          isEducatorPractice()
            ? getEducatorProgressText({ markCompleteContent, periodProgress })
                ?.progressCompletionText || ''
            : ''
        }
        handleMarkComplete={handleMarkCompleteButtonClicked}
        // TODO: Pendo surveys are not ready yet, we should re-enable the below logic when they are:
        hasSurvey={false}
        // hasSurvey={
        //   isComplete && (markCompleteContent?.surveyButtonText || false)
        // }
        isComplete={isComplete}
        isDisabled={isDisabled()}
        markCompleteButtonIconUrl={markCompleteButtonIconUrl}
        markCompleteButtonIconUrlHover={markCompleteButtonIconUrlHover}
        markCompleteButtonText={getMarkCompleteButtonText({
          markCompleteContent,
          isComplete,
          isAdmin: isAdmin(userContext),
          periodProgress,
        })}
        shouldShowCelebrationIcon={shouldShowCelebrationIcon()}
        surveyButtonText={markCompleteContent?.surveyButtonText}
        surveyCommentary={markCompleteContent?.surveyText}
      />
      {isEducatorPractice() && (
        <HsEducatorCompleteModal
          {...getEducatorCompleteModalContent(markCompleteContent)}
          handleClose={() => setEducatorProgressModalOpen(false)}
          initialValue={periodProgress}
          practiceName={practiceName}
          show={educatorProgressModalOpen}
          submitProgress={handleEducatorProgressModalSubmit}
        />
      )}
    </>
  )
}

const mapStateToProps = state => ({
  activeSites: licenseSelectors.selectActiveSites(state),
  highSchoolInstructorData: lmsSelectors.selectHsInstructorData(state),
  userContext: contextSelectors.selectProgramContext(state),
})

const mapDispatchToProps = {
  fetchOrCreateInstructorProgramFlex:
    lmsOperations.fetchOrCreateInstructorProgramFlex,
  onMarkComplete: lmsOperations.makeAttemptV2,
}

const courseManagerOptions = {
  // this component is only ever used in High School
  programResolver: () => programs.highSchool,
}

HsIndividualResourceMarkCompleteContainer.propTypes = {
  activeSites: PropTypes.array,
  activityType: PropTypes.string,
  courseManager: CourseManagerType,
  courseNodeId: PropTypes.string,
  fetchOrCreateInstructorProgramFlex: PropTypes.func,
  highSchoolInstructorData: PropTypes.object,
  markCompleteContent: PropTypes.object,
  onMarkComplete: PropTypes.func,
  practiceName: PropTypes.string,
  userContext: PropTypes.object,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  CourseManagerGetter(
    HsIndividualResourceMarkCompleteContainer,
    courseManagerOptions,
  ),
)
