import {
  AcademicSuccessIcon,
  AppleAndBooksIcon,
  EditIcon,
  IconRocketship,
  IconHsTrees,
  IconTrees,
} from 'secondstep-components'

export default {
  hsTrees: IconHsTrees,
  rocketShip: IconRocketship,
  rocket: IconRocketship,
  trees: IconTrees,

  // TODO: These are values available in Contentful, but not exported from secondstep-components
  'grad cap': AcademicSuccessIcon,
  handshake: AppleAndBooksIcon,
  paper: EditIcon,
}
