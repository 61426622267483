import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AnchoredHeaderBodyCopyWrapper } from './component.styles'
import HeaderBodyCopy from '../HeaderBodyCopy'

class AnchoredHeaderBodyCopy extends Component {
  static propTypes = {
    bodyCopy: PropTypes.string,
    currentHash: PropTypes.string,
    h2Text: PropTypes.string,
    h3Text: PropTypes.string,
    hash: PropTypes.string,
    id: PropTypes.string,
  }

  componentDidMount() {
    this.scrollToSelf()
  }

  componentDidUpdate() {
    this.scrollToSelf()
  }

  ref = React.createRef()

  scrollToSelf() {
    const { currentHash, hash } = this.props

    if (currentHash !== hash) {
      return
    }
    if (!this.ref) {
      return
    }
    this.ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  stripHashTag = hash => {
    const id = hash.replace('#', '')
    return id
  }

  render() {
    const { h2Text, h3Text, bodyCopy, id, hash } = this.props
    const hashId = this.stripHashTag(hash)

    return (
      <AnchoredHeaderBodyCopyWrapper id={hashId} ref={this.ref}>
        <HeaderBodyCopy
          bodyCopy={bodyCopy}
          h2Text={h2Text}
          h3Text={h3Text}
          id={id}
        />
      </AnchoredHeaderBodyCopyWrapper>
    )
  }
}

export default AnchoredHeaderBodyCopy
