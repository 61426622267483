import React from 'react'
import PropTypes from 'prop-types'
import { HeaderFive, NotificationBellIcon } from 'secondstep-components'
import {
  DISMISS_BUTTON_TEXT,
  SETTING_BUTTON_TEXT,
  BODY_TEXT,
  HEADER_TITLE,
} from './constants'
import { multiSiteTemplate, singleSiteTemplate } from './data.js'
import {
  ButtonsWrapper,
  DescriptionText,
  DismissButton,
  NotificationWrapper,
  SettingsButton,
  StatusText,
  TextWrapper,
  TopWrapper,
} from './component.styles'
import MsBHUMultiSiteModal from 'components/MsBHUMultiSiteModal'
import MsBHUSingleSiteModal from 'components/MsBHUSingleSiteModal'

const MspBullyingUnitNotification = ({
  handleCloseModal,
  handleDismissNotification,
  handleOnSave,
  handleOpenModal,
  hasMultipleSites,
  isModalOpen,
  mspSiteContexts,
  sitesWithBullyingUnitOnCount,
}) => {
  return (
    <NotificationWrapper data-testid="bullying-unit-notification">
      <TopWrapper>
        <TextWrapper>
          <NotificationBellIcon />
          <HeaderFive>{HEADER_TITLE}</HeaderFive>
        </TextWrapper>
        <DescriptionText>{BODY_TEXT}</DescriptionText>
        <StatusText>
          {hasMultipleSites
            ? multiSiteTemplate(
                sitesWithBullyingUnitOnCount,
                mspSiteContexts.length,
              )
            : singleSiteTemplate(sitesWithBullyingUnitOnCount > 0)}
        </StatusText>
      </TopWrapper>
      <ButtonsWrapper>
        <DismissButton onClick={handleDismissNotification}>
          {DISMISS_BUTTON_TEXT}
        </DismissButton>
        <SettingsButton onClick={handleOpenModal}>
          {SETTING_BUTTON_TEXT}
        </SettingsButton>
      </ButtonsWrapper>
      {isModalOpen && hasMultipleSites && (
        <MsBHUMultiSiteModal
          onModalCancel={handleCloseModal}
          onModalSave={handleOnSave}
          rawSitesData={mspSiteContexts}
        />
      )}
      {isModalOpen && !hasMultipleSites && (
        <MsBHUSingleSiteModal
          onModalCancel={handleCloseModal}
          onModalSave={handleOnSave}
          site={mspSiteContexts[0]}
        />
      )}
    </NotificationWrapper>
  )
}

MspBullyingUnitNotification.propTypes = {
  handleCloseModal: PropTypes.func,
  handleDismissNotification: PropTypes.func,
  handleOnSave: PropTypes.func,
  handleOpenModal: PropTypes.func,
  hasMultipleSites: PropTypes.bool,
  isModalOpen: PropTypes.bool,
  mspSiteContexts: PropTypes.array,
  sitesWithBullyingUnitOnCount: PropTypes.number,
}

export default MspBullyingUnitNotification
