import { themeGet } from 'styled-system'
import styled from 'styled-components'
import { HeaderTwo, HeaderSix } from 'secondstep-components'

export const MediaGroupingWrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.isArticleList ? 'column' : 'row')};
  border-radius: 12px;
  padding-right: 0.875rem;
  padding-left: 0.875rem;
  padding-top: 0.5rem;
  padding-bottom: ${themeGet('spacing.blue16_1rem')};
  background-color: ${themeGet('colors.backgroundLightGray')};
  margin-bottom: ${themeGet('spacing.turquoise20_1_25rem')};

  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
  }
`

export const MediaTitle = styled(HeaderTwo)``

export const MediaTypeTitle = styled(HeaderSix)`
  color: ${props =>
    props.isLink
      ? themeGet('colors.links.primary')
      : themeGet('colors.darkGray')};
  font-size: 1rem;
  cursor: ${props => (props.isLink ? 'pointer' : 'auto')};
  :hover {
    color: ${props =>
      props.isLink
        ? themeGet('colors.links.primaryHover')
        : themeGet('colors.darkGray')};
  }
`

export const MediaGroupWrapper = styled.div`
  flex-direction: column;
  flex-basis: 33%;
  padding-left: 0.875rem;
  padding-right: 0.875rem;
  padding-top: 0.313rem;

  ${themeGet('breakpoints.tablet')} {
    margin-bottom: ${themeGet('spacing.blue16_1rem')};
  }
`

export const TextLinkWrapper = styled.div`
  padding-top: 0.375rem;
`
