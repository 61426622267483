import { push } from 'connected-react-router'
import { connect } from 'react-redux'
import { operations as lmsManager } from 'store/lmsManager'
import { selectors as licenseSelectors } from 'store/licenseManager'
import { PRODUCTTEACHPAGE } from './constants'
import CreateCourse from './component.jsx'
import ContentNamespaceGetter from 'layers/content/Hocs/ContentNamespaceGetter'
import { forwardRefWrapped } from 'utils/forwardRefWrapped'

const mapStateToProps = state => ({
  activeDigitalLicenses: licenseSelectors.selectActiveDigitalLicenses(state),
  lms: state.lmsManager,
  pathName: state.router.location.pathname,
  userAccess: state.userAccessManager.access,
  userContext: state.userContextManager,
  userProfile: state.userProfileManager,
})

/* istanbul ignore next */
const mapDispatchToProps = dispatch => ({
  createCourseInstance: async (courseInstance, programLmsKey) =>
    dispatch(lmsManager.createCourseInstance(courseInstance, programLmsKey)),
  createCourseInstanceV2: async (courseInstance, programLmsKey, userContext) =>
    dispatch(
      lmsManager.createCourseInstanceV2(
        courseInstance,
        programLmsKey,
        userContext,
      ),
    ),
  deletingCourseCreatedStatus: async () =>
    dispatch(lmsManager.deletingCourseCreatedStatus()),
  deleteCourseInstance: async courseInstance =>
    dispatch(lmsManager.deleteCourseInstance(courseInstance)),
  fetchInstructorData: async () => dispatch(lmsManager.fetchInstructorData()),
  fetchInstructorDataV2: async reqBody =>
    dispatch(lmsManager.fetchInstructorDataV2(reqBody)),
  updateCourseInstance: async updatedCourseInstance =>
    dispatch(lmsManager.updateCourseInstance(updatedCourseInstance)),
  redirectToMyClasses: (url = PRODUCTTEACHPAGE) => dispatch(push(url)),
})

export const mapper = (entry, _, ownProps) => {
  const { contentfulCourses: ownPropsCourses = [] } = ownProps || {}
  const { productSubsections: fetchedCourses = [] } = entry || {}

  const contentfulCourses =
    ownPropsCourses.length > 0 ? ownPropsCourses : fetchedCourses
  return { contentfulCourses }
}

const options = { include: 2, mapper, spread: true }

export default forwardRefWrapped(
  ContentNamespaceGetter(
    CreateCourse,
    options,
    'productSection',
    connect(mapStateToProps, mapDispatchToProps),
  ),
)
