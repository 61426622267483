import styled from 'styled-components'
import ReactMarkdown from 'react-markdown'

import { themeGet } from 'styled-system'
import {
  ContainedButton,
  FormInputSelect,
  HeaderTwo,
  WarningIcon,
} from 'secondstep-components'
import WarningIconB from './assets/warning_icon.svg'

export const AlertSection = styled.div`
  align-items: center;
  display: flex;
  background-color: #fffccb;
  padding: 1.25rem;
  margin-top: 1.25rem;
`

export const AlertText = styled.p`
  font-family: ${themeGet('font.molde.regular')};
`

export const ApiErrorSection = styled.div`
  background-color: ${themeGet('colors.highschool.pink6')};
  border-radius: 0.5rem;
  color: ${themeGet('colors.deleteRed')};
  margin-top: 1.25rem;
  padding: 0.25rem 0.75rem;
`

export const Button = styled(ContainedButton)`
  background-color: ${themeGet('colors.highschool.pink1')};
  flex-basis: 25%;
`

export const DefaultValue = styled.span`
  color: ${themeGet('colors.inputPlaceholder')};
`

export const ErrorHandlingText = styled.div`
  display: flex;
  align-items: center;
  color: ${themeGet('colors.deleteRed')};
  font-family: ${themeGet('font.molde.regular')};
  font-size: 0.75rem;
  margin-top: -5px;
  margin-bottom: 3px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.25rem;
`
export const FooterText = styled.div`
  flex-basis: 30%;
  font-family: ${themeGet('font.molde.regular')};
  white-space: pre;
`

export const StyledMarkdown = styled(ReactMarkdown)``

export const StyledFormInputSelect = styled(FormInputSelect)`
  margin-top: 1.25rem;
`

export const Subtitle = styled.h6`
  font-family: ${themeGet('font.molde.regular')};
  margin-bottom: 1.25rem;
`

export const Title = styled(HeaderTwo)`
  font-family: ${themeGet('font.komet')};
  font-weight: 800;
  color: ${themeGet('colors.highschool.green2')};
`

export const WarningIconStyled = styled.img.attrs({
  src: WarningIconB,
})`
  height: 1.5625rem;
  margin-right: 0.625rem;
`

export const StyledErrorIcon = styled(WarningIcon).attrs(({ theme }) => ({
  color: theme?.colors?.deleteRed,
}))`
  height: 0.75rem;
  width: 0.75rem;
  margin-top: -0.125rem;
  margin-right: 0.3125rem;
`
