import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.125rem;

  ${themeGet('breakpoints.mobile')} {
    min-width: 55px; //40svg + 15 margin
    margin-top: -50px;

    svg {
      width: 40px;
      height: 40px;
    }
  }
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-right: ${themeGet('spacing.turquoise20_1_25rem')};
`

export const Title = styled.span`
  color: ${themeGet('colors.sela.gray1')};
  font-family: 'LufgaCFC-Medium';
  font-size: 20px;
  letter-spacing: 0;
  line-height: 25px;
`

export const SubTitle = styled.span`
  color: ${themeGet('colors.sela.gray1')};
  font-family: 'Molde-Regular';
  font-size: 18px;
  letter-spacing: 0;
  line-height: 26px;

  ${themeGet('breakpoints.mobile')} {
    margin-left: -55px;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2.125rem 0 1.9375rem 1.9375rem;
  margin: 0 1.25rem;
  ${({ isDone }) => isDone && `padding-left: 0;`}
  ${themeGet('breakpoints.tablet')} {
    padding: 1.4375rem 0rem 1.4375rem 0.625rem;
    ${({ isDone }) => isDone && `padding-left: 0;`}

    flex-direction: column;
    align-items: start;

    h5 {
      font-size: 1.125rem;
    }
  }
`

export const ContentIconWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const ButtonViewCertificate = styled.button`
  border-radius: 1.25rem;
  border: none;
  background-color: ${themeGet('colors.progressBar.primary')};
  color: ${themeGet('colors.white')};
  font-family: 'LufgaCFC-SemiBold';
  font-size: 16px;
  letter-spacing: 0.5px;
  line-height: 24px;
  text-align: center;
  height: 40px;
  width: 100px;
  margin-left: auto;
  cursor: pointer;
  &:hover {
    background: ${themeGet('colors.buttons.primaryBackgroundHover')};
  }

  ${themeGet('breakpoints.tablet')} {
    margin: ${themeGet('spacing.turquoise20_1_25rem')} auto 0.625rem 4.6875rem;
  }

  ${themeGet('breakpoints.mobile')} {
    margin-left: 0rem;
  }
`

export const Certificate = styled.div`
  display: flex;
  align-items: center;
  padding: 0;
  border-radius: 0.625rem;
  border: none;
  background: ${({ isComplete }) =>
    isComplete
      ? themeGet('colors.backgrounds.primary')
      : themeGet('colors.modules.primaryBackground')};
  margin: ${themeGet('spacing.red40_2_5rem')}
    ${themeGet('spacing.turquoise20_1_25rem')} 0rem
    ${themeGet('spacing.turquoise20_1_25rem')};
`
