import React from 'react'
import { connect } from 'react-redux'
import withSiteContext from 'layers/content/v2/Hocs/withSiteContext'
import { RouteContentGetter } from 'layers/content/v2/Hocs/RouteContentGetter'
import { RouteContentProductAppMap } from 'utils/productMaps'
import { getRouteServiceProductPath } from 'utils/productHelpers'
import withApiError from 'layers/errorHandling/apiError'
import { navigationEvent } from 'layers/navigation/store/operations'
import { selectors as lmsSelectors } from 'store/lmsManager'

import HighSchoolTrainingLandingComponent from './component'

const HighSchoolTrainingLandingContainer = props => {
  const { match, routeContent, instructorData } = props || {}

  const { content = {}, loading } = routeContent
  const readyToLoad = !!content && !loading && !!instructorData

  return (
    readyToLoad && (
      <HighSchoolTrainingLandingComponent
        content={content}
        instructorData={instructorData}
        match={match}
      />
    )
  )
}

const options = {
  app: RouteContentProductAppMap.learnHsProduct,
  routeResolver: getRouteServiceProductPath,
}

const mapDispatchToProps = {
  navigationEvent,
}

const mapStateToProps = state => ({
  instructorData: lmsSelectors.selectHsInstructorData(state),
})

const HydratedComponent = connect(
  mapStateToProps,
  mapDispatchToProps,
)(HighSchoolTrainingLandingContainer)

export default RouteContentGetter(
  withSiteContext(withApiError(HydratedComponent), { useGuard: true }),
  options,
)
