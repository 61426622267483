import React, { useState } from 'react'
import PropTypes from 'prop-types'
import MsBHUSingleSiteModal from './component'

export const MsBHUSingleSiteModalContainer = ({
  onModalCancel,
  onModalSave,
  site,
}) => {
  const [bhuUnitEnabled, setBhuUnitEnabled] = useState(
    site?.sitePreferences?.mspDigital?.bullyingHarassmentUnitEnabled,
  )

  const setEnabled = () => setBhuUnitEnabled(true)
  const setDisabled = () => setBhuUnitEnabled(false)

  const handleOnSave = () => {
    const sitePreferenceUpdate = {
      siteId: site.siteId,
      sitePreferences: {
        mspDigital: {
          bullyingHarassmentUnitEnabled: bhuUnitEnabled,
        },
      },
    }
    onModalSave([sitePreferenceUpdate])
  }

  return (
    <MsBHUSingleSiteModal
      bhuUnitEnabled={bhuUnitEnabled}
      handleOnSave={() => handleOnSave()}
      onModalCancel={onModalCancel}
      setDisabled={setDisabled}
      setEnabled={setEnabled}
      siteName={site.name}
    />
  )
}

MsBHUSingleSiteModalContainer.propTypes = {
  onModalCancel: PropTypes.func,
  onModalSave: PropTypes.func,
  site: PropTypes.shape({
    name: PropTypes.string,
    siteId: PropTypes.number,
    sitePreferences: PropTypes.shape({
      mspDigital: PropTypes.shape({
        bullyingHarassmentUnitEnabled: PropTypes.bool,
      }),
    }),
  }).isRequired,
}

export default MsBHUSingleSiteModalContainer
