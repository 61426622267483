import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Select } from 'grommet'

export const StyledDropdown = styled(Select)`
  display: flex;
  align-self: center;
  background: transparent;
  border: none;
  border-radius: 0.25rem;
  color: ${themeGet('callouts.bodyTextColor')};
  font-weight: unset;
  font-family: Molde-Medium;
  padding: 0;
  & [aria-expanded='true'] {
    svg {
      transform: rotate(180deg);
    }
  }
  svg {
    fill: ${themeGet('colors.themeSecondary')};
    stroke: ${themeGet('colors.themeSecondary')};
  }
`

export const StyledDropdownOption = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${themeGet('callouts.bodyTextColor')};
  font-size: 1rem;
  padding-top: 0.438rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-bottom: 0.438rem;
  align-items: center;
  background-color: ${themeGet('colors.white')};

  &:hover,
  &:focus,
  &:active,
  &:link,
  &:visited {
    background-color: ${themeGet('colors.hsSecondaryLightGreen')};
    svg {
      path {
        fill: ${themeGet('callouts.bodyTextColor')};
      }
    }
  }

  svg {
    margin: 0;
    padding: 0;
    path {
      fill: ${themeGet('callouts.bodyTextColor')};
    }
  }
`
export const StyledDropdownOptionText = styled.div`
  padding: 0.75rem;
`
