import cloneDeep from 'lodash/cloneDeep'
import isEmpty from 'lodash/isEmpty'
import { createSelector } from 'reselect'

/**
 * @param {array} activeDigitalLicenses - Collection of active licenses (mapped to v2 shape)
 * @param {object} userAccess - User access object
 * @param {string} filterType - 'K5P' | 'MSP'
 * @returns {{
 *  ...license,
 *  grades: string[] // A list of grades the user has access to for the license,
 *  licenseId: string // The license id as a string
 *  product: string | undefined // The product name
 *  siteName: string | undefined // The site name
 * }[]} - A sorted list of filtered licenses with grades
 */
const getLicensesWithGrades = (
  activeDigitalLicenses,
  userAccess,
  filterType,
) => {
  if (isEmpty(activeDigitalLicenses)) {
    return []
  }
  if (!userAccess || isEmpty(userAccess)) {
    return []
  }
  if (!['K5P', 'MSP'].includes(filterType)) {
    console.error(`Unexpected filter type: ${filterType}`)
    return []
  }

  const clonedActiveDigitalLicenses = cloneDeep(activeDigitalLicenses)
  const licensesWithGrades = clonedActiveDigitalLicenses
    .map(license => {
      const { licenseId, programCodes, type: skuCode } = license

      // Filter out any licenses without a license id (likely to only happen with test accounts)
      if (!licenseId || typeof licenseId !== 'number') {
        console.error('Unexpected license shape - missing or malformed id')
        return
      }
      if (!programCodes || programCodes.length === 0) {
        console.error('Unexpected license shape - missing programCodes')
        return
      }
      if (!skuCode) {
        console.error('Unexpected license shape - missing skuCode')
        return
      }
      // filter out family licenses
      if (skuCode.endsWith('F')) {
        return
      }

      const isLicenseMs =
        filterType === 'MSP' && programCodes.includes(filterType)
      const isLicenseElem =
        filterType === 'K5P' && programCodes.includes(filterType)

      if (isLicenseMs) {
        const { mspDigital: mspDigitalAccess } = userAccess || {}
        return buildLicenseWithGrades(mspDigitalAccess, license)
      }
      if (isLicenseElem) {
        const { k5SelDigital: elemDigitalAccess } = userAccess || {}
        return buildLicenseWithGrades(elemDigitalAccess, license)
      }
    })
    .filter(x => x)
    .sort((licenseA, licenseB) =>
      licenseA.siteName > licenseB.siteName ? 1 : -1,
    )

  return licensesWithGrades
}

const buildLicenseWithGrades = (gradeAccess, license) => {
  const { product, siteName, licenseId } = license
  const licenseIdString = licenseId.toString()
  const grades = getAccessibleGrades(gradeAccess, licenseIdString)
  const licenseObj = {
    ...license,
    grades,
    licenseId: licenseIdString,
    product: product || undefined,
    siteName: siteName || undefined,
  }
  return licenseObj
}

const getAccessibleGrades = (gradesAccess, licenseId) => {
  if (!gradesAccess || !licenseId) {
    return []
  }

  const gradeNumbers = Object.keys(gradesAccess)
  const accessibleGrades =
    gradeNumbers.filter(gradeNumber => {
      const canAccess = canAccessGrade(gradesAccess, gradeNumber, licenseId)

      if (canAccess) {
        return gradeNumber
      }
    }) || []

  return accessibleGrades
}

const canAccessGrade = (gradesAccess = [], gradeNumber, licenseId) => {
  const gradeAccess = gradesAccess[gradeNumber]
  const { teacher } = gradeAccess || {}
  const { hasRole, licenseIds } = teacher || {} // Always use teacher permissions
  const licenseHasAccess = Object.values(licenseIds).includes(licenseId)
  const canAccess = hasRole && licenseHasAccess

  return canAccess
}

const findAdminLicenseIds = accessObj => {
  const filterKeys = ['k5SelDigital', 'mspDigital', 'highSchool']
  const adminLicenseIds = filterKeys
    .map(key => {
      if (!accessObj[key]) return

      const product = accessObj[key]
      const licenseIds = Object.values(product)
        .map(({ admin, family }) => {
          if (key !== 'highSchool' && admin?.hasRole) {
            return admin.licenseIds
          } else if (
            key === 'highSchool' &&
            (admin?.hasRole || family?.hasRole)
          ) {
            return [...(family?.licenseIds || []), ...(admin?.licenseIds || [])]
          }
        })
        .filter(item => item)
        .flat()

      return licenseIds
    })
    .filter(item => item)
    .flat()

  return [...new Set(adminLicenseIds)]
}

/**
 * @deprecated To be removed via LEARN-16518
 */
const filterAdminK8Licenses = (accessObj, activeDigitalLicenses) => {
  if (activeDigitalLicenses?.length === 0) return null
  const k8Types = ['K8P', 'MSP']
  const activeAdminLicenses = filterAdminLicenses(
    accessObj,
    activeDigitalLicenses,
  )
  const activeAdminK8Licenses = activeAdminLicenses?.filter(l =>
    k8Types.includes(l.type),
  )
  return activeAdminK8Licenses
}

/**
 * @deprecated To be removed via LEARN-16518
 */
const filterAdminLicenses = (accessObj, activeDigitalLicenses) => {
  if (activeDigitalLicenses?.length === 0) return null
  const adminLicenseIds = findAdminLicenseIds(accessObj)
  const filteredLicenses = activeDigitalLicenses
    ?.filter(({ licenseId }) =>
      adminLicenseIds.find(
        adminLicenseId => adminLicenseId === licenseId?.toString(),
      ),
    )
    .sort((a, b) => a['siteName']?.localeCompare(b['siteName']))

  return filteredLicenses
}

// This maps v3 licenses to v2 for pages that are managed by long-lived feature flags that we couldn't put behind v3 feature flags
const selectActiveDigitalLicenses = createSelector(
  [
    state => state.licenseManager.activeDigitalLicenses,
    state => state.licenseManager.sites,
    state => state.licenseManager.isFetchingActiveDigitalLicenses,
    state => state.licenseManager.isFetchingUserSites,
    state => state.licenseManager.error,
  ],
  (activeLicenses, userSites, isFetchingLicenses, isFetchingSites, error) => {
    if (!activeLicenses?.length) return activeLicenses

    const shouldNotFetch = !!error || isFetchingSites || isFetchingLicenses
    const isNotHydrated = !userSites || !activeLicenses
    if (shouldNotFetch) {
      return {} // this return value can be checked in components that use this selector to prevent refetching if error or fetching in progress
    }
    if (isNotHydrated) {
      return null
    }

    const activeDigitalLicenses = activeLicenses?.map(
      ({ id, product, site, expirationDate }) => {
        // claims may be empty if the site is not returned by /sites/me
        const { claims = [] } =
          userSites?.find(({ id }) => id === site.id) || {}
        return {
          claims,
          licenseId: id,
          product: product.description,
          productType: product.type,
          programCodes: product.programCodes,
          siteId: site.id,
          siteName: site.name,
          type: product.skuCode,
          expirationDate,
          sku: product.sku?.skuValue,
        }
      },
    )
    return activeDigitalLicenses
  },
)

const selectActiveSites = createSelector(
  [
    state => state.licenseManager?.sites,
    state => state.licenseManager.isFetchingUserSites,
    state => state.licenseManager.error,
  ],
  (sites, isFetchingUserSites, error) => {
    const shouldNotFetch = !!error || isFetchingUserSites
    const isNotHydrated = !sites

    if (shouldNotFetch) {
      return {} // this return value can be checked in components that use this selector to prevent refetching if error or fetching in progress
    }
    if (isNotHydrated) {
      return null
    }
    const activeSites = sites
      .filter(site => site?.licenses?.some(({ isExpired }) => !isExpired))
      .sort((a, b) => a['name']?.localeCompare(b['name']))

    return activeSites
  },
)

const selectActiveAdminSites = createSelector(
  [selectActiveSites, state => state.userAccessManager?.access],
  (activeSites, userAccess) => {
    const isNotSettled = !Array.isArray(activeSites) || !userAccess
    if (isNotSettled) {
      return activeSites
    }

    const accessibleAdminLicenseIds = findAdminLicenseIds(userAccess)

    const activeAdminSites = activeSites.filter(site =>
      site?.licenses.some(({ id }) =>
        accessibleAdminLicenseIds.includes(id.toString()),
      ),
    )

    return activeAdminSites
  },
)

const selectActiveAdminHsSites = createSelector(
  [selectActiveAdminSites],
  activeAdminSites => {
    const isNotSettled = !Array.isArray(activeAdminSites)
    if (isNotSettled) {
      return activeAdminSites
    }
    const hsTypes = ['HSP', 'K12P', 'HSPF']

    const hsSites = activeAdminSites.filter(site =>
      site?.licenses?.some(({ product }) =>
        product?.programCodes?.some(code => hsTypes.includes(code)),
      ),
    )
    return hsSites
  },
)

const selectActiveAdminK8Sites = createSelector(
  [selectActiveAdminSites],
  activeAdminSites => {
    const isNotSettled = !Array.isArray(activeAdminSites)
    if (isNotSettled) {
      return activeAdminSites
    }
    const k8Types = ['K5P', 'K8P', 'MSP']

    const k8Sites = activeAdminSites.filter(site =>
      site?.licenses?.some(({ product }) =>
        product?.programCodes?.some(code => k8Types.includes(code)),
      ),
    )
    return k8Sites
  },
)

const selectActiveAdminSitePreferences = createSelector(
  [selectActiveAdminSites],
  activeSites => {
    const isNotSettled = !Array.isArray(activeSites)
    if (isNotSettled) {
      return activeSites
    }
    const sitePreferences = activeSites.reduce((map, site) => {
      map[site.id] = site.sitePreferences
      return map
    }, {})
    return sitePreferences
  },
)

export default {
  filterAdminK8Licenses,
  filterAdminLicenses,
  findAdminLicenseIds,
  getLicensesWithGrades,
  selectActiveAdminHsSites,
  selectActiveAdminK8Sites,
  selectActiveAdminSites,
  selectActiveDigitalLicenses,
  selectActiveSites,
  selectActiveAdminSitePreferences,
}
