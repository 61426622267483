import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import {
  BodyCopy,
  ContainedButton,
  HeaderFour,
  IconCertificate,
} from 'secondstep-components'
import variables from 'themes/variables'

const breakpoints = {
  small: '525px',
}

export const GrayBackground = styled.div`
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
  margin: 0 0 1.4375rem;
  background-color: ${themeGet('colors.blueLight')};
`

export const CertificatePanelWrapper = styled(Box)`
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.5625rem 3rem 1.5rem;
  max-width: ${variables.containerMaxWidth};

  ${HeaderFour} {
    color: ${themeGet('colors.darkerDarkBlue')};
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 0.375rem;

    ${themeGet('breakpoints.mobile')} {
      margin-bottom: 0.5rem;
    }
  }

  ${BodyCopy} {
    color: ${themeGet('colors.darkGray')};
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  @media screen and (max-width: ${breakpoints.small}) {
    flex-direction: column;

    ${ContainedButton} {
      margin-right: 1.5rem;
    }
  }

  ${themeGet('breakpoints.mobile')} {
    padding: 1.5rem 1rem 1.75rem 2.5rem;
    svg {
      margin-top: -1px;
    }
  }
`

export const ContentWrapper = styled(Box)`
  align-items: center;
  flex-direction: row;

  @media screen and (max-width: ${breakpoints.small}) {
    align-items: flex-start;
  }
`

export const IconCertificateStyled = styled(IconCertificate).attrs(
  ({ theme }) => ({
    colorBorder: theme.colors.darkBlue,
    colorMedal: theme.colors.yellow,
    colorRibbon: theme.colors.orange,
  }),
)`
  align-self: flex-start;
`

export const TextWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  margin: 0 1rem 0.75rem;

  @media screen and (max-width: ${breakpoints.small}) {
    margin: 0 0 1.75rem 0.75rem;
  }

  ${themeGet('breakpoints.mobile')} {
    margin-bottom: 1.3125rem;
  }
`
