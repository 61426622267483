import { connect } from 'react-redux'
import ModuleButton from './component'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import NavigationHelper from 'layers/navigation/navigationHelper'

const mapper = entry => {
  const { buttonLabel: label } = entry || {}
  return { label }
}
const options = {
  include: 3,
  mapper,
  spread: true,
  entryIdProp: true,
}

export default ContentEntryDataGetter(
  ModuleButton,
  options,
  connect(null, NavigationHelper.mapDispatchToProps),
)
