import React from 'react'
import PropTypes from 'prop-types'
import { MarkdownParagraph } from 'components/MarkdownComponent'
import LessonMaterialsList from 'components/Teach/LessonMaterialsList'
import { Wrapper, PrimaryColumn, SecondaryColumn } from './component.styles'
import { PREP_PDF_TITLE } from './constants'

const LessonGroup = ({ className, children, materials, pdfs }) => {
  const hasMaterials = pdfs || materials
  return (
    <Wrapper className={className}>
      <PrimaryColumn>{children}</PrimaryColumn>
      {hasMaterials && (
        <SecondaryColumn>
          {materials && <MarkdownParagraph>{materials}</MarkdownParagraph>}
          {pdfs && (
            <LessonMaterialsList materials={pdfs} title={PREP_PDF_TITLE} />
          )}
        </SecondaryColumn>
      )}
    </Wrapper>
  )
}

LessonGroup.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  materials: PropTypes.string,
  pdfs: PropTypes.arrayOf(PropTypes.object),
}

export default LessonGroup
