export const CONFIRM_ALERT_MESSAGE =
  'Changing the level will affect the pacing schedule. '
export const CONFIRM_MESSAGE =
  'You can edit pacing dates after the level is set.'
export const CONFIRM_TITLE = 'Confirm Level Change'
export const IMPLEMENTATION_PREFERENCES = [
  'implementationType',
  'implementationLevel',
  'studentActivities',
]
