import React from 'react'
import PropTypes from 'prop-types'
import { SpinnerAnimatedIcon } from 'secondstep-components'
import {
  LoadingContent,
  LoadingWrapper,
  StyledBodyCopy,
} from './component.styles'

const ReportsLoading = ({ background, className, text }) => {
  const loadingText = text || 'Loading...'
  return (
    <LoadingWrapper>
      <LoadingContent background={background} className={className}>
        <SpinnerAnimatedIcon />
        <StyledBodyCopy data-testid="loading-text">
          {loadingText}
        </StyledBodyCopy>
      </LoadingContent>
    </LoadingWrapper>
  )
}

ReportsLoading.propTypes = {
  background: PropTypes.bool,
  className: PropTypes.string,
  text: PropTypes.string,
}

export default ReportsLoading
