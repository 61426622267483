import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Data, DataSearch, DataTable } from 'grommet'

import { CheckIcon } from 'secondstep-components'

import {
  MODAL_TITLE,
  CURRENT_SCHOOL_LABEL,
  CHANGE_SCHOOL_LABEL,
  NO_SCHOOLS_FOUND,
  MODAL_TEXT,
} from './constants'

import {
  ModalTitle,
  NoResults,
  StyledContainer,
  StyledModal,
  StyledModalFooter,
  StyledParagraph,
  StyledPrimaryButton,
  StyledSecondaryButton,
} from './component.styles'

const SelectSiteModal = props => {
  const { activeSites, currentSite, handleClose, handleSiteChange, show } =
    props || {}

  const [newSite, setNewSite] = useState()

  const getResultsCount = () => {
    // Logic used to create a "No Schools found" result
    // the delay is to wait the user input X ui refresh
    const timerId = setTimeout(() => {
      setRecords(document.querySelectorAll('#table tr')?.length || 0)
    }, 100)

    return () => clearTimeout(timerId)
  }

  const [records, setRecords] = useState(activeSites?.length)

  const resetSiteBeforeClose = () => {
    setNewSite(currentSite?.siteId)
    handleClose()
  }

  return (
    activeSites && (
      <StyledModal onClose={resetSiteBeforeClose} show={show}>
        <ModalTitle>{MODAL_TITLE}</ModalTitle>
        <StyledContainer>
          <StyledParagraph>{CURRENT_SCHOOL_LABEL}</StyledParagraph>
          <StyledParagraph style={{ marginBottom: '0.75rem' }}>
            <strong>{currentSite.siteName}</strong>
          </StyledParagraph>
          <StyledParagraph>{CHANGE_SCHOOL_LABEL}</StyledParagraph>
          <Data data={activeSites}>
            <DataSearch
              id="search"
              onInput={evt => {
                // to allow the user continue typing after selecting a site.
                setNewSite({ id: null, name: evt?.target?.value })
                return getResultsCount()
              }}
              placeholder={'Enter School Name'}
              value={newSite?.name}
            />
            <DataTable
              columns={[
                {
                  property: 'name',
                  search: true,
                },
                {
                  property: 'select',
                  render: datum => datum.id === newSite?.id && <CheckIcon />,
                },
              ]}
              id="table"
              onClickRow={({ datum }) => {
                const newId = datum.id === newSite?.id ? null : datum.id
                setNewSite(activeSites?.find(s => s.id === newId))
              }}
              style={{ display: records > 1 ? 'block' : 'none' }}
            />
          </Data>
          {/* /GROMMET ADDS AN ENTRA ROW */}
          {records === 1 && <NoResults>{NO_SCHOOLS_FOUND}</NoResults>}
          <StyledParagraph style={{ marginTop: '1.25rem' }}>
            {MODAL_TEXT}
          </StyledParagraph>
        </StyledContainer>
        <StyledModalFooter>
          <StyledSecondaryButton onClick={resetSiteBeforeClose}>
            Cancel
          </StyledSecondaryButton>
          <StyledPrimaryButton
            disabled={!newSite?.id}
            onClick={() => handleSiteChange(newSite)}
          >
            Change
          </StyledPrimaryButton>
        </StyledModalFooter>
      </StyledModal>
    )
  )
}

SelectSiteModal.propTypes = {
  activeSites: PropTypes.array.isRequired,
  currentSite: PropTypes.shape({
    siteId: PropTypes.number,
    siteName: PropTypes.string,
  }),
  handleClose: PropTypes.func.isRequired,
  handleSiteChange: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
}

export default SelectSiteModal
