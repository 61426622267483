import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  BodyCopy,
  Tooltip,
  ProgressBarIndicator,
  TooltipContentWrapper,
} from 'secondstep-components'

export const Bullet = styled.span`
  display: inline-block;
  height: 0.8125rem;
  width: 0.8125rem;
  margin-right: 0.5rem;
  border-radius: 50%;
  background-color: ${themeGet('colors.yellow')};
  transform: translateY(0.0938rem);
`

export const ClassCount = styled.span`
  font-family: ${themeGet('fontFamilySemibold')};
`

export const ContentWrapper = styled.div.attrs(({ numOfClasses }) => ({
  opacity: numOfClasses === 0 ? '50%' : '100%',
  pointerEvents: numOfClasses === 0 ? 'none' : 'auto',
}))`
  a {
    color: ${themeGet('colors.darkGray')};
    font-family: ${themeGet('fontFamily')};
    font-size: 1rem;
    opacity: ${({ opacity }) => opacity};
    pointer-events: ${({ pointerEvents }) => pointerEvents};

    span:not(:nth-last-child(-n + 2)) {
      font-family: ${themeGet('fontFamilySemibold')};
    }

    &:hover {
      color: ${themeGet('colors.blueGray')};
    }
  }

  svg {
    color: ${themeGet('colors.blueGray')};
    margin-left: 0.25rem;
    transform: translateY(0.125rem);
  }

  &:hover {
    svg {
      transform: translate(0, 0.125rem);
    }
  }

  ${themeGet('breakpoints.mobile')} {
    a {
      line-height: 1.3125rem;
    }
  }
`

export const GradeDataWrapper = styled.div``

export const DetailsWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;
  }
`

export const StyledBodyCopy = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};
  display: ${({ display }) => display};
  font-family: ${themeGet('fontFamily')};
  font-size: 0.875rem;
  padding-top: 0.125rem;

  ${themeGet('breakpoints.mobile')} {
    padding-top: 0;
  }
`

export const StyledProgressBarIndicator = styled(ProgressBarIndicator)`
  [class*='Background-'] {
    [class*='IndicatorWrapper-'] {
      [class*='Indicator-'] {
        background-color: ${themeGet('colors.blue')};
      }
    }
  }
`

export const StyledTooltip = styled(Tooltip)`
  ${TooltipContentWrapper} {
    text-align: left;
    top: 1.625rem;
    left: 8rem;
    padding: 0.75rem 1rem;
    border-radius: 0.3125rem;
    line-height: 1rem;
    color: ${themeGet('colors.gray675')};
    ::after {
      content: ' ';
      width: 0.75rem;
      height: 0.75rem;
      position: absolute;
      bottom: 82px;
      left: 3%;
      background-color: white;
      transform: translateY(400%) rotate(45deg);
    }
  }

  ${themeGet('breakpoints.tablet')} {
    width: unset;
  }
`
