import styled from 'styled-components'
import { HeartIcon } from 'secondstep-components'
import { themeGet } from 'styled-system'
import {
  ReportsTable,
  TextHiddenOverflow,
  TableHeaderTooltip,
  TextContainer,
} from 'components/ReportsTable/component.styles'

export const ClassesTable = styled(ReportsTable).attrs(
  ({ numOfClasses, hasUnit5 }) => ({
    numOfClasses,
    hasUnit5,
  }),
)`
  border-radius: 12px;
  table-layout: fixed;

  [class*='AutoTableHeaderTh'] {
    padding: ${props => (props.numOfClasses === 1 ? '0.85rem 1.25rem' : '0')};

    [class*='AutoTableHeaderSort'] {
      padding: 1rem 1.125rem;
    }

    [class*='FlexContainer'] {
      left: 35px;
    }
  }

  [class*='AutoTableHeaderTh']:first-child {
    padding-left: ${props => (props.numOfClasses === 1 ? '2.25rem' : '0')};
    ${props => props.hasUnit5 && 'width: 22%'};
    [class*='AutoTableHeaderSort'] {
      padding-right: 1.125rem;
      padding-left: 2.25rem;
    }

    [class*='FlexContainer'] {
      left: 55px;
    }
  }

  // 'Lesson Progress' header:
  [class*='AutoTableHeaderTh']:nth-child(2) {
    ${props => (props.hasUnit5 ? 'width: 11%' : 'width: 22%')};
    padding-right: ${props => (props.hasUnit5 ? '5rem' : '2.5rem')};
    & div {
      ${props => props.hasUnit5 && 'overflow: visible'};
      text-overflow: ellipsis;
      white-space: normal;
      text-align: left;
      line-height: 1.3;
      font-size: 1em;
      ${props => props.hasUnit5 && 'margin-bottom:  0.375rem'};
    }
  }

  ${props =>
    props.hasUnit5 &&
    `[class*='AutoTableHeaderTh']:nth-child(3) {
      width: 11%;  
      padding-right: 5rem;
     
      & div {
        overflow: visible;
        text-overflow: ellipsis;
        white-space: normal;
        text-align: left;
        line-height: 1.3;
        font-size: 1em;
         margin-bottom: 0.375rem;
      }
    }`}

  ${props =>
    props.hasUnit5 &&
    `[class*='AutoTableHeaderTh']:nth-child(4) {
          width: 20%;
      }`} 

  [class*='AutoTableHeaderTh']:last-child {
    padding-right: 0;
    ${props => (props.hasUnit5 ? 'width: 14%' : 'width: 23%')};
    [class*='AutoTableHeaderSort'] {
      margin-right: 0;
      padding-right: 2.5rem;
    }
  }

  [class*='Row'] {
    cursor: pointer;
    &:hover,
    &:focus {
      background-color: ${themeGet('colors.focusBackground')};
      outline: 2px solid ${themeGet('colors.blueGray')};
      outline-style: auto;
    }
  }

  [class*='Cell'] {
    padding: 1.1875rem;
  }

  @media screen and (max-width: 1200px) {
    [class*='Cell']:nth-child(3) {
      padding-right: 1.25rem;
    }
  }

  ${themeGet('breakpoints.large')} {
    [class*='AutoTableHeaderTh']:nth-child(4) {
      width: ${props => (props.numOfClasses === 1 ? '20%' : '25%')};
    }
  }

  ${themeGet('breakpoints.medium')} {
    [class*='AutoTableHeaderTh']:first-child {
      width: ${props => (props.numOfClasses === 1 ? '20%' : '24%')};
    }

    [class*='AutoTableHeaderTh']:nth-child(2) {
      [class*='FlexContainer'] {
        width: 69%;
      }
    }

    [class*='AutoTableHeaderTh']:nth-child(3) {
      [class*='FlexContainer'] {
        width: 74%;
      }
    }

    [class*='AutoTableHeaderTh']:nth-child(4) {
      [class*='FlexContainer'] {
        width: 70%;
      }
    }
  }

  ${themeGet('breakpoints.tablet')} {
    [class*='AutoTableHeaderTh']:first-child {
      [class*='FlexContainer'] {
        width: ${props => (props.numOfClasses === 1 ? '80%' : '56%')};
      }
    }

    [class*='AutoTableHeaderTh']:nth-child(2) {
      [class*='FlexContainer'] {
        width: ${props => (props.numOfClasses === 1 ? '80%' : '57%')};
      }
    }

    [class*='AutoTableHeaderTh']:nth-child(3) {
      width: 24%;

      [class*='FlexContainer'] {
        width: ${props => (props.numOfClasses === 1 ? '80%' : '60%')};
      }
    }

    [class*='AutoTableHeaderTh']:nth-child(4) {
      [class*='FlexContainer'] {
        width: ${props => (props.numOfClasses === 1 ? '70%' : '42%')};
      }
    }
  }

  @media screen and (max-width: 730px) {
    [class*='AutoTableHeaderTh']:nth-child(3),
    [class*='AutoTableHeaderTh']:nth-child(4),
    [class*='Cell']:nth-child(3),
    [class*='Cell']:nth-child(4) {
      display: none;
    }

    [class*='AutoTableHeaderTh']:nth-child(2) {
      border-radius: 0 0.625rem 0 0;
    }
  }

  ${themeGet('breakpoints.mobileLarge')} {
    [class*='AutoTableHeaderTh']:first-child {
      width: auto;

      [class*='AutoTableHeaderSort'] {
        padding-left: 1.75rem;
      }

      [class*='FlexContainer'] {
        left: 47px;
      }
    }

    [class*='AutoTableHeaderTh']:nth-child(2) {
      width: auto;
    }

    [class*='AutoTableHeaderTh']:first-child {
      width: ${props => (props.numOfClasses === 1 ? '40%' : '47%')};
    }

    [class*='AutoTableHeaderTh']:nth-child(2) {
      padding-left: ${themeGet('spacing.yellow12_0_75rem')};

      [class*='FlexContainer'] {
        width: ${props => (props.numOfClasses === 1 ? '80%' : '65%')};
      }
    }

    [class*='Cell']:nth-child(2) {
      padding-left: ${props =>
        props.numOfClasses === 1 ? '0.625rem' : '1.875rem'};
    }

    [class*='Row']:last-child {
      [class*='Cell']:nth-child(2) {
        border-radius: 0 0 0.625rem 0;
      }
    }

    [class*='Cell'] {
      padding: 1.125rem 1rem;
    }
  }
`

export const StyledHeartIcon = styled(HeartIcon)`
  height: 15px;
`

export const SmallText = styled(TextHiddenOverflow)`
  font-size: 0.9125rem;
`

export const BigText = styled(TextHiddenOverflow)`
  margin-top: ${themeGet('spacing.green4_0_25rem')};
  font-family: ${themeGet('fontFamilySemibold')};
`

export const IconContainer = styled.div.attrs(({ visible }) => ({
  visibility: visible ? 'visible' : 'hidden',
}))`
  visibility: ${props => props.visibility};
  margin-top: ${themeGet('spacing.green4_0_25rem')};
  margin-right: 1.0875rem;

  ${themeGet('breakpoints.mobileLarge')} {
    margin-right: 0.8125rem;
  }
`

export const SingleClassTextContainer = styled(TextContainer).attrs(
  ({ hasCompletedLessonLastPeriod }) => ({
    hasCompletedLessonLastPeriod,
  }),
)`
  padding-left: ${props =>
    props.hasCompletedLessonLastPeriod ? '0' : '1.1875rem'};
`

export const MultipleClassesTextContainer = styled(TextContainer).attrs(
  ({ hasCompletedLessonLastPeriod }) => ({
    hasCompletedLessonLastPeriod,
  }),
)`
  padding-left: ${props =>
    props.hasCompletedLessonLastPeriod ? '0.0875rem' : '2.25rem'};

  ${themeGet('breakpoints.tablet')} {
    padding-left: ${props =>
      props.hasCompletedLessonLastPeriod ? '0.0875rem' : '2.25rem'};
  }

  ${themeGet('breakpoints.mobileLarge')} {
    padding-left: ${props =>
      props.hasCompletedLessonLastPeriod ? '0.0875rem' : '1.9125rem'};
  }
`

export const StyledTextContainer = styled(TextContainer).attrs(
  ({ numOfClasses }) => ({
    numOfClasses,
  }),
)`
  padding-left: ${props => (props.numOfClasses === 1 ? '0.125rem' : '1rem')};
`

export const ClassesTooltip = styled(TableHeaderTooltip)`
  [class*='TooltipContentWrapper'] {
    left: 4rem;
    top: 1.5rem;

    ::after {
      bottom: 90%;
      left: 29%;

      ${themeGet('breakpoints.tablet')} {
        left: 31%;
      }
    }
  }
`

export const LessonProgressTooltip = styled(TableHeaderTooltip)`
  [class*='TooltipContentWrapper'] {
    left: 4rem;
    top: 1.5rem;

    ::after {
      bottom: 90%;
      left: 29.25%;
    }

    ${themeGet('breakpoints.tablet')} {
      left: -6.25rem;

      ::after {
        left: 89.25%;
      }
    }

    ${themeGet('breakpoints.mobileLarge')} {
      left: -5.9125rem;
    }
  }
`

export const LastLessonTooltip = styled(TableHeaderTooltip)`
  [class*='TooltipContentWrapper'] {
    left: 50%;
    top: 1.5rem;

    ::after {
      left: 50%;
    }
  }
`

export const MarkedDoneTooltip = styled(TableHeaderTooltip)`
  [class*='TooltipContentWrapper'] {
    left: -5.5rem;
    top: 1.5rem;

    ::after {
      left: 85%;
    }
  }
`
