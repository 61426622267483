import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { ContainedButton } from 'secondstep-components'
import {
  StyledHeaderThree,
  StyledHeaderTwo,
} from '../LessonDetail/component.styles'

export const UnitLessonListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const ButtonBox = styled(Box)`
  flex-direction: row;
  align-items: center;
  margin 0;
  justify-content: flex-end;

  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;
    align-items: flex-start;
    margin-left: 0.75rem;
    margin-top: 0.3rem;
  }
`

export const ContentContainer = styled.div`
  background-color: ${themeGet('colors.lighterGray')};
  border-radius: 20px;
  box-shadow: 0 3px 0 ${themeGet('colors.gray500')};
`

export const H3 = styled(StyledHeaderThree)`
  margin: 0 0 0 ${themeGet('spacing.turquoise20_1_25rem')};
`

export const H2 = styled(StyledHeaderTwo)`
  margin: ${themeGet('spacing.amber28_1_75rem')} 0
    ${themeGet('spacing.turquoise20_1_25rem')} 0;
`

export const TeachButton = styled(ContainedButton)`
  border-radius: 21.5px;
  margin: ${themeGet('spacing.turquoise20_1_25rem')}
    ${themeGet('spacing.amber28_1_75rem')}
    ${themeGet('spacing.amber28_1_75rem')} ${themeGet('spacing.red40_2_5rem')};

  ${themeGet('breakpoints.mobileLarge')} {
    margin-left: 0;
  }
`
