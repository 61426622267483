import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const PrimaryColumn = styled.div`
  display: flex;
  flex-grow: 1;
`

export const SecondaryColumn = styled.div`
  justify-content: flex-end;
  display: flex;
  flex-shrink: 1;
  align-items: self-end;

  ${themeGet('breakpoints.mobileLarge')} {
    justify-content: flex-start;
    margin-top: ${themeGet('spacing.pink8_0_5rem')};
  }
`
