import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { manageContent } from './helpers'
import { LAYOUT_WITH_ANCHOR_NAVIGATION } from './pageLayouts'
import {
  AnchoredArticleWrapper,
  AnchoredContentWrapper,
  AnchoredPageWrapper,
  AnchorNavigationWrapper,
  ContentWrapper,
  GrayBackground,
  PageWrapper,
  BannerSpacer,
} from './component.styles'

import HighSchoolPreviewBanner from 'components/HighSchoolPreviewBanner'

import AnchoredHeaderBodyCopy from 'experienceComponents/AnchoredHeaderBodyCopy'
import ArticleHeaderBodyCopy from 'experienceComponents/ArticleHeaderBodyCopy'
import AnchorNavigation from 'experienceComponents/AnchorNavigation'
import AssetDescription from 'experienceComponents/AssetDescription'
import BookDescription from 'experienceComponents/BookDescription'
import ContentLandingBanner from 'experienceComponents/ContentLandingBanner'
import H3LinkList from 'experienceComponents/H3LinkList'
import HeaderBodyCopy from 'experienceComponents/HeaderBodyCopy'
import InlineTranslation from 'components/InlineTranslation'
import LanguageToggle from 'experienceComponents/LanguageToggle'
import MultilingualPdfSelector from 'components/MultilingualPdfSelector'
import TextLinkWithFileIcon from 'components/TextLinkWithFileIcon'
import VideoFullWidth from 'experienceComponents/VideoFullWidth'
import ImageDescription from 'experienceComponents/ImageDescription'
import FlexWidthImage from 'components/FlexWidthImage'
import MediaBodyCopyComponent from 'experienceComponents/MediaBodyCopy'
import ResourcesContentAccordionContainer from 'components/ResourcesContentAccordion'

const ContentComponents = {
  accordion: ResourcesContentAccordionContainer,
  anchor: AnchoredHeaderBodyCopy,
  assetDescription: AssetDescription,
  book: BookDescription,
  category: H3LinkList,
  headerBodyCopy: HeaderBodyCopy,
  image: FlexWidthImage,
  imageBodyCopy: ImageDescription,
  inlineTranslation: InlineTranslation,
  langPdf: MultilingualPdfSelector,
  lang: LanguageToggle,
  textLinkIcon: TextLinkWithFileIcon,
  video: VideoFullWidth,
  mediaBodyCopy: MediaBodyCopyComponent,
}

const NonAnchoredContentComponents = {
  ...ContentComponents,
  headerBodyCopy: ArticleHeaderBodyCopy,
}

export class ResourcesContent extends Component {
  static propTypes = {
    anchorNavigation: PropTypes.arrayOf(
      PropTypes.shape({
        hash: PropTypes.string.isRequired,
        id: PropTypes.string.isRequired,
        text: PropTypes.string.isRequired,
      }),
    ),
    children: PropTypes.node,
    content: PropTypes.arrayOf(
      PropTypes.shape({
        data: PropTypes.object.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
    currentLocale: PropTypes.string,
    getEntryWithNewLocale: PropTypes.func,
    landingBanner: PropTypes.shape({
      text: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
    }).isRequired,
    pageLayout: PropTypes.string.isRequired,
  }

  getPageContent() {
    const { pageLayout, isFamilyResourcesPage, isSelaProduct } =
      this.props || {}

    const useAlternateBackground = isFamilyResourcesPage || isSelaProduct

    if (pageLayout === LAYOUT_WITH_ANCHOR_NAVIGATION) {
      const { anchorNavigation } = this.props || {}
      return (
        <GrayBackground
          alternateBackground={useAlternateBackground}
          isSelaProduct={isSelaProduct}
        >
          <AnchoredPageWrapper>
            <AnchorNavigationWrapper>
              <AnchorNavigation links={anchorNavigation} />
            </AnchorNavigationWrapper>
            <AnchoredContentWrapper>
              {this.getAnchoredContentComponents()}
            </AnchoredContentWrapper>
          </AnchoredPageWrapper>
        </GrayBackground>
      )
    } else {
      return (
        <GrayBackground isSelaProduct={isSelaProduct}>
          <ContentWrapper>{this.getContentComponents()}</ContentWrapper>
        </GrayBackground>
      )
    }
  }

  getAnchoredContentComponents() {
    const { content = [] } = this.props || {}

    const articleGroups = this.groupArticlesByAnchors(content)

    return articleGroups.map((articleGroup, wrapperIndex) => {
      return manageContent(
        articleGroup,
        ContentComponents,
        this.getContentComponent.bind(this),
        contentComponents => (
          <AnchoredArticleWrapper key={`anchorGroup-${wrapperIndex}`}>
            {contentComponents}
          </AnchoredArticleWrapper>
        ),
      )
    })
  }

  groupArticlesByAnchors(contents = []) {
    let insideBoundary = false

    const groups = contents.reduce((result, content, idx) => {
      const { name, data } = content
      if (name === 'lang') {
        const { boundary } = data || {}
        // Reached Language Toggle block start
        if (boundary) {
          insideBoundary = true
          const newGroup = [content]
          const newResult = [...result, newGroup]

          return newResult
        } else {
          // Reached Language Toggle block end
          insideBoundary = false
        }
      }

      // Prevent Anchor from starting new group if it's within a Language Toggle block
      if (name === 'anchor' && !insideBoundary) {
        const newGroup = [content]
        const newResult = [...result, newGroup]
        return newResult
      }

      const lastIndex = result.length - 1
      const currentIndex = lastIndex < 0 ? 0 : lastIndex
      const currentGroup = result[currentIndex] || []

      const updatedCurrentGroup = [...currentGroup, content]
      const resultsLessLastItem = result.slice(0, result.length - 1)
      const newResult = [...resultsLessLastItem, updatedCurrentGroup]
      return newResult
    }, [])

    return groups
  }

  getContentComponents() {
    const { content } = this.props || []

    return manageContent(
      content,
      ContentComponents,
      this.getContentComponent.bind(this),
      contentComponents => contentComponents,
    )
  }

  getContentComponent(contentItem, index, nonAnchor) {
    const { name, data: componentProps } = contentItem || {}
    const { getEntryWithNewLocale, currentLocale } = this.props
    const Components = nonAnchor
      ? NonAnchoredContentComponents
      : ContentComponents

    const ContentComponent = (Components[name] && Components[name]) || null

    if (ContentComponent === null) {
      return null
    }
    // We should eventually have an Experience Component that handles
    // this styling/logic for us and this will be able to go away
    if (name === 'image') {
      return (
        <ContentComponent
          key={`content-${index}-${name}`}
          {...componentProps}
        />
      )
    }

    return (
      <ContentComponent
        key={`content-${index}-${name}`}
        {...componentProps}
        getEntryWithNewLocale={getEntryWithNewLocale}
        locale={currentLocale}
      />
    )
  }

  render() {
    const {
      isDistrictSupportsPage,
      isFamilyResourcesPage,
      isHsPreview,
      isSelaLeaderPage,
      isSelaProduct,
      landingBanner,
      children,
    } = this.props || {}

    const useAlternateBackground = isFamilyResourcesPage || isSelaProduct

    return (
      <PageWrapper
        alternateBackground={useAlternateBackground}
        data-testid="resources-content-page"
      >
        {isHsPreview && (
          <>
            <BannerSpacer />
            <HighSchoolPreviewBanner />
          </>
        )}
        {children}
        <ContentLandingBanner
          isDistrictSupportsPage={isDistrictSupportsPage}
          isFamilyResourcesPage={isFamilyResourcesPage}
          isSelaLeaderPage={isSelaLeaderPage}
          isSelaProduct={isSelaProduct}
          key="landingBanner"
          {...landingBanner}
        />
        {this.getPageContent()}
      </PageWrapper>
    )
  }
}

export default ResourcesContent
