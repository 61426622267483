import types from './types'

export const initialState = {
  isLoading: false,
  loadingTextState: '',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case types.LOADING: {
      return {
        ...state,
        isLoading: true,
        loadingTextState: action.payload,
      }
    }

    case types.NOT_LOADING: {
      return {
        ...state,
        isLoading: false,
        loadingTextState: '',
      }
    }

    default:
      return state
  }
}
