import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { DateRange } from 'secondstep-components'
import {
  ResponsiveContainer,
  Bar,
  BarChart,
  Cell,
  LabelList,
  ReferenceLine,
  XAxis,
  YAxis,
} from 'recharts'
import { withTheme } from 'styled-components'
import { themeGet } from 'styled-system'
import { TOOLTIP_LOCATIONS } from 'utils/tooltipHelpers'
import PacingModal from 'components/PacingModal'
import {
  BAR_CHART_TITLE,
  EDUCATOR_PRACTICES_PROGRESS_BAR_LABEL,
  HEADER_TITLE,
  PACING_SCHEDULE_LINK_TEXT,
  SCHOOLWIDE_PRACTICES_PROGRESS_BAR_LABEL,
  TAB_FOUR_LABEL,
  TAB_ONE_LABEL,
  TAB_THREE_LABEL,
  TAB_TWO_LABEL,
} from './constants'
import tabTitleMap from './tabTitleMap'
import {
  AdminPathwayProgressWrapper,
  BarChartTitle,
  BarChartWrapper,
  ChartContainer,
  EducatorPracticesProgressBar,
  HeaderWrapper,
  PacingAndPathwayWrapper,
  PacingScheduleLink,
  ProgressBarCountLabel,
  ProgressBarLabelWrapper,
  ProgressBarTitleLabel,
  ProgressBarsWrapper,
  SchoolwidePracticesProgressBar,
  SingleBarWrapper,
  StyledAdminTooltip,
  StyledHairline,
  TabButton,
  TabContentWrapper,
  TabHeaderTitle,
  TabsWrapper,
  Title,
} from './component.styles'

const AdminPathwayProgress = props => {
  const [activeTab, setActiveTab] = useState(props.persistedActiveTab || 1)
  const [educatorPracticesCompleted, setEducatorPracticesCompleted] = useState(
    0,
  )
  const [educatorPracticesCount, setEducatorPracticesCount] = useState(0)
  const [isPacingModalOpen, setIsPacingModalOpen] = useState(false)
  const [
    schoolwidePracticesCompleted,
    setSchoolwidePracticesCompleted,
  ] = useState(0)
  const [pathwayStudentActivities, setPathwayStudentActivities] = useState(true)
  const [schoolwidePracticesCount, setSchoolwidePracticesCount] = useState(0)
  const [showBarChart, setShowBarChart] = useState(true)

  const { programKey, site } = props
  const {
    currentPacingName = '',
    educatorPracticesPathways,
    pathwayDates,
    preferences,
    schoolwidePracticesPathways,
    siteId,
    studentActivitiesByPathways,
  } = site

  useEffect(() => {
    const { persistedActiveTab, setAdminActivePathwayTabBySite } = props
    const lastChar = parseInt(currentPacingName?.slice(-1))
    const tabNumber = isNaN(lastChar) ? 1 : lastChar
    const practicesCompletedStr = `pathway${activeTab}PracticesOrActivitiesCompleted`
    const practicesCountStr = `pathway${activeTab}PracticesOrActivitiesCount`
    const pathwayActivities = studentActivitiesByPathways?.find(pathway => {
      const activeTabNumber = persistedActiveTab || tabNumber
      return pathway.pathway_number === activeTabNumber
    })

    if (!persistedActiveTab) {
      setActiveTab(tabNumber)
      setAdminActivePathwayTabBySite(tabNumber, siteId)
    } else {
      setActiveTab(persistedActiveTab)
    }

    setShowBarChart(preferences?.studentActivities)
    setPathwayStudentActivities(pathwayActivities)
    setEducatorPracticesCompleted(
      educatorPracticesPathways[practicesCompletedStr],
    )
    setEducatorPracticesCount(educatorPracticesPathways[practicesCountStr])
    setSchoolwidePracticesCompleted(
      schoolwidePracticesPathways[practicesCompletedStr],
    )
    setSchoolwidePracticesCount(schoolwidePracticesPathways[practicesCountStr])
  }, [activeTab, pathwayStudentActivities, props.persistedActiveTab, site])

  const toggleTab = tabNumber => {
    const { setAdminActivePathwayTabBySite } = props
    const practicesCompletedStr = `pathway${tabNumber}PracticesOrActivitiesCompleted`
    const practicesCountStr = `pathway${tabNumber}PracticesOrActivitiesCount`

    setEducatorPracticesCompleted(
      educatorPracticesPathways[practicesCompletedStr],
    )
    setEducatorPracticesCount(educatorPracticesPathways[practicesCountStr])
    setSchoolwidePracticesCompleted(
      schoolwidePracticesPathways[practicesCompletedStr],
    )
    setSchoolwidePracticesCount(schoolwidePracticesPathways[practicesCountStr])
    setActiveTab(tabNumber)
    setAdminActivePathwayTabBySite(tabNumber, siteId)
  }

  const getReferenceLineYValue = () => {
    const yMaxValue = Math.max(...barChartData.map(({ value }) => value))
    return isNaN(yMaxValue) || yMaxValue === 0 ? 1 : yMaxValue
  }

  const barChartData = [
    {
      color: `${themeGet('colors.highschool.pink1')(props)}`,
      name: 9,
      value:
        pathwayStudentActivities?.num_g9_student_activities_completed_in_pathway,
    },
    {
      color: themeGet('colors.darkBlue')(props),
      name: 10,
      value:
        pathwayStudentActivities?.num_g10_student_activities_completed_in_pathway,
    },
    {
      color: themeGet('colors.violet')(props),
      name: 11,
      value:
        pathwayStudentActivities?.num_g11_student_activities_completed_in_pathway,
    },
    {
      color: themeGet('colors.mediumTeal')(props),
      name: 12,
      value:
        pathwayStudentActivities?.num_g12_student_activities_completed_in_pathway,
    },
    { name: 'Grade', value: 0 },
  ]

  const educatorPracticesTotals = `${educatorPracticesCompleted} of ${educatorPracticesCount}
  Practices`
  const schoolwidePracticesTotals = `${schoolwidePracticesCompleted} of ${schoolwidePracticesCount} Practices`
  const {
    endDateTextFormatted,
    endDateInMs,
    startDateTextFormatted,
    startDateInMs,
  } = pathwayDates[`Pathway ${activeTab}`] || {}

  return (
    <AdminPathwayProgressWrapper showBarChart={showBarChart}>
      <HeaderWrapper>
        <Title>{HEADER_TITLE}</Title>
        <StyledAdminTooltip
          location={TOOLTIP_LOCATIONS.MONITOR_PATHWAY_PROGRESS}
          monitorView
          programKey={'HS_MONITOR'}
        />
      </HeaderWrapper>
      <TabsWrapper>
        <TabButton
          isActive={activeTab === 1}
          label={TAB_ONE_LABEL}
          onClick={() => toggleTab(1)}
        >
          {TAB_ONE_LABEL}
        </TabButton>
        <TabButton
          isActive={activeTab === 2}
          label={TAB_TWO_LABEL}
          onClick={() => toggleTab(2)}
        >
          {TAB_TWO_LABEL}
        </TabButton>
        <TabButton
          isActive={activeTab === 3}
          label={TAB_THREE_LABEL}
          onClick={() => toggleTab(3)}
        >
          {TAB_THREE_LABEL}
        </TabButton>
        <TabButton
          isActive={activeTab === 4}
          label={TAB_FOUR_LABEL}
          onClick={() => toggleTab(4)}
        >
          {TAB_FOUR_LABEL}
        </TabButton>
      </TabsWrapper>
      <StyledHairline />
      <TabHeaderTitle>{tabTitleMap[activeTab]}</TabHeaderTitle>
      <PacingAndPathwayWrapper>
        <DateRange
          endDateInMs={endDateInMs}
          endDateTextFormatted={endDateTextFormatted}
          startDateInMs={startDateInMs}
          startDateTextFormatted={startDateTextFormatted}
        />
        <PacingScheduleLink onClick={() => setIsPacingModalOpen(true)}>
          {PACING_SCHEDULE_LINK_TEXT}
        </PacingScheduleLink>
      </PacingAndPathwayWrapper>
      <TabContentWrapper>
        <ProgressBarsWrapper showBarChart={showBarChart}>
          <SingleBarWrapper>
            <ProgressBarLabelWrapper>
              <ProgressBarTitleLabel>
                {SCHOOLWIDE_PRACTICES_PROGRESS_BAR_LABEL}
              </ProgressBarTitleLabel>
              <ProgressBarCountLabel>
                {schoolwidePracticesTotals}
              </ProgressBarCountLabel>
            </ProgressBarLabelWrapper>
            <SchoolwidePracticesProgressBar
              isParentMounted={{ current: true }}
              isValueInsideBar
              percentComplete={
                Math.floor(
                  (schoolwidePracticesCompleted / schoolwidePracticesCount) *
                    100,
                ) || 0
              }
              shouldRenderIndicator
            />
          </SingleBarWrapper>
          <SingleBarWrapper>
            <ProgressBarLabelWrapper>
              <ProgressBarTitleLabel>
                {EDUCATOR_PRACTICES_PROGRESS_BAR_LABEL}
              </ProgressBarTitleLabel>
              <ProgressBarCountLabel>
                {educatorPracticesTotals}
              </ProgressBarCountLabel>
            </ProgressBarLabelWrapper>
            <EducatorPracticesProgressBar
              isParentMounted={{ current: true }}
              isValueInsideBar
              percentComplete={
                Math.floor(
                  (educatorPracticesCompleted / educatorPracticesCount) * 100,
                ) || 0
              }
              shouldRenderIndicator
            />
          </SingleBarWrapper>
        </ProgressBarsWrapper>
        {showBarChart && (
          <BarChartWrapper>
            <BarChartTitle>{BAR_CHART_TITLE}</BarChartTitle>
            <ChartContainer>
              <ResponsiveContainer width="100%">
                <BarChart
                  data={barChartData}
                  height="189px"
                  margin={0}
                  padding={0}
                  width="100%"
                >
                  <XAxis
                    axisLine={{
                      stroke: themeGet('colors.paleGray')(props),
                      strokeWidth: 2,
                    }}
                    dataKey="name"
                    tick={{ dy: 6 }}
                    tickLine={false}
                  />
                  <YAxis domain={[0, 1]} hide={true} />
                  <Bar dataKey="value" radius={5}>
                    {barChartData.map((entry, index) => (
                      <Cell fill={entry.color} key={`cell-${index}`} />
                    ))}
                    <LabelList
                      content={({ height, index, value, width, x, y }) => {
                        const isSmall = height < 50
                        const xValue = x + width / 2
                        const yValue = isSmall ? y - 10 : y + 28
                        const fill = isSmall
                          ? barChartData[index].color
                          : themeGet('colors.white')(props)

                        if (barChartData[index].name === 'Grade') {
                          return null
                        } else {
                          return (
                            <text
                              fill={fill}
                              fontFamily={themeGet('font.molde.medium')(props)}
                              fontSize={16}
                              textAnchor="middle"
                              x={xValue}
                              y={yValue}
                            >
                              {value}
                            </text>
                          )
                        }
                      }}
                      dataKey="value"
                      position="insideTop"
                    />
                  </Bar>
                  <ReferenceLine
                    ifOverflow="extendDomain"
                    stroke={themeGet('colors.paleGray')(props)}
                    strokeWidth={2}
                    y={getReferenceLineYValue()}
                  />
                </BarChart>
              </ResponsiveContainer>
            </ChartContainer>
          </BarChartWrapper>
        )}
      </TabContentWrapper>
      {isPacingModalOpen && (
        <PacingModal
          handleClose={() => setIsPacingModalOpen(false)}
          programKey={programKey}
          savedPreferences={preferences}
          siteId={siteId}
        />
      )}
    </AdminPathwayProgressWrapper>
  )
}

AdminPathwayProgress.propTypes = {
  persistedActiveTab: PropTypes.number,
  programKey: PropTypes.string.isRequired,
  setAdminActivePathwayTabBySite: PropTypes.func.isRequired,
  site: PropTypes.shape({
    currentPacingName: PropTypes.string.isRequired,
    educatorPracticesPathways: PropTypes.object.isRequired,
    pathwayDates: PropTypes.object.isRequired,
    preferences: PropTypes.object.isRequired,
    schoolwidePracticesPathways: PropTypes.object.isRequired,
    siteId: PropTypes.number.isRequired,
    studentActivitiesByPathways: PropTypes.array.isRequired,
  }),
}

export default withTheme(AdminPathwayProgress)
