import React from 'react'
import { connect } from 'react-redux'
import AdminHSSetup from './component'
import { operations as licenseOperations } from 'store/licenseManager'
import siteOperations from 'store/siteManager/operations'
import PropTypes from 'prop-types'
import { ADMIN_DASHBOARD_ENTRY_ID } from 'utils/entryIds'
import { navigationEvent } from 'layers/navigation/store/operations'
import {
  getProgramSnapshot,
  SNAPSHOTS_NAME_MAP,
} from 'utils/adminExperienceHelpers'

const AdminHSSetupContainer = ({
  contentful,
  fetchUserSites,
  isMultiSite,
  navigationEvent,
  preferences,
  programKey,
  siteId,
  siteName,
  sitePreferences,
  updateSitePreferences,
}) => {
  const snapshotFieldName = SNAPSHOTS_NAME_MAP[programKey]
  const snapshots =
    contentful?.[ADMIN_DASHBOARD_ENTRY_ID]?.payload?.[snapshotFieldName] || []
  const snapshot = getProgramSnapshot(programKey, snapshots)
  const contentData = snapshot?.['setupPanel']

  return (
    <AdminHSSetup
      contentData={contentData}
      fetchUserSites={fetchUserSites}
      isMultiSite={isMultiSite}
      navigationEvent={navigationEvent}
      preferences={preferences}
      programKey={programKey}
      siteId={siteId}
      siteName={siteName}
      sitePreferences={sitePreferences}
      updateSitePreferences={updateSitePreferences}
    />
  )
}

const mapStateToProps = ({ contentful, siteManager }) => {
  const { sitePreferences } = siteManager || {}

  return { contentful, sitePreferences }
}

const mapDispatchToProps = {
  navigationEvent,
  fetchUserSites: licenseOperations.fetchUserSites,
  updateSitePreferences: siteOperations.updateSitePreferences,
}

AdminHSSetupContainer.propTypes = {
  contentful: PropTypes.object,
  fetchUserSites: PropTypes.func,
  isMultiSite: PropTypes.bool,
  navigationEvent: PropTypes.func,
  preferences: PropTypes.object,
  programKey: PropTypes.string,
  siteId: PropTypes.number,
  siteName: PropTypes.string,
  sitePreferences: PropTypes.object,
  updateSitePreferences: PropTypes.func,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminHSSetupContainer)
