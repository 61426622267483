import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { CheckIcon, IconCaretSelect } from 'secondstep-components'

import {
  DropdownButton,
  DropdownSpaceFiller,
  DropdownOption,
  DropdownOptions,
  DropdownOptionSeparator,
  LabelWrapper,
  StyledLabel,
  Wrapper,
} from './component.styles'
import {
  EVENT_CLICK,
  EVENT_KEYDOWN,
  KEY_ESCAPE,
  KEY_ENTER,
  KEY_ARROW_DOWN,
  KEY_ARROW_UP,
} from './constants'

const SelaReportsDropdown = ({
  className,
  dataTestId,
  label,
  noSeparator,
  onChange,
  options,
  tooltip,
  value,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const toggleIsOpen = (open = null) => {
    if (open === null) {
      open = !isOpen
    }

    setIsOpen(open)
  }

  const handleEnterKey = (e, onClick) => {
    if (!onClick) return

    if (e?.key === KEY_ENTER) {
      onClick(e)
    }
  }

  const handleArrowKeys = (e, onClick) => {
    if (!onClick) return

    if (
      (e?.key === KEY_ARROW_DOWN && !isOpen) ||
      (e?.key === KEY_ARROW_UP && isOpen)
    ) {
      e.preventDefault()
      onClick()
    }
  }

  const getOptions = defaultKey => {
    const links = []

    options.forEach((option, idx) => {
      if (!noSeparator && idx === 2) {
        links.push(
          <DropdownOptionSeparator
            dataTestId={`${dataTestId}-separator`}
            key={`${dataTestId}-separator`}
          >
            <div />
          </DropdownOptionSeparator>,
        )
      }

      const { key, name } = option
      const isDefaultValue = key && defaultKey && key === defaultKey
      const linkId = `${dataTestId}-${key}`
      const linkOnClick = () => onChange(option)

      links.push(
        <DropdownOption
          data-focusable="true"
          dataTestId={linkId}
          isDefaultValue={isDefaultValue}
          key={linkId}
          onClick={linkOnClick}
          onKeyDown={e => handleEnterKey(e, linkOnClick)}
          tabIndex="0"
        >
          <span>{name}</span>
          <CheckIcon />
        </DropdownOption>,
      )
    })
    return links
  }

  useEffect(() => {
    const close = e => {
      if (e?.code === KEY_ESCAPE || e?.type === EVENT_CLICK) {
        toggleIsOpen(false)
      }
    }
    if (isOpen) {
      document.addEventListener(EVENT_CLICK, close)
      document.addEventListener(EVENT_KEYDOWN, close)
    }
    return () => {
      document.removeEventListener(EVENT_CLICK, close)
      document.removeEventListener(EVENT_KEYDOWN, close)
    }
  }, [isOpen])

  const { key, name } = value || ''

  return (
    <Wrapper
      className={className}
      data-focusable="true"
      dataTestId={dataTestId}
    >
      <LabelWrapper>
        <StyledLabel>{label}</StyledLabel>
        {tooltip && tooltip}
      </LabelWrapper>
      <DropdownButton
        dataTestId={`${dataTestId}-button`}
        isOpen={isOpen}
        onClick={() => toggleIsOpen()}
        onKeyDown={e => handleArrowKeys(e, () => toggleIsOpen())}
        tabIndex="0"
      >
        <span>{name}</span>
        <IconCaretSelect />
      </DropdownButton>

      {isOpen && (
        <>
          <DropdownSpaceFiller className="dropdown-space-filler" />
          <DropdownOptions
            dataTestId={`${dataTestId}-options`}
            key={`${dataTestId}-options`}
          >
            {getOptions(key)}
          </DropdownOptions>
        </>
      )}
    </Wrapper>
  )
}

SelaReportsDropdown.propTypes = {
  className: PropTypes.string,
  content: PropTypes.node,
  dataTestId: PropTypes.string,
  label: PropTypes.string,
  noSeparator: PropTypes.bool,
  onChange: PropTypes.func,
  options: PropTypes.array,
  tooltip: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
}

export default SelaReportsDropdown
