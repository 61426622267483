import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Accordion, Tooltip } from 'secondstep-components'

const customBreakpoints = {
  belowTablet: '@media screen and (max-width: 720px)',
}

export const StyledAccordion = styled(Accordion).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  width: 100%;
  margin-bottom: ${themeGet('spacing.turquoise20_1_25rem')};
  border: 2px solid
    ${themeGet('colors.backgrounds.secondary', 'colors.paleGray')};
  background: ${themeGet('colors.paleGray')};
  & :last-of-type {
    margin-bottom: 0px;
  }

  &:hover {
    border: 2px solid ${themeGet('colors.grayBlue')};
  }

  svg {
    color: ${themeGet('colors.blue')};
    margin-right: 0;
  }

  [class*='AccordionPanel'] {
    padding: 1.375rem 1.125rem 1.1375rem;
    background: ${themeGet('colors.paleGray')};
  }

  [class*='AccordionTitleWrapper'] {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    @media (max-width: 720px) {
      align-items: flex-end;
    }
  }

  [class*='AccordionContentWrapper'] {
    margin: 0 0.0625rem 0.0625rem 0.0625rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    [class*='AccordionContent'] {
      padding: 1.375rem 1.25rem 0.75rem;
    }
  }

  ${customBreakpoints.belowTablet} {
    [class*='StyledCaret'] {
      margin-bottom: 6px;
    }
  }

  ${themeGet('breakpoints.mobile')} {
    [class*='AccordionPanel'] {
      padding: 0.875rem 0.875rem 0.75rem;
    }

    [class*='AccordionContentWrapper'] {
      [class*='AccordionContent'] {
        padding: 0.875rem 1rem 0.5rem;
      }
    }

    margin-bottom: 1rem;
  }
`

export const UnitReportDownload = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 0.875rem;

  svg {
    width: 1.5rem;
    height: 1rem;
    margin-top: 0.125rem;
    margin-right: 0.5rem;
  }

  [class*='BodyCopy'] {
    text-align: start;
  }
`

export const LeaderTeamUnitReportDetail = styled(UnitReportDownload)`
  [class*='tooltip-content'] {
    transform: translate(-7.785rem, 0.5rem);
  }

  @media (max-width: 486px) {
    display: inline-block;
    max-width: 20rem;

    [class*='csv-download-link'] {
      display: inline;

      a {
        display: inline;
      }

      svg {
        margin-bottom: 0;
        margin-top: 0.75rem;
      }
    }

    [class*='tooltip-content'] {
      bottom: 3rem;
      left: -320%;
      transform: translate(1.215rem, 0.5rem);

      :after {
        left: 28%;
      }

      [class*='BodyCopy'] {
        :after {
          left: 28%;
        }
      }
    }
  }

  @media (max-width: 401px) {
    [class*='tooltip-content'] {
      transform: translate(0.215rem, 0.5rem);

      :after {
        left: 34%;
      }
      [class*='BodyCopy'] {
        :after {
          left: 34%;
        }
      }
    }
  }

  @media (max-width: 390px) {
    max-width: 16rem;
  }
`

export const LeaderTeamUnitReportDownload = styled(UnitReportDownload)`
  [class*='tooltip-content'] {
    transform: translate(-7.785rem, 0.5rem);
  }

  @media (max-width: 570px) {
    display: inline-block;
    max-width: 25rem;

    [class*='csv-download-link'] {
      display: inline;

      a {
        display: inline;
      }

      svg {
        margin-bottom: 0;
        margin-top: 0.75rem;
      }
    }

    [class*='tooltip-content'] {
      bottom: 3rem;
      left: -320%;
      transform: translate(1.215rem, 0.5rem);

      :after {
        left: 28%;
      }

      [class*='BodyCopy'] {
        :after {
          left: 28%;
        }
      }
    }
  }

  @media (max-width: 431px) {
    [class*='tooltip-content'] {
      transform: translate(-3.785rem, 0.5rem);

      :after {
        left: 60%;
      }

      [class*='BodyCopy'] {
        :after {
          left: 60%;
        }
      }
    }
  }

  @media (max-width: 397px) {
    max-width: 16rem;

    [class*='tooltip-content'] {
      transform: translate(-4.785rem, 0.5rem);

      :after {
        left: 66.5%;
      }

      [class*='BodyCopy'] {
        :after {
          left: 66.5%;
        }
      }
    }
  }
`

export const StaffUnitReportDetail = styled(UnitReportDownload)`
  [class*='tooltip-content'] {
    transform: translate(-7.785rem, 0.5rem);
  }

  @media (max-width: 430px) {
    [class*='tooltip-content'] {
      transform: translate(-8.5rem, 0.5rem);

      :after {
        left: 90.5%;
      }

      [class*='BodyCopy'] {
        :after {
          left: 90.5%;
        }
      }
    }
  }

  @media (max-width: 422px) {
    display: inline-block;
    max-width: 16rem;

    [class*='csv-download-link'] {
      display: inline;

      a {
        display: inline;
      }

      svg {
        margin-bottom: 0;
        margin-top: 0.75rem;
      }
    }

    [class*='tooltip-content'] {
      bottom: 3rem;
      left: -320%;
      transform: translate(2.5rem, 0.5rem);

      :after {
        left: 19.5%;
      }

      [class*='BodyCopy'] {
        :after {
          left: 19.5%;
        }
      }
    }
  }
`

export const StaffUnitReportDownload = styled(UnitReportDownload)`
  [class*='tooltip-content'] {
    left: -360%;
    transform: translate(-7.785rem, 0.5rem);

    :after {
      left: 89.5%;
    }

    [class*='BodyCopy'] {
      :after {
        left: 89.5%;
      }
    }
  }

  @media (max-width: 504px) {
    display: inline-block;
    max-width: 16rem;

    [class*='csv-download-link'] {
      display: inline;

      a {
        display: inline;
      }

      svg {
        margin-bottom: 0;
        margin-top: 0.75rem;
      }
    }

    [class*='tooltip-content'] {
      bottom: 3rem;
      left: -320%;
      transform: translate(-0.5rem, 0.5rem);

      :after {
        left: 39%;
      }

      [class*='BodyCopy'] {
        :after {
          left: 39%;
        }
      }
    }
  }
`

export const UnitCsvTooltip = styled(Tooltip)`
  width: 24px;
  div {
    bottom: 3rem;
    padding: 0.3rem 0.2rem 0.3rem 0.3rem;
    border-radius: 0.3125rem;

    :after {
      content: ' ';
      width: 0.75rem;
      height: 0.75rem;
      position: absolute;
      bottom: -4%;
      left: 50%;
      background-color: white;
      transform: translate(-50%) rotate(45deg);

      // arrow shadow - why are there two arrows?!
      // box-shadow: 1px 1px 1px 0 rgba(0,0,0,0.12);
      // box-shadow: 4px 4px 4px -2px rgba(0,0,0,0.12);
    }
  }

  [class*='BodyCopy'] {
    color: ${themeGet('colors.darkGray')};
    margin-bottom: 0;
    p {
      font-size: 0.75rem;
      line-height: 1rem;
      margin-block-end: 0;
    }
  }

  i {
    padding: 14px 5px;
    &.fas {
      color: ${themeGet('colors.blueGray')};
      font-size: 0.875rem;
    }
  }

  ${themeGet('breakpoints.tablet')} {
    div {
      left: -320%;

      :after {
        left: 86%;
      }
    }
  }
  ${themeGet('breakpoints.mobile')} {
    div {
      bottom: 4.2rem;
      left: -427%;

      :after {
        left: 96%;
      }
    }
  }
`
