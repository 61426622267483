import { createInMemoryCache } from '@algolia/cache-in-memory'
import { createFallbackableCache } from '@algolia/cache-common'
import { version } from '@algolia/client-common'
import { createBrowserLocalStorageCache } from '@algolia/cache-browser-local-storage'
import { isFlagOn } from 'utils/featureFlags'
import { MOCK_RESOURCE_LIBRARY_DATA_FLAG } from 'utils/highschoolHelpers'

export function getFacetKey(linkedMetadataContentType) {
  return linkedMetadataContentType.replace(
    /^(metadata)([A-Z])/,
    (_, _metadata, firstLetter) => firstLetter.toLowerCase(),
  )
}

export function sortFacets(a, b) {
  if (a.facetWeight < b.facetWeight) {
    return -1
  }

  if (a.facetWeight > b.facetWeight) {
    return 1
  }

  if (a.facetWeight === b.facetWeight) {
    return a.facetId.localeCompare(b.facetId)
  }
}

export function shouldUseMockServiceWorker(location) {
  const urlParams = new URLSearchParams(location.search)
  const liveDataParam = urlParams.get('liveData')
  // Add liveData=true query param to enable live Algolia data in lower environments
  if (liveDataParam?.toLocaleLowerCase() === 'true') {
    return false
  }

  // even if this flag is mistakenly enabled in Production, we should ALWAYS return live data in Prod
  if (
    isFlagOn([MOCK_RESOURCE_LIBRARY_DATA_FLAG]) &&
    process.env.NODE_ENV !== 'production'
  ) {
    return true
  }

  return false
}

export const getAlgoliaCacheSettings = app_name => ({
  responsesCache: createInMemoryCache(),
  requestsCache: createInMemoryCache({ serializable: false }),
  hostsCache: createFallbackableCache({
    caches: [
      createBrowserLocalStorageCache({
        key: `${version}-${app_name}`,
      }),
      createInMemoryCache(),
    ],
  }),
})

export const generateCompletionStatusMap = instructorData => {
  const completionStatusMap = {}
  // handle case were instructorData is undefined
  if (!instructorData) {
    return completionStatusMap
  }

  for (const lesson of instructorData?.site?.lesson_nodes || []) {
    if (lesson.leaf_node_id) {
      completionStatusMap[lesson.leaf_node_id.split('-')[0]] = {
        isComplete: lesson?.is_done,
      }
    }
  }
  for (const lesson of instructorData?.siteEducator?.lesson_nodes || []) {
    if (lesson.leaf_node_id) {
      completionStatusMap[lesson.leaf_node_id.split('-')[0]] = {
        isComplete: lesson?.is_done,
        isSkipped: lesson?.period_progress === 'skipped',
      }
    }
  }
  return completionStatusMap
}
