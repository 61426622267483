import React from 'react'
import { LinkBox } from './component.styles'
import {
  HeaderSix,
  IconPDF,
  TextLink,
  ClassChallengeIcon,
  ClassMeetingIcon,
  ServiceLearningProjectIcon,
} from 'secondstep-components'
import NavigationHelper from 'layers/navigation/navigationHelper'
import PropTypes from 'prop-types'

const ICON_TYPE = {
  class_challenge: <ClassChallengeIcon />,
  class_meeting: <ClassMeetingIcon />,
  service_learning_projects: <ServiceLearningProjectIcon />,
}

const ExtendLessonSection = ({
  navigationEvent,
  extensionLinks,
  extensionTitle,
}) => {
  const onClick = (navigationEvent, href) => {
    const type = NavigationHelper.types.NEW_TAB
    navigationEvent(href, type)
  }

  const onKeyPressCurry = href => event => {
    if (event.key === 'Enter') {
      onClick(navigationEvent, href)
    }
  }

  if (extensionLinks && extensionLinks.length === 0) {
    return null
  }

  return (
    <>
      <HeaderSix dataTestId="header-extend-lesson-links">
        {extensionTitle}
      </HeaderSix>
      {extensionLinks.map(extensionlLink => {
        const {
          contentType,
          displayTitle,
          id,
          icon,
          url,
          file,
        } = extensionlLink
        let Icon = ICON_TYPE[icon]

        let href = url

        if (!Icon) {
          if (contentType && contentType.id === 'pdf') {
            Icon = <IconPDF role="img" />
            href = file.file.url
          }
        }

        return (
          <LinkBox key={`extend-lesson-link-${id}`}>
            <TextLink
              dataTestId={`extend-lesson-textlink-${id}`}
              onClick={() => onClick(navigationEvent, href)}
              onKeyPress={onKeyPressCurry(null)}
            >
              {Icon ?? null}
              {displayTitle}
            </TextLink>
          </LinkBox>
        )
      })}
    </>
  )
}

ExtendLessonSection.defaultProps = {
  extensionLinks: [],
  extensionTitle: '',
}

ExtendLessonSection.propTypes = {
  extensionLinks: PropTypes.array,
  extensionTitle: PropTypes.string,
  navigationEvent: PropTypes.func,
}

export default ExtendLessonSection
