import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  // original state is used for fetching routes
  error: null,
  isFetching: false,
  routes: {},
  cancelledRoutes: {},
  // dedicate a the same state variables below to fetching subnavs:
  subnavs: {},
  isFetchingSubnav: false,
  errorSubnav: null,
}

const reducers = {
  fetchRouteCancel: (state, { payload }) => {
    const { app, route } = payload
    state.isFetching = false
    state.cancelledRoutes[route] = { app }
  },

  fetchingRoute: (state, { payload }) => {
    const { route } = payload
    state.error = null
    state.isFetching = true
    // if we refetch a route, consider it no longer a cancelled request
    delete state.cancelledRoutes[route]
  },

  fetchRouteError: (state, { payload }) => {
    const { error, app, route } = payload
    // handle a case where this route request was cancelled
    if (state.cancelledRoutes[route]?.app === app) {
      return
    }
    state.error = error
    state.isFetching = false
  },

  fetchRouteSuccess: (state, { payload }) => {
    const { data, route, app } = payload
    // handle a case where this route request was cancelled
    if (state.cancelledRoutes[route]?.app === app) {
      return
    }

    state.error = null
    state.isFetching = false

    state.routes[route] = {
      ...data,
      app,
    }
  },

  fetchingSubnav: state => {
    state.errorSubnav = null
    state.isFetchingSubnav = true
  },

  fetchSubnavError: (state, { payload }) => {
    const { error } = payload
    state.errorSubnav = error
    state.isFetchingSubnav = false
  },

  fetchSubnavSuccess: (state, { payload }) => {
    const { app, data } = payload
    state.errorSubnav = null
    state.isFetchingSubnav = false
    state.subnavs[app] = data
  },

  clearErrors: state => {
    state.error = initialState.error
    state.errorSubnav = initialState.errorSubnav
  },
}

const slice = createSlice({ initialState, name: 'routeService', reducers })

export const actions = slice.actions
export default slice.reducer
