import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
  error: null,
  isFetchingEntryId: false,
  slugToEntryIdMap: {},
}

const reducers = {
  fetchingEntryIdFromSlug: state => {
    state.error = null
    state.isFetchingEntryId = true
  },

  fetchEntryIdFromSlugError: (state, { payload }) => {
    const { error } = payload
    state.error = error
    state.isFetchingEntryId = false
  },

  fetchEntryIdFromSlugSuccess: (state, { payload }) => {
    const { slug, data } = payload

    state.error = null
    state.isFetchingEntryId = false

    if (slug) {
      state.slugToEntryIdMap[slug] = data
    }
  },
}

const slice = createSlice({ initialState, name: 'routeTree', reducers })

export const actions = slice.actions
export default slice.reducer
