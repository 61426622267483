import React from 'react'
import PropTypes from 'prop-types'
import ResourceCard from 'components/admin/ResourceCard'
import {
  PanelContentWrapper,
  PanelWrapper,
  StyledHeaderTwo,
} from './component.styles'

const GetStartedPanel = ({ displayTitle, tabColumns }) => {
  if (tabColumns?.length < 1) {
    return null
  }

  return (
    <PanelWrapper>
      <StyledHeaderTwo>{displayTitle}</StyledHeaderTwo>
      <PanelContentWrapper>
        {tabColumns.map((tabColumn, index) => (
          <ResourceCard {...tabColumn} key={`resource-card-${index}`} />
        ))}
      </PanelContentWrapper>
    </PanelWrapper>
  )
}

GetStartedPanel.propTypes = {
  displayTitle: PropTypes.string,
  tabColumns: PropTypes.array,
}

export default GetStartedPanel
