import React from 'react'
import PropTypes from 'prop-types'
import { ErrorMessage } from 'secondstep-components'
import ProfileCard from 'components/dashboard/ProfileCard/'
import DashboardLeftBox from 'components/dashboard/DashboardLeftBox'
import {
  DashboardCardBox,
  DashboardRightBox,
  DashboardWrapper,
  BoxContent,
} from './component.styles'
import { toUrlSafe } from 'utils/stringHelpers'
import removeRedactedContentfulArray from 'utils/removeRedactedContentfulArray'

const Dashboard = props => {
  const {
    match,
    classes = [],
    contentfulPrograms,
    pageTitle,
    hasUnassignedLicenses,
    lmsSelaPath,
    lmsFTPath,
    lmsTrainings,
    navigationEvent,
    programGroup,
    seedIds,
    unassignedLicenses,
    userProfile,
  } = props
  // the way we define middleSchoolContentfulData and contentfulCourses is temporary and can be refactored
  // when permissions are added. Their purpose is to connect the Create a Class button in the MS ProgramCard

  const contentfulProgramsAvailable = removeRedactedContentfulArray(
    contentfulPrograms,
  )

  const programGroupAvailable = removeRedactedContentfulArray(programGroup)
  const programGroupExists = programGroupAvailable.length > 0

  const { tabName } = match.params

  const activeTabProgramGroup =
    programGroupExists && tabName
      ? programGroupAvailable.find(
          tab => tab.route && toUrlSafe(tab.route) === tabName,
        )
      : {}

  if (!activeTabProgramGroup) return <ErrorMessage statusCode={404} />

  return [
    <DashboardWrapper animation="fadeIn" key="dashboard-wrapper">
      <DashboardCardBox dataTestId="dashboard-card-box">
        <DashboardLeftBox
          activeTabProgramGroup={activeTabProgramGroup}
          classes={classes}
          contentfulProgramsAvailable={contentfulProgramsAvailable}
          hasUnassignedLicenses={hasUnassignedLicenses}
          lmsFTPath={lmsFTPath}
          lmsSelaPath={lmsSelaPath}
          lmsTrainings={lmsTrainings}
          navigationEvent={navigationEvent}
          pageTitle={pageTitle}
          programGroupAvailable={programGroupAvailable}
          seedIds={seedIds}
          tabName={tabName}
          unassignedLicenses={unassignedLicenses}
          userProfile={userProfile}
        />
        <DashboardRightBox>
          <BoxContent>
            <ProfileCard userProfile={userProfile} />
          </BoxContent>
        </DashboardRightBox>
      </DashboardCardBox>
    </DashboardWrapper>,
  ]
}

Dashboard.propTypes = {
  classes: PropTypes.array,
  contentfulPrograms: PropTypes.array,
  hasUnassignedLicenses: PropTypes.bool,
  isContentfulRefetching: PropTypes.bool,
  isLicenseManagerSending: PropTypes.bool,
  lmsFTPath: PropTypes.array,
  lmsSelaPath: PropTypes.array,
  lmsTrainings: PropTypes.array,
  match: PropTypes.object.isRequired,
  navigationEvent: PropTypes.func,
  pageTitle: PropTypes.string,
  programGroup: PropTypes.arrayOf(PropTypes.object).isRequired,
  seedIds: PropTypes.object,
  unassignedLicenses: PropTypes.array.isRequired,
  userProfile: PropTypes.object.isRequired,
}

export default Dashboard
