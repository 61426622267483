export const knownLocales = {
  'en-US': {
    // In the future, we might need to specify that this is American English
    autonym: 'English',
    english: 'English',
  },
  'es-US': {
    // In the future, we might need to specify that this is español estadounidense
    autonym: 'Español',
    english: 'Spanish',
  },
  ar: {
    autonym: 'العربية',
    english: 'Arabic',
  },
  vi: {
    autonym: 'Tiếng Việt',
    english: 'Vietnamese',
  },
  hmn: {
    autonym: 'Hmoob',
    english: 'Hmong',
  },
  ko: { english: 'Korean', autonym: '한국어' },
  tl: { english: 'Tagalog', autonym: 'Tagalog' },
  ru: {
    autonym: 'Русский',
    english: 'Russian',
  },
  so: {
    autonym: 'Af Soomaali',
    english: 'Somali',
  },
  ht: {
    autonym: 'Kreyòl Ayisyen',
    english: 'Haitian Creole',
  },
  'zh-Hans': {
    autonym: '中文（简体）',
    english: 'Chinese (simplified)',
  },
  'zh-Hant': {
    autonym: '中文（繁體）',
    english: 'Chinese (traditional)',
  },
  'fr-CA': {
    autonym: 'Français canadien',
    english: 'French (Canada)',
  },
}

// This function is only for *inline translations* (where we must show the English name beside the autonym)
export function getLabelFor(locale) {
  if (!knownLocales[locale]) {
    return locale
  }

  const localeInfo = knownLocales[locale]

  if (!localeInfo.autonym) {
    return localeInfo.english
  }

  if (localeInfo.autonym === localeInfo.english) {
    return localeInfo.english
  }

  return `${localeInfo.english}, ${localeInfo.autonym}`
}

/**
 * Create a new array of the locales in targetLocales
 * sorted according to the label for the language. If firstLocale
 * is provided, it will be the first option regardless of its
 * computed place in the list.
 */
export const sortLocales = (targetLocales, firstLocale = undefined) =>
  [...targetLocales].sort((a, b) => {
    if (firstLocale) {
      if (a === firstLocale && b === firstLocale) {
        return 0
      }
      if (a === firstLocale) {
        return -1
      }
      if (b === firstLocale) {
        return 1
      }
    }

    const str1 = getLabelFor(a)
    const str2 = getLabelFor(b)
    return str1 < str2 ? -1 : +(str1 > str2)
  })

export function getLocalesFromLanguageToggle(languageToggle) {
  const localeSet = new Set(Object.keys(languageToggle)) // TODO: Remove non-locale fields
  // remove Contentful metadata fields from this entry:
  localeSet.delete('internalTitle')
  localeSet.delete('boundary')
  localeSet.delete('type')
  localeSet.delete('id') // TODO: We're going to have a problem with Indonesian
  localeSet.delete('contentType')
  localeSet.delete('locale')
  localeSet.delete('firstPublishedAt')
  localeSet.delete('publishedAt')
  localeSet.delete('publishedVersion')

  const targetLocales = Array.from(localeSet)
    .filter(locale => languageToggle[locale]) // Only include enabled locales
    .map(locale => locale.replace(/^LOCALE_/, '')) // Remove LOCALE_ prefix
    .map(locale => locale.replace(/([a-z]+)([A-Z]+[a-z]*)/, '$1-$2')) // Convert camelCase to kebab-case
    .map(locale => {
      // need to do this for mapping between different data sets
      if (locale === 'fr-Ca') {
        return 'fr-CA'
      } else if (locale === 'es-Us') {
        return 'es-US'
      } else {
        return locale
      }
    })
    .filter(locale => locale !== 'en-Us') // Remove en-US if present because the inline translation already has the English text above

  return targetLocales
}

export function formatFileLinks(fileLinks) {
  if (typeof fileLinks !== 'object' || fileLinks === null) {
    throw new Error('Invalid input: fileLinks must be an object')
  }

  const formattedLinks = {}

  Object.keys(fileLinks).forEach(locale => {
    let formattedLocale = locale.toLowerCase()

    // Specific transformations based on mapping requirements
    switch (formattedLocale) {
      case 'es-us':
        formattedLocale = 'es-US'
        break
      case 'fr-ca':
        formattedLocale = 'fr-CA'
        break
      case 'zh-hant':
        formattedLocale = 'zh-Hant'
        break
      case 'zh-hans':
        formattedLocale = 'zh-Hans'
        break
      default:
        break
    }

    formattedLinks[formattedLocale] = fileLinks[locale]
  })

  return formattedLinks
}
