import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BaseContainer, AwardIcon } from 'secondstep-components'

export const TrainingStartIcon = styled(AwardIcon)`
  color: ${themeGet('colors.blueGray')};
  height: 33px;
  width: 24px;
`

export const ImageRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
  margin: 0 57px;
`

export const TextRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  ${themeGet('breakpoints.mobile')} {
    flex-direction: column;
  }
`

export const Title = styled.span`
  font-family: ${themeGet('font.molde.medium')};

  ${themeGet('breakpoints.mobile')} {
    line-height: 16px;
  }
`

export const DateAndTitle = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('font.molde.regular')};

  ${themeGet('breakpoints.mobile')} {
    flex-direction: row;
    margin-bottom: 10px;
  }
`

export const DottedLineWrapper = styled.div`
  width: 0;
  display: flex;
  align-items: baseline;
  justify-content: center;

  svg {
    top: 8px;
    overflow: visible;
    position: relative;
  }

  ${themeGet('breakpoints.mobile')} {
    display: none;
  }
`

export const IconWrapper = styled.div`
  ${themeGet('breakpoints.mobile')} {
    width: 40px;
  }
`

export const DateAndTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${themeGet('breakpoints.mobile')} {
    align-items: start;
  }
`

export const StyledContainer = styled(BaseContainer).attrs(() => ({
  as: 'section',
}))`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.25rem;
  width: 100%;
  padding: 0;

  ${themeGet('breakpoints.mobile')} {
    min-width: 0;
    margin-bottom: -10px;
  }
`
