import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  BackpackIcon,
  Hairline,
  SearchSelect,
  TelescopeV2Icon,
  TextLink,
} from 'secondstep-components'

export const InlineTextLink = styled(TextLink)`
  display: inline;
`
export const AdminSearchSelectExplore = styled(SearchSelect)`
  padding: 0.125rem 1.5rem 0.125rem 0;

  button {
    background-color: transparent;
    border: 1px solid transparent;
    color: ${themeGet('colors.blue')};
    height: unset;
    justify-content: flex-start;
    padding: 0;
    z-index: 2;
    transition: all 0.2s ease;

    svg {
      color: ${themeGet('colors.blue')} !important;
      height: 22.5px;
      width: 22.5px;

      &:last-of-type {
        width: 14px;
        height: 12px;
        margin-left: auto;
      }
    }

    &:hover {
      border-bottom: 1px solid ${themeGet('colors.blue')};
      border-radius: 0;
      color: ${themeGet('colors.secondStepBlue')};
      transition: all 0.2s ease;

      svg {
        color: ${themeGet('colors.secondStepBlue')} !important;
      }
    }

    &:focus {
      border-color: transparent;
    }
  }

  span {
    font-family: ${themeGet('fontFamilySemibold')};
    padding-right: 0.5rem;
    padding-left: 0.75rem;
  }

  [class*='LabelWrapper'] {
    display: none;
  }

  [class*='DropdownButton'] {
    border-radius: 15px;
    svg:first-child {
      margin-top: 0;
      margin-bottom: 0;
      transform: rotate(0deg);
    }
    span {
      padding-left: 8px;
      padding-right: 8px;
    }
  }
  [class*='DropdownOptions'] {
    border: none;
    border-radius: 0.3125rem;
    box-shadow: 0 0 22px rgba(0, 0, 0, 0.2);
    margin-top: 0.25rem;
    padding: 0.25rem 0;
    width: max-content;
    min-width: 100%;

    button {
      padding: 0.125rem 1rem;

      svg {
        padding-left: 0.5rem;
      }

      &:hover,
      &.arrow-selected {
        span {
          color: ${themeGet('colors.white')};
        }
      }
    }

    span {
      color: ${themeGet('colors.blue')};
    }

    button:not(:last-child) {
      border-bottom: solid 1px ${themeGet('colors.paleGray')};
    }
  }

  [class*='DropdownSpaceFiller'] {
    display: none;
  }
`

export const ExploreContainer = styled.div`
  text-align: left;
  background: ${themeGet('colors.white')};
  width: 100%;
  padding: 1.5625rem 1.75rem 1.25rem 1.25rem;
  position: relative;
  background: ${themeGet('colors.white')};
  border-radius: 10px;
  box-sizing: border-box;

  [class*='BoxContent'] {
    width: 100%;
    padding: 0;
  }
`

export const LinkImage = styled.img.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))``

export const ProgramResource = styled.span`
  :first-child {
    width: 100%;
  }
  h3 {
    font-family: ${themeGet('font.sharpSlab.semibold')};
    font-size: 1rem;
    line-height: 1.5rem;
    color: ${themeGet('colors.darkBlue')};
    margin-right: 1.25rem;
  }
  ${themeGet('breakpoints.mobile')} {
    margin-top: 0.625rem;
  }
`

export const ProgramResourceContainer = styled.div`
  padding: 1.25rem;
  background: ${themeGet('colors.sela.gray3')};
  border-radius: 10px;
  margin-bottom: 1.25rem;
  &:last-child {
    margin-bottom: 0;
  }
`

export const ProgramResourceRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${themeGet('breakpoints.mobile')} {
    flex-direction: column;
    align-items: flex-start;
  }
`

export const StyledBackpackV2Icon = styled(BackpackIcon)`
  svg: {
    color: ${themeGet('colors.k5kit4')};
    height: 35px;
  }
`

export const StyledTelescopeIcon = styled(TelescopeV2Icon)`
  svg: {
    color: ${themeGet('colors.k5kit4')};
    height: 35px;
  }
`

export const SupportLinksWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-items: start;
  align-items: center;
`

export const Title = styled.h3`
  font-weight: normal;
  font-family: ${themeGet('font.sharpSlab.semibold')};
  font-size: 1.4375rem;
  line-height: 1.5625rem;
  color: ${themeGet('colors.darkBlue')};
  width: 100%;
`

export const TitleHairline = styled(Hairline)`
  height: 2px;
  margin-top: 0.875rem;
  margin-bottom: 1.25rem;
  background: ${themeGet('colors.paleGray')};
`

export const UnitContainer = styled.div`
  background: ${themeGet('colors.white')};
  width: 100%;
  padding: 1.25rem;
  border-radius: 10px;
  margin-top: 1.25rem;
  font-family: ${themeGet('font.molde.regular')};
  h4 {
    font-size: 1rem;
    font-family: ${themeGet('font.molde.medium')};
  }
  span {
    font-weight: bold;
    color: ${themeGet('colors.blue')};
  }
`
