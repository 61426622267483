import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Wrapper = styled.div`
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
  padding: 1.3125rem 1.25rem 0;
  box-sizing: border-box;
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const PrimaryColumn = styled.div`
  flex-grow: 1;
`

export const SecondaryColumn = styled.div`
  flex-shrink: 1;
`
