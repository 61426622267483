import styled from 'styled-components'
import { themeGet } from 'styled-system'
import ReportsModuleBox from 'components/ReportsModuleBox'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

export const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1195px;
  padding: 0 1.25rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media (max-width: 390px) {
    padding: 0 1rem;
  }
`

export const ErrorWrapper = styled(ReportsModuleBox)`
  display: flex;
  align-items: center;
  padding: 1.3125rem 1.25rem 13.25rem;
  color: ${themeGet('colors.deleteRed')};
  font-family: ${themeGet('font.molde.medium')};

  svg {
    margin-right: 0.5rem;
    color: ${themeGet('colors.deleteRed')};
  }
`

export const Footer = styled(ReportsModuleBox)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1.375rem 1.125rem;

  p:not(:last-child) {
    margin-block-end: 0.625rem;
  }

  ${themeGet('breakpoints.mobile')} {
    padding: 0.875rem 1rem 1rem;
  }
`
