import { createSelector } from 'reselect'

const selectSites = state => state.licenseManager.sites
const getSiteId = (_, siteId) => siteId

export const selectSiteById = createSelector(
  [selectSites, getSiteId],
  (sites, siteId) => {
    let site
    if (siteId && sites) {
      site = sites?.find(site => site.id === siteId)
    }
    return site
  },
)

export default {
  selectSiteById,
}
