import variables from './variables'
import landingBannerBlob from 'experienceComponents/LandingBanner/assets/landingBannerBlobsOST.svg'
import subLandingBannerBlob from 'experienceComponents/SubLandingBanner/assets/subLandingBannerBlobsOST.svg'

export default {
  meta: { themeName: 'ostKit23' },

  globalNav: {
    background: variables.colors.ost4,
    iconHoverBackground: variables.colors.opacity_20,
  },
  colors: {
    contentSetWrapper: {
      background: {
        color: variables.colors.ost3,
      },
    },
    heading: {
      level: {
        1: variables.colors.ost3,
        2: variables.colors.ost4,
        3: variables.colors.ost5,
      },
    },
    icon: {
      hover: {
        path: {
          fill: variables.colors.ost5,
        },
        span: {
          color: variables.colors.ost5,
        },
      },
      path: {
        fill: variables.colors.ost4,
        stroke: variables.colors.ost4,
      },
    },
    underline: {
      backgroundColor: {
        active: variables.colors.ost4,
        notActive: variables.colors.transparent,
        hover: variables.colors.ost5,
      },
    },
    backgrounds: {
      primary: variables.colors.ost4,
      secondary: variables.colors.white,
      tertiary: variables.colors.backgroundNeutralGray,
    },
    buttons: {
      primaryBackground: variables.colors.ost4,
      primaryBackgroundHover: variables.colors.ost3,
      primaryBackgroundDisabled: variables.colors.buttonDisabledKit,
      secondaryText: variables.colors.darkGray,
      secondaryOutline: variables.colors.gray600,
      tabActive: variables.colors.ost2,
      tabDefault: variables.colors.ost5,
      header: {
        level: {
          5: variables.colors.ost7,
        },
      },
    },
    labels: {
      primaryBackground: variables.colors.ost4,
      primaryHoverBackground: variables.colors.ost5,
    },
    links: {
      primary: variables.colors.ost3,
      primaryHover: variables.colors.ost5,
    },
    programCard: {
      background: {
        color: variables.colors.ost4,
      },
    },
    themeHighlight: variables.colors.ost4,
    themePrimary: variables.colors.ost4,
  },
  // TODO: LEARN-9633, move backround images and colors to separate objects
  banners: {
    contentTypeDescriptionBackgroundColor: variables.colors.darkishGray,
    headerTextColor: variables.colors.white,
    headerTextFont: 'Sharpslab-extrabold',
    headerTextSize: '42px',
    bannerTextColor: variables.colors.white,
    landingBackgroundColor: variables.colors.ost3,
    landingBackgroundImage: landingBannerBlob,
    sublandingBackgroundColor: variables.colors.ost4,
    sublandingBackgroundImage: subLandingBannerBlob,
    alignment: 'center',
    textAlignment: 'center',
  },
  anchorNavigation: {
    borderBottomStyle: 'none',
    borderBottomColor: 'none',
    borderBottomWidth: 'none',
    boxShadow: `0px 0px 10px ${variables.colors.gray300}`,
  },
  callouts: {
    headerTextColor: variables.colors.ost3,
    headerTextFont: 'Sharpslab-extrabold',
    headerTextSize: '28px',
    bodyTextColor: variables.colors.black,
    backgroundColor: variables.colors.white,
    iconBorderRadius: '0.688rem 0.688rem 2.813rem 0.688rem',
  },
  contentSetWrapper: {
    border: {
      radius: '0.688rem',
    },
  },
  fonts: {
    family: {
      level: {
        6: variables.font.molde.medium,
      },
    },
  },
  resources: {
    backgroundColorBottom: variables.colors.backgroundNeutralGray,
  },
}
