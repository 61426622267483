import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import LessonCollapsible from 'experienceComponents/LessonCollapsible'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { flattenArrayOfLessonPdfs } from './helpers'
import { getPresentationLink } from 'utils/playerHelper'
import {
  BULLYING_PATH,
  INSTANCE_MODE,
  LOCALE_US,
  PREVIEW_MODE,
  UNIT_RBH,
} from './constants'

export const Lesson = ({
  attempted,
  contentfulLesson,
  dataTestId,
  gradeTheme,
  instance,
  isDone,
  isOpen,
  lessonIndex,
  navigationEvent,
  pathname,
  scoId,
  setALessonIsOpen,
  setSelectedLessonIndex,
  selectedLessonABIndex,
}) => {
  const launchLesson = buttonAttemptStatus => {
    let unitText = ''
    if (pathname?.includes(BULLYING_PATH)) {
      unitText = UNIT_RBH
    } else {
      unitText =
        pathname
          ?.split('/')
          ?.pop()
          ?.replace('-', '') || ''
    }
    return getPresentationLink({
      attempted: buttonAttemptStatus,
      entryId: contentfulLesson?.presentation?.id,
      theme: gradeTheme,
      instance,
      lessonIndex,
      unitText,
      locale: LOCALE_US,
      mode: instance ? INSTANCE_MODE : PREVIEW_MODE,
      navigationEvent,
      scoId,
      selectedLessonABIndex,
    })
  }

  const toggleOpen = () => {
    setSelectedLessonIndex(lessonIndex)
    setALessonIsOpen(isOpen)
  }

  const { contentItem, lessonPdfsArray } =
    getLessonCollapsibleData(contentfulLesson) || {}

  return (
    <LessonCollapsible
      attempted={attempted}
      contentItem={contentItem}
      dataTestId={dataTestId}
      entryId={contentfulLesson?.presentation?.id}
      isDone={isDone}
      isOpen={isOpen}
      launchLesson={launchLesson}
      lessonIndex={lessonIndex}
      lessonPdfsArray={lessonPdfsArray}
      navigationEvent={navigationEvent}
      toggleOpen={toggleOpen}
    />
  )
}

Lesson.propTypes = {
  attempted: PropTypes.string.isRequired,
  contentfulLesson: PropTypes.object.isRequired,
  dataTestId: PropTypes.string.isRequired,
  gradeTheme: PropTypes.string,
  instance: PropTypes.string,
  isDone: PropTypes.bool,
  isOpen: PropTypes.bool,
  lessonIndex: PropTypes.number.isRequired,
  lessonPdfsArray: PropTypes.array,
  match: PropTypes.object.isRequired,
  navigationEvent: PropTypes.func,
  pathname: PropTypes.string,
  scoId: PropTypes.number,
  selectedLessonABIndex: PropTypes.oneOf([0, 1]),
  setALessonIsOpen: PropTypes.func.isRequired,
  setSelectedLessonIndex: PropTypes.func.isRequired,
}

export const getLessonCollapsibleData = (entry = {}) => {
  const contentItem = entry
  const { lessonPdfs, lessonPlan, studentHandout } = entry
  const lessonPdfsArray = flattenArrayOfLessonPdfs([
    lessonPlan,
    studentHandout,
    lessonPdfs,
  ])

  return { contentItem, lessonPdfsArray }
}

const mapStateToProps = ({ router }) => ({
  pathname: router.location.pathname,
})

export default connect(
  mapStateToProps,
  NavigationHelper.mapDispatchToProps,
)(Lesson)
