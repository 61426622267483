import React from 'react'
import PropTypes from 'prop-types'
import { toUrlSafe } from 'utils/stringHelpers'
import {
  StyledBackpackIcon,
  StyledAwardIcon,
  StyledTelescopeIcon,
} from './component.styles'
import ProgramTile from './ProgramTile'
import TilesGroup from './TileGroup'

const tileIconMap = {
  explore: StyledTelescopeIcon,
  createClass: StyledBackpackIcon,
  training: StyledAwardIcon,
}

export const FirstTimeClasses = props => {
  const { show, tileGroups, ...rest } = props
  const content = tileGroups.map((tileGroup, groupIndex) => {
    const tiles = tileGroup.tiles.map((tile, tileIndex) => {
      const { tileType, title, description, button } = tile

      let Icon = tileIconMap[tileType]

      if (!Icon) {
        console.warn(
          `invalid tileType props provided '${tileType}'. Choosing a default icon.`,
        )
        Icon = StyledTelescopeIcon
      }

      return (
        <ProgramTile
          button={button}
          dataTestId={`${toUrlSafe(tileGroup.name)}-${toUrlSafe(title)}-tile`}
          description={description}
          icon={<Icon aria-hidden="true" role="presentation" />}
          key={`${toUrlSafe(tileGroup.name)}-${toUrlSafe(title)}-tile`}
          tabIndex={0}
          title={title}
        />
      )
    })

    return (
      <TilesGroup
        groupName={tileGroup.name}
        key={toUrlSafe(tileGroup.name)}
        show={show}
        {...rest}
      >
        {tiles}
      </TilesGroup>
    )
  })

  return <> {content} </>
}

const tilesShape = PropTypes.shape({
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  button: PropTypes.node.isRequired,
  tileType: PropTypes.oneOf(['explore', 'createClass', 'training']).isRequired,
})
const tileGroupShape = PropTypes.shape({
  name: PropTypes.string.isRequired,
  tiles: PropTypes.arrayOf(tilesShape).isRequired,
})

FirstTimeClasses.propTypes = {
  show: PropTypes.bool.isRequired,
  tileGroups: PropTypes.arrayOf(tileGroupShape).isRequired,
}

export default FirstTimeClasses
