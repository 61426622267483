import React, { Component } from 'react'
import PropTypes from 'prop-types'

import GlobalNav from 'components/GlobalNav'
import ProgramNav from 'components/ProgramNav'
import { SETUP_ADMIN } from './constants'

export default class NavigationLayout extends Component {
  static propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string }),
    name: PropTypes.string,
    navigationEvent: PropTypes.func.isRequired,
    product: PropTypes.shape({
      product: PropTypes.string.isRequired,
    }),
    programs: PropTypes.array,
    roles: PropTypes.array,
    theme: PropTypes.object,
    userClaims: PropTypes.object,
  }

  render() {
    const {
      roles,
      userClaims,
      navigationEvent,
      product,
      programs,
      location,
      theme,
      name,
    } = this.props
    const isSetUpAdmin = roles?.includes(SETUP_ADMIN)
    const {
      hasElemAdminClaim,
      hasHighschoolAdminClaim,
      hasMsAdminClaim,
      hasSelaAdminClaim,
    } = userClaims || {}
    const hasAdminClaim =
      hasElemAdminClaim ||
      hasHighschoolAdminClaim ||
      hasMsAdminClaim ||
      hasSelaAdminClaim

    return (
      <React.Fragment>
        <GlobalNav
          hasAdminClaim={hasAdminClaim}
          isSetUpAdmin={isSetUpAdmin}
          name={name}
          navigationEvent={navigationEvent}
          pathname={location.pathname}
          product={product}
          theme={theme}
        />
        <ProgramNav
          goToProgram={navigationEvent}
          location={location}
          programs={programs}
        />
      </React.Fragment>
    )
  }
}
