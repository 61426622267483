import React from 'react'
import PropTypes from 'prop-types'
import { StyledTooltip } from './component.styles'

const AdminTooltip = ({
  className,
  content,
  dataTestId,
  iconClass,
  label,
  monitorView,
}) => {
  return (
    <StyledTooltip
      className={className}
      content={content}
      dataTestId={dataTestId || label}
      label={label}
      monitorView={monitorView}
    >
      <i className={`fas ${iconClass}`} />
    </StyledTooltip>
  )
}

AdminTooltip.propTypes = {
  className: PropTypes.string,
  content: PropTypes.object,
  dataTestId: PropTypes.string,
  iconClass: PropTypes.string,
  label: PropTypes.string,
  monitorView: PropTypes.bool,
}

AdminTooltip.defaultProps = {
  iconClass: 'fa-question-circle',
}

export default AdminTooltip
