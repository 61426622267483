export const NO_DATA_PLACEHOLDER = 'N/A'

export const MAX_UNITS_PER_CLASS = 5

export const MSP_GRADES_MAP = {
  6: 6,
  7: 7,
  8: 8,
}

export const K8_GRAPH_ENUM_TO_GRADE_NAME_MAP = {
  gradek: 'Kindergarten',
  grade1: 'Grade 1',
  grade2: 'Grade 2',
  grade3: 'Grade 3',
  grade4: 'Grade 4',
  grade5: 'Grade 5',
  grade6: 'Grade 6',
  grade7: 'Grade 7',
  grade8: 'Grade 8',
}

export const K8_GRADE_KEY_TO_GRAPH_ENUM = {
  k: 'gradek',
  1: 'grade1',
  2: 'grade2',
  3: 'grade3',
  4: 'grade4',
  5: 'grade5',
  6: 'grade6',
  7: 'grade7',
  8: 'grade8',
}

export const REPORTING_UNIT_SUMMARY_TO_LEARN_GRADES = {
  K: 'grade-k',
  '1': 'grade-1',
  '2': 'grade-2',
  '3': 'grade-3',
  '4': 'grade-4',
  '5': 'grade-5',
  '6': 'grade-6',
  '7': 'grade-7',
  '8': 'grade-8',
}

export const SORTED_GRADE_IDX = {
  'grade-k': 0,
  'grade-1': 1,
  'grade-2': 2,
  'grade-3': 3,
  'grade-4': 4,
  'grade-5': 5,
  'grade-6': 6,
  'grade-7': 7,
  'grade-8': 8,
}
