import styled from 'styled-components'
import { Box } from 'grommet'
import { themeGet } from 'styled-system'
import { PipeIcon, TextButton } from 'secondstep-components'

export const LinksBox = styled(Box)`
  flex-direction: row;
  align-items: center;

  ${PipeIcon} {
    color: ${themeGet('colors.buttons.iconBase')};
    margin: 0 0.5rem;
  }

  ${TextButton} {
    text-transform: none;
  }
`
