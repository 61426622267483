import styled, { withTheme } from 'styled-components'
import { themeGet } from 'styled-system'
import { BaseContainer } from 'secondstep-components'

export const TranslationWrapper = styled.div`
  margin-top: 1rem;
  margin-bottom: 1rem;

  .target-locale {
    display: inline;
    & input {
      /* This is a hack to overcome Chrome on Mac's handling of box-sizing: content-box */
      min-width: 30ch;
    }
    & input::placeholder {
      color: ${themeGet('colors.input.text')};
    }
    // Hide labels from display but leave for AT
    label {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute !important;
      width: 1px;
    }
  }
`
export const TranslatedContent = withTheme(styled(BaseContainer)`
  &[lang='ar'],
  &[lang='fa'],
  &[lang='he'],
  &[lang='ha'],
  &[lang='ur'] {
    direction: rtl;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      /* I think this is set somewhere in ss-components; eventually to support rtl, move there */
      text-align: start;
    }
  }

  background: ${({ theme }) => theme?.colors?.inlineTranslation?.background};
  & header {
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: flex-end;
  }

  & footer {
    margin-top: 1rem;
  }
`)

export const TextWrapper = withTheme(styled.div``)
