import NavigationHelper from 'layers/navigation/navigationHelper'

class UnitComponentEvents {
  static onClickAcknowledgements = ({
    match,
    acknowledgements,
    navigationEvent,
  }) => {
    return () => {
      const type = NavigationHelper.types.NEW_TAB
      const url = match.url.split('/unit')[0]
      const urlFragment = acknowledgements?.pageData?.route

      const isDuplicatePath = NavigationHelper.isADuplicatePath(
        url,
        urlFragment,
      )
      if (urlFragment && !isDuplicatePath) {
        navigationEvent(`${url}/${urlFragment}`, type)
      }
    }
  }
}

export default UnitComponentEvents
