import React from 'react'
import PropTypes from 'prop-types'
import { withTheme } from 'styled-components'
import {
  BlockWrapper,
  StyledAdminHsExplorePrograms,
  StyledExplorePrograms,
  StyledImplementationChecklist,
} from './component.styles'

const AdminHsK8Setup = ({ selectedProgram }) => {
  return (
    <BlockWrapper>
      {selectedProgram.name === 'K8' && <StyledExplorePrograms />}
      {selectedProgram.name === 'HS' && <StyledAdminHsExplorePrograms />}
      <StyledImplementationChecklist />
    </BlockWrapper>
  )
}

AdminHsK8Setup.propTypes = {
  selectedProgram: PropTypes.object,
}

export default withTheme(AdminHsK8Setup)
