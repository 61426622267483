/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React, { Component } from 'react'
import removeRedactedContentfulArray from 'utils/removeRedactedContentfulArray'
import ResourcesSubLandingPage from './component'
import { getPageTypeByUrl } from 'utils/getPageTypeByUrl'
import { isPreviewMode } from 'utils/highschoolHelpers'

export default class ResourcesSubLandingPageContainer extends Component {
  mapper = (entry, location, navigationEvent) => {
    const { displayTitle, description, content = [] } = entry || {}
    const fliteredContent = removeRedactedContentfulArray(content)

    const articles = fliteredContent.map(article => {
      const { description: body, displayTitle: title, pageData } = article || {}
      const { image } = article || {}
      const { image: image2 } = image || {}
      const { file } = image2 || {}
      const { url } = file || {}
      const { route } = pageData || {}
      return { body, image: url, link: route, location, navigationEvent, title }
    })
    const banner = { text: description, title: displayTitle }

    entry = { articles, banner }
    return entry
  }

  render() {
    const {
      entry,
      isSelaLeaderPage,
      isSelaProduct,
      location,
      navigationEvent,
    } = this.props || {}
    const parsedEntry = this.mapper(entry, location, navigationEvent) || {}

    const pageType = getPageTypeByUrl(location.pathname)

    return (
      <ResourcesSubLandingPage
        isHsPreview={isPreviewMode()}
        isSelaLeaderPage={isSelaLeaderPage}
        isSelaProduct={isSelaProduct}
        navigationEvent={navigationEvent}
        pageType={pageType}
        {...parsedEntry}
      />
    )
  }
}
