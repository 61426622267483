import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { OutlinedButton } from 'secondstep-components'

export const ButtonsWrapper = styled.div`
  ${OutlinedButton} {
    border-color: ${themeGet('colors.gray600')};
    color: ${themeGet('colors.gray1100')};
  }

  ${themeGet('breakpoints.tablet')} {
    margin-top: 0.5rem;

    button {
      &:nth-child(1) {
        margin: 0;
      }
    }
  }
`

export const MspBullyingUnitNotificationWrapper = styled.div`
  border-radius: 0.8125rem;
  margin-bottom: 1.25rem;
`

export const NotificationWrapper = styled.div`
  align-items: center;
  background: ${themeGet('colors.white')};
  border-radius: 0.8125rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.25rem;
`

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  h5 {
    margin-bottom: 0.25rem;
    margin-left: 0.25rem;
    font-family: ${themeGet('font.molde.semiBold')};
    font-size: 1.0625rem;
  }
  padding: 0.5rem 0;
`

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 0.8125rem 0.8125rem 0 0;
`
