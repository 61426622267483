const knownUnitsInternalTitleMap = {
  'unit-1': 'u1',
  'unit-2': 'u2',
  'unit-3': 'u3',
  'unit-4': 'u4',
  'recognizing-bullying-and-harassment': 'rbh',
}

function findCourseName() {
  const regex = /grade-(k|[1-9])/
  const matches = location.pathname.match(regex)
  return matches && matches.length > 0 ? matches[1] : null
}

// findFn is a function that takes a node and returns a boolean
function findCourseNode(courseTree, findFn) {
  for (const node of courseTree.children) {
    if (findFn && findFn(node)) {
      return node
    }

    if (!node.leaf) {
      const result = findCourseNode(node, findFn)
      if (result) {
        return result
      }
    }
  }
}

export function findLmsUnit({ courseTrees, lmsClass, unitId }) {
  const courseName = findCourseName()
  const courseTree = courseTrees[courseName]
  if (!courseTree) {
    return null
  }

  const unitInternalTitle = knownUnitsInternalTitleMap[unitId]
  const courseNode = findCourseNode(
    courseTree,
    node =>
      node.type === 'unit' && node.internalTitle.includes(unitInternalTitle),
  )
  const lmsUnit = lmsClass.units.find(
    u => u.contentfulID === courseNode.contentfulId,
  )

  return lmsUnit
}
