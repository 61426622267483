import { MAX_SCHOOL_NAME_LENGTH } from './constants'

export function isAdmin(userContext) {
  return userContext?.sites?.[0]?.claims?.includes('admin') || false
}

export function checkIfLessonIsComplete(lessonNodes, courseNodeId) {
  for (const lesson of lessonNodes) {
    // leaf_node_id may also contain presentationId as the suffix, but it always contains courseNodeId at start:
    if (lesson?.leaf_node_id?.startsWith(courseNodeId)) {
      return {
        isComplete: lesson?.is_done || false,
        date: lesson?.done_at || null,
        // only set on educator practices:
        periodProgress: lesson?.period_progress || null,
      }
    }
  }
  return { isComplete: false, date: null }
}

export function findNode(courseTreeNode, courseNodeId) {
  if (courseTreeNode.contentfulId === courseNodeId) {
    return courseTreeNode
  } else {
    for (const child of courseTreeNode.children) {
      const node = findNode(child, courseNodeId)
      if (node) {
        return node
      }
    }
  }
  return null
}

export function formatDate(date) {
  const options = { year: 'numeric', month: 'short', day: 'numeric' }
  return date ? new Date(date).toLocaleString('en-US', options) : null
}

export function formatSchoolName(schoolName) {
  return schoolName?.length > MAX_SCHOOL_NAME_LENGTH
    ? schoolName.substring(0, MAX_SCHOOL_NAME_LENGTH) + '...'
    : schoolName
}
