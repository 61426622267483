import LMSClient from '@cfc/lms-sdk'
import { backoff } from '@cfc/exponential-backoff'
import { get, createType } from 'lib/middleware/api'
import { domains, ENVIRONMENT_SUBDOMAIN } from 'config/env'
import api from 'lib/api/lmsApi'
import apiV2 from 'lib/api/lmsApiV2'
import blobApi from 'lib/api/blobApi'
import { formatError } from 'utils/formatError'
import { isFlagOn } from 'utils/featureFlags'
import Auth0Manager from 'auth/utils/auth0Manager'
import { actions } from './reducer'
import selectors from './selectors'
import { SHOULD_SHOW_IN_MODAL } from './constants'
import { CREATECOURSEINSTANCESCHEMA } from './data'

const createCourseInstance = (courseInstance, programLmsKey) => {
  return async dispatch => {
    dispatch(actions.creatingCourseInstance())

    try {
      if (!courseInstance) throw new Error('Failed to create class')
      await CREATECOURSEINSTANCESCHEMA.validate(courseInstance)
      const response = await api.addClassToInstructor(courseInstance)
      const course = selectors.attachLessonCountToUnits(response.data)
      await dispatch(
        actions.creatingCourseInstanceSuccess({
          courseInstance: {
            ...course,
            grade: courseInstance.grade,
          },
          programLmsKey,
        }),
      )
      // TODO: CreateCourse handleSubmit should look to redux for course
      // not returned data from this function
      return course
    } catch (error) {
      await dispatch(
        actions.creatingCourseInstanceError({
          error: formatError(error, SHOULD_SHOW_IN_MODAL),
        }),
      )
      throw error
    }
  }
}

const createCourseInstanceV2 = (courseInstance, programLmsKey, userContext) => {
  return async dispatch => {
    dispatch(actions.creatingCourseInstance())

    try {
      if (!courseInstance) throw new Error('Failed to create class')
      await CREATECOURSEINSTANCESCHEMA.validate(courseInstance)
      const response = await apiV2.addClassToInstructor(
        courseInstance,
        userContext,
      )
      const course = selectors.attachLessonCountToUnits(response.data)
      await dispatch(
        actions.creatingCourseInstanceSuccess({
          courseInstance: {
            ...course,
            grade: courseInstance.grade,
          },
          programLmsKey,
        }),
      )
      // TODO: CreateCourse handleSubmit should look to redux for course
      // not returned data from this function
      return course
    } catch (error) {
      await dispatch(
        actions.creatingCourseInstanceError({
          error: formatError(error, SHOULD_SHOW_IN_MODAL),
        }),
      )
      throw error
    }
  }
}

const makeAttemptV2 = ({
  registrationId,
  leafNodeId,
  progress,
  periodProgress,
  scormProgress,
  userContextB64,
}) => {
  return async dispatch => {
    dispatch(actions.makingAttempt())

    try {
      const client = new LMSClient({
        environment: ENVIRONMENT_SUBDOMAIN,
      })
      const token = await Auth0Manager.getAccessToken()
      const response = (await client.makeV2Attempt({
        jwt: token,
        registrationId,
        leafNodeId,
        progress,
        periodProgress,
        scormProgress,
        userContextB64,
      })) || { data: 'mocked response' }
      const { data } = response || {}
      await dispatch(
        actions.makingAttemptSuccess({
          attempt: data,
        }),
      )
    } catch (error) {
      await dispatch(
        actions.makingAttemptError({
          error: formatError(error),
        }),
      )
      throw error
    }
  }
}

const createSelaPathInstance = () => {
  return async (dispatch, getState) => {
    const isCreatingClass = getState().lmsManager?.isCreating
    if (isCreatingClass) return

    dispatch(actions.creatingCourseInstance())

    try {
      const response = await api.createSelaClass()
      const { data } = response || {}
      await dispatch(
        actions.creatingCourseInstanceSuccess({
          courseInstance: data,
          programLmsKey: 'Sela',
        }),
      )
    } catch (error) {
      await dispatch(
        actions.creatingCourseInstanceError({
          error: formatError(error),
        }),
      )
      throw error
    }
  }
}

const createFTPathInstance = () => {
  return async (dispatch, getState) => {
    const isCreatingClass = getState().lmsManager?.isCreating
    if (isCreatingClass) return

    dispatch(actions.creatingCourseInstance())

    try {
      const response = await api.createFTClass()
      const { data } = response || {}
      await dispatch(
        actions.creatingCourseInstanceSuccess({
          courseInstance: data,
          programLmsKey: 'SelaFT',
        }),
      )
    } catch (error) {
      await dispatch(
        actions.creatingCourseInstanceError({ error: formatError(error) }),
      )
      throw error
    }
  }
}

const deleteCourseInstance = courseInstance => {
  return async dispatch => {
    dispatch(actions.deletingCourseInstance())

    try {
      await api.deleteClass(courseInstance)
      await dispatch(actions.deletingCourseInstanceSuccess())
    } catch (error) {
      await dispatch(
        actions.deletingCourseInstanceError({
          error: formatError(error, SHOULD_SHOW_IN_MODAL),
        }),
      )
      throw error
    }
  }
}

const deletingCourseCreatedStatus = () => async dispatch =>
  dispatch(actions.deletingCourseCreatedStatus())

//  =======================================================

// This function uses apiMiddleware and it removes the need to create actions and you also specific the url directly removing the need for the lmsApi file
// this points to the dummy route
const fetchOrCreateInstructorProgramFlex = (siteId, userContext) => {
  return async (dispatch, getState) => {
    const state = getState()

    // Should not fetch if already fetching
    if (state?.lmsManager?.isFetchingOrCreatingProgramFlex) return

    dispatch(actions.fetchingOrCreatingInstructorProgramFlex())
    try {
      const response = await backoff(
        () => apiV2.fetchOrCreateInstructorProgramFlex(siteId, userContext),
        3,
      )
      await dispatch(
        actions.fetchingOrCreatingInstructorProgramFlexSuccess(response.data),
      )
    } catch (error) {
      await dispatch(
        actions.fetchingOrCreatingInstructorProgramFlexError({
          error: formatError(error),
        }),
      )
    }
  }
}

const fetchInstructorData = () => {
  const endpoint = '/instructor/me'
  const fullUrl = createEndpoint(endpoint)
  const typesOrdered = [
    createType(actions.fetchingInstructorData.toString(), {}),
    createType(actions.fetchingInstructorDataSuccess.toString(), {}),
    createType(actions.fetchingInstructorDataError.toString(), {}),
  ]
  return get(fullUrl, typesOrdered)
}

const fetchInstructorDataV2 = reqBody => {
  const endpoint = '/instructor/ms'
  const fullUrl = createEndpoint(endpoint, reqBody)
  const typesOrdered = [
    createType(actions.fetchingInstructorDataV2.toString(), {}),
    createType(actions.fetchingInstructorDataV2Success.toString(), {}),
    createType(actions.fetchingInstructorDataV2Error.toString(), {}),
  ]
  return get(fullUrl, typesOrdered)
}

export const createEndpoint = (endpoint, reqBody) => {
  const setV2Endpoint =
    reqBody &&
    (isFlagOn(['middle-school-june-release']) ||
      isFlagOn(['high-school-june-release']))
  const lmsUrl = setV2Endpoint ? domains.LMS_API_V2 : domains.LMS_API
  const baseApiAddress = `${lmsUrl}`
  const url = setV2Endpoint
    ? // passing a fake string as userContextB64 to avoid 502 error that occurs when passing the actual userContextB64
      // ?  `${baseApiAddress}${endpoint}?userContextB64=${encodeURIComponent(btoa(JSON.stringify(reqBody)))}`
      `${baseApiAddress}${endpoint}?userContextB64=mockUserContext`
    : `${baseApiAddress}${endpoint}`
  return url
}

const updateCourseInstance = courseInstance => {
  return async dispatch => {
    dispatch(actions.updatingCourseInstance())

    try {
      const response = await api.updateClass({
        instanceId: courseInstance.instance,
        scoId: courseInstance.scoId,
        title: courseInstance.title,
      })
      const { config } = response || {}
      const { data } = config || {}
      const updatedCourse = JSON.parse(data)
      await dispatch(
        actions.updatingCourseInstanceSuccess({
          courseInstance: updatedCourse,
        }),
      )
      return updatedCourse
    } catch (error) {
      await dispatch(
        actions.updatingCourseInstanceError({
          error: formatError(error, SHOULD_SHOW_IN_MODAL),
        }),
      )
      throw error
    }
  }
}

// TODO: Something needs to happen after we receive a success here to call fetchInstructorData
// Either create a thunk to orchestrate or create saga
const createTrainingRegistration = seedId => {
  return async dispatch => {
    dispatch(actions.creatingTrainingRegistration())
    try {
      await api.postRegistrations(seedId)

      // TODO: Temp solution until after BTS, the below comes from fecthInstructorData which maybe should be turned into a SAGA
      const lmsInstructorFetch = await api.listClassesByInstructor()
      dispatch(actions.creatingTrainingRegistrationSuccess())
      dispatch(actions.fetchingInstructorDataSuccess(lmsInstructorFetch.data))
    } catch (error) {
      dispatch(
        actions.creatingTrainingRegistrationError({
          error: formatError(error),
        }),
      )
    }
  }
}

const fetchTrainingQuestions = () => {
  return async dispatch => {
    dispatch(actions.fetchingTrainingQuestions())

    try {
      const { data } = await api.getTrainingQuestions()
      dispatch(
        actions.fetchingTrainingQuestionsSuccess({
          trainingQuestions: data,
        }),
      )
    } catch (e) {
      console.error(e)
      dispatch(
        actions.fetchingTrainingQuestionsError({ error: formatError(e) }),
      )
    }
  }
}

const deleteLessonProgressReport = () => {
  return async dispatch => {
    dispatch(actions.deletingLessonProgressReport())
  }
}

const deleteHistoricalReports = () => {
  return async dispatch => {
    dispatch(actions.deletingHistoricalReports())
  }
}

// create a new operation here that requests data from LMS and then from blob storage
const fetchHistoricalReports = (licenseId, type) => {
  return async dispatch => {
    dispatch(actions.fetchingHistoricalReportData())
    // call lms to get a list of available reports
    try {
      const { data } = await api.getHistoricalReports(licenseId, type)
      const promises = data.map(report =>
        fetchHistoricalReportDataFromBlobStorage(report),
      )
      const historicalReportData = await Promise.all(promises)
      dispatch(
        actions.fetchingHistoricalReportDataSuccess({
          historicalReportData,
          licenseId,
        }),
      )
    } catch (error) {
      dispatch(actions.fetchingHistoricalReportDataError({ error }))
    }
  }
}

const fetchHistoricalReportDataFromBlobStorage = async ({ url, name }) => {
  // call blobApi to get historical report data
  try {
    const { data } = url ? await blobApi.getBlobData(url) : []
    const historicalReportData = {
      name,
      data,
    }
    return historicalReportData
  } catch (error) {
    console.error(
      'fetchHistoricalReportDataFromBlobStorage failed',
      { error },
      { url },
      { name },
    )
  }
}

const clearErrors = () => {
  return async dispatch => {
    dispatch(actions.clearErrors())
  }
}

const fetchSeedIds = () => {
  return async dispatch => {
    dispatch(actions.fetchSeedIds())
    try {
      const { data } = await api.getTrainingPaths()
      let seedIds = {}

      data?.map(item => {
        if (!item.hasOwnProperty('paths')) {
          seedIds = { ...seedIds, ...item }
        } else {
          const { paths } = item
          Object.keys(item)
            .filter(key => key !== 'paths')
            .map(key => {
              seedIds = {
                ...seedIds,
                [key]: paths?.map(path => Object.values(path)).flat(),
              }
            })
        }
      })

      dispatch(actions.fetchSeedIdsSuccess(seedIds))
    } catch (error) {
      dispatch(
        actions.fetchSeedIdsError({
          error: formatError(error),
        }),
      )
    }
  }
}

const resetState = () => {
  return async dispatch => {
    dispatch(actions.resetCourseInstances())
  }
}

export default {
  clearErrors,
  createCourseInstance,
  createCourseInstanceV2,
  createFTPathInstance,
  createSelaPathInstance,
  createTrainingRegistration,
  deleteCourseInstance,
  deletingCourseCreatedStatus,
  deleteHistoricalReports,
  deleteLessonProgressReport,
  fetchHistoricalReports,
  fetchInstructorData,
  fetchInstructorDataV2,
  fetchOrCreateInstructorProgramFlex,
  fetchSeedIds,
  fetchTrainingQuestions,
  makeAttemptV2,
  resetState,
  updateCourseInstance,
}
