import React from 'react'
import PropTypes from 'prop-types'
import { TextLink } from 'secondstep-components'
import {
  AssignLicensePanel,
  WelcomeMessage,
  MainText,
  SecondText,
  AssignButton,
  SetupPanel,
  SetupWrapper,
  HelpContainer,
} from './component.styles.js'
import PuzzleIcon from './assets/PuzzleIcon'
import NavigationHelper from 'layers/navigation/navigationHelper'
import {
  MAIN_TEXT,
  SECOND_TEXT,
  LICENSE_MANAGEMENT_PATH,
  WELCOME_TEXT,
  FAQ_LINK,
} from './constants'

const AdminSetup = ({ firstName, navigationEvent }) => {
  const goToLicenseManagement = () => {
    const type = NavigationHelper.types.OPEN
    navigationEvent(LICENSE_MANAGEMENT_PATH, type)
  }

  return (
    <SetupWrapper>
      <SetupPanel>
        <WelcomeMessage>
          {WELCOME_TEXT}
          {firstName}!
        </WelcomeMessage>
        <AssignLicensePanel>
          <PuzzleIcon />
          <MainText>{MAIN_TEXT}</MainText>
          <SecondText>{SECOND_TEXT}</SecondText>
          <AssignButton onClick={goToLicenseManagement}>
            Assign Licenses
          </AssignButton>
          <HelpContainer>
            <span>Need help?</span>&nbsp;
            <TextLink href={FAQ_LINK}>See Setup FAQ</TextLink>
          </HelpContainer>
        </AssignLicensePanel>
      </SetupPanel>
    </SetupWrapper>
  )
}

AdminSetup.propTypes = {
  firstName: PropTypes.string,
  navigationEvent: PropTypes.func,
}

export default AdminSetup
