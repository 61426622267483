// for now we use router elements from https://github.com/supasate/connected-react-router#usage
// and in the same way
import {
  ConnectedRouter,
  connectRouter,
  routerMiddleware,
} from 'connected-react-router'

import { navigationEvent } from './store/operations'
import {
  PUSH,
  GO,
  REPLACE,
  NEW_TAB,
  NEW_TAB_SAME_SITE,
  GOFORWARD,
  GOBACK,
  DOWNLOAD_LINK,
  OPEN,
  NAVIGATION_EVENT,
} from './store/types'

export default class NavigationHelper {
  static NAMESPACE = 'router'
  static NAVIGATION_EVENT = NAVIGATION_EVENT

  static types = {
    DOWNLOAD_LINK,
    GO,
    GOBACK,
    GOFORWARD,
    NEW_TAB,
    NEW_TAB_SAME_SITE,
    OPEN,
    PUSH,
    REPLACE,
  }

  static isObject(value) {
    return Object.prototype.toString.call(value) === '[object Object]'
  }

  static isADuplicatePath(originalPathname, newPathname) {
    const isDuplicatePath = originalPathname.endsWith(newPathname)

    return isDuplicatePath
  }

  // enables both usage of passing and involing with mapper and spread and without
  //  connect(NavigationHelper.mapStateToProps(mapper, spread))(Component) -- with mapper
  //  connect(NavigationHelper.mapStateToProps)(Component) -- without mapper
  static mapStateToProps = (mapperOrState, spread) => {
    let mapper, state
    const _mapStateToProps = (state, ownProps) => {
      const router = NavigationHelper.getRouter(state)
      let mappedRouter = mapper ? mapper(router, state, ownProps) : router
      mappedRouter = spread ? { ...mappedRouter } : { router: mappedRouter }
      return mappedRouter
    }
    if (NavigationHelper.isObject(mapperOrState)) {
      state = mapperOrState
      return _mapStateToProps(state)
    }
    mapper = mapperOrState
    return _mapStateToProps
  }

  static mapDispatchToProps = {
    navigationEvent,
  }
  static ConnectedRouter() {
    return ConnectedRouter
  }
  static connectRouter() {
    return connectRouter
  }
  static navigationMiddleware() {
    return routerMiddleware
  }
  static getRouter(state) {
    return state[NavigationHelper.NAMESPACE]
  }
  static getLocation(state) {
    const router = NavigationHelper.getRouter(state)
    return router.location
  }
  static getPathname(state) {
    const location = NavigationHelper.getLocation(state)
    return location.pathname
  }
  static getSearch(state) {
    const location = NavigationHelper.getLocation(state)
    return location.search
  }

  static getQueryParamsByKey(state, keys = []) {
    const search = NavigationHelper.getSearch(state)
    const queryParams = new URLSearchParams(search)
    if (!Array.isArray(keys)) {
      keys = [keys]
    }
    const paramsByKey = {}
    for (const key of keys) {
      const value = queryParams.get(key)
      paramsByKey[key] = value
    }
    return paramsByKey
  }

  static getHash(state) {
    const location = NavigationHelper.getLocation(state)
    return location.hash
  }

  static generateRouteWithQueryParams(route, paramsByKey) {
    const queryString = Object.keys(paramsByKey)
      .map(key => key + '=' + paramsByKey[key])
      .join('&')
    return `${route}?${queryString}`
  }

  static generateUrlWithDepthForRoute(pathname, route, depth) {
    const startSlice = 1
    const endSlice = depth + 1
    const startPath = pathname.split('/').slice(startSlice, endSlice)
    const fullRoute = startPath.reduceRight((result, path) => {
      return `/${path}${result}`
    }, `/${route}`)

    return fullRoute
  }
}
