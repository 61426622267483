import React from 'react'
import PropTypes from 'prop-types'
import { toUrlSafe } from 'utils/stringHelpers'
import { getSlug } from 'pages/Lessons/utils'
import { HeaderFour, ProgressStatusMedium } from 'secondstep-components'
import CreateCourse from 'experienceComponents/CreateCourse'

import {
  CardWrapper,
  InfoBox,
  StyledGradeTile,
  Wrapper,
} from './component.styles'

const ClassCard = React.forwardRef(
  (
    {
      className,
      dataTestId,
      displayTitle,
      gradeDisplayTitle = '',
      instance,
      program,
      onBlur,
      onClick,
      onFocus,
      scoID,
      setFocus,
      totalAmount,
      totalComplete,
    },
    ref,
  ) => {
    const course = {
      instance,
      scoId: scoID,
      title: displayTitle,
    }
    const urlFragment = instance ? toUrlSafe(instance) : ''
    const courseInstancePath = `/product/${program}/lessons/teach/${getSlug(
      gradeDisplayTitle,
    )}?class=${urlFragment}`

    return (
      <Wrapper className="class-card" onBlur={onBlur} onFocus={onFocus}>
        <CardWrapper
          className={className}
          dataTestId={dataTestId}
          onClick={() => onClick(courseInstancePath)}
        >
          <StyledGradeTile
            dataTestId="classGrade"
            displayText={gradeDisplayTitle}
            isStatic
            size="small"
          />
          <InfoBox>
            {displayTitle && (
              <HeaderFour dataTestId="className">{displayTitle}</HeaderFour>
            )}
            <ProgressStatusMedium
              dataTestId={'classProgress'}
              totalAmount={totalAmount}
              totalComplete={totalComplete}
            />
          </InfoBox>
        </CardWrapper>
        <CreateCourse
          course={course}
          courseInstanceName={displayTitle}
          edit
          isClassCardMenu
          key={`create-course-${displayTitle}-${scoID}`}
          ref={ref}
          setFocus={setFocus}
        />
      </Wrapper>
    )
  },
)

ClassCard.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string.isRequired,
  displayTitle: PropTypes.string,
  gradeDisplayTitle: PropTypes.string,
  instance: PropTypes.string,
  onBlur: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  program: PropTypes.string,
  scoID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setFocus: PropTypes.func,
  totalAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalComplete: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
ClassCard.displayName = 'ClassCardForwardedRef'
export default ClassCard
