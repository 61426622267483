import { Logger } from 'logging'

export const sendApplicationInsightsEvent = (key, data) => {
  try {
    if (!key || !data) throw new Error('invalid event')
    Logger.logEvent({
      name: key,
      properties: data,
    })
  } catch (e) {
    console.error('Cannot log event')
  }
}

export const AI_HS_PAGE_USAGE_KEY = 'hs_page_viewed'
export const AI_HS_PAGE_HIT_CLICKED = 'hs_hit_clicked'
export const AI_HS_SEARCH_MATCHED = 'hs_search_matched'
export const AI_HS_FILTER_EVENT = 'hs_filter_event'
