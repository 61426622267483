import React, { Component } from 'react'
import PropTypes from 'prop-types'

import NavigationHelper from 'layers/navigation/navigationHelper'

import { Row, Logo } from './component.styles'

export default class FooterLogoLink extends Component {
  static propTypes = {
    alt: PropTypes.string,
    href: PropTypes.string,
    label: PropTypes.string,
    navigationEvent: PropTypes.func,
    src: PropTypes.string,
  }

  navigateLeaveApp = () => {
    const { navigationEvent, href } = this.props
    href &&
      navigationEvent &&
      navigationEvent(href, NavigationHelper.types.NEW_TAB)
  }
  handleKeyPress = e => {
    if (e.key === 'Enter') this.navigateLeaveApp()
    return e
  }

  render() {
    const { alt, src, label } = this.props
    const id = `footer-logo-link-${label}`

    return (
      <Row data-testid={id}>
        <Logo
          alt={alt}
          label={label}
          onClick={this.navigateLeaveApp}
          onKeyPress={this.handleKeyPress}
          src={src}
          tabIndex={0}
        />
      </Row>
    )
  }
}
