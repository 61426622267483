import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2.5rem;
  padding-bottom: 2rem;
`

export const TopWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  max-width: ${themeGet('breakpoints.sizes.large')};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 3.125rem;
  padding-top: 1.25rem;

  @media screen and (max-width: 91.625rem) {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
  }

  ${themeGet('breakpoints.mobile')} {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`

export const TopLeft = styled.div``

export const TopRight = styled.div``

export const BottomWrapper = styled.div`
  width: 100%;
`
