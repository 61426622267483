import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import AdminGradeList from 'components/AdminGradeList'
import AdminNoClasses from 'components/AdminNoClasses'
import { BHU_CARD_HEADING, BHU_LESSONS_COMPLETED } from './constants'

import {
  BHUBodyWrapper,
  BHUCardContainer,
  BHUCardHeading,
  BHUCardSubHeading,
  BHUStyledHairline,
  LessonsCompleted,
  LessonsTooltip,
} from './component.styles'
import { TOOLTIP_LOCATIONS, TOOLTIP_PROGRAMS } from 'utils/tooltipHelpers'

const AdminBHULessonProgressCard = ({
  classesCreated,
  grades,
  siteId,
  summary,
}) => {
  const decimalToPercentage = decimal => {
    return decimal > 0
      ? decimal < 0.01
        ? Math.ceil(decimal * 100)
        : Math.round(decimal * 100)
      : decimal
  }
  const { unit5 } = summary ?? 0
  const { completedLessons, completionPercentage, totalLessons } = unit5 || {}
  const bhuLessonCompletionPercentage = decimalToPercentage(
    completionPercentage,
  )

  return (
    <BHUBodyWrapper classesCreated={classesCreated}>
      <BHUCardContainer>
        <BHUCardHeading>{BHU_CARD_HEADING}</BHUCardHeading>

        <BHUCardSubHeading>
          <span>
            {`${bhuLessonCompletionPercentage}${BHU_LESSONS_COMPLETED}`}
            <LessonsTooltip
              location={TOOLTIP_LOCATIONS.HEADER_LESSONS}
              monitorView
              programKey={TOOLTIP_PROGRAMS.K8_MONITOR}
            />
          </span>
          <LessonsCompleted>{`${completedLessons} of ${totalLessons} Lessons`}</LessonsCompleted>
        </BHUCardSubHeading>
      </BHUCardContainer>
      {classesCreated === 0 && <AdminNoClasses />}
      {classesCreated > 0 && (
        <Fragment key="body-wrapper">
          <BHUStyledHairline />
          <AdminGradeList bhuStatus={true} grades={grades} siteId={siteId} />
        </Fragment>
      )}
    </BHUBodyWrapper>
  )
}

AdminBHULessonProgressCard.propTypes = {
  classesCreated: PropTypes.number,
  grades: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  siteId: PropTypes.number,
  summary: PropTypes.object,
}

export default AdminBHULessonProgressCard
