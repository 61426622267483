import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { Formik } from 'formik'

import {
  FinishLayoutWrapper,
  LayoutWrapper,
  StyledModal,
} from './container.styles'
import { validationSchema } from './data'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import withApiError from 'layers/errorHandling/apiError/component'
import {
  ERROR_TYPE_CONTENTFUL,
  SHOW_CONTENT_ON_401,
} from 'layers/errorHandling/apiError'
import { Loading } from 'secondstep-components'
import siteOperations from 'store/siteManager/operations'
import UserProgramProfileModal from './component'
import ConfirmationModal from './ConfirmationModal/component'
import StepModalCloseButton from 'components/StepModal/StepModalCloseButton'

const UserProgramProfileModalContainer = props => {
  const {
    apiError,
    currentSite,
    handleClose,
    modals,
    updateUserProgramPreferences,
  } = props
  const { siteId, highSchoolEducatorPreferences, highSchoolSitePreferences } =
    currentSite || {}
  const { studentActivities } = highSchoolSitePreferences || {}
  const [currentStep, setCurrentStep] = useState(0)
  const [showAllUncheckedModal, setShowAllUncheckedModal] = useState()

  const isAdmin = currentSite.claims
    .map(claim => claim.toLowerCase())
    .includes('admin')

  const {
    class: className,
    periods,
    teachesEducatorActivities,
    teachesSchoolwideActivities,
    teachesStudentActivities,
  } = highSchoolEducatorPreferences || {}

  const choiceContent = modals?.[0]
  const allUncheckedModal = modals?.[1]
  const normalConfirmationModal = modals?.[2]

  const showAlertSection = values =>
    (!values.teachesSchoolwideActivities && isAdmin) ||
    (!values.teachesEducatorActivities && !isAdmin)

  const determineConfirmationMessage = values => {
    const {
      teachesEducatorActivities,
      teachesSchoolwideActivities,
      teachesStudentActivities,
    } = values
    const allActivities =
      teachesEducatorActivities ||
      teachesSchoolwideActivities ||
      teachesStudentActivities
    const educatorAndSchoolwide =
      teachesEducatorActivities || teachesSchoolwideActivities
    const educatorAndStudent =
      teachesEducatorActivities || teachesStudentActivities

    if (isAdmin) {
      setShowAllUncheckedModal(
        studentActivities ? !allActivities : !educatorAndSchoolwide,
      )
    } else {
      setShowAllUncheckedModal(
        studentActivities ? !educatorAndStudent : !teachesEducatorActivities,
      )
    }
  }

  const handleSaveClick = async values => {
    const {
      className,
      periods,
      teachesEducatorActivities,
      teachesSchoolwideActivities,
      teachesStudentActivities,
    } = values

    const sitePreferences = {
      highSchool: {
        userProgramPreferences: {
          class: className,
          periods,
          teachesEducatorActivities,
          teachesSchoolwideActivities,
          teachesStudentActivities,
          hasUpdatedProfile: true,
        },
      },
    }
    try {
      await updateUserProgramPreferences(siteId, sitePreferences)
      determineConfirmationMessage(values)
      setCurrentStep(currentStep + 1)
    } catch (error) {}
  }
  const handleBackClick = () => setCurrentStep(currentStep - 1)
  const handleSubmit = () => handleClose()

  const onPeriodChange = (newValue, setFieldTouched, setFieldValue) => {
    let parsedValue = parseInt(newValue, 10)

    if (newValue === '' || newValue === null) {
      parsedValue = null
    } else if (isNaN(parsedValue) || parsedValue < 0) {
      parsedValue = 0
    }

    setFieldTouched('periods', true)
    setFieldValue('periods', parsedValue)
  }

  const isEducatorValid = values => {
    const { className, periods, teachesEducatorActivities } = values
    return (
      (teachesEducatorActivities && periods != null && className != null) ||
      !teachesEducatorActivities
    )
  }

  // TODO: update these functions to be one function that passes the name of the field in
  // Updated the Expandable Component to push the name prop down into the check box to
  // make this available
  const onClassChange = (value, setFieldValue) =>
    setFieldValue('className', value?.props?.children)
  const onTeachesEducatorActivitiesChange = (value, setFieldValue) =>
    setFieldValue('teachesEducatorActivities', value)
  const onTeachesSchoolwideActivitiesChange = (value, setFieldValue) =>
    setFieldValue('teachesSchoolwideActivities', value)
  const onTeachesStudentActivitiesChange = (value, setFieldValue) =>
    setFieldValue('teachesStudentActivities', value)

  return modals === undefined || modals.length === 0 ? (
    <Loading />
  ) : (
    <StyledModal
      dataTestId="user-program-profile-modal"
      isModalOpen={true}
      modalClose={() => handleClose()}
    >
      <Formik
        enableReinitialize={true}
        initialValues={{
          className,
          periods,
          teachesEducatorActivities: teachesEducatorActivities ?? !isAdmin,
          teachesSchoolwideActivities: teachesSchoolwideActivities ?? isAdmin,
          teachesStudentActivities,
        }}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          touched,
          setFieldValue,
          setFieldTouched,
          isValid,
        }) => (
          <>
            {currentStep === 1 && (
              <StepModalCloseButton handleClose={handleClose} />
            )}
            {currentStep === 0 && (
              <LayoutWrapper>
                <UserProgramProfileModal
                  errors={errors}
                  handleSaveClick={() => handleSaveClick(values)}
                  hasApiError={apiError}
                  hasStudentActivities={studentActivities}
                  isAdmin={isAdmin}
                  isEducatorValid={isEducatorValid(values)}
                  isValid={isValid}
                  modalContent={choiceContent}
                  onClassChange={e => onClassChange(e.value, setFieldValue)}
                  onPeriodChange={newValue =>
                    onPeriodChange(newValue, setFieldTouched, setFieldValue)
                  }
                  onTeachesEducatorActivitiesChange={() =>
                    onTeachesEducatorActivitiesChange(
                      !values.teachesEducatorActivities,
                      setFieldValue,
                    )
                  }
                  onTeachesSchoolwideActivitiesChange={() =>
                    onTeachesSchoolwideActivitiesChange(
                      !values.teachesSchoolwideActivities,
                      setFieldValue,
                    )
                  }
                  onTeachesStudentActivitiesChange={() =>
                    onTeachesStudentActivitiesChange(
                      !values.teachesStudentActivities,
                      setFieldValue,
                    )
                  }
                  setFieldValue={setFieldValue}
                  showAlertSection={showAlertSection(values)}
                  touched={touched}
                  values={values}
                />
              </LayoutWrapper>
            )}
            {currentStep === 1 && (
              <FinishLayoutWrapper>
                <ConfirmationModal
                  handleBackClick={handleBackClick}
                  handleSaveClick={handleSubmit}
                  modalContent={
                    showAllUncheckedModal
                      ? allUncheckedModal
                      : normalConfirmationModal
                  }
                />
              </FinishLayoutWrapper>
            )}
          </>
        )}
      </Formik>
    </StyledModal>
  )
}

const mapStateToProps = ({ userProfileManager, siteManager }) => {
  const apiError = !!siteManager.userProgramPreferences?.error

  return {
    apiError,
  }
}

const mapDispatchToProps = {
  updateUserProgramPreferences: siteOperations.updateUserProgramPreferences,
}

const mapper = entry => entry || {}

const options = {
  entryId: 'WtlG0arU4A6SqSVyLJjg4',
  include: 3,
  mapper,
  spread: true,
}

UserProgramProfileModalContainer.propTypes = {
  apiError: PropTypes.bool,
  currentSite: PropTypes.object,
  handleClose: PropTypes.func,
  modals: PropTypes.array,
  updateUserProgramPreferences: PropTypes.func,
}

export default ContentEntryDataGetter(
  withApiError(
    UserProgramProfileModalContainer,
    [ERROR_TYPE_CONTENTFUL],
    [SHOW_CONTENT_ON_401],
  ),
  options,
  connect(mapStateToProps, mapDispatchToProps),
)
