export const MODULES_DROPDOWN_LABEL_TEXT = 'Program Modules'
export const TRACKS_DROPDOWN_LABEL_TEXT = 'Program Tracks'
export const USER_MANAGEMENT_LINK_TEXT = 'Manage Invitations'
export const SELA_LEADER_PROGRAM = 'sela leader'
export const SELA_STAFF_PROGRAM = 'sela staff'
export const SELAFT_LEADER_PROGRAM = 'selaft leader'
export const SELAFT_STAFF_PROGRAM = 'selaft staff'
const leaderTeam = 'Leader Team'
const staff = 'Staff'

export const PROGRAM_TRACK_NAME = {
  [SELA_LEADER_PROGRAM]: leaderTeam,
  [SELA_STAFF_PROGRAM]: staff,
  [SELAFT_LEADER_PROGRAM]: leaderTeam,
  [SELAFT_STAFF_PROGRAM]: staff,
}
