import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Hairline } from 'secondstep-components'

export const AdvisoryActivityDetailsPaneWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding: 1.75rem;
  background-color: ${themeGet('colors.backgroundLightGray')};
  color: ${themeGet('colors.darkGray')};
  margin-bottom: 1.25rem;
  max-width: 350px;

  ${themeGet('breakpoints.tablet')} {
    max-width: 100%;
  }
`

export const MainTitle = styled.div`
  display: inline;
  font-family: ${themeGet('fontFamilyBold')};
  font-size: ${themeGet('font.size.headers.h6')};
  color: ${themeGet('colors.darkGray')};
  border-color: ${themeGet('colors.gray500')};
  border-bottom-width: 1px;
  padding-bottom: 0.75rem;
  margin-bottom: 0.25rem;
`

export const StyledHairline = styled(Hairline)`
  margin-bottom: 1rem;
`

export const TitleAndTextContainer = styled.div`
  display: flex;
  padding-bottom: 0.4rem;
`

export const SubTitle = styled.div`
  display: inline;
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: ${themeGet('font.size.text.base')};
`

export const Text = styled.div`
  display: inline;
  font-family: ${themeGet('fontFamily')};
  font-size: ${themeGet('font.size.text.base')};
  padding-left: 0.313rem;
`

// max-height to keep things aligned between cards with icons being of various sizes
export const Tag = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
  margin-top: 0.5rem;
`

export const ActivityTags = styled.div`
  display: flex;
  flex-direction: column;

  ${Tag} {
    &:first-of-type {
      margin-top: 0;
    }
  }
`

export const TagText = styled.div`
  display: inline;
  font-family: ${themeGet('fontFamily')};
  font-size: ${themeGet('font.size.text.base')};
  padding-left: 0.313rem;
`
