import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { variables } from 'themes'
import Hoverable from 'components/Hoverable'

import { LOG_OUT } from './constants'
import { IconWrapper, MenuItem, MenuItemContainer } from './component.styles'

export default class GlobalNavItem extends Component {
  static propTypes = {
    dataTestIdOverride: PropTypes.string,
    extraPadding: PropTypes.string,
    icon: PropTypes.string,
    iconTopOffset: PropTypes.string,
    index: PropTypes.number,
    label: PropTypes.string,
    link: PropTypes.string,
    onPress: PropTypes.func,
    resetLicenseManagerState: PropTypes.func,
    resetLmsManagerState: PropTypes.func,
    resetUserSessionManagerState: PropTypes.func,
    theme: PropTypes.object,
  }

  onPress = () => {
    const {
      label,
      link,
      onPress,
      resetLicenseManagerState,
      resetLmsManagerState,
      resetUserSessionManagerState,
    } = this.props
    if (onPress) {
      if (label === LOG_OUT) {
        resetLicenseManagerState()
        resetLmsManagerState()
        resetUserSessionManagerState()
        onPress(link)
      } else {
        onPress(link)
      }
    }
  }

  onHover = hover => {
    this.setState({ hover })
  }

  render() {
    const { label, index, theme } = this.props
    const OptionalIcon = this.props.icon
    const dataTestIdOverride = this.props.dataTestIdOverride
    const extraPadding = this.props.extraPadding || '0'
    const { hover } = this.state || {}
    const dataTestIdLabel =
      dataTestIdOverride || (typeof label === 'string' ? label : '')
    return (
      <Hoverable onHover={this.onHover} onPress={this.onPress}>
        <MenuItemContainer extraPadding={extraPadding} hover={hover}>
          <MenuItem
            data-testid={`global-nav-profile-dropdown-item-${dataTestIdLabel}-${index}`}
            hover={hover}
            theme={theme}
          >
            {OptionalIcon && (
              <IconWrapper iconTopOffset={this.props.iconTopOffset || '0'}>
                {/* fill color must be passed as a prop to target specific parts of each SVG: */}
                <OptionalIcon
                  fill={
                    hover
                      ? variables.colors.secondStepBlue
                      : variables.colors.blue
                  }
                />
              </IconWrapper>
            )}
            {label}
          </MenuItem>
        </MenuItemContainer>
      </Hoverable>
    )
  }
}
