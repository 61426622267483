import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Bullet = styled.span.attrs(props => {
  let background
  if (props.color === 'yellow') {
    background = themeGet('colors.yellow')(props)
  }
  if (props.color === 'green') {
    background = themeGet('colors.k5kit4')(props)
  }

  return {
    background,
  }
})`
  display: inline-block;
  height: 0.8125rem;
  width: 0.8125rem;
  margin-right: 0.5rem;
  margin-bottom: 0.25rem;
  border-radius: 50%;
  background: ${({ background }) => background};
`

export const Card = styled.div`
  background-color: ${themeGet('colors.white')};
  border: 2px solid ${themeGet('colors.paleGray')};
  border-radius: 10px;
  box-sizing: border-box;
  font-family: ${themeGet('font.molde.medium')};
  margin-bottom: 1.25rem;
  padding: 1.3125rem 1.25rem 0.75rem;
  width: 100%;
  flex-shrink: 0;
  max-height: 300px;
`

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
`

export const CountDetail = styled.div`
  font-family: ${themeGet('font.molde.regular')};
  display: flex;
  align-items: center;
`

export const HeaderLabel = styled.span`
  font-size: 1.125rem;
`

export const Separator = styled.hr`
  border: 1px solid ${themeGet('colors.paleGray')};
  margin-top: 0.8125rem;
  margin-bottom: 0.875rem;
`

export const Summary = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;

  &:last-of-type {
    margin-bottom: 0.375rem;
  }
`

export const Total = styled.span`
  font-family: ${themeGet('font.sharpSlab.medium')};
  font-size: 2.5rem;
  line-height: 2.6875rem;
`

export const SummaryDetail = styled.div`
  display: flex;
  gap: 1.5rem;
  justify-content: stretch;
`

export const SummaryTotal = styled.span``
