import React from 'react'
import PropTypes from 'prop-types'
import VideoDataGetter from 'experienceComponents/VideoDataGetter'
import { ContentWrapper, RatioWrapper, VideoWrapper } from './component.styles'

const InlineVideo = ({ entryId, locale, variant }) => (
  <ContentWrapper>
    <RatioWrapper>
      <VideoWrapper>
        <VideoDataGetter
          entryId={entryId}
          refetchLocale={locale}
          variant={variant}
        />
      </VideoWrapper>
    </RatioWrapper>
  </ContentWrapper>
)

InlineVideo.propTypes = {
  entryId: PropTypes.string,
  locale: PropTypes.string,
  variant: PropTypes.string,
}

export default InlineVideo
