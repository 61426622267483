export const formatHeader = (label, title, type) => {
  if (!title || !type) return false

  const labelString = label?.split(' ') || {}
  const secondChar = labelString[1]
  const hasANumber = isNaN(secondChar) === false

  const isNumberedModule = label?.includes(type) && hasANumber
  const formattedHeader = isNumberedModule ? `${label}: ${title}` : `${title}`

  return formattedHeader
}

export const formatModuleFileTitle = (label, title) => {
  if (!title) return false

  const labelString = label?.split(' ') || {}
  const secondChar = labelString[1]
  const hasANumber = isNaN(secondChar) === false

  const isNumberedModule = label?.includes('Module') && hasANumber
  const formattedHeader = isNumberedModule ? `${label} - ${title}` : `${title}`

  return formattedHeader
}

export const formatUnitFileTitle = (
  moduleLabel,
  unitLabel,
  moduleTitle,
  unitTitle,
) => {
  if (!unitTitle) return false

  const moduleLabelString = moduleLabel?.split(' ') || {}
  const moduleSecondChar = moduleLabelString[1]
  const moduleHasANumber = isNaN(moduleSecondChar) === false

  const unitLabelString = unitLabel?.split(' ') || {}
  const unitSecondChar = unitLabelString[1]
  const unitHasANumber = isNaN(unitSecondChar) === false

  const isNumberedModule = moduleLabel?.includes('Module') && moduleHasANumber
  const isNumberedUnit = unitLabel?.includes('Unit') && unitHasANumber

  let formattedHeader

  if (isNumberedModule && isNumberedUnit) {
    formattedHeader = `${moduleLabel} ${unitLabel} - ${unitTitle}`
  } else if (isNumberedModule) {
    formattedHeader = `${moduleLabel} - ${unitTitle}`
  } else if (isNumberedUnit) {
    formattedHeader = `${unitLabel} - ${unitTitle}`
  } else {
    formattedHeader = `${moduleTitle} - ${unitTitle}`
  }

  return formattedHeader
}

export const generateDownloadLinkText = (userRole, type) => ({
  downloadLinkText: `Ready to Download: ${userRole} ${type} Report (CSV)`,
  fetchLinkText: `Detailed ${userRole} ${type} Report (CSV)`,
})
