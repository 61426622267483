import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Anchor = styled.a`
  margin-right: 1.75rem;
  font-size: 1.125rem;
  color: ${props =>
    props.active
      ? themeGet('colors.primaryHeader.activeTabText')
      : themeGet('colors.primaryHeader.tabText')};
  text-decoration: ${props => (props.active ? 'underline' : 'none')};
  text-underline-offset: 0.25rem;
  font-family: ${themeGet('fonts.headerTab')};
`

export const BackLink = styled.a`
  color: ${themeGet('colors.primaryHeader.bodyText')};
  text-decoration: none;
  float: left;
`

export const Container = styled.header`
  background-color: ${themeGet('colors.primaryHeader.background')};
  background-image: url(${props => props.backgroundImage});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: bottom;
  color: ${themeGet('colors.primaryHeader.bodyText')};
  margin: 0rem;
  margin-left: calc(-50vw + 50%);
  box-sizing: border-box;
  padding-top: 1.25rem;
  padding-bottom: 1.75rem;
  padding-left: 20px;
  padding-right: 20px;
  min-height: 18rem;
  width: 100vw;
  ${themeGet('breakpoints.tablet')} {
    background-image: url(${props => props.mobileBackgroundImage});
    background-size: 175% 100%;
    text-align: center;
  }
`

export const ContentContainer = styled.div`
  position: relative;
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
  padding-right: 20%;
  margin-left: auto;
  margin-right: auto;
  max-width: 85.375rem;
  min-height: 14rem;
  ${themeGet('breakpoints.large')} {
    padding-right: 30%;
    margin-left: 0px;
    margin-right: 0px;
    min-height: 17rem;
  }
  ${themeGet('breakpoints.medium')} {
    padding-right: 42.5%;
    min-height: 22rem;
  }
  ${themeGet('breakpoints.tablet')} {
    padding-right: 0rem;
    min-height: 23rem;
  }
  ${themeGet('breakpoints.mobileLarge')} {
    min-height: 23rem;
  }
  ${themeGet('breakpoints.mobile')} {
    min-height: 25rem;
  }
`

export const Header1 = styled.h1`
  color: ${themeGet('colors.primaryHeader.bodyText')};
  font-family: ${themeGet('fonts.primaryHeaderRegular')};
  strong {
    font-family: ${themeGet('fonts.primaryHeaderBold')};
  }
  margin-top: 2.5rem;
`

export const LanguageToggleWrapper = styled.div`
  float: right;
  margin-top: -0.25rem;
  margin-right: 1.25rem;
  rect {
    color: ${themeGet('colors.primaryHeader.tabText')};
  }
`

export const TabLinkContainer = styled.nav`
  position: absolute;
  bottom: 3rem;
  ${themeGet('breakpoints.mobileLarge')} {
    bottom: 3.5rem;
    width: 90vw;
    display: flex;
    align-items: center;
    a {
      width: 35vw;
    }
  }
`

export const Paragraph = styled.p`
  font-size: 1.125rem;
  margin-top: 0.5rem;
`
