import { connect } from 'react-redux'

import NavigationHelper from 'layers/navigation/navigationHelper'
import ContentNamespaceGetter from 'layers/content/Hocs/ContentNamespaceGetter'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_CONTENTFUL_PRODUCT,
} from 'layers/errorHandling/apiError'
import { operations as loadingOperations } from 'store/loadingManager'

import Component from './component'

const options = {
  include: 5,
}

const mapDispatchToProps = {
  setAppIsLoadingState: loadingOperations.setIsLoadingState,
  setAppNotLoadingState: loadingOperations.setNotLoadingState,
}
export const mapStateToProps = ({ loadingManager }) => {
  return {
    isLoadingApp: loadingManager?.isLoading,
  }
}

const navigationConnect = connect(
  NavigationHelper.mapStateToProps,
  NavigationHelper.mapDispatchToProps,
)
const loadingConnect = connect(mapStateToProps, mapDispatchToProps)

// This is wrapped with the ContentNamespaceGetter to allow
// /district/supports to render properly.
export default ContentNamespaceGetter(
  withApiError(Component, [
    ERROR_TYPE_CONTENTFUL,
    ERROR_TYPE_CONTENTFUL_PRODUCT,
  ]),
  options,
  'implementation',
  navigationConnect,
  loadingConnect,
)
