import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Bullet, Tooltip } from 'secondstep-components'

export const BodyText = styled.div`
  display: flex;
  align-items: center;
`

export const StyledBullet = styled(Bullet).attrs(props => {
  let backgroundColor
  if (props.color === 'pink') {
    backgroundColor = themeGet('colors.hsPrimaryPink')(props)
  }

  if (props.color === 'darkBlue') {
    backgroundColor = themeGet('colors.darkBlue')(props)
  }

  if (props.color === 'violet') {
    backgroundColor = themeGet('colors.violet')(props)
  }

  if (props.color === 'mediumTeal') {
    backgroundColor = themeGet('colors.mediumTeal')(props)
  }

  return { backgroundColor }
})`
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-right: 0.25rem;
  margin-bottom: 0.125rem;
`

export const StyledTooltip = styled(Tooltip)``

export const TooltipWrapper = styled.div`
  background-color: ${themeGet('colors.linkLight')};
  border: solid 1px ${themeGet('colors.grayBlue')};
  border-radius: 0.375rem;
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
  padding: 0.5rem 1rem 0.75rem;
`

export const TooltipHeader = styled.span`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 0.8125rem;
  line-height: 17px;
`

export const TooltipBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const TooltipBodyText = styled.span`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamily')};
  font-size: 0.8125rem;
  line-height: 17px;
`
