export const HS_MONITOR_OVERALL_PROGRESS = {
  EDUCATOR_PRACTICES: 'Educator Practices',
  SCHOOLWIDE_PRACTICES: 'Schoolwide Practices',
  STUDENT_ACTIVITES: 'Student Activities',
}

export const HS_MONITOR_PRACTICE_TITLES = {
  'Educator Practices': 'Educator Practice Marked Done',
  'Schoolwide Practices': 'Schoolwide Practice Marked Done',
  'Student Activities': 'Student Activities by Grade',
}
