import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const StyledList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  gap: 0.5rem;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 1.75rem;
`
export const FilterChip = styled.button`
  background: none;
  border: 1px solid ${themeGet('colors.gray500')};
  border-radius: 0.13rem;
  padding: 0.44rem 0.65rem 0.25rem 0.65rem;
  cursor: pointer;
  height: 2.25rem;
  .close-icon {
    margin-left: 0.625rem;
  }
  :hover {
    border: 1px solid ${themeGet('colors.themePrimary')};
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  padding-bottom: 1.75rem;
`
const TextButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  text-decoration: underline;
  text-align: left;
  color: ${themeGet('colors.gray625')};
  height: 2rem;
  :hover {
    color: ${themeGet('colors.themePrimary')};
  }
`
export const ClearAllButton = styled(TextButton)`
  width: 5rem;
`

export const OverflowButton = styled(TextButton)`
  width: 5.5rem;
`
