import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const MicrolearningButton = styled.button.attrs(({ isLeader }) => ({
  backgroundHover: isLeader
    ? themeGet('colors.buttons.secondaryAltBackgroundHover')
    : themeGet('colors.buttons.secondaryBackgroundHover'),
}))`
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0;
  border-radius: 0.625rem;
  border: none;
  background: ${themeGet('colors.buttons.secondaryBackground')};
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: ${({ backgroundHover }) => backgroundHover};
    transition: background 0.2s;
  }

  ${themeGet('breakpoints.mobile')} {
    margin: 0;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 2.125rem 0 1.9375rem 1.9375rem;
  margin: 0 1.25rem;
  ${({ isDone }) => isDone && `padding-left: 0;`}
  text-align: left;

  ${themeGet('breakpoints.mobile')} {
    margin: 0;
  }
`

export const IconWrapper = styled.div.attrs(({ isLeader }) => ({
  completeIconColor: themeGet('colors.icons.primary'),
  meetingIconColor: isLeader
    ? themeGet('colors.icons.secondary')
    : themeGet('colors.icons.primary'),
}))`
  display: flex;
  align-items: center;
  margin-bottom: 0.125rem;

  .complete-icon {
    width: 23px;
    height: 23px;
    color: ${({ completeIconColor }) => completeIconColor} !important;
    margin-right: 0.5rem !important;
  }

  .meeting-icon {
    width: 65px;
    height: 39px;
    color: ${({ meetingIconColor }) => meetingIconColor};
  }

  ${themeGet('breakpoints.mobile')} {
    ${({ isDone }) => (isDone ? 'min-width: 85px;' : 'min-width: 55px;')}

    .complete-icon {
      width: 20px;
      height: 20px;
    }

    .meeting-icon {
      ${({ isDone }) => isDone && 'margin-left: 2px;'}
      width: 40px;
      height: 23px;
    }
  }
`

export const TextWrapper = styled.div.attrs(({ isActive, isDone }) => ({
  fontColor: isDone
    ? themeGet('colors.heading.secondary')
    : themeGet('colors.heading.primary'),
  titleFont: isActive ? themeGet('font.lufgaCFC.bold') : 'inherit',
}))`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;

  h5 {
    margin-bottom: 0;
    color: ${({ fontColor }) => fontColor};
    font-size: 1.25rem;
  }

  .title {
    margin-right: 0.75rem;
    font-family: ${({ titleFont }) => titleFont};
  }

  .time {
    white-space: nowrap;
    font-size: 1rem;
    font-family: ${themeGet('font.molde.regular')};
  }

  span {
    margin-top: 0.3125rem;
    color: ${({ fontColor }) => fontColor};
  }

  ${themeGet('breakpoints.mobile')} {
    ${({ isDone }) => isDone && `padding-left: 0;`}

    h5 {
      font-size: 1.125rem;
    }
  }
`
