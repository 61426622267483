import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledContainer,
  Description,
  DateTitle,
  DateWrapper,
  Error,
  ErrorText,
  Title,
  TitleWrapper,
  StyledWarningIcon,
} from './component.styles'
import { CalendarInput } from 'secondstep-components'

const PacingStartDateInput = ({
  title,
  description,
  date,
  handleChange,
  name,
  error,
  touched,
}) => {
  return (
    <StyledContainer>
      <TitleWrapper>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TitleWrapper>
      <DateWrapper>
        <DateTitle>{title} Date</DateTitle>
        <CalendarInput
          date={date}
          handleChange={handleChange}
          hasError={!!error && !!touched}
          name={name}
        />
        {error && touched && (
          <Error>
            <StyledWarningIcon />
            <ErrorText>{error}</ErrorText>
          </Error>
        )}
      </DateWrapper>
    </StyledContainer>
  )
}

PacingStartDateInput.propTypes = {
  date: PropTypes.object,
  description: PropTypes.string,
  error: PropTypes.string,
  errorHeader: PropTypes.string,
  handleChange: PropTypes.func,
  isTouched: PropTypes.bool,
  name: PropTypes.string,
  setFieldValue: PropTypes.func,
  title: PropTypes.string,
  touched: PropTypes.bool,
}

export default PacingStartDateInput
