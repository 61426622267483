import React from 'react'
import PropTypes from 'prop-types'
import TextLinkWithFileIcon from '../../TextLinkWithFileIcon'
import { UnorderedList, ListItem } from './component.styles'

const MaterialsList = ({ id, links }) => {
  const dataTestId = id ? 'materials-list-' + id : 'materials-list'

  return (
    <UnorderedList className={dataTestId} dataTestId={dataTestId}>
      {links.map(link => {
        const { id, href, text, fileType } = link || {}
        return (
          <ListItem key={id}>
            <TextLinkWithFileIcon fileType={fileType} href={href} text={text} />
          </ListItem>
        )
      })}
    </UnorderedList>
  )
}

MaterialsList.propTypes = {
  id: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      fileType: PropTypes.string,
      href: PropTypes.string,
      id: PropTypes.string,
      text: PropTypes.string,
    }),
  ).isRequired,
}

MaterialsList.defaultProps = {
  links: [],
}

export default MaterialsList
