import React from 'react'
import PropTypes from 'prop-types'
import { Grommet } from 'grommet'
import { getThemeFromUrl } from 'utils/productHelpers'
import { ErrorMessage } from 'secondstep-components'
import { getErrorStatusCode } from './errorHelper'

function APIErrorMessage(props) {
  const { additionalDetails, error, errorType, userId } = props || {}

  const statusCode = getErrorStatusCode(error || {})

  console.error(
    `Service Name: {${errorType} -> ${error}, ${JSON.stringify(
      error,
      null,
      2,
    )}`,
  )
  const ssTheme = getThemeFromUrl()

  return (
    <Grommet
      style={{
        backgroundColor: 'transparent',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        minHeight: '100vh',
      }}
      theme={ssTheme}
    >
      <ErrorMessage
        additionalDetails={additionalDetails}
        serviceName={errorType}
        statusCode={statusCode}
        userId={userId}
      />
    </Grommet>
  )
}

APIErrorMessage.propTypes = {
  additionalDetails: PropTypes.string,
  error: PropTypes.object,
  errorType: PropTypes.string,
  userId: PropTypes.number,
}

export default APIErrorMessage
