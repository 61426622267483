import React from 'react'
import PropTypes from 'prop-types'
import VideoDataGetter from 'experienceComponents/VideoDataGetter'
import { useModal } from 'hooks/useModal'
import { BodyCopy } from 'secondstep-components'
import { VideoPlayButton } from 'secondstepVideo'
import { isFlagOn } from 'utils/featureFlags'
import { isElementaryPage } from 'utils/productHelpers'

import {
  CopyBox,
  StyledModal,
  ThumbnailWrapper,
  VideoCalloutButton,
  VideoThumbnail,
  VideoTitle,
} from './component.styles'
import { PLAY, SEARCHKEY } from './constants'

const VideoCallout = props => {
  const {
    dataTestId,
    videoId,
    locale,
    videoDisplayTitle,
    videoLength,
    videoThumbnailUrl,
  } = props
  if (
    isElementaryPage() &&
    isFlagOn(['feature_LEARN-18511-k5-elem-program-flex'])
  ) {
    console.log('[DEBUG] VideoCallout rendering K5 in program flex')
  }

  const isNotReadyToRender = videoId === undefined || !videoThumbnailUrl
  if (isNotReadyToRender) {
    return null
  }

  const { showModal, createOpenHandler, closeModal } = useModal({
    searchKey: SEARCHKEY,
    searchValue: videoId,
  })

  if (!videoId) {
    return null
  }

  return (
    <VideoCalloutButton
      data-testid={`${dataTestId}-modal-open`}
      onClick={createOpenHandler}
      onKeyDown={createOpenHandler}
      role="button"
      tabIndex="0"
    >
      <ThumbnailWrapper>
        <VideoThumbnail imgUrl={videoThumbnailUrl} />
        <VideoPlayButton
          aria-label={`${PLAY} ${videoDisplayTitle ?? 'video'}`}
          tabIndex="-1"
        />
      </ThumbnailWrapper>
      <CopyBox>
        {videoDisplayTitle && (
          <VideoTitle dataTestId="video-callout-title">
            {videoDisplayTitle}
          </VideoTitle>
        )}
        <BodyCopy data-testid="video-callout-copy">{`(${videoLength})`}</BodyCopy>
      </CopyBox>
      <StyledModal
        dataTestId="video-callout-modal"
        modalTitle={videoDisplayTitle}
        onClose={closeModal}
        show={showModal}
        showClose
      >
        {videoId && (
          <VideoDataGetter entryId={videoId} refetchLocale={locale} />
        )}
      </StyledModal>
    </VideoCalloutButton>
  )
}

VideoCallout.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  locale: PropTypes.string,
  videoDisplayTitle: PropTypes.string,
  videoId: PropTypes.string,
  videoLength: PropTypes.string,
  videoThumbnailUrl: PropTypes.string,
}

export default VideoCallout
