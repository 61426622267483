import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  CurrentMarker,
  DateRangeWrapper,
  EndDateText,
  NotStartedText,
  NotStartedWrapper,
  OverallBar,
  ProgressBar,
  ProgressBarWrapper,
  StartDateText,
  StyledTooltip,
  TooltipContainer,
  Wrapper,
} from './component.styles'
import { pluralizeEnglish } from '../../utils/stringHelpers'

const DateRange = ({
  endDateInMs,
  endDateTextFormatted,
  startDateInMs,
  startDateTextFormatted,
}) => {
  const [isHovered, setIsHovered] = useState(false)
  const todaysDate = new Date().getTime()

  const hasStarted = todaysDate > startDateInMs
  const progressBarWidth =
    todaysDate > endDateInMs
      ? 100.0
      : Math.floor(
          (Math.abs(todaysDate - startDateInMs) /
            (endDateInMs - startDateInMs)) *
            100.0,
        )

  const notStartedText = `${startDateTextFormatted}–${endDateTextFormatted}`

  const handlePointerEnter = () => {
    setIsHovered(true)
  }

  const handlePointerLeave = () => {
    setIsHovered(false)
  }

  const handleOnClick = () => {
    setIsHovered(!isHovered)
  }

  const tooltipContent = () => {
    const daysLeft =
      todaysDate > endDateInMs
        ? 0
        : Math.ceil((endDateInMs - todaysDate) / (1000 * 60 * 60 * 24))
    const tooltipMessage = `${pluralizeEnglish(
      daysLeft,
      'day',
      'days',
    )} remaining`
    return <span>{tooltipMessage}</span>
  }

  return (
    <Wrapper
      onClick={handleOnClick}
      onPointerEnter={handlePointerEnter}
      onPointerLeave={handlePointerLeave}
    >
      {hasStarted && (
        <DateRangeWrapper>
          <StartDateText>{startDateTextFormatted}</StartDateText>
          <ProgressBarWrapper>
            <OverallBar />
            <ProgressBar progressBarWidth={progressBarWidth} />
            <CurrentMarker markerPosition={progressBarWidth} />
            {tooltipContent && (
              <StyledTooltip
                content={tooltipContent()}
                isSiblingHovered={isHovered}
                tooltipContentPosition={progressBarWidth}
              >
                <TooltipContainer />
              </StyledTooltip>
            )}
          </ProgressBarWrapper>
          <EndDateText>{endDateTextFormatted}</EndDateText>
        </DateRangeWrapper>
      )}
      {!hasStarted && (
        <NotStartedWrapper>
          <NotStartedText>{notStartedText}</NotStartedText>
        </NotStartedWrapper>
      )}
    </Wrapper>
  )
}

DateRange.propTypes = {
  endDateInMs: PropTypes.number,
  endDateTextFormatted: PropTypes.string,
  startDateInMs: PropTypes.number,
  startDateTextFormatted: PropTypes.string,
}

export default DateRange
