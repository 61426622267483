export const ADMIN_DASHBOARD_SUB_ROUTES = {
  k8: ['program_adoption', 'monitor'],
  hs: ['program_adoption', 'monitor'],
}
export const AVAILABLE_PROGRAMS = ['BPU', 'CPU', 'HS', 'K8', 'SELA']
export const LOADING_MESSAGE = 'Loading Admin dashboard...'
export const PROGRAM_TITLES = {
  BPU: 'Bullying Prevention Unit Training',
  CPU: 'Child Protection Unit Training',
  HS: 'Second Step High School Digital',
  K8: 'Second Step K8 Digital',
  SELA: 'Second Step SEL for Adults',
}
export const SELA_REPORTS_URL = '/reports/sel-for-adults'
