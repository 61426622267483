/* istanbul ignore file */
// TODO: increase code coverage
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
  operations as licenseOperations,
  selectors as licenseSelectors,
} from 'store/licenseManager'

import {
  operations as lmsOperations,
  selectors as lmsSelectors,
} from 'store/lmsManager'
import { operations as loadingOperations } from 'store/loadingManager'
import {
  selectors as contextSelectors,
  operations as userContextOperations,
} from 'auth/stores/userContext'

import { navigationEvent } from 'layers/navigation/store/operations'

import NavigationHelper from 'layers/navigation/navigationHelper'
import {
  DASHBOARD_ROUTE,
  isHighSchoolDashboard,
  isHighSchoolUrl,
} from 'utils/highschoolHelpers'

import {
  findSiteById,
  getSiteFromUrl,
  getDefaultSite,
  isSiteQueryParamPresent,
} from './helpers'

import SiteGuard, { createSiteGuard } from './guard'

import { SITE_QUERY_PARAM, LOADING_TEXT } from './constants'

export default function withSiteContext(Component, options) {
  const { useGuard } = options || {}
  const innerComponent = props => {
    const {
      allSites,
      fetchInstructorData,
      fetchUserSites,
      hsActiveSites,
      instructorData,
      isFetchingSites,
      isFetchingInstructorData,
      isLoadingApp,
      navigationEvent,
      setActiveSite,
      setAppIsLoadingState,
      setAppNotLoadingState,
      userContext,
    } = props
    const inHighSchoolProgram = isHighSchoolDashboard() || isHighSchoolUrl()
    let currentSite

    function navigate(url) {
      navigationEvent(
        `${url.pathname}${url.search}`,
        NavigationHelper.types.REPLACE,
      )
    }

    function isSiteSetup(site) {
      return (
        site.siteId === userContext?.sites?.[0]?.siteId &&
        userContext?.sites?.[0]?.programs?.highSchool?.preferences?.isSetup
      )
    }

    function getSiteAndUserPreferences() {
      const selectedSite = allSites.filter(
        site => currentSite?.siteId === site.id,
      )?.[0]

      currentSite.claims = selectedSite?.claims
      currentSite.highSchoolSitePreferences =
        selectedSite?.sitePreferences?.highSchool
      currentSite.highSchoolEducatorPreferences = {
        ...selectedSite?.sitePreferences?.highSchool?.userProgramPreferences,
      }
    }

    useEffect(() => {
      // hsActiveSites may still be an empty array if the user doesn't have any valid HS sites
      if (!isFetchingSites && (!hsActiveSites || !allSites?.length)) {
        fetchUserSites()
      }
    }, [])

    function render() {
      if (useGuard) {
        return (
          <SiteGuard
            guard={createSiteGuard()}
            navigate={navigate}
            site={currentSite}
          >
            <Component
              {...props}
              activeSites={hsActiveSites}
              currentSite={currentSite}
              instructorData={instructorData}
              navigationEvent={navigationEvent}
              setActiveSite={setActiveSite}
            />
          </SiteGuard>
        )
      }

      if (inHighSchoolProgram) {
        return (
          <Component
            {...props}
            activeSites={hsActiveSites}
            currentSite={currentSite}
            instructorData={instructorData}
            navigationEvent={navigationEvent}
            setActiveSite={setActiveSite}
          />
        )
      }
      return <Component {...props} />
    }

    useEffect(() => {
      // bypass this logic if we're not in the HS program:
      if (!inHighSchoolProgram) {
        return
      }
      const hasInstructorData =
        instructorData && instructorData?.site?.site_id === currentSite?.siteId
      const shouldFetchInstructorData =
        !isFetchingInstructorData &&
        !hasInstructorData &&
        currentSite &&
        currentSite.siteId &&
        currentSite.isSetup

      if (shouldFetchInstructorData) {
        fetchInstructorData(currentSite?.siteId, userContext)
      }
    }, [currentSite?.siteId])

    if (inHighSchoolProgram) {
      const siteFromUrl = getSiteFromUrl(hsActiveSites)
      const defaultSite =
        findSiteById(hsActiveSites, userContext?.hsActiveSiteId) ||
        getDefaultSite(hsActiveSites)

      // Redirect to default site if the query param provided is a invalid id
      if (
        !isFetchingSites &&
        defaultSite &&
        !siteFromUrl &&
        isSiteQueryParamPresent()
      ) {
        const newUrl = `${DASHBOARD_ROUTE}?${SITE_QUERY_PARAM}=${defaultSite.siteId}`
        navigate(newUrl)
      }
      currentSite = siteFromUrl || defaultSite
      // TODO: clean up hack once sitesMe is setup in site prefrences
      currentSite.isSetup = isSiteSetup(currentSite)
      if (currentSite?.siteId) {
        getSiteAndUserPreferences()
      }
      if ((isFetchingSites || isFetchingInstructorData) && !isLoadingApp) {
        setAppIsLoadingState(LOADING_TEXT)
      }

      if (!isFetchingSites && !isFetchingInstructorData && isLoadingApp) {
        setAppNotLoadingState()
      }

      const shouldUpdateActiveSite =
        currentSite && currentSite?.siteId !== userContext?.hsActiveSiteId

      if (shouldUpdateActiveSite) {
        setAppIsLoadingState(LOADING_TEXT)
        setActiveSite(currentSite?.siteId)
        return null
      }

      return currentSite && render()
    }

    return render()
  }

  innerComponent.displayName = 'withSiteContext'

  const mapStateToProps = state => {
    const hsProgramCode = 'HSP'
    const activeSites = licenseSelectors?.selectActiveSites(state) || []
    let hsActiveSites = []
    if (Array.isArray(activeSites)) {
      hsActiveSites = activeSites.filter(s =>
        s?.licenses?.some(l =>
          l?.product?.programCodes?.includes(hsProgramCode),
        ),
      )
    }

    return {
      allSites: activeSites,
      hsActiveSites,
      userContext: contextSelectors.selectProgramContext(state),
      instructorData: lmsSelectors.selectHsInstructorData(state),
      isFetchingInstructorData:
        state.lmsManager.isFetchingOrCreatingProgramFlex,
      isFetchingSites: state.licenseManager.isFetchingUserSites,
      isLoadingApp: state.loadingManager?.isLoading, // Added here
    }
  }

  const mapDispatchToProps = {
    fetchUserSites: licenseOperations.fetchUserSites,
    fetchInstructorData: lmsOperations.fetchOrCreateInstructorProgramFlex,
    setActiveSite: userContextOperations.setSiteContext,
    navigationEvent: navigationEvent,
    setAppIsLoadingState: loadingOperations.setIsLoadingState,
    setAppNotLoadingState: loadingOperations.setNotLoadingState,
  }

  return connect(mapStateToProps, mapDispatchToProps)(innerComponent)
}
