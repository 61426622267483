import { optimisticResponse } from '../../utils/reduxHelpers'
import { getClaimsBasedOnAccess } from '../../utils/accessClaims'

import { NAMESPACE, SUCCESS_TYPE, REQUEST_TYPE, FAILURE_TYPE } from './types'

export const initialState = {}
export const REDUCER_KEY = NAMESPACE

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case REQUEST_TYPE:
      return optimisticResponse(state, REDUCER_KEY)

    case FAILURE_TYPE:
      const error =
        action.payload || action.error || 'Error fetching access info for user'
      return {
        ...state,
        isFetching: false,
        access: {},
        claims: {},
        error,
      }

    case SUCCESS_TYPE: {
      const { access } = action.payload
      const claims = getClaimsBasedOnAccess(access)
      return {
        ...state,
        isFetching: false,
        access,
        claims,
        error: action.error,
        lastFetched: Date.now(),
      }
    }

    default: {
      return state
    }
  }
}
