import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BaseContainer } from 'secondstep-components'

export const Header2 = styled.h2`
  color: ${themeGet('colors.secondaryHeaderBodyItem.bodyText')};
  font-family: ${themeGet('fonts.secondaryHeaderSemiBold')};
  font-size: 1.4375rem;
`

export const StyledContainer = styled(BaseContainer).attrs(() => ({
  as: 'section',
}))`
  margin-left: auto;
  margin-right: auto;
  ${themeGet('breakpoints.large')} {
    margin-left: 20px;
    margin-right: 20px;
  }
  max-width: 85.375rem;
  margin-bottom: 2.5rem;
  padding: 1.1rem 1.25rem;
`

export const Paragraph = styled.p`
  color: ${themeGet('colors.secondaryHeaderBodyItem.bodyText')};
  margin-top: 0.5rem;
`
