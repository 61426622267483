/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { HeaderOne, HeaderFour, BackToTop } from 'secondstep-components'
import {
  Configure,
  Hits,
  InstantSearch,
  useInstantSearch,
} from 'react-instantsearch-hooks-web'
import { history } from 'instantsearch.js/es/lib/routers'
import CustomSortBy from './customSortBy'
import { ALGOLIA_INDEX_NAME } from 'config/env'
import { formatGrades } from 'utils/highschoolHelpers'
import {
  PAGE_HEADER_TITLE,
  RESULTS_PER_PAGE,
  SORT_BY_OPTIONS,
  SORT_BY,
} from './constants'
import {
  PageWrapper,
  CardContainer,
  BodyContainer,
  MainContainer,
  FiltersPanel,
  CurrentlyAppliedFilters,
  MainHeader,
  Background,
  SortByPanel,
  ResultsContainer,
  CardItem,
  BannerSpacer,
} from './component.styles'
import HighSchoolPreviewBanner from 'components/HighSchoolPreviewBanner'
import CustomCurrentRefinements from './refinements'
import Pagination from './pagination'
import AutocompleteSearch from 'components/AutocompleteSearch'
import CustomFilterBy from './customFilterBy'

const HsResourceLibrary = props => {
  const {
    description,
    facetsFilters = [],
    isPreview,
    noResultsText,
    onCardClick,
    searchboxPlaceHolder,
    searchboxTitle,
    searchClient,
  } = props || {}
  const [resultsCount, setResultsCount] = useState(null)
  const [currentRefinements, setCurrentRefinements] = useState([])

  const selectRef = useRef()
  // eslint-disable-next-line no-unused-vars
  const [sortedBy, setSortedBy] = useState(null)

  function NoResults() {
    const { results, uiState } = useInstantSearch()
    setSortedBy(uiState[ALGOLIA_INDEX_NAME]?.sortBy)
    if (!results.__isArtificial && results.nbHits === 0) {
      setResultsCount(0)
      return <p>{noResultsText}</p>
    }
    setResultsCount(results.nbHits)
    return null
  }
  return (
    <WithAlgolia searchClient={searchClient}>
      {isPreview && (
        <>
          <BannerSpacer />
          <HighSchoolPreviewBanner />
        </>
      )}
      <Background>
        <PageWrapper>
          <BackToTop />
          <header>
            <HeaderOne>{PAGE_HEADER_TITLE}</HeaderOne>
            <p>{description}</p>
          </header>

          <BodyContainer>
            <FiltersPanel>
              <HeaderFour id="sort-by-title">{SORT_BY}</HeaderFour>
              <SortByPanel>
                <CustomSortBy
                  aria-labelledby="sort-by-title"
                  items={SORT_BY_OPTIONS}
                  ref={selectRef}
                />
                <CustomFilterBy
                  facetsFilters={facetsFilters}
                  filtersSelected={currentRefinements}
                  resultsCount={resultsCount}
                />
              </SortByPanel>
            </FiltersPanel>

            <MainContainer>
              <MainHeader>
                <HeaderFour>{searchboxTitle}</HeaderFour>
                <AutocompleteSearch
                  data-testid="search-bar"
                  openOnFocus
                  placeholder={searchboxPlaceHolder}
                />
              </MainHeader>
              <ResultsContainer>
                {resultsCount > 0 && (
                  <HeaderFour>
                    {resultsCount} experience{resultsCount === 1 ? '' : 's'}
                  </HeaderFour>
                )}
                <CurrentlyAppliedFilters>
                  <CustomCurrentRefinements
                    setVisibleItems={setCurrentRefinements}
                    visibleItems={currentRefinements}
                  />
                </CurrentlyAppliedFilters>

                <Configure hitsPerPage={RESULTS_PER_PAGE} />

                <CardContainer>
                  <NoResults />
                  <Hits
                    data-testid="hits-container"
                    hitComponent={({ hit }) => {
                      return (
                        <CardItem
                          {...hit}
                          grade={formatGrades(hit.grade)}
                          hit={hit}
                          onClick={() => onCardClick(hit.routeSlug)}
                        />
                      )
                    }}
                    tabIndex={0}
                  />
                </CardContainer>
              </ResultsContainer>
              {resultsCount > RESULTS_PER_PAGE && <Pagination />}
            </MainContainer>
          </BodyContainer>
        </PageWrapper>
      </Background>
    </WithAlgolia>
  )
}

HsResourceLibrary.propTypes = {
  description: PropTypes.string,
  facetsFilters: PropTypes.array,
  facetsOrder: PropTypes.array,
  hit: PropTypes.object,
  noResultsText: PropTypes.string,
  onCardClick: PropTypes.func.isRequired,
  searchClient: PropTypes.object.isRequired,
  searchboxPlaceHolder: PropTypes.string,
  searchboxTitle: PropTypes.string,
}

const routing = {
  router: history({
    createURL({ qsModule, location, routeState }) {
      const indexState = routeState[ALGOLIA_INDEX_NAME] || {}
      const { origin, pathname, hash, search } = location
      // grab current query string, remove the trailing `?` and convert to object
      const queryParameters = qsModule.parse(search.slice(1)) || {}

      // if there is an active search
      if (Object.keys(indexState).length) {
        // merge the search params with the current query params
        Object.assign(queryParameters, routeState)
      } else {
        // remove the search params
        delete queryParameters[ALGOLIA_INDEX_NAME]
      }

      let queryString = qsModule.stringify(queryParameters)

      if (queryString.length) {
        queryString = `?${queryString}`
      }

      return `${origin}${pathname}${queryString}${hash}`
    },
  }),
}

export const WithAlgolia = ({ children, searchClient }) => {
  return (
    <InstantSearch
      indexName={ALGOLIA_INDEX_NAME}
      insights
      routing={routing}
      searchClient={searchClient}
    >
      {children}
    </InstantSearch>
  )
}

WithAlgolia.propTypes = {
  children: PropTypes.any,
  searchClient: PropTypes.object.isRequired,
}
export default HsResourceLibrary
