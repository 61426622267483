import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { BodyCopy, ContainedButton, HeaderTwo } from 'secondstep-components'
import { createHeadingText } from './core'
import {
  ContentWrapper,
  Description,
  DescriptionWrapper,
  Hairline,
  IntroCopy,
  ProductsTooltip,
  ProgramTooltip,
  ProgramToolipText,
  ProgramsWrapper,
  StyledPlainProgramCard,
  Tile,
  TileImg,
  TooltipAnchorCopy,
  QuestionIcon,
} from './component.styles'
import KitActivationModal from 'components/KitActivationModal'
import OnlineProgramsModal from 'components/OnlineProgramsModal'
import {
  INTRO_COPY,
  KIT_HELP,
  KIT_PRODUCT_LIST,
  ONLINE_PROGRAMS_HELP,
  ONLINE_PROGRAM_LIST,
  SUPPORT_LINK,
  FAMILY_ACCESS_LIST,
  FAMILY_ACCESS_HELP,
} from './constants'
import classroomKitImg from './assets/icon_PAK_classroomkits.png'
import onlineProgramImg from './assets/icon_PAK_onlineprograms.png'
import familyAccessImg from './assets/icon_PAK_familyaccess.png'

const FirstTimePakCard = ({ dataTestId, firstName, navigationEvent }) => {
  const WELCOME_HEADING = createHeadingText(firstName)

  const [showModalKit, setShowModalKit] = useState(false)
  const [showModalOnlineProgram, setShowModalOnlineProgram] = useState(false)

  const openModal = (event, isKit) => {
    if (event.type !== 'click' && event.key !== 'Enter') {
      return
    }
    if (isKit && !showModalKit) setShowModalKit(true)
    if (!isKit && !showModalOnlineProgram) setShowModalOnlineProgram(true)
  }

  const closeModal = () => {
    if (showModalKit) setShowModalKit(false)
    if (showModalOnlineProgram) setShowModalOnlineProgram(false)
  }

  return (
    <StyledPlainProgramCard dataTestId={dataTestId} hasBackground>
      <HeaderTwo dataTestId="ftue-header">{WELCOME_HEADING}</HeaderTwo>
      <Hairline />
      <ContentWrapper>
        <IntroCopy>{INTRO_COPY}</IntroCopy>
        <ProgramsWrapper>
          <Tile>
            <TileImg alt="classroom kit" src={classroomKitImg} />
            <DescriptionWrapper>
              <Description>
                For access to{' '}
                <ProductsTooltip
                  content={
                    <ProgramToolipText id="classroom-kits">
                      {KIT_PRODUCT_LIST}
                    </ProgramToolipText>
                  }
                >
                  <TooltipAnchorCopy aria-labelledby="classroom-kits">
                    classroom kit
                  </TooltipAnchorCopy>
                </ProductsTooltip>{' '}
                online resources, enter a kit Activation Key.{' '}
                <ProgramTooltip
                  content={<BodyCopy id="activation-key">{KIT_HELP}</BodyCopy>}
                >
                  <QuestionIcon aria-labelledby="activation-key" />
                </ProgramTooltip>
              </Description>
            </DescriptionWrapper>
            <ContainedButton
              dataTestId={'button-add-activation-key'}
              onClick={event => openModal(event, true)}
              tabIndex="0"
            >
              {'Add Activation Key'}
            </ContainedButton>
          </Tile>
          <Tile>
            <TileImg alt="online program" src={onlineProgramImg} />
            <DescriptionWrapper>
              <Description>
                For{' '}
                <ProductsTooltip
                  content={
                    <ProgramToolipText id="online-programs">
                      {ONLINE_PROGRAM_LIST}
                    </ProgramToolipText>
                  }
                >
                  <TooltipAnchorCopy aria-labelledby="online-programs">
                    online programs
                  </TooltipAnchorCopy>
                </ProductsTooltip>
                , contact your license adminstrator.{' '}
                <ProgramTooltip
                  content={
                    <BodyCopy id="license-management">
                      {ONLINE_PROGRAMS_HELP}
                    </BodyCopy>
                  }
                >
                  <QuestionIcon aria-labelledby="license-management" />
                </ProgramTooltip>
              </Description>
            </DescriptionWrapper>

            <OnlineProgramsModal
              isModalOpen={showModalOnlineProgram}
              modalClose={closeModal}
            />
            <ContainedButton
              dataTestId={'button-access-online-programs'}
              onClick={event => openModal(event, false)}
              tabIndex="0"
            >
              {'Access Online Programs'}
            </ContainedButton>
          </Tile>
          <Tile>
            <TileImg alt="family access" src={familyAccessImg} />
            <DescriptionWrapper>
              <Description>
                For{' '}
                <ProductsTooltip
                  content={
                    <ProgramToolipText id="family-access-list">
                      {FAMILY_ACCESS_LIST}
                    </ProgramToolipText>
                  }
                >
                  <TooltipAnchorCopy aria-labelledby="classroom-kits">
                    family access
                  </TooltipAnchorCopy>
                </ProductsTooltip>{' '}
                to see what your child is learning, enter a family Activation
                Key.{' '}
                <ProgramTooltip
                  content={
                    <BodyCopy id="family-access-key">
                      {FAMILY_ACCESS_HELP}
                    </BodyCopy>
                  }
                >
                  <QuestionIcon aria-labelledby="family-access-key" />
                </ProgramTooltip>
              </Description>
            </DescriptionWrapper>
            <ContainedButton
              dataTestId={'button-add-family-access'}
              onClick={event => openModal(event, true)}
              tabIndex="0"
            >
              {'Add Activation Key'}
            </ContainedButton>
          </Tile>
        </ProgramsWrapper>
        <BodyCopy tabIndex="0">{SUPPORT_LINK}</BodyCopy>
      </ContentWrapper>
      <KitActivationModal
        isModalOpen={showModalKit}
        modalClose={closeModal}
        navigationEvent={navigationEvent}
      />
    </StyledPlainProgramCard>
  )
}

FirstTimePakCard.propTypes = {
  dataTestId: PropTypes.string.isRequired,
  firstName: PropTypes.string,
  isModalOpen: PropTypes.bool,
  modalClose: PropTypes.func,
  navigationEvent: PropTypes.func.isRequired,
}

export default FirstTimePakCard
