import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import NavigationHelper from 'layers/navigation/navigationHelper'
import AdminGradeList from './component'
import { ALL_LESSONS_KEY, UNIT_5_GRADES, UNIT_5_NAME } from './constants'

const AdminGradeListContainer = ({
  bhuStatus,
  grades,
  navigationEvent,
  siteId,
  selectedTab,
}) => {
  const [selectedGrades, selectSelectedGrades] = useState(grades[selectedTab])

  useEffect(() => {
    bhuStatus
      ? selectSelectedGrades(
          grades[UNIT_5_NAME].filter(grade =>
            UNIT_5_GRADES.includes(grade.name),
          ),
        )
      : selectSelectedGrades(grades[selectedTab])
  }, [selectedTab])

  return (
    <AdminGradeList
      bhuStatus={bhuStatus}
      grades={selectedGrades}
      navigationEvent={navigationEvent}
      siteId={siteId}
    />
  )
}

AdminGradeListContainer.propTypes = {
  bhuStatus: PropTypes.bool,
  grades: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  navigationEvent: PropTypes.func,
  selectedTab: PropTypes.string,
  siteId: PropTypes.number,
}

const mapStateToProps = ({
  userSessionManager: {
    adminDashboard: {
      lessons: {
        lessonSelectedRow: { selectedUnitTab },
      },
    },
  },
}) => {
  return {
    selectedTab: selectedUnitTab || ALL_LESSONS_KEY,
  }
}

export default connect(
  mapStateToProps,
  NavigationHelper.mapDispatchToProps,
)(AdminGradeListContainer)
