import React, { createRef, useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import {
  HeaderThree,
  AcademicSuccessIcon,
  HandIcon,
} from 'secondstep-components'
import { toUrlSafe } from 'utils/stringHelpers'
import { getLmsPath, getFtueViewField } from './helper'
import FirstTimeClasses from 'experienceComponents/FirstTimeClasses'
import GradeTile from 'components/dashboard/GradeTile'
import HighSchoolProgramCard from 'components/dashboard/HighSchoolProgramCard'
import K8DigitalProgramCard from 'components/dashboard/K8DigitalProgramCard'
import ModuleCard from 'components/ModuleCard'
import ModuleResourceCard from 'components/ModuleResourceCard'
import PAKActivationCard from 'components/dashboard/PAKActivationCard'
import ProgramCard from 'components/dashboard/ProgramCard'
import ProgramLinkTile from 'components/dashboard/ProgramLinkTile'
import ResourceAndTrainingCard from 'components/dashboard/ResourceAndTrainingCard'
import {
  Content,
  Hairline,
  MyKitsGradeTilesContainer,
  OstTeachLinksWrapper,
  OstStaticLinksWrapper,
  ProgramCardsContainer,
  SelaLoadingDiv,
  SelaModuleWrapper,
  SelaTilesContainer,
  StyledPlainProgramCard,
  TilesContainer,
} from './component.styles'
import {
  DEFAULT_LABEL,
  LOADING_SELA_MESSAGE,
  SUBHEADER_KIT,
  SUBHEADER_OST,
  SUBHEADER_TEACH,
} from './constants'

const ActiveTabProgramCards = props => {
  const {
    activeTabProgramsAvailable,
    classes,
    contentfulCourses,
    contentfulModules,
    currentProgramName,
    firstName,
    getActiveTraining,
    getAvailableLinks,
    getProductType,
    lmsFTPath,
    lmsSelaPath,
    lmsTrainings,
    onComponentClick,
  } = props
  const [focusedClassIndex, setFocusedClassIndex] = useState(null)
  const [isHsInstancedView, setIsHsInstancedView] = useState(false)
  const classMenuButtonRefs = useRef([])
  const prevClassLength = useRef()
  const emptyClassesAddButtonRef = useRef(null)
  const addClassButtonAfterFTUERef = useRef(null)

  if (classMenuButtonRefs.current.length !== classes.length) {
    classMenuButtonRefs.current = Array(classes.length)
      .fill()
      .map((_, i) => classMenuButtonRefs.current[i] || createRef())
  }

  const hasEmptyClasses = prevClassLength.current === 1 && classes.length === 0
  useEffect(() => {
    if (
      prevClassLength.current === null ||
      (focusedClassIndex === null && hasEmptyClasses)
    ) {
      prevClassLength.current = classes.length
      return
    }

    const hasEdited = prevClassLength.current === classes.length
    const hasDeleted = prevClassLength.current > classes.length

    switch (true) {
      case hasEdited:
        setFocus(classMenuButtonRefs.current[focusedClassIndex])
        break
      case hasDeleted:
        if (classes.length > 0) {
          const newFocusClassIndex = Math.max(0, focusedClassIndex - 1)
          setFocusedClassIndex(newFocusClassIndex) // async - wont get updated in useEffect
          setFocus(classMenuButtonRefs.current[newFocusClassIndex])
        } else {
          setFocusedClassIndex(null)
          setFocus(emptyClassesAddButtonRef)
        }
        break
    }

    prevClassLength.current = classes.length
  }, [classes])

  const setFocus = element => {
    if (element) {
      element.current?.focus()
    }
  }

  const onClassFocus = classIndex => {
    setFocusedClassIndex(classIndex)
  }
  const onClassBlur = () => {
    setFocusedClassIndex(null)
  }

  return (
    <ProgramCardsContainer
      dataTestId="dashboard-program-container-exists"
      key="dashboard-program-exists"
    >
      {activeTabProgramsAvailable &&
        activeTabProgramsAvailable.map((program, index) => {
          const { displayTitle, internalTitle, displayTitleLink } = program

          const {
            isFamilyProduct,
            isHighSchool,
            isHighSchoolFamily,
            isK5Product,
            isMindYetiProduct,
            isMsProduct,
            isOstProduct,
            isSelaProduct,
          } = getProductType(internalTitle)

          const lmsPath =
            isSelaProduct && getLmsPath(internalTitle, lmsSelaPath, lmsFTPath)

          const {
            staticLinksAvailable,
            trainingLinksAvailable,
            sortedTeachLinks,
          } = getAvailableLinks(program)

          if (
            !isHighSchool &&
            !isK5Product &&
            !isMsProduct &&
            !isSelaProduct &&
            (staticLinksAvailable || []).length === 0 &&
            (trainingLinksAvailable || []).length === 0 &&
            (sortedTeachLinks || []).length === 0
          )
            return null

          const staticLinksAvailableExist = staticLinksAvailable?.length > 0
          const lmsPathExist = lmsPath?.length > 0
          const headerDataTestId = `subheader-${toUrlSafe(displayTitle)}`
          const firstTimeMS = classes.length === 0
          const programSubheader =
            isK5Product || isMsProduct ? SUBHEADER_TEACH : SUBHEADER_KIT
          return (
            <ProgramCard
              contentfulCourses={contentfulCourses}
              displayTitle={displayTitle}
              displayTitleLink={displayTitleLink}
              firstName={firstName}
              firstTime={firstTimeMS}
              isHighSchool={isHighSchool}
              isHighSchoolFamily={isHighSchoolFamily}
              isHsInstancedView={isHsInstancedView}
              isK5Product={isK5Product}
              isMindYetiProduct={isMindYetiProduct}
              isMsProduct={isMsProduct}
              isOstProduct={isOstProduct}
              isSelaProduct={isSelaProduct}
              key={`${displayTitle}-${index}`}
              onComponentClick={onComponentClick}
              program={internalTitle}
              ref={addClassButtonAfterFTUERef}
            >
              {isSelaProduct && (
                <Content>
                  {staticLinksAvailableExist && (
                    <SelaTilesContainer>
                      {staticLinksAvailable.map((staticLink, index) => {
                        const isOnlyLeader =
                          staticLink.permissionsRoles?.leader &&
                          !staticLink.permissionsRoles?.staff
                        return (
                          staticLink.displayTitle && (
                            <ModuleResourceCard
                              dataTestId={`sela-resource-card-${toUrlSafe(
                                staticLink.displayTitle,
                              )}`}
                              headerTitle={staticLink.displayTitle}
                              isOnlyLeader={isOnlyLeader}
                              key={`sela-resource-card-${index}`}
                              linkLabel={staticLink.linkLabel}
                              onClick={() => onComponentClick(staticLink.path)}
                            />
                          )
                        )
                      })}
                    </SelaTilesContainer>
                  )}
                  {staticLinksAvailableExist && lmsPathExist && <Hairline />}
                  {lmsPathExist &&
                    lmsPath[0].modules.map((lmsModule, index) => {
                      const contentfulModule = contentfulModules.find(
                        module => module.id === lmsModule.contentfulID,
                      )
                      const {
                        permissionsRoles,
                        label,
                        description,
                        displayTitle: moduleTitle,
                      } = contentfulModule
                      const isOnlyLeader =
                        permissionsRoles?.leader && !permissionsRoles?.staff
                      const {
                        attempted,
                        completeLessons,
                        contentfulID,
                        isDone,
                        totalLessons,
                      } = lmsModule
                      return (
                        <SelaModuleWrapper key={contentfulID}>
                          <ModuleCard
                            attempted={attempted}
                            completeLessons={completeLessons}
                            description={description}
                            displayTitle={moduleTitle}
                            entryId={contentfulID}
                            index={index}
                            internalTitle={internalTitle}
                            isDone={isDone}
                            isOnlyLeader={isOnlyLeader}
                            key={`module-car-${index}`}
                            label={label}
                            onClick={onComponentClick}
                            totalLessons={totalLessons}
                          />
                        </SelaModuleWrapper>
                      )
                    })}
                  {(!lmsPath || lmsPath.length === 0) && (
                    <SelaLoadingDiv dataTestId="sela-loading-modules">
                      {LOADING_SELA_MESSAGE}
                    </SelaLoadingDiv>
                  )}
                </Content>
              )}
              {(isK5Product || isMsProduct) &&
                getFtueViewField(program)?.id && (
                  <FirstTimeClasses
                    contentfulCourses={contentfulCourses}
                    entryId={getFtueViewField(program)?.id}
                    onComponentClick={onComponentClick}
                    show={firstTimeMS}
                  />
                )}
              {isK5Product &&
                classes.length > 0 && [
                  <K8DigitalProgramCard
                    classMenuButtonRefs={classMenuButtonRefs}
                    classes={classes}
                    contentfulCourses={contentfulCourses}
                    currentProgramName={currentProgramName}
                    displayTitle={displayTitle}
                    headerDataTestId={headerDataTestId}
                    index={index}
                    internalTitle={internalTitle}
                    isK5Product={true}
                    key={internalTitle}
                    onClassBlur={onClassBlur}
                    onClassFocus={onClassFocus}
                    onComponentClick={onComponentClick}
                    programSubheader={programSubheader}
                    setFocus={setFocus}
                    staticLinksAvailable={staticLinksAvailable}
                  />,
                ]}
              {isMsProduct &&
                classes.length > 0 && [
                  <K8DigitalProgramCard
                    classMenuButtonRefs={classMenuButtonRefs}
                    classes={classes}
                    contentfulCourses={contentfulCourses}
                    currentProgramName={currentProgramName}
                    displayTitle={displayTitle}
                    headerDataTestId={headerDataTestId}
                    index={index}
                    internalTitle={internalTitle}
                    isMsProduct={true}
                    key={internalTitle}
                    onClassBlur={onClassBlur}
                    onClassFocus={onClassFocus}
                    onComponentClick={onComponentClick}
                    programSubheader={programSubheader}
                    setFocus={setFocus}
                    staticLinksAvailable={staticLinksAvailable}
                  />,
                ]}
              {isFamilyProduct && [
                <TilesContainer key="family-resource-links">
                  {staticLinksAvailable?.length > 0 &&
                    staticLinksAvailable.map((staticLink, index) => {
                      const hasVideo = staticLink.internalTitle.includes(
                        'video',
                      )
                      return (
                        staticLink.displayTitle && (
                          <ResourceAndTrainingCard
                            dataTestId={`resource-and-training-card-${toUrlSafe(
                              staticLink.displayTitle,
                            )}`}
                            headerTitle={staticLink.displayTitle}
                            internalTitle={staticLink.internalTitle}
                            key={`resource-and-training-card-family-${index}`}
                            linkLabel={staticLink.linkLabel}
                            onClick={() => onComponentClick(staticLink.path)}
                            videoUrl={hasVideo ? staticLink.path : ''}
                          />
                        )
                      )
                    })}
                </TilesContainer>,
              ]}
              {isHighSchool && sortedTeachLinks?.length > 0 && (
                <HighSchoolProgramCard
                  displayTitle={displayTitle}
                  headerDataTestId={headerDataTestId}
                  setIsHsInstancedView={setIsHsInstancedView}
                  sortedTeachLinks={sortedTeachLinks}
                  staticLinksAvailable={staticLinksAvailable}
                />
              )}
              {isOstProduct && [
                <HeaderThree
                  className="program-subheader"
                  dataTestId={headerDataTestId}
                  key={`subheader-${displayTitle}`}
                >
                  {SUBHEADER_OST}
                </HeaderThree>,

                <TilesContainer key="out-of-school-time-links">
                  {sortedTeachLinks?.length > 0 &&
                    sortedTeachLinks.map(({ displayTitle, path } = {}) => (
                      <OstTeachLinksWrapper key={`${displayTitle}-teach-link`}>
                        <ProgramLinkTile
                          displayTitle={displayTitle}
                          key={`${displayTitle}-program-link`}
                          path={path}
                        />
                      </OstTeachLinksWrapper>
                    ))}
                  {staticLinksAvailable.map(
                    ({ displayTitle, linkLabel, path }) => (
                      <OstStaticLinksWrapper
                        key={`${displayTitle}-static-link`}
                      >
                        <ProgramLinkTile
                          ProgramSupportsIcon={HandIcon}
                          ProgramTrainingIcon={AcademicSuccessIcon}
                          displayTitle={displayTitle}
                          key={`${displayTitle}-program-link`}
                          linkLabel={linkLabel}
                          path={path}
                        />
                      </OstStaticLinksWrapper>
                    ),
                  )}
                </TilesContainer>,
              ]}
              {!isFamilyProduct &&
                !isHighSchool &&
                !isK5Product &&
                !isMsProduct &&
                !isOstProduct &&
                !isSelaProduct && [
                  sortedTeachLinks?.length > 0 && [
                    <HeaderThree
                      dataTestId={headerDataTestId}
                      key={`subheader-${displayTitle}`}
                    >
                      {programSubheader}
                    </HeaderThree>,
                    <MyKitsGradeTilesContainer
                      key={`program-${displayTitle}-teach-links`}
                    >
                      {sortedTeachLinks.map(({ linkLabel, path } = {}) => {
                        const checkedLinkLabel = !linkLabel
                          ? DEFAULT_LABEL
                          : linkLabel
                        return (
                          <GradeTile
                            dataTestId={`grade-tile-${toUrlSafe(
                              checkedLinkLabel,
                            )}`}
                            displayText={checkedLinkLabel}
                            key={`grade-tile-${checkedLinkLabel}`}
                            onClick={() => onComponentClick(path)}
                            target="_self"
                          />
                        )
                      })}
                    </MyKitsGradeTilesContainer>,
                  ],
                  <>
                    {(trainingLinksAvailable?.length > 0 ||
                      staticLinksAvailable?.length > 0) && (
                      <TilesContainer key="non-middle-school-training-and-resource-links">
                        {trainingLinksAvailable?.length > 0 &&
                          trainingLinksAvailable.map((trainingLink, index) => {
                            const {
                              attempted = 'not attempted',
                            } = getActiveTraining(
                              trainingLink.internalTitle,
                              lmsTrainings,
                            )
                            return (
                              <ResourceAndTrainingCard
                                attempted={attempted}
                                dataTestId={`resource-and-training-card-${toUrlSafe(
                                  trainingLink.displayTitle,
                                )}`}
                                headerTitle={trainingLink.displayTitle}
                                key={`resource-and-training-card-non-middle-school${index}`}
                                onClick={() =>
                                  onComponentClick(trainingLink.path)
                                }
                              />
                            )
                          })}
                        {staticLinksAvailable?.length > 0 &&
                          staticLinksAvailable.map((staticLink, index) => {
                            return (
                              staticLink.displayTitle && (
                                <ResourceAndTrainingCard
                                  dataTestId={`resource-and-training-card-${toUrlSafe(
                                    staticLink.displayTitle,
                                  )}`}
                                  headerTitle={staticLink.displayTitle}
                                  internalTitle={staticLink.internalTitle}
                                  isMindYetiProduct={isMindYetiProduct}
                                  key={`resource-and-training-card-static-${index}`}
                                  linkLabel={staticLink.linkLabel}
                                  onClick={() =>
                                    onComponentClick(staticLink.path)
                                  }
                                />
                              )
                            )
                          })}
                      </TilesContainer>
                    )}
                  </>,
                ]}
            </ProgramCard>
          )
        })}
      <StyledPlainProgramCard dataTestId="program-card-mobile-pak-container">
        <PAKActivationCard dataTestId="pak-activation-mobile" />
      </StyledPlainProgramCard>
    </ProgramCardsContainer>
  )
}

ActiveTabProgramCards.propTypes = {
  activeTabProgramsAvailable: PropTypes.array,
  classes: PropTypes.array,
  contentfulCourses: PropTypes.array,
  contentfulModules: PropTypes.array,
  currentProgramName: PropTypes.string,
  firstName: PropTypes.string,
  getActiveTraining: PropTypes.func,
  getAvailableLinks: PropTypes.func,
  getProductType: PropTypes.func,
  getTailGroups: PropTypes.func,
  lmsFTPath: PropTypes.array,
  lmsSelaPath: PropTypes.array,
  lmsTrainings: PropTypes.array,
  onComponentClick: PropTypes.func,
}

export default ActiveTabProgramCards
