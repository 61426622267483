import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withApiError, {
  ERROR_TYPE_REPORTING,
} from 'layers/errorHandling/apiError'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import { navigationEvent } from 'layers/navigation/store/operations'
import { operations as reportsOperations } from 'store/reportsManager'
import {
  operations as licenseOperations,
  selectors as licenseSelectors,
} from 'store/licenseManager'
import { operations as loadingOperations } from 'store/loadingManager'

import { getLicenseIdFromLicenseOptionText } from 'utils/productHelpers'

import SelaReport from './component'
import { LOADING_TEXT } from './constants'

export class SelaReportContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedLicense: {},
      selectedSelaReport: {},
    }
    this.checkToUpdateLoadingState = this.checkToUpdateLoadingState.bind(this)
  }

  componentDidMount() {
    const { activeDigitalLicenses, fetchActiveDigitalLicensesForUser } =
      this.props || {}

    this.checkToUpdateLoadingState()

    if (!Array.isArray(activeDigitalLicenses)) {
      fetchActiveDigitalLicensesForUser()
    } else {
      this.setDefaultLicense()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { isFetching: previousFetching } = prevProps
    const {
      isFetching,
      activeSelaLicenses,
      isLoadingApp,
      setAppNotLoadingState,
    } = this.props
    const { selectedLicense: previousLicense } = prevState
    const { selectedLicense } = this.state
    const doneFetchingLicense = previousFetching && !isFetching
    const doneLoading = doneFetchingLicense && isLoadingApp
    const licenseChanged =
      previousLicense?.licenseId !== selectedLicense?.licenseId

    if (activeSelaLicenses?.length > 0) {
      this.setDefaultLicense()
    }

    if (licenseChanged || doneFetchingLicense) {
      this.updateSelectedLicense()
    }

    this.checkToUpdateLoadingState()
    if (doneLoading) {
      setAppNotLoadingState()
    }
  }

  componentWillUnmount = async () => {
    const { isLoadingApp, setAppNotLoadingState } = this.props
    if (isLoadingApp) {
      setAppNotLoadingState()
    }
  }

  checkToUpdateLoadingState() {
    const {
      modules,
      activeSelaLicenses,
      setAppIsLoadingState,
      isLoadingApp,
      setAppNotLoadingState,
    } = this.props || {}

    const shouldLoad = activeSelaLicenses?.length === 0 || !modules

    if (shouldLoad && !isLoadingApp) {
      setAppIsLoadingState(LOADING_TEXT)
    }
    if (!shouldLoad && isLoadingApp) {
      setAppNotLoadingState()
    }
  }

  // TECH-DEBT: store selected license data in redux so other components can access the same data
  //            instead of having to drill it down from here
  setDefaultLicense() {
    const { selectedLicense } = this.state
    const { activeSelaLicenses, updateSelectedLicense } = this.props

    if (
      activeSelaLicenses?.length > 0 &&
      Object.entries(selectedLicense)?.length === 0
    ) {
      updateSelectedLicense(activeSelaLicenses[0].licenseId)
      this.setState({ selectedLicense: activeSelaLicenses[0] })
    }
  }

  getBannerBody = () => {
    const {
      activeSelaLicenses,
      singleLicenseText,
      multipleLicenseText,
    } = this.props
    if (activeSelaLicenses?.length > 1) {
      return { type: 'banner-body-multiple-license', text: multipleLicenseText }
    }
    return { type: 'banner-body-single-license', text: singleLicenseText }
  }

  onLicenseChange = e => {
    const { value: licenseOptionText } = e || {}
    // TODO: Change option value to the licenseId so you don't have to parse the label text to get it
    const licenseId = getLicenseIdFromLicenseOptionText(licenseOptionText)
    const { activeSelaLicenses, updateSelectedLicense } = this.props
    const matchedDigitalLicense = activeSelaLicenses.find(license => {
      const licenseIdString = license.licenseId.toString()
      return licenseIdString === licenseId
    })

    if (matchedDigitalLicense) {
      updateSelectedLicense(matchedDigitalLicense.licenseId)
      this.setState({ selectedLicense: matchedDigitalLicense })
    }
  }

  updateSelectedLicense = activeDigitalLicense => {
    const { fetchReportsContentTrack, selaReports } = this.props
    const { selectedLicense } = this.state
    const { licenseId } = selectedLicense

    if (!licenseId) return

    if (
      selaReports &&
      selaReports[licenseId] &&
      selaReports[licenseId].content_tracks
    ) {
      this.setState({ selectedSelaReport: selaReports[licenseId] })
    } else {
      fetchReportsContentTrack(licenseId)
    }
  }

  render() {
    const { selectedLicense, selectedSelaReport } = this.state
    const {
      activeSelaLicenses,
      displayTitle,
      emptyStateText,
      helpText,
      moduleCsvTooltip,
      modules,
      selectedContentTrack,
      selectedModuleId,
      staffProgressTooltip,
      unitCsvTooltip,
      updateSelectedContentTrack,
      updateSelectedModuleId,
      userTypeTooltip,
    } = this.props

    if (activeSelaLicenses?.length < 1 || !modules) {
      return null
    }

    return (
      <SelaReport
        activeSelaLicenses={activeSelaLicenses}
        bannerBody={this.getBannerBody()}
        bannerTitle={displayTitle}
        emptyStateText={emptyStateText}
        footerText={helpText}
        moduleCsvTooltip={moduleCsvTooltip}
        modules={modules}
        navigationEvent={navigationEvent}
        onLicenseChange={this.onLicenseChange}
        selaReport={selectedSelaReport}
        selectedContentTrack={selectedContentTrack}
        selectedLicense={selectedLicense}
        selectedModuleId={selectedModuleId}
        staffProgressTooltip={staffProgressTooltip}
        unitCsvTooltip={unitCsvTooltip}
        updateSelectedContentTrack={updateSelectedContentTrack}
        updateSelectedModuleId={updateSelectedModuleId}
        userTypeTooltip={userTypeTooltip}
      />
    )
  }
}

SelaReportContainer.propTypes = {
  activeDigitalLicenses: PropTypes.array,
  activeSelaLicenses: PropTypes.array,
  deleteReportsData: PropTypes.func,
  displayTitle: PropTypes.string,
  emptyStateText: PropTypes.string,
  fetchActiveDigitalLicensesForUser: PropTypes.func.isRequired,
  fetchReportsContentTrack: PropTypes.func.isRequired,
  helpText: PropTypes.string,
  isFetching: PropTypes.bool,
  isLoadingApp: PropTypes.bool,
  moduleCsvTooltip: PropTypes.string,
  modules: PropTypes.array,
  multipleLicenseText: PropTypes.string,
  selaReports: PropTypes.object,
  selectedContentTrack: PropTypes.string,
  selectedModuleId: PropTypes.string,
  setAppNotLoadingState: PropTypes.func,
  singleLicenseText: PropTypes.string,
  staffProgressTooltip: PropTypes.string,
  unitCsvTooltip: PropTypes.string,
  updateSelectedContentTrack: PropTypes.func,
  updateSelectedLicense: PropTypes.func.isRequired,
  updateSelectedModuleId: PropTypes.func,
  userTypeTooltip: PropTypes.string,
}

export const mapper = (entry, state) => {
  const { reportsManager } = state || {}
  const activeDigitalLicenses = licenseSelectors.selectActiveDigitalLicenses(
    state,
  )
  const { sela_report: selaReports } = reportsManager?.reports || {}
  const productName = activeDigitalLicenses?.find(
    ({ licenseId }) => licenseId === reportsManager?.selectedLicense,
  )?.product

  const {
    displayTitle,
    emptyStateText,
    helpText,
    multipleLicense,
    programRef,
    programRef2,
    singleLicense,
    tooltips,
  } = entry || {}

  const programData =
    (productName === 'SELA Field Test' ? programRef2 : programRef) || {}
  const { productSubsections } = programData || {}
  const tooltipsCopy = tooltips?.map(t => t.text)
  const [
    userTypeTooltip,
    moduleCsvTooltip,
    staffProgressTooltip,
    unitCsvTooltip,
  ] = tooltipsCopy || []

  return {
    activeDigitalLicenses,
    activeSelaLicenses: activeDigitalLicenses?.filter(
      license => license.type === 'ADL',
    ),
    displayTitle,
    emptyStateText,
    helpText,
    moduleCsvTooltip,
    modules: productSubsections,
    multipleLicenseText: multipleLicense,
    selaReports,
    singleLicenseText: singleLicense,
    staffProgressTooltip,
    unitCsvTooltip,
    userTypeTooltip,
  }
}

const options = {
  entryId: '4evS2d4N8ZmLoNf6IJAzE8',
  include: 4,
  mapper,
  spread: true,
}

const mapStateToProps = ({ reportsManager, loadingManager }) => ({
  selaReports: reportsManager?.reports,
  isFetching: reportsManager?.isFetching,
  selectedContentTrack: reportsManager?.selectedContentTrack,
  selectedModuleId: reportsManager?.selectedModuleId,
  isLoadingApp: loadingManager?.isLoading,
})

const mapDispatchToProps = {
  deleteReportsData: reportsOperations.deleteReportsData,
  fetchActiveDigitalLicensesForUser:
    licenseOperations.fetchActiveDigitalLicensesForUser,
  fetchReportsContentTrack: reportsOperations.fetchReportsContentTrack,
  navigationEvent,
  updateSelectedContentTrack: reportsOperations.updateSelectedContentTrack,
  updateSelectedLicense: reportsOperations.updateSelectedLicense,
  updateSelectedModuleId: reportsOperations.updateSelectedModuleId,
  setAppIsLoadingState: loadingOperations.setIsLoadingState,
  setAppNotLoadingState: loadingOperations.setNotLoadingState,
}

export default ContentEntryDataGetter(
  withApiError(SelaReportContainer, [ERROR_TYPE_REPORTING]),
  options,
  connect(mapStateToProps, mapDispatchToProps),
)
