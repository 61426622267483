import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { HeaderOne, TabSet } from 'secondstep-components'
import variables from 'themes/variables'

const breakpoints = {
  aboveTablet: '@media (max-width: 910px)',
}

export const tabTheme = {
  colors: {
    buttons: {
      tabActive: variables.colors.secondStepBlue,
      tabDefault: variables.colors.darkerGray,
    },
  },
  font: {
    molde: {
      medium: variables.font.molde.medium,
      regular: variables.font.molde.regular,
      semiBold: variables.font.molde.semiBold,
    },
  },
  select: {
    font: variables.font.molde.medium,
    colors: {
      primary: variables.colors.secondStepBlue,
      secondary: variables.colors.white,
    },
  },
  spacing: {
    pink8_0_5rem: variables.spacing.pink8_0_5rem,
    green4_0_25rem: variables.spacing.green4_0_25rem,
  },
}

export const StyledDropdown = styled.div`
  display: none;
  width: 180px;
`

export const StyledAlignDiv = styled.div`
  padding: 0 1.25rem;
`

export const HeadingWrapper = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin: 3.4375rem auto ${themeGet('spacing.turquoise20_1_25rem')} auto;
  width: 60.875rem;

  ${HeaderOne} {
    margin-bottom: 0.25rem;
  }

  ${breakpoints.aboveTablet} {
    ${TabSet} {
      display: none;
    }

    ${StyledDropdown} {
      display: block;
    }
  }
`

export const TabSetWrapper = styled.div`
  margin-top: 0.25rem;

  ${breakpoints.aboveTablet} {
    margin-top: 0;
  }
`

export const DropdownPrograms = styled.div`
  button {
    background-color: hsl(0, 0%, 100%);
    border-radius: 1.5625rem;
    border: none;
    box-shadow: none;
    color: ${themeGet('select.colors.primary')};
    width: 100%;

    input {
      padding: 0.25rem 0.75rem 0.375rem;
      font-size: 1rem;
      font-family: ${themeGet('font.molde.semiBold')};
      font-weight: normal;
      line-height: 1.5;
    }

    svg {
      fill: ${themeGet('select.colors.primary')};
      stroke: ${themeGet('select.colors.primary')};
    }
  }
`

export const ProgramOption = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.6rem;
  color: ${themeGet('select.colors.primary')};
  font-family: ${themeGet('font.molde.semiBold')};
  font-size: 1rem;

  &:hover,
  &:focus,
  &:visited {
    color: ${themeGet('select.colors.secondary')};
    background-color: ${themeGet('select.colors.primary')};

    svg {
      path {
        fill: ${themeGet('select.colors.secondary')};
      }
    }
  }

  svg {
    margin-left: 2em;
    margin-top: 0.4em;

    path {
      fill: ${themeGet('select.colors.primary')};
    }
  }
`
