import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  Hairline,
  HeaderThree,
  HeaderTwo,
  ProgressBarIndicator,
  TextLink,
  TooltipContentWrapper,
} from 'secondstep-components'
import AdminTooltip from 'components/AdminTooltip'

export const AdminPathwayProgressWrapper = styled.div.attrs(
  ({ showBarChart }) => ({
    padding: showBarChart
      ? '1.4375rem 1.25rem 1rem 1.25rem'
      : '1.4375rem 1.25rem 3.125rem 1.25rem',
  }),
)`
  display: flex;
  flex-direction: column;
  border-radius: 0.625rem;
  border: 2px solid ${themeGet('colors.paleGray')};
  padding: ${({ padding }) => padding};
  background: ${themeGet('colors.white')};
`

export const BarChartTitle = styled.span`
  padding-left: 0.375rem;
  font-family: ${themeGet('font.molde.medium')};
`

export const BarChartWrapper = styled.div`
  width: 35%;
  margin-left: 3.125rem;

  @media (max-width: 930px) {
    width: 100%;
    max-width: 355px;
    margin: 1.375rem 0 0 -0.4375rem;
  }
`

export const ChartContainer = styled.div`
  width: 100%;
  height: 135px;
  margin-top: 0.3125rem;
  overflow: hidden;
`

export const EducatorPracticesProgressBar = styled(ProgressBarIndicator)`
  [class*='__Background-sc'] {
    height: 23px;
    border-radius: 14px;
  }

  [class*='__IndicatorWrapper-sc'] {
    align-items: center;
  }

  [class*='__Indicator-sc'] {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 23px;
    border-radius: 14px;
    background-color: ${themeGet('colors.darkBlue')};
    border-color: ${themeGet('colors.darkBlue')};
  }
`

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 0.875rem;
`

export const PacingAndPathwayWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.375rem;
`

export const PacingScheduleLink = styled(TextLink)`
  align-self: flex-start;
  margin-top: 0.25rem;
  margin-left: 1rem;
`

export const ProgressBarCountLabel = styled.span`
  font-family: ${themeGet('font.molde.regular')};
`

export const ProgressBarLabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`

export const ProgressBarsWrapper = styled.div.attrs(({ showBarChart }) => ({
  width: showBarChart ? '75%' : '100%',
}))`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};

  @media(max-width: 930px) {
    width: 100%;
  }
}
`

export const ProgressBarTitleLabel = styled.span`
  font-family: ${themeGet('font.molde.medium')};
`

export const SchoolwidePracticesProgressBar = styled(
  EducatorPracticesProgressBar,
)`
  margin-bottom: 1.4375rem;

  [class*='__Indicator-sc'] {
    background-color: ${themeGet('colors.highschool.pink1')};
    border-color: ${themeGet('colors.highschool.pink1')};
  }
`

export const SingleBarWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const StyledAdminTooltip = styled(AdminTooltip)`
  margin-bottom: 0.125rem;

  ${TooltipContentWrapper} {
    top: -127px;

    &::after {
      bottom: 37%;
    }
  }
`

export const StyledHairline = styled(Hairline)`
  height: 2px;
  margin-bottom: 1.3125rem;
  background-color: ${themeGet('colors.paleGray')};
`

export const TabButton = styled.button.attrs(props => ({
  borderBottom: props.isActive
    ? `2px solid ${themeGet('colors.darkBlue')(props)}`
    : 'none',
  color: props.isActive
    ? themeGet('colors.darkBlue')(props)
    : themeGet('colors.darkGray')(props),
  fontFamily: props.isActive
    ? themeGet('font.molde.medium')(props)
    : themeGet('font.molde.regular')(props),
}))`
  margin: 0 1.625rem 0.8125rem 0;
  padding: 0;
  background: none;
  border: none;
  border-bottom: ${({ borderBottom }) => borderBottom};
  color: ${({ color }) => color};
  font-family: ${({ fontFamily }) => fontFamily};
  font-size: 1.125rem;
  line-height: 1.125rem;
  transition: color 0.2s ease;

  &:hover {
    color: ${themeGet('colors.darkBlue')};
    cursor: pointer;
    transition: color 0.2s ease;
  }
`

export const TabContentWrapper = styled.div`
  display: flex;

  @media (max-width: 930px) {
    flex-direction: column;
  }
`

export const TabHeaderTitle = styled(HeaderThree)`
  font-size: 1.125rem;
  font-family: ${themeGet('font.molde.medium')};
  color: ${themeGet('colors.darkGray')};
  margin-bottom: 0.8125rem;
`

export const TabsWrapper = styled.div``

export const Title = styled(HeaderTwo)``
