import {
  DEFAULT_CONTENTFUL_URL,
  DEFAULT_FORMAT,
  DEFAULT_INCLUDE,
  DEFAULT_LOCALE,
  DEFAULT_PREVIEW,
} from '../../../content/data'
import { getEntry } from '../../../content/store/operations'

export function getEntryForLearnLayer(entryRequestOptions) {
  const {
    entryId = null,
    locale = DEFAULT_LOCALE,
    format = DEFAULT_FORMAT,
    url = DEFAULT_CONTENTFUL_URL,
    include = DEFAULT_INCLUDE,
    preview = DEFAULT_PREVIEW,
  } = entryRequestOptions || {}

  if (!entryId) {
    return null
  }

  return getEntry(entryId, locale, preview, format, url, include)
}
