import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Image as RNWImage } from 'react-native'
import { StyledImage, Container } from './component.styles'
import WithZoom from 'components/WithZoom'
import WithImageModal from 'components/WithImageModal'
export default class FlexWidthImage extends Component {
  static propTypes = {
    source: PropTypes.string,
    width: PropTypes.string,
  }
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
    }
    this.openModal = this.openModal.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  componentDidMount() {
    const { source } = this.props || {}
    source &&
      RNWImage.getSize(source, (width, height) => {
        this.setState({ height, width })
      })
  }

  componentDidUpdate() {
    const { source } = this.props
    const { width: currentWidth, height: currentHeight } = this.state || {}

    source &&
      currentHeight !== undefined &&
      currentWidth !== undefined &&
      RNWImage.getSize(source, (width, height) => {
        if (currentHeight !== height || currentWidth !== width) {
          this.setState({ height, width })
        }
      })
  }

  onClose() {
    this.setState({ showModal: false })
  }
  openModal() {
    if (
      this.state.showModal ||
      (event.type !== 'click' && event.key !== 'enter')
    ) {
      return
    }

    this.setState({ showModal: true })
  }

  render() {
    const { source, width } = this.props
    const { presentedWidth, height, width: stateWidth, showModal } =
      this.state || {}
    const presentedHeight = (height / stateWidth) * presentedWidth

    const modalProps = {
      onClose: this.onClose,
      openModal: this.openModal,
      show: showModal,
    }
    const Image = (
      <StyledImage
        presentedHeight={presentedHeight}
        presentedWidth={presentedWidth}
        source={source}
      />
    )

    return (
      <Container
        data-testid="container"
        onLayout={event => {
          const { width: presentedWidth } = event.nativeEvent.layout
          this.setState({ presentedWidth })
        }}
        width={width}
      >
        {source && WithImageModal(Image, WithZoom()(Image))(modalProps)}
      </Container>
    )
  }
}
