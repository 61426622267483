export const pageTypes = {
  FAMILY_RESOURCE_PAGE: 'familyResourcePage',
  HS_TRAINING_PAGE: 'hsTrainingPage',
  HS_SUPPORT_PAGE: 'hsSupportPage',
  PAGE_NOT_DEFINED: 'PAGE_NOT_DEFINED',
}

const pages = [
  {
    url: 'family-resources',
    pageType: pageTypes.FAMILY_RESOURCE_PAGE,
  },
  {
    url: 'family/high-school',
    pageType: pageTypes.FAMILY_RESOURCE_PAGE,
  },
  {
    url: 'high-school/program-supports',
    pageType: pageTypes.HS_SUPPORT_PAGE,
  },
  {
    url: 'high-school/training',
    pageType: pageTypes.HS_TRAINING_PAGE,
  },
]

export const getPageTypeByUrl = url => {
  return (
    pages.find(p => url.includes(p.url))?.pageType || pageTypes.PAGE_NOT_DEFINED
  )
}
