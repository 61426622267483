import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  ADDITIONAL_DETAILS,
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_CONTENTFUL_PRODUCT,
  SHOW_ERROR_IN_MODAL,
  SHOW_CONTENT_ON_401,
  NO_ENTIRE_PAGE_ERROR,
} from './constants'
import {
  create401ErrorForRedacted,
  create404ErrorForContentful,
  create413ErrorForContentful,
} from './data'
import APIErrorMessage from './APIErrorMessage.jsx'
import ContentfulNodeFailureTypes from '../../learn/content/nodes/failureTypes'
import { INCLUDE_ERROR } from 'config/constants'

const errorShape = {
  response: PropTypes.shape({
    data: PropTypes.string,
    status: PropTypes.number,
    statusText: PropTypes.string,
  }),
  shouldShowInModal: PropTypes.bool,
}

const withApiError = (Wrapped, subscriptions = [], modifiers = []) => {
  return class extends Component {
    static propTypes = {
      contentfulProductError: PropTypes.shape(errorShape),
      contentfulProductSectionError: PropTypes.shape(errorShape),
      contentfulRootError: PropTypes.shape(errorShape),
      entry: PropTypes.object,
      error: PropTypes.object,
      licenseError: PropTypes.shape(errorShape),
      lmsError: PropTypes.shape(errorShape),
      nodeSystemError: PropTypes.string,
      profileError: PropTypes.shape(errorShape),
      reportingError: PropTypes.oneOfType([
        PropTypes.shape(errorShape),
        PropTypes.bool,
      ]),
      setNotLoadingState: PropTypes.func,
      userId: PropTypes.number,
    }

    getErrorPageGateModifiers = () => {
      const { error: contentfulEntryError } = this.props
      const isShowContent401Modifier = modifiers.some(
        modifier => modifier === SHOW_CONTENT_ON_401,
      )

      const is401Error =
        contentfulEntryError && contentfulEntryError.status === 401
      // if we have 401 modifier on, check contentfulEntryError for 401 status. If its a 401 then dont allow error page.
      // Default to true if isShowContent401Modifier is false since it should not block
      const shouldAllowErrorPage = isShowContent401Modifier ? !is401Error : true

      return shouldAllowErrorPage
    }

    shouldShowModalError = () => {
      const isShowErrorInModal = modifiers.some(
        modifier => modifier === SHOW_ERROR_IN_MODAL,
      )
      return isShowErrorInModal
    }

    shouldNotShowPageError = () => {
      const isNoEntirePageError = modifiers.some(
        modifier => modifier === NO_ENTIRE_PAGE_ERROR,
      )
      return isNoEntirePageError
    }

    validateShouldShowContentfulError = () => {
      const {
        entry,
        contentfulRootError,
        error: contentfulEntryError,
        nodeSystemError,
        contentfulProductError,
        contentfulProductSectionError,
      } = this.props
      const { redacted: entryRedacted } = entry || {}

      const isSubscribedToContentfulError = subscriptions.some(
        subscription => subscription === ERROR_TYPE_CONTENTFUL,
      )
      const isSubscribedToProductPathErrors = subscriptions.some(
        subscription => subscription === ERROR_TYPE_CONTENTFUL_PRODUCT,
      )

      const shouldGateAllowContentfulErrorPage = this.getErrorPageGateModifiers()

      if (shouldGateAllowContentfulErrorPage && isSubscribedToContentfulError) {
        const isThereAContentfulRootError = contentfulRootError
        const isThereAProductError =
          isSubscribedToProductPathErrors && contentfulProductError
        const isThereAProductSectionError =
          isSubscribedToProductPathErrors && contentfulProductSectionError
        const isThereAContentful404Error = this.validateAContentful404()
        const isThereAContentfulEntryError =
          nodeSystemError || contentfulEntryError || entryRedacted

        if (isThereAContentfulRootError) {
          return contentfulRootError
        }

        if (isThereAProductError) {
          return contentfulProductError
        }

        if (isThereAProductSectionError) {
          return contentfulProductSectionError
        }

        if (isThereAContentful404Error) {
          const error404 = create404ErrorForContentful()
          return error404
        }

        if (isThereAContentfulEntryError) {
          if (entryRedacted) {
            // make redacted into a 401 error so we can use it in the build method
            const redactedError = create401ErrorForRedacted()
            return redactedError
          }
          if (INCLUDE_ERROR === contentfulEntryError.message) {
            const error413 = create413ErrorForContentful(INCLUDE_ERROR)
            return error413
          }

          return nodeSystemError || contentfulEntryError
        }
      }

      return false
    }

    validateShouldShowServiceError = () => {
      return subscriptions.find(errorType => this.props[errorType])
    }

    /*
    this case happens when the node data getter cant find the next node in the line in the contentNode systems in  src/layers/learn/content
     */
    validateAContentful404 = () => {
      const { nodeSystemError } = this.props

      // just a string and not a full error so we just check the value
      if (
        nodeSystemError ===
        ContentfulNodeFailureTypes.CAN_NOT_FIND_NEXT_CHILD_NODE
      ) {
        return true
      }

      return false
    }

    validateShouldShowContentGetterError = () => {
      const { routeContent } = this.props || {}
      const { error } = routeContent || {}
      return error
    }

    validateShouldShowCourseManagerError = () => {
      const { courseManager } = this.props || {}
      const { error } = courseManager || {}
      return error
    }

    render() {
      const {
        licenseError,
        lmsError,
        profileError,
        reportingError,
        setNotLoadingState,
        userId,
      } = this.props

      const licenseHas403Error =
        !profileError &&
        !lmsError &&
        !reportingError &&
        licenseError &&
        licenseError.status === 403

      const apiServiceErrorType = this.validateShouldShowServiceError()
      const contentfulErrorType = this.validateShouldShowContentfulError()
      const shouldShowInModal = this.shouldShowModalError()
      const shouldNotShowPageError = this.shouldNotShowPageError()
      const contentOrRouteGetterErrorType = this.validateShouldShowContentGetterError()
      const courseManagerErrorType = this.validateShouldShowCourseManagerError()

      // ==================================
      // FIXME: HACK for licenseError until Sentinel removes the errant 403 return
      if (licenseHas403Error) {
        return <Wrapped {...this.props} />
      }
      // ==================================
      // Allow LMS Errors to be shown in the currently open Modals when modal is open
      if (lmsError && shouldShowInModal && lmsError.shouldShowInModal) {
        return <Wrapped {...this.props} />
      }

      if (reportingError) {
        const { requestName } = reportingError
        if (requestName === 'k8-class-details') {
          return <Wrapped {...this.props} />
        }
      }

      if (lmsError && shouldNotShowPageError) {
        return <Wrapped {...this.props} />
      }

      if (apiServiceErrorType) {
        if (lmsError) {
          setNotLoadingState()
        }
        const apiError = this.props[apiServiceErrorType] || {}
        return (
          <APIErrorMessage
            error={apiError}
            errorType={apiServiceErrorType}
            userId={userId}
          />
        )
      }

      if (contentfulErrorType) {
        return (
          <APIErrorMessage
            additionalDetails={ADDITIONAL_DETAILS}
            error={contentfulErrorType}
            errorType={ERROR_TYPE_CONTENTFUL}
            userId={userId}
          />
        )
      }

      if (contentOrRouteGetterErrorType) {
        return (
          <APIErrorMessage
            additionalDetails={ADDITIONAL_DETAILS}
            error={contentOrRouteGetterErrorType.message}
            errorType={contentOrRouteGetterErrorType.errorType}
            userId={userId}
          />
        )
      }

      if (courseManagerErrorType) {
        return (
          <APIErrorMessage
            additionalDetails={ADDITIONAL_DETAILS}
            error={courseManagerErrorType.message}
            errorType={courseManagerErrorType.errorType}
            userId={userId}
          />
        )
      }

      return <Wrapped {...this.props} />
    }
  }
}

export default withApiError
