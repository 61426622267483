import NavigationHelper from 'layers/navigation/navigationHelper'
import { getThemeFromUrl } from 'utils/productHelpers'
import { toUrlSafe } from 'utils/stringHelpers'
import { getTheme } from 'themes'
import pathnameMap from './pathnameMap'

const defaultProduct = pathname => {
  const title = getProductTitleFromPathname(pathname)

  let themeName
  if (pathname.includes('/family-mind-yeti/')) {
    themeName = 'mindyeti'
  } else {
    switch (true) {
      case pathname.includes('/reports/'):
      case pathname.includes('/family-resources/'):
      case pathname.includes('/dashboard'):
      case pathname.includes('/accessibility'):
      case pathname.includes('/webinars'):
      case pathname.includes('/resource-library'):
      case pathname.includes('/district/'):
      case pathname.includes('/admin'):
        themeName = 'platform'
        break
      default:
        themeName = 'main'
    }
  }

  const theme = getTheme(themeName)

  const product = {
    product: {
      product: '',
      title,
    },
    theme,
  }

  return product
}

function isPathwaysSelected(route) {
  return /pathways/.test(route)
}

// Checks if a route segment like `/b` is in a pathanme like `/a/b/c`
const _isSelected = pathname => route => {
  const r = new RegExp(`/${route}/|/${route}$`)
  return r.test(pathname)
}

function createSelectedPathResolver(path) {
  // high school learning experiences is a special case
  if (/high-school\/learning-experiences/.test(path)) {
    return isPathwaysSelected
  }

  // Default resolver
  return _isSelected(path)
}

export const navContentMapper = (entry, state) => {
  const pathname = NavigationHelper.getPathname(state)
  const isNamespacePath =
    pathname.includes('family-resources') && !pathname.includes('high-school')
  if (entry) {
    const entryProduct = findRawProductOrNamespaceByPath(entry, pathname)
    const isDashboard = pathname.indexOf('/dashboard') !== -1
    const isAccessibility = pathname.indexOf('/accessibility') !== -1
    const isWebinars = pathname.indexOf('/webinars') !== -1
    if (!entryProduct || isDashboard || isAccessibility || isWebinars) {
      return defaultProduct(pathname)
    }
    const isSelected = createSelectedPathResolver(pathname)
    let sectionName = 'productSections'

    if (isNamespacePath) sectionName = 'subsection'

    const programs = entryProduct[sectionName]?.map(
      ({ displayTitle: title, renderType, id: entryId, pageData }) => {
        const { route } = pageData || {}
        return {
          entryId,
          isSelected: isSelected(route),
          renderType,
          route,
          title,
        }
      },
    )

    const { internalTitle, displayTitle } = entryProduct
    const product = {
      product: internalTitle,
      title: displayTitle,
    }
    const theme = getThemeFromUrl()
    return { product, programs, theme }
  } else {
    return defaultProduct(pathname)
  }
}

export const findRawProductOrNamespaceByPath = (entry, pathname) => {
  const paths = pathname.split('/')
  const isProductPath = paths[1] === 'product'
  const isNamespacePath = paths[1] === 'family-resources'
  const isMindYetiFamilyPath = paths[1] === 'family-mind-yeti'
  const pathProduct = isProductPath ? paths[2] : paths[1]
  const { products = [] } = entry || {}

  if (isNamespacePath) {
    if (pathProduct === 'family-resources') return entry.familiesSection?.[0]
  }

  if (isMindYetiFamilyPath) {
    if (pathProduct === 'family-mind-yeti') return entry.familiesSection?.[1]
  }

  return products.find(product => {
    const { pageData = {} } = product || {}
    const { route } = pageData
    if (!route || !pathProduct) {
      return false
    }
    return toUrlSafe(route) === toUrlSafe(pathProduct)
  })
}

const getProductTitleFromPathname = pathname => {
  const paths = pathname.split('/')
  const isReportsPage = paths[1] === 'reports'
  let title

  if (isReportsPage) {
    title = pathnameMap[paths[1]]?.[paths[2]]?.title || ''
  } else {
    const pathProduct = paths.find(path => pathnameMap[path])
    title = pathnameMap[pathProduct]?.title || ''
  }

  return title
}
