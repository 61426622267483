export const BACK_TO_DASHBOARD = 'Back to Dashboard'
export const BUTTON_CANCEL = 'CANCEL'
export const BUTTON_SUBMIT = 'CREATE A CLASS'
export const COURSE_INSTANCE_LABEL = 'Class Name'
export const COURSE_INSTANCE_PLACEHOLDER = 'Enter a class name'
export const CREATE_HEADER = 'Create a Class'
export const END_DATE_LABEL = 'End Date'
export const END_DATE_PLACEHOLDER = 'Choose a date'
export const LICENSE_LABEL = 'Change License'
export const LICENSE_PLACEHOLDER = 'Select a License'
export const SELECTED_COURSE_LABEL = 'Grade'
export const SELECTED_COURSE_PLACEHOLDER = 'Select a grade'
export const START_DATE_LABEL = 'Start Date'
export const START_DATE_PLACEHOLDER = 'Choose a date'
export const LICENSE_PICKER_INFO =
  'To see program use for another school, select a different license below.'
