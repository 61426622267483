// TODO: can this be merged with ResourcesContent component?
import React from 'react'
import PropTypes from 'prop-types'
import { HeaderFour } from 'secondstep-components'
import ImageFullWidth from 'experienceComponents/ImageFullWidth'
import HeaderBodyCopy from 'experienceComponents/HeaderBodyCopy'
import ImageBodyCopyFlexible from 'components/ImageBodyCopyFlexible'
import InlineVideo from 'components/InlineVideo'
import contentTypes from 'utils/contentfulParser/contents'
import { toCamelCase } from 'utils/stringHelpers'
import {
  ContentWrapper,
  Spacer,
  StyledContainer,
  StyledTextLinkWithFileIcon,
} from './component.styles'
import { VIDEO_VARIANT } from './constants'

const CONTENT_MAPPING = {
  headerBodyCopy: {
    component: HeaderBodyCopy,
    parser: (props, handlers) => {
      return {
        ...contentTypes.headerBodyCopy(props),
        ...handlers,
      }
    },
  },
  h4: {
    component: HeaderFour,
    parser: content => ({
      children: content?.h4Content,
      // this is a required prop:
      dataTestId: content?.h4Content?.toLowerCase()?.replace(' ', '-'),
    }),
  },
  textLinkIcon: {
    component: StyledTextLinkWithFileIcon,
    parser: contentTypes.textLinkIcon,
  },
  image: {
    // TODO: this may not be the best component to display image fullscreen
    // Revisit this for the zoom and pan ticket above:
    component: ImageFullWidth,
    parser: content => ({
      imageThumbnailUrl: content?.image?.file?.url || content?.image?.url,
      imageUrl: content?.image?.file?.url || content?.image?.url,
      title: '',
      isZoomable: true,
    }),
  },
  imageBodyCopyFlexible: {
    component: ImageBodyCopyFlexible,
    parser: entry => ({
      ...contentTypes.imageBodyCopyFlexible(entry),
      isZoomable: true,
      title: '',
    }),
  },
  video: {
    // this isn't ideal because it refetches the already fetched video
    component: InlineVideo,
    parser: content => {
      return {
        entryId: content?.id,
        locale: content?.locale,
        variant: VIDEO_VARIANT,
      }
    },
  },
  spacer: {
    component: Spacer,
    parser: content => ({
      height: content.pixelHeight,
    }),
  },
}

export const getContentComponent = (contentBlock, key, handlers) => {
  const mapping =
    CONTENT_MAPPING[contentBlock?.contentType?.id] ||
    CONTENT_MAPPING[toCamelCase(contentBlock?.__typename)]

  if (!mapping) {
    return null
  }
  const Component = mapping.component

  return <Component key={key} {...mapping.parser(contentBlock, handlers)} />
}

const HsIndividualResourceActivityDetails = ({
  audience,
  details,
  referenceHandler,
}) => {
  return (
    <StyledContainer audience={audience}>
      <ContentWrapper>
        {details?.content.map((content, index) =>
          getContentComponent(content, index, {
            onHandleClickSup: referenceHandler,
          }),
        )}
      </ContentWrapper>
    </StyledContainer>
  )
}

HsIndividualResourceActivityDetails.propTypes = {
  audience: PropTypes.string,
  details: PropTypes.object,
  referenceHandler: PropTypes.func,
}

export default HsIndividualResourceActivityDetails
