import styled from 'styled-components'
import { themeGet } from 'styled-system'
import DefaultCheckmark from './assets/checkmark-default.svg'
import DoneCheckmark from './assets/checkmark-donestate-green.svg'
// TODO: for Delivery 4 import this hover state checkmark:
// import DoneCheckmarkHover from './assets/checkmark-donestate-hover-white.svg'

export const StyledHeader4 = styled.h4`
  font-family: ${themeGet('font.komet')};
  font-weight: ${themeGet('heading.level.4.font.weight')};
  margin-block-start: -0.438rem;
  margin-block-end: 0.9rem;
  ${themeGet('breakpoints.tablet')} {
    margin-block-end: 1.25rem;
  }
`

export const StyledParagraph = styled.p`
  margin-block-start: -0.438rem;
  margin-block-end: 1rem;
  ${themeGet('breakpoints.tablet')} {
    margin-block-end: 1.125rem;
  }
`

export const SectionWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: white;
  padding: 1.75rem 1.75rem 1.375rem 1.75rem;
  border-radius: 0.5rem;
  border: 0.094rem solid ${themeGet('colors.borders.primary')};
  margin-bottom: 2.5rem;
  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.25rem;
  }
`

export const StatusColumn = styled.div`
  ${themeGet('breakpoints.tablet')} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 0.75rem;
  }
`

export const NextColumn = styled.div`
  ${themeGet('breakpoints.tablet')} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
`

export const ButtonBase = styled.button`
  padding-top: 0.125rem;
  border-radius: 0.5rem;
  height: 2.688rem;
  font-family: ${themeGet('fonts.moldeMedium16.fontFamily')};
  font-size: ${themeGet('fonts.moldeMedium16.fontSize')};
  line-height: ${themeGet('fonts.moldeMedium16.lineHeight')};
  cursor: pointer;
`

export const MarkCompleteIcon = styled.div`
  background-image: url(${props =>
    props.isComplete ? DoneCheckmark : DefaultCheckmark});
  width: 1rem;
  height: 1rem;
  background-size: cover;
  margin-top: -0.187rem;
`

export const MarkCompleteButton = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.3rem;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: ${props =>
    props.isComplete
      ? `2px solid ${themeGet('colors.borders.primary')(props)}`
      : 'none'};
  color: ${props =>
    props.isComplete
      ? themeGet('colors.buttons.secondary.labelActive')
      : !props.hasPermissionsToComplete
      ? themeGet('colors.buttons.primary.titleDisabled')
      : themeGet('colors.buttons.primary.titleActive')};
  min-width: 10.938rem;
  background-color: ${props =>
    props.isComplete
      ? themeGet('colors.hsSecondaryLightGreen')
      : !props.hasPermissionsToComplete
      ? themeGet('colors.buttons.primary.bgDisabled')
      : themeGet('colors.buttons.primary.bgActive')};
  // TODO: for delivery 4, only override the cursor is hasPermissionsToComplete is false:
  cursor: ${props =>
    props.isComplete || !props.hasPermissionsToComplete
      ? 'default'
      : 'pointer'};
  :hover {
    // TODO: for Delivery 4 re-enable this hover styling when isComplete true :
    background-color: ${props =>
      !props.hasPermissionsToComplete || props.isComplete
        ? 'auto'
        : themeGet('colors.buttons.primary.bgHover')};
    color: ${props =>
      !props.hasPermissionsToComplete || props.isComplete
        ? 'auto'
        : themeGet('colors.buttons.primary.titleHover')};
    border: ${props => (props.isComplete ? 'auto' : 'none')};
  }
`

// TODO: for Delivery 4 re-add this styling to MarkCompleteButton when toggle complete to false is enabled:
/*
  &:hover {
    ${MarkCompleteIcon} {
      background-image: url(${props =>
        props.isComplete ? DoneCheckmarkHover : DefaultCheckmark});
    }
  }
*/

export const NextSectionButton = styled(ButtonBase)`
  float: right;
  border: ${props =>
    props.isComplete
      ? '1px solid transparent'
      : `1px solid ${themeGet('colors.buttons.secondary.titleActive')}`};
  background-color: ${props =>
    props.isComplete
      ? themeGet('colors.buttons.primary.bgActive')
      : 'transparent'};
  color: ${props =>
    props.isComplete
      ? 'white'
      : themeGet('colors.buttons.secondary.titleActive')};
  padding: 0rem 1rem;
  :hover {
    background-color: ${props =>
      props.isComplete
        ? themeGet('colors.buttons.primary.bgHover')
        : themeGet('colors.hsSecondaryLightGreen')};
  }
  ${themeGet('breakpoints.tablet')} {
    float: unset;
    margin-bottom: 0.125rem;
  }
`

export const MarkCompleteButtonWrapper = styled.div`
  display: flex;
  gap: 1.25rem;
  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  pre {
    white-space: pre-wrap;
  }
`

export const CompletionCommentary = styled.pre`
  margin-top: 0.4rem;
  font-family: ${themeGet('fonts.moldeRegular12.fontFamily')};
  font-size: ${themeGet('fonts.moldeRegular12.fontSize')};
  line-height: 1rem;
  font-style: italic;
`
