import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const AnchoredHeaderBodyCopyWrapper = styled.div`
  margin-top: 1.5625rem;

  ${themeGet('breakpoints.tablet')} {
    margin-top: 1.0625rem;
  }
`
