import PropTypes from 'prop-types'

const LessonType = PropTypes.shape({
  lessonType: PropTypes.string,
  displayTitle: PropTypes.string,
  description: PropTypes.string,
  presentation: PropTypes.shape({
    id: PropTypes.string,
  }),
  id: PropTypes.string,
})

export const UnitType = PropTypes.shape({
  additionalResources: PropTypes.array,
  displayTitle: PropTypes.string,
  description: PropTypes.string,
  lessons: PropTypes.arrayOf(LessonType),
  unitResources: PropTypes.array,
  unitVideo: PropTypes.shape({
    id: PropTypes.string,
    displayTitle: PropTypes.string,
    time: PropTypes.string,
    videoReference: PropTypes.shape({
      id: PropTypes.string,
      posterImage: PropTypes.shape({
        url: PropTypes.string,
      }),
    }),
  }),
  courseContent: PropTypes.shape({
    lessonsCount: PropTypes.arrayOf(
      PropTypes.shape({
        lessonCount1: PropTypes.string,
        lessonCount2: PropTypes.string,
      }),
    ),
    tabSummaries: PropTypes.arrayOf(
      PropTypes.shape({
        displayTitle: PropTypes.string,
        route: PropTypes.string,
        lessonsCount: PropTypes.number,
      }),
    ),
    coursePdf: PropTypes.arrayOf(
      PropTypes.shape({
        displayTitle: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    ),
  }),
})

const LMSIem = PropTypes.shape({
  contentfulID: PropTypes.string,
  completeLessons: PropTypes.number,
  instance: PropTypes.string,
  progress: PropTypes.number,
  attempted: PropTypes.string,
  learner: PropTypes.string,
  doneAt: PropTypes.string,
  updatedAt: PropTypes.string,
  scoID: PropTypes.number,
})

export const LMSUnitType = PropTypes.shape({
  ...LMSIem,
  lessons: PropTypes.arrayOf(LMSIem),
})
