import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { FooterLogoContainer } from './component.styles'
import { LOGOS } from './data'
import FooterLogoLink from './footerLogoLink'

export default class FooterLogos extends Component {
  static propTypes = {
    className: PropTypes.string,
    isAdmin: PropTypes.bool,
    isDashboard: PropTypes.bool,
    navigationEvent: PropTypes.func,
  }

  render() {
    const { className, navigationEvent, isAdmin, isDashboard } = this.props
    return (
      <FooterLogoContainer
        className={className}
        isAdmin={isAdmin}
        isDashboard={isDashboard}
      >
        {LOGOS.map(logo => {
          const { alt, href, label, source } = logo || {}
          return (
            <FooterLogoLink
              alt={alt}
              href={href}
              key={label}
              label={label}
              navigationEvent={navigationEvent}
              src={source}
            />
          )
        })}
      </FooterLogoContainer>
    )
  }
}
