import React from 'react'
import PropTypes from 'prop-types'
import { Route, Switch } from 'react-router'
import AllModules from 'components/AllModules'
import LearnErrorBoundary from 'components/LearnErrorBoundary'
import ModuleDetails from 'components/ModuleDetails'

const Sela = ({ allModulesContentfulId, match = {} }) => {
  return (
    <LearnErrorBoundary>
      <Switch>
        <Route
          path={`${match.path}/:contentfulId`}
          render={({ match: routeMatch = {} }) => (
            <ModuleDetails entryId={routeMatch.params?.contentfulId} />
          )}
        />
        <Route
          exact
          path={`${match.path}`}
          render={() => (
            <AllModules
              entryId={allModulesContentfulId}
              productName={match.params.productName}
            />
          )}
        />
      </Switch>
    </LearnErrorBoundary>
  )
}

Sela.propTypes = {
  allModulesContentfulId: PropTypes.string.isRequired,
  match: PropTypes.object,
}

export default Sela
