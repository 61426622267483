import styled, { withTheme } from 'styled-components'
import { themeGet } from 'styled-system'

export const Container = withTheme(styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
  tabIndex: 0,
}))`
  display: inline-block;
  box-sizing: border-box;
  width: 210px;
  height: 175px;
  padding: 16px 16px 0 16px;
  position: relative;
  border-radius: 0.75rem;
  overflow: hidden;
  cursor: pointer;
  line-height: initial;
  background-color: ${themeGet('colors.buttons.primaryBackground')};
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${themeGet('colors.buttons.primaryBackgroundHover')};
  }

  margin-left: ${themeGet('spacing.turquoise20_1_25rem')};
  &:first-of-type {
    margin-left: 0;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    width: 100%;
    margin-left: 0;
  }
`)

export const IconWrapper = withTheme(styled.div`
  width: 37px;
  height: 37px;
  border-radius: 5px;
  background: ${themeGet('colors.buttons.primaryBackgroundHover')};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 23px;
    path {
      fill: ${themeGet('colors.white')};
    }
  }
`)

export const Supertitle = styled.div`
  margin-top: 26px;
  text-transform: uppercase;
  color: ${themeGet('colors.white')};
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 1rem;
  line-height: 1rem;
`

export const Title = styled.div`
  margin-top: 6px;
  color: ${themeGet('colors.white')};
  font-family: ${themeGet('fontFamilyBold')};
  font-size: 22px;
  line-height: 26px;
`
