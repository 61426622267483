import React from 'react'
import PropTypes from 'prop-types'
import { AlphabetIcon, HeaderThree } from 'secondstep-components'
import {
  ProfileCardBox,
  StyledQuickLinksCard,
  UserDetails,
  RightColumn,
} from './component.styles'

export const ProfileCard = ({ userProfile }) => {
  const { firstName, lastName, roles } = userProfile || {}
  return (
    <ProfileCardBox>
      <UserDetails>
        <AlphabetIcon letter={firstName?.substring(0, 1)} />
        <RightColumn>
          <HeaderThree dataTestId="profile-name">{firstName}</HeaderThree>
          <HeaderThree dataTestId="profile-lastName">{lastName}</HeaderThree>
        </RightColumn>
      </UserDetails>
      <StyledQuickLinksCard userRoles={roles} />
    </ProfileCardBox>
  )
}

ProfileCard.propTypes = {
  userProfile: PropTypes.object.isRequired,
}

export default ProfileCard
