import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { operations } from 'store/routeServiceManager'
import { filterUniqueUserContextFields } from 'auth/stores/userContext'
import { useUserContext } from 'layers/content/v2/hooks/useUserContext'
import { ERROR_TYPES } from 'layers/content/constants'

export function useRouteGetter(options) {
  const { route, app, filterUserContext = filterUniqueUserContextFields } =
    options || {}
  const [previousRoute, setPreviousRoute] = useState(route)
  const dispatch = useDispatch()
  const userContext = useUserContext()
  const { data, error, loading } = useSelector(state => {
    const data = state.routeServiceManager?.routes[route] ?? undefined
    const error = state.routeServiceManager?.error
    const loading = state.routeServiceManager?.isFetching

    return {
      data,
      error: error
        ? { message: error, errorType: ERROR_TYPES.routeServiceError }
        : null,
      loading,
    }
  })

  useEffect(() => {
    const hasUserContext = userContext && Object.keys(userContext).length > 0
    if (!!previousRoute && previousRoute !== route) {
      // tell Redux to ignore the previous route fetched
      dispatch(operations.cancelFetchRoute({ app, route: previousRoute }))
      setPreviousRoute(route)
    }

    if (!!error || (!error && (!hasUserContext || !!data))) {
      return
    }

    dispatch(
      operations.fetchRoute({
        app,
        route,
        userContext: filterUserContext(userContext),
      }),
    )

    return () => {
      // cancel our route-service request on unmount:
      dispatch(operations.cancelFetchRoute({ app, route }))
      setPreviousRoute(null)
    }
  }, [userContext, route])

  return {
    data,
    error,
    loading,
  }
}
