import { ContainedButton, FormInputCheckBox } from 'secondstep-components'
import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  ModalHeaderInput,
  ButtonsWrapper,
  CancelButton,
  ModalWrapperInput,
} from '../component.styles'

export const DeleteMessageWrapper = styled.div`
  color: ${themeGet('colors.darkGray')};
  margin-bottom: 0.5rem;
  text-align: center;

  span {
    font-family: ${themeGet('fontFamily')};
    font-size: 1rem;
  }
`

export const DeleteWarningSpan = styled.span`
  color: ${themeGet('colors.deleteRed')};
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 1rem;
  margin-bottom: 0.3125rem;
  text-align: center;
`

export const ModalWrapperDelete = styled(ModalWrapperInput).attrs(() => ({
  size: 'small',
}))`
  padding: 2.3125rem 2.5rem 2.125rem 2.5rem;

  ${CancelButton} {
    background: none;
    margin: 0.5rem 0.875rem 0.4375rem 0.125rem !important;
    max-width: 9.375rem;
    padding: 0.5rem 1.25rem 0.3125rem 1.25rem;
    width: 50%;

    @media screen and (max-width: 398px) {
      margin-top: 0.5rem;
      padding: 0.5rem 0 0.5rem 0;
    }

    ${themeGet('breakpoints.mobile')} {
      margin-bottom: 0 !important;
      margin-right: 0.625rem !important;
    }
  }

  ${ModalHeaderInput} {
    color: ${themeGet('colors.themeSecondary')};

    font-family: ${themeGet('font.sharpSlab.extraBold')};
    font-size: 1.625rem;
    margin-bottom: 1rem;
    text-align: center;

    ${themeGet('breakpoints.mobile')} {
      margin-bottom: 1.25rem;
    }
  }

  ${themeGet('breakpoints.mobile')} {
    padding: 2.3125rem 1.25rem 2.5rem 1.25rem;
  }
`

export const ClassCardModalWrapperDelete = styled(ModalWrapperDelete)`
  ${ModalHeaderInput} {
    color: ${themeGet('colors.secondStepBlue')};

    font-family: ${themeGet('font.sharpSlab.semibold')};
    font-size: 1.625rem;
    margin-bottom: 0.75rem;
    text-align: center;

    ${themeGet('breakpoints.mobile')} {
      margin-bottom: 1rem;
    }
  }

  ${themeGet('breakpoints.mobile')} {
    padding: 2.5rem 1.25rem;
  }
`

export const DeleteButton = styled(ContainedButton)`
  background: ${props =>
    props.isDisabled
      ? themeGet('colors.gray650')
      : themeGet('colors.deleteRed')} !important;
  border: none;
  margin: 0.5rem 0.0625rem 0.4375rem 0.125rem;
  max-width: 9.375rem;
  opacity: 1;
  padding: 0.5rem 0 0.3125rem 0;
  width: 50%;

  @media screen and (max-width: 398px) {
    margin-top: 0.5rem;
    padding: 0.5rem 0 0.5rem 0;
  }

  ${themeGet('breakpoints.mobile')} {
    margin-bottom: 0;
  }
`

export const DeleteCheckBox = styled(FormInputCheckBox)`
  margin-bottom: 0.8125rem;

  [class*='CheckBoxOptionsContainer'] {
    justify-content: center;

    ${themeGet('breakpoints.mobile')} {
      align-items: center;
    }
  }

  [class*='CheckBoxInputContainer'] {
    margin-right: 0;
  }

  [class*='CheckBoxHidden']:checked ~ [class*='CheckBoxStyled'] {
    color: ${themeGet('colors.white')};
  }

  label {
    color: ${themeGet('colors.darkGray')};
    margin-bottom: 0;
  }
`

export const StyledButtonsWrapper = styled(ButtonsWrapper)`
  position: relative;
  bottom: 0;
  left: 0;

  justify-content: center;
  margin: 0;
  transform: none;
  width: 100%;

  ${themeGet('breakpoints.mobile')} {
    left: 0;
  }
`
