import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'
import { HeaderOne } from 'secondstep-components'
import {
  getHighlightColor,
  getBackgroundGradient,
} from 'utils/highschoolHelpers'
import { variables } from 'themes'
import { DefaultSiteSelectorWrapper } from 'components/SiteSelector'

export const tabTheme = {
  colors: {
    buttons: {
      tabActive: variables.colors.hsPrimaryPink,
      tabDefault: variables.colors.darkGray,
    },
  },
  font: {
    molde: {
      medium: variables.font.molde.medium,
      regular: variables.font.molde.regular,
      semiBold: variables.font.molde.semiBold,
    },
  },
}

export const ActivityButton = styled(Button)`
  background: ${themeGet('colors.buttons.primary.bgActive')};
  color: ${themeGet('colors.buttons.primary.titleActive')};
  border-radius: 0.5rem;
  padding: 21px;
  padding-top: 0.688rem;
  padding-bottom: 0.563rem;
  margin-bottom: 21px;
  font-family: ${themeGet('font.molde.medium')};
  font-size: 1rem;
  text-align: center;
  ${themeGet('breakpoints.mobileLarge')} {
    border-radius: 0.3rem;
    margin-bottom: 2.55rem;
  }
  :hover {
    background: ${themeGet('colors.buttons.primary.bgHover')};
    color: ${themeGet('colors.buttons.primary.titleHover')};
  }
`

export const AudienceTagContainer = styled.div`
  display: flex;
  gap: 0.75rem;
  margin-bottom: 1.75rem;
  ${themeGet('breakpoints.mobileLarge')} {
    justify-content: center;
    margin-top: 1.25rem;
    margin-bottom: 1.75rem;
  }
  div {
    background-color: ${props => getHighlightColor(props.audience)};
    border-radius: 0.3rem;
    padding: 0rem 0.75rem;
    font-size: 0.75em;
  }
`

export const ControlPanel = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 1.25rem 3.5625rem 1.25rem;
  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: ${themeGet('breakpoints.sizes.large')};
    ${themeGet('breakpoints.mobileLarge')} {
      flex-direction: column-reverse;
      padding-bottom: 0.125rem;
    }
  }

  // targets the TabSet buttons:
  [class*='StyledGrommet'] {
    align-self: last baseline;
    margin-bottom: 1.3rem;
    ${themeGet('breakpoints.mobileLarge')} {
      align-self: first baseline;
    }
  }
`

export const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: 2.5rem;
  background: ${props => getBackgroundGradient(props.audience)};
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: ${themeGet('breakpoints.sizes.large')};
  }
`

export const ExternalLinkIcon = styled.img`
  margin-left: 0.5rem;
  margin-bottom: -0.15rem;
`

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.3rem;
  ${themeGet('breakpoints.large')} {
    margin: 1.3rem 1.25rem;
  }
  ${themeGet('breakpoints.mobileLarge')} {
    margin: 1.3rem 1rem;
  }
  .content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: ${themeGet('breakpoints.sizes.large')};
    ${themeGet('breakpoints.mobileLarge')} {
      flex-direction: column-reverse;
      align-items: center;
      text-align: center;
    }
  }

  .resource-image {
    // fixed dimensions on desktop
    width: 21.438rem;
    height: 11.625rem;
    ${themeGet('breakpoints.mobileLarge')} {
      width: unset;
      height: unset;
      max-width: 100%;
    }
  }
`

export const PageContainer = styled.div`
  width: 100%;
`

export const StyledHeaderOne = styled(HeaderOne)`
  color: ${themeGet('colors.heading.level.1')};
  ${themeGet('breakpoints.mobileLarge')} {
    text-align: center;
  }
`

export const SurveyLink = styled.a`
  display: block;
  color: ${themeGet('colors.highschool.green2')};
  font-family: ${themeGet('font.molde.medium')};
  font-size: 1.125rem;
  text-decoration: none;
  margin-top: 1.563rem;
  :visited {
    color: ${themeGet('colors.highschool.green2')};
  }
  :focus,
  :hover {
    color: ${themeGet('colors.highschool.green1')};
  }
`

export const BannerSpacer = styled.div`
  margin-top: 7rem;
`
export const SiteSelectorWrapper = styled(DefaultSiteSelectorWrapper)`
  margin: auto;
  max-width: ${themeGet('breakpoints.sizes.large')};
  padding-right: 0;

  ${themeGet('breakpoints.large')} {
    padding-right: 1rem;
  }
`

export const HsIndividualResourceMarkCompleteWrapper = styled.div`
  margin-top: 1.5rem;
`
