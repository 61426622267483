import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const ReportsModuleBox = styled.div`
  width: 100%;
  padding: 1.5rem 1.25rem 1.25rem;
  margin-bottom: 2.5rem;
  box-sizing: border-box;
  border: 2px solid ${themeGet('colors.borders.primary', 'colors.beaconGray')};
  border-radius: 0.625rem;
  background: ${themeGet('colors.backgrounds.primary', 'colors.white')};
  z-index: 1;

  ${themeGet('breakpoints.mobile')} {
    padding: 1.125rem 1rem 1rem;
    margin-bottom: 1rem;
  }
`
