import styled from 'styled-components'
import { Box, Anchor, Text } from 'grommet'
import { Hairline } from 'secondstep-components'
import { themeGet } from 'styled-system'

export const QuickLinksWrapper = styled(Box)`
  margin-top: 1.2rem;
  align-items: center;
  width: 100%;
`

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  line-height: normal;
  margin-bottom: 1.25rem !important;
`

export const AdminTile = styled(Anchor).attrs(({ text }) => ({
  children: text,
}))`
  font-size: 14px;
  color: ${props => themeGet('globalNav.titleColor')(props)};
  transition: 0.2s;
  align-self: flex-start;
  margin-top: 0.2rem;
  &:hover {
    text-decoration: none;
  }
`

export const ReportsTile = styled(Anchor).attrs(({ text }) => ({
  children: text,
}))`
  font-size: 14px;
  color: ${props => themeGet('globalNav.titleColor')(props)};
  transition: 0.2s;
  align-self: flex-start;
  line-height: 1.25rem;
  &:hover {
    text-decoration: none;
  }
`
export const Disclaimer = styled(Text)`
  margin-bottom: 1.25rem !important;
  font-size: 13px;
  word-break: break-word;
  color: ${props => themeGet('dashboard.gray1')(props)};
  transition: 0.2s;
  align-self: flex-start;
  line-height: 1.063rem;
  text-align: start !important;
  a {
    display: inline;
  }
  &:hover {
    text-decoration: none;
  }
`
export const LinkSeparator = styled(Hairline)`
  width: 100%;
  border-top: 2px solid ${props => themeGet('globalNav.dividerColor')(props)};
  background: none;
  height: unset;
`
