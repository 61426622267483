import React, { Component } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import ContentDataEntryManager from 'layers/content/Hocs/dataManagers/contentDataEntryManager'

export default function ContentEntryDataGetter(
  component,
  options = {},
  ...functions
) {
  const {
    entryId,
    include,
    preview,
    mapper,
    spread,
    prefetch,
    log,
    entryIdProp,
  } = options

  log &&
    console.log('ContentEntryDataGetter', {
      entryId,
      include,
      preview,
      mapper,
      spread,
      prefetch,
      entryIdProp,
      log,
    })

  const entryRequestOptions = {
    entryId,
    include,
    preview,
    entryIdProp,
    log,
  }

  const entryResultOptions = {
    mapper,
    spread,
  }

  const Manager = new ContentDataEntryManager(
    entryRequestOptions,
    entryResultOptions,
  )

  const mergeProps = (stateProps, dispatchProps, ownProps) => ({
    log,
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    prefetch,
    component,
  })

  const baseConnect = connect(
    Manager.mapStateToProps,
    Manager.mapDispatchToProps,
    mergeProps,
  )

  return compose(baseConnect, ...functions)(ContentLifecycleHandler)
}

export class ContentLifecycleHandler extends Component {
  static propTypes = {
    component: PropTypes.elementType,
    entry: PropTypes.shape({
      id: PropTypes.string,
    }),
    entryId: PropTypes.string,
    getEntry: PropTypes.func.isRequired,
    getEntryWithLocale: PropTypes.func.isRequired,
    idOnEntry: PropTypes.string,
    locale: PropTypes.string,
    log: PropTypes.bool,
    prefetch: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(PropTypes.string),
    ]),
    prefetchEntryWithNewLocale: PropTypes.func,
    preview: PropTypes.bool,
  }

  componentDidMount() {
    const {
      getEntry,
      entryId,
      locale,
      getEntryWithLocale,
      preview,
      log,
    } = this.props

    if (log) {
      console.log('ContentLifecycleHandler componentDidMount', this.props)
    }

    this.prefetching()

    if (getEntryWithLocale && entryId && locale) {
      getEntryWithLocale(entryId, locale, preview)
      return
    }

    if (getEntry && entryId) {
      getEntry(entryId, locale, preview)
    }
  }

  componentDidUpdate(prevProps) {
    const {
      getEntry,
      entryId,
      locale,
      getEntryWithLocale,
      preview,
      log,
    } = this.props

    const { entryId: prevEntryId, locale: prevLocale } = prevProps
    if (log) {
      console.log(
        'ContentLifecycleHandler componentDidUpdate this.props, prevProps',
        this.props,
        prevProps,
      )
    }

    if (entryId === prevEntryId && locale === prevLocale) {
      if (log) {
        console.log(
          'ContentLifecycleHandler Early return',
          this.props,
          prevProps,
        )
      }
      return
    }

    if (entryId !== prevEntryId) {
      log &&
        console.log(
          'ContentLifecycleHandler prefetching',
          this.props,
          prevProps,
        )

      this.prefetching()
    }

    if (getEntryWithLocale && entryId && locale) {
      log &&
        console.log(
          'ContentLifecycleHandler getEntryWithLocale',
          this.props,
          prevProps,
        )
      getEntryWithLocale(entryId, locale, preview)
      return
    }

    if (entryId && getEntry) {
      log &&
        console.log('ContentLifecycleHandler getEntry', this.props, prevProps)
      getEntry(entryId, preview)
    }
  }

  prefetching = () => {
    const {
      entryId,
      preview,
      prefetch,
      prefetchEntryWithNewLocale,
    } = this.props

    if (prefetch && prefetchEntryWithNewLocale && entryId) {
      const locales = Array.isArray(prefetch) ? prefetch : [prefetch]
      locales.forEach(locale => {
        prefetchEntryWithNewLocale(entryId, locale, preview)
      })
    }
  }

  render() {
    // rest includes parentEntry if wrapped in a ContentParentNodeGetter HOC
    const {
      component: Component,
      log,
      idOnEntry,
      entryId,
      ...rest
    } = this.props
    log && console.log('ContentLifecycleHandler render', this.props, Component)

    if (
      (entryId && idOnEntry && idOnEntry === entryId) ||
      (!!idOnEntry && !entryId)
    ) {
      return (
        <Component
          {...rest}
          entryId={entryId}
          isContentChangingEntries={false}
        />
      )
    } else {
      // if entryId doesnt match entry.id then we are transitioning and dont want to show false data
      return <Component {...rest} isContentChangingEntries={true} />
    }
  }
}
