import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Tooltip, TooltipContentWrapper } from 'secondstep-components'
import {
  EDUCATOR_PRACTICES_HEADER_TEXT,
  SCHOOL_HEADER_TEXT,
  SCHOOLWIDE_PRACTICES_HEADER_TEXT,
  STUDENT_ACTIVITIES_HEADER_TEXT,
} from './constants'

export const StyledTooltip = styled(Tooltip).attrs(({ label, monitorView }) => {
  const singleLineLabel = label?.replace(/\s/g, ' ')
  const isEducatorPracticesOrSwPractices =
    singleLineLabel === EDUCATOR_PRACTICES_HEADER_TEXT ||
    singleLineLabel === SCHOOLWIDE_PRACTICES_HEADER_TEXT
  const isSchool = singleLineLabel === SCHOOL_HEADER_TEXT
  const isStudentActivities = singleLineLabel === STUDENT_ACTIVITIES_HEADER_TEXT
  return {
    afterBottom: monitorView
      ? isEducatorPracticesOrSwPractices
        ? '31%'
        : '51%'
      : '43%',
    afterLeft: monitorView && isStudentActivities ? '168px' : '76px',
    tooltipLeft: monitorView && isStudentActivities ? '-45px' : '48px',
    tooltipTop: monitorView
      ? isEducatorPracticesOrSwPractices
        ? '-150px'
        : '-95px'
      : '-112px',
    width: monitorView && isSchool ? '214px' : '211px',
  }
})`
  width: auto;
  margin-left: 0.5rem;

  i.fas {
    color: ${themeGet('colors.blueGray')};
    font-size: 0.875rem;
  }

  span {
    display: block;
    line-height: 1.15rem;
  }

  .tooltip-header {
    margin-bottom: 0.125rem;
    font-family: ${themeGet('font.molde.semiBold')};
  }

  ${TooltipContentWrapper} {
    top: ${({ tooltipTop }) => tooltipTop};
    left: ${({ tooltipLeft }) => tooltipLeft};
    width: ${({ width }) => width};
    padding: 0.75rem 1rem;
    border-radius: 0.3125rem;
    color: ${themeGet('colors.gray675')};
    text-align: left;
    text-wrap: wrap;
    line-height: 1rem;

    ::after {
      content: ' ';
      width: 0.75rem;
      height: 0.75rem;
      position: absolute;
      bottom: ${({ afterBottom }) => afterBottom};
      left: ${({ afterLeft }) => afterLeft};
      background-color: white;
      transform: translateY(400%) rotate(45deg);
    }
  }
`
