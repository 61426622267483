import React from 'react'
import PropTypes from 'prop-types'
import AdminHSSetup from 'components/AdminHSSetup'
import HighSchoolLaunchSnapshot from 'components/admin/LaunchSnapshot/HighSchool'
import {
  Bullet,
  CollapsibleListItem,
  ColumnCopy,
  ColumnWrapper,
  NameWrapper,
  PanelWrapper,
  PendingCount,
  StaffTrainingCompletionColumn,
  StyledProgressBarIndicator,
} from './component.styles'
import { LAUNCH_TEXT, PENDING, SCHOOL_SETUP_TEXT } from './constants'

const LaunchListItem = ({
  site,
  index,
  isMultiSite,
  isOpen,
  handleListItemClick,
  selectedProgramKey,
}) => {
  const {
    preferences,
    siteId,
    siteName,
    totalUsersCount,
    adminsPendingCount,
    schoolTraining,
    teachersPendingCount,
  } = site || {}
  const { isLaunched, isSetup } = preferences || {}
  const { training_program_completion_percent = 0 } = schoolTraining || {}
  const percentageComplete = Math.floor(
    training_program_completion_percent * 100.0,
  )
  const keyText = `${siteName}-${siteId}`
  const totalPendingCount = adminsPendingCount + teachersPendingCount

  return (
    <CollapsibleListItem
      followActiveProp={true}
      isActive={isOpen}
      key={`collapsible-${siteId}-${index}`}
      onClick={handleListItemClick}
      title={
        <PanelWrapper>
          <NameWrapper>{siteName}</NameWrapper>
          <ColumnWrapper>
            <ColumnCopy>{totalUsersCount}</ColumnCopy>
            {adminsPendingCount + teachersPendingCount > 0 && (
              <PendingCount>
                {adminsPendingCount + teachersPendingCount} {PENDING}
              </PendingCount>
            )}
          </ColumnWrapper>
          <ColumnWrapper>
            {isLaunched && (
              <StaffTrainingCompletionColumn>
                <StyledProgressBarIndicator
                  keyText={keyText}
                  percentComplete={percentageComplete}
                  shouldRenderIndicator={isLaunched}
                />
              </StaffTrainingCompletionColumn>
            )}
            {!isLaunched && !isSetup && (
              <StaffTrainingCompletionColumn>
                <Bullet />
                {SCHOOL_SETUP_TEXT}
              </StaffTrainingCompletionColumn>
            )}
            {!isLaunched && isSetup && (
              <StaffTrainingCompletionColumn>
                {LAUNCH_TEXT}
              </StaffTrainingCompletionColumn>
            )}
          </ColumnWrapper>
        </PanelWrapper>
      }
      totalPendingCount={totalPendingCount}
    >
      {isLaunched && (
        <HighSchoolLaunchSnapshot programKey={selectedProgramKey} site={site} />
      )}
      {!isLaunched && (
        <AdminHSSetup
          hideSetupHeader={true}
          isMultiSite={isMultiSite}
          preferences={preferences}
          programKey={selectedProgramKey}
          showHairline={false}
          siteId={siteId}
          siteName={siteName}
        />
      )}
    </CollapsibleListItem>
  )
}

LaunchListItem.propTypes = {
  handleListItemClick: PropTypes.func,
  index: PropTypes.number,
  isMultiSite: PropTypes.bool,
  isOpen: PropTypes.bool,
  selectedProgramKey: PropTypes.string,
  site: PropTypes.shape({
    preferences: PropTypes.shape({
      isLaunched: PropTypes.bool,
      isSetup: PropTypes.bool,
    }),
    siteId: PropTypes.number,
    siteName: PropTypes.string,
    totalUsersCount: PropTypes.number,
    adminsActiveCount: PropTypes.number,
    adminsPendingCount: PropTypes.number,
    teachersActiveCount: PropTypes.number,
    teachersPendingCount: PropTypes.number,
  }),
}

export default LaunchListItem
