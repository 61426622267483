export default {
  units: [
    {
      lessons: [
        ...Array(6).fill({ dateCompleted: null }),
        ...Array(10).fill({ dateCompleted: '2023-11-17 19:50:11.4220000' }),
      ],
    },
    {
      lessons: Array(21).fill({ dateCompleted: '2023-11-17 19:50:11.4220000' }),
    },
    {
      lessons: [
        ...Array(15).fill({ dateCompleted: null }),
        ...Array(5).fill({ dateCompleted: '2023-11-17 19:50:11.4220000' }),
      ],
    },
    {
      lessons: [
        ...Array(5).fill({ dateCompleted: null }),
        ...Array(6).fill({ dateCompleted: '2023-11-17 19:50:11.4220000' }),
      ],
    },
  ],
}
