import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { BodyCopy } from 'secondstep-components'
import {
  BodyCopyContainer,
  Container,
  ImageWrapper,
  StyledImage,
  StyledClosableModal,
} from './component.styles'
import WithZoom from 'components/WithZoom'

const ImageBodyCopyFlexible = ({
  altTextShort,
  bodyCopy1,
  expandImage,
  imageJustification,
  imageSize,
  url,
  isZoomable,
}) => {
  const [showModal, setShowModal] = useState(false)

  const openModal = event => {
    if (
      !expandImage ||
      showModal ||
      (event.type !== 'click' && event.key !== 'Enter')
    ) {
      return
    }

    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const bodyCopyMarkdown = (
    <BodyCopyContainer>
      <BodyCopy>{bodyCopy1}</BodyCopy>
    </BodyCopyContainer>
  )

  const Image = <StyledImage alt={altTextShort} src={url} />
  const ImageAsFunc = () => Image
  const EnhancedImage = WithZoom()(Image)
  return (
    <Container>
      {imageJustification === 'right-justified' && bodyCopyMarkdown}
      <ImageWrapper expandImage={expandImage} width={imageSize}>
        <StyledImage
          alt={altTextShort}
          onClick={openModal}
          onKeyPress={openModal}
          src={url}
          tabIndex="0"
        />
      </ImageWrapper>
      {imageJustification === 'left justified' && bodyCopyMarkdown}
      <StyledClosableModal
        className="image-body-modal"
        onClose={closeModal}
        show={showModal}
      >
        {(isZoomable && <EnhancedImage />) || <ImageAsFunc />}
      </StyledClosableModal>
    </Container>
  )
}

ImageBodyCopyFlexible.propTypes = {
  altTextShort: PropTypes.string,
  bodyCopy1: PropTypes.string,
  expandImage: PropTypes.bool,
  imageJustification: PropTypes.string,
  imageSize: PropTypes.string,
  isZoomable: PropTypes.bool,
  url: PropTypes.string,
}

export default ImageBodyCopyFlexible
