import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withApiError, {
  ERROR_TYPE_REPORTING,
} from 'layers/errorHandling/apiError'
import { selectors as reportsSelectors } from 'store/reportsManager'
import K8Class from './component'
import { getErrorStatusCode } from 'layers/errorHandling/apiError/errorHelper'

export const K8ClassContainer = ({
  classDetailsReport,
  classLessonColTooltip,
  classMarkedDoneTooltip,
  classStatusColTooltip,
  fixedTop,
  fixedView,
  gradeName,
  hasBHU,
  hasError,
  isFetchingClass,
  statusCode,
}) => {
  return (
    <K8Class
      classDetailsReport={classDetailsReport}
      classLessonColTooltip={classLessonColTooltip}
      classMarkedDoneTooltip={classMarkedDoneTooltip}
      classStatusColTooltip={classStatusColTooltip}
      fixedTop={fixedTop}
      fixedView={fixedView}
      gradeName={gradeName}
      hasBHU={hasBHU}
      hasError={hasError}
      isFetchingClass={isFetchingClass}
      statusCode={statusCode}
    />
  )
}

export const mapStateToProps = state => {
  const { reportsManager, router } = state
  const { error, isFetchingClass } = reportsManager
  const { requestName } = error || {}
  const hasError = requestName === 'k8-class-details'
  const statusCode = getErrorStatusCode(error || {})

  const { class: classInstance } = router.location.query
  const classDetailsReport = reportsSelectors.selectClassDetailsReport(
    state,
    classInstance,
  )

  return {
    classDetailsReport,
    hasError,
    isFetchingClass,
    statusCode,
  }
}

K8ClassContainer.propTypes = {
  classDetailsReport: PropTypes.object,
  classLessonColTooltip: PropTypes.string,
  classMarkedDoneTooltip: PropTypes.string,
  classStatusColTooltip: PropTypes.string,
  fixedTop: PropTypes.bool,
  fixedView: PropTypes.bool,
  gradeName: PropTypes.string,
  hasBHU: PropTypes.bool,
  hasError: PropTypes.bool,
  isFetchingClass: PropTypes.bool,
  statusCode: PropTypes.number,
}

export default connect(
  mapStateToProps,
  null,
)(withApiError(K8ClassContainer, [ERROR_TYPE_REPORTING]))
