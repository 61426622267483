import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Collapsible } from 'grommet'
import errorData from './data'
import { Header } from '../component.styles'
import {
  CloseButton,
  ContainedButton,
  ModalBodyCopy,
  WarningIcon,
} from 'secondstep-components'
import {
  ButtonWrapper,
  ContentWrapper,
  ModalBodyCopyStyled,
  ModalWrapperErrorDetails,
  SubHeader,
  CollapsibleButtonStyled,
} from './component.styles'
import {
  ERROR_DETAILS_HEADER,
  errorDetailsHeaderStatus,
  ERROR_DETAILS_SUBHEADER,
  ERROR_DETAILS_BODYCOPY,
  ERROR_DETAILS_CLOSE,
} from './constants'

const propTypes = {
  dataTestId: PropTypes.string,
  error: PropTypes.object,
  errorFor: PropTypes.string,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
}

const ErrorDetails = ({
  dataTestId,
  error,
  handleCancel,
  handleSubmit,
  errorFor,
}) => {
  const [isOpen, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!isOpen)
  const errorCode = error.toString().split('code ')[1] || 500
  const errorInfo = errorData[errorCode]
  return (
    <ModalWrapperErrorDetails dataTestId={dataTestId}>
      <CloseButton dataTestId="button-close" onClick={handleCancel} />
      <ContentWrapper>
        <WarningIcon dataTestId="icon-warning" />
        <Header dataTestId="modal-error-details-header">
          {errorFor ? errorDetailsHeaderStatus(errorFor) : ERROR_DETAILS_HEADER}
        </Header>
        <SubHeader data-testid="modal-error-details-subheader">
          {ERROR_DETAILS_SUBHEADER}
        </SubHeader>
        <ModalBodyCopy data-testid="modal-error-details-bodycopy">
          {ERROR_DETAILS_BODYCOPY}
        </ModalBodyCopy>
      </ContentWrapper>
      <ButtonWrapper>
        <ContainedButton dataTestId={'button-try-again'} onClick={handleSubmit}>
          {ERROR_DETAILS_CLOSE}
        </ContainedButton>
      </ButtonWrapper>
      <CollapsibleButtonStyled
        dataTestId="modal-error-details-collapsible"
        isOpen={isOpen}
        onClick={toggleOpen}
        small
      >
        {isOpen ? 'Hide Details' : 'Show Details'}
      </CollapsibleButtonStyled>
      <Collapsible open={isOpen}>
        <ModalBodyCopyStyled data-testid="modal-details-error-code">
          {`Error code: ${errorCode} ${errorInfo.type}   
          ${errorInfo.message}`}
        </ModalBodyCopyStyled>
      </Collapsible>
    </ModalWrapperErrorDetails>
  )
}

ErrorDetails.propTypes = propTypes

export default ErrorDetails
