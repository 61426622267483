import {
  CONTINUE_LEARNING,
  CONTINUE_TO_MEETING,
  LEADER_TEAM,
  LEADER_TEAM_TITLE,
  MINUTES,
  ML_TYPE_INDIVIDUAL,
  ML_TYPE_LEADER_GROUP,
  ML_TYPE_SMALL_GROUP,
  SMALL_GROUP,
  START_MODULE,
} from './constants'

export const calculateInsetData = ({
  contentfulNextMicrolearningItem1Lesson: leaderLesson,
  contentfulNextMicrolearningItem2Lesson: staffLesson,
  contentfulNextMicrolearningTitle,
  contentfulNextMicrolearningTime,
  contentfulNextMicrolearningType,
  lmsNextMicrolearning,
  isModuleDone,
  isModuleStarted,
}) => {
  // module is complete
  if (isModuleDone) {
    return {}
  }

  // choice items, leader
  if (leaderLesson?.microlearningType === ML_TYPE_LEADER_GROUP) {
    return {
      leaderChoice: lmsNextMicrolearning?.choices?.[0],
      leaderButtonText: LEADER_TEAM,
      staffChoice: lmsNextMicrolearning?.choices?.[1],
      staffButtonText: SMALL_GROUP,
      time: formatTime(leaderLesson?.time),
      title: LEADER_TEAM_TITLE,
    }
  }

  // choice items, staff
  if (staffLesson?.microlearningType === ML_TYPE_SMALL_GROUP) {
    return {
      staffChoice: lmsNextMicrolearning?.choices?.[0],
      staffButtonText: CONTINUE_TO_MEETING,
      time: formatTime(staffLesson?.time),
      title: `${staffLesson?.label}: ${staffLesson?.displayTitle}`,
    }
  }

  // non-choice item, leader group meeting
  if (contentfulNextMicrolearningType === ML_TYPE_LEADER_GROUP) {
    return {
      leaderChoice: lmsNextMicrolearning,
      leaderButtonText: CONTINUE_TO_MEETING,
      time: formatTime(contentfulNextMicrolearningTime),
      title: contentfulNextMicrolearningTitle,
    }
  }

  // non-choice item, small group meeting
  if (contentfulNextMicrolearningType === ML_TYPE_SMALL_GROUP) {
    return {
      staffChoice: lmsNextMicrolearning,
      staffButtonText: CONTINUE_TO_MEETING,
      time: formatTime(contentfulNextMicrolearningTime),
      title: contentfulNextMicrolearningTitle,
    }
  }

  // standard microlearning
  if (contentfulNextMicrolearningType === ML_TYPE_INDIVIDUAL) {
    return {
      staffChoice: lmsNextMicrolearning,
      staffButtonText: !isModuleStarted ? START_MODULE : CONTINUE_LEARNING,
      time: formatTime(contentfulNextMicrolearningTime),
      title: contentfulNextMicrolearningTitle,
    }
  }

  return {}
}

export const formatTime = time => (time ? `${time} ${MINUTES}` : null)
