import { getQueryParams } from './urlHelpers'

const SESSION_STORAGE_LOCATION = 'cacheToken'
const URL_QUERY_NAME = 'cache'

// doesnt store any values. Just a means to get and set preview token on the browser or retrieve it
export default class PreviewTokenManager {
  getToken = () => {
    const previewToken =
      this.parseUrlForToken() ||
      (sessionStorage && sessionStorage.getItem(SESSION_STORAGE_LOCATION))

    return previewToken
  }

  parseUrlForToken = () => {
    const windowUrl = window.location.href
    const queries = getQueryParams(windowUrl)
    const previewToken = queries[URL_QUERY_NAME]

    return previewToken
  }

  setToken = () => {
    const token = this.getToken()

    if (!token) {
      return
    }

    sessionStorage && sessionStorage.setItem(SESSION_STORAGE_LOCATION, token)
  }
}
