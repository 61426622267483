import React from 'react'
import PropTypes from 'prop-types'
import { RouteContentType } from 'layers/content/v2/Hocs/RouteContentGetter'
import NavigationHelper from 'layers/navigation/navigationHelper'
import CardSection from './cardSection'
import {
  PageContentWrapper,
  HeaderText,
  HeaderContainer,
  HeaderDescription,
  ContentContainer,
  ContentHeader,
  StyledBodyCopy,
  CardContentContainer,
} from './component.styles'

export const ExtensionActivities = ({ routeContent, navigationEvent }) => {
  const content = routeContent?.content
  const extensionLanding = content?.extensionLanding
  const displayTitle = extensionLanding?.displayTitle
  const description = extensionLanding?.description
  const contentArray = extensionLanding?.content

  // Filter out content items that have cards
  const cardData =
    contentArray?.filter(
      item => Array.isArray(item?.cards) && item.cards.length > 0,
    ) || []

  const handleClick = path => {
    navigationEvent?.(path, NavigationHelper.types.PUSH)
  }

  return (
    <PageContentWrapper>
      <HeaderContainer>
        <HeaderText>{displayTitle}</HeaderText>
        <HeaderDescription>{description}</HeaderDescription>
      </HeaderContainer>

      <ContentContainer>
        {/* content[0] will always be the header and description */}
        {contentArray?.[0] && (
          <>
            <ContentHeader>{contentArray[0]?.h2}</ContentHeader>
            <StyledBodyCopy>{contentArray[0]?.bodyCopy}</StyledBodyCopy>
          </>
        )}

        <CardContentContainer>
          {cardData?.map((section, index) => (
            <CardSection
              handleClick={handleClick}
              key={index}
              section={section}
            />
          ))}
        </CardContentContainer>
      </ContentContainer>
    </PageContentWrapper>
  )
}

ExtensionActivities.propTypes = {
  navigationEvent: PropTypes.func.isRequired,
  routeContent: RouteContentType(
    PropTypes.shape({
      redirectRule: PropTypes.shape({
        routeData: PropTypes.shape({
          route: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
    }),
  ),
}
