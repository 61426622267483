import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import {
  Background,
  IndicatorWrapper,
  Indicator,
  ProgressOutsideBar,
  ProgressInsideBar,
  StyledTooltip,
  TooltipContainer,
  Wrapper,
} from './component.styles'
import { ANIMATION_TIME } from './constants'

const ProgressBarIndicator = ({
  className,
  isParentMounted,
  isValueInsideBar,
  keyText,
  percentComplete,
  shouldRenderIndicator = true,
  shouldRenderTextProgress = false,
  tooltipContent = null,
}) => {
  const [isReadyToRender, setIsReadyToRender] = useState(false)
  const [progressWidth, setProgressWidth] = useState(0)
  const ref = useRef()
  const progressPercentage = `${percentComplete}%`
  const progressPercentageWithText =
    percentComplete === 0 ? 'Not Started' : `${percentComplete}% Complete`

  useEffect(() => {
    if (isParentMounted?.current && shouldRenderIndicator) {
      setIsReadyToRender(false)
      setTimeout(() => {
        setIsReadyToRender(true)

        const observer = new ResizeObserver(entries => {
          if (isParentMounted.current)
            setProgressWidth(entries[0].contentRect.width)
        })
        observer.observe(ref.current)
        return () => {
          ref.current && observer.unobserve(ref.current)
        }
      }, ANIMATION_TIME)
    }
  }, [isParentMounted, keyText, percentComplete])

  return (
    <Wrapper className={className} key={keyText}>
      <Background
        className="BackgroundClass"
        shouldRenderIndicator={shouldRenderIndicator}
      >
        {shouldRenderIndicator && (
          <IndicatorWrapper key={`${keyText}-indicator-wrapper`}>
            <Indicator
              animation_time={ANIMATION_TIME}
              key={`${keyText}-indicator`}
              percentComplete={percentComplete}
              ref={el => {
                if (isParentMounted) ref.current = el
              }}
            >
              {isValueInsideBar && isReadyToRender && progressWidth >= 35 && (
                <ProgressInsideBar
                  animation_time={ANIMATION_TIME}
                  progress_width={progressWidth}
                >
                  {shouldRenderTextProgress
                    ? progressPercentageWithText
                    : progressPercentage}
                </ProgressInsideBar>
              )}
            </Indicator>
            {isValueInsideBar && isReadyToRender && progressWidth < 35 && (
              <ProgressInsideBar
                animation_time={ANIMATION_TIME}
                progress_width={progressWidth}
              >
                {shouldRenderTextProgress
                  ? progressPercentageWithText
                  : progressPercentage}
              </ProgressInsideBar>
            )}
          </IndicatorWrapper>
        )}
        {tooltipContent && (
          <StyledTooltip
            content={tooltipContent}
            percentComplete={percentComplete}
            tooltipContentPosition={percentComplete}
          >
            <TooltipContainer percentComplete={percentComplete} />
          </StyledTooltip>
        )}
      </Background>
      {!isValueInsideBar && (
        <ProgressOutsideBar>
          {shouldRenderTextProgress
            ? progressPercentageWithText
            : progressPercentage}
        </ProgressOutsideBar>
      )}
    </Wrapper>
  )
}

ProgressBarIndicator.propTypes = {
  className: PropTypes.string,
  isParentMounted: PropTypes.object,
  isValueInsideBar: PropTypes.bool,
  keyText: PropTypes.string,
  percentComplete: PropTypes.number,
  shouldRenderIndicator: PropTypes.bool,
  shouldRenderTextProgress: PropTypes.bool,
  tooltipContent: PropTypes.node,
}

export default ProgressBarIndicator
