import React from 'react'
import PropTypes from 'prop-types'
import { Form } from 'grommet'
import {
  CloseButton,
  ContainedButton,
  HeartsWithStars,
} from 'secondstep-components'

import { getSchoolOption } from 'utils/productHelpers'
import {
  CancelButton,
  ModalHeaderInput,
  ModalWrapperInput,
} from '../component.styles'

import {
  ButtonsWrapperCreate,
  InputTextField,
  SelectField,
  SectionContainer,
  Row,
  Column,
  Text,
  Link,
} from './component.styles'
import {
  ASK_NO_TEACHING,
  ALMOST_THERE,
  BUTTON_CANCEL,
  BUTTON_SUBMIT,
  COURSE_INSTANCE_LABEL,
  COURSE_INSTANCE_PLACEHOLDER,
  CREATE_HEADER,
  PREVIEW_LESSONS,
  SCHOOL_LABEL,
  SCHOOL_PLACEHOLDER,
  SELECTED_COURSE_LABEL,
  SELECTED_COURSE_PLACEHOLDER,
  START_TEACHING,
} from './constants'

const Create = ({
  courseInstanceName,
  courses,
  dataTestId,
  handleCancel,
  handleClassNameChange,
  handleGradeChange,
  handleLicenseChange,
  handleSubmit,
  inputErrors,
  licenseDisabled,
  licenses,
  lessonsLink,
  selectedCourse,
  selectedLicenseId,
  submitDisabled,
}) => {
  const selectedSchool =
    selectedLicenseId &&
    licenses.filter(({ licenseId }) => licenseId === selectedLicenseId)[0]

  const licenseOptions = licenses.map(license => getSchoolOption(license))
  const licenseTextOptions = licenseOptions.map(license => license.text)
  const licenseOption = selectedSchool ? getSchoolOption(selectedSchool) : {}
  const { component: licenseValueJsx } = licenseOption

  let selectedGrade = selectedCourse
  if (courses.length === 1) {
    selectedGrade = courses[0]
  }

  return (
    <ModalWrapperInput dataTestId={dataTestId}>
      <ModalHeaderInput dataTestId="header-modal-create">
        {CREATE_HEADER}
      </ModalHeaderInput>
      <SectionContainer>
        <Row>
          <Column>
            <HeartsWithStars />
          </Column>

          <Column>
            <Row>
              <Text>
                <b>{ALMOST_THERE}</b> {START_TEACHING}
              </Text>
            </Row>
            <Row>
              <Text as="span">
                <b>{ASK_NO_TEACHING}</b>
                <Link href={lessonsLink}>{PREVIEW_LESSONS}</Link>
              </Text>
            </Row>
          </Column>
        </Row>
      </SectionContainer>
      <Form aria-label="form" onSubmit={handleSubmit}>
        <SelectField
          autoComplete={false}
          dataTestId="select-license"
          label={SCHOOL_LABEL}
          name="selectedLicense"
          onChange={handleLicenseChange}
          options={licenseTextOptions}
          placeholder={SCHOOL_PLACEHOLDER}
          value={licenseValueJsx}
        />

        <SelectField
          autoComplete={licenseDisabled}
          dataTestId="select-grade"
          label={SELECTED_COURSE_LABEL}
          name="selectedCourse"
          onChange={handleGradeChange}
          options={courses}
          placeholder={SELECTED_COURSE_PLACEHOLDER}
          value={selectedGrade}
        />
        <InputTextField
          autoComplete={false}
          dataTestId="create-class-name"
          inputErrors={inputErrors.className}
          label={COURSE_INSTANCE_LABEL}
          maxLength={21}
          name="courseInstanceName"
          onChange={handleClassNameChange}
          placeholder={COURSE_INSTANCE_PLACEHOLDER}
          value={courseInstanceName}
        />
        <ButtonsWrapperCreate right>
          <CancelButton
            dataTestId={'button-cancel'}
            onClick={handleCancel}
            type="button"
          >
            {BUTTON_CANCEL}
          </CancelButton>
          <ContainedButton
            dataTestId={'button-submit'}
            disabled={submitDisabled}
            type="submit"
          >
            {BUTTON_SUBMIT}
          </ContainedButton>
        </ButtonsWrapperCreate>
        <CloseButton
          dataTestId="button-close"
          onClick={handleCancel}
          type="button"
        />
      </Form>
    </ModalWrapperInput>
  )
}

Create.propTypes = {
  courseInstanceName: PropTypes.string,
  courses: PropTypes.array,
  dataTestId: PropTypes.string,
  endDate: PropTypes.string,
  handleCancel: PropTypes.func,
  handleClassNameChange: PropTypes.func,
  handleGradeChange: PropTypes.func,
  handleLicenseChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  inputErrors: PropTypes.object,
  lessonsLink: PropTypes.string,
  licenseDisabled: PropTypes.bool,
  licenses: PropTypes.array,
  selectedCourse: PropTypes.string,
  selectedLicenseId: PropTypes.string,
  startDate: PropTypes.string,
  submitDisabled: PropTypes.bool,
}

Create.defaultProps = {
  courses: [],
  handleCancel: () => {},
  handleClassNameChange: () => {},
  handleGradeChange: () => {},
  handleLicenseChange: () => {},
  licenses: [],
}
export default Create
