import React, { memo } from 'react'
import { Bar, BarChart, ReferenceLine, XAxis, YAxis } from 'recharts'
import { ChartResponsiveContainer } from './component.styles'
import { themeGet } from 'styled-system'
import { withTheme } from 'styled-components'
import PropTypes from 'prop-types'

const MAX_Y_DOMAIN_VALUE = 10
const MIN_BAR_HEIGHT_FOR_VALUE_TO_FIT = 32

/**
 * Should get the nearest MAX_DOMAIN_VALUE of the currentMax
 * so it can draw the two horizontal lines with their values
 * as requested here https://projects.invisionapp.com/share/YD139EFUUWV3#/screens/473626718_Full_Release_K8_Onboarding
 */
function getDomainYMax(currentMax) {
  if (currentMax === 0) {
    return MAX_Y_DOMAIN_VALUE
  }

  if (currentMax % MAX_Y_DOMAIN_VALUE === 0) {
    return currentMax
  }

  return (Math.floor(currentMax / MAX_Y_DOMAIN_VALUE) + 1) * MAX_Y_DOMAIN_VALUE
}

const AdminBarChart = memo(props => {
  const { data, height = '100%', width = '100%' } = props
  const yMaxDomainValue = getDomainYMax(
    Math.max(...data.map(({ value }) => value)),
  )
  const paleGray = themeGet('colors.paleGray')(props)
  const darkGray = themeGet('colors.darkGray')(props)
  const elemDigital1Color = themeGet('colors.elemDigital1')(props)

  const customBarValueLabel = ({ x, y, width, height, value }) => {
    if (value > 0) {
      let yValue = y + MIN_BAR_HEIGHT_FOR_VALUE_TO_FIT
      let fill = 'white'

      if (height < MIN_BAR_HEIGHT_FOR_VALUE_TO_FIT) {
        yValue = y
        fill = elemDigital1Color
      }

      const textRelativeYCoordinate = -6
      const middleFactorPosition = 2

      return (
        <text
          dy={textRelativeYCoordinate}
          fill={fill}
          textAnchor="middle"
          x={x + width / middleFactorPosition}
          y={yValue}
        >
          {value}
        </text>
      )
    }

    return null
  }

  const maxBarWidth = 60

  const GradeLabel = ({ viewBox }) => {
    const { y, width } = viewBox

    return (
      <text
        fill={darkGray}
        stroke="none"
        textAnchor="start"
        x={width + 16}
        y={y + 19}
      >
        <tspan>Grade</tspan>
      </text>
    )
  }

  return (
    <ChartResponsiveContainer height={height} width={width}>
      <BarChart
        barCategoryGap="15%"
        data={data}
        height={height}
        margin={{
          top: 0,
          right: 0,
          left: 0,
          bottom: 5,
        }}
        width={width}
      >
        <XAxis
          axisLine={{ stroke: paleGray }}
          dataKey="label"
          label={<GradeLabel />}
          padding={{ left: 30, right: 30 }}
          scale="point"
          stroke={darkGray}
          strokeWidth={2}
          tickLine={false}
        />
        <YAxis
          axisLine={false}
          className="chart-y-axis"
          dataKey="value"
          orientation="right"
          stroke={darkGray}
          tickCount={3}
          tickLine={false}
          tickMargin={10}
          unit={` ${props.dataType}`}
        />
        <ReferenceLine
          ifOverflow="extendDomain"
          stroke={paleGray}
          strokeWidth={2}
          y={yMaxDomainValue}
        />
        <ReferenceLine
          ifOverflow="extendDomain"
          stroke={paleGray}
          strokeWidth={2}
          y={yMaxDomainValue / 2}
        />
        <Bar
          dataKey="value"
          fill={elemDigital1Color}
          label={customBarValueLabel}
          maxBarSize={maxBarWidth}
          radius={5}
        />
      </BarChart>
    </ChartResponsiveContainer>
  )
})

AdminBarChart.defaultProps = {
  dataType: 'classes',
}

AdminBarChart.propTypes = {
  data: PropTypes.array.isRequired,
  dataType: PropTypes.string,
  height: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
  viewBox: PropTypes.object,
  width: PropTypes.oneOf([PropTypes.number, PropTypes.string]),
}

export default withTheme(AdminBarChart)
