import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  ComponentWrapper,
  GrayBackground,
  InstructionsText,
  PartsWrapper,
  PartSubsection,
  PartSubtitle,
  PartTitle,
} from './component.styles'

import { PART_1_TITLE, PART_3_TITLE, PART_2_TITLE } from './constants'

import {
  PART_1_ORDERED_SUBTITLES,
  PART_2_ORDERED_SUBTITLES,
  PART_3_ORDERED_SUBTITLES,
} from './data'

export default class AdvisoryActivityInstructions extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    part1: PropTypes.object.isRequired,
    part2: PropTypes.object.isRequired,
    part3: PropTypes.object.isRequired,
  }

  partSubtitleAndContent = (part = {}, orderedSubtitles = []) => {
    return orderedSubtitles.map(subtitle => {
      if (!part[subtitle]) {
        return null
      }

      const subtitleText = this.formatSubtitle(subtitle)
      const instructionsText = part[subtitle]

      return (
        <PartSubsection key={`${subtitleText}-${instructionsText}`}>
          <PartSubtitle>{subtitleText}</PartSubtitle>
          <InstructionsText>{instructionsText}</InstructionsText>
        </PartSubsection>
      )
    })
  }

  formatSubtitle = (text = '') => {
    if (text === '') {
      return ''
    }
    // TODO: All-caps is a style not a value. Please change to CSS.
    return (
      text
        // insert a space before all caps
        .replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, function(str) {
          return str.toUpperCase()
        })
    )
  }

  render() {
    const { part1, part2, part3, id } = this.props
    const instructionsDataTestId = `advisory-activities-instructions-${id}`

    return (
      <ComponentWrapper>
        <GrayBackground>
          <PartsWrapper data-testid={instructionsDataTestId}>
            <PartTitle partIndex={1}>{PART_1_TITLE}</PartTitle>
            {this.partSubtitleAndContent(part1, PART_1_ORDERED_SUBTITLES)}
            <PartTitle partIndex={2}>{PART_2_TITLE}</PartTitle>
            {this.partSubtitleAndContent(part2, PART_2_ORDERED_SUBTITLES)}
            <PartTitle partIndex={3}>{PART_3_TITLE}</PartTitle>
            {this.partSubtitleAndContent(part3, PART_3_ORDERED_SUBTITLES)}
          </PartsWrapper>
        </GrayBackground>
      </ComponentWrapper>
    )
  }
}
