import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_CONTENTFUL_PRODUCT,
} from 'layers/errorHandling/apiError'
import VideoDataSetterContainer from 'experienceComponents/VideoDataSetter'
import { getCaptionTrackWithSource } from './helpers'
import { usePrevious } from 'utils/componentHelpers'

const VideoDataGetter = props => {
  // default to English because this impacts the caption label for the video:
  const {
    entry,
    refetchLocale = 'en-US',
    getEntryWithNewLocale,
    variant,
    theme,
  } = props || {}

  const { videoReference, id } = entry || {}
  const {
    bitmovinUrl,
    bitmovinUrlExtended,
    posterImage,
    vttFile,
    transcript,
    transcriptExtended,
  } = videoReference || {}

  const [isRefetching, setRefetchStatus] = useState(false)
  const prevRefAzureVideoUrl = usePrevious(bitmovinUrl)
  const { file } = vttFile || {}
  const { url } = file || {}
  const { file: posterFileField } = posterImage || {}
  const { url: posterUrlField } = posterFileField || {}

  let tracks = null

  useEffect(() => {
    if (!isRefetching && refetchLocale !== undefined) {
      setRefetchStatus(true)
      getEntryWithNewLocale(refetchLocale)
    }
  }, [refetchLocale])

  useEffect(() => {
    if (bitmovinUrl !== prevRefAzureVideoUrl) {
      setRefetchStatus(false)
    }
  }, [bitmovinUrl])

  if (url) {
    // TODO using stringify bc pass from here to VideoDataSetterContainer is not able to keep track of an array with an object in it
    tracks = JSON.stringify(getCaptionTrackWithSource(url, refetchLocale))
  }

  if (!entry || isRefetching || !bitmovinUrl) {
    return null
  }

  return (
    <VideoDataSetterContainer
      id={id}
      key={bitmovinUrl}
      poster={posterUrlField}
      refetchLocale={refetchLocale}
      theme={theme}
      tracks={tracks}
      transcriptExtended={transcriptExtended}
      transcriptSource={transcript}
      variant={variant}
      videoUrl={bitmovinUrl}
      videoUrlExtended={bitmovinUrlExtended}
    />
  )
}
const mapper = entry => {
  return { entry }
}
const options = {
  include: 3,
  mapper,
  entryIdProp: true,
  prefetch: ['es-US'],
  spread: true,
}
const mapStateToProps = ({ productManager }) => {
  const theme = productManager?.productMap?.themeName

  return { theme }
}

export default connect(mapStateToProps)(
  ContentEntryDataGetter(
    withApiError(VideoDataGetter, [
      ERROR_TYPE_CONTENTFUL,
      ERROR_TYPE_CONTENTFUL_PRODUCT,
    ]),
    options,
  ),
)
