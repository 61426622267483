/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React from 'react'
import { connect } from 'react-redux'
import ResourceLanding from './component'
import { RouteContentGetter } from 'layers/content/v2/Hocs/RouteContentGetter'
import { navigationEvent } from 'layers/navigation/store/operations'
import withApiError from 'layers/errorHandling/apiError'
import {
  resolveRouteServiceApp,
  getRouteServiceProductPath,
} from 'utils/productHelpers'

const Container = props => {
  const { routeContent } = props || {}
  const { content = {}, loading } = routeContent
  const { programSectionArticleTree } = content

  const readyToLoad = !!content && !loading

  return (
    readyToLoad && (
      <ResourceLanding
        programSectionArticleTree={programSectionArticleTree}
        {...props}
      />
    )
  )
}
const mapDispatchtoProps = {
  navigationEvent,
}

const options = {
  app: () => resolveRouteServiceApp(window?.location?.pathname),
  routeResolver: getRouteServiceProductPath,
}

const ConnectedResourcesContentContainer = connect(
  null,
  mapDispatchtoProps,
)(Container)
export default RouteContentGetter(
  withApiError(ConnectedResourcesContentContainer),
  options,
)
