import styled, { withTheme } from 'styled-components'
import styledNative from 'styled-components/native'
import { BodyCopy, HeaderSix } from 'secondstep-components'
import { themeGet } from 'styled-system'

export const Container = withTheme(styledNative.View`
  background-color: ${themeGet('colors.white')};
  flex-direction: row;
  padding: 1.25rem 1.75rem;
`)

export const Left = withTheme(styledNative.View`
  padding-right: 1.563rem;
`)

export const BookCover = styledNative.Image`
  border-radius: 0.563rem;
  height: 15.313rem;
  width: 10rem;
  border: 1px solid #DDDDDD;  
`

export const Right = styledNative.View`
  flex-direction: column;
  flex: 1;
`

export const Title = styled(HeaderSix)`
  margin-top: -0.313rem;
  margin-bottom: 0.25rem;
  color: ${themeGet('colors.darkerDarkBlue')};
  max-width: 100%;
`

export const Author = styled(BodyCopy)`
  color: ${themeGet('colors.backgroundBody')};
`

export const Description = styled(BodyCopy)`
  margin-bottom: 0.5rem;
  margin-top: 0.563rem;
  color: ${themeGet('colors.darkGray')};
`

export const TagsContainer = styledNative.View`
  flex-direction: row;
  flex-wrap: wrap;
`

export const Tag = styledNative.View`
  cursor: pointer;
  flex-direction: row;
  margin-bottom: 0.438rem;
  padding-right: 1.25rem;
  flex-basis: 33%;
`
