import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { selectors as reportsSelectors } from 'store/reportsManager'
import K8ReportingLeaderTools from './component'

export const K8ReportingLeaderToolsContainer = ({
  entry,
  isSchoolSummary,
  navigationEvent,
  pendingInvitations,
  selectedSite,
}) => (
  <K8ReportingLeaderTools
    entry={entry}
    isSchoolSummary={isSchoolSummary}
    navigationEvent={navigationEvent}
    pendingInvitations={pendingInvitations}
    selectedSite={selectedSite}
  />
)

K8ReportingLeaderToolsContainer.propTypes = {
  entry: PropTypes.object,
  isSchoolSummary: PropTypes.bool,
  navigationEvent: PropTypes.func,
  pendingInvitations: PropTypes.number,
  selectedSite: PropTypes.number,
}

const mapStateToProps = state => {
  const schoolSummaryReport = reportsSelectors.selectSchoolSummaryReport(state)
  const { pendingInvitations } = schoolSummaryReport || {}
  const { selectedSite } = state.reportsManager

  return {
    pendingInvitations,
    selectedSite,
  }
}

export default connect(mapStateToProps)(K8ReportingLeaderToolsContainer)
