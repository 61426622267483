import React from 'react'
import PropTypes from 'prop-types'
import { splitProgramThemes } from './component.core'
import { StyledHeaderFour, StyledBox } from './component.styles'
import LinkList from './list.jsx'

const ProgramThemeList = ({ programThemes }) => {
  const {
    needSecondColumn,
    firstProgramThemesArray,
    secondProgramThemesArray,
  } = splitProgramThemes(programThemes)

  return (
    <>
      <StyledHeaderFour dataTestId="header-program-themes">
        Program Themes
      </StyledHeaderFour>
      <StyledBox>
        <LinkList programThemes={firstProgramThemesArray} />
        {needSecondColumn && (
          <LinkList programThemes={secondProgramThemesArray} />
        )}
      </StyledBox>
    </>
  )
}

ProgramThemeList.propTypes = {
  programThemes: PropTypes.array.isRequired,
}

export default ProgramThemeList
