import { actions } from './reducers'

const adminSetIsWelcomeMessageOpen = isOpen => {
  return async dispatch => {
    dispatch(actions.adminSetIsWelcomeMessageOpen({ isOpen }))
  }
}

const adminSetLessonSelectedRow = data => {
  const { rowId, selectedUnitTab } = data
  return async dispatch => {
    dispatch(actions.adminSetLessonSelectedRow({ rowId, selectedUnitTab }))
  }
}

const adminSetSelectedChecklistTab = (selectedTab, program) => {
  return async dispatch => {
    if (program === 'K8') {
      return dispatch(actions.adminSetMsSelectedChecklistTab({ selectedTab }))
    } else if (program === 'HS') {
      return dispatch(actions.adminSetHsSelectedChecklistTab({ selectedTab }))
    }
  }
}

const adminSetHSToggleView = toggleView => {
  return async dispatch => {
    dispatch(actions.adminSetHSToggleView({ toggleView }))
  }
}

const adminSetK8ToggleView = toggleView => {
  return async dispatch => {
    dispatch(actions.adminSetK8ToggleView({ toggleView }))
  }
}

const adminToggleChecklistItem = (internalKey, isChecked) => {
  return async dispatch => {
    dispatch(actions.adminToggleChecklistItem({ internalKey, isChecked }))
  }
}

const programDashboardSetRecentDashboardTab = tabInternalTitle => {
  return dispatch =>
    dispatch(
      actions.programDashboardSetRecentDashboardTab({
        recentDashboardTab: tabInternalTitle,
      }),
    )
}

const resetState = () => {
  return async dispatch => {
    dispatch(actions.resetState())
  }
}

const setLeaderDashboardRecentUrl = url => {
  return dispatch =>
    dispatch(
      actions.adminDashboardSetRecentUrl({
        recentLeaderDashboardUrl: url,
      }),
    )
}

const setAdminActivePathwayTabBySite = (tabNumber, siteId) => {
  return dispatch => {
    dispatch(actions.setAdminActivePathwayTabBySite({ tabNumber, siteId }))
  }
}

export default {
  adminSetIsWelcomeMessageOpen,
  adminSetLessonSelectedRow,
  adminSetSelectedChecklistTab,
  adminSetHSToggleView,
  adminSetK8ToggleView,
  adminToggleChecklistItem,
  programDashboardSetRecentDashboardTab,
  resetState,
  setAdminActivePathwayTabBySite,
  setLeaderDashboardRecentUrl,
}
