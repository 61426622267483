/* istanbul ignore file */
// TODO: ^ for the sake of time, temporarily ignore coverage on this file

import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { navigationEvent } from 'layers/navigation/store/operations'
import ContentDataGetter from 'layers/content/v2/Hocs/ContentDataGetter'
import { programs } from 'layers/content/v2/hooks/useCourseManager'
import {
  CourseManagerGetter,
  CourseManagerType,
} from 'layers/content/v2/Hocs/CourseManagerGetter'
import { selectors as licenseSelectors } from 'store/licenseManager'
import { selectors as lmsSelectors } from 'store/lmsManager'
import {
  getLatestDashboardTab,
  setLatestDashboardTab,
} from 'utils/highschoolHelpers'
import Component from './component'
import { ENTRY_ID, QUERY_TYPE } from './constants'
import {
  parseProgramTrainings,
  parsePathways,
  parseStaticLinks,
  parseTileImages,
  addInstanceToRoute,
  filterPathwayWithCourseTree,
  mergePacingDataIntoPathways,
  getMatchedPacingNodes,
  getTrainingNode,
  updateCollectionsWithTitle,
  getCollectionWithinDateRange,
  setActiveCollectionIndex,
  mergeInstructorDataIntoPathways,
  mergeInstructorDataIntoProgramTrainings,
  allProgramTrainingsComplete,
  allCollectionsComplete,
} from './helpers'

function Container({
  content,
  courseManager,
  navigationEvent,
  activeSites,
  highSchoolInstructorData,
  hsActiveSiteId,
}) {
  const { data, loading: loadingContent } = content || {}
  const { courseTrees, loading: loadingCourseTree } = courseManager || {}

  // TODO: handle logic to determine active index based on user's progress/pacing
  const [activeIndex, setActiveIndex] = useState(0)
  const [currentPathwayIndex, setCurrentPathwayIndex] = useState(0)
  const [dataParsed, setDataParsed] = useState(false)
  const [programTrainings, setProgramTrainings] = useState([])
  const [pathways, setPathways] = useState([])
  const [staticLinks, setStaticLinks] = useState([])
  const [tileImages, setTileImages] = useState([])
  const [tabStatuses, setTabStatuses] = useState([])
  const [trainingNode, setTrainingNode] = useState(null)
  // the semantic value of this bool doesn't matter...
  // the child component will listen for any value change to reset its accordions
  const [resetAccordionSignal, setResetAccordionSignal] = useState(false)
  const [
    shouldShowTrainingOverdueNotification,
    setShouldShowTrainingOverdueNotification,
  ] = useState(false)

  const activeSite = activeSites.find(site => site.id === hsActiveSiteId)

  const activeSitePacing =
    activeSite?.pacing?.highSchool || activeSite?.pacing.highschool

  function handlePathwayNavigation(index) {
    if (index !== activeIndex) {
      setResetAccordionSignal(!resetAccordionSignal)
      setActiveIndex(index)
      setLatestDashboardTab(index)
    }
  }

  function getPathwayProps(index) {
    return {
      staticLinks,
      tabStatuses,
      /*
       this array contains the following props for each pathway:
       - pathwayTitle: string
       - pathwayDescription: string
       - collections: list of collections
       - pathwayTraining: pathway training title and link
      */
      ...pathways[index],
      // tileImage[0] is training
      tileImage: tileImages[index + 1],
    }
  }

  function getComponentProps(index) {
    // if TRAINING:
    if (index === 0) {
      return {
        programTrainings,
        staticLinks,
        tabStatuses,
        tileImage: tileImages[0],
        trainingNode,
      }
    }
    // subtract 1 to account for training
    return getPathwayProps(index - 1)
  }

  useEffect(() => {
    if (
      loadingContent ||
      !data ||
      loadingCourseTree ||
      !courseTrees?.highschool ||
      !activeSite ||
      !highSchoolInstructorData ||
      // sometimes the highSchoolInstructorData selector erroneously returns an empty array, guard against that
      (Array.isArray(highSchoolInstructorData) &&
        highSchoolInstructorData.length === 0)
    ) {
      // TODO: display a loading screen
      return
    }

    const { dashboardProgram = null } = data || {}
    if (dashboardProgram) {
      const programTrainings = parseProgramTrainings(dashboardProgram)
      const programTrainingsWithCompleteStatus = mergeInstructorDataIntoProgramTrainings(
        programTrainings,
        highSchoolInstructorData,
      )
      setProgramTrainings(programTrainingsWithCompleteStatus)
      const allPathwayData = parsePathways(dashboardProgram)
      const pathwayDataInCourseTree = allPathwayData.map(pathway =>
        filterPathwayWithCourseTree(pathway, courseTrees?.highschool),
      )
      const pathwayDataWithPacing = mergePacingDataIntoPathways(
        pathwayDataInCourseTree,
        activeSitePacing,
      )
      const pathwayDataWithCompleteStatus = mergeInstructorDataIntoPathways(
        pathwayDataWithPacing,
        highSchoolInstructorData,
      )
      setPathways(pathwayDataWithCompleteStatus)
      setStaticLinks(parseStaticLinks(dashboardProgram))
      setTileImages(parseTileImages(dashboardProgram))
      const tabStatuses = []
      const areAllProgramTrainingsComplete = allProgramTrainingsComplete(
        programTrainingsWithCompleteStatus,
      )
      tabStatuses.push({
        isComplete: areAllProgramTrainingsComplete,
        isInCurrentPacing: false,
      })
      for (const pathway of pathwayDataWithCompleteStatus) {
        tabStatuses.push({
          isComplete: allCollectionsComplete(pathway),
          isInCurrentPacing: false,
        })
      }
      const matchedNodes = getMatchedPacingNodes(
        activeSitePacing,
        pathwayDataWithCompleteStatus,
      )
      const trainingNode = getTrainingNode(matchedNodes)
      setTrainingNode(trainingNode)
      const aggregatedCollections = updateCollectionsWithTitle(
        pathwayDataWithCompleteStatus,
        trainingNode,
      )
      const closestCollection = getCollectionWithinDateRange(
        aggregatedCollections,
      )
      const activeIndex = setActiveCollectionIndex(
        closestCollection,
        setActiveIndex,
      )
      // this state variable is basically a non-mutated version of activeIndex offset by one...
      // because activeIndex will change as the user clicks different tabs and...
      // minus one because activeIndex 0 is training:
      setCurrentPathwayIndex(activeIndex - 1)
      const latestHighSchoolTab = getLatestDashboardTab()
      // may return null and isNan(null) === false for some weird reason
      if (latestHighSchoolTab && !isNaN(latestHighSchoolTab)) {
        setActiveIndex(latestHighSchoolTab)
      }
      if (activeIndex !== -1 && !!tabStatuses[activeIndex]) {
        tabStatuses[activeIndex].isInCurrentPacing = true
      }
      // IF we're past Pathway 4, activeIndex will be -1 so this notification will not show
      if (activeIndex > 0 && !areAllProgramTrainingsComplete) {
        setShouldShowTrainingOverdueNotification(true)
      }
      setTabStatuses(tabStatuses)
      setDataParsed(true)
    } else {
      console.warn('No dashboardProgram data returned by content-service')
    }
  }, [data, courseTrees, activeSite, highSchoolInstructorData])

  if (!dataParsed) {
    return null
  }

  return (
    <Component
      {...getComponentProps(activeIndex)}
      currentIndex={activeIndex}
      currentPathwayIndex={currentPathwayIndex}
      handlePathwayNavigation={handlePathwayNavigation}
      navigationEvent={navigationEvent}
      resetAccordionSignal={resetAccordionSignal}
      // hide the notification if the user is on tab 0 (TRAINING tab), this spec may change later...
      shouldShowTrainingOverdueNotification={
        activeIndex > 0 && shouldShowTrainingOverdueNotification
      }
    />
  )
}
// reference: https://stackoverflow.com/a/43533028 in order to reference state inside of mapDispatchToProps
const getState = dispatch =>
  new Promise(resolve => {
    dispatch((_, getState) => {
      resolve(getState())
    })
  })

const mapStateToProps = state => {
  const activeSites = licenseSelectors.selectActiveSites(state)
  const highSchoolInstructorData = lmsSelectors.selectHsInstructorData(state)
  const hsActiveSiteId = state?.userContextManager?.hsActiveSiteId

  return {
    activeSites,
    highSchoolInstructorData,
    hsActiveSiteId,
  }
}

const mapDispatchToProps = dispatch => ({
  navigationEvent: async event => {
    const state = await getState(dispatch)
    dispatch(
      navigationEvent(
        addInstanceToRoute(event, state?.userContextManager?.hsActiveSiteId),
      ),
    )
  },
})

Container.propTypes = {
  activeSites: PropTypes.array,
  content: PropTypes.object,
  courseManager: CourseManagerType,
  highSchoolInstructorData: PropTypes.object,
  hsActiveSiteId: PropTypes.number,
  navigationEvent: PropTypes.func,
}
// TODO: ensure page is in the learnMyPrograms route tree and use RouteContentGetter HOC instead
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  CourseManagerGetter(
    ContentDataGetter(Container, {
      entryId: ENTRY_ID,
      queryType: QUERY_TYPE,
    }),
    {
      // this component is only used for High School:
      programResolver: () => programs.highSchool,
    },
  ),
)
