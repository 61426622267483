import { PropTypes } from 'prop-types'

export const scopeAndSequenceLinksType = PropTypes.arrayOf(
  PropTypes.shape({
    linkHref: PropTypes.string,
    linkLabel: PropTypes.string,
  }),
)

export const lessonsAmountsType = PropTypes.arrayOf(
  PropTypes.shape({
    totalAmount: PropTypes.number,
    totalComplete: PropTypes.number,
    categoryLabel: PropTypes.string,
  }),
)
