import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import { domains } from 'config/env'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
  ERROR_TYPE_CONTENTFUL_PRODUCT,
  ERROR_TYPE_LMS,
} from 'layers/errorHandling/apiError'
import ModuleOverviewInset from './component'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { calculateInsetData } from './data.js'

const ModuleOverviewInsetContainer = props => {
  const {
    contentfulNextMicrolearningItem1Lesson,
    contentfulNextMicrolearningItem2Lesson,
    isModuleDone,
    isModuleStarted,
    lmsNextMicrolearning,
    navigationEvent,
  } = props

  const {
    leaderChoice,
    leaderButtonText,
    staffChoice,
    staffButtonText,
    time,
    title,
  } = calculateInsetData(props)

  const leaderButtonOnClick = () => {
    const type = NavigationHelper.types.OPEN
    navigationEvent(
      `${domains.LMS_API_BASE}/launch/${leaderChoice.sco}/${leaderChoice.instance}`,
      type,
    )
  }

  const staffButtonOnClick = () => {
    const type = NavigationHelper.types.OPEN
    navigationEvent(
      `${domains.LMS_API_BASE}/launch/${staffChoice.sco}/${staffChoice.instance}`,
      type,
    )
  }

  if (
    !isModuleDone &&
    !contentfulNextMicrolearningItem1Lesson &&
    !contentfulNextMicrolearningItem2Lesson &&
    !lmsNextMicrolearning
  ) {
    return null
  }

  return (
    <ModuleOverviewInset
      isModuleDone={isModuleDone}
      isModuleStarted={isModuleStarted}
      leaderButtonOnClick={leaderButtonOnClick}
      leaderButtonText={leaderButtonText}
      staffButtonOnClick={staffButtonOnClick}
      staffButtonText={staffButtonText}
      time={time}
      title={title}
    />
  )
}
ModuleOverviewInsetContainer.propTypes = {
  contentfulNextMicrolearningItem1Lesson: PropTypes.object,
  contentfulNextMicrolearningItem2Lesson: PropTypes.object,
  isModuleDone: PropTypes.bool,
  isModuleStarted: PropTypes.bool,
  lmsNextMicrolearning: PropTypes.object,
  navigationEvent: PropTypes.func,
}

export const mapper = (entry = {}) => {
  const {
    displayTitle,
    time,
    microlearningType,
    item1Lesson,
    item2Lesson,
  } = entry

  return {
    contentfulNextMicrolearningItem1Lesson: item1Lesson,
    contentfulNextMicrolearningItem2Lesson: item2Lesson,
    contentfulNextMicrolearningTime: time,
    contentfulNextMicrolearningTitle: displayTitle,
    contentfulNextMicrolearningType: microlearningType,
  }
}

const options = {
  include: 1,
  mapper,
  spread: true,
  entryIdProp: true,
}

export default ContentEntryDataGetter(
  withApiError(ModuleOverviewInsetContainer, [
    ERROR_TYPE_CONTENTFUL,
    ERROR_TYPE_CONTENTFUL_PRODUCT,
    ERROR_TYPE_LMS,
  ]),
  options,
  connect(null, NavigationHelper.mapDispatchToProps),
)
