export function getStaticRoutes(path) {
  return [
    `${path}/implementation-guide/grades-k-8`,
    `${path}/implementation-guide/discover`,
    `${path}/implementation-guide/launch`,
    `${path}/implementation-guide/plan`,
    `${path}/implementation-guide/support`,
    `${path}/hs-implementation-guide`,
  ]
}
