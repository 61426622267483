import React from 'react'
import PropTypes from 'prop-types'
import {
  LabelBox,
  TopRow,
  Wrapper,
  BottomRow,
  BodyRow,
  BottomText,
  Title,
  Description,
} from './component.styles'
import statusBoxMap from './data'
import ProgressBar from '../ProgressBar/'
const ModuleCard = ({
  attempted,
  completeLessons,
  description,
  displayTitle,
  entryId,
  index,
  label,
  onClick,
  totalLessons,
  isOnlyLeader,
  internalTitle,
  productName,
}) => {
  const percentComplete = {
    completed: 100,
    incomplete: Math.floor((completeLessons / totalLessons) * 100),
    'not attempted': 0,
  }[attempted]
  const attemptedValue =
    attempted === 'incomplete' && percentComplete === 0
      ? 'not attempted'
      : attempted
  const { StatusBox, StatusText, StatusIcon, statusValue } = statusBoxMap[
    isOnlyLeader ? 'leader' : 'default'
  ][attemptedValue]
  const productRoute = internalTitle => {
    const map = {
      'sela program': 'sel-for-adults',
      'sela field test program': 'sel-for-adults-field-test',
    }
    return map[internalTitle]
  }
  return (
    <Wrapper
      dataTestId={`module-${index}-card`}
      isOnlyLeader={isOnlyLeader}
      onClick={() =>
        onClick(
          `/product/${productRoute(internalTitle) ||
            productName}/modules/${entryId}`,
        )
      }
    >
      <TopRow>
        <LabelBox dataTestId={`module-${index}-label-box`}>{label}</LabelBox>
        {StatusBox && (
          <StatusBox dataTestId={`module-${index}-status-box`}>
            {StatusIcon && (
              <StatusIcon dataTestId={`module-${index}-status-box-icon`} />
            )}
            <StatusText dataTestId={`module-${index}-status-box-text`}>
              {statusValue}
            </StatusText>
          </StatusBox>
        )}
      </TopRow>
      <BodyRow>
        <Title dataTestId={`module-${index}-title`}>{displayTitle}</Title>
        <Description data-testid={`module-${index}-description`}>
          {description}
        </Description>
        <ProgressBar
          className="progress-bar"
          dataTestId={`module-${index}-progressbar`}
          isOnlyLeader={isOnlyLeader}
          percentComplete={percentComplete}
        />
      </BodyRow>
      <BottomRow>
        <BottomText dataTestId={`module-${index}-bottomrow-text`}>
          Open Module
        </BottomText>
      </BottomRow>
    </Wrapper>
  )
}
ModuleCard.propTypes = {
  attempted: PropTypes.string,
  completeLessons: PropTypes.number,
  description: PropTypes.string,
  displayTitle: PropTypes.string,
  entryId: PropTypes.string,
  index: PropTypes.number,
  internalTitle: PropTypes.string,
  isOnlyLeader: PropTypes.bool,
  label: PropTypes.string,
  onClick: PropTypes.func,
  productName: PropTypes.string,
  totalLessons: PropTypes.number,
}
ModuleCard.defaultProps = {
  attempted: 'not attempted',
  completeLessons: 0,
  totalLessons: 0,
  isOnlyLeader: false,
}
export default ModuleCard
