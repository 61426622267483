import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { HeaderThree } from 'secondstep-components'

export const Wrapper = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  ${HeaderThree} {
    color: ${themeGet('colors.darkerDarkBlue')};
    font-family: ${themeGet('fontFamilyHeadingBold')};
    margin-bottom: 1.125rem;
  }
`

export const ResourceFlex = styled(Box)`
  flex-direction: row;

  div {
    padding-right: 1.25rem;

    &:last-of-type {
      padding-right: 0;
    }

    ul {
      padding-left: 1.1875rem;

      li {
        margin-bottom: 4px;
      }
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;

    div {
      padding-right: 0;
      width: 100%;
    }

    ul {
      margin-bottom: 0.4375rem;
    }
  }
`
