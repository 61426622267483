import React from 'react'
import { StyledDropdown, StyledDropdownOption } from './component.styles'
import { Select } from 'grommet'
import PropTypes from 'prop-types'
import { CheckIcon } from 'secondstep-components'

const PathwayDropdown = React.forwardRef(
  ({ value, options, handleOnChange, ...rest }, ref) => {
    const dropDownProps = {
      round: {
        size: '5px',
      },
      align: { top: 'bottom', left: 'left' },
      margin: { vertical: '6px' },
      target: ref.current,
    }

    return (
      <StyledDropdown {...rest}>
        <Select
          dropProps={dropDownProps}
          onChange={handleOnChange}
          options={options}
          ref={ref}
          value={value}
        >
          {(option, _index, _options, optionStatus) => {
            return (
              <StyledDropdownOption key={_index}>
                {option} {optionStatus.selected && <CheckIcon />}
              </StyledDropdownOption>
            )
          }}
        </Select>
      </StyledDropdown>
    )
  },
)

PathwayDropdown.propTypes = {
  handleOnChange: PropTypes.func,
  options: PropTypes.array,
  value: PropTypes.any,
}

export default PathwayDropdown
