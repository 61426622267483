import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'redux'

import NavigationHelper from 'layers/navigation/navigationHelper'
import SubNavGetter, {
  SubNavGetterComponentPropTypes,
} from 'layers/content/v2/Hocs/SubNavGetter'
import { getThemeFromUrl, getProductFromUrl } from 'utils/productHelpers'
import { RouteContentProductAppMap } from 'utils/productMaps'

import Component from './component'

function NavigationContainer(props) {
  const theme = getThemeFromUrl()
  const productConfig = getProductFromUrl()
  const product = {
    product: productConfig?.name,
  }
  return <Component product={product} theme={theme} {...props} />
}

const navigationConnect = connect(
  NavigationHelper.mapStateToProps,
  NavigationHelper.mapDispatchToProps,
)

const userConnect = connect(state => {
  const { userAccessManager } = state || {}
  const { claims } = userAccessManager || {}
  const { userProfileManager } = state || {}
  const { profile } = userProfileManager || {}
  const { firstName = '', lastName = '', roles = [] } = profile || {}
  return {
    roles,
    userClaims: claims,
    name: `${firstName} ${lastName}`.trim(),
  }
})

NavigationContainer.propTypes = {
  subNavData: SubNavGetterComponentPropTypes,
}

function resolveSubNavApp() {
  const { pathname } = window.location
  if (pathname.includes('/product/high-school')) {
    return RouteContentProductAppMap.learnHsProduct
  }
  if (pathname.includes('/product/middle-school')) {
    return RouteContentProductAppMap.learnMsProduct
  }
  if (pathname.startsWith('/family-resources')) {
    return RouteContentProductAppMap.learnFamilyResourcesK8
  }
  if (pathname.startsWith('/family')) {
    return RouteContentProductAppMap.learnFamilyResourcesHS
  }

  throw new Error(`Path ${pathname} does not have a supported subnav`)
}

const subNavGetterOptions = {
  app: resolveSubNavApp,
}

export default compose(
  navigationConnect,
  userConnect,
)(SubNavGetter(NavigationContainer, subNavGetterOptions))
