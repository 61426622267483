import React from 'react'
import PropTypes from 'prop-types'
import { HeaderFour } from 'secondstep-components'
import { Link, ListItem, Wrapper } from './component.styles'
import NavigationHelper from 'layers/navigation/navigationHelper'

const ResourceLinkList = ({ header, links, id, navigationEvent }) => {
  const onClick = href => {
    const type = NavigationHelper.types.DOWNLOAD_LINK
    navigationEvent(href, type)
  }
  return (
    <Wrapper>
      <HeaderFour dataTestId={`header-resource-link-list`}>{header}</HeaderFour>
      <ul>
        {links.map(({ displayTitle, file }) => (
          <ListItem key={`resource-links-${file.id}`}>
            <Link
              dataTestId={`resource-links-${id}`}
              onClick={() => onClick(file.file.url)}
            >
              {displayTitle}
            </Link>
          </ListItem>
        ))}
      </ul>
    </Wrapper>
  )
}

ResourceLinkList.propTypes = {
  header: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.object).isRequired,
  navigationEvent: PropTypes.func,
}

export default ResourceLinkList
