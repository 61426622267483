import variables from './variables'
import resourcesBannerImgDesktop from 'experienceComponents/SubLandingBanner/assets/SELA_Resources_Sublanding_Banner_1366.png'
import resourcesBannerImgTablet from 'experienceComponents/SubLandingBanner/assets/SELA_Resources_Sublanding_Banner_768.png'
import resourcesBannerImgMobile from 'experienceComponents/SubLandingBanner/assets/SELA_Resources_Sublanding_Banner_375_mobile.png'

export default {
  meta: { themeName: 'sela' },

  globalNav: {
    background: variables.colors.sela.gray1,
    iconHoverBackground: variables.colors.opacity_40,
  },

  skipToMain: {
    background: variables.colors.opacity_40,
  },

  colors: {
    borderColor: variables.colors.sela.teal4,
    themePrimary: variables.colors.sela.gray1,
    themeAccent: variables.colors.sela.gray2,
    primaryCopy: variables.colors.sela.gray1,
    heading: {
      level: {
        1: variables.colors.sela.gray1,
        2: variables.colors.sela.gray1,
        3: variables.colors.sela.gray1,
        4: variables.colors.sela.gray1,
        5: variables.colors.sela.gray1,
        6: variables.colors.sela.gray1,
      },
      primary: variables.colors.sela.gray1,
      secondary: variables.colors.sela.gray6,
    },
    icons: {
      primary: variables.colors.sela.teal1,
      secondary: variables.colors.sela.purple1,
      tertiary: variables.colors.sela.pink1,
      accent: variables.colors.sela.gray2,
    },
    backgrounds: {
      banners: {
        primary: variables.colors.teal3,
        secondary: variables.colors.sela.purple2,
      },
      primary: variables.colors.sela.teal3,
      secondary: variables.colors.sela.purple2,
      tertiary: variables.colors.sela.gray2,
    },
    modules: {
      primaryBackground: variables.colors.sela.gray3,
      primaryBackgroundHover: variables.colors.sela.teal3,
      leaderPrimaryBackgroundHover: variables.colors.sela.purple2,
      secondaryBackground: variables.colors.sela.gray4,
      secondaryBackgroundHover: variables.colors.sela.teal1,
      leaderSecondaryBackgroundHover: variables.colors.sela.purple1,
      tertiaryBackground: variables.colors.sela.teal4,
      leaderTertiaryBackground: variables.colors.sela.purple3,
      primaryStatusCopy: variables.colors.sela.gray5,
      secondaryCopy: variables.colors.white,
    },
    units: {
      primaryBackground: variables.colors.white,
      primaryBackgroundHover: variables.colors.sela.teal3,
      leaderPrimaryBackgroundHover: variables.colors.sela.purple2,
    },
    buttons: {
      primaryBackground: variables.colors.sela.teal5,
      primaryBackgroundHover: variables.colors.sela.gray1,
      primaryBackgroundDisabled: variables.colors.buttonDisabledKit,
      secondaryBackground: variables.colors.white,
      secondaryBackgroundHover: variables.colors.sela.teal3,
      secondaryAltBackgroundHover: variables.colors.sela.purple2,
      secondaryText: variables.colors.darkGray,
      secondaryOutline: variables.colors.gray600,
      tertiaryBackground: variables.colors.sela.teal3,
      tertiaryBackgroundHover: variables.colors.sela.teal4,
      tertiaryAltBackgroundHover: variables.colors.sela.purple3,
    },
    links: {
      primary: variables.colors.sela.teal5,
      primaryHover: variables.colors.sela.gray1,
      secondary: variables.colors.sela.purple1,
      secondaryHover: variables.colors.sela.gray1,
    },
    programCard: {
      background: {
        color: variables.colors.themeAccent,
      },
    },
    progressBar: {
      primary: variables.colors.sela.teal1,
      background: variables.colors.sela.gray2,
      leaderPrimary: variables.colors.sela.purple1,
    },
  },
  fonts: {
    header: variables.font.lufgaCFC.bold,
    label: variables.font.lufgaCFC.regular,
    bodyCopy: variables.font.molde.regular,
    iconText: variables.font.lufgaCFC.medium,
    statusText: variables.font.lufgaCFC.medium,
    buttonText: variables.font.lufgaCFC.semiBold,
    link: variables.font.molde.medium,
    resourceHeader: variables.font.lufgaCFC.medium,
  },
  heading: {
    level: {
      1: {
        font: {
          family: variables.font.lufgaCFC.bold,
          weight: variables.fontWeightNormal,
        },
        medium: {
          height: '3rem',
          size: '2.625rem',
        },
        tablet: {
          height: '3rem',
          size: '2.625rem',
        },
        mobile: {
          height: '2.75rem',
          size: '2.375rem',
        },
      },
      2: {
        font: {
          family: variables.font.lufgaCFC.bold,
          weight: variables.fontWeightNormal,
        },
        medium: {
          height: '2.25rem',
          size: '1.75rem',
        },
        tablet: {
          height: '2.25rem',
          size: '1.75rem',
        },
        mobile: {
          height: '1.875rem',
          size: '1.625rem',
        },
      },
      3: {
        font: {
          family: variables.font.lufgaCFC.bold,
          weight: variables.fontWeightNormal,
        },
        medium: {
          height: '3rem',
          size: '1.75rem',
        },
        tablet: {
          height: '2.125rem',
          size: '1.75rem',
        },
        mobile: {
          height: '2rem',
          size: '1.625rem',
        },
      },
      4: {
        font: {
          family: variables.font.lufgaCFC.medium,
          weight: variables.fontWeightNormal,
        },
        medium: {
          height: '1.3125rem',
          size: '1rem',
        },
        tablet: {
          height: '1.3125rem',
          size: '1rem',
        },
        mobile: {
          height: '1.3125rem',
          size: '1rem',
        },
      },
      5: {
        font: {
          family: variables.font.lufgaCFC.medium,
          weight: variables.fontWeightNormal,
        },
        medium: {
          height: '1.3125rem',
          size: '1rem',
        },
        tablet: {
          height: '1.3125rem',
          size: '1rem',
        },
        mobile: {
          height: '1.3125rem',
          size: '1rem',
        },
      },
      6: {
        font: {
          family: variables.font.lufgaCFC.medium,
          weight: variables.fontWeightNormal,
        },
        medium: {
          height: '1.3125rem',
          size: '1rem',
        },
        tablet: {
          height: '1.3125rem',
          size: '1rem',
        },
        mobile: {
          height: '1.3125rem',
          size: '1rem',
        },
      },
    },
  },
  bodyCopy: {
    medium: {
      height: '1.625rem',
      size: '1.125rem',
    },
    tablet: {
      height: '1.625rem',
      size: '1.125rem',
    },
    mobile: {
      height: '1.5rem',
      size: '1rem',
    },
  },
  // TODO: LEARN-9633, move backround images to separate object
  banners: {
    sublandingBackgroundImage: resourcesBannerImgDesktop,
    tabletSublandingBackgroundImg: resourcesBannerImgTablet,
    mobileSublandingBackgroundImg: resourcesBannerImgMobile,
    alignment: 'center',
    textAlignment: 'center',
  },
  breakpoints: variables.breakpoints,
  callouts: {
    backgroundColor: variables.colors.white,
    boxShadow: `0rem 0rem 0.625rem ${variables.colors.gray300}`,
  },
  anchorNavigation: {
    borderBottomStyle: 'none',
    borderBottomColor: 'none',
    borderBottomWidth: 'none',
    boxShadow: `0px 0px 10px ${variables.colors.gray300}`,
  },
}
