import styled from 'styled-components'
import {
  AutoTable,
  BaseContainer,
  BodyCopy,
  Tooltip,
  TooltipContentWrapper,
} from 'secondstep-components'
import { themeGet } from 'styled-system'

export const ReportsTable = styled(AutoTable)`
  color: ${themeGet('colors.darkGray')};
  border: 2px solid ${themeGet('colors.beaconGray')};
  background-color: white;
  margin-top: 0;

  [class*='AutoTableHeader'],
  [class*='Cell'] {
    line-height: 1;
  }

  [class*='AutoTableHeaderTh'] {
    padding: 0 1.125rem;
    ${themeGet('breakpoints.mobile')} {
      padding-left: ${themeGet('spacing.blue16_1rem')};
      padding-right: ${themeGet('spacing.blue16_1rem')};
    }
    background-color: ${themeGet('colors.beaconGray')};
  }

  [class*='AutoTableHeaderTh']:last-child,
  [class*='Cell']:last-child {
    padding-right: 40px;
  }
  [class*='AutoTableHeaderSpan'] {
    margin-right: ${themeGet('spacing.pink8_0_5rem')};
    padding: 0;
  }
`

export const FlexContainer = styled(BaseContainer)`
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: inherit;
  min-width: 0;
`
export const TextHiddenOverflow = styled.div`
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
export const TextContainer = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`
export const TableHeaderTooltip = styled(Tooltip)`
  white-space: break-spaces;

  margin-left: ${themeGet('spacing.pink8_0_5rem')};
  i {
    color: ${themeGet('colors.blueGray')};
    font-size: 0.875rem;
  }
  // override default tooltip breakpoint styling
  ${themeGet('breakpoints.tablet')} {
    margin-left: 0.25rem;
    width: ${themeGet('spacing.purple24_1_5rem')};
  }

  ${TooltipContentWrapper} {
    left: 1rem;
    top: 1.75rem;
    padding: 1rem;
    border-radius: 6px;
    color ${themeGet('colors.darkGray')};

    ::after {
      content: ' ';
      width: 0.75rem;
      height: 0.75rem;
      position: absolute;
      bottom: 93%;
      background-color: ${themeGet('colors.white')};
      transform: translate(-50%) rotate(45deg);
    }

    ${BodyCopy} {
      text-align: left;
      p {
        line-height: 1rem;
        font-size: 0.9125rem;
      }
    }
  }
`
