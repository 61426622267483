import React, { Component } from 'react'
import PropTypes from 'prop-types'

import removeRedactedContentfulArray from 'utils/removeRedactedContentfulArray'
import NavigationHelper from 'layers/navigation/navigationHelper'

import LandingBanner from 'experienceComponents/LandingBanner'
import FeatureCategoryCallout from 'experienceComponents/FeatureCategoryCallout'
import CategoryCalloutDisplay from 'experienceComponents/CategoryCalloutDisplay'

import {
  BackgroundWrapper,
  ContentWrapper,
  ResourcesLandingWrapper,
} from './component.styles'

import iconMap from 'utils/iconMap'

const CATEGORIES_TEXT = 'All Categories'

export class ResourcesLanding extends Component {
  static propTypes = {
    entry: PropTypes.object, // TODO: add shape for this later
    isDistrict: PropTypes.bool,
    location: PropTypes.object.isRequired,
    navigationEvent: PropTypes.func.isRequired,
    // this shape needs to live in the entry
    // programSubsections: PropTypes.arrayOf(
    //   PropTypes.shape({
    //     description: PropTypes.string.isRequired,
    //     id: PropTypes.string.isRequired,
    //     displayTitle: PropTypes.string.isRequired,
    //     pageData: PropTypes.object.isRequired,
    //     image: PropTypes.object.isRequired,
    //   }),
    // ),
  }

  categoriesMapper = categoryCalloutData => {
    const {
      description,
      displayTitle,
      iconSelector = 'rocket',
      pageData = {},
    } = categoryCalloutData
    const { route } = pageData

    return {
      Icon: iconMap[iconSelector],
      goToLocation: this.props.navigationEvent,
      location: this.props.location,
      text: description,
      title: displayTitle,
      link: route,
    }
  }

  render() {
    const { entry, navigationEvent, location, isDistrict } = this.props || {}
    const { redacted } = entry || {}
    if (redacted) {
      return null
    } // need to handle this error better

    const { programSubsections, description, displayTitle } = entry || {}
    const filteredProgramSubsections = removeRedactedContentfulArray(
      programSubsections,
    )
    const [featureCategoryCalloutData = {}, ...categoryCalloutsData] =
      filteredProgramSubsections || []
    const { image: featuredImage, pageData } = featureCategoryCalloutData || {}

    const { image } = featuredImage || {}
    const { file } = image || {}
    const { url } = file || {}

    const { route } = pageData || {}

    this.navigateToDashboardOnClick = () => {
      const { navigationEvent } = this.props
      const url = `/`
      const type = NavigationHelper.types.PUSH
      navigationEvent(url, type)
    }

    return (
      <ResourcesLandingWrapper>
        <LandingBanner
          isDistrict={isDistrict}
          navigateToDashboardOnClick={this.navigateToDashboardOnClick}
          text={description}
          title={displayTitle}
        />
        <BackgroundWrapper>
          <ContentWrapper>
            <FeatureCategoryCallout
              body={featureCategoryCalloutData.description}
              id={featureCategoryCalloutData.id}
              image={url}
              link={route}
              location={location}
              navigationEvent={navigationEvent}
              title={featureCategoryCalloutData.displayTitle}
            />
            {categoryCalloutsData.length >= 1 && (
              <CategoryCalloutDisplay
                categoryInfo={categoryCalloutsData.map(this.categoriesMapper)}
                dataTestId="category-callout"
                displayHeaderText={CATEGORIES_TEXT}
              />
            )}
          </ContentWrapper>
        </BackgroundWrapper>
      </ResourcesLandingWrapper>
    )
  }
}

export default ResourcesLanding
