import React, { useState } from 'react'

import PropTypes from 'prop-types'
import { AudioDescriptionButton, TranscriptButton } from 'secondstep-components'

import { ToggleWrapper, Tooltip } from './component.styles'
import {
  audioToolTipText,
  audioToolTipTextNotActive,
  tooltipTextActive,
  tooltipTextNotActive,
} from './constants'

const AccessibilityMenu = ({
  handleClickAudioDesc,
  hasLoadedTranscripts,
  isAudioDescActive,
  showAudioDescriptionButton,
  showTranscript,
  isTranscriptActive,
  transcriptRendered,
  variant = 'learn',
}) => {
  const [isAudioHovering, setIsAudioHovering] = useState(false)
  const [isHovering, setIsHovering] = useState(false)
  const tooltipText = transcriptRendered
    ? tooltipTextActive
    : tooltipTextNotActive
  const AudioDescTooltipText = isAudioDescActive
    ? audioToolTipTextNotActive
    : audioToolTipText
  return (
    <ToggleWrapper transcriptRendered={transcriptRendered}>
      {isAudioHovering && <Tooltip right={5.2}>{AudioDescTooltipText}</Tooltip>}
      {!isHovering && showAudioDescriptionButton && (
        <AudioDescriptionButton
          ariaLabel="Audio Description button"
          hasActive={isAudioDescActive}
          hasHover={isAudioHovering}
          hasResposive={false}
          onClick={handleClickAudioDesc}
          onMouseEnter={() => setIsAudioHovering(true)}
          onMouseLeave={() => setIsAudioHovering(false)}
          variant={variant}
        />
      )}
      {isHovering && <Tooltip right={2.6}>{tooltipText}</Tooltip>}
      {hasLoadedTranscripts && (
        <TranscriptButton
          ariaLabel="Transcription button"
          hasActive={isTranscriptActive}
          hasHover={isHovering}
          hasResposive={false}
          id="transcript-button"
          onClick={showTranscript}
          onMouseEnter={() => setIsHovering(true)}
          onMouseLeave={() => setIsHovering(false)}
          variant={variant}
        />
      )}
    </ToggleWrapper>
  )
}

AccessibilityMenu.propTypes = {
  handleClickAudioDesc: PropTypes.func,
  hasLoadedTranscripts: PropTypes.bool,
  isAudioDescActive: PropTypes.bool,
  isTranscriptActive: PropTypes.bool,
  showAudioDescriptionButton: PropTypes.bool,
  showTranscript: PropTypes.func,
  transcript: PropTypes.string,
  transcriptRendered: PropTypes.bool,
  variant: PropTypes.string,
}

export default AccessibilityMenu
