import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  BodyCopy,
  CircleRightArrowIcon,
  ContainedButton,
  Hairline,
  TextLink,
} from 'secondstep-components'
import agencyImg from './assets/ss_pathways_agency.png'
import belongingImg from './assets/ss_pathways_belonging.png'
import confidenceImg from './assets/ss_pathways_confidence.png'
import wellbeingImg from './assets/ss_pathways_wellbeing.png'

export const ButtonText = styled(BodyCopy)`
  position: absolute;
  top: 16px;
  left: 19px;
  color: ${themeGet('colors.darkGray')};
  text-align: left;
`

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-gap: 1.25rem;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  margin-top: 0.875rem;
`

export const ExploreProgramsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.375rem 1.25rem 1.25rem;
  border-radius: 0.625rem;
  background: ${themeGet('colors.white')};
  position: relative;
  box-sizing: border-box;
`

export const PathwaysContainer = styled.div`
  background-color: ${themeGet('colors.paleGray')};
  border: 2px solid ${themeGet('colors.paleGray')};
  border-radius: 0.625rem;
  padding: 0.875rem 1.125rem 1.125rem;
`

export const PracticesContainer = styled.div`
  background-color: ${themeGet('colors.paleGray')};
  border: 2px solid ${themeGet('colors.paleGray')};
  border-radius: 0.625rem;
  margin-top: 1.25rem;
  padding: 0.875rem 0 0.6875rem 1.125rem;
`

export const StyledBodyCopy = styled(BodyCopy)`
  font-family: ${themeGet('font.sharpSlab.semibold')};
  font-size: 1rem;
`

export const StyledButton = styled(ContainedButton)`
  position: relative;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border: none;
  border-radius: 0.625rem;
  height: 97px;
  min-width: 160px;

  &:not([disabled]):hover {
    background-color: transparent;
    background-size: 100% 100%;
    outline: 2px solid ${themeGet('colors.hsPrimaryPinkLight')};
  }

  &:not([disabled]):focus {
    background-color: transparent;
    background-size: 100% 100%;
    box-shadow: none;
    outline: 2px solid ${themeGet('colors.hsPrimaryPinkLight')};
  }
`

export const BelongingButton = styled(StyledButton)`
  background-image: url(${belongingImg});

  &:not([disabled]):hover {
    background-image: url(${belongingImg});
  }
`

export const ConfidenceButton = styled(StyledButton)`
  background-image: url(${confidenceImg});

  &:not([disabled]):hover {
    background-image: url(${confidenceImg});
  }
`

export const AgencyButton = styled(StyledButton)`
  background-image: url(${agencyImg});

  &:not([disabled]):hover {
    background-image: url(${agencyImg});
  }
`

export const WellBeingButton = styled(StyledButton)`
  background-image: url(${wellbeingImg});

  &:not([disabled]):hover {
    background-image: url(${wellbeingImg});
  }
`

export const StyledCircleRightArrowIcon = styled(CircleRightArrowIcon)`
  height: 23px;
  width: 23px;
  margin-bottom: 0 !important;
  padding-left: 0.75rem;

  circle {
    fill: ${themeGet('colors.white')};
  }
`

export const StyledTextLink = styled(TextLink)`
  color: ${themeGet('colors.darkBlue')};
  font-family: ${themeGet('font.sharpSlab.semibold')};
  font-size: 1rem;
  width: fit-content;

  svg {
    color: ${themeGet('colors.blue')};
    margin-bottom: 0.1875rem !important;
  }

  &:hover {
    g {
      color: ${themeGet('colors.darkBlue')} !important;
    }
  }

  &:focus {
    g {
      color: ${themeGet('colors.darkBlue')} !important;
    }
  }
`

export const Title = styled.h3`
  font-weight: normal;
  font-family: ${themeGet('font.sharpSlab.semibold')};
  font-size: 1.4375rem;
  line-height: 1.5625rem;
  color: ${themeGet('colors.darkBlue')};
  width: 100%;
`

export const TitleHairline = styled(Hairline)`
  height: 2px;
  margin-top: 0.875rem;
  margin-bottom: 1.25rem;
  background: ${themeGet('colors.paleGray')};
`
