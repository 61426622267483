import React from 'react'
import PropTypes from 'prop-types'

const HelpIcon = ({ fill }) => (
  <svg height="17px" viewBox="0 0 17 17" width="17px">
    <title>ss_icon_help</title>
    <g
      fill="none"
      fillRule="evenodd"
      id="ss_icon_help"
      stroke="none"
      strokeWidth="1"
    >
      <g id="Group" transform="translate(1.000000, 1.000000)">
        <circle cx="7.5" cy="7.5" fill={fill} id="btn-base" r="7.5" />
        <path
          d="M7.93604651,9.03286221 C7.93604651,9.28555124 7.76162791,9.4540106 7.5,9.4540106 C7.23837209,9.4540106 7.06395349,9.28555124 7.06395349,9.03286221 L7.06395349,7.7689959 C7.06395349,7.51630687 7.23837209,7.34784751 7.5,7.34784751 C8.45930233,7.34784751 9.24418605,6.58978041 9.24418605,5.66325396 C9.24418605,4.73672751 8.45930233,3.97866041 7.5,3.97866041 C6.54069767,3.97866041 5.75581395,4.73672751 5.75581395,5.66325396 C5.75581395,5.91594299 5.58139535,6.08440235 5.31976744,6.08440235 C5.05813953,6.08440235 4.88372093,5.91594299 4.88372093,5.66325396 C4.88372093,4.27346428 6.06104651,3.13636364 7.5,3.13636364 C8.93895349,3.13636364 10.1162791,4.27346428 10.1162791,5.66325396 C10.1162791,6.92669912 9.15697674,7.93745525 7.93604651,8.14802945 L7.93604651,9.03286221 Z M7.5,12.1744186 C7.18081395,12.1744186 6.91962209,11.9225719 6.91962209,11.6138701 C6.91962209,11.3055895 7.18081395,11.0533216 7.5,11.0533216 C7.81918605,11.0533216 8.08037791,11.3055895 8.08037791,11.6138701 C8.08037791,11.9225719 7.81918605,12.1744186 7.5,12.1744186"
          fill="#FFFFFF"
          id="question-mark"
          stroke="#FFFFFF"
          strokeWidth="0.856236786"
        />
      </g>
    </g>
  </svg>
)

HelpIcon.propTypes = {
  fill: PropTypes.string,
}

export default HelpIcon
