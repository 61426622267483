import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Main = styled.main.attrs(({ isPaleGray }) => ({
  backgroundColor: isPaleGray
    ? themeGet('colors.paleGray')
    : themeGet('colors.backgrounds.body', 'colors.white'),
}))`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  box-sizing: border-box;
  width: 100%;
  min-width: 375px;
  height: 100%;
  margin: 0 auto;
  background: ${({ backgroundColor }) => backgroundColor};
  position: relative;
  ${themeGet('breakpoints.tablet')} {
    padding-bottom: 0;
    position: unset;
  }
`
