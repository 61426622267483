import React from 'react'
import PropTypes from 'prop-types'
import CourseComponent from 'components/CourseComponent'
import LearnErrorBoundary from 'components/LearnErrorBoundary'
import UnitComponent from 'components/UnitComponent'
import { HeadingSpacer } from './component.styles'

export const Component = ({
  acknowledgements,
  entryId,
  match,
  onSelectUnit,
  productName,
  title,
  totalLessons,
  unitEntryId,
  unitIndex,
  units,
}) => {
  return (
    <LearnErrorBoundary>
      <HeadingSpacer />
      <CourseComponent
        displayTitle=""
        entryId={entryId}
        isActive={false}
        isEditable={false}
        skipHead={true}
        title={title}
        totalAmount={totalLessons}
      >
        <UnitComponent
          acknowledgements={acknowledgements}
          entryId={entryId}
          match={match}
          onSelectUnit={onSelectUnit}
          productName={productName}
          unitEntryId={unitEntryId}
          unitIndex={unitIndex}
          units={units}
        />
      </CourseComponent>
    </LearnErrorBoundary>
  )
}

Component.propTypes = {
  acknowledgements: PropTypes.shape({
    contentType: PropTypes.string,
    description: PropTypes.string,
    displayTitle: PropTypes.string,
    id: PropTypes.string,
    internalTitle: PropTypes.string,
    locale: PropTypes.string,
    pageData: PropTypes.string,
    type: PropTypes.string,
  }),
  entryId: PropTypes.string.isRequired,
  match: PropTypes.object.isRequired,
  onSelectUnit: PropTypes.func.isRequired,
  productName: PropTypes.string,
  title: PropTypes.string.isRequired,
  totalLessons: PropTypes.number.isRequired,
  unitEntryId: PropTypes.string.isRequired,
  unitIndex: PropTypes.number.isRequired,
  units: PropTypes.array.isRequired,
}

export default Component
