import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { Element, scroller } from 'react-scroll'
import { SpinnerAnimatedIcon } from 'secondstep-components'
import {
  formatHoursSinceTimestamp,
  formatItemCount,
  formatPageCount,
} from 'utils/reportsHelpers'
import { TOOLTIP_LOCATIONS, TOOLTIP_PROGRAMS } from 'utils/tooltipHelpers'
import {
  FooterWrapper,
  HeaderWrapper,
  LargeBullet,
  LastUpdatedMessage,
  LeftBox,
  ListHeaderWrapper,
  ListWrapper,
  LoadingWrapper,
  PaginationButton,
  PaginationIndexWrapper,
  PaginationItemWrapper,
  PaginationPageWrapper,
  RightBox,
  StyledAdminExportDownloadButton,
  StyledHairline,
  TableWrapper,
  ViewOnDesktopMessageWrapper,
  Wrapper,
} from './component.styles.js'
import {
  NEXT_BUTTON_TEXT,
  PREVIOUS_BUTTON_TEXT,
  SortOrder,
} from './constants.js'
import { TOGGLE_VIEW } from 'store/userSessionManager/constants'

const SortableAccordionList = ({
  children,
  className,
  currentItemCount,
  currentPage,
  handlePageChange,
  itemLabel,
  items,
  itemsPerPage,
  lastUpdatedAt,
  nextButtonText = NEXT_BUTTON_TEXT,
  previousButtonText = PREVIOUS_BUTTON_TEXT,
  renderLeftBox,
  renderSortButton,
  showHairline,
  sortButtons,
  sortKey,
  sortOrder,
  toggleView,
  totalItems,
  totalPages,
}) => {
  const [activeIndex, setActiveIndex] = useState(null)
  const [isScrolled, setIsScrolled] = useState(false)

  useEffect(() => {
    const handleScroll = () => {
      const hrElement = document.querySelector('.hr')
      const viewPortWidth = window.innerWidth
      const scrollPosition = window.scrollY
      let contentBottomOffset = 430

      if (viewPortWidth <= 932) {
        contentBottomOffset = 465
      }

      if (viewPortWidth <= 768) {
        contentBottomOffset = 555
      }

      if (viewPortWidth <= 566) {
        contentBottomOffset = 601
      }

      if (viewPortWidth <= 375) {
        contentBottomOffset = 633
      }

      const contentBottom =
        hrElement?.offsetTop + hrElement?.offsetHeight + contentBottomOffset

      setIsScrolled(scrollPosition > contentBottom)
    }
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    window.history.scrollRestoration = 'manual'
    setActiveIndex(null)
  }, [currentPage, sortOrder, sortKey])

  const hasMultipleItems = items?.length > 1 || currentPage > 1
  const itemStartIndex = (currentPage - 1) * itemsPerPage + 1
  const itemEndIndex = itemStartIndex + currentItemCount - 1

  const showPaginationButtons = totalPages > 1

  const scrollToContentWrapper = () => {
    scroller.scrollTo('content-wrapper', {
      duration: 200,
      delay: 50,
      smooth: true,
      offset: 60,
    })
  }

  const handleListItemClick = index => {
    if (activeIndex === index) {
      setActiveIndex(null)
    } else {
      setActiveIndex(index)
    }
  }

  const handleSortButtonClick = ({ handleOnClick, sortKey }, sortOrder) => {
    scrollToContentWrapper()
    setActiveIndex(null)
    handleOnClick && handleOnClick(sortKey, sortOrder)
  }

  const handlePageButtonClick = pageNumber => {
    scrollToContentWrapper()
    handlePageChange(pageNumber)
  }

  const lastUpdatedMessage = formatHoursSinceTimestamp(lastUpdatedAt)

  if (!Array.isArray(items)) {
    return (
      <Wrapper className={className}>
        <Element name="content-wrapper" />
        {/* {renderLoadingSubheader && renderLoadingSubheader()} */}
        <LoadingWrapper>
          <SpinnerAnimatedIcon />
          <p>Loading...</p>
        </LoadingWrapper>
      </Wrapper>
    )
  }

  return (
    <Wrapper className={className}>
      <HeaderWrapper>
        {renderLeftBox && <LeftBox>{renderLeftBox()}</LeftBox>}
        <RightBox>
          {toggleView === TOGGLE_VIEW.MONITOR && (
            <LastUpdatedMessage>{lastUpdatedMessage}</LastUpdatedMessage>
          )}
          <StyledAdminExportDownloadButton
            programKey={TOOLTIP_PROGRAMS.HS}
            toggleView={toggleView}
            tooltipLocation={TOOLTIP_LOCATIONS.EXPORT_BUTTON}
          />
        </RightBox>
      </HeaderWrapper>
      {showHairline && <StyledHairline />}
      <ViewOnDesktopMessageWrapper>
        <LargeBullet />
        <p>Please view at a higher width or on desktop.</p>
      </ViewOnDesktopMessageWrapper>
      <TableWrapper>
        <ListHeaderWrapper isScrolled={isScrolled}>
          {/* <StyledHr className="hr" isScrolled={isScrolled} /> */}

          {renderSortButton &&
            sortButtons.map(props => {
              return renderSortButton({
                ...props,
                handleOnClick: () => handleSortButtonClick(props, sortOrder),
                isActive: props.sortKey === sortKey,
                isCaretUp:
                  props.sortKey === sortKey && sortOrder === SortOrder.desc,
              })
            })}
          {/* !renderSortButton && sortButtons.map DEFAULTS */}
        </ListHeaderWrapper>
        <ListWrapper>
          {children &&
            items.map((item, index) => {
              return children(item, index, index === activeIndex, () =>
                handleListItemClick(index),
              )
            })}
          {/* !children && items.map DEFAULTS */}
        </ListWrapper>
        {hasMultipleItems && (
          <FooterWrapper>
            {showPaginationButtons && (
              <PaginationButton
                disabled={currentPage <= 1}
                onClick={() => handlePageButtonClick(currentPage - 1)}
              >
                {previousButtonText}
              </PaginationButton>
            )}
            <PaginationIndexWrapper>
              <PaginationPageWrapper>
                {formatPageCount(currentPage, totalPages)}
              </PaginationPageWrapper>
              <PaginationItemWrapper>
                {formatItemCount(
                  itemStartIndex,
                  itemEndIndex,
                  totalItems,
                  itemLabel,
                )}
              </PaginationItemWrapper>
            </PaginationIndexWrapper>
            {showPaginationButtons && (
              <PaginationButton
                disabled={currentPage >= totalPages}
                onClick={() => handlePageButtonClick(currentPage + 1)}
              >
                {nextButtonText}
              </PaginationButton>
            )}
          </FooterWrapper>
        )}
      </TableWrapper>
    </Wrapper>
  )
}

SortableAccordionList.propTypes = {
  children: PropTypes.func.isRequired,
  className: PropTypes.string,
  currentItemCount: PropTypes.number,
  currentPage: PropTypes.number,
  getPaginatedItems: PropTypes.func,
  handlePageChange: PropTypes.func,
  itemLabel: PropTypes.string,
  items: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.object), // fully hydrated
    PropTypes.object, // fetching or error
    PropTypes.oneOf([null]), // not hydrated
  ]),
  itemsPerPage: PropTypes.number,
  lastUpdatedAt: PropTypes.string,
  nextButtonText: PropTypes.string,
  previousButtonText: PropTypes.string,
  renderLeftBox: PropTypes.func,
  renderSortButton: PropTypes.func,
  showHairline: PropTypes.bool,
  sortButtons: PropTypes.arrayOf(
    PropTypes.shape({
      sortKey: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      handleOnClick: PropTypes.func,
    }),
  ),
  sortKey: PropTypes.string,
  sortOrder: PropTypes.string,
  toggleView: PropTypes.string,
  totalItems: PropTypes.number,
  totalPages: PropTypes.number,
}

export default SortableAccordionList
