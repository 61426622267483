import React from 'react'
import PropTypes from 'prop-types'
import { CertificateIcon } from 'secondstep-components'
import {
  ButtonViewCertificate,
  Certificate,
  ContentWrapper,
  ContentIconWrapper,
  SubTitle,
  IconWrapper,
  TextWrapper,
  Title,
} from './component.styles'

export const CertificateComponent = ({ isComplete, launchCertificate }) => {
  return (
    <Certificate className="certificate" isComplete={isComplete}>
      <ContentWrapper>
        <ContentIconWrapper>
          <IconWrapper>
            <CertificateIcon />
          </IconWrapper>
          <TextWrapper>
            <Title>Unit Certificate of Completion</Title>
            <SubTitle>
              Complete all the microlearnings in this unit to earn your
              certificate
            </SubTitle>
          </TextWrapper>
        </ContentIconWrapper>
        {isComplete && (
          <ButtonViewCertificate onClick={launchCertificate}>
            View
          </ButtonViewCertificate>
        )}
      </ContentWrapper>
    </Certificate>
  )
}

CertificateComponent.propTypes = {
  isComplete: PropTypes.bool,
  launchCertificate: PropTypes.func,
}

export default CertificateComponent
