import React from 'react'
import PropTypes from 'prop-types'
import { ClockIcon, HeaderOne, IconWithText } from 'secondstep-components'
import { TimeWrapper, TrainingHeaderWrapper } from './component.styles'

const TrainingHeader = ({
  displayTitle,
  someModulesCompleted,
  totalCompletedTrainingTime,
}) => {
  const showTime =
    someModulesCompleted &&
    totalCompletedTrainingTime &&
    totalCompletedTrainingTime !== 0 &&
    totalCompletedTrainingTime !== 'POD'
  return (
    <TrainingHeaderWrapper>
      <HeaderOne dataTestId="training-title">{displayTitle}</HeaderOne>
      {showTime && (
        <TimeWrapper dataTestId="total-training-time">
          <IconWithText
            icon={<ClockIcon />}
            text={`Total training time: ${totalCompletedTrainingTime}`}
          />
        </TimeWrapper>
      )}
    </TrainingHeaderWrapper>
  )
}

TrainingHeader.propTypes = {
  displayTitle: PropTypes.string.isRequired,
  someModulesCompleted: PropTypes.bool,
  totalCompletedTrainingTime: PropTypes.string.isRequired,
}

export default TrainingHeader
