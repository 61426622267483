import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  operations as reportsOperations,
  selectors as reportsSelectors,
} from 'store/reportsManager'
import AdminSiteList from 'components/AdminSiteList'

const PROGRAM = {
  k8: 'K8',
  sela: 'SELA',
}

const isK8Program = program => program === PROGRAM.k8
const isSelaProgram = program => program === PROGRAM.sela

const AdminSchoolTableContainer = ({
  adminSites,
  currentPage,
  fetchAdminK8Sites,
  fetchAdminSelaSitesList,
  isFetchingSchoolsList,
  navigationEvent,
  orderBy,
  reportingError,
  selectedProgram,
  sortKey,
  totalCount,
  totalPages,
  sitesPerPage,
  updateLaunchSelectedPaginationSortKey,
  updateLaunchSelectedPaginationSortOrder,
}) => {
  const programKey = selectedProgram.key
  const currentCount = adminSites?.length || 0
  useEffect(() => {
    if (!adminSites && !isFetchingSchoolsList && !reportingError) {
      if (isK8Program(programKey)) {
        fetchAdminK8Sites(sitesPerPage, currentPage, orderBy, sortKey)
      } else {
        if (isSelaProgram(programKey)) {
          fetchAdminSelaSitesList()
        }
      }
    }
  }, [selectedProgram, isFetchingSchoolsList, adminSites, reportingError])

  // TODO: add pagination qp to url qp for history and ai
  const getPaginatedSites = (page, key, order) => {
    if (key !== sortKey) updateLaunchSelectedPaginationSortKey(key)
    if (order !== orderBy) updateLaunchSelectedPaginationSortOrder(order)
    fetchAdminK8Sites(sitesPerPage, page, order, key)
  }

  const sites = adminSites ?? []

  if (reportingError) {
    return null
  }

  return (
    <AdminSiteList
      currentPage={currentPage}
      currentSiteCount={currentCount}
      getPaginatedSites={getPaginatedSites}
      isFetching={isFetchingSchoolsList}
      navigationEvent={navigationEvent}
      orderBy={orderBy}
      sites={sites}
      sitesPerPage={sitesPerPage}
      sortKey={sortKey}
      totalPages={totalPages}
      totalSites={totalCount}
    />
  )
}

AdminSchoolTableContainer.propTypes = {
  adminSites: PropTypes.array,
  currentPage: PropTypes.number,
  fetchAdminK8Sites: PropTypes.func,
  fetchAdminSelaSitesList: PropTypes.func,
  isFetchingSchoolsList: PropTypes.bool,
  navigationEvent: PropTypes.func,
  orderBy: PropTypes.string,
  programTitles: PropTypes.array,
  reportingError: PropTypes.bool,
  selectedProgram: PropTypes.object,
  sitesPerPage: PropTypes.number,
  sortKey: PropTypes.string,
  totalCount: PropTypes.number,
  totalPages: PropTypes.number,
  updateLaunchSelectedPaginationSortKey: PropTypes.func,
  updateLaunchSelectedPaginationSortOrder: PropTypes.func,
}

const mapStateToProps = state => {
  const { reportsManager } = state
  const isFetchingSchoolsList =
    reportsManager.isFetchingAdminK8SitesList ||
    reportsManager.isFetchingAdminSelaSitesList
  const adminSites = reportsSelectors.selectReportingAdminSites(state)
  const { error } = reportsManager || {}
  const reportingError = !!error
  const {
    currentPage,
    totalPages,
    totalCount,
    sortKey,
    orderBy,
    sitesPerPage,
  } = reportsManager?.admin?.launchPagination || {}

  return {
    isFetchingSchoolsList,
    reportingError,
    selectedProgram: reportsManager.selectedProgram,
    currentPage,
    totalCount,
    totalPages,
    adminSites,
    sortKey,
    orderBy,
    sitesPerPage,
  }
}

const mapDispatchToProps = {
  fetchK8ReportClassDetails: reportsOperations.fetchK8ReportClassDetails,
  fetchK8ReportGrade: reportsOperations.fetchK8ReportGrade,
  fetchK8ReportGradeAndClassDetails:
    reportsOperations.fetchK8ReportGradeAndClassDetails,
  fetchAdminK8Sites: reportsOperations.fetchAdminK8Sites,
  fetchAdminSelaSitesList: reportsOperations.fetchAdminSelaSitesList,
  updateLaunchSelectedPaginationSortKey:
    reportsOperations.updateLaunchSelectedPaginationSortKey,
  updateLaunchSelectedPaginationSortOrder:
    reportsOperations.updateLaunchSelectedPaginationSortOrder,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminSchoolTableContainer)
