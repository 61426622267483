import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { CheckBox } from 'secondstep-components'

export const StyledText = styled.div`
  margin: 0.9375rem 2.5rem 0rem 1.25rem;
  font-size: 0.875rem;
`

export const StyledMessage = styled.div`
  font-family: ${themeGet('font.molde.regular')};
  line-height: 1.0625rem;
`

export const StyledPanel = styled.div`
  background-color: ${themeGet('colors.paleGray')};
  border-radius: 0.625rem;
  color: ${themeGet('colors.darkGray')};
  display: flex;
  font-family: ${themeGet('font.molde.medium')};
  height: 91px;
  justify-content: space-between;
  width: 610px;
  margin-bottom: 1.75rem;
`

export const StyledTitle = styled.div`
  margin-bottom: 0.25rem;
  font-size: 1rem;
`

export const StyledAlertText = styled.span`
  font-family: ${themeGet('font.molde.medium')};
  color: ${themeGet('colors.deleteRed')};
`

export const StyledCheckbox = styled(CheckBox).attrs(props => ({
  borderColor: themeGet('colors.blueGray')(props),
  backgroundCheckedColor: themeGet('colors.blueGray')(props),
}))`
  display: flex;
  margin: 2.125rem 1.25rem 2.125rem 1.25rem;
  font-size: 1rem;
  padding-top: 0.1875rem;

  & label {
    font-family: ${themeGet('font.molde.medium')};
  }

  [class*='CheckBoxStyled-sc-'] {
    width: 1.1875rem;
    height: 1.125rem;
    padding-top: 0.125rem;
    border: 0.125rem solid ${({ borderColor }) => borderColor} !important;
    border-radius: 0.1875rem;
  }

  [class*='CheckBoxHidden']:checked ~ [class*='CheckBoxStyled'] {
    color: ${themeGet('colors.white')};
    background: ${({ backgroundCheckedColor }) => backgroundCheckedColor};
  }

  & label {
    align-items: center;
  }
`
