import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { CollapsibleButton, HeaderThree } from 'secondstep-components'

export const StyledCollapsibleButton = styled(CollapsibleButton)`
  display: flex;
  align-items: baseline;
  justify-content: flex-start;

  svg path {
    fill: ${themeGet('colors.buttons.primaryBackground')};
  }

  &:focus,
  &:hover {
    svg path {
      fill: ${themeGet('colors.buttons.primaryBackgroundHover')};
    }
  }
`

export const StyledHeaderThree = styled(HeaderThree)`
  display: inline;
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('fontFamilyBold')};
  margin: 0;
`
