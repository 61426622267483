import React from 'react'
import PropTypes from 'prop-types'

const LogoutIcon = ({ fill }) => (
  <svg height="17px" viewBox="0 0 17 17" width="17px">
    <title>ss_icon_logout</title>
    <g
      fill="none"
      fillRule="evenodd"
      id="ss_icon_logout"
      stroke="none"
      strokeLinecap="round"
      strokeWidth="1"
    >
      <g
        id="Group-16"
        stroke={fill}
        strokeWidth="2"
        transform="translate(2.000000, 2.000000)"
      >
        <polyline
          id="Path-6"
          points="7 0 0 0 0 13 7 13"
          strokeLinejoin="round"
        />
        <g id="Group-6" transform="translate(5.070212, 3.231978)">
          <line id="Path-2" x1="7" x2="0" y1="3.52950335" y2="3.47049665" />
          <polyline
            id="Path-3"
            points="3 5 6.5 2 10 5"
            transform="translate(6.500000, 3.500000) rotate(-270.000000) translate(-6.500000, -3.500000) "
          />
        </g>
      </g>
    </g>
  </svg>
)

LogoutIcon.propTypes = {
  fill: PropTypes.string,
}

export default LogoutIcon
