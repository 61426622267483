import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'
import { NotFound, Unauthorized } from 'secondstep-components'
import { VIEW_OPTION_PATHS } from 'utils/adminExperienceHelpers'
import { TOGGLE_VIEW } from 'store/userSessionManager/constants'
import withApiError, {
  ERROR_TYPE_LMS,
  ERROR_TYPE_PROFILE,
} from 'layers/errorHandling/apiError'
import { operations as userSessionOperations } from 'store/userSessionManager'
import AdminDashboard from 'pages/AdminDashboard'
import AdminSubPages from 'pages/AdminSubPages'
import PagesContainer from '../Pages'
import LearnErrorBoundary from 'components/LearnErrorBoundary'
import { BPU, CPU, HS, K8, SELA, SETUP } from './constants'
import { getStaticRoutes } from './data'

export const AdminRouter = ({
  hsToggleView,
  k8ToggleView,
  location,
  match,
  recentLeaderDashboardUrl,
  roles,
  setLeaderDashboardRecentUrl,
  userClaims,
}) => {
  const { path } = match

  const isFirstTimeSetupAdmin =
    roles.length === 1 && roles?.includes('Set-Up Admin')
  const {
    hasBpuAdminClaim = false,
    hasCpuAdminClaim = false,
    hasElemAdminClaim = false,
    hasHighschoolAdminClaim = false,
    hasMsAdminClaim = false,
    hasSelaAdminClaim = false,
  } = userClaims || {}

  let k8ViewOption = VIEW_OPTION_PATHS.PROGRAM_ADOPTION
  let hsViewOption = VIEW_OPTION_PATHS.PROGRAM_ADOPTION
  if (!recentLeaderDashboardUrl) {
    k8ViewOption = location?.pathname?.includes('monitor')
      ? VIEW_OPTION_PATHS.MONITOR
      : VIEW_OPTION_PATHS.PROGRAM_ADOPTION
    hsViewOption = location?.pathname?.includes('monitor')
      ? VIEW_OPTION_PATHS.MONITOR
      : VIEW_OPTION_PATHS.PROGRAM_ADOPTION
  } else {
    if (k8ToggleView) {
      if (k8ToggleView === TOGGLE_VIEW.MONITOR) {
        k8ViewOption = VIEW_OPTION_PATHS.MONITOR
      }
    }
    if (hsToggleView) {
      if (hsToggleView === TOGGLE_VIEW.MONITOR) {
        hsViewOption = VIEW_OPTION_PATHS.MONITOR
      }
    }
  }

  const hasAccessRoute = {
    k8: {
      hasAccess: hasElemAdminClaim || hasMsAdminClaim,
      validName: K8,
      path: `${K8}/${k8ViewOption}`,
    },
    hs: {
      hasAccess: hasHighschoolAdminClaim,
      validName: HS,
      path: `${HS}/${hsViewOption}`,
    },
    bpu: {
      hasAccess: hasBpuAdminClaim,
      validName: BPU,
      path: BPU,
    },
    cpu: {
      hasAccess: hasCpuAdminClaim,
      validName: CPU,
      path: CPU,
    },
    sela: {
      hasAccess: hasSelaAdminClaim,
      validName: SELA,
      path: SELA,
    },
  }

  const getDefaultAdminRoute = () => {
    const checkAccessAndReturnRoute = () => {
      for (const route in hasAccessRoute) {
        const program = hasAccessRoute[route]
        if (program.hasAccess) {
          const qualifiedPath = path.includes('program')
            ? program.path
            : `program/${program.path}`
          setLeaderDashboardRecentUrl(`program/${program.path}`)
          return qualifiedPath
        }
      }
    }

    if (isFirstTimeSetupAdmin) {
      return `${SETUP}`
    } else if (!recentLeaderDashboardUrl) {
      return checkAccessAndReturnRoute()
    } else {
      // check access to the program in the persisted url and if no access clear the persisted url
      const url = recentLeaderDashboardUrl.split('/')
      const programTextIndex = url.indexOf('program')
      const parsedProgram = url[programTextIndex + 1]
      const programToAccess = hasAccessRoute[parsedProgram]

      if (!programToAccess.hasAccess) {
        return checkAccessAndReturnRoute()
      } else {
        const formattedUrl = url.slice(programTextIndex).join('/') // Gaurentees that the url is in the right format of program/:programName/:viewOption
        return formattedUrl
      }
    }
  }

  const getProtectedAdminRoute = routerProps => {
    const { programName = '' } = routerProps?.match?.params
    const program = programName.toLowerCase()
    const { hasAccess = false, validName = '' } = hasAccessRoute[program] || {}

    if (isFirstTimeSetupAdmin && validName === '' && path === '/admin') {
      return <AdminDashboard {...routerProps} />
    } else if (hasAccess && program === validName) {
      return <AdminDashboard {...routerProps} />
    } else if (!hasAccess && program === validName) {
      return <Unauthorized />
    } else {
      return <NotFound {...routerProps} />
    }
  }

  const adminDefaultRoute = path.includes('admin') ? getDefaultAdminRoute() : ''
  return (
    <LearnErrorBoundary>
      <Switch>
        <Route
          component={AdminSubPages}
          exact
          path={`${path}/hs-implementation-guide`}
        />
        <Route
          component={AdminSubPages}
          exact
          path={`${path}/implementation-guide`}
        />
        <Route component={PagesContainer} path={getStaticRoutes(path)} />
        <Route exact path={`/admin/setup`} render={getProtectedAdminRoute} />
        <Route
          exact
          path={'/admin/program/:programName/:viewOption'}
          render={getProtectedAdminRoute}
        />
        <Route
          exact
          path={'/admin/program/:programName'}
          render={getProtectedAdminRoute}
        />
        <Redirect
          exact
          from={'/admin/program'}
          to={`/admin/program/${adminDefaultRoute}`}
        />
        <Redirect exact from={`/admin`} to={`/admin/${adminDefaultRoute}`} />
        <Route component={NotFound} />
      </Switch>
    </LearnErrorBoundary>
  )
}

AdminRouter.propTypes = {
  hsToggleView: PropTypes.oneOf([TOGGLE_VIEW.LAUNCH, TOGGLE_VIEW.MONITOR]),
  k8ToggleView: PropTypes.oneOf([TOGGLE_VIEW.LAUNCH, TOGGLE_VIEW.MONITOR]),
  location: PropTypes.object,
  match: PropTypes.object,
  recentLeaderDashboardUrl: PropTypes.string,
  roles: PropTypes.array,
  setLeaderDashboardRecentUrl: PropTypes.func,
  userClaims: PropTypes.object,
}

const mapStateToProps = ({
  userAccessManager,
  userProfileManager,
  userSessionManager,
}) => {
  return {
    roles: userProfileManager?.profile?.roles ?? [],
    userClaims: userAccessManager?.claims,
    recentLeaderDashboardUrl:
      userSessionManager?.adminDashboard?.recentLeaderDashboardUrl,
  }
}
const mapDispatchToProps = {
  setLeaderDashboardRecentUrl:
    userSessionOperations.setLeaderDashboardRecentUrl,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withApiError(AdminRouter, [ERROR_TYPE_PROFILE, ERROR_TYPE_LMS]))
