export const PROGRAM_TITLES = {
  BPU: 'Bullying Prevention Unit Training',
  CPU: 'Child Protection Unit Training',
  HS: (
    <span>
      Second Step<sup>®</sup> High School
    </span>
  ),
  K8: (
    <span>
      Second Step<sup>®</sup> Grades K-8
    </span>
  ),
  SELA: (
    <span>
      Second Step<sup>®</sup> SEL for Adults
    </span>
  ),
}

export const DROPDOWN_PROGRAMS = {
  BPU: {
    name: 'Bullying Prevention Unit Training',
    sortId: 2,
  },
  CPU: {
    name: 'Child Protection Unit Training',
    sortId: 3,
  },
  HS: {
    name: 'Grades 9-12',
    sortId: 1,
  },
  K8: {
    name: 'Grades K-8',
    sortId: 0,
  },
  SELA: {
    name: 'SEL for Adults',
    sortId: 4,
  },
}
export const CONTENTFUL_PROGRAM_LINK_INTERNAL_TITLE =
  'k8 admin quick links - explore lessons'

export const DASHBOARD_HEADER = 'Leader Dashboard'

export const PROGRAM_ERROR_MESSAGE = 'Program Title not Found'

export const PROGRAM_DROPDOWN_LABEL = 'Switch Program'
