import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { operations } from 'store/routeServiceManager'
import { filterUniqueUserContextFields } from 'auth/stores/userContext'
import { useUserContext } from 'layers/content/v2/hooks/useUserContext'
import { ERROR_TYPES } from 'layers/content/constants'

export function useSubNavGetter(options) {
  const { app, route, filterUserContext = filterUniqueUserContextFields } =
    options || {}
  const dispatch = useDispatch()
  const userContext = useUserContext()
  const { data, error, loading } = useSelector(state => {
    const data = state.routeServiceManager?.subnavs[app] ?? undefined
    const error = state.routeServiceManager?.errorSubnav
    const loading = state.routeServiceManager?.isFetchingSubnav

    return {
      data,
      error: error
        ? { message: error, errorType: ERROR_TYPES.routeServiceError }
        : null,
      loading,
    }
  })

  useEffect(() => {
    if (!app) {
      return
    }

    const hasUserContext = userContext && Object.keys(userContext).length > 0

    if (!!error || (!error && (!hasUserContext || !!data))) {
      return
    }

    dispatch(
      operations.fetchSubnav({
        app,
        route,
        userContext: filterUserContext(userContext),
      }),
    )
  }, [app, userContext, route])

  return {
    data,
    error,
    loading,
  }
}
