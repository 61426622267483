import React from 'react'
import PropTypes from 'prop-types'

import { HeaderTwo } from 'secondstep-components'
import {
  CardContainer,
  StyledTooltip,
  TitleAndToolTipContainer,
} from './component.styles'
import ResourceCard from 'experienceComponents/ResourceCard'
import {
  getResourceValues,
  getTextBasedOnLocation,
} from 'utils/highschoolHelpers'
export const CardSection = ({
  headerText,
  resources,
  onClick,
  facetCardSetting,
  tooltips,
  isIntegratedSection = false,
}) => {
  const { facets: desiredFacets } = facetCardSetting || {}

  return (
    <>
      <TitleAndToolTipContainer isIntegratedSection={isIntegratedSection}>
        <HeaderTwo className="integratedHeader">
          {headerText}
          <StyledTooltip
            content={getTextBasedOnLocation(tooltips, isIntegratedSection)}
          >
            <i className="fas fa-question-circle" />
          </StyledTooltip>
        </HeaderTwo>
      </TitleAndToolTipContainer>
      <CardContainer>
        {resources?.map((resource, index) => {
          const {
            audiences,
            grades,
            resourceTypes,
            durations,
            units,
          } = getResourceValues(resource, desiredFacets)
          return (
            <ResourceCard
              audience={audiences}
              description={resource?.description}
              duration={durations}
              grade={grades}
              image={resource?.image?.file?.url}
              imageDescription={resource?.image?.description}
              key={index}
              onClick={() => onClick(resource.routeSlug)}
              resourceType={resourceTypes}
              title={resource?.displayTitle}
              unit={units}
            />
          )
        })}
      </CardContainer>
    </>
  )
}
CardSection.propTypes = {
  facetCardSetting: PropTypes.array,
  headerText: PropTypes.string,
  isIntegratedSection: PropTypes.bool,
  onClick: PropTypes.func,
  resources: PropTypes.shape({ map: PropTypes.func }),
  tooltips: PropTypes.array,
}
export default CardSection
