import React from 'react'
import PropTypes from 'prop-types'
import ImageThumbnailButton from '../ImageThumbnailButton'
import VideoThumbnailButton from '../VideoThumbnailButton'
import AudioThumbnailButton from '../AudioThumbnailButton'

const MediaThumbnailButton = ({
  description,
  entryId,
  locale,
  thumbnailUrl,
  time,
  title,
  tracks,
  fileUrl,
  fileType,
  videoUrlExtended,
  transcriptSource,
  transcriptExtended,
}) => {
  if (fileType !== 'audio' && fileType !== 'video' && fileType !== 'image') {
    return null
  }

  return (
    <>
      {fileType === 'audio' && (
        <AudioThumbnailButton
          audioUrl={fileUrl}
          description={description}
          thumbnailUrl={thumbnailUrl}
          time={time}
          title={title}
        />
      )}
      {fileType === 'video' && (
        <VideoThumbnailButton
          description={description}
          entryId={entryId}
          locale={locale}
          thumbnailUrl={thumbnailUrl}
          time={time}
          title={title}
          tracks={tracks}
          transcriptExtended={transcriptExtended}
          transcriptSource={transcriptSource}
          videoUrl={fileUrl}
          videoUrlExtended={videoUrlExtended}
        />
      )}
      {fileType === 'image' && (
        <ImageThumbnailButton
          description={description}
          imageUrl={fileUrl}
          thumbnailUrl={thumbnailUrl}
          title={title}
        />
      )}
    </>
  )
}

MediaThumbnailButton.propTypes = {
  description: PropTypes.string,
  entryId: PropTypes.string,
  fileType: PropTypes.string.isRequired,
  fileUrl: PropTypes.string.isRequired,
  locale: PropTypes.string,
  thumbnailUrl: PropTypes.string,
  time: PropTypes.string,
  title: PropTypes.string,
  tracks: PropTypes.array,
  transcriptExtended: PropTypes.string,
  transcriptSource: PropTypes.string,
  videoUrlExtended: PropTypes.string,
}

MediaThumbnailButton.defaultProps = {
  time: '',
  title: '',
}

export default MediaThumbnailButton
