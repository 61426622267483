import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledContainer,
  DottedLineWrapper,
  TextRow,
  DateAndTitle,
  DateAndTitleWrapper,
  Title,
  TrainingStartIcon,
  IconWrapper,
} from './component.styles'
import { trainingText, programText, yearEndText } from './data'
import { IconClap, IconFinish, IconDots } from 'secondstep-components'
import { dateToMMMMddyyyy } from 'utils/timeFormattingHelpers'

const PacingSchedule = ({
  trainingStartDate,
  programStartDate,
  schoolYearEndDate,
}) => {
  const formattedTrainingStartDate = dateToMMMMddyyyy(trainingStartDate)
  const formattedProgramStartDate = dateToMMMMddyyyy(programStartDate)
  const formattedSchoolYearEndDate = dateToMMMMddyyyy(schoolYearEndDate)

  return (
    <StyledContainer>
      <TextRow>
        <DateAndTitle>
          <IconWrapper>
            <TrainingStartIcon />
          </IconWrapper>
          <DateAndTitleWrapper>
            <Title>{trainingText}</Title>
            <span>{formattedTrainingStartDate}</span>
          </DateAndTitleWrapper>
        </DateAndTitle>
        <DottedLineWrapper>
          <IconDots />
        </DottedLineWrapper>
        <DateAndTitle>
          <IconWrapper>
            <IconClap />
          </IconWrapper>
          <DateAndTitleWrapper>
            <Title>{programText}</Title>
            <span>{formattedProgramStartDate}</span>
          </DateAndTitleWrapper>
        </DateAndTitle>
        <DottedLineWrapper>
          <IconDots />
        </DottedLineWrapper>
        <DateAndTitle>
          <IconWrapper>
            <IconFinish />
          </IconWrapper>
          <DateAndTitleWrapper>
            <Title>{yearEndText}</Title>
            <span>{formattedSchoolYearEndDate}</span>
          </DateAndTitleWrapper>
        </DateAndTitle>
      </TextRow>
    </StyledContainer>
  )
}

PacingSchedule.propTypes = {
  programStartDate: PropTypes.object,
  schoolYearEndDate: PropTypes.object,
  trainingStartDate: PropTypes.object,
}

export default PacingSchedule
