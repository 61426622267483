import React from 'react'
import PropTypes from 'prop-types'

import GradeSummary from 'components/Teach/GradeSummary'

import GradeUnitLessons from './GradeUnitLessons'

const CourseDetail = ({
  displayTitle,
  description,
  lessons = [],
  coursePdfs = [],
  routeToPage,
  activities = [],
}) => {
  return (
    <>
      <GradeSummary
        activities={activities}
        description={description}
        materials={coursePdfs}
        title={displayTitle}
      />
      <GradeUnitLessons lessons={lessons} routeToPage={routeToPage} />
    </>
  )
}

CourseDetail.propTypes = {
  activities: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      displayTitle: PropTypes.string,
      url: PropTypes.string,
    }),
  ),
  coursePdfs: PropTypes.arrayOf(
    PropTypes.shape({
      internalTitle: PropTypes.string,
      displayTitle: PropTypes.string,
      file: PropTypes.shape({
        title: PropTypes.string,
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
    }),
  ),
  description: PropTypes.string,
  displayTitle: PropTypes.string,
  lessons: PropTypes.arrayOf(
    PropTypes.shape({
      lessonTitle: PropTypes.string,
      lessonInfos: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          teachUrl: PropTypes.string,
          slmUrl: PropTypes.string,
        }),
      ),
    }),
  ),
  routeToPage: PropTypes.func,
}

export default CourseDetail
