import styledNative from 'styled-components/native'
import { themeGet } from 'styled-system'
import styled, { withTheme } from 'styled-components'
import { HeaderTwo } from 'secondstep-components'

export const ContentCardListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  padding-bottom: 1.25rem;
  background-color: ${themeGet(
    'colors.backgrounds.body',
    'colors.contentBackgroundGray',
  )};

  [class*='componentstyles__Wrapper'] {
    margin-bottom: 26px;
  }
`

export const ContentSetWrapper = styled.div`
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
  padding: 0 1.25rem;
  box-sizing: border-box;
  z-index: 1;
  .styled-wrapper {
    background-color: ${themeGet('colors.contentSetWrapper.background.color')};
    border-radius: ${themeGet('contentSetWrapper.border.radius')};
    img {
      border-radius: 50%;
    }
  }
  .media-grouping-card {
    h2 {
      color: ${themeGet('colors.heading.level.1')};
    }
    h6 {
      font-family: ${themeGet('fonts.family.level.6')};
    }
  }
  [role='radio'][aria-checked='false']:hover {
    color: ${themeGet('colors.labels.primaryHoverBackground')};
  }
`

export const ContentWrapper = withTheme(styledNative.View.attrs(props => {
  const borderColor = themeGet('colors.borders.primary')(props)
  const border = borderColor ? `2px solid ${borderColor}` : 'none'

  return { border }
})`
  box-shadow: 0px 0px 10px ${themeGet('colors.boxShadow')};
  border-radius: 12px;
  padding: 1.5rem 1.25rem 1.25rem;
  margin-top: 1.25rem;
  border: ${({ border }) => border};
  background-color: ${themeGet('colors.white')};
`)

export const ContentHeaderWrapper = withTheme(styledNative.View`
  justify-content: flex-end;
  flex-direction: row;
  margin-bottom: 0.625rem;
`)

export const ContentHeader = styled(HeaderTwo)``

export const ContentCount = styledNative.Text`
  margin-bottom: 0.813rem;
  align-self: flex-end;
`
