import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { TextLink, BodyCopy } from 'secondstep-components'
import { SS_LOGO_LABEL } from './constants'

export const Container = styled(Box).attrs(({ dataTestId, isPaleGray }) => {
  const background = isPaleGray
    ? themeGet('colors.paleGray')
    : themeGet('colors.background', 'none')

  return {
    as: 'footer',
    animate: 'fadeIn',
    background,
    'data-testid': dataTestId,
  }
})`
  display: flex;
  box-sizing: border-box;
  background: ${({ background }) => background};
  z-index: 1000;
  bottom: 0;
  width: 100%;
  height: 6.5rem;

  ${themeGet('breakpoints.tablet')} {
    height: 7.5rem;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    height: auto;
    padding-top: 1.125rem;
    position: unset;
    text-align: center;
  }
`

export const ContentContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  > div {
    display: flex;
    width: 100%;
    max-width: ${props => (props.isDashboard ? '60.875rem' : '1366px')};

    ${themeGet('breakpoints.mobileLarge')} {
      flex-direction: column-reverse;
      align-items: center;
    }
  }
`

export const CopyrightSymbol = styled.p`
  font-size: 0.75rem;
  margin-top: -0.063rem;
  padding-right: 0.125rem;
`

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: ${themeGet('spacing.turquoise20_1_25rem')};
  box-sizing: border-box;
  padding-bottom: ${({ isDashboard }) => (isDashboard ? '1.5rem' : '0.8rem')};

  ${themeGet('breakpoints.tablet')} {
    padding-top: 0.875rem;
    padding-bottom: ${themeGet('spacing.red40_2_5rem')};
    align-items: self-start;
  }

  ${themeGet('breakpoints.xlarge')} {
    align-items: left;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    align-items: center;
  }

  ${themeGet('breakpoints.mobile')} {
    width: 100%;
  }
`

export const FooterLogoContainer = styled(Box).attrs(props => {
  const background = props.isAdmin ? themeGet('colors.paleGray')(props) : 'none'

  return { background }
})`
  display: flex;
  flex-direction: row;
  justify-content: end;
  padding-top: ${themeGet('spacing.pink8_0_5rem')};
  padding-right: ${themeGet('spacing.turquoise20_1_25rem')};
  background-color: ${({ background }) => background};

  ${themeGet('breakpoints.tablet')} {
    align-items: center;
    background: none;
    padding-top: 0;
    padding-bottom: ${themeGet('spacing.blue16_1rem')};
  }

  ${themeGet('breakpoints.mobileLarge')} {
    padding-right: 0;
  }

  ${themeGet('breakpoints.mobile')} {
    width: 100%;
    justify-content: center;
  }
`

export const TextContent = styled(TextLink)`
  font-family: Molde-Regular;
  font-size: 0.75rem;
  line-height: 1.125rem;
  text-decoration: none;
  &:active,
  &:visited,
  &:link {
    color: ${themeGet('colors.darkerGray')};
  }
`

export const BodyText = styled(BodyCopy)`
  font-family: Molde-Regular;
  font-size: 0.75rem;
  line-height: 1.125rem;
  color: ${themeGet('colors.darkerGray')};

  ${themeGet('breakpoints.xlarge')} {
    padding-right: 0.25rem;
  }

  ${themeGet('breakpoints.tablet')} {
    padding-right: 0;
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
`

export const LogoContent = styled(Row)`
  height: fit-content;
`

export const Divider = styled.p`
  font-family: Molde-Regular;
  font-size: 0.75rem;
  line-height: 1.125rem;
  margin: 0rem 0.25rem;
`

export const Logo = styled.img.attrs(({ label }) => ({
  logoHeight: label === SS_LOGO_LABEL ? '3rem' : '2.9375rem',
  logoWidth: label === SS_LOGO_LABEL ? '5.1875rem' : '8.6875rem',
}))`
  height: ${({ logoHeight }) => logoHeight};
  width: ${({ logoWidth }) => logoWidth};
  max-width: 1366px;
`

export const LogoDivider = styled.div`
  width: 1.25rem;
`

// Styled Component only used to fill white space to match dashboard sidebar
export const FooterDashboardRightBox = styled.div`
  background: ${themeGet('colors.white')};
  width: 10%;
  min-width: 18.375rem;

  ${themeGet('breakpoints.mobileLarge')} {
    display: none;
  }
`

export const TextWrapper = styled.div`
  display: flex;

  ${themeGet('breakpoints.belowTablet')} {
    flex-direction: column;
  }
`
