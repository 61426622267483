import merge from 'lodash/merge'
import isEmpty from 'lodash/isEmpty'

export const checkForActiveTraining = (
  productName,
  lmsTrainings,
  seedIds = {},
) => {
  const urlLookup = productName === 'k5kit' ? 'ssp' : productName

  const hasTraining = seedIds[urlLookup]?.find(item =>
    lmsTrainings.find(training => training.seed_id === item),
  )
  return !!hasTraining
}

export const mergeModules = (trainingSets = [], lmsInstance) => {
  if (isEmpty(trainingSets)) return []
  const mergedTrainingSets = trainingSets.map(set => {
    const mergedTrainingModules = set.trainingModules.map(module => {
      const matchingModule = lmsInstance.children.find(
        lmsChild => lmsChild.contentfulID === module.id,
      )
      return merge(module, matchingModule)
    })
    set.lmsAndContentfulModules = mergedTrainingModules
    return set
  })
  return mergedTrainingSets
}

export const getActiveTrainingData = (lmsTrainings, trainingPaths) => {
  let lmsTraining, matchingPath, trainingPath, trainingSets
  let firstTrainingSet = []
  lmsTrainings.forEach(lmsItem => {
    matchingPath = trainingPaths.find(path => lmsItem.contentfulID === path.id)
    if (matchingPath) {
      lmsTraining = lmsItem
      firstTrainingSet = matchingPath.trainingSets[0]
      trainingSets = matchingPath.trainingSets
      trainingPath = matchingPath
    }
  })
  return {
    firstTrainingSet,
    lmsTraining,
    trainingPath,
    trainingSets,
  }
}
