import { put } from 'redux-saga/effects'

import { NAVIGATION_EVENT } from '../../../navigation/store/types'
import ContenfulTypes from 'layers/content/store/types'
import ContentfulActions from 'layers/content/store/actions'
import { ROOT_ENTRY_ID } from '../../../content/Hocs/RootContent/data'
import { getRootEntry } from '../../../content/utils'

import SagaLifeCycle from '../../../saga/lifecycle'

import { getEntryForLearnLayer } from '../utils/api'
import { isAProductPath } from '../utils/verify'
import { NODE_START } from '../nodes/start/constants'

import { findRawProductByPath } from './selector'

const { CONTENTFUL_SUCCESS } = ContenfulTypes

export function* handleNamespaceUpdates(productEntry, productSectionEntry) {
  const { id: productId } = productEntry || {}
  const { id: productSectionId } = productSectionEntry || {}

  if (!productId) {
    return
  }

  yield put(ContentfulActions.setContentNamespace('product', productId))
  yield put(ContentfulActions.setContentNamespace(NODE_START, productId))

  if (productSectionId) {
    yield put(
      ContentfulActions.setContentNamespace('productSection', productSectionId),
    )
  }
}

export function* handleEntryDataRetrieval(productEntry, productSectionEntry) {
  const { id: productId } = productEntry || {}
  const { id: productSectionId } = productSectionEntry || {}

  if (!productId) {
    return
  }

  yield put(getEntryForLearnLayer({ entryId: productId, include: 3 }))

  if (productSectionId) {
    yield put(getEntryForLearnLayer({ entryId: productSectionId, include: 2 }))
  }
}

export default class ContentProduct extends SagaLifeCycle {
  *process(data) {
    const { CONTENTFUL_SUCCESS: entry, NAVIGATION_EVENT: navigation } = data
    const { value: pathname } = navigation
    const isProductPath = isAProductPath(pathname)

    if (!isProductPath) {
      return
    }

    const { productSectionEntry, productEntry } =
      findRawProductByPath(entry, pathname) || {}

    if (!productEntry) {
      return
    }

    yield handleNamespaceUpdates(productEntry, productSectionEntry)
    yield handleEntryDataRetrieval(productEntry, productSectionEntry)
  }

  subscribeEvents() {
    return [CONTENTFUL_SUCCESS, NAVIGATION_EVENT]
  }

  filter_NAVIGATION_EVENT(action) {
    const { payload: actionPayload } = action
    const { silent, value: pathname } = actionPayload || {}
    const isProductPath = isAProductPath(pathname)

    if (!pathname || !silent || !isProductPath) {
      this.earlyExit('filter_NAVIGATION_EVENT missing action')
    }
  }

  filter_CONTENTFUL_SUCCESS(action) {
    const { payload: entry, meta } = action || {}
    const { entryId: actionEntryId } = meta || {}
    if (
      !entry ||
      !actionEntryId ||
      // check that our action entry id matches the root id of the app
      actionEntryId !== ROOT_ENTRY_ID
    ) {
      this.earlyExit('filter_CONTENT_SUCCESS missing action')
    }
  }

  select_NAVIGATION_EVENT(state) {
    const { router } = state || {}
    const { location } = router || {}
    const { pathname } = location || {}
    return { value: pathname }
  }

  select_CONTENTFUL_SUCCESS(state) {
    const rootEntry = getRootEntry(state)
    const { payload } = rootEntry || {}
    return payload
  }
}
