import React from 'react'
import PropTypes from 'prop-types'
import { BodyCopy } from 'secondstep-components'

const ContentText = ({ content }) => <BodyCopy>{content}</BodyCopy>

ContentText.propTypes = {
  content: PropTypes.string,
}

export default ContentText
