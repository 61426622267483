import { actions } from './reducer'
import api from '../../lib/api/routeTreeApi'
import { formatError } from 'utils/formatError'

const fetchEntryIdFromSlug = slug => {
  return async dispatch => {
    dispatch(actions.fetchingEntryIdFromSlug())
    try {
      const { data } = await api.getResourceEntryIdBySlug(slug)
      dispatch(
        actions.fetchEntryIdFromSlugSuccess({
          slug,
          data,
        }),
      )
    } catch (error) {
      dispatch(actions.fetchEntryIdFromSlugError({ error: formatError(error) }))
    }
  }
}

export default {
  fetchEntryIdFromSlug,
}
