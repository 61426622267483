import styled from 'styled-components'
import { BodyCopy } from 'secondstep-components'

export const Container = styled.div`
  flex-direction: column;
  display: flex;

  & + & {
    margin-top: 1.75rem;
  }
`

export const Title = styled(BodyCopy)`
  font-weight: bold;
  font-size: 1.125rem;
`

export const Credit = styled(BodyCopy)`
  margin: 0.75rem 0 0 0;
`

export const Description = styled(BodyCopy)`
  margin: 1.25rem 0 0 0;
`
