import actions from './actions'
import api from 'lib/api/secondStepApi'
import { formatError } from 'utils/formatError'
import licenseTypes from '../licenseManager/types'
import {
  getUserContextProgramPreferences,
  setSitePreferencesCalculatedFields,
} from 'utils/siteData'
import { types as userContextTypes } from 'auth/stores/userContext'

export const SHOULD_SHOW_IN_MODAL = true

const updateSitePreferences = (siteId, sitePreferences) => {
  return async (dispatch, getState) => {
    dispatch(actions.savingSitePreferences(siteId))
    try {
      const result = await api.updateSitePreferences(siteId, sitePreferences)
      const siteData = result.data
      dispatch(actions.savingSitePreferencesSuccess(siteId))

      // we only expect 1 site preference set being changed at once
      const sitePreferenceProgram = Object.keys(sitePreferences)[0]
      const userProgramPreferencesAndCourses = getUserContextProgramPreferences(
        siteId,
        sitePreferenceProgram,
        getState()?.userContextManager?.sites,
      )

      const contextHighSchoolAreUsersAdded =
        userProgramPreferencesAndCourses?.preferences?.areUsersAdded || false
      if (sitePreferences.highSchool) {
        sitePreferences.highSchool.areUsersAdded = contextHighSchoolAreUsersAdded
      }
      const sites = getState()?.licenseManager?.sites
      const site = sites?.find(sites => sites.id === siteId) || {}
      const sitePrefs = site?.sitePreferences || {}
      const sitePrefProg = sitePrefs[sitePreferenceProgram] || {}
      // TODO: need to revisit this calculation when we align all preferences into either context or site preferences
      const preferencesWithCalaculatedFields = setSitePreferencesCalculatedFields(
        {
          ...sitePrefs,
          [sitePreferenceProgram]: {
            ...sitePrefProg,
            ...sitePreferences[sitePreferenceProgram],
          },
        },
        site,
      )

      dispatch({
        type: licenseTypes.UPDATE_SITE,
        payload: { siteId, sitePreferences: preferencesWithCalaculatedFields },
      })

      const calculatedProgramPreferences =
        preferencesWithCalaculatedFields[sitePreferenceProgram]
      const userSiteContext =
        getState()?.userContextManager?.sites?.find(
          site => site.siteId === siteId,
        ) || {}

      const programContextPreferences =
        userSiteContext?.programs?.[sitePreferenceProgram]?.preferences || {}
      dispatch({
        type: userContextTypes.UPDATE_SITE_PROGRAM_PREFERENCES_TYPE,
        payload: {
          siteId,
          programName: sitePreferenceProgram,
          preferences: {
            isImplementationPlanComplete:
              calculatedProgramPreferences.isImplementationPlanComplete,
            isSetup:
              programContextPreferences.isSetup ||
              (programContextPreferences.isPacingComplete &&
                programContextPreferences.areUsersAdded) ||
              false,
          },
        },
      })

      const { pacing, schoolYearEndDate } = siteData || {}
      let pacingDatesForProgram = pacing?.[sitePreferenceProgram] || null
      if (pacingDatesForProgram === null) {
        // temp check to try lowercase program name
        pacingDatesForProgram =
          pacing?.[sitePreferenceProgram.toLowerCase()] || null
      }
      dispatch({
        type: licenseTypes.UPDATE_SITE_PACING_DATES,
        payload: {
          siteId,
          pacingDates: pacingDatesForProgram,
          programName: sitePreferenceProgram,
          schoolYearEndDateString: schoolYearEndDate,
        },
      })
    } catch (error) {
      await dispatch(
        actions.savingSitePreferencesError({
          error: formatError(error, SHOULD_SHOW_IN_MODAL),
        }),
      )
      throw error
    }
  }
}

const updateSitePacingDates = (siteId, programName, pacingUpdate) => {
  return async (dispatch, getState) => {
    dispatch(actions.savingSitePacingDates())
    try {
      const response = await api.updateSitePacingDates(
        siteId,
        programName,
        pacingUpdate,
      )
      const { data: pacingDates } = response
      dispatch(actions.savingSitePacingDatesSuccess(pacingDates, programName))
      dispatch({
        type: licenseTypes.UPDATE_SITE_PACING_DATES,
        payload: {
          siteId,
          pacingDates,
          programName,
          schoolYearEndDateString: pacingUpdate.schoolYearEndDate.toISOString(),
        },
      })

      // currently this code is assuming a highschool context
      const userSiteContext =
        getState()?.userContextManager?.sites?.find(
          site => site.siteId === siteId,
        ) || {}

      const programContextPreferences =
        userSiteContext?.programs?.[programName]?.preferences || {}
      const newCalculatedPreferences = {
        isPacingComplete: true,
        isSetup:
          programContextPreferences.isSetup ||
          (programContextPreferences.isImplementationPlanComplete &&
            programContextPreferences.areUsersAdded),
      }
      dispatch({
        type: userContextTypes.UPDATE_SITE_PROGRAM_PREFERENCES_TYPE,
        payload: {
          siteId,
          programName,
          preferences: newCalculatedPreferences,
        },
      })

      const sites = getState()?.licenseManager?.sites
      const site = sites?.find(sites => sites.id === siteId) || {}
      const sitePrefs = site?.sitePreferences || {}
      // TODO: need to revisit this calculation when we align all preferences into either context or site preferences
      const preferencesWithCalaculatedFields = setSitePreferencesCalculatedFields(
        {
          ...sitePrefs,
          [programName]: {
            ...sitePrefs[programName],
            ...newCalculatedPreferences,
          },
        },
        site,
      )
      dispatch({
        type: licenseTypes.UPDATE_SITE,
        payload: { siteId, sitePreferences: preferencesWithCalaculatedFields },
      })
    } catch (error) {
      dispatch(
        actions.savingSitePacingDatesError({
          error: formatError(error, SHOULD_SHOW_IN_MODAL),
        }),
      )
      throw error
    }
  }
}

const updateMSPOptIn = mspOptInArr => {
  return async dispatch => {
    dispatch(actions.updateMspOptInPrefences())
    try {
      const response = await api.updateMspOptInSettings(mspOptInArr)
      const data = response.data
      dispatch(actions.updateMspOptInPrefencesSuccess(data))
      dispatch({
        type: licenseTypes.UPDATE_SITES_MSP_OPT_IN,
        payload: mspOptInArr,
      })
      mspOptInArr.forEach(site => {
        dispatch({
          type: userContextTypes.UPDATE_SITE_PROGRAM_PREFERENCES_TYPE,
          payload: {
            siteId: site.siteId,
            programName: 'mspDigital',
            preferences: {
              preferences: {
                bullyingHarassmentUnitEnabled:
                  site.sitePreferences.mspDigital.bullyingHarassmentUnitEnabled,
              },
            },
          },
        })
      })
    } catch (error) {
      await dispatch(
        actions.updateMspOptInPrefencesError({
          error: formatError(error, SHOULD_SHOW_IN_MODAL),
        }),
      )
      throw error
    }
  }
}

const updateUserProgramPreferences = (siteId, sitePreferences) => {
  return async dispatch => {
    dispatch(actions.updatingUserProgramPreferences())
    try {
      const response = await api.updateUserProgramPreferences(
        siteId,
        sitePreferences,
      )
      const data = response.data
      const sitePreferencesUpdated = data.sitePreferences || {}

      dispatch({
        type: licenseTypes.UPDATE_SITE,
        payload: { siteId, sitePreferences: sitePreferencesUpdated },
      })
      dispatch(actions.updatingUserProgramPreferencesSuccess(data))
    } catch (error) {
      await dispatch(actions.updatingUserProgramPreferencesError(error))
      throw error
    }
  }
}

export default {
  updateMSPOptIn,
  updateSitePreferences,
  updateSitePacingDates,
  updateUserProgramPreferences,
}
