import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import ProfileReducer from 'auth/stores/profile/reducers.js'
import AccessReducer from 'auth/stores/access'
import UserContextReducer from 'auth/stores/userContext'
import createPersistedReducer from 'utils/createPersistedReducer.js'

import ContentfulReducer from 'layers/content/store/reducer'

import docServiceManager from './docServiceManager'
import licenseManager from './licenseManager'
import lmsManager from './lmsManager'
import loadingManager from './loadingManager'
import productManager from './productManager'
import reportsManager from './reportsManager'
import routeTreeManager from './routeTreeManager'
import siteManager from './siteManager'
import termsManager from './termsManager'
import userSessionManager from './userSessionManager'
import contentServiceManager from './contentServiceManager'
import routeServiceManager from './routeServiceManager'
import courseManager from './courseManager'

const rootReducer = history =>
  combineReducers({
    [ContentfulReducer.ROOT_NAMESPACE]: ContentfulReducer.entryReducer,
    docServiceManager,
    licenseManager: createPersistedReducer(
      'licenseManager',
      licenseManager,
      [],
      [
        'adminNotificationsExist',
        'expiredLicenses',
        'nonAdminNotificationsExist',
      ],
    ),
    lmsManager: createPersistedReducer('lmsManager', lmsManager, [
      'error',
      'lessonReports',
      'historicalReports',
    ]),
    loadingManager,
    productManager,
    reportsManager,
    router: connectRouter(history),
    routeTreeManager,
    siteManager: siteManager,
    termsManager: termsManager,
    userAccessManager: AccessReducer,
    userContextManager: createPersistedReducer(
      'cfc_hs_active_site',
      UserContextReducer,
      [],
      ['hsActiveSiteId'],
    ),
    userProfileManager: ProfileReducer,
    userSessionManager: createPersistedReducer(
      'userSessionManager',
      userSessionManager,
      [],
      ['adminDashboard', 'programDashboard'],
    ),
    contentServiceManager,
    routeServiceManager,
    courseManager,
  })

export default rootReducer
