import React from 'react'
import {
  ThumbnailWrapper,
  StyledClosableModal,
  ImageCalloutButton,
} from './component.styles'
import PropTypes from 'prop-types'

const WithImageModal = (ThumbnailImage, Image) => {
  const InnerComponent = props => {
    const { title, openModal, onClose, dataTestId, show } = props
    return (
      <ImageCalloutButton
        data-testid={dataTestId}
        onClick={openModal && openModal}
        onKeyPress={openModal && openModal}
        role="button"
      >
        <ThumbnailWrapper>{ThumbnailImage}</ThumbnailWrapper>
        <StyledClosableModal
          className="full-image-modal"
          modalTitle={(!!title && title) || ''}
          onClose={!!onClose && onClose}
          show={show}
        >
          <Image />
        </StyledClosableModal>
      </ImageCalloutButton>
    )
  }

  InnerComponent.propTypes = {
    children: PropTypes.any,
    dataTestId: PropTypes.string,
    imageAlt: PropTypes.string,
    imageSource: PropTypes.string,
    isZoomable: PropTypes.bool,
    onClose: PropTypes.func,
    openModal: PropTypes.func,
    show: PropTypes.bool,
    thumbnailAlt: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    title: PropTypes.string,
  }
  return InnerComponent
}

WithImageModal.propTypes = {
  Image: PropTypes.node,
  ThumbnailImage: PropTypes.node,
}

export default WithImageModal
