import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const ContainerSection = styled.div.attrs(({ dataTestId }) => {
  return {
    'data-testid': dataTestId,
  }
})`
  background-color: ${themeGet('colors.lighterGray')};
  padding: ${themeGet('spacing.red40_2_5rem')}
    ${themeGet('spacing.turquoise20_1_25rem' + 'spacing.amber28_1_75rem')};
  margin-bottom: ${themeGet('spacing.amber28_1_75rem')};

  ${themeGet('breakpoints.mobile')}, ${themeGet('breakpoints.mobileLarge')} {
    padding: ${themeGet('spacing.amber28_1_75rem')}
      ${themeGet('spacing.turquoise20_1_25rem')};
  }
`

export const MainViewColumn = styled.div.attrs(({ dataTestId }) => {
  return {
    'data-testid': dataTestId,
  }
})`
  width: 50%;

  ${themeGet('breakpoints.mobileLarge')} {
    width: 100%;

    ul {
      padding-left: ${themeGet('spacing.blue16_1rem')};
    }
  }
`
