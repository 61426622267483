import types from './types'

const currentProductMap = productMap => ({
  type: types.GET_CURRENT_PRODUCT_MAP,
  productMap,
})

export default {
  currentProductMap,
}
