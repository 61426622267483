import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { HeaderThree, AlphabetIcon } from 'secondstep-components'
import QuickLinksCard from '../QuickLinksCard'

const breakpoints = {
  belowTablet: '@media (max-width: 750px)',
}

export const StyledQuickLinksCard = styled(QuickLinksCard)``

export const ProfileCardBox = styled(Box).attrs({
  'data-testid': 'profile-card',
})`
  align-items: center;
  ${AlphabetIcon} {
    min-height: 2.813rem;
    min-width: 2.813rem;
    align-self: center;
  }

  ${themeGet('breakpoints.xlarge')} {
    margin-left: auto;
    margin-right: auto;
    width: 16.5rem;
  }

  ${breakpoints.belowTablet} {
    flex-direction: row;
    svg {
      width: 2.25rem;
      height: 2.25rem;
      margin: 0 1rem 0 0;
    }

    ${StyledQuickLinksCard} {
      display: none;
    }
  }
`
export const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 0.625rem;
  justify-content: center;

  ${HeaderThree} {
    font-family: ${themeGet('font.sharpSlab.semibold')};
    font-size: 1.375rem;
    line-height: 1.625rem;
    text-align: left;
    margin-bottom: 0;
  }
`
export const UserDetails = styled(Box)`
  flex-direction: row;
  align-self: flex-start;
  align-items: center;
  justify-content: flex-start;
  word-break: break-word;
  width: 100%;
  ${breakpoints.belowTablet} {
    ${HeaderThree} {
      margin-bottom: 0;
      text-align: left;
    }

    span {
      text-align: left;
    }
  }
`
