import React from 'react'
import PropTypes from 'prop-types'
import StreamingLessonMediaPage from './component'
import ContentParentNodeGetter from 'layers/content/Hocs/ContentParentNodeGetter'
import ContentfulParser from 'utils/contentfulParser/contents'

const StreamingLessonMediaPageContainer = ({
  entry,
  parentEntry,
  getEntryWithNewLocale,
  getEntryWithNewLocaleCurrentNode,
}) => {
  const mapper = entry => {
    const { content = [] } = entry || {}
    const sections = content.map(contentItem => {
      const { content = [] } = contentItem || {}
      const mediaList = content.map(media => {
        const mediaData = ContentfulParser.parseMedia(media)
        const {
          id,
          time,
          title,
          description,
          fileUrl,
          fileType,
          thumbnailUrl,
          tracks,
        } = mediaData || {}

        return {
          id,
          time,
          title,
          description: description || title,
          fileUrl,
          fileType,
          thumbnailUrl,
          tracks,
        }
      })

      return {
        id: contentItem.id,
        mediaList: mediaList,
        title: contentItem.displayTitle,
      }
    })

    return { ...entry, sections: sections }
  }

  const parsedEntry = mapper(entry) || {}

  const handleGetEntryWithNewLocale = locale => {
    if (getEntryWithNewLocaleCurrentNode) {
      getEntryWithNewLocaleCurrentNode(locale)
    }

    if (getEntryWithNewLocale) {
      getEntryWithNewLocale(locale)
    }
  }

  return (
    <StreamingLessonMediaPage
      entry={parsedEntry}
      getEntryWithNewLocale={handleGetEntryWithNewLocale}
      parentEntry={parentEntry}
    />
  )
}

export default ContentParentNodeGetter(StreamingLessonMediaPageContainer)

StreamingLessonMediaPageContainer.propTypes = {
  entry: PropTypes.object,
  getEntryWithNewLocale: PropTypes.func,
  getEntryWithNewLocaleCurrentNode: PropTypes.func,
  parentEntry: PropTypes.object,
}
