import { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AdminSitesSummary from './component'
import withApiError from 'layers/errorHandling/apiError/component'
import { ERROR_TYPE_REPORTING } from 'layers/errorHandling/apiError'
import { operations as reportsOperations } from 'store/reportsManager'

export const AdminSitesSummaryContainer = ({
  classes,
  fetchAdminK8SitesSummary,
  isFetchingSummary,
  totalSitesCount,
  withoutClassesTotalCount,
  users,
  reportingError,
}) => {
  useEffect(() => {
    if (!isFetchingSummary && !users && !reportingError) {
      fetchAdminK8SitesSummary()
    }
  }, [isFetchingSummary, users])

  if (
    isFetchingSummary ||
    !classes ||
    Object.keys(classes).length === 0 ||
    totalSitesCount <= 1
  ) {
    return null
  }

  return (
    <AdminSitesSummary
      classes={classes}
      loading={isFetchingSummary}
      totalSitesCount={totalSitesCount}
      usersData={users}
      withoutClassesTotalCount={withoutClassesTotalCount}
    />
  )
}

AdminSitesSummaryContainer.propTypes = {
  classes: PropTypes.object,
  fetchAdminK8SitesSummary: PropTypes.func,
  isFetchingSummary: PropTypes.bool,
  reportingError: PropTypes.bool,
  totalSitesCount: PropTypes.number,
  users: PropTypes.object,
  withoutClassesTotalCount: PropTypes.number,
}

const mapStateToProps = state => {
  const { reportsManager } = state
  const {
    admin: {
      k8SitesSummary: {
        withoutClassesTotalCount,
        users,
        classes = {},
        totalSitesCount = 0,
      },
    },
    error,
    isFetchingAdminK8SitesSummary,
  } = reportsManager || {}
  const reportingError = !!error

  return {
    totalSitesCount,
    isFetchingSummary: isFetchingAdminK8SitesSummary,
    withoutClassesTotalCount,
    users,
    classes,
    reportingError,
  }
}

const mapDispatchToProps = {
  fetchAdminK8SitesSummary: reportsOperations.fetchAdminK8SitesSummary,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withApiError(AdminSitesSummaryContainer, [ERROR_TYPE_REPORTING]))
