export const INTRO_COPY = `It looks like you don’t have access to any programs yet.`
export const EDUCATORS_URL =
  'https://support.secondstep.org/hc/en-us/articles/360017505433-How-do-I-activate-my-kit-or-digital-product-'
export const FAMILIES_URL = 'https://secondstep.org/family-support'
export const SUPPORT_LINK = `Having trouble? Visit our support page for [educators](${EDUCATORS_URL}) and [families](${FAMILIES_URL}).`

export const KIT_PRODUCT_LIST = `
Second Step® Early Learning
<br>
Second Step® Elementary Classroom Kits
<br>
Second Step® Child Protection Unit
<br>
Second Step® Bullying Prevention Unit
<br>
Second Step® Out-of-School-Time
<br>
Second Step® Middle School 2008
<br>
Principal Toolkit
`
export const ONLINE_PROGRAM_LIST = `
Second Step® Digital Elementary
<br>
Second Step® Middle School
<br>
Second Step® SEL for Adults
<br>
Child Protection Unit Training
<br>
Bullying Prevention Unit Training
`
export const FAMILY_ACCESS_LIST = `
View Second Step® lessons and family
<br>
resources in the elementary and middle 
<br> 
school programs.`

export const KIT_HELP = `You can find your Activation Key on the first page of your Second Step binder, notebook, or family letter.`
export const ONLINE_PROGRAMS_HELP = `The license administrator manages your school's online licenses and can add and remove staff access to the program license.`
export const FAMILY_ACCESS_HELP = `
You’ll receive a confidential family
<br> 
Activation Key from your child’s school district.`
