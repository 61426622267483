import React from 'react'
import PropTypes from 'prop-types'
import { ScheduleWrapper, Calendar, ScheduleCopy } from './component.styles'
import { ModalHeader, PacingContinue } from '../component.styles'

const Schedule = ({ handleContinueButtonClick, data }) => {
  const { bodyCopy, header, nextLabel } = data || {}
  return (
    <ScheduleWrapper>
      <Calendar />
      <ModalHeader>{header}</ModalHeader>
      <ScheduleCopy>{bodyCopy}</ScheduleCopy>
      <PacingContinue onClick={() => handleContinueButtonClick()}>
        {nextLabel}
      </PacingContinue>
    </ScheduleWrapper>
  )
}

Schedule.propTypes = {
  data: PropTypes.object,
  handleContinueButtonClick: PropTypes.func,
}

export default Schedule
