import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import AdminBHULessonProgressCard from 'components/AdminBHULessonProgressCard'
import AdminGradeList from 'components/AdminGradeList'
import AdminLessonProgressCard from 'components/AdminLessonProgressCard'
import AdminLessonProgressTabs from 'components/AdminLessonProgressTabs'
import AdminNoClasses from 'components/AdminNoClasses'
import AdminSiteHeader from 'components/AdminSiteHeader'
import { SpinnerAnimatedIcon } from 'secondstep-components'
import {
  BodyWrapper,
  LoadingWrapper,
  SingleSiteWrapper,
  StyledHairline,
} from './component.styles'

const AdminSingleSiteView = ({
  isFetchingLessons,
  sites,
  sitesPreferences,
  summary,
}) => {
  if (isFetchingLessons) {
    return (
      <SingleSiteWrapper>
        <LoadingWrapper>
          <SpinnerAnimatedIcon />
          <p>Loading...</p>
        </LoadingWrapper>
      </SingleSiteWrapper>
    )
  }
  if (Array.isArray(sites) && summary) {
    const { classesCreated, units, grades, siteId, summary: siteSummary } =
      sites?.[0] || {}
    const hasBHUEnabled =
      sitesPreferences[siteId]?.mspDigital?.bullyingHarassmentUnitEnabled ??
      false
    const hasBHUClassCreated = summary.unit5.lessonsTotal > 0
    const bhuStatus = hasBHUEnabled && hasBHUClassCreated

    return (
      <SingleSiteWrapper>
        <AdminSiteHeader
          {...summary}
          singleSite
          sitesPreferences={sitesPreferences}
        />
        {summary?.classesCreated > 0 && (
          <Fragment key="single-site-1">
            <StyledHairline />
            <AdminLessonProgressTabs bhuStatus={bhuStatus} outline={true} />
          </Fragment>
        )}
        <BodyWrapper noClasses={summary?.classesCreated === 0}>
          {summary?.classesCreated === 0 && <AdminNoClasses />}
          {summary?.classesCreated > 0 && (
            <Fragment key="single-site-2">
              <AdminLessonProgressCard
                bhuStatus={bhuStatus}
                summary={siteSummary}
                units={units}
              />
              <StyledHairline extraMargin={true} />
              <AdminGradeList grades={grades} siteId={siteId} />
            </Fragment>
          )}
        </BodyWrapper>
        {bhuStatus && (
          <AdminBHULessonProgressCard
            classesCreated={classesCreated}
            grades={grades}
            siteId={siteId}
            summary={siteSummary}
          />
        )}
      </SingleSiteWrapper>
    )
  }
  return null
}

AdminSingleSiteView.propTypes = {
  isFetchingLessons: PropTypes.bool,
  sites: PropTypes.array,
  sitesPreferences: PropTypes.object,
  summary: PropTypes.object,
}

export default AdminSingleSiteView
