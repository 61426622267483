import React, { useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import NavigationHelper from 'layers/navigation/navigationHelper'
import ToggleOption from 'components/Teach/RadioToggle/ToggleOption'

import CourseDetail from './component'
import { transformLessonData } from './data'
import { GRADE_TAB_SELECTION, DEFAULT_PAGE_TITLE } from './constants'
import {
  StyledRadioToggle,
  PageContentWrapper,
  PageWrapper,
  StyledHeaderOne,
} from './component.styles'

const CourseDetailsPage = ({
  navigationEvent,
  gradeOptions = [],
  activities,
  displayTitle,
  description,
  currentGrade,
  coursePdfs = [],
  lessons = [],
  pathname,
}) => {
  const [selection, updateSelection] = useState(currentGrade)
  const lookupChoice = gradeOptions.reduce((hash, current) => {
    hash[current.id] = current
    return hash
  }, {})
  const urlActivities = activities.filter(a => a && a.url)
  const lessonsData = transformLessonData(lessons)
  const hasGradeOptions = gradeOptions.length > 1
  const pageTitle = hasGradeOptions ? DEFAULT_PAGE_TITLE : displayTitle

  const gradeClicked = clickedId => {
    updateSelection(clickedId)
    const { [clickedId]: option } = lookupChoice || {}
    const { route } = option || {}
    route && navigationEvent(route)
  }

  const pushToPage = (url, type) => {
    if (!url) {
      return
    }

    const isDuplicatePath = NavigationHelper.isADuplicatePath(pathname, url)
    if (!isDuplicatePath) {
      const newRoute = `${pathname}/${url}`
      navigationEvent(newRoute, type)
    }
  }

  return (
    <PageWrapper>
      <PageContentWrapper>
        <StyledHeaderOne>{pageTitle}</StyledHeaderOne>
        {hasGradeOptions && (
          <StyledRadioToggle
            description={GRADE_TAB_SELECTION}
            onSelect={gradeClicked}
            options={gradeOptions}
            value={selection}
          >
            {gradeOptions.map(option => {
              const { id } = option
              return <ToggleOption key={id} option={option} />
            })}
          </StyledRadioToggle>
        )}

        <CourseDetail
          activities={urlActivities}
          coursePdfs={coursePdfs}
          description={description}
          displayTitle={displayTitle}
          lessons={lessonsData}
          navigationEvent={navigationEvent}
          routeToPage={pushToPage}
        />
      </PageContentWrapper>
    </PageWrapper>
  )
}

CourseDetailsPage.propTypes = {
  activities: PropTypes.arrayOf(PropTypes.object),
  coursePdfs: PropTypes.arrayOf(PropTypes.object),
  currentGrade: PropTypes.string,
  description: PropTypes.string,
  displayTitle: PropTypes.string,
  gradeOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      id: PropTypes.string,
      route: PropTypes.string,
    }),
  ),
  lessons: PropTypes.arrayOf(PropTypes.object),
  navigationEvent: PropTypes.func,
  pathname: PropTypes.string,
}

export default connect(
  NavigationHelper.mapStateToProps,
  NavigationHelper.mapDispatchToProps,
)(CourseDetailsPage)
