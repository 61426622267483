import React from 'react'
import PropTypes from 'prop-types'
import {
  BodyCopy,
  CsvDownloadLink,
  HeaderTwo,
  SpinnerAnimatedIconMedium,
} from 'secondstep-components'
import {
  formatHeader,
  generateDownloadLinkText,
} from 'utils/selaReportsHelpers'
import { selectors as reportsSelectors } from 'store/reportsManager'
import { STAFF_LABEL, LEADER_LABEL } from './constants'
import {
  LeaderTeamCsvDetailWrapper,
  LeaderTeamCsvDownloadWrapper,
  LeaderProgressTooltip,
  LeaderProgressWrapper,
  ModuleCsvTooltip,
  StaffCsvDetailWrapper,
  StaffCsvDownloadWrapper,
  StaffProgressTooltip,
  StaffProgressWrapper,
  TrackLabel,
  TrackProgress,
  Wrapper,
} from './component.styles'
import ReportsUnitCollapsible from 'components/ReportsUnitCollapsible'

const ReportsModuleCard = ({
  fetchReportsUserDetails,
  isFetching,
  licenseId,
  moduleId,
  moduleLabel,
  moduleTitle,
  moduleTooltipCopy,
  schoolName,
  selectedTrack,
  staffTooltipCopy,
  type,
  units,
  unitTooltipCopy,
  user_details,
  users_completed,
  users_in_progress,
  users_not_started,
  validateReportData,
}) => {
  if (isFetching && !selectedTrack) {
    validateReportData(true)
    return (
      <Wrapper>
        <SpinnerAnimatedIconMedium />
      </Wrapper>
    )
  }

  if (!isFetching && user_details?.length < 1) {
    validateReportData(false)
  }

  const { content_track } = selectedTrack || {}
  const { data, filename, headers } =
    reportsSelectors.createCsvData(
      user_details,
      moduleId,
      moduleLabel,
      null,
      null,
      schoolName,
      type,
      content_track,
    ) || {}

  const moduleHeader = formatHeader(moduleLabel, moduleTitle, 'Module')
  const isStaffTrack = content_track?.includes('staff')
  const trackLabel = `**${isStaffTrack ? STAFF_LABEL : LEADER_LABEL}**`
  const staffProgressText = `**${users_completed}**&nbsp;Completed&nbsp;• **${users_in_progress}**&nbsp;In&nbsp;Progress&nbsp;• **${users_not_started}**&nbsp;Not&nbsp;Started&nbsp;`
  const userRole = isStaffTrack ? 'Staff' : 'Leader Team'
  const { downloadLinkText, fetchLinkText } = generateDownloadLinkText(
    userRole,
    'Module',
  )
  const userDetailsExist = !!user_details?.length > 0
  const CsvWrapper = isStaffTrack
    ? userDetailsExist
      ? StaffCsvDownloadWrapper
      : StaffCsvDetailWrapper
    : userDetailsExist
    ? LeaderTeamCsvDownloadWrapper
    : LeaderTeamCsvDetailWrapper

  const ProgressWrapper = isStaffTrack
    ? StaffProgressWrapper
    : LeaderProgressWrapper
  const UserProgressTooltip = isStaffTrack
    ? StaffProgressTooltip
    : LeaderProgressTooltip

  if (!moduleHeader || !units) {
    return (
      <Wrapper>
        <SpinnerAnimatedIconMedium />
      </Wrapper>
    )
  }

  return (
    <Wrapper>
      <HeaderTwo>{moduleHeader}</HeaderTwo>
      <CsvWrapper>
        {!userDetailsExist && (
          <CsvDownloadLink
            asyncOnClick={() => fetchReportsUserDetails(licenseId)}
            data={data}
            filename={filename}
            headers={headers}
            isAsync
          >
            {fetchLinkText}
          </CsvDownloadLink>
        )}
        {userDetailsExist && (
          <CsvDownloadLink data={data} filename={filename} headers={headers}>
            {downloadLinkText}
          </CsvDownloadLink>
        )}
        <ModuleCsvTooltip content={<BodyCopy>{moduleTooltipCopy}</BodyCopy>}>
          <i className="fas fa-question-circle" />
        </ModuleCsvTooltip>
      </CsvWrapper>
      <ProgressWrapper>
        <TrackLabel>{trackLabel}</TrackLabel>
        <TrackProgress>{staffProgressText}</TrackProgress>
        <UserProgressTooltip content={<BodyCopy>{staffTooltipCopy}</BodyCopy>}>
          <i className="fas fa-question-circle" />
        </UserProgressTooltip>
      </ProgressWrapper>
      {units?.map((unit, i) => {
        const {
          contentful_id,
          display_title,
          content_children: lessons,
          type,
          total_users,
          users_completed,
          users_in_progress,
          users_not_started,
        } = unit
        return (
          <ReportsUnitCollapsible
            dataTestId={`module-unit-${i}`}
            displayTitle={display_title}
            entryId={contentful_id}
            key={`module-unit-${i}`}
            lessons={lessons}
            moduleLabel={moduleLabel}
            moduleTitle={moduleTitle}
            programTrack={content_track}
            schoolName={schoolName}
            totalUsers={total_users}
            trackLabel={trackLabel}
            type={type}
            unit={unit}
            unitTooltipCopy={unitTooltipCopy}
            usersCompleted={users_completed}
            usersInProgress={users_in_progress}
            usersNotStarted={users_not_started}
          />
        )
      })}
    </Wrapper>
  )
}

ReportsModuleCard.propTypes = {
  fetchReportsUserDetails: PropTypes.func,
  isFetching: PropTypes.bool,
  licenseId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  moduleId: PropTypes.string,
  moduleLabel: PropTypes.string,
  moduleTitle: PropTypes.string,
  moduleTooltipCopy: PropTypes.string,
  schoolName: PropTypes.string,
  selectedTrack: PropTypes.object,
  staffTooltipCopy: PropTypes.string,
  trackLabel: PropTypes.string,
  type: PropTypes.string,
  unitTooltipCopy: PropTypes.string,
  units: PropTypes.array,
  user_details: PropTypes.array,
  users_completed: PropTypes.number,
  users_in_progress: PropTypes.number,
  users_not_started: PropTypes.number,
  validateReportData: PropTypes.func,
}

export default ReportsModuleCard
