// ========
// DO NOT PLACE THIS FOLDER IN THE FLAG SYSTEM. Controls flag system setup and can lead to inconsistency
// ========
import { withAuth0 } from '@auth0/auth0-react'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useState } from 'react'
import { hot } from 'react-hot-loader/root'
import FeedbackCollection from '@cfc/feedback-collection'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { FlagsSingleton } from 'flags'
import { operations as licenseOperations } from 'store/licenseManager'
import { LD_CREDS } from 'config/env'
import LoadingParent from 'components/LoadingParent'
import SecondStepLayout from 'layouts/SecondStepLayout'
import withApiError, {
  ERROR_TYPE_ACCESS,
  ERROR_TYPE_PROFILE,
} from 'layers/errorHandling/apiError'

import GlobalStyles from 'themes/globalStyles'
import {
  handleAuthUpdate,
  handleSitesMeFetch,
  handleUpdateUserContext,
  handleUserAnalyticsSetup,
} from './handlers'
import { LOADING_PROFILE, LOADING_USER_CONTEXT } from './constants'

export const AppInitialize = ({
  access,
  auth0,
  children,
  dispatch,
  fetchUserSites,
  profile,
  sites,
  router,
}) => {
  // ============= Launch DARKLY USER CONTEXT ================
  const [isLoadingUserContext, setLoadingUserContext] = useState(null)
  const [hasUserContextBeenCalled, setContextHasBeenCalled] = useState(false)
  const hasAllInitialStateBeenLoaded =
    (!isEmpty(access) || access !== undefined) &&
    !isEmpty(profile) &&
    sites !== null &&
    !isLoadingUserContext &&
    hasUserContextBeenCalled

  useEffect(() => {
    const defaultLaunchDarklyUser = LD_CREDS.user.key
    const shouldUpdateLaunchDarklyUserContext =
      !isEmpty(profile) &&
      !hasUserContextBeenCalled &&
      FlagsSingleton.getUserContext().key === defaultLaunchDarklyUser

    if (shouldUpdateLaunchDarklyUserContext) {
      handleUpdateUserContext({
        profile,
        setContextHasBeenCalled,
        setLoadingUserContext,
      })
    }
  }, [profile, hasUserContextBeenCalled])
  // ============= END Launch DARKLY USER CONTEXT ================

  // ============= AUTH UPDATES ================
  useEffect(() => {
    handleAuthUpdate({ auth0, dispatch })
    handleSitesMeFetch(auth0, fetchUserSites)
  }, [auth0?.isAuthenticated, auth0?.isLoading, auth0?.user?.nickname])

  // ===== FEEDBACK COLLECTION USER IDENTIFCATION =======
  useEffect(() => {
    if (!isEmpty(profile)) {
      FeedbackCollection.initialize(process.env.FEEDBACK_COLLECTION_ID)
      FeedbackCollection.setUserId(profile?.id)
    }
  }, [profile])

  // ======== USER ANALYTICS SETUP =================
  useEffect(() => {
    handleUserAnalyticsSetup(profile, sites)
  }, [profile, sites])

  return (
    <>
      <GlobalStyles />
      {isLoadingUserContext && (
        <LoadingParent loadingText={LOADING_USER_CONTEXT} />
      )}
      {!hasAllInitialStateBeenLoaded && !isLoadingUserContext && (
        <LoadingParent loadingText={LOADING_PROFILE} />
      )}
      {hasAllInitialStateBeenLoaded && (
        <SecondStepLayout>{children}</SecondStepLayout>
      )}
    </>
  )
}

const mapStateToProps = ({
  licenseManager,
  router,
  userAccessManager,
  userProfileManager,
}) => {
  return {
    access: userAccessManager?.access,
    profile: userProfileManager?.profile,
    router: router,
    sites: licenseManager?.sites,
  }
}
const mapDispatchToProps = {
  fetchUserSites: licenseOperations.fetchUserSites,
}

export default hot(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(
    withAuth0(
      withApiError(AppInitialize, [ERROR_TYPE_ACCESS, ERROR_TYPE_PROFILE]),
    ),
  ),
)

AppInitialize.propTypes = {
  access: PropTypes.object,
  auth0: PropTypes.shape({
    isAuthenticated: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
    error: PropTypes.object,
    loginWithRedirect: PropTypes.func.isRequired,
    user: PropTypes.shape({
      email: PropTypes.string,
      name: PropTypes.string,
      nickname: PropTypes.string,
      sub: PropTypes.string,
    }),
  }),
  children: PropTypes.node,
  dispatch: PropTypes.func,
  fetchUserSites: PropTypes.func,
  profile: PropTypes.object,
  router: PropTypes.object,
  sites: PropTypes.array,
}
