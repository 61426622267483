import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { DownloadIcon } from 'secondstep-components'
import AdminTooltip from 'components/AdminTooltip/component'
import {
  ColumnWrapper,
  ImageLinkListWrapper,
  ImageWrapper,
  LinksWrapper,
  LinkListWrapper,
  StyledImage,
  StyledContainedButton,
  StyledLinkDropdown,
  HeaderBodyCopyWrapper,
  StyledHeaderThree,
  StyledBodyCopy,
  StyledCircleRightArrowIcon,
  StyledTextLink,
  StyledTextLinkWithFileIcon,
} from './component.styles'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { superscriptRegistered } from '../helpers'

const AdminFlexibleContentColumn = ({
  className,
  columnContent,
  breakpointTablet,
  handleNavigate,
  navigateToPage,
  toggleModal,
}) => {
  const dropdownLinkNavigation = link => {
    const { download = null, openSameTab, url: optionUrlPath } = link || {}

    if (download) {
      handleNavigate(
        download?.file?.file?.url,
        NavigationHelper.types.DOWNLOAD_LINK,
      )
    }

    const type = openSameTab
      ? NavigationHelper.types.PUSH
      : NavigationHelper.types.NEW_TAB
    handleNavigate(optionUrlPath, type)
  }

  return (
    <ColumnWrapper breakpointTablet={breakpointTablet} className={className}>
      {columnContent?.map((content, index) => {
        const contentType = content?.contentType?.id
        if (contentType === 'image') {
          return (
            <StyledImage
              alt={content?.image?.description}
              key={content?.image?.description}
              src={content?.image?.file?.url}
            />
          )
        }
        if (contentType === 'linkButton') {
          return (
            <StyledContainedButton
              key={`${contentType}${index}`}
              label={content?.buttonText}
              onClick={() =>
                handleNavigate(content?.url, NavigationHelper.types.OPEN)
              }
            />
          )
        }
        if (contentType === 'headerBodyCopy') {
          return (
            <HeaderBodyCopyWrapper key={`${contentType}${index}`}>
              <StyledHeaderThree>{content.h3}</StyledHeaderThree>
              {content.bodyCopy && (
                <StyledBodyCopy>{content.bodyCopy}</StyledBodyCopy>
              )}
            </HeaderBodyCopyWrapper>
          )
        }
        if (contentType === 'modalFlow') {
          if (content?.buttonLabel) {
            return (
              <Fragment key={`modal-flow-${contentType}${index}`}>
                <StyledHeaderThree>{content.header}</StyledHeaderThree>
                <StyledContainedButton
                  label={content.buttonLabel}
                  onClick={toggleModal}
                />
                {/* TODO: this needs to be smarter by associating the tooltip to content[property].[tooltip[].tooltipLocation] */}
                {content?.tooltips?.map(tooltip => {
                  return (
                    <AdminTooltip
                      className={className}
                      content={tooltip.text}
                      dataTestId={`tooltip-${tooltip.tooltipLocation}`}
                      key={tooltip.tooltipLocation}
                    />
                  )
                })}
              </Fragment>
            )
          }
        }
        if (contentType === 'horizontalLinks') {
          return (
            <LinksWrapper key={`links-wrapper-${index}`}>
              {content?.links?.map((link, index) => {
                const linkContentType = link?.contentType?.id
                if (linkContentType === 'url') {
                  return (
                    <StyledTextLink
                      key={`${contentType}${index}`}
                      onClick={() =>
                        handleNavigate(link?.url, NavigationHelper.types.OPEN)
                      }
                    >
                      {link?.displayTitle}
                    </StyledTextLink>
                  )
                }
                if (linkContentType === 'textLinkIcon') {
                  const fileType = link?.asset?.contentType?.id
                  return (
                    <StyledTextLinkWithFileIcon
                      fileType={fileType}
                      href={link?.asset?.file?.file?.url}
                      key={`${fileType}${index}`}
                      text={link?.asset?.displayTitle}
                    />
                  )
                }
                if (linkContentType === 'linkButton') {
                  return (
                    <StyledContainedButton
                      key={`${contentType}${index}`}
                      label={link?.buttonText}
                      onClick={() =>
                        handleNavigate(link?.url, NavigationHelper.types.OPEN)
                      }
                    />
                  )
                }
              })}
            </LinksWrapper>
          )
        }
        if (contentType === 'imageLinkList') {
          return (
            <ImageLinkListWrapper key={`image-link-list-wrapper-${index}`}>
              <ImageWrapper>
                <StyledImage
                  alt={content?.image?.description}
                  key={content?.image?.description}
                  src={content?.image?.file?.url}
                />
              </ImageWrapper>
              <LinkListWrapper>
                {content?.content?.map(item => {
                  if (item?.contentType?.id === 'headerBodyCopy') {
                    const { h3: header } = item || {}
                    return (
                      <StyledHeaderThree
                        key={`link-list-wraper-header-${index}`}
                      >
                        {header}
                      </StyledHeaderThree>
                    )
                  }
                  if (item?.contentType?.id === 'linkButtonMulti2') {
                    const { linkMenu } = item || {}
                    return (
                      <StyledLinkDropdown
                        // dataTestId={toKebabCase(displayTitle)}
                        defaultLabel="Download Playbook"
                        dropdownButtonIcon={<DownloadIcon />}
                        key={`link-dropdown-${index}`}
                        keyProp="id"
                        labelProp="buttonText"
                        onChange={link => dropdownLinkNavigation(link)}
                        options={linkMenu}
                        shouldHideSelectedIcon={true}
                      />
                    )
                  }
                  if (item?.contentType?.id === 'textLinkIcon') {
                    const fileType = item?.asset?.contentType?.id
                    return (
                      <StyledTextLinkWithFileIcon
                        fileType={fileType}
                        href={item?.asset?.file?.file?.url}
                        key={`${fileType}${index}`}
                        text={item?.asset?.displayTitle}
                      />
                    )
                  }
                })}
              </LinkListWrapper>
            </ImageLinkListWrapper>
          )
        }
        if (contentType === 'linkCallout') {
          const { link, linkTitle, subtitle } = content || {}
          const { url } = link || {}

          if (link?.file) {
            const { file } = link || {}
            return (
              <Fragment key={`link-callout-wrapper-${contentType}${index}`}>
                <StyledTextLink
                  key={`${contentType}${index}`}
                  onClick={() =>
                    handleNavigate(file?.file?.url, NavigationHelper.types.OPEN)
                  }
                >
                  {superscriptRegistered(linkTitle)}
                  <StyledCircleRightArrowIcon />
                </StyledTextLink>
                {subtitle}
              </Fragment>
            )
          }

          return (
            <>
              <StyledTextLink
                key={`${contentType}${index}`}
                onClick={e => {
                  e.preventDefault()
                  navigateToPage(url)
                }}
              >
                {superscriptRegistered(linkTitle)}
                <StyledCircleRightArrowIcon />
              </StyledTextLink>
              {subtitle}
            </>
          )
        }
        if (contentType === 'textLinkIcon') {
          const fileType = content?.asset?.contentType?.id

          if (fileType === 'pdf') {
            const { displayTitle } = content?.asset || {}
            return (
              <StyledTextLinkWithFileIcon
                fileType={fileType}
                href={content?.asset?.file?.file?.url}
                key={`${fileType}${index}`}
                text={displayTitle}
              />
            )
          } else if (fileType === 'url') {
            const url = content?.asset?.url
            return (
              <StyledTextLinkWithFileIcon
                fileType={content?.asset?.icon}
                href={url}
                key={`${fileType}${index}`}
                text={content?.asset?.displayTitle}
              />
            )
          } else {
            return (
              <StyledTextLinkWithFileIcon
                fileType={fileType}
                href={content?.asset?.file?.file?.url}
                key={`${fileType}${index}`}
                text={content?.asset?.displayTitle}
              />
            )
          }
        }
        if (contentType === 'visualLauncher') {
          return (
            <div
              key="visual-launcher"
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              LIGHTBOX LAUNCHER PLACEHOLDER
            </div>
          )
        }
      })}
    </ColumnWrapper>
  )
}

AdminFlexibleContentColumn.propTypes = {
  alternateContent: PropTypes.object,
  breakpointTablet: PropTypes.string,
  className: PropTypes.string,
  columnContent: PropTypes.array,
  handleNavigate: PropTypes.func,
  navigateToPage: PropTypes.func,
  toggleModal: PropTypes.func,
}
export default AdminFlexibleContentColumn
