class ContenfulTypes {
  static #CONTENTFUL_REQUEST = 'CONTENTFUL_REQUEST'
  static #CONTENTFUL_SUCCESS = 'CONTENTFUL_SUCCESS'
  static #CONTENTFUL_FAILURE = 'CONTENTFUL_FAILURE'

  static #SET_CONTENT_NAMESPACE = 'content/SET_CONTENT_NAMESPACE'

  static #START_CONTENT_NODES = 'content/START_CONTENT_NODES'
  static #UPDATE_CONTENT_NODES = 'content/UPDATE_CONTENT_NODES'
  static #UPDATE_CONTENT_NODES_ERROR = 'UPDATE_CONTENT_NODES_ERROR'
  static #UPDATE_CONTENT_NODES_ERROR_SYSTEM_MESSAGE = 'content/UPDATE_CONTENT_NODES_ERROR_SYSTEM_MESSAGE'
  static #RESET_CONTENT_NODES = 'content/RESET_CONTENT_NODES'

  static #SETUP_CONTENTFUL_GET = 'SETUP_CONTENTFUL_GET'

  static get CONTENTFUL_REQUEST() {
    return this.#CONTENTFUL_REQUEST
  }
  static get CONTENTFUL_SUCCESS() {
    return this.#CONTENTFUL_SUCCESS
  }
  static get CONTENTFUL_FAILURE() {
    return this.#CONTENTFUL_FAILURE
  }

  static get SET_CONTENT_NAMESPACE() {
    return this.#SET_CONTENT_NAMESPACE
  }

  static get START_CONTENT_NODES() {
    return this.#START_CONTENT_NODES
  }
  static get UPDATE_CONTENT_NODES() {
    return this.#UPDATE_CONTENT_NODES
  }
  static get UPDATE_CONTENT_NODES_ERROR() {
    return this.#UPDATE_CONTENT_NODES_ERROR
  }
  static get UPDATE_CONTENT_NODES_ERROR_SYSTEM_MESSAGE() {
    return this.#UPDATE_CONTENT_NODES_ERROR_SYSTEM_MESSAGE
  }
  static get RESET_CONTENT_NODES() {
    return this.#RESET_CONTENT_NODES
  }

  static get SETUP_CONTENTFUL_GET() {
    return this.#SETUP_CONTENTFUL_GET
  }
}

export default ContenfulTypes
