import { connect } from 'react-redux'

import Component from './component'

import NavigationHelper from 'layers/navigation/navigationHelper'

export default connect(
  NavigationHelper.mapStateToProps(),
  NavigationHelper.mapDispatchToProps,
)(Component)
