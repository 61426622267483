import {
  getFileType,
  makeUrlFriendly,
  programThemeToIconMap,
  localeToTrackData,
} from './helpers'
import { fileTypes } from 'utils/contentfulFileTypes'
import { getLocalesFromLanguageToggle } from 'utils/translations'

const parseVttFiles = (vttFiles = []) => {
  const tracks = vttFiles.map(track => {
    const { file, locale } = track || {}
    const { url } = file || {}
    const { srclang, label } = localeToTrackData(locale)
    const completeTrack = {
      kind: 'captions',
      label,
      src: url,
      srclang,
    }
    return completeTrack
  })
  return tracks
}

function video(content) {
  const { videoRef, id, displayTitle, description, time } = content || {}
  const {
    azureVideoUrl,
    azureVideoUrlExtended,
    posterImage,
    vttFile = [],
    transcript,
    transcriptExtended,
  } = videoRef || {}
  const { file, url } = posterImage || {}
  const { url: url2 } = file || {}
  const tracks = parseVttFiles(vttFile)

  return {
    dataTestId: id,
    description,
    displayTitle,
    id,
    time,
    tracks,
    videoRef,
    videoThumbnailUrl: url || url2,
    videoUrl: azureVideoUrl,
    videoUrlExtended: azureVideoUrlExtended,
    transcriptSource: transcript,
    transcriptExtended,
  }
}

function mediaBodyCopy(content) {
  const { bodyCopy, id, internalTitle, locale, media, contentType } =
    content || {}
  const { id: contentId } = contentType || {}
  const { description, displayTitle, id: videoEntryId } = media || {}
  return {
    bodyCopy,
    id,
    internalTitle,
    locale,
    contentId,
    description,
    displayTitle,
    videoEntryId,
  }
}

function assetDescription(content) {
  const { id, assets: rawAssets = [] } = content || {}
  const assets = rawAssets.map(asset => file(asset))

  return { assets, id }
}

function headerBodyCopy(content) {
  const { bodyCopy, h2, h3, id } = content || {}

  return { bodyCopy, h2Text: h2, h3Text: h3, id }
}

function inlineTranslation(content) {
  const { languageToggle, id, content: contentArray } = content || {}

  const targetLocales = getLocalesFromLanguageToggle(languageToggle).filter(
    locale => locale !== content.locale,
  ) // We do not want the current locale included

  // TODO: For now, only handle one content item
  const child = contentArray[0]

  return {
    bodyCopy: child.bodyCopy,
    h2Text: child.h2,
    h3Text: child.h3,
    id,
    contentId: child.id,
    targetLocales,
  }
}

function langPdf(content) {
  const { languageToggle, id, pdfItem } = content || {}

  const targetLocales = getLocalesFromLanguageToggle(languageToggle)
  const entryId = pdfItem?.id || ''

  return {
    id,
    entryId,
    targetLocales,
    fileLinks: {},
  }
}

function lang(content) {
  const { boundary, enUs, esUs } = content || {}
  return { boundary, enUs, esUs }
}

function image(content) {
  const { image, width } = content || {}
  const { file, url } = image || {}
  const { url: url2 } = file || {}

  return { source: url || url2, width }
}

function url(content) {
  const { url, displayTitle, id } = content || {}

  return { url, displayTitle, id }
}

// An individual H3LinkList which should eventually belong to a larger grouping of 'Asset Category Models'
function category(content) {
  const { assets = [], displayTitle, id } = content || {}
  const links = assets.map(asset => {
    // TODO: Route needs to come from Contentful model
    const { displayTitle, url: initialUrl, contentType: contentTypeWrapper } =
      asset || {}

    let contentType

    if (typeof contentTypeWrapper === 'string') {
      contentType = contentTypeWrapper.toLowerCase()
    } else {
      contentType = contentTypeWrapper?.id
    }

    const linkContentTypeOptions = {
      image,
      video,
      file,
      url,
    }
    const isFileType =
      contentType && fileTypes.find(type => type === contentType)

    if (linkContentTypeOptions[contentType] || isFileType) {
      // falls back to fileType
      const parserByType =
        (linkContentTypeOptions[contentType] &&
          linkContentTypeOptions[contentType]) ||
        linkContentTypeOptions['file']
      const parsed = parserByType && parserByType(asset)

      const { url, displayTitle, id, title, videoUrl } = parsed || {}
      const href = url || videoUrl
      const text = displayTitle || title

      return { href, text, contentType, id }
    }

    return { href: initialUrl, id, text: displayTitle, contentType }
  })

  return { header: displayTitle, id, links }
}

function anchor(content) {
  const { h2Text, bodyCopy = '', id } = content || {}
  const hash = `#${makeUrlFriendly(h2Text)}`

  return { bodyCopy, h2Text, hash, id }
}

// returns props for PDFs using TextLinkWithFileIcon
function pdf(content) {
  const { contentType, displayTitle } = content || {}

  // url could come from two different places
  const url = content.file?.file?.url ?? content.file?.url ?? null

  // contentType could come from two different places
  const id = contentType?.id ?? contentType ?? ''

  return { text: displayTitle, fileType: id.toLowerCase(), href: url }
}

function presentation(content) {
  const { id, locale } = content
  return { id, locale }
}

function text(content) {
  const { content: textContent } = content
  return { content: textContent }
}

function textLinkIcon(content) {
  const { asset: originalAsset } = content || {}
  const asset = file(originalAsset)
  const { title: text, url: href, fileType, description } = asset

  return { fileType, href, text: text || description }
}

function file(content) {
  const {
    contentType,
    description = '',
    displayTitle = '',
    file: rootFile,
    thumbnail,
  } = content || {}
  const { id } = contentType || {}
  const {
    file,
    fileName: fileName2,
    url: fileUrl,
    fileType: fileTypeFromContentful,
  } = rootFile || {}
  const { fileName, url } = file || {}
  const { file: thumbnailFile, url: thumbnailUrl } = thumbnail || {}
  const { url: image } = thumbnailFile || {}
  const fileType =
    id ||
    getFileType(fileName) ||
    getFileType(fileName2) ||
    getFileType(fileTypeFromContentful) ||
    getFileType(fileUrl)

  return {
    description: description ?? '',
    fileType,
    image: image || thumbnailUrl,
    title: displayTitle,
    url: url || fileUrl,
  }
}

const book = (contentfulData = {}) => {
  const {
    id = '',
    author = '',
    illustrator = '',
    displayTitle = '',
    description = '',
    metadata = [],
    coverImage,
  } = contentfulData || {}

  const { image } = coverImage || {}
  const { file, url: url2 = null } = image || {}
  const { url = null } = file || {}

  const metadataEntries = metadata.filter(
    entry => entry.programTheme && programThemeToIconMap[entry.programTheme],
  )
  const tags = metadataEntries.map((entry, index) => {
    const { programTheme } = entry
    const tagId = `TAG-${id}-${index}`
    const tag = {
      icon: programThemeToIconMap[programTheme],
      id: tagId,
      link: '',
      title: programTheme,
    }
    return tag
  })

  const book = {
    author,
    dataTestId: id,
    description,
    illustrator,
    image: url || url2,
    tags,
    title: displayTitle,
  }

  return book
}

function accordion(data) {
  const { accordionHeader } = data || {}
  const title = accordionHeader || ''
  return { title }
}

function advisoryActivity(content) {
  const {
    action = '',
    demonstration = '',
    discussion = '',
    id = '',
    introduction = '',
    investigation = '',
    materials = '',
    planning = '',
    preparation = '',
    projectDescription = '',
    reflection = '',
    steps = '',
    warmUp = '',
  } = content || {}

  const partOne = { materials, preparation, projectDescription }
  const partTwo = {
    action,
    discussion,
    introduction,
    investigation,
    planning,
    steps,
    warmUp,
  }
  const partThree = { demonstration, reflection }

  return { id, partOne, partThree, partTwo }
}

function advisoryActivityCard(content) {
  const {
    description = '',
    displayTitle = '',
    id = '',
    metadata = [],
    pageData = {},
    slpType = '',
    time = '',
  } = content || {}

  return {
    description,
    displayTitle,
    id,
    metadata,
    pageData,
    slpType,
    time,
  }
}

function contentBlock(content) {
  const {
    content: blockContent = [],
    displayTitle = '',
    presentationButtonLabel,
  } = content || {}

  return {
    presentationButtonLabel,
    content: blockContent,
    displayTitle,
  }
}

function playlist(entry) {
  const {
    displayTitle,
    bannerImage,
    introTitle,
    introDescription,
    introDocs,
    listTitle,
    listDescription,
    content,
    closingMessageHeader,
    closingMessageBody,
  } = entry || {}

  return {
    displayTitle,
    bannerImage: parseImageData(bannerImage).fileUrl,
    introTitle,
    introDescription,
    introDocs: introDocs?.map(pdf => playlistPdf(pdf)),
    listTitle,
    listDescription,
    sessions: content.map(session => playlistSession(session)),
    closingMessageHeader,
    closingMessageBody,
  }
}

function playlistPdf(pdf) {
  const { displayTitle, file } = pdf || {}

  return {
    displayTitle,
    url: file?.file?.url,
  }
}

function playlistSession(session) {
  const { displayTitle, videoType, video, pdf } = session || {}

  let parsedPdf
  if (pdf) {
    parsedPdf = playlistPdf(pdf)
  }

  return {
    displayTitle,
    isVideo: videoType,
    video: parseMedia(video),
    pdf: parsedPdf,
  }
}

const imageBodyCopy = ({ bodyCopy, image: imageContainer }) => {
  const { image: imageDetails, accessibility, expandImage } =
    imageContainer || {}

  const { file, url, width, height } = imageDetails || {}
  const { url: url2, details } = file || {}
  const { image: imageDimentions } = details || {}
  const { height: height2, width: width2 } = imageDimentions || {}
  const { altTextShort } = accessibility || {}

  return {
    url: url || url2,
    bodyCopy,
    height: height || height2 || 0,
    width: width || width2 || 0,
    altTextShort,
    expandImage,
  }
}

const imageBodyCopyFlexible = entry => {
  const {
    bodyCopy1,
    image: imageContainer,
    imageSize,
    imageJustification,
  } = entry

  const { image: imageDetails, accessibility, expandImage } =
    imageContainer || {}
  const {
    file,
    url,
    expandImage: expandImage2,
    accessibility: accessibility2,
  } = imageDetails || {}
  const { altTextShort } = accessibility || accessibility2 || {}
  const { url: url2 } = file || {}

  return {
    url: url || url2,
    bodyCopy1,
    imageSize,
    imageJustification,
    altTextShort,
    expandImage: expandImage || expandImage2,
  }
}

const parseAudioData = media => {
  let { displayTitle, file } = media || {}
  const url = file?.file?.url ?? file?.url ?? null
  return { title: displayTitle, fileUrl: url }
}

const parseVideoData = media => {
  const {
    videoUrl: fileUrl,
    description,
    displayTitle: title,
    videoThumbnailUrl: thumbnailUrl,
    time,
    tracks,
  } = video(media) || {}

  return {
    description: description || title,
    fileUrl,
    thumbnailUrl,
    time,
    title,
    tracks,
  }
}

const parseImageData = media => {
  let { accessibility, image, displayTitle } = media || {}
  let { file } = image || {}
  let { url } = file || {}
  let { altTextShort } = accessibility || {}
  return {
    title: displayTitle,
    fileUrl: url,
    thumbnailUrl: url,
    description: altTextShort || displayTitle,
  }
}

const parseMedia = media => {
  const { id, contentType } = media || {}
  const { id: fileType } = contentType || {}

  if (fileType === 'audio') {
    return { ...parseAudioData(media), id, fileType }
  } else if (fileType === 'video') {
    return { ...parseVideoData(media), id, fileType }
  } else {
    return { ...parseImageData(media), id, fileType }
  }
}

const parseContentType = entry => {
  const { contentType } = entry || {}
  const { id } = contentType || {}
  return id
}

const parseSlug = entry => {
  const { pageData } = entry || {}
  const { route = '' } = pageData || {}
  const formattedRoute = route.replace(/^[/]+/g, '')
  return formattedRoute
}

const parsePathname = location => {
  const { pathname = '' } = location || {}

  return pathname.replace(/[/]+$/g, '')
}

function activityMultiMediaGroupingCard(initialContent) {
  const { displayTitle = '', id = '', content = [], pageData = {} } =
    initialContent || {}

  return {
    displayTitle,
    id,
    pageData,
    content,
  }
}

const contentTypes = {
  accordion,
  activityMultiMediaGroupingCard,
  advisoryActivity,
  advisoryActivityCard,
  anchor,
  assetDescription,
  book,
  category,
  contentBlock,
  headerBodyCopy,
  inlineTranslation,
  image,
  imageBodyCopy,
  imageBodyCopyFlexible,
  lang,
  langPdf,
  parseAudioData,
  parseMedia,
  parseContentType,
  parseSlug,
  parsePathname,
  pdf,
  playlist,
  presentation,
  text,
  textLinkIcon,
  video,
  mediaBodyCopy,
}

export default contentTypes
