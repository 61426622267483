// src/mocks/browser.js
import { setupWorker } from 'msw'
import { handlers } from './handlers'

let worker
// this is a browser-based msw mock, don't initialize worker for jest tests (Node environment)
if (process.env.JEST_WORKER_ID === undefined) {
  // This configures a Service Worker with the given request handlers.
  worker = setupWorker(...handlers)
}

export { worker }
