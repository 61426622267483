import React from 'react'
import PropTypes from 'prop-types'
import { TabButton, TabSet } from 'secondstep-components'
import { isFlagOn } from 'utils/featureFlags'
import { isElementaryPage } from 'utils/productHelpers'
import { TabSetWrapper } from './component.styles'
import { ADMIN, BPU_DISPLAY, BPU_OFF } from './constants'

const UnitTabSet = props => {
  const {
    ariaControls,
    aggregateMspSite,
    onSelect,
    selectedUnitSlug,
    units,
  } = props

  // this selection of display text is necessary for 1 use case where
  // the Bullying Unit is off for an Admin on all sites but the Bullying Unit
  // tab is still displayed in Preview mode
  // (see secondstep-auth/src/stores/userContext/selectors.js for more info)
  const getDisplayText = displayTitle => {
    if (displayTitle !== BPU_DISPLAY) return displayTitle
    if (!aggregateMspSite) return

    const hasBPU = aggregateMspSite?.preferences?.bullyingHarassmentUnitEnabled
    const hasAdmin = aggregateMspSite?.claims.includes(ADMIN)
    return hasBPU || !hasAdmin ? displayTitle : displayTitle + BPU_OFF
  }

  if (
    isElementaryPage() &&
    isFlagOn(['feature_LEARN-18511-k5-elem-program-flex'])
  ) {
    console.log('[DEBUG] UnitTabSet rendering K5 in program flex')
  }

  return (
    <TabSetWrapper>
      <TabSet dataTestId="unit-tabset">
        {units.map(unit => {
          const { displayTitle, route } = unit
          const unitSlug = route.split('/').pop()
          const isActive = unitSlug === selectedUnitSlug
          return (
            <TabButton
              ariaControls={ariaControls}
              dataTestId={`unit-tab-${unitSlug}`}
              isActive={isActive}
              key={unitSlug}
              label={getDisplayText(displayTitle)}
              onClick={() => onSelect(unitSlug)}
            />
          )
        })}
      </TabSet>
    </TabSetWrapper>
  )
}

UnitTabSet.propTypes = {
  aggregateMspSite: PropTypes.shape({
    claims: PropTypes.array,
    preferences: PropTypes.shape({
      implementationLevel: PropTypes.number,
      bullyingHarassmentUnitEnabled: PropTypes.bool,
      isSetup: PropTypes.bool,
    }),
  }).isRequired,
  ariaControls: PropTypes.string,
  getDisplayText: PropTypes.func,
  onSelect: PropTypes.func.isRequired,
  selectedUnitSlug: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      displayTitle: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default UnitTabSet
