import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  Hairline,
  TabButton,
  TabSet,
  HeaderOne,
  HeaderTwo,
  BodyCopy,
} from 'secondstep-components'
import ContentTypeDescription from 'experienceComponents/ContentTypeDescription'

export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
`

export const HeaderBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 60px);
  background: ${themeGet('colors.backgrounds.banners.primary', 'colors.white')};
  z-index: 1;
`

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
  box-sizing: border-box;
  padding: 3.625rem 1.25rem 0;
  z-index: 1;

  .pathway_dropdown {
    display: none;
  }

  .text-wrapper {
    ${HeaderOne} {
      margin-bottom: 1.25rem;
      color: ${themeGet('colors.headers.primaryHeader')};
      font-family: ${themeGet('fonts.primary')};
      font-size: 42px;
      font-weight: 800;
      letter-spacing: 1.11px;
      line-height: 46px;
    }
    ${BodyCopy} {
      padding-bottom: 1.75rem;
      color: ${themeGet('colors.darkGray')};
      font-family: ${themeGet('fontFamily')};
      font-size: 1rem;
    }
  }
  .pathway_dropdown button {
    background-color: none;
  }

  ${themeGet('breakpoints.tablet')} {
    .content-overview-wrapper {
      align-items: start;
    }
  }
  ${themeGet('breakpoints.mobileLarge')} {
    padding: 3.625rem 1rem 0;
    text-align: center;
    .pathway_dropdown {
      display: inline;
    }
  }
`

export const StyledHairline = styled(Hairline)`
  z-index: 1;
  background-color: ${themeGet('colors.gray500')};
  height: 1px;
`

export const StyledTabButton = styled(TabButton).attrs(({ isActive }) => ({
  backgroundColor: isActive
    ? themeGet('colors.heading.level.2')
    : 'transparent',
  fontFamily: isActive
    ? themeGet('fontFamilySemibold')
    : themeGet('fontFamily'),
}))`
  margin-top: 1.75rem;
  margin-right: 1.75rem;
  padding-bottom: 10px;
  [class*='StyledBox'] {
    font-family: ${({ fontFamily }) => fontFamily};
    color: ${({ isActive }) =>
      isActive
        ? themeGet('colors.themePrimary')
        : themeGet('colors.headers.primaryHeader')};

    &:hover {
      color: ${themeGet('colors.themePrimary')};
    }
  }

  [class*='Underline'] {
    display: ${({ isActive }) => (isActive ? 'in-line' : 'none')};
    background-color: ${themeGet('colors.themePrimary')};
    position: relative;
    top: 4px;
  }
`

export const StyledTabSet = styled(TabSet)`
  flex-wrap: wrap;
  height: auto;
  margin-bottom: 1.25rem;
  ${themeGet('breakpoints.mobileLarge')} {
    display: none;
  }
  z-index: 1;

  [class*='StyledTabsHeader'] {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${themeGet('colors.highschool.green0')};
  padding-bottom: 2.5rem;
`

export const ContentSetWrapper = styled.div`
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
  padding: 0 1.25rem;
  box-sizing: border-box;
  z-index: 1;
  ${themeGet('breakpoints.mobileLarge')} {
    padding: 0 1rem;
  }
  & div > div[data-testid='activity-type-description-pathway-desc-wrapper'] {
    padding: 1.75rem 1.75rem ${({ hasFooter }) => (hasFooter ? '0' : '1.75rem')}
      1.75rem;
    ${themeGet('breakpoints.mobileLarge')} {
      padding: 1.25rem 1.75rem
        ${({ hasFooter }) => (hasFooter ? '0' : '1.25rem')} 1.25rem;
    }
    ${HeaderTwo} {
      font-family: ${themeGet('fonts.primary')};
      font-weight: 800;
      font-size: 1.75rem;
      color: ${themeGet('colors.headers.primaryHeader')};
    }

    ${BodyCopy} {
      margin-top: 1.75rem;
      color: ${themeGet('colors.darkGray')};
      font-family: ${themeGet('fontFamily')};
    }
    div[data-testid='link-content'] {
      font-family: ${themeGet('fontFamily')};
      padding-top: 1.25rem;
      padding-bottom: unset;
    }
  }
`
export const StyledContentTypeDescription = styled(
  ContentTypeDescription,
).attrs(({ singleLink, ...props }) => ({
  id: 'pathway-desc-wrapper',
  backgroundColor: themeGet('colors.white')(props),
  borderColor: themeGet('colors.highschool.green0')(props),
  titleColor: themeGet('colors.black')(props),
  bodyColor: themeGet('colors.grayLink')(props),
  singleLink: {
    ...singleLink,
    color: themeGet('colors.themePrimary')(props),
    hoverColor: themeGet('colors.themeSecondary')(props),
  },
}))``

export const BannerSpacer = styled.div`
  margin-top: 7rem;
`
