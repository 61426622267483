import {
  ACCESSIBILITY_TITLE,
  ADMIN_TITLE,
  DASHBOARD_TITLE,
  DISTRICT_TITLE,
  FAMILY_RESOURCES_ELEMENTARY_TITLE,
  FAMILY_RESOURCES_MIDDLE_SCHOOL_TITLE,
  K8_REPORTS_TITLE,
  SELA_REPORTS_TITLE,
  RESOURCE_LIBRARY_TITLE,
  WEBINARS_TITLE,
} from './constants'

export default {
  admin: {
    title: ADMIN_TITLE,
  },
  dashboard: {
    title: DASHBOARD_TITLE,
  },
  accessibility: {
    title: ACCESSIBILITY_TITLE,
  },
  district: {
    title: DISTRICT_TITLE,
  },
  'family-resources': {
    elementary: {
      title: FAMILY_RESOURCES_ELEMENTARY_TITLE,
    },
    'middle-school': {
      title: FAMILY_RESOURCES_MIDDLE_SCHOOL_TITLE,
    },
  },
  resourceLibrary: {
    title: RESOURCE_LIBRARY_TITLE,
  },
  reports: {
    k8: {
      title: K8_REPORTS_TITLE,
    },
    'sel-for-adults': {
      title: SELA_REPORTS_TITLE,
    },
  },
  webinars: {
    title: WEBINARS_TITLE,
  },
}
