import React from 'react'
import PropTypes from 'prop-types'
import { CloseButton, ContainedButton } from 'secondstep-components'
import { CancelButton, ModalHeaderInput } from '../component.styles'
import {
  ClassCardModalWrapperEdit,
  ModalWrapperEdit,
  StyledButtonsWrapper,
  StyledFormInputText,
} from './component.styles.js'
import {
  EDIT_HEADER,
  COURSE_INSTANCE_LABEL,
  COURSE_INSTANCE_PLACEHOLDER,
  EDIT_CANCEL,
  EDIT_SUBMIT,
} from './constants'

const propTypes = {
  courseInstanceName: PropTypes.string,
  dataTestId: PropTypes.string,
  handleCancel: PropTypes.func,
  handleClassNameChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  inputErrors: PropTypes.object,
  isClassCardMenu: PropTypes.bool,
  submitDisabled: PropTypes.bool,
}

const Edit = ({
  courseInstanceName,
  dataTestId,
  handleCancel,
  handleClassNameChange,
  handleSubmit,
  inputErrors,
  isClassCardMenu,
  submitDisabled,
}) => {
  const EditModal = isClassCardMenu
    ? ClassCardModalWrapperEdit
    : ModalWrapperEdit

  return (
    <EditModal dataTestId={dataTestId} isClassCardMenu={isClassCardMenu}>
      <CloseButton dataTestId="button-close" onClick={handleCancel} />
      <ModalHeaderInput dataTestId="modal-edit-header">
        {EDIT_HEADER}
      </ModalHeaderInput>
      <StyledFormInputText
        autoComplete={false}
        autofocus
        dataTestId="edit-class-name"
        inputErrors={inputErrors.className}
        label={COURSE_INSTANCE_LABEL}
        maxLength={21}
        name="courseInstanceName"
        onChange={handleClassNameChange}
        placeholder={COURSE_INSTANCE_PLACEHOLDER}
        value={courseInstanceName}
      />
      <StyledButtonsWrapper right>
        <CancelButton dataTestId={'button-cancel'} onClick={handleCancel}>
          {EDIT_CANCEL}
        </CancelButton>
        <ContainedButton
          dataTestId={'button-save-changes'}
          disabled={submitDisabled}
          onClick={handleSubmit}
        >
          {EDIT_SUBMIT}
        </ContainedButton>
      </StyledButtonsWrapper>
    </EditModal>
  )
}

Edit.propTypes = propTypes

export default Edit
