import {
  HS_COURSE,
  HS_UNIT,
  WEEK,
  MULTIMEDIA_GROUPING,
} from 'containers/Pages/constants'

export default {
  activityList: {
    contentType: 'activityList',
    includesDepth: 3,
    nextNodesField: 'listContent',
    nextNodesType: 'array',
  },
  advisoryActivity: {
    contentType: 'advisoryActivity',
    includesDepth: 3,
    nextNodesField: 'metadata',
    nextNodesType: 'array',
  },
  article: {
    contentType: 'article',
    includesDepth: 3,
    // This is not actually the next node field, it's the end of the tree.
    // It's required to retrieve the correct content.
    nextNodeFields: 'content',
    nextNodesType: 'array',
  },
  course: {
    contentType: 'course',
    includesDepth: 3,
    nextNodesField: 'scopeAndSequence',
    nextNodesType: 'array',
  },
  presentation: {
    contentType: 'presentation',
    includesDepth: 3,
    nextNodesField: 'sections',
    nextNodesType: 'array',
  },
  product: {
    contentType: 'product',
    includesDepth: 3,
    nextNodesField: 'productSections',
    nextNodesType: 'array',
  },
  productLine: {
    contentType: 'productLine',
    includesDepth: 3,
    nextNodesField: 'products',
    nextNodesType: 'array',
  },
  programSectionArticleTree: {
    contentType: 'programSectionArticleTree',
    includesDepth: 3,
    nextNodesField: 'programSubsections',
    nextNodesType: 'array',
  },
  staticLandingPage: {
    contentType: 'staticLandingPage',
    includesDepth: 3,
    nextNodesField: 'childPages',
    nextNodesType: 'array',
  },
  subsectionLanding: {
    contentType: 'subsectionLanding',
    includesDepth: 3,
    nextNodesField: 'content',
    nextNodesType: 'array',
  },
  training: {
    contentType: 'training',
    includesDepth: 3,
    nextNodesField: 'trainingPaths',
    nextNodesType: 'array',
  },
  trainingPath: {
    contentType: 'trainingPath',
    includesDepth: 3,
    nextNodesField: 'trainingSets',
    nextNodesType: 'array',
  },
  trainingCategory: {
    contentType: 'trainingCategory',
    includesDepth: 3,
    nextNodesField: 'trainingModules',
    nextNodesType: 'array',
  },
  testTrainingModule: {
    contentType: 'testTrainingModule',
    includesDepth: 3,
    nextNodesField: 'additionalResources',
    nextNodesType: 'array',
  },
  unit: {
    contentType: 'unit',
    includesDepth: 3,
    nextNodesField: 'lessons',
    nextNodesType: 'array',
  },
  firstLevelSections: {
    contentType: 'firstLevelSections',
    includesDepth: 3,
    nextNodesField: 'productSubsections',
    nextNodesType: 'array',
  },
  groupedContent: {
    contentType: 'groupedContent',
    /* TODO necessary for now to get videos to work bc they are deeply nested.
         Need to break up groupedContent grabs in future to allow for less includes
       */
    includesDepth: 4,
    nextNodesField: 'content',
    nextNodesType: 'array',
  },
  kitTeachSection: {
    contentType: 'kitTeachSection',
    includesDepth: 3,
    nextNodesField: 'courses',
    nextNodesType: 'array',
  },
  courseSlm: {
    contentType: 'courseSlm',
    includesDepth: 3,
    nextNodesField: 'lessons',
    nextNodesType: 'array',
  },
  slmLesson: {
    contentType: 'slmLesson',
    includesDepth: 3,
    nextNodesField: 'content',
    nextNodesType: 'array',
  },
  lessonSlmEl: {
    contentType: 'lessonSlmEl',
    includesDepth: 3,
    nextNodesField: 'content',
    nextNodesType: 'array',
  },
  slm: {
    contentType: 'slm',
    includesDepth: 3,
  },
  adminDashboard: {
    contentType: 'adminDashboard',
    includesDepth: 3,
    nextNodesField: 'implementation',
    nextNodesType: 'array',
  },
  districtSection: {
    contentType: 'districtSection',
    includesDepth: 3,
    nextNodesField: 'resourcePages',
    nextNodesType: 'array',
  },
  familiesSection: {
    contentType: 'familiesSection',
    includesDepth: 3,
    nextNodesField: 'subsection',
    nextNodesType: 'array',
  },
  playlist1: {
    contentType: 'playlist1',
    includesDepth: 3,
    nextNodesField: 'content',
    nextNodesType: 'array',
  },
  playlist1Tab: {
    contentType: 'playlist1Tab',
    includesDepth: 3,
    nextNodesField: 'videoCard',
    nextNodesType: 'array',
  },
  resourceLibraryPage: {
    contentType: 'resourceLibraryPage',
    includesDepth: 3,
    nextNodeFields: 'content',
    nextNodesType: 'array',
  },
  [HS_COURSE]: {
    contentType: HS_COURSE,
    includesDepth: 3,
    nextNodesField: 'units',
    nextNodesType: 'array',
  },
  [HS_UNIT]: {
    contentType: HS_UNIT,
    includesDepth: 3,
    nextNodesField: 'weeks',
    nextNodesType: 'array',
  },
  [WEEK]: {
    contentType: WEEK,
    includesDepth: 3,
    nextNodesField: 'weeks',
    nextNodesType: 'array',
  },
  [MULTIMEDIA_GROUPING]: {
    contentType: MULTIMEDIA_GROUPING,
    includesDepth: 3,
    nextNodesField: 'content',
    nextNodesType: 'array',
  },
}
