import React from 'react'
import PropTypes from 'prop-types'
import { isFlagOn } from 'utils/featureFlags'
import K8DigitalProgramCard from './component'
import K8DigitalProgramCardV2 from 'components/dashboard/K8DigitalProgramCardV2'

// TODO: remove this container when june release for ms/hs is complete
const K8DigitalProgramCardContainer = props => {
  // TODO: this is insufficient to support K5 on program flex
  // the referenced component also needs some changes because it is middle school specific right now
  // before merging need to add a hardcoded Contentful entry mapping for K5:
  if (
    props.isK5Product &&
    isFlagOn(['feature_LEARN-18511-k5-elem-program-flex'])
  ) {
    return <K8DigitalProgramCardV2 {...props} />
  }

  return (
    <>
      {props.isK5Product && <K8DigitalProgramCard {...props} />}
      {props.isMsProduct && <K8DigitalProgramCardV2 {...props} />}
    </>
  )
}

K8DigitalProgramCardContainer.propTypes = {
  isK5Product: PropTypes.bool,
  isMsProduct: PropTypes.bool,
}

export default K8DigitalProgramCardContainer
