import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import UnitTabSet from './component'
import { aggregateMSPSiteContext } from 'auth/stores/userContext/selectors'

const UnitTabSetContainer = props => {
  const {
    ariaControls,
    onSelect,
    selectedUnitSlug,
    units,
    userContextManager,
  } = props
  const aggregateMspSite = aggregateMSPSiteContext(userContextManager) || null

  return (
    aggregateMspSite && (
      <UnitTabSet
        aggregateMspSite={aggregateMspSite}
        ariaControls={ariaControls}
        onSelect={onSelect}
        selectedUnitSlug={selectedUnitSlug}
        units={units}
      />
    )
  )
}

UnitTabSetContainer.propTypes = {
  ariaControls: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  selectedUnitSlug: PropTypes.string,
  units: PropTypes.arrayOf(
    PropTypes.shape({
      displayTitle: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
    }),
  ).isRequired,
  userContextManager: PropTypes.object,
}

const mapStateToProps = ({ userContextManager }) => ({
  userContextManager: userContextManager,
})

export default connect(mapStateToProps)(UnitTabSetContainer)
