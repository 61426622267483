import { themeGet } from 'styled-system'
import styled from 'styled-components'
import { HeaderSix } from 'secondstep-components'

export const AdvisoryActivityCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  padding-right: 1.75rem;
  padding-left: 1.75rem;
  padding-top: 0.5rem;
  background-color: ${themeGet('colors.backgroundLightGray')};
  box-shadow: 0px 0px 0.625rem
    ${props => (props.isHovered ? themeGet('colors.lightGray') : '#fff')};
  cursor: pointer;
`

export const LinkStyled = styled.a`
  text-decoration: none;
  padding: 0;
  margin: 0;
  margin-bottom: 1.25rem;
  color: ${themeGet('colors.darkGray')};
`

export const AdvisoryActivityInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 108px;
  padding-bottom: 0.813rem;
  border-bottom: 1px solid ${themeGet('colors.gray500')};
`

export const ActivityTags = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 1rem;
  padding-bottom: 0.75rem;

  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
  }
`

// max-height to keep things aligned between cards with icons being of various sizes
export const Tag = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 20px;
  padding-right: 1rem;

  ${themeGet('breakpoints.tablet')} {
    margin-bottom: 0.5rem;
  }
`

export const TagText = styled.p`
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: ${themeGet('font.size.text.base')};
  color: ${themeGet('colors.darkGray')};
  padding-left: 0.313rem;
`

export const ActivityDetail = styled(HeaderSix)`
  font-size: 1rem;
  color: ${themeGet('colors.darkGray')};
  margin-top: 0.875rem;
  border-color: ${themeGet('colors.gray500')};
  text-align: end;
`

export const TitleAndDescription = styled.div`
  flex-basis: 88%;
`
