import {
  ExtraLargeHeader,
  LargeHeader,
  MediumHeader,
  SmallHeader,
  ExtraSmallHeader,
  MainHeader,
} from './HeaderComponent'

export {
  ExtraLargeHeader,
  LargeHeader,
  MediumHeader,
  SmallHeader,
  ExtraSmallHeader,
  MainHeader,
}
