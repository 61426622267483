import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
  StyledModal,
  StyleModalBody,
  StyledModalFooter,
  StyledModalTitle,
  StyledOption,
  StyledOptionsLegend,
  StyledOptionsSection,
  StyledPrimaryButton,
  StyledSecondaryButton,
  StyledText,
} from './component.styles'

const HsEducatorCompleteModal = props => {
  const {
    handleClose,
    // use this prop to indicate if the modal should open with an option already selected
    initialValue,
    modalCancelButtonText,
    modalDescription,
    modalHelperText,
    modalSaveButtonText,
    modalTitle,
    options,
    practiceName,
    show,
    submitProgress,
  } = props || {}

  const [selected, setSelected] = useState(initialValue)

  const isSelected = option => {
    return option.value === selected
  }

  // HACK: because periodProgress is set asynchonously...
  // we may need to update the selected value after the state variable has initialized
  useEffect(() => {
    if (initialValue !== selected) {
      setSelected(initialValue)
    }
  }, [initialValue])

  return (
    options?.length > 0 && (
      <StyledModal onClose={handleClose} show={show}>
        <StyledModalTitle>{modalTitle}</StyledModalTitle>
        <StyleModalBody>
          {practiceName && (
            <StyledText>{`${modalDescription} "${practiceName}"?`}</StyledText>
          )}
          <StyledOptionsSection>
            {options.map((option, i) => (
              <StyledOption
                className={isSelected(option) ? 'selected' : ''}
                key={i}
                onClick={() =>
                  isSelected(option)
                    ? setSelected(null)
                    : setSelected(option.value)
                }
              >
                {option.text}
              </StyledOption>
            ))}
          </StyledOptionsSection>
          {modalHelperText && (
            <StyledOptionsLegend>{modalHelperText}</StyledOptionsLegend>
          )}
        </StyleModalBody>
        <StyledModalFooter>
          <StyledSecondaryButton onClick={handleClose}>
            {modalCancelButtonText}
          </StyledSecondaryButton>
          <StyledPrimaryButton
            disabled={!selected}
            onClick={() => submitProgress(selected)}
          >
            {modalSaveButtonText}
          </StyledPrimaryButton>
        </StyledModalFooter>
      </StyledModal>
    )
  )
}

HsEducatorCompleteModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  initialValue: PropTypes.string,
  modalCancelButtonText: PropTypes.string,
  modalDescription: PropTypes.string,
  modalHelperText: PropTypes.string,
  modalSaveButtonText: PropTypes.string,
  modalTitle: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.string,
    }),
  ).isRequired,
  show: PropTypes.bool.isRequired,
  submitProgress: PropTypes.func.isRequired,
}

export default HsEducatorCompleteModal
