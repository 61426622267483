export const transformLessonData = lessons => {
  let lessonTitle = null
  let lessonInfos = []
  const transformed = lessons.reduce((lessonContainer, lesson) => {
    const { contentType, content, displayTitle, h2, pageData } = lesson
    const { id } = contentType
    if (id === 'headerBodyCopy') {
      if (lessonTitle) {
        lessonContainer.push({
          lessonTitle: lessonTitle,
          lessonInfos: lessonInfos,
        })
      }
      lessonTitle = h2
      lessonInfos = []
    } else if (id === 'slmLesson' || id === 'lessonSlmEl') {
      const { route } = pageData || {}
      let slmRoute
      if (content) {
        const slmContent = content.find(contentEntry => {
          const { contentType: contentEntryType } = contentEntry || {}
          const { id: contentId } = contentEntryType || {}
          return contentId === 'slm'
        })
        const { pageData: slmPageData } = slmContent || {}
        var { route: slmRouteHolder } = slmPageData || {}
        slmRoute = slmRouteHolder
      }

      const lessonInfo = {
        name: displayTitle,
        teachUrl: route,
        slmUrl: slmRoute ? `${route}/${slmRoute}` : null,
      }
      lessonInfos.push(lessonInfo)
    }
    return lessonContainer
  }, [])

  transformed.push({
    lessonTitle: lessonTitle,
    lessonInfos: lessonInfos,
  })

  return transformed
}

export const processTabRoutes = courseContainer => {
  if (!courseContainer || !courseContainer.courses) {
    return []
  }

  const accessibleCourses = courseContainer.courses.filter(
    course => !course.redacted,
  )

  return accessibleCourses.map(course => {
    const { pageData, displayTitle, internalTitle } = course || {}
    const id = internalTitle
    const { route } = pageData || {}

    return { title: displayTitle, id: id, route: route }
  })
}

export const getLastSlug = path => {
  let modifiedPath = path
  const lastCharacterIndex = path.length - 1
  const lastCharacter = path[lastCharacterIndex]

  if (lastCharacter === '/') {
    modifiedPath = modifiedPath.slice(0, modifiedPath.length - 1)
  }

  if (modifiedPath.length > 0) {
    const pathSlugs = modifiedPath.split('/')
    const lastSlug = pathSlugs.pop()

    return lastSlug
  }

  return null
}
