import CompleteIcon from './assets/ss_hsmyprograms_complete.svg'
import CurrentIcon from './assets/ss_hsmyprograms_currentpathway.svg'

export function getIconForTab({ isComplete, isInCurrentPacing }) {
  if (isComplete) {
    return {
      altText: 'complete',
      icon: CompleteIcon,
    }
  }
  if (isInCurrentPacing) {
    return {
      altText: 'current',
      icon: CurrentIcon,
    }
  }
  return {
    altText: null,
    icon: null,
  }
}
