import styledNative from 'styled-components/native'

export const Container = styledNative.View`
  width: ${width => width || '100%'};
  height: auto;
`
export const StyledImage = styledNative.Image`
  height:  ${({ presentedHeight }) => presentedHeight}; 
  width: ${({ presentedWidth }) => presentedWidth};
`
