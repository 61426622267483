import styled from 'styled-components'
import constants from 'themes/variables'

export const TopBar = styled.div`
  align-self: stretch;
  background: white;
`

export const Constrainer = styled.div`
  max-width: ${constants.breakpoints.sizes.large};
  margin: 0 auto;
  display: flex;
  align-items: start;
  padding: 1rem;
`
