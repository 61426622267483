import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  Hairline,
  HeaderOne,
  HeaderTwo,
  HeaderThree,
  BaseContainer,
} from 'secondstep-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
  box-sizing: border-box;
  padding: 0 1.25rem;

  p {
    margin-block-end: 1rem;
    margin-block-start: 1rem;
  }

  ${themeGet('breakpoints.tablet')} {
    align-items: flex-start;
    padding: 0 ${themeGet('spacing.blue16_1rem')};

    p {
      margin-block-end: ${themeGet('spacing.turquoise20_1_25rem')};
      margin-block-start: ${themeGet('spacing.turquoise20_1_25rem')};
    }
  }
`

export const ContainerSection = styled(BaseContainer)`
  background-color: ${themeGet('colors.lighterGray')};
  padding: ${themeGet('spacing.amber28_1_75rem')};
  margin-top: ${themeGet('spacing.turquoise20_1_25rem')};
  margin-bottom: ${themeGet('spacing.amber28_1_75rem')};

  ${themeGet('breakpoints.mobileLarge')} {
    padding: ${themeGet('spacing.amber28_1_75rem')}
      ${themeGet('spacing.turquoise20_1_25rem')};
    width: 100%;
  }
`

export const ViewWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;

  ${themeGet('breakpoints.mobileLarge')} {
    flex-direction: column;

    &.lesson-materials-list-prep-pdfs,
    &.lesson-materials-list-reinforce-pdfs {
      flex-direction: row;
      align-items: self-end;

      ~ .materials-list-prep-pdfs {
        padding-left: 0;
      }
    }
  }
`

export const MainViewColumn = styled.div`
  flex-basis: 100%;
  flex: 2;
  margin: 0 ${themeGet('spacing.amber28_1_75rem')} 0 0;

  ${themeGet('breakpoints.mobileLarge')} {
    margin: 0;
  }
`

export const SecondaryViewColumn = styled(BaseContainer)`
  flex-basis: 100%;
  flex: 1;
  padding: ${themeGet('spacing.amber28_1_75rem')};
  margin-top: 0;

  ${themeGet('breakpoints.mobileLarge')} {
    padding-left: ${themeGet('spacing.turquoise20_1_25rem')};
    padding-right: ${themeGet('spacing.turquoise20_1_25rem')};

    ul {
      margin-top: 0;
      padding-left: ${themeGet('spacing.blue16_1rem')};
    }
  }
`

export const SplitColumn = styled.div`
  flex: 1;
  margin-right: ${themeGet('spacing.amber28_1_75rem')};
`

export const DividerHairline = styled(Hairline)`
  margin: ${themeGet('spacing.turquoise20_1_25rem')} 0;
`

export const EmphasisHairline = styled(Hairline)`
  margin: ${themeGet('spacing.yellow12_0_75rem')} 0
    ${themeGet('spacing.blue16_1rem')} 0;
`

export const StyledHeaderOne = styled(HeaderOne)`
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('fontFamilyHeadingBold')};
  margin: 0 0 ${themeGet('spacing.amber28_1_75rem')} 0;

  ${themeGet('breakpoints.mobileLarge')} {
    margin-top: 2.5rem;
  }
`

export const StyledHeaderTwo = styled(HeaderTwo)`
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('fontFamilyHeadingBold')};
  margin: 0;
`

export const StyledHeaderThree = styled(HeaderThree)`
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('fontFamilyBold')};
  margin: 0;
`
export const StyledHeaderThreeNoWrap = styled(StyledHeaderThree)`
  white-space: nowrap;
  margin-top: ${themeGet('blue16_1rem')};
`
