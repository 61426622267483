import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const TranslatedPdfWrapper = styled.div`
  .target-locale {
    display: inline;

    & input {
      font-family: 'Molde-Regular';
      font-weight: 400;
      box-sizing: content-box;
      /*This is a hack to overcome Chrome on Mac's handling of box-sizing: content-box*/
      min-width: 30ch;
    }

    & input::placeholder {
      color: ${themeGet('colors.input.text')};
    }

    // Hide labels from display but leave for AT
    label {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute !important;
      width: 1px;
    }
  }
`
