import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  BodyCopy,
  ContainedButton,
  HeaderTwo,
  IconWithText,
  WarningIcon,
} from 'secondstep-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.75rem 0 2.5rem;
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`
export const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.3125rem 0;
`

export const SchoolStatusText = styled(BodyCopy)`
  font-size: 1.4375rem;
  color: ${themeGet('colors.darkBlue')};
  font-family: ${themeGet('font.sharpSlab.semibold')};
`

export const StyledIconWithText = styled(IconWithText).attrs(props => {
  return {
    svgColor: props.isComplete
      ? themeGet('colors.darkBlue')(props)
      : themeGet('colors.highschool.pink1')(props),
  }
})`
  font-size: 1.0625rem;
  font-family: ${themeGet('font.sharpSlab.semibold')};
  color: ${({ svgColor }) => svgColor};
`

export const StyledImage = styled.img`
  height: 120px;
  width: 120px;
  border-radius: 10px;
  margin-top: 0.1875rem;
  margin-bottom: 1.5rem;

  ${themeGet('breakpoints.tablet')} {
    margin-bottom: 1.1875rem;
  }
`

export const StyledDescription = styled(BodyCopy)`
  padding-top: 0.8125rem;
`

export const StyledHeaderTwo = styled(HeaderTwo)`
  color: ${themeGet('colors.darkBlue')};
  font-size: 1.4375rem;
  line-height: 1.5625rem;
  margin-bottom: 0;
  text-align: center;

  ${themeGet('breakpoints.mobile')} {
    font-size: 1.4375rem;
    line-height: 1.5625rem;
  }
`

export const StyledSiteName = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamily')};
  font-size: 0.875rem;
  letter-spacing: 0.88px;
  padding-bottom: 0.3125rem;
`

export const StyledBodyCopy = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};
  margin: 0;
  margin-top: 1.5rem;
`
export const StyledContainedButton = styled(ContainedButton)`
  width: 155px;
  align-self: center;
  border-radius: 0.3125rem;
  line-height: 1;
  background-color: ${themeGet('colors.blue')};
  border-color: ${themeGet('colors.blue')};
  padding: 0.8125rem 2.625rem 0.6875rem;
  margin-top: 1.4375rem;

  &:not([disabled]):hover {
    background: ${themeGet('colors.linkLight')};
    border-color: ${themeGet('colors.darkBlue')};
    color: ${themeGet('colors.darkBlue')};
  }

  &:focus {
    background: ${themeGet('colors.linkLight')};
    border-color: ${themeGet('colors.darkBlue')};
    color: ${themeGet('colors.darkBlue')};
  }
`

export const StyledError = styled.span`
  color: ${themeGet('colors.highschool.pink1')};
  display: flex;
  font-size: 0.9rem;
  margin: 0.75rem 0rem;
  vertical-align: text-top;
  line-height: 1;
`

export const SetupButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 28px;
`

export const StyledWarningIcon = styled(WarningIcon)`
  display: inline-block;
  height: 0.75rem;
  margin-right: 0.25rem;
  width: 0.75rem;

  circle {
    fill: ${themeGet('colors.highschool.pink1')};
  }
`

export const TitleWrapper = styled.div`
  display: flex;
`
