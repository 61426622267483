import { PROGRAM_OST, GRADES_k1, GRADES_23, GRADES_45 } from './constants'

export const getProductType = internalTitle => {
  const isOstProduct = internalTitle.includes(PROGRAM_OST)
  const isOstProductk1 = isOstProduct && internalTitle.includes(GRADES_k1)
  const isOstProduct23 = isOstProduct && internalTitle.includes(GRADES_23)
  const isOstProduct45 = isOstProduct && internalTitle.includes(GRADES_45)

  return {
    isOstProductk1,
    isOstProduct23,
    isOstProduct45,
    isOstProduct,
  }
}
