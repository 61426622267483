import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  StyledContainer,
  StyledTelescopeIcon,
  StyledStrongText,
} from './component.styles'
import {
  BANNER_SCROLL_THRESHOLD,
  MARGIN_TOP_DEFAULT,
  MARGIN_TOP_MOBILE,
  MARGIN_TOP_SCROLL,
  MOBILE_LARGE_BREAKPOINT,
} from './constants'
import hsPreviewBanner from './assets/ss_highschoolpreview_banner.svg'

const HighSchoolPreviewBanner = ({ strongText, text }) => {
  const [dynamicCSSPosition, setDynamicCSSPosition] = useState('relative')
  const [dynamicMarginTop, setDynamicMarginTop] = useState(
    window.innerWidth <= MOBILE_LARGE_BREAKPOINT
      ? MARGIN_TOP_MOBILE
      : MARGIN_TOP_DEFAULT,
  )

  const handleScroll = () => {
    if (window.scrollY > BANNER_SCROLL_THRESHOLD) {
      setDynamicCSSPosition('fixed')
      setDynamicMarginTop(MARGIN_TOP_SCROLL)
    } else {
      setDynamicCSSPosition('relative')
      // need a negative offset if at the top of the screen:
      if (window.innerWidth <= MOBILE_LARGE_BREAKPOINT) {
        setDynamicMarginTop(MARGIN_TOP_MOBILE)
      } else {
        setDynamicMarginTop(MARGIN_TOP_DEFAULT)
      }
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => window.removeEventListener('scroll', handleScroll)
  }, [])

  return (
    <StyledContainer
      bannerImage={hsPreviewBanner}
      style={{
        position: dynamicCSSPosition,
        marginTop: dynamicMarginTop,
      }}
    >
      <StyledTelescopeIcon aria-hidden="true" role="presentation" />
      <StyledStrongText>{strongText}</StyledStrongText>
      {text && <p>{text}</p>}
    </StyledContainer>
  )
}

HighSchoolPreviewBanner.propTypes = {
  strongText: PropTypes.string.isRequired,
  text: PropTypes.string,
}

export default HighSchoolPreviewBanner
