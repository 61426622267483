import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { AnchoredContentLinkWrapper } from './component.styles'

export default class AnchoredContentLink extends Component {
  static propTypes = {
    link: PropTypes.shape({
      hash: PropTypes.string,
      id: PropTypes.string,
      text: PropTypes.string,
    }).isRequired,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
    navigationEvent: PropTypes.func,
  }

  onClick = () => {
    const { navigationEvent, link, location } = this.props
    const { hash } = link || {}
    if (!hash) {
      return
    }

    const { pathname, search } = location || {}
    const pathWithHash = `${pathname}${search}${hash}`
    navigationEvent && navigationEvent(pathWithHash)
  }

  render() {
    const { link, index } = this.props || {}
    const { id, hash, text } = link || {}

    return (
      <AnchoredContentLinkWrapper
        dataTestId={`anchored-text-link-${index}-${id}`}
        hash={hash}
        key={id}
        onClick={this.onClick}
      >
        {text}
      </AnchoredContentLinkWrapper>
    )
  }
}
