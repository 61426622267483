import styledNative from 'styled-components/native'
import styled, { withTheme } from 'styled-components'
import { themeGet } from 'styled-system'
import { DefaultSiteSelectorWrapper } from 'components/SiteSelector'

const breakpoints = {
  aboveTablet: '@media (max-width: 1050px)',
}

// changing this to a div for the time being to allow for inset shadow, this is temporary
export const PageWrapper = styled.div.attrs(({ alternateBackground }) => ({
  background: alternateBackground
    ? themeGet('colors.backgrounds.tertiary', 'colors.white')
    : themeGet('colors.backgrounds.neutralGray', 'none'),
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  background: ${({ background }) => background};
`

export const AnchoredContentWrapper = styled.div`
  width: 100%;
  margin-top: -3.75rem;
  position: relative;

  li {
    ::marker {
      color: ${themeGet('colors.markerOverride') ?? 'inherit'};
    }
  }

  ${breakpoints.aboveTablet} {
    margin-right: 0px;
  }
  ${themeGet('breakpoints.tablet')} {
    display: flex;
    flex-direction: column;
  }
`

export const AnchoredPageWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
  background-color: transparent;
`

export const AnchoredArticleWrapper = styled.div.attrs(props => {
  const borderColor = themeGet('colors.borders.primary')(props)
  const border = themeGet('callouts.border')(props)
  if (border) {
    return { border }
  }
  return {
    border: borderColor && `2px solid ${borderColor}`,
  }
})`
  width: 100%;
  padding: 1rem 1.75rem 0.9375rem 1.75rem;
  margin-bottom: 1.563rem;
  box-sizing: border-box;
  border: ${({ border }) => border};
  border-radius: 0.625rem;
  background-color: ${themeGet('callouts.backgroundColor', 'colors.white')};
  box-shadow: ${themeGet('callouts.boxShadow', 'none')};

  ${themeGet('breakpoints.mobile')} {
    padding-top: 0.5rem;
    padding-bottom: 0.125rem;
  }
`

export const ContentWrapper = withTheme(
  styledNative.View(props => {
    const borderColor = themeGet('colors.borders.primary')(props)
    const border = themeGet('callouts.border')(props)

    return {
      backgroundColor: themeGet('colors.white')(props),
      borderRadius: '0.625rem',
      border: border ?? (borderColor && `2px solid ${borderColor}`),
      boxShadow: themeGet('callouts.boxShadow', 'none'),
      boxSizing: 'border-box',
      marginBottom: '2.5rem',
      marginTop: '-3.75rem',
      maxWidth: themeGet('breakpoints.sizes.large')(props),
      padding: '1.75rem',
      paddingBottom: '2.5rem',
      width: '100%',
    }
  }),
)

export const GrayBackground = styled.div.attrs(({ alternateBackground }) => ({
  background: alternateBackground
    ? themeGet('colors.backgrounds.tertiary')
    : themeGet('colors.backgroundNeutralGray', 'colors.contentBackgroundGray'),
}))`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  padding: 0 1.25rem;
  background-color: ${({ background }) => background};
`

export const AnchorNavigationWrapper = styled.div`
  margin-top: -3.75rem;
  margin-right: 1.25rem;
  min-width: 205px;

  ${breakpoints.aboveTablet} {
    display: none;
  }
`
export const BannerSpacer = styled.div`
  margin-top: 7rem;
`
export const SiteSelectorWrapper = styled(DefaultSiteSelectorWrapper)`
  padding-right: 0;
  max-width: ${themeGet('breakpoints.sizes.large')};
  margin: auto;

  ${breakpoints.aboveTablet} {
    padding-right: 1.25rem;
  }
`
