import { withRouter } from 'react-router-dom'
import { SpinnerAnimatedIcon } from 'secondstep-components'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import withApiError, {
  ERROR_TYPE_CONTENTFUL,
  SHOW_CONTENT_ON_401,
} from 'layers/errorHandling/apiError'

import MonitorSupport from './component'
import { ENTRY_ID } from './constants'
import { LoadingWrapper } from './component.styles'

const Container = props => {
  const { heading2, tabContent } = props || {}

  if (!tabContent) {
    return (
      <LoadingWrapper>
        <SpinnerAnimatedIcon />
        <p>Loading...</p>
      </LoadingWrapper>
    )
  }
  return <MonitorSupport tabs={tabContent} title={heading2} />
}

const mapper = entry => {
  return entry
}
const options = {
  include: 3,
  mapper,
  entryId: ENTRY_ID,
  prefetch: ['es-US'],
  spread: true,
}

export default ContentEntryDataGetter(
  withRouter(
    withApiError(Container, [ERROR_TYPE_CONTENTFUL, SHOW_CONTENT_ON_401]),
  ),
  options,
)
