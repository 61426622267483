import React from 'react'
import PropTypes from 'prop-types'

import ToggleOption from '../RadioToggle/ToggleOption'
import { StyledRadioToggle } from './component.styles'

const ButtonToggle = ({ description, options = [], onSelect, value = '' }) => {
  return (
    <StyledRadioToggle
      description={description}
      onSelect={onSelect}
      options={options}
      value={value}
    >
      {Array.isArray(options) &&
        options.map(option => {
          const { id } = option
          return <ToggleOption key={id} option={option} />
        })}
    </StyledRadioToggle>
  )
}

ButtonToggle.propTypes = {
  description: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
    }),
  ).isRequired,
  value: PropTypes.string.isRequired,
}

export default ButtonToggle
