import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  BodyCopy,
  ContainedButton,
  Hairline,
  HeaderTwo,
} from 'secondstep-components'

export const BodyCopyWrapper = styled.div`
  border-radius: 10px;
  padding: 1rem 1rem 0.8125rem 1.25rem;
  background-color: ${themeGet('colors.paleGray')};
`

export const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.375rem;
`

export const ChecklistWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.375rem 1.25rem 1.25rem;
  border-radius: 0.625rem;
  background: ${themeGet('colors.white')};
  position: relative;
  box-sizing: border-box;
`

export const FooterWrapper = styled.div`
  display: flex;
  margin-top: auto;
`

export const HeaderWrapper = styled.div`
  display: flex;

  ${themeGet('breakpoints.medium')} {
    flex-direction: column;
  }
`

export const CompletedTag = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 31px;
  padding: 0 1.25rem;
  position: absolute;
  right: 0;
  bottom: 20px;

  p {
    position: relative;
    margin-top: 9px;
    z-index: 1;
    line-height: 1rem;
    color: ${themeGet('colors.secondStepBlue')};
  }
`

export const LeftButton = styled.button.attrs(props => {
  const background = props.disabled
    ? themeGet('colors.beaconGray')(props)
    : themeGet('colors.blueGray')(props)
  const hoverBackground = props.disabled
    ? themeGet('colors.beaconGray')(props)
    : themeGet('colors.secondStepBlue')(props)
  const cursor = props.disabled ? 'default' : 'pointer'
  return {
    background,
    cursor,
    'data-testid': props.dataTestId,
    hoverBackground,
  }
})`
  display: inline-block;
  background: ${({ background }) => background};
  padding-top: 0.375rem;
  margin-right: 0.5rem;
  border: none;
  border-radius: 0.125rem;
  cursor: ${({ cursor }) => cursor};
  transition: all 0.2s ease;

  svg {
    color: ${themeGet('colors.white')};
    transform: rotate(180deg);
  }

  &:hover {
    background: ${({ hoverBackground }) => hoverBackground};
    transition: all 0.2s ease;
  }

  ${themeGet('breakpoints.admin.large')} {
    display: none;
  }

  ${themeGet('breakpoints.medium')} {
    display: inline-block;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    display: none;
  }
`

export const PageButtonsWrapper = styled.div``

export const RightButton = styled(LeftButton)`
  margin-right: 0;

  svg {
    transform: none;
  }
`

export const StyledBodyCopy = styled(BodyCopy)`
  p {
    font-family: ${themeGet('fontFamilySemibold')};
    margin-block-end: 0 !important;
    margin-bottom: 0;
  }

  ul {
    padding-left: 1rem;
    margin: 0;

    li {
      font-family: ${themeGet('fontFamily')};
      margin-top: 0.5rem;
    }
  }
`

export const StyledButton = styled(ContainedButton)`
  padding: 0.1875rem 1.0625rem 0;
  border-radius: 0.9375rem;
`

export const StyledHairline = styled(Hairline)`
  height: 2px;
  margin: 0.25rem 0 0.9375rem;
  background: ${themeGet('colors.paleGray')};

  ${themeGet('breakpoints.admin.large')} {
    margin-bottom: 1.1875rem;
  }

  ${themeGet('breakpoints.medium')} {
    margin-bottom: 0.9375rem;
  }
`

export const StyledHeaderTwo = styled(HeaderTwo)``

export const SubHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.875rem;

  ${themeGet('breakpoints.admin.large')} {
    margin-bottom: 1.0625rem;
  }

  ${themeGet('breakpoints.medium')} {
    margin-bottom: 0.875rem;
  }
`

export const TabsWrapper = styled.div`
  display: flex;
`

export const Tab = styled.button.attrs(props => {
  const borderBottom = props.isSelected
    ? `2px solid ${themeGet('colors.secondStepBlue')(props)}`
    : `2px solid ${themeGet('colors.transparent')(props)}`
  const color = props.isSelected
    ? themeGet('colors.secondStepBlue')(props)
    : themeGet('colors.darkGray')(props)
  const cursor = props.isSelected ? 'default' : 'pointer'
  const fontFamily = props.isSelected
    ? themeGet('font.molde.medium')(props)
    : themeGet('font.molde.regular')(props)
  return {
    borderBottom,
    color,
    cursor,
    'data-testid': props.dataTestId,
    fontFamily,
  }
})`
  padding: 0;
  margin-right: 1.625rem;
  border: none;
  border-bottom: ${({ borderBottom }) => borderBottom};
  color: ${({ color }) => color};
  background: none;
  font-size: 1.125rem;
  line-height: 1.125rem;
  font-family: ${({ fontFamily }) => fontFamily};
  cursor: ${({ cursor }) => cursor};
  transition: all 0.2s ease;

  &:hover {
    border-bottom: 2px solid ${themeGet('colors.darkGray')};
    transition: all 0.2s ease;
  }

  ${themeGet('breakpoints.admin.large')} {
    &:last-of-type {
      margin-right: 0;
    }
  }
`

export const TagBackground = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background: ${themeGet('colors.elemDigital1')};
  opacity: 30%;

  &::before {
    content: '';
    position: absolute;
    top: 4px;
    left: -11px;
    width: 21px;
    height: 22px;
    transform: rotate(45deg);
    background: ${themeGet('colors.elemDigital1')};
  }
`
