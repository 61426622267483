import {
  SELA_LEADER_PROGRAM,
  SELA_STAFF_PROGRAM,
  SELAFT_LEADER_PROGRAM,
  SELAFT_STAFF_PROGRAM,
  PROGRAM_TRACK_NAME,
} from './constants'

const isSelaProgram = name =>
  name === SELA_LEADER_PROGRAM ||
  name === SELA_STAFF_PROGRAM ||
  name === SELAFT_LEADER_PROGRAM ||
  name === SELAFT_STAFF_PROGRAM

const filterPrograms = content_tracks => {
  return content_tracks?.filter(content => {
    const { content_track } = content || {}
    return isSelaProgram(content_track)
  })
}

const sortProgramTracks = content_tracks =>
  content_tracks
    ?.map(({ display_title, content_track }) => {
      if (isSelaProgram(content_track))
        return {
          key: content_track,
          name:
            PROGRAM_TRACK_NAME[content_track] ??
            display_title.replace('Track', '').trim(),
        }
    })
    .sort((a, b) => b.name.localeCompare(a.name)) || [] // descending sorted, so staff goes first

export const formatProgramModules = modules => {
  let options = []

  modules?.forEach(({ displayTitle, id, label }) => {
    const newLabel = label?.includes('Module') ? `${label}: ` : ''
    options.push({ key: id, name: `${newLabel}${displayTitle}` })
  })

  return options
}

export const filterTrackOptions = (selectedModule, content_tracks) => {
  let selaPrograms = filterPrograms(content_tracks)

  selaPrograms = selaPrograms?.filter(content =>
    content.content_children?.some(
      ({ contentful_id }) => contentful_id === selectedModule?.id,
    ),
  )

  const programTrackOptions = sortProgramTracks(selaPrograms)

  return programTrackOptions
}

export const getTrackFromModule = (moduleId, content_tracks) => {
  for (let index = 0; index < content_tracks.length; index++) {
    const moduleExist = content_tracks[index].content_children?.some(
      ({ contentful_id }) => contentful_id === moduleId,
    )
    if (moduleExist) return content_tracks[index]
  }

  return null
}

export const findUserStatusTotal = (userStatus, inviteStatuses) => {
  const status = inviteStatuses?.find(
    item => item.license_status.toLowerCase() === userStatus.toLowerCase(),
  )
  return status?.total || 0
}
