import { FlagsComponentRouter } from 'flags'

import OffComponent from './flag.off/index'
import OnComponent from './flag.on/index'

const FlagToggler = FlagsComponentRouter({
  flagSubscriptions: [
    'middle-school-leader-dashboard-optin-functionality-release-2',
  ],
  OnComponent,
  OffComponent,
})

export default FlagToggler
