import styled from 'styled-components'
import { Box } from 'grommet'
import { themeGet } from 'styled-system'
import { HeaderFive, PipeIcon } from 'secondstep-components'

export const LessonABWrapper = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 1.625rem 1.6875rem 1.625rem 5rem;
  z-index: 1;
  background-color: ${themeGet('colors.lessonABBackground')};

  ${HeaderFive} {
    max-width: 60%;
    margin-bottom: 0;
    font-size: 1.25rem;
  }

  ${themeGet('breakpoints.tablet')} {
    ${HeaderFive} {
      margin-top: -0.125rem;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: flex-start;

    ${HeaderFive} {
      max-width: 100%;
      margin-bottom: 1.0625rem;
    }

    @media (max-width: 500px) {
      padding-left: 3.0625rem;
    }
  }
`

export const LinksBox = styled(Box)`
  flex-direction: row;
  align-items: center;

  ${PipeIcon} {
    color: ${themeGet('colors.buttons.iconBase')};
    margin: 0 0.5rem;
  }
`

export const ChooseLessonBox = styled(Box)`
  padding: 0 0 1.5rem 5rem;
  margin-top: -0.5rem;

  @media (max-width: 500px) {
    padding-left: 3.0625rem;
  }
`
