import { FlagsComponentRouter } from 'flags'

import {
  isHighSchoolFamilyResources,
  isNewFamilyResources,
  isHighSchoolExtensionActivities,
} from 'utils/productHelpers'

import OffComponent from './flag.off/index'
import OnComponent from './flag.on/index'

const FlagToggler = FlagsComponentRouter({
  flagSubscriptions: [
    'feature_LEARN-18248-hs-family-resources',
    'feature-learn-17909-program-flex-family-resources',
    'feature_learn-18453-hs-student-extension-activities',
  ],
  OnComponent,
  OffComponent,
  onCondition: (_, subscribedFlags) =>
    isHighSchoolFamilyResources(_, subscribedFlags) ||
    isNewFamilyResources(_, subscribedFlags) ||
    // allow for new HS Extension Activities
    isHighSchoolExtensionActivities(_, subscribedFlags),
})

export default FlagToggler
