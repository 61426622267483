import React from 'react'
import PropTypes from 'prop-types'
import {
  BodyCopy,
  HeaderTwo,
  SpinnerAnimatedIconMedium,
} from 'secondstep-components'

import AccessibleTextLink from 'components/AccessibleTextLink'
import SelaReportsDropdown from 'components/SelaReportsDropdown'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { domains } from 'config/env'

import {
  MODULES_DROPDOWN_LABEL_TEXT,
  TRACKS_DROPDOWN_LABEL_TEXT,
  USER_MANAGEMENT_LINK_TEXT,
} from './constants'
import {
  filterTrackOptions,
  formatProgramModules,
  getTrackFromModule,
} from './helpers'
import {
  DropdownsWrapper,
  LeftContent,
  LeftDropdown,
  LicenseUserData,
  RightContent,
  RightDropdown,
  Separator,
  UserTypesWrapper,
  UserTypesTooltip,
  Wrapper,
} from './component.styles'

const ReportsLicenseCard = ({
  isFetching,
  modules,
  navigationEvent,
  selaReport,
  selectedLicense,
  selectedTrack,
  setSelectedTrack,
  selectedModule,
  setSelectedModule,
  tooltipCopy,
  totalAcceptedUsers,
  totalInvitedUsers,
  totalLicenseUsers,
  updateSelectedContentTrack,
  updateSelectedModuleId,
  validateReportData,
}) => {
  if (isFetching && (!selectedLicense || !totalLicenseUsers)) {
    validateReportData(true)
    return (
      <Wrapper>
        <SpinnerAnimatedIconMedium />
      </Wrapper>
    )
  }

  const { content_tracks } = selaReport || {}

  if (!isFetching && (!totalLicenseUsers || content_tracks?.length < 1)) {
    validateReportData(false)
  }

  const programModuleOptions = formatProgramModules(modules)
  const programTrackOptions = filterTrackOptions(selectedModule, content_tracks)

  const handleModuleChange = e => {
    const { key } = e
    const findModule = track => {
      const trackModule = track?.content_children?.find(
        ({ contentful_id }) => contentful_id === key,
      )

      if (!trackModule) {
        content_tracks.forEach(track => {
          const trackModuleExists = track.content_children.some(
            ({ contentful_id }) => contentful_id === key,
          )
          if (trackModuleExists) {
            const matchingModule = modules.find(
              programModule => programModule.id === key,
            )

            setSelectedModule(matchingModule)
            updateSelectedModuleId(matchingModule.id)
            setSelectedTrack(track)
            updateSelectedContentTrack(track.content_track)
          }
        })
      }

      const matchingModule = modules.find(
        programModule => programModule.id === trackModule.contentful_id,
      )

      return matchingModule
    }

    let matchingModule = findModule(selectedTrack)

    if (!matchingModule) {
      const track = getTrackFromModule(key, content_tracks)
      matchingModule = findModule(track)

      setSelectedTrack(track)
      updateSelectedContentTrack(track.content_track)
    }

    setSelectedModule(matchingModule)
    updateSelectedModuleId(matchingModule.id)
    const trackType = selectedTrack.content_track.split(' ')[1]

    if (!matchingModule?.permissionRoles?.internalTitle?.includes(trackType)) {
      const newTrack =
        content_tracks.find(track => track.content_track.includes(trackType)) ||
        {}
      setSelectedTrack(newTrack)
      updateSelectedContentTrack(newTrack.content_track)
    }
  }

  const handleTrackChange = e => {
    const { key } = e
    const matchingTrack =
      content_tracks?.find(({ content_track }) => content_track === key) || {}

    setSelectedTrack(matchingTrack)
    updateSelectedContentTrack(matchingTrack.content_track)
  }

  const navigateOutOfAppOnClick = () => {
    const type = NavigationHelper.types.OPEN
    const url = `${domains.ADMIN_DOMAIN}/user-management/${selectedLicense.siteId}`

    navigationEvent(url, type)
  }

  const { licenseId, product, siteName: schoolName } = selectedLicense
  const licenseInfoText = `${product} | ID: ${licenseId}`
  const totalLicensedUsersText = `**${totalLicenseUsers} Users**`
  const userTotalsByGroupText = `| ${totalInvitedUsers} Invited • ${totalAcceptedUsers} Active`
  const selectedModuleFormatted = programModuleOptions.find(
    ({ key }) => key === selectedModule?.id,
  )
  const selectedTrackFormatted = programTrackOptions.find(
    ({ key }) => key === selectedTrack?.content_track,
  )

  return (
    <Wrapper>
      <HeaderTwo>{schoolName}</HeaderTwo>
      <LicenseUserData>
        <LeftContent>
          <BodyCopy>{licenseInfoText}</BodyCopy>
        </LeftContent>
        <RightContent>
          <UserTypesWrapper>
            <BodyCopy>{totalLicensedUsersText}</BodyCopy>
            <UserTypesTooltip content={<BodyCopy>{tooltipCopy}</BodyCopy>}>
              <i className="fas fa-question-circle" />
            </UserTypesTooltip>
            <BodyCopy>{userTotalsByGroupText}</BodyCopy>
          </UserTypesWrapper>
          <AccessibleTextLink
            dataTestId="accessible-link-manage-invitations"
            onClick={navigateOutOfAppOnClick}
          >
            {USER_MANAGEMENT_LINK_TEXT}
          </AccessibleTextLink>
        </RightContent>
      </LicenseUserData>
      <Separator />
      <DropdownsWrapper>
        <LeftDropdown>
          <SelaReportsDropdown
            dataTestId="select-program-modules"
            label={MODULES_DROPDOWN_LABEL_TEXT}
            onChange={handleModuleChange}
            options={programModuleOptions}
            value={selectedModuleFormatted}
          />
        </LeftDropdown>
        <RightDropdown>
          <SelaReportsDropdown
            dataTestId="select-program-tracks"
            label={TRACKS_DROPDOWN_LABEL_TEXT}
            onChange={handleTrackChange}
            options={programTrackOptions}
            value={selectedTrackFormatted}
          />
        </RightDropdown>
      </DropdownsWrapper>
    </Wrapper>
  )
}

ReportsLicenseCard.propTypes = {
  isFetching: PropTypes.bool,
  modules: PropTypes.array,
  navigationEvent: PropTypes.func,
  selaReport: PropTypes.object,
  selectedLicense: PropTypes.object,
  selectedModule: PropTypes.object,
  selectedTrack: PropTypes.object,
  setSelectedModule: PropTypes.func,
  setSelectedTrack: PropTypes.func,
  tooltipCopy: PropTypes.string,
  totalAcceptedUsers: PropTypes.number,
  totalInvitedUsers: PropTypes.number,
  totalLicenseUsers: PropTypes.number,
  updateSelectedContentTrack: PropTypes.func,
  updateSelectedModuleId: PropTypes.func,
  validateReportData: PropTypes.func,
}

export default ReportsLicenseCard
