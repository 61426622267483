import React, { Fragment, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import withApiError, {
  ERROR_TYPE_REPORTING,
} from 'layers/errorHandling/apiError'
import {
  operations as reportsOperations,
  selectors as reportsSelectors,
} from 'store/reportsManager'
import { TOGGLE_VIEW } from 'store/userSessionManager/constants'
import { TOOLTIP_LOCATIONS, TOOLTIP_PROGRAMS } from 'utils/tooltipHelpers'
import AdminSortButton from 'components/AdminSortButton'
import LeftBox from 'components/SortableAccordionList/flag.on/SortableAccordionLeftBox'
import AccordionListItem from './AccordionListItem'
import { StyledSortableAccordionList } from './component.styles'
import {
  PENDING_COPY,
  SCHOOLS_COPY,
  SETUP_COPY,
  sortOrders,
  SITE_HEADER_LAUNCH,
  SITE_HEADER_MONITOR,
  titlesBySortKeyMap,
  USERS_COPY,
} from './constants'

const renderLeftBox = (summary, toggleView) => {
  const { schoolsRequiringSetup, totalPendingCount, totalSchools, totalUsers } =
    summary || {}
  const siteHeader =
    toggleView === TOGGLE_VIEW.LAUNCH ? SITE_HEADER_LAUNCH : SITE_HEADER_MONITOR
  const props = {
    header: siteHeader,
    subHeaderRows: [
      {
        label: summary.totalSchools,
        copy: SCHOOLS_COPY(totalSchools),
      },
      { label: totalUsers, copy: USERS_COPY(totalUsers) },
      {
        label: totalPendingCount,
        copy: PENDING_COPY(totalPendingCount),
      },
    ],
    bottomHeader: schoolsRequiringSetup
      ? SETUP_COPY(schoolsRequiringSetup)
      : null,
  }
  return <LeftBox {...props} />
}

export const AdminHSMultiSiteContainer = ({
  adminEmail,
  fetchHsAdminSnapshot,
  hsToggleView,
  isFetching,
  lastUpdatedAt,
  selectedProgramKey,
  sites,
  sitesPerPage,
  summary,
  totalPages,
}) => {
  const history = useHistory()
  const location = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [
    location.search,
  ])

  const [sortKey, setSortKey] = useState('school')
  const [sortOrder, setSortOrder] = useState(sortOrders.asc)
  const [currentPage, setCurrentPage] = useState(1)

  useEffect(() => {
    if (!sites) {
      fetchHsAdminSnapshot(adminEmail)
    }
  }, [sites])

  useEffect(() => {
    searchParams.set('sortKey', sortKey)
    searchParams.set('sortOrder', sortOrder)
    searchParams.set('pageNumber', currentPage)
    history.replace({ search: searchParams.toString() })
  }, [sortKey, sortOrder, currentPage])

  const handleSort = newSortKey => {
    if (sortKey === newSortKey) {
      setSortOrder(
        sortOrder === sortOrders.asc ? sortOrders.desc : sortOrders.asc,
      )
    } else {
      setSortKey(newSortKey)
      setSortOrder(sortOrders.asc)
    }
  }

  // TODO: this component is tied to launch context, refactor using context provider or hoc
  const sortButtonData = [
    {
      sortKey: 'school',
      get title() {
        return titlesBySortKeyMap[this.sortKey]
      },
      handleOnClick: handleSort,
      tooltipLocation: TOOLTIP_LOCATIONS.LAUNCH_SITE,
    },
    {
      sortKey: 'users',
      get title() {
        return titlesBySortKeyMap[this.sortKey]
      },
      handleOnClick: handleSort,
      tooltipLocation: TOOLTIP_LOCATIONS.LAUNCH_USERS,
    },
    {
      sortKey: 'training',
      get title() {
        return titlesBySortKeyMap[this.sortKey]
      },
      handleOnClick: handleSort,
      tooltipLocation: TOOLTIP_LOCATIONS.LAUNCH_TRAINING_COMPLETION,
    },
  ]

  const monitorSortButtonData = [
    {
      sortKey: 'school',
      get title() {
        return titlesBySortKeyMap[this.sortKey]
      },
      handleOnClick: handleSort,
      tooltipLocation: TOOLTIP_LOCATIONS.SITES_TABLE_SCHOOL,
    },
    {
      sortKey: 'pacing',
      get title() {
        return titlesBySortKeyMap[this.sortKey]
      },
      handleOnClick: handleSort,
      tooltipLocation: TOOLTIP_LOCATIONS.SITES_TABLE_PACING,
    },
    {
      sortKey: 'schoolwidePractices',
      get title() {
        return titlesBySortKeyMap[this.sortKey]
      },
      handleOnClick: handleSort,
      tooltipLocation: TOOLTIP_LOCATIONS.SITES_TABLE_SCHOOLWIDE_PRACTICES,
    },
    {
      sortKey: 'educatorPractices',
      get title() {
        return titlesBySortKeyMap[this.sortKey]
      },
      handleOnClick: handleSort,
      tooltipLocation: TOOLTIP_LOCATIONS.SITES_TABLE_EDUCATOR_PRACTICES,
    },
    {
      sortKey: 'studentActivities',
      get title() {
        return titlesBySortKeyMap[this.sortKey]
      },
      handleOnClick: handleSort,
      tooltipLocation: TOOLTIP_LOCATIONS.SITES_TABLE_STUDENT_ACTIVITIES,
    },
  ]

  return (
    <Fragment key="admin-hs-multi-site-container">
      <StyledSortableAccordionList
        currentItemCount={sites?.length}
        currentPage={currentPage}
        handlePageChange={setCurrentPage}
        isFetching={isFetching}
        itemLabel="School"
        items={sites}
        itemsPerPage={sitesPerPage}
        lastUpdatedAt={lastUpdatedAt}
        renderLeftBox={() => renderLeftBox(summary, hsToggleView)}
        renderSortButton={props => (
          <AdminSortButton
            {...props}
            monitorView={hsToggleView === TOGGLE_VIEW.MONITOR}
            programKey={
              hsToggleView === TOGGLE_VIEW.MONITOR
                ? TOOLTIP_PROGRAMS.HS_MONITOR
                : TOOLTIP_PROGRAMS.HS
            }
          />
        )}
        showHairline={true}
        sortButtons={
          hsToggleView === TOGGLE_VIEW.LAUNCH
            ? sortButtonData
            : monitorSortButtonData
        }
        sortKey={sortKey}
        sortOrder={sortOrder}
        toggleView={hsToggleView}
        totalItems={summary?.totalSchools}
        totalPages={totalPages}
      >
        {(site, index, isOpen, handleListItemClick) => {
          const props = {
            site,
            index,
            isMultiSite: true,
            isOpen,
            handleListItemClick,
            selectedProgramKey,
            toggleView: hsToggleView,
          }
          return <AccordionListItem {...props} />
        }}
      </StyledSortableAccordionList>
    </Fragment>
  )
}

const mapStateToProps = state => {
  const {
    reportsManager: {
      selectedProgram,
      admin: {
        hs: {
          isFetching,
          launchPagination: { sitesPerPage, totalCount, totalPages },
        },
        lastUpdatedAt,
      },
    },
    userProfileManager: {
      profile: { email },
    },
    userSessionManager: {
      adminDashboard: {
        hs: { toggleView: hsToggleView },
      },
    },
  } = state
  // TODO: this component is tied to launch context, refactor using context provider or hoc
  const hsSitesWithPreferences = reportsSelectors.selectSortedAdminHsSnapshotSites(
    state,
  )
  const launchSnapshotSummary = reportsSelectors.selectAdminHsSnapshotSummary(
    state,
  )
  return {
    adminEmail: email,
    hsToggleView,
    lastUpdatedAt: lastUpdatedAt?.last_updated_at,
    selectedProgramKey: selectedProgram?.key,
    sites: hsSitesWithPreferences,
    sitesPerPage,
    summary: launchSnapshotSummary,
    totalCount,
    totalPages,
    isFetching,
  }
}

const mapDispatchToProps = {
  fetchHsAdminSnapshot: reportsOperations.fetchHsAdminSnapshot,
}

AdminHSMultiSiteContainer.propTypes = {
  adminEmail: PropTypes.string,
  fetchHsAdminSnapshot: PropTypes.func,
  hsToggleView: PropTypes.string,
  isFetching: PropTypes.bool,
  lastUpdatedAt: PropTypes.string,
  selectedProgramKey: PropTypes.string,
  sites: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        siteName: PropTypes.string,
        siteId: PropTypes.number,
        users: PropTypes.array,
        preferences: PropTypes.shape({
          areUsersAdded: PropTypes.bool,
          implementationLevel: PropTypes.number,
          implementationType: PropTypes.string,
          isLaunched: PropTypes.bool,
          isSetup: PropTypes.bool,
          studentActivities: PropTypes.bool,
        }),
        adminsPendingCount: PropTypes.number,
        teachersPendingCount: PropTypes.number,
        totalUsersCount: PropTypes.number,
      }),
    ), // fully hydrated
    PropTypes.object, // fetching or error
    PropTypes.oneOf([null]), // not hydrated
  ]),
  sitesPerPage: PropTypes.number,
  summary: PropTypes.shape({
    totalSchools: PropTypes.number,
    schoolsRequiringSetup: PropTypes.number,
    totalUsers: PropTypes.number,
  }),
  totalPages: PropTypes.number,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withApiError(AdminHSMultiSiteContainer, [ERROR_TYPE_REPORTING]))
