import React from 'react'
import PropTypes from 'prop-types'
import { GRADE_METADATA_ID, processGrades } from 'utils/highschoolHelpers'
import { toCamelCase } from 'utils/stringHelpers'
import {
  StyledContainer,
  StyledHeaderFour,
  MetadataContainer,
  MetadataColumn,
  MetadataCell,
} from './component.styles'

// export these functions for testability:
export const getMetadataValues = (facet, resourceMetadata) => {
  const facets = resourceMetadata?.metadataValues?.filter(meta => {
    if (!meta?.contentType) {
      return false
    }
    return (
      meta?.contentType?.id === facet?.facetId ||
      toCamelCase(meta?.contentType) === facet?.facetId
    )
  })

  if (!facets) {
    return ''
  }

  if (facet?.facetId === GRADE_METADATA_ID) {
    // remove "Grade(s) " label with regex:
    return processGrades(facets).replace(/[a-zA-Z]+ /, '')
  }

  return facets.map(f => f[facet?.facetPropertyName]).join(', ')
}

export const getColumnDisplayValues = (columnFacets, resourceMetadata) => {
  const columnDisplay = []
  for (let i = 0; i < columnFacets.length; i++) {
    const colValue = getMetadataValues(columnFacets[i], resourceMetadata)
    if (!colValue) {
      continue
    }

    columnDisplay.push({
      label: columnFacets[i].displayTitle,
      value: colValue,
    })
  }
  return columnDisplay
}

const HsIndividualResourceMetadata = ({
  audience,
  facetMetadataSetting,
  resourceMetadata,
}) => {
  const {
    column1Facets,
    column1Label,
    column2Facets,
    column2Label,
  } = facetMetadataSetting
  return (
    <StyledContainer>
      <div className="content">
        <MetadataContainer audience={audience}>
          <MetadataColumn audience={audience}>
            {/* apply some extra styling to the left header */}
            <StyledHeaderFour audience={audience} left>
              {column1Label}
            </StyledHeaderFour>
            <dl>
              {getColumnDisplayValues(column1Facets, resourceMetadata).map(
                (facet, index) => (
                  <MetadataCell key={`${facet.label}-${index}`}>
                    <dt>
                      <strong>{facet.label}</strong>
                    </dt>
                    <dd>{facet.value}</dd>
                  </MetadataCell>
                ),
              )}
            </dl>
          </MetadataColumn>
          <MetadataColumn audience={audience}>
            <StyledHeaderFour audience={audience}>
              {column2Label}
            </StyledHeaderFour>
            <dl>
              {getColumnDisplayValues(column2Facets, resourceMetadata).map(
                (facet, index) => (
                  <MetadataCell key={`${facet.label}-${index}`}>
                    <dt>
                      <strong>{facet.label}</strong>
                    </dt>
                    <dd>{facet.value}</dd>
                  </MetadataCell>
                ),
              )}
            </dl>
          </MetadataColumn>
        </MetadataContainer>
      </div>
    </StyledContainer>
  )
}

HsIndividualResourceMetadata.propTypes = {
  audience: PropTypes.string,
  facetMetadataSetting: PropTypes.object,
  resourceMetadata: PropTypes.object,
}

export default HsIndividualResourceMetadata
