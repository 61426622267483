import { ENGLISH_LOCALE, SPANISH_LOCALE } from 'layers/content/data'
import { LOCALES } from 'layers/content/constants'

import ContentfulParser from 'utils/contentfulParser/contents'

export const contentComponentsProps = {
  advisoryActivity: content => ContentfulParser.advisoryActivityCard(content),
  book: content => ContentfulParser.book(content),
  contentBlock: content => ContentfulParser.contentBlock(content),
  multimediaGrouping: content =>
    ContentfulParser.activityMultiMediaGroupingCard(content),
}

export const LOCALE_OPTIONS = [
  { id: ENGLISH_LOCALE, title: LOCALES[ENGLISH_LOCALE] },
  { id: SPANISH_LOCALE, title: LOCALES[SPANISH_LOCALE] },
]

export const getLanguageToggleOptions = languageToggle => {
  if (!languageToggle) {
    return null
  }

  const { enUs, esUs } = languageToggle

  if (enUs && esUs) {
    return LOCALE_OPTIONS
  }

  return null
}
