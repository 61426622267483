import styled from 'styled-components'
import { ResponsiveContainer } from 'recharts'

export const ChartResponsiveContainer = styled(ResponsiveContainer)`
  .chart-y-axis .recharts-cartesian-axis-tick:first-child {
    display: none;
  }

  .recharts-surface {
    overflow: visible;
  }
`
