/* istanbul ignore file */
// TODO [LEARN-15811]: ^ ignore coverage on this file until we can have app test scaffolding ready
import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Grommet } from 'grommet'
import { TabButton, TabSet } from 'secondstep-components'
import { launchPresentation } from 'utils/playerHelper'
import HsIndividualResourceActivityDetails from 'components/HsIndividualResourceActivityDetails'
import HsIndividualResourceMetadata from 'components/HsIndividualResourceMetadata'
import HsIndividualResourceReferences from 'components/HsIndividualResourceReferences'
import HsIndividualResourceMarkComplete from 'components/HsIndividualResourceMarkComplete'
import HighSchoolPreviewBanner from 'components/HighSchoolPreviewBanner'
import {
  getAudienceMetadata,
  getGradeMetadata,
  getTypeMetadata,
} from 'utils/highschoolHelpers'
import {
  tabTheme,
  ActivityButton,
  AudienceTagContainer,
  ControlPanel,
  DetailsContainer,
  ExternalLinkIcon,
  HeaderContainer,
  PageContainer,
  StyledHeaderOne,
  SurveyLink,
  BannerSpacer,
  SiteSelectorWrapper,
  HsIndividualResourceMarkCompleteWrapper,
} from './component.styles'
import { SURVEY_TEXT, LAUNCH_BUTTON_TEXT } from './constants'
import externalLink from './assets/external_link.svg'
import withSiteContext from 'layers/content/v2/Hocs/withSiteContext'
import SiteSelector from 'components/SiteSelector'

const HsIndividualResource = ({ entry, navigationEvent, isPreview }) => {
  const {
    activityDetails,
    facetMetadataSetting,
    image,
    // rename this so it sounds less like a function:
    markComplete: markCompleteContent,
    presentation,
    references,
    resourceMetadata,
    surveyLink,
  } = entry || {}

  const [activeActivityIndex, setActiveActivityIndex] = useState(0)
  const [reference, setReference] = useState()
  const [openAccordionReference, setOpenAccordionReference] = useState(false)
  const supRef = useRef()

  const scrollToBottom = () => {
    setTimeout(() => supRef.current.scrollIntoView({ behavior: 'smooth' }), 500)
  }

  if (!entry) {
    return null
  }

  const { description, displayTitle, courseNodeConnector } =
    resourceMetadata || {}
  const { courseNodeEntryPoint: courseNodeId } = courseNodeConnector || {}
  const audience = getAudienceMetadata(resourceMetadata)
  const grades = getGradeMetadata(resourceMetadata)
  const type = getTypeMetadata(resourceMetadata)

  const handleReferences = refClicked => {
    const referenceClicked = refClicked.replace(/[^\w\s]/gi, '')
    if (refClicked && referenceClicked) {
      setOpenAccordionReference(true)
      setReference(referenceClicked)
      scrollToBottom()
    }
  }

  const handleActivityTabs = activityIndex => {
    if (openAccordionReference) {
      setOpenAccordionReference(!openAccordionReference)
    }

    setReference(null)
    setActiveActivityIndex(activityIndex)
  }

  const launchActivity = (isPreview = false) => {
    const { id } = presentation
    const theme = 'gradehs'
    const locale = 'en-US'
    const mode = isPreview ? 'preview' : ''
    const lessonTitle = resourceMetadata?.routeSlug
    launchPresentation({
      entryId: id,
      locale,
      mode,
      navigationEvent,
      theme,
      lessonTitle,
    })
  }

  const shouldShowMarkComplete = ({ markCompleteContent, isPreview }) => {
    if (isPreview) {
      return false
    }
    if (!markCompleteContent) {
      return false
    }
    return true
  }

  return (
    <PageContainer>
      {isPreview && (
        <>
          <BannerSpacer />
          <HighSchoolPreviewBanner />
        </>
      )}
      {!isPreview && (
        <SiteSelectorWrapper>
          <SiteSelector />
        </SiteSelectorWrapper>
      )}
      <HeaderContainer>
        <div className="content">
          <div>
            <AudienceTagContainer audience={audience}>
              {!!type && <div>{type}</div>}
              {!!grades && grades.length && <div>{grades}</div>}
            </AudienceTagContainer>
            <StyledHeaderOne>{displayTitle}</StyledHeaderOne>
            <p>{description}</p>
            {!!surveyLink && (
              <SurveyLink
                href={surveyLink}
                rel="noopener noreferrer"
                target="_blank"
              >
                {SURVEY_TEXT}
                <ExternalLinkIcon
                  alt={'External Link Icon'}
                  src={externalLink}
                />
              </SurveyLink>
            )}
            {shouldShowMarkComplete({ markCompleteContent, isPreview }) && (
              <HsIndividualResourceMarkCompleteWrapper>
                <HsIndividualResourceMarkComplete
                  // 'audience' is a very overloaded term, but in this particular component it means which type activity this is
                  activityType={audience}
                  courseNodeId={courseNodeId}
                  markCompleteContent={markCompleteContent}
                  practiceName={displayTitle}
                />
              </HsIndividualResourceMarkCompleteWrapper>
            )}
          </div>
          <div>
            <img
              alt={image?.description}
              className="resource-image"
              src={image?.url}
            />
          </div>
        </div>
      </HeaderContainer>
      <HsIndividualResourceMetadata
        audience={audience}
        facetMetadataSetting={facetMetadataSetting}
        resourceMetadata={resourceMetadata}
      />
      <ControlPanel>
        <div className="content">
          {/* override the theme for tab buttons: */}
          <Grommet theme={tabTheme}>
            <TabSet>
              {activityDetails.map((activity, index) => (
                <TabButton
                  dataTestId={`${activity.displayTitle
                    .toLowerCase()
                    .replace(' ', '-')}-tab`}
                  isActive={index === activeActivityIndex}
                  key={`${activity.displayTitle.toLowerCase()}-${index}`}
                  label={activity.displayTitle}
                  onClick={() => handleActivityTabs(index)}
                />
              ))}
            </TabSet>
          </Grommet>
          {!!presentation && (
            <ActivityButton onClick={() => launchActivity(isPreview)}>
              {LAUNCH_BUTTON_TEXT}
            </ActivityButton>
          )}
        </div>
      </ControlPanel>
      <DetailsContainer audience={audience}>
        <div className="content">
          {!!activityDetails[activeActivityIndex] && (
            <HsIndividualResourceActivityDetails
              audience={audience}
              details={activityDetails[activeActivityIndex]}
              referenceHandler={handleReferences}
            />
          )}

          {!!references && (
            <HsIndividualResourceReferences
              audience={audience}
              focusedReference={reference}
              isOpen={openAccordionReference}
              ref={supRef}
              references={references}
              setAccordionCollapse={setOpenAccordionReference}
            />
          )}
        </div>
      </DetailsContainer>
    </PageContainer>
  )
}

HsIndividualResource.propTypes = {
  entry: PropTypes.object,
  isPreview: PropTypes.bool,
  navigationEvent: PropTypes.func,
}

export default withSiteContext(HsIndividualResource, {
  useGuard: true,
})
