import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy } from 'secondstep-components'
import ContinueButton from 'components/ContinueButton'

export const ScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-items: flex-start;
  padding: 14px 20px 20px;

  ${themeGet('breakpoints.mobile')} {
    padding: 14px 20px 8px;
    height: auto;
  }
`

export const ScheduleCopy = styled(BodyCopy)`
  color: ${themeGet('colors.darkGray')};
  text-align: center;
  margin-bottom: 2px;

  ${themeGet('breakpoints.mobile')} {
    text-align: start;
  }
`

export const PacingContinue = styled(ContinueButton)`
  width: 162px;
  align-self: center;
  margin-top: 40px;
  margin-bottom: 20px;

  ${themeGet('breakpoints.mobile')} {
    margin-top: 28px;
  }
`
