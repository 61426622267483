import React from 'react'
import PropTypes from 'prop-types'
import { useContentGetter } from 'layers/content/v2/hooks/useContentGetter'

export default function ContentDataGetter(Component, options = {}) {
  const { entryId, queryType, locale, isPreview } = options

  function ComponentWithDataGetter(props) {
    const content = useContentGetter({
      entryId,
      queryType,
      locale,
      isPreview,
    })

    return <Component {...props} content={content} />
  }

  ComponentWithDataGetter.propTypes = {
    content: PropTypes.object,
    fetchContent: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    userContext: PropTypes.object,
  }

  return ComponentWithDataGetter
}
