export const findProgramLinks = (
  programQuickLinks,
  selectedProgram,
  programAccessMap,
) => {
  const match = programQuickLinks.find(({ permissionsPrograms }) => {
    if (!permissionsPrograms) {
      return false
    }
    if (selectedProgram.name === 'K8') {
      return (
        permissionsPrograms?.[
          programAccessMap?.[selectedProgram.name]?.k5program
        ] &&
        permissionsPrograms?.[
          programAccessMap?.[selectedProgram.name]?.msprogram
        ]
      )
    }
    return permissionsPrograms?.[
      programAccessMap?.[selectedProgram.name]?.program
    ]
  })
  return match?.supportLinks ?? []
}
