import React from 'react'
import PropTypes from 'prop-types'
import {
  ActivityDetail,
  ActivityTags,
  AdvisoryActivityCardWrapper,
  AdvisoryActivityInfo,
  Tag,
  TagText,
  TitleAndDescription,
  LinkStyled,
} from './component.styles'
import HeaderBodyCopy from '../HeaderBodyCopy'
import { toPascalCase } from '../../utils/stringHelpers'
import { ProgramThemeIcons } from 'secondstep-components'
import NavigationHelper from '../../layers/navigation/navigationHelper'

export default class AdvisoryActivityCard extends React.Component {
  static propTypes = {
    description: PropTypes.string.isRequired,
    displayTitle: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    location: PropTypes.object.isRequired,
    metadata: PropTypes.array.isRequired,
    navigationEvent: PropTypes.func.isRequired,
    pageData: PropTypes.object.isRequired,
    slpType: PropTypes.string,
    time: PropTypes.string,
  }

  constructor(props) {
    super(props)

    this.state = {
      isHovered: false,
    }
  }

  navigateToPage = () => {
    const { navigationEvent, pageData, location } = this.props
    const { route } = pageData || {}
    const { pathname = '' } = location || {}
    const depth = pathname.split('/').length - 1

    const isDuplicatePath = NavigationHelper.isADuplicatePath(pathname, route)

    if (route && !isDuplicatePath) {
      const fullRoute = NavigationHelper.generateUrlWithDepthForRoute(
        pathname,
        route,
        depth,
      )
      navigationEvent && navigationEvent(fullRoute)
    } else {
      console.error('link missing in a advisory activity card')
    }
  }

  hoverManager = isHovered => {
    this.setState({ isHovered })
  }

  render() {
    const {
      description,
      displayTitle,
      id,
      time = '',
      metadata = [],
      slpType = '',
      index,
    } = this.props
    const { isHovered } = this.state

    const activityCardPrefix = `activity-card-${index}`
    const activityCardLink = `${activityCardPrefix}-link-${id}`
    const activityDetail = slpType || time
    return (
      <LinkStyled
        href="javascript:void(0)"
        onClick={this.navigateToPage}
        onMouseEnter={() => this.hoverManager(true)}
        onMouseLeave={() => this.hoverManager(false)}
      >
        <AdvisoryActivityCardWrapper
          data-testid={activityCardLink}
          isHovered={isHovered}
        >
          <AdvisoryActivityInfo>
            <TitleAndDescription>
              <HeaderBodyCopy
                bodyCopy={description}
                h3Text={displayTitle}
                id={id}
              />
            </TitleAndDescription>
            <ActivityDetail>{activityDetail}</ActivityDetail>
          </AdvisoryActivityInfo>
          <ActivityTags>
            {metadata.map(({ programTheme }) => {
              const iconKey = `${toPascalCase(programTheme)}Icon`
              const Icon = ProgramThemeIcons[iconKey]
              return (
                <Tag key={programTheme}>
                  {Icon && <Icon />}
                  <TagText>{programTheme}</TagText>
                </Tag>
              )
            })}
          </ActivityTags>
        </AdvisoryActivityCardWrapper>
      </LinkStyled>
    )
  }
}
