import styled, { withTheme } from 'styled-components'
import { Image } from 'react-native'
import styledNative from 'styled-components/native'
import { themeGet } from 'styled-system'
import { TextLink, HeaderThree, BodyCopy } from 'secondstep-components'

export const Container = withTheme(styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  background-color: ${themeGet('callouts.backgroundColor')};
  border-radius: 0.688rem;
  border: ${themeGet('callouts.border', 'none')};
  box-shadow: ${themeGet('callouts.boxShadow', 'none')};
  flex-wrap: wrap;
  flex-direction: row;
  margin-bottom: ${themeGet('spacing.turquoise20_1_25rem')};
  gap: ${themeGet('spacing.amber28_1_75rem')};
  padding: ${themeGet('spacing.amber28_1_75rem')};

  ${themeGet('breakpoints.mobileLarge')} {
    padding: ${themeGet('spacing.turquoise20_1_25rem')}
      ${themeGet('spacing.turquoise20_1_25rem')}
      ${themeGet('spacing.amber28_1_75rem')};
    margin-bottom: ${themeGet('spacing.blue16_1rem')};
  }
`)

export const Left = withTheme(styled.div`
  display: flex;
`)

export const Icon = withTheme(
  styledNative(Image)(props => {
    const borderRadiusShorthand = themeGet(
      'resources.callouts.iconBorderRadius',
      '0.688rem 0.688rem 0.688rem 0.688rem',
    )(props)
    const [
      borderTopLeftRadius,
      borderTopRightRadius,
      borderBottomRightRadius,
      borderBottomLeftRadius,
    ] = borderRadiusShorthand.split(' ')
    return {
      borderBottomLeftRadius,
      borderBottomRightRadius,
      borderTopLeftRadius,
      borderTopRightRadius,
      height: '11.563rem',
      width: '17.813rem',
    }
  }),
)

export const Right = withTheme(styled.div`
  display: flex;
  min-width: 15rem;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`)

export const HeaderText = styled(HeaderThree)`
  margin-bottom: 0;
  color: ${themeGet('callouts.headerTextColor')};
  font-size: ${themeGet('spacing.amber28_1_75rem')};
  font-family: ${themeGet('callouts.headerTextFont')};
  font-weight: ${themeGet('callouts.headerTextFontWeight')};
  line-height: ${themeGet('spacing.amber28_1_75rem')};
`

export const BodyText = styled(BodyCopy)`
  color: ${themeGet('callouts.bodyTextColor')};
  flex-wrap: wrap;
  margin-top: ${themeGet('spacing.blue16_1rem')};

  ${themeGet('breakpoints.mobileLarge')} {
    margin-top: ${themeGet('spacing.turquoise20_1_25rem')};
  }
`

export const LearnMoreLink = styled(TextLink)`
  cursor: pointer;
  margin-top: ${themeGet('spacing.amber28_1_75rem')};
  font-family: ${themeGet('fontFamilySemibold')};
  width: fit-content;
`
export const Oval = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 178px;
  width: 178px;
  background-color: #d0efe5;
  border-radius: 50%; /* Makes it oval */
`
