import ListItem from './component'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'

const mapper = (entry, _, ownProps) => {
  const {
    additionalResources = [],
    buttonLabel = 'Training',
    description = '',
    displayTitle = '',
    trainingFile,
  } = entry || {}
  const {
    attempted,
    choiceLessons = [],
    dataTestId,
    instance,
    isFirstModule,
    scoID,
    time = 'Not Available',
  } = ownProps || {}

  const { choiceText = '' } = trainingFile || {}
  return {
    additionalResources,
    attempted,
    buttonLabel,
    choiceLessons,
    choiceText,
    dataTestId,
    description,
    displayTitle,
    instance,
    isFirstModule,
    scoID,
    time,
  }
}
const options = {
  include: 3,
  mapper,
  spread: true,
  entryIdProp: true,
}

export default ContentEntryDataGetter(ListItem, options)
