import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Box } from 'grommet'
import { FormInputText, SuccessIcon, TextLink } from 'secondstep-components'
import {
  CancelButton,
  ButtonBox,
  IconBox,
  InputBox,
  InputBoxWrapper,
  StyledErrorText,
  StyledInputField,
  Terms,
  UnlockButton,
  Wrapper,
} from './component.styles'

import {
  CANCEL_BUTTON,
  ERROR_MESSAGE,
  FORM_INPUT_TEXT,
  FORM_PLACEHOLDER_TEXT,
  SUBMIT_BUTTON,
  TERMS_DESC,
} from './constants'

export const CodeActivationForm = ({
  postActivationCode,
  className,
  dataTestId = 'pak-activation-form',
  isValidActivationCode,
  isValidating,
  modalClose,
  passClearValue,
  setOpen,
  showCancel,
  validateActivationCode,
}) => {
  const [visualActivationCode, setVisualActivationCode] = useState('')
  const activationCodeLength = 12

  const trimSpaces = text => text.replace(/\s/g, '')
  const activationCodeHasValidLength = activationCode =>
    activationCode.length === activationCodeLength

  const activationCode = trimSpaces(visualActivationCode)

  const customMaxLength = activationCodeHasValidLength(activationCode)
    ? visualActivationCode.length
    : activationCodeLength * 2

  const isActivationCodeFormDisabled = !activationCode || !isValidActivationCode
  const showValidationWarning =
    !isValidating &&
    !isValidActivationCode &&
    activationCodeHasValidLength(activationCode)

  const activationCodeHandleChange = e => {
    const { value } = e.target

    const rawValue = value
      .replace(/[^0-9a-zA-Z\s]/g, '') // remove invalid chars
      .replace(/^\s/, '') // remove empty space if it's at the beggining
      .replace(/\s+/g, ' ') // merge double space

    setVisualActivationCode(rawValue)

    const valueTrimmed = trimSpaces(rawValue)
    if (activationCodeHasValidLength(valueTrimmed) || isValidActivationCode) {
      validateActivationCode(valueTrimmed)
    }
  }

  const submitActivationCode = async () => {
    if (setOpen) {
      setOpen(false)
    }
    setVisualActivationCode('')
    await postActivationCode(activationCode)
    window.location.reload()
  }

  const clearValue = () => {
    setVisualActivationCode('')
  }

  if (passClearValue) passClearValue(clearValue)

  return (
    <Wrapper className={className} dataTestId={dataTestId}>
      <InputBoxWrapper>
        <InputBox>
          <StyledInputField
            isValidActivationCode={isValidActivationCode && activationCode}
            showValidationWarning={showValidationWarning}
          >
            <FormInputText
              customCharValue={activationCode}
              customMaxLength={customMaxLength}
              dataTestId={dataTestId}
              label={FORM_INPUT_TEXT}
              maxLength={activationCodeLength}
              onChange={activationCodeHandleChange}
              placeholder={FORM_PLACEHOLDER_TEXT}
              value={visualActivationCode}
            />
          </StyledInputField>
          <IconBox>
            {isValidActivationCode && activationCode && (
              <Box animation="fadeIn">
                <SuccessIcon dataTestId="icon-success" />
              </Box>
            )}
          </IconBox>
        </InputBox>
        {showValidationWarning && (
          <StyledErrorText dataTestId="invalid-pak-message">
            {ERROR_MESSAGE}
          </StyledErrorText>
        )}
      </InputBoxWrapper>
      <Terms>
        {TERMS_DESC}
        <TextLink href="//www.secondstep.org/terms-of-use">
          Terms of Use
        </TextLink>
      </Terms>
      <ButtonBox showCancel={showCancel}>
        <>
          {showCancel && (
            <CancelButton
              dataTestId={'button-cancel-modal-pak'}
              onClick={modalClose}
            >
              {CANCEL_BUTTON}
            </CancelButton>
          )}
          <UnlockButton
            dataTestId="button-submit-pak-number"
            disabled={isActivationCodeFormDisabled}
            onClick={submitActivationCode}
            showCancel={showCancel}
          >
            {SUBMIT_BUTTON}
          </UnlockButton>
        </>
      </ButtonBox>
    </Wrapper>
  )
}

CodeActivationForm.propTypes = {
  className: PropTypes.string,
  dataTestId: PropTypes.string,
  isValidActivationCode: PropTypes.bool.isRequired,
  isValidating: PropTypes.bool.isRequired,
  modalClose: PropTypes.func,
  passClearValue: PropTypes.func,
  postActivationCode: PropTypes.func.isRequired,
  setOpen: PropTypes.func,
  showCancel: PropTypes.bool,
  validateActivationCode: PropTypes.func.isRequired,
}

export default CodeActivationForm
