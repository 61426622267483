import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BaseContainer, BodyCopy } from 'secondstep-components'
import { getHighlightColor } from 'utils/highschoolHelpers'
import TextLinkWithFileIcon from 'components/TextLinkWithFileIcon'

export const ContentWrapper = styled.div`
  width: 38.75rem;
  @media screen and (max-width: 660px) {
    // avoid text overflowing container:
    // when screen is narrower than 620px + 20px padding on both sides
    width: unset;
    max-width: 100%;
  }
`

export const Spacer = styled.div`
  width: 100%;
  // height is a number in Contentful specifying pixel height:
  height: ${props => props.height}px;
`

export const StyledContainer = styled(BaseContainer)`
  display: flex;
  align-items: center;
  width: 100%;
  background-color: white;
  border: 1.5px solid ${props => getHighlightColor(props.audience)};
  margin: -3.6875rem 0 1.3rem;
  padding: 1.5rem 1.75rem 1.3rem;
  line-height: 1.5rem;
  // breakpoints.large (1366px) + margin:
  @media screen and (max-width: 1400px) {
    width: calc(100% - 2.5rem);
  }
  ${themeGet('breakpoints.tablet')} {
    padding: 1.5rem 3.375rem 1rem 3.375rem;
  }
  ${themeGet('breakpoints.mobileLarge')} {
    padding: 1.25rem;
    padding-bottom: 2.5rem;
  }
  li::marker {
    color: ${themeGet('colors.markerOverride')};
  }
  li:not(:last-child) {
    margin-bottom: 0.5rem;
  }
  h2,
  h3,
  h4 {
    font-family: ${themeGet('font.komet')};
    margin: 0;
  }
  h2 {
    color: ${themeGet('colors.heading.level.2')};
    font-weight: ${themeGet('fonts.kometHeavy28.weight')};
    font-size: ${themeGet('fonts.kometHeavy28.fontSize')};
    padding-top: 0.5rem;
    padding-bottom: 0.695rem;
  }
  h3,
  h4 {
    font-weight: bold;
  }
  h3 {
    color: ${themeGet('colors.heading.level.3')};
    font-size: 1.25rem;
    padding-top: 0.875rem;
    padding-bottom: 0.75rem;
  }
  h4 {
    color: ${themeGet('colors.heading.level.4')};
    font-size: 1.0625rem;
    padding-top: 0.375rem;
    padding-bottom: 0.35rem;
  }
  ${BodyCopy} {
    padding-bottom: 1.1725rem;
  }
  [class*='r-marginTop'] {
    margin-top: 0;
  }
  a,
  a:visited {
    text-decoration: none;
    color: ${themeGet('colors.hsPrimaryPink')};
  }
  a:focus,
  a:hover {
    color: ${themeGet('colors.highschool.green1')};
  }
  p,
  li,
  span {
    line-height: 1.5rem;
  }
  p:not(:last-child) {
    margin-block-end: 0.87rem;
  }
  sup {
    color: ${themeGet('colors.hsPrimaryPink')};
  }
  ul,
  ol {
    margin-block-end: 0.87rem;
    padding-left: 1.5rem;
  }
  ol {
    list-style-position: outside;
  }
  [class*='ImageCalloutButton'] {
    padding-bottom: 1.75rem;
  }
`

export const StyledTextLinkWithFileIcon = styled(TextLinkWithFileIcon)`
  margin-bottom: 0.5rem;
  color: ${themeGet('colors.hsPrimaryPink')};
  fill: ${themeGet('colors.hsPrimaryPink')};
  :hover {
    color: ${themeGet('colors.highschool.green1')};
    fill: ${themeGet('colors.highschool.green1')};
  }
`
