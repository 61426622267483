import styled from 'styled-components'
import { themeGet } from 'styled-system'
import {
  Hairline,
  HeaderOne,
  HeaderTwo,
  BaseContainer,
} from 'secondstep-components'

export const ContainerSection = styled(BaseContainer)`
  background-color: ${themeGet('colors.lighterGray')};
  padding: ${themeGet('spacing.amber28_1_75rem')};
  margin-top: ${themeGet('spacing.turquoise20_1_25rem')};
  margin-bottom: ${themeGet('spacing.amber28_1_75rem')};
`

export const DividerHairline = styled(Hairline)`
  margin: ${themeGet('spacing.turquoise20_1_25rem')} 0;
`

export const StyledHeaderOne = styled(HeaderOne)`
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('fontFamilyHeadingBold')};
  margin: 0 0 ${themeGet('spacing.amber28_1_75rem')} 0;
`

export const StyledHeaderTwo = styled(HeaderTwo)`
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('fontFamilyHeadingBold')};
  margin: 0;
`
