import styled from 'styled-components'
import { Modal, ModalContentWrapper } from 'secondstep-components'

export const Header2 = styled.h2`
  margin-bottom: 1.5rem;
`

export const StyledModal = styled(Modal)`
  ${ModalContentWrapper} {
    box-sizing: border-box;
    width: ${props => props.width};
    height: ${props => props.height};
    padding: 2.8125rem 1.75rem 1.75rem;
  }
`
