import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ContentEntryDataGetter from 'layers/content/Hocs/ContentEntryDataGetter'
import withApiError from 'layers/errorHandling/apiError/component'
import {
  ERROR_TYPE_CONTENTFUL,
  SHOW_CONTENT_ON_401,
} from 'layers/errorHandling/apiError'

import {
  operations as siteOperations,
  actions as siteActions,
} from 'store/siteManager'
import StepModalComponent from './component'

const ProgramKeyMap = {
  HS: 'highSchool',
}

const implementationLevelStepNumber = 2

const StepModalContainer = ({
  clearError,
  confirmedOptions,
  currentStep: initCurrentStep,
  flowLabel,
  handleClose,
  hasError,
  modals,
  programKey,
  siteId,
  updateSitePreferences,
  savedPreferences,
}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [selections, setSelections] = useState({
    implementationType: null,
    implementationLevel: null,
    studentActivities: null,
  })

  const [selectedOptions, setSelectedOptions] = useState([])
  const [isLevelChangeConfirmed, setIsLevelChangeConfirmed] = useState(false)
  const [displayConfirmationPanel, setDisplayConfirmationPanel] = useState(
    false,
  )
  const [savedPreferencesCopy, setSavedPreferencesCopy] = useState({
    ...savedPreferences,
  })

  const handleConfirmationChange = event =>
    setIsLevelChangeConfirmed(event.target.checked)

  useEffect(() => {
    if (savedPreferences) {
      setSelections({
        implementationType: savedPreferences?.implementationType?.toLowerCase(),
        implementationLevel: savedPreferences?.implementationLevel,
        studentActivities: savedPreferences?.studentActivities,
      })
    }
  }, [])

  useEffect(() => {
    setIsLevelChangeConfirmed(false)
    setDisplayConfirmationPanel(
      currentStep === implementationLevelStepNumber &&
        savedPreferencesCopy.implementationLevel !== null,
    )
  }, [currentStep])

  const handleStepChange = async toStep => {
    if (toStep > 1 && toStep < modals.length) {
      await updateSitePreferences(siteId, {
        [ProgramKeyMap[programKey]]: selections,
      })
      setSavedPreferencesCopy({ ...selections })
    }

    setCurrentStep(toStep)
  }

  const handleSelection = choice => {
    const value =
      choice?.value?.string || choice?.value?.number || choice?.value?.boolean
    let key
    let newSelectedOptions = selectedOptions

    switch (typeof value) {
      case 'string':
        key = 'implementationType'
        newSelectedOptions[0] = choice
        break

      case 'number':
        setDisplayConfirmationPanel(
          savedPreferencesCopy?.implementationLevel !== null &&
            savedPreferencesCopy?.implementationLevel !== value,
        )
        setIsLevelChangeConfirmed(false)
        key = 'implementationLevel'
        newSelectedOptions[1] = choice
        break

      case 'boolean':
        key = 'studentActivities'
        newSelectedOptions[2] = choice
        break

      default:
        break
    }
    setSelectedOptions(newSelectedOptions)
    if (!key || value == null) return
    setSelections({ ...selections, [key]: value })
  }

  useEffect(() => {
    setCurrentStep(initCurrentStep || 0)
  }, [initCurrentStep])

  useEffect(() => {
    if (confirmedOptions) setSelections(confirmedOptions)
  }, [confirmedOptions])

  return (
    <StepModalComponent
      confirmedOptions={selectedOptions}
      currentStep={currentStep}
      displayConfirmationPanel={displayConfirmationPanel}
      flowLabel={flowLabel}
      handleClose={() => {
        clearError('sitePreferences')
        handleClose()
      }}
      handleSelection={handleSelection}
      handleStepChange={handleStepChange}
      hasError={hasError}
      isLevelChangeConfirmed={isLevelChangeConfirmed}
      modals={modals}
      onConfirmationChange={handleConfirmationChange}
      savedPreferences={savedPreferences}
    />
  )
}

const mapStateToProps = ({ siteManager }) => {
  const { sitePreferences } = siteManager
  return { hasError: !!sitePreferences?.error }
}

const mapDispatchToProps = {
  updateSitePreferences: siteOperations.updateSitePreferences,
  clearError: siteActions.clearError,
}

const mapper = entry => {
  const { flowLabel, modals } = entry || {}
  return {
    flowLabel,
    modals,
  }
}

const options = {
  entryId: '3EYeQge3T6CyatYrDhwVXN',
  include: 3,
  mapper,
  spread: true,
}

StepModalContainer.propTypes = {
  clearError: PropTypes.func,
  confirmedOptions: PropTypes.array,
  currentStep: PropTypes.number,
  dispatch: PropTypes.func,
  fetchUserSites: PropTypes.func,
  flowLabel: PropTypes.string,
  handleClose: PropTypes.func,
  hasError: PropTypes.bool,
  modals: PropTypes.array,
  programKey: PropTypes.string,
  savedPreferences: PropTypes.object,
  siteId: PropTypes.number,
  updateSitePreferences: PropTypes.func,
}

export default ContentEntryDataGetter(
  withApiError(
    StepModalContainer,
    [ERROR_TYPE_CONTENTFUL],
    [SHOW_CONTENT_ON_401],
  ),
  options,
  connect(mapStateToProps, mapDispatchToProps),
)
