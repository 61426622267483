import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import PlainProgramCard from 'components/dashboard/PlainProgramCard'
import FooterLogos from 'components/dashboard/FooterLogos'

const breakpoints = {
  aboveTablet: '@media (max-width: 1000px)',
  belowTablet: '@media (min-width: 750px)',
  aboveMobile: '@media (max-width: 750px)',
}

export const BoxContent = styled(Box)`
  max-width: 60.875rem;
  padding: 0 ${themeGet('spacing.turquoise20_1_25rem')};
  position: relative;
  width: 100%;
  padding-bottom: ${themeGet('spacing.turquoise20_1_25rem')};
`

export const UnassignedLicenseCardWrapper = styled.div`
  margin-top: ${themeGet('spacing.turquoise20_1_25rem')};
`

export const FooterAnchor = styled(Box)`
  height: '100%';
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`

export const FooterBottomRightContainer = styled(Box)`
  flex-direction: row;
  justify-content: center;
  margin-bottom: ${themeGet('spacing.red40_2_5rem')};

  ${breakpoints.aboveMobile} {
    display: none;
  }
`

export const StyledLogos = styled(FooterLogos)``
export const StyledPlainProgramCard = styled(PlainProgramCard)``

export const ProgramCardsContainer = styled(Box).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -1.25rem;

  [class*='IconBox'] {
    margin-top: 1.9375rem;
  }

  [class*='FormFieldStyled'] {
    margin-bottom: 0rem;
  }
`
export const DashboardLeftBoxStyled = styled(Box)`
  align-items: center;
  background-color: ${themeGet('colors.paleGray')};
  width: 90%;

  ${themeGet('breakpoints.xlarge')} {
    width: 90%;
  }

  ${breakpoints.aboveTablet} {
    width: 100%;
  }

  ${breakpoints.belowTablet} {
    ${StyledPlainProgramCard} {
      display: none;
    }
  }
`

export const FooterBottomLeftContainer = styled(Box)`
  margin-bottom: 2.125rem;
  margin-left: ${themeGet('spacing.turquoise20_1_25rem')};
  margin-right: ${themeGet('spacing.turquoise20_1_25rem')};
  margin-top: 1.063rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  ${breakpoints.aboveMobile} {
    margin-top: ${themeGet('spacing.red40_2_5rem')};
  }

  ${breakpoints.belowTablet} {
    div:nth-child(2),
    ${StyledLogos} {
      display: none;
    }
  }

  @media (max-width: 625px) {
    flex-direction: column-reverse;
    text-align: center;
    align-items: center;
  }

  @media (max-width: 1307px) and (min-width: 626px) {
    align-self: baseline;
    width: 95%;
  }

  @media (min-width: 1308px) {
    width: 966px;
    margin-left: auto;
    margin-right: auto;
  }
`
