import styled from 'styled-components'
import FormInputTextInput from '../FormInputTextInput'
import { Add, Subtract } from 'grommet-icons'
import { themeGet } from 'styled-system'

export const FormInputNumberInputWrapper = styled.div`
  position: relative;
`

export const Vr = styled.hr`
  display: inline-block;
  transform: rotate(90deg);
  width: 22px;
  margin-top: 10px;
  border: 1px solid rgb(238, 240, 242);
`

export const ControlsBox = styled.div`
  position: absolute;
  top: 10px;
  right: 11px;
`

export const FormAdd = styled(Add).attrs({
  tabIndex: '0',
})`
  height: 17px;
  fill: #979797;
  stroke: #979797;
  cursor: pointer;
`

export const FormSubtract = styled(Subtract).attrs({
  tabIndex: '0',
})`
  height: 17px;
  fill: #979797;
  stroke: #979797;
  cursor: pointer;
`

export const Input = styled(FormInputTextInput).attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  line-height: 16px;
  height: 43px;
  border-color: ${({ hasError }) =>
    hasError ? themeGet('colors.deleteRed') : ''} !important;
`
