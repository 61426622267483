import React from 'react'
import PropTypes from 'prop-types'
import LessonDetail from 'components/Teach/LessonDetail'
import { Wrapper } from './component.styles'

const LessonDetailsPage = ({ entry }) => {
  return (
    <Wrapper>
      <LessonDetail {...entry} />
    </Wrapper>
  )
}

LessonDetailsPage.propTypes = {
  entry: PropTypes.object,
}

export default LessonDetailsPage
