import LessonSkippedIcon from './assets/ss_hsmyprograms_practices_skipped.svg'
import LessonCompleteIcon from './assets/ss_hsmyprograms_practices_complete.svg'
import LessonToDoIcon from './assets/ss_hsmyprograms_practices_incomplete.svg'

export function getLessonStatusIcon({ isComplete, isSkipped }) {
  // NOTE: a skipped lesson will also be isComplete=true, so this check takes priority
  if (isSkipped) {
    return {
      altText: 'lesson skipped',
      icon: LessonSkippedIcon,
    }
  }
  if (isComplete) {
    return {
      altText: 'lesson complete',
      icon: LessonCompleteIcon,
    }
  }
  return {
    altText: 'lesson incomplete',
    icon: LessonToDoIcon,
  }
}
