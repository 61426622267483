import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Accordion, Hairline } from 'secondstep-components'

export const AccordionWrapper = styled(Accordion)`
  width: 100%;
  margin: 1.75rem auto 1.25rem;
  box-sizing: border-box;
  border-radius: 0.625rem;
  background: ${themeGet('colors.backgrounds.primary', 'colors.white')};
  display: flex;
  justify-content: space-between;
  ${themeGet('breakpoints.tablet')} {
    flex-direction: column;
  }

  [class*='AccordionPanel-'] {
    padding: 1.25rem 1.25rem 1rem;
    background: ${themeGet('colors.white')};

    [class*='StyledCaret'] {
      padding: 0;
    }
  }

  [class*='AccordionTitleWrapper'] {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  [class*='AccordionContent-'] {
    padding: 0 1.0625rem 1rem;
  }
`

export const HeaderTitle = styled.h2`
  color: ${themeGet('colors.darkBlue')};
  font-size: 1.4375rem;
  font-family: ${themeGet('font.sharpSlab.semibold')};
`

export const LoadingWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 72px;
  width: 100%;
  padding: 1.5rem 1.25rem 1.25rem;
  margin-top: 1.75rem;
  box-sizing: border-box;
  border-radius: 0.625rem;
  background: ${themeGet('colors.backgrounds.primary', 'colors.white')};

  svg {
    color: ${themeGet('colors.darkBlue')};
  }

  p {
    margin-top: 0.25rem;
    margin-left: 0.5rem;
    font-size: 1.125rem;
    font-family: ${themeGet('font.molde.medium')};
    color: ${themeGet('colors.darkGray')};
  }

  ${themeGet('breakpoints.mobileLarge')} {
    margin-bottom: 1.25rem;
  }
`
export const StyledHairline = styled(Hairline)`
  height: 0.125rem;
  margin-top: 0.315rem;
  margin-bottom: 1.255rem;
  background-color: ${themeGet('colors.paleGray')};
`

export const TabSetWrapper = styled.div`
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
`
