import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, HeaderOne } from 'secondstep-components'

export const BannerWrapper = styled.div.attrs(({ bannerType }) => ({
  background: {
    districtSupports: themeGet('colors.paleGray'),
    familyResources: 'none',
    general: themeGet('colors.white'),
    selaLeader: themeGet('colors.backgrounds.secondary'),
    selaProduct: themeGet('colors.backgrounds.primary'),
  }[bannerType],
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 5.9375rem;
  padding-top: 3.3125rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  background-position: center;
  background-color: ${({ background }) => background};

  p {
    max-width: none;
  }

  ${themeGet('breakpoints.tablet')} {
    padding-top: 1.875rem;
  }

  ${themeGet('breakpoints.mobile')} {
    padding-bottom: 5.1875rem;
  }
`

export const BannerContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${themeGet('banners.alignment', 'center')};
  width: 100%;
  max-width: ${themeGet('breakpoints.sizes.large')};
  box-sizing: border-box;

  ${themeGet('breakpoints.tablet')} {
    align-items: center;
  }
`

export const BannerText = styled(BodyCopy)`
  color: ${themeGet('colors.darkGrey')};
  margin-top: 0.1875rem;
  max-width: 60%;
  font-size: 18px;
  text-align: center;

  ${themeGet('breakpoints.tablet')} {
    max-width: 75%;
  }

  ${themeGet('breakpoints.mobile')} {
    max-width: 100%;
  }
`

export const BannerHeader = styled(HeaderOne)`
  font-family: ${themeGet('banners.headerTextFont')};
  margin-bottom: 0.688rem;
  text-align: ${themeGet('banners.alignment', 'center')};

  ${themeGet('breakpoints.tablet')} {
    text-align: center;
  }
`
