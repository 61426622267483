import React, { useState } from 'react'
import PropTypes from 'prop-types'
import PacingNavigationComponent from './component'

const PathwayNavigationContainer = ({
  handlePathwayNavigation,
  startingIndex,
}) => {
  // TODO: Set active index to most recent pathway visited
  const [activeIndex, setActiveIndex] = useState(startingIndex || 0)
  // TODO: drive these labels from Contentful
  const pathwayTitles = [
    'TRAINING',
    'PATHWAY 1',
    'PATHWAY 2',
    'PATHWAY 3',
    'PATHWAY 4',
  ] // dynamic data

  const handleSquareClick = index => {
    setActiveIndex(index)
    handlePathwayNavigation(index)
  }

  return (
    <PacingNavigationComponent
      activeIndex={activeIndex}
      handleSquareClick={handleSquareClick}
      pathwayTitles={pathwayTitles}
    />
  )
}

PathwayNavigationContainer.propTypes = {
  handlePathwayNavigation: PropTypes.func,
  startingIndex: PropTypes.number,
}

export default PathwayNavigationContainer
