import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { HeaderTwo, BaseContainer } from 'secondstep-components'

export const StyledHeaderTwo = styled(HeaderTwo)`
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('fontFamilyHeadingBold')};
  margin: 0;
`

export const StyledContainer = styled(BaseContainer)`
  background-color: ${themeGet('colors.lighterGray')};
  padding: ${themeGet('spacing.amber28_1_75rem')};
  margin-top: ${themeGet('spacing.turquoise20_1_25rem')};
  margin-bottom: ${themeGet('spacing.amber28_1_75rem')};
`
