import React from 'react'
import PropTypes from 'prop-types'
import { MODULE_COMPLETE, UP_NEXT } from './constants'
import {
  Wrapper,
  Label,
  Title,
  Time,
  ButtonRow,
  StaffButton,
  LeaderButton,
  CompleteTitle,
} from './component.styles'

import { ThreeStarIcon } from 'secondstep-components'

const ModuleOverviewInset = ({
  isModuleDone,
  isModuleStarted,
  leaderButtonOnClick,
  leaderButtonText,
  staffButtonOnClick,
  staffButtonText,
  time,
  title,
}) => {
  const label = !isModuleStarted ? null : UP_NEXT

  return (
    <Wrapper dataTestId="module-overview-inset">
      {isModuleDone ? (
        <>
          <ThreeStarIcon />
          <CompleteTitle>{MODULE_COMPLETE}</CompleteTitle>
        </>
      ) : (
        <>
          {label && <Label>{label}</Label>}
          {title && <Title>{title}</Title>}
          {time && <Time>{time}</Time>}
          <ButtonRow>
            {leaderButtonText && (
              <LeaderButton
                label={leaderButtonText}
                onClick={leaderButtonOnClick}
              />
            )}
            {staffButtonText && (
              <StaffButton
                label={staffButtonText}
                onClick={staffButtonOnClick}
              />
            )}
          </ButtonRow>
        </>
      )}
    </Wrapper>
  )
}

ModuleOverviewInset.propTypes = {
  isModuleDone: PropTypes.bool,
  isModuleStarted: PropTypes.bool,
  leaderButtonOnClick: PropTypes.func,
  leaderButtonText: PropTypes.string,
  staffButtonOnClick: PropTypes.func,
  staffButtonText: PropTypes.string,
  time: PropTypes.string,
  title: PropTypes.string,
}

ModuleOverviewInset.defaultProps = {
  isModuleDone: false,
  isModuleStarted: false,
}

export default ModuleOverviewInset
