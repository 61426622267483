import React from 'react'
import { connect } from 'react-redux'

import { GetRootContent } from 'layers/content/Hocs/RootContent'
import NavigationHelper from 'layers/navigation/navigationHelper'

import { navContentMapper } from './data'
import Component from './component'

function NavigationContainer(props) {
  return <Component {...props} />
}

const navigationConnect = connect(
  NavigationHelper.mapStateToProps,
  NavigationHelper.mapDispatchToProps,
)

const userConnect = connect(state => {
  const { userAccessManager } = state || {}
  const { claims } = userAccessManager || {}
  const { userProfileManager } = state || {}
  const { profile } = userProfileManager || {}
  const { firstName = '', lastName = '', roles = [] } = profile || {}
  return {
    roles,
    userClaims: claims,
    name: `${firstName} ${lastName}`.trim(),
  }
})

const options = {
  include: 3,
  mapper: navContentMapper,
  spread: true,
}

export default GetRootContent(
  NavigationContainer,
  options,
  navigationConnect,
  userConnect,
)
