import React from 'react'
import PropTypes from 'prop-types'
import {
  StyledContainer,
  SubTitle,
  DateRange,
  Title,
  TitleWrapper,
} from './component.styles'
import { dateToMMMMddyyyy } from 'utils/timeFormattingHelpers'

const PacingDate = ({ title, subtitle, startDate, endDate }) => {
  const formattedStartDate = dateToMMMMddyyyy(startDate)
  const formattedEndDate = dateToMMMMddyyyy(endDate)

  return (
    <StyledContainer>
      <TitleWrapper>
        <Title>{title}</Title>
        <SubTitle>{subtitle.toUpperCase()}</SubTitle>
      </TitleWrapper>
      <DateRange>
        <span>{formattedStartDate}—</span>
        <span>{formattedEndDate}</span>
      </DateRange>
    </StyledContainer>
  )
}

PacingDate.propTypes = {
  endDate: PropTypes.object,
  startDate: PropTypes.object,
  subtitle: PropTypes.string,
  title: PropTypes.string,
}

export default PacingDate
