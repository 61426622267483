import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'
import { BodyCopy, HeaderTwo } from 'secondstep-components'

export const UnitOverviewWrapper = styled(Box)`
  flex-direction: column;
  flex: 2;
  padding: 2.3rem 1.75rem 2.25rem;
  border-radius: 11px;
  color: 'red';
  background: ${themeGet('colors.themeSecondary')};
  background-image: url(${themeGet('banners.backgroundImage')});
  background-repeat: no-repeat;

  ${HeaderTwo} {
    font-size: 1.75rem;
    line-height: 2rem;
    margin-bottom: 0.5rem;
    color: ${themeGet('colors.fontLight')};

    ${themeGet('breakpoints.mobile')} {
      font-size: 1.625rem;
    }
  }

  ${BodyCopy} {
    margin-bottom: 0.9375rem;
    color: ${themeGet('colors.fontLight')};
    max-width: 680px;

    a {
      color: ${themeGet('colors.fontLight')};
      text-decoration: underline;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  ${themeGet('breakpoints.tablet')} {
    flex: 3.5;
  }
`
