import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { SearchSelect } from 'secondstep-components'

export default styled(SearchSelect)`
  padding: 0.125rem 1.5rem 0.375rem 0;

  button {
    background-color: transparent;
    border: 1px solid transparent;
    color: ${themeGet('colors.white')};
    height: unset;
    justify-content: flex-start;
    padding: 0;
    z-index: 2;

    svg {
      color: ${themeGet('colors.white')} !important;

      &:last-of-type {
        width: 14px;
        height: 12px;
        margin-left: auto;
      }
    }

    &:hover {
      border-bottom: 1px solid ${themeGet('colors.white')};
      border-radius: 0;
    }

    &:focus {
      border-color: transparent;
    }
  }

  span {
    font-family: ${themeGet('fontFamilySemibold')};
    padding-right: 0.5rem;
    padding-left: 0.75rem;
  }

  [class*='LabelWrapper'] {
    display: none;
  }

  [class*='DropdownButton'] {
    border-radius: 15px;
  }

  [class*='DropdownOptions'] {
    border: none;
    border-radius: 0.3125rem;
    box-shadow: 0 0 22px rgba(0, 0, 0, 0.2);
    margin-top: 0.25rem;
    padding: 0.25rem 0;
    width: max-content;
    min-width: 100%;

    button {
      padding: 0.125rem 1rem;

      svg {
        padding-left: 0.5rem;
      }

      &:hover,
      &.arrow-selected {
        span {
          color: ${themeGet('colors.white')};
        }
      }
    }

    span {
      color: ${themeGet('colors.darkBlue')};
    }

    button:not(:last-child) {
      border-bottom: solid 1px ${themeGet('colors.paleGray')};
    }
  }

  [class*='DropdownSpaceFiller'] {
    display: none;
  }
`
