import React from 'react'
import PropTypes from 'prop-types'

import {
  CloseButton,
  ContentWrapper,
  Email,
  Section1,
  Section2,
  Status,
  Support,
  Title,
} from './component.styles'
import {
  CLOSE,
  EMAIL,
  SECTION1,
  SECTION2,
  STATUS,
  SUPPORT,
  TITLE,
} from './constants'

const MSSupplementalUnitModal = ({
  bpuStatus,
  handleClose /* mspSiteContexts */,
}) => (
  <ContentWrapper>
    <Title>{TITLE}</Title>
    <Section1>{SECTION1}</Section1>
    <Status>
      {STATUS}
      <b>{bpuStatus}</b>
    </Status>
    <Section2>{SECTION2}</Section2>
    <Support>
      {SUPPORT} <Email href="mailto: support@secondstep.org">{EMAIL}</Email>
    </Support>

    <CloseButton onClick={handleClose}>{CLOSE}</CloseButton>
  </ContentWrapper>
)

MSSupplementalUnitModal.propTypes = {
  bpuStatus: PropTypes.string,
  handleClose: PropTypes.func,
  mspSiteContexts: PropTypes.array,
}

export default MSSupplementalUnitModal
