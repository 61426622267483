const checkIfUserHasMSSiteWideLicenseAndAccess = (licenses, claims) => {
  let hasMiddleSchoolSiteWideLicense = false
  const { hasMsAdminClaim, hasMsTeacherClaim } = claims || {}

  if (Array.isArray(licenses)) {
    hasMiddleSchoolSiteWideLicense = !!licenses.find(x => x.type === 'MSP')
  }

  // Due to site centric change a user might be on an MSP license but won't
  // have access so we need to double check the user has access as well
  const hasMSAccess = hasMsAdminClaim || hasMsTeacherClaim
  return hasMiddleSchoolSiteWideLicense && hasMSAccess
}

export default {
  checkIfUserHasMSSiteWideLicenseAndAccess,
}
