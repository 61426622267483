/* istanbul ignore file */
// TODO: ^ ignore coverage on this file until we can have app test scaffolding ready
import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { RouteContentGetter } from 'layers/content/v2/Hocs/RouteContentGetter'
import NavigationHelper from 'layers/navigation/navigationHelper'
import withApiError from 'layers/errorHandling/apiError'
import withSiteContext from 'layers/content/v2/Hocs/withSiteContext'
import HsResourceLibrary from './component'
import {
  HIGH_SCHOOL_INDIVIDUAL_RESOURCE_PATH,
  isPreviewMode,
} from 'utils/highschoolHelpers'
import { getRouteServiceProductPath } from 'utils/productHelpers'
import { worker } from 'mocks/browser'
import algoliasearch from 'algoliasearch'
import searchInsights from 'search-insights'

import {
  ALGOLIA_APP_NAME,
  ALGOLIA_API_KEY,
  ALGOLIA_INDEX_NAME,
} from 'config/env'

import {
  DEFAULT_SEARCHBOX_PLACEHOLDER,
  DEFAULT_SEARCHBOX_TITLE,
} from './constants'
import {
  shouldUseMockServiceWorker,
  getAlgoliaCacheSettings,
  generateCompletionStatusMap,
} from './helpers'
import { userSegmentationBuilder } from 'utils/userSegmentationHelpers'
import {
  AI_HS_PAGE_USAGE_KEY,
  sendApplicationInsightsEvent,
} from 'utils/sendAnalyticsDataHelpers'
import { RouteContentProductAppMap } from 'utils/productMaps'
import { selectors as lmsSelectors } from 'store/lmsManager'

const HsResourceLibraryContainer = props => {
  if (shouldUseMockServiceWorker(window.location)) {
    worker.start()
  }

  const {
    navigationEvent,
    userSegmentationInfo,
    routeContent,
    instructorData,
  } = props || {}

  const { content = {}, loading } = routeContent

  const { resourceLibraryPage } = content

  const readyToLoad =
    !!content && !loading && (!!instructorData || isPreviewMode()) // if we're in preview mode, instructorData is not required

  const clientOptions = {
    ...getAlgoliaCacheSettings(ALGOLIA_APP_NAME),
  }

  const searchClient = algoliasearch(
    ALGOLIA_APP_NAME,
    ALGOLIA_API_KEY,
    clientOptions,
  )
  // In the future we will give people the option to opt-in/opt-out of cookies
  searchInsights('init', {
    appId: ALGOLIA_APP_NAME,
    apiKey: ALGOLIA_API_KEY,
    partial: true,
    useCookie: true,
  })

  const searchIndex = searchClient.initIndex(ALGOLIA_INDEX_NAME)

  const [facetsFilters, setFacetsFilters] = useState([])
  const [settings, setSettings] = useState(null)
  const [isHsPreviewMode, setIsHsPreviewMode] = useState(false)

  useEffect(() => {
    let shouldGetSettings = true
    searchIndex.getSettings().then(settings => {
      if (shouldGetSettings) {
        setSettings(settings)
        const facetsFilters =
          settings?.renderingContent?.facetOrdering?.facets?.order ?? []

        const prettyFacetsFilters = facetsFilters.map(f => ({
          key: f,
          displayTitle:
            resourceLibraryPage?.facetCardSetting.facets.find(s =>
              f
                .toUpperCase()
                .includes(s.facetId.toUpperCase().replace('METADATA', '')),
            )?.facetDisplayTitle || f,
        }))
        setFacetsFilters(prettyFacetsFilters)
        setIsHsPreviewMode(isPreviewMode())
      }
    })
    return () => (shouldGetSettings = false)
  }, [])

  useEffect(() => {
    sendApplicationInsightsEvent(AI_HS_PAGE_USAGE_KEY, userSegmentationInfo)
  }, [userSegmentationInfo])

  const completionStatuses = useMemo(() => {
    return instructorData ? generateCompletionStatusMap(instructorData) : {}
  }, [instructorData])

  const onCardClick = routeSlug => {
    if (!routeSlug) return
    const searchParams = window.location.search
    const type = NavigationHelper.types.NEW_TAB_SAME_SITE
    navigationEvent &&
      navigationEvent(
        `${HIGH_SCHOOL_INDIVIDUAL_RESOURCE_PATH}/${routeSlug}${searchParams}`,
        type,
      )
  }

  return (
    settings &&
    readyToLoad && (
      <HsResourceLibrary
        {...mapAdditionalProps(resourceLibraryPage)}
        completionStatuses={completionStatuses}
        facetsFilters={facetsFilters}
        isPreview={isHsPreviewMode}
        onCardClick={onCardClick}
        searchClient={searchClient}
      />
    )
  )
}
const options = {
  app: RouteContentProductAppMap.learnHsProduct,
  routeResolver: getRouteServiceProductPath,
}
const mapAdditionalProps = content => ({
  noResultsText: content.noResult,
  description: content.description,
  searchboxPlaceHolder:
    content.searchPlaceholder || DEFAULT_SEARCHBOX_PLACEHOLDER,
  searchboxTitle: content.searchTitle || DEFAULT_SEARCHBOX_TITLE,
})

const mapStateToProps = state => ({
  userSegmentationInfo: userSegmentationBuilder(state),
  instructorData: lmsSelectors.selectHsInstructorData(state),
})

const HydratedComponent = connect(mapStateToProps)(HsResourceLibraryContainer)

export default withSiteContext(
  RouteContentGetter(withApiError(HydratedComponent), options),
  { useGuard: true },
)
