import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import MediaGroup from 'components/Teach/MediaGroup'
import CollapsibleGroup from 'components/Teach/CollapsibleGroup'
import LessonGroup from 'components/Teach/LessonGroup'
import DividerHairline from 'components/Teach/DividerHairline'
import ContainedIconButton from 'components/Teach/ContainedIconButton'
import ContainerSection from 'components/Teach/ContainerSection'
import Book from 'components/Teach/Book'
import { StyledHeaderThree, StyledMarkdownParagraph } from './component.styles'
import {
  ACTIVITIES_TITLE,
  BUTTON_SUPERTITLE,
  SLM_TITLE,
  SCRIPT_TITLE,
  BB_PREP_VIDEO_TITLE,
} from './constants'

const LessonDailyActivities = ({ days }) => {
  return (
    <ContainerSection title={ACTIVITIES_TITLE}>
      {Array.isArray(days) &&
        days.map((day, index) => {
          const {
            id,
            bbPrepVideos,
            books,
            dayText,
            dayPdfs,
            title,
            streamingLessonMediaRoute,
            lessonScriptRoute,
          } = day || {}
          const hasVideos = bbPrepVideos && bbPrepVideos.length > 0

          return (
            <Fragment key={id}>
              {index > 0 && <DividerHairline />}
              <CollapsibleGroup label={title}>
                <LessonGroup content={dayText} pdfs={dayPdfs} title={title}>
                  {dayText && (
                    <StyledMarkdownParagraph small>
                      {dayText}
                    </StyledMarkdownParagraph>
                  )}
                  {books &&
                    books.map(book => {
                      const { dataTestId: bookId } = book
                      return <Book {...book} key={bookId} />
                    })}
                  {streamingLessonMediaRoute && (
                    <ContainedIconButton
                      icon="media"
                      key="media"
                      supertitle={BUTTON_SUPERTITLE}
                      title={SLM_TITLE}
                      url={streamingLessonMediaRoute}
                    />
                  )}
                  {lessonScriptRoute && (
                    <ContainedIconButton
                      icon="script"
                      key="script"
                      supertitle={BUTTON_SUPERTITLE}
                      title={SCRIPT_TITLE}
                      url={lessonScriptRoute}
                    />
                  )}
                  {hasVideos && (
                    <React.Fragment key="bbPrepVideo">
                      <StyledHeaderThree>
                        {BB_PREP_VIDEO_TITLE}
                      </StyledHeaderThree>
                      <MediaGroup mediaList={bbPrepVideos} />
                    </React.Fragment>
                  )}
                </LessonGroup>
              </CollapsibleGroup>
            </Fragment>
          )
        })}
    </ContainerSection>
  )
}

LessonDailyActivities.propTypes = {
  days: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      bbPrepVideos: PropTypes.array,
      books: PropTypes.arrayOf,
      dayText: PropTypes.string,
      dayPdfs: PropTypes.arrayOf(PropTypes.object),
      title: PropTypes.string,
      streamingLessonMediaRoute: PropTypes.string,
      lessonScriptRoute: PropTypes.string,
    }),
  ),
}
export default LessonDailyActivities
