import styled, { withTheme } from 'styled-components'
import { themeGet } from 'styled-system'
import { Box } from 'grommet'

export const AudioCalloutButton = styled.a`
  border-radius: 0.75rem;
  display: flex;

  svg {
    transition: all 0.2s ease;
    color: ${themeGet('colors.blue')};
  }

  &:focus,
  &:hover {
    cursor: pointer;

    svg {
      color: ${themeGet('colors.orange')};
    }
  }
`

export const ThumbnailWrapper = styled(Box)`
  position: relative;
  width: 100%;
  height: 158px;
`

export const AudioThumbnail = withTheme(styled.div`
  width: 213px;
  height: 154px;
  border-radius: 0.75rem;
  overflow: hidden;
  border: 0 solid ${themeGet('colors.tealButton')};

  #button-base {
    fill: ${themeGet('colors.buttons.primaryBackground')};
    transition: fill 0.2s ease;
  }

  #horizontal-lines {
    fill: ${themeGet('colors.buttons.primaryBackgroundHover')};
  }

  &:hover {
    #button-base {
      fill: ${themeGet('colors.buttons.primaryBackgroundHover')};
    }
  }
`)
