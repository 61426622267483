import { fileTypes } from './constants'

export function getContent(entry) {
  const { fields, content: initialContent } = entry || {}
  const { content } = fields || []

  return initialContent || content || null
}

export function getContentTypeName(contentItem) {
  const { contentType } = contentItem || {}
  const { id } = contentType || {}

  return id
}

export function makeUrlFriendly(string) {
  return (string || '')
    .toLowerCase()
    .split(' ')
    .join('-')
}

export const getFileType = (fileName = '') => {
  let fileType = null
  const extensions = fileName.split('.')

  if (extensions.length > 1) {
    const lastExtension = extensions[extensions.length - 1].toLowerCase()
    fileType = fileTypes[lastExtension] || null
  }
  if (!fileType) {
    switch (fileName) {
      case 'application/pdf':
        fileType = fileTypes['pdf']
        break
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        fileType = fileTypes['doc']
        break
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        fileType = fileTypes['ppt']
        break
    }
  }
  return fileType
}

export const programThemeToIconMap = {
  'Academic Success': 'AcademicSuccessIcon',
  'Bullying and Harassment': 'BullyingAndHarassmentIcon',
  'Decision-Making': 'DecisionMakingIcon',
  'Growth Mindset': 'GrowthMindsetIcon',
  'Helping Others': 'HelpingOthersIcon',
  'Perspective-Taking': 'PerspectiveTakingIcon',
  'Planning Ahead': 'PlanningAheadIcon',
  'Problem-Solving': 'ProblemSolvingIcon',
  'Starting Right': 'StartingRightIcon',
  'Staying Calm': 'StayingCalmIcon',
  'Thoughts and Emotions': 'ThoughtsAndEmotionsIcon',
  Belonging: 'BelongingIcon',
  Conflicts: 'ConflictsIcon',
  Relationships: 'RelationshipsIcon',
  Resilience: 'ResilienceIcon',
  Values: 'ValuesIcon',
}

export const localeToTrackData = locale => {
  const map = {
    'en-US': { srclang: 'en', label: 'English' },
    'es-US': { srclang: 'es', label: 'Spanish' },
  }
  const trackData = map[locale] || map['en-US']
  return trackData
}
