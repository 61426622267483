import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const Wrapper = styled.div.attrs(({ transcriptRendered }) => ({
  height: transcriptRendered ? '99%' : '100%',
}))`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  .vjs-poster img {
    object-fit: cover;
    border-radius: 0.625rem;
  }
  .amsWrapper {
    height: ${({ height }) => height};
  }
  .video-js,
  video {
    border-radius: 11px;
    .vjs-big-play-button {
      width: 3.75rem;
      height: 3.75rem;
      :focus {
        outline: 3px groove green !important;
        border-radius: 0;
      }
    }
  }
`
export const AccessibilityWrapper = styled.div.attrs(
  ({ transcriptRendered }) => ({
    height: transcriptRendered ? '94.5%' : '98%',
    marginTop: transcriptRendered ? '0.3rem' : '0',
  }),
)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${({ height }) => height};
  margin-top: ${({ marginTop }) => marginTop};
`
export const AudioDescriptionButton = styled.div`
  cursor: pointer;
`
export const TranscriptButton = styled.div`
  cursor: pointer;
  margin-right: 1rem;
`
export const TranscriptOutterWrapper = styled.div`
  height: 99%;
  display: block;
  flex-basis: 19.875rem;
  background-color: ${themeGet('colors.paleGray')};
  flex-basis: 40%;
  overflow: scroll;
  border-radius: 5px;
  margin-left: 0.75rem;
  .description .transcript-line {
    color: ${themeGet('colors.blue')};
  }
  .transcript {
    margin-left: 1rem;
    margin-right: 1rem;
  }
`
export const AccessibilityMenu = styled.div`
  background-color: blue;
  height: 3.438rem;
  width: 100%;
`
export const ToggleWrapper = styled.div`
  height: 3.375rem;
  width: 100%;
  display: flex;
  vertical-align: top;
  justify-content: flex-end;
  align-items: center;
  column-gap: 0.75rem;
  position: relative;
  button {
    max-height: 30px;
  }
  svg {
    width: 31px;
  }
`
export const Tooltip = styled.span`
  box-sizing: border-box;
  height: 1.938rem;
  width: auto;
  padding: 0.313rem 1.25rem 0.313rem 1.25rem;
  border: 0.063rem solid #ced8df;
  border-radius: 0.375rem;
  background-color: #fff;
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
  color: #7e7c7c;
  font-family: Molde-Regular;
  font-size: 1rem;
  letter-spacing: 0;
  text-align: center;
  position: absolute;
  right: ${props => props.right}rem;
`
