import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Button } from 'grommet'
import DimissIcon from './assets/ss_hs_trainingbannerdismiss.svg'
import DimissIconHover from './assets/ss_hs_trainingbannerdismiss_hover.svg'

export const Container = styled.div`
  background-color: ${themeGet('colors.highschool.pink6')};
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
  border-radius: 0.625rem;
  margin-bottom: 2rem;
  a {
    color: ${themeGet('colors.highschool.pink1')};
    cursor: pointer;
    font-weight: bold;
  }
  a:hover {
    color: ${themeGet('colors.hsSecondaryGreen')};
  }
`

export const DismissButton = styled(Button)`
  border: none;
  width: 1.1rem;
  height: 1.1rem;
  flex-shrink: 0;
  background-image: url(${DimissIcon});
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 0;
  padding: 0;
  :hover {
    background-image: url(${DimissIconHover});
    box-shadow: none;
  }
`

// use this indirection to bypass linting errors about <a> tag with onClick handler:
export const Link = styled.a``
