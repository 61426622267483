import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import MspBullyingUnitNotification from './component'
import { COOKIE_NAME } from './constants'
import { getCookie, setCookie } from 'utils/cookiesHelper'
import {
  getMSOptInData,
  calculateSitesWithBullyingUnitOnCount,
} from 'utils/siteData'
import siteOperations from 'store/siteManager/operations'

export const MspBullyingUnitNotificationContainer = ({
  mspSiteContexts,
  updateMSPOptIn,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isNotificationShowing, setIsNotificationShowing] = useState(false)

  useEffect(() => {
    const shouldDisplayNotification = !getCookie(COOKIE_NAME)
    setIsNotificationShowing(shouldDisplayNotification)
  }, [])

  const handleDismissNotification = () => {
    setCookie(COOKIE_NAME, true, 365)
    setIsNotificationShowing(false)
  }

  const handleCloseModal = () => setIsModalOpen(false)

  const handleOnSave = async mspOptInArr => {
    await updateMSPOptIn(mspOptInArr)
    handleCloseModal()
  }

  const hasMsp = mspSiteContexts?.length > 0
  const hasMultipleSites = mspSiteContexts?.length > 1

  return (
    isNotificationShowing &&
    hasMsp && (
      <MspBullyingUnitNotification
        handleCloseModal={handleCloseModal}
        handleDismissNotification={handleDismissNotification}
        handleOnSave={handleOnSave}
        handleOpenModal={() => setIsModalOpen(!isModalOpen)}
        hasMultipleSites={hasMultipleSites}
        isModalOpen={isModalOpen}
        mspSiteContexts={mspSiteContexts}
        sitesWithBullyingUnitOnCount={calculateSitesWithBullyingUnitOnCount(
          mspSiteContexts,
        )}
      />
    )
  )
}

MspBullyingUnitNotificationContainer.propTypes = {
  mspSiteContexts: PropTypes.array,
  updateMSPOptIn: PropTypes.func,
}

const mapStateToProps = ({ licenseManager }) => ({
  mspSiteContexts: getMSOptInData(licenseManager.sites),
})

const mapDispatchToProps = {
  updateMSPOptIn: siteOperations.updateMSPOptIn,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MspBullyingUnitNotificationContainer)
