import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { BodyCopy, HeaderTwo, Tooltip } from 'secondstep-components'

import ReportsModuleBox from 'components/ReportsModuleBox'

export const Wrapper = styled(ReportsModuleBox)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .csv-download-link {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;

    svg {
      width: 24px;
      height: 16px;
      margin-top: 0.125rem;
      margin-bottom: 0.25rem;
    }

    a {
      display: flex;
      align-items: flex-start;
      font-family: ${themeGet('font.links.primary')};
      margin-right: 0.5rem;
      text-decoration: none;
    }
  }

  ${HeaderTwo} {
    margin-bottom: 0.3625rem;
  }

  ${BodyCopy} {
    margin-bottom: 1.375rem;
  }

  ${themeGet('breakpoints.mobileLarge')} {
    ${BodyCopy} {
      margin-bottom: 0;
    }
  }

  @media (max-width: 390px) {
    padding: 1.125rem 1rem 1rem;
    margin-bottom: 1rem;
  }
`

export const ProgressWrapper = styled.div`
  display: inline;
`

export const LeaderProgressWrapper = styled(ProgressWrapper)`
  @media (max-width: 630px) {
    max-width: 29rem;
  }
`

export const StaffProgressWrapper = styled(ProgressWrapper)`
  ${themeGet('breakpoints.mobileLarge')} {
    max-width: 26rem;
  }
`

export const TrackLabel = styled(BodyCopy)`
  display: inline;
  margin-right: 0.1875rem;
`

export const TrackProgress = styled(BodyCopy)`
  display: inline;
`

export const CsvWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  [class*='tooltip-content'] {
    bottom: -6rem;
    :after {
      bottom: 92%;
    }
  }

  [class*='BodyCopy'] {
    :after {
      bottom: 92%;
    }
  }
`

export const LeaderTeamCsvDetailWrapper = styled(CsvWrapper)`
  @media (max-width: 446px) {
    display: inline;

    button {
      margin-bottom: 0.75rem;
    }

    .csv-download-link {
      display: inline;

      a {
        display: inline;
      }

      svg {
        margin-bottom: 0;
        margin-top: 0.75rem;
      }
    }

    [class*='tooltip-content'] {
      transform: translateX(4rem);

      :after {
        left: 12.5%;
      }
    }

    [class*='BodyCopy'] {
      :after {
        left: 12.5%;
      }
    }
  }
`

export const LeaderTeamCsvDownloadWrapper = styled(CsvWrapper)`
  @media (max-width: 528px) {
    display: inline;

    button {
      margin-bottom: 0.75rem;
    }

    .csv-download-link {
      display: inline;

      a {
        display: inline;
      }

      svg {
        margin-bottom: 0;
        margin-top: 0.75rem;
      }
    }

    [class*='tooltip-content'] {
      transform: translateX(3.5rem);

      :after {
        left: 15.5%;
      }
    }

    [class*='BodyCopy'] {
      :after {
        left: 15.5%;
      }
    }
  }

  @media (max-width: 409px) {
    [class*='tooltip-content'] {
      transform: translateX(0.5rem);

      :after {
        left: 34.5%;
      }
    }

    [class*='BodyCopy'] {
      :after {
        left: 34.5%;
      }
    }
  }
`

export const StaffCsvDetailWrapper = styled(CsvWrapper)`
  [class*='tooltip-content'] {
  }

  [class*='BodyCopy'] {
    :after {
      bottom: ;
    }
  }

  @media (max-width: 400px) {
    [class*='tooltip-content'] {
      transform: translateX(-7.875rem);

      :after {
        top: -4%;
      }
    }

    [class*='BodyCopy'] {
      :after {
        top: -4%;
      }
    }
  }
`

export const StaffCsvDownloadWrapper = styled(CsvWrapper)`
  @media (max-width: 472px) {
    display: inline;

    button {
      margin-bottom: 0.75rem;
    }

    .csv-download-link {
      display: inline;

      a {
        display: inline;
      }

      svg {
        margin-bottom: 0;
        margin-top: 0.75rem;
      }
    }

    [class*='tooltip-content'] {
      transform: translateX(4rem);

      :after {
        left: 12.5%;
      }
    }

    [class*='BodyCopy'] {
      :after {
        left: 12.5%;
      }
    }
  }
`

const StyledTooltip = styled(Tooltip)`
  width: 14px;

  div {
    bottom: 2rem;
    padding: 0.6rem;
    border-radius: 0.3125rem;
    font-size: 0.75rem;
    line-height: 1rem;

    ::after {
      content: ' ';
      width: 0.75rem;
      height: 0.75rem;
      position: absolute;
      bottom: -4%;
      left: 50%;
      background-color: white;
      transform: translate(-50%) rotate(45deg);
    }
  }

  i.fas {
    color: ${themeGet('colors.blueGray')};
    font-size: 0.875rem;
  }

  [class*='BodyCopy'] {
    color: ${themeGet('colors.darkGray')};
    margin-bottom: 0;
    text-align: start;

    p {
      font-size: 0.75rem;
      line-height: 1rem;
      margin-block-end: 0;
    }
  }
`

export const UserProgressTooltip = styled(StyledTooltip)`
  margin: 0 0 1.25rem 0.25rem;

  div {
    bottom: 2rem;
  }

  span {
    color: ${themeGet('colors.darkGray')};
    font-size: 0.75rem;
    line-height: 1rem;
    a {
      color: ${themeGet('colors.blueGray')};
    }
  }

  [class*='tooltip-content'] {
    max-width: 13rem;
    padding: 0.75rem 1rem 0.75rem 0.875rem;

    [class*='BodyCopy'] {
      padding: 0;
    }
  }
`

export const LeaderProgressTooltip = styled(UserProgressTooltip)`
  [class*='tooltip-content'] {
    ${themeGet('breakpoints.tablet')} {
      transform: translateX(-13rem);
    }

    @media (max-width: 630px) {
      transform: translateX(-2rem);
    }

    @media (max-width: 490px) {
      transform: translate(-7.5rem);
    }

    @media (max-width: 430px) {
      transform: translateX(-8.5rem);
    }
  }

  ${themeGet('breakpoints.tablet')} {
    div {
      :after {
        left: 87%;
      }
    }
  }

  @media (max-width: 630px) {
    div {
      :after {
        left: 13.5%;
      }
    }
  }

  @media (max-width: 490px) {
    div {
      :after {
        left: 50.5%;
      }
    }
  }

  @media (max-width: 430px) {
    div {
      :after {
        left: 57%;
      }
    }
  }
`

export const StaffProgressTooltip = styled(UserProgressTooltip)`
  [class*='tooltip-content'] {
    ${themeGet('breakpoints.tablet')} {
      transform: translateX(-13rem);
    }

    ${themeGet('breakpoints.mobileLarge')} {
      transform: translateX(-1.5rem);
    }

    @media (max-width: 430px) {
      transform: translateX(-8.5rem);
    }
  }

  ${themeGet('breakpoints.tablet')} {
    div {
      :after {
        left: 87%;
      }
    }
  }

  ${themeGet('breakpoints.mobileLarge')} {
    div {
      :after {
        left: 10%;
      }
    }
  }

  @media (max-width: 430px) {
    div {
      :after {
        left: 57%;
      }
    }
  }
`

export const ModuleCsvTooltip = styled(StyledTooltip)`
  div {
    bottom: 1.5rem;
    padding: 0.3rem 0.25rem 0.3rem 0.4rem;
  }

  [class*='BodyCopy'] {
    color: ${themeGet('colors.darkGray')};
    margin-bottom: 0;
    p {
      font-size: 0.75rem;
      line-height: 1rem;
      margin-block-end: 0;
      text-align: start;
    }
  }

  ${themeGet('breakpoints.tablet')} {
    div {
      left: -627%;

      :after {
        left: 88%;
      }
    }
  }
`
