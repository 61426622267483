import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BodyCopy, WarningIcon } from 'secondstep-components'
import ReportsBanner from 'components/ReportsBanner'
import ReportsLicenseCard from 'components/ReportsLicenseCard'
import ReportsModuleCard from 'components/ReportsModuleCard'
import {
  ContentWrapper,
  ErrorWrapper,
  Wrapper,
  Footer,
} from './component.styles'
import { ERROR_TEXT, STAFF_TRACK } from './constants'

const SelaReport = props => {
  const {
    activeSelaLicenses,
    bannerBody,
    bannerTitle,
    footerText,
    modules,
    moduleCsvTooltip,
    onLicenseChange,
    selaReport,
    selectedContentTrack,
    selectedLicense,
    selectedModuleId,
    staffProgressTooltip,
    unitCsvTooltip,
    updateSelectedContentTrack,
    updateSelectedModuleId,
    userTypeTooltip,
  } = props
  const [reportDataExists, setReportDataExists] = useState(true)
  const [selectedModule, setSelectedModule] = useState(null)
  const [selectedTrack, setSelectedTrack] = useState(null)

  let defaultModule = {}
  let defaultTrack = {}

  function setModuleAndTrackDefaults() {
    const isSelaProgram = selectedLicense?.product?.includes(
      'Second Step SEL for Adults',
    )
    if (isSelaProgram) {
      const selectedOrSecondModule = !selectedModuleId && modules?.[1]
      const selectedOrStaffTrack =
        !selectedContentTrack &&
        selaReport?.content_tracks?.find(
          track => track.content_track === STAFF_TRACK,
        )
      defaultModule = selectedOrSecondModule || defaultModule
      defaultTrack = selectedOrStaffTrack || defaultTrack
    } else {
      const selectedOrFirstModule = !selectedModuleId && modules?.[0]
      const selectedOrFirstTrack =
        !selectedContentTrack && selaReport?.content_tracks?.[0]
      defaultModule = selectedOrFirstModule || defaultModule
      defaultTrack = selectedOrFirstTrack || defaultTrack
    }
  }

  useEffect(() => {
    setModuleAndTrackDefaults()

    if (selectedModuleId) {
      const matchingModule = modules.find(({ id }) => id === selectedModuleId)
      setSelectedModule(matchingModule)
      updateSelectedModuleId(matchingModule.id)
    } else {
      setSelectedModule(defaultModule)
      updateSelectedModuleId(defaultModule.id)
    }

    if (selectedContentTrack) {
      const matchingTrack =
        selaReport?.content_tracks?.find(
          ({ content_track }) => content_track === selectedContentTrack,
        ) || {}

      setSelectedTrack(matchingTrack)
      updateSelectedContentTrack(matchingTrack.content_track)
    } else {
      setSelectedTrack(defaultTrack)
      updateSelectedContentTrack(defaultTrack.content_track)
    }
  }, [selaReport])

  useEffect(() => {
    setReportDataExists(true)
    setSelectedModule(defaultModule)
    updateSelectedModuleId(defaultModule.id)
    setSelectedTrack(defaultTrack)
    updateSelectedContentTrack(defaultTrack.content_track)
  }, [selectedLicense])

  const validateReportData = dataExists => {
    setReportDataExists(dataExists)
  }

  const { licenseId, siteName: schoolName } = selectedLicense || {}

  return (
    <Wrapper>
      <ReportsBanner
        activeAdminLicenses={activeSelaLicenses}
        body={bannerBody}
        onLicenseChange={onLicenseChange}
        selectedLicense={selectedLicense}
        title={bannerTitle}
      />
      <ContentWrapper>
        {!reportDataExists && (
          <ErrorWrapper>
            <WarningIcon dataTestId="icon-warning" height={20} width={20} />
            {ERROR_TEXT}
          </ErrorWrapper>
        )}
        {reportDataExists && (
          <>
            <ReportsLicenseCard
              modules={modules}
              selectedLicense={selectedLicense}
              selectedModule={selectedModule}
              selectedTrack={selectedTrack}
              setSelectedModule={setSelectedModule}
              setSelectedTrack={setSelectedTrack}
              tooltipCopy={userTypeTooltip}
              validateReportData={validateReportData}
            />
            <ReportsModuleCard
              entryId={selectedModule?.id}
              licenseId={licenseId}
              moduleTooltipCopy={moduleCsvTooltip}
              schoolName={schoolName}
              selectedModule={selectedModule}
              selectedTrack={selectedTrack}
              staffTooltipCopy={staffProgressTooltip}
              unitTooltipCopy={unitCsvTooltip}
              validateReportData={validateReportData}
            />
          </>
        )}
        <Footer>
          <BodyCopy>{footerText}</BodyCopy>
        </Footer>
      </ContentWrapper>
    </Wrapper>
  )
}

SelaReport.propTypes = {
  activeSelaLicenses: PropTypes.array,
  bannerBody: PropTypes.object,
  bannerTitle: PropTypes.string,
  footerText: PropTypes.string,
  moduleCsvTooltip: PropTypes.string,
  modules: PropTypes.array,
  onLicenseChange: PropTypes.func.isRequired,
  selaReport: PropTypes.object,
  selectedContentTrack: PropTypes.string,
  selectedLicense: PropTypes.object,
  selectedModule: PropTypes.object,
  selectedModuleId: PropTypes.string,
  selectedTrack: PropTypes.object,
  setSelectedModule: PropTypes.func,
  setSelectedTrack: PropTypes.func,
  staffProgressTooltip: PropTypes.string,
  unitCsvTooltip: PropTypes.string,
  updateSelectedContentTrack: PropTypes.func,
  updateSelectedModuleId: PropTypes.func,
  userTypeTooltip: PropTypes.string,
}

SelaReport.defaultProps = {
  activeSelaLicenses: [],
}

export default SelaReport
