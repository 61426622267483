import styled from 'styled-components'
import { themeGet } from 'styled-system'

export const LoadingWrapper = styled.div`
  div {
    background-color: ${themeGet('colors.backgroundModalLightNoOpacity')};
  }

  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
`
