import React from 'react'
import PropTypes from 'prop-types'
import {
  HS_MONITOR_OVERALL_PROGRESS,
  HS_MONITOR_PRACTICE_TITLES,
} from './constants.js'
import {
  BodyText,
  StyledBullet,
  TooltipBodyText,
  TooltipBodyWrapper,
  TooltipHeader,
  TooltipWrapper,
} from './component.styles.js'

const AdminDonutTooltip = ({ data, programName }) => {
  const tooltipHeaderText = HS_MONITOR_PRACTICE_TITLES[programName]

  if (programName === HS_MONITOR_OVERALL_PROGRESS.STUDENT_ACTIVITES) {
    return (
      <TooltipWrapper>
        <TooltipHeader>{tooltipHeaderText}</TooltipHeader>
        <TooltipBodyWrapper>
          <BodyText>
            <StyledBullet color="pink" />
            <TooltipBodyText>{`Grade 9: ${data?.grade9LessonsCompleted} Complete`}</TooltipBodyText>
          </BodyText>
          <BodyText>
            <StyledBullet color="darkBlue" />
            <TooltipBodyText>{`Grade 10: ${data?.grade10LessonsCompleted} Complete`}</TooltipBodyText>
          </BodyText>
          <BodyText>
            <StyledBullet color="violet" />
            <TooltipBodyText>{`Grade 11: ${data?.grade11LessonsCompleted} Complete`}</TooltipBodyText>
          </BodyText>
          <BodyText>
            <StyledBullet color="mediumTeal" />
            <TooltipBodyText>{`Grade 12: ${data?.grade12LessonsCompleted} Complete`}</TooltipBodyText>
          </BodyText>
        </TooltipBodyWrapper>
      </TooltipWrapper>
    )
  } else if (
    programName === HS_MONITOR_OVERALL_PROGRESS.EDUCATOR_PRACTICES ||
    programName === HS_MONITOR_OVERALL_PROGRESS.SCHOOLWIDE_PRACTICES
  ) {
    return (
      <TooltipWrapper>
        <TooltipHeader>{tooltipHeaderText}</TooltipHeader>
        <TooltipBodyWrapper>
          <BodyText>
            <TooltipBodyText>{`Pathway 1: ${data?.pathway1PracticesOrActivitiesCompleted} of ${data?.pathway1PracticesOrActivitiesCount} practices`}</TooltipBodyText>
          </BodyText>
          <BodyText>
            <TooltipBodyText>{`Pathway 2: ${data?.pathway2PracticesOrActivitiesCompleted} of ${data?.pathway2PracticesOrActivitiesCount} practices`}</TooltipBodyText>
          </BodyText>
          <BodyText>
            <TooltipBodyText>{`Pathway 3: ${data?.pathway3PracticesOrActivitiesCompleted} of ${data?.pathway3PracticesOrActivitiesCount} practices`}</TooltipBodyText>
          </BodyText>
          <BodyText>
            <TooltipBodyText>{`Pathway 4: ${data?.pathway4PracticesOrActivitiesCompleted} of ${data?.pathway4PracticesOrActivitiesCount} practices`}</TooltipBodyText>
          </BodyText>
        </TooltipBodyWrapper>
      </TooltipWrapper>
    )
  }
  return null
}

AdminDonutTooltip.propTypes = {
  data: PropTypes.object,
  programName: PropTypes.string,
}

export default AdminDonutTooltip
