import autoBind from 'auto-bind'
import ContenfulTypes from 'layers/content/store/types'
import ContentfulActions from 'layers/content/store/actions'
import ContentfulNodeFailureTypes from 'layers/learn/content/nodes/failureTypes'
import { select, takeEvery, put } from 'redux-saga/effects'
import {
  getContentNodesFromState,
  getEntryIdAndEntryFromAction,
} from '../../utils/selectors'
import { DO_NOT_RUN_CODE } from '../../../../saga/lifecycle'

export default class FailureChecker {
  constructor() {
    autoBind(this)
  }

  *subscribe() {
    yield takeEvery(
      ContenfulTypes.CONTENTFUL_FAILURE,
      this.checkFailuresLifecycle,
    )
  }

  // reliant on this.state set at beginning of lifecycle
  getState(selector = state => state) {
    const value = selector(this.state)
    return value
  }

  // returns error if we should not run. Otherwise just continue
  *validateGateShouldRun(action) {
    const { last: lastNode, current: currentNode } =
      this.getState(getContentNodesFromState) || {}
    const { entryId: currentNodeEntryId } = currentNode || {}
    const { entryId } = getEntryIdAndEntryFromAction(action) || {}
    const { error: isError } = action || {}
    const { errorNode: lastNodeHasErrorAlready } = lastNode || {}

    if (
      !currentNode ||
      entryId !== currentNodeEntryId ||
      !isError ||
      lastNodeHasErrorAlready
    ) {
      this.earlyExit(ContentfulNodeFailureTypes.ITEM_SELECTION_FAILED, {
        lastNode,
        entryId,
        currentNodeEntryId,
      })
    }
  }

  *checkFailuresLifecycle(action) {
    try {
      this.state = yield select(state => state)

      yield this.validateGateShouldRun(action)

      const { current: currentNode } =
        this.getState(getContentNodesFromState) || {}
      const { payload: error } = action.payload
      const message = error?.message || this.DEFAULT_ERROR_MESSAGE

      const errorNode = {
        ...currentNode,
        error,
        errorNode: true,
        errorMessage: message,
      }

      yield put(ContentfulActions.updateContentNodesError(errorNode))
      yield put(ContentfulActions.updateContentNodesErrorSystemMessage(error))
    } catch (e) {
      if (e.code === this.ERROR_CODE_HANDLED) {
        this.log && console.log(e, e.payload)
        return
      }
      console.error(e)
      throw e
    }
  }

  earlyExit(message, payload) {
    const e = new Error(message)
    e.code = this.ERROR_CODE_HANDLED
    e.payload = payload
    throw e
  }

  ERROR_CODE_HANDLED = DO_NOT_RUN_CODE
  DEFAULT_ERROR_MESSAGE = 'There was an error retrieving content'
}
