import styled from 'styled-components'
import { themeGet } from 'styled-system'

import { HeaderOne, BaseContainer } from 'secondstep-components'

export const Wrapper = styled.div.attrs(({ dataTestId }) => ({
  'data-testid': dataTestId,
}))`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: ${themeGet('colors.paleGray')};
  padding-left: 20px;
  padding-right: 20px;

  ${themeGet('breakpoints.tablet')} {
    padding-left: 16px;
    padding-right: 16px;
  }
  ${themeGet('breakpoints.mobile')} {
    padding-left: 16px;
    padding-right: 16px;
  }
`
export const Container = styled.div`
  max-width: ${themeGet('breakpoints.sizes.large')};
`

export const BoxContent = styled(BaseContainer)`
  margin-top: 0 !important;
`

export const ContentHeaderOne = styled(HeaderOne)`
  font-family: ${themeGet('font.sharpSlab.semibold')};
  margin: 0 auto;
  padding-top: ${themeGet('spacing.indigo70_4_375rem')};
  padding-bottom: ${themeGet('spacing.indigo70_4_375rem')};
  text-align: center;
`
