import React from 'react'
import PropTypes from 'prop-types'
import { ContainedButton } from 'secondstep-components'
import { launchPresentation as launchHelper } from 'utils/playerHelper'

const ContentPresentation = ({
  id,
  locale,
  navigationEvent,
  pathname,
  presentationButtonLabel,
  isFamilyResource,
}) => {
  if (!id || !presentationButtonLabel) return null

  const launchPresentation = async () => {
    const gradeFromPathname = pathname.split('/')?.[3]?.split('-')?.[0] || ''

    const getUnitFromPathname = () => {
      const unitPathnameSplit = pathname.split('/')?.[4] || ''
      const pattern = /unit-\d+/
      const unitText =
        (unitPathnameSplit &&
          unitPathnameSplit.match(pattern)?.[0].replace('-', '')) ||
        ''
      return unitText
    }

    const getLessonIndex = () => {
      const pattern = /Lesson (\d+)/
      const lessonText = presentationButtonLabel.match(pattern)
      return (!!lessonText && parseInt(lessonText[1])) || null
    }

    await launchHelper({
      entryId: id,
      locale,
      lessonIndex: getLessonIndex(),
      mode: isFamilyResource && 'family',
      navigationEvent,
      theme: gradeFromPathname,
      unitText: getUnitFromPathname(),
    })
  }

  return (
    <ContainedButton
      dataTestId="content-presentation-button"
      onClick={() => launchPresentation()}
    >
      {presentationButtonLabel}
    </ContainedButton>
  )
}

ContentPresentation.propTypes = {
  id: PropTypes.string,
  isFamilyResource: PropTypes.bool,
  locale: PropTypes.string,
  navigationEvent: PropTypes.func,
  pathname: PropTypes.string,
  presentationButtonLabel: PropTypes.string,
}

export default ContentPresentation
