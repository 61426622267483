import { FlagsComponentRouter, FlagsSingleton } from 'flags'

import { isHighSchoolUrl } from 'utils/highschoolHelpers'
import { isElementaryProgramFlex } from 'utils/productHelpers'

import OffComponent from './flag.off/index'
import OnComponent from './flag.on/index'

const FlagToggler = FlagsComponentRouter({
  flagSubscriptions: [
    'middle-school-june-release',
    'high-school-june-release',
    'feature_LEARN-18511-k5-elem-program-flex',
  ],
  OnComponent,
  OffComponent,
  flagsChecker: FlagsSingleton.checkAtLeastOneFlagTrue,
  onCondition: (props, subscribedFlags) =>
    props?.match?.params?.productName === 'middle-school' ||
    isHighSchoolUrl() ||
    isElementaryProgramFlex(props, subscribedFlags),
})

export default FlagToggler
