import React from 'react'
import PropTypes from 'prop-types'

const UserManagementIcon = ({ fill }) => (
  <svg height="13px" viewBox="0 0 15 13" width="15px">
    <title>AP_icon_usermanagement</title>
    <g
      fill="none"
      fillRule="evenodd"
      id="AP_icon_usermanagement"
      stroke="none"
      strokeWidth="1"
    >
      <g id="Group-3" transform="translate(0.000000, 6.497714)">
        <mask fill="white" id="mask-2">
          <polygon
            id="path-1"
            points="0 0 9.63463143 0 9.63463143 6.50228586 0 6.50228586"
          />
        </mask>
        <g id="Clip-2" />
        <path
          d="M9.6311472,6.50230379 L0.0170471242,6.50230379 C0.0115594726,6.47290378 0.00217735854,6.44601354 0.00226586905,6.41912329 C0.00412458975,5.8268208 -0.0090634762,5.23389086 0.0127101092,4.64221581 C0.0890946791,2.56404793 1.5057939,0.762759957 3.50170589,0.187129415 C6.19693942,-0.590267609 8.9902426,1.13214231 9.53564436,3.91483464 C9.59264513,4.20596638 9.61955233,4.50642006 9.62707572,4.80346765 C9.6412374,5.36538417 9.6311472,5.92810739 9.6311472,6.50230379"
          fill={fill}
          id="Fill-1"
          mask="url(#mask-2)"
        />
      </g>
      <g id="Group-6" transform="translate(2.142710, 0.000000)">
        <mask fill="white" id="mask-4">
          <polygon
            id="path-3"
            points="0 0 5.3492262 0 5.3492262 5.41683459 0 5.41683459"
          />
        </mask>
        <g id="Clip-5" />
        <path
          d="M2.67196031,5.41683459 C1.18887821,5.41584801 -0.00282729805,4.2070418 0,2.70656605 C0.00274886406,1.20349091 1.19365777,-0.000743963484 2.67620881,-2.72103208e-05 C4.15681262,0.000779817146 5.34931472,1.21012383 5.3492262,2.71095812 C5.34904918,4.20982046 4.15389177,5.41781996 2.67196031,5.41683459"
          fill={fill}
          id="Fill-4"
          mask="url(#mask-4)"
        />
      </g>
      <path
        d="M10.6937867,11.9079602 C10.7663653,10.6029765 10.5084457,9.38915082 9.82620666,8.27024767 C9.86488576,8.23878608 9.89940486,8.20777266 9.93693331,8.18070315 C11.8703569,6.78635423 14.6155989,7.972483 14.9376001,10.3548692 C15.0061957,10.8614814 14.9813243,11.3810906 15,11.9079602 L10.6937867,11.9079602 Z"
        fill={fill}
        id="Fill-7"
      />
      <path
        d="M9.63226086,4.33053974 C9.6336786,3.13078657 10.5895921,2.1661438 11.7758985,2.16730799 C12.9599921,2.16865356 13.9138698,3.13777804 13.9123669,4.33815864 C13.910949,5.53665692 12.9530882,6.50327165 11.7683751,6.50183909 C10.5842815,6.50031372 9.63084627,5.53127887 9.63226086,4.33053974"
        fill={fill}
        id="Fill-9"
      />
    </g>
  </svg>
)

UserManagementIcon.propTypes = {
  fill: PropTypes.string,
}

export default UserManagementIcon
