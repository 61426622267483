import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import PathwayNavigation from 'experienceComponents/PathwayNavigation'
import StudentActivitiesCard from 'components/dashboard/HighSchoolStudentActivitiesCard'
import StaticCollectionCard from 'components/dashboard/HighSchoolStaticCollectionCard'
import HighSchoolTrainingOverdueBanner from 'components/dashboard/HighSchoolTrainingOverdueBanner'
import { isWithinDateRange } from 'utils/dateHelpers'
import {
  CollectionType,
  LinkDescriptionTitle,
  LinkTitle,
} from 'utils/highschoolHelpers'
import { formatPacingDates } from './helpers'
import {
  CollectionContainer,
  Container,
  PacingContainer,
  PacingDates,
  PacingHeader,
  PathwayHeader,
  PathwayNavigationWrapper,
  StaticDescriptionLinkContainer,
  StaticLinksPanelContainer,
  StyledHairline,
  TileImage,
  TrainingHeader,
} from './component.styles'
import { PATHWAY_TITLES, HIGH_SCHOOL_TRAINING_PATH } from './constants'
import ProgressIndicatorSvg from './ProgressIndicatorSvg'

function shouldShowStudentActivities(collection) {
  return (
    !!collection?.studentActivities && collection.studentActivities.length > 0
  )
}

function CollectionDisplay({
  collection,
  index,
  navigationEvent,
  resetAccordionSignal,
  training,
}) {
  const { pacing } = collection || {}
  const { startDate, endDate } = pacing || {}
  const formattedPacingDates = formatPacingDates(startDate, endDate)
  const isCurrentCollection = isWithinDateRange(startDate, endDate)
  const showPacingDates = !!formatPacingDates

  const [accordionIsOpen, setAccordionIsOpen] = useState(isCurrentCollection)

  useEffect(() => {
    setAccordionIsOpen(isCurrentCollection)
  }, [resetAccordionSignal])

  return (
    <CollectionContainer>
      <PacingContainer>
        {isCurrentCollection && <ProgressIndicatorSvg />}
        {/* TODO: pull this from Contentful instead of hardcoding: */}
        <PacingHeader isCurrentCollection={isCurrentCollection}>
          Collection {index}
        </PacingHeader>
        {showPacingDates && <PacingDates>{formattedPacingDates}</PacingDates>}
      </PacingContainer>
      {!!training && (
        <StaticCollectionCard
          isComplete={training?.isComplete}
          navigationEvent={navigationEvent}
          path={training?.path}
          title={training?.title}
        />
      )}
      {/* TODO: in the future this may be filtered out on the frontend if user is not facilitating schoolwide */}
      <StaticCollectionCard
        isComplete={collection?.schoolwide?.isComplete}
        navigationEvent={navigationEvent}
        path={collection?.schoolwide?.path}
        title={collection?.schoolwide?.title}
      />
      <StaticCollectionCard
        isComplete={collection?.educatorPractice?.isComplete}
        isSkipped={collection?.educatorPractice?.isSkipped}
        navigationEvent={navigationEvent}
        path={collection?.educatorPractice?.path}
        title={collection?.educatorPractice?.title}
      />
      {/* student activities may be empty if filtered out by user context */}
      {shouldShowStudentActivities(collection) && (
        <StudentActivitiesCard
          navigationEvent={navigationEvent}
          resetAccordionSignal={resetAccordionSignal}
          startExpanded={accordionIsOpen}
          studentActivities={collection?.studentActivities}
        />
      )}
    </CollectionContainer>
  )
}

CollectionDisplay.propTypes = {
  collection: CollectionType,
  index: PropTypes.number,
  navigationEvent: PropTypes.func,
  resetAccordionSignal: PropTypes.bool,
  training: LinkTitle,
}

function PathwayView({
  collections,
  navigationEvent,
  pathwayTitle,
  pathwayDescription,
  resetAccordionSignal,
  training,
}) {
  return (
    <>
      <PathwayHeader>
        <h3>{pathwayTitle}</h3>
        <p>{pathwayDescription}</p>
      </PathwayHeader>
      {collections?.map((collection, index) => (
        <CollectionDisplay
          collection={collection}
          index={index + 1}
          key={index}
          navigationEvent={navigationEvent}
          resetAccordionSignal={resetAccordionSignal}
          // Pathway training should be displayed in first collection:
          training={index === 0 ? training : null}
        />
      ))}
    </>
  )
}

PathwayView.propTypes = {
  collections: PropTypes.arrayOf(CollectionType),
  navigationEvent: PropTypes.func,
  pathwayDescription: PropTypes.string,
  pathwayTitle: PropTypes.string,
  resetAccordionSignal: PropTypes.bool,
  tileImage: PropTypes.string,
  training: LinkTitle,
}

function ProgramTrainingView({ navigationEvent, trainings, trainingNode }) {
  const { startDate, endDate } = trainingNode || {}
  const formattedTrainingDates = formatPacingDates(startDate, endDate)
  const isCurrentCollection = isWithinDateRange(startDate, endDate)
  const showPacingDates = !!formattedTrainingDates
  return (
    <>
      <PacingContainer>
        {isCurrentCollection && <ProgressIndicatorSvg />}
        {/* TODO pull this from Contentful */}
        <TrainingHeader>Program Training</TrainingHeader>
        {showPacingDates && <PacingDates>{formattedTrainingDates}</PacingDates>}
      </PacingContainer>
      {trainings?.map((training, index) => (
        <StaticCollectionCard
          isComplete={training?.isComplete}
          key={index}
          navigationEvent={navigationEvent}
          path={training?.path}
          title={training?.title}
        />
      ))}
    </>
  )
}

ProgramTrainingView.propTypes = {
  navigationEvent: PropTypes.func,
  trainingNode: PropTypes.object,
  trainings: PropTypes.arrayOf(LinkTitle),
}

function StaticDescriptionLink({ description, navigationEvent, title, path }) {
  return (
    <StaticDescriptionLinkContainer onClick={() => navigationEvent(path)}>
      <h4>{title}</h4>
      <p>{description}</p>
    </StaticDescriptionLinkContainer>
  )
}

StaticDescriptionLink.propTypes = {
  description: PropTypes.string,
  navigationEvent: PropTypes.func,
  path: PropTypes.string,
  title: PropTypes.string,
}

function StaticLinksPanel({ navigationEvent, staticLinks }) {
  return (
    <StaticLinksPanelContainer>
      {staticLinks?.map((link, index) => (
        <StaticDescriptionLink
          description={link?.description}
          key={index}
          navigationEvent={navigationEvent}
          path={link?.path}
          title={link?.title}
        />
      ))}
    </StaticLinksPanelContainer>
  )
}

StaticLinksPanel.propTypes = {
  navigationEvent: PropTypes.func,
  staticLinks: PropTypes.arrayOf(LinkDescriptionTitle),
}

function Component({
  collections,
  currentIndex,
  currentPathwayIndex,
  handlePathwayNavigation,
  navigationEvent,
  pathwayDescription,
  pathwayTitle,
  pathwayTraining,
  programTrainings,
  resetAccordionSignal,
  shouldShowTrainingOverdueNotification,
  staticLinks,
  tabStatuses,
  tileImage,
  trainingNode,
}) {
  return (
    <Container>
      <PathwayNavigationWrapper>
        <PathwayNavigation
          handlePathwayNavigation={handlePathwayNavigation}
          startingIndex={currentIndex}
          tabStatuses={tabStatuses}
        />
      </PathwayNavigationWrapper>
      {shouldShowTrainingOverdueNotification && (
        <HighSchoolTrainingOverdueBanner
          navigationEvent={navigationEvent}
          pathwayTitle={PATHWAY_TITLES[currentPathwayIndex]}
          // regex to strip out the trailing slash from the path:
          programTrainingLink={HIGH_SCHOOL_TRAINING_PATH.replace(
            /\/([^/]*)$/,
            '$1',
          )}
        />
      )}
      {/* NOTE: we shouldn't have a case where both programTrainings and collections are set, should only be one or the other */}
      {!!programTrainings && programTrainings?.length > 0 && (
        <ProgramTrainingView
          navigationEvent={navigationEvent}
          trainingNode={trainingNode}
          trainings={programTrainings}
        />
      )}
      {!!collections && collections?.length > 0 && (
        <PathwayView
          collections={collections}
          navigationEvent={navigationEvent}
          pathwayDescription={pathwayDescription}
          pathwayTitle={pathwayTitle}
          resetAccordionSignal={resetAccordionSignal}
          training={pathwayTraining}
        />
      )}
      <StyledHairline />
      <StaticLinksPanel
        navigationEvent={navigationEvent}
        staticLinks={staticLinks}
      />
      <TileImage alt="" src={tileImage} />
    </Container>
  )
}

Component.propTypes = {
  collections: PropTypes.arrayOf(CollectionType),
  currentIndex: PropTypes.number,
  currentPathwayIndex: PropTypes.number,
  handlePathwayNavigation: PropTypes.func,
  navigationEvent: PropTypes.func,
  pathwayDescription: PropTypes.string,
  pathwayTitle: PropTypes.string,
  pathwayTraining: LinkTitle,
  programTrainings: PropTypes.arrayOf(LinkTitle),
  resetAccordionSignal: PropTypes.bool,
  shouldShowTrainingOverdueNotification: PropTypes.bool,
  staticLinks: PropTypes.arrayOf(LinkDescriptionTitle),
  tabStatuses: PropTypes.array,
  tileImage: PropTypes.string,
  trainingNode: PropTypes.object,
}

export default Component
