import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { navigationEvent } from 'layers/navigation/store/operations'
import NavigationHelper from 'layers/navigation/navigationHelper'
import ReportsPageHeader from './component'

export const ReportsPageHeaderContainer = ({
  administratorsCount,
  hasClassesWithCompletedLessonLastPeriod,
  numClassesWithCompletedLessonLastPeriod,
  period,
  entry,
  gradeOrSiteName,
  isSchoolSummary,
  lastUpdatedAt,
  navigationEvent,
  selectedSite,
  teachersCount,
  tooltipText,
  totalClassesCount,
}) => {
  const navigateTo = url => {
    const type = NavigationHelper.types.PUSH
    navigationEvent(url, type)
  }

  return (
    <ReportsPageHeader
      administratorsCount={administratorsCount}
      entry={entry}
      gradeOrSiteName={gradeOrSiteName}
      hasClassesWithCompletedLessonLastPeriod={
        hasClassesWithCompletedLessonLastPeriod
      }
      isSchoolSummary={isSchoolSummary}
      lastUpdatedAt={lastUpdatedAt}
      navigateTo={navigateTo}
      navigationEvent={navigationEvent}
      numClassesWithCompletedLessonLastPeriod={
        numClassesWithCompletedLessonLastPeriod
      }
      period={period}
      selectedSite={selectedSite}
      teachersCount={teachersCount}
      tooltipText={tooltipText}
      totalClassesCount={totalClassesCount}
    />
  )
}

ReportsPageHeaderContainer.propTypes = {
  administratorsCount: PropTypes.number,
  entry: PropTypes.object,
  gradeOrSiteName: PropTypes.string,
  hasClassesWithCompletedLessonLastPeriod: PropTypes.bool,
  isSchoolSummary: PropTypes.bool,
  lastUpdatedAt: PropTypes.string,
  navigationEvent: PropTypes.func,
  numClassesWithCompletedLessonLastPeriod: PropTypes.number,
  period: PropTypes.number,
  selectedSite: PropTypes.number,
  teachersCount: PropTypes.number,
  tooltipText: PropTypes.string,
  totalClassesCount: PropTypes.number,
}

const mapStateToProps = ({ reportsManager }) => ({
  selectedSite: reportsManager.selectedSite,
})

const mapDispatchToProps = {
  navigationEvent,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsPageHeaderContainer)
