import React from 'react'
import PropTypes from 'prop-types'

import LessonDetailsPage from './component'
import { mapper } from './data'

const LessonDetailsPageContainer = ({ entry, location }) => {
  const parsedEntry = mapper(entry, location) || {}

  return <LessonDetailsPage entry={parsedEntry} />
}

LessonDetailsPageContainer.propTypes = {
  entry: PropTypes.object,
  location: PropTypes.object,
}

export default LessonDetailsPageContainer
