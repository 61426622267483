import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { MarkdownParagraph } from 'components/MarkdownComponent'
import TextLinkWithFileIcon from 'components/TextLinkWithFileIcon'
import { TextLink } from 'secondstep-components'

export const GridWrapper = styled.div`
  display: grid;
  grid-row-gap: 0;
  grid-column-gap: 0.563rem;
  grid-template-columns: repeat(auto-fill, minmax(31.25rem, 1fr));
  .first-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  @media screen and (min-width: 1400px) {
    grid-column-gap: 12.725rem;
  }
  
  @media screen and (min-width: 1120px) {
    height: 17.688rem;
  }

  @media screen and (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(19.25rem, 1fr));
  }
  @media screen and (max-width: 1070px) {
    grid-template-columns: repeat(auto-fill, minmax(20.25rem, 1fr));
  }
  @media screen and (min-width: 1071px) and  (max-width: 1118px) {
    grid-template-columns: repeat(auto-fill, minmax(21.25rem, 1fr));
  }

}`

export const BodyWrapper = styled.div`
  font-family: ${themeGet('font.molde.medium')};
  font-size: 1.125rem;
  line-height: 1.35rem;
  text-align: start;
  color: ${themeGet('colors.darkGray')};
}`

export const VideoWrapper = styled.div`
  margin-top: -2.175rem;
 
  height: 19.75rem;
  h4 {
    font-family: ${themeGet('font.molde.medium')};
    font-size: 1.125rem;
    color: ${themeGet('colors.darkBlue')};
    margin-top: -0.625rem;
    width: 85%;
  }

  @media screen and (max-width: 390px) {
    padding-right: 1.25rem;
  }
}
`
export const StyledMarkdownParagraph = styled(MarkdownParagraph)`
  font-family: ${themeGet('font.molde.regular')};
  color: ${themeGet('colors.darkGray')};
  font-size: 1rem;
  line-height: 1.375rem;
  margin-bottom: 2rem;
  li {
    list-style-type: disc;
  }
  ul {
    margin-top: 0.247rem;
    padding-right: 1.25rem;
    padding-left: 0.813rem;
  }
  margin-top: 3.725rem;
  strong {
    font-family: ${themeGet('font.molde.medium')};
    color: ${themeGet('colors.darkBlue')};
    font-weight: normal;
    font-size: 1.125rem;
    line-height: 1.75rem;
    padding-right: 1.25rem;
  }
  p {
    margin-bottom: 0;
  }
  @media screen and (max-width: 737px) {
    margin-top: 0;
  }
`

export const StyledTextLinkWithFileIcon = styled(TextLinkWithFileIcon)`
  position: relative;
  svg {
    position: absolute;
    min-width: 1.125rem;
    margin-top: 0;
    padding-top: 0;
    top: 0;
  }
  span {
    margin-left: 1.5rem;
    ${themeGet('breakpoints.mobileLarge')} {
      max-width: 19rem;
    }
    ${themeGet('breakpoints.mobile')} {
      max-width: 15rem;
    }
  }
  margin-right: 0.525rem;
`
export const LinkWrapper = styled.div`
  margin-top: 1.825rem;
  @media screen and (min-width: 1120px) {
    margin-top: -1.563rem;
  }
  display: flex;
  flex-wrap: wrap;
  color: ${themeGet('colors.blue')};
  font-size: 1rem;
  font-family: ${themeGet('font.molde.medium')};
  row-gap: 1rem;
  margin-bottom: 0.188rem;
  a > span {
    margin-top: 0.188rem;
    max-width: 19rem;
  }

  ${themeGet('breakpoints.large')} {
    width: 34rem;
  }

  @media screen and (max-width: 556px) {
    line-height: 1.063rem;
    width: 100%;
    .pipe-between-links {
      display: none;
    }
    a:last-of-type {
      margin-left: 0;
    }
  }
`

export const StyledLink = styled(TextLink)`
  text-align: start;
  margin-right: 0.525rem;
  margin-left: 0.525rem;
  text-decoration: none;
  line-height: inherit;
  color: ${themeGet('colors.blue')};
  font-size: 1rem;
  font-family: ${themeGet('font.molde.medium')};
  margin-top: 0.063rem;
`
