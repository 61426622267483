import SortableAccordionList from 'components/SortableAccordionList'
import styled from 'styled-components'
import { TOGGLE_VIEW } from 'store/userSessionManager/constants'

export const StyledSortableAccordionList = styled(SortableAccordionList).attrs(
  ({ toggleView }) => ({
    gridTemplateColumns:
      toggleView === TOGGLE_VIEW.MONITOR
        ? '1fr 1fr 1fr 1fr 1fr'
        : '1fr 1fr 1fr',
  }),
)`
  [class*='ListHeaderWrapper-sc'] {
    display: grid;
    grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
    margin-bottom: 0.9375rem;
  }
  [class*='PanelWrapper-sc'] {
    // display: grid;
    // grid-template-columns: 1fr 1fr 1fr;
    // align-items: center;
  }
`
