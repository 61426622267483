import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { ModalContentWrapper } from 'secondstep-components'
import ClosableModal from 'experienceComponents/ClosableModal'
import { MarkdownParagraph } from 'components/MarkdownComponent'
import { Button } from 'grommet'

export const StyledModal = styled(ClosableModal)`
  .Overlay {
    background-color: ${themeGet('colors.highschool.modalOverlay')};
  }
  ${ModalContentWrapper} {
    height: auto;
    max-width: 34rem;
    min-width: 21.125rem;
    padding: 2.3rem 1.75rem 2.55rem;
    transition: 0.5s height ease;
    display: flex;

    ${themeGet('breakpoints.mobileLarge')} {
      padding-left: 0.875rem;
      padding-right: 0.875rem;
      max-width: calc(100% - 1.25rem);
    }
  }
`

export const StyledModalTitle = styled.h2`
  color: ${themeGet('colors.highschool.green2')};
  font-family: ${themeGet('font.komet')};
  font-size: 1.75rem;
  font-weight: 800;
  line-height: 1.75rem;
  margin-bottom: 1.063rem;
`

export const StyledModalFooter = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 2.75rem;
`

const BaseButton = styled(Button)`
  height: 2.688rem;
  width: 6.875rem;
  border-radius: 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 2.5rem;
  text-align: center;

  font-family: ${themeGet('font.molde.medium')};
  margin-left: 1rem;
`

export const StyledPrimaryButton = styled(BaseButton)`
  background-color: ${themeGet('colors.buttons.primary.bgActive')};
  color: ${themeGet('colors.white')};

  &:not([disabled]):hover {
    background-color: ${themeGet('colors.buttons.primary.bgHover')};
    span {
      color: ${themeGet('colors.buttons.primary.labelHover')};
    }
    transition: all 0.2s ease;
  }
`

export const StyledSecondaryButton = styled(BaseButton)`
  background-color: ${themeGet('colors.white')};
  color: ${themeGet('colors.highschool.green2')};
  border: 0.125rem solid ${themeGet('colors.highschool.green2')};

  &:not([disabled]):hover {
    background-color: ${themeGet('colors.buttons.secondary.bgHover')};
    span {
      color: ${themeGet('colors.buttons.secondary.labelHover')};
    }
    transition: all 0.2s ease;
  }
`

export const StyleModalBody = styled.div``

export const StyledOptionsSection = styled.div`
  display: flex;
  justify-content: space-around;
  column-gap: 1rem;
  flex-wrap: wrap;

  @media screen and (max-width: 535px) {
    width: 74%;
  }

  @media screen and (max-width: 409px) {
    width: 100%;
  }
`

export const StyledOption = styled.button`
  flex-basis: 6.875rem;
  flex-grow: 1;
  background-color: ${themeGet('colors.highschool.beige3')};
  border: none;
  border-radius: 0.5rem;
  box-sizing: border-box;
  color: ${themeGet('colors.highschool.green2')};
  cursor: pointer;
  font-family: ${themeGet('font.molde.medium')};
  font-size: 1rem;
  font-weight: 500;
  height: 2.688rem;
  line-height: 2.688rem;
  letter-spacing: 0;
  outline-color: ${themeGet('colors.highschool.pink5')};
  text-align: center;
  vertical-align: middle;
  max-width: 9.188rem;

  &.selected {
    outline: 0.125rem solid ${themeGet('colors.hsPrimaryPink')};
  }

  &:not(.selected) {
    &:hover {
      outline: 0.125rem solid ${themeGet('colors.highschool.pink5')};
    }
  }

  margin-bottom: 0.875rem;
`

export const StyledText = styled.p`
  text-wrap: wrap;
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('font.molde.regular')};
  font-size: 1rem;
  letter-spacing: 0;
  line-height: 1.375rem;
  margin-bottom: 0.875rem;
`

export const StyledOptionsLegend = styled(MarkdownParagraph)`
  height: 1.375rem;
  width: 20.063rem;
  color: ${themeGet('colors.darkerDarkBlue')};
  font-family: ${themeGet('font.molde.regular')};
  font-size: 0.875rem;
  letter-spacing: 0;
  line-height: 1.375rem;
`
