import styled from 'styled-components'
import { BaseContainer, HeaderTwo } from 'secondstep-components'
import AdminExportDownloadButton from 'components/AdminExportDownloadButton'

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const StyledBaseContainer = styled(BaseContainer)`
  padding: 1.375rem 1.25rem 1.25rem;
  margin-top: 0;
`

export const StyledAdminExportDownloadButton = styled(
  AdminExportDownloadButton,
)`
  align-items: center;
  margin-bottom: 0.75rem;
`

export const StyledHeaderTwo = styled(HeaderTwo)`
  margin-bottom: 0.875rem;
`
