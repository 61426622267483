import React from 'react'
import PropTypes from 'prop-types'
import AdminWelcomeGuide from './component'
import { connect } from 'react-redux'
import operations from 'store/userSessionManager/operations'

const AdminWelcomeGuideContainer = ({
  isCollapsible,
  isWelcomeMessageOpen,
  navigationEvent,
  onboardingSnapshot,
  selectedProgram,
  setIsWelcomeMessageOpen,
}) => {
  return (
    <AdminWelcomeGuide
      isCollapsible={isCollapsible}
      isWelcomeMessageOpen={isWelcomeMessageOpen}
      navigationEvent={navigationEvent}
      onboardingSnapshot={onboardingSnapshot}
      selectedProgram={selectedProgram}
      setIsWelcomeMessageOpen={setIsWelcomeMessageOpen}
    />
  )
}

AdminWelcomeGuideContainer.propTypes = {
  isCollapsible: PropTypes.bool,
  isWelcomeMessageOpen: PropTypes.bool,
  navigationEvent: PropTypes.func,
  onboardingSnapshot: PropTypes.array,
  selectedProgram: PropTypes.object,
  setIsWelcomeMessageOpen: PropTypes.func,
}

const mapStateToProps = (
  { userSessionManager: { adminDashboard } },
  { selectedProgram = {} },
) => {
  const {
    k8: { isWelcomeMessageOpen },
  } = adminDashboard

  let isCollapsible =
    !!adminDashboard[selectedProgram?.key.toLowerCase()] ?? false

  return {
    isCollapsible,
    isWelcomeMessageOpen,
  }
}

const mapDispatchToProps = {
  setIsWelcomeMessageOpen: operations.setIsWelcomeMessageOpen,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdminWelcomeGuideContainer)
