import styled from 'styled-components'

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  position: relative;
`

export const RatioWrapper = styled.div`
  // scale video player for 16:9 aspect ratio
  // add a bit of extra padding to remove black bars on sides
  padding-top: 57.5%;
  width: 100%;
  position: relative;
`

export const VideoWrapper = styled.div`
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-bottom 1.125rem;

  .azuremediaplayer,
  .vjs-poster img,
  .vjs-tech,
  .vjs-play-control,
  .vjs-play-control:hover,
  .vjs-fullscreen-control,
  .vjs-fullscreen-control:hover,
  .amp-flush-skin.amp-size-s .vjs-control-bar .amp-controlbaricons-right .vjs-control:not(.vjs-menu-item):last-child {
    border-radius: 0.625rem !important;
    overflow: hidden;
  }

  .vjs-control-bar,
  .amp-flush-skin.amp-size-s .vjs-control-bar {
    border-radius: 0 0 0.625rem 0.625rem !important;
  }

  // makes sure the poster stays in position when toggling transcript:
  .vjs-poster {
    img {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .vjs-big-play-button:focus {
    outline: 0.313rem auto white;
  }
`
