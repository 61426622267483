import styled from 'styled-components'

export const ExtraLargeHeader = styled.h2`
  font-size: 2.1875rem;
  line-height: 3.25rem;
  margin: 0;
  color: ${props => (props.white ? '#fff' : '#1f1f1f')};
  text-align: ${props => (props.center ? 'center' : 'left')};
`

export const LargeHeader = styled(ExtraLargeHeader.withComponent('h3'))`
  font-size: 1.75rem;
  line-height: 2rem;
`

export const MediumHeader = styled(ExtraLargeHeader.withComponent('h4'))`
  font-size: 1.5rem;
  line-height: 1.75rem;
`

export const SmallHeader = styled(ExtraLargeHeader.withComponent('h5'))`
  font-size: 1.3125rem;
  line-height: 1.5625rem;
`

export const ExtraSmallHeader = styled(ExtraLargeHeader.withComponent('h6'))`
  font-size: 1.125rem;
  line-height: 1.375rem;
`

export const MainHeader = styled(ExtraLargeHeader.withComponent('h1'))`
  font-size: 1.25rem;
`
