import ContentfulReducer from 'layers/content/store/reducer'
import ContenfulTypes from 'layers/content/store/types'
import { NAVIGATION_EVENT } from 'layers/navigation/store/types'

const mappedState = {
  [ContenfulTypes.CONTENTFUL_REQUEST]: ContentfulReducer.ROOT_NAMESPACE,
  [ContenfulTypes.CONTENTFUL_FAILURE]: ContentfulReducer.ROOT_NAMESPACE,
  [ContenfulTypes.CONTENTFUL_SUCCESS]: ContentfulReducer.ROOT_NAMESPACE,
  [ContenfulTypes.SET_CONTENT_NAMESPACE]: ContentfulReducer.ROOT_NAMESPACE,
  [NAVIGATION_EVENT]: 'router',
}
const disable = true
export default store => next => action => {
  const { type: fullType, payload, ...rest } = action
  const [prefix, actionType] = fullType.split('/')
  const type = prefix && actionType ? prefix : fullType
  const getState = () =>
    store.getState()[type] ||
    store.getState()[`${type}Manager`] ||
    store.getState()[mappedState[type]]
  const data = payload || { ...rest }
  const state = getState()
  next(action)
  if (disable) {
    return
  }
  console.groupCollapsed(`Action type: ${fullType}`)
  console.info('Action data:', data)
  console.info('State before:', state)
  console.info('State after:', getState())
  console.groupEnd()
}
