import React, { useEffect, useState } from 'react'
import { useCurrentRefinements } from 'react-instantsearch-hooks-web'
import _ from 'lodash'
import {
  ClearAllButton,
  Container,
  FilterChip,
  OverflowButton,
  ButtonContainer,
  StyledList,
} from './refinements.styles'
import {
  CLEAR_ALL_BUTTON_TEXT,
  SEE_LESS_BUTTON_TEXT,
  SEE_MORE_BUTTON_TEXT,
} from './constants'

import {
  AI_HS_FILTER_EVENT,
  sendApplicationInsightsEvent,
} from 'utils/sendAnalyticsDataHelpers'

import CloseIcon from './assets/refinement_clear_icon.svg'
import PropTypes from 'prop-types'

// rough approximations:
const WIDTH_PER_FILTER = 140
const MOBILE_BREAKPOINT = 580
const DESKTOP_PADDING = 300
const MAX_CONTAINER_WIDTH = 972

// export for testability
export const getVisibleItemCount = windowWidth => {
  const availableWidth = Math.min(
    windowWidth > MOBILE_BREAKPOINT
      ? windowWidth - DESKTOP_PADDING
      : windowWidth,
    MAX_CONTAINER_WIDTH,
  )
  // muliply by 2 for two rows
  // subtract 1 for the action buttons:
  return Math.floor((availableWidth * 2) / WIDTH_PER_FILTER) - 1
}

const CustomCurrentRefinements = props => {
  const { visibleItems, setVisibleItems } = props
  const [hasOverflowItems, setHasOverflowItems] = useState(false)
  const [overflowItemsVisible, setOverflowItemsVisible] = useState(false)

  // observed bug where this returns an empty array when called on a resize event
  const { items, refine } = useCurrentRefinements(props)
  const truncateItems = () => {
    const filterItems = items.flatMap(item => item.refinements)
    const visibleCount = getVisibleItemCount(window.innerWidth)
    if (overflowItemsVisible) {
      if (visibleCount > filterItems.length && hasOverflowItems) {
        setHasOverflowItems(false)
        setOverflowItemsVisible(false)
      }
      // requires deep equality check to avoid infinite loop in tests
      if (!_.isEqual(filterItems, visibleItems)) {
        setVisibleItems(filterItems)
      }
      return
    }
    const areItemsHidden = visibleCount < filterItems.length
    if (areItemsHidden !== hasOverflowItems) {
      setHasOverflowItems(areItemsHidden)
    }
    const truncatedItems = filterItems.slice(0, visibleCount)
    // requires deep equality check to avoid infinite loop in tests
    if (!_.isEqual(truncatedItems, visibleItems)) {
      setVisibleItems(truncatedItems)
    }
  }

  const handleClearAll = () => {
    items.forEach(refinement => {
      refinement.refinements.forEach(value => {
        refine(value)
      })
    })
  }

  useEffect(() => {
    if (visibleItems.length > 0) {
      sendApplicationInsightsEvent(AI_HS_FILTER_EVENT, visibleItems)
    }
  }, [visibleItems])

  useEffect(() => {
    truncateItems()
    window.addEventListener('resize', truncateItems)
    return () => window.removeEventListener('resize', truncateItems)
  }, [items, overflowItemsVisible])

  return (
    items.length > 0 && (
      <Container>
        <StyledList>
          {visibleItems.map((ref, index) => (
            <FilterChip key={index} onClick={() => refine(ref)}>
              {ref.value}
              {'  '}
              <img alt="close icon" className="close-icon" src={CloseIcon} />
            </FilterChip>
          ))}
          {hasOverflowItems && (
            <ButtonContainer>
              <OverflowButton
                onClick={() => setOverflowItemsVisible(!overflowItemsVisible)}
              >
                {overflowItemsVisible
                  ? SEE_LESS_BUTTON_TEXT
                  : SEE_MORE_BUTTON_TEXT}
              </OverflowButton>
            </ButtonContainer>
          )}
          {items.length > 0 && (
            <ButtonContainer>
              <ClearAllButton onClick={handleClearAll}>
                {CLEAR_ALL_BUTTON_TEXT}
              </ClearAllButton>
            </ButtonContainer>
          )}
        </StyledList>
      </Container>
    )
  )
}

CustomCurrentRefinements.propTypes = {
  setVisibleItems: PropTypes.func,
  visibleItems: PropTypes.array,
}
export default CustomCurrentRefinements
