import React from 'react'

import dayjs from 'dayjs'

import { getTheme } from 'themes'
import { toCamelCase } from 'utils/stringHelpers'
import { deepMerge } from 'grommet/utils'

import LicenseOption from 'components/LicenseOption'
import SchoolOption from 'components/SchoolOption'
import productMaps, {
  ProductType,
  RouteContentProductAppMap,
} from './productMaps'

export const DEFAULT_LICENSE_ID = 'Unknown license ID'
export const DEFAULT_PRODUCT = 'Unknown product'
export const DEFAULT_SITE_NAME = 'Unknown school'
export const SEPARATOR = ' | '
export const UNKNOWN_LICENSE = 'Unknown license'

export const Category = {
  BULK: { category: 'BULK', name: 'Schoolwide' },
  FREE: { category: 'FREE', name: 'Trial' },
  INDIVIDUAL: { category: 'INDIVIDUAL', name: 'Individual' },
}

export const AccessLevel = {
  Setup: { role: 'SETUP', name: 'Setup', article: 'a' },
  User: { role: 'USER', name: 'User', article: 'a' },
  Admin: { role: 'ADMIN', name: 'Administrator', article: 'an' },
  PTK: { role: 'PTK', name: 'Principal Toolkit User', article: 'a' },
}

export const InvitationStatus = {
  INV: 'Invited',
  CAN: 'Canceled',
  ACT: 'Active',
  Invited: 'INV',
  Canceled: 'CAN',
  Active: 'ACT',
  None: null,
}

export const extractExpirationInfo = (obj, format = 'MMMM DD, YYYY') => {
  const startDate = obj.startDate ? dayjs(obj.startDate) : null
  const cancellationDate = obj.canceledDate
    ? dayjs(obj.canceledDate)
    : undefined
  const expirationDate = obj.expirationDate
    ? dayjs(obj.expirationDate)
    : undefined
  const date =
    obj.canceledDate && cancellationDate < expirationDate
      ? cancellationDate
      : expirationDate
  const now = dayjs.utc()
  const isExpired = date <= now
  const daysLeft = Math.max(0, date && date.diff(now, 'days'))
  let icon = ''
  if (date <= now) icon = <i className="fa fa-times-circle text-danger" />
  else if (daysLeft <= 90)
    icon = <i className="fa fa-exclamation-triangle text-warning" />

  return {
    now: now,
    startDate: {
      native: obj.startDate,
      dayjs: startDate,
      formatted: obj.startDate ? startDate.format(format) : null,
    },
    expirationDate: {
      native: obj.expirationDate,
      dayjs: expirationDate,
      formatted: obj.expirationDate ? expirationDate.format(format) : null,
    },
    canceledDate: {
      native: obj.canceledDate,
      dayjs: obj.canceledDate ? cancellationDate : null,
      formatted: obj.canceledDate ? cancellationDate.format(format) : null,
    },
    effectiveEndDate: {
      dayjs: date,
      formatted: date && date.format(format),
    },
    format: format,
    isExpired: isExpired,
    isExpiringSoon: !isExpired && daysLeft <= 90,
    expirationWarningThresholdDays: 90,
    daysLeft: daysLeft,
    label: isExpired ? 'Expired on ' : 'Expires on ',
    icon: icon,
  }
}

export const selectUsers = (license, accessLevel, email = '') => {
  let users = []
  const filterFunc = !email
    ? user => user.invitationStatus !== InvitationStatus.Canceled
    : user =>
        user.invitationStatus !== InvitationStatus.Canceled &&
        user.email === email
  switch (accessLevel.role) {
    case AccessLevel.Setup.role:
      users = [license.setupAdminEmail].filter(filterFunc)
      break

    case AccessLevel.User.role:
      users = [...license.users.filter(filterFunc)] || []
      break

    case AccessLevel.Admin.role:
      users = [...license.admins.filter(filterFunc)] || []
      break

    case AccessLevel.PTK.role:
      users = [...license.ptkUsers.filter(filterFunc)] || []
      break

    default:
      return []
  }

  return users
}

export const getProductAccess = (licenses = [], currentUserEmail) => {
  let result = {}

  for (let i = 0; i < licenses.length; i++) {
    const skuCode = licenses[i].skuCode
    const expiration = extractExpirationInfo(licenses[i])

    // short-circuits for BPU/CPU, canceled licenses, and expiration
    if (skuCode !== ProductType.MSP.skuCode || expiration.isExpired) continue

    if (!result[skuCode])
      result[skuCode] = {
        roles: new Set(),
        grades: new Set(),
        userLicenses: [],
      }

    // USER ACCESS
    const hasAccess =
      selectUsers(licenses[i], AccessLevel.User, currentUserEmail).length > 0

    if (hasAccess) {
      result[skuCode].roles = new Set([
        ...result[skuCode].roles,
        AccessLevel.User.role,
      ])
      result[skuCode].grades = new Set([
        ...result[skuCode].grades,
        ...licenses[i].grades,
      ])
      result[skuCode].userLicenses.push(licenses[i])
    }

    // PTK ACCESS
    if (skuCode === ProductType.MSP.skuCode) {
      const hasAccess =
        selectUsers(licenses[i], AccessLevel.PTK, currentUserEmail).length > 0

      if (hasAccess)
        result[skuCode].roles = new Set([
          ...result[skuCode].roles,
          AccessLevel.PTK.role,
        ])
    }
  }
  return result
}

export const audience = (() => {
  const _audienceMap = {
    Student: null,
    Teacher: AccessLevel.User.role,
    Counselor: AccessLevel.User.role,
    'Parent/Carevgiver': null,
    'Site Administrator': AccessLevel.PTK.role,
    'District Administrator': null,
    'Implementation Coordinator': null,
  }

  return {
    toRole: audience => _audienceMap[audience],
  }
})()

/**
 *  This function takes the metadata object from Contentful and parsed it to return a list of strings (array)
 * @param {Array} metadata - array of metadata from Contentful
 * @param {String} contentTypeId - the contentType.sys.id that is returned
 * @param {String} fieldId
 * @return {Array}
 */
export const parseMetadata = (metadata = [], contentTypeId, fieldId) => {
  return metadata
    .filter(item => item.contentType.sys.id === contentTypeId)
    .map(value => value[fieldId])
}

// Get text to display a license value in a dropdown.
export const getLicenseOptionText = (
  licenseId,
  product = DEFAULT_PRODUCT,
  siteName,
) => {
  if (!licenseId && !product && !siteName) {
    return UNKNOWN_LICENSE
  }

  const suffix = `${product}${SEPARATOR}ID: ${licenseId}`
  const optionText = siteName ? `${siteName}${SEPARATOR}${suffix}` : suffix

  return optionText
}

// Get a license option to use for a license dropdown
export const getLicenseOption = license => {
  const { licenseId: id, product, siteName } = license || {}
  const text = getLicenseOptionText(id, product, siteName)
  const licenseOption = {
    id,
    component: (
      <LicenseOption licenseId={id} product={product} siteName={siteName} />
    ),
    text,
  }

  return licenseOption
}

// Get text to display a school value in a dropdown.
export const getSchoolOptionText = (
  licenseId,
  product = DEFAULT_PRODUCT,
  siteName,
) => {
  if (!licenseId && !product && !siteName) {
    return UNKNOWN_LICENSE
  }

  const optionText = siteName

  return optionText
}

// Get a school option to use for a school dropdown
export const getSchoolOption = license => {
  const { licenseId: id, product, siteName } = license || {}
  const text = getSchoolOptionText(id, product, siteName)
  const schoolOption = {
    id,
    component: (
      <SchoolOption licenseId={id} product={product} siteName={siteName} />
    ),
    text,
  }

  return schoolOption
}

export const getLicenseFromSchoolOptionText = (optionText, licenses) => {
  if (!optionText) {
    return
  }

  if (typeof optionText !== 'string') {
    return
  }

  return licenses.find(license => {
    return license.siteName === optionText
  })
}

// Extract a license ID from a dropdown license option value
export const getLicenseIdFromLicenseOptionText = licenseOptionText => {
  if (!licenseOptionText) {
    return
  }

  if (typeof licenseOptionText !== 'string') {
    return
  }

  const splitLicenseOption = licenseOptionText
    ? licenseOptionText.split(`${SEPARATOR}ID: `)
    : []
  const hasIdArrayNode = splitLicenseOption.length >= 1
  const rawId = hasIdArrayNode && splitLicenseOption[1]
  const formattedLicenseId = rawId && rawId.trim()

  return formattedLicenseId
}

/**
 * take a possible program name (grades-k-5) and search for the matching product object in productMaps (elementary)
 * @param {string} possibleProgramName - search by program.programName or program.otherNames
 */
export const getProductFromProductMap = (possibleProgramName = '') => {
  return Object.values(productMaps).find(product => {
    const allPossibleProgramNames = [
      ...(product?.alternateNames || []),
      product.productName,
    ]
    return allPossibleProgramNames.find(
      name => name?.toLowerCase() === possibleProgramName.toLowerCase(),
    )
  })
}

export const getProductFromUrl = () => {
  let productName
  const isProductPath = window.location.href.includes('/product/')
  const isReportsPath = window.location.href.includes('/reports/')
  const isDistrictPath = window.location.href.includes('/district/')
  const isHighSchoolFamilyResourcesPath = window.location.href.includes(
    '/family/',
  )
  const isFamilyResourcesPath = window.location.href.includes(
    '/family-resources/',
  )
  const isMindYetiFamilyPath = window.location.href.includes(
    '/family-mind-yeti/',
  )
  const isAdminPath = window.location.href.includes('/admin')
  const isOstKitPathK1 = window.location.href.includes(
    '/out-of-school-time/grades-k-1',
  )
  const isOstKitPath23 = window.location.href.includes(
    '/out-of-school-time/grades-2-3',
  )
  const isOstKitPath45 = window.location.href.includes(
    '/out-of-school-time/grades-4-5',
  )
  const isOstKitDefaultPath = window.location.href.includes(
    '/out-of-school-time',
  )
  const notOstPath =
    !isOstKitDefaultPath &&
    !isOstKitPathK1 &&
    !isOstKitPath23 &&
    !isOstKitPath45
  if (isAdminPath) productName = 'admin'
  if (isDistrictPath) productName = 'district'
  if (isFamilyResourcesPath) productName = 'familyResources'
  if (isHighSchoolFamilyResourcesPath) productName = 'familyResourcesHS'
  if (isMindYetiFamilyPath) productName = 'mind-yeti'
  if (isReportsPath) productName = 'reports'
  if (isOstKitPathK1) {
    productName = 'outOfSchoolTimeK1'
  } else if (isOstKitPath23) {
    productName = 'outOfSchoolTime23'
  } else if (isOstKitPath45) {
    productName = 'outOfSchoolTime45'
  } else if (isOstKitDefaultPath) {
    productName = 'outOfSchoolTime'
  }
  if (isProductPath && notOstPath) {
    productName = window.location.href.split('/product/')
    productName =
      productName.length > 1 ? productName[1].split('/')[0] : undefined
  }
  if (!productName) {
    return undefined
  }

  return productMaps[toCamelCase(productName)]
}

export const getThemeFromUrl = () => {
  let mainTheme = getTheme('main')
  let platformTheme = getTheme('platform')

  const isAccessibility = window.location.href.includes('/accessibility')
  const isWebinars = window.location.href.includes('/webinars')
  const isDashboardPath = window.location.href.includes('/dashboard')
  if (isDashboardPath || isAccessibility || isWebinars)
    return deepMerge(mainTheme, platformTheme)
  const product = getProductFromUrl()
  if (product) {
    const productTheme = getTheme(product?.themeName)
    mainTheme = deepMerge(mainTheme, productTheme)
  }

  return mainTheme
}

export const isMiddleSchoolProductJuneRelease = (_, subscribedFlags) => {
  const { pathname } = window.location

  return (
    subscribedFlags['middle-school-june-release'] &&
    pathname.includes('product/middle-school')
  )
}

export const isHighSchoolOrMiddleSchoolJuneRelease = (_, subscribedFlags) => {
  const { pathname } = window.location

  return (
    (subscribedFlags['high-school-june-release'] &&
      pathname.includes('product/high-school')) ||
    isMiddleSchoolProductJuneRelease(_, subscribedFlags)
  )
}

export const isNewFamilyResources = (_, subscribedFlags) => {
  const { pathname } = window.location

  return (
    // NOTE: there are URLs which include family-resources that are not part the Family section of site
    pathname.startsWith('/family-resources') &&
    subscribedFlags['feature-learn-17909-program-flex-family-resources']
  )
}

export function isHighSchoolExtensionActivities(_, subscribedFlags) {
  const { pathname } = window.location

  return (
    subscribedFlags['feature_learn-18453-hs-student-extension-activities'] &&
    pathname.includes('product/high-school')
  )
}

export const isElementaryPage = () => {
  const { pathname } = window.location

  return pathname.includes('/product/elementary')
}

export const isElementaryProgramFlex = (_, subscribedFlags) => {
  return (
    isElementaryPage() &&
    subscribedFlags['feature_LEARN-18511-k5-elem-program-flex']
  )
}

const USER_CONTEXT_OVERRIDE_KEY = 'userContextOverride'

// userContextOverride should be base64 encoded
export function extractUserContextOverride(location = window.location) {
  const searchParams = new URLSearchParams(location.search)
  const userContextOverride = searchParams.get(USER_CONTEXT_OVERRIDE_KEY)
  if (userContextOverride) {
    sessionStorage.setItem(USER_CONTEXT_OVERRIDE_KEY, userContextOverride)
  }
  // we save for the length of a browser session so we don't need to re-add to the query params every page reload
  const sessionStickyUserContextOverride = sessionStorage.getItem(
    USER_CONTEXT_OVERRIDE_KEY,
  )
  if (sessionStickyUserContextOverride) {
    return sessionStickyUserContextOverride
  }

  return userContextOverride
}

export const isHighSchoolFamilyResources = (_, subscribedFlags) => {
  const { pathname } = window.location
  // this is a bit of an oddity, but /family-resources/ is K8 and /family/ is HS:
  return (
    pathname.startsWith('/family/') &&
    subscribedFlags['feature_LEARN-18248-hs-family-resources']
  )
}

export function resolveRouteServiceApp(path) {
  if (path.startsWith('/family-resources')) {
    return RouteContentProductAppMap.learnFamilyResourcesK8
  }
  if (path.startsWith('/family')) {
    return RouteContentProductAppMap.learnFamilyResourcesHS
  }
  if (
    path.startsWith('/product/high-school') ||
    // support modified routes missing the /product prefix:
    path.startsWith('/high-school')
  ) {
    return RouteContentProductAppMap.learnHsProduct
  }
  if (
    path.startsWith('/product/middle-school') ||
    // support modified routes missing the /product prefix:
    path.startsWith('/middle-school')
  ) {
    return RouteContentProductAppMap.learnMsProduct
  }
  // TODO: add elementary (k5) here once that's a supported route tree
  throw new Error(`Path ${path} does not have a supported route-service app`)
}

export function getRouteServiceProductPath() {
  const { pathname } = window.location
  if (pathname.startsWith('/product')) {
    // remove the /product/ prefix because this is not part of the sub-product route trees such learnHsProduct
    return pathname.replace('/product', '')
  }
  return pathname
}
