import ResumeButton from '../../components/ResumeButton'
import ReviewButton from '../../components/ReviewButton'
import StartButton from '../../components/StartButton'
import PreviewButton from '../../components/PreviewButton'

export default {
  'not attempted': {
    Button: StartButton,
    children: 'Teach',
    dataTestId: 'button-start',
  },
  incomplete: {
    Button: ResumeButton,
    children: 'Resume',
    dataTestId: 'button-resume',
  },
  completed: {
    Button: ReviewButton,
    children: 'Review',
    dataTestId: 'button-review',
  },
  preview: {
    Button: PreviewButton,
    children: 'Preview',
    dataTestId: 'button-preview',
  },
}
