import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  ContentContainer,
  PageWrapper,
  BannerSpacer,
  SiteSelectorWrapper,
} from './component.styles'
import HighSchoolPreviewBanner from 'components/HighSchoolPreviewBanner'

import SubLandingBanner from 'experienceComponents/SubLandingBanner'
import ContentCallout from 'experienceComponents/ContentCallout'

import NavigationHelper from 'layers/navigation/navigationHelper'
import withSiteContext from 'layers/content/v2/Hocs/withSiteContext'
import SiteSelector from 'components/SiteSelector'
import { isHighSchoolUrl, isHighSchoolDashboard } from 'utils/highschoolHelpers'
import { isFlagOn } from 'utils/featureFlags'
import { isElementaryPage } from 'utils/productHelpers'

class ResourcesSubLandingPage extends Component {
  static propTypes = {
    articles: PropTypes.arrayOf(PropTypes.object),
    banner: PropTypes.object,
    entry: PropTypes.object,
    isHsPreview: PropTypes.bool,
    navigationEvent: PropTypes.func,
  }

  componentDidMount() {
    if (
      isElementaryPage() &&
      isFlagOn(['feature_LEARN-18511-k5-elem-program-flex'])
    ) {
      console.log(
        '[DEBUG] ResourcesSubLandingPage rendering K5 in program flex',
      )
    }
  }

  render() {
    const {
      articles = [],
      banner,
      isSelaLeaderPage,
      isSelaProduct,
      isHsPreview,
      pageType,
    } = this.props || {}

    const filteredArticles = articles.filter(article => {
      return article.body && article.title
    })

    this.navigateToDashboardOnClick = () => {
      const { navigationEvent } = this.props
      const url = `/dashboard`
      const type = NavigationHelper.types.PUSH
      navigationEvent(url, type)
    }

    return (
      <PageWrapper isSelaProduct={isSelaProduct}>
        {isHsPreview && (
          <>
            <BannerSpacer />
            <HighSchoolPreviewBanner />
          </>
        )}
        {!isHsPreview && (
          <SiteSelectorWrapper>
            <SiteSelector />
          </SiteSelectorWrapper>
        )}
        <SubLandingBanner
          isSelaLeaderPage={isSelaLeaderPage}
          key="banner"
          navigateToDashboardOnClick={this.navigateToDashboardOnClick}
          pageType={pageType}
          {...banner}
        />
        <ContentContainer>
          {filteredArticles.map((article, index) => {
            const uniqueId = `article-${index + 1}`
            return (
              <ContentCallout
                {...article}
                dataTestId={uniqueId}
                isHsPreview={isHsPreview}
                key={uniqueId}
              />
            )
          })}
        </ContentContainer>
      </PageWrapper>
    )
  }
}
const needsSiteContext = isHighSchoolUrl() || isHighSchoolDashboard()
const ComponentToExport = needsSiteContext
  ? withSiteContext(ResourcesSubLandingPage, { useGuard: true })
  : ResourcesSubLandingPage

export default ComponentToExport
