import styled from 'styled-components'
import { themeGet } from 'styled-system'
import { Accordion, Bullet, Hairline, HeaderThree } from 'secondstep-components'
import AdminLessonProgressTabs from 'components/AdminLessonProgressTabs'

const breakpoints = {
  customExtraLarge: '@media screen and (max-width: 1315px)',
  customLarge: '@media screen and (max-width: 1250px)',
  customMedium: '@media screen and (max-width: 1100px)',
  customTablet: '@media screen and (max-width: 860px)',
  customMobileLarge: '@media screen and (max-width: 620px)',
}

export const BodyWrapper = styled.div.attrs(({ classesCreated }) => ({
  padding: classesCreated === 0 ? '0' : '0.875rem 1.25rem 1.75rem',
  alignItems: classesCreated === 0 ? 'center' : 'inherit',
}))`
  align-items: ${({ alignItems }) => alignItems};
  background-color: ${themeGet('colors.linkLight')};
  border: 2px solid ${themeGet('colors.paleGray')};
  border-radius: 0.625rem;
  padding: ${({ padding }) => padding};

  ${themeGet('breakpoints.mobile')} {
    padding-bottom: 0.3125rem;
  }
`

export const ClassesProgressWrapper = styled.div`
  width: 18%;
  min-width: 145px;
  color: ${themeGet('colors.darkGray')};
  font-size: 1rem;
  padding-top: 0.1875rem;
  padding-left: 1rem;
  line-height: 1.375rem;

  ${breakpoints.customMedium} {
    width: 9.875rem;
    padding-left: 0.875rem;
  }

  ${breakpoints.customTablet} {
    display: none;
  }
`

export const ClassProgressBullet = styled(Bullet)`
  background-color: ${themeGet('colors.yellow')};
  transform: translateY(0.0625rem);
`

export const ClassesNotStartedText = styled.span`
  font-family: ${themeGet('fontFamily')};
`

export const ClassesNotStartedWrapper = styled.div``

export const ClassesStartedText = styled.span`
  font-family: ${themeGet('fontFamilySemibold')};
`

export const LessonCompletionWrapper = styled.div.attrs(
  ({ classesCreated }) => ({
    opacity: classesCreated === 0 ? '50%' : 'inherit',
  }),
)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 185px;
  opacity: ${({ opacity }) => opacity};
  padding-top: 0.125rem;

  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 1rem;

  [class*='Background'] {
    background-color: ${themeGet('colors.linkLight')};
    height: 0.75rem;
    margin-top: 0;
    max-width: 9.25rem;
  }

  [class*='Indicator'] {
    height: 0.75rem;
  }

  [class*='ProgressOutsideBar'] {
    padding-top: 0.0875rem;
  }

  [class*='Wrapper'] {
    width: 100%;
  }

  ${breakpoints.customMedium} {
    width: auto;
    min-width: 11.25rem;

    [class*='Background'] {
      width: 100%;
      max-width: 11.25rem;
    }
  }

  ${themeGet('breakpoints.mobileLarge')} {
    min-width: unset;
    width: 100px;
    padding-left: 0.375rem;

    [class*='Background'] {
      display: none;
    }

    [class*='ProgressOutsideBar'] {
      padding-left: 0;
    }
  }

  ${themeGet('breakpoints.mobile')} {
    width: 44%;
  }
`

export const NoClassesMessage = styled.span`
  font-family: ${themeGet('fontFamilySemibold')};
`

export const PanelWrapper = styled.div`
  display: flex;
  direction: row;
  align-items: center;
  width: 100%;

  ${breakpoints.customMedium} {
    justify-content: space-between;
  }

  ${themeGet('breakpoints.mobile')} {
    justify-content: flex-start;
  }
`

export const RightWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;

  ${breakpoints.customMedium} {
    display: none;
  }
`

export const SiteCollapsible = styled(Accordion).attrs(
  ({ classesNotStarted, dataTestId }) => ({
    padding:
      classesNotStarted === 0
        ? '0.9125rem 0.25rem 0.9125rem 1.125rem'
        : '0.6875rem 0.25rem 0.6875rem 1.125rem',
    'data-testid': dataTestId,
  }),
)`
  width: 100%;
  border: 2px solid
    ${themeGet('colors.backgrounds.secondary', 'colors.paleGray')};
  background: ${themeGet('colors.paleGray')};

  &:hover {
    outline: 2px solid ${themeGet('colors.grayBlue')};
  }

  svg {
    color: ${themeGet('colors.blue')};
    margin-right: 0;
  }

  [class*='AccordionPanel'] {
    padding: ${({ padding }) => padding};
    background: ${themeGet('colors.paleGray')};

    ${breakpoints.customTablet} {
      padding: 0.875rem 1.125rem;
    }

    &:hover {
      svg {
        color: ${themeGet('colors.darkBlue')};
      }
    }
  }

  [class*='AccordionTitleWrapper'] {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
  }

  [class*='AccordionTitle'] {
    width: 100%;
    margin-right: 0;
  }

  [class*='AccordionContentWrapper'] {
    width: 100%;
    margin: 0;
    overflow: visible;
    [class*='AccordionContent'] {
      padding: 0 1rem 1.25rem 1rem;
      background: ${themeGet('colors.paleGray')};
    }
  }

  [class*='StyledCaret'] {
    &:hover {
      color: ${themeGet('colors.darkBlue')};
    }
  }
`

export const StyledAdminLessonProgressTabs = styled(AdminLessonProgressTabs)`
  margin: 0.8125rem 0 0.75rem;

  ${breakpoints.customTablet} {
    margin: 0 0 0.5rem;
  }
`

export const StyledHairline = styled(Hairline)`
  color: ${themeGet('colors.paleGray')};
  height: 0.125rem;
  margin: 1.75rem 0 1.4375rem;
  opacity: 0.5;
  width: unset;

  ${themeGet('breakpoints.mobile')} {
    margin: 1.25rem 0 0.9375rem;
  }
`

export const StyledHeader = styled(HeaderThree)`
  font-size: 1.125rem;
  font-family: ${themeGet('fontFamilySemibold')};
  width: 28%;
  margin-bottom: 0;
  padding-top: 0.125rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${breakpoints.customLarge} {
    width: 24%;
  }

  ${breakpoints.customMedium} {
    width: 260px;
  }

  ${breakpoints.customMobileLarge} {
    width: 9.25rem;
  }

  ${themeGet('breakpoints.mobile')} {
    width: 142px;
  }
`

export const UnitWrapper = styled.div.attrs(({ classesCreated }) => ({
  opacity: classesCreated === 0 ? '50%' : 'inherit',
}))`
  display: flex;
  width: 65px;
  justify-content: flex-start;
  align-items: center;
  color: ${themeGet('colors.darkGray')};
  font-family: ${themeGet('fontFamilySemibold')};
  font-size: 1rem;
  opacity: ${({ opacity }) => opacity};
  padding-top: 0.1875rem;
  padding-left: 2rem;

  ${breakpoints.customExtraLarge} {
    padding-left: 1.6875rem;
  }

  ${breakpoints.customMedium} {
    display: none;
  }

  [class*='Bullet'] {
    margin-bottom: 0.1875rem;
  }
`
