import React from 'react'
import PropTypes from 'prop-types'
import NavigationHelper from 'layers/navigation/navigationHelper'
import { domains } from 'config/env'
import { ContainedButton } from 'secondstep-components'
import {
  LicenseCardBox,
  LicenseCardButtonBox,
  LicenseCardLeftBox,
  LicenseCardRightBox,
  StyledHeaderTwo,
  StyledPlainProgramCard,
} from './component.styles'

import {
  LICENSE_CARD_HEADER,
  LICENSE_CARD_TEXTLINK,
  UNASSIGNED_LICENSES,
} from './constants'

export const LicenseCard = ({
  className,
  navigationEvent,
  unassignedLicenses,
}) => {
  if (!unassignedLicenses) return

  const unassignedLicensesCount = unassignedLicenses.length
  const onClick = () => {
    const type = NavigationHelper.types.OPEN
    navigationEvent(`${domains.ADMIN_DOMAIN}/license-management`, type)
  }
  return (
    <StyledPlainProgramCard className={className} dataTestId="license-card">
      <LicenseCardBox>
        <LicenseCardLeftBox>
          <StyledHeaderTwo>{LICENSE_CARD_HEADER}</StyledHeaderTwo>
          <span>{UNASSIGNED_LICENSES(unassignedLicensesCount)}</span>
        </LicenseCardLeftBox>
        <LicenseCardRightBox>
          <LicenseCardButtonBox>
            <ContainedButton
              dataTestId="text-link-manage-account"
              onClick={onClick}
            >
              {LICENSE_CARD_TEXTLINK}
            </ContainedButton>
          </LicenseCardButtonBox>
        </LicenseCardRightBox>
      </LicenseCardBox>
    </StyledPlainProgramCard>
  )
}

LicenseCard.propTypes = {
  className: PropTypes.string,
  navigationEvent: PropTypes.func,
  unassignedLicenses: PropTypes.array.isRequired,
}

export default LicenseCard
