// TODO: remove stripTimeToUTC function after Koalified has fixed timestamps on the backend: https://secondstep.atlassian.net/browse/LEARN-18325
export const isWithinDateRange = (startDate, endDate) => {
  const stripTimeToUTC = date => {
    return new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()),
    )
  }

  const now = stripTimeToUTC(new Date())
  const start = stripTimeToUTC(new Date(startDate))
  const end = stripTimeToUTC(new Date(endDate))

  return now >= start && now <= end
}
