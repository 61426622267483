import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import ImplementationLevelCardComponent from './component'

const ImplementationLevelCardContainer = props => {
  const {
    alt,
    clickable,
    description,
    handleSelection,
    icon,
    iconHeight,
    iconWidth,
    isActive,
    subtitle,
    title,
    value,
  } = props

  const [activeState, setActiveState] = useState(false)

  useEffect(() => {
    if (isActive != null) setActiveState(isActive)
  }, [isActive])

  useEffect(() => {
    if (!handleSelection) return
    if (activeState) handleSelection(value)
  }, [activeState])

  const toggleActiveState = () => setActiveState(true)

  return (
    <ImplementationLevelCardComponent
      alt={alt}
      clickable={clickable}
      description={description}
      icon={icon}
      iconHeight={iconHeight}
      iconWidth={iconWidth}
      isActive={activeState}
      subtitle={subtitle}
      title={title}
      toggleActiveState={toggleActiveState}
    />
  )
}

ImplementationLevelCardContainer.propTypes = {
  alt: PropTypes.string,
  clickable: PropTypes.bool,
  description: PropTypes.string,
  handleSelection: PropTypes.func,
  icon: PropTypes.string,
  iconHeight: PropTypes.number,
  iconWidth: PropTypes.number,
  isActive: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.object,
}

export default ImplementationLevelCardContainer
