import styledNative from 'styled-components/native'
import styled, { withTheme } from 'styled-components'
import { themeGet } from 'styled-system'

export const PageWrapper = styled.div.attrs(({ isSelaProduct }) => ({
  background: isSelaProduct
    ? themeGet('colors.backgrounds.tertiary')
    : themeGet('resources.sublandingBackgroundColor'),
}))`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  background-color: ${({ background }) => background};
  padding-bottom: 2rem;
`

export const ContentContainer = withTheme(styledNative.View`
  padding: 0 1.25rem;
  width: 100%;
  max-width: 1366px;
`)

export const BannerSpacer = styled.div`
  margin-top: 7rem;
`
