import React, { Component } from 'react'

import ParseEntry from 'utils/contentfulParser'
import { isPreviewMode } from 'utils/highschoolHelpers'

import ResourcesContent from './component'
import { determinePageLayout } from './pageLayouts'

export default class ResourcesContentContainer extends Component {
  mapper = entry => {
    if (entry) {
      const { derivedContent, rootContent, content } = ParseEntry(entry)
      const { anchorNavigation } = derivedContent || {}
      const { landingBanner } = rootContent || {}
      const pageLayout = determinePageLayout(anchorNavigation)
      const currentLocale = entry && entry.locale
      entry = {
        anchorNavigation,
        content,
        landingBanner,
        pageLayout,
        currentLocale,
      }

      return entry
    }

    return null
  }

  render() {
    const {
      children,
      entry,
      getEntryWithNewLocale,
      isDistrictSupportsPage,
      isFamilyResourcesPage,
      isSelaLeaderPage,
      isSelaProduct,
    } = this.props || {}
    const parsedEntry = this.mapper(entry) || {}

    return (
      <ResourcesContent
        {...parsedEntry}
        getEntryWithNewLocale={getEntryWithNewLocale}
        isDistrictSupportsPage={isDistrictSupportsPage}
        isFamilyResourcesPage={isFamilyResourcesPage}
        isHsPreview={isPreviewMode()}
        isSelaLeaderPage={isSelaLeaderPage}
        isSelaProduct={isSelaProduct}
      >
        {children}
      </ResourcesContent>
    )
  }
}
