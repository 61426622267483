import Understanding from './component'

const Container = props => {
  const { tabContent } = props || {}
  const { tabColumn1, tabColumn2 } = tabContent || {}
  const bodyCopy = tabColumn1[0]?.bodyCopy
  const link1 = tabColumn1[1]
  const link2 = tabColumn1[2]
  const videoId = tabColumn2[0]?.id

  return (
    bodyCopy &&
    videoId && (
      <Understanding
        bodyCopy={bodyCopy}
        link1={link1}
        link2={link2}
        videoId={videoId}
      />
    )
  )
}

export default Container
