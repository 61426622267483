import styled from 'styled-components'
import { Box } from 'grommet'
import { HeaderThree, BaseContainer } from 'secondstep-components'
import { themeGet } from 'styled-system'

export const RecommendedWrapper = styled(BaseContainer)`
  margin-top: 1.25rem;

  ${HeaderThree} {
    margin-bottom: 0.25rem;
  }
`

export const ActivityFlex = styled(Box)`
  flex-direction: row;

  ${themeGet('breakpoints.tablet')} {
    display: block;
    flex-direction: column;
  }

  ${themeGet('breakpoints.mobile')} {
    flex-direction: row;
  }
`
