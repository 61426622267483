import { SITE_QUERY_PARAM } from './constants'

export const getDefaultSite = siteList => {
  if (siteList?.length === 0 || !Array.isArray(siteList)) {
    return null
  }

  const { id: siteId, name: siteName } = siteList.sort((a, b) =>
    a.name < b.name ? -1 : 1,
  )[0]

  return { siteId, siteName }
}

export const getSiteFromUrl = siteList => {
  const queryParams = new URLSearchParams(location?.search)
  const siteId = queryParams.get(SITE_QUERY_PARAM)
  return findSiteById(siteList, siteId)
}

export const findSiteById = (siteList, searchId) => {
  if (siteList?.length === 0 || !searchId) {
    return null
  }

  const { id: siteId, name: siteName, sitePreferences } =
    siteList.find(site => site.id === parseInt(searchId)) || {}

  if (!siteId) return null
  const { highSchool: highSchoolObj } = sitePreferences || {}
  const { isSetup } = highSchoolObj || {}

  return { siteId, siteName, isSetup: !!isSetup }
}

export const isSiteQueryParamPresent = () => {
  return location.href.indexOf(SITE_QUERY_PARAM) > -1
}
