import React from 'react'

import BackLink from 'components/BackLink'
import ResourcesContent from 'pages/ResourcesContent'
import PropTypes from 'prop-types'

import { Constrainer, TopBar } from './component.styles'

const Webinars = ({ entry, backToDashboard }) => {
  return (
    <ResourcesContent entry={entry}>
      <TopBar>
        <Constrainer>
          <BackLink onClick={backToDashboard}>Back to Dashboard</BackLink>
        </Constrainer>
      </TopBar>
    </ResourcesContent>
  )
}

Webinars.propTypes = {
  backToDashboard: PropTypes.func.isRequired,
  entry: PropTypes.object.isRequired,
}

export default Webinars
